import { LeftOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Card, Col, Row, Switch } from "antd";
import { arrayMoveImmutable } from "array-move";
import React from "react";
import { FormattedMessage } from "react-intl";
import { SortableContainer, SortableElement } from "react-sortable-hoc";

import {
  MileStoneBatch,
  MilestoneReorder,
} from "./Components/MilestoneReorder";
import useMilestoneManagementScreen from "./hooks/useMilestoneManagementScreen";
import SingleMilestoneItem from "./SingleMilestoneItem";
import {
  MilestoneManagementScreenProps,
  SortableMilestoneItemProps,
} from "./types/SortableMilestoneItemProps";

const sortStyle = {
  SortableList: {
    position: "relative",
    zIndex: "0",
    outline: "none",
    listStyle: "none",
    padding: "0",
  },
};

// @ts-expect-error TS2322
const SortableMilestoneItem: React.FC<SortableMilestoneItemProps> =
  SortableElement(
    ({
      singleMilestone,
      isMilestoneSortable,
      onSaveMilestone,
      deleteAMilestone,
    }: SortableMilestoneItemProps) => (
      <li>
        <SingleMilestoneItem
          onSaveMilestone={onSaveMilestone}
          singleMilestone={singleMilestone}
          isMilestoneSortable={isMilestoneSortable}
          deleteAMilestone={deleteAMilestone}
        />
      </li>
    ),
  );
type SortableSingleMilestoneListProps = {
  deleteAMilestone?: any;
  isMilestoneSortable?: any;
  items?: {
    map?: (...args: any[]) => any;
  };
  onSaveMilestone?: any;
};
// @ts-expect-error TS2322
const SortableSingleMilestoneList: React.FC<SortableSingleMilestoneListProps> =
  SortableContainer(
    // @ts-expect-error TS7031
    ({ items, isMilestoneSortable, onSaveMilestone, deleteAMilestone }) => {
      return (
        // @ts-expect-error TS2322
        <ul style={sortStyle["SortableList"]}>
          {/*
           // @ts-expect-error TS7006 */}
          {items.map((singleMilestone, index) => {
            return (
              <SortableMilestoneItem
                key={index}
                // @ts-expect-error TS2322
                index={index}
                singleMilestone={singleMilestone}
                disabled={!isMilestoneSortable}
                onSaveMilestone={onSaveMilestone}
                deleteAMilestone={deleteAMilestone}
                isMilestoneSortable={isMilestoneSortable}
              />
            );
          })}
        </ul>
      );
    },
  );

const MilestoneManagementScreen: React.FC<MilestoneManagementScreenProps> =
  function (props: MilestoneManagementScreenProps) {
    const {
      batchName,
      history,
      setIsMilestoneSortable,
      persistedOrder,
      setMilestones,
      isMilestoneSortable,
      saveReOrder,
      setPersistedOrder,
      milestones,
      onSaveEditMilestone,
      deleteAMilestone,
    } = useMilestoneManagementScreen(props);
    return (
      <>
        <Card title={<FormattedMessage id="screens.company.milestone_mgt" />}>
          <Row>
            <Col span={24}>
              <Button
                onClick={() => {
                  history.goBack();
                }}
                icon={<LeftOutlined />}
              >
                <FormattedMessage id="screen.batch_hypothesis.go_back" />
              </Button>
            </Col>
            <Col
              xs={24}
              sm={{ offset: 2, span: 20 }}
              md={{ offset: 3, span: 18 }}
              lg={{ offset: 6, span: 12 }}
            >
              <Row>
                <Col>
                  <MileStoneBatch>{batchName}</MileStoneBatch>
                  <br />
                </Col>
              </Row>
              <Row gutter={[12, 12]}>
                <Col span={18}>
                  <MilestoneReorder>
                    <FormattedMessage id="screens.label.reorder_milestones" />
                    <Switch
                      style={{ marginLeft: "6px" }}
                      size={"small"}
                      onChange={(checked, event) => {
                        setIsMilestoneSortable(checked);
                        if (checked === false) {
                          if (persistedOrder) {
                            setMilestones(persistedOrder);
                          }
                        }
                      }}
                      checked={isMilestoneSortable}
                    />
                  </MilestoneReorder>
                </Col>
              </Row>
              {isMilestoneSortable ? (
                <Row>
                  <Col>
                    <Button
                      type={"ghost"}
                      onClick={() => {
                        setPersistedOrder(null);
                        saveReOrder();
                      }}
                    >
                      <FormattedMessage id="screen.label.save" />
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      onClick={() => {
                        setIsMilestoneSortable(false);
                        if (persistedOrder) {
                          setMilestones(persistedOrder);
                        }
                      }}
                    >
                      <FormattedMessage id="screen.label.cancel" />
                    </Button>
                  </Col>
                </Row>
              ) : null}
              <br />
              {Array.isArray(milestones) ? (
                <SortableSingleMilestoneList
                  isMilestoneSortable={isMilestoneSortable}
                  items={milestones}
                  // @ts-expect-error TS2322
                  helperClass={"SortableHelper"}
                  onSaveMilestone={onSaveEditMilestone}
                  deleteAMilestone={deleteAMilestone}
                  // @ts-expect-error TS7031
                  onSortEnd={({ oldIndex, newIndex }) => {
                    const orderedItems = arrayMoveImmutable(
                      milestones,
                      oldIndex,
                      newIndex,
                    ).map((m, index) => {
                      return {
                        // @ts-expect-error TS2698
                        ...m,
                        index,
                      };
                    });
                    // @ts-expect-error TS2345
                    setMilestones(orderedItems);
                  }}
                />
              ) : null}

              <br />
              <Button
                onClick={() => {
                  const milestone = {
                    index: milestones.length,
                    name: "",
                    date: new Date(),
                  };
                  // @ts-expect-error TS2769
                  setMilestones(milestones.concat(milestone));
                }}
                style={{ width: "100%" }}
                className="add-milestone-btn"
                type="dashed"
                icon={<PlusOutlined />}
              >
                <FormattedMessage id="screens.batch_milestone.save_milestone" />
              </Button>
            </Col>
          </Row>
        </Card>
      </>
    );
  };
export default MilestoneManagementScreen;
