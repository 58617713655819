import { notification } from "antd";
import { useMemo, useState } from "react";
import { useIntl } from "react-intl";

import { EventData } from "@/../types/Event";
import { EventMember } from "@/../types/EventMember";
import { User } from "@/../types/User";
import { useEventMember } from "@/features/event/hooks/useEventMember";

export const useEventUserState = (event: EventData, user: User) => {
  const initEventState = useMemo(() => {
    if (event?.EventMembers && event.EventMembers.length > 0) {
      return event.EventMembers.find((ev) => ev.userId === user.id);
    }
  }, [event, user]);

  return useState(initEventState);
};

export const useEventActions = (
  event: EventData,
  user: User,
  setEventUserState: React.Dispatch<
    React.SetStateAction<EventMember | undefined>
  >,
) => {
  const intl = useIntl();
  const { joinEvent, leaveEvent } = useEventMember();

  const sendNotification = (isFailed: boolean, successMessageId: string) => {
    if (isFailed) {
      return notification.error({
        message: intl.formatMessage({ id: "screen.label.error" }),
      });
    }

    notification.success({
      message: intl.formatMessage({ id: successMessageId }),
    });
  };

  const applyEvent = async () => {
    const { isFailed, response } = await joinEvent(
      {
        eventId: event.id,
        queryParams: "",
      },
      {
        userId: user.id,
        eventId: event.id,
      },
    );
    sendNotification(isFailed, "screen.label.apply_participation_event");
    if (response.data?.status === "success") {
      setEventUserState(response.data.data);
    }

    if (isFailed) {
      throw new Error("Failed to apply event");
    }
  };

  const cancelEvent = async () => {
    const { isFailed } = await leaveEvent({
      eventId: event.id,
      userId: user.id,
    });
    sendNotification(isFailed, "screen.label.canceled_participate_event");
    if (!isFailed) {
      setEventUserState(undefined);
    }
  };

  return {
    applyEvent,
    cancelEvent,
  };
};
