import { memo } from "react";
import { useIntl } from "react-intl";

import styles from "@/features/review/components/UploadReviewExcelButton/styles.module.scss";
import { UploadReviewExcelButtonProps } from "@/features/review/components/UploadReviewExcelButton/type";
import { PrimaryButton } from "@/shared/buttons/components/PrimaryButton";

export const UploadReviewExcelButton = memo(
  ({ uploadExcel, disabled }: UploadReviewExcelButtonProps) => {
    const intl = useIntl();
    const registryButtonLabel = intl.formatMessage({
      id: "screen.label.execute_registry",
    });
    return (
      <PrimaryButton
        onClick={uploadExcel}
        className={styles.registry_button}
        disabled={disabled}
        data-testid="upload-review-excel-button"
      >
        {registryButtonLabel}
      </PrimaryButton>
    );
  },
);
