import { useCallback, useState } from "react";

export const usePagination = (defaultLimit?: number) => {
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(defaultLimit ?? 10);

  const onNextPage = useCallback(() => {
    setPage((prev) => prev + 1);
  }, [setPage]);

  const onPrevPage = useCallback(() => {
    setPage((prev) => prev - 1);
  }, [setPage]);

  const resetPage = useCallback(() => {
    setPage(1);
  }, [setPage]);
  const onChangeLimit = useCallback(
    (limit: number) => {
      setLimit(limit);
      resetPage();
    },
    [resetPage],
  );

  return {
    page,
    limit,
    resetPage,
    onNextPage,
    onPrevPage,
    onChangeLimit,
  };
};
