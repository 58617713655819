import { useState } from "react";

import { VariantIcon } from "@/shared/icons/components/VariantIcon/component";
import { FileIconVariant } from "@/shared/icons/components/VariantIcon/type";

import styles from "./CustomImage.module.scss";

type ImageProps = ThumbnailProps & AltIconProps;

export const Image = (props: ImageProps) => {
  const [errored, setErrored] = useState(false);

  const onError = () => {
    setErrored(true);
  };
  const { altIcon, gallery, ...thumbnailProps } = props;

  if (!errored) {
    return (
      <FileThumbnail {...thumbnailProps} gallery={gallery} onError={onError} />
    );
  } else {
    return <FileIcon gallery={gallery} altIcon={altIcon} />;
  }
};

type ThumbnailProps = JSX.IntrinsicElements["img"] & { gallery?: boolean };

const FileThumbnail = ({ gallery, ...imgProps }: ThumbnailProps) => (
  <img {...imgProps} className={gallery ? styles.gallery : styles.list} />
);

type AltIconProps = {
  altIcon: FileIconVariant;
  gallery?: boolean;
};

const FileIcon = ({ altIcon, gallery }: AltIconProps) => {
  return (
    <VariantIcon
      variant={altIcon}
      className={gallery ? styles.gallery : styles.list}
    />
  );
};
