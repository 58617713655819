import { Popover } from "antd";
import React from "react";
import { Link } from "react-router-dom";

import { Ptag } from "../../organisms";

type Props = {
  eventUUID: string;
  text: string;
  textStyle: React.CSSProperties;
};

export const EventTitleColumn: React.FC<Props> = ({
  eventUUID,
  text,
  textStyle,
}) => {
  return (
    <Link
      to={`/dashboard/events.detail/${eventUUID}`}
      className={"view-event-btn"}
    >
      <Ptag
        style={{
          maxLines: 2,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          ...textStyle,
        }}
      >
        <Popover
          placement="topLeft"
          content={
            <Ptag
              style={{
                ...textStyle,
              }}
            >
              {text}
            </Ptag>
          }
        >
          {text}
        </Popover>
      </Ptag>
    </Link>
  );
};
