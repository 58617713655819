import { useCallback } from "react";
import { useIntl } from "react-intl";

import { Checkbox } from "@/shared/checkbox/components/CheckBox";
import { SelectOption } from "@/shared/selectbox/types/SelectOption";

import styles from "./styles.module.scss";
import { CheckableMenuListProps, CheckOptionItemProps } from "./type";

const CheckOptionItem = ({
  onChange,
  checked,
  label,
}: CheckOptionItemProps) => {
  const checkedLabelClass = checked ? styles.option_label_checked : "";
  return (
    <button className={styles.option_button} onClick={onChange}>
      <div className={styles.option_item}>
        <Checkbox disabled={false} checked={checked} onCheck={onChange} />
        <span className={`${styles.option_label} ${checkedLabelClass}`}>
          {label}
        </span>
      </div>
    </button>
  );
};

export const CheckableMenuList = <OptionValue,>({
  onSelect,
  selectedOptions,
  resetCheck,
  checkedExits,
  ...props
}: CheckableMenuListProps<OptionValue>) => {
  const intl = useIntl();
  const onChange = useCallback(
    (newValue: SelectOption<OptionValue>) => {
      onSelect(newValue);
    },
    [onSelect],
  );
  const resetButtonLabelClass = `${styles.reset_button_label} ${
    checkedExits
      ? styles.reset_button_label_checked
      : styles.reset_button_label_unchecked
  }`;
  return (
    <div className={styles.menu_list_container}>
      {props.options.map(
        (option) =>
          "value" in option && (
            <CheckOptionItem
              key={String(option.value)}
              onChange={() => {
                onChange(option);
              }}
              checked={selectedOptions.some(
                ({ value }) => value === option.value,
              )}
              label={option.label ?? ""}
            />
          ),
      )}
      <button
        className={styles.reset_button}
        disabled={!checkedExits}
        onClick={resetCheck}
        data-testid="reset-checkbox-button"
      >
        <span className={resetButtonLabelClass}>
          {intl.formatMessage({ id: "screen.label.condition_reset" })}
        </span>
      </button>
    </div>
  );
};
