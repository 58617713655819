import { Modal } from "antd";
import { FormattedMessage, useIntl } from "react-intl";

import { formatDateTimeRange } from "@/utils/formatDateTime";

import { EventCancellationModalProps } from "./type";

export const EventCancellationModal = ({
  event,
  onCloseRegisterEventModal,
}: EventCancellationModalProps) => {
  const intl = useIntl();

  return (
    <Modal
      title={intl.formatMessage({
        id: "screen.label.event_cancel_notification",
      })}
      centered
      footer={null}
      onCancel={onCloseRegisterEventModal}
      visible={event.id > 0}
      width={"45vw"}
    >
      <p>
        <FormattedMessage
          id={"screen.label.event_cancel_description"}
          values={{
            event: event.name,
            dateTime: formatDateTimeRange(
              new Date(event.beginsAt).toDateString(),
              event.beginsAt,
              event.endsAt,
              intl.locale,
            ),
          }}
        />
      </p>
    </Modal>
  );
};
