import { ContentContext } from "./ContextProvider";

// @ts-expect-error TS7006
export default function withContext(Component) {
  // @ts-expect-error TS7006
  return function contextComponent(props) {
    return (
      <ContentContext.Consumer>
        {(context) => <Component {...props} context={context} />}
      </ContentContext.Consumer>
    );
  };
}
