import { useCallback } from "react";
import { useIntl } from "react-intl";

import { Checkbox } from "@/shared/checkbox/components/CheckBox";

import styles from "./styles.module.scss";
import {
  ReviewInfoGridCheckboxHeaderProps,
  ReviewInfoGridHeaderProps,
} from "./type";

export const ReviewInfoGridCheckboxHeader = ({
  checked,
  onCheckAll,
  disabled,
}: ReviewInfoGridCheckboxHeaderProps) => {
  const handleCheck = useCallback(() => {
    onCheckAll();
  }, [onCheckAll]);
  return (
    <div className={styles.checkbox_header}>
      <Checkbox disabled={disabled} checked={checked} onCheck={handleCheck} />
    </div>
  );
};

export const ReviewInfoGridProjectInfoHeader = () => {
  const intl = useIntl();
  const title = intl.formatMessage({
    id: "screen.label.review.submitted_project_info",
  });
  return (
    <div className={styles.project_info_header}>
      <span>{title}</span>
    </div>
  );
};

export const ReviewInfoGridReviewResultInfoHeader = () => {
  const intl = useIntl();
  const title = intl.formatMessage({
    id: "screen.label.review.review_result_info",
  });
  return (
    <div className={styles.review_info_header}>
      <span>{title}</span>
    </div>
  );
};

export const ReviewInfoGridHeader = ({
  checked,
  onCheckAll,
  disabled,
}: ReviewInfoGridHeaderProps) => {
  return (
    <div
      id="review_info_grid_header"
      className={styles.review_info_grid_header_container}
    >
      <ReviewInfoGridCheckboxHeader
        disabled={disabled}
        checked={checked}
        onCheckAll={onCheckAll}
      />
      <ReviewInfoGridProjectInfoHeader />
      <ReviewInfoGridReviewResultInfoHeader />
    </div>
  );
};
