import { Card } from "antd";
import { MdLightbulbOutline } from "react-icons/md";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";

import AuthActions from "@/Redux/AuthRedux";

import styles from "./styles.module.scss";

export const ErrorPage = () => {
  const dispatch = useDispatch();
  const logout = () => {
    dispatch(AuthActions.logout());
  };
  return (
    <Card>
      <div className={styles.root}>
        <img
          className={styles.logo}
          src="/login_banner.png"
          alt="incubation suite logo"
        />
        <h1 className={styles.unexpected_error_occured}>
          <FormattedMessage id="screen.label.unexpected_error_occured" />
        </h1>

        <div className={styles.info}>
          <div className={styles.try_this}>
            <h2 className={styles.try_following}>
              <MdLightbulbOutline />
              <FormattedMessage id="screen.label.try_following" />:
            </h2>
            <ul className={styles.try_following_list}>
              <li className={styles.list_disc}>
                <FormattedMessage id="screen.label.reload_page" />
              </li>
              <li className={styles.list_disc}>
                <FormattedMessage
                  id="screen.label.login_again"
                  values={{
                    logout: (chunks) => (
                      <a href="/" onClick={logout}>
                        {chunks}
                      </a>
                    ),
                  }}
                />
              </li>
            </ul>
          </div>

          <div>
            <p className={styles.let_us_know_via_email}>
              <FormattedMessage
                id="screen.label.let_us_know_via_email"
                values={{
                  email: (chunks) => (
                    <a href="mailto:support_incubation-suite@alphadrive.co.jp">
                      {chunks}
                    </a>
                  ),
                }}
              />
            </p>

            <div>
              <p className={styles.let_us_know_small}>
                <FormattedMessage id="screen.label.let_us_know_profile" />
              </p>
              <p className={styles.let_us_know_small}>
                <FormattedMessage id="screen.label.let_us_know_details" />
              </p>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};
