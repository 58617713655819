import { ExaminationResultEntity } from "../types/examinationResultEntity";
import { EXAMINATION_RESULTS } from "./examinationResults";
// 未選択
export const BLANK: ExaminationResultEntity = {
  label: "screen.label.unanswered",
  value: EXAMINATION_RESULTS.BLANK,
} as const;

// 条件付き通過
export const CONDITIONAL_PASS = {
  label: "screen.label.conditional_pass",
  value: EXAMINATION_RESULTS.CONDITIONAL_PASS,
} as const;

//通過
export const PASS = {
  label: "screen.label.pass",
  value: EXAMINATION_RESULTS.PASS,
} as const;

//見送り
export const SEND_OFF = {
  label: "screen.label.send_off",
  value: EXAMINATION_RESULTS.SEND_OFF,
} as const;
//保留
export const ON_HOLD = {
  label: "screen.label.on_hold",
  value: EXAMINATION_RESULTS.ON_HOLD,
};
//その他
export const OTHER = {
  label: "screen.label.other",
  value: EXAMINATION_RESULTS.OTHER,
};

export const EXAMINATION_RESULT_ENTITIES = {
  PASS,
  SEND_OFF,
  CONDITIONAL_PASS,
  ON_HOLD,
  OTHER,
  BLANK,
} as const;
