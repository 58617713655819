import { useCallback, useState } from "react";

import { Batch } from "../../../types/Batch";
import { JsendError } from "../../../types/JSendObject";
import { api } from "../../Saga";
import { endpoints, parseEndpoint } from "../endpoints";
import { GetBatchResponse } from "./type";

const useBatchRequest = (batchId: string) => {
  const [batch, setBatch] = useState<Batch | undefined>(undefined);

  const getBatch = useCallback(async (batchId: string) => {
    const response = await api.getRequest<GetBatchResponse, JsendError>(
      parseEndpoint(endpoints.singleBatch, {
        batchId,
      }),
    );
    return response;
  }, []);

  const updateBatch = useCallback(async () => {
    const response = await getBatch(batchId);

    if (response.ok && response.data?.status === "success") {
      setBatch(response.data.data);
    }
  }, [batchId, getBatch]);

  return { batch, updateBatch };
};

export default useBatchRequest;
