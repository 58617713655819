import { Action } from "@reduxjs/toolkit";
import { createActions, createReducer } from "reduxsauce";
import Immutable from "seamless-immutable";

import { User } from "@/../types/User";

import { ToAction, ToActionType } from "./types";

/* ------------- Types and Action Creators ------------- */
type UserActionCreators = {
  userGetProfileRequest: () => Action<"USER_GET_PROFILE_REQUEST">;
  userGetProfileSuccess: () => Action<"USER_GET_PROFILE_SUCCESS">;
  userGetProfileFailure: () => Action<"USER_GET_PROFILE_FAILURE">;

  userUpdateProfileRequest: () => Action<"USER_UPDATE_PROFILE_REQUEST">;
  userUpdateProfileSuccess: () => Action<"USER_UPDATE_PROFILE_SUCCESS">;
  userUpdateProfileFailure: () => Action<"USER_UPDATE_PROFILE_FAILURE">;

  userRefreshProfileRequest: () => Action<"USER_REFRESH_PROFILE_REQUEST">;
  userRefreshProfileSuccess: () => Action<"USER_REFRESH_PROFILE_SUCCESS">;
  userRefreshProfileFailure: () => Action<"USER_REFRESH_PROFILE_FAILURE">;

  userGetsRequest: () => Action<"USER_GETS_REQUEST">;
  userGetsSuccess: () => Action<"USER_GETS_SUCCESS">;
  userGetsFailure: () => Action<"USER_GETS_FAILURE">;

  userCreateProfileRequest: () => Action<"USER_CREATE_PROFILE_REQUEST">;
  userCreateProfileSuccess: () => Action<"USER_CREATE_PROFILE_SUCCESS">;
  userCreateProfileFailure: () => Action<"USER_CREATE_PROFILE_FAILURE">;

  userGetSuccess: () => Action<"USER_GET_SUCCESS">;

  userSetAllUsersForMention: () => Action<"USER_SET_ALL_USERS_FOR_MENTION">;
};
type UserAction = ToAction<UserActionCreators>;
type UserActionType = ToActionType<UserAction>;

const { Types, Creators } = createActions<UserActionType, UserActionCreators>({
  userGetProfileRequest: [],
  userGetProfileSuccess: null,
  userGetProfileFailure: ["errors"],

  userUpdateProfileRequest: ["data", "message"],
  userUpdateProfileSuccess: null,
  userUpdateProfileFailure: ["errors"],

  userRefreshProfileRequest: ["data"],
  userRefreshProfileSuccess: null,
  userRefreshProfileFailure: ["errors"],

  userGetsRequest: ["page", "filter", "sort"],
  userGetsSuccess: ["usersPayload"],
  userGetsFailure: ["errors"],

  userCreateProfileRequest: ["data", "message"],
  userCreateProfileSuccess: null,
  userCreateProfileFailure: ["errors"],

  userGetSuccess: ["userPayload"],

  userSetAllUsersForMention: ["allUsersForMentionPayload"],
});

export const UserTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const defaultUserPayload: User = {
  id: -1,
  name: "",
  email: "",
  // @ts-expect-error TS2322
  type: "",
  // @ts-expect-error TS2322
  isSuper: null,
  companyId: -1,
  roleId: -1,
  departmentId: -1,
  workHistorySummary: "",
  profileImage: "",
  personalBio: "",
  // @ts-expect-error TS2322
  createdAt: new Date(),
  // @ts-expect-error TS2322
  updatedAt: new Date(),
  // @ts-expect-error ts(2740)
  Company: {
    id: 1,
    name: "",
    icon: "",
    currentPlan: "",
    stages: [],
  },
  subsidiary: "",
  userTag: "",
  Interest: [],
  Project: [],
};

export type UserState = {
  fetching: boolean;
  errors: unknown;
  error?: unknown;
  usersPayload: {
    rows: User[];
    count: number;
    totalAdmins: number;
    totalGeneralUser: number;
    totalProgramManagers: number;
    totalMentorUser: number;
    totalStoppedUsers?: number;
  };
  userPayload: User;
  allUsersForMentionPayload: {
    isSuper: boolean;
    type: string;
    isActive: boolean;
    deletedAt: null;
    stoppedAt: null;
    id: number;
    uuid: string;
    name: string;
    email: string;
  }[];
};

export const INITIAL_STATE = Immutable<UserState>({
  fetching: false,
  errors: {},
  usersPayload: {
    rows: [],
    count: 0,
    totalAdmins: 0,
    totalGeneralUser: 0,
    totalProgramManagers: 0,
    totalMentorUser: 0,
  },
  userPayload: defaultUserPayload,
  allUsersForMentionPayload: [],
});

type ImmUserState = typeof INITIAL_STATE;

/* ------------- Reducers ------------- */
const request = (state: ImmUserState) => state.merge({ fetching: true });

const success = (state: ImmUserState) => {
  // @ts-expect-error TS2322
  return state.merge({ fetching: false, error: null });
};

// @ts-expect-error TS7031
const failure = (state: ImmUserState, { error }) =>
  state.merge({ fetching: false, error });

const userGetsRequest = (state: ImmUserState) =>
  state.merge({ fetching: true });

// @ts-expect-error TS7031
const userGetsSuccess = (state: ImmUserState, { usersPayload }) => {
  return state.merge({ fetching: false, usersPayload });
};

// @ts-expect-error TS7031
const userGetsFailure = (state: ImmUserState, { error }) =>
  state.merge({ fetching: false, error });

const userCreateProfileRequest = (state: ImmUserState) =>
  state.merge({ fetching: true });

const userSetAllUsersForMention = (
  state: ImmUserState,
  // @ts-expect-error TS7031
  { allUsersForMentionPayload },
) => state.merge({ allUsersForMentionPayload });

const userCreateProfileSuccess = (state: ImmUserState) => {
  return state.merge({ fetching: false });
};

// @ts-expect-error TS7031
const userCreateProfileFailure = (state: ImmUserState, { errors }) =>
  state.merge({ fetching: false, errors });

// @ts-expect-error TS7031
const userGetSuccess = (state: ImmUserState, { userPayload }) =>
  state.merge({ userPayload });

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer<ImmUserState, UserAction>(INITIAL_STATE, {
  [Types.USER_GET_PROFILE_REQUEST]: request,
  [Types.USER_GET_PROFILE_SUCCESS]: success,
  [Types.USER_GET_PROFILE_FAILURE]: failure,

  [Types.USER_UPDATE_PROFILE_REQUEST]: request,
  [Types.USER_UPDATE_PROFILE_SUCCESS]: success,
  [Types.USER_UPDATE_PROFILE_FAILURE]: failure,

  [Types.USER_REFRESH_PROFILE_REQUEST]: request,
  [Types.USER_REFRESH_PROFILE_SUCCESS]: success,
  [Types.USER_REFRESH_PROFILE_FAILURE]: failure,

  [Types.USER_CREATE_PROFILE_REQUEST]: userCreateProfileRequest,
  [Types.USER_CREATE_PROFILE_SUCCESS]: userCreateProfileSuccess,
  [Types.USER_CREATE_PROFILE_FAILURE]: userCreateProfileFailure,

  [Types.USER_GETS_REQUEST]: userGetsRequest,
  [Types.USER_GETS_SUCCESS]: userGetsSuccess,
  [Types.USER_GETS_FAILURE]: userGetsFailure,

  [Types.USER_GET_SUCCESS]: userGetSuccess,

  [Types.USER_SET_ALL_USERS_FOR_MENTION]: userSetAllUsersForMention,
});
