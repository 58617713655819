import { EditFilled, EllipsisOutlined } from "@ant-design/icons";
import { Button, Popover } from "antd";
import { useCallback } from "react";

import { Event } from "../../../../types/Event";
import { EventActionButtons } from "../../molecules/events/EventActionButtons";

type EventActionMenuProps = {
  event: Event;
  eventMemberPageLink: (event: Event) => string;
  isExpired: (event: Event) => boolean;
  onCreateEventNotification: (event: Event) => void;
  onCreateEventSurvey: (event: Event) => void;
  onConfirmDelete: (event: Event) => void;
  onEdit: (event: Event) => void;
  onToggleAcceptMember: (event: Event) => void;
};

export const EventActionMenu = ({
  event,
  eventMemberPageLink,
  isExpired,
  onCreateEventNotification,
  onCreateEventSurvey,
  onConfirmDelete,
  onEdit,
  onToggleAcceptMember,
}: EventActionMenuProps) => {
  const handleEdit = useCallback(() => {
    onEdit(event);
  }, [event, onEdit]);

  return (
    <>
      <Button
        type="ghost"
        style={{ marginRight: 16 }}
        onClick={handleEdit}
        icon={<EditFilled />}
      />
      <Popover
        content={
          <EventActionButtons
            event={event}
            eventMemberPageLink={eventMemberPageLink}
            onConfirmDelete={onConfirmDelete}
            isExpired={isExpired}
            onToggleAcceptMember={onToggleAcceptMember}
            onCreateEventNotification={onCreateEventNotification}
            onCreateEventSurvey={onCreateEventSurvey}
          />
        }
      >
        <Button type="ghost" icon={<EllipsisOutlined />}></Button>
      </Popover>
    </>
  );
};
