import { Col, Row } from "antd";
import { EditorState } from "draft-js";
import { FC, ReactNode, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import { Comment, CommentId } from "@/../types/Comment";
import { Thread } from "@/../types/Thread";
import { User } from "@/../types/User";
import { DraftEditor as Editor } from "@/Components/molecules";
import { CommentDeleteButton } from "@/features/projectComment/component/CommentDeleteButton";
import { CommentEditButton } from "@/features/projectComment/component/CommentEditButton";
import { CommentInfoLabel } from "@/features/projectComment/component/CommentInfoLabel";
import CommentActions from "@/Redux/CommentRedux";
import {
  convertFromRawWithTrimmer,
  sanitizeContentStateMarkupHtml,
  sanitizeEditorStateMarkupHtml,
} from "@/utils/common";

import { CommentEditModal } from "../CommentEditModal";
import { CommentFooter } from "../CommentFooter";
import { ReplyEditor } from "../ReplyEditor";
import { ThreadItem } from "../ThreadItem";
import { useReplyThreads } from "./hooks";

const EditorWrapper = styled(Row)`
  border-bottom-color: #f0f0f0;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  margin-bottom: 16px;
  padding-bottom: 16px;
  padding-left: 50px;
`;

export type CommentItemProps = {
  projectId?: string;
  comment: Comment;
  user: User;
  index: number;
  showAllChildren: boolean;
  canEdit?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  suggestions?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  editorToolbar?: any;
  renderThreadMessageFooter: (
    commentId: CommentId,
    thread: Thread,
  ) => ReactNode;
  renderMainCommentReaction: (
    comment: Comment,
    index: number,
    showChildren: boolean,
  ) => ReactNode;
};

export const CommentItem: FC<CommentItemProps> = ({
  projectId,
  comment,
  user,
  canEdit,
  showAllChildren,
  index,
  suggestions,
  editorToolbar,
  renderThreadMessageFooter,
  renderMainCommentReaction,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [showEditModal, setShowEditModal] = useState(false);

  const {
    threads,
    replyActive,
    showChildren,
    replyEditorState,
    onReplyEditorStateChange,
    onFocusReplyEditor,
    onCancelReply,
    onPostReply,
    onOpenThreads,
    onEditThread,
    onDeleteThread,
  } = useReplyThreads({
    projectId,
    comment,
    user,
    showAllChildren,
  });

  const defaultEditorState = sanitizeEditorStateMarkupHtml(
    EditorState.createWithContent(convertFromRawWithTrimmer(comment.content)),
  );

  const onDeleteComment = (comment: Comment) => {
    const message = intl.formatMessage({
      id: "screen.label.comment_has_been_deleted",
    });
    dispatch(
      CommentActions.commentDeleteRequest(
        // @ts-expect-error TS2345
        {
          commentId: comment.id,
          projectId,
        },
        message,
      ),
    );
  };

  return (
    <>
      {/* コメント編集モーダル */}
      <CommentEditModal
        defaultEditorState={defaultEditorState}
        visible={showEditModal}
        onCancel={() => setShowEditModal(false)}
        toolbar={editorToolbar}
        suggestions={suggestions}
        commentId={comment.id}
      />

      <Row align="middle" justify="space-between">
        <Col flex="4">
          <CommentInfoLabel
            id={`comment_${comment.id}`}
            commentedPerson={comment.User}
            commentedDateAsISO={comment.createdAt}
          />
        </Col>
        <Col flex="1">
          <Row
            style={{
              marginRight: "10px",
            }}
            align="middle"
            justify="end"
          >
            {user.id == comment.User.id && canEdit ? (
              <>
                <CommentEditButton
                  comment={comment}
                  onEdit={() => setShowEditModal(true)}
                />
                <CommentDeleteButton
                  comment={comment}
                  onDelete={onDeleteComment}
                />
              </>
            ) : null}
          </Row>
        </Col>
      </Row>
      <EditorWrapper>
        {comment.nodalData ? (
          <div
            style={{
              display: "block",
              width: "100%",
            }}
          >
            <Editor
              editorClassName={"shortEditor"}
              toolbarHidden={true}
              readOnly={true}
              editorState={sanitizeContentStateMarkupHtml(
                convertFromRawWithTrimmer(comment.content),
              )}
              // @ts-expect-error TS2322
              localization={{
                locale: "ja",
              }}
              mention={{
                separator: " ",
                trigger: "@",
              }}
            />
          </div>
        ) : (
          <p>{comment.content}</p>
        )}
        <div
          style={{
            display: "flex",
          }}
        >
          {renderMainCommentReaction(comment, index, showChildren)}
        </div>
        <CommentFooter
          threads={threads}
          canEdit={canEdit}
          showChildren={showChildren}
          onOpenCommentThread={onOpenThreads}
        />
        {showChildren && (
          <Row
            style={{
              display: "block",
              width: "100%",
              borderLeftWidth: "4px",
              borderLeftColor: "#DDDDDD",
              borderLeftStyle: "solid",
              marginTop: "3vh",
            }}
            // @ts-expect-error TS2322
            flex="1"
            align="top"
            justify="space-between"
            key={`comment_children_${comment.id}`}
          >
            {threads.map((thread) => (
              <ThreadItem
                key={thread.id}
                commentId={comment.id}
                thread={thread}
                user={user}
                canEdit={canEdit}
                onEdit={onEditThread}
                onDelete={onDeleteThread}
                renderThreadMessageFooter={renderThreadMessageFooter}
              />
            ))}
            {canEdit && (
              <ReplyEditor
                active={replyActive}
                suggestions={suggestions}
                toolbar={editorToolbar}
                editorState={replyEditorState}
                onEditorStateChange={onReplyEditorStateChange}
                onFocusEditor={onFocusReplyEditor}
                onPostReply={onPostReply}
                onCancelReply={onCancelReply}
              />
            )}
          </Row>
        )}
      </EditorWrapper>
    </>
  );
};
