import { Card, Col, Form, Row } from "antd";
import { FormattedMessage } from "react-intl";

import { ButtonCV } from "../../Components/atoms";
import { InsuRejectButton } from "../../Components/atoms/buttons/shared";
import { Input, TextArea } from "../../Components/molecules";
import FileUploadComponent from "../project/FileUploadComponent";
import useEditSmallCategoryScreen from "./hooks/useEditSmallCategoryScreen";
import { IEditSmallCategoryScreenProps } from "./types/IEditSmallCategoryScreenProps";

const EditSmallCategoryScreen = function (
  props: IEditSmallCategoryScreenProps,
) {
  const { intl, state, handleChange, onUpdateSmallCategory } =
    useEditSmallCategoryScreen(props);

  return (
    <>
      <h2>
        <FormattedMessage id={"screen.label.small_category_edit"} />
      </h2>
      <Card>
        <Row>
          <Col xs={24} sm={12}>
            <Form layout={"vertical"}>
              <Col span={24}>
                <Form.Item
                  label={`${intl.formatMessage({
                    id: "screen.label.title",
                  })} *`}
                >
                  <Input
                    value={state.name}
                    onChange={(event) =>
                      handleChange("name", event.target.value)
                    }
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  label={intl.formatMessage({
                    id: "screen.label.more_detail",
                  })}
                >
                  <TextArea
                    rows={7}
                    value={state.description}
                    onChange={(event) =>
                      handleChange("description", event.target.value)
                    }
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={12} md={8} lg={8}>
                {state.name ? (
                  <Form.Item
                    label={`${intl.formatMessage({
                      id: "screen.label.thumbnail_attached",
                    })} *`}
                  >
                    <FileUploadComponent
                      imageSrc={state.thumbnail}
                      message={`${intl.formatMessage({
                        id: "screen.label.upload_image_click",
                      })}`}
                      onFileChanged={(thumbnail) => {
                        handleChange("thumbnail", thumbnail);
                      }}
                    />
                  </Form.Item>
                ) : null}
              </Col>
            </Form>
          </Col>
          <Col span={24}>
            <ButtonCV
              onClick={onUpdateSmallCategory}
              style={{
                marginTop: 20,
                width: "7vw",
                marginRight: "0.3vw",
              }}
            >
              {intl.formatMessage({
                id: "screen.label.storage",
              })}
            </ButtonCV>
            <InsuRejectButton
              // @ts-expect-error TS2339
              onClick={props.history.goBack}
              style={{
                marginTop: 20,
                width: "7vw",
              }}
            >
              <FormattedMessage id={"screen.label.cancel"} />
            </InsuRejectButton>
          </Col>
        </Row>
      </Card>
    </>
  );
};
export default EditSmallCategoryScreen;
