import { useEffect, useRef, useState } from "react";

import { isEmailValid } from "../../../utils/common";
import {
  ILoginScreenProps,
  LoginScreenState,
} from "../types/ILoginScreenProps";

const useLoginScreen = (props: ILoginScreenProps) => {
  const [state, customSetState] = useState<LoginScreenState>(() => {
    return {
      email: "",
      password: "",
      redirect: "",
    };
  });

  const setStateCallbackRef = useRef(() => {});
  useEffect(() => {
    const callBack = setStateCallbackRef.current;
    callBack();
    setStateCallbackRef.current = () => {};
  }, [state]);
  const setState = (data: LoginScreenState, callback = () => {}) => {
    setStateCallbackRef.current = callback;
    customSetState((previousState) => {
      return {
        ...previousState,
        ...data,
      };
    });
  };
  useEffect(() => {
    localStorage.removeItem("projectListScreen");
    localStorage.removeItem("membersScreen");
    // @ts-expect-error TS18048
    const redirectHistory = props.location.state
      ? // @ts-expect-error TS18048

        props.location.state.from
      : null;
    // @ts-expect-error TS18048
    const url = new URLSearchParams(props.location.search);
    const redirect = url.get("redirect");
    if (
      (redirect && redirect.length > 0) ||
      (redirectHistory && redirectHistory.pathname.length > 0)
    ) {
      let redirectLocation = redirect || redirectHistory.pathname;
      if (redirectLocation.indexOf("http") !== -1) redirectLocation = "";
      setState({
        redirect: redirectLocation,
      });
    }
  }, []);

  const getCompanyId = () => {
    const {
      match: { params },
    } = props;
    return params.id ? params.id : null;
  };
  const onChange = (e: { target: { id: any; value: any } }) => {
    setState({ [e.target.id]: `${e.target.value}`.trim() });
  };
  const onSubmit = () => {
    const companyRegId = getCompanyId();
    if (companyRegId) {
      const userData = {
        email: state.email,
        password: state.password,
        companyRegId: companyRegId,
      };
      // @ts-expect-error TS18048
      if (state.redirect.length > 0) {
        // @ts-expect-error TS7053
        userData["redirect"] = state.redirect;
      }
      props.dispatchIpLoginRequest(userData);
    } else {
      const userData = {
        email: state.email,
        password: state.password,
      };
      // @ts-expect-error TS18048
      if (state.redirect.length > 0) {
        // @ts-expect-error TS7053
        userData["redirect"] = state.redirect;
      }
      props.dispatchLoginRequest(userData);
    }
  };
  const isInputsValid = () => {
    // @ts-expect-error TS2365
    return !isEmailValid(state.email) || !state.password.length > 0;
  };
  return {
    state,
    setState,
    getCompanyId,
    onChange,
    onSubmit,
    isInputsValid,
  };
};
export default useLoginScreen;
