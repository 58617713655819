import FileSaver from "file-saver";
import { useEffect, useRef } from "react";
import { useIntl } from "react-intl";

import { endpoints, parseEndpoint } from "../../../Services/endpoints";
import { useStateWithRef } from "../../../utils/hooks";
import {
  IProgramInfoViewScreenProps,
  ProgramInfoViewScreenState,
} from "../types/IProgramInfoViewScreenProps";

const useProgramInfoViewScreen = (props: IProgramInfoViewScreenProps) => {
  const intl = useIntl();
  const { updatePageTitle, apiRequest, user } = props.context;
  const [state, customSetState, stateRef] = useStateWithRef(() => {
    return {
      faq: [],
      editorStateInJson: "",
      files: [],
      videoUrl: "",
      update: true,
      infodetail: false,
      infofaq: false,
      infofiles: false,
    };
  });
  const setStateCallbackRef = useRef(() => {});
  useEffect(() => {
    const callBack = setStateCallbackRef.current;
    callBack();
    setStateCallbackRef.current = () => {};
  }, [state]);
  const setState = (data: ProgramInfoViewScreenState, callback = () => {}) => {
    setStateCallbackRef.current = callback;
    // @ts-expect-error TS7006
    customSetState((previousState) => {
      return {
        ...previousState,
        ...data,
      };
    });
  };

  useEffect(() => {
    updatePageTitle("screen.label.program_information");
    getProgramInfoDetail();
    getProgramInfoFaq();
    getProgramInfoFiles();
  }, []);

  const checkStatus = () => {
    const { infodetail, infofaq, infofiles } = stateRef.current;
    if (infodetail && infofiles && infofaq) {
      setTimeout(() => {
        setState({
          update: false,
        });
      }, 800);
    }
  };
  const getProgramInfoFiles = () => {
    const requestBuilder = {
      method: "getRequest",
      url: parseEndpoint(endpoints.programInfoFiles),
      data: {},
    };
    // @ts-expect-error TS2345
    apiRequest(requestBuilder, ({ data }) => {
      setState(
        {
          files: data,
          infofiles: true,
        },
        () => {
          checkStatus();
        },
      );
    });
  };
  const getProgramInfoDetail = () => {
    const requestBuilder = {
      method: "getRequest",
      url: parseEndpoint(endpoints.programInfoDetails),
      data: {},
    };
    // @ts-expect-error TS2345
    apiRequest(requestBuilder, ({ data }) => {
      let permission = false;
      if (user.isSuper === true || user.type === "admin") permission = true; // allow permission for super and admin in any case
      // @ts-expect-error TS18046
      if (data.status === "private" && !permission) {
        // if it is private and there is no permission
        // send the user back
        // @ts-expect-error TS18048
        props.history.push("/dashboard");
      } else {
        setState(
          {
            // @ts-expect-error TS18046
            editorStateInJson: data.content,
            // @ts-expect-error TS18046
            videoUrl: data.videos[0],
            infodetail: true,
          },
          () => {
            checkStatus();
          },
        );
      }
    });
  };
  const getProgramInfoFaq = () => {
    const requestBuilder = {
      method: "getRequest",
      url: parseEndpoint(endpoints.programInfoFaq),
      data: {},
    };
    // @ts-expect-error TS2345
    apiRequest(requestBuilder, ({ data }) => {
      setState(
        {
          faq: data,
          infofaq: true,
        },
        () => {
          checkStatus();
        },
      );
    });
  };
  // @ts-expect-error TS7006
  const userFileDownloaded = (fileId, fileDetail = {}) => {
    const data = {
      method: "postRequest",
      url: parseEndpoint(endpoints.userFileDownload, {
        fileId,
      }),
      data: fileDetail,
    };
    // @ts-expect-error TS2345
    apiRequest(data, ({ data }) => {});
  };
  // @ts-expect-error TS7006
  const saveUserFile = (href, fileName, extension, fileId) => {
    const xhr = new XMLHttpRequest();
    xhr.withCredentials = true;
    xhr.open("GET", href, true);
    xhr.responseType = "arraybuffer";
    xhr.onload = (e) => {
      // @ts-expect-error TS18047
      const arrayBufferView = new Uint8Array(e.currentTarget.response);
      const blob = new Blob([arrayBufferView]);
      userFileDownloaded(fileId ? fileId : -1, {
        fileName,
        fileSize: blob.size,
      });
      FileSaver.saveAs(blob, fileName);
    };
    xhr.send();
  };

  return {
    intl,
    state,
    user,
    saveUserFile,
  };
};

export default useProgramInfoViewScreen;
