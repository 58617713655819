import { Tag as AntTag } from "antd";
import styled from "styled-components";

export const InformationTag = styled(AntTag)`
  border: 1px solid
    ${(props) =>
      // @ts-expect-error TS2339
      getColor(props.type)};
  border-radius: 0;
  color: ${(props) =>
    // @ts-expect-error TS2339
    getColor(props.type)};
`;

function getColor(type = "default") {
  if (type === "important") {
    return "#0D9A65";
  }
  return "#323232";
}
