import { Card, Col, Form, Modal, Row } from "antd";
import PropTypes from "prop-types";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";

import { ButtonOption } from "../../Components/atoms";
import {
  InsuAcceptButton,
  InsuRejectButton,
} from "../../Components/atoms/buttons/shared";
import { Input, Option, Select, TextArea } from "../../Components/molecules";
import { ManageCategoriesComponent } from "../../Components/organisms";
import VideoActions from "../../Redux/VideoRedux";
import FileUploadComponent from "../project/FileUploadComponent";
import useEditVideoScreen from "./hooks/useEditVideoScreen";
import { IEditVideoScreenProps } from "./types/IEditVideoScreenProps";

const EditVideoScreen: FC<IEditVideoScreenProps> = function (props) {
  const {
    state,
    intl,
    setState,
    onUpdateNewVideo,
    onDeleteSmallCategory,
    onSubmitNewCategory,
    onChangeNewVideoData,
    getLargeCategoryIdForSelect,
  } = useEditVideoScreen(props);

  const { user } = props.context;

  // @ts-expect-error TS7006
  const _renderSmallCategories = (largeCategoryId) => {
    const data = state.smallCategories[largeCategoryId];
    if (data && Array.isArray(data)) {
      const defVal = (
        <Option value={0}>
          {intl.formatMessage({
            id: "screen.label.choose_one_option",
          })}
        </Option>
      );
      const options = data.map((sm) => {
        return (
          <Option key={sm.id} value={sm.id}>
            {sm.name}
          </Option>
        );
      });
      return [defVal, ...options];
    }
  }; //
  return (
    <div className={"EditVideoScreen"}>
      <Modal
        title={intl.formatMessage({
          id: "screen.label.category_management",
        })}
        visible={state.isModalManageCategoriesVisible}
        footer={null}
        onCancel={() => {
          setState({
            isModalManageCategoriesVisible: false,
          });
        }}
      >
        <ManageCategoriesComponent
          onDeleteSmallCategory={onDeleteSmallCategory}
          onSubmitNewCategory={onSubmitNewCategory}
          // @ts-expect-error TS2322
          largeCategories={props.largeCategories}
          smallCategories={state.smallCategories}
          user={user}
        />
      </Modal>
      <Card
        bodyStyle={{
          paddingLeft: "7vw",
          paddingRight: "7vw",
        }}
      >
        {state.editVideo.videoLargeCategoryId > 0 ? (
          <Form layout={"vertical"}>
            <Row gutter={[32, 0]}>
              <Col xs={24} sm={12} md={8} lg={8}>
                <Form.Item
                  label={`${intl.formatMessage({
                    id: "screen.label.large_category",
                  })} *`}
                >
                  <Select
                    defaultValue={state.editVideo.videoLargeCategoryId}
                    onChange={(videoLargeCategoryId) => {
                      onChangeNewVideoData(
                        "videoLargeCategoryId",
                        videoLargeCategoryId,
                      );
                      onChangeNewVideoData("videoSmallCategoryId", 0);
                      setState({
                        videoLargeCategoryId,
                      });
                    }}
                  >
                    {/*
                     // @ts-expect-error TS18048 */}
                    {props.largeCategories
                      // @ts-expect-error TS7006
                      .filter((l) => l.companyId !== null)
                      // @ts-expect-error TS7006
                      .map((l) => {
                        return (
                          <Option
                            disabled={getLargeCategoryIdForSelect(l)}
                            key={l.id}
                            value={l.id}
                          >
                            {l.name}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8} lg={8}>
                <Form.Item
                  label={`${intl.formatMessage({
                    id: "screen.label.small_category",
                  })} *`}
                >
                  <Select
                    defaultValue={state.editVideo.videoSmallCategoryId}
                    value={state.editVideo.videoSmallCategoryId}
                    onChange={(videoSmallCategoryId) => {
                      onChangeNewVideoData(
                        "videoSmallCategoryId",
                        videoSmallCategoryId,
                      );
                    }}
                  >
                    {_renderSmallCategories(state.videoLargeCategoryId)}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24}>
                <ButtonOption
                  style={{ marginBottom: "30px" }}
                  onClick={() => {
                    setState({
                      isModalManageCategoriesVisible: true,
                    });
                  }}
                >
                  <FormattedMessage id="screen.label.to_add_delete_category" />
                </ButtonOption>
              </Col>

              <Col xs={24} sm={12} md={13} lg={14}>
                <Form.Item
                  label={`${intl.formatMessage({
                    id: "screen.label.title",
                  })} *`}
                >
                  <Input
                    value={state.editVideo.name}
                    onChange={(e) => {
                      onChangeNewVideoData("name", e.target.value);
                    }}
                    placeholder={intl.formatMessage({
                      id: "screen.label.please_enter",
                    })}
                    className={"video-name"}
                  />
                </Form.Item>
                <br />
                <Form.Item
                  label={`${intl.formatMessage({
                    id: "screen.label.more_detail",
                  })} *`}
                >
                  <TextArea
                    value={state.editVideo.description}
                    onChange={(e) => {
                      onChangeNewVideoData("description", e.target.value);
                    }}
                    rows={3}
                    placeholder={intl.formatMessage({
                      id: "screen.label.please_enter",
                    })}
                  />
                </Form.Item>
                <br />
                <Form.Item label={"Vimeo URL *"}>
                  <Input
                    value={state.editVideo.videoUrl}
                    onChange={(e) => {
                      onChangeNewVideoData("videoUrl", e.target.value);
                    }}
                    placeholder="Vimeo URL"
                  />
                </Form.Item>
                <Form.Item
                  label={`${intl.formatMessage({
                    id: "screen.label.thumbnail_attached",
                  })} *`}
                >
                  <FileUploadComponent
                    imageSrc={state.editVideo.imageThumbnail}
                    message={`${intl.formatMessage({
                      id: "screen.label.upload_image_click",
                    })}`}
                    onFileChanged={(imageThumbnail) => {
                      onChangeNewVideoData("imageThumbnail", imageThumbnail);
                    }}
                  />
                </Form.Item>
                <br />
                <Form.Item>
                  <InsuAcceptButton
                    onClick={onUpdateNewVideo}
                    type="primary"
                    style={{
                      marginTop: 20,
                      marginLeft: "0.3vw",
                      marginRight: "0.3vw",
                    }}
                  >
                    {intl.formatMessage({
                      id: "screen.label.storage",
                    })}
                  </InsuAcceptButton>
                  <InsuRejectButton
                    // @ts-expect-error TS2339
                    onClick={props.history.goBack}
                    style={{
                      marginLeft: "0.3vw",
                      marginRight: "0.3vw",
                    }}
                  >
                    <FormattedMessage id={"screen.label.cancel"} />
                  </InsuRejectButton>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        ) : null}
      </Card>
    </div>
  );
};

// @ts-expect-error TS7006
const mapStateToProps = (state) => ({
  largeCategories: state.videos.largeCategoriesPayload,
});
// @ts-expect-error TS7006
const mapDispatchToProps = (dispatch) => ({
  // @ts-expect-error TS7006
  dispatchSetVideoCategories: (largeCategories) =>
    // @ts-expect-error TS2554
    dispatch(VideoActions.videoLargeCategoriesGetSuccess(largeCategories)),
});

EditVideoScreen.propTypes = {
  dispatchSetVideoCategories: PropTypes.func.isRequired,
  // @ts-expect-error TS2322
  context: PropTypes.object,
  match: PropTypes.object.isRequired,
  largeCategories: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
};
export default connect(mapStateToProps, mapDispatchToProps)(EditVideoScreen);
