import { EventRecommendations } from "../EventRecommendations";
import styles from "./styles.module.scss";
import { EventSummaryProps } from "./type";

export const EventSummary = ({ recommendations }: EventSummaryProps) => {
  return (
    <>
      {recommendations.length > 0 && (
        <div className={styles.event_recommendations}>
          <EventRecommendations recommendations={recommendations} />
        </div>
      )}
    </>
  );
};
