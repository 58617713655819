import { Tag, TagProps, Tooltip } from "antd";
import { FC } from "react";

const TAG_MAX_LENGTH = 40;
export type EllipsisTagProps = TagProps & {
  tag: string;
};

export const EllipsisTag: FC<EllipsisTagProps> = ({ tag, ...props }) => {
  const isLong = tag.length > TAG_MAX_LENGTH;

  return isLong ? (
    <Tooltip title={tag}>
      <Tag {...props}>{`${tag.slice(0, TAG_MAX_LENGTH)}...`}</Tag>
    </Tooltip>
  ) : (
    <Tag {...props}>{tag}</Tag>
  );
};
