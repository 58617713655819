import styled from "styled-components";
type PasswordConditionTextProps = {
  color: string;
};
export const ConditionText = styled.p`
  font-size: 0.8em;
`;

export const PasswordConditionText = styled.p<PasswordConditionTextProps>`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: ${(p) => p.color};
  margin-top: 8px;
  .anticon-check-circle {
    margin-left: 4px;
    color: #1f86e9;
  }
`;
