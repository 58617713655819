import { notification } from "antd";
import { ApiResponse } from "apisauce";
import { push } from "connected-react-router";
import { all, call, put, takeEvery } from "redux-saga/effects";

import { CommonResponse } from "@/Services/commonResponsTypes";

import ApiActions, { ApiRequestAction, ApiTypes } from "../Redux/ApiRedux";
import type { API } from ".";

function* attemptApiRequest(
  api: API,
  { data, callback, errorCallback = () => {} }: ApiRequestAction<unknown>,
): Generator {
  const apiResponse = (yield call(
    api[data.method],
    data.url,
    data.data,
    data.headers,
  )) as ApiResponse<CommonResponse<unknown>, unknown>;
  if (apiResponse.ok) {
    // @ts-expect-error apiResponse.data' は 'undefined' の可能性があります。
    switch (apiResponse.data.status) {
      case "success":
        yield put(ApiActions.apiSuccess());
        if (callback) {
          // @ts-expect-error このdataはSuccessResponseらしい
          callback(apiResponse.data);
        }
        if (data.successMessage) {
          notification.success({
            message: data.successMessage,
          });
        }

        if (data.successReRoute) {
          yield put(push(data.successReRoute));
        }
        break;
      case "fail":
      case "error":
        // @ts-expect-error apiResponse.data' は 'undefined' の可能性があります。
        yield put(ApiActions.apiFailure(apiResponse.data.data));
        // @ts-expect-error このdataはFailOrErrorResponseらしい
        errorCallback(apiResponse.data);
        break;
      default:
        break;
    }
  } else {
    notification.error({
      message: apiResponse.problem,
    });
    console.log("error callback", apiResponse);
    errorCallback(apiResponse.problem);
    yield put(ApiActions.apiFailure(apiResponse.problem));
    if (apiResponse.status === 401) {
      localStorage.removeItem("persist:root");
      localStorage.removeItem("currentRoute");
      // @ts-expect-error TS2322
      window.location = "/login";
    }
  }
}

function* apiSaga(api: API) {
  yield all([takeEvery(ApiTypes.API_REQUEST, attemptApiRequest, api)]);
}

export default apiSaga;
