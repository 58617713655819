import { BasicReviewInfo } from "../../types/reviewInfo";
import { canPublish } from "../ReviewInfoRow/helper";
/**
 * @description
 * 公開可能なレビュー情報が存在する場合はtrue, そうでないならfalseを返す
 */
export const checkHasPublishableReviewInfos = (
  reviewInfos: BasicReviewInfo[],
): boolean => {
  const publishableReviewInfos = reviewInfos.filter(canPublish);
  return publishableReviewInfos.length !== 0;
};

/**
 * @description
 * 公開可能なレビュー情報が全てチェックされているならtrue, そうでないならfalseを返す
 */
export const checkIsAllChecked = (
  reviewInfos: BasicReviewInfo[],
  rowSelection: string[],
): boolean => {
  const publishableReviewInfos = reviewInfos.filter(canPublish);
  return publishableReviewInfos.every((reviewInfo) =>
    rowSelection.includes(reviewInfo.uuid),
  );
};
