import { PlusOutlined } from "@ant-design/icons";
import { Button, Col } from "antd";
import React from "react";

type Props = {
  buttonLabel?: string;
  onClick: () => void;
};

export const CreateEventButton: React.FC<Props> = ({
  onClick,
  buttonLabel,
}) => {
  return (
    <Col
      xs={24}
      sm={12}
      md={{ span: 8, offset: 8 }}
      lg={{ span: 6, offset: 12 }}
    >
      <Button
        type="primary"
        className="primary-outline"
        style={{
          maxWidth: 200,
          float: "right",
        }}
        onClick={onClick}
      >
        <PlusOutlined />
        {buttonLabel ?? ""}
      </Button>
    </Col>
  );
};
