import { Button as AntButton, DatePicker as AntDatePicker } from "antd";
import { Switch } from "antd";
import PropTypes from "prop-types";
import styled from "styled-components";

export const Button = styled(AntButton)<any>`
  &:hover {
    color: inherit;
    background-color: inherit;
  }
`;

export const DatePicker = styled(AntDatePicker)``;

export const ButtonSwitch = styled(Switch)`
  background-color: ${(props) => (props.checked ? "#1A90FF" : "#B5B5B5")};
`;

export const ButtonUndo = styled(Button)`
  background-color: #b5b5b5;
  color: white;
`;

export const ButtonOption = styled(Button)`
  border: 1px solid
    ${(props) => (props.borderColor ? props.borderColor : "#1f86e9")};
  color: ${(props) => (props.textColor ? props.textColor : "#1f86e9")};
  background-color: transparent;
`;

export const ButtonCV = styled(Button)`
  background-color: #1f86e9;
  color: white;
`;
export const ButtonSubmit = styled(Button)`
  background: #1f86e9;
  border: 1px solid #c4c4c4;
  border-radius: 3px;
  color: white;
  width: 100%;
  width: 366px;
  height: 48px;
  margin-top: 24px;
`;
export const ButtonVerify = styled(Button)`
  background: #1f86e9;
  border: 1px solid #c4c4c4;
  border-radius: 3px;
  color: white;
  width: 100%;
  height: 48px;
  margin-top: 40px;
`;
export const ButtonRegister = styled(Button)`
  background: #1f86e9;
  border: 1px solid #c4c4c4;
  border-radius: 3px;
  color: white;
  width: 100%;
  width: 366px;
  height: 48px;
  margin-top: 0px;
`;

const SvgIcon = styled.img`
  width: ${(props) => (props.width ? props.width : "24px")};
  height: ${(props) => (props.height ? props.height : "24px")};
  margin-left: 0.1vw;
  margin-right: 0.1vw;
  border-radius: 2px;
`;

// @ts-expect-error TS7031
export const IconButton = ({ onClick, iconSvg }) => (
  <div onClick={onClick}>
    <SvgIcon src={iconSvg} />
  </div>
);

// @ts-expect-error TS7031
export const ButtonDate = ({ onClick, iconSvg, text }) => (
  <div onClick={onClick}>
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        border: "1px solid #323232",
        borderRadius: "4px",
        padding: "4px 15px",
        width: "fit-content",
      }}
    >
      <SvgIcon width={"14px"} height={"14px"} src={iconSvg} />
      <span
        style={{
          marginLeft: "8px",
          color: "#1F86E9",
          fontWeight: "bold",
        }}
      >
        {text}
      </span>
    </div>
  </div>
);

ButtonDate.propTypes = {
  onClick: PropTypes.func.isRequired,
  iconSvg: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired,
};
IconButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  iconSvg: PropTypes.object.isRequired,
};

export { CopyButton } from "./CopyButton";
