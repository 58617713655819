import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Row, Switch } from "antd";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";

import ApiActions, { ApiRequestData } from "../../Redux/ApiRedux";
import BatchExaminationActions from "../../Redux/BatchExaminationRedux";
import { endpoints, parseEndpoint } from "../../Services/endpoints";
import { SingleHypoCol, SingleHypoTitle } from "./Components/SingleHypo";
import {
  SingleExaminationSubmissionCategoryProps,
  SingleSubmission,
} from "./types/IBatchExaminationScoreManagementScreen";

const R = require("ramda");

const SingleExaminationSubmissionCategory: React.FC<SingleExaminationSubmissionCategoryProps> =
  function (props) {
    const { isSortable, singleSubmissionCategory } = props;
    const [editing, setEditing] = React.useState(false);
    const [localSingleSubmissionCategory, setLocalSingleSubmissionCategory] =
      useState<SingleSubmission>();
    const dispatch = useDispatch();

    useEffect(() => {
      setLocalSingleSubmissionCategory(singleSubmissionCategory);
    }, [singleSubmissionCategory]);

    const saveChangesToSingleSubmissionCategory = (
      batchsubmissionCategoryId: number,
      batchId: number,
      data: any,
    ) => {
      if (batchsubmissionCategoryId) {
        const requestBuilder: ApiRequestData = {
          method: "putRequest",
          url: parseEndpoint(
            endpoints.singleBatchExaminationSubmissionCategory,
            {
              batchId,
              batchsubmissionCategoryId,
            },
          ),
          data,
        };
        dispatch(
          ApiActions.apiRequest(requestBuilder, ({ data }) => {
            dispatch(
              // @ts-expect-error TS2554
              BatchExaminationActions.batchExaminationGetsRequest({
                batchId,
              }),
            );
          }),
        );
      } else {
        const requestBuilder: ApiRequestData = {
          method: "postRequest",
          url: parseEndpoint(endpoints.createNewSubmissionCategory, {
            batchId,
          }),
          data,
        };
        dispatch(
          ApiActions.apiRequest(requestBuilder, ({ data }) => {
            dispatch(
              // @ts-expect-error TS2554
              BatchExaminationActions.batchExaminationGetsRequest({
                batchId,
              }),
            );
          }),
        );
      }
    };

    if (localSingleSubmissionCategory) {
      return (
        <div
          className={isSortable ? "noselect" : ""}
          style={{
            marginBottom: "1vh",
            marginTop: "3vh",
            borderWidth: 1,
            borderColor: isSortable ? "#BDBDBD" : "#e9e9e9",
            borderRadius: "8px",
            padding: "1rem",
            borderStyle: isSortable ? "dashed" : "solid",
            cursor: isSortable ? "row-resize" : "default",
            backgroundColor: "white",
          }}
        >
          <Row>
            <SingleHypoCol span={3}>
              <SingleHypoTitle>
                {
                  localSingleSubmissionCategory.SubmissionCategory
                    .submissionCategoryNumber
                }
              </SingleHypoTitle>
            </SingleHypoCol>
            <Col span={21}>
              <Row justify="start" align="middle">
                <Col span={24}>
                  <Form layout={"vertical"}>
                    <Form.Item
                      label={
                        <FormattedMessage
                          id={"screen.submission.category_name"}
                        />
                      }
                    >
                      <Input
                        style={{ width: "100%" }}
                        onChange={(e) => {
                          setEditing(true);
                          const newLocalSingleSubmissionCategory: SingleSubmission =
                            R.clone(localSingleSubmissionCategory);
                          newLocalSingleSubmissionCategory.submissionCategoryMetadata.submissionCategory =
                            e.target.value;
                          setLocalSingleSubmissionCategory(
                            newLocalSingleSubmissionCategory,
                          );
                        }}
                        value={
                          localSingleSubmissionCategory
                            .submissionCategoryMetadata.submissionCategory
                        }
                      />
                    </Form.Item>
                    <Form.Item>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "baseline",
                        }}
                      >
                        <p
                          style={{
                            marginRight: "0.3vw",
                          }}
                        >
                          <FormattedMessage id="screen.submission.examination.mgt_submission_category.effectiveness" />
                        </p>
                        <Switch
                          size="small"
                          checked={
                            localSingleSubmissionCategory
                              .submissionCategoryMetadata.isActive
                          }
                          onChange={(checked) => {
                            setEditing(true);
                            const newLocalSingleSubmissionCategory: SingleSubmission =
                              R.clone(localSingleSubmissionCategory);
                            newLocalSingleSubmissionCategory.submissionCategoryMetadata.isActive =
                              checked;
                            setLocalSingleSubmissionCategory(
                              newLocalSingleSubmissionCategory,
                            );
                          }}
                          checkedChildren={<CheckOutlined />}
                          unCheckedChildren={<CloseOutlined />}
                        />
                      </div>
                    </Form.Item>
                  </Form>
                </Col>
              </Row>
              {editing && (
                // @ts-expect-error TS2322
                <Row align="end" style={{ marginTop: 10 }}>
                  <Button
                    type="primary"
                    size="small"
                    onClick={() => {
                      setEditing(false);

                      const { submissionCategoryMetadata } =
                        localSingleSubmissionCategory;
                      saveChangesToSingleSubmissionCategory(
                        // @ts-expect-error TS2345
                        localSingleSubmissionCategory?.id,
                        localSingleSubmissionCategory?.batchId,
                        submissionCategoryMetadata,
                      );
                    }}
                    style={{ float: "right", fontSize: 14 }}
                  >
                    <FormattedMessage id="screens.title.save" />
                  </Button>
                  <Button
                    size="small"
                    onClick={() => {
                      setEditing(false);
                      setLocalSingleSubmissionCategory(
                        singleSubmissionCategory,
                      );
                    }}
                    style={{
                      float: "right",
                      marginLeft: 10,
                      fontSize: 14,
                    }}
                  >
                    <FormattedMessage id="screen.label.cancel" />
                  </Button>
                </Row>
              )}
            </Col>
          </Row>
        </div>
      );
    }
    return <></>;
  };

SingleExaminationSubmissionCategory.propTypes = {
  // @ts-expect-error TS2322
  isSortable: PropTypes.bool,
  // @ts-expect-error TS2322
  singleSubmissionCategory: PropTypes.object,
  onSaveEditSubmissionCategory: PropTypes.func,
  // @ts-expect-error TS2322
  singleSubmissionCategoryIndex: PropTypes.number,
};

export default SingleExaminationSubmissionCategory;
