import { Layout } from "antd";
import styled from "styled-components";

type ReferenceThumbProps = {
  size?: number;
  borderRadius?: number;
};

export const ReferenceLayout = styled(Layout)`
  background-color: #f5f5f5;
  height: 100%;
  min-height: 100vh;
`;

export const ReferenceThumbMenuBar = styled("div")`
  align-items: center;
  background-color: #eeeeee;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: flex-end;
  padding: 4px;
  width: 100%;
  height: 40px;
`;

export const ReferenceDesLabel = styled("span")`
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5; /* number of lines to show */
  -webkit-box-orient: vertical;
  max-width: 250px;
`;

export const SingleUploadReferenceLayout = styled("div")`
  border-color: #bbbbbb;
  border-width: 1px;
  border-style: solid;
  width: min-content;
  min-width: 22vw;
  border-radius: 4px;
  margin-top: 1vh;
  margin-bottom: 1vh;
`;
