import { memo, useCallback } from "react";
import { useIntl } from "react-intl";

import { SearchBar } from "@/shared/search/SearchBar/component";

import { SubmittedProjectSearchBarProps } from "./type";
export const SubmittedProjectSearchBar = memo(
  ({ setSearchWord, resetPagingToInitial }: SubmittedProjectSearchBarProps) => {
    const intl = useIntl();
    const onSearch = useCallback(
      (value: string) => {
        resetPagingToInitial();
        setSearchWord(value);
      },
      [resetPagingToInitial, setSearchWord],
    );
    return (
      <SearchBar
        width="320px"
        onSearch={onSearch}
        placeholder={intl.formatMessage({
          id: "screen.label.project_name_owner_name_des",
        })}
      />
    );
  },
);
