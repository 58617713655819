import { Action } from "@reduxjs/toolkit";
import { createActions, createReducer } from "reduxsauce";
import Immutable from "seamless-immutable";

import { ToAction, ToActionType } from "./types";

/* ------------- Types and Action Creators ------------- */
type CommentActionCreators = {
  commentCreateRequest: () => Action<"COMMENT_CREATE_REQUEST">;
  commentCreateSuccess: () => Action<"COMMENT_CREATE_SUCCESS">;
  commentCreateFailure: () => Action<"COMMENT_CREATE_FAILURE">;

  commentUpdateRequest: () => Action<"COMMENT_UPDATE_REQUEST">;
  commentUpdateSuccess: () => Action<"COMMENT_UPDATE_SUCCESS">;
  commentUpdateFailure: () => Action<"COMMENT_UPDATE_FAILURE">;

  commentDeleteRequest: () => Action<"COMMENT_DELETE_REQUEST">;
  commentDeleteSuccess: () => Action<"COMMENT_DELETE_SUCCESS">;
  commentDeleteFailure: () => Action<"COMMENT_DELETE_FAILURE">;

  commentGetsRequest: () => Action<"COMMENT_GETS_REQUEST">;
  commentGetsSuccess: () => Action<"COMMENT_GETS_SUCCESS">;
  commentGetsFailure: () => Action<"COMMENT_GETS_FAILURE">;
};

type CommentAction = ToAction<CommentActionCreators>;
type CommentActionType = ToActionType<CommentAction>;

const { Types, Creators } = createActions<
  CommentActionType,
  CommentActionCreators
>({
  commentCreateRequest: ["data", "message"],
  commentCreateSuccess: null,
  commentCreateFailure: ["errors"],

  commentUpdateRequest: ["data", "message"],
  commentUpdateSuccess: null,
  commentUpdateFailure: ["errors"],

  commentDeleteRequest: ["data", "message"],
  commentDeleteSuccess: null,
  commentDeleteFailure: ["errors"],

  commentGetsRequest: ["data"],
  commentGetsSuccess: ["commentsPayload"],
  commentGetsFailure: ["errors"],
});

export const CommentTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
type CommentState = {
  fetching: boolean;
  /** 多分使ってない */
  errors: unknown;
  error?: unknown;
  commentsPayload: {
    rows: unknown[];
    count: number;
  };
};

export const INITIAL_STATE = Immutable<CommentState>({
  fetching: false,
  errors: {},
  commentsPayload: {
    rows: [],
    count: 0,
  },
});
type ImmCommentState = typeof INITIAL_STATE;

/* ------------- Reducers ------------- */
const commentCreateRequest = (state: ImmCommentState) =>
  state.merge({ fetching: true });

const commentCreateSuccess = (state: ImmCommentState) => {
  return state.merge({ fetching: false });
};

// @ts-expect-error TS7031
const commentCreateFailure = (state: ImmCommentState, { error }) =>
  state.merge({ fetching: false, error });

const commentDeleteRequest = (state: ImmCommentState) =>
  state.merge({ fetching: true });

const commentDeleteSuccess = (state: ImmCommentState) => {
  return state.merge({ fetching: false });
};

// @ts-expect-error TS7031
const commentDeleteFailure = (state: ImmCommentState, { error }) =>
  state.merge({ fetching: false, error });

const commentGetsRequest = (state: ImmCommentState) =>
  state.merge({ fetching: true });

// @ts-expect-error TS7031
const commentGetsSuccess = (state: ImmCommentState, { commentsPayload }) => {
  return state.merge({ fetching: false, commentsPayload });
};

// @ts-expect-error TS7031
const commentGetsFailure = (state: ImmCommentState, { error }) =>
  state.merge({ fetching: false, error });

const commentUpdateRequest = (state: ImmCommentState) =>
  state.merge({ fetching: true });

const commentUpdateSuccess = (state: ImmCommentState) => {
  return state.merge({ fetching: false });
};

// @ts-expect-error TS7031
const commentUpdateFailure = (state: ImmCommentState, { error }) =>
  state.merge({ fetching: false, error });
/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.COMMENT_CREATE_REQUEST]: commentCreateRequest,
  [Types.COMMENT_CREATE_SUCCESS]: commentCreateSuccess,
  [Types.COMMENT_CREATE_FAILURE]: commentCreateFailure,

  [Types.COMMENT_UPDATE_REQUEST]: commentUpdateRequest,
  [Types.COMMENT_UPDATE_SUCCESS]: commentUpdateSuccess,
  [Types.COMMENT_UPDATE_FAILURE]: commentUpdateFailure,

  [Types.COMMENT_DELETE_REQUEST]: commentDeleteRequest,
  [Types.COMMENT_DELETE_SUCCESS]: commentDeleteSuccess,
  [Types.COMMENT_DELETE_FAILURE]: commentDeleteFailure,

  [Types.COMMENT_GETS_REQUEST]: commentGetsRequest,
  [Types.COMMENT_GETS_SUCCESS]: commentGetsSuccess,
  [Types.COMMENT_GETS_FAILURE]: commentGetsFailure,
});
