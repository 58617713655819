import { memo } from "react";

import DownloadIcon from "@/shared/buttons/components/DownloadIconButton/assets/download.svg";
import styles from "@/shared/buttons/components/DownloadIconButton/styles.module.scss";
import { DownloadIconButtonProps } from "@/shared/buttons/components/DownloadIconButton/type";

export const DownloadIconButton = memo(
  ({ alt, className, ...rest }: DownloadIconButtonProps) => {
    return (
      <button
        className={`${styles.download_icon_button} ${className}`}
        {...rest}
      >
        <img src={DownloadIcon} alt={alt} />
      </button>
    );
  },
);
