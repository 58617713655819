import { Avatar as PreviewAvatar, Col, Modal, Row } from "antd";
import { useState } from "react";
import Avatar from "react-avatar-edit";
import { useIntl } from "react-intl";

import { ButtonCV, ButtonOption, ButtonUndo } from "../../../atoms";

type Props = {
  titleId: string;
  avatarTitle: string;
  url: any;
  source: any;
  onFileNameChange: (fileName: string) => void;
  onFileUpload: () => void;
  onImageEditCrop: (data: string) => void;
  onImageEditClose: () => void;
};
// avatarTitle = ''screen.label.please_select_file''
// titleId = 'screen.label.profile_image_upload';
const ProfileImage = function (props: Props) {
  const {
    avatarTitle,
    titleId,
    url,
    source,
    onFileUpload,
    onImageEditCrop,
    onImageEditClose,
    onFileNameChange,
  } = props;
  const intl = useIntl();
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <span className="ant-form-item-label">
        {intl.formatMessage({
          id: "screen.label.profile_photo",
        })}
      </span>
      <br />
      <br />
      <Row align="middle" justify="start">
        <PreviewAvatar
          size={80}
          style={{
            marginRight: 20,
            width: 80,
            height: 80,
          }}
          src={url}
        />
        <ButtonOption onClick={() => setShowModal(true)}>
          {intl.formatMessage({
            id: "screen.label.upload_image_click",
          })}
        </ButtonOption>
      </Row>
      <Modal
        destroyOnClose
        title={intl.formatMessage({
          id: titleId,
        })}
        centered
        style={{ minWidth: "40vw" }}
        visible={showModal}
        onCancel={() => setShowModal(false)}
        onOk={onFileUpload}
        cancelText={intl.formatMessage({
          id: "screen.label.cancel",
        })}
        okText={intl.formatMessage({
          id: "screen.label.application",
        })}
        footer={
          <Col span={16} offset={4}>
            <Row align="middle" justify="center">
              <ButtonUndo onClick={() => setShowModal(false)}>
                {intl.formatMessage({
                  id: "screen.label.cancel",
                })}
              </ButtonUndo>
              <ButtonCV onClick={onFileUpload}>
                {intl.formatMessage({
                  id: "screen.label.application",
                })}
              </ButtonCV>
            </Row>
          </Col>
        }
      >
        {/*
         // @ts-expect-error TS2339 */}
        <center>
          <div style={{ overflow: "hidden" }}>
            <Avatar
              label={intl.formatMessage({
                id: avatarTitle,
              })}
              borderStyle={{
                borderColor: "#1f86e9",
                borderStyle: "dashed",
                borderWidth: "1px",
              }}
              labelStyle={{
                fontSize: "0.8rem",
              }}
              width={200}
              height={200}
              onCrop={onImageEditCrop}
              onClose={onImageEditClose}
              src={source}
              onFileLoad={(file) => {
                // @ts-expect-error TS2339
                onFileNameChange(file.name);
              }}
            />
            <br />
            <p>
              {intl.formatMessage({
                id: "screen.label.maximum_that_can_be_uploaded_size_10mb",
              })}
            </p>
          </div>
          {/*
         // @ts-expect-error TS2339 */}
        </center>
      </Modal>
    </>
  );
};
export default ProfileImage;
