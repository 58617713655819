import { useIntl } from "react-intl";

import { SortReviewSelectBoxProps } from "@/features/review/components/SortReviewSelectBox/type";
import { ReviewSortField } from "@/features/review/types/ReviewSortField";
import { SingleDropdownSelect } from "@/shared/selectbox/components/SingleDropdownSelect/component";
import { SelectOption } from "@/shared/selectbox/types/SelectOption";

export const SortReviewSelectBox = ({
  reviewSortField,
  onChangeSortField,
}: SortReviewSelectBoxProps) => {
  const intl = useIntl();
  const submissionOrderLabel = intl.formatMessage({
    id: "review.sort_submission_order",
  });
  const ownerNameOrderLabel = intl.formatMessage({
    id: "review.sort_owner_name",
  });
  const projectNameOrderLabel = intl.formatMessage({
    id: "review.sort_project_name",
  });
  const projectIdOrderLabel = intl.formatMessage({
    id: "review.sort_project_id",
  });

  const optionsMap = new Map<ReviewSortField, SelectOption<ReviewSortField>>([
    ["createdAt", { value: "createdAt", label: submissionOrderLabel }],
    ["userId", { value: "userId", label: ownerNameOrderLabel }],
    ["projectName", { value: "projectName", label: projectNameOrderLabel }],
    ["projectId", { value: "projectId", label: projectIdOrderLabel }],
  ]);
  const options = Array.from(optionsMap.values());
  const selectedOption = optionsMap.get(reviewSortField);

  const onSelect = (newValue: SelectOption<ReviewSortField>) => {
    if (!newValue.value) return;
    onChangeSortField(newValue.value);
  };

  return (
    <SingleDropdownSelect
      width="190px"
      options={options}
      onSelect={onSelect}
      selectedValue={selectedOption}
      id="sort-review-select-box"
    />
  );
};
