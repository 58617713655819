import { notification } from "antd";
import { useCallback } from "react";
import { useIntl } from "react-intl";

export const usePreviewFile = () => {
  const intl = useIntl();

  const previewFile = useCallback(
    (
      file: File,
      setImage: React.Dispatch<React.SetStateAction<File | string | null>>,
    ) => {
      const reader = new FileReader();

      reader.onloadend = () => {
        setImage(reader.result as string);
      };

      reader.onerror = () => {
        notification.error({
          message: intl.formatMessage({
            id: "screen.message.file_rendering_error",
          }),
        });
      };

      reader.readAsDataURL(file);
    },
    [intl],
  );

  return { previewFile };
};
