import { notification } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { responseCallbackWithNotification } from "../../../../Services/api/responseCallbackWithNotiofication";
import { toggleIsExaminationPhaseEnabled } from "../../../../Services/batches/batchMutateRequest";
import useBatchRequest from "../../../../Services/batches/useBatchRequest";
import { ToggleIsExaminationPhaseModal } from "../../../batch/components/ToggleIsExaminationPhaseModal/component";
import { useExaminationPhasesRequest } from "../../hooks/useExaminationPhaseRequest";
import { useExaminationPhaseValidation } from "../../hooks/useExaminationPhaseValidation";
import { ExaminationPhase } from "../../types/ExaminationPhase";
import { ExaminationPhaseCreateModal } from "../ExaminationPhaseCreateModal";
import { ExaminationPhaseDeleteConfirmModal } from "../ExaminationPhaseDeleteConfirmModal";
import { ExaminationPhaseList } from "../ExaminationPhaseList";
import { ExaminationPhaseManagementScreenContainer } from "../ExaminationPhaseManagementScreenContainer";
import { ExaminationPhaseUpdateActiveConfirmModal } from "../ExaminationPhaseUpdateActiveConfirmModal";
import styles from "./styles.module.scss";
import { ExaminationPhaseManagementScreenProps } from "./type";

export const ExaminationPhaseManagementScreen = React.memo(
  (props: ExaminationPhaseManagementScreenProps) => {
    const { id: batchId } = props.match.params;
    const intl = useIntl();

    const {
      createExaminationPhase,
      getExaminationPhases,
      updateExaminationPhaseOrder,
      updateActiveExaminationPhase,
      updateExaminationPhaseName,
      deleteExaminationPhase,
    } = useExaminationPhasesRequest(batchId);

    const { validateExaminationPhaseName } = useExaminationPhaseValidation();

    const { batch, updateBatch } = useBatchRequest(batchId);

    useEffect(() => {
      updateBatch();
    }, [updateBatch]);

    const { examinationPhases } = getExaminationPhases();

    const [createModalVisible, setCreateModalVisible] = useState(false);
    const [deleteConfirmModalVisible, setDeleteConfirmModalVisible] =
      useState<boolean>(false);
    const [deleteExaminationPhaseId, setDeleteExaminationPhaseId] =
      useState<number>();
    const [
      activeChangeConfirmModalVisible,
      setActiveChangeConfirmModalVisible,
    ] = useState<boolean>(false);
    const [activeChangeExaminationPhaseId, setActiveChangeExaminationPhaseId] =
      useState<number>();
    const [
      toggleIsExaminationPhaseConfirmModalVisible,
      setToggleIsExaminationPhaseConfirmModalVisible,
    ] = useState<boolean>(false);

    const onClickDeleteButton = useCallback((examinationPhaseId: number) => {
      setDeleteExaminationPhaseId(examinationPhaseId);
      setDeleteConfirmModalVisible(true);
    }, []);

    const onClickActiveUpdateButton = useCallback(
      (examinationPhaseId: number) => {
        setActiveChangeExaminationPhaseId(examinationPhaseId);
        setActiveChangeConfirmModalVisible(true);
      },
      [],
    );

    const onClickCreateButton = useCallback(() => {
      setCreateModalVisible(true);
    }, []);

    const onCreateCancel = useCallback(() => {
      setCreateModalVisible(false);
    }, []);

    const onDeleteCancel = useCallback(() => {
      setDeleteConfirmModalVisible(false);
    }, []);

    const onUpdateActiveCancel = useCallback(() => {
      setActiveChangeConfirmModalVisible(false);
    }, []);

    const onUpdateExaminationPhaseName = useCallback(
      async (examinationPhaseId: number, name: string) => {
        const isValid = validateExaminationPhaseName(name);
        if (!isValid) return;

        const response = await updateExaminationPhaseName(
          examinationPhaseId,
          name,
        );
        if (response.ok && response.data?.status === "success") {
          notification.success({
            message: intl.formatMessage({
              id: "screen.label.examination_phase_update_was_successed",
            }),
          });
        }
      },
      [intl, updateExaminationPhaseName, validateExaminationPhaseName],
    );

    const onDelete = useCallback(
      async (examinationPhaseId: number) => {
        const response = await deleteExaminationPhase(examinationPhaseId);
        if (response.ok && response.data?.status === "success") {
          notification.success({
            message: intl.formatMessage({
              id: "screen.label.examination_phase_delete_was_successed",
            }),
          });
        }
        setDeleteConfirmModalVisible(false);
      },
      [deleteExaminationPhase, intl],
    );

    const onUpdateActiveExaminationPhase = async (
      examinationPhaseId: number,
    ) => {
      const response = await updateActiveExaminationPhase(examinationPhaseId);
      if (response.ok && response.data?.status === "success") {
        notification.success({
          message: intl.formatMessage({
            id: "screen.label.successed_update_active_examination_phase",
          }),
        });
      }
      setActiveChangeConfirmModalVisible(false);
    };

    const onCreateExaminationPhase = useCallback(
      async (name: string) => {
        const isValid = validateExaminationPhaseName(name);
        if (!isValid) return;

        const response = await createExaminationPhase(name);
        if (response.ok && response.data?.status === "success") {
          notification.success({
            message: intl.formatMessage({
              id: "screen.label.examination_phase_update_was_successed",
            }),
          });
          setCreateModalVisible(false);
        }
      },
      [createExaminationPhase, intl, validateExaminationPhaseName],
    );

    const onSortSave = async (examinationPhases: ExaminationPhase[]) => {
      const response = await updateExaminationPhaseOrder(examinationPhases);
      if (response.ok && response.data?.status === "success") {
        notification.success({
          message: intl.formatMessage({
            id: "screen.label.examination_phase_sort_was_successed",
          }),
        });
      }
    };

    const onTurnOnIsExaminationPhaseEnable = useCallback(async () => {
      const response = await toggleIsExaminationPhaseEnabled(batchId, true);
      const message = intl.formatMessage({
        id: "screen.label.toggle_is_examination_phase_enabled_was_successed",
      });
      responseCallbackWithNotification(response, message, () => {
        updateBatch();
      });
    }, [batchId, intl, updateBatch]);

    const onTurnOffIsExaminationPhaseEnable = useCallback(async () => {
      const response = await toggleIsExaminationPhaseEnabled(batchId, false);
      const message = intl.formatMessage({
        id: "screen.label.toggle_is_examination_phase_enabled_was_successed",
      });
      responseCallbackWithNotification(response, message, () => {
        updateBatch();
        setToggleIsExaminationPhaseConfirmModalVisible(false);
      });
    }, [batchId, intl, updateBatch]);

    const onClickIsExaminationPhaseEnableToggleButton = useCallback(
      async (isExaminationPhaseEnabled: boolean) => {
        if (isExaminationPhaseEnabled) {
          setToggleIsExaminationPhaseConfirmModalVisible(true);
          return;
        }
        onTurnOnIsExaminationPhaseEnable();
      },
      [onTurnOnIsExaminationPhaseEnable],
    );

    const onToggleIsExaminationPhaseEnableCancel = useCallback(() => {
      setToggleIsExaminationPhaseConfirmModalVisible(false);
    }, []);

    return examinationPhases ? (
      <>
        <ExaminationPhaseManagementScreenContainer
          batch={batch}
          onClickToggleButton={() => {
            onClickIsExaminationPhaseEnableToggleButton(
              !!batch?.isExaminationPhaseEnabled,
            );
          }}
        >
          <div className={styles.container}>
            <ExaminationPhaseList
              onClickCreateButton={onClickCreateButton}
              examinationPhases={examinationPhases}
              onUpdateExaminationPhaseName={onUpdateExaminationPhaseName}
              onClickActiveUpdateButton={onClickActiveUpdateButton}
              onClickDeleteButton={onClickDeleteButton}
              onSortSave={onSortSave}
            />
          </div>
        </ExaminationPhaseManagementScreenContainer>
        {deleteExaminationPhaseId && (
          <ExaminationPhaseDeleteConfirmModal
            visible={deleteConfirmModalVisible}
            onDeleteCancel={onDeleteCancel}
            onDelete={() => {
              onDelete(deleteExaminationPhaseId);
            }}
          />
        )}
        {activeChangeExaminationPhaseId && (
          <ExaminationPhaseUpdateActiveConfirmModal
            visible={activeChangeConfirmModalVisible}
            onUpdateActive={() => {
              onUpdateActiveExaminationPhase(activeChangeExaminationPhaseId);
            }}
            onUpdateActiveCancel={onUpdateActiveCancel}
          />
        )}
        <ExaminationPhaseCreateModal
          visible={createModalVisible}
          onCreateCancel={onCreateCancel}
          onCreate={onCreateExaminationPhase}
        />
        <ToggleIsExaminationPhaseModal
          onCancel={onToggleIsExaminationPhaseEnableCancel}
          onExecute={onTurnOffIsExaminationPhaseEnable}
          isVisible={toggleIsExaminationPhaseConfirmModalVisible}
        />
      </>
    ) : null;
  },
);
