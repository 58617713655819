import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";

import { ExaminationResult } from "@/../types/SubmittedProject";
import { MultiCheckSelectBox } from "@/shared/selectbox/components/MultiCheckSelectBox";
import { SelectOption } from "@/shared/selectbox/types/SelectOption";

import { EXAMINATION_RESULT_ENTITIES } from "../../const/examinationResultEntity";
import { ReviewResultSelectBoxProps } from "./type";

export const ReviewResultSelectBox = ({
  selectedResults,
  setSelectedResults,
  resetPage,
}: ReviewResultSelectBoxProps) => {
  const intl = useIntl();
  const options = Object.values(EXAMINATION_RESULT_ENTITIES).map(
    ({ label, value }) => ({
      label: intl.formatMessage({ id: label }),
      value,
    }),
  );
  const resetCheck = useCallback(() => {
    setSelectedResults([]);
  }, [setSelectedResults]);

  const onSelect = useCallback(
    (newValue: SelectOption<ExaminationResult>) => {
      if (!newValue.value) return;
      const newValueIsSelected = selectedResults.includes(newValue.value);
      if (newValueIsSelected) {
        setSelectedResults(
          selectedResults.filter((result) => result !== newValue.value),
        );
        return;
      }
      setSelectedResults([...selectedResults, newValue.value]);
      resetPage();
    },
    [resetPage, selectedResults, setSelectedResults],
  );

  const selectedOptions = useMemo(
    () => options.filter(({ value }) => selectedResults.includes(value)),
    [options, selectedResults],
  );

  const defaultLabel = intl.formatMessage({
    id: "screen.label.review.filter_by_review_result",
  });

  return (
    <MultiCheckSelectBox
      id="review-result-select-box"
      width="190px"
      selectedOptions={selectedOptions}
      options={options}
      onSelect={onSelect}
      defaultLabel={defaultLabel}
      resetCheck={resetCheck}
    />
  );
};
