import { Card, Col, Row } from "antd";
import PropTypes from "prop-types";
import { FC, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";

import { firstComeFirstServer, lottery } from "../../../utils/constants";
import { Line } from "../../atoms";
import {
  IMemberActivityComponentProps,
  MemberActivityComponentState,
} from "./types";

const MemberActivityComponent: FC<IMemberActivityComponentProps> = function (
  props,
) {
  const intl = useIntl();
  const [state, customSetState] = useState<MemberActivityComponentState>(() => {
    return {
      projectActivities: [],
      videoActivities: [],
      presenceActivities: [],
      eventActivities: [],
      profileActivities: [],
    };
  });
  const setStateCallbackRef = useRef(() => {});
  useEffect(() => {
    const callBack = setStateCallbackRef.current;
    callBack();
    setStateCallbackRef.current = () => {};
  }, [state]);
  const setState = (
    data: MemberActivityComponentState,
    callback = () => {},
  ) => {
    setStateCallbackRef.current = callback;
    customSetState((previousState) => {
      return {
        ...previousState,
        ...data,
      };
    });
  };

  useEffect(() => {
    getProjectActivities(props.inspectedUserId);
    getVideosActivities(props.inspectedUserId);
    getPresenceActivities(props.inspectedUserId);
    getEventActivities(props.inspectedUserId);
    getProfileActivities(props.inspectedUserId);
  }, []);

  // @ts-expect-error TS7006
  const getProfileActivities = (inspectedUserId) => {
    const data = {
      method: "getRequest",
      url: `statistics/${inspectedUserId}/profile`,
      data: {},
    };
    // @ts-expect-error TS7031
    props.dispatchApiRequest(data, ({ data }) => {
      setState({
        profileActivities: data,
      });
    });
  };
  // @ts-expect-error TS7006
  const getProjectActivities = (inspectedUserId) => {
    const data = {
      method: "getRequest",
      url: `statistics/${inspectedUserId}/project`,
      data: {},
    };
    // @ts-expect-error TS7031
    props.dispatchApiRequest(data, ({ data }) => {
      setState({
        projectActivities: data,
      });
    });
  };
  // @ts-expect-error TS7006
  const getVideosActivities = (inspectedUserId) => {
    const data = {
      method: "getRequest",
      url: `statistics/${inspectedUserId}/videos`,
      data: {},
    };
    // @ts-expect-error TS7031
    props.dispatchApiRequest(data, ({ data }) => {
      setState({
        videoActivities: data,
      });
    });
  };
  // @ts-expect-error TS7006
  const getEventActivities = (inspectedUserId) => {
    const data = {
      method: "getRequest",
      url: `statistics/${inspectedUserId}/events`,
      data: {},
    };
    // @ts-expect-error TS7031
    props.dispatchApiRequest(data, ({ data }) => {
      setState({
        eventActivities: data,
      });
    });
  };
  // @ts-expect-error TS7006
  const getPresenceActivities = (inspectedUserId) => {
    const data = {
      method: "getRequest",
      url: `statistics/${inspectedUserId}/presence`,
      data: {},
    };
    // @ts-expect-error TS7031
    props.dispatchApiRequest(data, ({ data }) => {
      setState({
        presenceActivities: data,
      });
    });
  };
  const _renderProjectActivities = () => {
    if (Array.isArray(state.projectActivities)) {
      return state.projectActivities.map((singleStat, i) => {
        return (
          <>
            <Row
              key={i}
              justify="space-between"
              style={{
                padding:
                  singleStat.headerRow || singleStat.tableRow
                    ? "10px 20px"
                    : "10px 10px",
                backgroundColor: singleStat.headerRow
                  ? "rgba(235, 235, 235, 1)"
                  : "transparent",
              }}
            >
              <Col>{singleStat.title}</Col>
              <Col>
                {singleStat.tableRow ? (
                  <Link to={singleStat.count}>Project URL</Link>
                ) : (
                  singleStat.count
                )}
              </Col>
            </Row>
            {!singleStat.headerRow && !singleStat.section && <Line />}
          </>
        );
      });
    }
  };
  const _renderEventSubTable = (singleStat: any) => {
    if (Object.hasOwn(singleStat, "meta")) {
      return (
        <>
          <Col span={6}>
            {singleStat.tableRow ? (
              <Link to={`/dashboard/events.detail/${singleStat.key}`}>
                {singleStat.title}
              </Link>
            ) : (
              singleStat.title
            )}
          </Col>
          <Col span={4}>{singleStat.meta.beginsAt}</Col>
          <Col span={3}>
            {singleStat.meta.isHeader
              ? singleStat.meta.type
              : singleStat.meta.type === "lottery"
              ? lottery
              : firstComeFirstServer}
          </Col>
          {singleStat.meta?.applicationDeadline ? (
            <Col span={4}>{singleStat.meta.applicationDeadline}</Col>
          ) : null}
          {singleStat.meta?.applicationStatus ? (
            <Col span={4}>
              {intl.formatMessage({
                id: singleStat.meta.applicationStatus,
              })}
            </Col>
          ) : null}
          {singleStat.meta?.eventStatus ? (
            <Col span={3}>
              {intl.formatMessage({
                id: singleStat.meta.eventStatus,
              })}
            </Col>
          ) : null}
        </>
      );
    }
    return (
      <>
        <Col>{singleStat.title}</Col>
        <Col>{singleStat.count}</Col>
      </>
    );
  };
  const _renderEventActivities = () => {
    if (Array.isArray(state.eventActivities)) {
      return state.eventActivities.map((singleStat, i) => {
        return (
          <>
            <Row
              key={i}
              justify="space-between"
              style={{
                padding:
                  singleStat.headerRow || singleStat.tableRow
                    ? "10px 20px"
                    : "10px 10px",
                backgroundColor: singleStat.headerRow
                  ? "rgba(235, 235, 235, 1)"
                  : "transparent",
              }}
            >
              {_renderEventSubTable(singleStat)}
            </Row>
            {!singleStat.headerRow && !singleStat.section && <Line />}
          </>
        );
      });
    }
  };
  const _renderVideosActivities = () => {
    if (Array.isArray(state.videoActivities)) {
      return state.videoActivities.map((singleStat, i) => {
        return (
          <>
            <Row
              key={i}
              justify="space-between"
              style={{
                padding:
                  singleStat.headerRow || singleStat.tableRow
                    ? "10px 20px"
                    : "10px 10px",
                backgroundColor: singleStat.headerRow
                  ? "rgba(235, 235, 235, 1)"
                  : "transparent",
              }}
            >
              <Col>{singleStat.title}</Col>
              <Col>{singleStat.count}</Col>
            </Row>
            {!singleStat.headerRow && !singleStat.section && <Line />}
          </>
        );
      });
    }
  };
  const _renderPresenceActivities = () => {
    if (Array.isArray(state.presenceActivities)) {
      return state.presenceActivities.map((singleStat, i) => {
        return (
          <>
            <Row
              key={i}
              justify="space-between"
              style={{
                padding: "10px 10px",
              }}
            >
              <Col>{singleStat.title}</Col>
              <Col>{singleStat.count}</Col>
            </Row>
            <Line />
          </>
        );
      });
    }
  };
  const _renderProfileActivities = () => {
    if (Array.isArray(state.profileActivities)) {
      return state.profileActivities.map((singleStat, i) => {
        return (
          <>
            <Row
              key={i}
              justify="space-between"
              style={{
                padding: "10px 10px",
              }}
            >
              <Col>{singleStat.title}</Col>
              <Col>{singleStat.count}</Col>
            </Row>
            <Line />
          </>
        );
      });
    }
  };

  return (
    <>
      <Card title={null} bordered={false}>
        <h3 style={{ fontWeight: "bolder" }}>
          {intl.formatMessage({
            id: "screen.label.project_activity",
          })}
        </h3>
        {_renderProjectActivities()}
      </Card>
      <br />
      <Card title={null} bordered={false}>
        <h3 style={{ fontWeight: "bolder" }}>
          {intl.formatMessage({
            id: "screen.label.video_activity",
          })}
        </h3>
        {_renderVideosActivities()}
      </Card>
      <br />
      <Card title={null} bordered={false}>
        <h3 style={{ fontWeight: "bolder" }}>
          {intl.formatMessage({
            id: "screen.label.login_activity",
          })}
        </h3>
        {_renderPresenceActivities()}
      </Card>
      <br />
      <Card title={null} bordered={false}>
        <h3 style={{ fontWeight: "bolder" }}>
          {intl.formatMessage({
            id: "screen.label.events_activities",
          })}
        </h3>
        {_renderEventActivities()}
      </Card>
      <br />
      <Card title={null} bordered={false}>
        <h3 style={{ fontWeight: "bolder" }}>
          {intl.formatMessage({
            id: "screen.label.profile_activities",
          })}
        </h3>
        {_renderProfileActivities()}
      </Card>
    </>
  );
};
MemberActivityComponent.propTypes = {
  inspectedUserId: PropTypes.any,
  // @ts-expect-error TS2322
  dispatchApiRequest: PropTypes.func,
};
export default MemberActivityComponent;
