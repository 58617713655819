import { useCallback, useState } from "react";

import SearchIcon from "./assets/search-icon.svg";
import Separator from "./assets/separator.svg";
import styles from "./styles.module.scss";
import { SearchBarProps } from "./type";

export const SearchBar = (props: SearchBarProps) => {
  const { name, placeholder, value, width, onSearch, onChangeSearchWord } =
    props;
  const [searchWord, setSearchWord] = useState("");

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchWord(e.target.value);
      onChangeSearchWord && onChangeSearchWord(e.target.value);
    },
    [setSearchWord, onChangeSearchWord],
  );
  const handleSearch = useCallback(() => {
    onSearch(searchWord);
  }, [onSearch, searchWord]);

  const handleEnter = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      // Enterキーが押されたときに入力が確定していたなら検索を実行する（IMEのサジェストを確定させる時にEnterキーを押した場合などは実行しない）
      if (e.key !== "Enter" || e.nativeEvent.isComposing) return;
      handleSearch();
    },
    [handleSearch],
  );

  return (
    <div className={styles.search_container} style={{ width: width }}>
      <input
        type="search"
        role="searchbox"
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        className={styles.search_input}
        aria-label="search input"
        onKeyDown={handleEnter}
      />
      <img
        src={Separator}
        alt="separator"
        className={styles.search_separator}
      />
      <button
        onClick={handleSearch}
        className={styles.search_button}
        aria-label="submit search"
      >
        <img
          src={SearchIcon}
          alt="search-button"
          className={styles.search_button_image}
        />
      </button>
    </div>
  );
};
