import "./styles.css";

import { Col, Row } from "antd";
import React from "react";

import { Line } from "../../atoms";
import { Notification } from "./types";
type Props = {
  enableEditing?: boolean;
  notification?: Notification;
  unread?: boolean;
  children: React.ReactNode;
  onClick: () => void;
};
function NotificationItemWraper(props: Props) {
  const { unread, onClick, children, notification, enableEditing } = props;
  const itemClass =
    enableEditing && notification?.deletedAt
      ? "notification-item-deleted"
      : "notification-item";
  return (
    <div className="notification-wrapper">
      <Row onClick={onClick} align="middle" className={itemClass}>
        <Col flex="none">
          <span
            className={"tiny-dot"}
            style={{
              backgroundColor: unread ? "#00bf76" : "",
            }}
          />
        </Col>
        <Col flex="1">{children}</Col>
      </Row>
      <Line />
    </div>
  );
}

export default NotificationItemWraper;
