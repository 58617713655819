import { CaretDownOutlined } from "@ant-design/icons";
import { useCallback, useState } from "react";
import { useIntl } from "react-intl";

import {
  useVerticalSortableListContext,
  VerticalSortbaleListContext,
} from "../../../../Components/sortable/VerticalSortableList";
import { PlusIconTextButton } from "../../../../shared/buttons/components/PlusIconTextButton";
import { ExaminationPhaseItem } from "../ExaminationPhaseItem";
import { ExaminationPhaseSortSwitch } from "../ExaminationPhaseSortSwitch";
import styles from "./styles.module.scss";
import { ExaminationPhaseListProps } from "./type";

export const ExaminationPhaseList = ({
  examinationPhases,
  onUpdateExaminationPhaseName,
  onClickActiveUpdateButton,
  onClickCreateButton,
  onClickDeleteButton,
  onSortSave,
}: ExaminationPhaseListProps) => {
  const intl = useIntl();
  const createButtonlabel = intl.formatMessage({
    id: "screen.label.create_examination_phase",
  });

  const { sortableItems, hundleDragEnd } =
    useVerticalSortableListContext(examinationPhases);
  const [isSortable, setIsSortable] = useState(false);

  const onSwitchSortable = useCallback(() => {
    setIsSortable(!isSortable);
  }, [isSortable]);

  const sortSave = useCallback(() => {
    onSortSave(sortableItems);
    setIsSortable(false);
  }, [onSortSave, sortableItems]);

  return (
    <div>
      <div className={styles.header_container}>
        <ExaminationPhaseSortSwitch
          onSwitchSortable={onSwitchSortable}
          sortable={isSortable}
          onSortSave={sortSave}
        />
        <PlusIconTextButton
          onClick={onClickCreateButton}
          className={styles.create_btn}
        >
          {createButtonlabel}
        </PlusIconTextButton>
      </div>
      <VerticalSortbaleListContext
        sortableItems={sortableItems}
        hundleDragEnd={hundleDragEnd}
        isSortable={isSortable}
      >
        <div className={styles.items_container}>
          {sortableItems.map((examinationPhase, index) => (
            <>
              <ExaminationPhaseItem
                key={examinationPhase.id}
                examinationPhase={examinationPhase}
                onUpdateExaminationPhaseName={onUpdateExaminationPhaseName}
                onClickDeleteButton={onClickDeleteButton}
                onClickActiveUpdateButton={onClickActiveUpdateButton}
                isSortable={isSortable}
                index={index}
              />
              {index !== examinationPhases.length - 1 && (
                <div className={styles.down_icon_container}>
                  <CaretDownOutlined className={styles.down_icon} />
                </div>
              )}
            </>
          ))}
        </div>
      </VerticalSortbaleListContext>
    </div>
  );
};
