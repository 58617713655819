import { Button } from "antd";

import styles from "./styles.module.scss";
import { AntdButtonProps } from "./type";

export const AntdButton = ({
  children,
  category,
  className,
  ...props
}: React.PropsWithChildren<AntdButtonProps>) => {
  return (
    <Button
      className={`${styles[category]} ${className}`}
      type="primary"
      {...props}
    >
      {children}
    </Button>
  );
};
