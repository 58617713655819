import { useState } from "react";

export const useConfirmExecution = <T = boolean>() => {
  const [confirmation, setConfirmation] = useState<{
    resolve: (shouldExecute: T | boolean) => void;
  }>();

  const confirmExecution = (): Promise<T | boolean> => {
    return new Promise<T | boolean>((resolve) => {
      setConfirmation({
        resolve: (shouldExecute: T | boolean) => {
          resolve(shouldExecute);
        },
      });
    });
  };
  const execute = (result: T | boolean = true) => {
    confirmation?.resolve(result);
  };
  const notExecute = () => {
    confirmation?.resolve(false);
  };
  return {
    confirmExecution,
    execute,
    notExecute,
  };
};
