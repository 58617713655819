import { FC } from "react";

import { VariantIcon } from "@/shared/icons/components/VariantIcon/component";
import { FileIconVariant } from "@/shared/icons/components/VariantIcon/type";

import styles from "./styles.module.scss";

export type ReferenceThumbAltIconProps = {
  type: FileIconVariant;
  size?: number;
};

export const ReferenceThumbAltIcon: FC<ReferenceThumbAltIconProps> = ({
  type,
  size,
}) => {
  const width = size ? `${size}px` : "72px";
  const height = size ? `${size}px` : "72px";
  return (
    <VariantIcon
      variant={type}
      className={styles.thumb_alt_icon}
      style={{ width, height }}
    />
  );
};
