import { all, call, takeLatest } from "redux-saga/effects";

import { AsyncTypes } from "@/Redux/AsyncRedux";

let actionList = {};
// @ts-expect-error TS7006
function setActionList(list) {
  actionList = {
    ...actionList,
    ...list,
  };
}

export { setActionList };

// @ts-expect-error TS7006
function* serial(api, { actions }) {
  for (let i = 0; i < actions.length; i++) {
    const action = actions[i];
    if (action.type) {
      const { type, ...args } = action;
      // @ts-expect-error TS7053
      yield call(actionList[type], api, args);
    } else {
      yield action();
    }
  }
}

// @ts-expect-error TS7006
function* asyncSaga(api) {
  // @ts-expect-error TS2345
  yield all([takeLatest(AsyncTypes.SERIAL, serial, api)]);
}
export default asyncSaga;
