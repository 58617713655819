import { PlusOutlined } from "@ant-design/icons";
import { MenuOutlined } from "@ant-design/icons";
import { Card, Col, Form, Modal, Popconfirm, Row } from "antd";
import { arrayMoveImmutable } from "array-move";
import moment from "moment";
import { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { ApiRequestData } from "@/Redux/ApiRedux";

import {
  ButtonOption,
  ButtonSwitch,
  HorizonalLayout,
  IconDelete,
  IconEdit,
} from "../../Components/atoms";
import { CustomPagination, SearchField } from "../../Components/molecules";
import { Option, Select } from "../../Components/molecules";
import { ManageCategoriesComponent } from "../../Components/organisms";
import VideoSortableTable from "../../Components/organisms/videos/VideoSortableTable";
import VideoActions from "../../Redux/VideoRedux";
import { endpoints, parseEndpoint } from "../../Services/endpoints";
import { dateTimeFormat, defaultThumbnailImg } from "../../utils/constants";
import { getInitStorageValue } from "../../utils/localStorage";
import {
  ISmallCategoriesManagementScreenProps,
  SmallCategoriesManagementScreenState,
} from "./types/ISmallCategoriesManagementScreenProps";

const PageSaveId = "SmallCategoriesManagementScreen";
const pageKey = `pagination_page_${PageSaveId}`;
const limitKey = `pagination_limit_${PageSaveId}`;
const R = require("ramda");
class SmallCategoriesManagementScreen extends Component<
  ISmallCategoriesManagementScreenProps,
  SmallCategoriesManagementScreenState
> {
  state = {
    page: getInitStorageValue(pageKey, 1),
    limit: getInitStorageValue(limitKey, process.env.REACT_APP_PAGE_SIZE),
    isCategoryModalVisible: false,
    smallCategories: {},
    videos: {
      rows: [],
      count: 0,
    },
    isSearch: false,
    canRearrange: false,
    hasStartedRearrange: false,
  };
  getVideoLargeCategories = () => {
    // @ts-expect-error TS2532
    if (this.props.largeCategories.length === 0) {
      const requestBuilder: ApiRequestData = {
        method: "getRequest",
        url: parseEndpoint(endpoints.videoLargeCategory),
        data: {},
      };
      this.props.context.apiRequest(requestBuilder, ({ data }) => {
        // @ts-expect-error TS2722
        this.props.dispatchSetVideoCategories(data);
        this.actionAfterFetchingLargeCategories(data);
      });
    } else {
      this.actionAfterFetchingLargeCategories(this.props.largeCategories);
    }
  };
  // @ts-expect-error TS7006
  actionAfterFetchingLargeCategories = (data) => {
    const { smallCategories } = this.state;
    if (Array.isArray(data)) {
      const largeCategories = data.filter(
        (l) => l.id == this.getLargeCategoryId(),
      );
      for (let i = 0; i < largeCategories.length; i++) {
        const singleLargeCategory = largeCategories[i];
        const singleSmallCat = Object.assign(
          {},
          singleLargeCategory.VideoSmallCategory,
        );
        singleSmallCat.Vidoes = [];
        // @ts-expect-error TS7053
        smallCategories[singleLargeCategory.id] = singleSmallCat;
        this.getSmallCategoryForEachLargeCategory(singleLargeCategory.id);
      }
      setTimeout(() => {
        if (largeCategories.length > 0) {
          this.setState({
            smallCategories,
            videoLargeCategoryId: largeCategories[0].id,
          });
          // @ts-expect-error TS7053
          const data = this.state.smallCategories[largeCategories[0].id];
          if (Array.isArray(data) && data.length > 0) {
            this.setState(
              {
                videoSmallCategoryId: data[0].id,
              },
              () => {
                this.applyFilter(`videoSmallCategoryId=${data[0].id}`);
              },
            );
          }
        }
      }, 2 * 1000);
    }
  };
  // @ts-expect-error TS7006
  getSmallCategoryForEachLargeCategory = (largeCategoryId) => {
    const requestBuilder: ApiRequestData = {
      method: "getRequest",
      url: parseEndpoint(endpoints.smallCategoriesForSingleLargeCategory, {
        largeCategoryId,
      }),
      data: {},
    };
    this.props.context.apiRequest(requestBuilder, ({ data }) => {
      const { smallCategories } = this.state;
      // @ts-expect-error TS7053
      smallCategories[largeCategoryId] = data;
      this.setState({
        smallCategories,
        videoLargeCategoryId: largeCategoryId,
      });
    });
  };
  getLargeCategoryName = () => {
    const {
      match: { params },
    } = this.props;
    return params.name ? params.name : "";
  };
  getLargeCategoryId = () => {
    const {
      match: { params },
    } = this.props;
    return params.id ? params.id : -1;
  };
  componentDidMount() {
    this.props.context.updatePageTitle("screen.label.video");
    this.getVideoLargeCategories();
  }
  getTableColumns = () => {
    return [
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
      },
      {
        // @ts-expect-error TS2532
        title: this.props.intl.formatMessage({
          id: "screen.label.video_name",
        }),
        dataIndex: "name",
        key: "name",
        width: "200px",
        // @ts-expect-error TS7006
        render: (text, row) => {
          const { intl } = this.props;
          return (
            <Link to={`/dashboard/videos-play/${row.id}`}>
              <img
                // @ts-expect-error TS18048
                alt={intl.formatMessage({
                  id: "screens.alt.video_thumbnail",
                })}
                src={row.imageThumbnail || defaultThumbnailImg}
                style={{
                  width: `160px`,
                  height: `96px`,
                  maxWidth: `160px`,
                  maxHeight: `96px`,
                  objectFit: "cover",
                }}
              />
              <br />
              <p
                style={{
                  color: "#000000",
                  fontSize: "12px",
                  fontWeight: "normal",
                  fontStyle: "normal",
                }}
              >
                {text}
              </p>
            </Link>
          );
        },
      },
      {
        // @ts-expect-error TS2532
        title: this.props.intl.formatMessage({
          id: "screen.label.category_title",
        }),
        dataIndex: "category",
        key: "category",
        // @ts-expect-error TS7006
        render: (text, row) => {
          return (
            <>
              <h4>
                {row.VideoLargeCategory.name ? row.VideoLargeCategory.name : ""}
              </h4>
              <h5>
                {row.VideoSmallCategory.name ? row.VideoSmallCategory.name : ""}
              </h5>
            </>
          );
        },
      },
      {
        // @ts-expect-error TS2532
        title: this.props.intl.formatMessage({
          id: "screen.label.posted_date_and_time",
        }),
        dataIndex: "createdAt",
        key: "createdAt",
        // @ts-expect-error TS7006
        render: (text, row) =>
          moment(row.createdAt).local().format(dateTimeFormat),
      },
      {
        // @ts-expect-error TS2532
        title: this.props.intl.formatMessage({
          id: "screen.label.recommended",
        }),
        dataIndex: "",
        key: "",
        // @ts-expect-error TS7006
        render: (_, row) => {
          return (
            <>
              <label className="container">
                <input
                  onChange={(e) => {
                    this._onChangeRecommendedCheckBox(e, row);
                  }}
                  checked={row.VideoRecommendation.length > 0}
                  type="checkbox"
                />
                <span className="checkmark" />
              </label>
            </>
          );
        },
      },
      this.state.canRearrange
        ? {
            title: "",
            dataIndex: "",
            key: "",
            // @ts-expect-error TS7006
            render: (_, row) => {
              return (
                <MenuOutlined
                  className={"drag-handle"}
                  style={{ cursor: "grab", color: "#999" }}
                />
              );
            },
          }
        : {
            title: "",
            dataIndex: "",
            key: "",
            // @ts-expect-error TS7006
            render: (_, row) => {
              if (row.companyId === this.props.context.user.companyId) {
                const deleteVideoNode = (
                  <HorizonalLayout>
                    <Link to={`/dashboard/videos-edit/${row.id}`}>
                      <div
                        style={{
                          marginRight: "7px",
                          marginTop: "1px",
                        }}
                      >
                        {IconEdit}
                      </div>
                    </Link>

                    <Popconfirm
                      // @ts-expect-error TS2532
                      title={this.props.intl.formatMessage({
                        id: "screen.label.confirm_delete_video",
                      })}
                      onConfirm={() => {
                        this.deleteVideo(row.id);
                      }}
                      // @ts-expect-error TS2532
                      okText={this.props.intl.formatMessage({
                        id: "screen.label.yes",
                      })}
                      // @ts-expect-error TS2532
                      cancelText={this.props.intl.formatMessage({
                        id: "screen.label.no",
                      })}
                    >
                      <a>{IconDelete}</a>
                    </Popconfirm>
                  </HorizonalLayout>
                );
                const selectedLargeCategory = row.VideoLargeCategory;
                if (selectedLargeCategory) {
                  if (
                    selectedLargeCategory.companyId === null &&
                    this.props.context.user.isSuper
                  ) {
                    return deleteVideoNode;
                  } else if (selectedLargeCategory.companyId !== null) {
                    return deleteVideoNode;
                  }
                }
              }
            },
          },
    ];
  };
  // @ts-expect-error TS7006
  _onChangeRecommendedCheckBox = (e, video) => {
    const requestType = e.target.checked ? "postRequest" : "deleteRequest";
    const url = e.target.checked
      ? parseEndpoint(endpoints.recommendations)
      : parseEndpoint(endpoints.removeRecommendation, {
          videoId: video.id,
        });
    const requestBuilder: ApiRequestData = {
      method: requestType,
      url,
      data: {
        videoId: video.id,
      },
      // @ts-expect-error TS2532
      successMessage: this.props.intl.formatMessage({
        id: "screen.label.video_update",
      }),
    };
    this.props.context.apiRequest(requestBuilder, ({}) => {
      this.applyFilter(
        // @ts-expect-error TS2339
        `videoSmallCategoryId=${this.state.videoSmallCategoryId}`,
      );
    });
  };
  // @ts-expect-error TS7006
  deleteVideo = (videoId) => {
    const requestBuilder: ApiRequestData = {
      method: "deleteRequest",
      url: parseEndpoint(endpoints.singleVideo, {
        videoId,
      }),
      data: {},
    };
    this.props.context.apiRequest(requestBuilder, ({}) => {
      this.applyFilter(
        // @ts-expect-error TS2339
        `videoSmallCategoryId=${this.state.videoSmallCategoryId}`,
      );
    });
  };
  reorderVideoApi = () => {
    const data = this.state.videos.rows.map((v) => {
      return {
        // @ts-expect-error TS2339
        id: v.id,
      };
    });

    const requestBuilder: ApiRequestData = {
      method: "putRequest",
      url: parseEndpoint(endpoints.videoSmallCategory, {
        // @ts-expect-error TS2339
        smallCategoryId: this.state.videoSmallCategoryId,
      }),
      // @ts-expect-error TS2532
      successMessage: this.props.intl.formatMessage({
        id: "screen.label.reordering_video_completed",
      }),
      data: {
        body: data,
      },
    };
    this.props.context.apiRequest(requestBuilder, ({ data }) => {
      this.setState(
        {
          page: 1,
        },
        () => {
          this.applyFilter(
            // @ts-expect-error TS2339
            `videoSmallCategoryId=${this.state.videoSmallCategoryId}`,
          );
        },
      );
    });
  };
  // @ts-expect-error TS7006
  _canReOrder = (video) => {
    return video?.companyId === this.props.context.user?.companyId;
  };
  _shouldTableBeSortable = () => {
    return !(this.state.isSearch || !this.state.canRearrange);
  };
  getDragProps = () => {
    if (!this._shouldTableBeSortable()) {
      return {
        // @ts-expect-error TS7006
        onDragEnd: (fromIndex, toIndex) => {},
      };
    }
    return {
      // @ts-expect-error TS7006
      onDragEnd: (fromIndex, toIndex) => {
        const { videos } = this.state;

        const item = videos.rows[fromIndex];

        if (this._canReOrder(item)) {
          const newOrder = arrayMoveImmutable(
            R.clone(videos.rows),
            fromIndex,
            toIndex,
          );

          // @ts-expect-error TS2322
          videos.rows = newOrder.filter((v) => typeof v !== "undefined");
          this.setState(
            {
              videos,
            },
            () => {
              this.reorderVideoApi();
            },
          );
        }
      },
      handleSelector: ".drag-handle",
      lineClassName: ".drag-row-style",
    };
  };
  _isGlobalLargeCategory = () => {
    return (
      // @ts-expect-error TS2532
      this.props.largeCategories.filter((l) => {
        return l.id == this.getLargeCategoryId() && l.companyId == null;
      }).length > 0
    );
  };
  render() {
    return (
      <>
        <Modal
          // @ts-expect-error TS2532
          title={this.props.intl.formatMessage({
            id: "screen.label.category_management",
          })}
          visible={this.state.isCategoryModalVisible}
          footer={null}
          onCancel={() => {
            this.setState({
              // @ts-expect-error TS2345
              isCategoryModalVisible: false,
            });
          }}
        >
          <ManageCategoriesComponent
            onDeleteSmallCategory={this.onDeleteSmallCategory}
            onSubmitNewCategory={this.onSubmitNewCategory}
            smallCategories={this.state.smallCategories}
            // @ts-expect-error TS2322
            largeCategories={this.props.largeCategories}
            user={this.props.context.user}
            activeLargeCategory={parseInt(this.getLargeCategoryId())}
          />
        </Modal>
        <Row align="middle" justify="space-between">
          <Col>
            <h2>{this.getLargeCategoryName()}</h2>
          </Col>
          <Col>
            {!this._isGlobalLargeCategory() ? (
              <Row align="middle">
                <ButtonOption
                  style={{
                    maxWidth: 200,
                    marginRight: 16,
                  }}
                  icon={<PlusOutlined />}
                >
                  <Link to="/dashboard/videos-new">
                    <FormattedMessage id="screen.label.new_video_posting" />
                  </Link>
                </ButtonOption>
                <ButtonOption
                  onClick={() => {
                    this.setState({
                      // @ts-expect-error TS2345
                      isCategoryModalVisible: true,
                    });
                  }}
                  style={{
                    maxWidth: 200,
                  }}
                >
                  {/*
                   // @ts-expect-error TS2532 */}
                  {this.props.intl.formatMessage({
                    id: "screen.label.edit_category",
                  })}
                </ButtonOption>
              </Row>
            ) : null}
          </Col>
        </Row>
        <Card>
          <Row>
            <Col xs={24} sm={12} md={12}>
              <HorizonalLayout>
                <SearchField
                  // @ts-expect-error TS2532
                  enterButton={this.props.intl.formatMessage({
                    id: "screen.label.retrieval",
                  })}
                  // @ts-expect-error TS2322
                  onSearch={(value) => {
                    this.setState({ page: 1 }, () => {
                      this.applyFilter(
                        `filter=name iLike %${value}%&videoLargeCategoryId=${this.getLargeCategoryId()}`,
                      );
                      this.setState({
                        isSearch: true,
                      });
                    });
                  }}
                />
                <Form layout={"inline"} name="basic">
                  <Form.Item>
                    {/*
                     // @ts-expect-error TS2339 */}
                    {this.state.videoLargeCategoryId &&
                    // @ts-expect-error TS2339
                    this.state.videoSmallCategoryId ? (
                      <Select
                        // @ts-expect-error TS2339
                        defaultValue={this.state.videoSmallCategoryId}
                        onChange={(videoSmallCategoryId) => {
                          this.setState(
                            {
                              videoSmallCategoryId,
                              page: 1,
                            },
                            () => {
                              this.applyFilter(
                                `videoSmallCategoryId=${videoSmallCategoryId}`,
                              );
                              this.setState({
                                isSearch: false,
                              });
                            },
                          );
                        }}
                        style={{
                          maxWidth: "50vw",
                          minWidth: 100,
                        }}
                      >
                        {this._renderSmallCategories(
                          // @ts-expect-error TS2339
                          this.state.videoLargeCategoryId,
                        )}
                      </Select>
                    ) : null}
                  </Form.Item>
                </Form>
              </HorizonalLayout>
            </Col>
            <Col
              xs={24}
              md={12}
              style={{
                paddingTop: "7px",
                marginLeft: "-10px",
                float: "right",
              }}
            >
              {this._canReOrder(this.state.videos.rows[0]) ? (
                <HorizonalLayout
                  style={{
                    justifyContent: "flex-end",
                  }}
                >
                  <p
                    style={{
                      marginLeft: "0.5vw",
                      marginRight: "0.5vw",
                    }}
                  >
                    <FormattedMessage id={"screen.label.sort_items"} />
                  </p>{" "}
                  <ButtonSwitch
                    onChange={(checked) => {
                      this.setState({
                        canRearrange: checked,
                      });
                    }}
                    checked={this.state.canRearrange}
                  />
                </HorizonalLayout>
              ) : null}
            </Col>
          </Row>
          <br />

          {this._renderTable()}
          <br />
          <CustomPagination
            saveId={PageSaveId}
            count={this.state.videos.count}
            initPage={this.state.page}
            initLimit={this.state.limit}
            onChangePage={(page, limit) => {
              this.setState(
                {
                  page,
                  // @ts-expect-error TS2345
                  limit,
                },
                () => {
                  this.applyFilter("");
                },
              );
            }}
          />
        </Card>
      </>
    );
  }
  _renderTable = () => {
    return (
      <VideoSortableTable
        canReArrange={this._shouldTableBeSortable()}
        columns={this.getTableColumns()}
        dataSource={this.state.videos.rows}
        rowKey={"id"}
        getDragProps={this.getDragProps}
      />
    );
  };
  // @ts-expect-error TS7006
  _renderSmallCategories = (largeCategoryId) => {
    // @ts-expect-error TS7053
    const data = this.state.smallCategories[largeCategoryId];
    if (data && Array.isArray(data)) {
      return data.map((sm) => {
        return (
          <Option key={sm.id} value={sm.id}>
            {sm.name}
          </Option>
        );
      });
    }
  };
  // @ts-expect-error TS7006
  onDeleteSmallCategory = (largeCategoryId, smallCategoryId) => {
    const requestBuilder: ApiRequestData = {
      method: "deleteRequest",
      url: parseEndpoint(endpoints.videoSmallCategory, {
        smallCategoryId,
      }),
      data: {},
    };
    this.props.context.apiRequest(requestBuilder, ({ data }) => {
      this.getSmallCategoryForEachLargeCategory(largeCategoryId);
      const requestBuilder: ApiRequestData = {
        method: "getRequest",
        url: parseEndpoint(endpoints.videoLargeCategory),
        data: {},
      };
      this.props.context.apiRequest(requestBuilder, ({ data }) => {
        // @ts-expect-error TS2722
        this.props.dispatchSetVideoCategories(data);
      });
    });
  };
  // @ts-expect-error TS7006
  onSubmitNewCategory = (newSmallCategory) => {
    if (newSmallCategory.name.length > 0) {
      const data = {
        ...newSmallCategory,
        companyId: this.props.context.user.companyId,
      };
      const requestBuilder: ApiRequestData = {
        method: "postRequest",
        url: parseEndpoint(endpoints.smallCategoriesForSingleLargeCategory, {
          largeCategoryId: newSmallCategory.videoLargeCategoryId,
        }),
        data,
      };
      this.props.context.apiRequest(requestBuilder, ({ data }) => {
        this.getSmallCategoryForEachLargeCategory(
          newSmallCategory.videoLargeCategoryId,
        );
      });
    }
  };
  // @ts-expect-error TS7006
  applyFilter = (filter) => {
    const { page, limit } = this.state;
    const requestBuilder: ApiRequestData = {
      method: "getRequest",
      url: parseEndpoint(endpoints.smallCategoryVideos, {
        // @ts-expect-error TS2339
        videoSmallCategoryId: this.state.videoSmallCategoryId,
        page: page - 1,
        limit,
        filter,
      }),
      data: {},
    };
    this.props.context.apiRequest(requestBuilder, ({ data }) => {
      this.setState({
        // @ts-expect-error TS2322
        videos: data,
      });
    });
  };
}
// @ts-expect-error TS7006
const mapStateToProps = (state) => ({
  largeCategories: state.videos.largeCategoriesPayload,
});
// @ts-expect-error TS7006
const mapDispatchToProps = (dispatch) => ({
  // @ts-expect-error TS7006
  dispatchSetVideoCategories: (largeCategories) =>
    // @ts-expect-error TS2554
    dispatch(VideoActions.videoLargeCategoriesGetSuccess(largeCategories)),
});
export default injectIntl(
  // @ts-expect-error TS2769
  connect(mapStateToProps, mapDispatchToProps)(SmallCategoriesManagementScreen),
);
