import { Col, Row } from "antd";
import { FilterDropdownProps } from "antd/lib/table/interface";
import moment from "moment";
import { PureComponent } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Link } from "react-router-dom";

import EditIcon from "../../../assets/icons/edit.svg";
import {
  AllProjectListComponentState,
  IAllProjectListComponentProps,
} from "../../../Screens/project/types/IAllProjectListComponentProps";
import {
  getObjUUID,
  getProjectAvatar,
  isEmptyString,
} from "../../../utils/common";
import { dateTimeFormat } from "../../../utils/constants";
import {
  nonRecruiting_option,
  recruiting_option,
} from "../../../utils/constants";
import { getInitStorageValue } from "../../../utils/localStorage";
import { ButtonCV, Card, Checkbox, Table, Tag } from "../../atoms";
import { CustomPagination } from "../../molecules";
import { SearchDefault, Select } from "../../molecules";
import CsvExportModal from "./CsvExportModal";
const PageSaveId = "AllProjectListComponent";
const pageKey = `pagination_page_${PageSaveId}`;
const limitKey = `pagination_limit_${PageSaveId}`;
const { Option } = Select;
const projectLocalStorageKeys = "AllProjectListState";

class AllProjectListComponent extends PureComponent<
  IAllProjectListComponentProps,
  AllProjectListComponentState
> {
  // @ts-expect-error TS7006
  constructor(props) {
    super(props);
    const persistedState = localStorage.getItem(projectLocalStorageKeys);
    if (persistedState) {
      this.state = JSON.parse(persistedState);
    } else {
      this.state = {
        searchOtherProjectValue: "",
        allProjectPage: getInitStorageValue(pageKey, 1),
        allProjectLimit: getInitStorageValue(
          limitKey,
          process.env.REACT_APP_PAGE_SIZE,
        ),
        currentAllProjMilestoneSort: false,
        currentAllProjUpdateAtSort: false,
        allMemberStatusCheckList: [],
        checkedListAll: [],
        allTagCheckList: [],
        allSort: "id",
        sortOrder: "DESC",
        milestonesFilterAllProjectsCheckList: [],
        batches: this.props.batchId !== "" ? [this.props.batchId] : [],
      };
    }
  }
  componentDidMount() {
    this.setState(
      {
        batches: this.props.batchId !== "" ? [this.props.batchId] : [],
      },
      () => {
        this.handleAdvanceFilter();
      },
    );
    window.addEventListener("beforeunload", this.eventOnBeforeUnload);
  }
  // @ts-expect-error TS7006
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      // @ts-expect-error TS2532
      prevProps.projects.projectsPayload !== this.props.projects.projectsPayload
    ) {
      if (
        // @ts-expect-error TS2532
        Array.isArray(this.props.projects.projectsPayload.rows) &&
        // @ts-expect-error TS2532
        this.props.projects.projectsPayload.rows.length > 0
      ) {
        localStorage.setItem(
          projectLocalStorageKeys,
          JSON.stringify(this.state),
        );
      }
    }
    if (this.props.batchId !== "" && prevProps.batchId !== this.props.batchId) {
      this.setState(
        {
          batches: [this.props.batchId],
          searchOtherProjectValue: "",
        },
        () => {
          this.handleAdvanceFilter();
        },
      );
    }
  }
  eventOnBeforeUnload = () => {
    localStorage.removeItem(projectLocalStorageKeys);
  };
  getSortField = () => {
    if (this.state.currentAllProjMilestoneSort) {
      // @ts-expect-error TS2367
      return this.state.currentAllProjMilestoneSort === "ascend"
        ? "ASC"
        : "DESC";
    } else if (this.state.currentAllProjUpdateAtSort) {
      // @ts-expect-error TS2367
      return this.state.currentAllProjUpdateAtSort === "ascend"
        ? "ASC"
        : "DESC";
    }
    return "DESC";
  };
  handleAdvanceFilter = () => {
    const parsedMilestones =
      // @ts-expect-error TS2532
      this.state.milestonesFilterAllProjectsCheckList.map((sb) => {
        // @ts-expect-error TS18048
        const ps = sb.split("_").slice(1).join();
        if (ps.length > 0) {
          return ps;
        }
        return sb;
      });
    // @ts-expect-error TS2722
    this.props.handleAllProjectsAdvanceFilter({
      recruitmentStatus: this.state.allMemberStatusCheckList,
      stages: this.state.checkedListAll,
      tags: this.state.allTagCheckList,
      milestones: parsedMilestones,
      sortField: this.state.allSort,
      sortOrder: this.state.sortOrder,
      page: this.state.allProjectPage,
      limit: this.state.allProjectLimit,
      batches: this.state.batches,
      name:
        // @ts-expect-error TS2532
        this.state.searchOtherProjectValue.trim().length > 0
          ? this.state.searchOtherProjectValue
          : undefined,
      description:
        // @ts-expect-error TS2532
        this.state.searchOtherProjectValue.trim().length > 0
          ? this.state.searchOtherProjectValue
          : undefined,
    });
  };
  // @ts-expect-error TS7006
  handleAllMemberStatusChange = (type) => {
    this.setState(
      {
        allProjectPage: 1,
        allMemberStatusCheckList: type,
      },
      () => {
        this.handleAdvanceFilter();
      },
    );
  };
  // @ts-expect-error TS7006
  handleAllStageChange = (value) => {
    this.setState(
      {
        allProjectPage: 1,
        checkedListAll: value,
      },
      () => {
        this.handleAdvanceFilter();
      },
    );
  };
  // @ts-expect-error TS7006
  handleAllProjectsMilestoneChange = (value) => {
    this.setState(
      {
        allProjectPage: 1,
        milestonesFilterAllProjectsCheckList: value,
      },
      () => {
        this.handleAdvanceFilter();
      },
    );
  };
  // @ts-expect-error TS7006
  handleAllTagChange = (value) => {
    this.setState(
      {
        allProjectPage: 1,
        allTagCheckList: value,
      },
      () => {
        this.handleAdvanceFilter();
      },
    );
  };
  onChangeOtherProjectsPage = () => {
    this.handleAdvanceFilter();
  };
  getMileStones = () => {
    if (Array.isArray(this.props.batches)) {
      if (this.props.batchId && this.props.batchId.length > 0) {
        const batch = this.props.batches.find(
          (b) => b.id === this.props.batchId,
        );
        if (batch) {
          // @ts-expect-error TS7006
          return batch.milestones.map((m) => {
            return {
              ...m,
              name: `${m.name}`,
              id: `${batch.id}_${m.name}`,
            };
          });
        }
      } else {
        return this.props.batches
          .map((b) => {
            // @ts-expect-error TS7006
            return b.milestones.map((m) => {
              return {
                ...m,
                name: `${b.name}:${m.name}`,
                id: `${b.id}_${m.name}`,
              };
            });
          })
          .flat(1);
      }
    }
    return [];
  };
  getAllMilestonePriorityProps = () => {
    return {
      filterDropdown: ({
        // @ts-expect-error TS7031
        setSelectedKeys,
        // @ts-expect-error TS7031
        selectedKeys,
        // @ts-expect-error TS7031
        confirm,
        // @ts-expect-error TS7031
        clearFilters,
      }) => {
        const milestones = this.getMileStones();
        return (
          <div style={{ padding: 8 }}>
            <Checkbox.Group
              style={{ width: "100%" }}
              // @ts-expect-error TS2322
              value={this.state.milestonesFilterAllProjectsCheckList}
              onChange={(value) => this.handleAllProjectsMilestoneChange(value)}
            >
              <Row>
                {/*
                 // @ts-expect-error TS7006 */}
                {milestones.map((o) => (
                  <Col span={8} key={o.name}>
                    <Checkbox value={o.id}>{o.name}</Checkbox>
                  </Col>
                ))}
              </Row>
            </Checkbox.Group>
            <br />
            <ButtonCV
              type="primary"
              onClick={() => {
                clearFilters();
                setSelectedKeys([]);
                this.handleAllProjectsMilestoneChange([]);
              }}
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              Reset
            </ButtonCV>
          </div>
        );
      },
      // @ts-expect-error TS7006
      onFilter: (value, record) => {},
    };
  };
  getAllColumnMemberStatusProps = () => ({
    filterDropdown: ({
      // @ts-expect-error TS7031
      setSelectedKeys,
      // @ts-expect-error TS7031
      selectedKeys,
      // @ts-expect-error TS7031
      confirm,
      // @ts-expect-error TS7031
      clearFilters,
    }) => {
      // @ts-expect-error TS7034
      const options = [];
      [recruiting_option, nonRecruiting_option].forEach((o) => {
        options.push(
          <Col key={o.value} span={8}>
            <Checkbox value={o.value}>
              <FormattedMessage id={o.label} />
            </Checkbox>
          </Col>,
        );
      });
      return (
        <div style={{ padding: 8 }}>
          <Checkbox.Group
            style={{ width: "100%" }}
            // @ts-expect-error TS2322
            value={this.state.allMemberStatusCheckList}
            onChange={(value) => this.handleAllMemberStatusChange(value)}
          >
            {/*
             // @ts-expect-error TS7005 */}
            <Row>{options}</Row>
          </Checkbox.Group>
          <br />
          <ButtonCV
            type="primary"
            onClick={() => {
              clearFilters();
              setSelectedKeys([]);
              this.handleAllMemberStatusChange([]);
            }}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Reset
          </ButtonCV>
        </div>
      );
    },
    // @ts-expect-error TS7006
    onFilter: (value, record) => {},
  });
  getAllColumnPriorityProps = () => ({
    filterDropdown: ({
      // @ts-expect-error TS7031
      setSelectedKeys,
      // @ts-expect-error TS7031
      selectedKeys,
      // @ts-expect-error TS7031
      confirm,
      // @ts-expect-error TS7031
      clearFilters,
    }) => {
      // @ts-expect-error TS2532
      const stages = this.props.company.stages ? this.props.company.stages : [];
      // @ts-expect-error TS7034
      const options = [];
      // @ts-expect-error TS7006
      stages.forEach((o, index) => {
        options.push(
          <Col span={8} key={o}>
            <Checkbox value={o} key={o}>
              {o}
            </Checkbox>
          </Col>,
        );
      });
      return (
        <div style={{ padding: 8 }}>
          <Checkbox.Group
            style={{ width: "100%" }}
            // @ts-expect-error TS2322
            value={this.state.checkedListAll}
            onChange={(value) => this.handleAllStageChange(value)}
          >
            {/*
             // @ts-expect-error TS7005 */}
            <Row>{options}</Row>
          </Checkbox.Group>
          <br />
          <ButtonCV
            type="primary"
            onClick={() => {
              clearFilters();
              setSelectedKeys([]);
              this.handleAllStageChange([]);
            }}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Reset
          </ButtonCV>
        </div>
      );
    },
    // @ts-expect-error TS7006
    onFilter: (value, record) => {},
  });
  getAllColumnTagProps = () => ({
    filterDropdown: ({
      // @ts-expect-error TS7031
      setSelectedKeys,
      // @ts-expect-error TS7031
      selectedKeys,
      // @ts-expect-error TS7031
      confirm,
      // @ts-expect-error TS7031
      clearFilters,
    }) => {
      const tags =
        this.props.tags && Array.isArray(this.props.tags)
          ? this.props.tags
          : [];
      // @ts-expect-error TS7034
      const options = [];
      tags.forEach((o) => {
        options.push(
          <Col span={8} key={o.id}>
            <Checkbox value={o.id} key={o.id}>
              {o.name}
            </Checkbox>
          </Col>,
        );
      });
      return (
        <div style={{ padding: 8 }}>
          <Checkbox.Group
            style={{ width: "100%" }}
            // @ts-expect-error TS2322
            value={this.state.allTagCheckList}
            onChange={(value) => this.handleAllTagChange(value)}
          >
            {/*
             // @ts-expect-error TS7005 */}
            <Row>{options}</Row>
          </Checkbox.Group>
          <br />
          <ButtonCV
            type="primary"
            onClick={() => {
              clearFilters();
              setSelectedKeys([]);
              this.handleAllTagChange([]);
            }}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Reset
          </ButtonCV>
        </div>
      );
    },
    // @ts-expect-error TS7006
    onFilter: (value, record) => {},
  });
  getAllColumnBatchProps = () => ({
    filterDropdown: ({
      setSelectedKeys,
      clearFilters,
    }: FilterDropdownProps) => {
      const options =
        Array.isArray(this.props.batches) && this.props.batches.length > 0
          ? this.props.batches
              .filter((batch) => batch.status > 0)
              .map((batch) => (
                <Col span={8} key={batch.id}>
                  <Checkbox value={batch.id} key={batch.id}>
                    {batch.name}
                  </Checkbox>
                </Col>
              ))
          : [];

      return (
        <div style={{ padding: 8 }}>
          <Checkbox.Group
            style={{ width: "100%" }}
            value={this.state.batches}
            onChange={(batches) => {
              this.setState({ batches }, () => {
                this.handleAdvanceFilter();
              });
            }}
          >
            <Row>{options}</Row>
          </Checkbox.Group>
          <br />
          <ButtonCV
            type="primary"
            onClick={() => {
              clearFilters?.();
              setSelectedKeys([]);
              this.setState({ batches: [] }, () => {
                this.handleAdvanceFilter();
              });
            }}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Reset
          </ButtonCV>
        </div>
      );
    },
  });
  // @ts-expect-error TS7006
  onTableChangeAllProj = (pagination, filters, sorter, extra) => {
    if (sorter) {
      const order = sorter.order && sorter.order === "ascend" ? "asc" : "desc";
      this.setState(
        {
          allSort: sorter.field,
          sortOrder: order,
          currentAllProjMilestoneSort:
            sorter.field === "milestoneDate" ? sorter.order : false,
          currentAllProjUpdateAtSort:
            sorter.field === "updatedAt" ? sorter.order : false,
        },
        () => {
          this.handleAdvanceFilter();
        },
      );
    }
  };
  getSelectedBatch = () => {
    if (this.props.batchId === "") {
      return {
        displayCheckpoint: true,
      };
    }
    if (Array.isArray(this.props.batches)) {
      return this.props.batches.find((b) => b.id === this.props.batchId);
    }
    return null;
  };
  getAllProjectsColumnHeader = () => {
    const { company, authUser } = this.props;
    const selectedBatch = this.getSelectedBatch();

    const columns = [
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
        sorter: true,
      },
      {
        title: "",
        dataIndex: "icon",
        key: "icon",
        // @ts-expect-error TS7006
        render: function _fn(text, row) {
          return <div>{getProjectAvatar(row)}</div>;
        },
      },
      {
        // @ts-expect-error TS2532
        title: this.props.intl.formatMessage({
          id: "screen.label.project_name",
        }),
        dataIndex: "name",
        key: "name",
        // @ts-expect-error TS7006
        render: (text, row) => (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div style={{ maxWidth: "20vw" }}>
              <Link to={"/dashboard/project.top/" + getObjUUID(row)}>
                {text}
              </Link>
            </div>
          </div>
        ),
      },
      {
        // @ts-expect-error TS2532
        title: this.props.intl.formatMessage({
          id: "screen.label.date_modified",
        }),
        key: "updatedAt",
        dataIndex: "updatedAt",
        // sorter: true,
        // sortOrder: this.state.currentAllProjUpdateAtSort,
        // @ts-expect-error TS7006
        render: (text) => (
          <span>{moment(text).local().format(dateTimeFormat)}</span>
        ),
        sortDirections: ["descend", "ascend"],
      },
      {
        // @ts-expect-error TS2532
        title: this.props.intl.formatMessage({
          id: "screen.label.member_status",
        }),
        key: "type",
        dataIndex: "type",
        // @ts-expect-error TS7006
        render: (text) => (
          <span>
            {isEmptyString(text)
              ? ""
              : // @ts-expect-error TS2532
                this.props.intl.formatMessage({
                  id: `screen.label.${text}`.toLowerCase(),
                })}
          </span>
        ),
        ...this.getAllColumnMemberStatusProps(),
      },
      {
        // @ts-expect-error TS2532
        title: this.props.intl.formatMessage({
          id: "screen.label.stage",
        }),
        key: "stage",
        dataIndex: "stage",
        // @ts-expect-error TS7006
        render: (text) => <span>{isEmptyString(text) ? "" : text}</span>,
        ...this.getAllColumnPriorityProps(),
      },
      {
        // @ts-expect-error TS2532
        title: this.props.intl.formatMessage({
          id: "screen.label.project_label",
        }),
        key: "tag",
        dataIndex: "tag",
        // @ts-expect-error TS7006
        render: (text) => <span>{isEmptyString(text) ? "" : text}</span>,
        ...this.getAllColumnTagProps(),
      },
      {
        // @ts-expect-error TS2532
        title: this.props.intl.formatMessage({
          id: "screen.label.owner",
        }),
        key: "",
        dataIndex: "",
        // @ts-expect-error TS7006
        render: (text, row) => {
          if (Array.isArray(row.team)) {
            const projectOwners = Array.isArray(row.team)
              ? // @ts-expect-error TS7006
                row.team.filter((team) => {
                  return team.isProjectOwner;
                })
              : [];
            // @ts-expect-error TS7006
            return projectOwners.map((team, index) => {
              if (projectOwners.length === 1) {
                if (team.User.stoppedAt) {
                  return (
                    <Tag color="purple" key={getObjUUID(team.User)}>
                      {/*
                       // @ts-expect-error TS2532 */}
                      {this.props.intl.formatMessage({
                        id: "screen.label.stopped_user",
                      })}
                    </Tag>
                  );
                }
                if (team.User.deletedAt) {
                  return (
                    <Tag color="red" key={getObjUUID(team.User)}>
                      {/*
                       // @ts-expect-error TS2532 */}
                      {this.props.intl.formatMessage({
                        id: "screen.label.deleted_user",
                      })}
                    </Tag>
                  );
                } else {
                  return (
                    <Link
                      to={`/dashboard/member/${getObjUUID(team.User)}`}
                      key={getObjUUID(team.User)}
                    >
                      {team.User.name
                        ? team.User.name
                        : team.User.Credential
                        ? team.User.Credential.email
                          ? team.User.Credential.email.split("@")[0]
                          : ""
                        : ""}
                    </Link>
                  );
                }
              } else if (
                projectOwners.length > 1 &&
                (team.User.stoppedAt !== null || team.User.deletedAt !== null)
              ) {
                return "";
              } else {
                let comma = ",";
                if (index === projectOwners.length - 1) comma = "";
                return (
                  <Link
                    to={`/dashboard/member/${getObjUUID(team.User)}`}
                    key={getObjUUID(team.User)}
                  >
                    {team.User.name}
                    {comma}{" "}
                  </Link>
                );
              }
            });
          }
        },
      },
      // @ts-expect-error TS18048
      company.isAllowCheckpoint &&
      selectedBatch?.displayCheckpoint &&
      // @ts-expect-error TS18048
      authUser.type !== "employee"
        ? {
            // @ts-expect-error TS2532
            title: this.props.intl.formatMessage({
              id: "screen.label.project_list.checkpoint_coverage_rate",
            }),
            key: "checkpointCoverageRate",
            dataIndex: "checkpointCoverageRate",
            sorter: true,
            render: function _fn(checkpointCoverageRate: number, row: any) {
              if (
                row.batch?.displayCheckpoint &&
                checkpointCoverageRate !== -1
              ) {
                return `${checkpointCoverageRate} %`;
              }
            },
          }
        : undefined,

      {
        title: "",
        key: "isAdmin",
        dataIndex: "isAdmin",
        // @ts-expect-error TS7006
        render: (_, row) => {
          const projectOwners = Array.isArray(row.team)
            ? row.team
                // @ts-expect-error TS7006
                .filter((team) => {
                  return team.isProjectOwner;
                })
                // @ts-expect-error TS7006
                .map((t) => t.userId)
            : [];
          // @ts-expect-error TS2532
          const text = projectOwners.includes(this.props.authUser.id);
          return text ? (
            <Link to={`/dashboard/projects.edit/${getObjUUID(row)}`}>
              <img
                src={EditIcon}
                style={{
                  height: "24px",
                  width: "24px",
                }}
              />
            </Link>
          ) : (
            <span />
          );
        },
      },
    ];
    if (this.props.batchId === "") {
      columns.splice(5, 0, {
        // @ts-expect-error TS2532
        title: this.props.intl.formatMessage({
          id: "screen.label.held_times",
        }),
        key: "batch",
        dataIndex: "batch",
        // @ts-expect-error TS7006
        render: (text, row) => {
          if (row.batch) {
            return (
              <div
                style={{
                  maxWidth: 150,
                  minWidth: 100,
                  wordWrap: "break-word",
                  display: "inline-block",
                }}
              >
                <span>{row.batch.name}</span>
              </div>
            );
          }
          return null;
        },
        ...this.getAllColumnBatchProps(),
      });
    }
    return columns.filter((c) => c);
  };
  _renderGetProjectToCSV = () => {
    // @ts-expect-error TS2532
    if (this.props.authUser.isSuper) {
      let url = `projects/download/projects-csv?`;
      if (this.state.batches && this.state.batches.length > 0) {
        for (const batch of this.state.batches) {
          url = url + `batches[]=${batch}`;
        }
      }
      return <CsvExportModal url={url} title={"screen.label.csv_download"} />;
    }
  };
  render() {
    return (
      <>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h2>
            {/*
             // @ts-expect-error TS2532 */}
            {this.props.intl.formatMessage(
              {
                id: "screen.label.house_project_count",
              },
              {
                // @ts-expect-error TS2532
                count: this.props.projects.projectsPayload.count,
              },
            )}
          </h2>
          {this._renderGetProjectToCSV()}
        </div>
        <Card>
          <SearchDefault
            value={this.state.searchOtherProjectValue}
            // @ts-expect-error TS2532
            placeholder={this.props.intl.formatMessage({
              id: "screen.label.please_enter_keyword",
            })}
            // @ts-expect-error TS2532
            enterButton={this.props.intl.formatMessage({
              id: "screen.label.retrieval",
            })}
            onChange={(e) => {
              this.setState(
                {
                  searchOtherProjectValue: e.target.value,
                },
                () => {
                  if (
                    this.state.searchOtherProjectValue &&
                    this.state.searchOtherProjectValue.length === 0
                  ) {
                    localStorage.removeItem(projectLocalStorageKeys);
                  }
                },
              );
            }}
            onSearch={(value) => {
              this.setState(
                {
                  allProjectPage: 1,
                  searchOtherProjectValue: value,
                },
                () => {
                  this.searchOtherProjects(value);
                },
              );
            }}
          />
          <br />
          <br />
          {/*
           // @ts-expect-error TS2769 */}
          <Table
            pagination={false}
            columns={this.getAllProjectsColumnHeader()}
            // @ts-expect-error TS2532
            dataSource={this.props.projects.projectsPayload.rows}
            onChange={this.onTableChangeAllProj}
            className="ant-table-x-scroll"
          />
          <br />
          <CustomPagination
            saveId={PageSaveId}
            // @ts-expect-error TS2532
            count={this.props.projects.projectsPayload.count}
            initPage={this.state.allProjectPage}
            initLimit={this.state.allProjectLimit}
            onChangePage={(page, limit) => {
              this.setState(
                {
                  allProjectPage: page,
                  allProjectLimit: limit,
                },
                () => {
                  if (
                    this.state.searchOtherProjectValue &&
                    this.state.searchOtherProjectValue.length > 0
                  ) {
                    this.searchOtherProjects(
                      this.state.searchOtherProjectValue,
                    );
                  } else {
                    this.onChangeOtherProjectsPage();
                  }
                },
              );
            }}
          />
        </Card>
      </>
    );
  }
  // @ts-expect-error TS7006
  searchOtherProjects = (value) => {
    this.setState(
      {
        checkedListAll: [],
      },
      () => {
        this.handleAdvanceFilter();
      },
    );
  };
}
// @ts-expect-error TS2769
export default injectIntl(AllProjectListComponent);
