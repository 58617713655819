import { Col, Form, Row } from "antd";
import {
  Card,
  Checkbox,
  DatePicker,
  Input,
  message,
  notification,
  Popconfirm,
  Popover,
  Radio,
  Select,
  Tag,
} from "antd";
import moment from "moment";
import React, { PureComponent } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";

import { ApiRequestData } from "@/Redux/ApiRedux";

import InfoIcon from "../../assets/icons/info.svg";
import {
  InsuAcceptButton,
  InsuPrimaryButton,
  InsuRejectButton,
} from "../../Components/atoms/buttons/shared";
import { ProjectCreateRoot } from "../../Components/molecules/project/ProjectCreateRoot";
import ProjectActions from "../../Redux/ProjectRedux";
import { endpoints, parseEndpoint } from "../../Services/endpoints";
import { getPureStringFromHtml } from "../../utils/common";
import ProjectImageEdit from "./ProjectImageEdit";
import ProjectLinksComponent from "./ProjectLinksComponent";
import {
  IProjectEditScreenProps,
  ProjectEditScreenState,
} from "./types/IProjectEditScreenProps";

const { Option } = Select;
// @ts-expect-error TS7034
let navigate;
const MILESTONE_SPECIFIC_DATE = 0;
const MILESTONE_CUSTOM_DATE = 1;
const MILESTONE_NOT_SPECIFIC_DATE = 2;
const defaultMilestoneDateOptions = [
  {
    label: <FormattedMessage id="screen.label.specify_default_date" />,
    value: MILESTONE_SPECIFIC_DATE,
  },
  {
    label: <FormattedMessage id="screen.label.customize_date" />,
    value: MILESTONE_CUSTOM_DATE,
  },
  {
    label: <FormattedMessage id="screen.label.not_specify_date" />,
    value: MILESTONE_NOT_SPECIFIC_DATE,
  },
];

class ProjectEditScreen extends PureComponent<
  IProjectEditScreenProps,
  ProjectEditScreenState
> {
  milestondateRef: any;
  state = {
    name: "",
    description: "",
    icon: "",
    stage: "",
    stages: [],
    defaultMilestoneDate: 0,
    showStage: false,
    showTag: false,
    canOnlyView: true,
    milestone: "",
    milestoneDate: null,
    reminder: "",
    reminderDate: null,
    isPrivate: true,
    tags: [],
    projectTags: [],
    links: [],
    batch: null,
  };
  componentDidMount() {
    this.props.context.updatePageTitle("screen.label.edit_project");
    const projectId = this.getProjectId();
    this.props.dispatchGetProjectDetail(projectId);
    this.getTag();
    this.milestondateRef = React.createRef();
  }
  // @ts-expect-error TS7006
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.projectPayload !== this.props.projectPayload) {
      if (
        // @ts-expect-error TS2532
        this.props.projectPayload.isProjectOwner ||
        // @ts-expect-error TS2532
        this.props.projectPayload.user.type === "admin"
      ) {
        this.setState({
          // @ts-expect-error TS2532
          isPrivate: this.props.projectPayload.isPrivate,
        });
        // @ts-expect-error TS2532
        if (prevProps.projectPayload.id !== this.props.projectPayload.id) {
          // @ts-expect-error TS7005
          window.clearTimeout(navigate);
          this.getBatch();
          let links = [];
          // @ts-expect-error TS2532
          if (Array.isArray(this.props.projectPayload.links)) {
            // @ts-expect-error TS2532
            links = this.props.projectPayload.links.map((link) => link.uuid);
          }

          this.setState({
            // @ts-expect-error TS2532
            icon: this.props.projectPayload.icon,
            // @ts-expect-error TS2532
            name: this.props.projectPayload.name,
            // @ts-expect-error TS2532
            description: this.props.projectPayload.description,
            // @ts-expect-error TS2532
            milestone: this.props.projectPayload.milestone,
            // @ts-expect-error TS2532
            stage: this.props.projectPayload.stage,
            // @ts-expect-error TS2532
            stages: this.props.projectPayload.Company.stages,
            // @ts-expect-error TS2532
            projectTags: this.props.projectPayload.projectTags,
            // @ts-expect-error TS2532
            reminder: this.props.projectPayload.reminder,
            // @ts-expect-error TS2532
            reminderDate: this.props.projectPayload.reminderDate,
            defaultMilestoneDate:
              // @ts-expect-error TS2532
              this.props.projectPayload.defaultMilestoneDate,
            // @ts-expect-error TS2532
            milestoneDate: this.props.projectPayload.milestoneDate,
            links,
          });
        } else {
          setTimeout(() => {
            this.checkState();
          }, 5000);
        }
      } else {
        navigate = setTimeout(() => {
          document.location = "/dashboard";
        }, 5000);
      }
      // @ts-expect-error TS2532
      if (this.props.projectPayload.user.type === "admin") {
        this.setState({
          showStage: true,
          showTag: true,
          canOnlyView: false,
        });
      }
      // @ts-expect-error TS2532
      if (this.props.projectPayload.user.type === "employee") {
        this.setState({
          canOnlyView: true,
        });
      }
    }
  }
  checkState() {
    if (this.state.name.length === 0) {
      this.setState({
        // @ts-expect-error TS2532
        name: this.props.projectPayload.name,
        // @ts-expect-error TS2532
        description: this.props.projectPayload.description,
        // @ts-expect-error TS2532
        milestone: this.props.projectPayload.milestone,
        // @ts-expect-error TS2532
        milestoneDate: this.props.projectPayload.milestoneDate,
        // @ts-expect-error TS2532
        stage: this.props.projectPayload.stage,
        // @ts-expect-error TS2532
        stages: this.props.projectPayload.Company.stages,
        // @ts-expect-error TS2532
        projectTags: this.props.projectPayload.projectTags,
        // @ts-expect-error TS2532
        icon: this.props.projectPayload.icon,
        // @ts-expect-error TS2532
        reminder: this.props.projectPayload.reminder,
        // @ts-expect-error TS2532
        reminderDate: this.props.projectPayload.reminderDate,
      });
    }
  }
  getTag = () => {
    const tagRequest: ApiRequestData = {
      method: "getRequest",
      url: parseEndpoint(endpoints.tags),
      data: {},
    };
    this.props.context.apiRequest(tagRequest, ({ data }) => {
      this.setState({
        tags: data,
        // @ts-expect-error TS2532
        projectTags: this.props.projectPayload.projectTags,
      });
    });
  };
  // @ts-expect-error TS7006
  handleChange = (key, value) => {
    this.setState({ [key]: value });
  };
  // @ts-expect-error TS7006
  handleSubmit = (e) => {
    e.preventDefault();
    const keys = Object.keys(this.state);
    const data = {};
    if (getPureStringFromHtml(this.state.description).trim() === "") {
      notification.warning({
        // @ts-expect-error TS2532
        message: this.props.intl.formatMessage({
          id: "screen.label.description_required",
        }),
      });
      return;
    }
    for (let i = 0; i < keys.length; i++) {
      if (keys[i] === "batch") {
        // @ts-expect-error TS7053
        data["batchId"] = this.state.batch ? this.state.batch.id : null;
      } else {
        // @ts-expect-error TS7053
        data[keys[i]] = this.state[keys[i]];
      }
    }
    // @ts-expect-error TS2532
    const message = this.props.intl.formatMessage({
      id: "screen.label.project_has_been_updated",
    });
    const projectId = this.getProjectId();
    this.props
      .dispatchCreateProject(
        {
          ...data,
          isPrivate: this.state.isPrivate,
          // @ts-expect-error TS2532
          id: this.props.projectPayload.id,
          uid: projectId,
        },
        message,
      )
      .then(() => {
        this.props.history?.push?.(`/dashboard/project.top/${projectId}`);
        this.props.dispatchGetMyProjects({
          // @ts-expect-error TS2322
          userId: this.props.context.user.id,
          page: 0,
        });
      });
  };
  // @ts-expect-error TS7006
  onBeforeUpload = (file) => {
    //Prevent file uploader to upload file immediately
    this.handleChange("icon", file);
    return false;
  };
  getProjectId = () => {
    const {
      match: { params },
    } = this.props;
    return params.id ? params.id : -1;
  };
  getBatch = () => {
    const { projectPayload } = this.props;
    // @ts-expect-error TS18048
    const batchId = projectPayload.batchId;
    const batchRequest: ApiRequestData = {
      method: "getRequest",
      url: `batch/${batchId && batchId !== "" ? batchId : "default"}`,
    };
    this.props.context.apiRequest(batchRequest, ({ data }) => {
      this.setState(
        {
          batch: data,
        },
        () => {
          this.setMileStoneDate();
        },
      );
    });
  };
  setMileStoneDate = () => {
    if (
      // @ts-expect-error TS2532
      this.props.projectPayload.defaultMilestoneDate == MILESTONE_SPECIFIC_DATE
    ) {
      // @ts-expect-error TS2551
      if (this.state.milestones && this.state.milestones.length > 0) {
        // @ts-expect-error TS2551
        const milestonePair = this.state.milestones.find(
          // @ts-expect-error TS7006
          (m) => m.name === this.props.projectPayload.milestone,
        );
        if (milestonePair) {
          this.setState({
            milestoneDate: milestonePair.date,
          });
        }
      }
    } else if (
      // @ts-expect-error TS2532
      this.props.projectPayload.defaultMilestoneDate == MILESTONE_CUSTOM_DATE
    ) {
      this.setState({
        // @ts-expect-error TS2532
        milestoneDate: this.props.projectPayload.milestoneDate,
      });
    } else {
      this.setState({
        milestoneDate: null,
      });
    }
  };
  // @ts-expect-error TS7006
  onChangeTags = (tag) => {
    // @ts-expect-error TS2345
    if (this.state.projectTags.includes(tag.id)) {
      const newTag = this.state.projectTags.filter((tagId) => {
        if (tagId === tag.id) {
          return false;
        }
        return true;
      });
      this.setState({
        projectTags: newTag,
      });
    } else {
      this.setState({
        projectTags: this.state.projectTags.concat(tag.id),
      });
    }
  };
  onChangeValue = (value: string) => {
    this.handleChange("description", value);
  };
  render() {
    let stageNode = <div />;
    let tagNode = <div />;
    const {
      stage,
      stages,
      showStage,
      showTag,
      tags,
      projectTags,
      canOnlyView,
    } = this.state;
    if (
      showStage &&
      stages &&
      stages.length > 0 &&
      this.props.loading === false
    ) {
      // @ts-expect-error TS7034
      const options = [];
      stages.forEach((o) => {
        options.push(
          <Option key={o} value={o}>
            {o}
          </Option>,
        );
      });
      stageNode = (
        <Select
          defaultValue={stage}
          style={{ width: "18vw" }}
          onChange={(value) => this.handleChange("stage", value)}
        >
          {/*
           // @ts-expect-error TS7005 */}
          {options}
        </Select>
      );
    }
    if (showTag && tags && tags.length > 0 && this.props.loading === false) {
      // @ts-expect-error TS2739
      tagNode = this.state.tags.map((tag) => {
        return (
          <Tag
            style={{
              border: "1px solid #323232",
              borderRadius: "0px",
            }}
            // @ts-expect-error TS2345
            color={projectTags && projectTags.includes(tag.id) ? "black" : ""}
            onClick={() => this.onChangeTags(tag)}
            // @ts-expect-error TS2339
            key={tag.id}
          >
            {/*
             // @ts-expect-error TS2339 */}
            {tag.name}
          </Tag>
        );
      });
    }
    if (
      canOnlyView &&
      tags &&
      tags.length > 0 &&
      this.props.loading === false
    ) {
      // @ts-expect-error TS2322
      tagNode = this.state.tags.map((tag) => {
        return (
          // @ts-expect-error TS2345
          <Tag color={projectTags.includes(tag.id) ? "black" : ""} key={tag.id}>
            {/*
             // @ts-expect-error TS2339 */}
            {tag.name}
          </Tag>
        );
      });
    }
    const layout = {
      labelCol: { span: 24 },
      wrapperCol: { sm: { span: 24 } },
    };
    return (
      <div className={"ProjectEditScreen"}>
        <Row>
          <Col
            xs={{ offset: 17, span: 7 }}
            sm={{ offset: 19, span: 5 }}
            lg={{ offset: 21, span: 3 }}
          >
            <Popconfirm
              // @ts-expect-error TS2532
              title={this.props.intl.formatMessage({
                id: "screen.label.project_delete_confirm",
              })}
              onConfirm={this.confirm}
              onCancel={this.cancel}
              // @ts-expect-error TS2532
              okText={this.props.intl.formatMessage({
                id: "screen.label.yes",
              })}
              // @ts-expect-error TS2532
              cancelText={this.props.intl.formatMessage({
                id: "screen.label.no",
              })}
            >
              <InsuPrimaryButton loading={this.props.loading}>
                {/*
                 // @ts-expect-error TS2532 */}
                {this.props.intl.formatMessage({
                  id: "screen.label.delete_project",
                })}
              </InsuPrimaryButton>
            </Popconfirm>
          </Col>
        </Row>

        <Card
          style={{
            marginTop: "1vh",
          }}
        >
          <ProjectCreateRoot>
            <Form {...layout} style={{ maxWidth: 600 }}>
              <div className={"alpha-cloud-form-group"}>
                <Form.Item
                  // @ts-expect-error TS2532
                  label={`${this.props.intl.formatMessage({
                    id: "screen.label.project_name",
                  })} *`}
                >
                  <Input
                    className={"project-name"}
                    required
                    onChange={(event) =>
                      this.handleChange("name", event.target.value)
                    }
                    value={this.state.name}
                    size="large"
                    // @ts-expect-error TS2532
                    placeholder={this.props.intl.formatMessage({
                      id: "screen.label.please_enter",
                    })}
                  />
                </Form.Item>
                <br />
                <Form.Item
                  label={<FormattedMessage id={`screen.project.camera`} />}
                >
                  <ProjectImageEdit
                    // @ts-expect-error TS2322
                    project={this.props.projectPayload}
                    url={this.state.icon}
                    onFileChanged={this.onBeforeUpload}
                  />
                </Form.Item>
                <br />
                <Form.Item
                  // @ts-expect-error TS2532
                  label={`${this.props.intl.formatMessage({
                    id: "screen.label.explanatory_text",
                  })} *`}
                >
                  <Input.TextArea
                    value={this.state.description}
                    rows={5}
                    onChange={(e) =>
                      this.handleChange("description", e.target.value)
                    }
                    // @ts-expect-error TS2532
                    placeholder={this.props.intl.formatMessage({
                      id: "screen.label.please_enter",
                    })}
                  />
                </Form.Item>
              </div>

              <div className={"alpha-cloud-form-group"}>
                <Form.Item
                  // @ts-expect-error TS2532
                  label={this.props.intl.formatMessage({
                    id: "screen.label.stage",
                  })}
                >
                  {stageNode}
                </Form.Item>
                <br />
                <Form.Item
                  // @ts-expect-error TS2532
                  label={this.props.intl.formatMessage({
                    id: "screen.label.project_label",
                  })}
                >
                  {tagNode}
                </Form.Item>
              </div>

              <div className={"alpha-cloud-form-group"}>
                {this._renderBatchMilestones()}
                <Form.Item
                  label={this._renderTitleWithPopover(
                    // @ts-expect-error TS2532
                    this.props.intl.formatMessage({
                      id: "screen.label.milestone_expiration_date",
                    }),
                    "screens.message.milestonedate.popover",
                  )}
                >
                  {this._renderMilestoneDateOption()}

                  <DatePicker
                    disabled={
                      this.state.defaultMilestoneDate !== MILESTONE_CUSTOM_DATE
                    }
                    // @ts-expect-error TS2769
                    value={
                      this.state.milestoneDate
                        ? moment(this.state.milestoneDate).local()
                        : ""
                    }
                    onChange={(date) => {
                      if (
                        this.state.defaultMilestoneDate == MILESTONE_CUSTOM_DATE
                      ) {
                        this.handleChange("milestoneDate", date);
                      }
                    }}
                    // @ts-expect-error TS2532
                    placeholder={this.props.intl.formatMessage({
                      id: "screen.label.no_date_specified",
                    })}
                  />
                </Form.Item>
              </div>

              <div className={"alpha-cloud-form-group"}>
                <Form.Item
                  label={this._renderTitleWithPopover(
                    // @ts-expect-error TS2532
                    this.props.intl.formatMessage({
                      id: "screen.label.reminder_name",
                    }),
                    "screens.message.remindername.popover",
                  )}
                >
                  <Input
                    onChange={(event) =>
                      this.handleChange("reminder", event.target.value)
                    }
                    value={this.state.reminder}
                    size="large"
                    // @ts-expect-error TS2532
                    placeholder={this.props.intl.formatMessage({
                      id: "screen.label.reminder_name",
                    })}
                  />
                </Form.Item>
                <Form.Item
                  label={this._renderTitleWithPopover(
                    // @ts-expect-error TS2532
                    this.props.intl.formatMessage({
                      id: "screen.label.reminder_deadline",
                    }),
                    "screens.message.reminderdate.popover",
                  )}
                >
                  <DatePicker
                    value={
                      this.state.reminderDate
                        ? moment(this.state.reminderDate).local()
                        : null
                    }
                    onChange={(date) => {
                      this.handleChange("reminderDate", date);
                    }}
                    // @ts-expect-error TS2532
                    placeholder={this.props.intl.formatMessage({
                      id: "screen.label.no_date_specified",
                    })}
                  />
                </Form.Item>
              </div>

              <ProjectLinksComponent
                // @ts-expect-error TS7006
                onUpdateLinks={(links) => {
                  this.setState({ links });
                }}
                project={this.props.projectPayload}
              />
              <Form.Item>
                <Checkbox
                  checked={this.state.isPrivate}
                  onChange={(e) =>
                    this.handleChange("isPrivate", e.target.checked)
                  }
                >
                  {this._renderTitleWithPopover(
                    // @ts-expect-error TS2532
                    this.props.intl.formatMessage({
                      id: "screen.label.enable_private_mode",
                    }),
                    "screens.message.private.popover",
                  )}
                </Checkbox>
              </Form.Item>
              <br />

              <Form.Item>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <InsuAcceptButton
                    onClick={this.handleSubmit}
                    className={"project-edit-btn"}
                    loading={this.props.loading}
                    style={{
                      backgroundColor: "#1F86E9",
                      color: "#fff",
                      marginRight: "0.4vw",
                    }}
                  >
                    <FormattedMessage id="screen.label.save" />
                  </InsuAcceptButton>
                  <InsuRejectButton
                    // @ts-expect-error TS2339
                    onClick={this.props.history.goBack}
                    loading={this.props.loading}
                    style={{
                      backgroundColor: "#B5B5B5",
                      color: "#fff",
                    }}
                  >
                    {/*
                     // @ts-expect-error TS2532 */}
                    {this.props.intl.formatMessage({
                      id: "screen.label.cancel",
                    })}
                  </InsuRejectButton>
                </div>
              </Form.Item>
            </Form>
          </ProjectCreateRoot>
        </Card>
      </div>
    );
  }
  _renderMilestoneDateOption = () => {
    const milestones =
      // @ts-expect-error TS2339
      this.state.batch && Array.isArray(this.state.batch.milestones)
        ? // @ts-expect-error TS2339
          this.state.batch.milestones
        : [];
    return (
      <div
        style={{
          marginBottom: "20px",
        }}
      >
        <Radio.Group
          options={defaultMilestoneDateOptions}
          onChange={(e) => {
            if (e.target.value === MILESTONE_SPECIFIC_DATE) {
              if (this.state.milestone != "") {
                const milestonePair = milestones.find(
                  // @ts-expect-error TS7006
                  (m) => m.name === this.state.milestone,
                );
                if (milestonePair) {
                  this.handleChange("milestoneDate", milestonePair.date);
                }
              }
            } else if (e.target.value === MILESTONE_CUSTOM_DATE) {
              this.handleChange(
                "milestoneDate",
                // @ts-expect-error TS2532
                this.props.projectPayload.milestoneDate,
              );
            } else {
              this.handleChange("milestoneDate", null);
            }
            this.handleChange("defaultMilestoneDate", e.target.value);
          }}
          value={this.state.defaultMilestoneDate}
        />
      </div>
    );
  };
  // @ts-expect-error TS7006
  _renderTitleWithPopover = (title, id) => {
    return (
      <span>
        {title}
        <Popover
          content={
            <div style={{ maxWidth: 300 }}>
              {/*
               // @ts-expect-error TS2532 */}
              {this.props.intl.formatMessage({ id })}
            </div>
          }
        >
          <img
            src={InfoIcon}
            style={{
              marginLeft: "0.2vw",
            }}
          />
        </Popover>
      </span>
    );
  };
  _renderBatchMilestones = () => {
    const milestones =
      // @ts-expect-error TS2339
      this.state.batch && Array.isArray(this.state.batch.milestones)
        ? // @ts-expect-error TS2339
          this.state.batch.milestones
        : [];
    if (Array.isArray(milestones)) {
      return (
        <Form.Item
          label={this._renderTitleWithPopover(
            // @ts-expect-error TS2532
            this.props.intl.formatMessage({
              id: "screen.label.milestone_name",
            }),
            "screens.message.milestonename.popover",
          )}
        >
          <Select
            // @ts-expect-error TS2532
            defaultValue={this.props.projectPayload.milestone}
            onChange={(value) => {
              this.handleChange("milestone", value);
              if (this.state.defaultMilestoneDate === MILESTONE_SPECIFIC_DATE) {
                const milestonePair = milestones.find((m) => m.name === value);
                if (milestonePair) {
                  this.handleChange("milestoneDate", milestonePair.date);
                }
              }
            }}
          >
            {milestones.map((milestone, index) => {
              return (
                <Option key={index} value={milestone.name}>
                  {milestone.name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
      );
    }
  };
  // @ts-expect-error TS7006
  confirm = (e) => {
    // @ts-expect-error TS2532
    const message = this.props.intl.formatMessage({
      id: "screen.label.project_has_been_removed",
    });
    this.props.dispatchDeleteProject(this.getProjectId(), message).then(() => {
      this.props.dispatchGetMyProjects({
        // @ts-expect-error TS2322
        userId: this.props.context.user.id,
        page: 0,
      });
    });
  };
  // @ts-expect-error TS7006
  cancel = (e) => {
    message.error("Project deletion cancelled");
  };
}
// @ts-expect-error TS7006
const mapDispatchToProps = (dispatch) => ({
  // @ts-expect-error TS7006
  dispatchCreateProject: (data, message) =>
    new Promise((resolve, reject) =>
      dispatch(
        // @ts-expect-error TS2554
        ProjectActions.projectCreateRequest(data, message, resolve, reject),
      ),
    ),
  // @ts-expect-error TS7006
  dispatchGetProjectDetail: (id) =>
    // @ts-expect-error TS2554
    dispatch(ProjectActions.projectGetRequest(id)),
  // @ts-expect-error TS7006
  dispatchDeleteProject: (id, message) =>
    new Promise((resolve, reject) =>
      dispatch(
        // @ts-expect-error TS2554
        ProjectActions.projectDeleteRequest(id, message, resolve, reject),
      ),
    ),
  // @ts-expect-error TS7006
  dispatchGetMyProjects: (data) =>
    // @ts-expect-error TS2554
    dispatch(ProjectActions.projectCurrentMemberGetsRequest(data)),
});
// @ts-expect-error TS7006
const mapStateToProps = (state) => ({
  loading: state.projects.fetching,
  projectPayload: state.projects.projectPayload,
  defaultBatch: state.batch.defaultBatch,
});
export default injectIntl(
  // @ts-expect-error TS2769
  connect(mapStateToProps, mapDispatchToProps)(ProjectEditScreen),
);
