import { Modal, Select } from "antd";
import React from "react";
import { useIntl } from "react-intl";

import { Props } from "../../../Screens/project/types/Props";
import { ButtonCV } from "../../atoms";
import { InsuRejectButton } from "../../atoms/buttons/shared";
import {
  BatchSelectComponent,
  BatchSelectModalRoot,
} from "../../molecules/project/BatchSelectModalRoot";

const { Option } = Select;

function BatchSelectModal(props: Props) {
  const { batches, showModal, onCloseModal, onSelectBatch } = props;
  const intl = useIntl();
  const [batchId, setBatchId] = React.useState(null);
  React.useEffect(() => {
    if (batches && batches.length > 0) {
      const filteredBatches = batches.filter((a) => a.status === 1);
      if (filteredBatches.length > 0) {
        // @ts-expect-error TS2345
        setBatchId(filteredBatches[0].id);
      }
    }
  }, [batches]);
  return (
    <Modal
      width={"42vw"}
      bodyStyle={{
        paddingLeft: "6%",
        paddingRight: "6%",
      }}
      centered
      title={intl.formatMessage({
        id: "screen.label.select_holding_times",
      })}
      visible={showModal}
      footer={null}
      onCancel={() => {
        onCloseModal();
      }}
    >
      <BatchSelectModalRoot>
        <BatchSelectComponent>
          <p>
            {intl.formatMessage({
              id: "screen.label.batch_select_des_1",
            })}
            <br />
            {intl.formatMessage({
              id: "screen.label.batch_select_des_2",
            })}
          </p>
          <Select
            style={{ width: "100%" }}
            value={batchId ? batchId : null}
            onChange={setBatchId}
          >
            {batches &&
              batches
                .filter((a) => a.status === 1)
                .map((batch) => {
                  return (
                    <Option value={batch.id} key={batch.id}>
                      {batch.name}
                    </Option>
                  );
                })}
          </Select>
          <br />
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <InsuRejectButton
              style={{
                marginLeft: "0.1vw",
                marginRight: "0.1vw",
              }}
              size="middle"
              onClick={onCloseModal}
            >
              {intl.formatMessage({
                id: "screen.label.cancel",
              })}
            </InsuRejectButton>
            <ButtonCV
              style={{
                marginLeft: "0.1vw",
                marginRight: "0.1vw",
              }}
              size="middle"
              onClick={() => {
                if (batchId && batchId !== "") {
                  const batch = batches.find((a) => a.id === batchId);
                  onSelectBatch(batch?.id);
                }
                onCloseModal();
              }}
            >
              {intl.formatMessage({
                id: "screen.label.creating",
              })}
            </ButtonCV>
          </div>
        </BatchSelectComponent>
      </BatchSelectModalRoot>
    </Modal>
  );
}
export default BatchSelectModal;
