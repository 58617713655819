import { Col, Row, Tooltip } from "antd";
import moment from "moment";
import { useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import EditOutlineIcon from "@/assets/icons/edit_outline.svg";
import { InsuPrimaryButton } from "@/Components/atoms/buttons/shared";
import { ContentContext } from "@/Provider";
import { getProjectAvatar } from "@/utils/common";

import { ToolTipForPrivateProject } from "../ToolTipForPrivateProject";

export const ProjectCardTitle = () => {
  const intl = useIntl();
  const history = useHistory();
  const { user } = useContext(ContentContext);
  // @ts-expect-error TS2339
  const project = useSelector((state) => state.projects.projectPayload);
  const isOwner =
    project.isProjectOwner || (user.type === "admin" && user.isSuper);

  const updatedAt = moment(project.updatedAt).local().format("YYYY/MM/DD");

  return (
    <Row align="middle" justify="space-between">
      <Col>
        <Row align="middle">
          <Col>{getProjectAvatar(project)}</Col>
          <Col
            style={{
              maxWidth: "30vw",
              wordBreak: "break-word",
            }}
          >
            <span
              style={{
                fontStyle: "normal",
                fontWeight: "bold",
                fontSize: "1rem",
              }}
            >
              {project.name}
            </span>
          </Col>
          <Col style={{ marginLeft: 16 }}>
            {project.isPrivate ? (
              <ToolTipForPrivateProject />
            ) : (
              <Tooltip
                title={intl.formatMessage({
                  id: `screens.message.project.public`,
                })}
              >
                <div className="status-title-public">
                  <FormattedMessage id="screens.title.project.public" />
                </div>
              </Tooltip>
            )}
          </Col>
        </Row>
      </Col>
      <Col>
        <Row align="middle" gutter={16}>
          <Col>
            <span
              style={{
                fontSize: "0.8rem",
                fontWeight: "normal",
                fontStyle: "normal",
                lineHeight: "100%",
              }}
            >{`${intl.formatMessage({
              id: "screen.label.last_updated",
            })}：${updatedAt}`}</span>
          </Col>
          {isOwner && (
            <Col>
              <InsuPrimaryButton
                type="ghost"
                onClick={() => {
                  history.push("/dashboard/projects.edit/" + project.uuid);
                }}
                style={{
                  borderColor: "white",
                  color: "white",
                }}
              >
                <Row align="middle">
                  <FormattedMessage id={"screen.project_top.edit_project"} />
                  <img
                    alt=""
                    src={EditOutlineIcon}
                    style={{ marginLeft: "6px" }}
                  />
                </Row>
              </InsuPrimaryButton>
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );
};
