import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { FC, useContext } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import {
  ProjectTopAcceptButton,
  ProjectTopGhostButton,
} from "@/Components/molecules/project/ProjectTopScreen";
import { useParamId } from "@/hooks/useParamId";
import { ContentContext } from "@/Provider";
import { ApiRequestData } from "@/Redux/ApiRedux";
import ProjectActions from "@/Redux/ProjectRedux";
import { ReduxState } from "@/Redux/types";
import { endpoints, parseEndpoint } from "@/Services/endpoints";
import { getObjUUID } from "@/utils/common";

import { useUserTeamStatus } from "../../hooks/useUserTeamStatus";

export const ProjectInvitationAcceptBox: FC = () => {
  const dispatch = useDispatch();
  const { user, apiRequest } = useContext(ContentContext);

  const project = useSelector(
    (state: ReduxState) => state.projects.projectPayload,
  );

  const { isWaitingCurrentUserApproval } = useUserTeamStatus({
    user,
    team: [...project.Team],
    project,
  });

  const projectId = useParamId();

  const dispatchGetProjectDetail = () =>
    dispatch(
      ProjectActions.projectGetRequest(
        // @ts-expect-error TS2554
        projectId,
      ),
    );

  const acceptToJoinProject = () => {
    const acceptToJoinProjectRequest: ApiRequestData = {
      method: "putRequest",
      url: parseEndpoint(endpoints.updateTeamMember, {
        uid: projectId,
        userId: getObjUUID(user),
      }),
      data: {
        userStatus: 1,
      },
    };
    apiRequest(acceptToJoinProjectRequest, () => dispatchGetProjectDetail());
  };

  const rejectToJoinProject = () => {
    const rejectToJoinProjectRequest: ApiRequestData = {
      method: "putRequest",
      url: parseEndpoint(endpoints.updateTeamMember, {
        uid: projectId,
        userId: getObjUUID(user),
      }),
      data: {
        userStatus: -1,
      },
    };
    apiRequest(rejectToJoinProjectRequest, () => dispatchGetProjectDetail());
  };

  if (!isWaitingCurrentUserApproval) return null;
  return (
    <div className="invitation-title" style={{ marginBottom: 24 }}>
      <FormattedMessage id="screen.label.invited_to_this_project" />
      <ProjectTopAcceptButton
        onClick={() => acceptToJoinProject()}
        icon={<CheckOutlined />}
      >
        <FormattedMessage id="screen.label.approve_invitation" />
      </ProjectTopAcceptButton>
      <ProjectTopGhostButton
        onClick={() => rejectToJoinProject()}
        icon={<CloseOutlined />}
      >
        <FormattedMessage id="screen.label.reject_invitation" />
      </ProjectTopGhostButton>
    </div>
  );
};
