import { useCallback, useState } from "react";

// @ts-expect-error TS2307
import { ExaminationPhase } from "../../../examinationPhase/types/ExaminationPhase";

export const useSelectedExaminationPhaseId = (
  examinationPhases?: ExaminationPhase[],
): [
  number | undefined,
  {
    getAndSetSelectedExaminationPhaseId: (
      examinationPhases: ExaminationPhase[] | undefined,
    ) => void;
    setSelectedExaminationPhaseId: React.Dispatch<
      React.SetStateAction<number | undefined>
    >;
  },
] => {
  const getCurrentExaminationPhaseId = useCallback(
    (examinationPhases?: ExaminationPhase[]): number | undefined => {
      if (!examinationPhases || examinationPhases?.length <= 0) return;
      const activeId = examinationPhases.find(
        (examinationPhase) => examinationPhase.isActive,
      )?.id;
      const firstId = examinationPhases[0]?.id;
      return activeId ?? firstId;
    },
    [],
  );

  const [selectedExaminationPhaseId, setSelectedExaminationPhaseId] = useState<
    number | undefined
  >(getCurrentExaminationPhaseId(examinationPhases));

  const getAndSetSelectedExaminationPhaseId = useCallback(
    (examinationPhases) => {
      setSelectedExaminationPhaseId(
        getCurrentExaminationPhaseId(examinationPhases),
      );
    },
    [getCurrentExaminationPhaseId],
  );

  return [
    selectedExaminationPhaseId,
    { getAndSetSelectedExaminationPhaseId, setSelectedExaminationPhaseId },
  ];
};
