import FileSaver from "file-saver";

import { endpoints, parseEndpoint } from "../../../Services/endpoints";

// @ts-expect-error TS7006
const useProjectArchiveDetailsCard = (props) => {
  // @ts-expect-error TS7006
  const fileDownloaded = (fileId, fileDetail = {}) => {
    const data = {
      method: "postRequest",
      url: parseEndpoint(endpoints.fileDownload, {
        fileId,
      }),
      data: fileDetail,
    };
    // @ts-expect-error TS7031
    props.dispatchApiRequest(data, ({ data }) => {});
  };
  // @ts-expect-error TS7006
  const saveFile = (href, fileName, extension?, fileId?) => {
    const xhr = new XMLHttpRequest();
    xhr.withCredentials = true;
    xhr.open("GET", href, true);
    xhr.responseType = "arraybuffer";
    xhr.onload = (e) => {
      // @ts-expect-error TS18047
      const arrayBufferView = new Uint8Array(e.currentTarget.response);
      const blob = new Blob([arrayBufferView]);
      fileDownloaded(fileId ? fileId : -1, {
        fileName,
        fileSize: blob.size,
      });
      FileSaver.saveAs(blob, fileName);
    };
    xhr.send();
  };

  return {
    saveFile,
  };
};

export default useProjectArchiveDetailsCard;
