import { Line } from "../../atoms";

const VideoLineDivider = () => (
  <div
    style={{
      marginTop: "5vh",
      marginBottom: "5vh",
    }}
  >
    <Line />
  </div>
);

export default VideoLineDivider;
