import { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";

type ProjectTastProps = {
  props: any;
  projectId: string;
  handleOk: () => void;
  handleCancel: (init: () => void) => void;
};

const useProjectTaskCreateScreen = (props: ProjectTastProps) => {
  const intl = useIntl();
  const [state, customSetState] = useState(() => {
    return {
      title: "",
      description: "",
      acceptedTeamPage: 1,
      icon: "",
      incharge: [],
      status: "PENDING",
      priority: 0,
      timelimit: null,
    };
  });

  const setStateCallbackRef = useRef(() => {});
  useEffect(() => {
    const callBack = setStateCallbackRef.current;
    callBack();
    setStateCallbackRef.current = () => {};
  }, [state]);
  const setState = (data: any, callback = () => {}) => {
    setStateCallbackRef.current = callback;
    customSetState((previousState) => {
      return {
        ...previousState,
        ...data,
      };
    });
  };

  useEffect(() => {
    fetchAcceptedTeam(state.acceptedTeamPage);
  }, []);

  // @ts-expect-error TS7006
  const fetchAcceptedTeam = (page) => {
    setState({
      acceptedTeamPage: page,
    });
    // @ts-expect-error TS2339
    props.dispatchFetchUsers({
      page: page - 1,
      id: props.projectId,
    });
  };

  // @ts-expect-error TS7006
  const handleChange = (key, value) => {
    setState({ [key]: value });
  };
  // @ts-expect-error TS7006
  const onChange = (date) => {
    setState({
      timelimit: date,
    });
  };
  // @ts-expect-error TS7006
  const handleSubmit = (e) => {
    e.preventDefault();
    // @ts-expect-error TS2339
    props.dispatchCreateProjectTask({
      title: state.title,
      description: state.description,
      icon: state.icon,
      incharge: state.incharge,
      timelimit: state.timelimit,
      status: state.status,
      priority: state.priority,
      projectId: props.projectId,
    });
    handleOk();
  };
  const initState = () => {
    setState({
      title: "",
      description: "",
      acceptedTeamPage: 1,
      icon: "",
      incharge: [],
      status: "PENDING",
      priority: 0,
      timelimit: null,
    });
  };
  const handleOk = () => {
    setTimeout(() => {
      props.handleOk();
      initState();
    }, 800);
  };
  const handleCancel = () => {
    props.handleCancel(initState);
    initState();
  };
  return {
    handleSubmit,
    handleChange,
    intl,
    onChange,
    handleCancel,
    state,
  };
};

export default useProjectTaskCreateScreen;
