import { ApisauceInstance } from "apisauce";
import { useCallback } from "react";
import useSWR from "swr";
import { useSWRConfig } from "swr";

import { JsendError } from "../../../types/JSendObject";
import { api } from "../../Saga";
import { useSWRCache } from "../../utils/useSWRCache";
import { endpoints, parseEndpoint } from "../endpoints";
import { isFailedResponse } from "../isFailedResponse";
import {
  EventDeleteResponse,
  EventJoinResponse,
  EventUpsertResponse,
  GetAllEventsResponse,
  JoinEventData,
  JoinEventQuery,
  LeaveEventQuery,
  ToggleAcceptMemberData,
  ToggleAcceptMemberResponse,
  UseEventRequestProps,
} from "./type";

type AxiosRequestConfig = Parameters<ApisauceInstance["any"]>[0];

export const useEventRequest = ({
  page = 0,
  limit = process.env.REACT_APP_PAGE_SIZE ?? "10",
  includeDeleted = true,
}: UseEventRequestProps = {}) => {
  const { cache } = useSWRConfig();
  const { matchedFilter } = useSWRCache();
  const url = parseEndpoint(endpoints.events, { page, limit });
  const addtionalUrl = includeDeleted ? "&includeDeleted=true" : "";
  const getAllEventsUrl = url + addtionalUrl;

  const clearEventsCache = () => {
    const regex =
      /events\?page=[0-9]+&limit=[0-9]+&includeDeleted=(true|false)/;
    const matchedKeys = matchedFilter(regex);
    matchedKeys?.forEach((key) => cache.delete(key));
  };

  const fetcher = useCallback(async () => {
    const response = await api.getRequest<GetAllEventsResponse, JsendError>(
      getAllEventsUrl,
    );
    if (!response.ok) throw response;

    return response.data ?? { data: { rows: [], count: 0 } };
  }, [getAllEventsUrl]);

  const {
    data,
    error,
    mutate: allEventsMutate,
    isValidating,
  } = useSWR<GetAllEventsResponse, JsendError>(getAllEventsUrl, fetcher, {
    revalidateIfStale: true,
  });

  const getAllEvents = () => {
    return {
      events: data?.data.rows,
      count: data?.data.count,
      error,
      isValidating,
    };
  };

  const createEvent = async (data: FormData, config?: AxiosRequestConfig) => {
    const url = parseEndpoint(endpoints.createEvent);
    const response = await api.postRequest<
      FormData,
      EventUpsertResponse,
      JsendError
    >(url, data, config);
    // @ts-expect-error TS2345
    const isFailed = isFailedResponse(response);
    if (!isFailed) {
      clearEventsCache();
      allEventsMutate();
    }

    return { response, isFailed };
  };

  const deleteEvent = async (
    id: number,
    params?: {},
    config?: AxiosRequestConfig,
  ) => {
    const url = parseEndpoint(endpoints.singleEvent, { id });
    const response = await api.deleteRequest<EventDeleteResponse, JsendError>(
      url,
      params,
      config,
    );
    // @ts-expect-error TS2345
    const isFailed = isFailedResponse(response);
    if (!isFailed) allEventsMutate();

    return { response, isFailed };
  };

  const updateEvent = async (
    id: string,
    data: FormData,
    config?: AxiosRequestConfig,
  ) => {
    const url = parseEndpoint(endpoints.singleEvent, { id });
    const response = await api.putRequest<
      FormData,
      EventUpsertResponse,
      JsendError
    >(url, data, config);
    // @ts-expect-error TS2345
    const isFailed = isFailedResponse(response);
    if (!isFailed) allEventsMutate();

    return { response, isFailed };
  };

  const leaveEevent = async (
    query: LeaveEventQuery,
    params?: {},
    config?: AxiosRequestConfig,
  ) => {
    const { eventId, userId } = query;
    const url = parseEndpoint(endpoints.findEventMember, {
      eventId,
      userId,
    });
    const response = await api.deleteRequest<EventDeleteResponse, JsendError>(
      url,
      params,
      config,
    );
    // @ts-expect-error TS2345
    const isFailed = isFailedResponse(response);
    if (!isFailed) allEventsMutate();

    return { response, isFailed };
  };

  const joinEvent = async (
    query: JoinEventQuery,
    data: JoinEventData,
    config?: AxiosRequestConfig,
  ) => {
    const url = parseEndpoint(endpoints.getEventUsers, {
      data: {
        eventId: query.eventId,
      },
      queryParams: query.queryParams,
    });
    const response = await api.postRequest<
      JoinEventData,
      EventJoinResponse,
      JsendError
    >(url, data, config);
    // @ts-expect-error TS2345
    const isFailed = isFailedResponse(response);
    allEventsMutate();

    return { response, isFailed };
  };

  const toggleAcceptMember = async (
    id: number,
    data: ToggleAcceptMemberData,
  ) => {
    const url = parseEndpoint(endpoints.toggleAcceptanceStatus, { id });
    const response = await api.putRequest<
      ToggleAcceptMemberData,
      ToggleAcceptMemberResponse,
      JsendError
    >(url, data);
    // @ts-expect-error TS2345
    const isFailed = isFailedResponse(response);
    if (!isFailed) allEventsMutate();

    return { response, isFailed };
  };

  return {
    getAllEvents,
    createEvent,
    deleteEvent,
    updateEvent,
    leaveEevent,
    joinEvent,
    toggleAcceptMember,
  };
};
