import { LikeOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import { useIntl } from "react-intl";

import type { News } from "./type";
type StateProps = {
  comment: News;
  user: Object;
  disabled: boolean;
  onLike: (id: string) => void;
};
type OwnProps = {};
type Props = OwnProps & StateProps;
function NewsLike(props: Props) {
  const { comment, user, disabled, onLike } = props;
  const intl = useIntl();
  // @ts-expect-error TS2339
  const isLike = comment.User.id === user.id && comment.numberOfLikes > 0;
  const color = disabled ? "#C4C4C4" : isLike ? "#00BF76" : "black";
  return (
    // @ts-expect-error TS2322
    <Row key={`${comment.id}-like`} align="middle" justify="flex-start">
      <Col>
        <div
          className={`like-btn ${disabled ? "disabled" : "enabled"}`}
          onClick={() => {
            if (!disabled) {
              onLike(comment.id);
            }
          }}
        >
          <LikeOutlined
            style={{
              color: color,
            }}
          />
          <span style={{ marginRight: 10, marginLeft: 10, color }}>
            {intl.formatMessage({ id: "screen.label.like" })}
          </span>
          <span style={{ color }}>{comment.numberOfLikes}</span>
        </div>
      </Col>
    </Row>
  );
}
export default NewsLike;
