import { Action } from "@reduxjs/toolkit";
import { createActions, createReducer } from "reduxsauce";
import Immutable from "seamless-immutable";

import { ToAction, ToActionType } from "./types";

/* ------------- Types and Action Creators ------------- */

type ActionCreators = {
  batchExaminationGetsRequest: () => Action<"BATCH_EXAMINATION_GETS_REQUEST">;
  batchExaminationGetsSuccess: () => Action<"BATCH_EXAMINATION_GETS_SUCCESS">;
};

type BatchExamAction = ToAction<ActionCreators>;
type BatchExamActionType = ToActionType<BatchExamAction>;

const { Types, Creators } = createActions<BatchExamActionType, ActionCreators>({
  batchExaminationGetsRequest: ["data"],
  batchExaminationGetsSuccess: ["payload"],
});

export const BatchExaminationTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
type BatchExamState = {
  batchExaminationSubmissionCategory: unknown[];
  fetching: boolean;
};

export const INITIAL_STATE = Immutable<BatchExamState>({
  batchExaminationSubmissionCategory: [],
  fetching: false,
});

type ImmBatchExamState = typeof INITIAL_STATE;

/* ------------- Reducers ------------- */

const batchExaminationGetsRequest = (state: ImmBatchExamState) =>
  state.merge({ fetching: true });

const batchExaminationGetsSuccess = (
  state: ImmBatchExamState,
  { payload }: { payload: { batchExaminationSubmissionCategory: unknown[] } },
) => {
  const { batchExaminationSubmissionCategory } = payload;
  return state.merge({
    fetching: false,
    // @ts-expect-error TS2322
    batchExaminationSubmissionCategory,
  });
};

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer<ImmBatchExamState, BatchExamAction>(
  INITIAL_STATE,
  {
    [Types.BATCH_EXAMINATION_GETS_REQUEST]: batchExaminationGetsRequest,
    [Types.BATCH_EXAMINATION_GETS_SUCCESS]: batchExaminationGetsSuccess,
  },
);
