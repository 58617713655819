import { FC, useCallback, useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { ContentContext } from "@/Provider";
import { endpoints, parseEndpoint } from "@/Services/endpoints";
import { TagList } from "@/shared/tags/components/TagList";

import { Department } from "../../../../../types/Department";

type UserDepartmentTags = {
  //
};

export const UserDepartmentTags: FC<UserDepartmentTags> = () => {
  const intl = useIntl();
  const context = useContext(ContentContext);

  const [departments, setDepartments] = useState<Department[]>([]);

  const getDepartment = useCallback(() => {
    const departmentRequest = {
      method: "getRequest",
      url: parseEndpoint(endpoints.departments),
      data: {},
    };
    // @ts-expect-error TS2345
    context.apiRequest(departmentRequest, ({ data }) => {
      // @ts-expect-error TS7006
      setDepartments(data);
    });
    // dispatch(CompanyActions.companyGetRequest())
  }, [context, setDepartments]);

  useEffect(() => {
    getDepartment();
  }, [getDepartment]);

  const handleCreate = (departmentName: string) => {
    const isDuplicate = departments.some(
      (department) => department.name === departmentName,
    );
    const isEmpty = departmentName.trim().length === 0;

    if (isDuplicate || isEmpty) return;

    const departmentRequest = {
      method: "postRequest",
      url: parseEndpoint(endpoints.departments),
      data: {
        name: departmentName,
      },
    };
    // @ts-expect-error TS2345
    context.apiRequest(departmentRequest, () => {
      getDepartment();
    });
  };

  const handleEdit = (oldDepartment: string, newDepartment: string) => {
    const isDuplicateTag = departments.some(
      (department) => department.name === newDepartment,
    );
    const isEmpty = newDepartment.trim().length === 0;

    if (isDuplicateTag || isEmpty) return;

    const departmentId = departments.find(
      (department) => department.name === oldDepartment,
    )?.id;

    const requestBuilder = {
      method: "putRequest",
      url: parseEndpoint(endpoints.singleDepartment, {
        // @ts-expect-error TS2322
        departmentId,
      }),
      data: {
        name: newDepartment,
      },
    };
    // @ts-expect-error TS2345
    context.apiRequest(requestBuilder, () => {
      getDepartment();
    });
  };

  const handleDeleted = (departmentName: string) => {
    const departmentId = departments.find(
      (department) => department.name === departmentName,
    )?.id;

    const departmentRequest = {
      method: "deleteRequest",
      url: parseEndpoint(endpoints.singleDepartment, {
        // @ts-expect-error TS2322
        departmentId,
      }),
      data: {},
    };
    // @ts-expect-error TS2345
    context.apiRequest(departmentRequest, () => {
      getDepartment();
    });
  };

  return (
    <TagList
      tags={departments.map((department) => department.name)}
      createLabel={intl.formatMessage({
        id: "screen.label.department1",
      })}
      onCreated={handleCreate}
      onEdited={handleEdit}
      onDeleted={handleDeleted}
    />
  );
};
