import { CrossIconButton } from "@/shared/buttons/components/CrossIconButton";

import styles from "./styles.module.scss";
import { ImagePreviewProps } from "./type";

export const ImagePreview = ({ image, removeFile }: ImagePreviewProps) => {
  return (
    <div className={`${styles.image_preview_container}`}>
      <img
        className={styles.image_preview}
        src={image as string}
        alt="chosen"
      />
      <CrossIconButton
        className={styles.image_preview_delete_button}
        alt="delete-attached-file-button"
        onClick={removeFile}
      />
    </div>
  );
};
