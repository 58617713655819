import { DateTime } from "luxon";

export const formatDateTimeRange = (
  date: string,
  beginsAt: string,
  endsAt: string,
  locale: string,
) => {
  const dateStr = DateTime.fromFormat(date, "EEE MMM dd yyyy")
    .setLocale(locale)
    .toFormat("M/d(EEE)");
  const beginsAtStr = DateTime.fromISO(beginsAt)
    .setLocale(locale)
    .toFormat("H:mm");
  const endsAtStr = DateTime.fromISO(endsAt).setLocale(locale).toFormat("H:mm");

  return `${dateStr} ${beginsAtStr} ~ ${endsAtStr}`;
};
