import moment from "moment";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { IconFlag } from "@/Components/atoms";
import {
  MileStoneComponent,
  MileStoneLocalDate,
} from "@/Components/molecules/project/ProjectTopScreen";
import { ReduxState } from "@/Redux/types";
import { isEmptyString } from "@/utils/common";

export const ProjectMileStoneLabel = () => {
  const intl = useIntl();
  const project = useSelector(
    (state: ReduxState) => state.projects.projectPayload,
  );

  const milestone_title = project.milestone;
  const milestoneDate = project.milestoneDate;
  if (!isEmptyString(milestone_title) || milestoneDate) {
    const milestoneLocalDate = milestoneDate
      ? `${moment(
          // @ts-expect-error TS2769
          milestoneDate,
        )
          .local()
          .format("YYYY/MM/DD")} (${intl.formatMessage({
          id: `screens.title.day_week${moment(
            // @ts-expect-error TS2769
            milestoneDate,
          ).day()}`,
        })})`
      : intl.formatMessage({
          id: "screen.label.no_date_specified",
        });
    return (
      <MileStoneComponent>
        {IconFlag}
        <MileStoneLocalDate> {milestoneLocalDate}</MileStoneLocalDate>
        <span style={{ fontWeight: "bold" }}>
          {isEmptyString(milestone_title) ? "" : milestone_title}
        </span>
      </MileStoneComponent>
    );
  }
  return null;
};
