import { Card, Col, Form } from "antd";
import { FormattedMessage } from "react-intl";

import { ButtonCV, ButtonSwitch } from "../../Components/atoms";
import { DraftEditor, nodeConfig } from "../../Components/molecules";
import { CustomTableToolbar } from "../../Components/organisms";
import { convertYVLinksToEmbed } from "../../utils/common";
import useProgramInfoViewQAMgtScreen from "./hooks/useProgramInfoViewQAMgtScreen";

function ProgramInfoViewQAMgtScreen() {
  const {
    intl,
    authUser,
    companyId,
    editorState,
    setEditorState,
    programInfoStatus,
    setProgramInfoStatus,
    getMarkUpAsHtml,
    saveQAInfo,
    uploadImageCallBack,
  } = useProgramInfoViewQAMgtScreen();
  return (
    <div>
      <Card>
        <Col span={16}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <ButtonSwitch
              checked={programInfoStatus === "public"}
              defaultChecked={programInfoStatus === "public"}
              onChange={(state) => {
                setProgramInfoStatus(state ? "public" : "private");
                saveQAInfo({
                  status: state ? "public" : "private",
                });
              }}
            />
            <p className="offset-left-16">
              {intl.formatMessage({
                id: "screen.label.release",
              })}
            </p>
          </div>
          <br />
          <Form layout={"vertical"}>
            <Form.Item
              label={intl.formatMessage({
                id: "screen.label.description",
              })}
            >
              <DraftEditor
                toolbarHidden={authUser.type !== "admin"}
                readOnly={authUser.type !== "admin"}
                toolbar={nodeConfig(
                  uploadImageCallBack,
                  // @ts-expect-error TS2683
                  convertYVLinksToEmbed.bind(this, authUser.token, companyId),
                )}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
                placeholder={intl.formatMessage({
                  id: "screen.label.please_enter",
                })}
                editorState={editorState}
                onEditorStateChange={setEditorState}
                toolbarCustomButtons={[
                  <CustomTableToolbar key={"customTableToolBar"} />,
                ]}
              />
            </Form.Item>
            <Form.Item>
              <ButtonCV
                onClick={() => {
                  saveQAInfo({
                    content: getMarkUpAsHtml(),
                  });
                }}
              >
                <FormattedMessage id="screen.label.storage" />
              </ButtonCV>
            </Form.Item>
          </Form>
        </Col>
      </Card>
    </div>
  );
}
export default ProgramInfoViewQAMgtScreen;
