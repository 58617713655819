export const formatTime = (time: string) => {
  // If time is already formatted, return it
  if (time.includes(":")) return time;

  let formatted = time;
  switch (time.length) {
    // '' -> ''
    case 0:
      formatted = "";
      break;
    // '1' -> '01:00'
    case 1:
      formatted = `0${time}:00`;
      break;
    // '12' -> '12:00'
    case 2:
      formatted = `${time}:00`;
      break;
    // '123' -> '1:23'
    case 3:
      formatted = `0${time.slice(0, 1)}:${time.slice(1)}`;
      break;
    // '1234' -> '12:34'
    case 4:
    default:
      formatted = `${time.slice(0, 2)}:${time.slice(2, 4)}`;
  }
  return formatted;
};
