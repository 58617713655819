import { notification } from "antd";
import { useEffect, useRef } from "react";
import { useIntl } from "react-intl";

import { endpoints, parseEndpoint } from "../../../Services/endpoints";
import createFormData from "../../../utils/createFormData";
import { useStateWithRef } from "../../../utils/hooks";
import { EditVideoScreenState } from "../types/IEditVideoScreenProps";

// @ts-expect-error TS7006
const useEditVideoScreen = (props) => {
  const intl = useIntl();
  const [state, customSetState, stateRef] = useStateWithRef(() => {
    return {
      isModalManageCategoriesVisible: false,
      smallCategories: {},
      videoLargeCategoryId: 0,
      editVideo: {
        videoLargeCategoryId: 0,
        videoSmallCategoryId: 0,
        name: "",
        description: "",
        videoUrl: "",
      },
    };
  });
  const setStateCallbackRef = useRef(() => {});
  useEffect(() => {
    const callBack = setStateCallbackRef.current;
    callBack();
    setStateCallbackRef.current = () => {};
  }, [state]);
  const setState = (data: EditVideoScreenState, callback = () => {}) => {
    setStateCallbackRef.current = callback;
    // @ts-expect-error TS7006
    customSetState((previousState) => {
      return {
        ...previousState,
        ...data,
      };
    });
  };

  useEffect(() => {
    props.dispatchSetPageTitle("screen.label.video");
    getVideoLargeCategories();
    setTimeout(() => {
      getSingleVideo(getVideoId());
    }, 2000);
  }, []);

  const getVideoId = () => {
    const {
      match: { params },
    } = props;
    return params.id ? params.id : -1;
  };
  // @ts-expect-error TS7006
  const getSingleVideo = (videoId) => {
    const requestBuilder = {
      method: "getRequest",
      url: parseEndpoint(endpoints.singleVideo, {
        videoId,
      }),
      data: {},
    };
    // @ts-expect-error TS7031
    props.dispatchApiRequest(requestBuilder, ({ data }) => {
      const { currentVideo } = data;
      const editVideo = {
        videoLargeCategoryId: currentVideo.VideoLargeCategory.id,
        videoSmallCategoryId: currentVideo.VideoSmallCategory.id,
        name: currentVideo.name,
        description: currentVideo.description,
        imageThumbnail: currentVideo.imageThumbnail,
        videoUrl: currentVideo.videoUrl,
      };
      setState({
        editVideo,
        videoLargeCategoryId: currentVideo.VideoLargeCategory.id,
      });
    });
  };
  const onUpdateNewVideo = () => {
    let { editVideo } = state;
    const selectedLargeCategory = props.largeCategories.filter(
      // @ts-expect-error TS7006
      (l) => l.id === state.videoLargeCategoryId,
    );
    if (selectedLargeCategory.length <= 0)
      return notification.error({
        message: intl.formatMessage({
          id: "screen.label.large_category_required_field",
        }),
      });
    if (editVideo.videoSmallCategoryId <= 0)
      return notification.error({
        message: intl.formatMessage({
          id: "screen.label.small_category_required_field",
        }),
      });
    if (editVideo.name.length <= 0)
      return notification.error({
        message: intl.formatMessage({
          id: "screen.label.title_required_field",
        }),
      });
    if (editVideo.description.length <= 0)
      return notification.error({
        message: intl.formatMessage({
          id: "screen.label.details_are_required",
        }),
      });
    if (!editVideo.videoUrl)
      return notification.error({
        message: intl.formatMessage({
          id: "screen.label.vimeo_url_required_field",
        }),
      });
    editVideo = selectedLargeCategory[0].companyId
      ? {
          ...editVideo,
          companyId: selectedLargeCategory[0].companyId,
        }
      : editVideo;
    // if (typeof editVideo.videoUrl === 'string') {
    //   delete editVideo.videoUrl
    // }
    if (
      editVideo.imageThumbnail == null ||
      typeof editVideo.imageThumbnail === "string"
    ) {
      // imageThumbnail は設定 or 更新する場合、バイナリとして送信するため、それ以外のデータの場合は削除する。
      delete editVideo.imageThumbnail;
    }
    const baseUrl = selectedLargeCategory[0].companyId
      ? ""
      : `${process.env.REACT_APP_BASEHOST}companies/`;
    const requestBuilder = {
      method: "putRequest",
      url: parseEndpoint(endpoints.updateGlobalVideo, {
        baseUrl,
        videoLargeCategoryId: editVideo.videoLargeCategoryId,
        videoSmallCategoryId: editVideo.videoSmallCategoryId,
        videoId: getVideoId(),
      }),
      data: createFormData(editVideo),
      successMessage: intl.formatMessage({
        id: "screen.label.video_updated_successfully",
      }),
      successReRoute: "/global-video",
    };
    // @ts-expect-error TS7031
    props.dispatchApiRequest(requestBuilder, ({ data }) => {});
  };
  // @ts-expect-error TS7006
  const onDeleteSmallCategory = (largeCategoryId, smallCategoryId) => {
    const requestBuilder = {
      method: "deleteRequest",
      url: parseEndpoint(endpoints.videoSmallCategory, {
        smallCategoryId,
      }),
      data: {},
    };
    // @ts-expect-error TS7031
    props.dispatchApiRequest(requestBuilder, ({ data }) => {
      getSmallCategoryForEachLargeCategory(largeCategoryId);
    });
  };
  // @ts-expect-error TS7006
  const onSubmitNewCategory = (newSmallCategory) => {
    if (newSmallCategory.name.length > 0) {
      const data = {
        ...newSmallCategory,
        companyId: null,
      };
      const requestBuilder = {
        method: "postRequest",
        url: parseEndpoint(endpoints.smallCategoriesForSingleLargeCategory, {
          largeCategoryId: newSmallCategory.videoLargeCategoryId,
        }),
        data,
      };
      // @ts-expect-error TS7031
      props.dispatchApiRequest(requestBuilder, ({ data }) => {
        getSmallCategoryForEachLargeCategory(
          newSmallCategory.videoLargeCategoryId,
        );
      });
    }
  };
  // @ts-expect-error TS7006
  const onChangeNewVideoData = (key, value) => {
    const { editVideo } = stateRef.current;
    editVideo[key] = value;
    setState({
      editVideo,
    });
  };
  const getVideoLargeCategories = () => {
    if (props.largeCategories.length === 0) {
      const requestBuilder = {
        method: "getRequest",
        url: parseEndpoint(endpoints.videoLargeCategory),
        data: {},
      };
      // @ts-expect-error TS7031
      props.dispatchApiRequest(requestBuilder, ({ data }) => {
        props.dispatchSetVideoCategories(data);
        actionAfterFetchingLargeCategories(data);
      });
    } else {
      actionAfterFetchingLargeCategories(props.largeCategories);
    }
  };
  // @ts-expect-error TS7006
  const actionAfterFetchingLargeCategories = (data) => {
    if (Array.isArray(data)) {
      data.forEach((singleLargeCategory) => {
        getSmallCategoryForEachLargeCategory(singleLargeCategory.id);
      });
    }
  };
  // @ts-expect-error TS7006
  const getSmallCategoryForEachLargeCategory = (largeCategoryId) => {
    const requestBuilder = {
      method: "getRequest",
      url: parseEndpoint(endpoints.smallCategoriesForSingleLargeCategory, {
        largeCategoryId,
      }),
      data: {},
    };
    // @ts-expect-error TS7031
    props.dispatchApiRequest(requestBuilder, ({ data }) => {
      const { smallCategories } = state;
      smallCategories[largeCategoryId] = data;
      setState({ smallCategories });
    });
  };

  // @ts-expect-error TS7006
  const getLargeCategoryIdForSelect = (largeCategory) => {
    if (largeCategory.companyId !== null) {
      return false;
    } else if (largeCategory.companyId === null && props.user.isSuper) {
      return false;
    }
    return true;
  };

  return {
    intl,
    state: stateRef.current,
    setState,
    getVideoId,
    getSingleVideo,
    onUpdateNewVideo,
    onDeleteSmallCategory,
    onSubmitNewCategory,
    onChangeNewVideoData,
    getVideoLargeCategories,
    actionAfterFetchingLargeCategories,
    getSmallCategoryForEachLargeCategory,
    getLargeCategoryIdForSelect,
  };
};

export default useEditVideoScreen;
