import { useMemo } from "react";
import { Link } from "react-router-dom";

import { UserNameLabel } from "@/shared/label/UserNameLabel/component";

import { makeUserProfilesUrl } from "../../helper/makeUrl";
import { getDisplayMembers, sortMembersByIsOwnerAndName } from "./helper";
import styles from "./styles.module.scss";
import { SubmittedProjectMembersLabelProps } from "./type";

export const SubmittedProjectMembersLabel = ({
  projectMembers,
}: SubmittedProjectMembersLabelProps) => {
  // 表示可能なメンバーのみ取得
  const displayableMembers = useMemo(
    () => getDisplayMembers(projectMembers),
    [projectMembers],
  );
  // ソート結果をキャッシュする (メンバーはほぼ変更がないので、レンダリング時にソートし直しを防ぐため)
  const targetMembers = useMemo(() => {
    return [...displayableMembers.sort(sortMembersByIsOwnerAndName)];
  }, [displayableMembers]);

  //プロジェクト提出したユーザーを表示するので、パスワードは登録済みと判断できる
  return (
    <div className={styles.names_container}>
      {targetMembers.map((member) => (
        <Link key={member.uuid} to={makeUserProfilesUrl(member.uuid)}>
          <UserNameLabel
            name={member.name}
            hasChangedPassword={true}
            stoppedAt={member.stoppedAt}
            deletedAt={member.deletedAt}
            className={
              member.isProjectOwner
                ? ` ${styles.owner_name_link}`
                : `${styles.member_name_link}`
            }
          />
        </Link>
      ))}
    </div>
  );
};
