import { CopyOutlined } from "@ant-design/icons";
import { Button, Card, Col, Form, Input, message, Row, Switch } from "antd";
import { FC, useContext, useState } from "react";
// @ts-expect-error TS7016
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { ContentContext } from "@/Provider";
import CompanyActions from "@/Redux/CompanyRedux";

export type CompanySettingsCardProps = {
  //
};

export const CompanySettingsCard: FC<CompanySettingsCardProps> = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const context = useContext(ContentContext);

  // @ts-expect-error TS2339
  const company = useSelector((state) => state.company.companyPayload);

  const [name, setName] = useState(company.name ?? "");
  const [isCompanyRegistrationEnabled, setIsCompanyRegistrationEnabled] =
    useState(company.isCompanyRegistrationEnabled ?? false);

  const onSubmit = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    dispatch(
      CompanyActions.companyUpdateRequest(
        // @ts-expect-error TS2339
        {
          name,
          isCompanyRegistrationEnabled,
        },
        intl.formatMessage({
          id: "screen.label.updated_company_information",
        }),
      ),
    );
  };

  return (
    <Card
      title={intl.formatMessage({
        id: `screen.label.companies_set`,
      })}
      style={{
        marginBottom: 30,
      }}
    >
      <Row>
        <Col offset={4} span={14}>
          <Form layout={"vertical"}>
            <br />
            <Form.Item
              label={intl.formatMessage({
                id: "screen.label.contract_companies",
              })}
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 18 }}
            >
              <Input
                type="text"
                disabled={!context.user.isSuper}
                value={name}
                onChange={(event) => setName(event.target.value)}
              />
            </Form.Item>
            <Form.Item
              label={intl.formatMessage({
                id: `screen.label.use_plan`,
              })}
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 18 }}
            >
              {company.currentPlan ?? ""}
            </Form.Item>

            <Form.Item
              label={intl.formatMessage({
                id: "screens.settings.enable_registration",
              })}
            >
              <Switch
                onChange={(checked) => setIsCompanyRegistrationEnabled(checked)}
                checked={isCompanyRegistrationEnabled}
              />
            </Form.Item>

            {isCompanyRegistrationEnabled && (
              <Form.Item
                label={intl.formatMessage({
                  id: `screen.label.registration_request_url`,
                })}
                wrapperCol={{ span: 18 }}
              >
                <Input
                  addonAfter={
                    <CopyToClipboard
                      text={`${window.location.origin}/register/${company.registrationId}`}
                    >
                      <CopyOutlined
                        onClick={() => message.success("Link Copied")}
                      />
                    </CopyToClipboard>
                  }
                  type="text"
                  disabled
                  value={`${window.location.origin}/register/${company.registrationId}`}
                />
              </Form.Item>
            )}

            <Form.Item
              label={intl.formatMessage({
                id: `screen.label.password_policy`,
              })}
            >
              <p>
                {intl.formatMessage({
                  id: `screens.message.pass_condition`,
                })}
              </p>
            </Form.Item>

            <Form.Item
              style={{
                alignItems: "center",
                textAlign: "center",
                display: "grid",
              }}
            >
              <Button
                id={"alpha_savecompany_titles"}
                type="primary"
                htmlType="button"
                onClick={onSubmit}
              >
                {intl.formatMessage({
                  id: "screens.title.save",
                })}
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Card>
  );
};
