/* eslint-disable react/prop-types */
import { Card, Col, Row } from "antd";
import { EditorState } from "draft-js";
import moment from "moment";
import { FC, useEffect, useState } from "react";
import { ReactElement } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import ReactPlayer from "react-player/lazy";

import {
  EventDetail,
  EventMember,
  Notification,
} from "../../../types/Notification";
import { ButtonUndo } from "../../Components/atoms";
import { DraftEditor } from "../../Components/molecules";
import { PreLineEditor } from "../../Components/organisms";
import { Editor } from "../../editor/src";
import { endpoints, parseEndpoint } from "../../Services/endpoints";
import {
  convertFromRawWithTrimmer,
  getObjUUID,
  isJsonString,
  sanitizeEditorStateMarkupHtml,
} from "../../utils/common";
import { dateTimeFormat } from "../../utils/constants";
import { firstComeFirstServer, lottery } from "../../utils/constants";
import {
  getEventStatus,
  getUserEventApplicationStatus,
} from "../../utils/eventUtils";
import { INotificationDetailProps } from "./types/INotificationDetailProps";

const notificationMetaType = {
  eventRegistration: "event_registration",
  recruitMembers: "recruit_members",
  poll: "poll",
  generalNotice: "general_notice",
  importantNotice: "important_notice",
  projectCreation: "project_creation",
  projectInvitationRejection: "project_invitation_rejection",
  projectInvitationAcceptance: "project_invitation_acceptance",
  projectSubmissionReview: "project_submission_review",
  projectSubmission: "project_submission",
  projectInvitationRequest: "project_invitation_request",
  projectInvitationRequestFromMyself: "project_invitation_request_from_myself",
};

const NotificationDetail: FC<INotificationDetailProps> = function (props) {
  const intl = useIntl();
  const { user, apiRequest, updatePageTitle } = props.context;
  const [eventDetail, setEventDetail] = useState<EventDetail>();
  const [type, setType] = useState<string>("");
  const [notification, setNotification] = useState<Notification>();
  useEffect(() => {
    updatePageTitle?.("screen.label.notification_detail");
    getNotificationDetail(getNotificationId());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getNotificationId = (): number => {
    const {
      match: { params },
    } = props;
    return params.id ? parseInt(params.id) : -1;
  };
  const getIsEventMember = (eventDetail: EventDetail) => {
    const getIsEventMemberRequestData = {
      method: "getRequest",
      url: `events/${getObjUUID(eventDetail)}/users/${user.id}`,
      data: {},
    };
    apiRequest?.(
      // @ts-expect-error TS2345
      getIsEventMemberRequestData,
      ({ data }: { data: EventMember | null }) => {
        const newEventDetail = {
          ...eventDetail,
          isMemberStatus: data !== null,
        };
        setEventDetail(newEventDetail);
      },
    );
  };
  const getEventDetails = (id: string) => {
    const requestBuilder = {
      method: "getRequest",
      url: parseEndpoint(endpoints.singleEvent, {
        id,
      }),
      data: {},
    };
    // @ts-expect-error TS2345
    apiRequest?.(requestBuilder, ({ data }: { data: EventDetail }) => {
      setEventDetail(data);
      getIsEventMember(data);
    });
  };
  const getNotificationDetail = (notificationId: number) => {
    const requestBuilder = {
      method: "getRequest",
      url: parseEndpoint(endpoints.updateNotification, {
        id: notificationId,
      }),
      data: {},
    };
    // @ts-expect-error TS2345
    apiRequest?.(requestBuilder, ({ data }: { data: Notification }) => {
      updatePageTitle?.(data.notificationCategory || "");
      setType(data.meta.type);
      setNotification(data);
      switch (data.meta.type) {
        case notificationMetaType.eventRegistration:
          return getEventDetails(getObjUUID(data.meta));
        default:
      }
    });
  };
  const _renderNotificationBody = (): ReactElement | null => {
    if (!type.length) return null;
    switch (type) {
      case notificationMetaType.eventRegistration:
        return _renderEventNotificationDetail();
      case notificationMetaType.recruitMembers:
        return _renderRecruitmentDetail();
      default:
        return _renderDefault();
    }
  };
  const _renderDefault = (): ReactElement | null => {
    if (!notification) return null;
    let noticeDetailsNode = <>{notification.message}</>;
    if (notification.nodalData === true) {
      noticeDetailsNode = (
        <DraftEditor
          toolbarHidden={true}
          readOnly={true}
          editorState={sanitizeEditorStateMarkupHtml(
            EditorState.createWithContent(
              convertFromRawWithTrimmer(notification.message),
            ),
          )}
        />
      );
    }
    return (
      <>
        <h4>
          {intl.formatMessage({
            id: "screen.label.title",
          })}
        </h4>
        <p>{notification.actionText}</p>
        <br />
        {notification.meta.type === "poll" ? (
          <>
            <h4>URL</h4>
            <a
              rel="noreferrer"
              style={{ whiteSpace: "pre-line" }}
              target={"_blank"}
              href={
                notification.message.includes("http")
                  ? notification.message
                  : `https://${notification.message}`
              }
            >
              {notification.message}
            </a>
          </>
        ) : (
          <>
            <h4>
              {intl.formatMessage({
                id: "screen.label.more_detail",
              })}
            </h4>
            <PreLineEditor>{noticeDetailsNode}</PreLineEditor>
          </>
        )}
        <br />
        {notification.meta.videoUrl ? (
          <div className="player-wrapper">
            <ReactPlayer
              loop={false}
              controls
              className="react-player"
              url={notification.meta.videoUrl}
              width="100%"
              height="100%"
              config={{
                file: {
                  attributes: {
                    controlsList: "nodownload",
                  },
                },
              }}
            />
          </div>
        ) : null}
        <span>
          {notification.meta.projectId ? (
            <ButtonUndo
              onClick={() => {
                if (notification.meta.projectId) {
                  props.history.push(
                    `/dashboard/project.top/${notification.meta.projectId}`,
                  );
                }
              }}
            >
              {intl.formatMessage({
                id: "screen.label.check_project",
              })}
            </ButtonUndo>
          ) : null}
        </span>
      </>
    );
  };

  // Not in use?
  const _renderRecruitmentDetail = (): ReactElement => {
    if (!notification) return <></>;
    return (
      <>
        {notification.meta.name}{" "}
        {intl.formatMessage({
          id: "screen.label.there_looking_for_members",
        })}
        <br />
        <PreLineEditor>{notification.message}</PreLineEditor>
        <h4>
          {intl.formatMessage({
            id: "screen.label.application_method",
          })}
        </h4>
        <ol style={{ paddingLeft: "20px" }}>
          <li>
            {intl.formatMessage({
              id: "screen.label.check_project_des",
            })}
          </li>
          <li>
            {intl.formatMessage({
              id: "screen.label.apply_to_become_project_member_des",
            })}
          </li>
        </ol>
        <ButtonUndo
          onClick={() => {
            if (notification.meta) {
              props.history.push(
                `/dashboard/project.top/${notification.meta.projectId}`,
              );
            }
          }}
        >
          {intl.formatMessage({
            id: "screen.label.check_project",
          })}
        </ButtonUndo>
      </>
    );
  };

  // This is called when user clicks "詳細" TEXT in event notification popup content in HomeScreen
  // Note that "詳細" BUTTON in the bottom of the notification popup navigates user to event.detail screen instead
  const _renderEventNotificationDetail = (): ReactElement => {
    if (!eventDetail) return <></>;
    return (
      <>
        <h4>
          {intl.formatMessage({
            id: "screen.label.event_name",
          })}
        </h4>
        <p>{eventDetail.name}</p>
        <br />
        <h4>
          {intl.formatMessage({
            id: "screen.label.more_detail",
          })}
        </h4>
        <PreLineEditor>
          {eventDetail.nodalData && isJsonString(eventDetail.description) ? (
            <Editor
              toolbarHidden={true}
              readOnly={true}
              editorState={sanitizeEditorStateMarkupHtml(
                EditorState.createWithContent(
                  convertFromRawWithTrimmer(eventDetail.description),
                ),
              )}
              localization={{
                locale: "ja",
              }}
            />
          ) : (
            eventDetail.description
          )}
        </PreLineEditor>
        <br />

        <h4>
          {intl.formatMessage({
            id: "screen.label.event_date_and_time",
          })}
        </h4>
        <Row>
          <Col>
            <p>
              {moment(eventDetail.beginsAt).local().format(dateTimeFormat)}{" "}
              &nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </p>
          </Col>
          <Col>
            {eventDetail.endsAt ? (
              <p>
                ~&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                {eventDetail.endsAt
                  ? moment(eventDetail.endsAt).local().format(dateTimeFormat)
                  : ""}
              </p>
            ) : null}
          </Col>
        </Row>

        {eventDetail.type !== "basic" ? (
          <>
            <h4>
              {intl.formatMessage({
                id: "screen.label.reception_method",
              })}
            </h4>
            <p>
              {eventDetail.type === "lottery" ? lottery : firstComeFirstServer}
            </p>
            <br />
          </>
        ) : null}

        {eventDetail.capacity > 0 ? (
          <>
            <h4>
              {user.type === "admin"
                ? intl.formatMessage({
                    id: "screens.events.admin_capacity",
                  })
                : intl.formatMessage({
                    id: "screen.label.capacity",
                  })}
            </h4>
            <p>{_renderCapacitySectionInModal(eventDetail)}</p>
            <br />
          </>
        ) : null}

        {eventDetail.applicationDeadline ? (
          <>
            <h4>
              {intl.formatMessage({
                id: "screen.label.events_application_deadline_date",
              })}
            </h4>
            <p>
              {eventDetail.applicationDeadline
                ? moment(eventDetail.applicationDeadline)
                    .local()
                    .format(dateTimeFormat)
                : ""}
            </p>
            <br />
          </>
        ) : null}

        <h4>
          {intl.formatMessage({
            id: "screens.events.application_status",
          })}
        </h4>
        <p>{getUserEventApplicationStatus(eventDetail, user.id)}</p>

        <br />
        <h4>
          {" "}
          {intl.formatMessage({
            id: "screens.events.event_status",
          })}
        </h4>
        <p>{getEventStatus(eventDetail)}</p>

        <br />
        <h4>
          {intl.formatMessage({
            id: "screens.events.user_participation_status",
          })}
        </h4>
        <p>
          {eventDetail.EventMembers.filter(
            (eventMember: EventMember) => eventMember.userId === user.id,
          )?.length > 0 &&
          eventDetail.EventMembers.filter(
            (eventMember: EventMember) => eventMember.userId === user.id,
          )[0].wasPresent ? (
            <FormattedMessage id="screens.events.participation_confirmed" />
          ) : null}
        </p>
        <br />
        {eventDetail.videoUrl && eventDetail.videoUrl.length > 0 ? (
          <div className="player-wrapper">
            <ReactPlayer
              loop={false}
              controls
              className="react-player"
              url={eventDetail.videoUrl}
              width="100%"
              height="100%"
              config={{
                file: {
                  attributes: {
                    controlsList: "nodownload",
                  },
                },
              }}
            />
          </div>
        ) : null}

        {_renderFootterEventNotification()}
      </>
    );
  };
  const _renderCapacitySectionInModal = (event: EventDetail): string => {
    if (user.type === "admin") {
      if (event.capacity) {
        return `${event.EventMembers?.length}/${event.capacity}`;
      }
      return `${event.EventMembers?.length}`;
    }
    if (event.type === "first-come-first-serve") {
      if (
        event.capacity &&
        event.EventMembers &&
        event.EventMembers.length >= event.capacity
      ) {
        return intl.formatMessage({
          id: "screen.label.events_full_des",
        });
      }
      return `${event.capacity}`;
    }
    return `${event.capacity}`;
  };
  const _renderFootterEventNotification = (): ReactElement | null => {
    if (!eventDetail) return null;
    return (
      <span>
        <ButtonUndo
          loading={eventDetail.isMemberStatus == null}
          onClick={
            eventDetail.isMemberStatus ? onUnRegisterEvent : onRegisterForEvent
          }
        >
          {eventDetail.isMemberStatus
            ? intl.formatMessage({
                id: "screen.label.cancel_participation_application",
              })
            : intl.formatMessage({
                id: "screen.label.apply_for_participation",
              })}
        </ButtonUndo>
        {user.type === "admin" ? (
          <ButtonUndo
            className="offset-left-16"
            onClick={() => {
              toggleAcceptingMemberAndNotAcceptingMember(eventDetail);
            }}
          >
            {eventDetail.isAcceptingMembers
              ? intl.formatMessage({
                  id: "screen.label.wanted_stop",
                })
              : intl.formatMessage({
                  id: "screen.label.resume_recruitment",
                })}
          </ButtonUndo>
        ) : null}
      </span>
    );
  };
  const toggleAcceptingMemberAndNotAcceptingMember = (
    eventDetail: EventDetail,
  ) => {
    const requestBuilder = {
      method: "putRequest",
      url: parseEndpoint(endpoints.toggleAcceptanceStatus, {
        id: getObjUUID(eventDetail),
      }),
      data: {
        isAcceptingMembers: !eventDetail.isAcceptingMembers,
      },
    };
    // @ts-expect-error TS2345
    apiRequest?.(requestBuilder, () => {
      getEventDetails(getObjUUID(eventDetail));
    });
  };
  const onUnRegisterEvent = () => {
    const eventRequestData = {
      method: "deleteRequest",
      url: parseEndpoint(endpoints.findEventMember, {
        eventId: getObjUUID(eventDetail),
        userId: user.id,
      }),
      successMessage: intl.formatMessage({
        id: "screen.label.canceled_participate_event",
      }),
      data: {},
    };
    // @ts-expect-error TS2345
    apiRequest?.(eventRequestData, () => {
      getNotificationDetail(getNotificationId());
    });
  };
  const onRegisterForEvent = () => {
    if (!eventDetail) return;
    const eventRequestData = {
      method: "postRequest",
      url: parseEndpoint(endpoints.getEventUsers, {
        data: {
          eventId: getObjUUID(eventDetail),
        },
        queryParams: "",
      }),
      successMessage: intl.formatMessage({
        id: "screen.label.apply_participation_event",
      }),
      data: {
        userId: user.id,
        eventId: eventDetail.id,
      },
    };
    // @ts-expect-error TS2345
    apiRequest?.(eventRequestData, () => {
      getNotificationDetail(getNotificationId());
    });
  };

  return (
    <>
      <Card>{_renderNotificationBody()}</Card>
    </>
  );
};

export default NotificationDetail;
