import { Row } from "antd";
import { useIntl } from "react-intl";

import { ButtonOption } from "../../atoms";
type Props = {
  mainCategoryId: string;
  title: string;
  onAddItem: (mainCategoryId: string) => void;
};
function AddCategoryItem(props: Props) {
  const intl = useIntl();
  const { mainCategoryId, title, onAddItem } = props;
  return (
    <>
      <br />
      <Row align="middle" justify="end">
        <ButtonOption
          onClick={() => {
            onAddItem(mainCategoryId);
          }}
          style={{
            marginTop: "1px",
          }}
        >
          {intl.formatMessage({ id: title })}
        </ButtonOption>
      </Row>
      <br />
    </>
  );
}
export default AddCategoryItem;
