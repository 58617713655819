import React, { useContext, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Company } from "../../types/Company";
import { User } from "../../types/User";
import ApiActions from "../Redux/ApiRedux";
import CompanyActions from "../Redux/CompanyRedux";
import HomeActions from "../Redux/HomeRedux";
import UserActions from "../Redux/UserRedux";
import { DispatchApiRequest } from "./type";

type Props = {
  children: any;
};
export type Context = {
  user: User;
  company: Company;
  updatePageTitle: (title: string) => void;
  fetchUser: (data: any, message: string) => void;
  updateUser: (data: any, message: string) => void;
  refreshUser: (data: any) => void;
  updateCompany: (data: any, message: string) => void;
  apiRequest: DispatchApiRequest;
};

// @ts-expect-error TS2345
export const ContentContext = React.createContext<Context>(null);

export default function ContextProvider(props: Props) {
  const dispatch = useDispatch();

  const authPayload = useSelector((state: any) => state.auth);
  const companyPayload = useSelector((state: any) => state.company);

  const user = useMemo(() => {
    if (authPayload.payload) {
      return authPayload.payload;
    }
    return null;
  }, [authPayload]);

  const company = useMemo(() => {
    if (companyPayload.companyPayload) {
      return companyPayload.companyPayload;
    }
    return null;
  }, [companyPayload]);

  const updatePageTitle = (title: string) => {
    // @ts-expect-error TS2554
    dispatch(HomeActions.pageTitle(title));
  };

  const updateUser = (data: any, message: string) => {
    // @ts-expect-error TS2554
    dispatch(UserActions.userUpdateProfileRequest(data, message));
  };

  const fetchUser = (data: any, message: string) => {
    dispatch(UserActions.userGetProfileRequest());
  };

  const refreshUser = (data: any) => {
    // @ts-expect-error TS2554
    dispatch(UserActions.userRefreshProfileRequest(data));
  };

  const updateCompany = (data: any, message: string) => {
    // @ts-expect-error TS2554
    dispatch(CompanyActions.companyUpdateRequest(data, message));
  };

  const apiRequest: Context["apiRequest"] = (data, callback, errorCallBack) => {
    dispatch(ApiActions.apiRequest(data, callback, errorCallBack));
  };

  return (
    <ContentContext.Provider
      value={{
        user,
        company,
        fetchUser,
        updateUser,
        refreshUser,
        updateCompany,
        updatePageTitle,
        apiRequest,
      }}
    >
      {props.children}
    </ContentContext.Provider>
  );
}

export const useContextProvider = () => useContext(ContentContext);
