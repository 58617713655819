import { Tag as AntTag } from "antd";
import styled from "styled-components";

export const UserBadge = styled(AntTag)`
  border: none;
  border-radius: 0;
  color: #1f86e9;

  background: rgba(31, 134, 233, 0.1);
  //padding: 4px 12px;
  //min-width: 7.6vw;
  //min-height: 6.6vh;
`;
