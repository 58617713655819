import { Card, Col, Row } from "antd";
import PropTypes from "prop-types";
import { FC } from "react";
import { connect } from "react-redux";

import { ProfileImage } from "../../Components/organisms";
import ProjectActions from "../../Redux/ProjectRedux";
import { ProfileForm } from "../../shared/forms/components/ProfileForm";
import useProfileScreen from "./hooks/useProfileScreen";
import { IProfileScreenProps } from "./types/IProfileScreenProps";

const ProfileScreen: FC<IProfileScreenProps> = function (props) {
  const {
    state,
    setState,
    createInterest,
    handleSubmit,
    onProfileImageEditClose,
    onProfileImageEditCrop,
    uploadProfileImage,
  } = useProfileScreen(props);

  const { user, company, apiRequest, refreshUser } = props.context;

  let url =
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP8/5+hHgAHggJ/PchI7wAAAABJRU5ErkJggg==";
  if (user.profileImage) {
    if (user.profileImage.indexOf("http") > -1) {
      url = `${user.profileImage}`;
    } else {
      url = `https://${user.profileImage}`;
    }
  }
  return (
    <Card>
      <Row>
        <Col span={12}>
          <ProfileImage
            titleId="screen.label.profile_image_upload"
            avatarTitle="screen.label.please_select_file"
            url={url}
            // @ts-expect-error TS2339
            source={state.src}
            onFileUpload={uploadProfileImage}
            onFileNameChange={(fileName) => {
              setState({
                // @ts-expect-error TS2345
                originalFileName: fileName,
              });
            }}
            onImageEditCrop={onProfileImageEditCrop}
            onImageEditClose={onProfileImageEditClose}
          />

          <ProfileForm
            refreshUser={refreshUser}
            createInterest={createInterest}
            handleSubmit={handleSubmit}
            interests={state.interest}
            // @ts-expect-error TS2322
            userDetails={user}
            dispatchApiRequest={apiRequest}
            // @ts-expect-error TS2322
            companyDetails={company}
          />
        </Col>
      </Row>
    </Card>
  );
};

// @ts-expect-error TS7006
const mapStateToProps = (state) => ({
  projects: state.projects,
  teams: state.teams,
});
// @ts-expect-error TS7006
const mapDispatchToProps = (dispatch) => ({
  // @ts-expect-error TS7006
  dispatchGetMyProjects: (data) =>
    // @ts-expect-error TS2554
    dispatch(ProjectActions.projectCurrentMemberGetsRequest(data)),
});

ProfileScreen.propTypes = {
  // @ts-expect-error TS2322
  context: PropTypes.object,
  projects: PropTypes.object,
  teams: PropTypes.object,
  dispatchGetMyProjects: PropTypes.func,
};
export default connect(mapStateToProps, mapDispatchToProps)(ProfileScreen);
