import { PropsWithChildren } from "react";

import { AntdButton } from "../AntdButton";
import { AntdSecondaryButtonProps } from "./type";

export const AntdSecondaryButton = ({
  children,
  ...props
}: PropsWithChildren<AntdSecondaryButtonProps>) => {
  return (
    <AntdButton category="secondary" {...props}>
      {children}
    </AntdButton>
  );
};
