import { EditorState, Modifier } from "draft-js";
import { getSelectedBlock } from "draftjs-utils";

import { stringToSlug } from "./common";
export function customChunkRenderer(nodeName, node) {
  const allowedNodes = [
    "div",
    "table",
    "tbody",
    "tr",
    "th",
    "td",
    "thead",
    "style",
  ];

  if (allowedNodes.includes(nodeName)) {
    return {
      type: nodeName.toString().toUpperCase(),
      mutability: "MUTABLE",
      data: {
        innerText: node.innerText,
        innerHTML: node.innerHTML,
      },
    };
  }
  return null;
}

export function entityMapper(entity) {
  if (entity.type === "DIV") {
    return `<div>${entity.data.innerHTML}</div>`;
  }
  if (entity.type === "TABLE") {
    return `<table>${entity.data.innerHTML}</table>`;
  }
  if (entity.type === "TBODY") {
    return `<tbody>${entity.data.innerHTML}</tbody>`;
  }
  if (entity.type === "TR") {
    return `<tr>${entity.data.innerHTML}</tr>`;
  }
  if (entity.type === "TH") {
    return `<th>${entity.data.innerHTML}</th>`;
  }
  if (entity.type === "TD") {
    return `<td>${entity.data.innerHTML}</td>`;
  }
  if (entity.type === "STYLE") {
    return `<style>${entity.data.innerHTML}</style>`;
  }
  return "";
}

export function entityMapperToComponent(entity) {
  if (entity.type === "DIV") {
    const divEntity = () => (
      <div dangerouslySetInnerHTML={{ __html: entity.data.innerHTML }} />
    );
    divEntity.displayName = "divEntity";
    return divEntity;
  }
  if (entity.type === "TABLE") {
    const tableEntity = () => (
      <table dangerouslySetInnerHTML={{ __html: entity.data.innerHTML }} />
    );
    tableEntity.displayName = "tableEntity";
    return tableEntity;
  }
  if (entity.type === "TBODY") {
    const tbodyentity = (
      <tbody dangerouslySetInnerHTML={{ __html: entity.data.innerHTML }} />
    );
    tbodyentity.displayName = "tbodyentity";
    return tbodyentity;
  }
  if (entity.type === "TR") {
    const trEntity = () => (
      <tr dangerouslySetInnerHTML={{ __html: entity.data.innerHTML }} />
    );
    trEntity.displayName = "trEntity";
    return trEntity;
  }
  if (entity.type === "TH") {
    const thEntity = () => (
      <th dangerouslySetInnerHTML={{ __html: entity.data.innerHTML }} />
    );
    thEntity.displayName = "thEntity";
    return thEntity;
  }
  if (entity.type === "TD") {
    const tdEntity = () => (
      <td dangerouslySetInnerHTML={{ __html: entity.data.innerHTML }} />
    );
    tdEntity.displayName = tdEntity;
    return tdEntity;
  }
  if (entity.type === "STYLE") {
    const styleEntity = () => <style>{entity.data.innerHTML}</style>;
    styleEntity.displayName = "styleEntity";
    return styleEntity;
  }

  return "";
}

export const getTableHeaders = () => {
  const headersNode = document.querySelectorAll(
    "#dynamic-table-id>thead>tr>th",
  );
  const cols = [];
  if (headersNode) {
    for (let i = 0; i < headersNode.length; i++) {
      const th = headersNode[i];
      cols.push(th.textContent);
    }
  }
  return cols;
};

export const getTableData = () => {
  const columns = getTableHeaders();
  const bodyNodes = document.querySelectorAll("#dynamic-table-id>tbody>tr>td");
  const dataSource = [];
  if (bodyNodes) {
    for (let i = 0; i < bodyNodes.length; i = i + columns.length) {
      const singleRow = {};
      let columnsHeaderTrackerIndex = 0;
      for (let j = i; j < i + columns.length; j++) {
        const td = bodyNodes[j];
        const k = columnsHeaderTrackerIndex;
        singleRow[stringToSlug(k)] = td.textContent;
        columnsHeaderTrackerIndex++;
      }
      dataSource.push(singleRow);
    }
  }
  return dataSource;
};

export const addMention = (editorState, separator, trigger, suggestion) => {
  const { value, url } = suggestion;
  const entityKey = editorState
    .getCurrentContent()
    .createEntity("MENTION", "IMMUTABLE", {
      text: `${trigger}${value}`,
      value,
      url,
    })
    .getLastCreatedEntityKey();
  const selectedBlock = getSelectedBlock(editorState);
  const selectedBlockText = selectedBlock.getText();
  let focusOffset = editorState.getSelection().focusOffset;
  const mentionIndex =
    (selectedBlockText.lastIndexOf(separator + trigger, focusOffset) || 0) + 1;
  let spaceAlreadyPresent = false;
  if (selectedBlockText.length === mentionIndex + 1) {
    focusOffset = selectedBlockText.length;
  }
  if (selectedBlockText[focusOffset] === " ") {
    spaceAlreadyPresent = true;
  }
  let updatedSelection = editorState.getSelection().merge({
    anchorOffset: mentionIndex,
    focusOffset,
  });
  let newEditorState = EditorState.acceptSelection(
    editorState,
    updatedSelection,
  );
  let contentState = Modifier.replaceText(
    newEditorState.getCurrentContent(),
    updatedSelection,
    `${trigger}${value}`,
    newEditorState.getCurrentInlineStyle(),
    entityKey,
  );
  newEditorState = EditorState.push(
    newEditorState,
    contentState,
    "insert-characters",
  );

  if (!spaceAlreadyPresent) {
    // insert a blank space after mention
    updatedSelection = newEditorState.getSelection().merge({
      anchorOffset: mentionIndex + value.length + trigger.length,
      focusOffset: mentionIndex + value.length + trigger.length,
    });
    newEditorState = EditorState.acceptSelection(
      newEditorState,
      updatedSelection,
    );
    contentState = Modifier.insertText(
      newEditorState.getCurrentContent(),
      updatedSelection,
      " ",
      newEditorState.getCurrentInlineStyle(),
      undefined,
    );
  }
  return newEditorState;
};
