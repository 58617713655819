import { Col, Collapse, PageHeader, Row } from "antd";
import { FC, useContext, useMemo } from "react";
import { useSelector } from "react-redux";

import { ProjectCheckpoints } from "@/Components/organisms";
import ProjectHypothesisEditor from "@/Components/organisms/project/ProjectHypothesisEditor";
import { useUserTeamStatus } from "@/features/project/hooks/useUserTeamStatus";
import { useParamId } from "@/hooks/useParamId";
import { ContentContext } from "@/Provider";
import { ReduxState } from "@/Redux/types";
import { EditIconButton } from "@/shared/buttons/components/EditIconButton";

import { BatchHypothesis } from "../../../../types/BatchHypothesis";
import { ProjectHypothesis as ProjectHypothesisType } from "../../../../types/ProjectHypothesis";
import { InformationPopoverIcon } from "../InformationPopoverIcon";

const { Panel } = Collapse;
const customPanelStyle = {
  background: "#fff ",
  overflow: "hidden",
};
type ProjectHypothesisItemProps = {
  batchHypothesis: BatchHypothesis;
  projectHypothesis: ProjectHypothesisType;
  displayCheckpoint: boolean;
  onSubmit: (hypothesisFormId: string, value: string) => void;
  onStartEdit: (hypothesisFormId: string) => void;
  onCancelEdit: (hypothesisFormId: string) => void;
  isEditing: boolean;
};

export const ProjectHypothesisItem: FC<ProjectHypothesisItemProps> = ({
  batchHypothesis,
  projectHypothesis,
  displayCheckpoint,
  onStartEdit,
  onCancelEdit,
  isEditing,
  onSubmit,
}) => {
  const { user, company } = useContext(ContentContext);
  const project = useSelector(
    (state: ReduxState) => state.projects.projectPayload,
  );
  const { isTeamMember } = useUserTeamStatus({
    user,
    team: [...project.Team],
    project,
  });

  const { hypothesisFormDescription, hypothesisFormName, useInfoMark } =
    batchHypothesis.hypothesisFormMetadata;

  const hypothesisFormId = batchHypothesis.hypothesisFormId;
  const batchId = batchHypothesis.batchId;
  const projectId = useParamId();

  const enableCheckpoint =
    company.isAllowCheckpoint &&
    displayCheckpoint &&
    (project.Batch?.displayCheckpoint ?? false);

  const editButton = useMemo(() => {
    if (!isTeamMember) return null;
    return (
      <EditIconButton
        id="edit-hypothesis"
        alt="edit"
        onClick={() =>
          isEditing
            ? onCancelEdit(hypothesisFormId)
            : onStartEdit(hypothesisFormId)
        }
      />
    );
  }, [hypothesisFormId, isEditing, isTeamMember, onCancelEdit, onStartEdit]);

  return (
    <Collapse activeKey="key" bordered={false}>
      <Panel
        showArrow={false}
        header={
          <PageHeader
            className="project-item-header"
            title={hypothesisFormName}
            subTitle={
              useInfoMark ? (
                <InformationPopoverIcon info={hypothesisFormDescription} />
              ) : null
            }
            extra={editButton}
          >
            {!useInfoMark ? hypothesisFormDescription : ""}
          </PageHeader>
        }
        key="key"
        style={customPanelStyle}
      >
        <Row align="top" justify="space-between">
          <Col span={enableCheckpoint ? 11 : 24}>
            <ProjectHypothesisEditor
              hypothesisFormId={hypothesisFormId}
              updateProject={onSubmit}
              content={projectHypothesis?.hypothesisFormMetadata ?? ""}
              isEditing={isEditing}
              onStartEdit={() => onStartEdit(hypothesisFormId)}
              onCancelEdit={() => onCancelEdit(hypothesisFormId)}
            />
          </Col>
          <Col span={enableCheckpoint ? 12 : 0}>
            {enableCheckpoint && (
              <ProjectCheckpoints
                companyPlan={company.currentPlan}
                projectId={projectId}
                batchId={batchId}
                hypothesisFormId={hypothesisFormId}
              />
            )}
          </Col>
        </Row>
      </Panel>
    </Collapse>
  );
};
