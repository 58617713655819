import { InfoCircleOutlined } from "@ant-design/icons";
import styled from "styled-components";

export const ProjectCreateRoot = styled.div`
  display: flex;
  flex-direction: column;
  //align-items: center;
  //justify-content: center;
`;
export const InfoCircleComponent = styled(InfoCircleOutlined)`
  font-size: 16px;
  color: rgba(0, 0, 0, 0.45);
  margin-left: 10px;
  cursor: pointer;
`;
