import { Action } from "@reduxjs/toolkit";
import { createActions, createReducer } from "reduxsauce";
import Immutable from "seamless-immutable";

import { ToAction, ToActionType } from "./types";

/* ------------- Types and Action Creators ------------- */
type VideoActionCreators = {
  videoLargeCategoriesGetSuccess: () => Action<"VIDEO_LARGE_CATEGORIES_GET_SUCCESS">;
};
type VideoAction = ToAction<VideoActionCreators>;
type VideoActionType = ToActionType<VideoAction>;

const { Types, Creators } = createActions<VideoActionType, VideoActionCreators>(
  {
    videoLargeCategoriesGetSuccess: ["largeCategoriesPayload"],
  },
);

export const VideoTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
type VideoState = {
  largeCategoriesPayload: unknown[];
};

export const INITIAL_STATE = Immutable<VideoState>({
  largeCategoriesPayload: [],
});

type ImmVideoState = typeof INITIAL_STATE;

/* ------------- Reducers ------------- */

const videoLargeCategoriesGetSuccess = (
  state: ImmVideoState,
  // @ts-expect-error TS7031
  { largeCategoriesPayload },
) => state.merge({ largeCategoriesPayload });

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer<ImmVideoState, VideoAction>(
  INITIAL_STATE,
  {
    [Types.VIDEO_LARGE_CATEGORIES_GET_SUCCESS]: videoLargeCategoriesGetSuccess,
  },
);
