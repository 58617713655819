import { notification } from "antd";
import { all, call, put, takeLatest } from "redux-saga/effects";

import TeamActions, { TeamTypes } from "../Redux/TeamRedux";
import { endpoints, parseEndpoint } from "../Services/endpoints";

const defaultPageSize = process.env.REACT_APP_PAGE_SIZE;

// @ts-expect-error TS7006
function* attemptGetProjectMembers(api, action) {
  const { data } = action;
  const limit = data.limit || defaultPageSize;
  let usersResponse;
  if (Object.hasOwn(data, "shouldPaginate") && !data.shouldPaginate) {
    // @ts-expect-error TS7057
    usersResponse = yield call(
      api.getRequest,
      parseEndpoint(endpoints.allAcceptedProjectMembers, { data }),
    );
  } else {
    // @ts-expect-error TS7057
    usersResponse = yield call(
      api.getRequest,
      parseEndpoint(endpoints.limitedAcceptedProjectMembers, {
        data,
        limit,
      }),
    );
  }

  if (usersResponse.ok) {
    switch (usersResponse.data.status) {
      case "success":
        // @ts-expect-error TS2554
        yield put(TeamActions.teamGetSuccess(usersResponse.data.data));
        break;
      case "fail":
      case "error":
        // @ts-expect-error TS2554
        yield put(TeamActions.teamGetFailure(usersResponse.data.data));
        break;
      default:
        break;
    }
  } else {
    notification.error({
      message: usersResponse.problem,
    });
    // @ts-expect-error TS2554
    yield put(TeamActions.teamGetFailure(usersResponse.problem));
    if (usersResponse.status === 401) {
      // @ts-expect-error TS2322
      window.location = "/login";
    }
  }
}

// @ts-expect-error TS7006
function* attemptGetProjectPendingMembers(api, action) {
  const { data } = action;

  const limit = data.limit || defaultPageSize;

  // @ts-expect-error TS7057
  const usersResponse = yield call(
    api.getRequest,
    parseEndpoint(endpoints.pendingProjectMembers, { data, limit }),
  );

  if (usersResponse.ok) {
    switch (usersResponse.data.status) {
      case "success":
        // @ts-expect-error TS2554
        yield put(TeamActions.teamPendingGetSuccess(usersResponse.data.data));
        break;
      case "fail":
      case "error":
        if (typeof usersResponse.data.data === "string") {
        }
        // @ts-expect-error TS2554
        yield put(TeamActions.teamPendingGetFailure(usersResponse.data.data));
        break;
      default:
        break;
    }
  } else {
    notification.error({
      message: usersResponse.problem,
    });
    // @ts-expect-error TS2554
    yield put(TeamActions.teamPendingGetFailure(usersResponse.problem));
    if (usersResponse.status === 401) {
      // @ts-expect-error TS2322
      window.location = "/login";
    }
  }
}

// @ts-expect-error TS7006
function* attemptAddMemberToTeam(api, action) {
  const { data, message } = action;

  // @ts-expect-error TS7057
  const teamResponse = yield call(
    api.postRequest,
    parseEndpoint(endpoints.inviteMemberToTeam, {
      projectUUid: data[0].uid,
    }),
    data,
  );
  if (teamResponse.ok) {
    switch (teamResponse.data.status) {
      case "success": {
        // @ts-expect-error TS2554
        yield put(TeamActions.teamCreateSuccess(teamResponse.data.data));
        notification.success({
          message: message,
        });
        const requestData = {
          page: 0,
          id: data[0].uid,
        };

        // @ts-expect-error TS2554
        yield put(TeamActions.teamPendingGetRequest(requestData));
        // @ts-expect-error TS2554
        yield put(TeamActions.teamGetRequest(requestData));
        break;
      }
      case "fail":
      case "error":
        if (typeof teamResponse.data.data === "string") {
        }
        // @ts-expect-error TS2554
        yield put(TeamActions.teamCreateFailure(teamResponse.data.data));
        break;
      default:
        break;
    }
  } else {
    notification.error({
      message: teamResponse.problem,
    });
    // @ts-expect-error TS2554
    yield put(TeamActions.teamCreateFailure(teamResponse.problem));
    if (teamResponse.status === 401) {
      // @ts-expect-error TS2322
      window.location = "/login";
    }
  }
}

// @ts-expect-error TS7006
function* teamSaga(api) {
  yield all([
    takeLatest(TeamTypes.TEAM_GET_REQUEST, attemptGetProjectMembers, api),
    takeLatest(
      TeamTypes.TEAM_PENDING_GET_REQUEST,
      attemptGetProjectPendingMembers,
      api,
    ),
    takeLatest(TeamTypes.TEAM_CREATE_REQUEST, attemptAddMemberToTeam, api),
  ]);
}

export default teamSaga;
