import { Table } from "antd";
import PropTypes from "prop-types";
import { PureComponent } from "react";
import ReactDragListView from "react-drag-listview";

type VideoSortableTableProps = {
  canReArrange: boolean;
  columns: Array<any>;
  dataSource: Array<any>;
  rowKey: string;
  getDragProps: any;
};
class VideoSortableTable extends PureComponent<VideoSortableTableProps, {}> {
  _renderTable = () => {
    const {
      canReArrange,
      columns,
      dataSource,
      rowKey = "id",
      getDragProps,
    } = this.props;
    const table = (
      <Table
        pagination={false}
        rowKey={rowKey}
        columns={columns}
        components={{
          body: {
            row: (props: any) => {
              if (props["data-row-key"] === dataSource[0]?.id && canReArrange) {
                return (
                  <tr
                    onDragStart={() => {
                      document
                        .querySelector('[data-first-draggable-item~="true"]')
                        ?.removeAttribute("data-first-draggable-item");
                    }}
                    data-first-draggable-item={"true"}
                    {...props}
                  />
                );
              }
              return (
                <tr
                  onDragStart={() => {
                    document
                      .querySelector('[data-first-draggable-item~="true"]')
                      ?.removeAttribute("data-first-draggable-item");
                  }}
                  {...props}
                />
              );
            },
          },
        }}
        dataSource={Array.isArray(dataSource) ? dataSource : []}
      />
    );
    if (canReArrange) {
      return <ReactDragListView {...getDragProps()}>{table}</ReactDragListView>;
    }
    return table;
  };
  render() {
    return <>{this._renderTable()}</>;
  }
}

// @ts-expect-error TS2339
VideoSortableTable.propTypes = {
  canReArrange: PropTypes.bool.isRequired,
  columns: PropTypes.array.isRequired,
  dataSource: PropTypes.array.isRequired,
  rowKey: PropTypes.string.isRequired,
  getDragProps: PropTypes.object.isRequired,
};

export default VideoSortableTable;
