import { useCallback, useMemo, useState } from "react";

import { SubmittedProjectUuid } from "@/../types/SubmittedProject";

import { canPublish } from "../components/ReviewInfoRow/helper";
import { BasicReviewInfo } from "../types/reviewInfo";

export const useSelectedPublishTarget = (reviewInfos: BasicReviewInfo[]) => {
  // 公開対象としてユーザーが選択した提出データのUUIDを一覧に表示されていないものも含めて管理する
  const [selectedPublishTargetsSet, setSelectedPublishTargetsSet] = useState<
    Set<SubmittedProjectUuid>
  >(new Set());

  const resetSelectedPublishTargets = () =>
    setSelectedPublishTargetsSet(new Set());

  const hasSelectedTargets = useMemo(
    () => selectedPublishTargetsSet.size !== 0,
    [selectedPublishTargetsSet],
  );

  const countOfSelectedTargets = useMemo(
    () => selectedPublishTargetsSet.size,
    [selectedPublishTargetsSet],
  );

  // 現在表示されている公開可能な提出データのuuidの集合
  const displayedPublishableUuids = useMemo(
    () => new Set(reviewInfos.filter(canPublish).map(({ uuid }) => uuid)),
    [reviewInfos],
  );

  const selectedPublishTargets = useMemo(
    () => [...selectedPublishTargetsSet],
    [selectedPublishTargetsSet],
  );

  const toggleAllDisplayedPublishTargets = useCallback(
    (isAllChecked: boolean) => {
      if (isAllChecked) {
        // 現在表示している公開可能なすべてのデータの選択を解除する
        // 表示されていない公開可能なデータのみ選択する
        const notDisplayedSelectedPublishTargets = new Set(
          selectedPublishTargets.filter(
            (uuid) => !displayedPublishableUuids.has(uuid),
          ),
        );
        setSelectedPublishTargetsSet(notDisplayedSelectedPublishTargets);
      } else {
        // 現在表示している公開可能なデータをすべて選択対象として追加する
        const selectedPublishTargetsSet = new Set<string>([
          ...selectedPublishTargets,
          ...displayedPublishableUuids,
        ]);
        setSelectedPublishTargetsSet(selectedPublishTargetsSet);
      }
    },
    [displayedPublishableUuids, selectedPublishTargets],
  );

  const onCheck = useCallback(
    (uuid: string, checked: boolean) => {
      if (checked) {
        //チェックしたデータを選択対象に追加する
        setSelectedPublishTargetsSet(
          new Set([...selectedPublishTargets, uuid]),
        );
      } else {
        //チェックを外したデータを選択対象から外す
        const filteredTargetsSet = new Set(
          selectedPublishTargets.filter((targetUuid) => targetUuid !== uuid),
        );
        setSelectedPublishTargetsSet(filteredTargetsSet);
      }
    },
    [selectedPublishTargets],
  );

  return {
    selectedPublishTargets,
    setSelectedPublishTargetsSet,
    resetSelectedPublishTargets,
    hasSelectedTargets,
    countOfSelectedTargets,
    toggleAllDisplayedPublishTargets,
    onCheck,
  };
};
