import { all, fork } from "redux-saga/effects";

import API from "../Services/APIService";
import apiSaga from "./ApiSaga";
import archivesSaga from "./ArchiveSaga";
import asyncSaga from "./AsyncSaga";
import authSaga from "./AuthSaga";
import BatchExaminationSaga from "./BatchExaminationSaga";
import BatchSaga from "./BatchSaga";
import commentSaga from "./CommentSaga";
import companySaga from "./CompanySaga";
import eventSaga from "./EventSaga";
import filesSaga from "./FilesSaga";
import homeSaga from "./HomeSaga";
import IndustryMainCategorySaga from "./IndustryMainCategorySaga";
import IndustrySaga from "./IndustrySaga";
import IndustrySubCategorySaga from "./IndustrySubCategorySaga";
import NewsCommentSaga from "./NewsCommentSaga";
import NewsSaga from "./NewsSaga";
import projectSaga from "./ProjectSaga";
import teamSaga from "./TeamSaga";
import ThreadSaga from "./ThreadSaga";
import userProfileSaga from "./UserSaga";

export const api = API.create();
export type API = typeof api;

export default function* rootSaga() {
  yield all([
    // @ts-expect-error TS7057
    yield fork(asyncSaga, api),
    // @ts-expect-error TS7057
    yield fork(authSaga, api),
    // @ts-expect-error TS7057
    yield fork(userProfileSaga, api),
    // @ts-expect-error TS7057
    yield fork(projectSaga, api),
    // @ts-expect-error TS7057
    yield fork(eventSaga, api),
    // @ts-expect-error TS7057
    yield fork(commentSaga, api),
    // @ts-expect-error TS7057
    yield fork(teamSaga, api),
    // @ts-expect-error TS7057
    yield fork(filesSaga, api),
    // @ts-expect-error TS7057
    yield fork(archivesSaga, api),
    // @ts-expect-error TS7057
    yield fork(homeSaga, api),
    // @ts-expect-error TS7057
    yield fork(apiSaga, api),
    // @ts-expect-error TS7057
    yield fork(companySaga, api),
    // @ts-expect-error TS7057
    yield fork(ThreadSaga, api),
    // @ts-expect-error TS7057
    yield fork(NewsSaga, api),
    // @ts-expect-error TS7057
    yield fork(NewsCommentSaga, api),
    // @ts-expect-error TS7057
    yield fork(IndustrySaga, api),
    // @ts-expect-error TS7057
    yield fork(IndustryMainCategorySaga, api),
    // @ts-expect-error TS7057
    yield fork(IndustrySubCategorySaga, api),
    // @ts-expect-error TS7057
    yield fork(BatchSaga, api),
    // @ts-expect-error TS7057
    yield fork(BatchExaminationSaga, api),
  ]);
}
