type TimeFormat = "yyyy-MM-dd" | "yyyy-MM-dd HH:mm" | "yyyy-MM-dd HH:mm:ss";

/**
 * 条件に応じて、データフォーマットを示す文字列を返します。
 */
export const getFormat = (
  showHour: boolean,
  showSecond: boolean,
): TimeFormat => {
  const baseFormat = "yyyy-MM-dd";
  const hourAndMinuteFormat = "HH:mm";
  const secondFormat = ":ss";
  if (showSecond) {
    return `${baseFormat} ${hourAndMinuteFormat}${secondFormat}`;
  }
  if (showHour) {
    return `${baseFormat} ${hourAndMinuteFormat}`;
  }
  return baseFormat;
};
