import { CloseOutlined } from "@ant-design/icons";
import { Card, Col, notification, Row } from "antd";
import React from "react";
import { useIntl } from "react-intl";
import { connect } from "react-redux";

import { ReduxDispatch, ReduxState } from "@/Redux/types";

import { ButtonUndo } from "../../../../Components/atoms";
import { SearchField } from "../../../../Components/molecules";
import { NewsCreateForm, NewsFormData } from "../../../../Components/organisms";
import ApiActions, {
  ApiRequestData,
  ApiSuccessCallback,
} from "../../../../Redux/ApiRedux";
import { endpoints, parseEndpoint } from "../../../../Services/endpoints";
import createFormData from "../../../../utils/createFormData";
type ActionProps = {
  getNews: () => void;
  dispatchApiRequest: () => void;
};
type StateProps = {
  auth: Object;
};
type Props = StateProps & ActionProps;
function NewsCreate(props: Props) {
  const [showForm, setShowForm] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const intl = useIntl();
  const handleSubmit = (data: NewsFormData) => {
    setLoading(true);
    const requestBuilder = {
      method: "postRequest",
      url: parseEndpoint(endpoints.news),
      data: createFormData(data),
    };
    // @ts-expect-error TS2554
    props.dispatchApiRequest(requestBuilder, ({ data }) => {
      notification.success({
        message: intl.formatMessage({
          id: "screen.label.added_news_item",
        }),
      });
      props.getNews();
      setLoading(false);
      setShowForm(!showForm);
    });
  };
  const onShowForm = () => {
    setShowForm(!showForm);
  };
  const renderTitle = () => {
    return (
      <Row align="middle" justify="space-between">
        <Col>
          <h3 style={{ fontWeight: "bold" }}>
            {intl.formatMessage({
              id: "screen.label.news_post",
            })}
          </h3>
        </Col>
        {showForm && (
          <Col>
            <ButtonUndo
              size="small"
              onClick={onShowForm}
              icon={<CloseOutlined />}
            />
          </Col>
        )}
      </Row>
    );
  };
  return (
    <Card>
      <div style={{ maxWidth: 640 }}>
        {renderTitle()}
        <br />
        {showForm ? (
          <NewsCreateForm
            item={null}
            // @ts-expect-error TS2339
            user={props.auth.payload}
            companies={[]}
            isGlobal={false}
            onSubmit={handleSubmit}
            loading={loading}
          />
        ) : (
          <Row onClick={onShowForm}>
            <Col span={18}>
              <SearchField
                placeholder={intl.formatMessage({
                  id: "screen.label.enter_post_comment",
                })}
                enterButton={intl.formatMessage({
                  id: "screens.button.post",
                })}
                onFocus={(event) => {
                  event.stopPropagation();
                }}
              />
            </Col>
          </Row>
        )}
      </div>
    </Card>
  );
}
const mapStateToProps = (state: ReduxState) => ({
  auth: state.auth,
});
const mapDispatchToProps = (dispatch: ReduxDispatch) => ({
  dispatchApiRequest: (data: ApiRequestData, callback: ApiSuccessCallback) =>
    dispatch(ApiActions.apiRequest(data, callback)),
});
// @ts-expect-error TS2345
export default connect(mapStateToProps, mapDispatchToProps)(NewsCreate);
