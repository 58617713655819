import { Col } from "antd";
import styled from "styled-components";

export const FormHelp = styled.div`
  margin-bottom: 3vh;
`;

export const Ptag = styled.p`
  margin-bottom: 0px;
`;

export const FCFSColumn = styled(Col)`
  padding-top: 7px;
`;
