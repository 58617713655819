import { Form as AntForm, Input as TextInput, InputProps } from "antd";
import React from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";

export const Form = styled(AntForm)``;
export type Props = {
  title?: string;
  rows?: number;
  enterButton?: React.ReactNode;
  children?: React.ReactNode;
} & InputProps;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
`;

export const InputTitle = styled.span`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  color: #323232;
  margin-bottom: 8px;
`;

export const InputDefault = styled(TextInput)`
  font-size: 14px;
  padding: 10px;
  border-radius: 4px;
  background: #fafafa !important;
  border-color: #bbbbbb !important;
  max-width: ${(props) =>
    props.size === "middle"
      ? "640px"
      : props.size === "small"
      ? "400px"
      : "100%"};
  min-height: 46px;
`;

export const TextAreaDefault = styled(TextInput.TextArea)`
  font-size: 14px;
  padding: 10px;
  border-radius: 4px;
  background: #fafafa !important;
  border-color: #bbbbbb !important;
  width: ${(props) =>
    props.size === "large"
      ? "640px"
      : props.size === "small"
      ? "400px"
      : "100%"};
`;

type SearchDefaultArgs = Parameters<typeof TextInput.Search>;

export const SearchDefault = (...[props]: SearchDefaultArgs) => (
  <TextInput.Search style={{ width: "400px" }} {...props} />
);

export const Password = styled(TextInput.Password)``;

export const Input = (props: Props) => {
  const intl = useIntl();
  return (
    <InputContainer>
      {props.title && props.title !== "" && (
        <InputTitle>{props.title}</InputTitle>
      )}
      <InputDefault
        {...props}
        placeholder={
          props.placeholder ||
          intl.formatMessage({
            id: "screen.label.please_enter",
          })
        }
      />
    </InputContainer>
  );
};

export const TextArea = (props: Props) => {
  const intl = useIntl();
  return (
    <InputContainer>
      {props.title && props.title !== "" && (
        <InputTitle>{props.title}</InputTitle>
      )}
      {/*
       // @ts-expect-error TS2769 */}
      <TextAreaDefault
        {...props}
        placeholder={
          props.placeholder ||
          intl.formatMessage({
            id: "screen.label.please_enter",
          })
        }
      />
    </InputContainer>
  );
};

export const SearchField = (props: Props) => {
  const intl = useIntl();
  return (
    <SearchDefault
      {...props}
      placeholder={
        props.placeholder ||
        intl.formatMessage({
          id: "screen.label.please_enter_keyword",
        })
      }
      enterButton={
        props.enterButton ||
        intl.formatMessage({
          id: "screen.label.retrieval",
        })
      }
    />
  );
};
