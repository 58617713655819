import { PlusOutlined } from "@ant-design/icons";
import { Form, List, Space } from "antd";
import { FC } from "react";
import { FormattedMessage } from "react-intl";

import { ButtonCV, Tag } from "../../../../Components/atoms";
import {
  Input,
  Option,
  Select,
  TextArea,
} from "../../../../Components/molecules";
import { UserTypeDropdown } from "../../../../features/user/components/UserTypeDropdown";
import * as constants from "../../../../utils/constants";
import useProfileFormComponent from "./hook";
import ProfileEmailForm from "./ProfileEmailForm";
import ProfilePasswordForm from "./ProfilePasswordForm";
import ProfileSubEmail from "./ProfileSubEmail";
import { ProfileFormProps } from "./type";

export const ProfileForm: FC<ProfileFormProps> = function (props) {
  const {
    intl,
    state,
    handleChange,
    handleSubmit,
    handleSubmitPassword,
    onNewInterestTyped,
    onStartTypeNewInterest,
    onChangeInterest,
  } = useProfileFormComponent(props);

  const renderUserWithdrawalStatus = () => {
    if (props.userDetails.deletedAt) {
      return (
        <Tag color={"red"}>
          {intl.formatMessage({
            id: "screen.label.delete",
          })}
        </Tag>
      );
    }
    if (props.userDetails.stoppedAt) {
      return (
        <Tag color={"magenta"}>
          {intl.formatMessage({
            id: "screen.label.stop",
          })}
        </Tag>
      );
    }
  }; //
  const _renderProjects = () => {
    if (state.projects) {
      return (
        <Form.Item
          label={intl.formatMessage({
            id: "screen.label.affiliation_project",
          })}
        >
          <List
            size="small"
            dataSource={state.projects}
            renderItem={(item) => (
              <List.Item
                key={item.id}
                style={{ borderBottom: `1px solid #dddddd` }}
              >
                {item.name}
              </List.Item>
            )}
          />
        </Form.Item>
      );
    }
  }; //
  const _renderInterest = () => {
    // @ts-expect-error TS2339
    const { interestInputFieldVisible, interestNewInputField, interests } =
      state;
    // @ts-expect-error TS7006
    const interestTags = interests?.map((interestData) => {
      const selectedClass = state.myInterests?.includes(interestData.id)
        ? "tag-selected"
        : "";
      return (
        <Tag
          className={`wrap-tag-text clickable-tag ${selectedClass}`}
          onClick={() => onChangeInterest(interestData)}
          key={`interest2-${interestData.id}`}
        >
          {interestData.name}
        </Tag>
      );
    });
    const inputTag = interestInputFieldVisible ? (
      <Input
        type="text"
        style={{ width: "50%", padding: "4px 10px" }}
        value={interestNewInputField}
        onChange={(e) => handleChange("interestNewInputField", e.target.value)}
        onBlur={onNewInterestTyped}
        onPressEnter={onNewInterestTyped}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={true}
      />
    ) : (
      <Tag
        className="clickable-tag"
        onClick={onStartTypeNewInterest}
        style={{ background: "transparent", borderStyle: "dashed" }}
      >
        <PlusOutlined />
        <FormattedMessage id="screen.label.new_interest" />
      </Tag>
    );
    interestTags.push(inputTag);
    return interestTags;
  }; //
  const _renderDepartment = () => {
    if (props.companyDetails) {
      if (props.companyDetails.selectedInputType === "input") {
        return (
          <Input
            type="text"
            placeholder={intl.formatMessage({
              id: "screen.label.department",
            })}
            // @ts-expect-error TS2322
            value={state.department}
            onChange={(event) => handleChange("department", event.target.value)}
          />
        );
      } else if (
        props.companyDetails.selectedInputType === "dropdown" &&
        Array.isArray(props.companyDetails.Department)
      ) {
        const { Department } = props.companyDetails;
        return (
          <Select
            defaultValue={props.userDetails.departmentId}
            onChange={(value) => {
              handleChange("departmentId", value);
              const departments = Department.filter((singleD) => {
                return singleD.id === value;
              });
              if (departments.length > 0) {
                handleChange("department", departments[0].name);
              }
            }}
          >
            {Department.map((department) => {
              return (
                <Option key={department.id} value={department.id}>
                  {department.name}
                </Option>
              );
            })}
          </Select>
        );
      }
    }
  }; //
  // @ts-expect-error TS7034
  const options = [];
  const subsidiaries = Array.isArray(state.subsidiaries)
    ? state.subsidiaries
    : [];
  subsidiaries.forEach((o) => {
    options.push(
      <Option key={o} value={o}>
        {o}
      </Option>,
    );
  });
  // @ts-expect-error TS7034
  const userTagoptions = [];
  const userTags = Array.isArray(state.userTags) ? state.userTags : [];
  userTags.forEach((o) => {
    userTagoptions.push(
      <Option key={o} value={o}>
        {o}
      </Option>,
    );
  });
  let tagOptions = <></>;
  if (props.authUser && props.authUser.type === "admin") {
    tagOptions = (
      <Form.Item label={constants.userTags}>
        <Select
          placeholder=""
          onChange={(value) => handleChange("userTag", value)}
          defaultValue={state.userTag}
        >
          {/*
           // @ts-expect-error TS7005 */}
          {userTagoptions}
        </Select>
      </Form.Item>
    );
  }

  return (
    <Form
      layout={"vertical"}
      className="profile-form"
      name="basic"
      onFinish={handleSubmit}
    >
      <Space size={32} direction={"vertical"}>
        <Form.Item>{renderUserWithdrawalStatus()}</Form.Item>
        <Form.Item
          label={intl.formatMessage({
            id: "screens.title.username",
          })}
        >
          <Input
            id={"alpha_username"}
            value={state.name}
            placeholder={intl.formatMessage({
              id: "screens.title.username",
            })}
            onChange={(event) => handleChange("name", event.target.value)}
          />
        </Form.Item>
        <ProfileEmailForm
          refreshUser={props.refreshUser}
          apiRequest={props.dispatchApiRequest}
          email={state.loginEmail?.email}
          userId={props.userDetails.id}
          companyId={props.userDetails.companyId}
          verified={state.loginEmail?.verified}
        />
        {tagOptions}
        {props.authUser ? (
          <UserTypeDropdown
            // @ts-expect-error TS2322
            loginUserType={props.authUser.type}
            isSuper={props.authUser.isSuper}
            // @ts-expect-error TS2322
            defaultValue={
              props.userDetails.isSuper ? "isSuper" : props.userDetails.type
            }
            onChange={(value) => {
              if (value === "isSuper") {
                handleChange("isSuper", true);
                handleChange("type", "admin");
              } else {
                handleChange("isSuper", false);
                handleChange("type", value);
              }
            }}
          />
        ) : null}
        <ProfilePasswordForm onChangePassword={handleSubmitPassword} />
        <ProfileSubEmail
          apiRequest={props.dispatchApiRequest}
          userId={props.userDetails.id}
          companyId={props.userDetails.companyId}
          subEmails={state.subEmails}
        />

        <Form.Item
          label={intl.formatMessage({
            id: "screen.label.contract_companies",
          })}
        >
          <span>{props.companyDetails.name}</span>
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({
            id: "screen.label.affiliation_companies",
          })}
        >
          <Select
            placeholder={intl.formatMessage({
              id: "screen.label.affiliation_companies",
            })}
            onChange={(value) => handleChange("subsidiary", value)}
            defaultValue={state.subsidiary}
          >
            {/*
             // @ts-expect-error TS7005 */}
            {options}
          </Select>
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({
            id: "screen.label.department",
          })}
        >
          {_renderDepartment()}
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({
            id: "screen.label.position",
          })}
        >
          <Input
            type="text"
            placeholder={intl.formatMessage({
              id: "screen.label.position",
            })}
            // @ts-expect-error TS2322
            value={state.role}
            onChange={(event) => handleChange("role", event.target.value)}
          />
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({
            id: "screen.label.main_work_experience",
          })}
          help={intl.formatMessage({
            id: "screen.label.maximum_1000_characters",
          })}
        >
          <TextArea
            rows={7}
            value={state.workHistorySummary}
            onChange={(event) =>
              handleChange("workHistorySummary", event.target.value)
            }
          />
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({
            id: "screen.label.profile",
          })}
          help={intl.formatMessage({
            id: "screen.label.maximum_1000_characters",
          })}
        >
          <TextArea
            placeholder={intl.formatMessage({
              id: "screen.label.user_profile_interest_des",
            })}
            rows={7}
            value={state.personalBio}
            onChange={(event) =>
              handleChange("personalBio", event.target.value)
            }
          />
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({
            id: "screen.label.interest",
          })}
        >
          <div style={{ color: "gray", fontSize: "0.8rem" }}>
            <p>
              {intl.formatMessage({
                id: "screen.label.user_profile_interest_des1",
              })}
            </p>
            <p>
              {intl.formatMessage({
                id: "screen.label.example",
              })}
              &nbsp;&nbsp;
              {intl.formatMessage({
                id: "screen.label.user_profile_interest_des2",
              })}
            </p>
            <p>
              &nbsp;&nbsp;&nbsp;&nbsp;{" "}
              {intl.formatMessage({
                id: "screen.label.user_profile_interest_des3",
              })}
            </p>
            <p>
              &nbsp;&nbsp;&nbsp;&nbsp;{" "}
              {intl.formatMessage({
                id: "screen.label.user_profile_interest_des4",
              })}
            </p>
          </div>
          {_renderInterest()}
        </Form.Item>
        {_renderProjects()}
        <Form.Item>
          <ButtonCV id={"alpha_updateProfile"} htmlType="submit">
            <FormattedMessage id="screen.label.storage" />
          </ButtonCV>
        </Form.Item>
      </Space>
    </Form>
  );
};
