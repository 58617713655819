import { zodResolver } from "@hookform/resolvers/zod";
import { Modal, Popconfirm } from "antd";
import { ChangeEvent, FC } from "react";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";

import {
  useIpRangeRequest,
  useLoginTypeRequest,
} from "@/features/company/hooks";
import { SecondaryButton } from "@/shared/buttons/components/SecondaryButton";
import { TertiaryButton } from "@/shared/buttons/components/TertiaryButton";
import { ErrorMessage } from "@/shared/label/ErrorMessage";
import { RadioButton } from "@/shared/radioButtons/components/RadioButton";

import { IpRangeSchema, ipRangeSchema } from "./schema";
import styles from "./styles.module.scss";

type IpRangeSettingModalProps = {
  uuid: string;
  name: string;
  visible: boolean;
  handleClose: () => void;
};

export const IpRangeSettingModal: FC<IpRangeSettingModalProps> = ({
  uuid,
  name,
  visible,
  handleClose,
}) => {
  const intl = useIntl();
  const ipAddressLimitLabel = intl.formatMessage({
    id: "screen.label.ip_address_limit",
  });
  const enable = intl.formatMessage({ id: "screen.label.enable" });
  const disable = intl.formatMessage({ id: "screen.label.disable" });
  const ipAddressList = intl.formatMessage({
    id: "screen.label.ip_address_list",
  });
  const deleteLabel = intl.formatMessage({ id: "screen.label.delete" });
  const addIpAddressLabel = intl.formatMessage({
    id: "screen.label.add_ip_address",
  });
  const addLabel = intl.formatMessage({ id: "screen.label.add" });
  const ipAddressErrorLabel = intl.formatMessage({
    id: "screen.label.ip_address_error_message",
  });
  const cidrErrorLabel = intl.formatMessage({
    id: "screen.label.cidr_error_message",
  });
  const deleteConfirmText = intl.formatMessage({
    id: "screen.label.comment_deletion_confirm",
  });
  const yesText = intl.formatMessage({ id: "screen.label.yes" });
  const noText = intl.formatMessage({ id: "screen.label.no" });

  const { ipRanges, putIpRangeRequest, deleteIpRangeRequest } =
    useIpRangeRequest(uuid);
  const { loginType, putLoginTypeRequest } = useLoginTypeRequest(uuid);

  const onChangeLoginType = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value !== "ip" && value !== "public") return;
    putLoginTypeRequest(value);
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<IpRangeSchema>({
    mode: "onTouched",
    resolver: zodResolver(ipRangeSchema),
  });

  return (
    <Modal
      width={400}
      title={name}
      visible={visible}
      footer={null}
      onCancel={() => {
        handleClose();
        reset();
      }}
    >
      <div className={styles.modal_container}>
        <div className={styles.section_container}>
          <h3 className={styles.section_title}>{ipAddressLimitLabel}</h3>
          <div className={styles.login_type_container}>
            {loginType && (
              <>
                <RadioButton
                  checked={loginType === "ip"}
                  name="loginType"
                  value="ip"
                  label={enable}
                  onChange={onChangeLoginType}
                />
                <RadioButton
                  checked={loginType === "public"}
                  name="loginType"
                  value="public"
                  label={disable}
                  onChange={onChangeLoginType}
                />
              </>
            )}
          </div>
        </div>
        <div className={styles.section_container}>
          <h3 className={styles.section_title}>{ipAddressList}</h3>
          <ul className={styles.list_container}>
            {ipRanges?.map((ipRange) => (
              <li className={styles.list_items} key={ipRange}>
                {ipRange}
                <Popconfirm
                  title={deleteConfirmText}
                  onConfirm={() => {
                    deleteIpRangeRequest(ipRange);
                  }}
                  okText={yesText}
                  cancelText={noText}
                >
                  <TertiaryButton className={styles.delete_button}>
                    {deleteLabel}
                  </TertiaryButton>
                </Popconfirm>
              </li>
            ))}
          </ul>
        </div>
        <div className={styles.section_container}>
          <h3 className={styles.section_title}>{addIpAddressLabel}</h3>
          <form onSubmit={handleSubmit(putIpRangeRequest)}>
            <div className={styles.add_ip_container}>
              <input
                className={`${styles.add_ip_input} ${
                  styles.add_ip_address_input
                } ${errors.ipAddress && styles.add_ip_input_error}`}
                placeholder="123.456.789.10"
                aria-label="ipAddress"
                {...register("ipAddress")}
              />
              {`/`}
              <input
                className={`${styles.add_ip_input} ${
                  styles.add_ip_cidr_input
                } ${errors.cidrPrefix && styles.add_ip_input_error}`}
                {...register("cidrPrefix", { valueAsNumber: true })}
                aria-label="cidrPrefix"
                placeholder="24"
              />
              <SecondaryButton type="submit">{addLabel}</SecondaryButton>
            </div>
            <div>
              <ErrorMessage>
                {errors.ipAddress && ipAddressErrorLabel}
              </ErrorMessage>
            </div>
            <div>
              <ErrorMessage>{errors.cidrPrefix && cidrErrorLabel}</ErrorMessage>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};
