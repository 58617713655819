import { ComponentProps, FC } from "react";
import { useIntl } from "react-intl";

import styles from "./styles.module.scss";

type RequiredLabelProps = ComponentProps<"span">;

export const RequiredLabel: FC<RequiredLabelProps> = ({
  className,
  ...props
}) => {
  const intl = useIntl();
  const label = intl.formatMessage({ id: "screen.label.required" });

  return (
    <span {...props} className={`${styles.label} ${className}`}>
      {label}
    </span>
  );
};
