import { useCallback, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { ApiRequestData } from "@/Redux/ApiRedux";

import { ContentContext } from "../../../../Provider";
import { endpoints, parseEndpoint } from "../../../../Services/endpoints";
import ProjectTaskModal from "../ProjectTaskModal";

type Props = {
  showModal: boolean;
  task?: any | null;
  projectId: string;
  onHideModal: () => void;
  onCreatedTask: (id: number) => void;
};
const RelatedTaskCreation = (props: Props) => {
  const { showModal, task, projectId, onCreatedTask, onHideModal } = props;
  const context = useContext(ContentContext);
  // @ts-expect-error TS2339
  const teams = useSelector((state) => state.teams);
  const [timelimit, setTimelimit] = useState(null);
  const [status, setStatus] = useState(null);
  const [priority, setPriority] = useState(null);
  const [description, setDescription] = useState(null);
  const [title, setTitle] = useState(null);
  const [incharge, setIncharge] = useState([]);

  const { apiRequest } = context;

  useEffect(() => {
    if (task) {
      setTimelimit(task.timelimit);
      setStatus(task.status);
      setPriority(task.priority);
      setDescription(task.description);
      setTitle(task.title);
      setIncharge(task.incharge);
    }
  }, [task]);

  const handleChange = (eventType: string, value: any) => {
    if (eventType == "timelimit") {
      setTimelimit(value);
    } else if (eventType == "status") {
      setStatus(value);
    } else if (eventType == "priority") {
      setPriority(value);
    } else if (eventType == "description") {
      setDescription(value);
    } else if (eventType == "title") {
      setTitle(value);
    } else if (eventType == "incharge") {
      setIncharge(value);
    }
  };

  const handleCreateTask = useCallback(() => {
    if (task) {
      const requestBuilder: ApiRequestData = {
        method: "putRequest",
        url: parseEndpoint(endpoints.updateProjectTask, {
          id: projectId,
          taskId: task.id,
        }),
        data: {
          title,
          description,
          incharge,
          timelimit,
          priority,
          status,
        },
      };
      apiRequest(requestBuilder, ({ data }) => {
        onCreatedTask(task.id);
        handleCancel();
      });
    } else {
      const requestBuilder: ApiRequestData = {
        method: "postRequest",
        url: parseEndpoint(endpoints.createProjectTaskForCheckpoint, {
          projectId,
        }),
        data: {
          title,
          description,
          incharge,
          timelimit,
          priority,
          status: "PENDING",
        },
      };
      apiRequest(requestBuilder, ({ data }) => {
        // @ts-expect-error TS18046
        onCreatedTask(data.id);
        handleCancel();
      });
    }
  }, [
    task,
    title,
    description,
    incharge,
    timelimit,
    priority,
    projectId,
    status,
    onCreatedTask,
  ]);

  const handleCancel = useCallback(() => {
    if (task) {
      setTimelimit(task.timelimit);
      setStatus(task.status);
      setPriority(task.priority);
      setDescription(task.description);
      setTitle(task.title);
      setIncharge(task.incharge);
    } else {
      setTimelimit(null);
      setStatus(null);
      setPriority(null);
      setDescription(null);
      setTitle(null);
      setIncharge([]);
    }
    onHideModal();
  }, [task, onHideModal]);

  return (
    <ProjectTaskModal
      timelimit={timelimit}
      status={status}
      priority={priority}
      description={description}
      title={title}
      showModal={showModal}
      incharge={incharge}
      isCreating={task ? false : true}
      teamMembers={teams.teamMembersPayload.rows}
      handleChange={handleChange}
      handleOk={handleCreateTask}
      handleCancel={handleCancel}
    />
  );
};

export default RelatedTaskCreation;
