import { Layout } from "antd";
import React, { Suspense } from "react";
import { connect } from "react-redux";
import { Route, withRouter } from "react-router-dom";

import { ReduxDispatch, ReduxState } from "@/Redux/types";

import CustomLoader from "../../Components/atoms/CustomLoader";
import { NavbarUser } from "../../Components/organisms";
import ApiActions, {
  ApiRequestData,
  ApiSuccessCallback,
} from "../../Redux/ApiRedux";
import AuthActions from "../../Redux/AuthRedux";
import GlobalReferenceList from "../reference/global/GlobalReferenceList";
import IndustryReportsScreen from "./industry-report/global";
import IndustryReportCreateScreen from "./industry-report/global/create";
import IndustryReportDetailScreen from "./industry-report/global/detail";
import IndustryReportUpdateScreen from "./industry-report/global/update";
import ManagementScreen from "./manage";
import NewsScreen from "./news/global";
import NewsCreateScreen from "./news/global/create";
import NewsDetailScreen from "./news/global/detail";
import NewsUpdateScreen from "./news/global/update";

const { Header, Content } = Layout;
type ActionProps = {
  dispatchSetBaseUrl: () => void;
  dispatchLogoutUser: () => void;
  dispatchSetAuthToken: () => void;
  dispatchApiRequest: () => void;
};
type StateProps = {
  auth: Object;
  pageTitle: string;
};
type Props = StateProps & ActionProps;
function LibraryScreen(props: Props) {
  const logUserOut = () => {
    props.dispatchLogoutUser();
    // @ts-expect-error TS2554
    props.dispatchSetBaseUrl(process.env.REACT_APP_BASEHOST);
  };
  React.useEffect(() => {
    // @ts-expect-error TS2554
    props.dispatchSetAuthToken(props.auth.payload.token);
  }, [props.auth]);
  return (
    <Layout
      style={{
        backgroundColor: "#f5f5f5",
        height: "100%",
        minHeight: "100vh",
      }}
    >
      <Layout>
        <Header>
          <NavbarUser
            pageTitle={props.pageTitle}
            // @ts-expect-error TS2339
            userDetails={props.auth.payload}
            logoutUser={logUserOut}
            showSwitchLang={false}
          />
        </Header>
        <Suspense fallback={<CustomLoader />}>
          <Content style={{ padding: "3% 5% 3% 5%" }}>
            <Route
              path={"/global-library"}
              exact
              component={ManagementScreen}
            />
            <Route
              path={"/global-library/reference"}
              exact
              component={GlobalReferenceList}
            />
            <Route path={"/global-library/news"} exact component={NewsScreen} />
            <Route
              path={"/global-library/news/:id"}
              exact
              component={NewsDetailScreen}
            />
            <Route
              path={"/global-library/news-create"}
              exact
              component={NewsCreateScreen}
            />
            <Route
              path={"/global-library/news-update/:id"}
              exact
              component={NewsUpdateScreen}
            />
            <Route
              path={"/global-library/industry-report"}
              exact
              component={IndustryReportsScreen}
            />
            <Route
              path={"/global-library/industry-report/:id"}
              exact
              component={IndustryReportDetailScreen}
            />
            <Route
              path={"/global-library/industry-report-create"}
              exact
              component={IndustryReportCreateScreen}
            />
            <Route
              path={"/global-library/industry-report-update/:id"}
              exact
              component={IndustryReportUpdateScreen}
            />
          </Content>
        </Suspense>
      </Layout>
    </Layout>
  );
}
const mapStateToProps = (state: ReduxState) => ({
  pageTitle: state.dashboard.pageTitle,
  auth: state.auth,
});
const mapDispatchToProps = (dispatch: ReduxDispatch) => ({
  // @ts-expect-error TS7006
  dispatchSetBaseUrl: (url) => dispatch(AuthActions.loginSetBaseUrl(url)),
  dispatchLogoutUser: () => dispatch(AuthActions.logout()),
  // @ts-expect-error TS7006
  dispatchSetAuthToken: (token) =>
    dispatch(AuthActions.loginSetAuthToken(token)),
  dispatchApiRequest: (data: ApiRequestData, callback: ApiSuccessCallback) =>
    dispatch(ApiActions.apiRequest(data, callback)),
});
export default withRouter(
  // @ts-expect-error TS2345
  connect(mapStateToProps, mapDispatchToProps)(LibraryScreen),
);
