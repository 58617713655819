import { Button, Card, Col, Form, Input, Row } from "antd";
import { FC, useContext, useState } from "react";
// @ts-expect-error TS7016
import { ChromePicker } from "react-color";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { ContentContext } from "@/Provider";
import CompanyActions from "@/Redux/CompanyRedux";
import FileUploadComponent from "@/Screens/project/FileUploadComponent";
import { endpoints, parseEndpoint } from "@/Services/endpoints";
import createFormData from "@/utils/createFormData";

export type ImageSettingsCardProps = {
  //
};

export const ImageSettingsCard: FC<ImageSettingsCardProps> = () => {
  const intl = useIntl();
  const context = useContext(ContentContext);
  const dispatch = useDispatch();

  // @ts-expect-error TS2339
  const company = useSelector((state) => state.company.companyPayload);

  const [bannerImage, setBannerImage] = useState<File>();
  const [icon, setIcon] = useState<File>();
  const [bannerImageBackgroundColor, setBannerImageBackgroundColor] =
    useState<string>(company.bannerImageBackgroundColor ?? "#6f49f5");
  const [bannerLink, setBannerLink] = useState<string>(company.bannerLink);

  const onSubmitBanner = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    const requestData = {
      bannerImage,
      icon,
      companyId: context.user.company.id,
      bannerImageBackgroundColor,
    };
    if (bannerLink) {
      // @ts-expect-error TS2339
      requestData.bannerLink = bannerLink.trim().startsWith("http")
        ? bannerLink
        : `https://${bannerLink}`;
    }
    for (const propName in requestData) {
      if (
        // @ts-expect-error TS7053
        requestData[propName] === null ||
        // @ts-expect-error TS7053
        requestData[propName] === undefined
      ) {
        // @ts-expect-error TS7053
        delete requestData[propName];
      }
    }
    const requestBuilder = {
      method: "putRequest",
      url: parseEndpoint(endpoints.settings),
      successMessage: intl.formatMessage({
        id: "screen.label.updated_image",
      }),
      data: createFormData(requestData),
    };
    // @ts-expect-error TS2345
    context.apiRequest(requestBuilder, () => {
      dispatch(CompanyActions.companyGetRequest());
    });
  };

  return (
    <Card
      title={intl.formatMessage({
        id: "screen.label.image_settings",
      })}
      style={{
        marginBottom: 30,
      }}
    >
      <Row>
        <Col offset={4} span={14}>
          <Form layout={"vertical"}>
            <Form.Item
              label={intl.formatMessage({
                id: "screen.label.banner_link",
              })}
              style={{ marginBottom: 16 }}
            >
              <Input
                onChange={(event) => setBannerLink(event.target.value)}
                value={bannerLink}
                style={{ marginBottom: 10 }}
              />
            </Form.Item>
            <Form.Item
              help={intl.formatMessage({
                id: "screen.label.banner_image_size_recommend",
              })}
              label={intl.formatMessage({
                id: "screen.label.banner_image",
              })}
            >
              <FileUploadComponent
                onFileChanged={(file) => setBannerImage(file)}
              />
            </Form.Item>

            <br />

            <Form.Item
              help={intl.formatMessage({
                id: "screen.label.company_logo_size_recommend",
              })}
              label={intl.formatMessage({
                id: "screen.label.company_logo",
              })}
            >
              <FileUploadComponent onFileChanged={(file) => setIcon(file)} />
            </Form.Item>

            <br />

            <Form.Item
              label={intl.formatMessage({
                id: "screen.label.logo_background_color",
              })}
            >
              <ChromePicker
                color={bannerImageBackgroundColor}
                // @ts-expect-error TS7006
                onChangeComplete={(color) =>
                  setBannerImageBackgroundColor(color.hex)
                }
              />
            </Form.Item>

            <Form.Item
              style={{
                alignItems: "center",
                textAlign: "center",
                display: "grid",
              }}
            >
              <Button type="primary" htmlType="button" onClick={onSubmitBanner}>
                {intl.formatMessage({
                  id: "screens.title.save",
                })}
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Card>
  );
};
