import { PropsWithChildren } from "react";

import { AntdButton } from "../AntdButton";
import { AntdWarningButtonProps } from "./type";

export const AntdWarningButton = ({
  children,
  ...props
}: PropsWithChildren<AntdWarningButtonProps>) => {
  return (
    <AntdButton category="danger" {...props}>
      {children}
    </AntdButton>
  );
};
