import styled from "styled-components";

import { FixedMenuItem } from "./Menus";

export const DrawerContaienr = styled.div`
  width: 205px;
  max-width: 205px;
`;

export const FloatLogoImg = styled.img`
  width: 30px;
  height: 30px;
  object-fit: contain;
  border-radius: 6px;
`;
export const FloatingMenu = styled.div`
  background-color: #323232;
  display: flex;
  height: 73px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
export const PlanDetails = styled.span`
  background-color: transparent;
  border: 1px solid #dddddd;
  border-radius: 2px;
  color: #dddddd;
  font-size: 10px;
  font-weight: 400;
  margin-left: 6px;
  padding: 2px 6px;
  vertical-align: top;
`;

export const FixedLogoMenu = styled(FixedMenuItem)`
  background-color: ${(props) =>
    // @ts-expect-error TS2339
    props.backgroundColor};
`;

export const CompanyLogoContainer = styled.div`
  background-color: ${(props) =>
    // @ts-expect-error TS2339
    props.backgroundColor};
  display: flex;
  height: 73px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const ProjectLogo = styled.div`
  background-color: #475865;
  padding: 15px;
  color: $white-color;
  overflow: hidden;
  align-items: center;
  display: flex;
  height: 73px;
`;

export const ProjectTitle = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1;
  color: #fff;
`;

export const FiexdLogoImg = styled.img`
  width: 100%;
  height: auto;
  object-fit: contain;
  border-radius: 6px;
`;
