import { message, Upload } from "antd";
import { UploadChangeParam, UploadFile } from "antd/lib/upload";
import { FC, useContext } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { InsuPrimaryButton } from "@/Components/atoms/buttons/shared";
import { handleCustomRequest } from "@/Components/molecules";
import { ContentContext } from "@/Provider";
import { ApiRequestData } from "@/Redux/ApiRedux";
import { ReduxState } from "@/Redux/types";
import { endpoints, parseEndpoint } from "@/Services/endpoints";
import { getObjUUID } from "@/utils/common";

import { useParamId } from "../../../../hooks/useParamId";
import { ParsedFileList } from "../ProjectSubmissionModal/container";

type ProjectSubmissionFileUploadProps = {
  fileList: UploadFile[];
  setFileList: (fileList: UploadFile[]) => void;
  parsedFileList: ParsedFileList;
  setParsedFileList: (parsedFileList: ParsedFileList) => void;
};

export const ProjectSubmissionFileUpload: FC<
  ProjectSubmissionFileUploadProps
> = ({ fileList, setFileList, parsedFileList, setParsedFileList }) => {
  const project = useSelector(
    (state: ReduxState) => state.projects.projectPayload,
  );
  const projectId = useParamId();
  const intl = useIntl();
  const { user, apiRequest } = useContext(ContentContext);

  const onSaveUploadedFile = async (responseData: unknown) => {
    if (responseData) {
      const requestBuilder: ApiRequestData = {
        method: "postRequest",
        // @ts-expect-error TS7006
        url: `${process.env.REACT_APP_BASEHOST}companies/${project.companyId}/projects/${projectId}/save-only-upload-project-files`,
        data: responseData,
      };
      return new Promise((resolve, reject) => {
        apiRequest(
          requestBuilder,
          (data) => {
            message.success(
              // @ts-expect-error TS2345
              `${responseData.file.fileName} ${intl.formatMessage({
                id: "screen.label.upload_file_has_been_completed",
              })}`,
            );
            resolve(data);
          },
          (error) => {
            reject(error);
          },
        );
      });
    }
  };

  const handleFileChange = async (info: UploadChangeParam<UploadFile>) => {
    const newFileList = info.fileList.map((file) => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });
    setFileList(newFileList);

    const changeTargetFile = info.file;
    const { response } = changeTargetFile;

    if (changeTargetFile.status === "done") {
      // save project files
      try {
        const savedRes = await onSaveUploadedFile(response?.data);
        if (savedRes) {
          const savedIdx = newFileList.findIndex(
            (a) => a.uid == changeTargetFile.uid,
          );
          if (savedIdx !== -1) {
            newFileList[savedIdx].response = savedRes;
          }
        }
      } catch (error) {
        //
      }

      const rawFilter = newFileList.filter((singleFile) => {
        return singleFile?.response?.status === "success";
      });

      const parsedFileList = rawFilter.map((singleFile) => {
        return {
          uploadUrl: singleFile.response.data.file.hash,
          uploadedFileId: singleFile.response.data.file.id,
          friendlyFileName: singleFile.name,
        };
      });
      setFileList(rawFilter);
      setParsedFileList(parsedFileList);
    } else if (changeTargetFile.status === "error") {
      if (info?.file?.error?.message) {
        const errorMsg = info?.file?.error?.message;
        message.error(errorMsg);
      } else {
        message.error(`${info.file.name} file upload failed.`);
      }
    }
  };

  const handleFileRemove = (file: UploadFile) => {
    const fileId = file.response.data.file.id;
    const newParsedFileList = parsedFileList.filter((parsedFile) => {
      return parsedFile.uploadedFileId !== fileId;
    });
    setParsedFileList(newParsedFileList);

    const requestBuilder: ApiRequestData = {
      method: "deleteRequest",
      url: parseEndpoint(endpoints.deleteProjectFile, {
        projectId,
        fileId,
      }),
      data: {},
    };
    apiRequest(requestBuilder);
  };

  const handleBeforeUpload = (file: UploadFile) => {
    const limitation = 1024 * 1024 * 1000;
    if (file.size && file.size < limitation) return true;
    message.success(
      `${intl.formatMessage({
        id: "screen.label.file_limit_msg",
      })}`,
    );
    return Upload.LIST_IGNORE;
  };

  const props = {
    name: "fileName",
    action: `${process.env.REACT_APP_BASEHOST}companies/${getObjUUID(
      user.company,
    )}/projects/${projectId}`,
    data: {
      projectId,
    },
    headers: {
      Authorization: `Bearer ${user.token}`,
      alphafrontEndPath: window.location.href,
      details: JSON.stringify({
        type: "project_submission",
        projectId,
      }),
    },
    onChange: handleFileChange,
    onRemove: handleFileRemove,
    customRequest: handleCustomRequest,
    beforeUpload: handleBeforeUpload,
  };
  return (
    // @ts-expect-error TS2345
    <Upload fileList={fileList} {...props}>
      <InsuPrimaryButton>
        {intl.formatMessage({
          id: "screen.label.upload_files",
        })}
      </InsuPrimaryButton>
    </Upload>
  );
};
