import { SearchOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Modal,
  Popover,
  Row,
  Select,
  Tabs,
} from "antd";
import { Table } from "antd";
import type { ColumnsType, ColumnType } from "antd/lib/table";
import moment from "moment";
import { Component } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Immutable from "seamless-immutable";

import { Interest } from "@/../types/Interest";
import { User } from "@/../types/User";
import { UserManagementModal } from "@/features/user/components/UserManagementModal";
import { ModalType } from "@/features/user/components/UserManagementModal/type";
import { ApiRequestData } from "@/Redux/ApiRedux";
import { ReduxDispatch, ReduxState } from "@/Redux/types";

import {
  ButtonCV,
  ButtonOption,
  ButtonUndo,
  IconDelete,
  IconEdit,
  Tag,
} from "../../Components/atoms";
import { CustomPagination } from "../../Components/molecules";
import { Input, Select as Dropdown } from "../../Components/molecules";
import { MemberSearchTerms } from "../../Components/organisms";
import CsvExportModal from "../../Components/organisms/project/CsvExportModal";
import UserActions from "../../Redux/UserRedux";
import { endpoints, parseEndpoint } from "../../Services/endpoints";
import { ProfileForm } from "../../shared/forms/components/ProfileForm";
import { getDisplayName, getObjUUID, getUserAvatar } from "../../utils/common";
import * as constants from "../../utils/constants";
import {
  adminUser,
  dateTimeFormat,
  generalUser,
  mentorUser,
  programManager,
} from "../../utils/constants";
import { getInitStorageValue } from "../../utils/localStorage";
import MembersInactive from "./MembersInactive";
import MembersRecovery from "./MembersRecovery";
import {
  IMembersScreenProps,
  MembersScreenState,
} from "./types/IMembersScreenProps";
const PageActiveSaveId = "MembersScreen_Active";
const activePageKey = `pagination_page_${PageActiveSaveId}`;
const activeLimitKey = `pagination_limit_${PageActiveSaveId}`;
const PageInactiveSaveId = "MembersScreen_InActive";
const inactiviePageKey = `pagination_page_${PageInactiveSaveId}`;
const inactivieLimitKey = `pagination_limit_${PageInactiveSaveId}`;
const { TabPane } = Tabs;
const { Option } = Select;
const membersScreenLocalStorageKeys = "membersScreen";

class MembersScreen extends Component<IMembersScreenProps, MembersScreenState> {
  // @ts-expect-error TS7006
  constructor(props) {
    super(props);
    const persistedState = localStorage.getItem(membersScreenLocalStorageKeys);
    if (persistedState) {
      this.state = JSON.parse(persistedState);
    } else {
      this.state = {
        searchUser: "",
        searchTerm: "",
        showUserSearchModal: false,
        page: getInitStorageValue(activePageKey, 1),
        limit: getInitStorageValue(
          activeLimitKey,
          process.env.REACT_APP_PAGE_SIZE,
        ),
        interest: [],
        departments: [],
        filteredDepartments: [],
        filteredSubsidiaries: [],
        filteredUserTags: [],
        filteredIsSuper: [],
        sortOrder: "descend",
        sortField: "id",
        userDetails: {
          id: 0,
          uuid: "",
          name: "",
          type: "",
          role: null,
          department: null,
          workHistorySummary: null,
          personalBio: null,
          profileImage: null,
          company: {},
          interest: [],
          subsidiary: "",
          userTag: "",
          verified: true,
        },
        activeTab: "active",
        filteredUserType: [],
        searchParams: {
          User: {
            name: "",
            email: "",
            subsidiary: "",
            userTag: "",
            role: "",
            type: "",
            department: "",
            personalBio: "",
            workHistorySummary: "",
          },
          Project: {
            id: "",
          },
          Interest: [],
        },
        allProjects: [],
        searchKeyword: "",
        showEditUserDetailsModal: false,
        showUserManagementModal: false,
        userManagementModalType: undefined,
      };
    }
  }
  componentWillUnmount() {
    localStorage.setItem(
      membersScreenLocalStorageKeys,
      JSON.stringify(this.state),
    );
  }
  performAdvanceSearch = () => {
    const { apiRequest } = this.props.context;
    const requestbuilder: ApiRequestData = {
      method: "postRequest",
      url: parseEndpoint(endpoints.usersAdvanceSearch, {
        page: this.state.page - 1,
        limit: this.state.limit,
      }),
      data: {
        ...this.state.searchParams,
        sortOrder: this.state.sortOrder,
        sortField: this.state.sortField,
      },
    };
    apiRequest(requestbuilder, ({ data }) => {
      this.props.dispatchSetAdvanceSearchResult(data);
    });
  };
  getCompanyAllProject = () => {
    const { apiRequest } = this.props.context;
    const requestbuilder: ApiRequestData = {
      method: "getRequest",
      url: `projects/all`,
      data: {},
    };
    apiRequest(requestbuilder, ({ data }) => {
      this.setState({
        // @ts-expect-error TS2322
        allProjects: data,
      });
    });
  };
  getInterest = () => {
    const { apiRequest } = this.props.context;
    const interestRequest: ApiRequestData = {
      method: "getRequest",
      url: `interests`,
      data: {},
    };
    apiRequest(interestRequest, ({ data }) => {
      this.setState({
        // @ts-expect-error TS2322
        interest: data,
      });
    });
  };
  getDepartments = () => {
    const { apiRequest } = this.props.context;
    const interestRequest: ApiRequestData = {
      method: "getRequest",
      url: `departments`,
      data: {},
    };
    apiRequest(interestRequest, ({ data }) => {
      this.setState({
        // @ts-expect-error TS2322
        departments: data,
      });
    });
  };
  componentDidMount() {
    const { updatePageTitle } = this.props.context;
    updatePageTitle("screen.label.user");
    if (localStorage.getItem(membersScreenLocalStorageKeys) == null) {
      this.performAdvanceSearch();
    }
    this.getInterest();
    this.getDepartments();
    this.getCompanyAllProject();
  }
  componentDidUpdate(
    prevProps: IMembersScreenProps,
    prevState: MembersScreenState,
  ) {
    if (this.state !== prevState) {
      const {
        filteredUserType,
        filteredDepartments,
        filteredSubsidiaries,
        filteredUserTags,
        filteredIsSuper,
        page,
        sortOrder,
        sortField,
      } = this.state;
      let filterExpression = "";
      let sortExpression = "";
      if (
        sortField &&
        sortField !== "" &&
        sortOrder &&
        // @ts-expect-error ts(2367)
        sortOrder !== ""
      ) {
        sortExpression = `${sortField} ${
          sortOrder === "ascend" ? "asc" : "desc"
        }`;
      }
      if (
        filteredDepartments &&
        Array.isArray(filteredDepartments) &&
        filteredDepartments.length > 0
      ) {
        filterExpression = `department | ${filteredDepartments.join("|")}`;
      }
      if (
        filteredSubsidiaries &&
        Array.isArray(filteredSubsidiaries) &&
        filteredSubsidiaries.length > 0
      ) {
        const subSudirFilter = `subsidiary | ${filteredSubsidiaries.join("|")}`;
        const connectFitler =
          filterExpression !== "" ? ` join ${subSudirFilter}` : subSudirFilter;
        filterExpression = `${filterExpression}${connectFitler}`;
      }
      if (
        filteredUserTags &&
        Array.isArray(filteredUserTags) &&
        filteredUserTags.length > 0
      ) {
        const userTagFilter = `userTag | ${filteredUserTags.join("|")}`;
        const connectFitler =
          filterExpression !== "" ? ` join ${userTagFilter}` : userTagFilter;
        filterExpression = `${filterExpression}${connectFitler}`;
      }
      const updatefilteredUserType = filteredUserType.filter(
        (item) => item !== "isSuper",
      );
      if (
        updatefilteredUserType &&
        Array.isArray(updatefilteredUserType) &&
        updatefilteredUserType.length > 0
      ) {
        const userTypeFilter = `type | ${updatefilteredUserType.join("|")}`;
        const connectFitler =
          filterExpression !== "" ? ` join ${userTypeFilter}` : userTypeFilter;
        filterExpression = `${filterExpression}${connectFitler}`;
      }
      if (
        filteredIsSuper &&
        Array.isArray(filteredIsSuper) &&
        filteredIsSuper.length > 0
      ) {
        const userSuperFilter = `isSuper | ${filteredIsSuper.join("|")}`;
        const connectFitler =
          filterExpression !== ""
            ? ` join ${userSuperFilter}`
            : userSuperFilter;
        filterExpression =
          filterExpression = `${filterExpression}${connectFitler}`;
      }
      this.fetchUsers(page, filterExpression, sortExpression);
    }
  }
  fetchUsers = (page: unknown, filter = "", sort = "") => {
    // this.props.dispatchFetchUsers(page - 1, filter, sort)
  };
  getDepartmentfilter = (): Pick<
    ColumnType<User>,
    "filterDropdown" | "onFilter"
  > => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      return (
        <div style={{ padding: 8 }}>
          <Checkbox.Group
            style={{ width: "100%" }}
            value={this.state.filteredDepartments}
            onChange={(value) => {
              if (value) {
                this.handleChangeAdvanceSearch(
                  "department",
                  "User",
                  value.join(","),
                );
              } else {
                this.handleChangeAdvanceSearch("department", "User", "");
              }
              this.setState(
                {
                  page: 1,
                  // @ts-expect-error TS2322
                  filteredDepartments: value,
                },
                () => {
                  this.performAdvanceSearch();
                },
              );
            }}
          >
            <Row>
              {this.state.departments.map((department, index) => {
                return (
                  <Col span={8} key={index}>
                    <Checkbox value={department.name}>
                      {department.name}
                    </Checkbox>
                  </Col>
                );
              })}
            </Row>
          </Checkbox.Group>
          <br />
          <ButtonCV
            onClick={() => {
              // @ts-expect-error TS2722
              clearFilters();
              setSelectedKeys([]);
              this.handleChangeAdvanceSearch("department", "User", "");
              this.setState(
                {
                  filteredDepartments: [],
                  page: 1,
                },
                () => {
                  this.performAdvanceSearch();
                },
              );
            }}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Reset
          </ButtonCV>
        </div>
      );
    },
    // @ts-expect-error TS7006
    onFilter: (value, record) => {},
  });
  getUserTypefilter = (): Pick<
    ColumnType<User>,
    "filterDropdown" | "onFilter"
  > => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      const userTypes = [
        { type: "mentor", name: mentorUser },
        { type: "employee", name: generalUser },
        { type: "admin", name: programManager },
        { type: "isSuper", name: adminUser },
      ];
      return (
        <div style={{ padding: 8 }}>
          <Checkbox.Group
            style={{ width: "100%" }}
            value={this.state.filteredUserType}
            onChange={(value) => {
              const filteredIsSuper = [];
              if (value) {
                this.handleChangeAdvanceSearch("type", "User", value.join(","));
              }
              if (value.includes("isSuper")) {
                filteredIsSuper.push(true);
              }
              this.setState(
                {
                  // @ts-expect-error TS2322
                  filteredUserType: value,
                  // @ts-expect-error TS2322
                  filteredIsSuper,
                  page: 1,
                },
                () => {
                  this.performAdvanceSearch();
                },
              );
            }}
          >
            <Row>
              {userTypes.map((userType, index) => {
                return (
                  <Col span={8} key={index}>
                    <Checkbox value={userType.type}>{userType.name}</Checkbox>
                  </Col>
                );
              })}
            </Row>
          </Checkbox.Group>
          <br />
          <ButtonCV
            onClick={() => {
              // @ts-expect-error TS2722
              clearFilters();
              setSelectedKeys([]);
              const searchParam = Object.assign({}, this.state.searchParams);
              const children = searchParam.User;
              // @ts-expect-error TS2722
              delete children.type;
              delete children.isSuper;
              this.setState(
                {
                  filteredUserType: [],
                  searchParams: searchParam,
                  page: 1,
                },
                () => {
                  this.performAdvanceSearch();
                },
              );
            }}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Reset
          </ButtonCV>
        </div>
      );
    },
    // @ts-expect-error TS7006
    onFilter: (value, record) => {},
  });
  getSubsidiariesfilter = (): Pick<
    ColumnType<User>,
    "filterDropdown" | "onFilter"
  > => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      const { company } = this.props.context;
      const subsidiaries = Array.isArray(company.subsidiaries)
        ? company.subsidiaries
        : [];
      return (
        <div style={{ padding: 8 }}>
          <Checkbox.Group
            style={{ width: "100%" }}
            value={this.state.filteredSubsidiaries}
            onChange={(value) => {
              if (value) {
                this.handleChangeAdvanceSearch(
                  "subsidiary",
                  "User",
                  value.join(","),
                );
              } else {
                this.handleChangeAdvanceSearch("subsidiary", "User", "");
              }
              this.setState(
                {
                  // @ts-expect-error TS2322
                  filteredSubsidiaries: value,
                  page: 1,
                },
                () => {
                  this.performAdvanceSearch();
                },
              );
            }}
          >
            <Row>
              {subsidiaries.map((subsidiaries, index) => {
                return (
                  <Col span={8} key={index}>
                    <Checkbox value={subsidiaries}>{subsidiaries}</Checkbox>
                  </Col>
                );
              })}
            </Row>
          </Checkbox.Group>
          <br />
          <ButtonCV
            onClick={() => {
              // @ts-expect-error TS2722
              clearFilters();
              setSelectedKeys([]);
              this.handleChangeAdvanceSearch("subsidiary", "User", "");
              this.setState(
                {
                  filteredSubsidiaries: [],
                  page: 1,
                },
                () => {
                  this.performAdvanceSearch();
                },
              );
            }}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Reset
          </ButtonCV>
        </div>
      );
    },
    // @ts-expect-error TS7006
    onFilter: (value, record) => {},
  });
  getUserTagsfilter = (): Pick<
    ColumnType<User>,
    "filterDropdown" | "onFilter"
  > => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => {
      const { company } = this.props.context;
      const userTags = Array.isArray(company.userTags) ? company.userTags : [];
      return (
        <div style={{ padding: 8 }}>
          <Checkbox.Group
            style={{ width: "100%" }}
            value={this.state.filteredUserTags}
            onChange={(value) => {
              if (value) {
                this.handleChangeAdvanceSearch(
                  "userTag",
                  "User",
                  value.join(","),
                );
              } else {
                this.handleChangeAdvanceSearch("userTag", "User", "");
              }
              // @ts-expect-error TS2322
              this.setState({ filteredUserTags: value, page: 1 }, () => {
                this.performAdvanceSearch();
              });
            }}
          >
            <Row>
              {userTags.map((filteredUserTags, index) => {
                return (
                  <Col span={8} key={index}>
                    <Checkbox value={filteredUserTags}>
                      {filteredUserTags}
                    </Checkbox>
                  </Col>
                );
              })}
            </Row>
          </Checkbox.Group>
          <br />
          <ButtonCV
            onClick={() => {
              // @ts-expect-error TS2722
              clearFilters();
              setSelectedKeys([]);
              this.handleChangeAdvanceSearch("userTag", "User", "");
              this.setState(
                {
                  filteredUserTags: [],
                  page: 1,
                },
                () => {
                  this.performAdvanceSearch();
                },
              );
            }}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Reset
          </ButtonCV>
        </div>
      );
    },
    // @ts-expect-error TS7006
    onFilter: (value, record) => {},
  });
  _renderAddMemberButton = () => {
    const { user } = this.props.context;
    if (user.type === "admin") {
      return (
        <>
          <Col xs={24} sm={8} md={8} lg={8}>
            <CsvExportModal
              url="/users/export"
              title="screen.label.csv_download"
              btnStyle={{
                float: "right",
              }}
            />
            <ButtonOption
              style={{
                maxWidth: 200,
                float: "right",
                marginRight: 20,
              }}
            >
              <Link to="/dashboard/member.add">
                +
                {this.props.intl.formatMessage({
                  id: "screen.label.add_user",
                })}
              </Link>
            </ButtonOption>
          </Col>
        </>
      );
    }
  };
  onEditUserClick = (userDetails: MembersScreenState["userDetails"]) => {
    this.setState({
      showEditUserDetailsModal: true,
      userDetails: {
        ...userDetails,
        email:
          // @ts-expect-error TS2532
          userDetails.Credential.email,
        verified:
          // @ts-expect-error TS2532
          userDetails.Credential.verified ?? userDetails.verified,
      },
    });
  };
  // @ts-expect-error TS7006
  handleSubmitEditUser = (updatedData) => {
    const { apiRequest } = this.props.context;

    const {
      name,
      role,
      department,
      workHistorySummary,
      personalBio,
      subsidiary,
      userTag,
      interest,
      password,
      departmentId,
      type,
    } = updatedData;
    const data = {
      name,
      role,
      department,
      workHistorySummary,
      personalBio,
      subsidiary,
      userTag,
      interest,
      password,
      departmentId,
      type,
    };
    if (Object.hasOwn(updatedData, "isSuper")) {
      // @ts-expect-error TS2339
      data.isSuper = updatedData.isSuper;
    }
    const updateUserRequest: ApiRequestData = {
      method: "putRequest",
      successMessage: this.props.intl.formatMessage({
        id: "screen.label.user_information_has_been_edited",
      }),
      url: parseEndpoint(endpoints.updateUserProfile, {
        data: {
          id: getObjUUID(this.state.userDetails),
        },
      }),
      data,
    };
    apiRequest(updateUserRequest, ({ data }) => {
      this.performAdvanceSearch();
    });
    this.closeEditUserDetailsModals();
  };
  _renderFirstLoginConditionSelect = () => {
    return (
      <Dropdown
        allowClear
        // @ts-expect-error TS2532
        value={this.state.searchParams.User.firstLogin}
        onChange={(v) => {
          this.handleChangeAdvanceSearch("firstLogin", "User", v);
        }}
      >
        <Option value={"completed_first_login"}>
          {this.props.intl.formatMessage({
            id: "screen.users.completed_first_login",
          })}
        </Option>
        <Option value={"not_completed_first_login"}>
          {this.props.intl.formatMessage({
            id: "screen.users.not_completed_first_login",
          })}
        </Option>
      </Dropdown>
    );
  };
  _renderSubsidiariesSelectForSearch = () => {
    const { company } = this.props.context;
    const subsidiaries = Array.isArray(company.subsidiaries)
      ? company.subsidiaries
      : [];
    return (
      <Dropdown
        allowClear
        value={this.state.searchParams.User.subsidiary}
        onChange={(v) => {
          this.handleChangeAdvanceSearch("subsidiary", "User", v);
        }}
      >
        {subsidiaries.map((s) => {
          return (
            <Option key={s} value={s}>
              {s}
            </Option>
          );
        })}
      </Dropdown>
    );
  };
  _renderUserTagsSelectForSearch = () => {
    const { company } = this.props.context;
    const userTags = Array.isArray(company.userTags) ? company.userTags : [];
    return (
      <Dropdown
        allowClear
        value={this.state.searchParams.User.userTag}
        onChange={(v) => {
          this.handleChangeAdvanceSearch("userTag", "User", v);
        }}
      >
        {userTags.map((s) => {
          return (
            <Option key={s} value={s}>
              {s}
            </Option>
          );
        })}
      </Dropdown>
    );
  };
  _renderProjectsSelectForSearch = () => {
    const allProjects = Array.isArray(this.state.allProjects)
      ? this.state.allProjects
      : [];
    return (
      <Dropdown
        allowClear
        value={this.state.searchParams.Project.id}
        onChange={(v) => {
          this.handleChangeAdvanceSearch("id", "Project", v);
        }}
      >
        {allProjects.map((p) => {
          return (
            <Option key={p.id} value={p.id}>
              {p.name}
            </Option>
          );
        })}
      </Dropdown>
    );
  };
  _renderProjectsDepartmentForSearch = () => {
    const departments = Array.isArray(this.state.departments)
      ? this.state.departments
      : [];
    const { searchParams } = this.state;
    const departmentValue = departments
      .map((item) => item.name)
      .includes(searchParams?.User.department)
      ? searchParams?.User.department
      : "";
    return (
      <Dropdown
        allowClear
        value={departmentValue}
        onChange={(v) => {
          if (v) {
            this.handleChangeAdvanceSearch("department", "User", v);
          } else {
            this.handleChangeAdvanceSearch("department", "User", "");
          }
        }}
      >
        {departments.map((p) => {
          return (
            <Option key={p.name} value={p.name}>
              {p.name}
            </Option>
          );
        })}
      </Dropdown>
    );
  };

  onChangeInterest = (interest: Interest) => {
    const { Interest } = this.state.searchParams;
    if (Interest.includes(interest.id)) {
      const filtered = Interest.filter((ii) => ii !== interest.id);
      const searchParam = Object.assign({}, this.state.searchParams);
      searchParam.Interest = filtered;
      this.setState({
        searchParams: searchParam,
      });
    } else {
      const newFiltered = Interest.concat(interest.id);
      const searchParam = Object.assign({}, this.state.searchParams);
      searchParam.Interest = newFiltered;
      this.setState({
        searchParams: searchParam,
      });
    }
  };

  _renderInterestTagsForAdvanceSearch = () =>
    this.state.interest.map((interest) => {
      const { id, name } = interest;
      const selectedClass = this.state.searchParams.Interest.includes(id)
        ? "tag-selected"
        : "";

      return (
        <Tag
          key={`interest-${id}`}
          className={`wrap-tag-text clickable-tag ${selectedClass}`}
          onClick={() => this.onChangeInterest(interest)}
        >
          {name}
        </Tag>
      );
    });

  _renderUserTypeSelectForSearch = () => {
    return (
      <Dropdown
        allowClear
        value={
          this.state.searchParams.User.isSuper
            ? "isSuper"
            : this.state.searchParams.User.type
        }
        onChange={(value) => {
          if (value === "isSuper") {
            this.handleChangeAdvanceSearch("type", "User", "isSuper");
            this.handleChangeAdvanceSearch("isSuper", "User", true);
          } else if (typeof value !== "undefined") {
            this.handleChangeAdvanceSearch("type", "User", value);
            this.handleChangeAdvanceSearch("isSuper", "User", false);
          } else {
            const searchParam = Object.assign({}, this.state.searchParams);
            const children = searchParam.User;
            // @ts-expect-error TS2790
            delete children.type;
            delete children.isSuper;
            this.setState({ searchParams: searchParam });
          }
        }}
      >
        <Option value="isSuper">
          {this.props.intl?.formatMessage?.({
            id: "screen.label.admin_user",
          })}
        </Option>
        <Option value="admin">
          {this.props.intl?.formatMessage?.({
            id: "screen.label.management_user",
          })}
        </Option>
        <Option value="employee">
          {this.props.intl?.formatMessage?.({
            id: "screen.label.general_user",
          })}
        </Option>
        <Option value="mentor">
          {this.props.intl?.formatMessage?.({
            id: "screen.label.mentor",
          })}
        </Option>
      </Dropdown>
    );
  };
  handleChangeAdvanceSearch = (key: string, parent: string, value: any) => {
    const searchParam = Object.assign({}, this.state.searchParams);
    // @ts-expect-error TS7053
    const children = searchParam[parent];
    children[key] = value;
    this.setState({ searchParams: searchParam });
  };
  // @ts-expect-error TS7006
  setKeywordSearchParams = (value) => {
    this.handleChangeAdvanceSearch("name", "User", value);
    this.handleChangeAdvanceSearch("email", "User", value);
    this.handleChangeAdvanceSearch("role", "User", value);
    this.handleChangeAdvanceSearch("personalBio", "User", value);
    this.handleChangeAdvanceSearch("workHistorySummary", "User", value);
  };
  // @ts-expect-error TS7006
  _renderDelete = (row, child) => {
    const { user } = this.props.context;
    if (user.type === "admin") {
      return child;
    }
  };
  // @ts-expect-error TS7006
  _renderStop = (row, child) => {
    const { user } = this.props.context;
    if (user.type === "admin") {
      if (row.deletedAt) {
        return null;
      }
      return child;
    }
  };
  handleOpenUserManagementModal = (
    // @ts-expect-error TS7006
    userDetails,
    type: ModalType,
  ) => {
    this.setState({
      userDetails: userDetails,
      showUserManagementModal: true,
      userManagementModalType: type,
    });
  };
  handleCloseUserManagementModal = () => {
    this.setState({
      // @ts-expect-error TS2739
      userDetails: {},
      showUserManagementModal: false,
    });
  };
  // @ts-expect-error TS7006
  handleSubmitUserManagement = (userDetails) => {
    if (this.state.userManagementModalType === "delete") {
      this.deleteUserRequest(
        userDetails,
        this.props.intl.formatMessage({
          id: "screen.label.completed_deletion_processing",
        }),
      );
    }
    if (
      this.state.userManagementModalType === "stop" ||
      this.state.userManagementModalType === "restore"
    ) {
      this.changeStatusPendingUserRequest(
        userDetails,
        {
          stoppedAt: userDetails.stoppedAt ? null : new Date(),
        },
        this.props.intl.formatMessage({
          id: userDetails.stoppedAt
            ? "screen.label.canceled_stop_processing"
            : "screen.label.completed_stop_processing",
        }),
      );
    }
    this.handleCloseUserManagementModal();
  };
  _renderUsersStats = () => {
    const { user } = this.props.context;
    const { usersPayload } = this.props.users;
    if (usersPayload) {
      const extraSpace = `\xa0\xa0\xa0`;
      if (user.isSuper) {
        return `${this.props.intl.formatMessage({
          id: "screen.label.user_list",
          // @ts-expect-error TS2532
        })} ( ${usersPayload?.getTotalUsersInSystem}${this.props.intl.formatMessage(
          {
            id: "screen.label.name",
          },
        )} ${extraSpace}/ ${extraSpace}${this.props.intl.formatMessage({
          id: "screen.label.stop",
        })}: ${usersPayload?.totalStoppedUsers} ${this.props.intl.formatMessage(
          {
            id: "screen.label.name",
          },
        )}, ${extraSpace} ${this.props.intl.formatMessage({
          id: "screen.label.delete",
          // @ts-expect-error TS2532
        })}: ${usersPayload?.totalDeletedUsers} ${this.props.intl.formatMessage(
          {
            id: "screen.label.name",
          },
        )})`;
      } else if (user.type === "admin") {
        return `${this.props.intl.formatMessage({
          id: "screen.label.user_list",
          // @ts-expect-error TS2532
        })} (${usersPayload?.getTotalUsersInSystem}${this.props.intl.formatMessage(
          {
            id: "screen.label.name",
          },
        )} ${extraSpace}/${extraSpace} ${this.props.intl.formatMessage({
          id: "screen.label.stop",
        })}: ${usersPayload?.totalStoppedUsers} ${this.props.intl.formatMessage(
          {
            id: "screen.label.name",
          },
        )})`;
      } else {
        return `${this.props.intl.formatMessage({
          id: "screen.label.user_list",
          // @ts-expect-error TS2532
        })} (${usersPayload?.getTotalUsersInSystem}${this.props.intl.formatMessage(
          {
            id: "screen.label.name",
          },
        )} ${extraSpace}/${extraSpace} ${this.props.intl.formatMessage({
          id: "screen.label.stop",
        })}: ${usersPayload?.totalStoppedUsers} ${this.props.intl.formatMessage(
          {
            id: "screen.label.name",
          },
        )})`;
      }
    }
  };

  renderSearchModal = () => {
    const { user } = this.props.context;

    return (
      <>
        <ButtonOption
          icon={<SearchOutlined />}
          onClick={() => {
            this.setState({
              showUserSearchModal: true,
            });
          }}
        >
          {this.props.intl.formatMessage({
            id: "screen.label.search_for_users",
          })}
        </ButtonOption>
        <MemberSearchTerms
          onPerformSerach={() => {
            this.setState(
              {
                page: 1,
              },
              () => {
                this.performAdvanceSearch();
              },
            );
          }}
          onChangeSearchTerm={this.handleChangeAdvanceSearch}
          onChangeInterest={this.onChangeInterest}
          users={this.state.searchParams?.User}
          projects={this.state.searchParams?.Project}
          allProjects={this.state.allProjects}
          interst={this.state.searchParams?.Interest}
          allInterests={this.state.interest}
        />
        <Modal
          title={this.props.intl.formatMessage({
            id: "screen.label.user_search",
          })}
          centered
          style={{ minWidth: "45vw" }}
          visible={this.state.showUserSearchModal}
          onCancel={() => {
            this.setState({
              showUserSearchModal: false,
            });
          }}
          destroyOnClose
          footer={null}
        >
          <Form layout={"vertical"}>
            <Row>
              <Col span={24}>
                <Row gutter={[12, 12]}>
                  <Col span={12}>
                    <Form.Item
                      label={this.props.intl.formatMessage({
                        id: "screen.label.affiliation_companies",
                      })}
                    >
                      {this._renderSubsidiariesSelectForSearch()}
                    </Form.Item>
                  </Col>
                  {user.type === "admin" ? (
                    <Col span={12}>
                      <Form.Item
                        label={this.props.intl.formatMessage({
                          id: "screen.users.first_login",
                        })}
                      >
                        {this._renderFirstLoginConditionSelect()}
                      </Form.Item>
                    </Col>
                  ) : null}
                  <Col span={12}>
                    <Form.Item label={constants.userTags}>
                      {this._renderUserTagsSelectForSearch()}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label={this.props.intl.formatMessage({
                        id: "screen.label.user_type",
                      })}
                    >
                      {this._renderUserTypeSelectForSearch()}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label={this.props.intl.formatMessage({
                        id: "screen.label.affiliation_project",
                      })}
                    >
                      {this._renderProjectsSelectForSearch()}
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      label={this.props.intl.formatMessage({
                        id: "screen.label.affiliation_department",
                      })}
                    >
                      {this._renderProjectsDepartmentForSearch()}
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item
                      label={this.props.intl.formatMessage({
                        id: "screen.label.interest",
                      })}
                    >
                      {this._renderInterestTagsForAdvanceSearch()}
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item>
                      <Input
                        title={this.props.intl.formatMessage({
                          id: "screen.label.keyword",
                        })}
                        value={this.state.searchKeyword}
                        placeholder={this.props.intl.formatMessage({
                          id: "screen.label.keyword",
                        })}
                        onChange={(event) => {
                          this.setKeywordSearchParams(event.target.value);
                          this.setState({
                            searchKeyword: event.target.value,
                          });
                        }}
                      />
                    </Form.Item>
                  </Col>

                  <Col
                    span={12}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Row align="middle">
                      <ButtonCV
                        style={{
                          paddingRight: 40,
                          paddingLeft: 40,
                          marginRight: 10,
                        }}
                        onClick={() => {
                          this.setState(
                            {
                              page: 1,
                            },
                            () => {
                              this.performAdvanceSearch();
                            },
                          );
                        }}
                      >
                        {this.props.intl.formatMessage({
                          id: "screen.label.retrieval",
                        })}
                      </ButtonCV>
                      <ButtonUndo
                        onClick={() => {
                          const searchParams = {
                            User: {
                              name: "",
                              email: "",
                              subsidiary: "",
                              userTag: "",
                              role: "",
                              department: "",
                              personalBio: "",
                              workHistorySummary: "",
                            },
                            Project: {
                              id: "",
                            },
                            Interest: [],
                          };
                          this.setState(
                            {
                              page: 1,
                              // @ts-expect-error TS2322
                              searchParams,
                              searchKeyword: "",
                            },
                            () => {
                              this.performAdvanceSearch();
                            },
                          );
                        }}
                      >
                        {this.props.intl.formatMessage({
                          id: "screen.label.releasing_search_criteria",
                        })}
                      </ButtonUndo>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Modal>
      </>
    );
  };

  renderUserStatus = (user: any) => {
    if (user.stoppedAt && !user.deletedAt) {
      return (
        <span>
          [
          {this.props.intl.formatMessage({
            id: "screen.label.stopping",
          })}
          ]
        </span>
      );
    }
    return null;
  };

  changeStatusPendingUserRequest = (
    // @ts-expect-error TS7006
    userDetails,
    // @ts-expect-error TS7006
    data,
    successMessage = this.props.intl.formatMessage({
      id: "screen.label.approved_registration_request",
    }),
  ) => {
    const { apiRequest } = this.props.context;
    const updateUserRequest: ApiRequestData = {
      method: "putRequest",
      successMessage,
      url: parseEndpoint(endpoints.updateUserProfile, {
        data: {
          id: getObjUUID(userDetails),
        },
      }),
      data,
    };
    apiRequest(updateUserRequest, ({}) => {
      this.performAdvanceSearch();
    });
  };

  deleteUserRequest = (
    // @ts-expect-error TS7006
    userDetails,
    successMessage = this.props.intl.formatMessage({
      id: "screen.label.approved_registration_request",
    }),
  ) => {
    const { apiRequest } = this.props.context;
    const updateUserRequest: ApiRequestData = {
      method: "deleteRequest",
      successMessage,
      url: parseEndpoint(endpoints.deleteUser, {
        data: {
          id: getObjUUID(userDetails),
        },
      }),
    };
    apiRequest(updateUserRequest, ({}) => {
      this.performAdvanceSearch();
    });
  };

  closeEditUserDetailsModals = () => {
    this.setState({
      userDetails: {
        id: 0,
        uuid: "",
        name: "",
        type: "",
        role: null,
        department: null,
        workHistorySummary: null,
        personalBio: null,
        profileImage: null,
        company: {},
        interest: [],
      },
      showEditUserDetailsModal: false,
    });
  };
  createInterest = (interest: string) => {
    const { apiRequest } = this.props.context;
    const interestRequest: ApiRequestData = {
      method: "postRequest",
      url: parseEndpoint(endpoints.interest),
      data: {
        name: interest,
      },
    };
    apiRequest(interestRequest, ({ data }) => {
      this.getInterest();
    });
  };

  render() {
    const { user, company, apiRequest } = this.props.context;
    const columns: ColumnsType<User> = [
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
        responsive: ["sm"],
        sortDirections: ["descend", "ascend"],
        sorter: true,
      },
      {
        title: this.props.intl.formatMessage({
          id: "screen.events.full_name",
        }),
        dataIndex: "name",
        key: "name",
        render: (text, row, index) => {
          if (
            row.createdAt === row.updatedAt ||
            !(
              // @ts-expect-error TS2352
              row.Credential.hasChangedPassword
            )
          ) {
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <div style={{ minWidth: 50 }}>{getUserAvatar(row)}</div>
                <Link to={"/dashboard/member/" + getObjUUID(row)}>
                  {this.renderUserStatus(row)}{" "}
                  {this.props.intl.formatMessage({
                    id: "screen.label.registration_waiting_invitation",
                  })}
                </Link>
              </div>
            );
          }
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div style={{ minWidth: 50 }}>{getUserAvatar(row)}</div>
              <div style={{ maxWidth: "20vw" }}>
                <Link to={"/dashboard/member/" + getObjUUID(row)}>
                  {this.renderUserStatus(row)} {getDisplayName(row)}
                </Link>
              </div>
            </div>
          );
        },
        sortDirections: ["descend", "ascend"],
        sorter: true,
      },
      {
        title: this.props.intl.formatMessage({
          id: "screen.label.email",
        }),
        dataIndex: "email",
        key: "email",
        render: (text, row, index) => (
          <div style={{ width: "15vw", wordWrap: "break-word" }}>
            {row?.Credential?.email}
          </div>
        ),
      },
      {
        title: this.props.intl.formatMessage({
          id: "screen.label.affiliation_companies",
        }),
        dataIndex: "subsidiary",
        key: "subsidiary",
        ...this.getSubsidiariesfilter(),
        sortDirections: ["descend", "ascend"],
        sorter: true,
      },
      {
        title: constants.userTags,
        dataIndex: "userTag",
        key: "userTag",
        responsive: ["md"],
        ...this.getUserTagsfilter(),
        sortDirections: ["descend", "ascend"],
        sorter: true,
      },
      {
        title: this.props.intl.formatMessage({
          id: "screen.label.affiliation_department",
        }),
        dataIndex: "department",
        key: "department",
        ...this.getDepartmentfilter(),
        sortDirections: ["descend", "ascend"],
        sorter: true,
        render: (text) => {
          return (
            <div
              style={{
                maxWidth: "12rem",
                maxHeight: "3rem",
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                // @ts-expect-error TS2322
                WebkitLineClamp: "2",
                WebkitBoxOrient: "vertical",
              }}
            >
              {text}
            </div>
          );
        },
      },
      {
        title: this.props.intl.formatMessage({
          id: "screen.label.user_type",
        }),
        dataIndex: "type",
        key: "type",
        render: (text, data) => {
          if (data.type === "employee")
            return <Tag className="user-type-tag">{generalUser}</Tag>;
          if (data.type === "mentor")
            return <Tag className="user-type-tag">{mentorUser}</Tag>;
          if (
            data.type === "admin" &&
            (data.isSuper === false || data.isSuper === null)
          )
            return <Tag className="user-type-tag">{programManager}</Tag>;
          if (data.type === "admin" && data.isSuper === true)
            return <Tag className="user-type-tag">{adminUser}</Tag>;
        },
        ...this.getUserTypefilter(),
        sortDirections: ["descend", "ascend"],
        sorter: true,
      },
      {
        title: this.props.intl.formatMessage({
          id: "screen.label.created_date",
        }),
        dataIndex: "createdAt",
        key: "createdAt",
        render: (text) => (
          <span>{moment(text).local().format(dateTimeFormat)}</span>
        ),
      },
      {
        render: (text, data) => {
          if (user.id == data.id) {
            return <></>;
          }
          const row = Immutable.asMutable(data, { deep: true });
          // @ts-expect-error ts(2322)
          row.company = data.Company;
          row.interest = Array.isArray(data.Interest)
            ? data.Interest.map((interest) => interest.id)
            : [];

          if (user.type === "admin" && user.isSuper) {
            return row.deletedAt ? null : (
              <Button
                type="ghost"
                size="small"
                onClick={() =>
                  this.onEditUserClick(
                    // @ts-expect-error ts(2345)
                    row,
                  )
                }
                icon={IconEdit}
              />
            );
          }
          if (user.type === "admin" && !row.isSuper) {
            return row.deletedAt ? null : (
              <Button
                type="ghost"
                size="small"
                onClick={() =>
                  this.onEditUserClick(
                    // @ts-expect-error ts(2345)
                    row,
                  )
                }
                icon={IconEdit}
              />
            );
          }
          return <div />;
        },
      },
      {
        render: (text, row) => {
          if (user.id == row.id) {
            return <></>;
          }
          if (user.type !== "admin") {
            return <></>;
          }
          if (row.isSuper && !user.isSuper) {
            return <></>;
          }
          const stopNode = (
            <Button
              type={"link"}
              onClick={() => {
                this.handleOpenUserManagementModal(
                  row,
                  row.stoppedAt ? "restore" : "stop",
                );
              }}
              className="offset-right-4"
            >
              {this.props.intl.formatMessage({
                id: row.stoppedAt
                  ? "screen.label.restoration_from_stop"
                  : "screen.label.stop",
              })}
            </Button>
          );
          const deleteNode = (
            <Button
              type={"link"}
              onClick={() => {
                this.handleOpenUserManagementModal(row, "delete");
              }}
            >
              {this.props.intl.formatMessage({
                id: "screen.label.delete",
              })}
            </Button>
          );
          const content = (
            <>
              {this._renderStop(row, stopNode)}
              {this._renderDelete(row, deleteNode)}
            </>
          );
          return row.deletedAt ? null : (
            <Popover content={content}>
              <ButtonUndo size="small" icon={IconDelete} />
            </Popover>
          );
        },
      },
    ];

    return (
      <>
        <Modal
          title={this.props.intl.formatMessage({
            id: "screen.label.edit_user",
          })}
          style={{ minWidth: "45vw" }}
          visible={this.state.showEditUserDetailsModal}
          onCancel={this.closeEditUserDetailsModals}
          destroyOnClose
          footer={null}
        >
          <ProfileForm
            dispatchApiRequest={apiRequest}
            handleSubmit={this.handleSubmitEditUser}
            createInterest={this.createInterest}
            departments={[]}
            roles={[]}
            // @ts-expect-error ts(2739)
            userDetails={this.state.userDetails}
            // @ts-expect-error ts(2322)
            interests={this.state.interest}
            // @ts-expect-error ts(2741)
            companyDetails={company}
            // @ts-expect-error TS2779
            authUser={user}
          />
        </Modal>
        <UserManagementModal
          isVisible={this.state.showUserManagementModal}
          modalType={this.state.userManagementModalType}
          onCancel={this.handleCloseUserManagementModal}
          onSubmit={() =>
            this.handleSubmitUserManagement(this.state.userDetails)
          }
          userName={
            this.state.userDetails?.name ||
            this.state.userDetails?.Credential?.email
          }
        />
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={16} md={16} lg={16}>
            <h2> {this._renderUsersStats()}</h2>
          </Col>
          {this._renderAddMemberButton()}
        </Row>

        <Tabs
          onChange={(activeTab) => {
            this.setState({
              activeTab,
            });
          }}
          type="card"
        >
          <TabPane
            tab={this.props.intl.formatMessage({
              id: "screen.label.active_users",
            })}
            key="active"
          >
            {this.renderSearchModal()}
            <br />
            <Table
              pagination={false}
              rowKey={"id"}
              columns={columns}
              dataSource={this.props.users.usersPayload.rows}
              onChange={(pagination, filters, sorter) => {
                if (sorter) {
                  // @ts-expect-error TS2339
                  const { order, columnKey } = sorter;
                  this.setState(
                    {
                      sortOrder: order || "descend",
                      sortField: columnKey,
                    },
                    () => {
                      this.performAdvanceSearch();
                    },
                  );
                }
              }}
              className="ant-table-x-scroll"
            />
            <br />
            <br />
            <CustomPagination
              saveId={PageActiveSaveId}
              count={this.props.users.usersPayload.count}
              initPage={this.state.page}
              initLimit={this.state.limit}
              onChangePage={(page, limit) => {
                this.setState({ page, limit }, () => {
                  this.performAdvanceSearch();
                });
              }}
            />
            <br />
            <br />
          </TabPane>
          {user.type === "admin" && (
            <TabPane
              tab={this.props.intl.formatMessage({
                id: "screen.label.registration_request_list",
              })}
              key="inactive"
            >
              <MembersInactive
                activeTab={this.state.activeTab}
                context={this.props.context}
                performMainSearch={this.performAdvanceSearch}
              />
            </TabPane>
          )}
          {user.type === "admin" && (
            <TabPane
              tab={this.props.intl.formatMessage({
                id: "screen.label.recovery_request_list",
              })}
              key="recovery"
            >
              <MembersRecovery
                activeTab={this.state.activeTab}
                context={this.props.context}
                performMainSearch={this.performAdvanceSearch}
              />
            </TabPane>
          )}
        </Tabs>
      </>
    );
  }
}
const mapStateToProps = (state: ReduxState) => ({
  users: state.user,
});
const mapDispatchToProps = (dispatch: ReduxDispatch) => ({
  // @ts-expect-error TS7006
  dispatchSetAdvanceSearchResult: (data) =>
    // @ts-expect-error TS2554
    dispatch(UserActions.userGetsSuccess(data)),
});
export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(MembersScreen),
);
