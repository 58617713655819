import { checkIfUrl } from "@/utils/checkIfUrl";
import { urlRegex } from "@/utils/urlRegex";

import styles from "./styles.module.scss";

const ReplaceUrlStringToAnchor = (text: string) => {
  const parts = text.split(urlRegex);

  return parts.map((part, index) =>
    checkIfUrl(part) ? (
      <a key={index} href={part} target="_blank" rel="noopener noreferrer">
        {part}
      </a>
    ) : (
      part
    ),
  );
};

export type TextWithLinkProps = {
  text: string;
};

export const TextWithLink = ({ text }: TextWithLinkProps) => {
  return (
    <div className={styles.container}>{ReplaceUrlStringToAnchor(text)}</div>
  );
};
