import React, { useMemo } from "react";
import { useIntl } from "react-intl";

import { I18NContext } from "../../../i18n/context";
import { ContentContext } from "../../../Provider";

const useLanguageSettingsScreen = () => {
  const context = React.useContext(I18NContext);
  const contentContext = React.useContext(ContentContext);
  const intl = useIntl();
  // @ts-expect-error TS2554
  useMemo(() => {
    if (contentContext.user) {
      // @ts-expect-error TS2339
      context.selectLanguage(contentContext.user.language);
    }
  });
  // @ts-expect-error TS7006
  const updateUserLanguage = (language) => {
    let message = intl.formatMessage({
      id: "screen.label.account_successfully_updated",
    });
    if (language === "en") {
      message = "Your account has been successfully updated";
    } else {
      message = "アカウントが正常に更新されました";
    }
    contentContext.updateUser(
      {
        language,
        id: contentContext.user.id,
      },
      message,
    );
  };
  React.useEffect(() => {
    contentContext.updatePageTitle("screen.label.lang_setting");
  }, []);

  return {
    intl,
    authPayload: contentContext.user,
    updateUserLanguage,
    context,
  };
};

export default useLanguageSettingsScreen;
