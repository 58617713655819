import { Popover } from "antd";
import React from "react";

import { EmojiProps } from "../../../Screens/project/types/EmojiProps";

const Emoji: React.FC<EmojiProps> = ({ emoji }: EmojiProps) => {
  const defaultStyle = {
    borderStyle: "solid",
    borderWidth: "1px",
    borderRadius: "14px",
    borderColor: "rgb(29 155 209)",
    paddingLeft: "7px",
    paddingRight: "7px",
    paddingTop: "2px",
    paddingBottom: "2px",
    marginRight: "0.5vw",
  };
  const styleSelected = {
    ...defaultStyle,
    backgroundColor: "rgb(235 245 250)",
  };
  // @ts-expect-error TS18048
  const users = Array.isArray(emoji.usersName) ? (
    // @ts-expect-error TS18048
    emoji.usersName.map((u, index) => {
      return <p key={index}>{u}</p>;
    })
  ) : (
    <></>
  );
  const content = <div>{users}</div>;
  return (
    <Popover content={content} trigger="hover">
      <span
        // @ts-expect-error TS18048
        style={emoji.currentUserSelected ? styleSelected : defaultStyle}
        className="emoji"
        role="img"
        aria-label={""}
        aria-hidden={"true"}
      >
        {/*
         // @ts-expect-error TS18048 */}
        {emoji.emoji}{" "}
        {/*
         // @ts-expect-error TS18048 */}
        <span style={{ fontSize: "0.8rem" }}>{emoji.usersName.length}</span>
      </span>
    </Popover>
  );
};
export default Emoji;
