import { useMemo } from "react";
import { Link } from "react-router-dom";

import { Checkbox } from "@/shared/checkbox/components/CheckBox/component";

import { ProjectInfoCell } from "../ProjectInfoCell/component";
import { ReviewResultInfoCell } from "../ReviewResultInfoCell/component";
import { canPublish } from "./helper";
import styles from "./styles.module.scss";
import { ReviewInfoRowProps } from "./type";
export const ReviewInfoRow = ({
  reviewInfo,
  rowSelection,
  onCheck,
}: ReviewInfoRowProps) => {
  const handleCheck = (checked: boolean) => {
    onCheck(reviewInfo.uuid, checked);
  };
  // 一括チェック時のパフォーマンスは要チェック
  const selected = useMemo(() => {
    return rowSelection.includes(reviewInfo.uuid);
  }, [reviewInfo.uuid, rowSelection]);

  const disableRowSelection = useMemo(
    () => !canPublish(reviewInfo),
    [reviewInfo],
  );

  return (
    <div key={reviewInfo.uuid} className={styles.review_info_row_container}>
      <div className={styles.review_info_row_checkbox}>
        <Checkbox
          checked={selected}
          disabled={disableRowSelection}
          onCheck={handleCheck}
        />
      </div>
      <Link
        to={`/dashboard/examinations/${reviewInfo.uuid}`}
        className={styles.review_info_row_link}
      >
        <div className={styles.review_info_row_project_info}>
          <ProjectInfoCell
            projectId={reviewInfo.projectId}
            projectName={reviewInfo.projectName}
            categoryName={reviewInfo.categoryName}
            projectMembers={reviewInfo.projectMembers}
            hasHandOverNote={reviewInfo.hasHandOverNote}
            submittedAt={reviewInfo.submittedAt}
          />
        </div>
        <div className={styles.review_info_row_review_info}>
          <ReviewResultInfoCell
            result={reviewInfo.result}
            feedbackComment={reviewInfo.feedbackComment}
            isPublished={reviewInfo.isPublished}
          />
        </div>
      </Link>
    </div>
  );
};
