import { Col, Form, Row, Table, Tabs, Tag } from "antd";
import PropTypes from "prop-types";
import { FC, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { Line } from "../../Components/atoms";
import { MemberActivityComponent } from "../../Components/organisms";
import ProjectActions from "../../Redux/ProjectRedux";
import UserActions from "../../Redux/UserRedux";
import { defaultUserPayload } from "../../Redux/UserRedux";
import { endpoints, parseEndpoint } from "../../Services/endpoints";
import { getDisplayName, getObjUUID, getUserAvatar } from "../../utils/common";
import {
  IMemberScreenProps,
  MemberScreenState,
} from "./types/IMemberScreenProps";

const { TabPane } = Tabs;

const MemberScreen: FC<IMemberScreenProps> = function (props) {
  const intl = useIntl();
  const [state, customSetState] = useState<MemberScreenState>(() => {
    return {
      departments: [],
      interests: [],
      projects: [],
    };
  });

  const { apiRequest, user } = props.context;

  useEffect(() => {
    getDepartment();
    getInterest();
    getUserProjects(getMemberId());
    const data = {
      method: "getRequest",
      url: parseEndpoint(endpoints.updateUserProfile, {
        data: {
          id: getMemberId(),
        },
      }),
      data: {},
    };
    // @ts-expect-error TS2345
    apiRequest(data, ({ data }) => {
      // @ts-expect-error TS18046
      data.Interest = Array.isArray(data.Interest)
        ? // @ts-expect-error TS18046
          data.Interest.map((interest) => interest.id)
        : [];
      // @ts-expect-error TS2722
      props.dispatchSetUserDetail(data);
    });
    return () => {
      // @ts-expect-error TS2722
      props.dispatchSetUserDetail(defaultUserPayload);
    };
  }, []);
  const setStateCallbackRef = useRef(() => {});
  useEffect(() => {
    const callBack = setStateCallbackRef.current;
    callBack();
    setStateCallbackRef.current = () => {};
  }, [state]);
  const setState = (data: MemberScreenState, callback = () => {}) => {
    setStateCallbackRef.current = callback;
    customSetState((previousState) => {
      return {
        ...previousState,
        ...data,
      };
    });
  };

  const getDepartment = () => {
    const departmentRequest = {
      method: "getRequest",
      url: parseEndpoint(endpoints.departments),
      data: {},
    };
    // @ts-expect-error TS2345
    apiRequest(departmentRequest, ({ data }) => {
      setState({
        departments: data,
      });
    });
  };
  const getInterest = () => {
    const interestRequest = {
      method: "getRequest",
      url: parseEndpoint(endpoints.interest),
      data: {},
    };
    // @ts-expect-error TS2345
    apiRequest(interestRequest, ({ data }) => {
      setState({
        interests: data,
      });
    });
  };

  const getMemberId = () => {
    const {
      match: { params },
    } = props;
    return params.id ? params.id : -1;
  };
  const renderUserWithdrawalStatus = () => {
    // @ts-expect-error TS18048
    if (props.member.deletedAt) {
      return (
        <Tag color={"red"}>
          {intl.formatMessage({
            id: "screen.label.delete",
          })}
        </Tag>
      );
    }
    // @ts-expect-error TS18048
    if (props.member.stoppedAt) {
      return (
        <Tag color={"magenta"}>
          {intl.formatMessage({
            id: "screen.label.stop",
          })}
        </Tag>
      );
    }
  };

  // @ts-expect-error TS7006
  const getUserProjects = (userId) => {
    const userProjectsRequest = {
      method: "getRequest",
      url: `users/${userId}/projects`,
      data: {},
    };
    // @ts-expect-error TS2345
    apiRequest(userProjectsRequest, ({ data }) => {
      setState({
        // @ts-expect-error TS18046
        projects: data.rows,
      });
    });
  };
  const _renderInterest = () =>
    state.interests
      // @ts-expect-error TS7031
      .filter(({ id }) => props.member.Interest.includes(id))
      // @ts-expect-error TS7031
      .map(({ id, name }) => (
        <Tag className="wrap-tag-text tag-selected" key={id}>
          {name}
        </Tag>
      ));

  const renderMemberProfile = () => {
    return (
      <div
        style={{
          backgroundColor: "rgba(250, 250, 250, 1)",
          padding: "60px",
        }}
      >
        <Row>
          <Col span={5}>
            {getUserAvatar(props.member, () => {}, {
              width: "60%",
              height: "auto",
            })}
          </Col>
          <Col span={16}>
            <Row gutter={[12, 22]}>
              <Col span={12}>
                <h3>{getDisplayName(props.member)}</h3>
                {/*
                 // @ts-expect-error TS18048 */}
                <span>{props.member.email}</span>
              </Col>
              <Col span={4}>{renderUserWithdrawalStatus()}</Col>
              <Col span={12}>
                <span className="ant-form-item-label">
                  {intl.formatMessage({
                    id: "screen.label.contract_companies",
                  })}
                </span>
                {/*
                 // @ts-expect-error TS18048 */}
                <p>{props.member.Company.name}</p>
              </Col>
              <Col span={12}>
                <span className="ant-form-item-label">
                  {intl.formatMessage({
                    id: "screen.label.affiliation_companies",
                  })}
                </span>
                {/*
                 // @ts-expect-error TS18048 */}
                <p>{props.member.subsidiary}</p>
              </Col>
              <Col span={12}>
                <span className="ant-form-item-label">
                  {intl.formatMessage({
                    id: "screen.label.affiliation_department",
                  })}
                </span>
                {/*
                 // @ts-expect-error TS18048 */}
                <p>{props.member.department}</p>
              </Col>
              <Col span={12}>
                <span className="ant-form-item-label">
                  {intl.formatMessage({
                    id: "screen.label.title_manager",
                  })}
                </span>
                {/*
                 // @ts-expect-error TS18048 */}
                <p>{props.member.role}</p>
              </Col>
              <Col span={24}>
                <span className="ant-form-item-label">
                  {intl.formatMessage({
                    id: "screen.label.user_tag",
                  })}
                </span>
                {/*
                 // @ts-expect-error TS18048 */}
                <p>{props.member.userTag}</p>
              </Col>

              <Col span={24}>
                <span className="ant-form-item-label">
                  {intl.formatMessage({
                    id: "screen.label.interest",
                  })}
                </span>
                <div>{_renderInterest()}</div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  };

  const renderProjectColumns = () => {
    return [
      {
        title: intl.formatMessage({
          id: "screen.label.project_name",
        }),
        dataIndex: "name",
        key: "name",
        // @ts-expect-error TS7006
        render: function _fn(text, row) {
          return (
            <span style={{ flexGrow: 1 }}>
              <Link to={`/dashboard/project.top/${getObjUUID(row)}`}>
                {text}
              </Link>
            </span>
          );
        },
      },
      {
        title: intl.formatMessage({
          id: "screen.label.project_label",
        }),
        dataIndex: "projectTags",
        key: "projectTags",
        // @ts-expect-error TS7006
        render: function _fn(text, row, index) {
          return (
            <div>
              {Array.isArray(row.projectTags)
                ? // @ts-expect-error TS7006
                  row.projectTags.map((tag) => {
                    return <Tag key={`${tag.id}_tag`}>{tag.name}</Tag>;
                  })
                : null}
            </div>
          );
        },
      },
      {
        title: intl.formatMessage({
          id: "screen.label.stage",
        }),
        dataIndex: "stage",
        key: "stage",
        // @ts-expect-error TS7006
        render: function _fn(text, row, index) {
          if (row.stage && row.stage.length > 0) {
            return <Tag>{row.stage}</Tag>;
          }
          return null;
        },
      },
    ];
  };
  return (
    <>
      <br />
      <Tabs type="card">
        <TabPane
          tab={intl.formatMessage({
            id: "screen.label.profile",
          })}
          key={"profile"}
        >
          {renderMemberProfile()}
          <Line />
          <Row>
            <Col span={20}>
              <Form layout={"vertical"} name="basic">
                <Form.Item
                  label={intl.formatMessage({
                    id: "screen.label.main_work_experience",
                  })}
                >
                  <p
                    style={{
                      whiteSpace: "pre-line",
                    }}
                  >
                    {/*
                     // @ts-expect-error TS18048 */}
                    {props.member.workHistorySummary}
                  </p>
                </Form.Item>

                <Form.Item
                  label={intl.formatMessage({
                    id: "screen.label.profile",
                  })}
                >
                  <p
                    style={{
                      whiteSpace: "pre-line",
                    }}
                  >
                    {/*
                     // @ts-expect-error TS18048 */}
                    {props.member.personalBio}
                  </p>
                </Form.Item>

                <Form.Item
                  label={intl.formatMessage({
                    id: "screen.label.affiliation_project",
                  })}
                >
                  <Table
                    tableLayout={"auto"}
                    pagination={false}
                    rowKey={"id"}
                    columns={renderProjectColumns()}
                    dataSource={state.projects}
                  />
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </TabPane>
        {user.type === "admin" ? (
          <TabPane
            tab={intl.formatMessage({
              id: "screen.label.activity",
            })}
            key={"activity"}
          >
            <MemberActivityComponent
              inspectedUserId={getMemberId()}
              dispatchApiRequest={apiRequest}
            />
          </TabPane>
        ) : null}
      </Tabs>
    </>
  );
};

MemberScreen.propTypes = {
  // @ts-expect-error TS2322
  context: PropTypes.object,
  match: PropTypes.object,
  member: PropTypes.object,
  dispatchSetUserDetail: PropTypes.func,
};
// @ts-expect-error TS7006
const mapStateToProps = (state) => ({
  member: state.user.userPayload,
  projects: state.projects,
  teams: state.teams,
});
// @ts-expect-error TS7006
const mapDispatchToProps = (dispatch) => ({
  // @ts-expect-error TS7006
  dispatchSetUserDetail: (userPayload) =>
    // @ts-expect-error TS2554
    dispatch(UserActions.userGetSuccess(userPayload)),
  dispatchGetMyProjects: (data: { userId: string; page: number }) =>
    // @ts-expect-error TS2554
    dispatch(ProjectActions.projectCurrentMemberGetsRequest(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(MemberScreen);
