import PropTypes from "prop-types";
import { FC, ReactElement } from "react";

import { SingleVideoCardComponent } from "../../molecules";

type NextVideoComponentProps = {
  title: ReactElement<any, any> | string;
  description: string;
  icon: ReactElement<any, any>;
  imgSrc: string;
  videoName: string;
  datePosted: string;
  onClick: () => void;
  type: string;
};

const NextVideoComponent: FC<NextVideoComponentProps> = function (props) {
  const {
    title,
    description,
    icon,
    imgSrc,
    datePosted,
    videoName,
    onClick,
    type,
  } = props;

  return (
    <div
      onClick={onClick}
      style={{
        backgroundColor: "#EBEBEB",
        paddingBottom: "5%",
        paddingLeft: "6%",
        paddingRight: "6%",
        paddingTop: "5%",
      }}
    >
      {type === "previous" ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {icon}
          <p
            style={{
              marginTop: "20px",
              marginBottom: "24px",
              marginLeft: "1.3vw",
              fontWeight: "bold",
              fontStyle: "normal",
              fontSize: "18px",
              lineHeight: "26px",
              color: "#323232",
            }}
          >
            {title}
          </p>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "row-reverse",
            alignItems: "center",
          }}
        >
          {icon}
          <p
            style={{
              marginTop: "20px",
              marginBottom: "24px",
              marginRight: "1.3vw",
              fontWeight: "bold",
              fontStyle: "normal",
              fontSize: "18px",
              lineHeight: "26px",
              color: "#323232",
            }}
          >
            {title}
          </p>
        </div>
      )}

      <SingleVideoCardComponent
        imgSrc={imgSrc}
        videoName={videoName}
        description={description}
        datePosted={datePosted}
        onClick={() => {}}
      />
    </div>
  );
};
NextVideoComponent.propTypes = {
  title: PropTypes.any.isRequired,
  // @ts-expect-error TS2322
  description: PropTypes.string,
  imgSrc: PropTypes.string.isRequired,
  videoName: PropTypes.string.isRequired,
  datePosted: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  icon: PropTypes.any.isRequired,
  onClick: PropTypes.func.isRequired,
};
NextVideoComponent.defaultProps = {
  description: "",
};
export default NextVideoComponent;
