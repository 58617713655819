import { notification } from "antd";
import { all, call, put, select, takeLatest } from "redux-saga/effects";

import NewsActions, { newsStore, NewsTypes } from "../Redux/NewsRedux";
import { endpoints, parseEndpoint } from "../Services/endpoints";

const updateNewsRows = (curRows = [], newRows = []) => {
  const cloneCurRows = [...curRows];
  const cloneNewRows = [...newRows];
  // @ts-expect-error TS7034
  const unduplicatedRows = [];
  for (let index = 0; index < cloneNewRows.length; index++) {
    const newRow = cloneNewRows[index];
    // @ts-expect-error TS2339
    const findIndex = cloneCurRows.findIndex((a) => a.id === newRow.id);
    if (findIndex !== -1) {
      // @ts-expect-error TS2322
      cloneCurRows[findIndex] = { ...cloneCurRows[findIndex], ...newRow };
    } else {
      unduplicatedRows.push(newRow);
    }
  }
  // @ts-expect-error TS7005
  return [...cloneCurRows, ...unduplicatedRows];
};

// @ts-expect-error TS7006
const deleteNewsRow = (curRows = [], id) => {
  const cloneCurRows = [...curRows];
  // @ts-expect-error TS2339
  const findIndex = cloneCurRows.findIndex((a) => a.id === id);
  if (findIndex !== -1) {
    cloneCurRows.splice(findIndex, 1);
  }
  return cloneCurRows;
};

// @ts-expect-error TS7006
const updateNewsRow = (curRows = [], id, updates) => {
  const cloneCurRows = [...curRows];
  // @ts-expect-error TS2339
  const findIndex = cloneCurRows.findIndex((a) => a.id === id);
  if (findIndex !== -1) {
    // @ts-expect-error TS2322
    cloneCurRows[findIndex] = { ...cloneCurRows[findIndex], ...updates };
  }
  return cloneCurRows;
};

// @ts-expect-error TS7006
function* attemptGetNews(api, action) {
  const { data } = action;
  const {
    page = 0,
    limit = process.env.REACT_APP_PAGE_SIZE,
    filter = {},
    isGlobal = false,
  } = data;
  const {
    name = undefined,
    content = undefined,
    url = undefined,
    pageTitle = undefined,
    category = undefined,
    sortField = "createdAt",
    sortOrder = "DESC",
    newsComment = undefined,
  } = filter;

  const filterObject = {
    User: {
      name,
    },
    NewsComments: {
      content,
    },
    News: {
      url,
      pageTitle,
      category,
      newsComment,
    },
    sortField,
    sortOrder,
  };

  // @ts-expect-error TS7057
  const newsResponse = yield call(
    api.postRequest,
    parseEndpoint(endpoints.advanceNewsSearch, { page, limit }),
    filterObject,
  );

  if (newsResponse.ok) {
    switch (newsResponse.data.status) {
      case "success": {
        const { rows, count } = newsResponse.data.data;
        const { newsPayload } = yield select(newsStore);

        const oldRows = newsPayload.rows;
        if (page === 0) {
          // @ts-expect-error TS2554
          yield put(NewsActions.newsGetSuccess(newsResponse.data.data));
        } else {
          if (isGlobal) {
            // @ts-expect-error TS2554
            yield put(NewsActions.newsGetSuccess(newsResponse.data.data));
          } else {
            yield put(
              // @ts-expect-error TS2554
              NewsActions.newsGetSuccess({
                count,
                rows: updateNewsRows(oldRows, rows),
              }),
            );
          }
        }

        break;
      }
      case "fail":
      case "error":
        // @ts-expect-error TS2554
        yield put(NewsActions.newsGetFailure(newsResponse.data.data));
        break;
      default:
        break;
    }
  } else {
    notification.error({
      message: newsResponse.problem,
    });
    // @ts-expect-error TS2554
    yield put(NewsActions.newsGetFailure(newsResponse.problem));
    if (newsResponse.status === 401) {
      // @ts-expect-error TS2322
      window.location = "/login";
    }
  }
}

// @ts-expect-error TS7006
function* attemptUpdateNews(api, action) {
  const { data } = action;
  const { id, ...body } = data;

  // @ts-expect-error TS7057
  const newsResponse = yield call(
    api.putRequest,
    parseEndpoint(endpoints.updateNews, { id }),
    body,
  );
  if (newsResponse.ok) {
    switch (newsResponse.data.status) {
      case "success": {
        yield put(NewsActions.newsUpdateSuccess());
        const { newsPayload } = yield select(newsStore);
        const rows = newsPayload.rows;
        yield put(
          // @ts-expect-error TS2554
          NewsActions.newsGetSuccess({
            rows: updateNewsRow(rows, id, body),
          }),
        );
        break;
      }
      case "fail":
      case "error":
        if (typeof newsResponse.data.data === "string") {
        }
        // @ts-expect-error TS2554
        yield put(NewsActions.newsUpdateFailure(newsResponse.data.data));
        break;
      default:
        break;
    }
  } else {
    notification.error({
      message: newsResponse.problem,
    });
    // @ts-expect-error TS2554
    yield put(NewsActions.newsUpdateFailure(newsResponse.problem));
    if (newsResponse.status === 401) {
      // @ts-expect-error TS2322
      window.location = "/login";
    }
  }
}

// @ts-expect-error TS7006
function* attemptCreateNew(api, action) {
  const { data, message } = action;

  // @ts-expect-error TS7057
  const newsResponse = yield call(
    api.postRequest,
    parseEndpoint(endpoints.news),
    data,
  );
  if (newsResponse.ok) {
    switch (newsResponse.data.status) {
      case "success":
        if (
          Array.isArray(newsResponse.data.data) &&
          newsResponse.data.data.length > 0
        ) {
          // @ts-expect-error TS2554
          yield put(NewsActions.newsCreateFailure(newsResponse.data.data));
        } else {
          notification.success({
            message: message,
          });
        }
        break;
      case "fail":
      case "error":
        if (typeof newsResponse.data.data === "string") {
        }
        // @ts-expect-error TS2554
        yield put(NewsActions.newsCreateFailure(newsResponse.data.data));
        break;
      default:
        break;
    }
  } else {
    notification.error({
      message: newsResponse.problem,
    });
    // @ts-expect-error TS2554
    yield put(NewsActions.newsCreateFailure(newsResponse.problem));
    if (newsResponse.status === 401) {
      // @ts-expect-error TS2322
      window.location = "/login";
    }
  }
}

// @ts-expect-error TS7006
function* attemptDeleteNews(api, action) {
  const { id } = action;
  // @ts-expect-error TS7057
  const newsResponse = yield call(
    api.deleteRequest,
    parseEndpoint(endpoints.deleteNews, { id }),
  );
  if (newsResponse.ok) {
    switch (newsResponse.data.status) {
      case "success": {
        const { newsPayload } = yield select(newsStore);
        const rows = newsPayload.rows;
        yield put(
          // @ts-expect-error TS2554
          NewsActions.newsGetSuccess({
            rows: deleteNewsRow(rows, id),
          }),
        );
        break;
      }
      case "fail":
      case "error":
        if (typeof newsResponse.data.data === "string") {
        }
        // @ts-expect-error TS2554
        yield put(NewsActions.newsDeleteFailure(newsResponse.data.data));
        break;
      default:
        break;
    }
  } else {
    // @ts-expect-error TS2554
    yield put(NewsActions.newsDeleteFailure(newsResponse.problem));
    if (newsResponse.status === 401) {
      // @ts-expect-error TS2322
      window.location = "/login";
    }
  }
}

// @ts-expect-error TS7006
function* newsSaga(api) {
  yield all([
    takeLatest(NewsTypes.NEWS_GET_REQUEST, attemptGetNews, api),
    takeLatest(NewsTypes.NEWS_UPDATE_REQUEST, attemptUpdateNews, api),
    takeLatest(NewsTypes.NEWS_CREATE_REQUEST, attemptCreateNew, api),
    takeLatest(NewsTypes.NEWS_DELETE_REQUEST, attemptDeleteNews, api),
  ]);
}
export default newsSaga;
