import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import ApiActions, { ApiRequestData } from "../../../Redux/ApiRedux";
import { endpoints, parseEndpoint } from "../../../Services/endpoints";

// @ts-expect-error TS7006
const useMilestoneManagementScreen = (props) => {
  const { history } = props;
  const [isMilestoneSortable, setIsMilestoneSortable] = useState(false);
  const [milestones, setMilestones] = useState([]);
  const dispatch = useDispatch();
  const [batchName, setBatchName] = useState("");
  const [persistedOrder, setPersistedOrder] = useState(null);
  const getBatchId = () => {
    const {
      match: { params },
    } = props;
    return params.id ? params.id : -1;
  };
  const getBatchDetails = () => {
    const requestBuilder: ApiRequestData = {
      method: "getRequest",
      url: parseEndpoint(endpoints.singleBatch, {
        batchId: getBatchId(),
      }),
      data: {},
    };
    dispatch(
      ApiActions.apiRequest(requestBuilder, ({ data }) => {
        //@ts-expect-error 要API型定義
        setBatchName(data.name);
        setIsMilestoneSortable(false);
        //@ts-expect-error 要API型定義
        if (Array.isArray(data.milestones)) {
          // @ts-expect-error TS7006
          const orderMilestone = data.milestones.sort((a, b) => {
            return a.index - b.index;
          });
          if (persistedOrder === null) {
            setPersistedOrder(orderMilestone);
          }
          // @ts-expect-error TS2345
          setMilestones([...orderMilestone]);
        }
      }),
    );
  };
  // @ts-expect-error TS7006
  const saveMilestoneNewOrder = (milestones) => {
    const requestBuilder: ApiRequestData = {
      method: "putRequest",
      url: parseEndpoint(endpoints.singleBatch, {
        batchId: getBatchId(),
      }),
      data: {
        milestones: milestones,
      },
    };
    dispatch(
      ApiActions.apiRequest(requestBuilder, ({ data }) => {
        getBatchDetails();
      }),
    );
  };
  // @ts-expect-error TS7006
  const deleteAMilestone = (index) => {
    const newMilestones = milestones
      .filter((m) => {
        // @ts-expect-error TS2339
        return m.index !== index;
      })
      .map((m, ii) => {
        return {
          // @ts-expect-error TS2698
          ...m,
          index: ii,
        };
      });
    const requestBuilder: ApiRequestData = {
      method: "putRequest",
      url: parseEndpoint(endpoints.singleBatch, {
        batchId: getBatchId(),
      }),
      data: {
        milestones: newMilestones,
      },
    };
    // @ts-expect-error TS2345
    setMilestones([...newMilestones]);
    dispatch(
      ApiActions.apiRequest(requestBuilder, ({ data }) => {
        getBatchDetails();
      }),
    );
  };
  // @ts-expect-error TS7006
  const onSaveEditMilestone = (milestone) => {
    const newMilestones = milestones.map((m) => {
      // @ts-expect-error TS2339
      if (m.index !== milestone.index) {
        return m;
      }
      return milestone;
    });
    const requestBuilder: ApiRequestData = {
      method: "putRequest",
      url: parseEndpoint(endpoints.singleBatch, {
        batchId: getBatchId(),
      }),
      data: {
        milestones: newMilestones,
      },
    };
    dispatch(
      ApiActions.apiRequest(requestBuilder, ({ data }) => {
        getBatchDetails();
      }),
    );
  };
  const saveReOrder = () => {
    saveMilestoneNewOrder(milestones);
  };
  useEffect(() => {
    getBatchDetails();
  }, []);
  return {
    batchName,
    history,
    setIsMilestoneSortable,
    persistedOrder,
    setMilestones,
    isMilestoneSortable,
    saveReOrder,
    setPersistedOrder,
    milestones,
    onSaveEditMilestone,
    deleteAMilestone,
  };
};

export default useMilestoneManagementScreen;
