import { Card, Col, Popover } from "antd";
import moment from "moment";
import React from "react";

import DownloadIcon from "../../../assets/icons/download.svg";
import { GridViewProjectFilesProps } from "../../../Screens/project/types/GridViewProjectFilesProps";
import { dateFormat } from "../../../utils/constants";
import { GridViewCard } from "../../molecules/project/GridViewCard";

const { Meta } = Card;

const GridViewProjectFiles: React.FC<GridViewProjectFilesProps> = function ({
  files,
  saveFile,
  preview,
  _renderFileDeleteButton,
}) {
  // @ts-expect-error TS7006
  const cardHeader = (file) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row-reverse",
        }}
      >
        {/*
         // @ts-expect-error TS2722 */}
        <div>{_renderFileDeleteButton(file)}</div>

        <div
          onClick={() => {
            const hashImageRef =
              file.hash.indexOf("http") === -1
                ? `https://${file.hash}`
                : file.hash;
            // @ts-expect-error TS2722
            saveFile(
              hashImageRef,
              file.name,
              file.name.split(".").pop(),
              file.id,
            );
          }}
        >
          <a target="_blank">
            <img style={{ marginLeft: "1vw" }} src={DownloadIcon} />
          </a>
        </div>
      </div>
    );
  };
  // @ts-expect-error TS18048
  return files.map((file) => {
    return (
      <Col xs={24} sm={12} md={8} lg={6} key={file.id}>
        <div
          className={"GridViewProjectFiles"}
          style={{
            marginRight: "1vw",
            marginLeft: "1vw",
            marginBottom: "2vh",
            boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.15)",
            borderRadius: "4px",
            border: "1px solid #DDDDDD",
          }}
        >
          {/*
           // @ts-expect-error TS2722 */}
          <GridViewCard title={cardHeader(file)} cover={preview(file)}>
            <Popover content={file.name} trigger="hover">
              <Meta
                title={file.name}
                description={moment(file.date).local().format(dateFormat)}
              />
            </Popover>
          </GridViewCard>
        </div>
      </Col>
    );
  });
};
export default GridViewProjectFiles;
