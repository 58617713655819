import { Select as AntSelect } from "antd";
import styled from "styled-components";

const { Option } = AntSelect;

type SizeProps = {
  width?: string;
  height?: string;
};

const Select = styled(AntSelect)<SizeProps>`
  width: ${(props) => (props.width ? props.width : "20%")};
  height: ${(props) => (props.height ? props.height : "46px")};
` as typeof AntSelect;

export { Option, Select };
