import { Modal } from "antd";
import { useIntl } from "react-intl";

import { Col } from "@/Components/atoms";
import ProjectExaminationReview from "@/Components/organisms/submissions/ProjectExxaminationReview";
import { usePublishedReviewResult } from "@/features/submission/hooks/useProjectReview";

import { ProjectReviewDetailModalProps } from "./type";

export const ProjectReviewDetailModal = ({
  submissionId,
  projectId,
  visible,
  onCancel,
}: ProjectReviewDetailModalProps) => {
  const intl = useIntl();
  const { publishedReviewResult } = usePublishedReviewResult(submissionId);

  return publishedReviewResult ? (
    <Modal
      title={`${intl.formatMessage({
        id: "screen.label.examination_outcome",
      })}`}
      visible={visible}
      onCancel={onCancel}
      footer={null}
      width={"60vw"}
    >
      <Col>
        <ProjectExaminationReview
          publishedReviewResult={publishedReviewResult}
          projectId={projectId}
        />
      </Col>
      <br />
    </Modal>
  ) : null;
};
