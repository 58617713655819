import styled from "styled-components";

export const VerticalLayout = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px;
`;

export const HorizonalLayout = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0px;
`;
