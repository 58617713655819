import { VFC } from "react";
import styled from "styled-components";

import ArchiveIcon from "../../../assets/icons/archive_icon.svg";
import CalendarIcon from "../../../assets/icons/calender.svg";
import ChartIcon from "../../../assets/icons/charts.svg";
import CircleIcon from "../../../assets/icons/circle.svg";
import CloseIcon from "../../../assets/icons/close.svg";
import CopyIcon from "../../../assets/icons/copy.svg";
import DateArrowIcon from "../../../assets/icons/date_arrow.svg";
import DeleteIcon from "../../../assets/icons/discard.svg";
import DownArrowIcon from "../../../assets/icons/down_arrow_icon.svg";
import DownloadIcon from "../../../assets/icons/download.svg";
import DraggableIcon from "../../../assets/icons/draggable.svg";
import EditIcon from "../../../assets/icons/edit.svg";
import EventIcon from "../../../assets/icons/event_icon.svg";
import ExaminationIcon from "../../../assets/icons/examination.svg";
import FaceIcon from "../../../assets/icons/face.svg";
import FilledStarIcon from "../../../assets/icons/filled_star.svg";
import FilterIcon from "../../../assets/icons/filter.svg";
import FlagIcon from "../../../assets/icons/flag.svg";
import GoodIcon from "../../../assets/icons/good.svg";
import GrayWarningIcon from "../../../assets/icons/gray_warning.svg";
import HomeIcon from "../../../assets/icons/home_icon.svg";
import IndustryIcon from "../../../assets/icons/industry_icon.svg";
import InfoDarkIcon from "../../../assets/icons/info.svg";
import InfoIcon from "../../../assets/icons/info_icon.svg";
import LeftArrowIcon from "../../../assets/icons/left_arrow.svg";
import MenuIcon from "../../../assets/icons/menu.svg";
import Menu2Icon from "../../../assets/icons/menu2.svg";
import MoreIcon from "../../../assets/icons/more.svg";
import NotificationIcon from "../../../assets/icons/notification_icon.svg";
import PlusIcon from "../../../assets/icons/plus.svg";
import FileIcon from "../../../assets/icons/project_file.svg";
import ProjectIcon from "../../../assets/icons/project_icon.svg";
import TaskIcon from "../../../assets/icons/project_task.svg";
import TopIcon from "../../../assets/icons/project_top.svg";
import Reference from "../../../assets/icons/reference.svg";
import RightArrowIcon from "../../../assets/icons/right_arrow.svg";
import SearchIcon from "../../../assets/icons/search.svg";
import SettingIcon from "../../../assets/icons/setting_icon.svg";
import SortIcon from "../../../assets/icons/sort.svg";
import StatisticsIcon from "../../../assets/icons/statistics_icon.svg";
import SubmissionIcon from "../../../assets/icons/submission_icon.svg";
import UpArrowIcon from "../../../assets/icons/up_arrow_icon.svg";
import UploadIcon from "../../../assets/icons/upload.svg";
import UsersIcon2 from "../../../assets/icons/users.svg";
import UsersIcon from "../../../assets/icons/users_icon.svg";
import VideoIcon from "../../../assets/icons/video_icon.svg";
import VideoNextIcon from "../../../assets/icons/video_next.svg";
import VideoPreviousIcon from "../../../assets/icons/video_previous.svg";
import VisibleIcon from "../../../assets/icons/visible.svg";
import InVisibleIcon from "../../../assets/icons/visible_off.svg";
import ZoomInIcon from "../../../assets/icons/zoomin.svg";
import ZoomOutIcon from "../../../assets/icons/zoomout.svg";

const ImageIcon = styled.img``;

export const Draggable = <ImageIcon src={DraggableIcon} />;
export const IconHome = <ImageIcon src={HomeIcon} />;
export const IconFlag = <ImageIcon src={FlagIcon} />;
export const IconFile = <ImageIcon src={FileIcon} />;
export const IconNoti = <ImageIcon src={NotificationIcon} />;
export const IconProject = <ImageIcon src={ProjectIcon} />;
export const IconLibrary = <ImageIcon src={IndustryIcon} />;
export const IconVideo = <ImageIcon src={VideoIcon} />;
export const IconTask = <ImageIcon src={TaskIcon} />;
export const IconInfo = <ImageIcon src={InfoIcon} />;
export const IconInfoDark = <ImageIcon src={InfoDarkIcon} />;
export const IconResult = <ImageIcon src={SubmissionIcon} />;
export const IconStatistics = <ImageIcon src={StatisticsIcon} />;
export const IconUsers = <ImageIcon src={UsersIcon} />;
export const IconUsers2 = <ImageIcon src={UsersIcon2} />;
export const IconExamination = <ImageIcon src={ExaminationIcon} />;
export const IconArchive = <ImageIcon src={ArchiveIcon} />;
export const IconEvent = <ImageIcon src={EventIcon} />;
export const IconSetting = <ImageIcon src={SettingIcon} />;
export const IconTop = <ImageIcon src={TopIcon} />;
export const IconCircle = <ImageIcon src={CircleIcon} />;
export const IconClose = <ImageIcon src={CloseIcon} />;
export const IconCopy = <ImageIcon src={CopyIcon} />;
export const IconDataArrow = <ImageIcon src={DateArrowIcon} />;
export const IconFace = <ImageIcon src={FaceIcon} />;
export const IconFilter = <ImageIcon src={FilterIcon} />;
export const IconGood = <ImageIcon src={GoodIcon} />;
export const IconLeftArrow = <ImageIcon src={LeftArrowIcon} />;
export const IconRightArrow = <ImageIcon src={RightArrowIcon} />;
export const IconMenu = <ImageIcon src={MenuIcon} />;
export const IconMenu2 = <ImageIcon src={Menu2Icon} />;
export const IconPlus = <ImageIcon src={PlusIcon} />;
export const IconSort = <ImageIcon src={SortIcon} />;
export const IconSearch = <ImageIcon src={SearchIcon} />;
export const IconZoomIn = <ImageIcon src={ZoomInIcon} />;
export const IconZoomOut = <ImageIcon src={ZoomOutIcon} />;
export const IconUpArrow = <ImageIcon src={UpArrowIcon} />;
export const IconDownArrow = <ImageIcon src={DownArrowIcon} />;
export const IconVisible = <ImageIcon src={VisibleIcon} />;
export const IconInVisible = <ImageIcon src={InVisibleIcon} />;
export const IconMore = <ImageIcon src={MoreIcon} />;
export const IconDelete = <ImageIcon src={DeleteIcon} />;
export const IconEdit = <ImageIcon src={EditIcon} />;
export const IconCalendar = <ImageIcon src={CalendarIcon} />;
export const IconUpload = <ImageIcon src={UploadIcon} />;
export const IconDownload = <ImageIcon src={DownloadIcon} />;
export const IconVideoNext = <ImageIcon src={VideoNextIcon} />;
export const IconVideoPrev = <ImageIcon src={VideoPreviousIcon} />;
export const FilledStar = <ImageIcon src={FilledStarIcon} />;
export const IconChart = <ImageIcon src={ChartIcon} />;
export const IconReference = <ImageIcon src={Reference} />;
export const IconGrayWarning = <ImageIcon src={GrayWarningIcon} />;

type ResizableProps = {
  width?: number;
  height?: number;
  alt?: string;
};

export const ResizableIconUsers: VFC<ResizableProps> = (props) => (
  <ImageIcon src={UsersIcon} {...props} />
);
export const ResizableIconUsers2: VFC<ResizableProps> = (props) => (
  <ImageIcon src={UsersIcon2} {...props} />
);
export const ResizableIconChart: VFC<ResizableProps> = (props) => (
  <ImageIcon src={ChartIcon} {...props} />
);
export const ResizableIconCaclendar: VFC<ResizableProps> = (props) => (
  <ImageIcon src={CalendarIcon} {...props} />
);
export const ResizableIconDownload: VFC<ResizableProps> = (props) => (
  <ImageIcon src={DownloadIcon} {...props} />
);
