import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import { EventData } from "@/../types/Event";
import { EventBasicInfoForm } from "@/features/event/components/EventBasicInfoForm";
import { EventScheduleInfoForm } from "@/features/event/components/EventScheduleInfoForm";
import { EventThumbnailUploadButton } from "@/features/event/components/EventThumbnailUploadButton";
import { PrimaryButton } from "@/shared/buttons/components/PrimaryButton";
import { TertiaryButton } from "@/shared/buttons/components/TertiaryButton";
import { ImageUploadArea } from "@/shared/upload/components/ImageUploadArea";

import { EventDetailInfoForm } from "../EventDetailInfoForm";
import styles from "./styles.module.scss";
import { EventFormProps } from "./type";

export const EventForm = ({
  onSubmit,
  thumbnailFile,
  setThumbnailFile,
}: EventFormProps) => {
  const { handleSubmit } = useFormContext<Omit<EventData, "thumbnailUrl">>();

  const history = useHistory();
  const intl = useIntl();
  const publishEventMessage = intl.formatMessage({
    id: "screens.events.publish_event",
  });
  const cancelMessage = intl.formatMessage({
    id: "screen.label.cancel",
  });

  return (
    <div className={styles.container}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ImageUploadArea
          initialImage={thumbnailFile}
          ContentComponent={EventThumbnailUploadButton}
          onChange={setThumbnailFile}
        />
        <div className={styles.event_form}>
          <EventBasicInfoForm />
          <EventScheduleInfoForm />
          <EventDetailInfoForm />
          <div className={styles.event_buttons}>
            <PrimaryButton type="submit">{publishEventMessage}</PrimaryButton>
            <TertiaryButton
              onClick={history.goBack}
              className={styles.cancel_button}
              type="button"
            >
              {cancelMessage}
            </TertiaryButton>
          </div>
        </div>
      </form>
    </div>
  );
};
