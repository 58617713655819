import { memo } from "react";

import { ExcelErrorMessageProps } from "@/features/review/components/ExcelErrorMessage//type";
import styles from "@/features/review/components/ExcelErrorMessage/styles.module.scss";
import { DownloadIconButton } from "@/shared/buttons/components/DownloadIconButton";

export const ExcelErrorMessage = memo(
  ({ message, downloadable, downloadErrorExcel }: ExcelErrorMessageProps) => {
    return (
      <div className={styles.message_container}>
        <div className={styles.message}>{message}</div>
        {downloadable && (
          <DownloadIconButton
            onClick={downloadErrorExcel}
            alt="download-error-excel-file"
          />
        )}
      </div>
    );
  },
);
