import { Row, Switch, Typography } from "antd";
import { useIntl } from "react-intl";

import { AntdButton } from "../../../../shared/buttons/components/AntdButton";
import styles from "./styles.module.scss";
import { ExaminationPhaseSortSwitchProps } from "./type";

export const ExaminationPhaseSortSwitch = ({
  onSwitchSortable,
  sortable,
  onSortSave,
}: ExaminationPhaseSortSwitchProps) => {
  const intl = useIntl();

  const switchLabel = intl.formatMessage({
    id: "screen.label.sort_examination_phase",
  });
  const saveButtonLabel = intl.formatMessage({
    id: "screen.label.storage",
  });
  const cancelButtonLabel = intl.formatMessage({
    id: "screen.label.cancel",
  });
  return (
    <div className={styles.container}>
      <Row>
        <Typography className={styles.switch_btn_label}>
          {switchLabel}
        </Typography>
        <Switch
          className={styles.switch_btn}
          onClick={onSwitchSortable}
          checked={sortable}
        />
      </Row>
      {sortable && (
        <Row className={styles.btn_container}>
          <AntdButton category="primary" onClick={onSortSave}>
            {saveButtonLabel}
          </AntdButton>
          <AntdButton
            category="secondary"
            className={styles.cancel_btn}
            onClick={onSwitchSortable}
          >
            {cancelButtonLabel}
          </AntdButton>
        </Row>
      )}
    </div>
  );
};
