import { ContentState, EditorState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import { useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { uploadImageWithDraftEditor } from "../../../Components/molecules";
import { ContentContext } from "../../../Provider";
import { endpoints, parseEndpoint } from "../../../Services/endpoints";
import { getObjUUID, sanitizeHtmlString } from "../../../utils/common";
import { customChunkRenderer } from "../../../utils/editorUtils";

const useProgramInfoViewQAScreen = () => {
  const contentContext = useContext(ContentContext);
  const { user, apiRequest } = contentContext;
  const intl = useIntl();
  const companyId = getObjUUID(user.company);
  const [editorState, setEditorState] = useState(() => {
    return EditorState.createEmpty();
  });
  useEffect(() => {
    getExistingProgramQAInfo();
  }, []);
  const getExistingProgramQAInfo = () => {
    const requestBuilder = {
      method: "getRequest",
      url: parseEndpoint(endpoints.programInfoQA),
      data: {},
    };
    // @ts-expect-error TS2345
    apiRequest(requestBuilder, ({ data }) => {
      // @ts-expect-error TS18046
      if (data.content) {
        const contentBlock = htmlToDraft(
          // @ts-expect-error TS18046
          sanitizeHtmlString(data.content),
          // @ts-expect-error TS2345
          customChunkRenderer,
        );
        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(
            contentBlock.contentBlocks,
          );
          setEditorState(EditorState.createWithContent(contentState));
        }
      }
    });
  };
  const uploadImageCallBack = (file: any) => {
    return new Promise((resolve, reject) => {
      uploadImageWithDraftEditor({
        file,
        companyId,
        authToken: user.token,
        subLink: "program_info_qa",
        headerDetail: {
          type: "program_info_qa",
        },
      })
        .then((response: any) => {
          resolve(response);
        })

        .catch((error) => {
          reject(error);
        });
    });
  };

  return {
    intl,
    authUser: user,
    companyId,
    editorState,
    getExistingProgramQAInfo,
    uploadImageCallBack,
    setEditorState: () => {},
  };
};

export default useProgramInfoViewQAScreen;
