import { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { ApiRequestData } from "@/Redux/ApiRedux";

import { useContextProvider } from "../../../Provider/ContextProvider";
import { endpoints, parseEndpoint } from "../../../Services/endpoints";
import { getObjUUID } from "../../../utils/common";

export const useProjectCheckAccessPermission = (props: any) => {
  const history = useHistory();
  const context = useContextProvider();

  const getProjectId = () => {
    const {
      match: { params },
    } = props;
    return params.id ? params.id : -1;
  };

  const checkTeamMember = (project: any) => {
    const user = context?.user;
    const isTeamMember =
      project.isTeamMember || (user.type === "admin" && user.isSuper);
    if (!isTeamMember && project.isPrivate) {
      history.replace(`/dashboard/project.top/${getObjUUID(project)}`);
    }
  };

  const getProjectDetail = (id: string) => {
    const batchRequest: ApiRequestData = {
      method: "getRequest",
      url: parseEndpoint(endpoints.singleProject, { id }),
    };
    context?.apiRequest(batchRequest, ({ data }) => {
      checkTeamMember(data);
    });
  };

  useEffect(() => {
    getProjectDetail(getProjectId());
  }, []);

  return;
};
