import { FC } from "react";

import { EditableTag } from "../EditableTag/component";
import { InputTag } from "../InputTag";

export type TagList = {
  tags: string[];
  createLabel: string;
  onCreated: (tag: string) => void;
  onEdited?: (oldTag: string, newTag: string) => void;
  onDeleted: (tag: string) => void;
};

export const TagList: FC<TagList> = ({
  tags,
  createLabel,
  onCreated,
  onEdited,
  onDeleted,
}) => (
  <>
    {tags.map((tag, i) => (
      <EditableTag
        key={i}
        tag={tag}
        onEdited={onEdited}
        onDeleted={onDeleted}
      />
    ))}

    <InputTag onCreated={onCreated} createLabel={createLabel} />
  </>
);
