import styled from "styled-components";

export const CompanyBanner = styled.img`
  width: 100%;
  max-height: 40vh;
  object-fit: contain;
`;

export const PreLineEditor = styled.p`
  white-space: pre-line;
`;
