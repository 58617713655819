import { Button, Col, Row, Switch } from "antd";
import { arrayMoveImmutable } from "array-move";
import React from "react";
import { FormattedMessage } from "react-intl";
import { SortableContainer, SortableElement } from "react-sortable-hoc";

import BatchActions from "../../Redux/BatchRedux";
import { SortableBatch } from "./Components/SortableBatch";
import useBatchManagementComponent from "./hooks/useBatchManagementComponent";
import NewBatchModal from "./NewBatchModal";
import SingleBatchItem from "./SingleBatchItem";
import {
  SortableSingleBatchItemProps,
  SortableSingleBatchListProps,
} from "./types/SortableSingleBatchItemProps";

// @ts-expect-error TS2322
const SortableSingleBatchItem: React.FC<SortableSingleBatchItemProps> =
  SortableElement(
    ({
      onSaveBatch,
      batch,
      openNewBatchModal,
      isBatchesSortable,
    }: SortableSingleBatchItemProps) => (
      <li>
        <SingleBatchItem
          onSaveBatch={onSaveBatch}
          batch={batch}
          openNewBatchModal={openNewBatchModal}
          isBatchesSortable={isBatchesSortable}
        />
      </li>
    ),
  );

// @ts-expect-error TS2322
const SortableSingleBatchList: React.FC<SortableSingleBatchListProps> =
  SortableContainer(
    ({
      items,
      onSaveBatch,
      isBatchesSortable,
      openNewBatchModal,
    }: SortableSingleBatchListProps) => {
      return (
        <div>
          {/*
           // @ts-expect-error TS18048 */}
          {items.map((batch, index) => {
            return (
              <SortableSingleBatchItem
                key={batch.id}
                // @ts-expect-error TS2322
                disabled={!isBatchesSortable}
                index={index}
                onSaveBatch={onSaveBatch}
                batch={batch}
                openNewBatchModal={openNewBatchModal}
                isBatchesSortable={isBatchesSortable}
              />
            );
          })}
        </div>
      );
    },
  );

const BatchManagementComponent: React.FC = function () {
  const {
    batches,
    defaultNewBatchData,
    setDefaultNewBatchData,
    _clearBatchDefault,
    setIsBatchesSortable,
    isNewBatchModalVisible,
    setIsNewBatchModalVisible,
    isBatchesSortable,
    dispatch,
    onSaveBatch,
  } = useBatchManagementComponent();
  return (
    <>
      {isNewBatchModalVisible ? (
        <NewBatchModal
          defaultNewBatchData={defaultNewBatchData}
          onSaveBatch={onSaveBatch}
          setIsNewBatchModalVisible={setIsNewBatchModalVisible}
          isVisible={isNewBatchModalVisible}
          onModalCLosed={() => {
            _clearBatchDefault();
          }}
        />
      ) : null}
      <Row gutter={[12, 12]} align={"middle"}>
        <Col span={18}>
          <SortableBatch>
            <FormattedMessage id="screens.label.reorder_batches" />
            <Switch
              style={{ marginLeft: "6px" }}
              size={"small"}
              onChange={(checked) => {
                setIsBatchesSortable(checked);
                if (checked === false) {
                  // @ts-expect-error TS2554
                  dispatch(BatchActions.batchGetsRequest(""));
                }
              }}
              checked={isBatchesSortable}
            />
          </SortableBatch>
        </Col>

        <Col span={6}>
          <Button
            onClick={() => {
              setIsNewBatchModalVisible(true);
            }}
            style={{
              backgroundColor: "#00D282",
              color: "white",
              fontWeight: 400,
            }}
          >
            <FormattedMessage id="screens.company.new_batch_action" />
          </Button>
        </Col>
      </Row>
      {isBatchesSortable ? (
        <Row>
          <Col>
            <Button
              type={"ghost"}
              onClick={() => {
                if (Array.isArray(batches)) {
                  batches.forEach((b) => {
                    onSaveBatch({
                      id: b.id,
                      ord: b.ord,
                    });
                  });
                }
              }}
            >
              <FormattedMessage id="screen.label.save" />
            </Button>
          </Col>
          <Col>
            <Button
              onClick={() => {
                // @ts-expect-error TS2554
                dispatch(BatchActions.batchGetsRequest(""));
                setIsBatchesSortable(false);
              }}
            >
              <FormattedMessage id="screen.label.cancel" />
            </Button>
          </Col>
        </Row>
      ) : null}

      {Array.isArray(batches) ? (
        <SortableSingleBatchList
          // @ts-expect-error TS2322
          pressDelay={100}
          onSaveBatch={onSaveBatch}
          // @ts-expect-error TS7006
          openNewBatchModal={(d) => {
            setDefaultNewBatchData(d);
            setIsNewBatchModalVisible(true);
          }}
          helperClass={"SortableHelper"}
          items={batches}
          isBatchesSortable={isBatchesSortable}
          // @ts-expect-error TS7031
          onSortEnd={({ oldIndex, newIndex }) => {
            const orderedItems = arrayMoveImmutable(
              batches,
              oldIndex,
              newIndex,
            ).map((b, index) => {
              return {
                ...b,
                ord: index,
              };
            });
            // @ts-expect-error TS2554
            dispatch(BatchActions.batchGetsSuccess(orderedItems));
          }}
        />
      ) : null}
    </>
  );
};
export default BatchManagementComponent;
