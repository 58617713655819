import { notification } from "antd";
import { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";

import { endpoints, parseEndpoint } from "../../../../Services/endpoints";
import { ChangePasswordComponentState } from "./type";

// @ts-expect-error TS7006
const useChangePasswordComponent = (props) => {
  const intl = useIntl();
  const [state, customSetState] = useState<ChangePasswordComponentState>(() => {
    return {
      showPassResetModal: false,
      newPassword: "",
      confirmPassword: "",
      name: props.user.name,
    };
  });

  const setStateCallbackRef = useRef(() => {});
  useEffect(() => {
    const callBack = setStateCallbackRef.current;
    callBack();
    setStateCallbackRef.current = () => {};
  }, [state]);
  const setState = (
    data: ChangePasswordComponentState,
    callback = () => {},
  ) => {
    setStateCallbackRef.current = callback;
    customSetState((previousState) => {
      return {
        ...previousState,
        ...data,
      };
    });
  };

  useEffect(() => {
    setState({
      showPassResetModal: !props.user.hasPasswordChanged,
    });
  }, [props?.user?.hasPasswordChanged]);

  const handleResetPass = () => {
    const isPasswordValid = handleValidPassword(state.newPassword);
    if (isPasswordValid) {
      const requestBuilder = {
        method: "postRequest",
        url: parseEndpoint(endpoints.changePassword),
        data: {
          id: props.user.id,
          password: state.newPassword,
          name: state.name,
        },
      };
      // @ts-expect-error TS7031
      props.dispatchApiRequest(requestBuilder, ({ data }) => {
        props.refreshData();
        setState({
          showPassResetModal: false,
        });
      });
    }
  };
  // @ts-expect-error TS7006
  const handleValidPassword = (password) => {
    if (password) {
      if (password.replace(/(\r\n|\n|\r|\s)/g, "").length < 8) {
        notification.error({
          message: intl.formatMessage({
            id: "screen.label.password_too_short",
          }),
        });
        return false;
      }
      if (!password.match(/\d/)) {
        notification.error({
          message: intl.formatMessage({
            id: "screen.label.password_please_include_number",
          }),
        });
        return false;
      } else if (!password.match(/[a-z]/i)) {
        notification.error({
          message: intl.formatMessage({
            id: "screen.label.password_please_include_en",
          }),
        });
        return false;
      } else if (password !== state.confirmPassword) {
        notification.error({
          message: intl.formatMessage({
            id: "screens.message.pass_match",
          }),
        });
        return false;
      }
      return true;
    }
    notification.error({
      message: intl.formatMessage({
        id: "screen.label.password_too_short",
      }),
    });
    return false;
  };
  // @ts-expect-error TS7006
  const handleChange = (key, value) => {
    setState({ [key]: value });
  };

  return {
    intl,
    state,
    handleResetPass,
    handleChange,
  };
};

export default useChangePasswordComponent;
