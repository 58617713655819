import { Card, Col, Row } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import Title from "antd/lib/typography/Title";
import { useIntl } from "react-intl";

import { IconGrayWarning } from "@/Components/atoms/icons";

import styles from "./styles.module.scss";
import { NotFoundCardProps } from "./type";

function capitalize(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const NotFoundCard = ({ resourceName }: NotFoundCardProps) => {
  const intl = useIntl();

  return (
    <Card className={`library-news ${styles.error_card}`}>
      <Row align="middle" justify="center" className={styles.error_row}>
        <Col flex="none"> {IconGrayWarning} </Col>

        <Col span={10} className={styles.description_column}>
          <Title level={4}>
            {intl.formatMessage(
              {
                id: "screen.label.resource_not_found",
              },
              {
                resource: capitalize(resourceName),
              },
            )}
          </Title>
          <Paragraph className={styles.description_paragraph}>
            {intl.formatMessage(
              {
                id: "screen.label.reasons_for_missing_resources",
              },
              {
                resource: resourceName,
              },
            )}
          </Paragraph>
        </Col>
      </Row>
    </Card>
  );
};
