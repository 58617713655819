import { useMemo } from "react";
import { useIntl } from "react-intl";

import { PublishedStatus } from "@/features/review/components/ProjectSubmissionsScreen/type";
import { PublishSelectBoxProps } from "@/features/review/components/PublishSelectBox/type";
import { MultiCheckSelectBox } from "@/shared/selectbox/components/MultiCheckSelectBox";
import { SelectOption } from "@/shared/selectbox/types/SelectOption";

export const PublishSelectBox = ({
  selectedPublishedStatuses,
  onChangeSelectedPublishedStatus,
  resetCheck,
}: PublishSelectBoxProps) => {
  const intl = useIntl();
  const defaultLabel = intl.formatMessage({
    id: "screen.label.review.filter_by_published_status",
  });
  const publishedLabel = intl.formatMessage({
    id: "screen.label.review.published",
  });
  const unpublishedLabel = intl.formatMessage({
    id: "screen.label.review.unpublished",
  });

  const options: SelectOption<PublishedStatus>[] = useMemo(
    () => [
      {
        value: "PUBLISHED",
        label: publishedLabel,
      },
      {
        value: "UNPUBLISHED",
        label: unpublishedLabel,
      },
    ],
    [publishedLabel, unpublishedLabel],
  );

  const onSelect = (newValue: SelectOption<PublishedStatus>) => {
    if (newValue.value === null) return;
    onChangeSelectedPublishedStatus(newValue.value);
  };

  const selectedOptions = useMemo(
    () =>
      options.filter(
        ({ value }) => value && selectedPublishedStatuses.includes(value),
      ),
    [options, selectedPublishedStatuses],
  );

  return (
    <MultiCheckSelectBox
      defaultLabel={defaultLabel}
      onSelect={onSelect}
      selectedOptions={selectedOptions}
      resetCheck={resetCheck}
      options={options}
      id="publish-select-box"
      width="230px"
    />
  );
};
