/* eslint-disable react/prop-types */
import "./styles.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { QuestionCircleOutlined } from "@ant-design/icons";
import { Button, Col, Form, Modal, Popconfirm, Popover, Row } from "antd";
import { EditorState } from "draft-js";
import moment from "moment";
import { FC, ReactElement, useState } from "react";
import { FormattedMessage, injectIntl, useIntl } from "react-intl";
import { withRouter } from "react-router-dom";

import { endpoints, parseEndpoint } from "../../../Services/endpoints";
import {
  convertFromRawWithTrimmer,
  convertToRawWithTrimmer,
  convertYVLinksToEmbed,
  getObjUUID,
  isJsonString,
  sanitizeEditorStateMarkupHtml,
} from "../../../utils/common";
import { dateTimeFormat } from "../../../utils/constants";
import { Checkbox, IconInVisible, IconMore, IconVisible } from "../../atoms";
import {
  DraftEditor,
  Input,
  nodeConfig,
  uploadImageWithDraftEditor,
} from "../../molecules";
import {
  INotificationComponentProps,
  Notification,
  NotificationData,
  NotificationMeta__deleted_event,
  NotificationMeta__event_registration,
  NotificationMeta__industry_theme_report,
  NotificationMeta__news_posted,
  NotificationMeta__project_creation,
  NotificationMeta__project_invitation_acceptance,
  NotificationMeta__project_invitation_rejection,
  NotificationMeta__project_invitation_request,
  NotificationMeta__project_invitation_request_from_myself,
  NotificationMeta__project_submission,
  NotificationMeta__project_submission_review,
  NotificationMeta__project_watch,
  NotificationMeta__projection_rejection_by_invitee,
  NotificationMeta__projection_rejection_by_owner,
  NotificationMeta__recruit_members,
  NotificationMeta__user_mentioned,
} from "./types";
import NotificationItemWraper from "./Wrapper";

const notificationColorAndTitle: { [key: string]: { title_id: string } } = {
  other: {
    title_id: "screen.label.creating_project",
  },
  industry_theme_report: {
    title_id: "screen.label.industry_theme_report",
  },
  news_posted: {
    title_id: "screen.label.news",
  },
  project_watch: {
    title_id: "screen.label.watch",
  },
  projection_rejection_by_invitee: {
    title_id: "screen.label.decline_participation",
  },
  projection_rejection_by_owner: {
    title_id: "screen.label.rejection_participation",
  },
  user_mentioned: {
    title_id: "screen.label.mention",
  },
  project_invitation_request_from_myself: {
    title_id: "screen.label.participation_application",
  },
  project_invitation_request: {
    title_id: "screen.label.member_invitation",
  },
  project_invitation_acceptance: {
    title_id: "screen.label.approval_participation",
  },
  project_invitation_rejection: {
    title_id: "screen.label.denial_participation",
  },
  project_submission_review: {
    title_id: "screen.label.examination_outcome",
  },
  project_creation: {
    title_id: "screen.label.creating_project",
  },
  general_notice: {
    title_id: "screen.label.notice",
  },
  important_notice: {
    title_id: "screen.label.important_notices",
  },
  poll: {
    title_id: "screen.label.questionnaire",
  },
  deleted_event: {
    title_id: "screen.label.seminars_events",
  },
  event_registration: {
    title_id: "screen.label.seminars_events",
  },
  project_submission: {
    title_id: "screen.label.submission_project",
  },
  recruit_members: {
    title_id: "screen.label.members_wanted",
  },
  reference_posted: {
    title_id: "screen.label.reference",
  },
};

const NotificationComponent: FC<INotificationComponentProps> = function (
  props,
) {
  const intl = useIntl();

  const [notificationData, setNotificationData] = useState<NotificationData>({
    notificationId: 0,
    actionText: "",
    editorState: null,
    sendEmail: false,
    meta: { type: "" },
    message: "",
    videoUrl: "",
  });

  const toggleNotificationVisibility = (notification: Notification) => {
    const notificationRequest = {
      method: "putRequest",
      url: parseEndpoint(endpoints.updateNotification, {
        id: notification.id,
      }),
      data: {
        visibility: notification.visibility === "show" ? "hide" : "show",
      },
    };
    // @ts-expect-error TS2345
    props.dispatchApiRequest?.(notificationRequest, () => {
      props.refresh();
    });
  };
  const _renderEditNotificationModal = (
    modalTitle: React.ReactNode | string,
  ) => {
    const { notification } = props;
    return (
      <Modal
        destroyOnClose
        title={modalTitle}
        visible={notificationData.notificationId > 0}
        onOk={onNotificationEditClickedOk}
        onCancel={onNotificationEditClickedCancel}
        cancelText={intl.formatMessage({
          id: "screen.label.cancel",
        })}
      >
        <Form layout={"vertical"}>
          <Form.Item>
            <Input
              title={intl.formatMessage({
                id: "screen.label.title",
              })}
              required
              onChange={(event) => {
                onChangeNotification("actionText", event.target.value);
              }}
              value={notificationData.actionText}
              placeholder={intl.formatMessage({
                id: "screen.label.title",
              })}
            />
          </Form.Item>
          <Form.Item>
            {notification.nodalData ? (
              <DraftEditor
                title={intl.formatMessage({
                  id: "screen.label.message",
                })}
                toolbar={nodeConfig(
                  uploadImageCallBack,
                  convertYVLinksToEmbed.bind(
                    NotificationComponent,
                    props.authToken,
                    props.notification.companyId,
                  ),
                )}
                placeholder={intl.formatMessage({
                  id: "screen.label.message",
                })}
                editorState={notificationData.editorState}
                onEditorStateChange={(editorState) => {
                  onChangeNotification("editorState", editorState);
                }}
              />
            ) : (
              <Input
                title={intl.formatMessage({
                  id: "screen.label.message",
                })}
                required
                onChange={(event) => {
                  onChangeNotification("message", event.target.value);
                }}
                value={notificationData.message}
                placeholder={intl.formatMessage({
                  id: "screen.label.message",
                })}
              />
            )}
          </Form.Item>
          <Form.Item>
            <Input
              title={intl.formatMessage({
                id: "screen.label.video_url",
              })}
              onChange={(event) =>
                onChangeNotification("videoUrl", event.target.value)
              }
              value={notificationData.videoUrl ?? ""}
              placeholder={intl.formatMessage({
                id: "screen.label.video_url",
              })}
            />
          </Form.Item>

          {_canSendEmail(notification.meta.type) ? (
            <Form.Item>
              <p>
                <FormattedMessage id="screens.notification.send_email_count" />:{" "}
                {`${notificationData.sendEmailCount}`}
              </p>
              <Checkbox
                onChange={(e) => {
                  onChangeNotification("sendEmail", e.target.checked);
                }}
                checked={notificationData.sendEmail}
              >
                {intl.formatMessage({
                  id: "screen.label.email_notification",
                })}
              </Checkbox>
            </Form.Item>
          ) : null}
        </Form>
      </Modal>
    );
  };
  const _canSendEmail = (notificationMetaType?: string | null): boolean => {
    switch (notificationMetaType) {
      case "general_notice":
        return true;
      case "important_notice":
        return true;
      case "poll":
        return true;
      case "event_registration":
        return true;
      default:
        return false;
    }
  };
  const _shouldShowEditResendButton = (
    notificationMetaType?: string | null,
  ): boolean => {
    const { enableEditing } = props;
    if (!enableEditing) {
      return false;
    }
    switch (notificationMetaType) {
      case "general_notice":
      case "important_notice":
      case "poll":
      case "event_reminder_from_admin":
        return true;
      default:
        return false;
    }
  };
  const onMakeUpdateNotificationAPIRequest = (
    id: number,
    data: Partial<Notification>,
    successMessage = intl.formatMessage({
      id: "screen.label.notification_updated",
    }),
  ) => {
    const requestBuilder = {
      method: "putRequest",
      url: parseEndpoint(endpoints.updateNotification, {
        id,
      }),
      data,
      successMessage,
    };
    // @ts-expect-error TS2345
    props.dispatchApiRequest?.(requestBuilder, () => {
      props.refresh();
    });
  };
  const onCLickForEditNotification = () => {
    const { notification } = props;
    setNotificationData({
      notificationId: notification.id,
      actionText: notification.actionText,
      sendEmail: notification.sendEmail,
      message: notification.message,
      videoUrl: notification.meta.videoUrl,
      sendEmailCount: notification.meta.sendEmailCount
        ? notification.meta.sendEmailCount
        : 0,
      editorState:
        notification.nodalData && isJsonString(notification.message)
          ? sanitizeEditorStateMarkupHtml(
              EditorState.createWithContent(
                convertFromRawWithTrimmer(notification.message),
              ),
            )
          : null,
    });
  };
  const onNotificationEditClickedCancel = () => {
    setNotificationData({
      notificationId: 0,
      actionText: "",
      sendEmailCount: 0,
    });
  };

  const onChangeNotification = <N extends keyof NotificationData>(
    key: N,
    value: NotificationData[N],
  ) => {
    notificationData[key] = value;
    setNotificationData({ ...notificationData });
  };
  const onNotificationEditClickedOk = () => {
    const { notification } = props;
    const { notificationId, editorState, ...dataa } = notificationData;
    const { sendEmailCount = 0, videoUrl = null, ...data } = dataa;
    data.meta = notification.meta;
    // @ts-expect-error TS2322
    data.meta.videoUrl = videoUrl;
    data.meta.sendEmailCount = notificationData.sendEmail
      ? sendEmailCount + 1
      : sendEmailCount;

    if (Object.hasOwn(data.meta, "proxy")) {
      delete data.meta.proxy;
    }
    if (notification.nodalData) {
      if (!editorState) throw Error("editorState is required");
      data.message = `${convertToRawWithTrimmer(editorState)}`;
      onMakeUpdateNotificationAPIRequest(notificationId, data);
    } else {
      onMakeUpdateNotificationAPIRequest(notificationId, data);
    }
    onNotificationEditClickedCancel();
  };
  const uploadImageCallBack = (file: File): Promise<unknown> => {
    const { notification } = props;
    return new Promise<unknown>((resolve, reject) => {
      uploadImageWithDraftEditor({
        file,
        companyId: `${notification.companyId}`,
        authToken: props.authToken,
        subLink: "notification",
        headerDetail: {
          type: "WYSIWYG file",
          notificationId: notification.id ? notification.id : null,
        },
      })
        .then((response: unknown) => {
          resolve(response);
        })

        .catch((error) => {
          reject(error);
        });
    });
  };
  const renderTitle = (
    title: string,
    style?: { [key: string]: string | number },
  ): ReactElement => {
    return (
      <div
        className={"notification-type"}
        style={{
          ...style,
        }}
      >
        {title}
      </div>
    );
  };
  const renderSentEmailBadge = (
    notification: Notification,
  ): ReactElement | null => {
    const { draftMessage } = props;
    if (notification.sendEmail && !draftMessage) {
      return renderTitle(
        intl.formatMessage({
          id: "screen.label.email_notified",
        }),
        {
          marginLeft: 20,
        },
      );
    }
    return null;
  };

  const _renderNotificationEditButton = (
    notification: Notification,
  ): ReactElement | null => {
    const { enableEditing } = props;

    if (
      enableEditing &&
      notification.deletedAt == null &&
      _shouldShowEditResendButton(notification.meta.type)
    ) {
      return (
        <Button
          style={{ marginLeft: 6 }}
          type={"link"}
          onClick={(e) => {
            if (e && e.stopPropagation) e.stopPropagation();
            if (props.onCLickForEditNotification) {
              props.onCLickForEditNotification(notification);
            } else {
              onCLickForEditNotification();
            }
          }}
        >
          {intl.formatMessage({
            id: "screen.label.editing",
          })}
        </Button>
      );
    }
    return null;
  };
  const _renderNotificationDeleteButton = (
    notification: Notification,
  ): ReactElement | null => {
    const { onDeleteNotification, enableEditing } = props;
    const canDeleteMetaType: string[] = [
      "event_registration",
      "news_posted",
      "industry_theme_report",
      "project_creation",
      "recruit_members",
      "general_notice",
      "important_notice",
      "poll",
    ];

    if (
      enableEditing &&
      notification.deletedAt == null &&
      canDeleteMetaType.includes(notification.meta.type)
    ) {
      return (
        <Popconfirm
          title={intl.formatMessage({
            id: "screen.label.confirm_delete_notification",
          })}
          icon={<QuestionCircleOutlined style={{ color: "red" }} />}
          onConfirm={(e) => {
            // prevent parent event
            if (e && e.stopPropagation) e.stopPropagation();
            onDeleteNotification?.(notification);
          }}
          onCancel={(e) => {
            // prevent parent event
            if (e && e.stopPropagation) e.stopPropagation();
          }}
          okText={intl.formatMessage({
            id: "screen.label.yes",
          })}
          cancelText={intl.formatMessage({
            id: "screen.label.no",
          })}
        >
          <Button
            style={{ marginLeft: 6 }}
            type={"link"}
            onClick={(e) => {
              // prevent parent event
              if (e && e.stopPropagation) e.stopPropagation();
            }}
          >
            {intl.formatMessage({
              id: "screen.label.delete",
            })}
          </Button>
        </Popconfirm>
      );
    }
    return null;
  };
  const _renderNotificationVisibilityButton = (
    notification: Notification,
  ): ReactElement | null => {
    const { enableEditing } = props;

    const canShowEyeButtonMetaType: string[] = [
      "event_registration",
      "news_posted",
      "industry_theme_report",
      "project_creation",
      "recruit_members",
      "general_notice",
      "important_notice",
      "poll",
    ];
    if (
      notification.draftState !== 0 &&
      enableEditing &&
      notification.deletedAt == null &&
      canShowEyeButtonMetaType.includes(notification.meta.type)
    ) {
      return (
        <Popover
          content={
            notification.visibility === "show"
              ? intl.formatMessage({
                  id: "screen.notification.hide_notification",
                })
              : intl.formatMessage({
                  id: "screen.notification.show_notification",
                })
          }
          trigger="hover"
        >
          <Button
            className="offset-left-16"
            style={{ height: "auto", padding: 0 }}
            onClick={(e) => {
              // prevent parent event
              if (e && e.stopPropagation) e.stopPropagation();
              toggleNotificationVisibility(notification);
            }}
          >
            {notification.visibility === "show" ? IconInVisible : IconVisible}
          </Button>
        </Popover>
      );
    }
    return null;
  };
  const _renderEmailCountAndResendButton = (
    notification: Notification,
  ): ReactElement | null => {
    if (
      notification.draftState !== 0 &&
      !notification.deletedAt &&
      notification.meta &&
      _shouldShowEditResendButton(notification.meta.type)
    ) {
      const currentUserRole = props.currentUserRole;
      if (currentUserRole === "admin") {
        const data = notification;
        return (
          <Popconfirm
            title={intl.formatMessage({
              id: "screen.label.confirm_send_email_notification",
            })}
            icon={<QuestionCircleOutlined style={{ color: "red" }} />}
            onConfirm={(e) => {
              if (e && e.stopPropagation) e.stopPropagation();
              const sendEmailCount = data.meta.sendEmailCount
                ? data.meta.sendEmailCount
                : 0;
              data.meta.sendEmailCount = sendEmailCount + 1;
              onMakeUpdateNotificationAPIRequest(
                data.id,
                {
                  ...data,
                  sendEmail: true,
                },
                intl.formatMessage({
                  id: "screen.label.sent_email_success",
                }),
              );
            }}
            onCancel={(e) => {
              if (e && e.stopPropagation) e.stopPropagation();
            }}
            okText={intl.formatMessage({
              id: "screen.label.yes",
            })}
            cancelText={intl.formatMessage({
              id: "screen.label.no",
            })}
          >
            <Button
              style={{ marginLeft: 6 }}
              type={"link"}
              onClick={(e) => {
                if (e && e.stopPropagation) e.stopPropagation();
              }}
            >
              {intl.formatMessage({
                id: "screen.label.email_notification",
              })}
            </Button>
          </Popconfirm>
        );
      }
    }

    return null;
  };

  const renderDateTime = (notification: Notification): ReactElement => {
    return (
      <small style={{ marginLeft: 6, lineHeight: 3 }}>
        {moment(notification.updatedAt).local().format(dateTimeFormat)}
      </small>
    );
  };

  const renderPopOverNotificationActions = (
    notification: Notification,
  ): ReactElement | null => {
    const { enableEditing } = props;
    if (!enableEditing) {
      return null;
    }
    if (notification.deletedAt) {
      return null;
    }
    const renderPopOverContent = (
      <>
        {_renderNotificationDeleteButton(notification)}
        {_renderNotificationEditButton(notification)}
        {_renderEmailCountAndResendButton(notification)}
      </>
    );
    return (
      <Popover content={renderPopOverContent}>
        <Button
          className="offset-left-16"
          style={{ height: "auto", padding: 0 }}
          onClick={(e) => {
            if (e && e.stopPropagation) e.stopPropagation();
          }}
        >
          {IconMore}
        </Button>
      </Popover>
    );
  };

  const {
    notification,
    onTakeActionRegisterEvent,
    onPromptModal,
    onTakeActionNoticeClicked,
    unread,
  } = props;

  const titleColorAndId =
    notification && notification.meta && notification.meta.type
      ? notificationColorAndTitle[`${notification.meta.type}`]
      : notificationColorAndTitle.other;

  const NotificationActions = (
    <Row align="middle" justify="space-between">
      <Col flex={1}>
        <Row align="middle" justify="start">
          {renderTitle(
            intl.formatMessage({
              id: titleColorAndId.title_id,
            }),
          )}
          {renderSentEmailBadge(notification)}
        </Row>
      </Col>
      <Col flex="none">
        <Row align="middle" justify="end">
          {renderDateTime(notification)}
          {_renderNotificationVisibilityButton(notification)}
          {renderPopOverNotificationActions(notification)}
        </Row>
      </Col>
    </Row>
  );

  if (notification.meta?.type === "recruit_members") {
    const meta: NotificationMeta__recruit_members =
      notification.meta as NotificationMeta__recruit_members;
    return (
      <>
        {_renderEditNotificationModal(
          intl.formatMessage({
            id: "screen.label.members_wanted",
          }),
        )}
        <NotificationItemWraper
          enableEditing={props.enableEditing}
          notification={notification}
          unread={unread}
          onClick={() => {
            onPromptModal({
              name: meta.name,
              description: notification.message,
              uuid: getObjUUID(meta),
            });
          }}
        >
          {NotificationActions}
          <p className={"notification-wrap-title"}>{notification.actionText}</p>
        </NotificationItemWraper>
      </>
    );
  } else if (notification.meta?.type === "project_submission") {
    const meta: NotificationMeta__project_submission =
      notification.meta as NotificationMeta__project_submission;
    return (
      <>
        {_renderEditNotificationModal(
          intl.formatMessage({
            id: "screen.label.news_editing_project_submission",
          }),
        )}
        <NotificationItemWraper
          enableEditing={props.enableEditing}
          notification={notification}
          unread={unread}
          onClick={() => {
            props.history.push(
              `/dashboard/project.archive/${getObjUUID(meta)}/${meta.archive}`,
            );
          }}
        >
          {NotificationActions}
          <p className={"notification-wrap-title"}>{notification.actionText}</p>
        </NotificationItemWraper>
      </>
    );
  } else if (
    notification.meta?.type === "event_registration" &&
    notification.meta.action !== "deleted_event"
  ) {
    // イベント登録通知
    const meta: NotificationMeta__event_registration =
      notification.meta as NotificationMeta__event_registration;
    return (
      <>
        {_renderEditNotificationModal(
          intl.formatMessage({
            id: "screen.label.editing_events_announcements",
          }),
        )}
        <NotificationItemWraper
          enableEditing={props.enableEditing}
          notification={notification}
          unread={unread}
          onClick={() => {
            props.history.push(`/dashboard/events.detail/${getObjUUID(meta)}`);
          }}
        >
          {NotificationActions}
          <div
            className={"notification-wrap-title"}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div
              className={"notification-wrap-title"}
              style={{
                maxWidth: "15vw",
                marginTop: 0,
                marginRight: 16,
              }}
            >
              {notification.actionText}
            </div>
            {` ${
              meta.applicationDeadline
                ? `${intl.formatMessage({
                    id: "screen.label.events_application_deadline_date",
                  })}: ${moment(meta.applicationDeadline)
                    .local()
                    .format(dateTimeFormat)}`
                : ""
            }${
              meta.capacity
                ? ` / ${intl.formatMessage({
                    id: "screen.label.capacity",
                  })}: ${meta.capacity}`
                : ""
            }`}
          </div>
        </NotificationItemWraper>
      </>
    );
  } else if (notification.meta?.action === "deleted_event") {
    // イベントキャンセル通知
    const meta: NotificationMeta__deleted_event =
      notification.meta as NotificationMeta__deleted_event;
    return (
      <>
        {_renderEditNotificationModal(
          intl.formatMessage({
            id: "screen.label.editing_events_announcements",
          }),
        )}
        <NotificationItemWraper
          enableEditing={props.enableEditing}
          notification={notification}
          unread={unread}
          onClick={() => {
            onTakeActionRegisterEvent(meta);
          }}
        >
          {NotificationActions}
          <div
            className={"notification-wrap-title"}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div
              className={"notification-wrap-title"}
              style={{
                maxWidth: "15vw",
                marginTop: 0,
                marginRight: 16,
              }}
            >
              {notification.actionText}
            </div>
            {` ${
              meta.applicationDeadline
                ? `${intl.formatMessage({
                    id: "screen.label.events_application_deadline_date",
                  })}: ${moment(meta.applicationDeadline)
                    .local()
                    .format(dateTimeFormat)}`
                : ""
            }${
              meta.capacity
                ? ` / ${intl.formatMessage({
                    id: "screen.label.capacity",
                  })}: ${meta.capacity}`
                : ""
            }`}
          </div>
        </NotificationItemWraper>
      </>
    );
  } else if (notification.meta?.type === "poll") {
    return (
      <>
        {_renderEditNotificationModal(
          intl.formatMessage({
            id: "screen.label.editing_questionnaire",
          }),
        )}
        <NotificationItemWraper
          enableEditing={props.enableEditing}
          notification={notification}
          unread={unread}
          onClick={() =>
            onTakeActionNoticeClicked(
              notification,
              intl.formatMessage({
                id: "screen.label.questionnaire",
              }),
            )
          }
        >
          {NotificationActions}
          <p className={"notification-wrap-title"}>{notification.actionText}</p>
        </NotificationItemWraper>
      </>
    );
  } else if (notification.meta?.type === "important_notice") {
    return (
      <>
        {_renderEditNotificationModal(
          intl.formatMessage({
            id: "screen.label.editing_important_information",
          }),
        )}

        <NotificationItemWraper
          enableEditing={props.enableEditing}
          notification={notification}
          unread={unread}
          onClick={() =>
            onTakeActionNoticeClicked(
              notification,
              intl.formatMessage({
                id: "screen.label.notification",
              }),
            )
          }
        >
          {NotificationActions}
          <p className={"notification-wrap-title"}>{notification.actionText}</p>
        </NotificationItemWraper>
      </>
    );
  } else if (notification.meta?.type === "general_notice") {
    return (
      <>
        {_renderEditNotificationModal(
          intl.formatMessage({
            id: "screen.label.edit_announcement",
          }),
        )}

        <NotificationItemWraper
          enableEditing={props.enableEditing}
          notification={notification}
          unread={unread}
          onClick={() =>
            onTakeActionNoticeClicked(
              notification,
              intl.formatMessage({
                id: "screen.label.notice",
              }),
            )
          }
        >
          {NotificationActions}
          <p className={"notification-wrap-title"}>{notification.actionText}</p>
        </NotificationItemWraper>
      </>
    );
  } else if (notification.meta?.type === "project_creation") {
    const meta: NotificationMeta__project_creation =
      notification.meta as NotificationMeta__project_creation;
    return (
      <>
        {_renderEditNotificationModal(
          intl.formatMessage({
            id: "screen.label.news_editing_project_creation",
          }),
        )}

        <NotificationItemWraper
          enableEditing={props.enableEditing}
          notification={notification}
          unread={unread}
          onClick={() => {
            props.history.push(`/dashboard/project.top/${getObjUUID(meta)}`);
          }}
        >
          {NotificationActions}
          <p className={"notification-wrap-title"}>{notification.actionText}</p>
        </NotificationItemWraper>
      </>
    );
  } else if (notification.meta?.type === "project_submission_review") {
    const meta: NotificationMeta__project_submission_review =
      notification.meta as NotificationMeta__project_submission_review;
    return (
      <>
        {_renderEditNotificationModal(
          intl.formatMessage({
            id: "screen.label.project_reviews_announcements_editing",
          }),
        )}

        <NotificationItemWraper
          enableEditing={props.enableEditing}
          notification={notification}
          unread={unread}
          onClick={() => {
            const path = `/dashboard/project.reviews/${getObjUUID(meta)}`;
            const queryParam = meta.submittedProjectId
              ? `?submittedProjectId=${meta.submittedProjectId}`
              : "";
            props.history.push(path + queryParam);
          }}
        >
          {NotificationActions}
          <p className={"notification-wrap-title"}>{notification.actionText}</p>
        </NotificationItemWraper>
      </>
    );
  } else if (notification.meta?.type === "project_invitation_rejection") {
    const meta: NotificationMeta__project_invitation_rejection =
      notification.meta as NotificationMeta__project_invitation_rejection;
    return (
      <>
        {_renderEditNotificationModal("Edit Project Rejection Notification")}
        <NotificationItemWraper
          enableEditing={props.enableEditing}
          notification={notification}
          unread={unread}
          onClick={() => {
            props.history.push(`/dashboard/project.top/${getObjUUID(meta)}`);
          }}
        >
          {NotificationActions}
          <p className={"notification-wrap-title"}>{notification.actionText}</p>
        </NotificationItemWraper>
      </>
    );
  } else if (notification.meta?.type === "project_invitation_acceptance") {
    const meta: NotificationMeta__project_invitation_acceptance =
      notification.meta as NotificationMeta__project_invitation_acceptance;
    return (
      <>
        {_renderEditNotificationModal("Edit Project Acceptance Notification")}

        <NotificationItemWraper
          enableEditing={props.enableEditing}
          notification={notification}
          unread={unread}
          onClick={() => {
            props.history.push(`/dashboard/project.top/${getObjUUID(meta)}`);
          }}
        >
          {NotificationActions}
          <p className={"notification-wrap-title"}>{notification.actionText}</p>
        </NotificationItemWraper>
      </>
    );
  } else if (notification.meta?.type === "project_invitation_request") {
    const meta: NotificationMeta__project_invitation_request =
      notification.meta as NotificationMeta__project_invitation_request;
    return (
      <>
        {_renderEditNotificationModal(
          intl.formatMessage({
            id: "screen.label.edit_announcement",
          }),
        )}
        <NotificationItemWraper
          enableEditing={props.enableEditing}
          notification={notification}
          unread={unread}
          onClick={() => {
            props.history.push(`/dashboard/project.top/${getObjUUID(meta)}`);
          }}
        >
          {NotificationActions}
          <p className={"notification-wrap-title"}>{notification.actionText}</p>
        </NotificationItemWraper>
      </>
    );
  } else if (
    notification.meta?.type === "project_invitation_request_from_myself"
  ) {
    const meta: NotificationMeta__project_invitation_request_from_myself =
      notification.meta as NotificationMeta__project_invitation_request_from_myself;
    return (
      <>
        {_renderEditNotificationModal(
          intl.formatMessage({
            id: "screen.label.edit_announcement",
          }),
        )}
        <NotificationItemWraper
          enableEditing={props.enableEditing}
          notification={notification}
          unread={unread}
          onClick={() => {
            props.history.push(`/dashboard/project.team/${getObjUUID(meta)}`);
          }}
        >
          {NotificationActions}
          <p className={"notification-wrap-title"}>{notification.actionText}</p>
        </NotificationItemWraper>
      </>
    );
  } else if (notification.meta?.type === "user_mentioned") {
    const meta: NotificationMeta__user_mentioned =
      notification.meta as NotificationMeta__user_mentioned;
    return (
      <>
        {_renderEditNotificationModal(
          intl.formatMessage({
            id: "screen.label.edit_announcement",
          }),
        )}
        <NotificationItemWraper
          enableEditing={props.enableEditing}
          notification={notification}
          unread={unread}
          onClick={() => {
            props.history.push({
              pathname: `/dashboard/project.top/${getObjUUID(meta)}`,
              state: { fromMentioned: true },
            });
          }}
        >
          {NotificationActions}

          <p className={"notification-wrap-title"}>{notification.actionText}</p>
        </NotificationItemWraper>
      </>
    );
  } else if (notification.meta?.type === "projection_rejection_by_owner") {
    const meta: NotificationMeta__projection_rejection_by_owner =
      notification.meta as NotificationMeta__projection_rejection_by_owner;
    return (
      <>
        {_renderEditNotificationModal("Edit Project Rejection Notification")}
        <NotificationItemWraper
          enableEditing={props.enableEditing}
          notification={notification}
          unread={unread}
          onClick={() => {
            props.history.push(`/dashboard/project.top/${getObjUUID(meta)}`);
          }}
        >
          {NotificationActions}
          <p className={"notification-wrap-title"}>{notification.actionText}</p>
        </NotificationItemWraper>
      </>
    );
  } else if (notification.meta?.type === "projection_rejection_by_invitee") {
    const meta: NotificationMeta__projection_rejection_by_invitee =
      notification.meta as NotificationMeta__projection_rejection_by_invitee;
    return (
      <>
        {_renderEditNotificationModal("Edit Project Rejection Notification")}
        <NotificationItemWraper
          enableEditing={props.enableEditing}
          notification={notification}
          unread={unread}
          onClick={() => {
            props.history.push(`/dashboard/project.top/${getObjUUID(meta)}`);
          }}
        >
          {NotificationActions}
          <p className={"notification-wrap-title"}>{notification.actionText}</p>
        </NotificationItemWraper>
      </>
    );
  } else if (notification.meta?.type === "project_watch") {
    const meta: NotificationMeta__project_watch =
      notification.meta as NotificationMeta__project_watch;
    return (
      <NotificationItemWraper
        enableEditing={props.enableEditing}
        notification={notification}
        unread={unread}
        onClick={() => {
          props.history.push(`/dashboard/project.top/${getObjUUID(meta)}`);
        }}
      >
        {NotificationActions}
        <p className={"notification-wrap-title"}>{notification.actionText}</p>
      </NotificationItemWraper>
    );
  } else if (notification.meta?.type === "news_posted") {
    const meta: NotificationMeta__news_posted =
      notification.meta as NotificationMeta__news_posted;
    return (
      <NotificationItemWraper
        enableEditing={props.enableEditing}
        notification={notification}
        unread={unread}
        onClick={() => {
          props.history.push(
            `/dashboard/library/news/${getObjUUID(meta.news)}`,
          );
        }}
      >
        {NotificationActions}
        <p className={"notification-wrap-title"}>{notification.actionText}</p>
      </NotificationItemWraper>
    );
  } else if (notification.meta?.type === "industry_theme_report") {
    const meta: NotificationMeta__industry_theme_report =
      notification.meta as NotificationMeta__industry_theme_report;
    return (
      <NotificationItemWraper
        enableEditing={props.enableEditing}
        notification={notification}
        unread={unread}
        onClick={() => {
          props.history.push(
            `/dashboard/library/industry-report/${getObjUUID(
              meta.industryReport,
            )}`,
          );
        }}
      >
        {NotificationActions}
        <p className={"notification-wrap-title"}>{notification.actionText}</p>
      </NotificationItemWraper>
    );
  } else {
    return (
      <>
        {_renderEditNotificationModal(
          intl.formatMessage({
            id: "screen.label.edit_announcement",
          }),
        )}
        <NotificationItemWraper
          enableEditing={props.enableEditing}
          notification={notification}
          unread={unread}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onClick={() => {}}
        >
          {NotificationActions}
          <p className={"notification-wrap-title"}>{notification.actionText}</p>
        </NotificationItemWraper>
      </>
    );
  }
};
export default injectIntl(withRouter(NotificationComponent));
