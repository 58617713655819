import React from "react";

import { SingleVideoLargeCategory } from "../../molecules/videos";
type VideoTopCategorySectionProps = {
  largeCategories: Array<any>;
  largeCategoriesVideos: any;
};

export const VideoTopCategorySection = (
  props: VideoTopCategorySectionProps,
) => {
  const { largeCategories, largeCategoriesVideos } = props;

  const _getVideoArraySingleLargeCategory = (singleLargeCategoryId: number) => {
    const videos = largeCategoriesVideos[singleLargeCategoryId];
    if (videos && Array.isArray(videos)) {
      return videos;
    }
    return [];
  };

  if (Array.isArray(largeCategories)) {
    const rcNode = largeCategories.map((singleLargeCategory) => {
      return (
        <SingleVideoLargeCategory
          key={singleLargeCategory.id}
          largeCategory={singleLargeCategory}
          rows={_getVideoArraySingleLargeCategory(singleLargeCategory.id)}
        />
      );
    });
    return <>{rcNode}</>;
  }
  return <React.Fragment></React.Fragment>;
};
