import { Action } from "@reduxjs/toolkit";
import { createActions, createReducer } from "reduxsauce";
import Immutable from "seamless-immutable";

import { ToAction, ToActionType } from "./types";

/* ------------- Types and Action Creators ------------- */
type NewsActionCreators = {
  newsUpdateRequest: () => Action<"NEWS_UPDATE_REQUEST">;
  newsUpdateSuccess: () => Action<"NEWS_UPDATE_SUCCESS">;
  newsUpdateFailure: () => Action<"NEWS_UPDATE_FAILURE">;

  newsDeleteRequest: () => Action<"NEWS_DELETE_REQUEST">;
  newsDeleteSuccess: () => Action<"NEWS_DELETE_SUCCESS">;
  newsDeleteFailure: () => Action<"NEWS_DELETE_FAILURE">;

  newsCreateRequest: () => Action<"NEWS_CREATE_REQUEST">;
  newsCreateSuccess: () => Action<"NEWS_CREATE_SUCCESS">;
  newsCreateFailure: () => Action<"NEWS_CREATE_FAILURE">;

  newsGetRequest: () => Action<"NEWS_GET_REQUEST">;
  newsGetSuccess: () => Action<"NEWS_GET_SUCCESS">;
  newsGetFailure: () => Action<"NEWS_GET_FAILURE">;
  /// update nav header in the case of news editor open
};
type NewsAction = ToAction<NewsActionCreators>;
type NewsActionType = ToActionType<NewsAction>;

const { Types, Creators } = createActions<NewsActionType, NewsActionCreators>({
  newsUpdateRequest: ["data"],
  newsUpdateSuccess: null,
  newsUpdateFailure: ["errors"],

  newsDeleteRequest: ["id"],
  newsDeleteSuccess: null,
  newsDeleteFailure: ["errors"],

  newsCreateRequest: ["data", "message"],
  newsCreateSuccess: null,
  newsCreateFailure: ["errors"],

  newsGetRequest: ["data"],
  newsGetSuccess: ["newsPayload"],
  newsGetFailure: ["errors"],
  /// update nav header in the case of news editor open
});

export const NewsTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
type NewsState = {
  fetching: boolean;
  errors: unknown;
  newsPayload: {
    rows: unknown[];
    count: number;
  };
};

export const INITIAL_STATE = Immutable<NewsState>({
  fetching: false,
  errors: {},
  newsPayload: {
    rows: [],
    count: 0,
  },
});

type ImmNewsState = typeof INITIAL_STATE;

// @ts-expect-error TS7006
export const newsStore = (state) => state.news;

/* ------------- Reducers ------------- */

const newsCreateRequest = (state: ImmNewsState) =>
  state.merge({ fetching: true });

const newsCreateSuccess = (state: ImmNewsState) => {
  return state.merge({ fetching: false });
};

// @ts-expect-error TS7031
const newsCreateFailure = (state: ImmNewsState, { errors }) =>
  state.merge({ fetching: false, errors });

const newsDeleteRequest = (state: ImmNewsState) =>
  state.merge({ fetching: true });

const newsDeleteSuccess = (state: ImmNewsState) => {
  return state.merge({ fetching: false });
};

// @ts-expect-error TS7031
const newsDeleteFailure = (state: ImmNewsState, { errors }) =>
  state.merge({ fetching: false, errors });

const newsUpdateRequest = (state: ImmNewsState) =>
  state.merge({ fetching: true });

const newsUpdateSuccess = (state: ImmNewsState) => {
  return state.merge({ fetching: false });
};

// @ts-expect-error TS7031
const newsUpdateFailure = (state: ImmNewsState, { errors }) =>
  state.merge({ fetching: false, errors });

const newsGetRequest = (state: ImmNewsState) => state.merge({ fetching: true });

// @ts-expect-error TS7031
const newsGetSuccess = (state: ImmNewsState, { newsPayload }) => {
  return state.merge({ fetching: false, newsPayload });
};

// @ts-expect-error TS7031
const newsGetFailure = (state: ImmNewsState, { errors }) =>
  state.merge({ fetching: false, errors });
/* ------------- Hookup Reducers To Types ------------- */

// @ts-expect-error TS2345
export const reducer = createReducer<NewsState, NewsAction>(INITIAL_STATE, {
  [Types.NEWS_CREATE_REQUEST]: newsCreateRequest,
  [Types.NEWS_CREATE_SUCCESS]: newsCreateSuccess,
  [Types.NEWS_CREATE_FAILURE]: newsCreateFailure,

  [Types.NEWS_DELETE_REQUEST]: newsDeleteRequest,
  [Types.NEWS_DELETE_SUCCESS]: newsDeleteSuccess,
  [Types.NEWS_DELETE_FAILURE]: newsDeleteFailure,

  [Types.NEWS_GET_REQUEST]: newsGetRequest,
  [Types.NEWS_GET_SUCCESS]: newsGetSuccess,
  [Types.NEWS_GET_FAILURE]: newsGetFailure,

  [Types.NEWS_UPDATE_REQUEST]: newsUpdateRequest,
  [Types.NEWS_UPDATE_SUCCESS]: newsUpdateSuccess,
  [Types.NEWS_UPDATE_FAILURE]: newsUpdateFailure,
});
