import DOMPurify from "dompurify";
import { EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";

import { Comment } from "@/../types/Comment";
import { Thread } from "@/../types/Thread";
import { User } from "@/../types/User";
import ThreadActions from "@/Redux/ThreadRedux";
import {
  convertFromRawWithTrimmer,
  convertToRawWithTrimmer,
  sanitizeEditorStateMarkupHtml,
} from "@/utils/common";
import { addMention } from "@/utils/editorUtils";
import { getMentionsFromEditor } from "@/utils/projectUtils";

const getDefaultEditor = (comment: Comment, user: User) => {
  if (comment.User.uuid === user.uuid) {
    return EditorState.createEmpty();
  }
  return addMention(EditorState.createEmpty(), " ", "@", {
    value:
      comment.User.name.trim().length > 0
        ? comment.User.name
        : comment.User.email,
    url: comment.User.uuid,
  });
};

type EditMode = "new_reply" | "update_reply";

export type useReplyThreadsProps = {
  projectId?: string;
  comment: Comment;
  user: User;
  showAllChildren: boolean;
};

export const useReplyThreads = ({
  projectId,
  comment,
  user,
  showAllChildren,
}: useReplyThreadsProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [replyActive, setReplyActive] = useState(false);
  const [showChildren, setShowChildren] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [replyEditorState, setReplyEditorState] = useState<any>(
    getDefaultEditor(comment, user),
  );
  const [editMode, setEditMode] = useState<EditMode>("new_reply");
  const [replyId, setReplyId] = useState<number>();

  const threads = useMemo(() => {
    // comment が seamless-immutable によって immutable になっているため、
    // spread 構文で展開して別配列にする。
    const t = comment.children || comment.Thread || [];
    return [...t];
  }, [comment.children, comment.Thread]);

  const onFocusReplyEditor = useCallback(() => {
    setReplyActive((prev) => !prev);
  }, []);

  const onCancelReply = useCallback(() => {
    setReplyActive(false);
    setReplyEditorState(getDefaultEditor(comment, user));
    setEditMode("new_reply");
  }, [comment, user]);

  const onPostReply = useCallback(() => {
    const editorState = replyEditorState || EditorState.createEmpty();
    if (!editorState.getCurrentContent().hasText()) {
      return;
    }
    const mentions = getMentionsFromEditor(editorState);
    const rawNodes = convertToRawWithTrimmer(editorState, false);
    // @ts-expect-error TS2345
    const markup = draftToHtml(rawNodes);
    const html = DOMPurify.sanitize(markup, {
      FORBID_TAGS: ["a"],
      ADD_TAGS: ["iframe"],
      ADD_ATTR: [
        "allow",
        "allowfullscreen",
        "frameborder",
        "scrolling",
        "target",
      ],
      FORBID_ATTR: ["style"],
    });
    const content = JSON.stringify(rawNodes);
    const message = intl.formatMessage({
      id: "screen.label.comment_has_been_completed",
    });

    switch (editMode) {
      case "new_reply": {
        dispatch(
          ThreadActions.threadCreateRequest(
            {
              projectId: projectId,
              content: content,
              commentId: comment.id,
              nodalData: true,
              mentions,
              htmlContent: html,
            },
            message,
          ),
        );
        break;
      }
      case "update_reply": {
        dispatch(
          ThreadActions.threadUpdateRequest(
            {
              projectId: projectId,
              content: content,
              id: replyId,
              nodalData: true,
              mentions,
              htmlContent: html,
            },
            message,
          ),
        );
        break;
      }
    }

    onCancelReply();
  }, [
    replyEditorState,
    intl,
    editMode,
    onCancelReply,
    dispatch,
    projectId,
    comment.id,
    replyId,
  ]);

  const onOpenThreads = useCallback(() => {
    setShowChildren((prev) => !prev);
    setEditMode("new_reply");
  }, []);

  const onEditThread = useCallback((thread: Thread) => {
    const editNode = sanitizeEditorStateMarkupHtml(
      EditorState.createWithContent(convertFromRawWithTrimmer(thread.content)),
    );
    setReplyActive(true);
    setReplyEditorState(editNode);
    setEditMode("update_reply");
    setReplyId(thread.id);
  }, []);

  const onDeleteThread = useCallback(
    (thread: Thread) => {
      const message = intl.formatMessage?.({
        id: "screen.label.comment_has_been_deleted",
      });
      dispatch(
        ThreadActions.threadDeleteRequest(
          {
            thread: thread.id,
            projectId: projectId,
          },
          message,
        ),
      );
    },
    [dispatch, intl, projectId],
  );

  useEffect(() => {
    setShowChildren(showAllChildren);
  }, [showAllChildren]);

  return {
    threads,
    replyActive,
    showChildren,
    replyEditorState,
    onReplyEditorStateChange: setReplyEditorState,
    onFocusReplyEditor,
    onCancelReply,
    onPostReply,
    onOpenThreads,
    onEditThread,
    onDeleteThread,
  };
};
