import { AnyAction } from "@reduxjs/toolkit";
import { Col, Form, Layout, Row } from "antd";
import PropTypes from "prop-types";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { ButtonSubmit } from "../../Components/atoms";
import {
  AlphaDriveImg,
  AlphaDriveImgContainer,
  AuthLayout,
  Banner,
  HorizontalLine,
  InputForm,
  LinkContainer,
  LoginBanner,
  LoginLabel,
  LogoTitleLayout,
  VerifyHeaderBold,
} from "../../Components/molecules";
import AuthActions from "../../Redux/AuthRedux";
import useForgotPasswordScreen from "./hooks/useForgotPasswordScreen";
import { IForgotPasswordScreenProps } from "./types/IForgotPasswordScreenProps";

const { Content } = Layout;

const ForgotPasswordScreen: FC<IForgotPasswordScreenProps> = function (props) {
  const { state, onChange, onSubmit } = useForgotPasswordScreen(props);

  return (
    <AuthLayout className="layout">
      <Content className="authContent">
        <Row>
          <Banner>
            <LogoTitleLayout>
              <LoginBanner alt={"Banner"} src={"/login_banner.png"} />
              <LoginLabel>
                <FormattedMessage id="screen.label.comprehensive_support_tool_des" />
                <br />
                <FormattedMessage id="screen.label.incubation_suite" />
              </LoginLabel>
            </LogoTitleLayout>
            <AlphaDriveImgContainer>
              <div>
                <span>Powered by &nbsp;</span>
                <AlphaDriveImg alt={"alpha drive"} src={"/alpha_drive.png"} />
              </div>
            </AlphaDriveImgContainer>
          </Banner>
          <Col>
            <div className="contentRoot">
              <div>
                <VerifyHeaderBold>
                  <FormattedMessage id="screen.label.did_you_forget_your_password" />
                </VerifyHeaderBold>
                <Form layout={"vertical"} onFinish={onSubmit}>
                  <Form.Item style={{ marginBottom: 0 }}>
                    <h4 style={{ marginTop: "20px" }}>
                      <FormattedMessage id="screens.title.email" />{" "}
                    </h4>
                    <InputForm
                      required
                      onChange={onChange}
                      value={state.email}
                      // @ts-expect-error TS2769
                      error={"errors.email"}
                      id="email"
                      type="email"
                    />
                  </Form.Item>
                  <ButtonSubmit htmlType="submit">
                    <FormattedMessage id="screen.label.password_reset" />
                  </ButtonSubmit>
                  <HorizontalLine />
                  <LinkContainer>
                    <Link to="/" style={{ color: "#1F86E9" }}>
                      <FormattedMessage id="screen.label.click_here_to_login" />
                    </Link>
                  </LinkContainer>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </Content>
    </AuthLayout>
  );
};
ForgotPasswordScreen.propTypes = {
  // @ts-expect-error TS2322
  dispatchForgotPasswordRequest: PropTypes.func,
};
const mapStateToProps = (state: { auth: any; errors: any }) => ({
  auth: state.auth,
  errors: state.errors,
});
const mapDispatchToProps = (dispatch: (arg0: AnyAction) => any) => {
  return {
    dispatchForgotPasswordRequest: (data: any, message: any) =>
      // @ts-expect-error TS2554
      dispatch(AuthActions.forgotPasswordRequest(data, message)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ForgotPasswordScreen);
