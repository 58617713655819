import { Card, Col, Form, Modal, Row } from "antd";
import PropTypes from "prop-types";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";

import { ReduxDispatch, ReduxState } from "@/Redux/types";

import { ButtonCV } from "../../Components/atoms";
import { Input, Option, Select, TextArea } from "../../Components/molecules";
import { GlobalManageCategoriesComponent } from "../../Components/organisms";
import ApiActions, {
  ApiRequestData,
  ApiSuccessCallback,
} from "../../Redux/ApiRedux";
import HomeActions from "../../Redux/HomeRedux";
import VideoActions from "../../Redux/VideoRedux";
import FileUploadComponent from "../project/FileUploadComponent";
import useEditVideoScreen from "./hooks/useEditVideoScreen";
import { IEditVideoScreenProps } from "./types/IEditVideoScreenProps";

const EditVideoScreen: FC<IEditVideoScreenProps> = function (props) {
  const {
    intl,
    state,
    setState,
    onUpdateNewVideo,
    onDeleteSmallCategory,
    onSubmitNewCategory,
    onChangeNewVideoData,
    getLargeCategoryIdForSelect,
  } = useEditVideoScreen(props);

  // @ts-expect-error TS7006
  const _renderSmallCategories = (largeCategoryId) => {
    const data = state.smallCategories[largeCategoryId];
    if (data && Array.isArray(data)) {
      const defVal = (
        <Option value={0}>
          <FormattedMessage id="screen.label.choose_one_option" />
        </Option>
      );
      const options = data.map((sm) => {
        return (
          <Option key={sm.id} value={sm.id}>
            {sm.name}
          </Option>
        );
      });
      return [defVal, ...options];
    }
  }; //
  return (
    <>
      <Modal
        title={intl.formatMessage({
          id: "screen.label.category_management",
        })}
        visible={state.isModalManageCategoriesVisible}
        footer={null}
        onCancel={() => {
          setState({
            // @ts-expect-error TS2345
            isModalManageCategoriesVisible: false,
          });
        }}
      >
        <GlobalManageCategoriesComponent
          onDeleteSmallCategory={onDeleteSmallCategory}
          onSubmitNewCategory={onSubmitNewCategory}
          // @ts-expect-error TS2322
          largeCategories={props.largeCategories}
          smallCategories={state.smallCategories}
          user={props.user}
        />
      </Modal>
      <Card
        title={intl.formatMessage({
          id: "screen.label.edit_video",
        })}
        bodyStyle={{
          paddingLeft: "7vw",
          paddingRight: "7vw",
        }}
      >
        {state.editVideo.videoLargeCategoryId > 0 ? (
          <Form layout={"vertical"}>
            <Row gutter={[32, 0]}>
              <Col xs={24} sm={12} md={8} lg={8}>
                <Form.Item
                  label={`${intl.formatMessage({
                    id: "screen.label.large_category",
                  })} *`}
                >
                  <Select
                    defaultValue={state.editVideo.videoLargeCategoryId}
                    onChange={(videoLargeCategoryId) => {
                      onChangeNewVideoData(
                        "videoLargeCategoryId",
                        videoLargeCategoryId,
                      );
                      onChangeNewVideoData("videoSmallCategoryId", 0);
                      setState({
                        videoLargeCategoryId,
                      });
                    }}
                  >
                    {/*
                     // @ts-expect-error TS18048 */}
                    {props.largeCategories.map((l) => {
                      return (
                        <Option
                          disabled={getLargeCategoryIdForSelect(l)}
                          key={l.id}
                          value={l.id}
                        >
                          {l.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8} lg={8}>
                <Form.Item
                  label={`${intl.formatMessage({
                    id: "screen.label.small_category",
                  })} *`}
                >
                  <Select
                    value={state.editVideo.videoSmallCategoryId}
                    defaultValue={state.editVideo.videoSmallCategoryId}
                    onChange={(videoSmallCategoryId) => {
                      onChangeNewVideoData(
                        "videoSmallCategoryId",
                        videoSmallCategoryId,
                      );
                    }}
                  >
                    {_renderSmallCategories(state.videoLargeCategoryId)}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8} lg={8}>
                <ButtonCV
                  style={{ marginTop: "30px" }}
                  onClick={() => {
                    setState({
                      // @ts-expect-error TS2345
                      isModalManageCategoriesVisible: true,
                    });
                  }}
                >
                  <FormattedMessage id="screen.label.to_add_delete_category" />
                </ButtonCV>
              </Col>
              <Col span={24}>
                <Form.Item
                  label={`${intl.formatMessage({
                    id: "screen.label.title",
                  })} *`}
                >
                  <Input
                    value={state.editVideo.name}
                    onChange={(e) => {
                      onChangeNewVideoData("name", e.target.value);
                    }}
                    placeholder={intl.formatMessage({
                      id: "screen.label.please_enter",
                    })}
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  label={`${intl.formatMessage({
                    id: "screen.label.more_detail",
                  })} *`}
                >
                  <TextArea
                    value={state.editVideo.description}
                    onChange={(e) => {
                      onChangeNewVideoData("description", e.target.value);
                    }}
                    rows={3}
                    placeholder={intl.formatMessage({
                      id: "screen.label.please_enter",
                    })}
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item label={"Vimeo URL *"}>
                  <Input
                    value={state.editVideo.videoUrl}
                    onChange={(e) => {
                      onChangeNewVideoData("videoUrl", e.target.value);
                    }}
                    placeholder="Vimeo URL"
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={12} md={8} lg={8}>
                <Form.Item
                  label={`${intl.formatMessage({
                    id: "screen.label.thumbnail_attached",
                  })} *`}
                >
                  <FileUploadComponent
                    imageSrc={state.editVideo.imageThumbnail}
                    message={`＋ ${intl.formatMessage({
                      id: "screen.label.click",
                    })}`}
                    onFileChanged={(imageThumbnail) => {
                      onChangeNewVideoData("imageThumbnail", imageThumbnail);
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        ) : null}
      </Card>
      <Row>
        <Col span={24}>
          <ButtonCV onClick={onUpdateNewVideo} type="primary">
            <FormattedMessage id="screens.title.post" />
          </ButtonCV>
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  company: state.company.companyPayload,
  //@ts-expect-error 要API型定義
  largeCategories: state.company.companies.videosLargeCategory,
  user: state.auth.payload,
  auth: state.auth,
  companies: state.company.companies.rows,
});
const mapDispatchToProps = (dispatch: ReduxDispatch) => ({
  // @ts-expect-error TS7006
  dispatchSetPageTitle: (pageTitle) =>
    // @ts-expect-error TS2554
    dispatch(HomeActions.pageTitle(pageTitle)),
  dispatchApiRequest: (data: ApiRequestData, callback: ApiSuccessCallback) =>
    dispatch(ApiActions.apiRequest(data, callback)),
  // @ts-expect-error TS7006
  dispatchSetVideoCategories: (largeCategories) =>
    // @ts-expect-error TS2554
    dispatch(VideoActions.videoLargeCategoriesGetSuccess(largeCategories)),
});
EditVideoScreen.propTypes = {
  dispatchSetPageTitle: PropTypes.func,
  dispatchApiRequest: PropTypes.func,
  dispatchSetVideoCategories: PropTypes.func,

  // @ts-expect-error TS2322
  company: PropTypes.object,
  largeCategories: PropTypes.array,
  user: PropTypes.object,
  auth: PropTypes.object,
  match: PropTypes.object,
};
//@ts-expect-error 要API型定義
export default connect(mapStateToProps, mapDispatchToProps)(EditVideoScreen);
