import { notification } from "antd";
import { useIntl } from "react-intl";

export const useExaminationPhaseValidation = () => {
  const intl = useIntl();

  const validateExaminationPhaseName = (name: string) => {
    const isValid = name.trim().length !== 0;
    if (!isValid) {
      notification.error({
        message: intl.formatMessage({
          id: "screen.label.examination_phase_name_is_required",
        }),
      });
    }

    return isValid;
  };

  return {
    validateExaminationPhaseName,
  };
};
