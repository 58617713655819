import "./styles.css";

import classNames from "classnames";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

class Mention {
  constructor(className) {
    this.className = className;
  }
  getMentionComponent = () => {
    const className = this.className;
    const MentionComponent = ({ entityKey, children, contentState }) => {
      if (
        contentState &&
        contentState.getEntity(entityKey) &&
        contentState.getEntity(entityKey).getData()
      ) {
        const { url, value } = contentState.getEntity(entityKey).getData();
        var member_link = undefined;
        if (url && url !== "") {
          const member_id = `${url}`.split("/").pop();
          member_link = `/dashboard/member/${member_id}`;
        }
        return (
          <Link
            to={member_link || value}
            className={classNames("rdw-mention-link", className)}
          >
            {children}
          </Link>
        );
      } else {
        return (
          <a href={"#"} className={classNames("rdw-mention-link", className)}>
            {children}
          </a>
        );
      }
    };
    MentionComponent.propTypes = {
      entityKey: PropTypes.number,
      children: PropTypes.array,
      contentState: PropTypes.object,
    };
    return MentionComponent;
  };
  getMentionDecorator = () => ({
    strategy: this.findMentionEntities,
    component: this.getMentionComponent(),
  });
}

Mention.prototype.findMentionEntities = (
  contentBlock,
  callback,
  contentState,
) => {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === "MENTION"
    );
  }, callback);
};

export default Mention;
