import { Modal, Row } from "antd";
import { FC } from "react";
import { useIntl } from "react-intl";

import {
  InsuAcceptButton,
  InsuRejectButton,
} from "@/Components/atoms/buttons/shared";
import { useProjectWatchContext } from "@/features/project/contexts/ProjectWatchContext";

export const ProjectWatchModal: FC = () => {
  const intl = useIntl();

  const {
    showWatchModal,
    setShowWatchModal,
    createProjectWatch,
    disableWatch,
  } = useProjectWatchContext();

  if (disableWatch) return null;

  return (
    <Modal
      title={intl.formatMessage({
        id: "screen.label.project_watch_active",
      })}
      visible={showWatchModal}
      okText={intl.formatMessage({
        id: "screen.label.project_watch",
      })}
      cancelText={intl.formatMessage({
        id: "screen.label.not_watch",
      })}
      onCancel={() => setShowWatchModal(false)}
      footer={null}
    >
      <p>
        {intl.formatMessage({
          id: "screen.label.project_watch_des",
        })}
      </p>
      <Row justify="center">
        <InsuRejectButton onClick={() => setShowWatchModal(false)}>
          {intl.formatMessage({
            id: "screen.label.not_watch",
          })}
        </InsuRejectButton>
        <InsuAcceptButton
          onClick={() => {
            createProjectWatch();
            setShowWatchModal(false);
          }}
        >
          {intl.formatMessage({
            id: "screen.label.project_watch",
          })}
        </InsuAcceptButton>
      </Row>
    </Modal>
  );
};
