import { useContext } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import {
  InsuPrimaryButton,
  InsuRejectButton,
} from "@/Components/atoms/buttons/shared";
import { ContentContext } from "@/Provider";
import { ApiRequestData } from "@/Redux/ApiRedux";
import ProjectActions from "@/Redux/ProjectRedux";
import { ReduxState } from "@/Redux/types";
import { endpoints, parseEndpoint } from "@/Services/endpoints";
import { recruiting } from "@/utils/constants";

import { useUserTeamStatus } from "../../hooks/useUserTeamStatus";

export const ProjectApplyToBeMemberButton = () => {
  const intl = useIntl();
  const { user, apiRequest } = useContext(ContentContext);
  const dispatch = useDispatch();
  const params = useParams<{ id: string }>();
  const projectUuid = params.id;

  const project = useSelector(
    (state: ReduxState) => state.projects.projectPayload,
  );

  const {
    isWaitingCurrentUserApproval,
    isWaitingProjectOwnerApproval,
    isTeamMember,
  } = useUserTeamStatus({
    user,
    team: [...project.Team],
    project,
  });

  const isAccepting = project.type === recruiting;

  const applyToBeMember = () => {
    const data: ApiRequestData = {
      method: "postRequest",
      url: parseEndpoint(endpoints.applyToBeMemberTeam, {
        projectUUid: projectUuid,
      }),
      successMessage: intl.formatMessage({
        id: "screen.label.application_has_been_completed",
      }),
      data: {
        userId: user.id,
        teamRoleId: 1,
        userStatus: 1,
      },
    };
    apiRequest(data, () => {
      dispatch(
        ProjectActions.projectGetRequest(
          // @ts-expect-error TS2554
          projectUuid,
        ),
      );
    });
  };

  if (isAccepting && !isWaitingCurrentUserApproval) {
    if (isWaitingProjectOwnerApproval) {
      return (
        <InsuRejectButton>
          {intl.formatMessage({
            id: "screen.label.applying",
          })}
        </InsuRejectButton>
      );
    } else if (!isTeamMember) {
      return (
        <InsuPrimaryButton onClick={() => applyToBeMember()} style={{}}>
          {intl.formatMessage({
            id: "screen.label.to_apply_to_become_project_member",
          })}
        </InsuPrimaryButton>
      );
    }
  }
  return null;
};
