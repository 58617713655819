import { useIntl } from "react-intl";

import styles from "./styles.module.scss";

export const NoDataLabel = () => {
  const intl = useIntl();
  const noDataLabel = intl.formatMessage({
    id: "screen.label.no_applicable_data",
  });
  return <span className={styles.label}>{noDataLabel}</span>;
};
