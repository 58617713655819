import { ApiResponse } from "apisauce";
import { useCallback, useState } from "react";
import { useIntl } from "react-intl";

import { mimeType } from "@/const/mimeType";
import { useBool } from "@/hooks/useBool";
import { NullResponse } from "@/Services/commonResponsTypes";

import { importExcelRequest } from "./importExcelRequest";

export const useUploadReviewExcel = () => {
  const [errorExcelBlob, setErrorExcelBlob] = useState<Blob | null>(null);
  const {
    bool: hasRowError,
    changeToFalse: initRowError,
    changeToTrue: setRowErrorTrue,
  } = useBool();

  const intl = useIntl();
  const rowErrorMessage = intl.formatMessage({
    id: "screen.label.excel_file_error_message",
  });

  const processUploadedExcel = useCallback(
    (
      response: ApiResponse<ArrayBuffer>,
      successCallback: () => void,
      errorCallback: (message: string) => void,
    ) => {
      if (!response.data) return;
      if (response.headers?.["content-type"] === mimeType.xlsx) {
        const blob = new Blob([response.data], {
          type: mimeType.xlsx,
        });
        setErrorExcelBlob(blob);
        setRowErrorTrue();
        errorCallback(rowErrorMessage);
        return;
      }
      const json: NullResponse = JSON.parse(
        Buffer.from(response.data).toString(),
      );
      if (json.status === "success") {
        successCallback();
        return;
      }
      errorCallback(json.message);
    },
    [rowErrorMessage, setRowErrorTrue],
  );

  const handleUpload = useCallback(
    async (
      file: File | null,
      successCallback: () => void,
      errorCallback: (message: string) => void,
    ) => {
      if (!file) return;
      const response = await importExcelRequest(file);
      processUploadedExcel(response, successCallback, errorCallback);
    },
    [processUploadedExcel],
  );

  return {
    handleUpload,
    initRowError,
    errorExcelBlob,
    hasRowError,
  };
};
