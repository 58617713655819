import { Col, Row } from "antd";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { PublishedReviewResultDetail } from "@/../types/PublishedReviewResultDetail";

import { Card, Table } from "../../Components/atoms";
import { InsuPrimaryButton } from "../../Components/atoms/buttons/shared";
import { ProjectReviewDetailModal } from "../../features/projectReview/components/ProjectReviewDetailModal";
import useProjectReviewScreen from "./hooks/useProjectReviewScreen";
import { withCheckProjectAccess } from "./permissionWrapper";
import { IProjectReviewScreenProps } from "./types/IProjectReviewScreenProps";

const ProjectReviewScreen = function (props: IProjectReviewScreenProps) {
  const { intl, state, showModalVisible, hideModalVisible, getProjectId } =
    useProjectReviewScreen(props);

  // Display screening details when transitioning from a notification
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const submittedProjectId = query.get("submittedProjectId");
  useEffect(() => {
    if (submittedProjectId) {
      showModalVisible(submittedProjectId);
    }
  }, [submittedProjectId, showModalVisible]);

  const getReviewsTableColumn = () => {
    return [
      {
        title: intl.formatMessage({
          id: "screen.label.phase",
        }),
        dataIndex: "submissionCategory",
        key: "submissionCategory",
        render: (
          _: undefined,
          publishedReviewResult: PublishedReviewResultDetail,
        ) => {
          return publishedReviewResult.examinationPhaseName;
        },
      },
      {
        title: intl.formatMessage({
          id: "screen.label.category",
        }),
        dataIndex: "submissionCategory",
        key: "submissionCategory",
        render: (
          _: undefined,
          publishedReviewResponse: PublishedReviewResultDetail,
        ) => {
          return publishedReviewResponse.categoryName;
        },
      },
      {
        title: intl.formatMessage({
          id: "screen.label.examination_outcome",
        }),
        dataIndex: "result",
        key: "result",
        render: function _result(
          _: undefined,
          publishedReviewResult: PublishedReviewResultDetail,
        ) {
          return (
            <p>
              {publishedReviewResult && publishedReviewResult.result !== "BLANK"
                ? intl.formatMessage({
                    id: `screen.label.${publishedReviewResult.result}`.toLowerCase(),
                  })
                : ""}
            </p>
          );
        },
      },
      {
        render: function fn_(
          _: undefined,
          publishedReviewResult: PublishedReviewResultDetail,
        ) {
          return (
            // 審査詳細を見る
            <InsuPrimaryButton
              onClick={() => {
                showModalVisible(publishedReviewResult.submittedProjectId);
              }}
              style={{
                float: "right",
              }}
            >
              {intl.formatMessage({
                id: "screen.project.examination.view_details",
              })}
            </InsuPrimaryButton>
          );
        },
      },
    ];
  }; //

  return (
    <>
      <Row>
        <Col xs={24}>
          <h2>
            {intl.formatMessage(
              {
                id: "screen.label.examination_results_count_matter",
              },
              {
                count: state.reviews.length,
              },
            )}
          </h2>
        </Col>
      </Row>
      <Card>
        <Table
          className="ant-table-x-scroll"
          // rowClassName={() => {
          //     return 'on-hover-effect';
          // }}
          pagination={false}
          rowKey={"id"}
          // @ts-expect-error TS2769
          columns={getReviewsTableColumn()}
          dataSource={state.reviews}
        />
        <br />
      </Card>

      {state.submittedProjectId && (
        <ProjectReviewDetailModal
          visible={state.modalVisible}
          onCancel={hideModalVisible}
          projectId={getProjectId()}
          submissionId={state.submittedProjectId}
        />
      )}
    </>
  );
};
// @ts-expect-error TS2345
export default withCheckProjectAccess(ProjectReviewScreen);
