import { Button, Card, Col, Form, Row } from "antd";
import { FC, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { AllowCheckpoint } from "@/Components/organisms";
import CompanyActions from "@/Redux/CompanyRedux";

import { ProjectLabelTags } from "./ProjectLabelTags";
import { ProjectStageTags } from "./ProjectStageTags";

export type ProjectSettingsCardProps = {
  //
};

export const ProjectSettingsCard: FC<ProjectSettingsCardProps> = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  // @ts-expect-error TS2339
  const company = useSelector((state) => state.company.companyPayload);

  const [stages, setStages] = useState(company.stages ?? []);
  const [isAllowCheckpoint, setIsAllowCheckpoint] = useState(
    company.isAllowCheckpoint ?? false,
  );

  const onSubmit = () => {
    dispatch(
      CompanyActions.companyUpdateRequest(
        // プロジェクトラベルは変更のタイミングで保存される。設定画面全体で挙動を統一した方がいい
        // @ts-expect-error TS2339
        { stages, isAllowCheckpoint },
        intl.formatMessage({
          id: "screen.label.updated_company_information",
        }),
      ),
    );
  };

  return (
    <Card
      title={intl.formatMessage({
        id: "screen.label.project_settings",
      })}
      style={{
        marginBottom: 30,
      }}
    >
      <Row>
        <Col offset={4} span={14}>
          <Form layout={"vertical"}>
            <Form.Item
              label={intl.formatMessage({
                id: "screen.label.stage",
              })}
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 18 }}
            >
              <ProjectStageTags
                stages={stages}
                setStages={(stages) => setStages(stages)}
              />
            </Form.Item>

            <Form.Item
              label={intl.formatMessage({
                id: "screen.label.project_label",
              })}
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 18 }}
            >
              <ProjectLabelTags />
            </Form.Item>

            <AllowCheckpoint
              isAllowCheckpoint={isAllowCheckpoint}
              onChangeStatus={(checked) => setIsAllowCheckpoint(checked)}
            />

            <br />
            <Form.Item
              style={{
                alignItems: "center",
                textAlign: "center",
                display: "grid",
              }}
            >
              <Button
                id={"alpha_savecompany_titles"}
                type="primary"
                htmlType="button"
                onClick={onSubmit}
              >
                {intl.formatMessage({
                  id: "screens.title.save",
                })}
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Card>
  );
};
