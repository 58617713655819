import { memo } from "react";

import styles from "@/shared/buttons/components/CrossIconButton//styles.module.scss";
import CrossIcon from "@/shared/buttons/components/CrossIconButton/assets/cross.svg";
import { CrossIconButtonProps } from "@/shared/buttons/components/CrossIconButton/type";

export const CrossIconButton = memo(
  ({ className, alt, ...rest }: CrossIconButtonProps) => {
    return (
      <button className={`${styles.cross_icon_button} ${className}`} {...rest}>
        <img src={CrossIcon} alt={alt} />
      </button>
    );
  },
);
