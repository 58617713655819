import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ApiActions, { ApiRequestData } from "../../../Redux/ApiRedux";
import BatchExaminationActions from "../../../Redux/BatchExaminationRedux";
import { endpoints, parseEndpoint } from "../../../Services/endpoints";
import { SingleSubmission } from "../types/IBatchExaminationScoreManagementScreen";

// @ts-expect-error TS7006
const useBatchExaminationScoreManagementScreen = (props) => {
  const { history } = props;
  const dispatch = useDispatch();
  const [batchName, setBatchName] = useState("");

  const batchExamination = useSelector((state: any) => state.batchExamination);

  const getBatchExaminationDetails = () => {
    dispatch(
      // @ts-expect-error TS2554
      BatchExaminationActions.batchExaminationGetsRequest({
        batchId: getBatchId(),
      }),
    );
  };

  const updateExaminationSettings = (updatedExaminationSettings: any) => {
    const requestBuilder: ApiRequestData = {
      method: "putRequest",
      url: parseEndpoint(endpoints.singleBatchExamination, {
        batchId: getBatchId(),
      }),
      data: updatedExaminationSettings,
    };
    dispatch(
      ApiActions.apiRequest(requestBuilder, ({ data }) => {
        getBatchExaminationDetails();
      }),
    );
  };

  const getBatchId = () => {
    const {
      match: { params },
    } = props;
    return params.id ? params.id : -1;
  };
  const getBatchDetails = () => {
    const requestBuilder: ApiRequestData = {
      method: "getRequest",
      url: parseEndpoint(endpoints.singleBatch, {
        batchId: getBatchId(),
      }),
      data: {},
    };
    dispatch(
      ApiActions.apiRequest(requestBuilder, ({ data }) => {
        //@ts-expect-error 要API型定義
        setBatchName(data.name);
      }),
    );
  };
  useEffect(() => {
    getBatchDetails();
    getBatchExaminationDetails();
  }, []);

  const saveNewBatchCategoryNewOrder = (
    localSubmissionCategories: SingleSubmission[],
  ) => {
    const data: any = {};
    localSubmissionCategories.forEach(
      (singleSubmission: SingleSubmission, index: number) => {
        // @ts-expect-error TS2538
        data[singleSubmission.id] = index;
      },
    );
    const requestBuilder: ApiRequestData = {
      method: "putRequest",
      url: parseEndpoint(endpoints.reorderSingleBatchExamination, {
        batchId: getBatchId(),
      }),
      data,
    };
    dispatch(
      ApiActions.apiRequest(requestBuilder, ({ data }) => {
        getBatchExaminationDetails();
      }),
    );
  };

  return {
    history,
    batchName,
    saveNewBatchCategoryNewOrder,
    updateExaminationSettings,
    batchExaminationSubmissionCategory:
      batchExamination.batchExaminationSubmissionCategory,
    batchId: getBatchId(),
  };
};

export default useBatchExaminationScoreManagementScreen;
