import { BranchesOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Row, Select } from "antd";
import _flow from "lodash/flow";
import { memo } from "react";
import { Link } from "react-router-dom";

import DeleteIcon from "../../assets/icons/discard.svg";
import { getObjUUID } from "../../utils/common";
import useProjectLinkComponent from "./hooks/useProjectLinkComponent";
const { Option } = Select;
type Props = {
  showOnlyLinks: boolean;
  onUpdateLinks: () => void;
  project: Object;
};
function ProjectLinkComponent(props: Props) {
  const {
    deleteProjectLink,
    showOnlyLinks,
    user,
    links,
    intl,
    selectProjects,
    setSelectProjects,
    setSearchValue,
    searchValue,
    handleSearch,
    filteredProjects,
    setLinks,
    onUpdateLinks,
  } = useProjectLinkComponent(props);

  // @ts-expect-error TS7006
  const renderLinkEditRow = (item) => {
    return (
      <Row key={item.id} align="middle">
        <span style={{ fontWeight: "bold" }}>
          {intl.formatMessage({
            id: "screen.label.related_projects",
          })}{" "}
          :{" "}
        </span>
        <Link
          style={{ marginLeft: 15 }}
          to={`/dashboard/project.top/${item.uuid}`}
        >
          {item.name}
        </Link>
        <span
          style={{ marginLeft: 15 }}
          onClick={() => {
            deleteProjectLink(item.uuid);
          }}
        >
          [x {intl.formatMessage({ id: "screen.label.delete" })}]
        </span>
      </Row>
    );
  }; //
  // @ts-expect-error TS7006
  const renderLinkShowRow = (item) => {
    return (
      <Row key={item.id} align="middle">
        <BranchesOutlined style={{ fontWeight: "bold" }} />
        <span style={{ marginLeft: 8, fontWeight: "bold" }}>
          {intl.formatMessage({
            id: "screen.label.related_projects",
          })}{" "}
          :{" "}
        </span>
        <Link
          style={{ marginLeft: 15 }}
          to={`/dashboard/project.top/${item.uuid}`}
        >
          {item.name}
        </Link>
      </Row>
    );
  }; //
  // @ts-expect-error TS7006
  const renderOption = (project) => {
    return (
      <Option key={project.uuid} value={project.uuid}>
        {project.name}
      </Option>
    );
  }; //
  if (showOnlyLinks) {
    if (links.length > 0) {
      return (
        <>
          <Divider style={{ marginTop: 16, marginBottom: 16 }} />
          {links.map((item) => renderLinkShowRow(item))}
        </>
      );
    }
    return null;
  }
  if (user.type !== "admin") {
    return null;
  }
  return (
    <div>
      <h4>
        {intl.formatMessage({
          id: "screen.label.associate_project",
        })}{" "}
        ({links.length})
      </h4>

      <br />
      <Row align="middle">
        <Col flex="1">
          <Select
            showSearch
            allowClear
            mode="multiple"
            size="large"
            placeholder={intl.formatMessage({
              id: "screen.label.enter_project_name_or_link",
            })}
            value={selectProjects}
            onChange={(value) => {
              setSelectProjects(value);
              setSearchValue("");
            }}
            searchValue={searchValue}
            onSearch={handleSearch}
            optionFilterProp="children"
            filterOption={(input, option) =>
              // @ts-expect-error TS18048
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            style={{ width: "100%" }}
          >
            {filteredProjects.map((project) => {
              return renderOption(project);
            })}
          </Select>
        </Col>
        <Col flex="none" style={{ marginLeft: 10 }}>
          <Button
            style={{
              color: "#1F86E9",
              border: "1px solid #1F86E9",
            }}
            size="large"
            onClick={() => {
              if (selectProjects.length > 0) {
                const cloneLinks = [...links];
                for (const uuid of selectProjects) {
                  // @ts-expect-error TS2339
                  const project = filteredProjects.find((a) => a.uuid === uuid);
                  if (project) {
                    cloneLinks.push(project);
                  }
                }
                setSelectProjects([]);
                setLinks(cloneLinks);
                onUpdateLinks(cloneLinks.map((a) => getObjUUID(a)));
              }
            }}
          >
            {intl.formatMessage({ id: "screen.label.add" })}
          </Button>
        </Col>
      </Row>
      <br />
      <Row>
        <Col span={24}>
          <ul
            style={{
              padding: 0,
            }}
          >
            {links.length > 0 &&
              links.map((item) => {
                return (
                  <li
                    // @ts-expect-error TS2339
                    key={item.uuid}
                    style={{
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      borderBottomStyle: "solid",
                      borderBottomWidth: "1px",
                      borderBottomColor: "#DDDDDD",
                      height: "48px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        paddingRight: "1vw",
                        paddingLeft: "1vw",
                      }}
                    >
                      <>
                        <span
                          style={{
                            flexGrow: 1,
                            fontWeight: "normal",
                            fontStyle: "normal",
                            fontSize: "12px",
                            lineHeight: "200%",
                            color: "#323232",
                            width: "10%",
                            overflow: "hidden",
                          }}
                        >
                          {/*
                           // @ts-expect-error TS2339 */}
                          <Link to={`/dashboard/project.top/${item.uuid}`}>
                            {/*
                             // @ts-expect-error TS2339 */}
                            {item.name}
                          </Link>
                        </span>
                        <span
                          style={{
                            fontSize: "14px",
                            lineHeight: "20px",
                            fontWeight: "normal",
                          }}
                        >
                          <div
                            onClick={() => {
                              // @ts-expect-error TS2339
                              deleteProjectLink(item.uuid);
                            }}
                          >
                            <img src={DeleteIcon} />
                          </div>
                        </span>
                      </>
                    </div>
                  </li>
                );
              })}
          </ul>
        </Col>
      </Row>
    </div>
  );
}
export default _flow([memo])(ProjectLinkComponent);
