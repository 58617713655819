import { useCallback, useState } from "react";

import { ExaminationResult } from "@/../types/SubmittedProject";
export const useSelectedResults = () => {
  const [selectedResults, setSelectedResults] = useState<ExaminationResult[]>(
    [],
  );

  const resetSelectedResults = useCallback<() => void>(() => {
    setSelectedResults([]);
  }, [setSelectedResults]);

  return {
    selectedResults,
    setSelectedResults,
    resetSelectedResults,
  };
};
