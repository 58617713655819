import { Card, Layout } from "antd";
import React from "react";
import { connect } from "react-redux";

import { ReduxDispatch, ReduxState } from "@/Redux/types";

import { NewsCreateForm, NewsFormData } from "../../../../Components/organisms";
import ApiActions, {
  ApiRequestData,
  ApiSuccessCallback,
} from "../../../../Redux/ApiRedux";
import { endpoints, parseEndpoint } from "../../../../Services/endpoints";
import createFormData from "../../../../utils/createFormData";
type ActionProps = {
  dispatchApiRequest: () => void;
};
type StateProps = {
  auth: Object;
};
type otherProps = {
  match: Object;
};
type Props = StateProps & ActionProps & otherProps;
const { Content } = Layout;
function NewsUpdate(props: Props) {
  const [item, setItem] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [itemId] = React.useMemo(() => {
    const {
      // @ts-expect-error TS2339
      match: { params },
    } = props;
    const itemId = params.id ? params.id : -1;
    return [itemId];
  }, [props]);
  React.useEffect(() => {
    if (itemId && itemId !== -1) {
      setLoading(true);
      getNewsItem();
    }
  }, [itemId]);
  const getNewsItem = () => {
    const requestBuilder = {
      method: "getRequest",
      url: parseEndpoint(endpoints.updateNews, { id: itemId }),
    };
    // @ts-expect-error TS2554
    props.dispatchApiRequest(requestBuilder, ({ data }) => {
      setLoading(false);
      setItem(data);
    });
  };
  const handleSubmit = (data: NewsFormData) => {
    setLoading(true);
    const requestBuilder = {
      method: "putRequest",
      url: parseEndpoint(endpoints.updateNews, {
        id: itemId,
      }),
      data: createFormData(data),
    };
    // @ts-expect-error TS2554
    props.dispatchApiRequest(requestBuilder, ({ data }) => {
      setLoading(false);
      window.history.back();
    });
  };
  return (
    <Content>
      <Card>
        <NewsCreateForm
          item={item}
          // @ts-expect-error TS2339
          user={props.auth.payload}
          companies={[]}
          onSubmit={handleSubmit}
          // @ts-expect-error TS2322
          isModal={false}
          loading={loading}
          isGlobal={false}
        />
      </Card>
    </Content>
  );
}
const mapStateToProps = (state: ReduxState) => ({
  auth: state.auth,
});
const mapDispatchToProps = (dispatch: ReduxDispatch) => ({
  dispatchApiRequest: (data: ApiRequestData, callback: ApiSuccessCallback) =>
    dispatch(ApiActions.apiRequest(data, callback)),
});
// @ts-expect-error TS2345
export default connect(mapStateToProps, mapDispatchToProps)(NewsUpdate);
