import Select from "react-select";

import { Arrow } from "@/shared/icons/components/Arrow";
import { MultiCheckSelectBoxProps } from "@/shared/selectbox/components/MultiCheckSelectBox/type";
import { SelectOption } from "@/shared/selectbox/types/SelectOption";

import { CheckableMenuList } from "../CheckableMenuList";
import styles from "./styles.module.scss";

export const MultiCheckSelectBox = <OptionValue,>({
  id,
  width,
  options,
  defaultValue,
  selectedOptions,
  defaultLabel,
  onSelect,
  resetCheck,
}: MultiCheckSelectBoxProps<OptionValue>) => {
  const inputId = `${id}-input`;
  const checkedExists = selectedOptions.length > 0;
  const labelColor = `${checkedExists ? styles.checked_label_color : ""}`;
  const containerBorderColor = checkedExists
    ? styles.checked_border_color
    : styles.border_color;
  const label = checkedExists
    ? selectedOptions.map(({ label }) => label).join("、")
    : defaultLabel;
  return (
    <Select<SelectOption<OptionValue>, true>
      id={id}
      inputId={inputId}
      options={options}
      components={{
        DropdownIndicator: () => null,
        ClearIndicator: () => null,
        IndicatorSeparator: () => (
          <Arrow
            size="small"
            direction="down"
            color={`${checkedExists ? "green" : "black"}`}
          />
        ),
        MenuList: (props) => (
          <CheckableMenuList
            checkedExits={checkedExists}
            onSelect={onSelect}
            resetCheck={resetCheck}
            selectedOptions={selectedOptions}
            {...props}
          />
        ),
      }}
      defaultValue={defaultValue}
      value={{ value: null, label: label ?? "" }}
      styles={{
        container: (baseStyles) => ({
          ...baseStyles,
          border: `1px solid ${containerBorderColor}`,
          borderRadius: "20px",
          width,
          zIndex: 2,
        }),
        control: (baseStyles) => ({
          ...baseStyles,
          fontWeight: Number(styles.normal_font_weight),
          fontSize: Number(styles.secondary_font_size),
          padding: "0 8px",
          backgroundColor: "transparent",
          border: "none",
          boxShadow: "none",
        }),
        singleValue: (baseStyles) => ({
          ...baseStyles,
          color: labelColor,
        }),
        valueContainer: (baseStyles) => ({
          ...baseStyles,
          flexWrap: "nowrap",
          alignItems: "center",
        }),
      }}
      classNames={{
        container: () => styles.select_container,
        control: () => styles.select_box_control,
        indicatorsContainer: () => styles.select_indicators_container,
        input: () => styles.select_input,
      }}
    />
  );
};
