import { FC, useCallback, useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { ContentContext } from "@/Provider";
import { endpoints, parseEndpoint } from "@/Services/endpoints";
import { TagList } from "@/shared/tags/components/TagList";

import { Interest } from "../../../../../types/Interest";

type UserInterestTags = {
  //
};

export const UserInterestTags: FC<UserInterestTags> = () => {
  const intl = useIntl();
  const context = useContext(ContentContext);

  const [interests, setInterests] = useState<Interest[]>([]);

  const getInterest = useCallback(() => {
    const interestRequest = {
      method: "getRequest",
      url: parseEndpoint(endpoints.interest),
      data: {},
    };
    // @ts-expect-error TS2345
    context.apiRequest(interestRequest, ({ data }) => {
      // @ts-expect-error TS7006
      setInterests(data);
    });
  }, [context, setInterests]);

  useEffect(() => {
    getInterest();
  }, [getInterest]);

  const handleCreate = (interestName: string) => {
    const isDuplicate = interests.some(
      (interest) => interest.name === interestName,
    );
    const isEmpty = interestName.trim().length === 0;

    if (isDuplicate || isEmpty) return;

    const interestRequest = {
      method: "postRequest",
      url: parseEndpoint(endpoints.interest),
      data: {
        name: interestName,
      },
    };
    // @ts-expect-error TS2345
    context.apiRequest(interestRequest, () => {
      getInterest();
    });
  };

  const handleEdit = (oldInterest: string, newInterest: string) => {
    const isDuplicateTag = interests.some(
      (interest) => interest.name === newInterest,
    );
    const isEmpty = newInterest.trim().length === 0;

    if (isDuplicateTag || isEmpty) return;

    const interestId = interests.find(
      (interest) => interest.name === oldInterest,
    )?.id;

    const requestBuilder = {
      method: "putRequest",
      url: parseEndpoint(endpoints.singleInterest, {
        // @ts-expect-error TS2322
        interestId,
      }),
      data: {
        name: newInterest,
      },
    };
    // @ts-expect-error TS2345
    context.apiRequest(requestBuilder, () => {
      getInterest();
    });
  };

  const handleDeleted = (interestName: string) => {
    const interestId = interests.find(
      (interest) => interest.name === interestName,
    )?.id;

    const interestRequest = {
      method: "deleteRequest",
      url: parseEndpoint(endpoints.singleInterest, {
        // @ts-expect-error TS2322
        interestId,
      }),
      data: {},
    };
    // @ts-expect-error TS2345
    context.apiRequest(interestRequest, () => {
      getInterest();
    });
  };

  return (
    <TagList
      tags={interests.map((interest) => interest.name)}
      createLabel={intl.formatMessage({
        id: "screen.label.interest",
      })}
      onCreated={handleCreate}
      onEdited={handleEdit}
      onDeleted={handleDeleted}
    />
  );
};
