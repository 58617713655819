import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { Card, Col, Form, List, Upload } from "antd";
import PropTypes from "prop-types";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";

import {
  ButtonCV,
  ButtonOption,
  ButtonSwitch,
  IconDelete,
} from "../../Components/atoms";
import { DraftEditor, nodeConfig } from "../../Components/molecules";
import { Input } from "../../Components/molecules";
import ProgramInfoActions from "../../Redux/ProgramInfoRedux";
import { convertYVLinksToEmbed } from "../../utils/common";
import useProgramInfoViewMgtScreen from "./hooks/useProgramInfoViewMgtScreen";
import { IProgramInfoViewMgtScreenProps } from "./types/IProgramInfoViewMgtScreenProps";

const ProgramInfoViewMgtScreen: FC<IProgramInfoViewMgtScreenProps> = function (
  props,
) {
  const {
    intl,
    state,
    user,
    handleChange,
    getUploadProps,
    onEditorStateChange,
    saveFile,
    uploadImageCallBack,
    deleteProgramFiles,
    saveProgramInfo,
    setState,
  } = useProgramInfoViewMgtScreen(props);

  return (
    <>
      <Card>
        <Col span={18}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <ButtonSwitch
              checked={state.programInfoStatus === "public"}
              defaultChecked={state.programInfoStatus === "public"}
              onChange={(state) => {
                setState(
                  {
                    programInfoStatus: state ? "public" : "private",
                  },
                  () => {
                    saveProgramInfo();
                  },
                );
                // @ts-expect-error TS2722
                props.dispatchSetProgramInfoPayload({
                  content: "",
                  status: state ? "public" : "private",
                });
              }}
            />
            <p className="offset-left-16">
              {intl.formatMessage({
                id: "screen.label.release",
              })}
            </p>
          </div>
          <Form layout={"vertical"}>
            <br />
            <Form.Item
              label={intl.formatMessage({
                id: "screen.label.description",
              })}
            >
              <DraftEditor
                toolbar={nodeConfig(
                  uploadImageCallBack,
                  // @ts-expect-error TS2683
                  convertYVLinksToEmbed.bind(this, user.token, user.company.id),
                )}
                placeholder={intl.formatMessage({
                  id: "screen.label.please_enter",
                })}
                editorState={state.editorState}
                onEditorStateChange={onEditorStateChange}
              />
            </Form.Item>
            <br />
            <Form.Item label={"Video URL"}>
              <Input
                onChange={(e) => handleChange("videoUrl", e.target.value)}
                value={state.videoUrl}
              />
            </Form.Item>
            <br />
            <Form.Item>
              <ButtonCV type="primary" onClick={saveProgramInfo}>
                <FormattedMessage id="screen.label.storage" />
              </ButtonCV>
            </Form.Item>
            <br />
          </Form>
        </Col>
      </Card>
      <br />
      <br />
      <Card>
        <Col span={20}>
          <h4>
            {intl.formatMessage({
              id: "screen.label.file_upload",
            })}
          </h4>
          <Upload {...getUploadProps()}>
            <ButtonOption>
              +
              {intl.formatMessage({
                id: "screen.label.upload_files",
              })}
            </ButtonOption>
          </Upload>
          <br />
          <br />
          <h4>
            {intl.formatMessage({
              id: "screen.label.uploaded_files",
            })}
          </h4>
          <List
            size="large"
            dataSource={state.files}
            renderItem={(item) => (
              <List.Item
                actions={[
                  <div
                    // @ts-expect-error TS18046
                    key={item.id}
                    onClick={() => {
                      // @ts-expect-error TS18046
                      deleteProgramFiles(item.id);
                    }}
                  >
                    {IconDelete}
                  </div>,
                ]}
              >
                {/*
                 // @ts-expect-error TS18046 */}
                {item.fileName}
              </List.Item>
            )}
          />
        </Col>
      </Card>
    </>
  );
};

// @ts-expect-error TS7006
const mapDispatchToProps = (dispatch) => ({
  // @ts-expect-error TS7006
  dispatchSetProgramInfoPayload: (payload) =>
    // @ts-expect-error TS2554
    dispatch(ProgramInfoActions.programInfoGetSuccess(payload)),
});
ProgramInfoViewMgtScreen.propTypes = {
  // @ts-expect-error TS2322
  context: PropTypes.object,
  dispatchSetProgramInfoPayload: PropTypes.func,
};
export default connect(null, mapDispatchToProps)(ProgramInfoViewMgtScreen);
