import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import "./index.scss";

import ReactDOM from "react-dom";

import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { getTrackedApp } from "./utils/getTrackedApp";

function isIE() {
  let ua = navigator.userAgent;
  const is_ie = ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;
  return is_ie;
}

function reactRender() {
  const TrackedApp = getTrackedApp(App);
  ReactDOM.render(<TrackedApp />, document.getElementById("root"));
}

if (isIE()) {
  document.body.innerHTML += `<div id="unsupported">IE（Internet Explorer）はサポートされておりません。ログイン画面が表示されるブラウザをインストールの上、再度アクセスいただくようお願いします。推奨ブラウザはChromeとなります。</div>`;
} else {
  reactRender();
}

serviceWorker.unregister();
