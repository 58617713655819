import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Modal, Popconfirm, Row, Switch } from "antd";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

import { NewHypothesisModalProps } from "./types/NewHypothesisModalProps";
const { TextArea } = Input;

const NewHypothesisModal: React.FC<NewHypothesisModalProps> = function ({
  isVisible,
  setIsNewHypothesisModalVisible,
  defaultHypothesisData,
  onSaveHypothesis,
  modalOnClosed,
}: NewHypothesisModalProps) {
  const [localHypothesisData, setHypothesisData] = useState(null);
  useEffect(() => {
    if (localHypothesisData === null) {
      setHypothesisData(defaultHypothesisData);
    }
  }, [defaultHypothesisData]);
  // @ts-expect-error TS7006
  const renderSubTitle = (string) => {
    return (
      <div style={{ marginRight: 12 }}>
        <span style={{ fontSize: 14 }}>{string}</span>
      </div>
    );
  };
  // @ts-expect-error TS7006
  const _renderEachSwitchItems = (rnNode) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginRight: "1vw",
        }}
      >
        {rnNode}
      </div>
    );
  };
  if (localHypothesisData !== null) {
    return (
      <Modal
        width={"41vw"}
        title={<FormattedMessage id={"screens.hypothesis_modal_title"} />}
        visible={isVisible}
        footer={null}
        onCancel={() => {
          // @ts-expect-error TS2722
          modalOnClosed();
          // @ts-expect-error TS2722
          setIsNewHypothesisModalVisible(false);
        }}
      >
        <div>
          <Form layout={"vertical"}>
            <Form.Item
              label={<FormattedMessage id={"screens.hypothesis_title"} />}
            >
              <Input
                onChange={(e) => {
                  const a = Object.assign({}, localHypothesisData);
                  // @ts-expect-error TS2339
                  a.hypothesisFormMetadata.hypothesisFormName = e.target.value;
                  setHypothesisData(a);
                }}
                value={
                  // @ts-expect-error TS2339
                  localHypothesisData.hypothesisFormMetadata.hypothesisFormName
                }
              />
            </Form.Item>

            <Row justify="start" align="middle" style={{ marginTop: 8 }}>
              <Col>
                {_renderEachSwitchItems(
                  <>
                    {renderSubTitle(
                      <FormattedMessage id="screens.title.hypotheses.display" />,
                    )}
                    <Switch
                      size="small"
                      onChange={(checked) => {
                        const a = Object.assign({}, localHypothesisData);
                        // @ts-expect-error TS2339
                        a.hypothesisFormMetadata.displayHypothesis = checked;
                        setHypothesisData(a);
                      }}
                      checked={
                        // @ts-expect-error TS2339
                        localHypothesisData.hypothesisFormMetadata
                          .displayHypothesis
                      }
                      checkedChildren={<CheckOutlined />}
                      unCheckedChildren={<CloseOutlined />}
                    />
                  </>,
                )}
              </Col>
              <Col>
                {_renderEachSwitchItems(
                  <>
                    {renderSubTitle(
                      <FormattedMessage id="screens.title.hypotheses.frombegin" />,
                    )}
                    <Switch
                      size="small"
                      checked={
                        // @ts-expect-error TS2339
                        localHypothesisData.hypothesisFormMetadata
                          .firstViewHypothesis
                      }
                      onChange={(checked) => {
                        const a = Object.assign({}, localHypothesisData);
                        // @ts-expect-error TS2339
                        a.hypothesisFormMetadata.firstViewHypothesis = checked;
                        setHypothesisData(a);
                      }}
                      checkedChildren={<CheckOutlined />}
                      unCheckedChildren={<CloseOutlined />}
                    />
                  </>,
                )}
              </Col>
              <Col>
                {_renderEachSwitchItems(
                  <>
                    {renderSubTitle(
                      <FormattedMessage id="screens.title.hypotheses.userinfomark" />,
                    )}
                    <Switch
                      checked={
                        // @ts-expect-error TS2339
                        localHypothesisData.hypothesisFormMetadata.useInfoMark
                      }
                      size="small"
                      onChange={(checked) => {
                        const a = Object.assign({}, localHypothesisData);
                        // @ts-expect-error TS2339
                        a.hypothesisFormMetadata.useInfoMark = checked;
                        setHypothesisData(a);
                      }}
                      checkedChildren={<CheckOutlined />}
                      unCheckedChildren={<CloseOutlined />}
                    />
                  </>,
                )}
              </Col>
            </Row>
            <br />
            <Form.Item
              label={<FormattedMessage id="screen.label.description" />}
            >
              <TextArea
                onChange={(e) => {
                  const a = Object.assign({}, localHypothesisData);
                  // @ts-expect-error TS2339
                  a.hypothesisFormMetadata.hypothesisFormDescription =
                    e.target.value;
                  setHypothesisData(a);
                }}
                rows={5}
                value={
                  // @ts-expect-error TS2339
                  localHypothesisData.hypothesisFormMetadata
                    .hypothesisFormDescription
                }
              />
            </Form.Item>
            <Form.Item>
              <Popconfirm
                title={
                  <FormattedMessage id="screens.batch.new_hypothesis_confirm_msg" />
                }
                onConfirm={() => {
                  // @ts-expect-error TS2722
                  onSaveHypothesis(localHypothesisData);
                }}
                onCancel={() => {}}
                okText={<FormattedMessage id="screen.label.yes" />}
                cancelText={<FormattedMessage id="screen.label.no" />}
              >
                <Button
                  style={{
                    backgroundColor: "#00D282",
                    color: "white",
                    fontWeight: 400,
                  }}
                  type={"ghost"}
                >
                  <FormattedMessage id="screens.hypothesis_save" />
                </Button>
              </Popconfirm>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    );
  }
  return <></>;
};
export default NewHypothesisModal;
