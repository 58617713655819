import { Card, Col, Row } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";

import { Line } from "../../atoms/line";
import { VideoTopRecommendationCard } from "../../molecules/videos";

type VideoTopRecommendationSectionProps = {
  recommendationVideos: Array<any>;
};

export const VideoTopRecommendationSection = (
  props: VideoTopRecommendationSectionProps,
) => {
  const { recommendationVideos } = props;
  if (recommendationVideos.length > 0) {
    return (
      <Card>
        <Row>
          <Col span={24}>
            <p
              style={{
                fontWeight: "bold",
                color: "#323232",
                paddingLeft: "2vw",
                marginBottom: "24px",
                fontStyle: "normal",
                fontSize: "18px",
                lineHeight: "26px",
              }}
            >
              <FormattedMessage id="screen.label.featured_video" />
            </p>
            <br />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div>
              <Row gutter={12}>
                <Col xs={24} sm={12} md={12} lg={12}>
                  <VideoTopRecommendationCard
                    singleVideo={recommendationVideos[0]}
                  />
                  <br />
                </Col>
                <Col xs={24} sm={12} md={12} lg={12}>
                  <VideoTopRecommendationCard
                    singleVideo={recommendationVideos[1]}
                  />
                  <br />
                </Col>

                <Col xs={24} sm={8} md={8} lg={8}>
                  <VideoTopRecommendationCard
                    singleVideo={recommendationVideos[2]}
                  />
                  <br />
                </Col>
                <Col xs={24} sm={8} md={8} lg={8}>
                  <VideoTopRecommendationCard
                    singleVideo={recommendationVideos[3]}
                  />
                  <br />
                </Col>
                <Col xs={24} sm={8} md={8} lg={8}>
                  <VideoTopRecommendationCard
                    singleVideo={recommendationVideos[4]}
                  />
                  <br />
                </Col>
              </Row>
            </div>

            <Line color={"#e0e0e0"} />
            <br />
            <br />
            <br />
            <br />
          </Col>
        </Row>
      </Card>
    );
  }
  return <React.Fragment></React.Fragment>;
};
