import { Form, Modal } from "antd";
import DOMPurify from "dompurify";
import { EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { FC, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { DraftEditor } from "@/Components/molecules";
import CommentActions from "@/Redux/CommentRedux";
import { convertToRawWithTrimmer } from "@/utils/common";

export type CommentEditModalProps = {
  visible: boolean;
  commentId: number;
  defaultEditorState: EditorState;
  toolbar: unknown;
  suggestions: unknown;
  onCancel: () => void;
};

export const CommentEditModal: FC<CommentEditModalProps> = ({
  visible,
  commentId,
  defaultEditorState,
  toolbar,
  suggestions,
  onCancel,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  // @ts-expect-error TS2339
  const project = useSelector((state) => state.projects.projectPayload);
  const projectId = project.uuid;

  const [editorState, setEditorState] = useState(defaultEditorState);

  // @ts-expect-error TS7006
  const submitUpdate = (e) => {
    e.preventDefault();
    const rawNodes = convertToRawWithTrimmer(editorState, false);
    // @ts-expect-error TS7034
    const mentions = [];
    // @ts-expect-error TS2339
    Object.values(rawNodes.entityMap).forEach((entity) => {
      // @ts-expect-error TS18046
      if (entity.type === "MENTION") {
        // @ts-expect-error TS18046
        mentions.push(entity.data.url);
      }
    });
    // @ts-expect-error TS2345
    const markup = draftToHtml(rawNodes);
    const html = DOMPurify.sanitize(markup, {
      FORBID_TAGS: ["a"],
      ADD_TAGS: ["iframe"],
      ADD_ATTR: [
        "allow",
        "allowfullscreen",
        "frameborder",
        "scrolling",
        "target",
      ],
      FORBID_ATTR: ["style"],
    });
    const content = JSON.stringify(rawNodes);
    const message = intl.formatMessage({
      id: "screen.label.comment_has_been_completed",
    });

    dispatch(
      CommentActions.commentUpdateRequest(
        // @ts-expect-error TS7005
        {
          projectId,
          content: content,
          id: commentId,
          nodalData: true,
          // @ts-expect-error TS7005
          mentions,
          htmlContent: html,
        },
        message,
      ),
    );
    onCancel();
  };

  const handleCancel = () => {
    setEditorState(defaultEditorState);
    onCancel();
  };

  return (
    <Modal
      visible={visible}
      onOk={submitUpdate}
      onCancel={() => handleCancel()}
      cancelText={intl.formatMessage({
        id: "screen.label.cancel",
      })}
      className={"edit-comment-model"}
    >
      <Form layout={"vertical"}>
        <Form.Item
          label={intl.formatMessage({
            id: "screen.label.comment",
          })}
        >
          <DraftEditor
            toolbar={toolbar}
            wrapperClassName="wrapper-class"
            editorClassName="editor-class"
            toolbarClassName="toolbar-class"
            placeholder={intl.formatMessage({
              id: "screen.label.please_enter",
            })}
            editorState={editorState}
            onEditorStateChange={(editorState) => setEditorState(editorState)}
            // @ts-expect-error TS2322
            localization={{
              locale: "ja",
            }}
            mention={{
              separator: " ",
              trigger: "@",
              suggestions,
            }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
