import { z } from "zod";

export const projectSubmissionFormSchema = z.object({
  // 提出するカテゴリのID
  categoryId: z.string().min(1),
  // 申し送り事項
  title: z.string().max(200).optional(),
  // 提出ファイルURL
  fileUrl: z.string().optional(),
  // URLで提出する際の名称
  fileUrlName: z.string().optional(),
  // カテゴリ名
  category: z.string().optional(),
});

export type ProjectSubmissionFormSchema = z.infer<
  typeof projectSubmissionFormSchema
>;
