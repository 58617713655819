import { FC } from "react";
import { useIntl } from "react-intl";

import { TagList } from "@/shared/tags/components/TagList";

type UserSubsidiaryTags = {
  subsidiaries: string[];
  setSubsidiaries: (subsidiaries: string[]) => void;
};

export const UserSubsidiaryTags: FC<UserSubsidiaryTags> = ({
  subsidiaries,
  setSubsidiaries,
}) => {
  const intl = useIntl();

  const handleCreate = (newSubsidiary: string) => {
    const isDuplicate = subsidiaries.some(
      (subsidiary) => subsidiary === newSubsidiary,
    );
    const isEmpty = newSubsidiary.trim().length === 0;
    if (isDuplicate || isEmpty) return;

    setSubsidiaries([...subsidiaries, newSubsidiary]);
  };

  const handleDelete = (deletedSubsidiary: string) => {
    const _subsidiaries = subsidiaries.filter(
      (tag) => tag !== deletedSubsidiary,
    );
    setSubsidiaries(_subsidiaries);
  };

  return (
    <TagList
      tags={subsidiaries}
      createLabel={intl.formatMessage({
        id: "screen.label.affiliation_companies",
      })}
      onCreated={handleCreate}
      onDeleted={handleDelete}
    />
  );
};
