import "antd/dist/antd.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "./App.scss";

import { Worker } from "@react-pdf-viewer/core";
import { ConnectedRouter } from "connected-react-router";
import pdfJsWorker from "pdfjs-dist/legacy/build/pdf.worker.entry";
import { Suspense, useEffect } from "react";
import { Route, Switch } from "react-router-dom";

import CustomLoader from "./Components/atoms/CustomLoader";
import { CommonContextProviders } from "./contexts/CommonContextProviders";
import { ReduxContextProviders } from "./contexts/ReduxContextProviders";
import { ErrorBoundary } from "./features/error/components/ErrorBoundary";
import { TrackingWrapper } from "./features/tracking/components/TrackingWrapper";
import { history, setupStore } from "./Redux/store";
import ForgotPasswordScreen from "./Screens/auth/ForgotPassword";
import LoginScreen from "./Screens/auth/LoginScreen";
import PasswordResetScreen from "./Screens/auth/PasswordReset";
import Register from "./Screens/auth/Register";
import ThankYouScreen from "./Screens/auth/ThankYouScreen";
import VerifyEmailScreen from "./Screens/auth/VerifyEmailScreen";
import VerifyEmailSuccess from "./Screens/auth/VerifyEmailSuccess";
import VerifySubEmailScreen from "./Screens/auth/VerifySubEmailScreen";
import VerifySubEmailSuccess from "./Screens/auth/VerifySubEmailSuccess";
import CompaniesScreen from "./Screens/company/CompaniesScreen";
import UserCompaniesScreen from "./Screens/company/UserCompaniesScreen";
import Dashboard from "./Screens/dashboard/Dashboard";
import GlobalManagementScreen from "./Screens/global";
import LibraryScreen from "./Screens/library-manage";
import NotFoundScreen from "./Screens/NotFoundScreen";
import PrivateRoute from "./Screens/private-route/PrivateRoute";
import PrivateSuperAdminRoute from "./Screens/private-route/PrivateSuperAdminRoute";
import GlobalVideoScreen from "./Screens/videos-gmt";
import { appendScript } from "./utils/common";

const { store, persistor } = setupStore();

const App = () => {
  useEffect(() => {
    appendScript("/js/diff_patch.js");
  }, []);

  return (
    <Worker workerUrl={pdfJsWorker}>
      <CommonContextProviders>
        <ReduxContextProviders store={store} persistor={persistor}>
          <ConnectedRouter history={history}>
            <div className="App">
              <ErrorBoundary>
                <TrackingWrapper>
                  <Suspense fallback={<CustomLoader />}>
                    <Switch>
                      <Route exact path="/" component={LoginScreen} />
                      <Route exact path="/register/:id" component={Register} />
                      <Route exact path="/login/:id" component={LoginScreen} />
                      <Route exact path="/login" component={LoginScreen} />
                      <Route
                        exact
                        path="/thank-you"
                        component={ThankYouScreen}
                      />
                      <Route
                        exact
                        path="/forgot"
                        component={ForgotPasswordScreen}
                      />
                      <Route
                        exact
                        path="/reset/:id"
                        component={PasswordResetScreen}
                      />
                      <Route
                        exact
                        path="/verify-subemail/:id"
                        component={VerifySubEmailScreen}
                      />
                      <Route
                        exact
                        path="/verify-subemail-success"
                        component={VerifySubEmailSuccess}
                      />
                      <Route
                        exact
                        path="/verify-email/:id"
                        component={VerifyEmailScreen}
                      />
                      <Route
                        exact
                        path="/verify-email-success"
                        component={VerifyEmailSuccess}
                      />
                      <PrivateSuperAdminRoute
                        exact
                        path="/companies"
                        component={CompaniesScreen}
                      />
                      <PrivateRoute
                        exact
                        path="/user-companies/"
                        component={UserCompaniesScreen}
                      />

                      <PrivateSuperAdminRoute
                        path="/global"
                        component={GlobalManagementScreen}
                      />

                      <PrivateSuperAdminRoute
                        path="/global-library"
                        component={LibraryScreen}
                      />

                      <PrivateSuperAdminRoute
                        path="/global-video"
                        component={GlobalVideoScreen}
                      />

                      <PrivateRoute path="/dashboard" component={Dashboard} />
                      <Route path="*" component={NotFoundScreen} />
                    </Switch>
                  </Suspense>
                </TrackingWrapper>
              </ErrorBoundary>
            </div>
          </ConnectedRouter>
        </ReduxContextProviders>
      </CommonContextProviders>
    </Worker>
  );
};
export default App;
