import { memo } from "react";
import Select, { OnChangeValue } from "react-select";

import { Arrow } from "@/shared/icons/components/Arrow";
import styles from "@/shared/selectbox/components/LabelSelect/styles.module.scss";
import { SelectOption } from "@/shared/selectbox/components/LabelSelect/type";
import { LabelSelectProps } from "@/shared/selectbox/components/LabelSelect/type";

import { SingleSelectOptionItem } from "../SingleSelectOptionItem";
export const LabelSelect = memo(
  ({
    id,
    options,
    width,
    height,
    type,
    onSelect,
    value,
    maxWidth,
  }: LabelSelectProps) => {
    const fontWeight =
      type === "primary"
        ? Number(styles.bold_font_weight)
        : Number(styles.normal_font_weight);
    const fontSize =
      type === "primary"
        ? styles.primary_font_size
        : styles.secondary_font_size;
    const onChange = (newValue: OnChangeValue<SelectOption, false>) => {
      // 存在するOptionが選択された場合のみonSelectを実行する
      if (newValue) {
        onSelect(newValue);
      }
    };
    const controlClassName = `${styles.control} ${
      type === "primary" ? styles.control_primary : styles.control_secondary
    }`;

    return (
      <Select<SelectOption>
        value={value}
        id={id}
        options={options}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => (
            <Arrow
              size={type === "primary" ? "medium" : "small"}
              direction="down"
            />
          ),
          Option: (props) => (
            <SingleSelectOptionItem {...props} onSelect={onChange} />
          ),
        }}
        onChange={onChange}
        defaultValue={options[0]}
        styles={{
          container: (baseStyles) => ({
            ...baseStyles,
            border: "none",
            cursor: "pointer",
            maxWidth,
          }),
          control: (baseStyles) => ({
            ...baseStyles,
            borderTop: "none",
            borderLeft: "none",
            borderRight: "none",
            borderBottom: "none",
            width: width,
            height: height,
            fontWeight: fontWeight,
            fontSize: fontSize,
            backgroundColor: "transparent",
            borderColor: "transparent",
            boxShadow: "none",
            cursor: "pointer",
          }),
          menu: (baseStyles) => ({
            ...baseStyles,
            color: styles.black_color,
            // TODO: 現在の審査グリッドのヘッダーより表示を優先させるために付与している。グリッドの変更が終わったら削除する
            zIndex: 3,
            whiteSpace: "nowrap",
            width: "inherit",
            maxWidth,
          }),
          menuList: (baseStyles) => ({
            ...baseStyles,
            overflowX: "hidden",
          }),
          input: (baseStyles) => ({
            ...baseStyles,
            margin: "0",
            cursor: "pointer",
          }),
          singleValue: (baseStyles) => ({
            ...baseStyles,
            color: styles.black_color,
          }),
          valueContainer: (baseStyles) => ({
            ...baseStyles,
            padding: "0",
          }),
        }}
        classNames={{
          control: () => controlClassName,
          indicatorsContainer: () => styles.select_indicators_container,
          valueContainer: () => styles.select_value_container,
          input: () => styles.select_input,
        }}
      />
    );
  },
);
