import React from "react";
import { Route } from "react-router-dom";

import { ApiRequestData } from "@/Redux/ApiRedux";

import { withContext } from "../../Provider";
import { endpoints, parseEndpoint } from "../../Services/endpoints";
import { validUUIDV4 } from "../../utils/common";
import { UUIDRouterProps } from "./types/UUIDRouterProps";

const UUIDRouter: React.FC<UUIDRouterProps> = ({
  component: Component,
  renderContent,
  subRouter,
  context,
  ...rest
}: UUIDRouterProps) => {
  const user = context.user;
  const apiRequest = context.apiRequest;
  return (
    <Route
      {...rest}
      render={(props) => {
        const { match, history } = props;
        const { params, path } = match;
        const { id, ...restIds } = params;
        if (validUUIDV4(id)) {
          return renderContent ? (
            renderContent
          ) : (
            <Component {...props} context={context} />
          );
        } else {
          const requestBuilder: ApiRequestData = {
            method: "getRequest",
            url: parseEndpoint(endpoints.subRouter, {
              subRouter,
              // @ts-expect-error TS2322
              id,
            }),
          };
          // @ts-expect-error TS2722
          apiRequest(requestBuilder, ({ data }) => {
            let redirectPath = path.replace(/:id/g, data.uuid);
            if (restIds && Object.keys(restIds).length > 0) {
              Object.keys(restIds).forEach((key, index) => {
                const regex = new RegExp(`:${key}`, "g");
                // @ts-expect-error TS2769
                redirectPath = redirectPath.replace(regex, restIds[key]);
              });
            }
            history.replace(redirectPath);
          });
        }
      }}
    />
  );
};
export default withContext(UUIDRouter);
