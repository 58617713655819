import { Avatar } from "antd";
import styled from "styled-components";
type SizeType = "small" | "large" | "default";
type Props = {
  userId: number;
  url?: string;
  onClick?: () => void;
  size?: SizeType;
};

const images = [
  "/icons/user-blue.png",
  "/icons/user-gray.png",
  "/icons/user-green.png",
  "/icons/user-light.png",
  "/icons/user-orange.png",
  "/icons/user-pink.png",
  "/icons/user-purple.png",
  "/icons/user-yellow.png",
];

const PlaceHolderAvatar = styled(Avatar)`
  margin-right: 10px;
  vertical-align: middle;
`;

const MainAvatar = styled(PlaceHolderAvatar)`
  background-color: #e8e8e8;
`;

export const UserIcon = (props: Props) => {
  const { userId, url, onClick = () => {}, size = "large" } = props;
  if (url && url.length > 0) {
    let profileImage = url;
    if (profileImage.indexOf("http") === -1)
      profileImage = `https://${profileImage}`;
    return <MainAvatar size={size} src={profileImage} onClick={onClick} />;
  } else {
    const colorIndex = userId % images.length;
    return (
      <PlaceHolderAvatar
        size={size}
        src={images[colorIndex]}
        onClick={onClick}
      />
    );
  }
};
