import { CheckCircleFilled } from "@ant-design/icons";
import { AnyAction } from "@reduxjs/toolkit";
import { Col, Form, Layout, Row } from "antd";
import PropTypes from "prop-types";
import { FC } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { ButtonSubmit } from "../../Components/atoms";
import {
  AlphaDriveImg,
  AlphaDriveImgContainer,
  AuthLayout,
  Banner,
  HorizontalLine,
  InputFormPassword,
  LinkContainer,
  LoginBanner,
  LoginLabel,
  LogoTitleLayout,
  PasswordConditionText,
  VerifyHeaderBold,
} from "../../Components/molecules";
import AuthActions from "../../Redux/AuthRedux";
import usePasswordResetScreen from "./hooks/usePasswordResetScreen";
import { IPasswordResetScreenProps } from "./types/IPasswordResetScreenProps";
const { Content } = Layout;
const PasswordResetScreen: FC<IPasswordResetScreenProps> = function (props) {
  const { intl, state, onChange, onSubmit, isValidPassword } =
    usePasswordResetScreen(props);
  return (
    <AuthLayout className="layout">
      <Content className="authContent">
        <Row>
          <Banner>
            <LogoTitleLayout>
              <LoginBanner alt={"Banner"} src={"/login_banner.png"} />
              <LoginLabel>
                <FormattedMessage id="screen.label.comprehensive_support_tool_des" />
                <br />
                <FormattedMessage id="screen.label.incubation_suite" />
              </LoginLabel>
            </LogoTitleLayout>
            <AlphaDriveImgContainer>
              <div>
                <span>Powered by &nbsp;</span>
                <AlphaDriveImg alt={"alpha drive"} src={"/alpha_drive.png"} />
              </div>
            </AlphaDriveImgContainer>
          </Banner>
          <Col>
            <div className="contentRoot">
              <div>
                <VerifyHeaderBold>
                  <FormattedMessage id="screens.title.reset_password" />
                </VerifyHeaderBold>
                <Form layout={"vertical"} onFinish={onSubmit}>
                  <Form.Item
                    label={intl.formatMessage({
                      id: "screen.label.password",
                    })}
                  >
                    <InputFormPassword
                      required
                      onChange={onChange}
                      value={state.password}
                      id="password"
                      type="password"
                    />
                  </Form.Item>
                  {state.password && (
                    <PasswordConditionText
                      color={isValidPassword ? "#323232" : "#D60000"}
                    >
                      <FormattedMessage id="screens.message.pass_condition" />
                      {isValidPassword && <CheckCircleFilled />}
                    </PasswordConditionText>
                  )}
                  <div>
                    <ButtonSubmit htmlType="submit">
                      <FormattedMessage id="screen.label.change_your_password" />
                    </ButtonSubmit>
                  </div>
                  <HorizontalLine />
                  <LinkContainer>
                    <Link to="/">
                      <FormattedMessage id="screen.label.click_here_to_login" />
                    </Link>
                  </LinkContainer>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </Content>
    </AuthLayout>
  );
};
const mapStateToProps = (state: { auth: any; errors: any }) => ({
  auth: state.auth,
  errors: state.errors,
});
const mapDispatchToProps = (dispatch: (arg0: AnyAction) => any) => {
  return {
    dispatchResetPasswordRequest: (data: any, message: any) =>
      // @ts-expect-error TS2554
      dispatch(AuthActions.resetPasswordRequest(data, message)),
  };
};
PasswordResetScreen.propTypes = {
  match: PropTypes.object,
  // @ts-expect-error TS2322
  dispatchResetPasswordRequest: PropTypes.func,
};
export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(PasswordResetScreen),
);
