import { Row } from "antd";
import { useCallback, useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { ContentContext } from "../../../../Provider";
import ProjectActions from "../../../../Redux/ProjectRedux";
import { endpoints, parseEndpoint } from "../../../../Services/endpoints";
import { IconDownArrow, IconUpArrow } from "../../../atoms";
import { ProjectCheckpointItem } from "../../../molecules";
import RelatedTaskItems from "./RelatedTaskItems";

type Props = {
  companyPlan: string;
  projectId: string;
  batchId: string;
  hypothesisFormId: string;
};
const ProjectCheckPoints = (props: Props) => {
  const { companyPlan, projectId, batchId, hypothesisFormId } = props;
  const context = useContext(ContentContext);

  const { apiRequest } = context;
  const [items, setItems] = useState([]);
  const [showTasks, setShowTasks] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const dispatch = useDispatch();

  // @ts-expect-error TS2339
  const projectPayload = useSelector((state) => state.projects.projectPayload);

  useEffect(() => {
    onGetCheckPointsAndTasks();
  }, [projectId, batchId, hypothesisFormId]);

  const onShowAll = useCallback(() => {
    setShowAll((showAll) => !showAll);
  }, []);

  const onGetCheckPointsAndTasks = useCallback(() => {
    const requestBuilder = {
      method: "getRequest",
      url: parseEndpoint(endpoints.projectBatchHypothesisCheckpoints, {
        projectId,
        batchId,
        hypothesisFormId,
      }),
      data: {},
    };
    // @ts-expect-error TS2345
    apiRequest(requestBuilder, ({ data }) => {
      // @ts-expect-error TS2339
      if (data && data.length > 0) {
        // @ts-expect-error TS2339
        const dataItems = data.filter(
          // @ts-expect-error TS7006
          (item) => item.checkpointFormMetadata.displayCheckpoint,
        );
        setItems(dataItems);
      }
    });
  }, [projectId, batchId, hypothesisFormId]);

  const onUpdateProjectCheckPoint = useCallback(
    (checkpointFormId, updateData) => {
      const requestBuilder = {
        method: "putRequest",
        url: parseEndpoint(endpoints.projectBatchHypothesisCheckpoint, {
          projectId,
          batchId,
          checkpointFormId,
        }),
        data: updateData,
      };
      // @ts-expect-error TS2345
      apiRequest(requestBuilder, ({ data }) => {
        onGetCheckPointsAndTasks();
        // @ts-expect-error TS2339
        const { checkpointCoverageRate } = data;
        dispatch(
          // @ts-expect-error TS2554
          ProjectActions.projectGetSuccess({
            ...projectPayload,
            checkpointCoverageRate,
          }),
        );
      });
    },
    [projectId, batchId],
  );

  return (
    <div
      style={{
        background: items && items.length > 0 ? "#FAFAFA" : "#FFF",
        padding: 12,
      }}
    >
      {items &&
        items.length > 0 &&
        (showAll ? items : items.slice(0, 4)).map((item) => (
          <>
            <ProjectCheckpointItem
              disabled={false}
              // @ts-expect-error TS2339
              key={item.id}
              // @ts-expect-error TS2339
              item={item.CheckPointForms}
              companyPlan={companyPlan}
              checked={
                // @ts-expect-error TS2339
                item.ProjectCheckPoint
                  ? // @ts-expect-error TS2339
                    item.ProjectCheckPoint.isChecked
                  : false
              }
              onChecked={(isChecked) => {
                // @ts-expect-error TS2339
                onUpdateProjectCheckPoint(item.CheckPointForms.id, {
                  isChecked,
                });
              }}
              tasks={
                // @ts-expect-error TS2339
                item.ProjectCheckPoint
                  ? // @ts-expect-error TS2339
                    item.ProjectCheckPoint.relatedTaskList
                  : []
              }
              // @ts-expect-error TS2339
              showTask={!!showTasks.find((id) => id == item.id)}
              onShowTask={() => {
                let cloneShowTasks = [...showTasks];
                // @ts-expect-error TS2339
                if (showTasks.find((id) => id == item.id)) {
                  cloneShowTasks = cloneShowTasks.filter(
                    // @ts-expect-error TS2339
                    (id) => id !== item.id,
                  );
                } else {
                  // @ts-expect-error TS2345
                  cloneShowTasks.push(item.id);
                }
                setShowTasks(cloneShowTasks);
              }}
            />
            {companyPlan !== "BASIC" &&
              // @ts-expect-error TS2339
              showTasks.find((id) => id == item.id) && (
                <>
                  <RelatedTaskItems
                    projectId={projectId}
                    disabled={false}
                    tasks={
                      // @ts-expect-error TS2339
                      item.ProjectCheckPoint
                        ? // @ts-expect-error TS2339
                          item.ProjectCheckPoint.relatedTaskList
                        : []
                    }
                    onUpdateTaskList={(relatedTaskList) => {
                      // @ts-expect-error TS2339
                      onUpdateProjectCheckPoint(item.CheckPointForms.id, {
                        relatedTaskList,
                      });
                    }}
                  />
                </>
              )}
          </>
        ))}
      {items && items.length > 4 && (
        <Row>
          <div onClick={onShowAll}>
            {!showAll ? IconDownArrow : IconUpArrow}{" "}
            <span>
              {showAll ? (
                <FormattedMessage id="screen.label.hide_related_tasks" />
              ) : (
                <FormattedMessage id="screen.label.show_related_tasks" />
              )}
            </span>
          </div>
        </Row>
      )}
    </div>
  );
};

export default ProjectCheckPoints;
