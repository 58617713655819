import { useCallback } from "react";

import { NoDataLabel } from "@/shared/label/NoDataLabel";

import { ReviewInfoGridHeader } from "../ReviewInfoGridHeader";
import { ReviewInfoRow } from "../ReviewInfoRow";
import { checkHasPublishableReviewInfos, checkIsAllChecked } from "./helper";
import styles from "./styles.module.scss";
import { ReviewInfoGridProps } from "./type";

export const ReviewInfoGrid = ({
  reviewInfos,
  rowSelection,
  width,
  toggleAllDisplayedPublishTargets,
  onCheck,
}: ReviewInfoGridProps) => {
  const hasPublishableReviewInfos = checkHasPublishableReviewInfos(reviewInfos);
  const isAllChecked = hasPublishableReviewInfos
    ? checkIsAllChecked(reviewInfos, rowSelection)
    : false;

  const onCheckAll = useCallback(() => {
    toggleAllDisplayedPublishTargets(isAllChecked);
  }, [toggleAllDisplayedPublishTargets, isAllChecked]);

  return (
    <div style={{ width: width }}>
      <ReviewInfoGridHeader
        disabled={!hasPublishableReviewInfos}
        checked={isAllChecked}
        onCheckAll={onCheckAll}
      />
      {reviewInfos.length > 0 ? (
        reviewInfos.map((reviewInfo) => (
          <ReviewInfoRow
            key={reviewInfo.uuid}
            rowSelection={rowSelection}
            reviewInfo={reviewInfo}
            onCheck={onCheck}
          />
        ))
      ) : (
        <div className={styles.no_data_label_container}>
          <NoDataLabel />
        </div>
      )}
    </div>
  );
};
