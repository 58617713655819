import { EditFilled } from "@ant-design/icons";
import { Button, Col, Form, Input, Row, Select } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";

import { RangePicker } from "../../Components/molecules";
import { batchStatus } from "../../utils/batchUtils";
import { dateFormat } from "../../utils/constants";
import { SingleBatchItemProps } from "./types/SingleBatchItemProps";
const { Option } = Select;
const { TextArea } = Input;

const SingleBatchItem: React.FC<SingleBatchItemProps> = function ({
  batch,
  onSaveBatch,
  openNewBatchModal,
  isBatchesSortable,
}: SingleBatchItemProps) {
  const intl = useIntl();
  const [isEditingMode, setIsEditingMode] = useState(false);
  const [localBatch, setLocalBatch] = useState(null);
  useEffect(() => {
    setLocalBatch(batch);
  }, [batch]);
  const _renderBatchName = () => {
    if (isEditingMode) {
      return (
        <Input
          // @ts-expect-error TS18047
          value={localBatch.name}
          // @ts-expect-error TS18047
          defaultValue={localBatch.name}
          onChange={(e) => {
            setLocalBatch({
              // @ts-expect-error TS2698
              ...localBatch,
              name: e.target.value,
            });
          }}
        />
      );
    }
    // @ts-expect-error TS18047
    return <h4>{localBatch.name}</h4>;
  };
  const _renderDescriptionBox = () => {
    return (
      <TextArea
        onChange={(e) => {
          setLocalBatch({
            // @ts-expect-error TS2698
            ...localBatch,
            description: e.target.value,
          });
          setIsEditingMode(true);
        }}
        disabled={false}
        // @ts-expect-error TS18047
        value={localBatch.description}
        rows={2}
      />
    );
  };
  const _renderEditBatchNameButton = () => {
    if (!isEditingMode) {
      return (
        <Button
          onClick={() => {
            setIsEditingMode(true);
          }}
          type="ghost"
          icon={<EditFilled />}
        />
      );
    }
  };
  if (localBatch !== null) {
    return (
      <div
        className={isBatchesSortable ? "noselect" : ""}
        style={{
          marginBottom: "1vh",
          marginTop: "3vh",
          borderWidth: 1,
          borderColor: isBatchesSortable ? "#BDBDBD" : "#e9e9e9",
          borderRadius: "8px",
          padding: "1rem",
          borderStyle: isBatchesSortable ? "dashed" : "solid",
          cursor: isBatchesSortable ? "row-resize" : "default",
          backgroundColor: "white",
        }}
      >
        <div>
          <Row gutter={[12, 12]}>
            <Col>{_renderBatchName()}</Col>
            <Col>{_renderEditBatchNameButton()}</Col>
          </Row>
        </div>
        <div style={{ marginTop: "0.5vh", marginBottom: "0.5vh" }}>
          <div style={{ marginTop: "1vh", marginBottom: "1vh" }}>
            <Row>
              <Col span={24}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  {/*
                   // @ts-expect-error TS2339 */}
                  <Link to={`/dashboard/batch/${localBatch.id}/hypothesis-mgt`}>
                    <Button
                      style={{
                        marginRight: "0.3vw",
                      }}
                      type={"ghost"}
                    >
                      <FormattedMessage id="screens.company.hypothesis_mgt" />
                    </Button>
                  </Link>
                  {/*
                   // @ts-expect-error TS2339 */}
                  <Link to={`/dashboard/batch/${localBatch.id}/milestone-mgt`}>
                    <Button
                      style={{
                        marginRight: "0.3vw",
                      }}
                      type={"ghost"}
                    >
                      <FormattedMessage id="screens.company.milestone_mgt" />
                    </Button>
                  </Link>
                  <Link
                    // @ts-expect-error TS2339
                    to={`/dashboard/batch/${localBatch.id}/examination-mgt`}
                  >
                    <Button
                      style={{
                        marginRight: "0.3vw",
                      }}
                      type={"ghost"}
                    >
                      <FormattedMessage id="screen.users.examination_score_mgt" />
                    </Button>
                  </Link>
                  <Link
                    // @ts-expect-error TS2339
                    to={`/dashboard/batch/${localBatch.id}/examination-phases`}
                  >
                    <Button
                      style={{
                        marginRight: "0.3vw",
                      }}
                      type={"ghost"}
                    >
                      <FormattedMessage id="screen.users.examination_phases_management" />
                    </Button>
                  </Link>
                </div>
              </Col>
            </Row>
          </div>
          <Form layout={"inline"}>
            <Row>
              <Col span={24}>
                <br />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Form.Item
                    label={<FormattedMessage id="screens.label.batch_period" />}
                  >
                    <RangePicker
                      // @ts-expect-error TS2339
                      containerName={`batch-item-${localBatch.id}`}
                      format={dateFormat}
                      onChange={(selectedTimes) => {
                        if (selectedTimes && selectedTimes.length > 0) {
                          setLocalBatch({
                            // @ts-expect-error TS2698
                            ...localBatch,
                            from: selectedTimes[0]?.local(),
                            to: selectedTimes[1]?.local(),
                          });
                        }
                        setIsEditingMode(true);
                      }}
                      defaultValue={[
                        // @ts-expect-error TS2322
                        localBatch.from
                          ? // @ts-expect-error TS2339
                            moment(localBatch.from).local()
                          : null,
                        // @ts-expect-error TS2322
                        localBatch.to ? moment(localBatch.to).local() : null,
                      ]}
                    />
                  </Form.Item>

                  <Form.Item
                    label={
                      <FormattedMessage id="screens.company.batch_status_options" />
                    }
                  >
                    <Select
                      onChange={(value) => {
                        setLocalBatch({
                          // @ts-expect-error TS2698
                          ...localBatch,
                          status: value,
                        });
                        setIsEditingMode(true);
                      }}
                      style={{ width: "16vw" }}
                      // @ts-expect-error TS2339
                      value={localBatch.status}
                      // @ts-expect-error TS2339
                      defaultValue={localBatch.status}
                    >
                      {batchStatus.map(({ key, value }) => {
                        return (
                          <Option key={key} value={key}>
                            {intl.formatMessage({ id: value })}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </div>
              </Col>
              <Col span={24}>
                <br />
                <Form.Item
                  label={<FormattedMessage id="screen.label.description" />}
                >
                  {_renderDescriptionBox()}
                </Form.Item>
              </Col>
            </Row>
          </Form>
          {isEditingMode && (
            // @ts-expect-error TS2322
            <Row align="end" style={{ width: "40vw", marginTop: 10 }}>
              <Button
                type="primary"
                size="small"
                onClick={() => {
                  setIsEditingMode(false);
                  // @ts-expect-error TS2722
                  onSaveBatch(localBatch);
                }}
                style={{ float: "right", fontSize: 14 }}
              >
                <FormattedMessage id="screens.title.save" />
              </Button>
              <Button
                size="small"
                onClick={() => {
                  setIsEditingMode(false);
                  setLocalBatch(batch);
                }}
                style={{
                  float: "right",
                  marginLeft: 10,
                  fontSize: 14,
                }}
              >
                <FormattedMessage id="screen.label.cancel" />
              </Button>
            </Row>
          )}
          <Row>
            <Col>
              <br />
              <Button
                onClick={() => {
                  // @ts-expect-error TS2700
                  const { id, ord, seqid, ...rest } = localBatch;
                  rest.referenceBatchId = id;
                  // @ts-expect-error TS2722
                  openNewBatchModal(rest);
                }}
                type={"ghost"}
              >
                <FormattedMessage id="screen.company.copy_batch_settings" />
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
  return <></>;
};
export default SingleBatchItem;
