import { forwardRef, Ref } from "react";

import styles from "./styles.module.scss";
import { RadioButtonProps } from "./type";

export const RadioButton = forwardRef(
  ({ label, ...inputProps }: RadioButtonProps, ref?: Ref<HTMLInputElement>) => {
    return (
      <label className={styles.event_type_radio_button}>
        <input ref={ref} type="radio" {...inputProps} />
        {label}
      </label>
    );
  },
);
