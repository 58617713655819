import "react-datepicker/dist/react-datepicker.css";

import enUS from "date-fns/locale/en-US";
import ja from "date-fns/locale/ja";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import { useIntl } from "react-intl";

import {
  CustomCalendarContainer,
  CustomHeader,
  CustomInput,
} from "./customComponents";
import styles from "./styles.module.scss";
import { DatePickerProps } from "./type";

registerLocale("ja", ja);
registerLocale("en", enUS);

export const DatePicker = ({
  className,
  value,
  onChange,
  onBlur,
}: DatePickerProps) => {
  const intl = useIntl();
  return (
    <ReactDatePicker
      selected={value && new Date(value)}
      onChange={onChange}
      onBlur={onBlur}
      locale={intl.locale}
      dateFormat="yyyy/MM/dd"
      calendarClassName={styles.calendar}
      customInput={<CustomInput className={className} />}
      renderCustomHeader={(props) => <CustomHeader {...props} />}
      calendarContainer={CustomCalendarContainer}
    />
  );
};
