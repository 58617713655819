import { CloseOutlined } from "@ant-design/icons";
import { FC, useContext } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import {
  CloseOutlineComponent,
  CountUnreadComments,
} from "@/Components/molecules/project/ProjectTopScreen";
import { useParamId } from "@/hooks/useParamId";
import { ContentContext } from "@/Provider";
import { ApiRequestData } from "@/Redux/ApiRedux";
import CommentActions from "@/Redux/CommentRedux";
import ProjectActions from "@/Redux/ProjectRedux";
import { ReduxState } from "@/Redux/types";
import { endpoints, parseEndpoint } from "@/Services/endpoints";

type ProjectNotificationBoxProps = {
  scrollToCommentTop: () => void;
};

export const ProjectNotificationBox: FC<ProjectNotificationBoxProps> = ({
  scrollToCommentTop,
}) => {
  const dispatch = useDispatch();
  const { apiRequest } = useContext(ContentContext);
  const projectId = useParamId();

  const project = useSelector(
    (state: ReduxState) => state.projects.projectPayload,
  );

  const numberOfUnreadComments = project.numberOfUnreadComments;

  const dispatchGetProjectDetail = () =>
    dispatch(
      ProjectActions.projectGetRequest(
        // @ts-expect-error TS2554
        projectId,
      ),
    );

  const dispatchGetComments = () =>
    dispatch(
      CommentActions.commentGetsRequest(
        // @ts-expect-error TS2554
        {
          projectId,
          page: 0,
        },
      ),
    );

  const deleteUnreadCount = () => {
    const requestBuilder: ApiRequestData = {
      method: "deleteRequest",
      url: parseEndpoint(endpoints.getProjectCommentCount, {
        projectId,
      }),
      data: {},
    };
    apiRequest(requestBuilder, () => {
      dispatchGetProjectDetail();
      dispatchGetComments();
    });
  };

  if (!numberOfUnreadComments) return null;
  return (
    <div className="notification-title" style={{ marginBottom: 24 }}>
      <CountUnreadComments onClick={() => scrollToCommentTop()}>
        <FormattedMessage
          id="screen.label.new_comments_count"
          values={{ count: numberOfUnreadComments }}
        />
      </CountUnreadComments>
      <CloseOutlineComponent onClick={() => deleteUnreadCount()}>
        <CloseOutlined />
      </CloseOutlineComponent>
    </div>
  );
};
