import { useCallback, useState } from "react";

import { ReviewSortField } from "../types/ReviewSortField";

export const useReviewSortManager = () => {
  const INITIAL_SORT_FIELD: ReviewSortField = "createdAt";
  const [sortField, setSortFields] =
    useState<ReviewSortField>(INITIAL_SORT_FIELD);

  const sortOrder = sortField === "createdAt" ? "desc" : "asc";
  const onChangeSortField = useCallback((sortField: ReviewSortField) => {
    setSortFields(sortField);
  }, []);

  return {
    sortField,
    sortOrder,
    onChangeSortField,
  };
};
