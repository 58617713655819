import { CheckIcon } from "@/shared/icons/components/CheckIcon";
import styles from "@/shared/selectbox/components/SingleSelectOptionItem/styles.module.scss";
import { SingleSelectOptionItemProps } from "@/shared/selectbox/components/SingleSelectOptionItem/type";

export const SingleSelectOptionItem = <OptionValue,>({
  onSelect,
  ...props
}: SingleSelectOptionItemProps<OptionValue>) => {
  const [selectedValue] = props.getValue();
  const checked = props.data.value === selectedValue?.value;
  const checkIconClassName = `${checked ? "" : styles.checkbox_unchecked}`;
  const checkIconLabelClassName = `${styles.button_label} ${
    checked ? styles.button_label_checked : ""
  }`;
  return (
    <button
      className={styles.list_item_button}
      onClick={() => {
        onSelect(props.data);
      }}
    >
      <CheckIcon color="blue" className={checkIconClassName} />
      <span className={checkIconLabelClassName}>{props.data.label}</span>
    </button>
  );
};
