import { useIntl } from "react-intl";
import { Link } from "react-router-dom";

import styles from "@/features/passwordReset/components/PasswordResetPageLink/styles.module.scss";

export const PasswordResetPageLink = () => {
  const intl = useIntl();
  const label = intl.formatMessage({
    id: "screen.label.if_you_forget_your_password",
  });
  return (
    <Link
      to="/forgot"
      className={styles.link}
      data-testid="password-reset-page-link"
    >
      {label}
    </Link>
  );
};
