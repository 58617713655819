import { arrayMoveImmutable } from "array-move";
import { useEffect, useRef } from "react";
import { useIntl } from "react-intl";

import { endpoints, parseEndpoint } from "../../../Services/endpoints";
import { useStateWithRef } from "../../../utils/hooks";
import { getInitStorageValue } from "../../../utils/localStorage";
import {
  IMgtRecommendedVideosScreenProps,
  MgtRecommendedVideosScreenState,
} from "../types/IMgtRecommendedVideosScreenProps";

const PageSaveId = "MgtRecommendedVideosScreen";
const pageKey = `pagination_page_${PageSaveId}`;
const limitKey = `pagination_limit_${PageSaveId}`;

const R = require("ramda");
const useMgtRecommendedVideosScreen = (
  props: IMgtRecommendedVideosScreenProps,
) => {
  const intl = useIntl();
  const { apiRequest, updatePageTitle } = props.context;
  const [state, customSetState, stateRef] = useStateWithRef(() => {
    return {
      page: getInitStorageValue(pageKey, 1),
      limit: getInitStorageValue(limitKey, process.env.REACT_APP_PAGE_SIZE),
      videos: {
        rows: [],
        count: 0,
      },
      canRearrange: false,
    };
  });

  useEffect(() => {
    updatePageTitle("screen.label.video");
    applyFilter();
  }, []);

  const applyFilter = () => {
    const { page, limit } = stateRef.current;
    const requestBuilder = {
      method: "getRequest",
      url: parseEndpoint(endpoints.filterRecommendation, {
        page: page - 1,
        limit,
      }),
      data: {},
    };
    // @ts-expect-error TS2345
    apiRequest(requestBuilder, ({ data }) => {
      setState({
        videos: data,
      });
    });
  };
  const setStateCallbackRef = useRef(() => {});
  useEffect(() => {
    const callBack = setStateCallbackRef.current;
    callBack();
    setStateCallbackRef.current = () => {};
  }, [state]);
  const setState = (
    data: MgtRecommendedVideosScreenState,
    callback = () => {},
  ) => {
    setStateCallbackRef.current = callback;
    // @ts-expect-error TS7006
    customSetState((previousState) => {
      return {
        ...previousState,
        ...data,
      };
    });
  };

  const getDragProps = () => {
    return {
      // @ts-expect-error TS7006
      onDragEnd: (fromIndex, toIndex) => {
        const { videos } = state;
        const { page = 1, limit } = stateRef.current;

        const updatedFromIdx = fromIndex + (page - 1) * limit;
        const updatedToIdx = toIndex + (page - 1) * limit;

        const rows = [...videos.rows];

        const item = videos.rows[updatedFromIdx];

        rows.splice(updatedToIdx - 1, 0, item);
        const newOrder = arrayMoveImmutable(
          R.clone(videos.rows),
          updatedFromIdx,
          updatedToIdx,
        );

        const parsedRows = newOrder
          .filter((v) => typeof v !== "undefined")
          // @ts-expect-error TS18046
          .map((v) => v.id);
        updateVideoRecommendationOrder(parsedRows);
      },
      handleSelector: ".drag-handle",
    };
  };
  // @ts-expect-error TS7006
  const updateVideoRecommendationOrder = (viderOrder) => {
    const requestBuilder = {
      method: "putRequest",
      url: parseEndpoint(endpoints.reorderRecommendation),
      data: {
        videoOrder: viderOrder,
      },
      successMessage: intl.formatMessage({
        id: "screen.label.video_update",
      }),
    };
    // @ts-expect-error TS2345
    apiRequest(requestBuilder, ({}) => {
      applyFilter();
    });
  };
  // @ts-expect-error TS7006
  const onChangeRecommendedCheckBox = (checked, video) => {
    const requestType = checked ? "postRequest" : "deleteRequest";
    const url = checked
      ? parseEndpoint(endpoints.recommendations)
      : parseEndpoint(endpoints.removeRecommendation, {
          videoId: video.id,
        });
    const requestBuilder = {
      method: requestType,
      url,
      data: {
        videoId: video.id,
      },
      successMessage: intl.formatMessage({
        id: "screen.label.video_update",
      }),
    };
    // @ts-expect-error TS2345
    apiRequest(requestBuilder, ({}) => {
      applyFilter();
    });
  };

  return {
    intl,
    state: stateRef.current,
    setState,
    getDragProps,
    onChangeRecommendedCheckBox,
    applyFilter,
  };
};

export default useMgtRecommendedVideosScreen;
