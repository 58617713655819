import { api } from "../../Saga";
import { NullResponse } from "../commonResponsTypes";
import { endpoints, parseEndpoint } from "../endpoints";
import { IsExaminationPhaseEnabled } from "./type";

export const toggleIsExaminationPhaseEnabled = async (
  batchId: string,
  isExaminationPhaseEnabled: boolean,
) => {
  const url = parseEndpoint(endpoints.toggleIsUseExainationPhaseEnabled, {
    batchId,
  });
  const response = await api.patchRequest<
    IsExaminationPhaseEnabled,
    NullResponse
  >(url, {
    isExaminationPhaseEnabled,
  });
  return response;
};
