import { FC, memo } from "react";

import { usePageViewAnalytics } from "../../hooks/usePageViewAnalytics";

type TrackingWrapperProps = {
  children: React.ReactNode;
};

const TrackingWrapperBody: FC<TrackingWrapperProps> = ({ children }) => {
  // track page view
  usePageViewAnalytics();
  return <>{children}</>;
};

export const TrackingWrapper = memo(TrackingWrapperBody);
