import da from "./da";
import de from "./de";
import en from "./en";
import es from "./es";
import fr from "./fr";
import it from "./it";
import ja from "./ja";
import ko from "./ko";
import nl from "./nl";
import pl from "./pl";
import pt from "./pt";
import ru from "./ru";
import zh from "./zh";
import zh_tw from "./zh_tw";

export default {
  en,
  fr,
  zh,
  ru,
  pt,
  ko,
  it,
  nl,
  de,
  da,
  zh_tw,
  pl,
  es,
  ja,
};
