import moment from "moment";
import { FormattedMessage } from "react-intl";

import { checkDeadlinePassed } from "@/features/event/helpers/checkDeadlinePassed";

export const getEventStatus = (event) => {
  if (event.type === "first-come-first-serve") {
    return getFCFSEventStatus(event);
  }
  return lotteryEventStatus(event);
};

export const getUserEventApplicationStatus = (event, userId) => {
  if (event.type === "first-come-first-serve") {
    return getFCFSEventUserApplicationStatus(event, userId);
  }
  return lotteryEventUserApplicationStatus(event, userId);
};

const lotteryEventUserApplicationStatus = (event, userId) => {
  const eventMembers = Array.isArray(event.EventMembers)
    ? event.EventMembers
    : [];

  const userApplications = eventMembers.filter((ev) => ev.userId === userId);

  const isCurrentUserApplied = userApplications.length > 0;

  if (isCurrentUserApplied) {
    const currentUserParticipation = userApplications[0].canAttendState;
    if (currentUserParticipation === "undecided") {
      return (
        <FormattedMessage id="screens.events.user_participation_undecided" />
      );
    }
    if (currentUserParticipation === "decide_to_participate") {
      return (
        <FormattedMessage id="screens.events.user_participation_decide_to_participate" />
      );
    }
    if (currentUserParticipation === "decide_not_to_participate") {
      return <FormattedMessage id="screens.events.participation_cancelled" />;
    }
  }
  return <FormattedMessage id="screens.events.user_participation_un_applied" />;
};

const getFCFSEventUserApplicationStatus = (event, userId) => {
  const eventMembers = Array.isArray(event.EventMembers)
    ? event.EventMembers
    : [];

  const userApplications = eventMembers.filter((ev) => ev.userId === userId);

  const isCurrentUserApplied = userApplications.length > 0;
  if (isCurrentUserApplied) {
    const currentUserParticipation = userApplications[0].canAttendState;
    const appliedAppliedAfterLimit =
      userApplications[0].appliedAppliedAfterLimit;

    if (
      currentUserParticipation === "decide_to_participate" &&
      !appliedAppliedAfterLimit
    ) {
      return (
        <FormattedMessage id="screens.events.user_participation_decide_to_participate_early" />
      );
    }
    if (currentUserParticipation === "undecided" && appliedAppliedAfterLimit) {
      return (
        <FormattedMessage id="screens.events.user_participation_decide_to_participate_late" />
      );
    }
    if (currentUserParticipation === "decide_not_to_participate") {
      return <FormattedMessage id="screens.events.participation_cancelled" />;
    }
  }
  return <FormattedMessage id="screens.events.user_participation_un_applied" />;
};

const lotteryEventStatus = (event) => {
  const isPassedDeadline = checkDeadlinePassed(event.applicationDeadline);

  let hasEventHeld = true;

  if (event.endsAt) {
    hasEventHeld = !moment(event.endsAt).isAfter(moment());
  }

  const isAcceptingApplications =
    event && event.isAcceptingMembers && !isPassedDeadline;

  if (hasEventHeld) {
    return <FormattedMessage id="screens.events.end_of_event" />;
  }

  if (!isPassedDeadline && isAcceptingApplications) {
    return <FormattedMessage id="screens.events.accepting_applications" />;
  }
  if (isPassedDeadline && !isAcceptingApplications && !hasEventHeld) {
    return <FormattedMessage id="screens.events.deadline_and_holding" />;
  }
  if (!event.isAcceptingMembers) {
    return <FormattedMessage id="screens.events.deadline_and_holding" />;
  }
  return "unknown";
};

const getFCFSEventStatus = (event) => {
  const isCapacityFull = event.EventMembers.length >= event.capacity;
  const isPassedDeadline = checkDeadlinePassed(event.applicationDeadline);
  let hasEventHeld = true;

  if (event.endsAt) {
    hasEventHeld = !moment(event.endsAt).isAfter(moment());
  }

  const isAcceptingApplications =
    event && event.isAcceptingMembers && !isPassedDeadline;

  if (hasEventHeld) {
    return <FormattedMessage id="screens.events.end_of_event" />;
  }

  if (!isPassedDeadline && isAcceptingApplications && isCapacityFull) {
    return <FormattedMessage id="screens.events.full_waiting_cancellation" />;
  }

  if (!isPassedDeadline && isAcceptingApplications) {
    return <FormattedMessage id={"screens.events.accepting_applications"} />;
  }
  if (isPassedDeadline && !isAcceptingApplications && !hasEventHeld) {
    return <FormattedMessage id={"screens.events.deadline_and_holding"} />;
  }
  if (!event.isAcceptingMembers) {
    return <FormattedMessage id="screens.events.deadline_and_holding" />;
  }
  return "unknown";
};
