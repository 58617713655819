import { Button } from "antd";
import { FormattedMessage } from "react-intl";

import { useProjectWatchContext } from "../../contexts/ProjectWatchContext";

export const ProjectWatchButton = () => {
  const { isProjectWatch, toggleProjectWatch, disableWatch } =
    useProjectWatchContext();

  if (disableWatch) return null;
  return (
    <Button
      style={{
        marginLeft: 10,
        color: "#1F86E9",
        border: "1px solid #1F86E9",
      }}
      onClick={() => toggleProjectWatch()}
    >
      {isProjectWatch ? (
        <FormattedMessage id="screen.label.watch_cancel" />
      ) : (
        <FormattedMessage id="screen.label.project_watch" />
      )}
    </Button>
  );
};
