import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Col, Modal, Row } from "antd";
import { Location } from "history";
import { FC, useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { connect } from "react-redux";
import { Prompt, useHistory } from "react-router-dom";

import { ProjectInvitationAcceptBox } from "@/features/project/components/ProjectInvitationAcceptBox";
import { ProjectNotificationBox } from "@/features/project/components/ProjectNotificationBox";
import { ProjectReminderBox } from "@/features/project/components/ProjectReminderBox";
import { ProjectTopCard } from "@/features/project/components/ProjectTopCard";
import { ProjectTopComponentTopArea } from "@/features/project/components/ProjectTopComponentTopArea";
import { ProjectWatchModal } from "@/features/project/components/ProjectWatchModal";
import { ProjectWatchContextProvider } from "@/features/project/contexts/ProjectWatchContext";
import { useUserTeamStatus } from "@/features/project/hooks/useUserTeamStatus";
import { ProjectCommentArea } from "@/features/projectComment/component/ProjectCommentArea";
import { PROJECT_COMMENT_AREA_ID } from "@/features/projectComment/component/ProjectCommentArea/component";
import { ProjectHypothesisItem } from "@/features/projectHypothesis/ProjectHypothesisItem";
import { useParamId } from "@/hooks/useParamId";
import { ApiRequestData } from "@/Redux/ApiRedux";
import { ReduxDispatch, ReduxState } from "@/Redux/types";

import { BatchHypothesis } from "../../../types/BatchHypothesis";
import { ProjectHypothesis } from "../../../types/ProjectHypothesis";
import { Button, Card, Tag } from "../../Components/atoms";
import { ProjectDisplayCheckpoint } from "../../Components/molecules";
import CommentActions from "../../Redux/CommentRedux";
import ProjectActions from "../../Redux/ProjectRedux";
import TeamActions from "../../Redux/TeamRedux";
import { endpoints, parseEndpoint } from "../../Services/endpoints";
import createFormData from "../../utils/createFormData";
import ProjectArchiveScreen from "../project/ProjectArchiveScreen";
import ProjectFileScreen from "../project/ProjectFileScreen";
import ProjectGeneralNoticeScreen from "../project/ProjectGeneralNoticeScreen";
import ProjectReviewScreen from "../project/ProjectReviewScreen";
import ProjectTasksScreen from "../project/ProjectTasksScreen";
import ProjectTeamScreen from "../project/ProjectTeamScreen";
import UUIDRoute from "../uuid-route/UUIDRoute";
import { IProjectTopScreenProps } from "./types/IProjectTopScreenProps";

const ProjectTopScreen: FC<IProjectTopScreenProps> = ({
  project,
  context,
  dispatchGetComments,
  dispatchGetProjectDetail,
  dispatchFetchUsers,
  dispatchUpdateProjectDetail,
}) => {
  const intl = useIntl();
  const history = useHistory();
  const projectUuid = useParamId();

  const { updatePageTitle, apiRequest, user } = context;

  const { isOwner, isTeamMember } = useUserTeamStatus({
    user,
    team: [...project.Team],
    project,
  });

  const [detailHypothesisVisible, setDetailHypothesisVisible] = useState(false);
  const [batchHypotheses, setBatchHypotheses] = useState<BatchHypothesis[]>([]);
  const [projectHypotheses, setProjectHypotheses] = useState<{
    [hypothesisFormId: string]: ProjectHypothesis;
  }>({});
  const [editingHypothesisFormIds, setEditingHypothesisFormIds] = useState<
    string[]
  >([]);
  const [batchId, setBatchId] = useState<string | null>(null);
  const [displayCheckpoint, setDisplayCheckpoint] = useState(true);

  const fetchAcceptedTeam = useCallback(() => {
    dispatchFetchUsers({
      page: 0,
      id: projectUuid,
      shouldPaginate: false,
    });
  }, [dispatchFetchUsers, projectUuid]);

  const getProjectInfo = useCallback(() => {
    const projectId = projectUuid;
    dispatchGetProjectDetail(projectId);
    dispatchGetComments({
      projectId,
      page: 0,
    });
    fetchAcceptedTeam();
  }, [
    dispatchGetComments,
    dispatchGetProjectDetail,
    projectUuid,
    fetchAcceptedTeam,
  ]);

  const getBatchHypothesis = useCallback(
    (batchId: string) => {
      const batchRequest: ApiRequestData = {
        method: "getRequest",
        url: parseEndpoint(endpoints.batchHypothesis, { batchId }),
      };
      apiRequest(batchRequest, ({ data }) => {
        // @ts-expect-error TS7053
        const batchHypotheses = [...data];

        setBatchHypotheses(batchHypotheses);
      });
    },
    [apiRequest],
  );

  const getProjectHypothesis = useCallback(
    (projectId: number) => {
      const batchRequest: ApiRequestData = {
        method: "getRequest",
        url: parseEndpoint(endpoints.singleProjectHypothesis, {
          projectId,
        }),
      };
      apiRequest(batchRequest, ({ data }) => {
        // @ts-expect-error TS7053
        const projectHypothesisData = [...data];
        const projectHypotheses = {};
        for (const item of projectHypothesisData) {
          // @ts-expect-error TS7053
          projectHypotheses[item.hypothesisFormId] = item;
        }
        setProjectHypotheses(projectHypotheses);
      });
    },
    [apiRequest],
  );

  const scrollToCommentTop = useCallback(() => {
    const commentArea = document.getElementById(PROJECT_COMMENT_AREA_ID);
    commentArea?.scrollIntoView({
      behavior: "smooth",
    });
  }, []);

  const addEditingHypothesisFormId = useCallback(
    (id: string) => {
      if (!isTeamMember) return;
      if (editingHypothesisFormIds.includes(id)) return;

      setEditingHypothesisFormIds([...editingHypothesisFormIds, id]);
    },
    [editingHypothesisFormIds, isTeamMember],
  );

  const removeEditingHypothesisFormId = useCallback(
    (id: string) => {
      if (!isTeamMember) return;

      setEditingHypothesisFormIds(
        editingHypothesisFormIds.filter((_id) => _id !== id),
      );
    },
    [editingHypothesisFormIds, isTeamMember],
  );

  const updateProjectHypothesis = useCallback(
    (hypothesisFormId: string, hypothesisFormMetadata: string) => {
      const projectId = project.id;

      const originHypothesis = projectHypotheses[hypothesisFormId];

      const newHypothesis = {
        ...originHypothesis,
        hypothesisFormMetadata,
      };

      setProjectHypotheses({
        ...projectHypotheses,
        [hypothesisFormId]: newHypothesis,
      });

      removeEditingHypothesisFormId(hypothesisFormId);

      const requestData = {
        projectId,
        hypothesisFormId,
        hypothesisFormMetadata,
      };

      const requestParam: ApiRequestData = originHypothesis
        ? {
            method: "putRequest",
            url: parseEndpoint(endpoints.updateSingleProjectHypothesis, {
              id: originHypothesis.id,
            }),
            data: requestData,
          }
        : {
            method: "postRequest",
            url: parseEndpoint(endpoints.projectHypothesis),
            data: requestData,
          };

      apiRequest(requestParam, () => {
        getProjectHypothesis(projectId);
        dispatchUpdateProjectDetail(
          {
            hypothesisFormId,
            hypothesisFormMetadata,
            batchId: batchId,
            shouldArchvie: true,
            uid: projectUuid,
            id: projectId,
          },
          intl.formatMessage({
            id: "screen.label.project_has_been_updated",
          }),
        );
      });
    },
    [
      apiRequest,
      batchId,
      dispatchUpdateProjectDetail,
      getProjectHypothesis,
      intl,
      project,
      projectHypotheses,
      projectUuid,
      removeEditingHypothesisFormId,
    ],
  );

  const shouldBlockNavigation = useCallback(
    () => editingHypothesisFormIds.length > 0,
    [editingHypothesisFormIds],
  );

  const isNotSelfReload = useCallback(
    (pathname: string) => {
      const curPathName = `/dashboard/project.top/${projectUuid}`;
      return pathname !== curPathName;
    },
    [projectUuid],
  );

  const handleUnsavedConfirmOk = useCallback(
    async (pathname: string) => {
      await setEditingHypothesisFormIds([]);
      history.push(pathname);
    },
    [history],
  );

  const handleBlockNavigationModal = useCallback(
    (location: Location) => {
      const showBlockNavigation = shouldBlockNavigation();
      const shouldBlockSelfReload = isNotSelfReload(location.pathname);
      let unsavedHypoNames = "";
      if (showBlockNavigation && shouldBlockSelfReload) {
        for (let i = 0; i < batchHypotheses.length; i++) {
          if (
            editingHypothesisFormIds.includes(
              batchHypotheses[i].hypothesisFormId,
            )
          ) {
            const hypoName = batchHypotheses[i].hypothesisFormMetadata
              ? batchHypotheses[i].hypothesisFormMetadata.hypothesisFormName
              : "";
            const separator = unsavedHypoNames == "" ? "" : " ,  ";
            unsavedHypoNames = `${unsavedHypoNames}${separator}${hypoName}`;
          }
        }
        Modal.confirm({
          title: intl.formatMessage({
            id: `screens.title.unsaved.setting`,
          }),
          icon: <ExclamationCircleOutlined />,
          content: `${unsavedHypoNames} ${intl.formatMessage({
            id: `screens.message.unsaved.setting`,
          })}`,
          okText: intl.formatMessage({
            id: "screen.label.yes",
          }),
          cancelText: intl.formatMessage({
            id: "screen.label.no",
          }),
          onOk: () => {
            handleUnsavedConfirmOk(location.pathname);
          },
        });
        return false;
      } else {
        return true;
      }
    },
    [
      batchHypotheses,
      editingHypothesisFormIds,
      handleUnsavedConfirmOk,
      intl,
      isNotSelfReload,
      shouldBlockNavigation,
    ],
  );

  const handleDisplayCheckpoint = (checkpoint: boolean) => {
    const updateProjectDisplayCheckpoint: ApiRequestData = {
      method: "putRequest",
      url: parseEndpoint(endpoints.updateProjectDetails, {
        uid: projectUuid,
      }),
      data: createFormData({
        displayCheckpoint: checkpoint,
        id: project.id,
      }),
    };
    apiRequest(updateProjectDisplayCheckpoint, () => {
      setDisplayCheckpoint(checkpoint);
    });
  };

  const canViewProjectHypothesis = !project.isPrivate || isTeamMember;

  useEffect(() => {
    updatePageTitle("screen.label.top");
  }, [updatePageTitle]);

  useEffect(() => {
    getProjectInfo();
  }, [getProjectInfo, projectUuid]);

  useEffect(() => {
    if (!project || !project.id) {
      // プロジェクトの取得完了まで何もしない
      return;
    }

    setDisplayCheckpoint(project.displayCheckpoint);
    if (project.batchId) {
      setBatchId(project.batchId);
      getBatchHypothesis(project.batchId);
    }
    getProjectHypothesis(project.id);
  }, [getBatchHypothesis, getProjectHypothesis, project]);

  useEffect(() => {
    if (shouldBlockNavigation()) {
      window.onbeforeunload = () => true;
    } else {
      // @ts-expect-error TS2322
      window.onbeforeunload = undefined;
    }
  }, [shouldBlockNavigation]);

  const renderTopContentComponent = () => {
    const { company } = context;
    const displayBatchHypotheses = batchHypotheses.filter(
      ({ hypothesisFormMetadata }) => hypothesisFormMetadata?.displayHypothesis,
    );
    const sortedDisplayBatchHypotheses = displayBatchHypotheses.sort(
      (a, b) => a.hypothesisFormMetadata.order - b.hypothesisFormMetadata.order,
    );
    const firstViewBatchHypotheses = sortedDisplayBatchHypotheses.filter(
      ({ hypothesisFormMetadata }) =>
        hypothesisFormMetadata.firstViewHypothesis,
    );
    const otherBatchHypotheses = sortedDisplayBatchHypotheses.filter(
      ({ hypothesisFormMetadata }) =>
        !hypothesisFormMetadata.firstViewHypothesis,
    );

    return (
      // TOPのタブ内コンテンツ
      <>
        <ProjectTopComponentTopArea stage={project.stage} isOwner={isOwner} />
        <Row>
          <Col span={24}>
            {canViewProjectHypothesis && (
              <>
                {company.isAllowCheckpoint &&
                  project.Batch?.displayCheckpoint && (
                    <Row align="middle" justify="end">
                      {/* チェック網羅率 */}
                      <Tag>
                        <FormattedMessage
                          id={"screen.label.project_list.check_coverage_rate"}
                        />
                        &nbsp;
                        {project.checkpointCoverageRate} %
                      </Tag>
                      {/* チェックポイント」 */}
                      <ProjectDisplayCheckpoint
                        displayCheckpoint={displayCheckpoint}
                        onChangeStatus={(displayCheckpoint) => {
                          handleDisplayCheckpoint(displayCheckpoint);
                        }}
                      />
                    </Row>
                  )}

                {/* 基本の項目 */}
                {firstViewBatchHypotheses.map((batchHypothesis) => (
                  <ProjectHypothesisItem
                    key={batchHypothesis.id}
                    batchHypothesis={batchHypothesis}
                    projectHypothesis={
                      projectHypotheses[batchHypothesis.hypothesisFormId]
                    }
                    displayCheckpoint={displayCheckpoint}
                    isEditing={editingHypothesisFormIds.includes(
                      batchHypothesis.hypothesisFormId,
                    )}
                    onStartEdit={addEditingHypothesisFormId}
                    onCancelEdit={removeEditingHypothesisFormId}
                    onSubmit={updateProjectHypothesis}
                  />
                ))}

                {/* その他の項目 */}
                {detailHypothesisVisible &&
                  otherBatchHypotheses.map((batchHypothesis) => (
                    <ProjectHypothesisItem
                      key={batchHypothesis.id}
                      batchHypothesis={batchHypothesis}
                      projectHypothesis={
                        projectHypotheses[batchHypothesis.hypothesisFormId]
                      }
                      displayCheckpoint={displayCheckpoint}
                      isEditing={editingHypothesisFormIds.includes(
                        batchHypothesis.hypothesisFormId,
                      )}
                      onStartEdit={addEditingHypothesisFormId}
                      onCancelEdit={removeEditingHypothesisFormId}
                      onSubmit={updateProjectHypothesis}
                    />
                  ))}

                <div className="project-detail-hypothesis">
                  {detailHypothesisVisible ? (
                    <Button
                      style={{ border: "1px solid #1F86E9", color: "#1F86E9" }}
                      onClick={() => setDetailHypothesisVisible(false)}
                    >
                      <FormattedMessage id="screen.label.close_other_items" />
                    </Button>
                  ) : (
                    <Button
                      style={{ border: "1px solid #1F86E9", color: "#1F86E9" }}
                      onClick={() => setDetailHypothesisVisible(true)}
                    >
                      <FormattedMessage id="screen.label.open_other_items" />
                    </Button>
                  )}
                </div>

                {/* コメント欄 */}
                <ProjectCommentArea scrollToCommentTop={scrollToCommentTop} />
              </>
            )}
          </Col>
        </Row>
      </>
    );
  };

  if (!project || project.id == 0) {
    return null;
  }

  return (
    <div className="project-top">
      <ProjectWatchContextProvider>
        <ProjectWatchModal />
        {/* 画面離脱時のモーダル */}
        <Prompt
          when={shouldBlockNavigation()}
          message={handleBlockNavigationModal}
        />

        {/* 新着コメント件数 */}
        <ProjectNotificationBox scrollToCommentTop={scrollToCommentTop} />

        {/* 招待承認コンポーネント */}
        <ProjectInvitationAcceptBox />

        {/* リマインダー */}
        <ProjectReminderBox />

        <ProjectTopCard />
        <br />

        {/* タブ内コンテンツ */}
        <Card>
          <UUIDRoute
            exact
            path="/dashboard/project.top/:id"
            subRouter="projects"
            renderContent={renderTopContentComponent()}
          />
          <UUIDRoute
            exact
            path="/dashboard/project.reviews/:id"
            subRouter="projects"
            component={ProjectReviewScreen}
          />
          <UUIDRoute
            exact
            path="/dashboard/project.file/:id"
            subRouter="projects"
            component={ProjectFileScreen}
          />
          <UUIDRoute
            exact
            path="/dashboard/project.team/:id"
            subRouter="projects"
            component={ProjectTeamScreen}
          />
          <UUIDRoute
            exact
            path="/dashboard/project.tasks/:id"
            subRouter="projects"
            component={ProjectTasksScreen}
          />
          <UUIDRoute
            exact
            path="/dashboard/project.archive/:id"
            subRouter="projects"
            component={ProjectArchiveScreen}
          />
          <UUIDRoute
            exact
            path="/dashboard/project.notices/:id"
            subRouter="projects"
            component={ProjectGeneralNoticeScreen}
          />
        </Card>
      </ProjectWatchContextProvider>
    </div>
  );
};
const mapStateToProps = (state: ReduxState) => ({
  project: state.projects.projectPayload,
});

const mapDispatchToProps = (
  dispatch: ReduxDispatch,
): Pick<
  IProjectTopScreenProps,
  | "dispatchFetchUsers"
  | "dispatchGetComments"
  | "dispatchGetProjectDetail"
  | "dispatchUpdateProjectDetail"
> => ({
  dispatchFetchUsers: (data) =>
    dispatch(
      TeamActions.teamGetRequest(
        // @ts-expect-error TS2554
        data,
      ),
    ),
  dispatchUpdateProjectDetail: (data, message) =>
    new Promise((resolve, reject) =>
      dispatch(
        ProjectActions.projectCreateRequest(
          // @ts-expect-error TS2554
          data,
          message,
          resolve,
          reject,
        ),
      ),
    ),
  dispatchGetProjectDetail: (id) =>
    dispatch(
      ProjectActions.projectGetRequest(
        // @ts-expect-error TS2554
        id,
      ),
    ),
  dispatchGetComments: (data) =>
    dispatch(
      CommentActions.commentGetsRequest(
        // @ts-expect-error TS2554
        data,
      ),
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectTopScreen);
