import { notification } from "antd";
import { push } from "connected-react-router";
import { all, call, put, takeLeading } from "redux-saga/effects";

import EventActions, { EventTypes } from "../Redux/EventRedux";
import { endpoints, parseEndpoint } from "../Services/endpoints";
import generateQueryParam from "../utils/generateQueryParam";

// @ts-expect-error TS7006
function* attemptDeleteEvent(api, action) {
  const { data, message } = action;
  // @ts-expect-error TS7057
  const eventResponse = yield call(
    api.deleteRequest,
    parseEndpoint(endpoints.deleteEvent, { data }),
  );

  if (eventResponse.ok) {
    switch (eventResponse.data.status) {
      case "success":
        // @ts-expect-error TS2554
        yield put(EventActions.eventGetsRequest(0, 10, true));
        yield put(EventActions.eventDeleteSuccess());
        notification.success({
          message: message,
        });
        yield put(push("/dashboard/events"));
        break;
      case "fail":
      case "error":
        if (typeof eventResponse.data.data === "string") {
        }
        // @ts-expect-error TS2554
        yield put(EventActions.eventDeleteFailure(eventResponse.data.data));
        break;
      default:
        break;
    }
  } else {
    // @ts-expect-error TS2554
    yield put(EventActions.eventDeleteFailure(eventResponse.problem));
    if (eventResponse.status === 401) {
      // @ts-expect-error TS2322
      window.location = "/login";
    }
  }
}

// @ts-expect-error TS7006
function* attemptCreateEvent(api, action) {
  const { data, message } = action;

  const isUpdate = Object.hasOwn(data, "id") && data.id > 0;

  const eventResponse = isUpdate
    ? // @ts-expect-error TS7057
      yield call(
        api.putRequest,
        parseEndpoint(endpoints.updateEvent, { data }),
        data,
      )
    : // @ts-expect-error TS7057
      yield call(api.postRequest, parseEndpoint(endpoints.createEvent), data);

  if (eventResponse.ok) {
    switch (eventResponse.data.status) {
      case "success":
        // @ts-expect-error TS2554
        yield put(EventActions.eventGetsRequest(0, 10, true));
        yield put(EventActions.eventCreateSuccess());
        notification.success({
          message: message,
        });
        yield put(push("/dashboard/events"));
        break;
      case "fail":
      case "error":
        if (typeof eventResponse.data.data === "string") {
        }
        // @ts-expect-error TS2554
        yield put(EventActions.eventCreateFailure(eventResponse.data.data));
        break;
      default:
        break;
    }
  } else {
    // @ts-expect-error TS2554
    yield put(EventActions.eventCreateFailure(eventResponse.problem));
    if (eventResponse.status === 401) {
      // @ts-expect-error TS2322
      window.location = "/login";
    }
  }
}

// @ts-expect-error TS7006
function* attemptGetEvent(api, { id }) {
  // @ts-expect-error TS7057
  const eventResponse = yield call(
    api.getRequest,
    parseEndpoint(endpoints.singleEvent, { id }),
  );
  if (eventResponse.ok) {
    switch (eventResponse.data.status) {
      case "success":
        // @ts-expect-error TS2554
        yield put(EventActions.eventGetSuccess(eventResponse.data.data));
        break;
      case "fail":
      case "error":
        if (typeof eventResponse.data.data === "string") {
        }
        // @ts-expect-error TS2554
        yield put(EventActions.eventGetFailure(eventResponse.data.data));
        break;
      default:
        break;
    }
  } else {
    notification.error({
      message: eventResponse.problem,
    });
    // @ts-expect-error TS2554
    yield put(EventActions.eventGetsFailure(eventResponse.problem));
    if (eventResponse.status === 401) {
      // @ts-expect-error TS2322
      window.location = "/login";
    }
  }
}

function* attemptGetAllEvent(
  // @ts-expect-error TS7006
  api,
  // @ts-expect-error TS7031
  { page = 0, limit = process.env.REACT_APP_PAGE_SIZE, includeDeleted },
) {
  let url = parseEndpoint(endpoints.events, {
    page,
    // @ts-expect-error TS2322
    limit,
  });
  url += includeDeleted ? "&includeDeleted=true" : "";

  // @ts-expect-error TS7057
  const eventResponse = yield call(api.getRequest, url);
  if (eventResponse.ok) {
    switch (eventResponse.data.status) {
      case "success":
        // @ts-expect-error TS2554
        yield put(EventActions.eventGetsSuccess(eventResponse.data.data));
        break;
      case "fail":
      case "error":
        if (typeof eventResponse.data.data === "string") {
        }
        // @ts-expect-error TS2554
        yield put(EventActions.eventGetsFailure(eventResponse.data.data));
        break;
      default:
        break;
    }
  } else {
    notification.error({
      message: eventResponse.problem,
    });
    // @ts-expect-error TS2554
    yield put(EventActions.eventGetsFailure(eventResponse.problem));
    if (eventResponse.status === 401) {
      // @ts-expect-error TS2322
      window.location = "/login";
    }
  }
}

// @ts-expect-error TS7006
function* attemptFetchEventUsers(api, { data }) {
  const queryParams = generateQueryParam(data);

  // @ts-expect-error TS7057
  const eventResponse = yield call(
    api.getRequest,
    parseEndpoint(endpoints.getEventUsers, { data, queryParams }),
  );
  if (eventResponse.ok) {
    switch (eventResponse.data.status) {
      case "success":
        // @ts-expect-error TS2554
        yield put(EventActions.eventGetMembersSuccess(eventResponse.data.data));
        break;
      case "fail":
      case "error":
        if (typeof eventResponse.data.data === "string") {
        }
        // @ts-expect-error TS2554
        yield put(EventActions.eventGetMembersFailure(eventResponse.data.data));
        break;
      default:
        break;
    }
  } else {
    notification.error({
      message: eventResponse.problem,
    });
    // @ts-expect-error TS2554
    yield put(EventActions.eventGetMembersFailure(eventResponse.problem));
    if (eventResponse.status === 401) {
      // @ts-expect-error TS2322
      window.location = "/login";
    }
  }
}

// @ts-expect-error TS7006
function* eventSaga(api) {
  yield all([
    takeLeading(EventTypes.EVENT_CREATE_REQUEST, attemptCreateEvent, api),
    takeLeading(EventTypes.EVENT_DELETE_REQUEST, attemptDeleteEvent, api),
    // @ts-expect-error TS2345
    takeLeading(EventTypes.EVENT_GETS_REQUEST, attemptGetAllEvent, api),
    // @ts-expect-error TS2345
    takeLeading(EventTypes.EVENT_GET_REQUEST, attemptGetEvent, api),
    takeLeading(
      // @ts-expect-error TS2345
      EventTypes.EVENT_GET_MEMBERS_REQUEST,
      attemptFetchEventUsers,
      api,
    ),
  ]);
}

export default eventSaga;
