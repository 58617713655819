import { useCallback } from "react";

import { PhaseTab } from "../PhaseTab/component";
import styles from "./styles.module.scss";
import { PhaseTabGroupProps } from "./type";

export const PhaseTabGroup = ({
  phases,
  onSelectPhase,
  selectedPhaseId,
}: PhaseTabGroupProps) => {
  const isSelected = useCallback(
    (phaseId: number) => phaseId === selectedPhaseId,
    [selectedPhaseId],
  );
  return (
    <div role="tablist" className={styles.phase_tab_group}>
      {phases.map((phase) => (
        <PhaseTab
          key={phase.id}
          phase={phase}
          isSelected={isSelected(phase.id)}
          onSelected={onSelectPhase}
        />
      ))}
    </div>
  );
};
