import { Controller, useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";

import { Heading3 } from "@/shared/headings/components/Heading3";
import { PlainInput } from "@/shared/inputs/components/PlainInput";
import { PlainSelect } from "@/shared/selectbox/components/PlainSelect";

import styles from "./styles.module.scss";

export const EventBasicInfoForm = () => {
  const { register, control } = useFormContext();

  const intl = useIntl();
  const basicInfoMessage = intl.formatMessage({
    id: "screens.events.basic_info",
  });
  const eventTitleMessage = intl.formatMessage({
    id: "screens.events.event_title",
  });
  const pleaseEnterMessage = intl.formatMessage({
    id: "screen.label.please_enter",
  });
  const locationTypeMessage = intl.formatMessage({
    id: "screens.events.location_type",
  });
  const selectLocationTypeMessage = intl.formatMessage({
    id: "screens.events.select_location_type",
  });
  const onlineMessage = intl.formatMessage({
    id: "screens.title.online",
  });
  const offlineMessage = intl.formatMessage({
    id: "screens.title.offline",
  });
  const options = [
    {
      value: "ONLINE",
      label: onlineMessage,
    },
    {
      value: "OFFLINE",
      label: offlineMessage,
    },
  ];

  return (
    <div className={styles.event_info}>
      <Heading3>{basicInfoMessage}</Heading3>
      <div className={styles.event_info_inputs}>
        <PlainInput
          {...register("name")}
          label={eventTitleMessage}
          placeholder={pleaseEnterMessage}
          maxLength={120}
        />
        <Controller
          name="locationType"
          control={control}
          render={({ field }) => (
            <PlainSelect
              onChange={(value) => field.onChange(value?.value)}
              onBlur={field.onBlur}
              value={options.find((x) => x.value === field.value)}
              id="event-type-select-box"
              placeholder={selectLocationTypeMessage}
              options={options}
              className={styles.event_type_select_box}
              label={locationTypeMessage}
            />
          )}
        />
      </div>
    </div>
  );
};
