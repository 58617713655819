import { notification } from "antd";
import { all, call, put, takeLatest } from "redux-saga/effects";

import MainCategoryActions, {
  IndustryMainCategoryTypes as MainCategoryTypes,
} from "../Redux/IndustryMainCategoryRedux";
import { endpoints, parseEndpoint } from "../Services/endpoints";

// @ts-expect-error TS7006
function* attemptGetCategories(api, action) {
  // @ts-expect-error TS7057
  const dataResponse = yield call(
    api.getRequest,
    parseEndpoint(endpoints.industryReportCategory),
  );
  if (dataResponse.ok) {
    switch (dataResponse.data.status) {
      case "success":
        yield put(
          MainCategoryActions.industryMainCategoryGetSuccess(
            // @ts-expect-error TS2554
            dataResponse.data.data,
          ),
        );
        break;
      case "fail":
      case "error":
        if (typeof dataResponse.data.data === "string") {
        }
        yield put(
          MainCategoryActions.industryMainCategoryGetFailure(
            // @ts-expect-error TS2554
            dataResponse.data.data,
          ),
        );
        break;
      default:
        break;
    }
  } else {
    notification.error({
      message: dataResponse.problem,
    });
    yield put(
      // @ts-expect-error TS2554
      MainCategoryActions.industryMainCategoryGetFailure(dataResponse.problem),
    );
    if (dataResponse.status === 401) {
      // @ts-expect-error TS2322
      window.location = "/login";
    }
  }
}

// @ts-expect-error TS7006
function* attemptUpdateCategories(api, action) {
  const { data } = action;
  const { id, ...body } = data;

  // @ts-expect-error TS7057
  const dataResponse = yield call(
    api.putRequest,
    parseEndpoint(endpoints.updateMainCategory, { id }),
    body,
  );
  if (dataResponse.ok) {
    switch (dataResponse.data.status) {
      case "success":
        yield put(MainCategoryActions.industryMainCategoryUpdateSuccess());
        yield put(MainCategoryActions.industryMainCategoryGetRequest());
        break;
      case "fail":
      case "error":
        if (typeof dataResponse.data.data === "string") {
        }
        yield put(
          MainCategoryActions.industryMainCategoryUpdateFailure(
            // @ts-expect-error TS2554
            dataResponse.data.data,
          ),
        );
        break;
      default:
        break;
    }
  } else {
    notification.error({
      message: dataResponse.problem,
    });
    yield put(
      MainCategoryActions.industryMainCategoryUpdateFailure(
        // @ts-expect-error TS2554
        dataResponse.problem,
      ),
    );
    if (dataResponse.status === 401) {
      // @ts-expect-error TS2322
      window.location = "/login";
    }
  }
}

// @ts-expect-error TS7006
function* attemptCreateCategories(api, action) {
  const { data } = action;

  // @ts-expect-error TS7057
  const dataResponse = yield call(
    api.postRequest,
    parseEndpoint(endpoints.createIndustryMainCategory),
    data,
  );
  if (dataResponse.ok) {
    switch (dataResponse.data.status) {
      case "success":
        yield put(
          MainCategoryActions.industryMainCategoryCreateSuccess(
            // @ts-expect-error TS2554
            dataResponse.data.data,
          ),
        );
        yield put(MainCategoryActions.industryMainCategoryGetRequest());
        break;
      case "fail":
      case "error":
        if (typeof dataResponse.data.data === "string") {
        }
        yield put(
          MainCategoryActions.industryMainCategoryCreateFailure(
            // @ts-expect-error TS2554
            dataResponse.data.data,
          ),
        );
        break;
      default:
        break;
    }
  } else {
    notification.error({
      message: dataResponse.problem,
    });
    yield put(
      MainCategoryActions.industryMainCategoryCreateFailure(
        // @ts-expect-error TS2554
        dataResponse.problem,
      ),
    );
    if (dataResponse.status === 401) {
      // @ts-expect-error TS2322
      window.location = "/login";
    }
  }
}

// @ts-expect-error TS7006
function* attemptDeleteCategories(api, action) {
  const { data } = action;
  // @ts-expect-error TS7057
  const dataResponse = yield call(
    api.deleteRequest,
    parseEndpoint(endpoints.deleteMainCategory, { data }),
  );

  if (dataResponse.ok) {
    switch (dataResponse.data.status) {
      case "success":
        yield put(MainCategoryActions.industryMainCategoryDeleteSuccess());
        yield put(MainCategoryActions.industryMainCategoryGetRequest());
        break;
      case "fail":
      case "error":
        if (typeof dataResponse.data.data === "string") {
        }
        yield put(
          MainCategoryActions.industryMainCategoryDeleteFailure(
            // @ts-expect-error TS2554
            dataResponse.data.data,
          ),
        );
        break;
      default:
        break;
    }
  } else {
    yield put(
      MainCategoryActions.industryMainCategoryDeleteFailure(
        // @ts-expect-error TS2554
        dataResponse.problem,
      ),
    );
    if (dataResponse.status === 401) {
      // @ts-expect-error TS2322
      window.location = "/login";
    }
  }
}

// @ts-expect-error TS7006
function* newsSaga(api) {
  yield all([
    takeLatest(
      MainCategoryTypes.INDUSTRY_MAIN_CATEGORY_GET_REQUEST,
      attemptGetCategories,
      api,
    ),
    takeLatest(
      MainCategoryTypes.INDUSTRY_MAIN_CATEGORY_UPDATE_REQUEST,
      attemptUpdateCategories,
      api,
    ),
    takeLatest(
      MainCategoryTypes.INDUSTRY_MAIN_CATEGORY_CREATE_REQUEST,
      attemptCreateCategories,
      api,
    ),
    takeLatest(
      MainCategoryTypes.INDUSTRY_MAIN_CATEGORY_DELETE_REQUEST,
      attemptDeleteCategories,
      api,
    ),
  ]);
}
export default newsSaga;
