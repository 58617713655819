import { useCallback, useState } from "react";

import { PublishedStatus } from "../ProjectSubmissionsScreen/type";

export const usePublishedStatus = () => {
  const [selectedPublishedStatuses, setSelectedPublishedStatus] = useState<
    PublishedStatus[]
  >([]);

  const resetAllPublishedStatus = useCallback(() => {
    setSelectedPublishedStatus([]);
  }, []);

  const onChangeSelectedPublishedStatus = useCallback(
    (publishedStatus: PublishedStatus) => {
      const isAlreadySelected =
        selectedPublishedStatuses.includes(publishedStatus);

      setSelectedPublishedStatus(
        isAlreadySelected
          ? selectedPublishedStatuses.filter(
              (value) => value !== publishedStatus,
            )
          : [...selectedPublishedStatuses, publishedStatus],
      );
    },
    [selectedPublishedStatuses],
  );

  return {
    selectedPublishedStatuses,
    resetAllPublishedStatus,
    onChangeSelectedPublishedStatus,
  };
};
