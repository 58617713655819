import { Card } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";

import { ExaminationDetail } from "@/../types/ExaminationDetail";
import { ExaminationResult } from "@/../types/ExaminationResult";
import { PublishReviewConfirmButton } from "@/features/review/components/PublishReviewConfirmButton/component";
import { SaveReviewConfirmButton } from "@/features/review/components/SaveReviewConfirmButton/component";

import { ButtonOption, HorizonalLayout } from "../../atoms";
import { PreviewCurrentSubmission } from "../../molecules/submissions";
import { AddReview } from "../../molecules/submissions";

type AddReviewToProjectSubmissionProps = {
  examinationDetail: ExaminationDetail;
  hasChanged: boolean;
  defaultExaminationResult: ExaminationResult;
  saveFile: (
    href: string,
    fileName: string,
    extension?: string,
    fileId?: number | string,
  ) => void;
  updateEvalutationDetail: (index: number, key: string, value: string) => void;
  setExaminationDetail: React.Dispatch<
    React.SetStateAction<ExaminationDetail | undefined>
  >;
  onSaveProjectSubmissionReview: () => void;
  onDeleteProjectSubmission: (id: number) => void;
  handleChanged: () => void;
  publishReview: () => void;
};
export const AddReviewToProjectSubmission = (
  props: AddReviewToProjectSubmissionProps,
) => {
  const {
    examinationDetail,
    hasChanged,
    defaultExaminationResult,
    saveFile,
    updateEvalutationDetail,
    setExaminationDetail,
    onSaveProjectSubmissionReview,
    onDeleteProjectSubmission,
    handleChanged,
    publishReview,
  } = props;

  const popConfirmOkButtonDisabled =
    !hasChanged ||
    examinationDetail.examinationResult === "BLANK" ||
    examinationDetail.evaluations.length === 0;
  const disabled =
    examinationDetail.isPublished || defaultExaminationResult === "BLANK";

  return (
    <div>
      <HorizonalLayout
        style={{
          justifyContent: "flex-end",
        }}
      >
        <ButtonOption
          onClick={() => {
            onDeleteProjectSubmission(examinationDetail.projectArchive.id);
          }}
        >
          <FormattedMessage id={"screen.submission.give_review.cancel"} />
        </ButtonOption>
      </HorizonalLayout>
      <br />
      <Card>
        <PreviewCurrentSubmission
          saveFile={saveFile}
          examinationDetail={examinationDetail}
        />
        <AddReview
          handleChanged={handleChanged}
          setExaminationDetail={setExaminationDetail}
          updateEvalutationDetail={updateEvalutationDetail}
          examinationDetail={examinationDetail}
        />
        <br />
        <SaveReviewConfirmButton
          onConfirm={onSaveProjectSubmissionReview}
          disabled={popConfirmOkButtonDisabled}
        />
        <PublishReviewConfirmButton
          onConfirm={publishReview}
          disabled={disabled}
        />
      </Card>
    </div>
  );
};
