import { Card, Col, Form, Modal, Row, Upload } from "antd";
import PropTypes from "prop-types";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";

import { ReduxDispatch, ReduxState } from "@/Redux/types";

import { ButtonCV, IconUpload } from "../../Components/atoms";
import { Input, Option, Select, TextArea } from "../../Components/molecules";
import { GlobalManageCategoriesComponent } from "../../Components/organisms";
import ApiActions, {
  ApiRequestData,
  ApiSuccessCallback,
} from "../../Redux/ApiRedux";
import HomeActions from "../../Redux/HomeRedux";
import VideoActions from "../../Redux/VideoRedux";
import FileUploadComponent from "../project/FileUploadComponent";
import usePostNewVideoScreen from "./hooks/usePostNewVideoScreen";
import { IPostNewVideoScreenProps } from "./types/IPostNewVideoScreenProps";

const PostNewVideoScreen: FC<IPostNewVideoScreenProps> = function (props) {
  const {
    intl,
    state,
    setState,
    getUploadProps,
    onCreateNewVideo,
    getLargeCategoryIdForSelect,
    onDeleteSmallCategory,
    onSubmitNewCategory,
    onChangeNewVideoData,
  } = usePostNewVideoScreen(props);

  // @ts-expect-error TS7006
  const _renderSmallCategories = (largeCategoryId) => {
    const data = state.smallCategories[largeCategoryId];
    if (data && Array.isArray(data)) {
      return data.map((sm) => {
        return (
          <Option key={sm.id} value={sm.id}>
            {sm.name}
          </Option>
        );
      });
    }
  }; //

  return (
    <>
      <Modal
        title={intl.formatMessage({
          id: "screen.label.category_management",
        })}
        visible={state.isModalManageCategoriesVisible}
        footer={null}
        onCancel={() => {
          setState({
            // @ts-expect-error TS2345
            isModalManageCategoriesVisible: false,
          });
        }}
      >
        <GlobalManageCategoriesComponent
          onDeleteSmallCategory={onDeleteSmallCategory}
          onSubmitNewCategory={onSubmitNewCategory}
          // @ts-expect-error TS2322
          largeCategories={props.largeCategories}
          smallCategories={state.smallCategories}
          user={props.user}
        />
      </Modal>
      <Card
        title={intl.formatMessage({
          id: "screen.label.new_video_posting",
        })}
        bodyStyle={{
          paddingLeft: "7vw",
          paddingRight: "7vw",
        }}
      >
        <Form layout={"vertical"}>
          <Row gutter={[32, 0]}>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={`${intl.formatMessage({
                  id: "screen.label.large_category",
                })} *`}
              >
                <Select
                  onChange={(videoLargeCategoryId) => {
                    onChangeNewVideoData(
                      "videoLargeCategoryId",
                      videoLargeCategoryId,
                    );
                    setState({
                      videoLargeCategoryId,
                    });
                  }}
                >
                  {/*
                   // @ts-expect-error TS18048 */}
                  {props.largeCategories.map((l) => {
                    return (
                      <Option
                        disabled={getLargeCategoryIdForSelect(l)}
                        key={l.id}
                        value={l.id}
                      >
                        {l.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={`${intl.formatMessage({
                  id: "screen.label.small_category",
                })} *`}
              >
                <Select
                  style={{ width: "100%" }}
                  onChange={(videoSmallCategoryId) => {
                    onChangeNewVideoData(
                      "videoSmallCategoryId",
                      videoSmallCategoryId,
                    );
                  }}
                >
                  {_renderSmallCategories(state.videoLargeCategoryId)}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8}>
              <ButtonCV
                style={{ marginTop: "30px" }}
                onClick={() => {
                  setState({
                    // @ts-expect-error TS2345
                    isModalManageCategoriesVisible: true,
                  });
                }}
              >
                <FormattedMessage id="screen.label.to_add_delete_category" />
              </ButtonCV>
            </Col>
            <Col span={24}>
              <Form.Item
                label={`${intl.formatMessage({
                  id: "screen.label.title",
                })} *`}
              >
                <Input
                  onChange={(e) => {
                    onChangeNewVideoData("name", e.target.value);
                  }}
                  placeholder={intl.formatMessage({
                    id: "screen.label.please_enter",
                  })}
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label={`${intl.formatMessage({
                  id: "screen.label.more_detail",
                })} *`}
              >
                <TextArea
                  onChange={(e) => {
                    onChangeNewVideoData("description", e.target.value);
                  }}
                  rows={3}
                  placeholder={intl.formatMessage({
                    id: "screen.label.please_enter",
                  })}
                />
              </Form.Item>
            </Col>

            {/*
             // @ts-expect-error TS18048 */}
            {props.user.isSuper ? (
              <Col span={24}>
                <Form.Item label={"Vimeo URL *"}>
                  <Input
                    onChange={(e) => {
                      onChangeNewVideoData("videoUrl", e.target.value);
                    }}
                    placeholder="Vimeo URL"
                  />
                </Form.Item>
              </Col>
            ) : null}

            <Col xs={24} sm={12} md={8} lg={8}>
              <Form.Item
                label={`${intl.formatMessage({
                  id: "screen.label.thumbnail_attached",
                })} *`}
              >
                <FileUploadComponent
                  message={`＋ ${intl.formatMessage({
                    id: "screen.label.click",
                  })}`}
                  onFileChanged={(imageThumbnail) => {
                    onChangeNewVideoData("imageThumbnail", imageThumbnail);
                  }}
                />
              </Form.Item>
            </Col>

            {/*
             // @ts-expect-error TS18048 */}
            {props.user.type === "admin" ? (
              <Col xs={24} sm={12} md={8} lg={8}>
                <Form.Item
                  label={`${intl.formatMessage({
                    id: "screen.label.video_file_attachment",
                  })} *`}
                >
                  {/*
                   // @ts-expect-error TS2769 */}
                  <Upload {...getUploadProps()}>
                    <ButtonCV>
                      {IconUpload} ＋
                      {intl.formatMessage({
                        id: "screen.label.click",
                      })}
                    </ButtonCV>
                  </Upload>
                </Form.Item>
                {state.attachingVideo.message}
              </Col>
            ) : null}
          </Row>
        </Form>
      </Card>
      <Row>
        <Col span={24}>
          {state.attachingVideo.message.length > 0 ? null : (
            <ButtonCV onClick={onCreateNewVideo} type="primary">
              {intl.formatMessage({
                id: "screens.title.post",
              })}
            </ButtonCV>
          )}
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  company: state.company.companyPayload,
  //@ts-expect-error 要API型定義
  largeCategories: state.company.companies.videosLargeCategory,
  user: state.auth.payload,
  auth: state.auth,
  companies: state.company.companies.rows,
});
const mapDispatchToProps = (dispatch: ReduxDispatch) => ({
  // @ts-expect-error TS7006
  dispatchSetPageTitle: (pageTitle) =>
    // @ts-expect-error TS2554
    dispatch(HomeActions.pageTitle(pageTitle)),
  dispatchApiRequest: (data: ApiRequestData, callback: ApiSuccessCallback) =>
    dispatch(ApiActions.apiRequest(data, callback)),
  // @ts-expect-error TS7006
  dispatchSetVideoCategories: (largeCategories) =>
    // @ts-expect-error TS2554
    dispatch(VideoActions.videoLargeCategoriesGetSuccess(largeCategories)),
});

PostNewVideoScreen.propTypes = {
  dispatchSetPageTitle: PropTypes.func,
  dispatchApiRequest: PropTypes.func,
  dispatchSetVideoCategories: PropTypes.func,
  user: PropTypes.object,
  largeCategories: PropTypes.array,
};

//@ts-expect-error 要API型定義
export default connect(mapStateToProps, mapDispatchToProps)(PostNewVideoScreen);
