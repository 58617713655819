import { DateTime } from "luxon";
import { useIntl } from "react-intl";

import styles from "./styles.module.scss";
import { EventDeadlineProps } from "./type";

export const EventDeadline = ({ deadline }: EventDeadlineProps) => {
  const intl = useIntl();
  return deadline ? (
    <p className={styles.event_deadline}>
      {intl.formatMessage(
        {
          id: "screens.events.deadline",
        },
        {
          deadline: DateTime.fromISO(deadline).toFormat("M/d H:mm"),
        },
      )}
    </p>
  ) : null;
};
