import { Table } from "antd";
import { SortOrder } from "antd/es/table/interface";
import moment from "moment";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";

import { Tag } from "../../Components/atoms";
import { CustomPagination } from "../../Components/molecules";
import { getDisplayName, getObjUUID, getUserAvatar } from "../../utils/common";
import {
  adminUser,
  dateTimeFormat,
  generalUser,
  mentorUser,
  programManager,
} from "../../utils/constants";
import { getInitStorageValue } from "../../utils/localStorage";
import { IMembersRecoveryProps } from "./types/IMembersScreenProps";
const PageRecoverySaveId = "MembersScreen_Recovery";
const recoveryPageKey = `pagination_page_${PageRecoverySaveId}`;
const recoveryLimitKey = `pagination_limit_${PageRecoverySaveId}`;

export type SorterResult = {
  order: SortOrder;
  field: string;
};

const USER_STATUS_COLUMN_KEY = "user_withdrawn_status";
const MembersRecovery = (props: IMembersRecoveryProps) => {
  const intl = useIntl();
  const [recoveryUsers, setRecoveryUsers] = useState<{
    rows: any[];
    count: number;
  }>({ rows: [], count: 0 });
  const [recoveryLimit, setInactieLimit] = useState<number>(
    getInitStorageValue(recoveryLimitKey, process.env.REACT_APP_PAGE_SIZE),
  );
  const [recoveryUsersPage, setRecoveryUsersPage] = useState<number>(
    getInitStorageValue(recoveryPageKey, 1),
  );
  const [sorter, setSorter] = useState<SorterResult>({
    order: "descend",
    field: "updatedAt",
  });

  useEffect(() => {
    if (props.activeTab === "recovery") {
      getRecoveryUsers();
    }
  }, [props.activeTab, recoveryLimit, recoveryUsersPage, sorter]);
  // get recovery users
  const getRecoveryUsers = () => {
    const { apiRequest } = props.context;
    let url = `users/recovery?page=${recoveryUsersPage - 1}&limit=${
      recoveryLimit || process.env.REACT_APP_PAGE_SIZE
    }`;
    url += `&sortBy=${sorter.field}&sortOrder=${sorter.order}`;
    const requestbuilder = {
      method: "getRequest",
      url,
      data: {},
    };
    // @ts-expect-error TS2345
    apiRequest(requestbuilder, ({ data }: { data: any }) => {
      setRecoveryUsers(data);
    });
  };

  const onUpdateSorter = (sorter: any) => {
    const sortField = sorter.field ?? "updatedAt";
    const sortOrder = sorter.order ?? "descend";
    const sortColumnKey = sorter.columnKey;
    let sorterResult: SorterResult = {
      field: sortField,
      order: sortOrder,
    };
    if (sorter.order && sortColumnKey === USER_STATUS_COLUMN_KEY) {
      sorterResult = {
        field: sortColumnKey,
        order: sortOrder,
      };
    }
    setSorter(sorterResult);
  };

  const rowsOfRecoveryUsers = () => {
    if (Array.isArray(recoveryUsers.rows)) {
      return recoveryUsers.rows.filter((u) => u.isRecovery);
    }
    return [];
  };

  const getColumnsRecoveryUsers = () => {
    return [
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
        responsive: ["sm"],
        sortDirections: ["descend", "ascend"],
        sorter: true,
      },
      {
        title: intl.formatMessage({
          id: "screen.events.full_name",
        }),
        dataIndex: "name",
        key: "name",
        sortDirections: ["descend", "ascend"],
        sorter: true,
        render: function _fname(text: any, row: any, index: any) {
          if (row.name === "" && row.createdAt === row.updatedAt) {
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <div style={{ minWidth: 50 }}>{getUserAvatar(row)}</div>
                <Link to={"/dashboard/member/" + getObjUUID(row)}>
                  {intl.formatMessage({
                    id: "screen.label.registration_waiting_invitation",
                  })}
                </Link>
              </div>
            );
          }
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div style={{ minWidth: 50 }}>{getUserAvatar(row)}</div>
              <div style={{ maxWidth: "20vw" }}>
                <Link to={"/dashboard/member/" + getObjUUID(row)}>
                  {getDisplayName(row)}
                </Link>
              </div>
            </div>
          );
        },
      },
      {
        title: intl.formatMessage({
          id: "screen.label.email",
        }),
        dataIndex: "email",
        key: "email",
        sortDirections: ["descend", "ascend"],
        sorter: true,
        render: function _fname(text: any, row: any, index: any) {
          return (
            <div style={{ width: "15vw", wordWrap: "break-word" }}>
              {row?.Credential?.email}
            </div>
          );
        },
      },
      {
        title: intl.formatMessage({
          id: "screen.label.situation",
        }),
        key: USER_STATUS_COLUMN_KEY,
        sortDirections: ["descend", "ascend"],
        sorter: true,
        render: function _fname(text: any, row: any, index: any) {
          return (
            <div style={{ width: "15vw", wordWrap: "break-word" }}>
              {row.stoppedAt
                ? `${intl.formatMessage({
                    id: "screen.label.stopping",
                  })}`
                : ""}
            </div>
          );
        },
      },
      {
        title: intl.formatMessage({
          id: "screen.label.user_type",
        }),
        dataIndex: "type",
        sortDirections: ["descend", "ascend"],
        sorter: true,
        render: function _fname(text: any, data: any, index: any) {
          if (data.type === "employee")
            return <Tag className="tag-selected">{generalUser}</Tag>;
          if (data.type === "mentor")
            return <Tag className="tag-selected">{mentorUser}</Tag>;
          if (
            data.type === "admin" &&
            (data.isSuper === false || data.isSuper === null)
          )
            return <Tag className="tag-selected">{programManager}</Tag>;
          if (data.type === "admin" && data.isSuper === true)
            return <Tag className="tag-selected">{adminUser}</Tag>;
        },
      },
      {
        title: intl.formatMessage({
          id: "screen.label.request_at",
        }),
        dataIndex: "updatedAt",
        sortDirections: ["descend", "ascend"],
        sorter: true,
        render: function _fname(text: any, row: any, index: any) {
          return <span>{moment(text).local().format(dateTimeFormat)}</span>;
        },
      },
    ];
  };

  return (
    <>
      <br />
      <Table
        pagination={false}
        rowKey={"id"}
        // @ts-expect-error TS2322
        columns={getColumnsRecoveryUsers()}
        dataSource={rowsOfRecoveryUsers()}
        className="ant-table-x-scroll"
        onChange={(pagination, filters, sorter: any) => {
          onUpdateSorter(sorter);
        }}
      />
      <br />
      <CustomPagination
        saveId={PageRecoverySaveId}
        count={recoveryUsers.count}
        onChangePage={(recoveryUsersPage, recoveryLimit) => {
          setInactieLimit(recoveryLimit);
          setRecoveryUsersPage(recoveryUsersPage);
        }}
      />
    </>
  );
};

export default MembersRecovery;
