import { useIntl } from "react-intl";

import styles from "./styles.module.scss";
import { EventRemainingProps } from "./type";

export const EventRemaining = ({
  capacity,
  participantsCount,
}: EventRemainingProps) => {
  const intl = useIntl();
  return (
    <p className={styles.event_remaining}>
      <span className={styles.event_first_come_first_served}>
        （
        {intl.formatMessage({
          id: "screens.events.first_come_first_serve",
        })}
        ）
      </span>
      <span className={styles.event_remaining_count}>
        {intl.formatMessage(
          { id: "screens.events.remaining" },
          {
            // 残り席数が0以下（キャンセル待ちが発生した）場合は0を表示する
            remaining: Math.max(capacity - participantsCount, 0),
          },
        )}
      </span>
    </p>
  );
};
