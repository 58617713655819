import { notification } from "antd";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { endpoints, parseEndpoint } from "../../../../Services/endpoints";
import { getObjUUID } from "../../../../utils/common";
import { usePrevious } from "../../../../utils/hooks";
import {
  ProfileFormComponentState,
  ProfileFormProps,
  ProfileScreenResponseType,
  ProfileScreenSubEmailType,
} from "./type";

const useProfileFormComponent = (props: ProfileFormProps) => {
  const intl = useIntl();
  const [state, customSetState] = useState<ProfileFormComponentState>(() => {
    return {
      name: props.userDetails.name ? props.userDetails.name : "",
      role: props.userDetails.role,
      department: props.userDetails.department,
      workHistorySummary: props.userDetails.workHistorySummary
        ? props.userDetails.workHistorySummary
        : "",
      personalBio: props.userDetails.personalBio
        ? props.userDetails.personalBio
        : "",
      myInterests: props.userDetails.interest || [],
      subsidiary: props.userDetails.subsidiary,
      userTag: props.userDetails.userTag,
      subsidiaries: Array.isArray(props.companyDetails.subsidiaries)
        ? props.companyDetails.subsidiaries
        : props.userDetails
        ? props.userDetails.company
          ? props.userDetails.company.subsidiaries
            ? props.userDetails.company.subsidiaries
            : []
          : []
        : [],
      userTags: Array.isArray(props.companyDetails.userTags)
        ? props.companyDetails.userTags
        : props.userDetails
        ? props.userDetails.company
          ? props.userDetails.company.userTags
            ? props.userDetails.company.userTags
            : []
          : []
        : [],
      projects: [],
      interestInputFieldVisible: false,
      interestNewInputField: "",
      interests: props.interests,
    };
  });
  const setState = (data: Partial<ProfileFormComponentState>) => {
    customSetState((previousState) => {
      return {
        ...previousState,
        ...data,
      };
    });
  };
  const prevProps: ProfileFormProps = usePrevious(props);
  useEffect(() => {
    if (prevProps?.userDetails?.interest != props.userDetails.interest) {
      // @ts-expect-error TS2488
      const myInterests = [...state.myInterests, ...props.userDetails.interest];
      setState({
        myInterests: [...new Set(myInterests)],
      });
    }
    if (prevProps?.userDetails?.name != props.userDetails.name) {
      setState({
        name: props.userDetails.name ? props.userDetails.name : "",
      });
    }
  });

  useEffect(() => {
    getProfile(getObjUUID(props.userDetails));
  }, []);
  useEffect(() => {
    setState({
      // @ts-expect-error TS2345
      interests: props.interests,
    });
  }, [props.interests]);

  const handleChange = <K extends keyof ProfileFormComponentState>(
    key: K,
    value: ProfileFormComponentState[K],
  ) => {
    setState({ [key]: value });
  };
  // @ts-expect-error TS7006
  const getProfile = (userId) => {
    const profileScreenRequest = {
      method: "getRequest",
      url: parseEndpoint(endpoints.getProfileScreen, {
        userId,
      }),
      data: {},
    };
    props.dispatchApiRequest(
      // @ts-expect-error TS2345
      profileScreenRequest,
      ({ data }: { data: ProfileScreenResponseType }) => {
        setState({
          // @ts-expect-error TS2345
          interests: data.interest ?? [],
          projects: data.projects ?? [],
          loginEmail: data.loginEmail,
        });
        if (data.subEmails && data.subEmails.length > 0) {
          const subEmails = data.subEmails.map(
            (emailData: ProfileScreenSubEmailType) => {
              emailData.registered = true;
              return emailData;
            },
          );

          setState({ subEmails });
        }
      },
    );
  };
  const handleSubmit = () => {
    if (validateInput()) return;
    props.handleSubmit({
      ...state,
      interest: state.myInterests,
    });
  };

  const handleSubmitPassword = (password: string) => {
    if (password) {
      if (password.replace(/(\r\n|\n|\r|\s)/g, "").length < 8) {
        notification.error({
          message: intl.formatMessage({
            id: "screen.label.password_too_short",
          }),
        });
        return;
      }
      if (!password.match(/\d/)) {
        notification.error({
          message: intl.formatMessage({
            id: "screen.label.password_please_include_number",
          }),
        });
        return;
      } else if (!password.match(/[a-z]/i)) {
        notification.error({
          message: intl.formatMessage({
            id: "screen.label.password_please_include_en",
          }),
        });
        return;
      }
      props.handleSubmit({
        password,
      });
    }
  };
  const validateInput = () => {
    const { workHistorySummary, personalBio, name, password } = state;
    // @ts-expect-error TS18048
    if (name.replace(/\s/g, "").length === 0) {
      notification.error({
        message: intl.formatMessage({
          id: "screen.label.names_can_not_be_empty",
        }),
      });
      return true;
    }
    if (
      workHistorySummary &&
      workHistorySummary.replace(/(\r\n|\n|\r|\s)/g, "").length > 1000
    ) {
      notification.error({
        message: intl.formatMessage({
          id: "screen.label.work_experience_maximum_1000_characters",
        }),
      });
      return true;
    }
    if (
      personalBio &&
      personalBio.replace(/(\r\n|\n|\r|\s)/g, "").length > 1000
    ) {
      notification.error({
        message: intl.formatMessage({
          id: "screen.label.profile_maximum_1000_characters",
        }),
      });
      return true;
    }
    return false;
  };

  const onNewInterestTyped = () => {
    if (state.interestNewInputField) {
      props.createInterest(state.interestNewInputField);
    }
    setState({
      interestInputFieldVisible: false,
      interestNewInputField: "",
    });
  };
  const onStartTypeNewInterest = () => {
    setState({
      interestInputFieldVisible: true,
    });
  };
  // @ts-expect-error TS7006
  const onChangeInterest = (interest) => {
    // @ts-expect-error TS18048
    if (state.myInterests.includes(interest.id)) {
      // @ts-expect-error TS18048
      const newInterest = state.myInterests.filter((interestId) => {
        if (interestId === interest.id) {
          return false;
        }
        return true;
      });
      setState({
        myInterests: newInterest,
      });
    } else {
      setState({
        // @ts-expect-error TS18048
        myInterests: state.myInterests.concat(interest.id),
      });
    }
  };

  return {
    intl,
    state,
    setState,
    handleChange,
    getProfile,
    handleSubmit,
    handleSubmitPassword,
    validateInput,
    onNewInterestTyped,
    onStartTypeNewInterest,
    onChangeInterest,
  };
};

export default useProfileFormComponent;
