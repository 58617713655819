import { all, call, put, takeLatest } from "redux-saga/effects";

import BatchActions, { BatchTypes } from "../Redux/BatchRedux";
import { endpoints, parseEndpoint } from "../Services/endpoints";

// @ts-expect-error TS7006
function* attemptGetsBatches(api, {}) {
  // @ts-expect-error TS7057
  const batchResponse = yield call(
    api.getRequest,
    parseEndpoint(endpoints.batch),
  );
  if (batchResponse.ok) {
    switch (batchResponse.data.status) {
      case "success": {
        const batches = Array.isArray(batchResponse.data.data)
          ? batchResponse.data.data
          : [];
        // @ts-expect-error TS7006
        batches.sort((a, b) => {
          return a.ord - b.ord;
        });
        // @ts-expect-error TS2554
        yield put(BatchActions.batchGetsSuccess(batches));
        break;
      }
      case "fail":
      case "error":
        if (typeof batchResponse.data.data === "string") {
        }
    }
  } else {
    if (batchResponse.status === 401) {
      // @ts-expect-error TS2322
      window.location = "/login";
    }
  }
}

// @ts-expect-error TS7006
function* batchSaga(api) {
  yield all([
    // @ts-expect-error TS2345
    takeLatest(BatchTypes.BATCH_GETS_REQUEST, attemptGetsBatches, api),
  ]);
}

export default batchSaga;
