import { useIntl } from "react-intl";

import styles from "@/features/review/components/UploadReviewModalTitle/styles.module.scss";

export const UploadReviewModalTitle = () => {
  const intl = useIntl();
  const title = intl.formatMessage({
    id: "screen.label.excel_import_modal_header",
  });
  return (
    <div className={styles.title_container}>
      <span className={styles.title}>{title}</span>
    </div>
  );
};
