import { Menu } from "antd";
import { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";

import * as Icons from "../../atoms/icons";
import {
  CompanyLogoContainer,
  FiexdLogoImg,
  FixedLogoMenu,
  FixedMenuItem,
  SideFixedMenu,
} from "../../molecules";
import { ProjectsFixed } from "./Projects";

const { SubMenu } = Menu;

type FixedMenuProps = {
  company: any;
  companyIcon?: string;
  programInfo: any;
  programInfoQa: any;
  myProjects: Array<any>;
  batches?: Array<any>;
  isAdminUser: boolean;
  isSuperUser: boolean;
  active: string;
  gotoHome: () => void;
  gotoScreen: (url: string) => void;
  updateMenuState: (val: boolean) => void;
  pathname: string;
};

const SUB_MENU_KEY_LIST = {
  programInfo: {
    key: "program-info-view",
    regex: "^/dashboard/program-info-view",
  },
  project: {
    key: "project",
    regex: "^/dashboard/projects.*",
  },
  video: {
    key: "videos",
    regex: "^/dashboard/videos.*",
  },
  library: {
    key: "library",
    regex: "^/dashboard/library.*",
  },
  reference: {
    key: "reference",
    regex: "^/dashboard/reference.*",
  },
} as const;

export const FixedSideMenu = (props: FixedMenuProps) => {
  const intl = useIntl();
  const getCompanyBackgroundBannerColor = () => {
    if (props.company && props.company.bannerImageBackgroundColor) {
      if (props.company.bannerImageBackgroundColor === "#6f49f5") {
        return "#292929";
      }
      return props.company.bannerImageBackgroundColor;
    }
    return "#292929";
  };

  const getCompanyLogoPath = () => {
    const { companyIcon } = props;
    // @ts-expect-error TS18048
    if (companyIcon.includes("http")) {
      return companyIcon;
    }
    return window.location.protocol + "//" + props.companyIcon;
  };

  const currentBatchId = (): string => {
    const splitedPaths = location.pathname.split("/");
    const batchId = splitedPaths[splitedPaths.length - 1];
    return props.batches?.find(({ id }) => id === batchId)?.id ?? "";
  };

  const _renderProgramInfoMenu = () => {
    const isProgramInfoVisible =
      props.isAdminUser || props.programInfo?.status === "public";
    const isProgramInfoQaVisible =
      props.isAdminUser || props.programInfoQa?.status === "public";
    if (isProgramInfoVisible || isProgramInfoQaVisible) {
      return (
        <SubMenu
          // @ts-expect-error TS2322
          id="sidebar-program-info-view"
          key={SUB_MENU_KEY_LIST.programInfo.key}
          icon={Icons.IconInfo}
          title={<FormattedMessage id="screen.label.program_information" />}
          onTitleClick={() => {
            props.updateMenuState(false);
          }}
        >
          {isProgramInfoVisible ? (
            <FixedMenuItem
              onClick={() => {
                props.updateMenuState(false);
              }}
              id="sidebar-program-info-view-summary"
              key="/dashboard/program-info-view"
            >
              <Link to="/dashboard/program-info-view">
                <span>
                  <FormattedMessage id="screen.label.program_overview" />
                </span>
              </Link>
            </FixedMenuItem>
          ) : null}

          {isProgramInfoQaVisible ? (
            <FixedMenuItem
              onClick={() => {
                props.updateMenuState(false);
              }}
              id="sidebar-program-info-view-qa"
              key="/dashboard/program-info-view-qa"
            >
              <Link to="/dashboard/program-info-view-qa">
                <span>
                  <FormattedMessage id="screen.label.program_qa" />
                </span>
              </Link>
            </FixedMenuItem>
          ) : null}
        </SubMenu>
      );
    }
  };
  const _renderProjectMenu = () => {
    return (
      <SubMenu
        // @ts-expect-error TS2322
        id="sidebar-project"
        key={SUB_MENU_KEY_LIST.project.key}
        icon={Icons.IconProject}
        title={<FormattedMessage id="screen.label.project" />}
        onTitleClick={() => {
          props.updateMenuState(false);
        }}
      >
        {props.batches && props.batches.length !== 1 && (
          <FixedMenuItem
            onClick={() => {
              props.updateMenuState(false);
            }}
            id="sidebar-projects-list"
            key="/dashboard/projects.list"
          >
            <Link to="/dashboard/projects.list">
              <span>
                <FormattedMessage id="screen.label.all_projects" />
              </span>
            </Link>
          </FixedMenuItem>
        )}
        {props.batches &&
          props.batches.length > 0 &&
          props.batches
            .filter((a) => a.status === 1 || a.status === 3)
            .map((batch) => {
              return (
                <FixedMenuItem
                  onClick={() => {
                    props.updateMenuState(false);
                  }}
                  id={`sidebar-projects-list-${batch.id}`}
                  key={`/dashboard/projects.batchlist/${batch.id}`}
                >
                  <Link to={`/dashboard/projects.batchlist/${batch.id}`}>
                    <span>{batch.name}</span>
                  </Link>
                </FixedMenuItem>
              );
            })}
      </SubMenu>
    );
  };

  const defaultOpenKey = useMemo(
    () =>
      Object.values(SUB_MENU_KEY_LIST).find(({ regex }) =>
        // eslint-disable-next-line security/detect-non-literal-regexp
        new RegExp(regex).test(props.pathname),
      )?.key,
    [props.pathname],
  );

  return (
    <SideFixedMenu
      defaultSelectedKeys={[props.active]}
      selectedKeys={[props.active]}
      defaultOpenKeys={defaultOpenKey && [defaultOpenKey]}
      mode="inline"
      theme="dark"
    >
      <FixedLogoMenu
        id="sidebar-logo"
        className="rootLogoImage"
        key="companylogoimage"
      >
        <CompanyLogoContainer
          // @ts-expect-error TS2769
          backgroundColor={getCompanyBackgroundBannerColor()}
          onClick={props.gotoHome}
        >
          <FiexdLogoImg
            alt={intl.formatMessage({
              id: "screens.alt.company_logo",
            })}
            src={
              props.companyIcon
                ? getCompanyLogoPath()
                : `${window.location.origin}/logospace.png`
            }
          />
        </CompanyLogoContainer>
      </FixedLogoMenu>

      <FixedMenuItem
        id="sidebar-dashboard"
        key="/dashboard"
        icon={Icons.IconHome}
      >
        <Link to="/dashboard">
          <span>
            <FormattedMessage id="screen.label.home" />
          </span>
        </Link>
      </FixedMenuItem>

      {_renderProgramInfoMenu()}

      {_renderProjectMenu()}

      <FixedMenuItem
        id="sidebar-members"
        key="/dashboard/members"
        icon={Icons.IconUsers}
      >
        <Link to="/dashboard/members">
          <span>
            <FormattedMessage id="screen.label.user" />
          </span>
        </Link>
      </FixedMenuItem>
      <FixedMenuItem
        id="sidebar-events"
        key="/dashboard/events"
        icon={Icons.IconEvent}
      >
        <Link to="/dashboard/events">
          <span>
            <FormattedMessage id="screen.label.events" />
          </span>
        </Link>
      </FixedMenuItem>
      {props.isAdminUser ? (
        <SubMenu
          // @ts-expect-error TS2322
          id="sidebar-videos"
          key={SUB_MENU_KEY_LIST.video.key}
          icon={Icons.IconVideo}
          title={<FormattedMessage id="screen.label.video" />}
          onTitleClick={() => {
            props.updateMenuState(false);
          }}
        >
          <FixedMenuItem
            onClick={() => {
              props.updateMenuState(false);
            }}
            id="sidebar-videos-list"
            key="/dashboard/videos"
          >
            <Link to="/dashboard/videos">
              <span>
                <FormattedMessage id="screen.label.video_list" />
              </span>
            </Link>
          </FixedMenuItem>
          <FixedMenuItem
            onClick={() => {
              props.updateMenuState(false);
            }}
            id="sidebar-videos-mgt"
            key="/dashboard/videos-mgt"
          >
            <Link to="/dashboard/videos-mgt">
              <span>
                <FormattedMessage id="screen.label.video_management" />
              </span>
            </Link>
          </FixedMenuItem>
        </SubMenu>
      ) : (
        <FixedMenuItem
          id="sidebar-videos-list"
          key="/dashboard/videos"
          icon={Icons.IconVideo}
        >
          <Link to="/dashboard/videos">
            <span>
              <FormattedMessage id="screen.label.video" />
            </span>
          </Link>
        </FixedMenuItem>
      )}
      <SubMenu
        // @ts-expect-error TS2322
        id="sidebar-library"
        key={SUB_MENU_KEY_LIST.library.key}
        icon={Icons.IconLibrary}
        title={<FormattedMessage id="screen.label.library" />}
        onTitleClick={() => {
          props.updateMenuState(false);
        }}
      >
        <FixedMenuItem
          onClick={() => {
            props.updateMenuState(false);
          }}
          id="sidebar-news"
          key="/dashboard/library/news"
        >
          <Link to="/dashboard/library/news">
            <span>
              <FormattedMessage id="screen.label.news" />
            </span>
          </Link>
        </FixedMenuItem>
        <FixedMenuItem
          onClick={() => {
            props.updateMenuState(false);
          }}
          id="sidebar-industry-report"
          key="/dashboard/library/industry-report"
        >
          <Link to="/dashboard/library/industry-report">
            <span>
              <FormattedMessage id="screen.label.industry_theme_report" />
            </span>
          </Link>
        </FixedMenuItem>
      </SubMenu>

      <SubMenu
        // @ts-expect-error TS2322
        id="sidebar-reference"
        key={SUB_MENU_KEY_LIST.reference.key}
        icon={Icons.IconReference}
        title={<FormattedMessage id="screen.label.reference" />}
        onTitleClick={() => {
          props.updateMenuState(false);
        }}
      >
        <FixedMenuItem
          onClick={() => {
            props.updateMenuState(false);
          }}
          id="sidebar-reference-preset"
          key="/dashboard/reference-preset"
        >
          <Link to="/dashboard/reference-preset">
            <span>
              <FormattedMessage id="screen.label.preset_reference" />
            </span>
          </Link>
        </FixedMenuItem>
        <FixedMenuItem
          onClick={() => {
            props.updateMenuState(false);
          }}
          id="sidebar-reference-original"
          key="/dashboard/reference-original"
        >
          <Link to="/dashboard/reference-original">
            <span>
              <FormattedMessage id="screen.label.original_reference" />
            </span>
          </Link>
        </FixedMenuItem>
      </SubMenu>

      {props.isAdminUser && props.batches && props.batches.length > 0 && (
        <FixedMenuItem
          onClick={() => {
            props.updateMenuState(false);
          }}
          id="sidebar-project-submissions"
          key={`/dashboard/project_submissions/${currentBatchId()}`}
          icon={Icons.IconResult}
        >
          <Link to={`/dashboard/project_submissions/${props.batches[0].id}`}>
            <span>
              <FormattedMessage id="screen.label.examination_title" />
            </span>
          </Link>
        </FixedMenuItem>
      )}
      {props.isAdminUser ? (
        <FixedMenuItem
          id="sidebar-analytics"
          key="/dashboard/analytics"
          icon={Icons.IconStatistics}
        >
          <Link to="/dashboard/analytics">
            <span>
              <FormattedMessage id="screen.label.statistics" />
            </span>
          </Link>
        </FixedMenuItem>
      ) : null}
      {props.isAdminUser && (
        <FixedMenuItem
          id="sidebar-notices"
          key="/dashboard/notices/create"
          icon={Icons.IconNoti}
        >
          <Link to="/dashboard/notices/create">
            <span>
              <FormattedMessage id="screen.label.notification" />
            </span>
          </Link>
        </FixedMenuItem>
      )}
      <ProjectsFixed
        projects={props.myProjects}
        gotoScreen={props.gotoScreen}
      />

      {props.isAdminUser ? (
        <FixedMenuItem
          id="sidebar-settings"
          key="/dashboard/settings"
          icon={Icons.IconSetting}
        >
          <Link to="/dashboard/settings">
            <span>
              <FormattedMessage id="screen.label.setting" />
            </span>
          </Link>
        </FixedMenuItem>
      ) : null}
      {props.isSuperUser ? (
        <FixedMenuItem
          id="sidebar-login-activities"
          key="/dashboard/login-activities"
          icon={Icons.IconSetting}
        >
          <Link to="/dashboard/login-activities">
            <span>
              <FormattedMessage id="screen.label.login_history" />
            </span>
          </Link>
        </FixedMenuItem>
      ) : null}

      {props.isSuperUser ? (
        <FixedMenuItem
          id="sidebar-screen-activities"
          key="/dashboard/screen-activities"
          icon={Icons.IconSetting}
        >
          <Link to="/dashboard/screen-activities">
            <span>
              <FormattedMessage id="screen.label.screen_transition_log" />
            </span>
          </Link>
        </FixedMenuItem>
      ) : null}

      {props.isSuperUser ? (
        <FixedMenuItem
          id="sidebar-operational-log"
          key="/dashboard/operational-log"
          icon={Icons.IconSetting}
        >
          <Link to="/dashboard/operational-log">
            <span>
              <FormattedMessage id="screen.label.operation_history" />
            </span>
          </Link>
        </FixedMenuItem>
      ) : null}
    </SideFixedMenu>
  );
};
