import { Media } from "./card";
type ImageWrapperProps = {
  src: string;
};
type CardMediaProps = {
  imgUrl: string;
  cardSize: string;
};
export const ImageWrapper = (props: ImageWrapperProps) => {
  const { src } = props;
  return (
    <img
      style={{ display: `none` }}
      src={src}
      onError={(e: any) => {
        e.target.parentElement.style.filter = "";
      }}
      onLoad={(e: any) => {
        e.target.parentElement.style.filter = "";
      }}
    />
  );
};

const CardMedia = (props: CardMediaProps) => {
  const { cardSize, imgUrl } = props;
  return (
    <Media
      className="react_tinylink_card_media"
      // @ts-expect-error TS2769
      cardSize={cardSize}
      src={imgUrl}
      style={{ WebkitFilter: "blur(10px)", filter: "blur(10px)" }}
    >
      <ImageWrapper src={imgUrl} />
    </Media>
  );
};
export default CardMedia;
