import { Modal } from "antd";
import React from "react";
import { useIntl } from "react-intl";

import { EventSurveyFormComponent } from "../../organisms";

type Props = {
  eventId: number;
  visible: boolean;
  onClose: () => void;
};

export const CreateEventSurveyModal: React.FC<Props> = ({
  eventId,
  visible,
  onClose,
}) => {
  const intl = useIntl();
  return (
    <Modal
      destroyOnClose
      title={intl.formatMessage({
        id: "screens.title.event_survey",
      })}
      visible={visible}
      onCancel={onClose}
      footer={null}
    >
      <EventSurveyFormComponent eventId={eventId} onClose={onClose} />
    </Modal>
  );
};
