import { Card, Col, Row } from "antd";
import React from "react";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { ReduxDispatch, ReduxState } from "@/Redux/types";

import { ButtonOption, Checkbox } from "../../../../Components/atoms";
import { Option, SearchField, Select } from "../../../../Components/molecules";
import {
  getPermissionForNews,
  NewsCommentItem,
} from "../../../../Components/organisms";
import { ContextProps } from "../../../../Provider";
import ApiActions, {
  ApiRequestData,
  ApiSuccessCallback,
} from "../../../../Redux/ApiRedux";
import NewsActions from "../../../../Redux/NewsRedux";
import { endpoints, parseEndpoint } from "../../../../Services/endpoints";
import NewsCreateForm from "./create";
type OwnProps = {
  history: Object;
  context: ContextProps;
};
type StateProps = {
  news: {
    // @ts-expect-error TS2314
    rows: Array;
    count: number;
  };
  auth: Object;
  company: Object;
};
type ActionProps = {
  dispatchGetNews: (data: Object) => void;
  dispatchDelNews: (id: string) => void;
  dispatchUpdateNews: (data: Object) => void;
  dispatchApiRequest: () => void;
};
type Props = StateProps & ActionProps & OwnProps;
function NewItemsScreen(props: Props) {
  const intl = useIntl();
  // @ts-expect-error TS2339
  const { updatePageTitle } = props.context;
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(process.env.REACT_APP_PAGE_SIZE);
  const [searchTxt, setSearchTxt] = React.useState("");
  const [sortOrder, setSortOrder] = React.useState("");
  const [sortField, setSortField] = React.useState("");
  const [categories, setCategories] = React.useState([
    "from_issuper",
    "from_pm",
    "from_employee",
    "from_mentor",
  ]);
  React.useEffect(() => {
    updatePageTitle("screen.label.news");
    getNews();
  }, [page, limit, sortOrder, sortField, categories]);
  const getNews = () => {
    props.dispatchGetNews({
      page: page - 1,
      limit,
      filter: getSortFilter(),
    });
  };
  const getSortFilter = () => {
    const filterObj = {};
    if (sortField !== "") {
      // @ts-expect-error TS7053
      filterObj["sortField"] = sortField;
    }
    if (sortOrder !== "") {
      // @ts-expect-error TS7053
      filterObj["sortOrder"] = sortOrder;
    }
    if (searchTxt !== "") {
      // @ts-expect-error TS7053
      filterObj["name"] = searchTxt;
      // @ts-expect-error TS7053
      filterObj["content"] = searchTxt;
      // @ts-expect-error TS7053
      filterObj["url"] = searchTxt;
      // @ts-expect-error TS7053
      filterObj["pageTitle"] = searchTxt;
      // @ts-expect-error TS7053
      filterObj["newsComment"] = searchTxt;
    }
    if (categories.length > 0) {
      // @ts-expect-error TS7053
      filterObj["category"] = categories.join(",");
    }
    return filterObj;
  };
  // @ts-expect-error TS7006
  const onDeleteItem = (id) => {
    props.dispatchDelNews(id);
  };
  // @ts-expect-error TS7006
  const onUpdateItem = (id) => {
    // @ts-expect-error TS2339
    props.history.push(`/dashboard/library/news-update/${id}`);
  };
  // @ts-expect-error TS7006
  const onAddReaction = (id, emoji, emojiName) => {
    const requestBuilder = {
      method: "putRequest",
      url: parseEndpoint(endpoints.newsReaction, {
        newsId: id,
      }),
      data: {
        emoji,
        emojiName,
      },
    };
    // @ts-expect-error TS2554
    props.dispatchApiRequest(requestBuilder, ({ data }) => {
      getNews();
    });
  };
  // @ts-expect-error TS7006
  const onAddSubComment = (id, content) => {
    const requestBuilder = {
      method: "postRequest",
      url: parseEndpoint(endpoints.createNewsComment, {
        data: {
          id,
        },
      }),
      data: {
        content,
      },
    };
    // @ts-expect-error TS2554
    props.dispatchApiRequest(requestBuilder, ({ data }) => {
      getNews();
    });
  };
  // @ts-expect-error TS7006
  const onUpdateSubComment = (id, commentId, content) => {
    const requestBuilder = {
      method: "putRequest",
      url: parseEndpoint(endpoints.updateExistingNewsComment, {
        newsId: id,
        commentId,
      }),
      data: {
        content,
      },
    };
    // @ts-expect-error TS2554
    props.dispatchApiRequest(requestBuilder, ({ data }) => {
      getNews();
    });
  };
  // @ts-expect-error TS7006
  const onDeleteSubComment = (id, commentId) => {
    const requestBuilder = {
      method: "deleteRequest",
      url: parseEndpoint(endpoints.deleteComment, {
        data: {
          id,
          commentId,
        },
      }),
    };
    // @ts-expect-error TS2554
    props.dispatchApiRequest(requestBuilder, ({ data }) => {
      getNews();
    });
  };
  // @ts-expect-error TS7006
  const onAddSubReaction = (id, commentId, emoji, emojiName) => {
    const requestBuilder = {
      method: "putRequest",
      url: parseEndpoint(endpoints.newsCommentReaction, {
        newsId: id,
        commentId,
      }),
      data: {
        emoji,
        emojiName,
      },
    };
    // @ts-expect-error TS2554
    props.dispatchApiRequest(requestBuilder, ({ data }) => {
      getNews();
    });
  };
  // @ts-expect-error TS7006
  const onChangeCategory = (values) => {
    setPage(1);
    const cloneCategories = [...categories];
    for (const value of values) {
      const index = cloneCategories.indexOf(value);
      if (index !== -1) {
        cloneCategories.splice(index, 1);
      } else {
        cloneCategories.push(value);
      }
    }
    setCategories(cloneCategories);
  };
  const renderFilterTopItems = () => {
    return (
      <>
        <Row align="middle" justify="space-between">
          <SearchField
            placeholder={intl.formatMessage({
              id: "screen.label.please_enter_keyword",
            })}
            enterButton={intl.formatMessage({
              id: "screen.label.retrieval",
            })}
            value={searchTxt}
            onChange={(e) => {
              setSearchTxt(e.target.value);
            }}
            // @ts-expect-error TS2322
            onSearch={(value) => {
              if (page === 1) {
                getNews();
              } else {
                setPage(1);
              }
            }}
          />
          <Select
            onChange={(value) => {
              setPage(1);
              setSortField(value);
            }}
            defaultValue={"createdAt"}
            style={{ minWidth: 100, float: "right" }}
          >
            <Option value="createdAt" key="createdAt">
              {intl.formatMessage({
                id: "screen.label.posted_date",
              })}
            </Option>
            <Option value="numberOfLikes" key="numberOfLikes">
              {intl.formatMessage({
                id: "screen.label.number_of_stamps",
              })}
            </Option>
            <Option value="numberOfComments" key="numberOfComments">
              {intl.formatMessage({
                id: "screen.label.number_of_comments",
              })}
            </Option>
          </Select>
        </Row>
        <br />
        <Row>
          <Col>
            <Checkbox
              onChange={() => {
                onChangeCategory(["from_issuper"]);
              }}
              checked={categories.includes("from_issuper")}
            >
              {intl.formatMessage({
                id: "screen.label.from_issuper",
              })}
            </Checkbox>
          </Col>
          <Col style={{ marginLeft: 20 }}>
            <Checkbox
              onChange={() => {
                onChangeCategory(["from_pm"]);
              }}
              checked={categories.includes("from_pm")}
            >
              {intl.formatMessage({
                id: "screen.label.from_pm",
              })}
            </Checkbox>
          </Col>
          <Col style={{ marginLeft: 20 }}>
            <Checkbox
              checked={categories.includes("from_employee")}
              onChange={() => {
                onChangeCategory(["from_employee", "from_mentor"]);
              }}
            >
              {intl.formatMessage({
                id: "screen.label.from_employeer",
              })}
            </Checkbox>
          </Col>
        </Row>
      </>
    );
  };
  return (
    <>
      <NewsCreateForm getNews={getNews} />
      <br />
      <Card className="library-news">
        <div style={{ maxWidth: 640 }}>
          {renderFilterTopItems()}
          <br />
          {/*
           // @ts-expect-error TS7006 */}
          {props.news.rows.map((item) => {
            return (
              <NewsCommentItem
                key={item.id}
                // @ts-expect-error TS2322
                comment={item}
                isMain={true}
                isCanShow={false}
                // @ts-expect-error TS2339
                isCanEdit={getPermissionForNews(props.auth.payload, item)}
                // @ts-expect-error TS2339
                companyId={props.company.id}
                isCanEditEmoji={true}
                // @ts-expect-error TS2339
                user={props.auth.payload}
                onUpdate={onUpdateItem}
                onDelete={onDeleteItem}
                onAddReaction={onAddReaction}
                onAddSubComment={onAddSubComment}
                onUpdateSubComment={onUpdateSubComment}
                onDeleteSubComment={onDeleteSubComment}
                onAddSubReaction={onAddSubReaction}
              />
            );
          })}

          {props.news.rows.length > 0 &&
            props.news.rows.length < props.news.count && (
              <Row align="middle">
                <br />
                <br />
                <br />
                <br />
                <ButtonOption
                  style={{
                    paddingLeft: 30,
                    paddingRight: 30,
                  }}
                  size="small"
                  onClick={() => {
                    setPage(page + 1);
                  }}
                >
                  {intl.formatMessage({
                    id: "screen.label.load_more",
                  })}
                </ButtonOption>
                <br />
                <br />
              </Row>
            )}
        </div>
      </Card>
    </>
  );
}
const mapStateToProps = (state: ReduxState) => ({
  news: state.news.newsPayload,
  auth: state.auth,
  company: state.company.companyPayload,
});
const mapDispatchToProps = (dispatch: ReduxDispatch) => ({
  // @ts-expect-error TS7006
  dispatchGetNews: (data) => dispatch(NewsActions.newsGetRequest(data)),
  // @ts-expect-error TS7006
  dispatchDelNews: (id) => dispatch(NewsActions.newsDeleteRequest(id)),
  // @ts-expect-error TS7006
  dispatchUpdateNews: (data) => dispatch(NewsActions.newsUpdateRequest(data)),
  dispatchApiRequest: (data: ApiRequestData, callback: ApiSuccessCallback) =>
    dispatch(ApiActions.apiRequest(data, callback)),
});
export default withRouter(
  // @ts-expect-error TS2345
  connect(mapStateToProps, mapDispatchToProps)(NewItemsScreen),
);
