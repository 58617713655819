import { Card } from "antd";
import moment from "moment";

const { Meta } = Card;

export type SingleVideo = {
  id: number;
  name: string;
  VideoSmallCategory: {
    name: string;
  };
  createdAt: string;
  imageThumbnail: string;
};
type VideoCardFooterProps = {
  singleVideo: SingleVideo;
};

export const VideoCardFooter = (props: VideoCardFooterProps) => {
  const { singleVideo } = props;

  const _renderCardFooter = (title: string, time: string) => {
    return (
      <>
        {title}
        <br />
        <span style={{ float: "right" }}>{time}</span>
      </>
    );
  };

  return (
    <Card bordered={false}>
      <Meta
        title={singleVideo.name}
        description={_renderCardFooter(
          singleVideo.VideoSmallCategory
            ? singleVideo.VideoSmallCategory.name
            : "",
          moment(singleVideo.createdAt).local().format("YYYY-MM-DD HH:mm"),
        )}
      />
    </Card>
  );
};
