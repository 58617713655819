import { Button, Tag } from "antd";
import styled from "styled-components";

export const InsuPrimaryButton = styled(Button)`
  color: #1f86e9;
  border: 1px solid #1f86e9;
  background-color: transparent;
  margin-right: 0.1vw;
  margin-left: 0.1vw;
`;

export const InsuPrimaryTag = styled(Tag)`
  border: 1px solid #323232;
  border-radius: 0;
`;

export const InsuAcceptButton = styled(Button)`
  background-color: #1f86e9;
  color: white;
  margin-right: 0.1vw;
  margin-left: 0.1vw;
`;

export const InsuRejectButton = styled(Button)`
  background-color: #b5b5b5;
  color: white;
  margin-right: 0.1vw;
  margin-left: 0.1vw;
`;

export const InsuFlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;
