import { useState } from "react";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { useIntl } from "react-intl";

import { EventData } from "@/../types/Event";
import { PrimaryButton } from "@/shared/buttons/components/PrimaryButton";
import { Heading2 } from "@/shared/headings/components/Heading2";

import { EventApplicationButton } from "../EventApplicationButton";
import { EventApplyWaitingListButton } from "../EventApplyWaitingListButton";
import { EventCancelButton } from "../EventCancelButton";
import { EventCapacity } from "../EventCapacity";
import { EventDeadline } from "../EventDeadline";
import { EventRemaining } from "../EventRemaining";
import styles from "./styles.module.scss";

// イベントの申し込み期限が過ぎていたorイベントが終了していた場合のUI
export const DeadlinePassedUI = ({ event }: { event: EventData }) => {
  const intl = useIntl();

  return (
    <div className={styles.event_application_buttons}>
      <PrimaryButton disabled>
        {intl.formatMessage({
          id: "screen.label.event_application_has_ended",
        })}
      </PrimaryButton>
      <EventDeadline deadline={event.applicationDeadline} />
    </div>
  );
};

// イベントが募集停止していた場合のUI
export const NotAcceptingUI = () => {
  const intl = useIntl();

  return (
    <PrimaryButton disabled>
      {intl.formatMessage({
        id: "screen.label.event_not_accepting_members",
      })}
    </PrimaryButton>
  );
};

// 抽選イベントで、まだ申し込みをしていない場合のUI
export const LotteryNotAppliedUI = ({
  event,
  applyEventHandler,
}: {
  event: EventData;
  applyEventHandler: () => Promise<void>;
}) => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const handleButtonClick = async () => {
    setIsButtonDisabled(true);

    try {
      await applyEventHandler();
    } catch (error) {
      // applyForEvent処理が失敗した場合、ボタンを再度有効にする必要があるので
      setIsButtonDisabled(false);
    }
  };

  return (
    <>
      <EventCapacity capacity={event.capacity} />
      <div className={styles.event_application_buttons}>
        <EventApplicationButton
          onClick={handleButtonClick}
          disabled={isButtonDisabled}
        />
        <EventDeadline deadline={event.applicationDeadline} />
      </div>
    </>
  );
};

// 抽選イベントに申し込んでいるが、参加が未確定の場合のUI
export const LotteryUndecidedUI = ({
  cancelEventHandler,
}: {
  cancelEventHandler: () => Promise<void>;
}) => {
  const intl = useIntl();
  return (
    <>
      <p className={styles.event_checked}>
        {intl.formatMessage({
          id: "screens.events.now_on_electing",
        })}
      </p>
      <EventCancelButton cancelEventHandler={cancelEventHandler} />
    </>
  );
};

// 抽選イベントに不参加が確定している場合（落選）のUI
export const LotteryNotElectedUI = () => {
  const intl = useIntl();
  return (
    <Heading2>
      {intl.formatMessage({
        id: "screens.events.not_elected",
      })}
    </Heading2>
  );
};

// 先着イベントで、定員に達している場合のUI
export const FirstComeFirstServeFullUI = ({
  event,
  participantsCount,
  applyEventHandler,
}: {
  event: EventData;
  participantsCount: number;
  applyEventHandler: () => Promise<void>;
}) => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const handleButtonClick = async () => {
    setIsButtonDisabled(true);

    try {
      await applyEventHandler();
    } catch (error) {
      // applyForEvent処理が失敗した場合、ボタンを再度有効にする必要があるので
      setIsButtonDisabled(false);
    }
  };

  return (
    <>
      <EventRemaining
        capacity={event.capacity}
        participantsCount={participantsCount}
      />
      <div className={styles.event_application_buttons}>
        <EventApplyWaitingListButton
          onClick={handleButtonClick}
          disabled={isButtonDisabled}
        />
        <EventDeadline deadline={event.applicationDeadline} />
      </div>
    </>
  );
};

// 先着イベントで、まだ定員に達していない場合のUI
export const FirstComeFirstServeNotFullUI = ({
  event,
  participantsCount,
  applyEventHandler,
}: {
  event: EventData;
  participantsCount: number;
  applyEventHandler: () => Promise<void>;
}) => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const handleButtonClick = async () => {
    setIsButtonDisabled(true);

    try {
      await applyEventHandler();
    } catch (error) {
      // applyForEvent処理が失敗した場合、ボタンを再度有効にする必要があるので
      setIsButtonDisabled(false);
    }
  };

  return (
    <>
      <EventRemaining
        capacity={event.capacity}
        participantsCount={participantsCount}
      />
      <div className={styles.event_application_buttons}>
        <EventApplicationButton
          onClick={handleButtonClick}
          disabled={isButtonDisabled}
        />
        <EventDeadline deadline={event.applicationDeadline} />
      </div>
    </>
  );
};

// 参加が確定している場合のUI
export const ParticipationConfirmedUi = ({
  cancelEventHandler,
}: {
  cancelEventHandler: () => Promise<void>;
}) => {
  const intl = useIntl();
  return (
    <>
      <p className={styles.event_checked}>
        <BsFillCheckCircleFill className={styles.event_check_icon} />
        {intl.formatMessage({
          id: "screens.events.elected",
        })}
      </p>
      <EventCancelButton cancelEventHandler={cancelEventHandler} />
    </>
  );
};

// 先着イベントのキャンセル待ちに申し込んでいる場合のUI
export const FirstComeFirstServeWaitingListUI = ({
  cancelEventHandler,
}: {
  cancelEventHandler: () => Promise<void>;
}) => {
  const intl = useIntl();
  return (
    <>
      <p className={styles.event_checked}>
        {intl.formatMessage({
          id: "screens.events.waiting_on_list",
        })}
      </p>
      <EventCancelButton cancelEventHandler={cancelEventHandler} />
    </>
  );
};

export const ErrorUI = () => {
  const intl = useIntl();
  return (
    <Heading2>
      {intl.formatMessage({
        id: "screens.events.error_message",
      })}
    </Heading2>
  );
};
