import { useCallback, useContext, useState } from "react";
import { FormattedMessage } from "react-intl";

import { ContentContext } from "../../../../Provider";
import { endpoints, parseEndpoint } from "../../../../Services/endpoints";
import { ButtonOption } from "../../../atoms";
import { RelatedTaskItem } from "../../../molecules";
import { RelatedTaskCreation } from "../../../molecules";

type Props = {
  projectId: string;
  tasks: Array<any>;
  disabled: boolean;
  onUpdateTaskList?: (relatedTaskList: Array<number>) => void;
};
const RelatedTaskItems = (props: Props) => {
  const { projectId, tasks, disabled, onUpdateTaskList } = props;
  const context = useContext(ContentContext);
  const [showCreationModal, setShowCreationModal] = useState(false);
  const { apiRequest } = context;

  const onHideModal = () => {
    setShowCreationModal(false);
  };

  const onShowModal = () => {
    setShowCreationModal(true);
  };

  const onUpdateRelatedTask = useCallback(
    (id: number) => {
      let relatedTaskList = [];
      if (tasks && tasks.length > 0) {
        relatedTaskList = tasks.map((item) => item.id);
      }
      relatedTaskList.push(id);
      const unique = [...new Set(relatedTaskList)];
      if (onUpdateTaskList) {
        onUpdateTaskList(unique);
      }
    },
    [tasks, onUpdateTaskList],
  );

  const onUpdateProjectTask = useCallback(
    (taskId, status) => {
      const requestBuilder = {
        method: "putRequest",
        url: parseEndpoint(endpoints.updateProjectTask, {
          id: projectId,
          taskId: taskId,
        }),
        data: {
          status,
        },
      };
      // @ts-expect-error TS2345
      apiRequest(requestBuilder, ({ data }) => {
        onUpdateRelatedTask(taskId);
      });
    },
    [tasks, projectId],
  );

  return (
    <div
      style={{
        borderLeftColor: "#EBEBEB",
        borderLeftWidth: 1,
        borderLeftStyle: "solid",
        paddingLeft: 16,
      }}
    >
      {tasks.length > 0 &&
        tasks.map((item, index) => (
          <RelatedTaskItem
            disabled={disabled}
            projectId={projectId}
            key={item.id}
            index={`${index}`}
            item={item}
            onCreatedTask={disabled ? () => {} : onUpdateRelatedTask}
            onChangeStatus={(id, status) => {
              if (!disabled) {
                onUpdateProjectTask(id, status);
              }
            }}
          />
        ))}
      <RelatedTaskCreation
        showModal={showCreationModal}
        task={null}
        projectId={projectId}
        onCreatedTask={onUpdateRelatedTask}
        onHideModal={onHideModal}
      />
      {!disabled && (
        <ButtonOption onClick={onShowModal}>
          + <FormattedMessage id="screen.label.add_related_task" />
        </ButtonOption>
      )}
    </div>
  );
};

export default RelatedTaskItems;
