import { ApisauceInstance } from "apisauce";
import { useCallback } from "react";

import { JsendError } from "@/../types/JSendObject";
import { api } from "@/Saga";
import { endpoints, parseEndpoint } from "@/Services/endpoints";
import { isFailedResponse } from "@/Services/isFailedResponse";

import {
  EventDeleteResponse,
  EventJoinResponse,
  JoinEventData,
  JoinEventQuery,
  LeaveEventQuery,
} from "../types/response";

type AxiosRequestConfig = Parameters<ApisauceInstance["any"]>[0];

export const useEventMember = () => {
  const joinEvent = useCallback(
    async (
      query: JoinEventQuery,
      data: JoinEventData,
      config?: AxiosRequestConfig,
    ) => {
      const url = parseEndpoint(endpoints.getEventUsers, {
        data: {
          eventId: query.eventId,
        },
        queryParams: query.queryParams,
      });
      const response = await api.postRequest<
        JoinEventData,
        EventJoinResponse,
        JsendError
      >(url, data, config);
      const isFailed = isFailedResponse(response);

      return { response, isFailed };
    },
    [],
  );

  const leaveEvent = useCallback(
    async (query: LeaveEventQuery, config?: AxiosRequestConfig) => {
      const { eventId, userId } = query;
      const url = parseEndpoint(endpoints.findEventMember, {
        eventId,
        userId,
      });
      const response = await api.deleteRequest<EventDeleteResponse, JsendError>(
        url,
        {},
        config,
      );
      const isFailed = isFailedResponse(response);

      return { response, isFailed };
    },
    [],
  );

  return {
    joinEvent,
    leaveEvent,
  };
};
