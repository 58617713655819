import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ContentContext } from "@/Provider";

import BatchActions from "../../../Redux/BatchRedux";
import { endpoints, parseEndpoint } from "../../../Services/endpoints";
import { batchStatus } from "../../../utils/batchUtils";

const useBatchManagementComponent = () => {
  const context = useContext(ContentContext);
  const dispatch = useDispatch();
  // @ts-expect-error TS2339
  const batches = useSelector((state) => state.batch.batches);
  const [defaultNewBatchData, setDefaultNewBatchData] = useState({
    name: "",
    from: null,
    to: null,
    status: batchStatus[1].key,
    description: "",
    defaultBatch: false,
  });
  const _clearBatchDefault = () => {
    setDefaultNewBatchData({
      name: "",
      from: null,
      to: null,
      status: batchStatus[1].key,
      description: "",
      defaultBatch: false,
    });
  };
  const [isBatchesSortable, setIsBatchesSortable] = useState(false);
  useEffect(() => {
    // @ts-expect-error TS2554
    dispatch(BatchActions.batchGetsRequest(""));
  }, []);
  const [isNewBatchModalVisible, setIsNewBatchModalVisible] =
    React.useState(false);
  // @ts-expect-error TS7006
  const onSaveBatch = (batchData) => {
    if (!Object.hasOwn(batchData, "ord")) {
      batchData.ord = batches.length;
    }
    const requestBuilder = {
      method: batchData.id ? "putRequest" : "postRequest",
      url: batchData.id
        ? parseEndpoint(endpoints.singleBatch, {
            batchId: batchData.id,
          })
        : parseEndpoint(endpoints.batch),
      data: batchData,
    };
    // @ts-expect-error TS7031
    context.apiRequest(requestBuilder, ({ data }) => {
      setIsNewBatchModalVisible(false);
      setIsBatchesSortable(false);
      // @ts-expect-error TS2554
      dispatch(BatchActions.batchGetsRequest(""));
      _clearBatchDefault();
    });
  };

  return {
    batches,
    defaultNewBatchData,
    setDefaultNewBatchData,
    _clearBatchDefault,
    setIsBatchesSortable,
    isNewBatchModalVisible,
    setIsNewBatchModalVisible,
    onSaveBatch,
    isBatchesSortable,
    dispatch,
  };
};
export default useBatchManagementComponent;
