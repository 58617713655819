import { InfoCircleFilled } from "@ant-design/icons";
import { Col, Row, Tooltip } from "antd";

import { ButtonSwitch } from "../../../atoms";

type CheckPointProps = {
  id: string;
  name: string;
  description: string;
  isGlobal: boolean;
};
type Props = {
  item: CheckPointProps;
  display: boolean;
  isSortable: boolean;
  onChangeDisplay: (checked: boolean) => void;
  onUpdateCheckpoint: () => void;
};

const CheckPointItem = (props: Props) => {
  return (
    <Row
      align="top"
      justify="space-between"
      style={{
        marginBottom: "1vh",
        borderWidth: props.isSortable ? 1 : 0,
        borderColor: "#BDBDBD",
        cursor: props.isSortable ? "row-resize" : "default",
        borderRadius: "4px",
        padding: "2px 10px",
        borderStyle: props.isSortable ? "dashed" : "solid",
      }}
    >
      <Col
        span={21}
        style={{
          borderBottomColor: "#bbbbbb",
          borderBottomWidth: props.isSortable ? 0 : 1,
          borderBottomStyle: "solid",
          paddingBottom: 4,
        }}
      >
        <Row>
          <Col span={23}>
            {!props.item.isGlobal ? (
              <span
                style={{
                  color: "#1f86e9",
                  cursor: "pointer",
                }}
                onClick={props.onUpdateCheckpoint}
              >
                {props.item.name}{" "}
              </span>
            ) : (
              <span>{props.item.name} </span>
            )}
          </Col>
          <Col>
            <Tooltip title={props.item.description}>
              <InfoCircleFilled style={{ color: "#B5b5b5" }} />
            </Tooltip>
          </Col>
        </Row>
      </Col>
      <Col span={2}>
        <ButtonSwitch
          onChange={(checked) => {
            props.onChangeDisplay(checked);
          }}
          checked={props.display}
        />
      </Col>
    </Row>
  );
};

export default CheckPointItem;
