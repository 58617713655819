import { Checkbox, Col, Form, notification, Row } from "antd";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Company } from "../../../../types/Company";
import FileUploadComponent from "../../../Screens/project/FileUploadComponent";
import { ButtonCV } from "../../atoms";
import { Input, Option, Select, TextArea } from "../../molecules";
import IndustryReportPreviewModal from "./previewModal";
import {
  IndustryReport,
  IndustryReportMainCategory,
  IndustryReportSubCategory,
} from "./types";
type FormProps = {
  loading?: boolean;
  isGlobal?: boolean;
  companies: Array<Company>;
  mainCategories: Array<IndustryReportMainCategory>;
  onSubmit: (data: IndustryReportFormData) => void;
  item: IndustryReport | null;
};

export type IndustryReportFormData = {
  title: string;
  isGlobalReport: boolean;
  theme: string;
  summary: string;
  permittedCompanies: Array<number>;
  mainCategoryId: string;
  subCategoryId: string;
  notifyByEmail: boolean;
  industryReport?: string | null;
};

function IndustryReportForm({
  item,
  loading = false,
  isGlobal = true,
  companies,
  mainCategories,
  onSubmit,
}: FormProps) {
  const intl = useIntl();
  const [isGlobalReport, setIsGlobalreport] = React.useState(false);
  const [title, setTitle] = React.useState("");
  const [theme, setTheme] = React.useState("");
  const [summary, setSubmmary] = React.useState("");
  const [industryReport, setIndustryReport] = React.useState<string | null>(
    null,
  );
  const [industryReportFileName, setIndustryReportFileName] = React.useState<
    string | null
  >(null);
  const [industryReportFile, setIndustryReportFile] = React.useState<
    string | null
  >(null);
  const [mainCategoryId, setMainCategoryId] = React.useState("");
  const [subCategoryId, setSubCategoryId] = React.useState("");
  const [permittedCompanies, setPermittedCompanies] = React.useState<
    IndustryReport["permittedCompanies"]
  >([]);
  const [subCategories, setSubCategories] = React.useState<
    IndustryReportSubCategory[]
  >([]);
  const [notifyByEmail, setNotifyByEmail] = React.useState(false);
  const [showPreview, setShowPreview] = React.useState(false);
  React.useEffect(() => {
    if (item && item.id) {
      setTitle(item.title);
      setTheme(item.theme);
      setSubmmary(item.summary);
      setIsGlobalreport(item.isGlobalReport);
      setPermittedCompanies(item.permittedCompanies || []);
      setMainCategoryId(item.mainCategoryId);
      setSubCategoryId(item.subCategoryId);
      changeSubCategories(item.mainCategoryId);
      if (item.industryReportFiles.length > 0) {
        // @ts-expect-error TS2339
        setIndustryReportFileName(item.industryReportFiles[0].name);
        // @ts-expect-error TS2339
        setIndustryReport(item.industryReportFiles[0].filePath);
      }
    }
  }, [item]);
  const changeSubCategories = React.useCallback(
    (mainCategoryId) => {
      if (mainCategories && mainCategories.length > 0) {
        const mainCategoryItem = mainCategories.find(
          (a) => a.id === mainCategoryId,
        );
        if (mainCategoryItem) {
          setSubCategories(mainCategoryItem?.IndustryReportSubCategory || []);
        }
      }
    },
    [mainCategories],
  );
  const formValidation = () => {
    if (!mainCategoryId || mainCategoryId === "") {
      notification.error({
        message: intl.formatMessage({
          id: "screen.label.industry_theme_report_mediumcategory_choose_error",
        }),
      });
      return false;
    }
    if (!subCategoryId || subCategoryId === "") {
      notification.error({
        message: intl.formatMessage({
          id: "screen.label.industry_theme_report_subcategory_choose_error",
        }),
      });
      return false;
    }
    if (!industryReport || industryReport === "") {
      notification.error({
        message: intl.formatMessage({
          id: "screen.label.industry_theme_report_pdf_choose_error",
        }),
      });
      return false;
    }
    return true;
  };
  const formPreviewValidation = () => {
    if (!mainCategoryId || mainCategoryId === "") {
      return false;
    }
    if (!subCategoryId || subCategoryId === "") {
      return false;
    }
    if (!industryReport || industryReport === "") {
      return false;
    }
    if (!title || title === "") {
      return false;
    }
    if (!theme || theme === "") {
      return false;
    }
    if (!summary || summary === "") {
      return false;
    }
    return true;
  };
  const subCategoryItem = subCategories.find((a) => a.id === subCategoryId);
  const mainCategoryItem = mainCategories.find((a) => a.id === mainCategoryId);
  const _renderCompanySelect = () => {
    if (item) {
      if (
        item &&
        Array.isArray(item.permittedCompanies) &&
        Array.isArray(companies)
      ) {
        return (
          <Select
            mode="multiple"
            allowClear
            // @ts-expect-error TS2322
            height="auto"
            style={{ width: "100%", minWidth: 300 }}
            defaultValue={item.permittedCompanies}
            placeholder={intl.formatMessage({
              id: "screen.label.post_company",
            })}
            onChange={(value) => {
              setPermittedCompanies(value);
            }}
          >
            {companies.map((a) => {
              return (
                <Option key={a.id} value={a.id}>
                  {a.name}
                </Option>
              );
            })}
          </Select>
        );
      }
    } else {
      return (
        <Select
          mode="multiple"
          allowClear
          // @ts-expect-error TS2322
          height="auto"
          style={{ width: "100%", minWidth: 300 }}
          placeholder={intl.formatMessage({
            id: "screen.label.post_company",
          })}
          onChange={(value) => {
            setPermittedCompanies(value);
          }}
        >
          {companies.map((a) => {
            return (
              <Option key={a.id} value={a.id}>
                {a.name}
              </Option>
            );
          })}
        </Select>
      );
    }
  };
  return (
    <>
      <Form
        onFinish={() => {
          if (!formValidation()) {
            return;
          }
          const submitData: IndustryReportFormData = {
            title,
            isGlobalReport,
            theme,
            summary,
            permittedCompanies,
            mainCategoryId,
            subCategoryId,
            notifyByEmail,
          };
          if (industryReportFile) {
            submitData["industryReport"] = industryReport;
          }

          onSubmit(submitData);
        }}
        style={{ maxWidth: 600 }}
      >
        {isGlobal && (
          <>
            <Form.Item>
              <Row>
                <Col>
                  <h4 style={{ fontWeight: "bold" }}>
                    {intl.formatMessage({
                      id: "screen.label.visiblity",
                    })}
                  </h4>
                  <Checkbox
                    checked={isGlobalReport}
                    onChange={(event) => {
                      if (event.target.checked) {
                        setPermittedCompanies([]);
                      }
                      setIsGlobalreport(event.target.checked);
                    }}
                  >
                    {intl.formatMessage({
                      id: "screen.label.global",
                    })}
                  </Checkbox>
                </Col>
                {!isGlobalReport && (
                  <Col>
                    <h4 style={{ fontWeight: "bold" }}>
                      {intl.formatMessage({
                        id: "screen.label.company_name",
                      })}
                    </h4>
                    {_renderCompanySelect()}
                  </Col>
                )}
              </Row>
            </Form.Item>
            <br />
          </>
        )}

        <Form.Item>
          <h4 style={{ fontWeight: "bold" }}>
            {intl.formatMessage({ id: "screen.label.title" })} *
          </h4>
          <Input
            required
            value={title}
            onChange={(event) => {
              setTitle(event.target.value);
            }}
            placeholder={intl.formatMessage({
              id: "screen.label.please_enter",
            })}
          />
        </Form.Item>
        <br />
        <Form.Item>
          <h4 style={{ fontWeight: "bold" }}>
            {intl.formatMessage({ id: "screen.label.theme" })} *
          </h4>
          <Input
            required
            value={theme}
            onChange={(event) => {
              setTheme(event.target.value);
            }}
            placeholder={intl.formatMessage({
              id: "screen.label.please_enter",
            })}
          />
        </Form.Item>
        <br />
        <Form.Item>
          <Row>
            <Col>
              <h4 style={{ fontWeight: "bold" }}>
                {intl.formatMessage({
                  id: "screen.label.industry_medium_category",
                })}{" "}
                *
              </h4>
              <Select
                // @ts-expect-error TS2322
                required
                allowClear
                style={{ width: "90%", minWidth: 250 }}
                value={mainCategoryId}
                placeholder={intl.formatMessage({
                  id: "screen.label.industry_medium_category",
                })}
                onChange={(value) => {
                  setMainCategoryId(value);
                  setSubCategoryId("");
                  changeSubCategories(value);
                }}
              >
                {mainCategories &&
                  mainCategories.map((a) => (
                    <Option key={a.id} value={a.id}>
                      {a.name}
                    </Option>
                  ))}
              </Select>
            </Col>
            <Col>
              <h4
                style={{
                  fontWeight: "bold",
                  marginLeft: "10%",
                }}
              >
                {intl.formatMessage({
                  id: "screen.label.industry_sub_category",
                })}{" "}
                *
              </h4>
              <Select
                // @ts-expect-error TS2322
                required
                allowClear
                style={{
                  width: "90%",
                  minWidth: 250,
                  marginLeft: "10%",
                }}
                value={subCategoryId}
                placeholder={intl.formatMessage({
                  id: "screen.label.industry_sub_category",
                })}
                onChange={(value) => {
                  setSubCategoryId(value);
                }}
              >
                {subCategories &&
                  subCategories.map((a) => (
                    <Option key={a.id} value={a.id}>
                      {a.name}
                    </Option>
                  ))}
              </Select>
            </Col>
          </Row>
        </Form.Item>
        <br />
        <Form.Item>
          <h4 style={{ fontWeight: "bold" }}>
            {intl.formatMessage({
              id: "screen.label.overview",
            })}
          </h4>
          <TextArea
            required
            // @ts-expect-error TS2322
            autoSize={true}
            style={{ minHeight: 80 }}
            value={summary}
            onChange={(event) => {
              setSubmmary(event.target.value);
            }}
            placeholder={intl.formatMessage({
              id: "screen.label.please_enter",
            })}
          />
        </Form.Item>
        <br />
        <Form.Item>
          <h4 style={{ fontWeight: "bold" }}>
            {intl.formatMessage({
              id: "screen.label.industry_pdf_attach",
            })}
          </h4>
          <FileUploadComponent
            message={intl.formatMessage({
              id: "screen.label.click_to_upload_pdf",
            })}
            fileTypeAccept="application/pdf,application/vnd.ms-excel"
            onFileChanged={(file) => {
              const reader = new FileReader();
              reader.addEventListener("load", () =>
                // @ts-expect-error TS2345
                setIndustryReportFile(reader.result),
              );
              reader.readAsDataURL(file);
              // @ts-expect-error TS2345
              setIndustryReport(file);
              setIndustryReportFileName(file?.name);
            }}
          />
          {/* {typeof industryReportFileName === 'string' &&
                        industryReportFileName !== '' && (
                            <span>{industryReportFileName}</span>
                        )} */}
        </Form.Item>
        <br />
        <Form.Item>
          <h4 style={{ fontWeight: "bold" }}>
            {intl.formatMessage({
              id: "screen.label.notification",
            })}
          </h4>
          <Checkbox
            checked={notifyByEmail}
            onChange={(event) => {
              setNotifyByEmail(event.target.checked);
            }}
          >
            {intl.formatMessage({
              id: "screen.label.notify_by_email",
            })}
          </Checkbox>
        </Form.Item>
        <br />
        <Form.Item>
          <Row>
            <Col>
              <ButtonCV
                style={{
                  width: 150,
                }}
                disabled={!formPreviewValidation()}
                loading={loading}
                htmlType="submit"
                size="small"
              >
                <FormattedMessage id="screen.label.save" />
              </ButtonCV>
            </Col>
            <Col>
              <ButtonCV
                style={{
                  width: 150,
                  marginLeft: 30,
                }}
                disabled={!formPreviewValidation()}
                loading={loading}
                size="small"
                onClick={() => {
                  setShowPreview(true);
                }}
              >
                <FormattedMessage id="screen.label.preview" />
              </ButtonCV>
            </Col>
          </Row>
        </Form.Item>
      </Form>
      <IndustryReportPreviewModal
        visible={showPreview}
        onChangeVisible={() => {
          setShowPreview(false);
        }}
        title={title}
        theme={theme}
        summary={summary}
        // @ts-expect-error TS2322
        industryReport={
          industryReportFile ? industryReportFile : industryReport
        }
        mainCategoryName={mainCategoryItem ? mainCategoryItem.name : ""}
        subCategoryName={subCategoryItem ? subCategoryItem.name : ""}
      />
    </>
  );
}
export default IndustryReportForm;
