import { AnyAction } from "@reduxjs/toolkit";
import { Col, Form, Layout, Row } from "antd";
import PropTypes from "prop-types";
import { FC, useEffect } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";

import { LoginSupportVideoModal } from "@/features/customerSupport/components/LoginSupportVideoModal";
import { WatchLoginSupportVideoButton } from "@/features/customerSupport/components/WatchLoginSupportVideoButton";
import { PasswordResetPageLink } from "@/features/passwordReset/components/PasswordResetPageLink";
import { useBool } from "@/hooks/useBool";

import { ButtonSubmit } from "../../Components/atoms";
import {
  AlphaDriveImg,
  AlphaDriveImgContainer,
  AuthLayout,
  Banner,
  HorizontalLine,
  InputFormPassword,
  InputLoginForm,
  LoginBanner,
  LoginDiv,
  LoginLabel,
  LogoTitleLayout,
  VerifyHeaderBold,
} from "../../Components/molecules/auth/LoginLabel";
import AuthActions from "../../Redux/AuthRedux";
import useLoginScreen from "./hooks/useLoginScreen";
import { ILoginScreenProps } from "./types/ILoginScreenProps";
const { Content } = Layout;
import { ExternalLink } from "@/features/login/components/ExternalLink";

const LoginScreen: FC<ILoginScreenProps> = function (props) {
  useEffect(() => {
    if (
      Object.keys(props.auth.payload).length > 0 &&
      props.auth.payload.token
    ) {
      const route = localStorage.getItem("currentRoute");
      if (route && route.length > 0) {
        // @ts-expect-error TS2322
        window.location = route;
      }
    }
  }, []);
  const { state, onChange, onSubmit, isInputsValid } = useLoginScreen(props);
  const {
    bool: isVideoVisible,
    changeToFalse: setIsVideoVisibleToFalse,
    changeToTrue: setIsVideoVisibleToTrue,
  } = useBool();

  return (
    <AuthLayout className="layout">
      <Content className="authContent">
        <Row>
          <Banner>
            <LogoTitleLayout>
              <LoginBanner alt={"Banner"} src={"/login_banner.png"} />
              <LoginLabel>
                <FormattedMessage id="screen.label.comprehensive_support_tool_des" />
                <br />
                <FormattedMessage id="screen.label.incubation_suite" />
              </LoginLabel>
            </LogoTitleLayout>
            <AlphaDriveImgContainer>
              <div>
                <span>Powered by &nbsp;</span>
                <AlphaDriveImg alt={"alpha drive"} src={"/alpha_drive.png"} />
              </div>
            </AlphaDriveImgContainer>
            <ExternalLink href="https://resources.incubation-suite.com/policy/ext-modules-web-ja.pdf">
              <FormattedMessage id="screen.label.telecommunications_business_act_document_link" />
            </ExternalLink>
          </Banner>
          <Col>
            <div className="contentRoot">
              <LoginDiv>
                <VerifyHeaderBold>
                  <FormattedMessage id="screen.label.login" />
                </VerifyHeaderBold>
                <Form layout={"vertical"} onFinish={onSubmit}>
                  <Form.Item style={{ marginBottom: 0 }}>
                    <h4>
                      <FormattedMessage id="screens.title.email" />{" "}
                    </h4>
                    <InputLoginForm
                      required
                      onChange={onChange}
                      value={state.email}
                      // @ts-expect-error TS2769
                      error={"errors.email"}
                      id="email"
                      type="email"
                      data-testid="email"
                    />
                  </Form.Item>
                  <Form.Item>
                    <h4>
                      <FormattedMessage id="screen.label.password" />{" "}
                    </h4>
                    <InputFormPassword
                      required
                      onChange={onChange}
                      value={state.password}
                      // @ts-expect-error TS2769
                      error={"errors.password"}
                      id="password"
                      type="password"
                      data-testid="password"
                    />
                  </Form.Item>
                  <ButtonSubmit
                    disabled={isInputsValid()}
                    id="login"
                    htmlType="submit"
                    data-testid="submit"
                  >
                    <FormattedMessage id="screen.label.login" />
                  </ButtonSubmit>
                  <HorizontalLine />
                  <PasswordResetPageLink />
                </Form>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <WatchLoginSupportVideoButton
                    onClick={setIsVideoVisibleToTrue}
                  />
                </div>
              </LoginDiv>
            </div>
          </Col>
        </Row>
      </Content>
      <LoginSupportVideoModal
        isVisible={isVideoVisible}
        onClose={() => {
          setIsVideoVisibleToFalse();
        }}
      />
    </AuthLayout>
  );
};
const mapStateToProps = (state: { auth: any; errors: any }) => ({
  auth: state.auth,
  errors: state.errors,
});
const mapDispatchToProps = (dispatch: (arg0: AnyAction) => any) => {
  return {
    dispatchLoginRequest: (data: any) =>
      // @ts-expect-error TS2554
      dispatch(AuthActions.loginRequest(data)),
    dispatchIpLoginRequest: (data: any) =>
      // @ts-expect-error TS2554
      dispatch(AuthActions.loginByIpRequest(data)),
  };
};

LoginScreen.propTypes = {
  match: PropTypes.object,
  auth: PropTypes.object,
  location: PropTypes.object,
  // @ts-expect-error TS2322
  dispatchIpLoginRequest: PropTypes.func,
  // @ts-expect-error TS2322
  dispatchLoginRequest: PropTypes.func,
};
export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(LoginScreen),
);
