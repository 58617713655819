import { addProtocol } from "@/utils/http/addProtocol";

import styles from "./styles.module.scss";
import { EventThumbnailProps } from "./type";

export const EventThumbnail = ({ thumbnailUrl }: EventThumbnailProps) => {
  const url = addProtocol(thumbnailUrl);
  return (
    <div className={styles.event_thumbnail}>
      <div
        className={styles.event_thumbnail_blur}
        style={{
          backgroundImage: `url(${url})`,
        }}
      ></div>
      <img
        alt="event thumbnail"
        src={url}
        className={styles.event_thumbnail_image}
      ></img>
    </div>
  );
};
