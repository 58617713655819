import { notification } from "antd";
import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";

import HomeActions, { HomeTypes } from "../Redux/HomeRedux";
import { endpoints, parseEndpoint } from "../Services/endpoints";
import generateQueryParam from "../utils/generateQueryParam";

// @ts-expect-error TS7006
function* attemptFetchNotifications(api, { data, filter }) {
  const queryParams = generateQueryParam(data);
  let apiEnd = `notifications?${queryParams}`;
  if (filter && filter.length > 0) {
    apiEnd = `notifications?filter=${filter}&${queryParams}`;
  }
  // @ts-expect-error TS7057
  const notificationResponse = yield call(api.getRequest, apiEnd);
  if (notificationResponse.ok) {
    switch (notificationResponse.data.status) {
      case "success":
        if (data.notificationType === "admin") {
          yield put(
            HomeActions.homeGetNotificationsSuccess(
              // @ts-expect-error TS2554
              notificationResponse.data.data,
            ),
          );
        } else {
          yield put(
            HomeActions.homeGetOtherNotificationsSuccess(
              // @ts-expect-error TS2554
              notificationResponse.data.data,
            ),
          );
        }

        break;
      case "fail":
      case "error":
        if (typeof notificationResponse.data.data === "string") {
        }
        yield put(
          HomeActions.homeGetNotificationsFailure(
            // @ts-expect-error TS2554
            notificationResponse.data.data,
          ),
        );
        break;
      default:
        break;
    }
  } else {
    notification.error({
      message: notificationResponse.problem,
    });
    yield put(
      // @ts-expect-error TS2554
      HomeActions.homeGetNotificationsFailure(notificationResponse.problem),
    );
    if (notificationResponse.status === 401) {
      // @ts-expect-error TS2322
      window.location = "/login";
    }
  }
}

// @ts-expect-error TS7006
function* attemptTakeActionOnNotification(api, { data, id, message }) {
  // const queryParams = generateQueryParam(data);

  // @ts-expect-error TS7057
  const notificationResponse = yield call(
    api[data.method],
    data.url,
    data.data,
  );
  if (notificationResponse.ok) {
    switch (notificationResponse.data.status) {
      case "success":
        yield put(HomeActions.homeNotificationActionSuccess());

        yield put(
          // @ts-expect-error TS2554
          HomeActions.homeGetNotificationsRequest({
            page: 0,
            notificationType: "admin",
          }),
        );
        yield put(
          // @ts-expect-error TS2554
          HomeActions.homeGetOtherNotificationsRequest({
            page: 0,
            notificationType: "other",
          }),
        );

        yield call(
          api.putRequest,
          parseEndpoint(endpoints.updateNotification, { id }),
          {
            isRead: true,
          },
        );
        notification.success({
          message: message,
        });
        break;
      case "fail":
      case "error":
        if (typeof notificationResponse.data.data === "string") {
        }
        yield put(
          HomeActions.homeNotificationActionFailure(
            // @ts-expect-error TS2554
            notificationResponse.data.data,
          ),
        );
        break;
      default:
        break;
    }
  } else {
    notification.error({
      message: notificationResponse.problem,
    });
    yield put(
      // @ts-expect-error TS2554
      HomeActions.homeNotificationActionFailure(notificationResponse.problem),
    );

    if (notificationResponse.status === 401) {
      // @ts-expect-error TS2322
      window.location = "/login";
    }
  }
}

// @ts-expect-error TS7006
function* homeSaga(api) {
  yield all([
    takeEvery(
      // @ts-expect-error TS2345
      HomeTypes.HOME_GET_NOTIFICATIONS_REQUEST,
      attemptFetchNotifications,
      api,
    ),
    takeLatest(
      // @ts-expect-error TS2345
      HomeTypes.HOME_NOTIFICATION_ACTION_REQUEST,
      attemptTakeActionOnNotification,
      api,
    ),
  ]);
}

export default homeSaga;
