import type { Action } from "@reduxjs/toolkit";
import { createActions, createReducer } from "reduxsauce";
import Immutable from "seamless-immutable";

import { User } from "@/../types/User";

import { ToAction, ToActionType } from "./types";

/* ------------- Types and Action Creators ------------- */
type AuthActionCreators = {
  loginRequest: () => Action<"LOGIN_REQUEST">;
  loginSuccess: (user: User) => { type: "LOGIN_SUCCESS" };
  loginFailure: () => { type: "LOGIN_FAILURE" };

  loginByIpRequest: () => { type: "LOGIN_BY_IP_REQUEST" };

  forgotPasswordRequest: () => { type: "FORGOT_PASSWORD_REQUEST" };
  forgotPasswordSuccess: () => { type: "FORGOT_PASSWORD_SUCCESS" };
  forgotPasswordFailure: () => { type: "FORGOT_PASSWORD_FAILURE" };

  resetPasswordRequest: () => { type: "RESET_PASSWORD_REQUEST" };
  resetPasswordSuccess: () => { type: "RESET_PASSWORD_SUCCESS" };
  resetPasswordFailure: () => { type: "RESET_PASSWORD_FAILURE" };

  loginSetAuthToken: (token: string) => { type: "LOGIN_SET_AUTH_TOKEN" };
  loginSetBaseUrl: (url: string) => { type: "LOGIN_SET_BASE_URL" };

  logout: () => { type: "LOGOUT" };
};

type AuthAction = ToAction<AuthActionCreators>;
type AuthActionType = ToActionType<AuthAction>;

const { Types, Creators } = createActions<AuthActionType, AuthActionCreators>({
  loginRequest: ["data"],
  loginSuccess: ["payload"],
  loginFailure: ["errors"],

  loginByIpRequest: ["data"],

  forgotPasswordRequest: ["data", "message"],
  forgotPasswordSuccess: ["payload"],
  forgotPasswordFailure: ["errors"],

  resetPasswordRequest: ["data", "message"],
  resetPasswordSuccess: ["payload"],
  resetPasswordFailure: ["errors"],

  changePasswordRequest: ["data"],
  changePasswordSuccess: ["payload"],
  changePasswordFailure: ["errors"],

  loginSetAuthToken: ["token"],
  loginSetBaseUrl: ["url"],
  logout: null,
});

export const AuthTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
type AuthState = {
  fetching: boolean;
  payload: unknown;
  error?: null | Record<string, unknown>;
  /**おそらくerrorのTypoで、実際には使用していない */
  errors: unknown;
};
export const INITIAL_STATE = Immutable<AuthState>({
  fetching: false,
  payload: {},
  errors: {},
});
type ImmAuthState = typeof INITIAL_STATE;

/* ------------- Reducers ------------- */
const request = (state: ImmAuthState) => state.merge({ fetching: true });

const loginSetAuthToken = (state: ImmAuthState) => state;

const loginSetBaseUrl = (state: ImmAuthState) => state;

// @ts-expect-error TS7031
const success = (state: ImmAuthState, { payload }) => {
  return state.merge({ fetching: false, error: null, payload });
};

const logout = (state: ImmAuthState) => state;

// @ts-expect-error TS7031
const failure = (state: ImmAuthState, { error }) =>
  state.merge({ fetching: false, error });

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer<ImmAuthState, AuthAction>(INITIAL_STATE, {
  [Types.LOGIN_REQUEST]: request,
  [Types.LOGIN_SUCCESS]: success,
  [Types.LOGIN_FAILURE]: failure,

  [Types.LOGIN_BY_IP_REQUEST]: request,

  [Types.FORGOT_PASSWORD_REQUEST]: request,
  [Types.FORGOT_PASSWORD_SUCCESS]: success,
  [Types.FORGOT_PASSWORD_FAILURE]: failure,

  [Types.RESET_PASSWORD_REQUEST]: request,
  [Types.RESET_PASSWORD_SUCCESS]: success,
  [Types.RESET_PASSWORD_FAILURE]: failure,

  [Types.LOGIN_SET_AUTH_TOKEN]: loginSetAuthToken,
  [Types.LOGIN_SET_BASE_URL]: loginSetBaseUrl,
  [Types.LOGOUT]: logout,
});
