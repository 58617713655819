import { TableOutlined } from "@ant-design/icons";
import styled from "styled-components";

export const ProgramTable = styled(TableOutlined)`
  font-size: 20px;
  margin-top: 4px;
  border-color: #f0f0f0;
  border-width: 1px;
  border-style: solid;
  border-radius: 2px;
  padding-left: 3px;
  padding-right: 3px;
`;
