import { AtomicBlockUtils } from "draft-js";
import PropTypes from "prop-types";
import { Component } from "react";

import LayoutComponent from "./Component";

class Embedded extends Component {
  static propTypes = {
    editorState: PropTypes.any,
    onChange: PropTypes.func.isRequired,
    modalHandler: PropTypes.object,
    config: PropTypes.object,
    translations: PropTypes.object,
  };

  state = {
    expanded: false,
  };

  componentDidMount() {
    const { modalHandler } = this.props;
    modalHandler.registerCallBack(this.expandCollapse);
  }

  componentWillUnmount() {
    const { modalHandler } = this.props;
    modalHandler.deregisterCallBack(this.expandCollapse);
  }

  onExpandEvent = () => {
    this.signalExpanded = !this.state.expanded;
  };

  expandCollapse = () => {
    this.setState({
      expanded: this.signalExpanded,
    });
    this.signalExpanded = false;
  };

  doExpand = () => {
    this.setState({
      expanded: true,
    });
  };

  doCollapse = () => {
    this.setState({
      expanded: false,
    });
  };

  isPromise = (promise) => {
    return !!promise && typeof promise.then === "function";
  };

  addEmbeddedLink = async (embeddedLink, height, width) => {
    const {
      editorState,
      onChange,
      config: { embedCallback },
    } = this.props;
    let src;
    if (embedCallback) {
      src = await embedCallback(embeddedLink);
    } else {
      src = embeddedLink;
    }
    if (src && src.length > 0) {
      const entityKey = editorState
        .getCurrentContent()
        .createEntity("EMBEDDED_LINK", "MUTABLE", {
          src,
          height,
          width,
        })
        .getLastCreatedEntityKey();
      const newEditorState = AtomicBlockUtils.insertAtomicBlock(
        editorState,
        entityKey,
        " ",
      );
      onChange(newEditorState);
    }
    this.doCollapse();
  };

  render() {
    const { config, translations } = this.props;
    const { expanded } = this.state;
    const EmbeddedComponent = config.component || LayoutComponent;
    return (
      <EmbeddedComponent
        config={config}
        translations={translations}
        onChange={this.addEmbeddedLink}
        expanded={expanded}
        onExpandEvent={this.onExpandEvent}
        doExpand={this.doExpand}
        doCollapse={this.doCollapse}
      />
    );
  }
}

export default Embedded;

// todo: make default heights configurable
