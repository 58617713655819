import { Col, Row } from "antd";
import { FC } from "react";
import { FormattedMessage } from "react-intl";

import {
  IconRightArrow,
  LargeVideoDesc,
  LargeVideoName,
  LargeVideoRoot,
  LargeVideoThumbnail,
} from "../../Components/atoms";
import { defaultThumbnailImg } from "../../utils/constants";
import useDisplayAllVideosLargeCategoryScreen from "./hooks/useDisplayAllVideosLargeCategoryScreen";
import { IDisplayAllVideosLargeCategoryScreenProps } from "./types/IDisplayAllVideosLargeCategoryScreenProps";

const DisplayAllVideosLargeCategoryScreen: FC<IDisplayAllVideosLargeCategoryScreenProps> =
  function (props: IDisplayAllVideosLargeCategoryScreenProps) {
    const { state, getLargeCategoryName, windowWidth } =
      useDisplayAllVideosLargeCategoryScreen(props);
    const _renderSmallCategories = () => {
      const { smallCategories } = state;
      if (smallCategories && Array.isArray(smallCategories)) {
        return smallCategories.map((sm) => {
          let thumbnail = sm.thumbnail;
          const videosCount = Array.isArray(sm.Videos) ? sm.Videos.length : 0;
          if (
            thumbnail == null ||
            typeof thumbnail === "undefined" ||
            thumbnail?.length === 0
          ) {
            if (Array.isArray(sm.Videos) && sm.Videos.length > 0) {
              // id: sort
              const videos = Object.keys(sm.Videos).map((e) => ({
                key: sm.Videos[e].id,
                value: sm.Videos[e],
              }));
              videos.sort(function (a, b) {
                if (a.key < b.key) return -1;
                if (a.key > b.key) return 1;
                return 0;
              });
              for (const key in videos) {
                const video = videos[key].value;
                if (video.imageThumbnail) {
                  thumbnail = video.imageThumbnail;
                  break;
                }
              }
            }
            if (
              thumbnail == null ||
              typeof thumbnail === "undefined" ||
              thumbnail?.length === 0
            ) {
              thumbnail = defaultThumbnailImg;
            }
          }
          return (
            <Col key={sm.id} span={24}>
              <LargeVideoRoot
                onClick={() => {
                  // @ts-expect-error TS18048
                  props.history.push(
                    `/dashboard/videos-small-category/${sm.id}/${sm.name}`,
                  );
                }}
              >
                <Row
                  // @ts-expect-error TS2322
                  type="flex"
                  style={{ alignItems: "center", flexWrap: "nowrap" }}
                  justify="space-between"
                >
                  <div
                    style={{ display: "flex", flexWrap: "nowrap", gap: "12px" }}
                  >
                    <Col>
                      <LargeVideoThumbnail alt={sm.name} src={thumbnail} />
                    </Col>
                    <Col>
                      <LargeVideoName>
                        <h3
                          style={{
                            fontWeight: "bold",
                            fontSize: "18px",
                            lineHeight: "26.06px",
                          }}
                        >
                          {sm.name}
                        </h3>
                      </LargeVideoName>

                      <p
                        style={{
                          fontSize: "12px",
                          fontWeight: "normal",
                          lineHeight: "24px",
                        }}
                      >
                        <FormattedMessage
                          id="screen.label.video_count"
                          values={{ count: videosCount }}
                        />
                      </p>

                      <div>
                        <LargeVideoDesc>{sm.description}</LargeVideoDesc>
                      </div>
                    </Col>
                  </div>
                  <div style={{ color: "#B5B5B5" }}>{IconRightArrow}</div>
                </Row>
              </LargeVideoRoot>
            </Col>
          );
        });
      }
    }; //

    return (
      <>
        <Row>
          <Col>
            <h2>{getLargeCategoryName()}</h2>
          </Col>
        </Row>

        <Row>{_renderSmallCategories()}</Row>
      </>
    );
  };

export default DisplayAllVideosLargeCategoryScreen;
