import { useCallback, useEffect, useRef, useState } from "react";

import { mimeType } from "@/const/mimeType";
import { ImagePreview } from "@/shared/images/components/ImagePreview";
import { FileDroppableArea } from "@/shared/upload/components/FileDroppableArea";
import { useFileHandler } from "@/shared/upload/hooks/useFileHandler";

import { usePreviewFile } from "./hooks";
import styles from "./styles.module.scss";
import { ImageUploadAreaProps } from "./type";

export const ImageUploadArea = ({
  initialImage,
  ContentComponent,
  onChange,
}: ImageUploadAreaProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [image, setImage] = useState<File | string | null>(null);
  const { file, handleAttach, handleDrop, initFile, setAttachable } =
    useFileHandler([mimeType.jpeg, mimeType.png]);
  const { previewFile } = usePreviewFile();

  const handleRemoveFile = useCallback(() => {
    initFile();
    setAttachable(true);
    setImage(null);
    onChange(null);
  }, [initFile, setAttachable, onChange]);

  const handleUploadButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    inputRef.current?.click();
  };

  useEffect(() => {
    if (file) {
      previewFile(file, setImage);
      onChange(file);
    }
  }, [previewFile, onChange, file]);
  useEffect(() => {
    setImage(initialImage as string | null);
  }, [initialImage]);

  return (
    <FileDroppableArea
      handleAttach={handleAttach}
      handleDrop={handleDrop}
      accept=".jpeg, .png"
      inputRef={inputRef}
      dropAreaClass={styles.file_upload_wrapper}
    >
      {image ? (
        <ImagePreview image={image} removeFile={handleRemoveFile} />
      ) : (
        <ContentComponent onClick={handleUploadButtonClick} />
      )}
    </FileDroppableArea>
  );
};
