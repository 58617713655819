import { Form, Input } from "antd";
import { useCallback, useState } from "react";
import { useIntl } from "react-intl";

import { VerticalSortableItem } from "../../../../Components/sortable/VerticalSortableItem";
import { AntdButton } from "../../../../shared/buttons/components/AntdButton";
import { DeleteIconButton } from "../../../../shared/buttons/components/DeleteIconButton";
import { EditIconButton } from "../../../../shared/buttons/components/EditIconButton";
import { ExaminationPhaseUpdateActiveButton } from "../ExaminationPhaseUpdateActiveButton";
import styles from "./styles.module.scss";
import { ExaminationPhaseItemProps } from "./type";

export const ExaminationPhaseItem = ({
  examinationPhase,
  onUpdateExaminationPhaseName,
  onClickDeleteButton,
  onClickActiveUpdateButton,
  isSortable,
  index,
}: ExaminationPhaseItemProps) => {
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [examinationPhaseName, setExaminationPhaseName] = useState<string>(
    examinationPhase.name,
  );
  const intl = useIntl();

  const saveButtonLabel = intl.formatMessage({ id: "screen.label.storage" });
  const saveCancelButtonLabel = intl.formatMessage({
    id: "screen.label.cancel",
  });

  const onEdit = useCallback(() => {
    setIsEditable(true);
  }, []);

  const onCancel = useCallback(() => {
    setIsEditable(false);
    setExaminationPhaseName(examinationPhase.name);
  }, [examinationPhase.name]);

  const onChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      setExaminationPhaseName(e.target.value);
    },
    [],
  );

  const updateExaminationPhaseName = useCallback(
    (examinationPhaseId: number, examinationPhaseName: string) => {
      onUpdateExaminationPhaseName(examinationPhaseId, examinationPhaseName);
      setIsEditable(false);
      setExaminationPhaseName(examinationPhase.name);
    },
    [examinationPhase, onUpdateExaminationPhaseName],
  );

  return (
    <VerticalSortableItem
      item={examinationPhase}
      index={index}
      className={styles.item}
    >
      <div
        className={`${styles.item_container} ${
          examinationPhase.isActive ? styles.active : ""
        } ${isSortable ? styles.sortable : ""}`}
      >
        <div className={styles.content_container}>
          <div className={styles.left_container}>
            <div className={styles.item_header}>
              <ExaminationPhaseUpdateActiveButton
                onUpdateActive={() => {
                  onClickActiveUpdateButton(examinationPhase.id);
                }}
                isActive={examinationPhase.isActive}
              />
            </div>
            <div className={styles.form_container}>
              {isEditable ? (
                <Form>
                  <div className={styles.input_container}>
                    <Input
                      onChange={onChange}
                      defaultValue={examinationPhase.name}
                    />
                    <div className={styles.btn_container}>
                      <AntdButton
                        category="primary"
                        className={styles.save_btn}
                        onClick={() => {
                          updateExaminationPhaseName(
                            examinationPhase.id,
                            examinationPhaseName,
                          );
                        }}
                      >
                        {saveButtonLabel}
                      </AntdButton>
                      <AntdButton
                        category="secondary"
                        className={styles.cancel_btn}
                        onClick={onCancel}
                      >
                        {saveCancelButtonLabel}
                      </AntdButton>
                    </div>
                  </div>
                </Form>
              ) : (
                <div className={styles.phase_name_text}>
                  {examinationPhase.name}
                </div>
              )}
            </div>
          </div>
          {!isEditable && (
            <div className={styles.action_btn_container}>
              <EditIconButton
                id={`edit-phase-button-${examinationPhase.id}`}
                alt={`edit-phase-button-${examinationPhase.id}`}
                onClick={onEdit}
                className={styles.edit_icon}
              />
              <DeleteIconButton
                id={`delete-phase-button-${examinationPhase.id}`}
                alt={`delete-phase-button-${examinationPhase.id}`}
                onClick={() => {
                  onClickDeleteButton(examinationPhase.id);
                }}
                className={styles.delete_icon}
              />
            </div>
          )}
        </div>
      </div>
    </VerticalSortableItem>
  );
};
