import { Button } from "antd";
import styled from "styled-components";

export const BatchSelectModalRoot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const BatchSelectComponent = styled.div`
  width: 75%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const ProjectGhostButton = styled(Button)`
  margin-top: 24px;
  background-color: #00bf76;
  width: 30%;
  float: left;
`;
