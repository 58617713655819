import { JsendError } from "../../../types/JSendObject";
import { api } from "../../Saga";
import { endpoints, parseEndpoint } from "../endpoints";
import { isFailedResponse } from "../isFailedResponse";
import {
  EventSurveyData,
  EventSurveyResponse,
  FCFSEventReminderData,
  FCFSEventReminderResponse,
  LotteryEventReminderData,
  LotteryEventReminderResponse,
} from "./type";
export const useEventReminder = () => {
  const createLotteryEventReminder = async (
    id: number,
    data: LotteryEventReminderData,
  ) => {
    const url = parseEndpoint(endpoints.eventReminder, {
      eventId: id,
    });
    const response = await api.postRequest<
      LotteryEventReminderData,
      LotteryEventReminderResponse,
      JsendError
    >(url, data);
    // @ts-expect-error TS2345
    const isFailed = isFailedResponse(response);

    return { response, isFailed };
  };

  const createFCFSEventReminder = async (
    id: number,
    data: FCFSEventReminderData,
  ) => {
    const url = parseEndpoint(endpoints.eventFcfsReminder, {
      eventId: id,
    });

    const response = await api.postRequest<
      FCFSEventReminderData,
      FCFSEventReminderResponse,
      JsendError
    >(url, data);
    // @ts-expect-error TS2345
    const isFailed = isFailedResponse(response);

    return { response, isFailed };
  };

  const createEventSurvey = async (id: number, data: EventSurveyData) => {
    const url = parseEndpoint(endpoints.eventSurvey, {
      eventId: id,
    });
    const response = await api.postRequest<
      EventSurveyData,
      EventSurveyResponse,
      JsendError
    >(url, data);
    // @ts-expect-error TS2345
    const isFailed = isFailedResponse(response);

    return { response, isFailed };
  };

  return {
    createLotteryEventReminder,
    createFCFSEventReminder,
    createEventSurvey,
  };
};
