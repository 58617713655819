import { useCallback } from "react";

import { BatchSelectBoxProps } from "@/features/batch/components/BatchSelectBox/type";
import { LabelSelect } from "@/shared/selectbox/components/LabelSelect/component";
import { SelectOption } from "@/shared/selectbox/components/LabelSelect/type";

export const BatchSelectBox = ({
  batches,
  batchId,
  onChangeBatchSelect,
}: BatchSelectBoxProps) => {
  const onSelect = useCallback(
    (newValue: SelectOption) => {
      if (!newValue.value) return;
      onChangeBatchSelect(newValue.value);
    },
    [onChangeBatchSelect],
  );
  const options = batches.map((batch) => ({
    value: batch.id,
    label: batch.name,
  }));

  const selectedOption = options.find((option) => option.value === batchId);

  return (
    <LabelSelect
      maxWidth="600px"
      value={selectedOption}
      type="primary"
      id="program-select-box"
      options={options}
      onSelect={onSelect}
    />
  );
};
