type DescriptionProps = {
  description: string;
};

export const Description = ({ description }: DescriptionProps) => {
  return (
    <span
      style={{
        whiteSpace: "pre-line",
      }}
    >
      {description}
    </span>
  );
};
