import { Button, Popconfirm, Row } from "antd";
import React, { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import { Event } from "../../../../types/Event";

type Props = {
  event: Event;
  eventMemberPageLink: (event: Event) => string;
  isExpired: (event: Event) => boolean;
  onCreateEventNotification: (event: Event) => void;
  onCreateEventSurvey: (event: Event) => void;
  onConfirmDelete: (event: Event) => void;
  onToggleAcceptMember: (event: Event) => void;
};
export const EventActionButtons: React.FC<Props> = ({
  event,
  eventMemberPageLink,
  isExpired,
  onCreateEventNotification,
  onCreateEventSurvey,
  onConfirmDelete,
  onToggleAcceptMember,
}) => {
  const confirmDelete = useCallback(() => {
    onConfirmDelete(event);
  }, [event, onConfirmDelete]);

  const createEventNotification = useCallback(() => {
    onCreateEventNotification(event);
  }, [event, onCreateEventNotification]);

  const toggleAcceptMember = useCallback(() => {
    onToggleAcceptMember(event);
  }, [event, onToggleAcceptMember]);

  const createEventSurvey = useCallback(() => {
    onCreateEventSurvey(event);
  }, [event, onCreateEventSurvey]);

  return (
    <>
      <Row>
        <Popconfirm
          title={<FormattedMessage id="screens.events.confirm_delete_event" />}
          onConfirm={confirmDelete}
          okText={<FormattedMessage id="screen.label.yes" />}
          cancelText={<FormattedMessage id="screen.label.no" />}
          okButtonProps={{
            className: "popconfirm-del-btn",
          }}
        >
          <Button className={"delete-event-btn"} type="link">
            <FormattedMessage id="screen.label.delete" />
          </Button>
        </Popconfirm>
        {!isExpired(event) && (
          <Button type="link" onClick={toggleAcceptMember}>
            {event.isAcceptingMembers ? (
              <FormattedMessage id="screen.label.wanted_stop" />
            ) : (
              <FormattedMessage id="screen.label.resume_recruitment" />
            )}
          </Button>
        )}
        <Button type="link" className={"btn-view-event-members"}>
          <Link to={eventMemberPageLink(event)}>
            <FormattedMessage id="screen.label.participant_list_display" />
          </Link>
        </Button>
      </Row>
      <Row>
        <Button type="link" onClick={createEventNotification}>
          <FormattedMessage id="screens.events.create_event_notification" />
        </Button>
        <Button type="link" onClick={createEventSurvey}>
          <FormattedMessage id="screen.label.event_questionnaire_created" />
        </Button>
      </Row>
    </>
  );
};
