import React from "react";
import { Redirect, Route } from "react-router-dom";

import { withContext } from "../../Provider";
import { endpoints, parseEndpoint } from "../../Services/endpoints";
import { validUUIDV4 } from "../../utils/common";
import { UUIDAdminRouterProps } from "./types/UUIDAdminRouterProps";

const UUIDAdminRouter: React.FC<UUIDAdminRouterProps> = ({
  component: Component,
  subRouter,
  context,
  ...rest
}: UUIDAdminRouterProps) => {
  const user = context.user;
  const apiRequest = context.apiRequest;
  return (
    <Route
      {...rest}
      render={(props) => {
        const { location, match, history } = props;
        const { params, path } = match;
        const { id, ...restIds } = params;
        if (user.type === "admin") {
          if (validUUIDV4(id)) {
            return <Component {...props} context={context} />;
          } else {
            const requestBuilder = {
              method: "getRequest",
              url: parseEndpoint(endpoints.subRouter, {
                subRouter,
                // @ts-expect-error TS2322
                id,
              }),
            };
            // @ts-expect-error TS2345
            apiRequest(requestBuilder, ({ data }) => {
              //@ts-expect-error 要API型定義
              let redirectPath = path.replace(/:id/g, data.uuid);
              if (restIds && Object.keys(restIds).length > 0) {
                Object.keys(restIds).forEach((key, index) => {
                  const regex = new RegExp(`:${key}`, "g");
                  // @ts-expect-error TS2769
                  redirectPath = redirectPath.replace(regex, restIds[key]);
                });
              }
              history.replace(redirectPath);
            });
          }
        } else {
          return (
            <Redirect
              to={{
                pathname: "/dashboard",
                state: { from: location },
              }}
            />
          );
        }
      }}
    />
  );
};
export default withContext(UUIDAdminRouter);
