import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { Form } from "antd";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { ExaminationDetail } from "../../../../types/ExaminationDetail";
import { ExaminationResult } from "../../../../types/SubmittedProject";
import { getResultTypes } from "../../../utils/common";
import { ButtonOption, HorizonalLayout, Line, LineBorder } from "../../atoms";
import { Option, Select } from "../dropdown";
import { TextAreaDefault } from "../textfield";
import { VerticalTitle } from "../titles";
import { SingleSubmittedProjectReview } from "./SingleSubmittedProjectReview";

type AddReviewProps = {
  examinationDetail: ExaminationDetail;
  updateEvalutationDetail: (index: number, key: string, value: string) => any;
  setExaminationDetail: React.Dispatch<
    React.SetStateAction<ExaminationDetail | undefined>
  >;
  handleChanged: () => void;
};
export const AddReview = (props: AddReviewProps) => {
  const intl = useIntl();
  const {
    examinationDetail,
    updateEvalutationDetail,
    setExaminationDetail,
    handleChanged,
  } = props;
  const { evaluations } = examinationDetail;
  // @ts-expect-error TS2339
  const allUsers = useSelector((state) => state.user.allUsersForMentionPayload);
  // @ts-expect-error TS2339
  const user = useSelector((state) => state.auth.payload);
  // @ts-expect-error TS2339
  const companyDetails = useSelector((state) => state.company.companyPayload);

  const getSingleEmptyEvaluation = () => {
    return {
      companyId: companyDetails.id,
      feedback: "",
      review: "",
      score: 0,
      userId: parseInt(user.id),
      examinationFlags: [],
    };
  };

  // @ts-expect-error TS7006
  const getTotalScore = (evaluations) => {
    let totalScore = 0;
    for (let i = 0; i < evaluations.length; i++) {
      const singleScore = evaluations[i].score;

      if (typeof singleScore === "string" && singleScore.length <= 0) {
        continue;
      }
      if (!isNaN(singleScore)) {
        totalScore = totalScore + parseFloat(singleScore);
      }
    }
    return totalScore;
  };

  // @ts-expect-error TS7006
  const getExaminationFlagStatus = (examinersEvaluations) => {
    const allExaminerFlags = [];
    for (let i = 0; i < examinersEvaluations.length; i++) {
      allExaminerFlags.push(...examinersEvaluations[i].examinationFlags);
    }
    const counts = {};

    for (let i = 0; i < allExaminerFlags.length; i++) {
      const num = allExaminerFlags[i];
      // @ts-expect-error TS7053
      counts[num] = counts[num] ? counts[num] + 1 : 1;
    }
    const companyExaminations = getExaminationOptions();
    if (Array.isArray(companyExaminations)) {
      return companyExaminations.map((singleCompanyEvaluations, idx) => {
        const examName = singleCompanyEvaluations;
        // @ts-expect-error TS7053
        const score = counts[singleCompanyEvaluations]
          ? // @ts-expect-error TS7053
            counts[singleCompanyEvaluations]
          : 0;
        const total = examinersEvaluations.length;
        const t = `${score}/${total}`;
        return (
          <div
            style={{
              padding: "3vh 3vw 0vh 1vw",
            }}
            key={idx}
          >
            <span style={{ marginRight: "30%" }}>{examName}</span>
            <span>{t}</span>
          </div>
        );
      });
    }
    return [];
  };
  const getExaminationOptions = () => {
    if (
      companyDetails &&
      Array.isArray(companyDetails.examinationStatusOptions)
    ) {
      return companyDetails.examinationStatusOptions;
    }
    return [];
  };

  const onChangeExaminationResult = (value: ExaminationResult) => {
    examinationDetail.examinationResult = value;
    setExaminationDetail({ ...examinationDetail });
    handleChanged();
  };

  const onChangeFeedbackComment = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    examinationDetail.feedbackComment = event.target.value;
    setExaminationDetail({ ...examinationDetail });
    handleChanged();
  };

  return (
    <div>
      <br />
      {evaluations.map((evaluation, index) => {
        return (
          <div key={evaluation.id}>
            <br />
            <LineBorder
              style={{
                width: "65%",
              }}
            >
              <HorizonalLayout
                style={{
                  justifyContent: "flex-end",
                }}
              >
                <button
                  style={{
                    background: "transparent",
                    border: "none",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    examinationDetail.evaluations = evaluations.filter(
                      (_, i) => {
                        return i !== index;
                      },
                    );
                    setExaminationDetail({
                      ...examinationDetail,
                    });
                  }}
                >
                  <CloseOutlined style={{ fontSize: "1.5rem" }} />
                </button>
              </HorizonalLayout>

              <SingleSubmittedProjectReview
                users={allUsers}
                updateEvalutationDetail={updateEvalutationDetail}
                // @ts-expect-error TS2322
                currentUser={user}
                index={index}
                evaluation={evaluation}
                examinationFlags={getExaminationOptions()}
              />
            </LineBorder>
            <br />
          </div>
        );
      })}

      <Form.Item>
        <ButtonOption
          style={{
            marginTop: "7vh",
            marginBottom: "7vh",
          }}
          onClick={() => {
            examinationDetail.evaluations.push(getSingleEmptyEvaluation());

            setExaminationDetail({ ...examinationDetail });
          }}
          icon={<PlusOutlined />}
        >
          <FormattedMessage id={"screens.submission.add_new_evaluation"} />
        </ButtonOption>
      </Form.Item>

      <Line />
      <br />

      {evaluations.length > 0 ? (
        <VerticalTitle
          title={intl.formatMessage({
            id: "screen.label.examination_point_average",
          })}
        >
          <p>
            {getTotalScore(evaluations) / evaluations.length} (
            {intl.formatMessage(
              {
                id: "screen.label.totalJudgesCount",
              },
              {
                totalEvaluations: evaluations.length,
              },
            )}
            )
          </p>
        </VerticalTitle>
      ) : null}

      <VerticalTitle
        title={intl.formatMessage({
          id: "screen.label.final_review_points",
        })}
      >
        <p>
          {getTotalScore(evaluations)} (
          {intl.formatMessage(
            {
              id: "screen.label.totalJudgesCount",
            },
            {
              totalEvaluations: evaluations.length,
            },
          )}
          )
        </p>
      </VerticalTitle>

      <VerticalTitle
        title={intl.formatMessage({
          id: "screen.label.examination_outcome",
        })}
      >
        <Select
          defaultValue={
            examinationDetail.examinationResult === "BLANK"
              ? null
              : examinationDetail.examinationResult
          }
          onChange={onChangeExaminationResult}
        >
          {getResultTypes().map((result, index) => (
            <Option key={index} value={result.value}>
              <FormattedMessage id={result.label} />
            </Option>
          ))}
        </Select>
      </VerticalTitle>
      <VerticalTitle
        title={intl.formatMessage({
          id: "screen.label.feedback_comments",
        })}
      >
        <TextAreaDefault
          onChange={onChangeFeedbackComment}
          placeholder={intl.formatMessage({
            id: "screen.label.feedback_comments",
          })}
          value={examinationDetail.feedbackComment}
          style={{
            width: "60%",
          }}
        />
      </VerticalTitle>
      <p>
        <FormattedMessage id={"screen.label.feedback_comments_des"} />{" "}
      </p>

      <div
        style={{
          backgroundColor: "#EBEBEB",
          padding: "3vh 3vw 0vh 1vw",
        }}
      >
        <HorizonalLayout>
          <p
            style={{
              marginRight: "33%",
            }}
          >
            <FormattedMessage id={"screen.submission.flag_name"} />
          </p>
          <p>
            <FormattedMessage id={"screen.submission.number_of_inputs"} />
          </p>
        </HorizonalLayout>
      </div>
      {getExaminationFlagStatus(evaluations)}
    </div>
  );
};

AddReview.propTypes = {
  submission: PropTypes.any,
  updateEvalutationDetail: PropTypes.func.isRequired,
};
