import { Divider } from "antd";

import styles from "./styles.module.scss";
import { ItemWithUnderlineProps } from "./type";

export const ItemWithUnderline = ({
  title,
  children,
}: React.PropsWithChildren<ItemWithUnderlineProps>) => {
  return (
    <div>
      <div className={styles.item}>
        <p className={styles.label}>{title}</p>
        <div>{children}</div>
      </div>
      <Divider className={styles.divider} />
    </div>
  );
};
