import { useIntl } from "react-intl";

import { SecondaryButton } from "@/shared/buttons/components/SecondaryButton";

import styles from "./styles.module.scss";
import { EventThumbnailUploadButtonProps } from "./type";

export const EventThumbnailUploadButton = ({
  onClick,
}: EventThumbnailUploadButtonProps) => {
  const intl = useIntl();
  const uploadThumbnailMessage = intl.formatMessage({
    id: "screen.label.upload_thumbnail",
  });
  const selectFileMessage = intl.formatMessage({
    id: "screen.label.select_file",
  });
  const recommendedSizeMessage = intl.formatMessage(
    {
      id: "screen.label.recommended_size",
    },
    {
      size: "1920 x 1080",
    },
  );
  const optionalMessage = intl.formatMessage({
    id: "screen.label.optional",
  });
  return (
    <div className={styles.container}>
      <SecondaryButton onClick={onClick} className={styles.file_upload_button}>
        {uploadThumbnailMessage}
      </SecondaryButton>
      <div className={styles.file_upload_paragraph}>
        <p>
          {selectFileMessage}({optionalMessage})
        </p>
        <p>{recommendedSizeMessage}</p>
      </div>
    </div>
  );
};
