import { Modal } from "antd";
import ReactPlayer from "react-player";

import styles from "@/features/customerSupport/components/LoginSupportVideoModal/styles.module.scss";

import { LoginSupportVideoModalProps } from "./type";

export const LoginSupportVideoModal = ({
  isVisible,
  onClose,
}: LoginSupportVideoModalProps) => {
  const videoUrl = process.env.REACT_APP_LOGIN_SUPPORT_VIDEO_URL;
  return (
    <Modal
      data-testid="login-support-video-modal"
      visible={isVisible}
      footer={null}
      onCancel={onClose}
      bodyStyle={{
        padding: 0,
      }}
      className={styles.modal}
      destroyOnClose={true}
    >
      <ReactPlayer
        data-testid="login-support-video-player"
        url={videoUrl}
        width={styles.player_width}
        height={styles.player_height}
        controls={true}
      />
    </Modal>
  );
};
