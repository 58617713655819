import { DatePicker } from "antd";
import type { Moment } from "moment";
import moment from "moment";
import { useEffect, useRef, useState } from "react";

type Props = {
  containerName: string;
  format: string;
  defaultValue: [Moment, Moment];
  onChange: (values: [Moment | null, Moment | null]) => void;
};
export default function RangePicker(props: Props) {
  const { containerName, format, defaultValue, onChange } = props;
  const [pickerIsFocused, setPickerIsFocused] = useState(false);

  const customRangePickerName = `custom-range-picker-${containerName}`;

  const startValueRef = useRef<Moment | null>(null);
  const endValueRef = useRef<Moment | null>(null);

  useEffect(() => {
    if (defaultValue && defaultValue.length > 0) {
      if (defaultValue[0] && defaultValue[0].isValid()) {
        startValueRef.current = defaultValue[0];
      }
      if (defaultValue[1] && defaultValue[1].isValid()) {
        endValueRef.current = defaultValue[1];
      }
    }
  }, [defaultValue]);

  useEffect(() => {
    const querySelector = `.${customRangePickerName} .ant-picker-range .ant-picker-input input`;
    const [startInput, endInput] = document.querySelectorAll(querySelector);
    if (startInput) {
      startInput.addEventListener("keyup", (event: any) => {
        // @ts-expect-error TS2339
        if (moment(startInput.value, format).isValid()) {
          // @ts-expect-error TS2339
          startValueRef.current = moment(startInput.value, format);
        }
      });
    }
    if (endInput) {
      endInput.addEventListener("keyup", (event: any) => {
        // @ts-expect-error TS2339
        if (moment(endInput.value, format).isValid()) {
          // @ts-expect-error TS2339
          endValueRef.current = moment(endInput.value, format);
        }
      });
    }
  }, [pickerIsFocused, format, customRangePickerName]);

  const onBlur = () => {
    console.log("onBlur", startValueRef.current, endValueRef.current);
    onChange([startValueRef.current, endValueRef.current]);
  };

  return (
    <div className={customRangePickerName}>
      <DatePicker.RangePicker
        onFocus={() => setPickerIsFocused(true)}
        format={format}
        onChange={(selectedDates) => {
          if (selectedDates && selectedDates.length > 0) {
            if (selectedDates[0] && selectedDates[0].isValid()) {
              startValueRef.current = selectedDates[0];
            }
            if (selectedDates[1] && selectedDates[1].isValid()) {
              endValueRef.current = selectedDates[1];
            }
          }
          onBlur();
        }}
        onOk={(selectedDates) => {
          if (selectedDates && selectedDates.length > 0) {
            if (selectedDates[0] && selectedDates[0].isValid()) {
              startValueRef.current = selectedDates[0];
            }
            if (selectedDates[1] && selectedDates[1].isValid()) {
              endValueRef.current = selectedDates[1];
            }
          }
          onBlur();
        }}
        value={defaultValue}
        onBlur={onBlur}
      />
    </div>
  );
}
