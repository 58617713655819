import { Card, Col, Input, Row } from "antd";
import { Table } from "antd";
import React from "react";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { ReduxDispatch, ReduxState } from "@/Redux/types";

import { CustomPagination } from "../../../../Components/molecules";
import { IndustryTblColumns } from "../../../../Components/organisms";
import { ContextProps } from "../../../../Provider";
import ApiActions, {
  ApiRequestData,
  ApiSuccessCallback,
} from "../../../../Redux/ApiRedux";
import IndustryActions from "../../../../Redux/IndustryRedux";
import { endpoints, parseEndpoint } from "../../../../Services/endpoints";
import useLocalStorage from "../../../../utils/localStorage";
const PageSaveId = "CompanyIndustryReports";
const pageKey = `pagination_page_${PageSaveId}`;
const limitKey = `pagination_limit_${PageSaveId}`;
const sortOrderKey = `pagination_sort_order_${PageSaveId}`;
const sortModelKey = `pagination_sort_model_${PageSaveId}`;
const { Search } = Input;
type OwnProps = {
  history: Object;
  context: ContextProps;
};
type StateProps = {
  industryReports: {
    // @ts-expect-error TS2314
    rows: Array;
    count: number;
  };
  auth: Object;
};
type ActionProps = {
  dispatchGetIndustryReports: () => void;
  dispatchApiRequest: () => void;
};
type Props = StateProps & ActionProps & OwnProps;
function IndustryReportItemsScreen(props: Props) {
  const intl = useIntl();
  // @ts-expect-error TS2339
  const { updatePageTitle } = props.context;
  const [page, setPage] = useLocalStorage(pageKey, 1);
  const [limit, setLimit] = useLocalStorage(
    limitKey,
    process.env.REACT_APP_PAGE_SIZE,
  );
  const [searchTxt, setSearchTxt] = React.useState("");
  const [sortOrder, setSortOrder] = useLocalStorage(sortOrderKey, "");
  const [sortModel, setSortModel] = useLocalStorage(sortModelKey, "");
  React.useEffect(() => {
    updatePageTitle("screen.label.industry_theme_report");
    getIndustryReports();
  }, [page, limit, sortOrder, sortModel]);
  const getIndustryReports = () => {
    // @ts-expect-error TS2554
    props.dispatchGetIndustryReports({
      page: page - 1,
      limit,
      filter: getSortFilter(),
    });
  };
  const getSortFilter = () => {
    const filterObj = {};
    if (sortModel !== "") {
      // @ts-expect-error TS7053
      filterObj["sortModel"] = sortModel;
    }
    if (sortOrder !== "") {
      // @ts-expect-error TS7053
      filterObj["sortOrder"] = sortOrder;
    }
    if (searchTxt !== "") {
      // @ts-expect-error TS7053
      filterObj["theme"] = searchTxt;
      // @ts-expect-error TS7053
      filterObj["summary"] = searchTxt;
      // @ts-expect-error TS7053
      filterObj["title"] = searchTxt;
    }
    return filterObj;
  };
  // @ts-expect-error TS7006
  const onGotoItem = (id) => {
    // @ts-expect-error TS2339
    props.history.push(`/dashboard/library/industry-report/${id}`);
  };
  // @ts-expect-error TS7006
  const onDeleteItem = (id) => {
    const requestBuilder = {
      method: "deleteRequest",
      url: parseEndpoint(endpoints.deleteIndustryReport, {
        data: {
          id,
        },
      }),
    };
    // @ts-expect-error TS2554
    props.dispatchApiRequest(requestBuilder, ({ data }) => {
      getIndustryReports();
    });
  };
  // @ts-expect-error TS7006
  const onUpdateItem = (id) => {
    // @ts-expect-error TS2339
    props.history.push(`/dashboard/library/industry-report-update/${id}`);
  };
  // @ts-expect-error TS7006
  const onTableChange = (pagination, filters, sorter, extra) => {
    if (sorter) {
      const order = sorter.order && sorter.order === "descend" ? "asc" : "desc";
      setSortOrder(order);
      setSortModel(sorter.field);
    }
  };
  // @ts-expect-error TS7006
  const changePage = (pageNum, limitNum) => {
    setPage(pageNum);
    setLimit(limitNum);
  };
  const renderTitle = () => {
    return (
      <h3 style={{ fontWeight: "bold" }}>
        {intl.formatMessage(
          {
            id: "screen.label.list_of_industry_reports",
          },
          {
            count: props.industryReports.count,
          },
        )}
      </h3>
    );
  };
  const renderFilterTopItems = () => {
    return (
      <Row align="middle" justify="space-between">
        <Col>
          <Search
            placeholder={intl.formatMessage({
              id: "screen.label.please_enter_keyword",
            })}
            size="large"
            enterButton={intl.formatMessage({
              id: "screen.label.retrieval",
            })}
            value={searchTxt}
            onChange={(e) => {
              setSearchTxt(e.target.value);
            }}
            onSearch={(value) => {
              setPage(1);
              getIndustryReports();
            }}
            style={{ width: "20vw" }}
          />
        </Col>
      </Row>
    );
  };
  return (
    <>
      {renderTitle()}
      <Card>
        {/*
         // @ts-expect-error TS2554 */}
        {renderFilterTopItems(searchTxt, setSearchTxt, getIndustryReports)}
        <br />
        <Table
          pagination={false}
          rowKey={"id"}
          columns={IndustryTblColumns({
            isGlobal: false,
            // @ts-expect-error TS2339
            user: props.auth.payload,
            onGoto: onGotoItem,
            onUpdate: onUpdateItem,
            onDelete: onDeleteItem,
          })}
          onChange={onTableChange}
          dataSource={props.industryReports ? props.industryReports.rows : []}
          className="ant-table-x-scroll"
        />
        <br />
        <CustomPagination
          saveId={PageSaveId}
          count={props.industryReports ? props.industryReports.count : 0}
          initPage={page}
          initLimit={limit}
          onChangePage={changePage}
        />
      </Card>
    </>
  );
}
const mapStateToProps = (state: ReduxState) => ({
  industryReports: state.industryReports.industryPayload,
  auth: state.auth,
});
const mapDispatchToProps = (dispatch: ReduxDispatch) => ({
  // @ts-expect-error TS7006
  dispatchGetIndustryReports: (data) =>
    // @ts-expect-error TS2554
    dispatch(IndustryActions.industryGetRequest(data)),
  dispatchApiRequest: (data: ApiRequestData, callback: ApiSuccessCallback) =>
    dispatch(ApiActions.apiRequest(data, callback)),
});
export default withRouter(
  // @ts-expect-error TS2345
  connect(mapStateToProps, mapDispatchToProps)(IndustryReportItemsScreen),
);
