import { DragEventHandler, PropsWithChildren } from "react";

import styles from "@/shared/upload/components/FileDroppableArea/styles.module.scss";
import { FileDroppableAreaProps } from "@/shared/upload/components/FileDroppableArea/type";

export const FileDroppableArea = ({
  dropAreaClass,
  inputRef,
  accept,
  children,
  handleAttach,
  handleDrop,
}: PropsWithChildren<FileDroppableAreaProps>) => {
  const handleDragOver: DragEventHandler = (e) => {
    e.preventDefault();
  };

  return (
    <div
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      className={`${styles.drop_area} ${dropAreaClass}`}
      data-testId="file-drop-area"
    >
      <input
        ref={inputRef}
        onChange={handleAttach}
        accept={accept}
        data-testId="file-drop-area-input"
        type="file"
        className={styles.input}
      />
      {children}
    </div>
  );
};
