import { Col, Form, Layout, Row } from "antd";
import PropTypes from "prop-types";
import { FC } from "react";
import { FormattedMessage } from "react-intl";

import { ButtonVerify } from "../../Components/atoms";
import {
  AlphaDriveImg,
  AlphaDriveImgContainer,
  AuthLayout,
  Banner,
  EmailH2,
  EmailLayout,
  EmailParagraph,
  InputForm as EmailInput,
  InputFormPassword,
  LoginBanner,
  LoginLabel,
  LogoTitleLayout,
  VerifyEmailHeader,
} from "../../Components/molecules";
import useVerifyEmailScreen from "./hooks/useVerifyEmailScreen";
import { IVerifySubEmailScreenProps } from "./types/IVerifySubEmailScreenProps";

const { Content } = Layout;

const VerifyEmailScreen: FC<IVerifySubEmailScreenProps> = function (props) {
  const { state, onChange, onSubmit, isInputsValid } =
    useVerifyEmailScreen(props);
  return (
    <AuthLayout className="layout">
      <Content className="authContent">
        <Row>
          <Banner>
            <LogoTitleLayout>
              <LoginBanner alt={"Banner"} src={"/login_banner.png"} />
              <LoginLabel>
                <FormattedMessage id="screen.label.comprehensive_support_tool_des" />
                <br />
                <FormattedMessage id="screen.label.incubation_suite" />
              </LoginLabel>
            </LogoTitleLayout>
            <AlphaDriveImgContainer>
              <div>
                <span>Powered by &nbsp;</span>
                <AlphaDriveImg alt={"alpha drive"} src={"/alpha_drive.png"} />
              </div>
            </AlphaDriveImgContainer>
          </Banner>
          <Col>
            <EmailLayout className="contentRoot">
              <div>
                <EmailH2>
                  <FormattedMessage id="screen.label.change_email_auth_title" />
                </EmailH2>
                <EmailParagraph>
                  <FormattedMessage id="screen.label.change_email_auth_des" />
                </EmailParagraph>
                <Form layout={"vertical"} onFinish={onSubmit}>
                  <Form.Item>
                    <h4>
                      <FormattedMessage id="screen.label.old_email" />
                    </h4>
                    <EmailInput
                      required
                      onChange={onChange}
                      value={state.oldEmail}
                      // @ts-expect-error TS2769
                      error={"errors.email"}
                      id="oldEmail"
                      type="email"
                    />
                  </Form.Item>
                  <Form.Item>
                    <VerifyEmailHeader>
                      <FormattedMessage id="screen.label.new_email" />
                    </VerifyEmailHeader>
                    <EmailInput
                      required
                      onChange={onChange}
                      value={state.newEmail}
                      // @ts-expect-error TS2769
                      error={"errors.email"}
                      id="newEmail"
                      type="email"
                    />
                  </Form.Item>
                  <Form.Item>
                    <VerifyEmailHeader>
                      <FormattedMessage id="screen.label.password" />
                    </VerifyEmailHeader>
                    <InputFormPassword
                      required
                      onChange={onChange}
                      value={state.password}
                      // @ts-expect-error TS2769
                      error={"errors.password"}
                      id="password"
                      type="password"
                    />
                  </Form.Item>

                  <ButtonVerify htmlType="submit">
                    <FormattedMessage id="screen.label.change_email_allow_register" />

                    <FormattedMessage id="screen.label.change_email_allow_changes" />
                  </ButtonVerify>
                </Form>
              </div>
            </EmailLayout>
          </Col>
        </Row>
      </Content>
    </AuthLayout>
  );
};

VerifyEmailScreen.propTypes = {
  match: PropTypes.object,
  location: PropTypes.object,
};
export default VerifyEmailScreen;
