import { Form } from "antd";
import { memo, useMemo } from "react";
import { FormattedMessage } from "react-intl";

import { Option, Select } from "../../../../Components/molecules/dropdown";
import {
  adminUser,
  generalUser,
  mentorUser,
  programManager,
} from "../../../../utils/constants";
import { UserTypeDropdownProps } from "./type";

export const UserTypeDropdown = memo((props: UserTypeDropdownProps) => {
  const { loginUserType, isSuper, defaultValue, onChange } = props;
  const canShowSuper = useMemo(
    () => loginUserType === "admin" && isSuper,
    [loginUserType, isSuper],
  );
  if (loginUserType !== "admin") return null;
  // only when login User is system manager, defaultValue can be 'super'
  if (!canShowSuper && defaultValue === "isSuper") return null;
  return (
    <Form.Item label={<FormattedMessage id="screen.label.user_type" />}>
      <Select
        onChange={onChange}
        defaultValue={defaultValue}
        style={{
          width: "100%",
          minWidth: "140px",
        }}
      >
        {canShowSuper && <Option value="isSuper">{adminUser}</Option>}
        <Option value="admin">{programManager}</Option>
        <Option value="employee">{generalUser}</Option>
        <Option value="mentor">{mentorUser}</Option>
      </Select>
    </Form.Item>
  );
});
