import { Card, Col, Modal, Row } from "antd";
import { useIntl } from "react-intl";

import { ButtonUndo } from "../../atoms";
import PdfViewer from "./pdfviewer";
type Props = {
  visible: boolean;
  onChangeVisible: () => void;
  title: string;
  theme: string;
  summary: string;
  industryReport: Object;
  mainCategoryName: string;
  subCategoryName: string;
};
function IndustryReportPreviewModal(props: Props) {
  const {
    visible,
    onChangeVisible,
    title = "",
    theme = "",
    summary = "",
    industryReport = null,
    mainCategoryName = "",
    subCategoryName = "",
  } = props;
  const intl = useIntl();
  const pdfContentType = "application/pdf";
  const base64toBlob = (data: string) => {
    if (data && data.length > 0) {
      // Cut the prefix `data:application/pdf;base64` from the raw base 64
      const base64WithoutPrefix = data.substr(
        `data:${pdfContentType};base64,`.length,
      );
      const bytes = atob(base64WithoutPrefix);
      let length = bytes.length;
      const out = new Uint8Array(length);
      while (length--) {
        out[length] = bytes.charCodeAt(length);
      }
      return new Blob([out], { type: pdfContentType });
    } else {
      return null;
    }
  };
  let url = "";
  if (industryReport) {
    if (typeof industryReport === "string") {
      url = industryReport;
    } else {
      // @ts-expect-error TS2345
      if (base64toBlob(industryReport)) {
        // @ts-expect-error TS2345
        url = URL.createObjectURL(base64toBlob(industryReport));
      }
    }
  }
  return (
    <Modal
      title={intl.formatMessage({
        id: "screen.label.industry_category_management",
      })}
      centered
      footer={null}
      visible={visible}
      width={"80vw"}
      onCancel={onChangeVisible}
    >
      <Card className="library-news">
        <Row align="middle" justify="center">
          <br />
          <br />
          <div style={{ width: "50vw" }}>
            <Row align="middle" justify="center">
              <PdfViewer url={url} />
            </Row>
            <br />
            <br />
            <Row align="middle">
              <h2>{title}</h2>
            </Row>
            <br />
            <br />
            <Row align="middle">
              <Col flex="200px">
                {intl.formatMessage({
                  id: "screen.label.overview",
                })}
              </Col>
              <Col flex="none">{summary}</Col>
            </Row>
            <hr
              style={{
                border: "none",
                borderBottom: "dashed 1px #BDBDBD",
              }}
            />
            <br />
            <Row align="middle">
              <Col flex="200px">
                {intl.formatMessage({
                  id: "screen.label.theme",
                })}
              </Col>
              <Col flex="none">{theme}</Col>
            </Row>
            <hr
              style={{
                border: "none",
                borderBottom: "dashed 1px #BDBDBD",
              }}
            />
            <br />
            <Row align="middle">
              <Col flex="200px">
                {intl.formatMessage({
                  id: "screen.label.industry_medium_category",
                })}
              </Col>
              <Col flex="none">{mainCategoryName}</Col>
            </Row>
            <hr
              style={{
                border: "none",
                borderBottom: "dashed 1px #BDBDBD",
              }}
            />
            <br />
            <Row align="middle">
              <Col flex="200px">
                {intl.formatMessage({
                  id: "screen.label.industry_sub_category",
                })}
              </Col>
              <Col flex="none">{subCategoryName}</Col>
            </Row>
            <br />
            <Row align="middle" justify="center">
              <ButtonUndo
                onClick={onChangeVisible}
                type={"ghost"}
                style={{
                  marginTop: "1px",
                }}
              >
                {intl.formatMessage({
                  id: "screen.label.close_preview",
                })}
              </ButtonUndo>
            </Row>
            <br />
            <br />
          </div>
        </Row>
      </Card>
    </Modal>
  );
}
export default IndustryReportPreviewModal;
