import PropTypes from "prop-types";

type ProjectTaskCheckboxProps = {
  onChange: () => any;
  checked: boolean;
  text: string;
};
export const ProjectTaskCheckbox = (props: ProjectTaskCheckboxProps) => {
  const { onChange, checked, text } = props;
  return (
    <label className="container project-task">
      {text}
      <input onChange={onChange} checked={checked} type="checkbox" />
      <span className="checkmark" />
    </label>
  );
};

ProjectTaskCheckbox.defaultProps = {
  checked: false,
};

ProjectTaskCheckbox.propTypes = {
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
};
