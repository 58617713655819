import DOMPurify from "dompurify";

export const sanitizeHtml = (dirtyHtmlString: string): string => {
  const cleanHtmlString = DOMPurify.sanitize(dirtyHtmlString, {
    ALLOWED_TAGS: ["p", "br", "a"],
    ALLOWED_ATTR: ["href"],
  });

  return cleanHtmlString;
};
