import PropTypes from "prop-types";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { getDisplayName } from "../../../utils/common";
import { ButtonSwitch, HorizonalLayout } from "../../atoms";
import { Option, Select } from "../dropdown";
import { InputDefault, TextAreaDefault } from "../textfield";
import { VerticalTitle } from "../titles";

export const SingleSubmittedProjectReview = ({
  // @ts-expect-error TS7031
  updateEvalutationDetail,
  // @ts-expect-error TS7031
  index,
  // @ts-expect-error TS7031
  users,
  // @ts-expect-error TS7031
  evaluation,
  // @ts-expect-error TS7031
  examinationFlags,
}) => {
  const [showJudgeName, setShowJudgeName] = useState(
    Boolean(evaluation?.judgeName),
  );
  // @ts-expect-error TS7006
  const defaultUser = users.find((a) => a.id === evaluation.userId);

  const intl = useIntl();
  return (
    <div>
      <VerticalTitle
        title={intl.formatMessage({
          id: "screen.label.fill_judge",
        })}
      >
        <Select
          showSearch
          onChange={(value) => {
            updateEvalutationDetail(index, "userId", value);
          }}
          value={defaultUser ? defaultUser.id : ""}
          // @ts-expect-error TS2322
          width={"50%"}
        >
          {users
            .filter(
              // @ts-expect-error TS7006
              (user) =>
                (user.type === "admin" || user.type === "mentor") &&
                user.email.trim().length > 0,
            )
            // @ts-expect-error TS7006
            .map((user, index) => {
              return (
                <Option value={parseInt(user.id)} key={parseInt(user.id)}>
                  {getDisplayName(user)}
                </Option>
              );
            })}
        </Select>
      </VerticalTitle>

      <HorizonalLayout>
        <p style={{ marginRight: "1vw" }}>
          <FormattedMessage id={"screen.label.enable_proxy_judge"} />
        </p>
        <ButtonSwitch
          onChange={(checked) => {
            setShowJudgeName(checked);
            if (!checked) {
              updateEvalutationDetail(index, "judgeName", null);
            }
          }}
          checked={showJudgeName}
        />
      </HorizonalLayout>

      <VerticalTitle
        title={intl.formatMessage({
          id: "screen.label.examination_flag",
        })}
      >
        <Select
          mode="multiple"
          allowClear
          value={
            Array.isArray(evaluation?.examinationFlags)
              ? evaluation?.examinationFlags
              : []
          }
          onChange={(value) => {
            updateEvalutationDetail(index, "examinationFlags", value);
          }}
          // @ts-expect-error TS2322
          width={"100%"}
        >
          {/*
           // @ts-expect-error TS7006 */}
          {examinationFlags.map((examinationStatus, index) => (
            <Option key={index} value={examinationStatus}>
              {examinationStatus}
            </Option>
          ))}
        </Select>
      </VerticalTitle>

      {showJudgeName ? (
        <VerticalTitle
          title={intl.formatMessage({
            id: "screen.submission.display_judge_name",
          })}
        >
          <InputDefault
            onChange={(event) => {
              updateEvalutationDetail(index, "judgeName", event.target.value);
            }}
            value={evaluation?.judgeName}
            placeholder={`${intl.formatMessage({
              id: "screen.label.judge",
            })} (${intl.formatMessage({
              id: "screen.label.displayName",
            })})`}
            style={{
              width: "60%",
            }}
          />
        </VerticalTitle>
      ) : null}

      <VerticalTitle
        title={intl.formatMessage({
          id: "screen.label.review_comments",
        })}
      >
        <TextAreaDefault
          placeholder={intl.formatMessage({
            id: "screen.label.review_comments",
          })}
          value={evaluation.review}
          onChange={(event) =>
            updateEvalutationDetail(index, "review", event.target.value)
          }
          style={{
            width: "100%",
          }}
        />
      </VerticalTitle>
      <p>
        <FormattedMessage id={"screen.label.review_comments_des"} />
      </p>

      <VerticalTitle
        title={intl.formatMessage({
          id: "screen.label.review_score",
        })}
      >
        <InputDefault
          required
          onChange={(event) => {
            // @ts-expect-error TS2345
            if (!isNaN(event.target.value)) {
              updateEvalutationDetail(index, "score", event.target.value);
            }
          }}
          value={evaluation.score}
          placeholder={intl.formatMessage({
            id: "screen.label.review_score",
          })}
          style={{
            width: "50%",
          }}
        />
      </VerticalTitle>
    </div>
  );
};

SingleSubmittedProjectReview.propTypes = {
  index: PropTypes.number.isRequired,
  updateEvalutationDetail: PropTypes.func.isRequired,
  evaluation: PropTypes.any.isRequired,
  users: PropTypes.array.isRequired,
  examinationFlags: PropTypes.array.isRequired,
};
