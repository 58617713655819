import { Card, Col, Progress, Row, Table } from "antd";
import moment from "moment";
import { Link } from "react-router-dom";

import { CustomPagination } from "../../Components/molecules";
import { dateTimeFormat, defaultThumbnailImg } from "../../utils/constants";
import useSingleSmallCategoryVideosScreen from "./hooks/useSingleSmallCategoryVideosScreen";
import { ISingleSmallCategoryVideosScreenProps } from "./types/ISingleSmallCategoryVideosScreenProps";
const PageSaveId = "SingleSmallCategoryVideosScreen";

function SingleSmallCategoryVideosScreen(
  props: ISingleSmallCategoryVideosScreenProps,
) {
  const {
    intl,
    state,
    applyFilter,
    setState,
    getSmallCategoryName,
    getSmallCategoryId,
  } = useSingleSmallCategoryVideosScreen(props);
  const getTableColumns = () => {
    return [
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
      },
      {
        title: intl.formatMessage({
          id: "screen.label.video_name",
        }),
        dataIndex: "name",
        key: "name",
        width: "200px",
        render: function _name(text: any, row: any) {
          return (
            <Link to={`/dashboard/videos-play/${row.id}`}>
              <img
                className={"video-wrapper-view"}
                alt={intl.formatMessage({
                  id: "screens.alt.video_thumbnail",
                })}
                src={row.imageThumbnail || defaultThumbnailImg}
                style={{
                  width: `160px`,
                  height: `96px`,
                  maxWidth: `160px`,
                  maxHeight: `96px`,
                  objectFit: "cover",
                }} //40vh
              />
              <br />
              <p
                style={{
                  color: "#000000",
                  fontSize: "12px",
                  fontWeight: "normal",
                  fontStyle: "normal",
                }}
              >
                {text}
              </p>
            </Link>
          );
        },
      },
      {
        title: intl.formatMessage({
          id: "screen.label.category_title",
        }),
        dataIndex: "category",
        key: "category",
        // @ts-expect-error TS7006
        render: function _category(text, row) {
          return (
            <>
              <h4>
                {row.VideoLargeCategory.name ? row.VideoLargeCategory.name : ""}
              </h4>
              <h5>
                {row.VideoSmallCategory.name ? row.VideoSmallCategory.name : ""}
              </h5>
            </>
          );
        },
      },
      {
        title: intl.formatMessage({
          id: "screen.label.status",
        }),
        dataIndex: "",
        key: "",
        // @ts-expect-error TS7006
        render: function _status(text, row) {
          if (row.VideoPlay.length === 0) {
            return (
              <>
                <Progress percent={0} size="small" />
                <p>
                  {intl.formatMessage({
                    id: "screen.label.unviewed",
                  })}
                </p>
              </>
            );
          } else if (
            row.VideoPlay.length > 0 &&
            row.VideoPlay[0].duration > 0.9
          ) {
            return (
              <>
                <Progress
                  percent={parseFloat(
                    (row.VideoPlay[0].duration * 100).toFixed(2),
                  )}
                  size="small"
                />
                <p>
                  {intl.formatMessage({
                    id: "screen.label.viewing_already",
                  })}
                </p>
              </>
            );
          }
          return (
            <>
              <Progress
                percent={parseFloat(
                  (row.VideoPlay[0].duration * 100).toFixed(2),
                )}
                size="small"
              />
              <p>
                {intl.formatMessage({
                  id: "screen.label.viewing_in",
                })}
              </p>
            </>
          );
        },
      },
      {
        title: intl.formatMessage({
          id: "screen.label.posted_date_and_time",
        }),
        dataIndex: "createdAt",
        key: "createdAt",
        // @ts-expect-error TS7006
        render: (text, row) =>
          moment(row.createdAt).local().format(dateTimeFormat),
      },
    ];
  }; //
  return (
    <>
      <Row>
        <Col span={24}>
          <h2>{getSmallCategoryName()}</h2>
        </Col>
      </Row>
      <Card>
        <Table
          pagination={false}
          rowKey={"id"}
          columns={getTableColumns()}
          dataSource={Array.isArray(state.videos.rows) ? state.videos.rows : []}
          className="ant-table-x-scroll"
        />
        <br />
        <CustomPagination
          saveId={PageSaveId}
          count={state.videos.count}
          onChangePage={(page, limit) => {
            setState(
              {
                page,
                limit,
              },
              () => {
                applyFilter(`videoSmallCategoryId=${getSmallCategoryId()}`);
              },
            );
          }}
        />
      </Card>
    </>
  );
}
export default SingleSmallCategoryVideosScreen;
