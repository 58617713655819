import styles from "./styles.module.scss";
import { FeedbackCommentLabelProps } from "./type";

export const FeedbackCommentLabel = ({
  feedbackComment,
  className,
  ...rest
}: FeedbackCommentLabelProps) => {
  return (
    <span className={`${styles.feedback_comment_label} ${className}`} {...rest}>
      {feedbackComment}
    </span>
  );
};
