import DeleteIcon from "./assets/delete.svg";
import styles from "./styles.module.scss";
import { DeleteButtonProps } from "./type";
export const DeleteIconButton = ({
  id,
  className,
  onClick,
  alt,
  ...rest
}: DeleteButtonProps) => {
  return (
    <button
      id={id}
      onClick={onClick}
      className={`${styles.delete_icon_button} ${className}`}
      {...rest}
    >
      <img src={DeleteIcon} alt={alt} />
    </button>
  );
};
