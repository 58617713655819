import { useIntl } from "react-intl";

import { defaultThumbnailImg } from "../../../utils/constants";
import { SingleVideo, VideoCardFooter } from "../../atoms/videos";

type VideoTopCategorySingleCardProps = {
  singleVideo: SingleVideo;
  onClick: () => any;
};

export const VideoTopCategorySingleCard = (
  props: VideoTopCategorySingleCardProps,
) => {
  const { singleVideo, onClick } = props;
  const intl = useIntl();
  if (!singleVideo) {
    return null;
  }
  return (
    <div
      key={singleVideo.id}
      className="card-video-wrapper-view-new"
      style={{
        marginRight: "0.5vw",
        boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.15)",
        marginBottom: "3vh",
      }}
    >
      <img
        alt={intl.formatMessage({
          id: "screens.alt.video_thumbnail",
        })}
        onClick={onClick}
        id={`card-video-wrapper-view${singleVideo.id}`}
        src={singleVideo.imageThumbnail || defaultThumbnailImg}
        className="card-video-wrapper-view-img"
        style={{ objectFit: "cover" }}
      />
      <VideoCardFooter singleVideo={singleVideo} />
    </div>
  );
};
