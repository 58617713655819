import { notification } from "antd";
import { push } from "connected-react-router";
import { all, call, put, takeLatest } from "redux-saga/effects";

import ProjectActions, { ProjectTypes } from "../Redux/ProjectRedux";
import {
  transformAllProjectsList,
  transformAllProjectTasksList,
} from "../Screens/project/TransformProjectsAPIData";
import { endpoints, parseEndpoint } from "../Services/endpoints";
import createFormData from "../utils/createFormData";
// @ts-expect-error TS7006
function* attemptSubmitProject(api, { data }) {
  // @ts-expect-error TS7057
  const projectResponse = yield call(
    api.postRequest,
    parseEndpoint(endpoints.submitProjectForReview, { data }),
    {
      projectId: data.projectId,
      title: data.title,
      comment: data.comment,
      fileUrl: data.fileUrl,
      fileUrlName: data.fileUrlName,
      category: data.category,
      parsedFileList: data.parsedFileList,
    },
  );
  if (projectResponse.ok) {
    switch (projectResponse.data.status) {
      case "success":
        yield put(ProjectActions.projectSubmitSuccess());
        notification.success({
          message: "",
        });
        break;
      case "fail":
      case "error":
        if (typeof projectResponse.data.data === "string") {
        }
        yield put(
          // @ts-expect-error TS2554
          ProjectActions.projectSubmitFailure(projectResponse.data.data),
        );
        break;
      default:
        break;
    }
  } else {
    notification.error({
      message: projectResponse.problem,
    });
    // @ts-expect-error TS2554
    yield put(ProjectActions.projectSubmitFailure(projectResponse.problem));
    if (projectResponse.status === 401) {
      // @ts-expect-error TS2322
      window.location = "/login";
    }
  }
}

// @ts-expect-error TS7006
function* attemptCreateProject(api, action) {
  const { data, message, resolve, reject } = action;
  const { uid, redirectURL, ...rest } = data;
  const isUpdate = Object.hasOwn(rest, "id") && rest.id > 0;

  const projectResponse = isUpdate
    ? // @ts-expect-error TS7057
      yield call(
        api.putRequest,
        parseEndpoint(endpoints.updateProjectDetails, { uid }),
        createFormData(rest),
      )
    : // @ts-expect-error TS7057
      yield call(
        api.postRequest,
        parseEndpoint(endpoints.createNewProject),
        createFormData(rest),
      );

  if (projectResponse.ok) {
    switch (projectResponse.data.status) {
      case "success":
        // @ts-expect-error TS2554
        yield put(ProjectActions.projectGetsRequest(0));
        yield put(ProjectActions.projectCreateSuccess());
        notification.success({
          message: message,
        });
        if (!isUpdate) {
          if (redirectURL && redirectURL !== "") {
            yield put(push(`/dashboard/projects.batchlist/${redirectURL}`));
          } else {
            yield put(push("/dashboard/projects.list"));
          }
        }
        yield call(resolve, projectResponse.data);
        break;
      case "fail":
      case "error":
        if (typeof projectResponse.data.data === "string") {
        }
        yield put(
          // @ts-expect-error TS2554
          ProjectActions.projectCreateFailure(projectResponse.data.data),
        );
        yield call(reject, projectResponse.data);
        break;
      default:
        break;
    }
  } else {
    notification.error({
      message: projectResponse.problem,
    });
    // @ts-expect-error TS2554
    yield put(ProjectActions.projectCreateFailure(projectResponse.problem));
    if (projectResponse.status === 401) {
      // @ts-expect-error TS2322
      window.location = "/login";
    }
  }
}

function* attemptGetAllProjects(
  // @ts-expect-error TS7006
  api,
  // @ts-expect-error TS7031
  { page, limit, filter, sort = null, tag = null },
) {
  const defaultLimit = process.env.REACT_APP_PAGE_SIZE;
  // @ts-expect-error TS7057
  const projectResponse = yield call(
    api.getRequest,
    `projects?page=${page}&limit=${limit || defaultLimit}${
      filter ? `&filter=${encodeURI(filter)}` : ``
    }${sort ? `&sort=${encodeURI(sort)}` : ``}${
      // @ts-expect-error TS2339
      tag ? `&tag=${tag.join(",")}` : ``
    }`,
  );
  if (projectResponse.ok) {
    switch (projectResponse.data.status) {
      case "success": {
        const count = projectResponse.data.data.count;
        const rows = transformAllProjectsList(projectResponse.data.data.rows);
        // @ts-expect-error TS2554
        yield put(ProjectActions.projectGetsSuccess({ count, rows }));
        break;
      }
      case "fail":
      case "error":
        if (typeof projectResponse.data.data === "string") {
        }
        // @ts-expect-error TS2554
        yield put(ProjectActions.projectGetsFailure(projectResponse.data.data));
        break;
      default:
        break;
    }
  } else {
    notification.error({
      message: projectResponse.problem,
    });
    // @ts-expect-error TS2554
    yield put(ProjectActions.projectGetsFailure(projectResponse.problem));
    if (projectResponse.status === 401) {
      // @ts-expect-error TS2322
      window.location = "/login";
    }
  }
}

// @ts-expect-error TS7006
function* attemptGetProjectDetail(api, { id }) {
  // @ts-expect-error TS7057
  const projectResponse = yield call(
    api.getRequest,
    parseEndpoint(endpoints.singleProject, { id }),
  );
  if (projectResponse.ok) {
    switch (projectResponse.data.status) {
      case "success":
        // @ts-expect-error TS2554
        yield put(ProjectActions.projectGetSuccess(projectResponse.data.data));
        // @ts-expect-error TS2554
        yield put(ProjectActions.projectTaskGetsRequest(id));
        break;
      case "fail":
      case "error":
        // @ts-expect-error TS2554
        yield put(ProjectActions.projectGetFailure(projectResponse.data.data));
        break;
      default:
        break;
    }
  } else {
    notification.error({
      message: projectResponse.problem,
    });
    // @ts-expect-error TS2554
    yield put(ProjectActions.projectGetFailure(projectResponse.problem));
    if (projectResponse.status === 401) {
      // @ts-expect-error TS2322
      window.location = "/login";
    }
  }
}

// @ts-expect-error TS7006
function* attemptGetAllProjectsMemberOf(api, { data }) {
  let endPoint = "users/" + data.userId + "/projects?page=" + data.page;
  if (data.filter)
    endPoint =
      "users/" +
      data.userId +
      "/projects?page=" +
      data.page +
      `&${data.filter}`;
  if (data.stage)
    endPoint =
      "users/" +
      data.userId +
      "/projects?page=" +
      data.page +
      `&stage=${data.stage}`;
  if (data.tag)
    endPoint =
      "users/" +
      data.userId +
      "/projects?page=" +
      data.page +
      `&tag=${data.tag.join(",")}`;
  if (data.milestone)
    endPoint =
      "users/" +
      data.userId +
      "/projects?page=" +
      data.page +
      `&milestone=${data.milestone}`;
  if (data.sort) endPoint += `&sort=${encodeURI(data.sort)}`;

  // @ts-expect-error TS7057
  const projectResponse = yield call(api.getRequest, endPoint);
  if (projectResponse.ok) {
    switch (projectResponse.data.status) {
      case "success": {
        const count = projectResponse.data.data.count;
        const rows = transformAllProjectsList(projectResponse.data.data.rows);
        yield put(
          // @ts-expect-error TS2554
          ProjectActions.projectCurrentMemberGetsSuccess({
            count,
            rows,
          }),
        );
        break;
      }
      case "fail":
      case "error":
        if (typeof projectResponse.data.data === "string") {
        }
        yield put(
          ProjectActions.projectCurrentMemberGetsFailure(
            // @ts-expect-error TS2554
            projectResponse.data.data,
          ),
        );
        break;
      default:
        break;
    }
  } else {
    notification.error({
      message: projectResponse.problem,
    });
    yield put(
      // @ts-expect-error TS2554
      ProjectActions.projectCurrentMemberGetsFailure(projectResponse.problem),
    );
    if (projectResponse.status === 401) {
      // @ts-expect-error TS2322
      window.location = "/login";
    }
  }
}

// @ts-expect-error TS7006
function* attemptGetAllProjectTasks(api, { id, query }) {
  let endpoint = `projects/${id}/tasks`;
  if (query) endpoint = encodeURI(`projects/${id}/tasks?${query}`);
  // @ts-expect-error TS7057
  const projectResponse = yield call(api.getRequest, endpoint);
  if (projectResponse.ok) {
    switch (projectResponse.data.status) {
      case "success": {
        const count = projectResponse.data.data.count;
        const rows = transformAllProjectTasksList(
          projectResponse.data.data.rows,
        );
        // @ts-expect-error TS2554
        yield put(ProjectActions.projectTaskGetsSuccess({ count, rows }));
        break;
      }
      case "fail":
      case "error":
        if (typeof projectResponse.data.data === "string") {
        }
        yield put(
          // @ts-expect-error TS2554
          ProjectActions.projectTaskGetsFailure(projectResponse.data.data),
        );
        break;
      default:
        break;
    }
  } else {
    notification.error({
      message: projectResponse.problem,
    });
    // @ts-expect-error TS2554
    yield put(ProjectActions.projectTaskGetsFailure(projectResponse.problem));
    if (projectResponse.status === 401) {
      // @ts-expect-error TS2322
      window.location = "/login";
    }
  }
}

// @ts-expect-error TS7006
function* attemptCreateProjectTasks(api, { data }) {
  // @ts-expect-error TS7057
  const projectResponse = yield call(
    api.postRequest,
    parseEndpoint(endpoints.createProjectTask, { data }),
    data,
  );
  if (projectResponse.ok) {
    switch (projectResponse.data.status) {
      case "success":
        yield put(ProjectActions.projectTaskCreateSuccess());
        break;
      case "fail":
      case "error":
        if (typeof projectResponse.data.data === "string") {
        }
        yield put(
          // @ts-expect-error TS2554
          ProjectActions.projectTaskCreateFailure(projectResponse.data.data),
        );
        break;
      default:
        break;
    }
  } else {
    notification.error({
      message: projectResponse.problem,
    });
    // @ts-expect-error TS2554
    yield put(ProjectActions.projectTaskCreateFailure(projectResponse.problem));
    if (projectResponse.status === 401) {
      // @ts-expect-error TS2322
      window.location = "/login";
    }
  }
}

// @ts-expect-error TS7006
function* attemptUpdateProjectTasks(api, { id, taskId, update }) {
  // @ts-expect-error TS7057
  const projectResponse = yield call(
    api.putRequest,
    parseEndpoint(endpoints.updateProjectTask, { id, taskId }),
    update,
  );
  if (projectResponse.ok) {
    switch (projectResponse.data.status) {
      case "success": {
        const count = projectResponse.data.data.count;
        const rows = transformAllProjectTasksList(
          projectResponse.data.data.rows,
        );
        // @ts-expect-error TS2554
        yield put(ProjectActions.projectTaskUpdateSuccess({ count, rows }));
        break;
      }
      case "fail":
      case "error":
        if (typeof projectResponse.data.data === "string") {
        }
        yield put(
          // @ts-expect-error TS2554
          ProjectActions.projectTaskUpdateFailure(projectResponse.data.data),
        );
        break;
      default:
        break;
    }
  } else {
    notification.error({
      message: projectResponse.problem,
    });
    // @ts-expect-error TS2554
    yield put(ProjectActions.projectTaskUpdateFailure(projectResponse.problem));
    if (projectResponse.status === 401) {
      // @ts-expect-error TS2322
      window.location = "/login";
    }
  }
}

// @ts-expect-error TS7006
function* attemptDeleteProject(api, { id, message, resolve, reject }) {
  // @ts-expect-error TS7057
  const projectResponse = yield call(
    api.deleteRequest,
    parseEndpoint(endpoints.deleteProject, { id }),
  );
  if (projectResponse.ok) {
    switch (projectResponse.data.status) {
      case "success":
        notification.success({
          message: message,
        });
        yield put(ProjectActions.projectDeleteSuccess());
        yield put(push("/dashboard/projects.list"));
        yield call(resolve, projectResponse.data);
        break;
      case "fail":
      case "error":
        if (typeof projectResponse.data.data === "string") {
        }
        yield put(
          // @ts-expect-error TS2554
          ProjectActions.projectDeleteFailure(projectResponse.data.data),
        );
        yield call(reject, projectResponse.data);
        break;
      default:
        break;
    }
  } else {
    notification.error({
      message: projectResponse.problem,
    });
    // @ts-expect-error TS2554
    yield put(ProjectActions.projectDeleteFailure(projectResponse.problem));
    if (projectResponse.status === 401) {
      // @ts-expect-error TS2322
      window.location = "/login";
    }
  }
}

// @ts-expect-error TS7006
function* projectSaga(api) {
  yield all([
    takeLatest(ProjectTypes.PROJECT_CREATE_REQUEST, attemptCreateProject, api),
    // @ts-expect-error TS2345
    takeLatest(ProjectTypes.PROJECT_GETS_REQUEST, attemptGetAllProjects, api),
    // @ts-expect-error TS2345
    takeLatest(ProjectTypes.PROJECT_GET_REQUEST, attemptGetProjectDetail, api),
    takeLatest(
      // @ts-expect-error TS2345
      ProjectTypes.PROJECT_TASK_GETS_REQUEST,
      attemptGetAllProjectTasks,
      api,
    ),
    takeLatest(
      // @ts-expect-error TS2345
      ProjectTypes.PROJECT_TASK_CREATE_REQUEST,
      attemptCreateProjectTasks,
      api,
    ),
    takeLatest(
      // @ts-expect-error TS2345
      ProjectTypes.PROJECT_TASK_UPDATE_REQUEST,
      attemptUpdateProjectTasks,
      api,
    ),
    takeLatest(
      // @ts-expect-error TS2345
      ProjectTypes.PROJECT_CURRENT_MEMBER_GETS_REQUEST,
      attemptGetAllProjectsMemberOf,
      api,
    ),
    // @ts-expect-error TS2345
    takeLatest(ProjectTypes.PROJECT_DELETE_REQUEST, attemptDeleteProject, api),
    // @ts-expect-error TS2345
    takeLatest(ProjectTypes.PROJECT_SUBMIT_REQUEST, attemptSubmitProject, api),
  ]);
}
export default projectSaga;
