import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { BatchSelectBox } from "@/features/batch/components/BatchSelectBox";
import { useExaminationPhasesRequest } from "@/features/examinationPhase/hooks/useExaminationPhaseRequest";
import { ExaminationPhase } from "@/features/examinationPhase/types/ExaminationPhase";
import { ImportReviewExcelButton } from "@/features/review/components/ImportReviewExcelButton";
import { PublishSelectBox } from "@/features/review/components/PublishSelectBox";
import { UploadReviewExcelModal } from "@/features/review/components/UploadReviewExcelModal";
import { useReviewSortManager } from "@/features/review/hooks/useReviewSortManager";
import { useSelectedExaminationPhaseId } from "@/features/review/hooks/useSelectedExaminationPhaseId";
import { useSelectedResults } from "@/features/review/hooks/useSelectedResults";
import { useSubmittedProjectRequest } from "@/features/review/hooks/useSubmittedProjects";
import { useBool } from "@/hooks/useBool";
import { batchesCacheKeyMatcher } from "@/Services/batches/batchesCacheKeyMatcher";
import { useBatchesRequest } from "@/Services/batches/useBatchesRequest";
import { Pagination } from "@/shared/pagination/components/Pagination";
import { usePagination } from "@/shared/pagination/components/Pagination/hooks";
import { useSWRCache } from "@/utils/useSWRCache";

import { useSelectedPublishTarget } from "../../hooks/useSelectedPublishTarget";
import { CategorySelectBox } from "../CategorySelectBox/component";
import { ExportCsvButton } from "../ExportCsvButton/component";
import { ExcelExportButton } from "../ExportExcelButton";
import { PhaseTabGroup } from "../PhaseTabGroup/component";
import { PublishReviewButton } from "../PublishReviewButton/component";
import { usePublishedStatus } from "../PublishSelectBox/hooks";
import { ReviewInfoGrid } from "../ReviewInfoGrid";
import { ReviewResultSelectBox } from "../ReviewResultSelectBox";
import { SortReviewSelectBox } from "../SortReviewSelectBox";
import { SubmittedProjectCountLabel } from "../SubmittedProjectCountLabel/component";
import { SubmittedProjectSearchBar } from "../SubmittedProjectSearchBar/component";
import styles from "./styles.module.scss";
import {
  FetchSubmittedProjectParams as FetchSubmittedProjectsParams,
  ProjectSubmissionsPropsType,
} from "./type";

export const ProjectSubmissionsScreen: React.FC<ProjectSubmissionsPropsType> = (
  props,
) => {
  const { getSortedBatches } = useBatchesRequest();
  const { batches } = getSortedBatches();
  const history = useHistory();
  const params = useParams<{ batchId: string }>();

  const {
    bool: isVisible,
    changeToFalse: closeModal,
    changeToTrue: openModal,
  } = useBool();

  const { selectedResults, setSelectedResults } = useSelectedResults();
  const [searchWord, setSearchWord] = useState<string>("");
  const [batchId, setBatchId] = useState(params.batchId);
  const { sortOrder, sortField, onChangeSortField } = useReviewSortManager();
  const [categoryId, setCategoryId] = useState<string | null>(null);
  const {
    selectedPublishedStatuses,
    onChangeSelectedPublishedStatus,
    resetAllPublishedStatus,
  } = usePublishedStatus();

  const currentBatch = useMemo(
    () => batches.find((batch) => batch.id === batchId),
    [batchId, batches],
  );

  // これらのstateが変更されるとuseSWRのparamsが変わるので再リクエストが送られる(キャッシュがなければ)
  const fetchSubmissionParams: FetchSubmittedProjectsParams = useMemo(
    () => ({
      results: selectedResults,
      categoryId,
      searchWord: searchWord,
      sortField,
      sortOrder,
      batchId,
      publishedStatuses: selectedPublishedStatuses,
    }),
    [
      selectedResults,
      categoryId,
      searchWord,
      sortField,
      sortOrder,
      batchId,
      selectedPublishedStatuses,
    ],
  );

  const { getExaminationPhases } = useExaminationPhasesRequest(batchId);
  const { examinationPhases } = getExaminationPhases();

  const { deleteMatchedCaches } = useSWRCache();

  const [
    selectedExaminationPhaseId,
    { getAndSetSelectedExaminationPhaseId, setSelectedExaminationPhaseId },
  ] = useSelectedExaminationPhaseId(examinationPhases);
  const { page, limit, onNextPage, onPrevPage, onChangeLimit, resetPage } =
    usePagination(50);

  const shouldFetch = () => {
    if (!examinationPhases) return false;
    if (examinationPhases.length > 0) return !!selectedExaminationPhaseId;
    return true;
  };

  const {
    reviewInfos,
    count,
    deleteSubmittedProjectCache,
    publishReviews,
    submittedProjectsMutate,
  } = useSubmittedProjectRequest({
    page: page - 1,
    limit,
    shouldFetch: shouldFetch(),
    examinationPhaseId: selectedExaminationPhaseId,
    params: fetchSubmissionParams,
  });

  const {
    selectedPublishTargets,
    resetSelectedPublishTargets,
    hasSelectedTargets,
    toggleAllDisplayedPublishTargets,
    onCheck,
    countOfSelectedTargets,
  } = useSelectedPublishTarget(reviewInfos);

  const setPageTitleWithBatchName = useCallback(
    (batchId: string) => {
      if (batches && batches.length > 0) {
        const batch = batches.find((a) => a.id === batchId);
        if (batch) {
          props.context.updatePageTitle?.(batch.name);
        }
      }
    },
    [props.context, batches],
  );

  const onChangePageByBatches = useCallback(
    (batchId: string) => {
      setPageTitleWithBatchName(batchId);
    },
    [setPageTitleWithBatchName],
  );

  useEffect(() => {
    /*
            このページを離れたらキャッシュを削除する。
            本当はプロジェクト提出時に行いたいが、それが困難なため。
            プロジェクトを提出するコンポーネントがクラスコンポーネントなのでSWRのキャシュをクリアするカスタムフックを呼ぶことができない。
        */
    return () => {
      deleteSubmittedProjectCache();
      deleteMatchedCaches(batchesCacheKeyMatcher);
    };
  }, [deleteMatchedCaches, deleteSubmittedProjectCache]);

  useEffect(() => {
    onChangePageByBatches(batchId);
  }, [batchId, onChangePageByBatches]);

  useEffect(() => {
    getAndSetSelectedExaminationPhaseId(examinationPhases);
  }, [getAndSetSelectedExaminationPhaseId, examinationPhases]);

  const onClickExaminationPhase = useCallback(
    (examinationPhase: ExaminationPhase) => {
      setSelectedExaminationPhaseId(examinationPhase.id);
      resetPage();
    },
    [resetPage, setSelectedExaminationPhaseId],
  );

  const onChangeBatchSelect = useCallback(
    (batchId: string) => {
      history.push(`/dashboard/project_submissions/${batchId}`);
      setBatchId(batchId);
      setCategoryId(null);
      resetPage();
    },
    [history, resetPage],
  );

  const onChangeCategoriesSelectBox = useCallback(
    (categoryId: string | null) => {
      resetPage();
      setCategoryId(categoryId);
    },
    [resetPage],
  );

  return (
    <div>
      <UploadReviewExcelModal
        onUploadSuccess={submittedProjectsMutate}
        isVisible={isVisible}
        closeModal={closeModal}
      />
      <div className={styles.select_box_container}>
        <BatchSelectBox
          batchId={batchId}
          batches={batches}
          onChangeBatchSelect={onChangeBatchSelect}
        />
        {currentBatch?.Batch_SubmissionCategory && (
          <CategorySelectBox
            categoryId={categoryId}
            categories={currentBatch.Batch_SubmissionCategory}
            onChange={onChangeCategoriesSelectBox}
          />
        )}
      </div>
      {examinationPhases &&
        selectedExaminationPhaseId &&
        currentBatch?.isExaminationPhaseEnabled && (
          <div className={styles.phase_tab_group_container}>
            <PhaseTabGroup
              selectedPhaseId={selectedExaminationPhaseId}
              phases={examinationPhases}
              onSelectPhase={onClickExaminationPhase}
            />
          </div>
        )}
      <div className={styles.examination_info_count}>
        <SubmittedProjectCountLabel count={count} />
        <div className={styles.review_buttons}>
          <ExportCsvButton
            disabled={reviewInfos.length <= 0}
            batchId={batchId}
            categoryId={categoryId}
            canShow={props.context.user?.isSuper ?? false}
          />
          <ExcelExportButton
            batchName={currentBatch?.name ?? ""}
            batchId={batchId}
            categoryId={categoryId}
            examinationPhaseId={selectedExaminationPhaseId}
            results={selectedResults}
          />
          <ImportReviewExcelButton onClick={openModal} />
        </div>
      </div>
      <div className={styles.grid_and_actions}>
        <div className={styles.grid_and_actions_inner}>
          <div className={styles.filter_and_sort_actions}>
            <div>
              <SubmittedProjectSearchBar
                setSearchWord={setSearchWord}
                resetPagingToInitial={resetPage}
              />
            </div>
            <div className={styles.select_boxes}>
              <ReviewResultSelectBox
                setSelectedResults={setSelectedResults}
                selectedResults={selectedResults}
                resetPage={resetPage}
              />
              <PublishSelectBox
                selectedPublishedStatuses={selectedPublishedStatuses}
                onChangeSelectedPublishedStatus={(publishedStatus) => {
                  onChangeSelectedPublishedStatus(publishedStatus);
                  resetPage();
                }}
                resetCheck={resetAllPublishedStatus}
              />
              <SortReviewSelectBox
                reviewSortField={sortField}
                onChangeSortField={onChangeSortField}
              />
            </div>
          </div>
          <div className={styles.pagination_container}>
            <Pagination
              page={page}
              limit={limit}
              count={count}
              onChangeLimit={onChangeLimit}
              onNextPage={onNextPage}
              onPrevPage={onPrevPage}
            />
            <PublishReviewButton
              countOfSelectedTargets={countOfSelectedTargets}
              selectedPublishTargets={selectedPublishTargets}
              publishReview={publishReviews}
              hasSelectedTargets={hasSelectedTargets}
              resetSelectedPublishTargets={resetSelectedPublishTargets}
            />
          </div>
          <ReviewInfoGrid
            reviewInfos={reviewInfos}
            rowSelection={selectedPublishTargets}
            width={"100%"}
            toggleAllDisplayedPublishTargets={toggleAllDisplayedPublishTargets}
            onCheck={onCheck}
          />
        </div>
      </div>
    </div>
  );
};
