import { FormattedMessage } from "react-intl";
export const parsePagination = function (page, total, unit = null) {
  let pageDisplay = "";
  if (total > 0) {
    const startOrder = (page - 1) * process.env.REACT_APP_PAGE_SIZE;
    const endOrder = startOrder + parseInt(process.env.REACT_APP_PAGE_SIZE);

    pageDisplay = `${startOrder + 1} ~ ${
      endOrder > total ? total : endOrder
    } / ${total}`;
  } else {
    pageDisplay = `0 ~ 0 / 0`;
  }
  if (unit) {
    return (
      <FormattedMessage
        id="screen.label.pagination_unit_count"
        values={{ count: pageDisplay, unit: unit }}
      />
    );
  } else {
    return (
      <FormattedMessage
        id="screen.label.pagination_count"
        values={{ count: pageDisplay }}
      />
    );
  }
};

export const customParsePagination = function (
  page,
  limit,
  total,
  unit = null,
) {
  let pageDisplay = "";
  let pageLimit = limit || process.env.REACT_APP_PAGE_SIZE;
  if (total > 0) {
    const startOrder = (page - 1) * pageLimit;
    const endOrder = startOrder + parseInt(pageLimit);

    pageDisplay = `${startOrder + 1} ~ ${
      endOrder > total ? total : endOrder
    } / ${total}`;
  } else {
    pageDisplay = `0 ~ 0 / 0`;
  }
  if (unit) {
    return (
      <FormattedMessage
        id="screen.label.pagination_unit_count"
        values={{ count: pageDisplay, unit: unit }}
      />
    );
  } else {
    return (
      <FormattedMessage
        id="screen.label.pagination_count"
        values={{ count: pageDisplay }}
      />
    );
  }
};
