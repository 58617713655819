import { message, notification } from "antd";
import { useEffect, useRef } from "react";
import { useIntl } from "react-intl";

import { endpoints, parseEndpoint } from "../../../Services/endpoints";
import createFormData from "../../../utils/createFormData";
import { useStateWithRef } from "../../../utils/hooks";
import { PostNewVideoScreenState } from "../types/IPostNewVideoScreenProps";

// @ts-expect-error TS7006
const usePostNewVideoScreen = (props) => {
  const intl = useIntl();
  const [state, customSetState, stateRef] = useStateWithRef(() => {
    return {
      isModalManageCategoriesVisible: false,
      smallCategories: {},
      videoLargeCategoryId: 0,
      companyIds: [],
      attachingVideo: {
        loading: false,
        message: "",
      },
      newVideo: {
        videoLargeCategoryId: 0,
        videoSmallCategoryId: 0,
        name: "",
        description: "",
        videoUrl: null,
      },
      companies: [],
    };
  });
  const setStateCallbackRef = useRef(() => {});
  useEffect(() => {
    const callBack = setStateCallbackRef.current;
    callBack();
    setStateCallbackRef.current = () => {};
  }, [state]);
  const setState = (data: PostNewVideoScreenState, callback = () => {}) => {
    setStateCallbackRef.current = callback;
    // @ts-expect-error TS7006
    customSetState((previousState) => {
      return {
        ...previousState,
        ...data,
      };
    });
  };

  useEffect(() => {
    props.dispatchSetPageTitle("screen.label.video");
    getVideoLargeCategories();
  }, []);

  const getUploadProps = () => {
    return {
      // @ts-expect-error TS7006
      beforeUpload: (file) => {
        return new Promise((resolve, reject) => {
          setState({
            // @ts-expect-error TS2345
            attachingVideo: {
              loading: true,
              message: intl.formatMessage({
                id: "screen.label.please_wait",
              }),
            },
          });
          const requestBuilder = {
            method: "postRequest",
            url: parseEndpoint(endpoints.generateVimeoForm),
            data: {
              size: file.size,
              name:
                state.newVideo.name.length > 0
                  ? state.newVideo.name
                  : file.name,
              description:
                state.newVideo.description.length > 0
                  ? state.newVideo.description
                  : "",
            },
          };
          // @ts-expect-error TS7031
          props.dispatchApiRequest(requestBuilder, ({ data }) => {
            const { newVideo } = state;
            newVideo.videoUrl = data.link;
            setState(
              {
                // @ts-expect-error TS2345
                formActionUrl: data.action,
                newVideo,
              },
              () => {
                // @ts-expect-error TS2794
                return resolve();
              },
            );
          });
        });
      },
      accept: "video/*",
      name: "file_data",
      action: state.formActionUrl,
      // @ts-expect-error TS7006
      onChange: (info) => {
        if (info.file.percent >= 100) {
          message.success(
            `${info.file.name} ${intl.formatMessage({
              id: "screen.label.upload_successfully",
            })}`,
          );
          setState({
            // @ts-expect-error TS2345
            attachingVideo: {
              loading: false,
              message: "",
            },
          });
        }
      },
      showUploadList: {
        removeIcon: true,
      },
    };
  };
  const onCreateNewVideo = () => {
    let { newVideo } = state;
    const selectedLargeCategory = props.largeCategories.filter(
      // @ts-expect-error TS7006
      (l) => l.id === state.videoLargeCategoryId,
    );
    const vimeoUrlMessage = props.user.isSuper
      ? intl.formatMessage({
          id: "screen.label.vimeo_file_attachment_required",
        })
      : intl.formatMessage({
          id: "screen.label.video_file_attachment_required",
        });
    if (selectedLargeCategory.length <= 0)
      return notification.error({
        message: intl.formatMessage({
          id: "screen.label.large_category_required_field",
        }),
      });
    if (newVideo.videoSmallCategoryId <= 0)
      return notification.error({
        message: intl.formatMessage({
          id: "screen.label.small_category_required_field",
        }),
      });
    if (newVideo.name.length <= 0)
      return notification.error({
        message: intl.formatMessage({
          id: "screen.label.title_required_field",
        }),
      });
    if (newVideo.description.length <= 0)
      return notification.error({
        message: intl.formatMessage({
          id: "screen.label.details_are_required",
        }),
      });
    if (!newVideo.videoUrl)
      return notification.error({ message: vimeoUrlMessage });
    newVideo = selectedLargeCategory[0].companyId
      ? {
          ...newVideo,
          companyId: selectedLargeCategory[0].companyId,
        }
      : newVideo;
    const baseUrl = selectedLargeCategory[0].companyId
      ? ""
      : `${process.env.REACT_APP_BASEHOST}companies/`;
    const requestBuilder = {
      method: "postRequest",
      url: parseEndpoint(endpoints.createGlobalVideo, {
        baseUrl,
        videoLargeCategoryId: newVideo.videoLargeCategoryId,
        videoSmallCategoryId: newVideo.videoSmallCategoryId,
      }),
      data: createFormData(newVideo),
      successMessage: intl.formatMessage({
        id: "screen.label.video_posted_wait_des",
      }),
      successReRoute: "/global-video",
    };
    // @ts-expect-error TS7031
    props.dispatchApiRequest(requestBuilder, ({ data }) => {});
  };
  // @ts-expect-error TS7006
  const getLargeCategoryIdForSelect = (largeCategory) => {
    if (largeCategory.companyId !== null) {
      return false;
    } else if (largeCategory.companyId === null && props.user.isSuper) {
      return false;
    }
    return true;
  };
  // @ts-expect-error TS7006
  const onDeleteSmallCategory = (largeCategoryId, smallCategoryId) => {
    const requestBuilder = {
      method: "deleteRequest",
      url: parseEndpoint(endpoints.videoSmallCategory, {
        smallCategoryId,
      }),
      data: {},
    };
    props.dispatchApiRequest(requestBuilder, ({}) => {
      getSmallCategoryForEachLargeCategory(largeCategoryId);
    });
  };
  // @ts-expect-error TS7006
  const onSubmitNewCategory = (newSmallCategory) => {
    if (newSmallCategory.name.length > 0) {
      const data = {
        ...newSmallCategory,
        companyId: null,
      };
      const requestBuilder = {
        method: "postRequest",
        url: parseEndpoint(endpoints.smallCategoriesForSingleLargeCategory, {
          largeCategoryId: newSmallCategory.videoLargeCategoryId,
        }),
        data,
      };
      // @ts-expect-error TS7031
      props.dispatchApiRequest(requestBuilder, ({ data }) => {
        getSmallCategoryForEachLargeCategory(
          newSmallCategory.videoLargeCategoryId,
        );
      });
    }
  };
  // @ts-expect-error TS7006
  const onChangeNewVideoData = (key, value) => {
    const { newVideo } = state;
    newVideo[key] = value;
    setState({
      newVideo,
    });
  };
  const getVideoLargeCategories = () => {
    if (props.largeCategories.length === 0) {
      const requestBuilder = {
        method: "getRequest",
        url: parseEndpoint(endpoints.videoLargeCategory),
        data: {},
      };
      // @ts-expect-error TS7031
      props.dispatchApiRequest(requestBuilder, ({ data }) => {
        props.dispatchSetVideoCategories(data);
        actionAfterFetchingLargeCategories(data);
      });
    } else {
      actionAfterFetchingLargeCategories(props.largeCategories);
    }
  };
  // @ts-expect-error TS7006
  const actionAfterFetchingLargeCategories = (data) => {
    if (Array.isArray(data)) {
      data.forEach((singleLargeCategory) => {
        if (singleLargeCategory.companyId != null) {
          setState({
            companyLargeCatId: singleLargeCategory.id,
          });
        }
        getSmallCategoryForEachLargeCategory(singleLargeCategory.id);
      });
    }
  };
  // @ts-expect-error TS7006
  const getSmallCategoryForEachLargeCategory = (largeCategoryId) => {
    const requestBuilder = {
      method: "getRequest",
      url: parseEndpoint(endpoints.smallCategoriesForSingleLargeCategory, {
        largeCategoryId,
      }),
      data: {},
    };
    // @ts-expect-error TS7031
    props.dispatchApiRequest(requestBuilder, ({ data }) => {
      const { smallCategories } = state;
      smallCategories[largeCategoryId] = data;
      setState({ smallCategories });
    });
  };
  return {
    intl,
    state: stateRef.current,
    setState,
    getUploadProps,
    onCreateNewVideo,
    getLargeCategoryIdForSelect,
    onDeleteSmallCategory,
    onSubmitNewCategory,
    onChangeNewVideoData,
    getVideoLargeCategories,
    actionAfterFetchingLargeCategories,
    getSmallCategoryForEachLargeCategory,
  };
};

export default usePostNewVideoScreen;
