import { UploadFile } from "antd";
import { FC, useContext, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { useParamId } from "@/hooks/useParamId";
import { ContentContext } from "@/Provider";
import { ApiRequestData } from "@/Redux/ApiRedux";
import { ReduxState } from "@/Redux/types";
import { endpoints, parseEndpoint } from "@/Services/endpoints";

import { Category } from "../../../../../types/Category";
import { ProjectSubmissionFileUpload } from "../ProjectSubmissionFileUpload";
import { ProjectSubmissionModal } from "./component";
import { ProjectSubmissionFormSchema } from "./schema";

export type ParsedFileList = {
  uploadUrl: string;
  uploadedFileId: string;
  friendlyFileName: string;
}[];

type ProjectSubmissionModalContainerProps = {
  visible: boolean;
  onClose: () => void;
};

export const ProjectSubmissionModalContainer: FC<
  ProjectSubmissionModalContainerProps
> = ({ visible, onClose }) => {
  const intl = useIntl();
  const { apiRequest } = useContext(ContentContext);

  const project = useSelector(
    (state: ReduxState) => state.projects.projectPayload,
  );
  const projectId = useParamId();

  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [parsedFileList, setParsedFileList] = useState<ParsedFileList>([]);

  const categories: Category[] = [
    ...(project.Batch?.Batch_SubmissionCategory ?? []),
  ];

  const handleClose = () => {
    setFileList([]);
    setParsedFileList([]);
    onClose();
  };

  const handleSubmit = (
    data: ProjectSubmissionFormSchema,
    successCallback: () => void,
  ) => {
    const requestBuilder: ApiRequestData = {
      url: parseEndpoint(endpoints.submitProjectForReview, {
        data: {
          projectId,
        },
      }),
      data: {
        projectId,
        title: data.title,
        fileUrl: data.fileUrl,
        fileUrlName: data.fileUrlName,
        category: data.category,
        parsedFileList,
        batch_SubmissionCategoryId: data.categoryId,
      },
      method: "postRequest",
      successMessage: intl.formatMessage({
        id: "screen.label.project_has_been_submitted",
      }),
    };

    apiRequest(requestBuilder, () => {
      successCallback();
      handleClose();
    });
  };

  const uploaderComponent = useMemo(
    () => (
      <ProjectSubmissionFileUpload
        fileList={fileList}
        setFileList={setFileList}
        parsedFileList={parsedFileList}
        setParsedFileList={setParsedFileList}
      />
    ),
    [fileList, parsedFileList],
  );

  return (
    <ProjectSubmissionModal
      visible={visible}
      onClose={handleClose}
      onSubmit={handleSubmit}
      projectName={project.name}
      categories={categories}
      uploaderComponent={uploaderComponent}
    />
  );
};
