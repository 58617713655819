import { FC } from "react";
import { useIntl } from "react-intl";

import { SortableTagList } from "@/shared/tags/components/SortableTagList";

type ExaminationLabelTags = {
  labels: string[];
  setLabels: (labels: string[]) => void;
};

export const ExaminationLabelTags: FC<ExaminationLabelTags> = ({
  labels,
  setLabels,
}) => {
  const intl = useIntl();

  const handleCreate = (newLabel: string) => {
    const isDuplicate = labels.some((label) => label === newLabel);
    const isEmpty = newLabel.trim().length === 0;
    if (isDuplicate || isEmpty) return;

    setLabels([...labels, newLabel]);
  };

  const handleEdit = (oldLabel: string, newLabel: string) => {
    const isDuplicate = labels.some((label) => label === newLabel);
    const isEmpty = newLabel.trim().length === 0;
    if (isDuplicate || isEmpty) return;

    const newLabels = labels.map((label) =>
      label === oldLabel ? newLabel : label,
    );
    setLabels(newLabels);
  };

  const handleDeleted = (deletedLabel: string) => {
    const _labels = labels.filter((tag) => tag !== deletedLabel);
    setLabels(_labels);
  };

  const handleSortEnd = (newLabels: string[]) => {
    setLabels(newLabels);
  };

  return (
    <SortableTagList
      tags={labels}
      createLabel={intl.formatMessage({
        id: "screen.label.examination_flag",
      })}
      onCreated={handleCreate}
      onEdited={handleEdit}
      onDeleted={handleDeleted}
      onSortEnd={handleSortEnd}
    />
  );
};
