import { ComponentProps, FC } from "react";
import { useIntl } from "react-intl";

import styles from "./styles.module.scss";

type InputLengthCountLabelProps = ComponentProps<"span"> & {
  count: number;
  maxLength: number;
  isError?: boolean;
};

export const InputLengthCountLabel: FC<InputLengthCountLabelProps> = ({
  className,
  count,
  maxLength,
  isError = false,
  ...props
}) => {
  const intl = useIntl();
  const text = intl.formatMessage(
    { id: "screens.label.input_length_count_label" },
    {
      count: <span className={styles.character_length}>{count}</span>,
      maxLength,
    },
  );
  const fontColor = isError ? styles.error_color : styles.default_color;

  return (
    <span className={`${styles.label} ${fontColor} ${className}`} {...props}>
      {text}
    </span>
  );
};
