import { LeftOutlined } from "@ant-design/icons";
import { Button, Card, Col, Row, Switch } from "antd";
import { arrayMoveImmutable } from "array-move";
import React from "react";
import { FormattedMessage } from "react-intl";
import { SortableContainer, SortableElement } from "react-sortable-hoc";

import { ReorderSwitch, sortStyle } from "./Components/ReorderSwitch";
import useBatchHypothesisManagementScreen from "./hooks/useBatchHypothesisManagementScreen";
import NewHypothesisModal from "./NewHypthesisModal";
import SingleHypothesisItem from "./SingleHypothesisItem";
import {
  BatchHypothesisManagementScreenProps,
  SortableSingleHypothesisItemProps,
  SortableSingleHypothesisListProps,
} from "./types/SortableSingleHypothesisItemProps";

// @ts-expect-error TS2322
const SortableSingleHypothesisItem: React.FC<SortableSingleHypothesisItemProps> =
  SortableElement(
    ({
      displayCheckpoint,
      singleHypothesis,
      openNewHypothesisModal,
      onSaveHypothesis,
      isHypothesisSortable,
    }: SortableSingleHypothesisItemProps) => (
      <li>
        <SingleHypothesisItem
          displayCheckpoint={displayCheckpoint}
          openNewHypothesisModal={openNewHypothesisModal}
          onSaveHypothesis={onSaveHypothesis}
          singleHypothesis={singleHypothesis}
          isHypothesisSortable={isHypothesisSortable}
        />
      </li>
    ),
  );

// @ts-expect-error TS2322
const SortableSingleHypothesisList: React.FC<SortableSingleHypothesisListProps> =
  SortableContainer(
    ({
      displayCheckpoint,
      items,
      isHypothesisSortable,
      openNewHypothesisModal,
      onSaveHypothesis,
    }: SortableSingleHypothesisListProps) => {
      return (
        // @ts-expect-error TS2322
        <ul style={sortStyle["SortableList"]}>
          {/*
           // @ts-expect-error TS18048 */}
          {items.map((singleHypothesis, index) => (
            <SortableSingleHypothesisItem
              displayCheckpoint={displayCheckpoint}
              key={singleHypothesis.id}
              // @ts-expect-error TS2322
              index={index}
              singleHypothesis={singleHypothesis}
              disabled={!isHypothesisSortable}
              openNewHypothesisModal={openNewHypothesisModal}
              onSaveHypothesis={onSaveHypothesis}
              isHypothesisSortable={isHypothesisSortable}
            />
          ))}
        </ul>
      );
    },
  );

const BatchHypothesisManagementScreen: React.FC<BatchHypothesisManagementScreenProps> =
  function (props: BatchHypothesisManagementScreenProps) {
    const {
      company,
      history,
      batchName,
      isHypothesisSortable,
      hypothesis,
      isNewHypothesisModalVisible,
      displayCheckpoint,
      defaultHypothesisData,
      saveOrder,
      _getHypothesis,
      onSaveHypothesis,
      setIsNewHypothesisModalVisible,
      _clearDefaultHypothesis,
      setIsHypothesisSortable,
      setDefaultHypothesisData,
      setHypothesis,
      onUpdateBatchDisplayCheckpoint,
    } = useBatchHypothesisManagementScreen(props);

    return (
      <>
        {isNewHypothesisModalVisible ? (
          <NewHypothesisModal
            onSaveHypothesis={onSaveHypothesis}
            defaultHypothesisData={defaultHypothesisData}
            setIsNewHypothesisModalVisible={setIsNewHypothesisModalVisible}
            isVisible={isNewHypothesisModalVisible}
            modalOnClosed={() => {
              _clearDefaultHypothesis();
            }}
          />
        ) : null}
        <Card title={<FormattedMessage id="screens.company.hypothesis_mgt" />}>
          <Row>
            <Col span={24}>
              <Button
                onClick={() => {
                  history.goBack();
                }}
                icon={<LeftOutlined />}
              >
                <FormattedMessage id="screen.batch_hypothesis.go_back" />
              </Button>
            </Col>
            <Col
              xs={24}
              sm={{ offset: 2, span: 20 }}
              md={{ offset: 3, span: 18 }}
              lg={{ offset: 6, span: 12 }}
            >
              <Row>
                <Col>
                  <p
                    style={{
                      fontFamily: "Roboto",
                      fontWeight: "bold",
                      fontSize: "24px",
                      lineHeight: "28px",
                    }}
                  >
                    {batchName}
                  </p>
                  <br />
                </Col>
              </Row>
              <Row gutter={[12, 12]} align="middle">
                <Col span={8}>
                  <ReorderSwitch>
                    <FormattedMessage id="screens.label.reorder_hypothesis" />
                    <Switch
                      style={{ marginLeft: "6px" }}
                      size={"small"}
                      onChange={(checked) => {
                        setIsHypothesisSortable(checked);
                        if (checked === false) {
                          _getHypothesis();
                        }
                      }}
                      checked={isHypothesisSortable}
                    />
                  </ReorderSwitch>
                </Col>
                {company?.isAllowCheckpoint && (
                  <Col span={8}>
                    <ReorderSwitch>
                      <FormattedMessage id="screen.label.enable_checkpoint" />
                      <Switch
                        style={{ marginLeft: "6px" }}
                        size={"small"}
                        onChange={(checked) => {
                          onUpdateBatchDisplayCheckpoint(checked);
                        }}
                        checked={displayCheckpoint}
                      />
                    </ReorderSwitch>
                  </Col>
                )}

                <Col span={6}>
                  <Button
                    onClick={() => {
                      setIsNewHypothesisModalVisible(true);
                    }}
                    style={{
                      backgroundColor: "#00D282",
                      color: "white",
                      fontWeight: 400,
                    }}
                  >
                    <FormattedMessage id="screen.batch_hypothesis.new_hypothesis" />
                  </Button>
                </Col>
              </Row>
              {isHypothesisSortable ? (
                <Row>
                  <Col>
                    <Button
                      type={"ghost"}
                      onClick={() => {
                        saveOrder();
                      }}
                    >
                      <FormattedMessage id="screen.label.save" />
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      onClick={() => {
                        _getHypothesis();
                      }}
                    >
                      <FormattedMessage id="screen.label.cancel" />
                    </Button>
                  </Col>
                </Row>
              ) : null}
              <br />
              {Array.isArray(hypothesis) ? (
                <SortableSingleHypothesisList
                  displayCheckpoint={displayCheckpoint}
                  // @ts-expect-error TS2322
                  distance={1}
                  items={hypothesis}
                  helperClass={"SortableHelper"}
                  // @ts-expect-error TS7006
                  openNewHypothesisModal={(copiedHypothesisData) => {
                    setDefaultHypothesisData(copiedHypothesisData);
                    setIsNewHypothesisModalVisible(true);
                  }}
                  onSaveHypothesis={onSaveHypothesis}
                  isHypothesisSortable={isHypothesisSortable}
                  // @ts-expect-error TS7031
                  onSortEnd={({ oldIndex, newIndex }) => {
                    const newHypothesisOrder = arrayMoveImmutable(
                      hypothesis,
                      oldIndex,
                      newIndex,
                    ).map((h, index) => {
                      return {
                        // @ts-expect-error TS2698
                        ...h,
                        hypothesisFormMetadata: {
                          order: index,
                        },
                      };
                    });
                    // @ts-expect-error TS2345
                    setHypothesis(newHypothesisOrder);
                  }}
                />
              ) : null}
            </Col>
          </Row>
        </Card>
      </>
    );
  };
export default BatchHypothesisManagementScreen;
