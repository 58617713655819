import ArrowGrayMediumLeft from "./images/arrow-gray-medium-left.svg";
import ArrowGrayMediumRight from "./images/arrow-gray-medium-right.svg";
import ArrowGreenSmallDown from "./images/arrow-green-small-down.svg";
import ArrowMediumDown from "./images/arrow-medium-down.svg";
import ArrowMediumLeft from "./images/arrow-medium-left.svg";
import ArrowMediumRight from "./images/arrow-medium-right.svg";
import ArrowMediumUp from "./images/arrow-medium-up.svg";
import ArrowSmallDown from "./images/arrow-small-down.svg";
import ArrowSmallLeft from "./images/arrow-small-left.svg";
import ArrowSmallRight from "./images/arrow-small-right.svg";
import ArrowSmallUp from "./images/arrow-small-up.svg";
import { ArrowProps } from "./type";

const getArrowImage = (
  size: string,
  direction: string,
  color: ArrowProps["color"],
) => {
  switch (size) {
    case "small":
      switch (direction) {
        case "up":
          return ArrowSmallUp;
        case "down":
          switch (color) {
            case "green":
              return ArrowGreenSmallDown;
            default:
              return ArrowSmallDown;
          }
        case "left":
          return ArrowSmallLeft;
        case "right":
          return ArrowSmallRight;
        default:
          return ArrowSmallDown;
      }
    case "medium":
      switch (direction) {
        case "up":
          return ArrowMediumUp;
        case "down":
          return ArrowMediumDown;
        case "left":
          switch (color) {
            case "gray":
              return ArrowGrayMediumLeft;
            default:
              return ArrowMediumLeft;
          }
        case "right":
          switch (color) {
            case "gray":
              return ArrowGrayMediumRight;
            default:
              return ArrowMediumRight;
          }
        default:
          return ArrowMediumDown;
      }
    default:
      return ArrowMediumDown;
  }
};
export const Arrow = ({ size, direction, color = "black" }: ArrowProps) => {
  const imageName = getArrowImage(size, direction, color);
  return <img src={imageName} alt="arrow-icon" />;
};
