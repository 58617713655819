import { useEffect, useRef } from "react";
import { useIntl } from "react-intl";

import { endpoints, parseEndpoint } from "../../../Services/endpoints";
import { useStateWithRef } from "../../../utils/hooks";
import { SmallCategoriesManagementScreenState } from "../types/ISmallCategoriesManagementScreenProps";

// @ts-expect-error TS7006
const useSmallCategoriesManagementScreen = (props) => {
  const intl = useIntl();
  const [state, customSetState, stateRef] = useStateWithRef(() => {
    return {
      page: 1,
      limit: process.env.REACT_APP_PAGE_SIZE,
      isCategoryModalVisible: false,
      smallCategories: {},
      videos: {
        rows: [],
        count: 0,
      },
      isSearch: false,
      searchWord: "",
      lastVideoId: null,
    };
  });
  const setStateCallbackRef = useRef(() => {});

  const setState = (
    data: SmallCategoriesManagementScreenState,
    callback = () => {},
  ) => {
    setStateCallbackRef.current = callback;
    // @ts-expect-error TS7006
    customSetState((previousState) => {
      return {
        ...previousState,
        ...data,
      };
    });
  };

  useEffect(() => {
    const callBack = setStateCallbackRef.current;
    callBack();
    setStateCallbackRef.current = () => {};
  }, [state]);

  useEffect(() => {
    props.dispatchSetPageTitle("screen.label.video");
    getVideoLargeCategories();
  }, []);

  useEffect(() => {
    applyFilter();
  }, [state.videoSmallCategoryId, state.page]);

  const getVideoLargeCategories = () => {
    if (props.largeCategories.length === 0) {
      const requestBuilder = {
        method: "getRequest",
        url: parseEndpoint(endpoints.videoLargeCategory),
        data: {},
      };
      // @ts-expect-error TS7031
      props.dispatchApiRequest(requestBuilder, ({ data }) => {
        props.dispatchSetVideoCategories(data);
        actionAfterFetchingLargeCategories(data);
      });
    } else {
      actionAfterFetchingLargeCategories(props.largeCategories);
    }
  };

  // @ts-expect-error TS7006
  const actionAfterFetchingLargeCategories = (data) => {
    const { smallCategories } = state;
    if (Array.isArray(data)) {
      const largeCategories = data.filter((l) => l.id == getLargeCategoryId());
      for (let i = 0; i < largeCategories.length; i++) {
        const singleLargeCategory = largeCategories[i];
        const singleSmallCat = Object.assign(
          {},
          singleLargeCategory.VideoSmallCategory,
        );
        singleSmallCat.Vidoes = [];
        smallCategories[singleLargeCategory.id] = singleSmallCat;
        getSmallCategoryForEachLargeCategory(singleLargeCategory.id);
      }
      setTimeout(() => {
        if (largeCategories.length > 0) {
          setState({
            smallCategories,
            videoLargeCategoryId: largeCategories[0].id,
          });
          const data = state.smallCategories[largeCategories[0].id];
          if (Array.isArray(data) && data.length > 0) {
            setState(
              {
                videoSmallCategoryId: data[0].id,
              },
              () => {
                applyFilter();
              },
            );
          }
        }
      }, 2 * 1000);
    }
  };

  // @ts-expect-error TS7006
  const getSmallCategoryForEachLargeCategory = (largeCategoryId) => {
    const requestBuilder = {
      method: "getRequest",
      url: parseEndpoint(endpoints.smallCategoriesForSingleLargeCategory, {
        largeCategoryId,
      }),
      data: {},
    };
    // @ts-expect-error TS7031
    props.dispatchApiRequest(requestBuilder, ({ data }) => {
      const { smallCategories } = state;
      smallCategories[largeCategoryId] = data;
      setState(
        {
          smallCategories,
          videoLargeCategoryId: largeCategoryId,
        },
        () => applyFilter(),
      );
    });
  };

  const getLargeCategoryName = () => {
    const {
      match: { params },
    } = props;
    return params.name ? params.name : "";
  };

  const getLargeCategoryId = () => {
    const {
      match: { params },
    } = props;
    return params.id ? params.id : -1;
  };

  // @ts-expect-error TS7006
  const deleteVideo = (videoId) => {
    const requestBuilder = {
      method: "deleteRequest",
      url: parseEndpoint(endpoints.singleVideo, {
        videoId,
      }),
      data: {},
    };
    props.dispatchApiRequest(requestBuilder, ({}) => {
      applyFilter();
    });
  };

  // @ts-expect-error TS7006
  const reorderVideoApi = (oldOrder) => {
    // @ts-expect-error TS7006
    const data = state.videos.rows.map((v) => {
      return {
        id: v.id,
        nextVideoId: v.nextVideoId,
        previousVideoId: v.previousVideoId,
      };
    });
    const preFirst = oldOrder[0].previousVideoId;
    const postLast = oldOrder[oldOrder.length - 1].nextVideoId;
    const requestBuilder = {
      method: "putRequest",
      url: parseEndpoint(endpoints.videoSmallCategory, {
        smallCategoryId: state.videoSmallCategoryId,
      }),
      successMessage: intl.formatMessage({
        id: "screen.label.reordering_video_completed",
      }),
      data: {
        body: data,
        preFirst,
        postLast,
      },
    };
    // @ts-expect-error TS7031
    props.dispatchApiRequest(requestBuilder, ({ data }) => {
      setState(
        {
          page: 1,
        },
        () => {
          applyFilter();
        },
      );
    });
  };

  const getDragProps = () => {
    if (state.isSearch) {
      return {
        // @ts-expect-error TS7006
        onDragEnd: (fromIndex, toIndex) => {},
        handleSelector: ".drag-handle",
      };
    }
    return {
      // @ts-expect-error TS7006
      onDragEnd: (fromIndex, toIndex) => {
        const { videos } = state;
        const rows = [...videos.rows];
        const oldOrder = Object.assign([], rows);
        const item = rows.splice(fromIndex - 1, 1)[0];
        rows.splice(toIndex - 1, 0, item);
        videos.rows = rows;
        setState(
          {
            videos,
          },
          () => {
            reorderVideoApi(oldOrder);
          },
        );
      },
      handleSelector: ".drag-handle",
    };
  };

  // @ts-expect-error TS7006
  const onDeleteSmallCategory = (largeCategoryId, smallCategoryId) => {
    const requestBuilder = {
      method: "deleteRequest",
      url: parseEndpoint(endpoints.videoSmallCategory, {
        smallCategoryId,
      }),
      data: {},
    };
    // @ts-expect-error TS7031
    props.dispatchApiRequest(requestBuilder, ({ data }) => {
      getSmallCategoryForEachLargeCategory(largeCategoryId);
    });
  };

  // @ts-expect-error TS7006
  const onSubmitNewCategory = (newSmallCategory) => {
    if (newSmallCategory.name.length > 0) {
      const data = {
        ...newSmallCategory,
        companyId: null,
      };
      const requestBuilder = {
        method: "postRequest",
        url: parseEndpoint(endpoints.smallCategoriesForSingleLargeCategory, {
          largeCategoryId: newSmallCategory.videoLargeCategoryId,
        }),
        data,
      };
      // @ts-expect-error TS7031
      props.dispatchApiRequest(requestBuilder, ({ data }) => {
        getSmallCategoryForEachLargeCategory(
          newSmallCategory.videoLargeCategoryId,
        );
      });
    }
  };

  const applyFilter = () => {
    const { page, limit, videoSmallCategoryId, searchWord, lastVideoId } =
      stateRef.current;
    const largeCategoryId = getLargeCategoryId();

    const filters = [];
    if (searchWord) {
      filters.push(`filter=name iLike %${searchWord}%`);
    }
    if (largeCategoryId) {
      filters.push(`videoLargeCategoryId=${largeCategoryId}`);
    }
    if (lastVideoId) {
      filters.push(`previousVideoId=${lastVideoId}`);
    }
    const filter = filters.join("&");

    if (videoSmallCategoryId) {
      const requestBuilder = {
        method: "getRequest",
        url: encodeURI(
          parseEndpoint(endpoints.smallCategoryVideos, {
            videoSmallCategoryId: videoSmallCategoryId,
            page: page - 1,
            limit,
            filter,
          }),
        ),
        data: {},
      };
      // @ts-expect-error TS7031
      props.dispatchApiRequest(requestBuilder, ({ data }) => {
        setState({
          videos: data,
        });
      });
    }
  };

  return {
    intl,
    state: stateRef.current,
    setState,
    getLargeCategoryName,
    getLargeCategoryId,
    deleteVideo,
    getDragProps,
    onDeleteSmallCategory,
    onSubmitNewCategory,
    applyFilter,
  };
};

export default useSmallCategoriesManagementScreen;
