import { Card, Checkbox, Form, Input, Popover, Select, Tag } from "antd";
import PropTypes from "prop-types";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";

import { ReduxState } from "@/Redux/types";
import { NotFoundCard } from "@/shared/cards/components/NotFoundCard";

import {
  InsuAcceptButton,
  InsuRejectButton,
} from "../../Components/atoms/buttons/shared";
import {
  InfoCircleComponent,
  ProjectCreateRoot,
} from "../../Components/molecules/project/ProjectCreateRoot";
import ProjectActions from "../../Redux/ProjectRedux";
import useProjectCreateScreen from "./hooks/useProjectCreateScreen";
import ProjectImageEdit from "./ProjectImageEdit";
import ProjectLinksComponent from "./ProjectLinksComponent";
import { IProjectCreateScreenProps } from "./types/IProjectCreateScreenProps";

const ProjectCreateScreen: FC<IProjectCreateScreenProps> = function (props) {
  const {
    intl,
    state,
    setState,
    onChangeTags,
    handleChange,
    handleSubmit,
    onBeforeUpload,
  } = useProjectCreateScreen(props);

  const { user, company } = props.context;

  const _renderCompanyProjectStages = () => {
    const stages = Array.isArray(company.stages) ? company.stages : [];
    if (Array.isArray(stages) && user.type === "admin") {
      return (
        <Form.Item
          label={intl.formatMessage({
            id: "screen.label.stage",
          })}
        >
          <Select
            defaultValue={state.stage}
            onChange={(value) => handleChange("stage", value)}
          >
            {stages.map((stage, index) => {
              return (
                <Select.Option key={index} value={stage}>
                  {stage}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
      );
    }
  };

  const _renderCompanyProjectTags = () => {
    // @ts-expect-error TS18048
    const tags = state.tags.map((tag) => {
      return (
        <Tag
          // @ts-expect-error TS18048
          color={state.projectTags.includes(tag.id) ? "black" : ""}
          onClick={() => onChangeTags(tag)}
          // @ts-expect-error TS18048
          key={tag.id}
        >
          {/*
           // @ts-expect-error TS18048 */}
          {tag.name}
        </Tag>
      );
    });
    if (Array.isArray(tags) && user.type === "admin") {
      return (
        <Form.Item
          label={intl.formatMessage({
            id: "screen.label.project_label",
          })}
        >
          {tags}
        </Form.Item>
      );
    }
  }; //

  // @ts-expect-error TS7006
  const _renderTitleWithPopover = (title, id) => {
    return (
      <span>
        {title}
        <Popover
          content={
            <div style={{ maxWidth: 300 }}>{intl.formatMessage({ id })}</div>
          }
        >
          <InfoCircleComponent />
        </Popover>
      </span>
    );
  };
  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { sm: { span: 24 } },
  };

  if (props.loading) {
    return <></>;
  }

  if (!state.batch) {
    return (
      <NotFoundCard
        resourceName={intl.formatMessage({ id: "screen.label.target_page" })}
      />
    );
  }

  return (
    <div className={"ProjectCreateScreen"}>
      <Card>
        <ProjectCreateRoot>
          <Form {...layout} style={{ maxWidth: 600 }}>
            <div className={"alpha-cloud-form-group"}>
              <Form.Item
                label={`${intl.formatMessage({
                  id: "screen.label.project_name",
                })} *`}
              >
                <Input
                  className={"project-name"}
                  required
                  onChange={(event) => handleChange("name", event.target.value)}
                  value={state.name}
                  size="large"
                  placeholder={intl.formatMessage({
                    id: "screen.label.please_enter",
                  })}
                />
              </Form.Item>
              <Form.Item
                label={<FormattedMessage id={`screen.project.camera`} />}
              >
                <ProjectImageEdit
                  project={props.projectPayload}
                  // @ts-expect-error TS2322
                  url={state.icon}
                  onFileChanged={onBeforeUpload}
                />
              </Form.Item>
              <Form.Item
                label={`${intl.formatMessage({
                  id: "screen.label.explanatory_text",
                })} *`}
              >
                <Input.TextArea
                  value={state.description}
                  rows={5}
                  onChange={(e) => handleChange("description", e.target.value)}
                  placeholder={intl.formatMessage({
                    id: "screen.label.please_enter",
                  })}
                />
              </Form.Item>
            </div>
            <div className={"alpha-cloud-form-group"}>
              {_renderCompanyProjectStages()}
              {_renderCompanyProjectTags()}
            </div>
            <div className={"alpha-cloud-form-group"}>
              <ProjectLinksComponent
                // @ts-expect-error TS7006
                onUpdateLinks={(links) => {
                  setState({ links });
                }}
                project={null}
              />
            </div>
            <div className={"alpha-cloud-form-group"}>
              <Form.Item>
                <Checkbox
                  checked={state.isPrivate}
                  onChange={(e) => handleChange("isPrivate", e.target.checked)}
                >
                  {_renderTitleWithPopover(
                    intl.formatMessage({
                      id: "screen.label.enable_private_mode",
                    }),
                    "screens.message.private.popover",
                  )}
                </Checkbox>
              </Form.Item>
            </div>
            <Form.Item>
              <InsuAcceptButton
                onClick={handleSubmit}
                className={"project-create-btn"}
                loading={props.loading}
                style={{
                  marginTop: 50,
                  marginLeft: "0.1vw",
                  marginRight: "0.1vw",
                }}
                type="primary"
              >
                {intl.formatMessage({
                  id: "screen.label.create",
                })}
              </InsuAcceptButton>
              <InsuRejectButton
                // @ts-expect-error TS18048
                onClick={props.history.goBack}
                style={{
                  marginTop: 50,
                  marginLeft: "0.1vw",
                  marginRight: "0.1vw",
                }}
              >
                {intl.formatMessage({
                  id: "screen.label.cancel",
                })}
              </InsuRejectButton>
            </Form.Item>
          </Form>
        </ProjectCreateRoot>
      </Card>
    </div>
  );
};

// @ts-expect-error TS7006
const mapDispatchToProps = (dispatch) => ({
  // @ts-expect-error TS7006
  dispatchCreateProject: (data, message) =>
    new Promise((resolve, reject) =>
      dispatch(
        // @ts-expect-error TS2554
        ProjectActions.projectCreateRequest(data, message, resolve, reject),
      ),
    ),
  dispatchGetMyProjects: (data: { userId: string; page: number }) =>
    // @ts-expect-error TS2554
    dispatch(ProjectActions.projectCurrentMemberGetsRequest(data)),
});
const mapStateToProps = (state: ReduxState) => ({
  loading: state.batch.fetching && state.projects.fetching,
  batches: state.batch.batches,
});
ProjectCreateScreen.propTypes = {
  // @ts-expect-error TS2322
  context: PropTypes.object,
  // @ts-expect-error TS2322
  history: PropTypes.string,
  match: PropTypes.object,
  projectPayload: PropTypes.object,
  // @ts-expect-error TS2322
  loading: PropTypes.bool,
  // @ts-expect-error TS2322
  dispatchCreateProject: PropTypes.func,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProjectCreateScreen);
