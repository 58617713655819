/**
 * Utility function to execute callback for eack key->value pair.
 * @param {Object} obj
 * @param {Function} callback
 */
export function forEach(obj, callback) {
  if (obj) {
    for (const key in obj) {
      // eslint-disable-line no-restricted-syntax
      if ({}.hasOwnProperty.call(obj, key)) {
        callback(key, obj[key]);
      }
    }
  }
}

/**
 * @param {Object} obj
 * @param {string} property
 */
export function hasProperty(obj, property) {
  let result = false;
  if (obj) {
    for (const key in obj) {
      // eslint-disable-line no-restricted-syntax
      if ({}.hasOwnProperty.call(obj, key) && property === key) {
        result = true;
        break;
      }
    }
  }
  return result;
}

/**
 * The function returns true if the string passed to it has no content.
 * @param {string} str
 */
export function isEmptyString(str) {
  return !str || !str.trim();
}

/**
 * The function will return true for simple javascript object,
 * which is not any other built in type like Array.
 */
export function isMap(obj) {
  return Object.prototype.toString.call(obj) === "[object Object]";
}

/**
 * The function will return filter out props fron and return new props.
 */
export function filter(obj, keys) {
  const filteredKeys = Object.keys(obj).filter((key) => keys.indexOf(key) < 0);
  const filteredObject = {};
  if (filteredKeys && filteredKeys.length > 0) {
    filteredKeys.forEach((key) => {
      filteredObject[key] = obj[key];
    });
  }
  return filteredObject;
}

export function stopPropagation(event) {
  event.stopPropagation();
}
