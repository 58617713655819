import { ExaminationResult } from "@/../types/ExaminationResult";

import { EXAMINATION_RESULT_ENTITIES } from "../../const/examinationResultEntity";

export const getResultLabelId = (result: ExaminationResult) => {
  switch (result) {
    case "BLANK":
      return EXAMINATION_RESULT_ENTITIES.BLANK.label;
    case "CONDITIONAL_PASS":
      return EXAMINATION_RESULT_ENTITIES.CONDITIONAL_PASS.label;
    case "PASS":
      return EXAMINATION_RESULT_ENTITIES.PASS.label;
    case "ON_HOLD":
      return EXAMINATION_RESULT_ENTITIES.ON_HOLD.label;
    case "OTHER":
      return EXAMINATION_RESULT_ENTITIES.OTHER.label;
    case "SEND_OFF":
      return EXAMINATION_RESULT_ENTITIES.SEND_OFF.label;
  }
};
