import { Checkbox, Modal, Row } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { useCallback, useState } from "react";

import { AntdSecondaryButton } from "../../../buttons/components/AntdSecondaryButton";
import { AntdWarningButton } from "../../../buttons/components/AntdWarningButton";
import styles from "./styles.module.scss";
import { ConfirmModalProps } from "./type";

export const ConfirmModal = ({
  isVisible,
  title,
  content,
  executionButtonText,
  cancelButtonText,
  confirmText,
  onExecute,
  onCancel,
}: ConfirmModalProps) => {
  const [isChecked, setIsChecked] = useState<boolean>(false);

  const onChange = useCallback((e: CheckboxChangeEvent) => {
    setIsChecked(e.target.checked);
  }, []);

  const cancel = useCallback(() => {
    onCancel();
    setIsChecked(false);
  }, [onCancel]);

  const execute = useCallback(() => {
    onExecute();
    setIsChecked(false);
  }, [onExecute]);

  return (
    <Modal onCancel={cancel} visible={isVisible} title={title} footer={null}>
      <div className={styles.content_container}>
        <p className={styles.text}>{content}</p>
        <Checkbox onChange={onChange} checked={isChecked}>
          {confirmText}
        </Checkbox>
        <Row>
          <AntdSecondaryButton onClick={cancel} className={styles.cancel_btn}>
            {cancelButtonText}
          </AntdSecondaryButton>
          <AntdWarningButton
            className={styles.execute_btn}
            onClick={execute}
            disabled={!isChecked}
          >
            {executionButtonText}
          </AntdWarningButton>
        </Row>
      </div>
    </Modal>
  );
};
