import { Col, Input, Popconfirm, Row } from "antd";
import React from "react";
import { useIntl } from "react-intl";

import { ButtonCV, ButtonUndo, IconDelete, IconEdit } from "../../atoms";
import { IndustryReportMainCategory } from "./types";
type Props = {
  item: IndustryReportMainCategory;
  onUpdateItem: (mainId: string, name: string) => void;
  onCreateItem: (name: string) => void;
  onDeleteItem: (mainId: string) => void;
  onRemoveItem: () => void;
};
function MainCategoryItem(props: Props) {
  const intl = useIntl();
  const { item, onUpdateItem, onCreateItem, onDeleteItem, onRemoveItem } =
    props;
  const [editing, setEditing] = React.useState(false);
  const [name, setName] = React.useState(false);
  React.useEffect(() => {
    if (item && item.id) {
      // @ts-expect-error TS2345
      setName(item.name);
    } else {
      setEditing(true);
      // @ts-expect-error TS2345
      setName(item.name);
    }
  }, [item]);
  return (
    <>
      <Row>
        <Col flex="none">
          {editing ? (
            <Input
              required
              // @ts-expect-error TS2322
              value={name}
              onChange={(event) => {
                // @ts-expect-error TS2345
                setName(event.target.value);
              }}
            />
          ) : (
            <h4 style={{ fontWeight: "bold" }}>{name}</h4>
          )}
        </Col>
        <Col flex="none" style={{ marginLeft: 16 }}>
          {editing ? (
            // @ts-expect-error TS2322
            <Row align="center" justify="end" style={{ marginTop: 4 }}>
              <ButtonCV
                onClick={() => {
                  setEditing(false);
                  if (item.id && item.id !== "") {
                    // @ts-expect-error TS2345
                    onUpdateItem(item.id, name);
                  } else {
                    // @ts-expect-error TS2345
                    onCreateItem(name);
                  }
                }}
                type="primary"
                size="small"
                style={{
                  marginLeft: 16,
                  color: "white",
                }}
              >
                {intl.formatMessage({
                  id: "screen.label.storage",
                })}
              </ButtonCV>
              <ButtonUndo
                onClick={() => {
                  setEditing(false);
                  if (!item.id) {
                    onRemoveItem();
                  }
                }}
                type={"ghost"}
                size="small"
                style={{
                  marginLeft: 10,
                }}
              >
                {intl.formatMessage({
                  id: "screen.label.cancel",
                })}
              </ButtonUndo>
            </Row>
          ) : (
            // @ts-expect-error TS2322
            <Row align="center" justify="end">
              <ButtonUndo
                icon={IconEdit}
                onClick={() => {
                  setEditing(true);
                }}
                type={"ghost"}
                size="small"
                style={{
                  marginRight: 10,
                }}
              />
              <Popconfirm
                title={intl.formatMessage({
                  id: "screen.label.delete_confirm_industory_main_category",
                })}
                onConfirm={() => {
                  onDeleteItem(item.id);
                }}
                okText={intl.formatMessage({
                  id: "screen.label.yes",
                })}
                cancelText={intl.formatMessage({
                  id: "screen.label.no",
                })}
              >
                <ButtonUndo icon={IconDelete} type={"ghost"} size="small" />
              </Popconfirm>
            </Row>
          )}
        </Col>
      </Row>
      <br />
    </>
  );
}
export default MainCategoryItem;
