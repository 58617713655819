import { convertToRaw } from "draft-js";

export const defaultProjectSetting = [
  {
    order: 0,
    name: "顧客仮説",
    key: "customer",
    type: "multiline",
    info: "誰が事業のターゲットになり得るか、顧客像の仮説。対象の顧客について大括りではなくセグメント分解されており、実在する顧客イメージを記入してください。",
    displayHypothesis: true,
    orderHypothesis: true,
    useInfoMark: true,
  },
  {
    order: 1,
    name: "課題仮説",
    key: "problem",
    type: "multiline",
    info: "顧客にとっての痛みを伴う程の課題は何でしょうか。表面の事象ではなく本質的な課題に迫って記入してください。",
    displayHypothesis: true,
    orderHypothesis: true,
    useInfoMark: true,
  },
  {
    order: 2,
    name: "提供価値仮説（UVP：Unique Value Proposition)",
    key: "uniqueValueProposition",
    type: "multiline",
    info: "顧客にとって課題解決された「理想の状態」に至るために必要な提供価値は何でしょうか。また他社や他サービスにはない独自の価値や差別化ポイントを記入してください。",
    displayHypothesis: true,
    orderHypothesis: true,
    useInfoMark: true,
  },
  {
    order: 3,
    name: "ソリューション仮説",
    key: "solution",
    type: "multiline",
    info: "提供されるソリューションで確かに顧客課題が解決されそうかの仮説。お金を払ってでも利用したいと思う理由や、実現の可能性についても触れながら記入してください。",
    displayHypothesis: true,
    orderHypothesis: true,
    useInfoMark: true,
  },
  {
    order: 4,
    name: "Why you？",
    key: "whyYou",
    type: "multiline",
    info: "あなたや、あなたのチーム／会社こそが取り組むべき理由。思い至った背景／動機や、顧客課題への想いについて記入してください。",
    displayHypothesis: true,
    orderHypothesis: true,
    useInfoMark: true,
  },
  {
    order: 5,
    name: "チャネル仮説",
    key: "channel",
    type: "multiline",
    info: "その解決策・ソリューションを、ターゲットする顧客に広めていくための手法の仮説。どのように必要とされる方々に届けていくのか記入してください。",
    displayHypothesis: true,
    orderHypothesis: true,
    useInfoMark: true,
  },
  {
    order: 6,
    name: "ビジネスモデル/収益構造",
    key: "revenueStructure",
    type: "multiline",
    info: "課題の解決策をどのように顧客に提供することで収益を上げるのかの仮説、また取り組めば取り組むほど儲かる構造の仮説をご記入ください。",
    displayHypothesis: true,
    orderHypothesis: true,
    useInfoMark: true,
  },
  {
    order: 7,
    name: "市場性",
    key: "marketability",
    type: "multiline",
    info: "市場の公開データ等からの推測ではなく、具体的な顧客の財布の大きさや、顧客数の積み上げでどれくらいの規模になりそうかをご記入ください。",
    displayHypothesis: true,
    orderHypothesis: true,
    useInfoMark: true,
  },
  {
    order: 8,
    name: "競争優位性",
    key: "advantage",
    type: "multiline",
    info: "顕在化している競合や潜在的な競合はどこでしょうか。競合が同じことに取り組むより自社が取り組んだほうが勝ち抜けるという見立てをご記入ください。",
    displayHypothesis: true,
    orderHypothesis: true,
    useInfoMark: true,
  },
  {
    order: 9,
    name: "その他",
    key: "others",
    type: "multiline",
    info: "その他、必要に応じて、事業の詳細や補足事項について記入してください。",
    displayHypothesis: true,
    orderHypothesis: true,
    useInfoMark: true,
  },
];

export const prettifyDiff = (diffs) => {
  const DIFF_DELETE = -1;
  const DIFF_INSERT = 1;
  const DIFF_EQUAL = 0;

  const html = [];
  const pattern_amp = /&/g;
  const pattern_lt = /</g;
  const pattern_gt = />/g;
  const pattern_para = /\n/g;
  for (let x = 0; x < diffs.length; x++) {
    const op = diffs[x][0]; // Operation (insert, delete, equal)
    const data = diffs[x][1]; // Text of change.
    const text = data
      .replace(pattern_amp, "&amp;")
      .replace(pattern_lt, "&lt;")
      .replace(pattern_gt, "&gt;")
      .replace(pattern_para, "<br>");
    switch (op) {
      case DIFF_INSERT:
        html[x] = '<span style="color:#00D382;">' + text + "</span>";
        break;
      case DIFF_DELETE:
        html[x] = '<span style="color:#EB3030;">' + text + "</span>";
        break;
      case DIFF_EQUAL:
        html[x] = "<span>" + text + "</span>";
        break;
    }
  }
  return html.join("");
};

export const prettifyDiffWithSign = (diffs) => {
  const DIFF_DELETE = -1;
  const DIFF_INSERT = 1;
  const DIFF_EQUAL = 0;

  const html = [];
  const pattern_amp = /&/g;
  const pattern_lt = /</g;
  const pattern_gt = />/g;
  const pattern_para = /\n/g;
  for (let x = 0; x < diffs.length; x++) {
    const op = diffs[x].sign; // Operation (insert, delete, equal)
    const data = diffs[x].val; // Text of change.
    const text = data
      .replace(pattern_amp, "&amp;")
      .replace(pattern_lt, "&lt;")
      .replace(pattern_gt, "&gt;")
      .replace(pattern_para, "<br>");
    switch (op) {
      case DIFF_INSERT:
        html[x] = '<span style="color:#00D382;">' + text + "</span>";
        break;
      case DIFF_DELETE:
        html[x] = '<span style="color:#EB3030;">' + text + "</span>";
        break;
      case DIFF_EQUAL:
        html[x] = "<span>" + text + "</span>";
        break;
    }
  }
  return html.join("");
};

export const getDiffConent = (prevPair, curPair, length) => {
  const diffPairs = [];
  for (var index = 0; index < length; index++) {
    // console.log("prev pairs ==>", index, prevPair[index]);
    // console.log("cur pairs ==>", index, curPair[index]);
    // console.log("compare pairs ==>", index, JSON.stringify(prevPair[index]) === JSON.stringify(curPair[index]));
    if (JSON.stringify(prevPair[index]) === JSON.stringify(curPair[index])) {
      diffPairs.push([]);
    } else {
      diffPairs.push(curPair[index]);
    }
  }
  return diffPairs;
};

export const getHypothesisChanged = (
  oldHypothesisValue = "",
  newHypothesisValue = "",
  MODE,
) => {
  const dmp = new window.diff_match_patch();
  const diffData = dmp.diff_main(
    oldHypothesisValue ? oldHypothesisValue : "",
    newHypothesisValue ? newHypothesisValue : "",
  );
  dmp.diff_cleanupEfficiency(diffData);
  // console.log("diffData", diffData)
  if (diffData.length > 0) {
    // console.log("normalizeDiff", MODE, normalizeDiff(diffData, MODE))
    return normalizeDiff(diffData, MODE);
  } else {
    return diffData;
  }
};

export const mixingGreenAndRed = (greenDiff, redDiff, length) => {
  const diffArr = [];
  for (var index = 0; index < length; index++) {
    diffArr.push(mixingGreenAndRedItem(greenDiff[index], redDiff[index]));
  }
  return diffArr;
};
export const mixingGreenAndRedItem = (greenDiff, redDiff) => {
  if (greenDiff.length === 0) {
    return redDiff;
  }

  if (redDiff === 0) {
    return greenDiff;
  }

  const length = Math.max(greenDiff.length, redDiff.length);
  const mixingArr = [];
  for (let index = 0; index < length; index++) {
    let greenItem = greenDiff[index];
    let redItem = redDiff[index];
    if (!greenItem) {
      mixingArr.push(redItem);
    } else if (!redItem) {
      mixingArr.push(greenItem);
    } else {
      const gSign = greenItem.sign;
      const rSign = redItem.sign;
      const gVal = greenItem.val;
      var resSign = gSign;
      if (gSign === 0) {
        resSign = rSign;
      } else if (rSign === 0) {
        resSign = gSign;
      } else {
        resSign = gSign * rSign;
      }
      mixingArr.push({
        sign: resSign,
        val: gVal,
      });
    }
  }

  return mixingArr;
};

export const normalizeDiff = (diffData, MODE) => {
  var normalDiffData = [];
  for (const index in diffData) {
    normalDiffData = [
      ...normalDiffData,
      ...normaizeDiffItem(diffData[index], MODE),
    ];
  }
  return normalDiffData;
};

export const normaizeDiffItem = (diffItem, MODE) => {
  const signStrings = [];
  const sign = diffItem[0];
  const strings = diffItem[1];
  for (const strIdx in strings) {
    if (sign === 0) {
      signStrings.push({
        sign,
        val: strings[strIdx],
      });
    } else {
      if (sign === MODE) {
        signStrings.push({
          sign,
          val: strings[strIdx],
        });
      }
    }
  }
  return signStrings;
};

export const getMentionsFromEditor = (editorState) => {
  const rawNodes = convertToRaw(editorState.getCurrentContent());

  const mentions = [];
  Object.values(rawNodes.entityMap).forEach((entity) => {
    if (entity.type === "MENTION") {
      mentions.push(entity.data.url);
    }
  });
  return mentions;
};

/**
 * @param {import('../../types/ProjectArchive').ProjectArchivesRecord} record
 * @returns
 */
export const getHypothesisObjectFromArray = (record) => {
  const hypothesisArray = record.hypothesisArray;
  /** @type{Record<string, string>} */
  const hypothesisObj = {};
  if (
    hypothesisArray &&
    Array.isArray(hypothesisArray) &&
    hypothesisArray.length > 0
  ) {
    for (const item of hypothesisArray) {
      hypothesisObj[item.hypothesisFormId] = item.hypothesisFormContent;
    }
  }
  return hypothesisObj;
};
