import { useContext, useEffect } from "react";

import { CompanySettingsCard } from "@/features/settings/components/CompanySettingsCard/component";
import { ExaminationSettingsCard } from "@/features/settings/components/ExaminationSettingsCard/component";
import { ImageSettingsCard } from "@/features/settings/components/ImageSettingsCard/component";
import { ProgramSettingsCard } from "@/features/settings/components/ProgramSettingsCard/component";
import { ProjectSettingsCard } from "@/features/settings/components/ProjectSettingsCard/component";
import { UserSettingsCard } from "@/features/settings/components/UserSettingsCard/component";
import { ContentContext } from "@/Provider";

const SettingsScreen = () => {
  const context = useContext(ContentContext);

  useEffect(() => {
    context.updatePageTitle("screen.label.setting");
  }, [context]);

  return (
    <>
      <CompanySettingsCard />
      <UserSettingsCard />
      <ProgramSettingsCard />
      <ProjectSettingsCard />
      <ExaminationSettingsCard />
      <ImageSettingsCard />
    </>
  );
};

export default SettingsScreen;
