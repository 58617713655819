import { Layout } from "antd";
import { PureComponent, Suspense } from "react";
import { connect } from "react-redux";

import { EditEventScreen } from "@/features/event/components/EditEventScreen";
import { EventDetailScreen } from "@/features/event/components/EventDetailScreen";
import { NewEventScreen } from "@/features/event/components/NewEventScreen";

import CustomLoader from "../../Components/atoms/CustomLoader";
import {
  ChangePasswordComponent,
  NavbarUser,
  PrivacyPolicyModal,
  SideMenu,
} from "../../Components/organisms";
import { ExaminationPhaseManagementScreen } from "../../features/examinationPhase/components/ExaminationPhaseManagementScreen";
import { ProjectSubmissionsScreen } from "../../features/review/components/ProjectSubmissionsScreen";
import { I18NContext } from "../../i18n/context";
import AuthActions from "../../Redux/AuthRedux";
import CompanyActions from "../../Redux/CompanyRedux";
import ProjectActions from "../../Redux/ProjectRedux";
import UserActions from "../../Redux/UserRedux";
import { endpoints, parseEndpoint } from "../../Services/endpoints";
import { getObjUUID } from "../../utils/common";
import { appName } from "../../utils/constants";
import { REFERENCE_TYPE } from "../../utils/enums";
import { ScrollToTop } from "../../utils/hooks";
import AnalyticsScreen from "../analytics/AnalyticsScreen";
import EventMembersListScreen from "../event/EventMembersListScreen";
import EventsScreen from "../event/EventsScreen";
import HomeScreen from "../home/HomeScreen";
import NotificationScreen from "../home/NotificationScreen";
import LibraryIndustryReportsScreen from "../library-manage/industry-report/company";
import LibraryIndustryReportDetailScreen from "../library-manage/industry-report/company/detail";
import LibraryIndustryReportUpdateScreen from "../library-manage/industry-report/company/update";
import LibraryNewsScreen from "../library-manage/news/company";
import LibraryNewsDetailScreen from "../library-manage/news/company/detail";
import LibraryNewsUpdateScreen from "../library-manage/news/company/update";
import MemberAddScreen from "../member/Member.add";
import MemberScreen from "../member/MemberScreen";
import MembersScreen from "../member/MembersScreen";
import CreateNoticeScreen from "../notices/CreateNoticeScreen";
import NotificationDetailScreen from "../notices/NotificationDetail";
import OperationalLogScreen from "../operational-log/OperationalLogScreen";
import PrivateAdminRoute from "../private-route/PrivateAdminRoute";
import PrivateRoute from "../private-route/PrivateRoute";
import EmailSettingsScreen from "../profile/EmailSettingsScreen";
import LanguageSettingsScreen from "../profile/LanguageSettingsScreen";
import ProfileScreen from "../profile/ProfileScreen";
import WatchSettingsScreen from "../profile/WatchSettingsScreen";
import ProgramInfoViewMgtScreen from "../program-info/ProgramInfoViewMgtScreen";
import ProgramInfoViewQAMgtScreen from "../program-info/ProgramInfoViewQAMgtScreen";
import ProgramInfoViewQAScreen from "../program-info/ProgramInfoViewQAScreen";
import ProgramInfoViewScreen from "../program-info/ProgramInfoViewScreen";
import ProjectArchiveScreenDetails from "../project/ProjectArchiveScreenDetails";
import ProjectCreateScreen from "../project/ProjectCreateScreen";
import ProjectEditScreen from "../project/ProjectEditScreen";
import ProjectsListScreen from "../project/ProjectsListScreen";
import ProjectTopScreen from "../project/ProjectTopScreen";
import CompanyReferenceDetailScreen from "../reference/company/CompanyReferenceDetailScreen";
import { CompanyReferenceList } from "../reference/company/CompanyReferenceList";
import BatchExaminationScoreManagementScreen from "../settings/BatchExaminationScoreManagementScreen";
import BatchHypothesisManagementScreen from "../settings/BatchHypothesisManagementScreen";
import MilestoneManagementScreen from "../settings/MilestoneManagementScreen";
import SettingsScreen from "../settings/SettingsScreen";
import UserActivityScreen from "../settings/UserActivityScreen";
import UserViewedPagesActivitiesScreen from "../settings/UserViewedPagesActivitiesScreen";
import SingleSubmissionPreviewScreen from "../submissions/SingleSubmissionPreviewScreen";
import UUIDAdminRoute from "../uuid-route/UUIDAdminRoute";
import UUIDRoute from "../uuid-route/UUIDRoute";
import DisplayAllVideosLargeCategoryScreen from "../videos/DisplayAllVideosLargeCategoryScreen";
import EditSmallCategoryScreen from "../videos/EditSmallCategoryScreen";
import EditVideoScreen from "../videos/EditVideoScreen";
import LargeCategoriesManagementScreen from "../videos/LargeCategoriesManagementScreen";
import MgtRecommendedVideosScreen from "../videos/MgtRecommendedVideosScreen";
import PostNewVideoScreen from "../videos/PostNewVideoScreen";
import SingleSmallCategoryVideosScreen from "../videos/SingleSmallCategoryVideosScreen";
import SmallCategoriesManagementScreen from "../videos/SmallCategoriesManagementScreen";
import VideoListScreen from "../videos/VideoListScreen";
import VideoTopScreen from "../videos/VideoTopScreen";
import WatchVideoScreen from "../videos/WatchVideoScreen";
import { DashboardState, IDashboardProps } from "./types/IDashboardProps";

const { Header, Content, Footer } = Layout;

class Dashboard extends PureComponent<IDashboardProps, DashboardState> {
  context: any;
  resetPasswordModalRef: any;
  static contextType = I18NContext;
  state = {
    companies: [],
    isSideBarOpen: false,
    sideBarOption: "fixed",
    userAgreedPrivacyPolicy: false,
    privacyPolicy: null,
    loadingPolicy: true,
  };
  componentDidMount() {
    const {
      context: { user },
    } = this.props;

    if (!user.company) {
      // @ts-expect-error TS2339
      this.props.history.push("/user-companies/");
      return;
    }

    this.props.dispatchSetAuthToken(user.token);
    // @ts-expect-error TS2339
    this.context.selectLanguage(user.language);
    this.setBaseUrl(getObjUUID(user.company));
    // @ts-expect-error TS2322
    this.props.dispatchGetMyProjects({ userId: user.id, page: 0 });
    this.props.dispatchGetCompanyDetail(); // current data required not one at login
    // @ts-expect-error TS2722
    this.props.dispatchFetchAllUsers(0);
    this.getUserCompanies();
    this.getAllUsersForMention();
    if (user.isSuper) {
      this.setState({
        userAgreedPrivacyPolicy: true,
        loadingPolicy: false,
      });
    } else {
      this.getPrivacyPolicy();
    }
    localStorage.setItem("currentRoute", "/dashboard");
  }

  getPrivacyPolicy = () => {
    const {
      context: { apiRequest },
    } = this.props;
    const requestBuilder = {
      method: "getRequest",
      url: parseEndpoint(endpoints.privacyPolicy),
    };
    apiRequest(
      // @ts-expect-error TS2345
      requestBuilder,
      ({ data }) => {
        // @ts-expect-error TS2339
        if (data && data.id) {
          this.setState({
            privacyPolicy: data,
          });
          // @ts-expect-error TS2339
          this.getUserPrivacyPolicyState(data.id);
        } else {
          this.setState({
            loadingPolicy: false,
          });
        }
      },
      () => {
        this.setState({
          loadingPolicy: false,
        });
      },
    );
  };

  getUserPrivacyPolicyState = (privacyPolicyId: string) => {
    const {
      context: { apiRequest },
    } = this.props;
    const userPrivacyStateRequestBuilder = {
      method: "getRequest",
      url: parseEndpoint(endpoints.privacyPolicyState, {
        privacyPolicyId,
      }),
    };
    apiRequest(
      // @ts-expect-error TS2345
      userPrivacyStateRequestBuilder,
      ({ data: userPrivacyStateData }) => {
        if (
          userPrivacyStateData &&
          // @ts-expect-error TS2339
          userPrivacyStateData.allowPrivacyPolicy &&
          // @ts-expect-error TS2339
          userPrivacyStateData.premisePrivacyPolicy
        ) {
          this.setState({
            // @ts-expect-error TS2339
            userAgreedPrivacyPolicy: !!userPrivacyStateData.agreed,
            loadingPolicy: false,
          });
        } else {
          this.setState({
            userAgreedPrivacyPolicy: true,
            loadingPolicy: false,
          });
        }
      },
      () => {
        this.setState({
          loadingPolicy: false,
        });
      },
    );
  };

  updateUserPrivacyPolicyState = () => {
    const {
      context: { apiRequest },
    } = this.props;
    const { privacyPolicy } = this.state;
    if (!privacyPolicy) {
      return;
    }
    const requestBuilder = {
      method: "putRequest",
      url: parseEndpoint(endpoints.privacyPolicyState, {
        // @ts-expect-error TS2339
        privacyPolicyId: privacyPolicy?.id,
      }),
      data: {
        agreementDate: new Date().toISOString(),
      },
    };
    // @ts-expect-error TS2345
    apiRequest(requestBuilder, ({ data }) => {
      // @ts-expect-error TS2339
      if (data && data.allowPrivacyPolicy && data.premisePrivacyPolicy) {
        this.setState({
          // @ts-expect-error TS2339
          userAgreedPrivacyPolicy: !!data.agreed,
        });
      } else {
        this.setState({
          userAgreedPrivacyPolicy: true,
        });
      }
    });
  };

  getAllUsersForMention = () => {
    const {
      context: { apiRequest },
    } = this.props;
    const requestBuilder = {
      method: "getRequest",
      url: parseEndpoint(endpoints.getAllUsersForMentions),
    };
    // @ts-expect-error TS2345
    apiRequest(requestBuilder, ({ data }) => {
      // @ts-expect-error TS2722
      this.props.userSetAllUsersForMention(data);
    });
  };
  getUserCompanies = () => {
    const {
      context: { user, apiRequest },
    } = this.props;
    const requestBuilder = {
      method: "getRequest",
      url: parseEndpoint(endpoints.getUserCompanies, {
        userId: getObjUUID(user),
      }),
    };
    // @ts-expect-error TS2345
    apiRequest(requestBuilder, ({ data }) => {
      this.setState({
        companies: data,
      });
    });
  };
  // @ts-expect-error TS7006
  setBaseUrl = (companyId) => {
    const baseUrl = `${process.env.REACT_APP_BASEHOST}companies/${companyId}/`;
    this.props.dispatchSetBaseUrl(baseUrl);
  };
  // @ts-expect-error TS7006
  handleChangePassword = (password) => {
    const {
      context: { user, apiRequest },
    } = this.props;
    const requestBuilder = {
      method: "postRequest",
      url: parseEndpoint(endpoints.changePassword),
      data: { id: user.id, password },
    };
    // @ts-expect-error TS2345
    apiRequest(requestBuilder, ({ data }) => {
      if (this.resetPasswordModalRef && this.resetPasswordModalRef.current) {
        this.resetPasswordModalRef.current.hideModal();
      }
    });
  };
  _renderSideMenu = () => {
    const {
      context: { user, company },
    } = this.props;
    return (
      <SideMenu
        // @ts-expect-error TS2322
        context={this.props.context}
        open={this.state.isSideBarOpen}
        onToggleDrawer={() => {
          this.setState({
            isSideBarOpen: !this.state.isSideBarOpen,
          });
        }}
        key={"insu-side-menu"}
        sideBarOption={this.state.sideBarOption}
        // @ts-expect-error TS2532
        project={this.props.projects.projectPayload}
        // @ts-expect-error TS2532
        myProjects={this.props.projects.myProjectsPayload.rows}
        isAdminUser={user.type === "admin"}
        isSuperUser={user.type === "admin" && user.isSuper}
        company={company}
        // @ts-expect-error TS2532
        active={this.props.location.pathname}
        companyIcon={company.icon}
      />
    );
  };
  // @ts-expect-error TS7006
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged({
        alphaFrontendSourceUrl: prevProps.location.pathname,
        // @ts-expect-error TS2532
        alphaFrontendDestinationUrl: this.props.location.pathname,
      });
    }
    if (window.innerWidth < 768 && this.state.sideBarOption !== "hide") {
      //Prevent excess setState call
      this.setState({
        sideBarOption: "hide",
      });
    } else if (
      window.innerWidth > 768 &&
      this.state.sideBarOption !== "fixed"
    ) {
      //Prevent excess setState call
      this.setState({
        sideBarOption: "fixed",
      });
    }
  }
  // @ts-expect-error TS7006
  onSwitchCompanies = (companyId) => {
    const {
      context: { apiRequest },
    } = this.props;
    const requestBuilder = {
      method: "putRequest",
      url: parseEndpoint(endpoints.prepareUserDataForSelectedCompany, {
        companyId,
      }),
    };
    // @ts-expect-error TS2345
    apiRequest(requestBuilder, ({ data }) => {
      // @ts-expect-error TS2722
      this.props.dispatchSetAuthPayload({
        // @ts-expect-error TS2698
        ...data,
        hasPasswordChanged: true,
      });
      const baseUrl = `${process.env.REACT_APP_BASEHOST}companies/${companyId}/`;
      this.props.dispatchSetBaseUrl(baseUrl);
      // @ts-expect-error TS18046
      this.props.dispatchSetAuthToken(data.token);
      setTimeout(() => {
        this.prepareDataForCompany(data);
      }, 1000);
    });
  };
  // @ts-expect-error TS7006
  prepareDataForCompany = (payload) => {
    const {
      context: { apiRequest },
    } = this.props;
    const requestBuilder = {
      method: "putRequest",
      url: parseEndpoint(endpoints.prepareCompanyData),
      data: {},
    };
    // @ts-expect-error TS2345
    apiRequest(requestBuilder, ({ data }) => {
      // @ts-expect-error TS2722
      this.props.dispatchSetAuthPayload({
        ...payload,
        hasPasswordChanged: true,
      });
      setTimeout(() => {
        // @ts-expect-error TS2322
        window.location = "/dashboard";
      }, 1000);
      localStorage.removeItem("projectListScreen");
      localStorage.removeItem("membersScreen");
    });
  };
  // @ts-expect-error TS7006
  onRouteChanged = (data) => {
    const {
      context: { apiRequest },
    } = this.props;
    const requestBuilder = {
      method: "postRequest",
      url: parseEndpoint(endpoints.createScreenActivity),
      data,
    };
    // @ts-expect-error TS2345
    apiRequest(requestBuilder, ({ data }) => {});
  };
  render() {
    const {
      context: { user, apiRequest, refreshUser },
    } = this.props;
    const { userAgreedPrivacyPolicy, privacyPolicy, loadingPolicy } =
      this.state;
    if (loadingPolicy) {
      return <Suspense fallback={<CustomLoader />} />;
    }
    const isAllowPrivacyPolicy = user.company.isAllowPrivacyPolicy;
    if (isAllowPrivacyPolicy && !userAgreedPrivacyPolicy && privacyPolicy) {
      return (
        <PrivacyPolicyModal
          show={!userAgreedPrivacyPolicy}
          // @ts-expect-error TS2339
          policyUrl={privacyPolicy?.policyUrl ?? null}
          onAgreePrivacyPolicy={this.updateUserPrivacyPolicyState}
          onCancel={this.logUserOut}
        />
      );
    }
    return (
      <Layout style={{ backgroundColor: "#f5f5f5" }}>
        {this._renderSideMenu()}
        <Layout>
          <Header>
            <NavbarUser
              onToggleDrawer={() => {
                this.setState({
                  isSideBarOpen: !this.state.isSideBarOpen,
                });
              }}
              sideBarOption={this.state.sideBarOption}
              onSwitchCompanies={this.onSwitchCompanies}
              userCompanies={this.state.companies}
              pageTitle={this.props.pageTitle}
              userDetails={user}
              logoutUser={this.logUserOut}
            />
          </Header>
          <Suspense fallback={<CustomLoader />}>
            <ChangePasswordComponent
              refreshData={() => {
                refreshUser({ id: user.id });
              }}
              dispatchApiRequest={apiRequest}
              user={user}
            />
            <Content style={{ padding: "3% 3% 0 3%" }}>
              <PrivateRoute path={"/dashboard"} exact component={HomeScreen} />
              <PrivateRoute
                exact
                path="/dashboard/profile"
                component={ProfileScreen}
              />
              <PrivateRoute
                exact
                path="/dashboard/videos"
                component={VideoTopScreen}
              />
              <PrivateRoute
                exact
                path="/dashboard/videos-list"
                component={VideoListScreen}
              />
              <PrivateAdminRoute
                exact
                path="/dashboard/videos-mgt/recommendation"
                component={MgtRecommendedVideosScreen}
              />
              <PrivateAdminRoute
                exact
                path="/dashboard/videos-new"
                component={PostNewVideoScreen}
              />
              <PrivateRoute
                exact
                path="/dashboard/videos-play/:id"
                component={WatchVideoScreen}
              />
              <PrivateRoute
                exact
                path="/dashboard/videos-large-category/:id/:name"
                component={DisplayAllVideosLargeCategoryScreen}
              />
              <PrivateAdminRoute
                exact
                path="/dashboard/videos-edit/:id"
                component={EditVideoScreen}
              />
              <ScrollToTop>
                <PrivateAdminRoute
                  exact
                  path="/dashboard/batch/:id/hypothesis-mgt"
                  component={BatchHypothesisManagementScreen}
                />
              </ScrollToTop>

              <ScrollToTop>
                <PrivateAdminRoute
                  exact
                  path="/dashboard/batch/:id/examination-mgt"
                  component={BatchExaminationScoreManagementScreen}
                />
              </ScrollToTop>

              <ScrollToTop>
                <PrivateAdminRoute
                  exact
                  path="/dashboard/batch/:id/milestone-mgt"
                  component={MilestoneManagementScreen}
                />
              </ScrollToTop>
              <PrivateAdminRoute
                exact
                path="/dashboard/batch/:id/examination-phases"
                component={ExaminationPhaseManagementScreen}
              />
              <PrivateAdminRoute
                exact
                path="/dashboard/operational-log"
                component={OperationalLogScreen}
              />
              <PrivateAdminRoute
                exact
                path="/dashboard/small-category-edit/:id"
                component={EditSmallCategoryScreen}
              />
              <PrivateAdminRoute
                exact
                path="/dashboard/videos-mgt"
                component={LargeCategoriesManagementScreen}
              />
              <PrivateRoute
                path="/dashboard/videos-small-category/:id/:name"
                component={SingleSmallCategoryVideosScreen}
              />
              <PrivateAdminRoute
                exact
                path="/dashboard/video-mgt/videos-large-category/:id/:name"
                component={SmallCategoriesManagementScreen}
              />
              <PrivateRoute
                exact
                path="/dashboard/events"
                component={EventsScreen}
              />
              <PrivateAdminRoute
                exact
                path="/dashboard/events/new"
                component={NewEventScreen}
              />
              <PrivateAdminRoute
                exact
                path="/dashboard/events/:id/edit"
                component={EditEventScreen}
              />
              <PrivateRoute
                exact
                path="/dashboard/events.detail/:id"
                subRouter="events"
                component={EventDetailScreen}
              />
              <PrivateAdminRoute
                exact
                path="/dashboard/project_submissions/:batchId"
                component={ProjectSubmissionsScreen}
              />
              <PrivateAdminRoute
                exact
                path="/dashboard/examinations/:uuid"
                component={SingleSubmissionPreviewScreen}
              />

              <UUIDAdminRoute
                exact
                subRouter="events"
                path="/dashboard/events.members/:id"
                component={EventMembersListScreen}
              />
              <PrivateAdminRoute
                exact
                path="/dashboard/analytics"
                component={AnalyticsScreen}
              />
              <PrivateAdminRoute
                exact
                path="/dashboard/login-activities"
                component={UserActivityScreen}
              />
              <PrivateAdminRoute
                exact
                path="/dashboard/screen-activities"
                component={UserViewedPagesActivitiesScreen}
              />
              <PrivateRoute
                exact
                path="/dashboard/members"
                component={MembersScreen}
              />
              <UUIDRoute
                exact
                path="/dashboard/member/:id"
                subRouter="users"
                component={MemberScreen}
              />
              <PrivateAdminRoute
                exact
                path="/dashboard/member.add"
                component={MemberAddScreen}
              />
              <PrivateRoute
                exact
                path="/dashboard/projects.create/:id"
                component={ProjectCreateScreen}
              />
              <UUIDRoute
                exact
                path="/dashboard/project.reviews/:id"
                subRouter="projects"
                component={ProjectTopScreen}
              />
              <UUIDRoute
                exact
                path="/dashboard/project.top/:id"
                subRouter="projects"
                component={ProjectTopScreen}
              />
              <UUIDRoute
                exact
                path="/dashboard/project.file/:id"
                subRouter="projects"
                component={ProjectTopScreen}
              />
              <UUIDRoute
                exact
                path="/dashboard/project.team/:id"
                subRouter="projects"
                component={ProjectTopScreen}
              />
              <UUIDRoute
                exact
                path="/dashboard/project.tasks/:id"
                subRouter="projects"
                component={ProjectTopScreen}
              />
              <PrivateRoute
                exact
                path="/dashboard/projects.list"
                component={ProjectsListScreen}
              />
              <PrivateRoute
                exact
                path="/dashboard/projects.batchlist/:id"
                component={ProjectsListScreen}
              />
              <UUIDRoute
                exact
                path="/dashboard/projects.edit/:id"
                subRouter="projects"
                component={ProjectEditScreen}
              />
              <UUIDRoute
                exact
                path="/dashboard/project.archive/:id"
                subRouter="projects"
                component={ProjectTopScreen}
              />
              <UUIDRoute
                exact
                path="/dashboard/project.archive/:id/:archiveId"
                subRouter="projects"
                component={ProjectArchiveScreenDetails}
              />
              <PrivateAdminRoute
                exact
                path="/dashboard/notices/create"
                component={CreateNoticeScreen}
              />
              <UUIDRoute
                exact
                path="/dashboard/project.notices/:id"
                subRouter="projects"
                component={ProjectTopScreen}
              />
              <PrivateRoute
                exact
                path="/dashboard/notifications/:notificationType"
                component={NotificationScreen}
              />
              <PrivateAdminRoute
                exact
                path="/dashboard/settings/"
                component={SettingsScreen}
              />
              <PrivateRoute
                exact
                path="/dashboard/email-subscription/"
                component={EmailSettingsScreen}
              />
              <PrivateRoute
                exact
                path="/dashboard/watch-subscription/"
                component={WatchSettingsScreen}
              />
              <PrivateRoute
                exact
                path="/dashboard/lang-setting/"
                component={LanguageSettingsScreen}
              />
              <PrivateRoute
                exact
                path="/dashboard/notification-detail/:id"
                component={NotificationDetailScreen}
              />
              <PrivateRoute
                exact
                path="/dashboard/program-info-view"
                component={ProgramInfoViewScreen}
              />
              <PrivateAdminRoute
                exact
                path="/dashboard/program-info-view-qa-mgt"
                component={ProgramInfoViewQAMgtScreen}
              />
              <PrivateRoute
                exact
                path="/dashboard/program-info-view-qa"
                component={ProgramInfoViewQAScreen}
              />
              <PrivateAdminRoute
                exact
                path="/dashboard/program-info-view-mgt"
                component={ProgramInfoViewMgtScreen}
              />
              <PrivateRoute
                exact
                path="/dashboard/library/news"
                component={LibraryNewsScreen}
              />
              <PrivateRoute
                exact
                path="/dashboard/library/news-update/:id"
                component={LibraryNewsUpdateScreen}
              />
              <PrivateRoute
                exact
                path="/dashboard/library/news/:id"
                component={LibraryNewsDetailScreen}
              />
              <PrivateRoute
                exact
                path="/dashboard/library/industry-report"
                component={LibraryIndustryReportsScreen}
              />
              <PrivateRoute
                exact
                path="/dashboard/reference-preset"
                component={() => (
                  <CompanyReferenceList referenceType={REFERENCE_TYPE.PRESET} />
                )}
              />
              <PrivateRoute
                exact
                path="/dashboard/reference-preset/:id"
                component={() => (
                  <CompanyReferenceDetailScreen
                    referenceType={REFERENCE_TYPE.PRESET}
                  />
                )}
              />
              <PrivateRoute
                exact
                path="/dashboard/reference-original"
                component={() => (
                  <CompanyReferenceList
                    referenceType={REFERENCE_TYPE.ORIGINAL}
                  />
                )}
              />
              <PrivateRoute
                exact
                path="/dashboard/reference-original/:id"
                component={() => (
                  <CompanyReferenceDetailScreen
                    referenceType={REFERENCE_TYPE.ORIGINAL}
                  />
                )}
              />
              <PrivateRoute
                exact
                path="/dashboard/library/industry-report/:id"
                component={LibraryIndustryReportDetailScreen}
              />
              <PrivateRoute
                exact
                path="/dashboard/library/industry-report-update/:id"
                component={LibraryIndustryReportUpdateScreen}
              />
              {/* エラーページテスト用 */}
              {(process.env.REACT_APP_ENV === "local" ||
                process.env.REACT_APP_ENV === "development") && (
                <PrivateRoute
                  path={"/dashboard/error-check"}
                  exact
                  component={() => {
                    throw new Error();
                  }}
                />
              )}
            </Content>
          </Suspense>
          <Footer
            style={{ textAlign: "center" }}
          >{`Copyright© ${appName} Produced by Alphadrive Co.,Ltd. All rights reserved.`}</Footer>
        </Layout>
      </Layout>
    );
  }
  logUserOut = () => {
    this.props.dispatchLogoutUser();
    this.props.dispatchSetBaseUrl(process.env.REACT_APP_BASEHOST);
  };
}
// @ts-expect-error TS7006
const mapStateToProps = (state) => ({
  pageTitle: state.dashboard.pageTitle,
  projects: state.projects,
});
// @ts-expect-error TS7006
const mapDispatchToProps = (dispatch) => ({
  dispatchGetCompanyDetail: () => dispatch(CompanyActions.companyGetRequest()),
  dispatchLogoutUser: () => dispatch(AuthActions.logout()),
  // @ts-expect-error TS7006
  dispatchSetAuthToken: (token) =>
    dispatch(AuthActions.loginSetAuthToken(token)),
  // @ts-expect-error TS7006
  dispatchSetBaseUrl: (url) => dispatch(AuthActions.loginSetBaseUrl(url)),
  dispatchGetMyProjects: (data: { userId: string; page: number }) =>
    // @ts-expect-error TS2554
    dispatch(ProjectActions.projectCurrentMemberGetsRequest(data)),
  // @ts-expect-error TS7006
  dispatchFetchAllUsers: (page) => dispatch(UserActions.userGetsRequest(page)),
  // @ts-expect-error TS7006
  dispatchSetAuthPayload: (data) => dispatch(AuthActions.loginSuccess(data)),
  // @ts-expect-error TS7006
  userSetAllUsersForMention: (data) =>
    // @ts-expect-error TS2554
    dispatch(UserActions.userSetAllUsersForMention(data)),
  // @ts-expect-error TS7006
  dispatchSetSuccessMyProjects: (data) =>
    // @ts-expect-error TS2554
    dispatch(ProjectActions.projectCurrentMemberGetsSuccess(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
