import { ChangeHandler } from "react-hook-form";

import { filterAndConvertToNumber } from "@/utils/filterAndConvertToNumber";
import { formatTime } from "@/utils/formatTime";
import { removeColon } from "@/utils/removeColon";

export const useTimeInput = ({
  onChange,
  onBlur,
  ref,
  name,
}: {
  onChange: ChangeHandler;
  onBlur: ChangeHandler;
  ref: React.Ref<HTMLInputElement>;
  name: string;
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // 5文字以上入力されたら、5文字目以降を削除
    event.target.value = filterAndConvertToNumber(event.target.value).slice(
      0,
      4,
    );
    onChange(event);
  };

  const handleBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.target.value = formatTime(event.target.value);
    onChange(event);
    onBlur(event);
  };

  const handleFocus = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.target.value = removeColon(event.target.value);
    onChange(event);
  };

  return {
    onFocus: handleFocus,
    onBlur: handleBlur,
    onChange: handleChange,
    ref,
    name,
  };
};
