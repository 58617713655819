import { PlusOutlined } from "@ant-design/icons";
import { Input } from "antd";
import { FC, useCallback, useState } from "react";

import { WhiteDashTag } from "@/Screens/settings/Components/WhiteDashTag";

const INPUT_MAX_WIDTH = 160;

export type InputTag = {
  onCreated: (tag: string) => void;
  createLabel: string;
};

export const InputTag: FC<InputTag> = ({ onCreated, createLabel }) => {
  const [inputValue, setInputValue] = useState("");
  const [showInput, setShowInput] = useState(false);

  const handleCreate = useCallback(() => {
    onCreated(inputValue);
    setInputValue("");
    setShowInput(false);
  }, [inputValue, onCreated]);

  return showInput ? (
    <Input
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus
      type="text"
      size="small"
      style={{ width: INPUT_MAX_WIDTH }}
      value={inputValue}
      onChange={(e) => setInputValue(e.target.value)}
      onBlur={handleCreate}
      onPressEnter={handleCreate}
    />
  ) : (
    <WhiteDashTag onClick={() => setShowInput(true)}>
      <PlusOutlined /> {createLabel}
    </WhiteDashTag>
  );
};
