import { notification } from "antd";
import { useEffect, useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import ApiActions, { ApiRequestData } from "../../../Redux/ApiRedux";
import { endpoints, parseEndpoint } from "../../../Services/endpoints";
import {
  IPasswordResetScreenProps,
  PasswordResetScreenState,
} from "../types/IPasswordResetScreenProps";

const usePasswordResetScreen = (props: IPasswordResetScreenProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();

  const [state, customSetState] = useState<PasswordResetScreenState>(() => {
    return {
      password: "",
    };
  });
  const onChange = (e: { target: { id: any; value: any } }) => {
    setState({ [e.target.id]: e.target.value });
  };

  const passwordValidation = (password: string) => {
    if (password.replace(/(\r\n|\n|\r|\s)/g, "").length < 8) {
      return false;
    }
    if (!password.match(/\d/)) {
      return false;
    } else if (!password.match(/[a-z]/i)) {
      return false;
    }
    return true;
  };

  const onSubmit = () => {
    const { password } = state;
    if (password) {
      if (password.replace(/(\r\n|\n|\r|\s)/g, "").length < 8) {
        notification.error({
          message: intl.formatMessage({
            id: "screen.label.password_too_short",
          }),
        });
        return true;
      }
      if (!password.match(/\d/)) {
        notification.error({
          message: intl.formatMessage({
            id: "screen.label.password_please_include_number",
          }),
        });
        return true;
      } else if (!password.match(/[a-z]/i)) {
        notification.error({
          message: intl.formatMessage({
            id: "screen.label.password_please_include_en",
          }),
        });
        return true;
      }
    }
    const userData = {
      // @ts-expect-error TS18048
      token: props.match.params.id,
      password: state.password,
    };
    const message = intl.formatMessage({
      id: "screen.label.changing_password_has_been_completed",
    });
    props.dispatchResetPasswordRequest(userData, message);
  };
  const setStateCallbackRef = useRef(() => {});
  useEffect(() => {
    const callBack = setStateCallbackRef.current;
    callBack();
    setStateCallbackRef.current = () => {};
  }, [state]);

  useEffect(() => {
    // @ts-expect-error TS18048
    const token = props.match.params.id;
    if (token) {
      const checkTokenRequest: ApiRequestData = {
        method: "getRequest",
        url: parseEndpoint(endpoints.resetTokenCheck, { token }),
      };
      dispatch(
        ApiActions.apiRequest(checkTokenRequest, ({ data }) => {
          // @ts-expect-error 要API型定義
          if (!data.tokenExist) {
            history.push("/");
            notification.error({
              message: intl.formatMessage({
                id: "screen.label.token_is_not_valid",
              }),
            });
          }
        }),
      );
    }
    // @ts-expect-error TS18048
  }, [props.match.params]);

  const setState = (data: PasswordResetScreenState, callback = () => {}) => {
    setStateCallbackRef.current = callback;
    customSetState((previousState) => {
      return {
        ...previousState,
        ...data,
      };
    });
  };

  const isValidPassword = useMemo(() => {
    if (state.password) {
      return passwordValidation(state.password);
    }
    return false;
  }, [state.password]);

  return {
    intl,
    onChange,
    onSubmit,
    state,
    setState,
    isValidPassword,
  };
};

export default usePasswordResetScreen;
