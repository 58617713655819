import { ChangeEvent, DragEvent, useCallback, useState } from "react";

export const useFileHandler = (mimeTypes?: string[]) => {
  const [file, setFile] = useState<File | null>(null);
  const [attachable, setAttachable] = useState<boolean>(true);

  const handleAttach = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      if (!attachable) return;
      if (e.target.files === null) return;
      const attachedFile = e.target.files[0];
      if (mimeTypes && !mimeTypes.includes(attachedFile.type)) return;
      setFile(attachedFile);
      e.target.value = "";
    },
    [mimeTypes, attachable],
  );

  const handleDrop = useCallback(
    (e: DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      if (!attachable) return;
      if (e.dataTransfer.files === null) return;
      const attachedFile = e.dataTransfer.files[0];
      if (mimeTypes && !mimeTypes.includes(attachedFile.type)) return;
      setFile(attachedFile);
    },
    [mimeTypes, attachable],
  );

  const initFile = useCallback(() => {
    setFile(null);
  }, []);

  return {
    file,
    initFile,
    handleAttach,
    handleDrop,
    setAttachable,
  };
};
