import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { Dispatch } from "@reduxjs/toolkit";
import { Col, Form, Modal, notification, Row, Select, Tabs } from "antd";
import { SelectValue } from "antd/lib/select";
import { EditorState } from "draft-js";
import moment from "moment";
import PropTypes from "prop-types";
import { FC, ReactElement, useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import ReactPlayer from "react-player/lazy";
import { connect } from "react-redux";

import {
  EventDetail,
  EventMember,
  Notification,
} from "../../../types/Notification";
import {
  Badge,
  ButtonCV,
  ButtonOption,
  ButtonUndo,
  Checkbox,
  Line,
} from "../../Components/atoms";
import { CustomPagination } from "../../Components/molecules";
import {
  DraftEditor as Editor,
  Input,
  nodeConfig,
  Select as Dropdown,
  uploadImageWithDraftEditor,
} from "../../Components/molecules";
import { NotificationComponent } from "../../Components/organisms";
import HomeActions from "../../Redux/HomeRedux";
import { endpoints, parseEndpoint } from "../../Services/endpoints";
import {
  convertFromRawWithTrimmer,
  convertToRawWithTrimmer,
  convertYVLinksToEmbed,
  getObjUUID,
  isJsonString,
  sanitizeEditorStateMarkupHtml,
} from "../../utils/common";
import { dateTimeFormat } from "../../utils/constants";
import * as constants from "../../utils/constants";
import { useStateWithRef } from "../../utils/hooks";
import { getInitStorageValue } from "../../utils/localStorage";
import { usePrevious } from "../../utils/usePrevious";
import {
  IImportantNoticeScreenProps,
  ImportantNoticeScreenState,
  NoticeDetail,
  NotificationsResponse,
} from "./types/IImportantNoticeScreenProps";

const PageSaveId = "ImportantNoticeScreen";
const activePageKey = `pagination_page_${PageSaveId}`;
const activeLimitKey = `pagination_limit_${PageSaveId}`;
const PageDraftSaveId = "ImportantNoticeScreen_Draft";
const draftPageKey = `pagination_page_${PageDraftSaveId}`;
const draftLimitKey = `pagination_limit_${PageDraftSaveId}`;
const { Option } = Select;
const { TabPane } = Tabs;

const SAVE_AS_DRAFT_DELAY = 1000;

const ImportantNoticeScreen: FC<IImportantNoticeScreenProps> = function (
  props,
) {
  const intl = useIntl();
  const { user, apiRequest, company, updatePageTitle } = props.context;

  const eventDetailInitialValue: Partial<EventDetail> = {
    name: "",
    description: "",
    id: "",
    nodalData: false,
    // @ts-expect-error TS2322
    beginsAt: new Date(),
    isMemberStatus: null,
  };
  const [eventDetail, setEventDetail] = useState<Partial<EventDetail>>(
    eventDetailInitialValue,
  );

  const noticeDetailInitialValue: NoticeDetail = {
    noticeType: "",
    title: "",
    description: "",
    id: "",
    nodalData: false,
    meta: {
      type: "",
    },
  };
  const [noticeDetail, setNoticeDetail] = useState<NoticeDetail>(
    noticeDetailInitialValue,
  );

  const [notifications, setNotifications] = useState<NotificationsResponse>({
    rows: [],
    count: 0,
    unReadNotification: [],
  });

  const [notificationsPagination, setNotificationsPagination] = useState<{
    metaType: string;
    page: number;
    limit: number;
  }>({
    metaType: "",
    page: getInitStorageValue(activePageKey, 1),
    limit: getInitStorageValue(
      activeLimitKey,
      parseInt(process.env.REACT_APP_PAGE_SIZE || "10"),
    ),
  });

  const [draftNotifications, setDraftNotifications] =
    useState<NotificationsResponse>({
      rows: [],
      count: 0,
      unReadNotification: [],
    });

  const [draftNotificationsPagination, setDraftNotificationsPagination] =
    useState<{
      page: number;
      limit: number;
    }>({
      page: getInitStorageValue(draftPageKey, 1),
      limit: getInitStorageValue(
        draftLimitKey,
        parseInt(process.env.REACT_APP_PAGE_SIZE || "10"),
      ),
    });

  const [isLoadingSaveDraft, setIsLoadingSaveDraft] = useState<boolean>(false);
  const [autoSaveNotificationId, setAutoSaveNotificationId] =
    useState<number>(0);

  const [visibleModal, setVisibleModal] = useState<
    null | "edit" | "generic" | "event" | "wanted"
  >(null);

  const [editorState, setEditorState] = useState<EditorState>(
    EditorState.createEmpty(),
  );

  const [sendEmail, setSendEmail] = useState<boolean>(false);

  // stateRef here is referred in "edit" modal
  const [state, customSetState, stateRef] = useStateWithRef(() => {
    return {
      title: "",
      description: "",
      type: "general_notice",
      modalTitle: "",
      modalDesc: "",
      modalLink: "",
      userTags: [],
      userTag: "",
      tagsUpdated: false,
    };
  });

  const setStateCallbackRef = useRef<(() => void) | null>(null);
  useEffect(() => {
    const callBack = setStateCallbackRef.current;
    if (callBack) {
      callBack();
    }
    setStateCallbackRef.current = null;
  }, [state]);

  // intended for updating fields in "edit" modal
  const setState = (
    data: ImportantNoticeScreenState,
    callback: (() => void) | null = null,
  ) => {
    setStateCallbackRef.current = callback;
    customSetState((previousState: Partial<ImportantNoticeScreenState>) => {
      return {
        ...previousState,
        ...data,
      };
    });
  };

  useEffect(() => {
    updatePageTitle?.("screen.label.notification");
    getNotificationsRequestion(notificationsPagination);
    getDraftNotificationsRequestion(draftNotificationsPagination);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const prevProps = usePrevious(props);
  useEffect(() => {
    const { tagsUpdated } = state;
    if (
      prevProps?.context.company?.userTags !== company.userTags ||
      !tagsUpdated
    ) {
      setState({
        tagsUpdated: true,
        userTags: Array.isArray(company.userTags) ? company.userTags : [],
      });
    }
  });

  const getNotificationsRequestion = (
    pagination: { page: number; limit: number; metaType: string } = {
      page: 1,
      limit: 10,
      metaType: "",
    },
  ) => {
    const notificationRequest = {
      method: "getRequest",
      url: `notifications?page=${pagination.page - 1}&limit=${
        pagination.limit
      }&notificationType=admin&draftState=1&${pagination.metaType}`,
      data: {},
    };
    apiRequest?.(
      // @ts-expect-error TS2345
      notificationRequest,
      ({ data }: { data: NotificationsResponse }) => {
        setNotifications({ ...data });
        setNotificationsPagination({ ...pagination });
      },
    );
  };
  const getDraftNotificationsRequestion = (
    pagination: { page: number; limit: number } = { page: 1, limit: 10 },
  ) => {
    const notificationRequest = {
      method: "getRequest",
      url: `notifications?page=${pagination.page - 1}&limit=${
        pagination.limit
      }&notificationType=admin&draftState=0`,
      data: {},
    };
    apiRequest?.(
      // @ts-expect-error TS2345
      notificationRequest,
      ({ data }: { data: NotificationsResponse }) => {
        setDraftNotifications({ ...data });
        setDraftNotificationsPagination({ ...pagination });
      },
    );
  };

  const saveAsDraftTimeoutRef = useRef<number>(0);

  const onEditorStateChange = (editorState: EditorState) => {
    setEditorState(editorState);
  };

  const handleChange = (
    key: string,
    value?: boolean | string | SelectValue,
  ) => {
    setState({ [key]: value });
  };

  const initModal = (refresh = false) => {
    setNoticeDetail(noticeDetailInitialValue);
    setEventDetail(eventDetailInitialValue);
    setIsLoadingSaveDraft(false);
    setVisibleModal(null);
    setEditorState(EditorState.createEmpty());
    setState(
      {
        userTag: "",
        title: "",
        description: "",
        modalTitle: "",
        modalDesc: "",
        modalLink: "",
        videoUrl: "",
      },
      () => {
        if (refresh) {
          getNotificationsRequestion(notificationsPagination);
          getDraftNotificationsRequestion(draftNotificationsPagination);
        }
      },
    );
  };
  const onCancelEditModal = () => {
    initModal(true);
  };
  const saveAsDraft = () => {
    if (stateRef.current.title.trim().length <= 0) {
      notification.warning({
        message: intl.formatMessage({
          id: "screen.label.enter_title",
        }),
      });
      return;
    }
    if (stateRef.current.type === "poll") {
      const notificationRequest = {
        method: "postRequest",
        url: parseEndpoint(endpoints.notificationsDraft),
        data: {
          notificationType: "admin",
          userTag: stateRef.current.userTag,
          senderId: user.id,
          actionData: {},
          actionText: `${stateRef.current.title}`,
          meta: {
            type: stateRef.current.type,
            videoUrl: stateRef.current.videoUrl,
          },
          message: stateRef.current.description,
          sendEmail,
          draftState: 0,
          nodalData: false,
          autoSaveNotificationId,
        },
      };
      if (isLoadingSaveDraft) {
        return;
      }
      setIsLoadingSaveDraft(true);
      apiRequest?.(
        // @ts-expect-error TS2345
        notificationRequest,
        ({ data }: { data: { id: number } }) => {
          const { id } = data;
          setIsLoadingSaveDraft(false);
          setAutoSaveNotificationId(id);
          getNotificationsRequestion(notificationsPagination);
          getDraftNotificationsRequestion(draftNotificationsPagination);
          notification.success({
            message: intl.formatMessage({
              id: "screen.label.draft_saved",
            }),
          });
          onCancelEditModal();
        },
      );
    } else {
      const content = convertToRawWithTrimmer(editorState);
      const notificationRequest = {
        method: "postRequest",
        url: parseEndpoint(endpoints.notificationsDraft),
        data: {
          notificationType: "admin",
          userTag: stateRef.current.userTag,
          nodalData: true,
          senderId: user.id,
          actionData: {},
          actionText: `${stateRef.current.title}`,
          meta: {
            type: stateRef.current.type,
            videoUrl: stateRef.current.videoUrl,
          },
          message: content,
          sendEmail: sendEmail,
          draftState: 0,
          autoSaveNotificationId,
        },
      };
      if (isLoadingSaveDraft) {
        return;
      }
      setIsLoadingSaveDraft(true);
      apiRequest?.(
        // @ts-expect-error TS2345
        notificationRequest,
        ({ data }: { data: { id: number } }) => {
          const { id } = data;
          setIsLoadingSaveDraft(false);
          setAutoSaveNotificationId(id);
          getNotificationsRequestion(notificationsPagination);
          getDraftNotificationsRequestion(draftNotificationsPagination);
          notification.success({
            message: intl.formatMessage({
              id: "screen.label.draft_saved",
            }),
          });
          onCancelEditModal();
        },
      );
    }
  };
  const submitNotice = () => {
    if (stateRef.current.type === "poll") {
      if (
        stateRef.current.title.trim().length > 0 &&
        stateRef.current.description.trim().length > 0
      ) {
        const notificationRequest = {
          method: "postRequest",
          url: parseEndpoint(endpoints.createNotifications),
          successMessage: intl.formatMessage({
            id: "screen.label.completed_successfully",
          }),
          data: {
            nodalData: false,
            notificationType: "admin",
            userTag: stateRef.current.userTag,
            senderId: user.id,
            actionData: {},
            actionText: `${stateRef.current.title}`,
            meta: {
              type: stateRef.current.type,
              videoUrl: stateRef.current.videoUrl,
              sendEmailCount: sendEmail ? 1 : 0,
            },
            message: stateRef.current.description,
            sendEmail,
            draftState: 1,
            autoSaveNotificationId,
          },
        };
        // @ts-expect-error TS2345
        apiRequest?.(notificationRequest, () => {
          onCancelEditModal();
        });
      } else {
        notification.error({
          message: intl.formatMessage({
            id: "screen.label.title_please_enter_url",
          }),
        });
      }
    } else if (stateRef.current.type !== "poll") {
      if (stateRef.current.title.trim().length <= 0) {
        notification.warning({
          message: intl.formatMessage({
            id: "screen.label.enter_title",
          }),
        });
        return;
      }
      const content = convertToRawWithTrimmer(editorState);
      const notificationRequest = {
        method: "postRequest",
        url: parseEndpoint(endpoints.createNotifications),
        successMessage: intl.formatMessage({
          id: "screen.label.completed_successfully",
        }),
        data: {
          notificationType: "admin",
          userTag: stateRef.current.userTag,
          nodalData: true,
          senderId: user.id,
          actionData: {},
          actionText: `${stateRef.current.title}`,
          meta: {
            type: stateRef.current.type,
            videoUrl: stateRef.current.videoUrl,
            sendEmailCount: sendEmail ? 1 : 0,
          },
          message: content,
          sendEmail,
          draftState: 1,
          autoSaveNotificationId,
        },
      };
      // @ts-expect-error TS2345
      apiRequest?.(notificationRequest, () => {
        onCancelEditModal();
      });
    }
  };
  const uploadImageCallBack = (file: File) => {
    return new Promise((resolve, reject) => {
      uploadImageWithDraftEditor({
        file,
        companyId: getObjUUID(company),
        authToken: user.token,
        subLink: "notification",
        headerDetail: {
          type: "WYSIWYG file",
          notificationId: null,
        },
      })
        .then((response: unknown) => {
          resolve(response);
        })

        .catch((error) => {
          reject(error);
        });
    });
  };
  const _renderOtherNotifications = (notifications: NotificationsResponse) => {
    if (Array.isArray(notifications.rows)) {
      return notifications.rows.map((notification) => {
        return (
          <NotificationComponent
            key={notification.id}
            enableEditing={true}
            currentUserRole={user.type}
            authToken={user.token}
            refresh={() => {
              getNotificationsRequestion(notificationsPagination);
              getDraftNotificationsRequestion(draftNotificationsPagination);
            }}
            dispatchApiRequest={apiRequest}
            // @ts-expect-error TS2322
            currentUserId={user.id}
            onDeleteNotification={onDeleteNotification}
            onTakeActionNoticeClicked={onTakeActionNoticeClicked}
            notification={notification}
            onPromptModal={onPromptModal}
            // @ts-expect-error TS2322
            onTakeActionRegisterEvent={onTakeActionRegisterEvent}
          />
        );
      });
    }
    return <></>;
  };
  const _renderDraftNotifications = (
    draftNotifications: NotificationsResponse,
  ) => {
    if (Array.isArray(draftNotifications.rows)) {
      return draftNotifications.rows.map((draftNotification) => {
        return (
          <NotificationComponent
            key={draftNotification.id}
            onCLickForEditNotification={(notification: Notification) => {
              const editorState = notification.nodalData
                ? sanitizeEditorStateMarkupHtml(
                    EditorState.createWithContent(
                      convertFromRawWithTrimmer(notification.message),
                    ),
                  )
                : EditorState.createEmpty();
              setAutoSaveNotificationId(notification.id);
              setVisibleModal("edit");
              setEditorState(editorState);
              setSendEmail(notification.sendEmail);
              setState({
                title: notification.actionText,
                type: notification.meta.type,
                videoUrl: notification.meta.videoUrl,
                description: notification.message,
                userTag: notification.userTag,
              });
            }}
            enableEditing={true}
            draftMessage={true}
            currentUserRole={user.type}
            authToken={user.token}
            refresh={() => {
              getNotificationsRequestion(notificationsPagination);
              getDraftNotificationsRequestion(draftNotificationsPagination);
            }}
            dispatchApiRequest={apiRequest}
            // @ts-expect-error TS2322
            currentUserId={user.id}
            onDeleteNotification={onDeleteNotification}
            onTakeActionNoticeClicked={onTakeActionNoticeClicked}
            notification={draftNotification}
            onPromptModal={onPromptModal}
            // @ts-expect-error TS2322
            onTakeActionRegisterEvent={onTakeActionRegisterEvent}
          />
        );
      });
    }
    return <></>;
  };
  const _renderCreateNotifications = (): ReactElement => {
    return (
      <ButtonOption
        onClick={() => {
          setAutoSaveNotificationId(0);
          setVisibleModal("edit");
        }}
      >
        +{" "}
        {intl.formatMessage({
          id: "screen.label.create_notification",
        })}
      </ButtonOption>
    );
  };
  const _renderTabHead = (title: string, count: number): ReactElement => {
    return (
      <>
        {title}
        {count > 0 ? (
          <Badge>
            {`${count}`}
            <FormattedMessage id="screen.label.count" />
          </Badge>
        ) : null}
      </>
    );
  };
  const _renderNotificationFooter = (): ReactElement | null => {
    if (eventDetail.action === "deleted_event") {
      return null;
    }
    return (
      <span>
        <ButtonUndo
          loading={eventDetail.isMemberStatus == null}
          onClick={
            eventDetail.isMemberStatus ? onUnRegisterEvent : onRegisterForEvent
          }
          type={"ghost"}
        >
          {eventDetail.isMemberStatus
            ? intl.formatMessage({
                id: "screen.label.cancel_participation_application",
              })
            : intl.formatMessage({
                id: "screen.label.apply_for_participation",
              })}
        </ButtonUndo>
      </span>
    );
  };
  const onTakeActionNoticeClicked = (
    notification: Notification,
    noticeType: string,
  ) => {
    const noticeDetail: NoticeDetail = {
      noticeType,
      title: notification.actionText,
      description: notification.message,
      id: notification.id,
      meta: notification.meta,
      nodalData: notification.nodalData,
    };
    let editNode = null;
    try {
      editNode = sanitizeEditorStateMarkupHtml(
        EditorState.createWithContent(
          convertFromRawWithTrimmer(notification.message),
        ),
      );
      // eslint-disable-next-line no-empty
    } catch (e) {}
    if (editNode !== null) {
      setEditorState(editNode);
    }
    setNoticeDetail(noticeDetail);
    setVisibleModal("generic");
  };
  const onDeleteNotification = (notification: Notification) => {
    const requestBuilder = {
      method: "putRequest",
      url: parseEndpoint(endpoints.updateNotification, {
        id: notification.id,
      }),
      data: {
        deletedAt: new Date(),
      },
    };
    // @ts-expect-error TS2345
    apiRequest?.(requestBuilder, () => {
      getNotificationsRequestion(notificationsPagination);
      getDraftNotificationsRequestion(draftNotificationsPagination);
    });
  };

  const onTakeActionRegisterEvent = (eventDetail: EventDetail) => {
    getIsEventMember(eventDetail);
  };
  const getIsEventMember = (eventDetail: EventDetail) => {
    const getIsEventMemberRequestData = {
      method: "getRequest",
      url: `events/${getObjUUID(eventDetail)}/users/${user.id}`,
      data: {},
    };
    apiRequest?.(
      // @ts-expect-error TS2345
      getIsEventMemberRequestData,
      ({ data }: { data: EventMember | null }) => {
        const newEventDetail = {
          ...eventDetail,
          isMemberStatus: data !== null,
        };
        setEventDetail(newEventDetail);
        setVisibleModal("event");
      },
    );
  };

  // Not in use? notification_type=other won't come into the list here at the moment
  // See: getNotificationsRequestion
  const onPromptModal = (modalEnv: {
    name: string;
    description: string;
    uuid: string;
  }) => {
    setVisibleModal("wanted");
    setState({
      modalTitle: modalEnv.name,
      modalDesc: modalEnv.description,
      modalLink: modalEnv.uuid,
    });
  };
  const handleCancel = () => {
    initModal();
  };
  const onCloseGenericModal = () => {
    initModal();
  };
  const onCloseEventModal = () => {
    initModal();
  };
  const onRegisterForEvent = () => {
    const eventRequestData = {
      method: "postRequest",
      url: parseEndpoint(endpoints.getEventUsers, {
        data: {
          eventId: getObjUUID(eventDetail),
        },
        queryParams: "",
      }),
      successMessage: intl.formatMessage({
        id: "screen.label.apply_participation_event",
      }),
      data: {
        userId: user.id,
        eventId: eventDetail.id,
      },
    };
    // @ts-expect-error TS2345
    apiRequest?.(eventRequestData);
    onCloseEventModal();
  };
  const onChangeNotificationFilter = (value: string) => {
    getNotificationsRequestion({
      ...notificationsPagination,
      page: 1,
      metaType: value,
    });
  };
  const onUnRegisterEvent = () => {
    const eventRequestData = {
      method: "deleteRequest",
      url: parseEndpoint(endpoints.findEventMember, {
        eventId: getObjUUID(eventDetail),
        userId: user.id,
      }),
      successMessage: intl.formatMessage({
        id: "screen.label.canceled_participate_event",
      }),
      data: {},
    };
    // @ts-expect-error TS2345
    apiRequest?.(eventRequestData);
    onCloseEventModal();
  };
  const _renderNoticeDetail = () => {
    let noticeDetailNode = <>{noticeDetail.description}</>;
    if (noticeDetail.nodalData === true) {
      noticeDetailNode = (
        <Editor
          toolbarHidden={true}
          readOnly={true}
          editorState={sanitizeEditorStateMarkupHtml(editorState)}
        />
      );
    }
    return noticeDetailNode;
  };

  const options: ReactElement[] = [];
  stateRef.current.userTags.forEach((userTag: string) => {
    options.push(
      <Option key={userTag} value={userTag}>
        {userTag}
      </Option>,
    );
  });
  let tagOptions = <></>;
  tagOptions = (
    <Form.Item label={constants.userTags}>
      <Dropdown
        allowClear
        style={{ width: 200 }}
        placeholder=""
        onChange={(value) => handleChange("userTag", value)}
        defaultValue={stateRef.current.userTag}
      >
        {options}
      </Dropdown>
    </Form.Item>
  );
  let desc = (
    <Form.Item
      label={intl.formatMessage({
        id: "screen.label.more_detail",
      })}
    >
      <Editor
        toolbar={nodeConfig(
          uploadImageCallBack,
          convertYVLinksToEmbed.bind({}, user.token, getObjUUID(company)),
        )}
        wrapperClassName="wrapper-class"
        editorClassName="editor-class"
        toolbarClassName="toolbar-class"
        placeholder={intl.formatMessage({
          id: "screen.label.please_enter",
        })}
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
      />
    </Form.Item>
  );
  if (stateRef.current.type === "poll") {
    desc = (
      <Input
        required
        type="url"
        placeholder="https://~"
        value={stateRef.current.description}
        onChange={(event) => handleChange("description", event.target.value)}
      />
    );
  }
  return (
    <>
      <Row justify="end">{_renderCreateNotifications()}</Row>
      <Tabs type="card" size={"small"}>
        <TabPane
          tab={intl.formatMessage({
            id: "screen.label.delivered",
          })}
          key={1}
        >
          <Row>
            <Col xs={24} sm={12} md={8} lg={6}>
              <Dropdown
                allowClear
                onChange={onChangeNotificationFilter}
                listHeight={400}
                style={{ width: "70%" }}
              >
                <Option value={`metaType=general_notice`}>
                  {intl.formatMessage({
                    id: "screen.label.notice",
                  })}
                </Option>
                <Option value={`metaType=important_notice`}>
                  {intl.formatMessage({
                    id: "screen.label.important_notices",
                  })}
                </Option>
                <Option value={`metaType=poll`}>
                  {intl.formatMessage({
                    id: "screen.label.questionnaire",
                  })}
                </Option>
                <Option value={`metaType=event_registration`}>
                  {intl.formatMessage({
                    id: "screen.label.seminars",
                  })}
                  /
                  {intl.formatMessage({
                    id: "screen.label.events",
                  })}
                </Option>
                <Option value={`metaType=news_posted`}>
                  {intl.formatMessage({
                    id: "screen.label.news",
                  })}
                </Option>
                <Option value={`metaType=industry_theme_report`}>
                  {intl.formatMessage({
                    id: "screen.label.industry_theme_report",
                  })}
                </Option>
              </Dropdown>
            </Col>
          </Row>
          <br />
          <br />
          <Line />
          <Row>
            <Col span={24}>{_renderOtherNotifications(notifications)}</Col>
          </Row>
          <br />
          <div className={"card-footer"} style={{ padding: "3%" }}>
            <CustomPagination
              saveId={PageSaveId}
              count={notifications.count}
              initPage={notificationsPagination.page}
              initLimit={notificationsPagination.limit}
              onChangePage={(page, limit) => {
                getNotificationsRequestion({
                  ...notificationsPagination,
                  page,
                  limit,
                });
              }}
            />
          </div>
        </TabPane>
        <TabPane
          tab={_renderTabHead(
            intl.formatMessage({
              id: "screen.label.draft_saved",
            }),
            draftNotifications.count,
          )}
          key={2}
        >
          {_renderDraftNotifications(draftNotifications)}
          <div className={"card-footer"} style={{ padding: "3%" }}>
            <CustomPagination
              saveId={PageDraftSaveId}
              count={draftNotifications.count}
              onChangePage={(page, limit) => {
                getDraftNotificationsRequestion({
                  ...draftNotificationsPagination,
                  page,
                  limit,
                });
              }}
            />
          </div>
        </TabPane>
      </Tabs>

      {/* edit */}
      <Modal
        afterClose={() => {
          setSendEmail(false);
        }}
        title={intl.formatMessage({
          id: "screen.label.add_announcement",
        })}
        visible={visibleModal === "edit"}
        style={{
          minWidth: "40vw",
        }}
        onCancel={onCancelEditModal}
        destroyOnClose
        footer={
          <Row align="middle" justify="center">
            <ButtonCV
              onClick={() => {
                submitNotice();
              }}
              style={{ paddingRight: 40, paddingLeft: 40 }}
              type="primary"
            >
              {<FormattedMessage id="screens.button.post" />}
            </ButtonCV>
            <ButtonCV
              onClick={() => {
                saveAsDraft();
              }}
              type="primary"
              className="primary-outline"
            >
              {<FormattedMessage id="screen.label.draft_saved" />}
            </ButtonCV>
            <ButtonUndo type="ghost" onClick={onCancelEditModal}>
              {<FormattedMessage id="screen.label.cancel" />}
            </ButtonUndo>
          </Row>
        }
      >
        <Row>
          <Col span={16} offset={4}>
            <Form layout={"vertical"}>
              <Form.Item
                label={intl.formatMessage({
                  id: "screen.label.notification_type",
                })}
              >
                <Dropdown
                  defaultValue={stateRef.current.type}
                  style={{ width: 200 }}
                  onChange={(value) => handleChange("type", value)}
                >
                  <Option value="general_notice">
                    {intl.formatMessage({
                      id: "screen.label.notice",
                    })}
                  </Option>
                  <Option value="important_notice">
                    {intl.formatMessage({
                      id: "screen.label.important_notices",
                    })}
                  </Option>
                  <Option value="poll">
                    {intl.formatMessage({
                      id: "screen.label.questionnaire",
                    })}
                  </Option>
                </Dropdown>
              </Form.Item>
              <Form.Item
                label={intl.formatMessage({
                  id: "screen.label.title",
                })}
              >
                <Input
                  required
                  type="text"
                  placeholder={intl.formatMessage({
                    id: "screen.label.please_enter",
                  })}
                  value={stateRef.current.title}
                  onChange={(event) =>
                    handleChange("title", event.target.value)
                  }
                />
              </Form.Item>
              {desc}

              <Form.Item
                label={intl.formatMessage({
                  id: "screen.label.video_url",
                })}
              >
                <Input
                  onChange={(event) =>
                    handleChange("videoUrl", event.target.value)
                  }
                  value={stateRef.current.videoUrl}
                  placeholder={intl.formatMessage({
                    id: "screen.label.video_url",
                  })}
                />
              </Form.Item>
              {tagOptions}
              <Form.Item>
                <Checkbox
                  onChange={(e) => {
                    setSendEmail(e.target.checked);
                  }}
                  checked={sendEmail}
                >
                  {intl.formatMessage({
                    id: "screen.label.email_notification",
                  })}
                </Checkbox>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>

      {/* event */}
      <Modal
        onCancel={onCloseEventModal}
        title={intl.formatMessage({
          id: "screen.label.events_news",
        })}
        visible={visibleModal === "event"}
        footer={_renderNotificationFooter()}
      >
        <h4>
          {intl.formatMessage({
            id: "screen.label.event_name",
          })}
        </h4>
        <p>{eventDetail.name}</p>
        <br />
        <h4>
          {intl.formatMessage({
            id: "screen.label.more_detail",
          })}
        </h4>
        <p style={{ whiteSpace: "pre-line" }}>
          {eventDetail.nodalData && isJsonString(eventDetail.description) ? (
            <Editor
              toolbarHidden={true}
              readOnly={true}
              editorState={sanitizeEditorStateMarkupHtml(
                EditorState.createWithContent(
                  convertFromRawWithTrimmer(eventDetail.description),
                ),
              )}
            />
          ) : (
            eventDetail.description
          )}
        </p>
        <br />

        <h4>
          {intl.formatMessage({
            id: "screen.label.event_date_and_time",
          })}
        </h4>
        <Row>
          <Col>
            <p>
              {moment(eventDetail.beginsAt).local().format(dateTimeFormat)}{" "}
              &nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </p>
          </Col>
          <Col>
            {eventDetail.endsAt ? (
              <p>
                ~&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                {eventDetail.endsAt
                  ? moment(eventDetail.endsAt).local().format(dateTimeFormat)
                  : ""}
              </p>
            ) : null}
          </Col>
        </Row>

        {typeof eventDetail.capacity === "number" &&
        eventDetail.capacity > 0 ? (
          <>
            <h4>
              {intl.formatMessage({
                id: "screen.label.capacity",
              })}
            </h4>
            <p>{eventDetail.capacity}</p>
          </>
        ) : null}

        {eventDetail.applicationDeadline ? (
          <>
            <h4>
              {intl.formatMessage({
                id: "screen.label.events_application_deadline_date",
              })}
            </h4>
            <p>
              {eventDetail.applicationDeadline
                ? moment(eventDetail.applicationDeadline)
                    .local()
                    .format(dateTimeFormat)
                : ""}
            </p>
          </>
        ) : null}

        {eventDetail.videoUrl && eventDetail.videoUrl.length > 0 ? (
          <div className="player-wrapper">
            <ReactPlayer
              loop={false}
              controls
              className="react-player"
              url={eventDetail.videoUrl}
              width="100%"
              height="100%"
              config={{
                file: {
                  attributes: {
                    controlsList: "nodownload",
                  },
                },
              }}
            />
          </div>
        ) : null}
      </Modal>

      {/* generic */}
      <Modal
        // title={stateRef.current.noticeDetails.noticeType}
        title={noticeDetail.noticeType}
        visible={visibleModal === "generic"}
        onOk={onCloseGenericModal}
        onCancel={onCloseGenericModal}
        footer={null}
      >
        <h4>
          {intl.formatMessage({
            id: "screen.label.title",
          })}
        </h4>
        <p>{noticeDetail.title}</p>
        <br />
        {noticeDetail.meta.type === "poll" ? (
          <>
            <h4>URL</h4>
            <a
              rel="noreferrer"
              style={{ whiteSpace: "pre-line" }}
              target={"_blank"}
              href={noticeDetail.description}
            >
              {noticeDetail.description}
            </a>
          </>
        ) : (
          <>
            <h4>
              {intl.formatMessage({
                id: "screen.label.more_detail",
              })}
            </h4>
            <p style={{ whiteSpace: "pre-line" }}>{_renderNoticeDetail()}</p>
          </>
        )}
        <br />
        {noticeDetail.meta.videoUrl ? (
          <div className="player-wrapper">
            <ReactPlayer
              loop={false}
              controls
              className="react-player"
              url={noticeDetail.meta.videoUrl}
              width="100%"
              height="100%"
              config={{
                file: {
                  attributes: {
                    controlsList: "nodownload",
                  },
                },
              }}
            />
          </div>
        ) : null}
      </Modal>

      {/* wanted, might not be in use */}
      <Modal
        title={intl.formatMessage({
          id: "screen.label.members_wanted",
        })}
        visible={visibleModal === "wanted"}
        onCancel={handleCancel}
        onOk={() => {
          handleCancel();
          props.history.push(
            `/dashboard/project.top/${stateRef.current.modalLink}`,
          );
        }}
        cancelText={intl.formatMessage({
          id: "screen.label.cancel",
        })}
        okText={intl.formatMessage({
          id: "screen.label.check_project",
        })}
      >
        {stateRef.current.modalTitle}{" "}
        {intl.formatMessage({
          id: "screen.label.there_looking_for_members",
        })}
        <br />
        <p style={{ whiteSpace: "pre-line" }}>{stateRef.current.modalDesc}</p>
        <h4>
          {intl.formatMessage({
            id: "screen.label.application_method",
          })}
        </h4>
        <ol style={{ paddingLeft: "20px" }}>
          <li>
            {intl.formatMessage({
              id: "screen.label.check_project_des",
            })}
          </li>
          <li>
            {intl.formatMessage({
              id: "screen.label.apply_to_become_project_member_des",
            })}
          </li>
        </ol>
      </Modal>
    </>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  dispatchTakeActionOnNotification: (
    data: Notification,
    id: number,
    message: string,
    // @ts-expect-error TS2554
  ) => dispatch(HomeActions.homeNotificationActionRequest(data, id, message)),
});

ImportantNoticeScreen.propTypes = {
  // @ts-expect-error TS2322
  context: PropTypes.object.isRequired,
  dispatchTakeActionOnNotification: PropTypes.func,
};
export default connect(null, mapDispatchToProps)(ImportantNoticeScreen);
