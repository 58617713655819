import React from "react";
// @ts-expect-error TS7016
import { useScrapper } from "react-tiny-link";

import { Card, Content, ContentWrapper, Footer, Header } from "./card";
import CardMedia from "./cardMedia";

type Props = {
  news: {
    id: string;
    url: string;
    pageTitle: string;
    category: string;
    newsComment: string;
    notifyByEmail: boolean;
    numberOfLikes: number;
    isGlobalNews: boolean;
    userId: number;
    permittedCompanies: Array<number>;
    // @ts-expect-error TS2304
    NewsEmojiResponse: Array<EmojiComment>;
    // @ts-expect-error TS2304
    NewsComments: Array<NewsComment>;
    User: Object;
    thumbImage: string;
    createdAt: string;
    updatedAt: string;
  };
};

const cardSize = "large";
const maxLine = 2;
const minLine = 1;
const REGEX_STRIP_WWW = /^www\./;

const getHostname = (href: string): string | null => {
  let hostname: string | null = null;
  if (href) {
    try {
      const url = new URL(href);
      hostname = url.hostname;
    } catch (e) {
      if (
        e instanceof TypeError &&
        e.message === "Failed to construct 'URL': Invalid URL"
      ) {
        // ignore the error caused by a wrong URL
      } else {
        throw e;
      }
    }
    hostname = hostname?.replace(REGEX_STRIP_WWW, "") ?? null;
  }
  return hostname;
};

function NewLinkPreview(props: Props) {
  const { news } = props;

  const [result, loading] = useScrapper({
    url: news.url,
    proxyUrl: "https://cors.bridged.cc",
  });
  const thumbImage = React.useMemo(() => {
    if (!news.thumbImage) {
      if (result && result.image && result.image.length > 0) {
        return result.image[0];
      }
      return null;
    }
    if (typeof news.thumbImage === "string") {
      if (news.thumbImage.includes("http")) {
        return news.thumbImage;
      }
      return `https://${news.thumbImage}`;
    } else {
      return URL.createObjectURL(news.thumbImage);
    }
  }, [news.thumbImage, result]);

  const pageTitle = React.useMemo(() => {
    if (result && result.title) {
      return result.title;
    }
    if (news.pageTitle) {
      return news.pageTitle;
    }
    if (news.url) {
      return news.url;
    }
    return null;
  }, [news, result]);

  const description = React.useMemo(() => {
    if (result && result.description) {
      return result.description;
    }
    if (news.url) {
      return news.url;
    }
    return null;
  }, [news, result]);

  return (
    <Card
      className="react_tinylink_card"
      // @ts-expect-error TS2769
      cardSize={cardSize}
      href={news.url}
      width={"640px"}
      isShownGraphic={true}
    >
      <CardMedia cardSize={cardSize} imgUrl={thumbImage} />
      <ContentWrapper
        className="react_tinylink_card_content_wrapper"
        // @ts-expect-error TS2769
        cardSize={cardSize}
      >
        <Header
          // @ts-expect-error TS2769
          maxLine={maxLine}
          minLine={minLine}
          className="react_tinylink_card_content_header"
        >
          <p className="react_tinylink_card_content_header_description">
            {pageTitle}
          </p>
        </Header>
        <Content
          // @ts-expect-error TS2769
          maxLine={maxLine}
          minLine={minLine}
          className="react_tinylink_card_content"
          cardSize={cardSize}
        >
          <p
            className="react_tinylink_card_content_description"
            title={description}
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "block",
            }}
          >
            {description}
          </p>
        </Content>
        <Footer className="react_tinylink_footer">
          <p className="react_tinylink_card_footer_description">
            {getHostname(news.url)}
          </p>
        </Footer>
      </ContentWrapper>
    </Card>
  );
}
export default NewLinkPreview;
