import { FC, PropsWithChildren, useCallback, useState } from "react";
import { IntlProvider } from "react-intl";
import { SWRConfig } from "swr";

import { I18NContext } from "@/i18n/context";
import English from "@/i18n/en.json";
import Japanese from "@/i18n/ja.json";

const defaultLocale = "ja";

export type CommonContextProvidersProps = {
  //
};

export const CommonContextProviders: FC<
  PropsWithChildren<CommonContextProvidersProps>
> = ({ children }) => {
  const [locale, setLocale] = useState(defaultLocale);

  const selectLanguage = useCallback((locale: string) => {
    setLocale(locale);
  }, []);

  return (
    <I18NContext.Provider
      value={{
        locale,
        selectLanguage,
      }}
    >
      <IntlProvider
        locale={locale}
        defaultLocale={defaultLocale}
        messages={locale === "ja" ? Japanese : English}
      >
        <SWRConfig
          value={{
            shouldRetryOnError: false,
            revalidateIfStale: false,
            revalidateOnFocus: false,
            revalidateOnReconnect: false,
          }}
        >
          {children}
        </SWRConfig>
      </IntlProvider>
    </I18NContext.Provider>
  );
};
