import { MenuOutlined } from "@ant-design/icons";
import { Card, Popconfirm } from "antd";
import moment from "moment";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { ReduxDispatch, ReduxState } from "@/Redux/types";

import { ButtonSwitch, HorizonalLayout } from "../../Components/atoms";
import { CustomPagination } from "../../Components/molecules";
import VideoSortableTable from "../../Components/organisms/videos/VideoSortableTable";
import ApiActions, {
  ApiRequestData,
  ApiSuccessCallback,
} from "../../Redux/ApiRedux";
import HomeActions from "../../Redux/HomeRedux";
import { dateTimeFormat, defaultThumbnailImg } from "../../utils/constants";
import useMgtRecommendedVideosScreen from "./hooks/useMgtRecommendedVideosScreen";
import { IMgtRecommendedVideosScreenProps } from "./types/IMgtRecommendedVideosScreenProps";
const PageSaveId = "MgtRecommendedVideosScreen";

const MgtRecommendedVideosScreen = function (
  props: IMgtRecommendedVideosScreenProps,
) {
  const {
    intl,
    state,
    setState,
    getDragProps,
    applyFilter,
    onChangeRecommendedCheckBox,
  } = useMgtRecommendedVideosScreen(props);
  const getTableColumns = () => {
    return [
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
      },
      {
        title: intl.formatMessage({
          id: "screen.label.video_name",
        }),
        dataIndex: "name",
        key: "name",
        width: "200px",
        // @ts-expect-error TS7006
        render: function _name(text, row) {
          return (
            <div style={{}}>
              <Link to={`/dashboard/videos-play/${row.id}`}>
                <img
                  alt={intl.formatMessage({
                    id: "screens.alt.video_thumbnail",
                  })}
                  src={row.imageThumbnail || defaultThumbnailImg}
                  style={{
                    width: `160px`,
                    height: `96px`,
                    maxWidth: `160px`,
                    maxHeight: `96px`,
                    objectFit: "cover",
                  }}
                />
                <br />
                <p
                  style={{
                    color: "#000000",
                    fontSize: "12px",
                    fontWeight: "normal",
                    fontStyle: "normal",
                  }}
                >
                  {text}
                </p>
              </Link>
            </div>
          );
        },
      },
      {
        title: intl.formatMessage({
          id: "screen.label.category_title",
        }),
        dataIndex: "category",
        key: "category",
        // @ts-expect-error TS7006
        render: function _category(text, row) {
          return (
            <>
              <h4>
                {row.VideoLargeCategory.name ? row.VideoLargeCategory.name : ""}
              </h4>
              <h5>
                {row.VideoSmallCategory.name ? row.VideoSmallCategory.name : ""}
              </h5>
            </>
          );
        },
      },
      {
        title: intl.formatMessage({
          id: "screen.label.posted_date_and_time",
        }),
        dataIndex: "createdAt",
        key: "createdAt",
        // @ts-expect-error TS7006
        render: (text, row) =>
          moment(row.createdAt).local().format(dateTimeFormat),
      },
      state.canRearrange
        ? {
            title: "",
            dataIndex: "",
            key: "",
            // @ts-expect-error TS7006
            render: function _canRearrangeFunc(_, row) {
              return (
                <MenuOutlined
                  className={"drag-handle"}
                  style={{ cursor: "grab", color: "#999" }}
                />
              );
            },
          }
        : {
            title: intl.formatMessage({
              id: "screen.label.recommended",
            }),
            dataIndex: "isRecommended",
            key: "isRecommended",
            // @ts-expect-error TS7006
            render: function _isRecommended(isRecommended, row) {
              return (
                <Popconfirm
                  onConfirm={() => {
                    onChangeRecommendedCheckBox(false, row);
                  }}
                  title={intl.formatMessage({
                    id: "screen.videos.delete_recommendation",
                  })}
                  okText={intl.formatMessage({
                    id: "screen.label.yes",
                  })}
                  cancelText={intl.formatMessage({
                    id: "screen.label.no",
                  })}
                >
                  {/*<Checkbox checked={true} />*/}
                  <label className="container">
                    <input onChange={() => {}} checked={true} type="checkbox" />
                    <span className="checkmark" />
                  </label>
                </Popconfirm>
              );
            },
          },
    ];
  }; //

  const page = state.page || 1;
  const limit = state.limit || 10;
  const videoRenders =
    state.videos && state.videos.rows
      ? state.videos.rows.slice((page - 1) * limit, page * limit)
      : [];

  return (
    <>
      <h2>
        <FormattedMessage id="screen.label.recommended_videos" />
      </h2>

      <Card>
        <HorizonalLayout
          style={{
            justifyContent: "flex-end",
          }}
        >
          <p
            style={{
              marginLeft: "0.5vw",
              marginRight: "0.5vw",
            }}
          >
            <FormattedMessage id={"screen.label.sort_items"} />
          </p>{" "}
          <ButtonSwitch
            onChange={(checked) => {
              setState({
                // @ts-expect-error TS2345
                canRearrange: checked,
              });
            }}
            checked={state.canRearrange}
          />
        </HorizonalLayout>
        <br />
        <VideoSortableTable
          canReArrange={state.canRearrange}
          columns={getTableColumns()}
          dataSource={videoRenders}
          rowKey={"id"}
          getDragProps={getDragProps}
        />
        <br />
        <CustomPagination
          saveId={PageSaveId}
          count={state.videos.count}
          onChangePage={(page, limit) => {
            setState(
              {
                page,
                limit,
              },
              () => {
                applyFilter();
              },
            );
          }}
        />
      </Card>
    </>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  largeCategories: state.videos.largeCategoriesPayload,
  user: state.auth.payload,
});
const mapDispatchToProps = (dispatch: ReduxDispatch) => ({
  // @ts-expect-error TS7006
  dispatchSetPageTitle: (pageTitle) =>
    // @ts-expect-error TS2554
    dispatch(HomeActions.pageTitle(pageTitle)),
  dispatchApiRequest: (data: ApiRequestData, callback: ApiSuccessCallback) =>
    dispatch(ApiActions.apiRequest(data, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  //@ts-expect-error 要API型定義
  MgtRecommendedVideosScreen,
);
