import { Card, Layout, Row } from "antd";
import React from "react";
import { useIntl } from "react-intl";
import { connect } from "react-redux";

import { IndustryReportFormData } from "@/Components/organisms/industry-report/form";
import { ReduxDispatch, ReduxState } from "@/Redux/types";

import { ButtonUndo } from "../../../../Components/atoms";
import {
  IndustryReportForm,
  IndustryReportMainCategory,
} from "../../../../Components/organisms";
import ApiActions, {
  ApiRequestData,
  ApiSuccessCallback,
} from "../../../../Redux/ApiRedux";
import IndustryMainCategoryActions from "../../../../Redux/IndustryMainCategoryRedux";
import IndustryActions from "../../../../Redux/IndustryRedux";
import { endpoints, parseEndpoint } from "../../../../Services/endpoints";
const { Content } = Layout;
type StateProps = {
  auth: Object;
  industryMainCategories: {
    industryMainCategoryPayload: Array<IndustryReportMainCategory>;
  };
};
type ActionProps = {
  dispatchApiRequest: (data: unknown) => void;
  dispatchUpdateReport: (data: unknown) => void;
  dispatchGetMainCategories: (data: unknown) => void;
  dispatchDeleteReport: (data: unknown, message: unknown) => void;
};
type otherProps = {
  match: { params: { id: string } };
};
type Props = ActionProps & StateProps & otherProps;
function IndustryReportCreate(props: Props) {
  const [loading, setLoading] = React.useState(false);
  const [companies, setCompanies] = React.useState([]);
  const [item, setItem] = React.useState(null);
  const [actionTotake, setActionToTake] = React.useState(null);
  const intl = useIntl();
  const [itemId] = React.useMemo(() => {
    const {
      match: { params },
    } = props;
    const itemId = params.id ? params.id : -1;
    if (itemId === -1) {
      // @ts-expect-error TS2345
      setActionToTake("new");
    }
    return [itemId];
  }, [props]);
  React.useEffect(() => {
    if (itemId && itemId !== -1 && itemId !== "") {
      setLoading(true);
      getReportItem();
    }
  }, [itemId]);
  React.useEffect(() => {
    if (item) {
      // @ts-expect-error TS2345
      setActionToTake("update");
    }
  }, [item]);
  const getReportItem = () => {
    const requestBuilder = {
      method: "getRequest",
      url: parseEndpoint(endpoints.updateIndustryReport, { id: itemId }),
    };
    // @ts-expect-error TS2554
    props.dispatchApiRequest(requestBuilder, ({ data }) => {
      setLoading(false);
      setItem(data);
    });
  };
  React.useEffect(() => {
    getCompanies();
    // @ts-expect-error TS2554
    props.dispatchGetMainCategories();
  }, []);
  const getCompanies = () => {
    const requestBuilder = {
      method: "getRequest",
      url: parseEndpoint(endpoints.companies),
      data: {},
    };
    // @ts-expect-error TS2554
    props.dispatchApiRequest(requestBuilder, ({ data }) => {
      if (data.rows && data.rows.length > 0) {
        const { libraryEnables } = data;
        const parseLibraryEnable = Array.isArray(libraryEnables)
          ? libraryEnables.reduce((s, c) => {
              return {
                ...s,
                [c.companyId]: {
                  isIndustryEnabled: c.isIndustryEnabled,
                },
              };
            }, {})
          : {};

        const tempComp = Array.isArray(data.rows)
          ? // @ts-expect-error TS7006
            data.rows.filter((c) => {
              return (
                parseLibraryEnable[c.id]?.isIndustryEnabled ||
                typeof parseLibraryEnable[c.id] === "undefined"
              );
            })
          : [];
        setCompanies(tempComp);
      }
    });
  };
  const handleSubmit = (data: IndustryReportFormData) => {
    const newData: IndustryReportFormData & { id?: string | number } = {
      ...data,
    };
    newData["id"] = itemId;

    props.dispatchUpdateReport(newData);
  };
  return (
    <Content>
      <Row align="middle" justify="space-between">
        <h2>
          {intl.formatMessage({
            id: "screen.label.create_industry_report",
          })}
        </h2>
        <ButtonUndo
          onClick={() => {
            props.dispatchDeleteReport(
              {
                id: itemId,
              },
              intl.formatMessage({
                id: "screen.label.industry_theme_report_delete_success",
              }),
            );
            window.history.back();
          }}
          style={{
            marginTop: "1px",
            backgroundColor: "#EB5757",
          }}
        >
          {intl.formatMessage({
            id: "screen.label.industry_theme_report_delete",
          })}
        </ButtonUndo>
      </Row>

      <Card>
        {actionTotake ? (
          <IndustryReportForm
            item={item}
            companies={companies}
            mainCategories={
              props.industryMainCategories.industryMainCategoryPayload
            }
            onSubmit={handleSubmit}
            loading={loading}
            isGlobal={true}
          />
        ) : null}
      </Card>
    </Content>
  );
}
const mapStateToProps = (state: ReduxState) => ({
  auth: state.auth,
  industryMainCategories: state.industryMainCategories,
});
const mapDispatchToProps = (dispatch: ReduxDispatch) => ({
  // @ts-expect-error TS7006
  dispatchUpdateReport: (data) =>
    // @ts-expect-error TS2554
    dispatch(IndustryActions.industryUpdateRequest(data)),
  // @ts-expect-error TS7006
  dispatchDeleteReport: (data, message) =>
    // @ts-expect-error TS2554
    dispatch(IndustryActions.industryDeleteRequest(data, message)),
  // @ts-expect-error TS7006
  dispatchGetMainCategories: (data) =>
    // @ts-expect-error TS2554
    dispatch(IndustryMainCategoryActions.industryMainCategoryGetRequest(data)),
  dispatchApiRequest: (data: ApiRequestData, callback: ApiSuccessCallback) =>
    dispatch(ApiActions.apiRequest(data, callback)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
  // @ts-expect-error TS2345
)(IndustryReportCreate);
