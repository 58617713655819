import { Form } from "antd";
import { FC } from "react";
import { useIntl } from "react-intl";

import { InsuAcceptButton, InsuRejectButton } from "../../atoms/buttons/shared";

type ProjectHypothesisSubmitButtonProps = {
  onSubmit: () => void;
  onCancel: () => void;
};

export const ProjectHypothesisSubmitButton: FC<
  ProjectHypothesisSubmitButtonProps
> = ({ onSubmit, onCancel }) => {
  const intl = useIntl();

  return (
    <Form.Item>
      <InsuRejectButton
        style={{
          float: "right",
          marginLeft: "0.1vw",
          marginRight: "0.1vw",
        }}
        onClick={onCancel}
      >
        {intl.formatMessage({
          id: "screen.label.cancel",
        })}
      </InsuRejectButton>
      <InsuAcceptButton
        style={{
          float: "right",
          marginLeft: "0.1vw",
          marginRight: "0.1vw",
        }}
        onClick={onSubmit}
      >
        {intl.formatMessage({
          id: "screens.title.save",
        })}
      </InsuAcceptButton>
    </Form.Item>
  );
};
