import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import ApiActions, { ApiRequestData } from "../../../Redux/ApiRedux";
import { endpoints, parseEndpoint } from "../../../Services/endpoints";
import {
  IVerifySubEmailScreenProps,
  VerifySubEmailScreenState,
} from "../types/IVerifySubEmailScreenProps";

const useVerifySubEmailScreen = (props: IVerifySubEmailScreenProps) => {
  const dispatch = useDispatch();
  const [state, customSetState] = useState<VerifySubEmailScreenState>(() => {
    return {
      email: "",
      password: "",
    };
  });

  const setStateCallbackRef = useRef(() => {});
  useEffect(() => {
    const callBack = setStateCallbackRef.current;
    callBack();
    setStateCallbackRef.current = () => {};
  }, [state]);
  const setState = (data: VerifySubEmailScreenState, callback = () => {}) => {
    setStateCallbackRef.current = callback;
    customSetState((previousState) => {
      return {
        ...previousState,
        ...data,
      };
    });
  };

  const getTokenId = () => {
    const {
      match: { params },
    } = props;
    return params.id ? params.id : null;
  };

  const onChange = (e: { target: { id: any; value: any } }) => {
    setState({ [e.target.id]: `${e.target.value}`.trim() });
  };
  const onSubmit = () => {
    const loginRequest: ApiRequestData = {
      method: "postRequest",
      url: parseEndpoint(endpoints.login),
      data: {
        email: state.email,
        password: state.password,
      },
    };
    dispatch(
      ApiActions.apiRequest(loginRequest, ({ data }) => {
        if (data) {
          // onSetAuthToken(data);
          // @ts-expect-error 要API型定義
          onAuthToken(data.uuid);
        }
      }),
    );
  };

  const onAuthToken = (uuid: string) => {
    const tokenRequest: ApiRequestData = {
      method: "postRequest",
      url: parseEndpoint(endpoints.subEmailVerify),
      data: {
        token: getTokenId(),
        uuid,
      },
    };
    dispatch(
      ApiActions.apiRequest(tokenRequest, ({ data }) => {
        //@ts-expect-error 要API型定義
        if (data && data.verified) {
          // @ts-expect-error ts(2339)
          props.history.push("/verify-subemail-success");
        }
      }),
    );
  };

  return {
    state,
    setState,
    onChange,
    onSubmit,
  };
};
export default useVerifySubEmailScreen;
