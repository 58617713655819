import { Card, Col, Divider, Row } from "antd";
import PropTypes from "prop-types";
import { FC } from "react";
import { FormattedMessage } from "react-intl";

import { ButtonSwitch } from "../../Components/atoms";
import useEmailSettingsScreen from "./hooks/useEmailSettingsScreen";
import { IEmailSettingsScreenProps } from "./types/IEmailSettingsScreenProps";
const emailListObject = [
  {
    emailType: "sendProjectApplied",
    title: (
      <FormattedMessage id="screen.label.when_subjected_to_project_participation_application" />
    ),
    isAdmin: false,
  },
  {
    emailType: "sendProjectInvitation",
    title: <FormattedMessage id="screen.label.when_was_invited_to_project" />,
    isAdmin: false,
  },
  {
    emailType: "projectAcceptance",
    title: <FormattedMessage id="screen.label.when_allowed_to_join_project" />,
    isAdmin: false,
  },
  {
    emailType: "projectRejection",
    title: (
      <FormattedMessage id="screen.label.when_denied_participaint_from_project" />
    ),
    isAdmin: false,
  },
  {
    emailType: "sendEventInvitation",
    title: <FormattedMessage id="screen.label.when_there_new_event_guide" />,
    isAdmin: false,
  },
  {
    emailType: "selectedForEvent",
    title: <FormattedMessage id="screen.label.when_event_winner" />,
    isAdmin: false,
  },
  {
    emailType: "notSelectedForEvent",
    title: <FormattedMessage id="screen.label.when_event_rejected" />,
    isAdmin: false,
  },
  {
    emailType: "sendEventCancellation",
    title: <FormattedMessage id="screen.label.when_an_event_canceled" />,
    isAdmin: false,
  },
  {
    emailType: "sendTaskAssigned",
    title: (
      <FormattedMessage id="screen.label.when_that_has_been_assigned_to_task" />
    ),
    isAdmin: false,
  },
  {
    emailType: "emailNotification",
    title: (
      <FormattedMessage id="screen.label.when_notice_has_been_delivered" />
    ),
    isAdmin: false,
  },
  {
    emailType: "sendProjectSubmitted",
    title: (
      <FormattedMessage id="screen.label.when_project_has_been_submitted" />
    ),
    isAdmin: true,
  },
  {
    emailType: "notifyProgramManagerUserInvitationRequest",
    title: (
      <FormattedMessage id="screen.label.when_receiving_user_registration_request" />
    ),
    isAdmin: true,
  },
  {
    emailType: "sendMentionInProjectComment",
    title: <FormattedMessage id="screen.label.when_mention_project_comment" />,
    isAdmin: false,
  },
  {
    emailType: "receiveEmailForProjectBelongingTo",
    title: (
      <FormattedMessage id="screen.email_subscription.receive_email_for_project_i_belong" />
    ),
    isAdmin: false,
  },
];
const watchingList = [
  {
    emailType: "sendProjectHypothesisUpdate",
    title: <FormattedMessage id="screen.label.when_project_hypo_updated" />,
    isAdmin: true,
  },
  {
    emailType: "sendProjectCommentUpdate",
    title: <FormattedMessage id="screen.label.when_project_comment_updated" />,
    isAdmin: true,
  },
];

const EmailSettingsScreen: FC<IEmailSettingsScreenProps> = function (props) {
  const { intl, onChangeEmailStatus, getCheckedDefaultState } =
    useEmailSettingsScreen(props);

  const { user } = props.context;

  let list = emailListObject.filter((email) => {
    if (!email.isAdmin) {
      return true;
    }
    if (email.isAdmin && user.type === "admin") {
      return true;
    }
    return false;
  });

  list = [...list, ...watchingList];
  return (
    <Card title={null} style={{ padding: "2vh 10vw" }}>
      <h3 style={{ fontWeight: "bolder" }}>
        {intl.formatMessage({
          id: "screen.label.notification_email_to_receive",
        })}
      </h3>
      <br />
      <br />
      {list.map((email, i) => {
        return (
          <>
            <Row key={i} align="middle" justify="center">
              <Col flex="1">{email.title}</Col>
              <Col flex="none">
                <ButtonSwitch
                  checked={getCheckedDefaultState(email)}
                  defaultChecked={getCheckedDefaultState(email)}
                  onChange={(state) => {
                    onChangeEmailStatus(email.emailType, state);
                  }}
                />
              </Col>
            </Row>
            <Divider style={{ margin: "10px 0" }} />
          </>
        );
      })}
    </Card>
  );
};

EmailSettingsScreen.propTypes = {
  // @ts-expect-error TS2322
  context: PropTypes.object,
};
export default EmailSettingsScreen;
