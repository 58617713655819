import styled from "styled-components";

export const LargeVideoDesc = styled.p`
  font-size: 12px;
  line-height: 24px;
  font-weight: normal;
  max-width: 70vw;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;
export const LargeVideoName = styled.div`
  max-width: 70vw;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;
export const LargeVideoThumbnail = styled.img`
  max-width: 213.3px;
  border-radius: 4px;
  width: 213.3px;
  height: 120px;
  object-fit: cover;
  max-height: 120px;
`;
export const LargeVideoRoot = styled.div`
  cursor: pointer;
  height: 160px;
  max-height: 160px;
  margin-top: 19px;
  margin-bottom: 19px;
  border-radius: 2px;
  background-color: white;
  padding-top: 20px;
  padding-right: 36px;
  padding-bottom: 20px;
  padding-left: 20px;

  overflow: hidden;
`;

export const VideoTitle = styled.p`
  margin-top: 20px;
  margin-bottom: 24px;
  margin-right: 1.3vw;
  font-weight: bold;
  font-style: normal;
  font-size: 18px;
  line-height: 26px;
  color: #323232;
`;
