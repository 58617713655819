import { useCallback } from "react";

import { api } from "../../../../Saga";
import { BaseErrorResponse } from "../../../../Services/commonResponsTypes";
import { endpoints, parseEndpoint } from "../../../../Services/endpoints";
import { DeleteCompanyResponse } from "./type";

export const useDeleteCompany = () => {
  const deleteCompany = useCallback(async (companyId: number) => {
    const url = parseEndpoint(endpoints.singleCompany, { companyId });
    const response = await api.deleteRequest<
      DeleteCompanyResponse,
      BaseErrorResponse
    >(url, undefined, {
      // テナント削除は時間がかかるため、Axios のタイムアウトを長めに設定しておく
      timeout: 10 * 60 * 1000,
    });

    return response;
  }, []);

  return { deleteCompany };
};
