import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

import { ContentContext } from "../../../Provider";
import { endpoints, parseEndpoint } from "../../../Services/endpoints";
import { dateTimeFormat } from "../../../utils/constants";
import useLocalStorage from "../../../utils/localStorage";

const PageSaveId = "OperationalLogScreen";
const pageKey = `pagination_page_${PageSaveId}`;
const limitKey = `pagination_limit_${PageSaveId}`;

const useOperationalLogScreen = () => {
  const context = useContext(ContentContext);
  const { apiRequest, user, updatePageTitle } = context;
  const [page, setPage] = useLocalStorage(pageKey, 1);
  const [limit, setLimit] = useLocalStorage(
    limitKey,
    process.env.REACT_APP_PAGE_SIZE,
  );
  const [operationalLogs, setOperationalLogs] = useState({
    count: 0,
    rows: [],
  });
  const [startDate, setStartDate] = useState(
    moment().subtract(1, "month").local(),
  );
  const [endDate, setEndDate] = useState(moment().local());
  useEffect(() => {
    updatePageTitle("screen.label.operation_history");
  }, []);
  useEffect(() => {
    filterLogs();
  }, [page, limit]);
  const filterLogs = () => {
    const requestBuilder = {
      method: "postRequest",
      url: parseEndpoint(endpoints.advanceFilterOperationalLogs),
      data: {
        page: page - 1,
        limit,
        startDate,
        endDate,
      },
    };
    // @ts-expect-error TS2345
    apiRequest(requestBuilder, ({ data }) => {
      // @ts-expect-error TS2345
      setOperationalLogs(data);
    });
  };
  const getTableColumns = () => {
    return [
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
        responsive: ["sm"],
      },
      {
        title: <FormattedMessage id="screens.title.email" />,
        // @ts-expect-error TS7006
        render: (text, row) => {
          return row.User.Credential.email;
        },
      },
      {
        title: <FormattedMessage id="screens.title.name" />,
        responsive: ["sm"],
        // @ts-expect-error TS7006
        render: (text, row) => {
          return row.User.name;
        },
      },
      {
        title: <FormattedMessage id="screens.title.ipaddress" />,
        dataIndex: "clientIp",
        key: "clientIp",
        responsive: ["sm"],
      },
      {
        title: "URL",
        dataIndex: "frontendUrl",
        key: "frontendUrl",
        responsive: ["sm"],
      },
      {
        title: <FormattedMessage id="screens.title.userEvent" />,
        dataIndex: "userEvent",
        key: "userEvent",
        responsive: ["sm"],
      },
      {
        title: <FormattedMessage id="screen.label.opeartion_date_time" />,
        dataIndex: "createdAt",
        key: "createdAt",
        responsive: ["sm"],
        // @ts-expect-error TS7006
        render: (text) => moment(text).local().format(dateTimeFormat),
      },
    ];
  };

  return {
    setPage,
    setLimit,
    operationalLogs,
    authUser: user,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    filterLogs,
    getTableColumns,
  };
};

export default useOperationalLogScreen;
