import { useIntl } from "react-intl";

import { SecondaryButton } from "@/shared/buttons/components/SecondaryButton";

import styles from "./styles.module.scss";
import { EventCancelButtonProps } from "./type";

export const EventCancelButton = ({
  cancelEventHandler,
}: EventCancelButtonProps) => {
  const intl = useIntl();

  return (
    <SecondaryButton onClick={cancelEventHandler} className={styles.cancel_btn}>
      {intl.formatMessage({
        id: "screens.events.cancel_application_for_application",
      })}
    </SecondaryButton>
  );
};
