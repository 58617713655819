import { useIntl } from "react-intl";

import styles from "@/features/review/components/ImportReviewExcelButton/styles.module.scss";
import { ImportReviewExcelButtonProps } from "@/features/review/components/ImportReviewExcelButton/type";
import { TertiaryButton } from "@/shared/buttons/components/TertiaryButton";
import { UploadIcon } from "@/shared/icons/components/UploadIcon";

export const ImportReviewExcelButton = (
  props: ImportReviewExcelButtonProps,
) => {
  const intl = useIntl();
  const label = intl.formatMessage({
    id: "screen.label.excel_import",
  });

  return (
    <TertiaryButton
      className={styles.button}
      data-testid="import-review-excel-button"
      {...props}
    >
      <UploadIcon className={styles.button_icon} />
      <span className={styles.button_font}>{label}</span>
    </TertiaryButton>
  );
};
