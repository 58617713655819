// 未選択
export const BLANK = "BLANK";
//条件付き通過
export const CONDITIONAL_PASS = "CONDITIONAL_PASS";
//通過
export const PASS = "PASS";
//見送り
export const SEND_OFF = "SEND_OFF";
//保留
export const ON_HOLD = "ON_HOLD";
//その他
export const OTHER = "OTHER";

export const EXAMINATION_RESULTS = {
  BLANK,
  CONDITIONAL_PASS,
  PASS,
  SEND_OFF,
  ON_HOLD,
  OTHER,
} as const;
