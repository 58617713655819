import { useIntl } from "react-intl";
import { SingleValue } from "react-select";

import { Arrow } from "@/shared/icons/components/Arrow";
import { PlainSelect } from "@/shared/selectbox/components/PlainSelect";
import { SelectOption } from "@/shared/selectbox/types/SelectOption";

import styles from "./styles.module.scss";
import { PaginationProps } from "./type";

export const Pagination = ({
  page,
  limit,
  count,
  onChangeLimit,
  onNextPage,
  onPrevPage,
}: PaginationProps) => {
  const optionsMap = new Map<number, SelectOption<number>>([
    [10, { value: 10, label: "10" }],
    [30, { value: 30, label: "30" }],
    [50, { value: 50, label: "50" }],
    [100, { value: 100, label: "100" }],
    [1000, { value: 1000, label: "1000" }],
  ]);
  const options = [...optionsMap.values()];

  const startItemIndex = count <= 0 ? 0 : 1 + (page - 1) * limit;
  const endItemIndex = page * limit;
  // 件数と現在のページで表示される最後のアイテムのインデックスのうち小さい方を表示する。
  // 例: 件数が6件、ページが1、表示件数が10の場合、6を表示する
  const displayEndIndex = Math.min(count, endItemIndex);
  const hasNextPage = count > endItemIndex;
  const hasPrevPage = page > 1;

  const defaultValue = optionsMap.get(limit);

  const handleChangeLimit = (newValue: SingleValue<SelectOption<number>>) => {
    if (!newValue?.value) return;
    onChangeLimit(newValue.value);
  };

  const intl = useIntl();
  const displayRangeLabel = `${startItemIndex} - ${displayEndIndex} / ${count} ${intl.formatMessage(
    { id: "screen.label.count" },
  )}`;
  const displayByItemLabel = intl.formatMessage({
    id: "screen.label.display_by_item",
  });

  return (
    <div className={styles.pagination_container}>
      <button
        className={styles.pagination_button}
        onClick={onPrevPage}
        disabled={!hasPrevPage}
        data-testid="prev-page-button"
      >
        <Arrow
          direction="left"
          size="medium"
          color={hasPrevPage ? "black" : "gray"}
        />
      </button>
      <button
        className={styles.pagination_button}
        onClick={onNextPage}
        disabled={!hasNextPage}
        data-testid="next-page-button"
      >
        <Arrow
          direction="right"
          size="medium"
          color={hasNextPage ? "black" : "gray"}
        />
      </button>
      {displayRangeLabel}
      <PlainSelect<number>
        defaultValue={defaultValue}
        id="pagination-display-limit"
        options={options}
        menuPlacement="auto"
        onChange={handleChangeLimit}
      />
      {displayByItemLabel}
    </div>
  );
};
