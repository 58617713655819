import { Action } from "@reduxjs/toolkit";
import { createActions, createReducer } from "reduxsauce";
import Immutable from "seamless-immutable";

import { ToAction, ToActionType } from "./types";

/* ------------- Types and Action Creators ------------- */
type ThreadActionCreators = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  threadCreateRequest: (
    data: any,
    message: string,
  ) => Action<"THREAD_CREATE_REQUEST">;
  threadCreateSuccess: () => Action<"THREAD_CREATE_SUCCESS">;
  threadCreateFailure: () => Action<"THREAD_CREATE_FAILURE">;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  threadUpdateRequest: (
    data: any,
    message: string,
  ) => Action<"THREAD_UPDATE_REQUEST">;
  threadUpdateSuccess: () => Action<"THREAD_UPDATE_SUCCESS">;
  threadUpdateFailure: () => Action<"THREAD_UPDATE_FAILURE">;

  threadDeleteRequest: (
    data: any,
    message: string,
  ) => Action<"THREAD_DELETE_REQUEST">;
  threadDeleteSuccess: () => Action<"THREAD_DELETE_SUCCESS">;
  threadDeleteFailure: () => Action<"THREAD_DELETE_FAILURE">;

  threadGetsRequest: () => Action<"THREAD_GETS_REQUEST">;
  threadGetsSuccess: () => Action<"THREAD_GETS_SUCCESS">;
  threadGetsFailure: () => Action<"THREAD_GETS_FAILURE">;
};

type ThreadAction = ToAction<ThreadActionCreators>;
type ThreadActionType = ToActionType<ThreadAction>;

const { Types, Creators } = createActions<
  ThreadActionType,
  ThreadActionCreators
>({
  threadCreateRequest: ["data", "message"],
  threadCreateSuccess: null,
  threadCreateFailure: ["errors"],

  threadUpdateRequest: ["data", "message"],
  threadUpdateSuccess: null,
  threadUpdateFailure: ["errors"],

  threadDeleteRequest: ["data", "message"],
  threadDeleteSuccess: null,
  threadDeleteFailure: ["errors"],

  threadGetsRequest: ["data"],
  threadGetsSuccess: ["threadsPayload"],
  threadGetsFailure: ["errors"],
});

export const ThreadTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
type ThreadState = {
  fetching: boolean;
  errors: unknown;
  error?: unknown;
  threadsPayload: {
    rows: unknown[];
    count: number;
  };
};

export const INITIAL_STATE = Immutable<ThreadState>({
  fetching: false,
  errors: {},
  threadsPayload: {
    rows: [],
    count: 0,
  },
});

type ImmTeamState = typeof INITIAL_STATE;

/* ------------- Reducers ------------- */
const threadCreateRequest = (state: ImmTeamState) =>
  state.merge({ fetching: true });

const threadCreateSuccess = (state: ImmTeamState) => {
  return state.merge({ fetching: false });
};

// @ts-expect-error TS7031
const threadCreateFailure = (state: ImmTeamState, { error }) =>
  state.merge({ fetching: false, error });

const threadDeleteRequest = (state: ImmTeamState) =>
  state.merge({ fetching: true });

const threadDeleteSuccess = (state: ImmTeamState) => {
  return state.merge({ fetching: false });
};

// @ts-expect-error TS7031
const threadDeleteFailure = (state: ImmTeamState, { error }) =>
  state.merge({ fetching: false, error });

const threadGetsRequest = (state: ImmTeamState) =>
  state.merge({ fetching: true });

// @ts-expect-error TS7031
const threadGetsSuccess = (state: ImmTeamState, { threadsPayload }) => {
  return state.merge({ fetching: false, threadsPayload });
};

// @ts-expect-error TS7031
const threadGetsFailure = (state: ImmTeamState, { error }) =>
  state.merge({ fetching: false, error });

const threadUpdateRequest = (state: ImmTeamState) =>
  state.merge({ fetching: true });

const threadUpdateSuccess = (state: ImmTeamState) => {
  return state.merge({ fetching: false });
};

// @ts-expect-error TS7031
const threadUpdateFailure = (state: ImmTeamState, { error }) =>
  state.merge({ fetching: false, error });

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer<ImmTeamState, ThreadAction>(
  INITIAL_STATE,
  {
    [Types.THREAD_CREATE_REQUEST]: threadCreateRequest,
    [Types.THREAD_CREATE_SUCCESS]: threadCreateSuccess,
    [Types.THREAD_CREATE_FAILURE]: threadCreateFailure,

    [Types.THREAD_UPDATE_REQUEST]: threadUpdateRequest,
    [Types.THREAD_UPDATE_SUCCESS]: threadUpdateSuccess,
    [Types.THREAD_UPDATE_FAILURE]: threadUpdateFailure,

    [Types.THREAD_DELETE_REQUEST]: threadDeleteRequest,
    [Types.THREAD_DELETE_SUCCESS]: threadDeleteSuccess,
    [Types.THREAD_DELETE_FAILURE]: threadDeleteFailure,

    [Types.THREAD_GETS_REQUEST]: threadGetsRequest,
    [Types.THREAD_GETS_SUCCESS]: threadGetsSuccess,
    [Types.THREAD_GETS_FAILURE]: threadGetsFailure,
  },
);
