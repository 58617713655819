import { Card, Layout, Select } from "antd";
import React from "react";
import { useIntl } from "react-intl";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";

import { ReduxDispatch, ReduxState } from "@/Redux/types";

import {
  getGlobalPermissionForNews,
  NewsCommentItem,
} from "../../../../Components/organisms";
import ApiActions, {
  ApiRequestData,
  ApiSuccessCallback,
} from "../../../../Redux/ApiRedux";
import NewsActions from "../../../../Redux/NewsRedux";
import { endpoints, parseEndpoint } from "../../../../Services/endpoints";
import useLocalStorage from "../../../../utils/localStorage";
const PageSaveId = "GlobalNews";
const pageKey = `pagination_page_${PageSaveId}`;
const limitKey = `pagination_limit_${PageSaveId}`;
const { Content } = Layout;
const { Option } = Select;
type OwnProps = {
  history: Object;
};
type StateProps = {
  news: {
    // @ts-expect-error TS2314
    rows: Array;
    count: number;
  };
  auth: Object;
};
type ActionProps = {
  dispatchApiRequest: () => void;
  dispatchGetNews: (data: Object) => void;
  dispatchDelNews: (id: string) => void;
};
type Props = StateProps & ActionProps & OwnProps;
type NewsDetailProps = {
  match?: any;
  auth?: {
    payload?: any;
  };
  dispatchApiRequest?: (...args: any[]) => any;
  dispatchDelNews?: (...args: any[]) => any;
  dispatchGetNews?: (...args: any[]) => any;
  history?: {
    goBack?: (...args: any[]) => any;
    push?: (...args: any[]) => any;
  };
};
// @ts-expect-error TS2322
const NewsDetail: React.FC<NewsDetailProps> = (
  props: Props & NewsDetailProps,
) => {
  const [page, setPage] = useLocalStorage(pageKey, 1);
  const [limit, setLimit] = useLocalStorage(
    limitKey,
    process.env.REACT_APP_PAGE_SIZE,
  );
  const intl = useIntl();
  const [companies, setCompanies] = React.useState([]);
  const [item, setItem] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [companyId, setCompanyId] = React.useState(null);
  const dispatch = useDispatch();
  const [itemId] = React.useMemo(() => {
    const {
      match: { params },
    } = props;
    const itemId = params.id ? params.id : -1;
    return [itemId];
  }, [props]);
  React.useEffect(() => {
    if (itemId && itemId !== -1) {
      setLoading(true);
      getNewsItem();
      getCompanies();
    }
  }, [itemId]);
  const getCompanies = () => {
    const requestBuilder: ApiRequestData = {
      method: "getRequest",
      url: parseEndpoint(endpoints.companies),
      data: {},
    };
    dispatch(
      ApiActions.apiRequest(requestBuilder, ({ data }) => {
        //@ts-expect-error 要API型定義
        if (data.rows && data.rows.length > 0) {
          //@ts-expect-error 要API型定義
          setCompanies(data.rows);
        }
      }),
    );
  };
  const getNewsItem = () => {
    const requestBuilder: ApiRequestData = {
      method: "getRequest",
      url: parseEndpoint(endpoints.updateNews, { id: itemId }),
    };
    dispatch(
      ApiActions.apiRequest(requestBuilder, ({ data }) => {
        setLoading(false);
        //@ts-expect-error 要API型定義
        setItem(data);
      }),
    );
  };
  // @ts-expect-error TS7006
  const onDeleteItem = (id) => {
    props.dispatchDelNews(id);
    // @ts-expect-error TS2722
    props.history.goBack();
  };
  // @ts-expect-error TS7006
  const onUpdateItem = (id) => {
    // @ts-expect-error TS2722
    props.history.push(`/global-library/news-update/${id}`);
  };
  // @ts-expect-error TS7006
  const onLikeItem = (commentId, isMain) => {
    const requestBuilder = {
      method: "postRequest",
      url: isMain
        ? parseEndpoint(endpoints.likeNews, {
            // @ts-expect-error TS18047
            newsId: item.id,
          })
        : parseEndpoint(endpoints.likeNewsComment, {
            // @ts-expect-error TS18047
            newsId: item.id,
            commentId,
          }),
    };
    // @ts-expect-error TS7031
    props.dispatchApiRequest(requestBuilder, ({ data }) => {
      props.dispatchGetNews({
        page: page - 1,
        limit,
        isGlobal: true,
      });
    });
  };
  const [selectableCompanies, selectCompanyId] = React.useMemo(() => {
    let selectableCompanies = companies,
      selectCompanyId = null;
    // @ts-expect-error TS2339
    if (item && !item.isGlobalNews && item.permittedCompanies.length > 0) {
      selectableCompanies = companies.filter((a) =>
        // @ts-expect-error TS2339
        item.permittedCompanies.includes(a.id),
      );
    }
    if (companyId) {
      selectCompanyId = companyId;
    } else {
      if (selectableCompanies.length > 0) {
        // @ts-expect-error TS2339
        selectCompanyId = selectableCompanies[0].id;
      }
    }
    return [selectableCompanies, selectCompanyId];
  }, [companies, item, companyId]);
  return (
    <Content>
      <Card className="library-news">
        <div style={{ maxWidth: 640 }}>
          <Select
            style={{ minWidth: 300 }}
            value={selectCompanyId}
            placeholder={intl.formatMessage({
              id: "screen.label.post_company",
            })}
            onChange={(value) => {
              setCompanyId(value);
            }}
          >
            {selectableCompanies.map((a) => (
              // @ts-expect-error TS2339
              <Option key={a.id} value={a.id}>
                {/*
                 // @ts-expect-error TS2339 */}
                {a.name}
              </Option>
            ))}
          </Select>
          {item && (
            <NewsCommentItem
              // @ts-expect-error TS2339
              key={item.id}
              // @ts-expect-error TS2322
              comment={item}
              isGlobal={true}
              isCanEditEmoji={false}
              isCanEdit={getGlobalPermissionForNews(props.auth.payload)}
              companyId={selectCompanyId}
              user={props.auth.payload}
              onUpdate={onUpdateItem}
              onDelete={onDeleteItem}
              onLike={onLikeItem}
            />
          )}
        </div>
      </Card>
    </Content>
  );
};
const mapStateToProps = (state: ReduxState) => ({
  auth: state.auth,
});
const mapDispatchToProps = (dispatch: ReduxDispatch) => ({
  // @ts-expect-error TS7006
  dispatchGetNews: (data) => dispatch(NewsActions.newsGetRequest(data)),
  // @ts-expect-error TS7006
  dispatchDelNews: (id) => dispatch(NewsActions.newsDeleteRequest(id)),
  dispatchApiRequest: (data: ApiRequestData, callback: ApiSuccessCallback) =>
    dispatch(ApiActions.apiRequest(data, callback)),
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NewsDetail),
);
