import { notification } from "antd";
import { EditorState } from "draft-js";
import { useEffect, useRef } from "react";
import { useIntl } from "react-intl";

import { uploadImageWithDraftEditor } from "../../../Components/molecules";
import { endpoints, parseEndpoint } from "../../../Services/endpoints";
import {
  convertFromRawWithTrimmer,
  convertToRawWithTrimmer,
  getObjUUID,
  sanitizeEditorStateMarkupHtml,
} from "../../../utils/common";
import { usePrevious, useStateWithRef } from "../../../utils/hooks";
import { getInitStorageValue } from "../../../utils/localStorage";
import {
  IProjectGeneralNoticeScreenProps,
  ProjectGeneralNoticeScreenState,
} from "../types/IProjectGeneralNoticeScreenProps";

const PageSaveId = "ProjectGeneralNoticeScreen";
const pageKey = `pagination_page_${PageSaveId}`;
const limitKey = `pagination_limit_${PageSaveId}`;
const PageDraftSaveId = "ProjectGeneralNoticeScreen_Draft";
const draftPageKey = `pagination_page_${PageDraftSaveId}`;
const draftLimitKey = `pagination_limit_${PageDraftSaveId}`;

const useProjectGeneralNoticeScreen = (
  props: IProjectGeneralNoticeScreenProps,
) => {
  const intl = useIntl();

  const { company, user, apiRequest, updatePageTitle } = props.context;
  const [state, customSetState, stateRef] = useStateWithRef(() => {
    return {
      metaType: "",
      autoSaveNotificationId: 0,
      sendEmail: false,
      page: getInitStorageValue(pageKey, 1),
      limit: getInitStorageValue(limitKey, process.env.REACT_APP_PAGE_SIZE),
      draftPage: getInitStorageValue(draftPageKey, 1),
      draftLimit: getInitStorageValue(
        draftLimitKey,
        process.env.REACT_APP_PAGE_SIZE,
      ),
      visible: false,
      title: "",
      editorState: EditorState.createEmpty(),
      description: "",
      userTags: [],
      notifications: {
        rows: [],
        count: 0,
      },
      draftNotifications: {
        rows: [],
        count: 0,
      },
      type: "general_notice",
      modalTitle: "",
      modalDesc: "",
      modalLink: "",
      modalVisible: false,
      eventDetail: {
        name: "",
        description: "",
        id: "",
        uuid: "",
        nodalData: false,
        beginsAt: new Date(),
        isMemberStatus: null,
      },
      noticeDetails: {
        noticeType: "",
        title: "",
        description: "",
        id: "",
        nodalData: false,
        meta: {
          type: "",
        },
      },
    };
  });
  const setStateCallbackRef = useRef(() => {});
  useEffect(() => {
    const callBack = setStateCallbackRef.current;
    callBack();
    setStateCallbackRef.current = () => {};
  }, [state]);
  const setState = (
    data: ProjectGeneralNoticeScreenState,
    callback = () => {},
  ) => {
    setStateCallbackRef.current = callback;
    // @ts-expect-error TS7006
    customSetState((previousState) => {
      return {
        ...previousState,
        ...data,
      };
    });
  };

  useEffect(() => {
    updatePageTitle("screen.label.notification");
    // @ts-expect-error TS18048
    if (`${props.project.id}` !== `0`) {
      getNotificationsRequestion();
      getDraftNotificationsRequestion();
    }
  }, []);

  const prevProps = usePrevious(props);
  useEffect(() => {
    if (prevProps?.context.company?.userTags !== company.userTags) {
      setState({
        userTags: Array.isArray(company.userTags) ? company.userTags : [],
      });
    }
    if (props.project !== prevProps?.project) {
      // @ts-expect-error TS18048
      if (`${props.project.id}` !== `0`) {
        getNotificationsRequestion();
        getDraftNotificationsRequestion();
      }
    }
  });

  const getNotificationsRequestion = () => {
    const { page, limit, metaType } = stateRef.current;
    const notificationRequest = {
      method: "getRequest",
      url: `notifications?page=${
        page - 1
      }&limit=${limit}&notificationType=other&draftState=1&projectId=${
        // @ts-expect-error TS18048
        props.project.id
        // @ts-expect-error TS18048
      }&filter=projectId eq ${props.project.id}&${metaType}`,
      data: {},
    };
    // @ts-expect-error TS2345
    apiRequest(notificationRequest, ({ data }) => {
      setState({
        notifications: data,
      });
    });
  };
  const getDraftNotificationsRequestion = () => {
    const { draftPage, draftLimit } = stateRef.current;
    const notificationRequest = {
      method: "getRequest",
      url: `notifications?page=${
        draftPage - 1
      }&limit=${draftLimit}&notificationType=other&draftState=0&projectId=${
        // @ts-expect-error TS18048
        props.project.id
        // @ts-expect-error TS18048
      }&filter=projectId eq ${props.project.id}`,
      data: {},
    };
    // @ts-expect-error TS2345
    apiRequest(notificationRequest, ({ data }) => {
      setState({
        draftNotifications: data,
      });
    });
  };
  // @ts-expect-error TS7006
  const handleChange = (key, value) => {
    setState({ [key]: value }, () => saveAsDraft());
  };
  const validCreateNotification = () => {
    if (stateRef.current.title.trim().length <= 0) {
      notification.warning({
        message: intl.formatMessage({
          id: "screen.label.enter_title",
        }),
      });
      return false;
    }
    return true;
  };
  const saveAsDraft = () => {
    if (!validCreateNotification()) {
      return;
    }
    if (stateRef.current.type === "poll") {
      const notificationRequest = {
        method: "postRequest",
        url: parseEndpoint(endpoints.notificationsDraft),
        data: {
          sendEmail: stateRef.current.sendEmail,
          notificationType: "other",
          // @ts-expect-error TS18048
          projectId: props.project.id,
          senderId: user.id,
          actionData: {},
          actionText: stateRef.current.title,
          meta: {
            type: "poll",
            // @ts-expect-error TS18048
            projectId: props.project.id,
            videoUrl: stateRef.current.videoUrl,
          },
          message: `${stateRef.current.description}`,
          draftState: 0,
          autoSaveNotificationId: stateRef.current.autoSaveNotificationId,
          nodalData: false,
        },
      };
      if (stateRef.current.isLoadingSaveDraft) {
        return;
      }
      setState({
        isLoadingSaveDraft: true,
      });
      // @ts-expect-error TS2345
      apiRequest(notificationRequest, ({ data }) => {
        // @ts-expect-error TS2339
        const { id } = data;
        setState({
          autoSaveNotificationId: id,
          isLoadingSaveDraft: false,
        });
        getDraftNotificationsRequestion();
        getNotificationsRequestion();
      });
    } else {
      const content = convertToRawWithTrimmer(stateRef.current.editorState);
      const notificationRequest = {
        method: "postRequest",
        url: parseEndpoint(endpoints.notificationsDraft),
        data: {
          sendEmail: stateRef.current.sendEmail,
          // @ts-expect-error TS18048
          projectId: props.project.id,
          senderId: user.id,
          actionData: {},
          nodalData: true,
          actionText: stateRef.current.title,
          meta: {
            type: "general_notice",
            // @ts-expect-error TS18048
            projectId: props.project.id,
            videoUrl: stateRef.current.videoUrl,
          },
          notificationType: "other",
          message: content,
          draftState: 0,
          autoSaveNotificationId: stateRef.current.autoSaveNotificationId,
        },
      };
      if (stateRef.current.isLoadingSaveDraft) {
        return;
      }
      setState({
        isLoadingSaveDraft: true,
      });
      // @ts-expect-error TS2345
      apiRequest(notificationRequest, ({ data }) => {
        // @ts-expect-error TS2339
        const { id } = data;
        setState({
          autoSaveNotificationId: id,
          isLoadingSaveDraft: false,
        });
        getDraftNotificationsRequestion();
        getNotificationsRequestion();
      });
    }
  };
  // @ts-expect-error TS7006
  const submitNotice = (e) => {
    setState({ visible: false });
    if (stateRef.current.type === "poll") {
      if (
        stateRef.current.title.trim().length > 0 &&
        stateRef.current.description.trim().length > 0
      ) {
        const notificationRequest = {
          method: "postRequest",
          url: parseEndpoint(endpoints.createNotifications),
          successMessage: intl.formatMessage({
            id: "screen.label.sent_notification",
          }),
          data: {
            autoSaveNotificationId: stateRef.current.autoSaveNotificationId,
            sendEmail: stateRef.current.sendEmail,
            notificationType: "other",
            // @ts-expect-error TS18048
            projectId: props.project.id,
            senderId: user.id,
            actionData: {},
            // @ts-expect-error TS18048
            actionText: `${props.project.name}: ${stateRef.current.title}`,
            meta: {
              type: "poll",
              // @ts-expect-error TS18048
              projectId: props.project.id,
              videoUrl: stateRef.current.videoUrl,
              sendEmailCount: stateRef.current.sendEmail ? 1 : 0,
            },
            message: `${stateRef.current.description}`,
            draftState: 1,
            nodalData: false,
          },
        };
        // @ts-expect-error TS2345
        apiRequest(notificationRequest, () => {
          onModalCancelled();
          getNotificationsRequestion();
          setState({ sendEmail: false });
        });
      } else {
        notification.error({
          message: intl.formatMessage({
            id: "screen.label.title_please_enter_url",
          }),
        });
      }
    } else if (stateRef.current.type === "general_notice") {
      if (stateRef.current.title.trim().length > 0) {
        const content = convertToRawWithTrimmer(stateRef.current.editorState);
        const notificationRequest = {
          method: "postRequest",
          url: parseEndpoint(endpoints.createNotifications),
          successMessage: intl.formatMessage({
            id: "screen.label.sent_notification",
          }),
          data: {
            autoSaveNotificationId: stateRef.current.autoSaveNotificationId,
            sendEmail: stateRef.current.sendEmail,
            // @ts-expect-error TS18048
            projectId: props.project.id,
            senderId: user.id,
            actionData: {},
            nodalData: true,
            // @ts-expect-error TS18048
            actionText: `${props.project.name}: ${stateRef.current.title}`,
            meta: {
              type: "general_notice",
              // @ts-expect-error TS18048
              projectId: props.project.id,
              videoUrl: stateRef.current.videoUrl,
              sendEmailCount: stateRef.current.sendEmail ? 1 : 0,
            },
            notificationType: "other",
            message: content,
            draftState: 1,
          },
        };
        // @ts-expect-error TS2345
        apiRequest(notificationRequest, () => {
          onModalCancelled();
          getNotificationsRequestion();
          setState({ sendEmail: false });
        });
      } else {
        notification.error({
          message: intl.formatMessage({
            id: "screen.label.title_required",
          }),
        });
      }
    }
  };

  const uploadImageCallBack = (file: any) => {
    return new Promise((resolve, reject) => {
      uploadImageWithDraftEditor({
        file,
        companyId: getObjUUID(company),
        authToken: user.token,
        subLink: "notification",
        headerDetail: {
          type: "notification",
          projectId: getProjectId(),
        },
      })
        .then((response: any) => {
          resolve(response);
        })

        .catch((error) => {
          reject(error);
        });
    });
  };
  const getProjectId = () => {
    const {
      match: { params },
    } = props;
    return params.id ? params.id : -1;
  };
  // @ts-expect-error TS7006
  const onEditorStateChange = (editorState) => {
    setState(
      {
        editorState,
      },
      () => saveAsDraft(),
    );
  };

  const onModalCancelled = () => {
    setState(
      {
        visible: false,
        title: " ",
        description: "",
        editorState: EditorState.createEmpty(),
        modalTitle: "",
        modalDesc: "",
        modalLink: "",
        modalVisible: false,
        videoUrl: "",
        eventDetail: {
          name: "",
          description: "",
          id: "",
          nodalData: false,
          beginsAt: new Date(),
          isMemberStatus: null,
        },
        noticeDetails: {
          noticeType: "",
          title: "",
          description: "",
          id: "",
          nodalData: false,
          meta: {
            type: "",
          },
        },
      },
      () => {
        getDraftNotificationsRequestion();
        getNotificationsRequestion();
      },
    );
  };
  // @ts-expect-error TS7006
  const onTakeActionNoticeClicked = (notification, noticeType) => {
    const noticeDetails = {
      noticeType,
      title: notification.actionText,
      description: notification.message,
      id: notification.id,
      meta: notification.meta,
      nodalData: notification.nodalData,
    };
    let editNode = null;
    try {
      editNode = sanitizeEditorStateMarkupHtml(
        EditorState.createWithContent(
          convertFromRawWithTrimmer(notification.message),
        ),
      );
    } catch (e) {}
    const state = {
      noticeDetails,
    };
    if (editNode !== null) {
      // @ts-expect-error TS7053
      state["editorState"] = editNode;
    }
    setState(state);
  };
  // @ts-expect-error TS7006
  const onDeleteNotification = (notification) => {
    const requestBuilder = {
      method: "deleteRequest",
      url: parseEndpoint(endpoints.updateNotification, {
        id: notification.id,
      }),
      data: {},
    };
    // @ts-expect-error TS2345
    apiRequest(requestBuilder, ({ data }) => {
      getNotificationsRequestion();
      getDraftNotificationsRequestion();
    });
  };
  // @ts-expect-error TS7006
  const onTakeActionOnNotification = (notificationData, id) => {
    const message = intl.formatMessage({
      id: "screen.label.notification_information_acquired",
    });
    // @ts-expect-error TS2722
    props.dispatchTakeActionOnNotification(notificationData, id, message);
  };
  // @ts-expect-error TS7006
  const onTakeActionRegisterEvent = (eventDetail) => {
    getIsEventMember(eventDetail);
    setState({
      eventDetail,
    });
  };
  // @ts-expect-error TS7006
  const getIsEventMember = (eventDetail) => {
    const getIsEventMemberRequestData = {
      method: "getRequest",
      url: parseEndpoint(endpoints.findEventMember, {
        eventId: getObjUUID(eventDetail),
        userId: user.id,
      }),
      data: {},
    };
    // @ts-expect-error TS2345
    apiRequest(getIsEventMemberRequestData, ({ data }) => {
      const newEventDettail = {
        ...eventDetail,
        isMemberStatus: data !== null,
      };
      setState({
        eventDetail: newEventDettail,
      });
    });
  };
  // @ts-expect-error TS7006
  const onPromptModal = (modalEnv) => {
    setState({
      modalTitle: modalEnv.name,
      modalDesc: modalEnv.description,
      modalLink: modalEnv.uuid,
      modalVisible: true,
    });
  };
  const onCloseNoticeModal = () => {
    setState({
      // @ts-expect-error TS2741
      noticeDetails: {
        noticeType: "",
        title: "",
        description: "",
        id: "",
        meta: {
          type: "",
        },
      },
    });
  };
  // @ts-expect-error TS7006
  const handleCancel = (e) => {
    setState({
      modalVisible: false,
    });
  };
  const onCloseRegisterEventModal = () => {
    setState({
      eventDetail: {
        name: "",
        description: "",
        id: "",
        // @ts-expect-error TS2322
        uuid: "",
        isMemberStatus: null,
        beginsAt: new Date(),
      },
    });
  };
  const onRegisterForEvent = () => {
    const eventRequestData = {
      method: "postRequest",
      url: parseEndpoint(endpoints.getEventUsers, {
        data: {
          eventId: getObjUUID(stateRef.current.eventDetail),
        },
        queryParams: "",
      }),
      successMessage: intl.formatMessage({
        id: "screen.label.apply_participation_event",
      }),
      data: {
        userId: user.id,
        eventId: stateRef.current.eventDetail.id,
      },
    };
    // @ts-expect-error TS2345
    apiRequest(eventRequestData, () => {});
    onCloseRegisterEventModal();
  };
  const onUnRegisterEvent = () => {
    const eventRequestData = {
      method: "deleteRequest",
      url: parseEndpoint(endpoints.findEventMember, {
        eventId: getObjUUID(stateRef.current.eventDetail),
        userId: user.id,
      }),
      successMessage: intl.formatMessage({
        id: "screen.label.canceled_participate_event",
      }),
      data: {},
    };
    // @ts-expect-error TS2345
    apiRequest(eventRequestData, () => {});
    onCloseRegisterEventModal();
  };

  return {
    state,
    setState,
    intl,
    uploadImageCallBack,
    onEditorStateChange,
    handleChange,
    getNotificationsRequestion,
    onDeleteNotification,
    onTakeActionNoticeClicked,
    onPromptModal,
    onTakeActionOnNotification,
    onTakeActionRegisterEvent,
    getDraftNotificationsRequestion,
    onModalCancelled,
    saveAsDraft,
    submitNotice,
    onCloseRegisterEventModal,
    onUnRegisterEvent,
    onRegisterForEvent,
    onCloseNoticeModal,
    handleCancel,
    validCreateNotification,
  };
};

export default useProjectGeneralNoticeScreen;
