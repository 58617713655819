import { DownOutlined } from "@ant-design/icons";
import { Button, Col, Menu, PageHeader, Row, Select, Tag } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import { getObjUUID, getUserAvatar } from "../../../utils/common";
import {
  adminUser,
  generalUser,
  mentorUser,
  programManager,
} from "../../../utils/constants";
import * as Icons from "../../atoms/icons";
import { NavbarUserProps } from "./types";

const { SubMenu } = Menu;
const { Option } = Select;

const NavbarUser: React.FC<NavbarUserProps> = ({
  sideBarOption,
  onToggleDrawer,
  onSwitchCompanies,
  userDetails,
  logoutUser,
  pageTitle,
  userCompanies,
  showSwitchLang = true,
}: NavbarUserProps) => {
  const getProgramType = () => {
    if (userDetails && userDetails.isSuper) {
      return adminUser;
    } else if (userDetails && userDetails.type === "admin") {
      return programManager;
    } else if (userDetails && userDetails.type === "mentor") {
      return mentorUser;
    } else if (userDetails && userDetails.type === "employee") {
      return generalUser;
    }
  };
  const onCompanyChanged = (companyId: any) => {
    // @ts-expect-error TS2722
    onSwitchCompanies(companyId);
  };
  const _renderNavbarButton = () => {
    if (sideBarOption) {
      return sideBarOption === "fixed" ? null : (
        <Button
          onClick={() => {
            if (onToggleDrawer) {
              onToggleDrawer();
            }
          }}
          icon={Icons.IconMenu}
        ></Button>
      );
    }
  };
  const getLogoutUrl = () => {
    return "/";
  };
  const userCompaniesComponent =
    Array.isArray(userCompanies) &&
    userCompanies.length > 1 &&
    userDetails.company.loginType !== "ip" ? (
      <Select
        key={`companySelect_${getObjUUID(userDetails.company)}`}
        showSearch
        style={{ width: 300 }}
        optionFilterProp="children"
        onChange={onCompanyChanged}
        filterOption={(input, option) =>
          // @ts-expect-error TS18048
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        defaultValue={getObjUUID(userDetails.company).toString()}
      >
        {userCompanies.map((company, index) => {
          return (
            <Option
              key={index}
              value={getObjUUID(company).toString()}
              id={`companySelect_${getObjUUID(company).toString()}`}
            >
              {/*
               // @ts-expect-error TS2339 */}
              {company.name}
            </Option>
          );
        })}
      </Select>
    ) : (
      <></>
    );
  return (
    <Menu
      theme="light"
      mode="horizontal"
      style={{ lineHeight: "73px", paddingRight: "0" }}
    >
      <Row align="middle" justify="start" style={{ width: "100%" }}>
        <Col flex={1}>
          <Row>
            {_renderNavbarButton()}
            {showSwitchLang && (
              <Menu.Item className="pagetitle" key="-11">
                <PageHeader
                  title={
                    <FormattedMessage
                      id={pageTitle}
                      defaultMessage={pageTitle}
                    />
                  }
                />
              </Menu.Item>
            )}

            <Menu.Item className="pagetitle" key="-1102">
              {userCompaniesComponent}
            </Menu.Item>
          </Row>
        </Col>
        <SubMenu
          style={{ float: "right" }}
          className="pagetitle"
          title={
            <span className="submenu-title-wrapper">
              <Tag className="user-type-tag" id={"programtype"}>
                {getProgramType()}
              </Tag>
              {getUserAvatar(userDetails, () => {}, {
                width: 30,
                height: 30,
                marginLeft: 10,
                marginRight: 10,
              })}
              <span style={{ color: "#323232" }}>{userDetails.name}</span>
              <DownOutlined style={{ marginLeft: 10, color: "#323232" }} />
            </span>
          }
        >
          <Menu.Item key="setting:1" id="navbar.screen.label.account_setting">
            <Link to="/dashboard/profile">
              <FormattedMessage id="screen.label.account_setting" />
            </Link>
          </Menu.Item>
          <Menu.Item
            key="/dashboard/email-subscription/"
            id="navbar.screen.label.mail_settings"
          >
            <Link to="/dashboard/email-subscription/">
              <FormattedMessage id="screen.label.mail_settings" />
            </Link>
          </Menu.Item>
          <Menu.Item
            key="/dashboard/watch-subscription/"
            id="navbar.screen.label.watch_setting"
          >
            <Link to="/dashboard/watch-subscription/">
              <FormattedMessage id="screen.label.watch_setting" />
            </Link>
          </Menu.Item>
          <Menu.Item
            key="/dashboard/lang-setting/"
            id="navbar.screen.label.lang_setting"
          >
            <Link to="/dashboard/lang-setting/">
              <FormattedMessage id="screen.label.lang_setting" />
            </Link>
          </Menu.Item>
          <Menu.Item key="setting:2" id="navbar.screen.label.log_out">
            <a href={getLogoutUrl()} onClick={logoutUser}>
              <FormattedMessage id="screen.label.log_out" />
            </a>
          </Menu.Item>
        </SubMenu>
      </Row>
    </Menu>
  );
};
export default NavbarUser;
