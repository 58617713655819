import { forwardRef, Ref } from "react";

import { PlainInput } from "../PlainInput";
import styles from "./styles.module.scss";
import { InputWithIconProps } from "./type";

export const InputWithIcon = forwardRef(
  (
    { Icon, className, ...inputProps }: InputWithIconProps,
    ref?: Ref<HTMLInputElement>,
  ) => {
    return (
      <div className={styles.input_wrapper}>
        <Icon className={styles.input_icon} />
        <PlainInput
          className={`${styles.input} ${className}`}
          {...inputProps}
          ref={ref}
        />
      </div>
    );
  },
);
