import { Team } from "../../../../types/Team";
import { User } from "../../../../types/User";

type UseUserTeamStatusProps = {
  user: User;
  team: Team[];
  project: {
    isProjectOwner: boolean;
    isTeamMember: boolean;
  } & unknown;
};
export const useUserTeamStatus = ({
  user,
  team,
  project,
}: UseUserTeamStatusProps) => {
  const isOwner =
    project.isProjectOwner || (user.type === "admin" && user.isSuper);

  const isTeamMember =
    project.isTeamMember || (user.type === "admin" && user.isSuper);

  const isWaitingCurrentUserApproval = team.some(
    (member) =>
      member.userId === user.id &&
      member.userStatus === 0 && // INVITATION_WAITING
      member.status === 1, // APPLICATION_APPROVED
  );

  const isWaitingProjectOwnerApproval = team.some(
    (member) =>
      member.userId === user.id &&
      member.userStatus === 1 && // INVITATION_APPROVED
      member.status === 0, // APPLICATION_WAITING
  );

  return {
    isOwner,
    isTeamMember,
    isWaitingCurrentUserApproval,
    isWaitingProjectOwnerApproval,
  };
};
