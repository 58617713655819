import { Card, Layout } from "antd";
import React from "react";
import { useDispatch } from "react-redux";

import { NewsCreateForm, NewsFormData } from "../../../../Components/organisms";
import ApiActions, { ApiRequestData } from "../../../../Redux/ApiRedux";
import { endpoints, parseEndpoint } from "../../../../Services/endpoints";
import createFormData from "../../../../utils/createFormData";
const { Content } = Layout;
type Props = {
  match: Object;
};
function NewsUpdate(props: Props) {
  const [item, setItem] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [companies, setCompanies] = React.useState([]);
  const dispatch = useDispatch();
  const [itemId] = React.useMemo(() => {
    const {
      // @ts-expect-error TS2339
      match: { params },
    } = props;
    const itemId = params.id ? params.id : -1;
    return [itemId];
  }, [props]);
  React.useEffect(() => {
    if (itemId && itemId !== -1 && itemId !== "") {
      setLoading(true);
      getNewsItem();
    }
  }, [itemId]);
  const getNewsItem = () => {
    const requestBuilder: ApiRequestData = {
      method: "getRequest",
      url: parseEndpoint(endpoints.updateNews, { id: itemId }),
    };
    dispatch(
      ApiActions.apiRequest(requestBuilder, ({ data }) => {
        setLoading(false);
        //@ts-expect-error 要API型定義
        setItem(data);
      }),
    );
  };
  React.useEffect(() => {
    getCompanies();
  }, []);
  const getCompanies = () => {
    const requestBuilder: ApiRequestData = {
      method: "getRequest",
      url: parseEndpoint(endpoints.companies),
      data: {},
    };
    dispatch(
      ApiActions.apiRequest(requestBuilder, ({ data }) => {
        //@ts-expect-error 要API型定義
        if (data.rows && data.rows.length > 0) {
          //@ts-expect-error 要API型定義
          const { libraryEnables } = data;
          const parseLibraryEnable = Array.isArray(libraryEnables)
            ? libraryEnables.reduce((s, c) => {
                return {
                  ...s,
                  [c.companyId]: {
                    isNewsEnabled: c.isNewsEnabled,
                  },
                };
              }, {})
            : {};
          //@ts-expect-error 要API型定義
          const tempComp = Array.isArray(data.rows)
            ? // @ts-expect-error TS7006
              data.rows.filter((c) => {
                return (
                  parseLibraryEnable[c.id]?.isNewsEnabled ||
                  typeof parseLibraryEnable[c.id] === "undefined"
                );
              })
            : [];
          setCompanies(tempComp);
        }
      }),
    );
  };
  const handleSubmit = (data: NewsFormData) => {
    setLoading(true);
    const requestBuilder: ApiRequestData = {
      method: "putRequest",
      url: parseEndpoint(endpoints.updateNews, {
        id: itemId,
      }),
      data: createFormData(data),
    };
    dispatch(
      ApiActions.apiRequest(requestBuilder, ({ data }) => {
        setLoading(false);
        window.history.back();
      }),
    );
  };
  return (
    <Content>
      <Card>
        <NewsCreateForm
          item={item}
          // @ts-expect-error TS2322
          user={null}
          companies={companies}
          onSubmit={handleSubmit}
          isModal={false}
          loading={loading}
          isGlobal={true}
        />
      </Card>
    </Content>
  );
}
export default NewsUpdate;
