import { useState } from "react";

import { useConfirmExecution } from "../../../utils/useConfirmExecution";

export const useFileUpload = () => {
  const { confirmExecution, execute, notExecute } = useConfirmExecution();
  const [selectedFile, setSelectedFile] = useState<File>();

  const beforeUpload = async (file: File) => {
    setSelectedFile(file);
    return await confirmExecution();
  };
  const handleUpload = () => {
    setSelectedFile(undefined);
    execute();
  };
  const handleUploadCancel = () => {
    setSelectedFile(undefined);
    notExecute();
  };

  return {
    selectedFile,
    beforeUpload,
    handleUpload,
    handleUploadCancel,
  };
};
