import { LeftOutlined } from "@ant-design/icons";
import { Card, Layout, Row } from "antd";
import isEmpty from "lodash/isEmpty";
import { useContext, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link, useParams } from "react-router-dom";

import CustomLoader from "@/Components/atoms/CustomLoader";
import { ReferenceThumbImage } from "@/features/reference/components/ReferenceThumbImage";

import { ReferenceType } from "../../../../types/Reference";
import {
  CopyButton,
  Description,
  LeftArrowComponent,
} from "../../../Components/atoms";
import { ReferenceThumbMenuBar } from "../../../Components/molecules";
import { ReferenceDetailButtons } from "../../../Components/organisms";
import { ContentContext } from "../../../Provider";
import useReferenceRequest from "../../../Services/references/useReferenceRequest";
import { CompanyReferenceNotFoundCard } from "./CompanyReferenceNotFoundCard";
const { Content } = Layout;
type ReferenceDetailProps = {
  referenceType: ReferenceType;
};
type Params = {
  id: string;
};

const CompanyReferenceDetailScreen = (props: ReferenceDetailProps) => {
  const { updatePageTitle } = useContext(ContentContext);
  const intl = useIntl();
  const { id: referenceId } = useParams<Params>();
  const { reference, error, referenceMutate, isValidating } =
    useReferenceRequest(referenceId);
  useEffect(() => {
    updatePageTitle(`screen.label.${props.referenceType}_reference`);
  }, [props.referenceType, updatePageTitle]);
  useEffect(() => {
    // TODO: 現状のupdateReference がSWRのキャッシュを更新してくれないので、unmount時にRevalidationする
    // See: https://swr.vercel.app/docs/mutation#revalidation
    () => referenceMutate();
  });

  if (isValidating) {
    return <CustomLoader />;
  }

  return (
    // 70px is footer size
    <Content style={{ height: "calc(100% - 70px)" }}>
      <LeftArrowComponent>
        <Link to={`/dashboard/reference-${props.referenceType}`}>
          <LeftOutlined />
          <span>
            <FormattedMessage
              id={`screen.label.${props.referenceType}_reference_list`}
            />
          </span>
        </Link>
      </LeftArrowComponent>
      {isEmpty(reference) || error ? (
        <CompanyReferenceNotFoundCard />
      ) : (
        <Card className="library-news">
          <Row align="middle" justify="center">
            <div style={{ width: "50vw", marginTop: "60px" }}>
              <Row align="middle" justify="center">
                <ReferenceThumbMenuBar>
                  <CopyButton
                    text={window.location.href}
                    message={intl.formatMessage({
                      id: "screen.label.url_copied",
                    })}
                  />
                </ReferenceThumbMenuBar>
                <ReferenceThumbImage
                  borderRadius={0}
                  thumbnailUrl={reference.thumbnailUrl}
                />
              </Row>
              <Row align="middle" style={{ marginTop: "36px" }}>
                <h2>{reference.fileName}</h2>
              </Row>
              <Row align="middle" style={{ marginTop: "12px" }}>
                <Description description={reference.description} />
              </Row>
              <Row
                align="middle"
                justify="center"
                style={{ marginTop: "40px" }}
              >
                <ReferenceDetailButtons
                  reference={reference}
                  referenceType={props.referenceType}
                />
              </Row>
            </div>
          </Row>
        </Card>
      )}
    </Content>
  );
};

export default CompanyReferenceDetailScreen;
