import { Card, Layout } from "antd";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { ReduxDispatch, ReduxState } from "@/Redux/types";

import {
  getPermissionForNews,
  NewsCommentItem,
} from "../../../../Components/organisms";
import ApiActions, {
  ApiRequestData,
  ApiSuccessCallback,
} from "../../../../Redux/ApiRedux";
import NewsActions from "../../../../Redux/NewsRedux";
import { endpoints, parseEndpoint } from "../../../../Services/endpoints";
import useLocalStorage from "../../../../utils/localStorage";
const PageSaveId = "CompanyNews";
const pageKey = `pagination_page_${PageSaveId}`;
const limitKey = `pagination_limit_${PageSaveId}`;
const { Content } = Layout;
type otherProps = {
  match: Object;
  company: Object;
};
type OwnProps = {
  history: Object;
};
type StateProps = {
  news: {
    // @ts-expect-error TS2314
    rows: Array;
    count: number;
  };
  auth: Object;
};
type ActionProps = {
  dispatchApiRequest: () => void;
  dispatchGetNews: (data: Object) => void;
  dispatchDelNews: (id: string) => void;
};
type Props = StateProps & ActionProps & OwnProps & otherProps;
function NewsDetail(props: Props) {
  const [page, setPage] = useLocalStorage(pageKey, 1);
  const [limit, setLimit] = useLocalStorage(
    limitKey,
    process.env.REACT_APP_PAGE_SIZE,
  );
  const [item, setItem] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [itemId] = React.useMemo(() => {
    const {
      // @ts-expect-error TS2339
      match: { params },
    } = props;
    const itemId = params.id ? params.id : -1;
    return [itemId];
  }, [props]);
  React.useEffect(() => {
    if (itemId && itemId !== -1) {
      setLoading(true);
      getNewsItem();
    }
  }, [itemId]);
  const getNewsItem = () => {
    const requestBuilder = {
      method: "getRequest",
      url: parseEndpoint(endpoints.updateNews, { id: itemId }),
    };
    // @ts-expect-error TS2554
    props.dispatchApiRequest(requestBuilder, ({ data }) => {
      setLoading(false);
      setItem(data);
    });
  };
  // @ts-expect-error TS7006
  const onDeleteItem = (id) => {
    props.dispatchDelNews(id);
    // @ts-expect-error TS2339
    props.history.goBack();
  };
  // @ts-expect-error TS7006
  const onUpdateItem = (id) => {
    // @ts-expect-error TS2339
    props.history.push(`/dashboard/library/news-update/${id}`);
  };
  // @ts-expect-error TS7006
  const onAddReaction = (id, emoji, emojiName) => {
    const requestBuilder = {
      method: "putRequest",
      url: parseEndpoint(endpoints.newsReaction, {
        newsId: id,
      }),
      data: {
        emoji,
        emojiName,
      },
    };
    // @ts-expect-error TS2554
    props.dispatchApiRequest(requestBuilder, ({ data }) => {
      getNewsItem();
    });
  };
  // @ts-expect-error TS7006
  const onAddSubComment = (id, content) => {
    const requestBuilder = {
      method: "postRequest",
      url: parseEndpoint(endpoints.createNewsComment, {
        data: {
          id,
        },
      }),
      data: {
        content,
      },
    };
    // @ts-expect-error TS2554
    props.dispatchApiRequest(requestBuilder, ({ data }) => {
      getNewsItem();
    });
  };
  // @ts-expect-error TS7006
  const onUpdateSubComment = (id, commentId, content) => {
    const requestBuilder = {
      method: "putRequest",
      url: parseEndpoint(endpoints.updateExistingNewsComment, {
        newsId: id,
        commentId,
      }),
      data: {
        content,
      },
    };
    // @ts-expect-error TS2554
    props.dispatchApiRequest(requestBuilder, ({ data }) => {
      getNewsItem();
    });
  };
  // @ts-expect-error TS7006
  const onDeleteSubComment = (id, commentId) => {
    const requestBuilder = {
      method: "deleteRequest",
      url: parseEndpoint(endpoints.deleteComment, {
        data: {
          id,
          commentId,
        },
      }),
    };
    // @ts-expect-error TS2554
    props.dispatchApiRequest(requestBuilder, ({ data }) => {
      getNewsItem();
    });
  };
  // @ts-expect-error TS7006
  const onAddSubReaction = (id, commentId, emoji, emojiName) => {
    const requestBuilder = {
      method: "putRequest",
      url: parseEndpoint(endpoints.newsCommentReaction, {
        newsId: id,
        commentId,
      }),
      data: {
        emoji,
        emojiName,
      },
    };
    // @ts-expect-error TS2554
    props.dispatchApiRequest(requestBuilder, ({ data }) => {
      getNewsItem();
    });
  };
  return (
    <Content>
      <Card className="library-news">
        <div style={{ maxWidth: 640 }}>
          {item && (
            <NewsCommentItem
              // @ts-expect-error TS2322
              comment={item}
              isGlobal={false}
              // @ts-expect-error TS2339
              isCanEdit={getPermissionForNews(props.auth.payload, item)}
              isCanEditEmoji={true}
              // @ts-expect-error TS2339
              companyId={props.company.id}
              // @ts-expect-error TS2339
              user={props.auth.payload}
              onUpdate={onUpdateItem}
              onDelete={onDeleteItem}
              onAddReaction={onAddReaction}
              onAddSubComment={onAddSubComment}
              onUpdateSubComment={onUpdateSubComment}
              onDeleteSubComment={onDeleteSubComment}
              onAddSubReaction={onAddSubReaction}
            />
          )}
        </div>
      </Card>
    </Content>
  );
}
const mapStateToProps = (state: ReduxState) => ({
  auth: state.auth,
  company: state.company.companyPayload,
});
const mapDispatchToProps = (dispatch: ReduxDispatch) => ({
  // @ts-expect-error TS7006
  dispatchGetNews: (data) => dispatch(NewsActions.newsGetRequest(data)),
  // @ts-expect-error TS7006
  dispatchDelNews: (id) => dispatch(NewsActions.newsDeleteRequest(id)),
  dispatchApiRequest: (data: ApiRequestData, callback: ApiSuccessCallback) =>
    dispatch(ApiActions.apiRequest(data, callback)),
});
export default withRouter(
  // @ts-expect-error TS2345
  connect(mapStateToProps, mapDispatchToProps)(NewsDetail),
);
