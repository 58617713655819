import { Card, Layout, Row } from "antd";
import React from "react";
import { useIntl } from "react-intl";
import { connect } from "react-redux";

import { ReduxDispatch, ReduxState } from "@/Redux/types";

import {
  IndustryReport,
  IndustryReportForm,
  IndustryReportMainCategory,
} from "../../../../Components/organisms";
import { IndustryReportFormData } from "../../../../Components/organisms/industry-report/form";
import ApiActions, {
  ApiRequestData,
  ApiSuccessCallback,
} from "../../../../Redux/ApiRedux";
import IndustryMainCategoryActions from "../../../../Redux/IndustryMainCategoryRedux";
import IndustryActions from "../../../../Redux/IndustryRedux";
import { endpoints, parseEndpoint } from "../../../../Services/endpoints";
const { Content } = Layout;
type StateProps = {
  auth: Object;
  industryMainCategories: {
    industryMainCategoryPayload: Array<IndustryReportMainCategory>;
  };
};
type ActionProps = {
  // @ts-expect-error TS7006
  dispatchApiRequest: (data) => void;
  // @ts-expect-error TS7006
  dispatchUpdateReport: (data) => void;
  // @ts-expect-error TS7006
  dispatchGetMainCategories: (data) => void;
  // @ts-expect-error TS7006
  dispatchDeleteReport: (data, message) => void;
};
type selfProps = {
  manage: Object;
};
type otherProps = {
  match: Object;
};
type Props = ActionProps & StateProps & selfProps & otherProps;
function IndustryReportCreate(props: Props) {
  const [loading, setLoading] = React.useState(false);
  const [item, setItem] = React.useState<IndustryReport | null>(null);
  const intl = useIntl();
  const [itemId] = React.useMemo(() => {
    const {
      // @ts-expect-error TS2339
      match: { params },
    } = props;
    const itemId = params.id ? params.id : -1;
    return [itemId];
  }, [props]);
  React.useEffect(() => {
    if (itemId && itemId !== -1 && itemId !== "") {
      setLoading(true);
      getReportItem();
    }
  }, [itemId]);
  const getReportItem = () => {
    const requestBuilder = {
      method: "getRequest",
      url: parseEndpoint(endpoints.updateIndustryReport, { id: itemId }),
    };
    // @ts-expect-error TS2554
    props.dispatchApiRequest(requestBuilder, ({ data }) => {
      setLoading(false);
      setItem(data);
    });
  };
  React.useEffect(() => {
    // @ts-expect-error TS2554
    props.dispatchGetMainCategories();
  }, []);
  const handleSubmit = (data: IndustryReportFormData) => {
    const newData: IndustryReportFormData & { id?: string } = { ...data };
    newData["id"] = itemId;
    props.dispatchUpdateReport(newData);
    window.history.back();
  };
  return (
    <Content>
      <Row align="middle" justify="space-between">
        <h2>
          {intl.formatMessage({
            id: "screen.label.create_industry_report",
          })}
        </h2>
      </Row>

      <Card>
        <IndustryReportForm
          item={item}
          companies={[]}
          mainCategories={
            props.industryMainCategories.industryMainCategoryPayload
          }
          onSubmit={handleSubmit}
          loading={loading}
          isGlobal={false}
        />
      </Card>
    </Content>
  );
}
const mapStateToProps = (state: ReduxState) => ({
  auth: state.auth,
  industryMainCategories: state.industryMainCategories,
});
const mapDispatchToProps = (dispatch: ReduxDispatch) => ({
  // @ts-expect-error TS7006
  dispatchUpdateReport: (data) =>
    // @ts-expect-error TS2554
    dispatch(IndustryActions.industryUpdateRequest(data)),
  // @ts-expect-error TS7006
  dispatchDeleteReport: (data, message) =>
    // @ts-expect-error TS2554
    dispatch(IndustryActions.industryDeleteRequest(data, message)),
  // @ts-expect-error TS7006
  dispatchGetMainCategories: (data) =>
    // @ts-expect-error TS2554
    dispatch(IndustryMainCategoryActions.industryMainCategoryGetRequest(data)),
  dispatchApiRequest: (data: ApiRequestData, callback: ApiSuccessCallback) =>
    dispatch(ApiActions.apiRequest(data, callback)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
  // @ts-expect-error TS2345
)(IndustryReportCreate);
