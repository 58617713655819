import { Action } from "@reduxjs/toolkit";
import { createActions, createReducer } from "reduxsauce";
import Immutable from "seamless-immutable";

import { ToAction, ToActionType } from "./types";

/* ------------- Types and Action Creators ------------- */
type IndustryMainCatActionCreators = {
  industryMainCategoryCreateRequest: () => Action<"INDUSTRY_MAIN_CATEGORY_CREATE_REQUEST">;
  industryMainCategoryCreateSuccess: () => Action<"INDUSTRY_MAIN_CATEGORY_CREATE_SUCCESS">;
  industryMainCategoryCreateFailure: () => Action<"INDUSTRY_MAIN_CATEGORY_CREATE_FAILURE">;

  industryMainCategoryDeleteRequest: () => Action<"INDUSTRY_MAIN_CATEGORY_DELETE_REQUEST">;
  industryMainCategoryDeleteSuccess: () => Action<"INDUSTRY_MAIN_CATEGORY_DELETE_SUCCESS">;
  industryMainCategoryDeleteFailure: () => Action<"INDUSTRY_MAIN_CATEGORY_DELETE_FAILURE">;

  industryMainCategoryGetRequest: () => Action<"INDUSTRY_MAIN_CATEGORY_GET_REQUEST">;
  industryMainCategoryGetSuccess: () => Action<"INDUSTRY_MAIN_CATEGORY_GET_SUCCESS">;
  industryMainCategoryGetFailure: () => Action<"INDUSTRY_MAIN_CATEGORY_GET_FAILURE">;

  industryMainCategoryUpdateRequest: () => Action<"INDUSTRY_MAIN_CATEGORY_UPDATE_REQUEST">;
  industryMainCategoryUpdateSuccess: () => Action<"INDUSTRY_MAIN_CATEGORY_UPDATE_SUCCESS">;
  industryMainCategoryUpdateFailure: () => Action<"INDUSTRY_MAIN_CATEGORY_UPDATE_FAILURE">;
};

type IndustryMainCatAction = ToAction<IndustryMainCatActionCreators>;
type IndustryMainCatActionType = ToActionType<IndustryMainCatAction>;

const { Types, Creators } = createActions<
  IndustryMainCatActionType,
  IndustryMainCatActionCreators
>({
  industryMainCategoryUpdateRequest: ["data"],
  industryMainCategoryUpdateSuccess: null,
  industryMainCategoryUpdateFailure: ["errors"],

  industryMainCategoryDeleteRequest: ["data"],
  industryMainCategoryDeleteSuccess: null,
  industryMainCategoryDeleteFailure: ["errors"],

  industryMainCategoryCreateRequest: ["data"],
  industryMainCategoryCreateSuccess: null,
  industryMainCategoryCreateFailure: ["errors"],

  industryMainCategoryGetRequest: ["data"],
  industryMainCategoryGetSuccess: ["industryMainCategoryPayload"],
  industryMainCategoryGetFailure: ["errors"],
  /// update nav header in the case of industryMainCategory editor open
});

export const IndustryMainCategoryTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

type IndustryMainCatState = {
  fetching: boolean;
  errors: unknown;
  industryMainCategoryPayload: unknown[];
};

export const INITIAL_STATE = Immutable<IndustryMainCatState>({
  fetching: false,
  errors: {},
  industryMainCategoryPayload: [],
});

type ImmIndustryMainCatState = typeof INITIAL_STATE;

/* ------------- Reducers ------------- */

const industryMainCategoryCreateRequest = (state: ImmIndustryMainCatState) =>
  state.merge({ fetching: true });

const industryMainCategoryCreateSuccess = (state: ImmIndustryMainCatState) => {
  return state.merge({ fetching: false });
};

const industryMainCategoryCreateFailure = (
  state: ImmIndustryMainCatState,
  // @ts-expect-error TS7031
  { errors },
) => state.merge({ fetching: false, errors });

const industryMainCategoryDeleteRequest = (state: ImmIndustryMainCatState) =>
  state.merge({ fetching: true });

const industryMainCategoryDeleteSuccess = (state: ImmIndustryMainCatState) => {
  return state.merge({ fetching: false });
};

const industryMainCategoryDeleteFailure = (
  state: ImmIndustryMainCatState,
  // @ts-expect-error TS7031
  { errors },
) => state.merge({ fetching: false, errors });

const industryMainCategoryUpdateRequest = (state: ImmIndustryMainCatState) =>
  state.merge({ fetching: true });

const industryMainCategoryUpdateSuccess = (state: ImmIndustryMainCatState) => {
  return state.merge({ fetching: false });
};

const industryMainCategoryUpdateFailure = (
  state: ImmIndustryMainCatState,
  // @ts-expect-error TS7031
  { errors },
) => state.merge({ fetching: false, errors });

const industryMainCategoryGetRequest = (state: ImmIndustryMainCatState) =>
  state.merge({ fetching: true });

const industryMainCategoryGetSuccess = (
  state: ImmIndustryMainCatState,
  // @ts-expect-error TS7031
  { industryMainCategoryPayload },
) => {
  return state.merge({ fetching: false, industryMainCategoryPayload });
};

const industryMainCategoryGetFailure = (
  state: ImmIndustryMainCatState,
  // @ts-expect-error TS7031
  { errors },
) => state.merge({ fetching: false, errors });
/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer<
  ImmIndustryMainCatState,
  IndustryMainCatAction
>(INITIAL_STATE, {
  [Types.INDUSTRY_MAIN_CATEGORY_CREATE_REQUEST]:
    industryMainCategoryCreateRequest,
  [Types.INDUSTRY_MAIN_CATEGORY_CREATE_SUCCESS]:
    industryMainCategoryCreateSuccess,
  [Types.INDUSTRY_MAIN_CATEGORY_CREATE_FAILURE]:
    industryMainCategoryCreateFailure,

  [Types.INDUSTRY_MAIN_CATEGORY_DELETE_REQUEST]:
    industryMainCategoryDeleteRequest,
  [Types.INDUSTRY_MAIN_CATEGORY_DELETE_SUCCESS]:
    industryMainCategoryDeleteSuccess,
  [Types.INDUSTRY_MAIN_CATEGORY_DELETE_FAILURE]:
    industryMainCategoryDeleteFailure,

  [Types.INDUSTRY_MAIN_CATEGORY_GET_REQUEST]: industryMainCategoryGetRequest,
  [Types.INDUSTRY_MAIN_CATEGORY_GET_SUCCESS]: industryMainCategoryGetSuccess,
  [Types.INDUSTRY_MAIN_CATEGORY_GET_FAILURE]: industryMainCategoryGetFailure,

  [Types.INDUSTRY_MAIN_CATEGORY_UPDATE_REQUEST]:
    industryMainCategoryUpdateRequest,
  [Types.INDUSTRY_MAIN_CATEGORY_UPDATE_SUCCESS]:
    industryMainCategoryUpdateSuccess,
  [Types.INDUSTRY_MAIN_CATEGORY_UPDATE_FAILURE]:
    industryMainCategoryUpdateFailure,
});
