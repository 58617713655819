import { Button, Col, Form, Input, Popconfirm, Row } from "antd";
import PropTypes from "prop-types";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import { IconDelete, IconEdit, Line } from "../../atoms";
import useManageCategoriesComponent from "./useManageCategoriesComponent";

type IManageCategoriesComponentProps = {
  activeLargeCategory?: number;
  intl?: {
    formatMessage?: (...args: any[]) => any;
  };
  largeCategories: any[];
  onDeleteSmallCategory: (...args: any[]) => any;
  onSubmitNewCategory: (...args: any[]) => any;
  smallCategories: any;
  user: any;
  isSuper?: any;
  formatMessage?: any;
  filter?: any;
} & React.HTMLAttributes<Element>;

const ManageCategoriesComponent: FC<IManageCategoriesComponentProps> =
  function (props) {
    const {
      intl,
      state,
      setState,
      getLargeCategoriesToRender,
      handleChangeNewSmallCategory,
      onCreateNewSmallCategory,
    } = useManageCategoriesComponent(props);

    // @ts-expect-error TS7006
    const _renderDeleteCategoryButton = (largeCategory, singleMini) => {
      if (largeCategory.companyId === null && props.user.isSuper) {
        return (
          <Popconfirm
            title={intl.formatMessage({
              id: "screen.label.confirm_delete_video_small_category",
            })}
            onConfirm={() => {
              props.onDeleteSmallCategory(largeCategory.id, singleMini.id);
            }}
            okText={intl.formatMessage({
              id: "screen.label.yes",
            })}
            cancelText={intl.formatMessage({
              id: "screen.label.no",
            })}
          >
            {IconDelete}
          </Popconfirm>
        );
      } else if (largeCategory.companyId !== null) {
        return (
          <Popconfirm
            title={intl.formatMessage({
              id: "screen.label.confirm_delete_video_small_category",
            })}
            onConfirm={() => {
              props.onDeleteSmallCategory(largeCategory.id, singleMini.id);
            }}
            okText={intl.formatMessage({
              id: "screen.label.yes",
            })}
            cancelText={intl.formatMessage({
              id: "screen.label.no",
            })}
          >
            {IconDelete}
          </Popconfirm>
        );
      }
    };
    // @ts-expect-error TS7006
    const _renderNewCategoryButton = (largeCategory) => {
      if (largeCategory.companyId === null && props.user.isSuper) {
        return (
          <Button
            onClick={() => {
              setState({
                isSmallCategoryModalVisible: true,
              });
              handleChangeNewSmallCategory(
                "videoLargeCategoryId",
                largeCategory.id,
              );
            }}
            style={{ float: "right" }}
          >
            <FormattedMessage id="screen.label.to_add_new_category" />
          </Button>
        );
      } else if (largeCategory.companyId !== null) {
        return (
          <Button
            onClick={() => {
              setState({
                isSmallCategoryModalVisible: true,
              });
              handleChangeNewSmallCategory(
                "videoLargeCategoryId",
                largeCategory.id,
              );
            }}
            style={{ float: "right" }}
          >
            <FormattedMessage id="screen.label.to_add_new_category" />
          </Button>
        );
      }
    };

    // @ts-expect-error TS7006
    const _renderLargeCategoryMiniCategories = (largeCategory) => {
      const data = props.smallCategories[largeCategory.id];
      if (data && Array.isArray(data)) {
        return data.map((singleMini) => {
          return _renderSingleMiniCategory(largeCategory, singleMini);
        });
      }
    }; //
    // @ts-expect-error TS7006
    const _renderSingleMiniCategory = (largeCategory, singleMini) => {
      return (
        <div key={singleMini.id}>
          <Row>
            <Col span={18}>
              <p>{singleMini.name}</p>
            </Col>

            <Col span={6}>
              <div style={{ float: "right" }}>
                <span>
                  <FormattedMessage
                    id="screen.label.count_matter"
                    values={{
                      count: singleMini.Videos.length,
                    }}
                  />
                </span>
                <Link to={`/global-video/small-category-edit/${singleMini.id}`}>
                  {IconEdit}
                </Link>

                {_renderDeleteCategoryButton(largeCategory, singleMini)}
              </div>
            </Col>
          </Row>
          <Line />
        </div>
      );
    }; //
    if (Array.isArray(props.largeCategories)) {
      // @ts-expect-error TS7006
      return getLargeCategoriesToRender().map((singleLargeCategory) => {
        return (
          <div key={singleLargeCategory.id}>
            <h3>{singleLargeCategory.name} </h3>
            <br />
            {_renderLargeCategoryMiniCategories(singleLargeCategory)}
            <br />
            {state.isSmallCategoryModalVisible &&
            state.newSmallCategory.videoLargeCategoryId ===
              singleLargeCategory.id ? (
              <Form layout={"vertical"}>
                <Row>
                  <Col span={16}>
                    <Form.Item
                      label={`${intl.formatMessage({
                        id: "screen.label.new_small_category_name",
                      })} *`}
                    >
                      <Input
                        onChange={(e) =>
                          handleChangeNewSmallCategory("name", e.target.value)
                        }
                        value={state.newSmallCategory.name}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <div
                      style={{
                        marginLeft: "12px",
                        marginTop: "30px",
                      }}
                    >
                      <Button
                        onClick={() => {
                          setState({
                            isSmallCategoryModalVisible: false,
                          });
                        }}
                        type={"ghost"}
                      >
                        <FormattedMessage id="screen.label.close" />
                      </Button>

                      <Button
                        onClick={onCreateNewSmallCategory}
                        style={{
                          color: "white",
                          marginLeft: "3px",
                          backgroundColor: "#00D282",
                        }}
                      >
                        <FormattedMessage id="screen.label.storage" />
                      </Button>
                    </div>
                  </Col>
                </Row>
                <hr
                  style={{
                    backgroundColor: "#e0e0e0",
                    border: "none",
                    height: "1px",
                  }}
                />
              </Form>
            ) : null}

            <Row>
              <Col span={24}>
                {_renderNewCategoryButton(singleLargeCategory)}
              </Col>
            </Row>
          </div>
        );
      });
    }
    return <></>;
  };

ManageCategoriesComponent.defaultProps = {
  activeLargeCategory: 0,
};
ManageCategoriesComponent.propTypes = {
  // @ts-expect-error TS2322
  largeCategories: PropTypes.array,
  user: PropTypes.object,
  activeLargeCategory: PropTypes.number,
  smallCategories: PropTypes.object,
  // @ts-expect-error TS2322
  onSubmitNewCategory: PropTypes.func,
  // @ts-expect-error TS2322
  onDeleteSmallCategory: PropTypes.func,
};
export default ManageCategoriesComponent;
