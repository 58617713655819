export default (obj) => {
  let key,
    keys = Object.keys(obj);
  let n = keys.length;
  const formData = new FormData();

  while (n--) {
    key = keys[n];
    formData.append(key, obj[key]);
  }
  return formData;
};
