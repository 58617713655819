import { forwardRef, Ref } from "react";

import styles from "./styles.module.scss";
import { PlainInputProps } from "./type";

export const PlainInput = forwardRef(
  (
    { label, className, ...inputProps }: PlainInputProps,
    ref?: Ref<HTMLInputElement>,
  ) => {
    return (
      <div className={styles.input_container}>
        {label && <label className={styles.label}>{label}</label>}
        <input
          ref={ref}
          className={`${className} ${styles.input}`}
          {...inputProps}
        />
      </div>
    );
  },
);
