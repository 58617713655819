import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  notification,
  Row,
  Select,
} from "antd";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { isUrl } from "../../../utils/common";
import { NewLinkPreview } from "../../molecules";
import FileUploadComponent from "./thumbUpload";
import type { News, NewsFormData } from "./type";
import { getPermissionForEmailSend } from "./utils";
const { Option } = Select;
const { TextArea } = Input;
type FormProps = {
  user: Object;
  loading: boolean;
  isGlobal: boolean;
  // @ts-expect-error TS2314
  companies: Array;
  onSubmit: (data: NewsFormData) => void;
  item: News | null;
};
function NewsCreateForm({
  // @ts-expect-error TS2322
  user = null,
  item,
  loading = false,
  isGlobal = false,
  companies,
  onSubmit,
}: FormProps) {
  const intl = useIntl();
  const [isGlobalNews, setIsGlobalNews] = React.useState(false);
  const [thumbManually, setThumbManually] = React.useState(false);
  const [thumbImage, setThumbImage] = React.useState<string | null>(null);
  const [url, setURL] = React.useState("");
  const [newsComment, setNewsComment] = React.useState("");
  const [tags, setTags] = React.useState([]);
  const [permittedCompanies, setPermittedCompanies] = React.useState([]);
  const [notifyByEmail, setNotifyByEmail] = React.useState(false);
  React.useEffect(() => {
    if (item && item.id) {
      setURL(item.url);
      setNewsComment(item.newsComment);
      // @ts-expect-error TS2339
      setTags(item.tags || []);
      setIsGlobalNews(item.isGlobalNews);
      // @ts-expect-error TS2345
      setPermittedCompanies(item.permittedCompanies || []);
      if (item.thumbImage) {
        setThumbImage(item.thumbImage);
        setThumbManually(true);
      }
    }
  }, [item]);
  return (
    <Form
      onFinish={() => {
        if (!isUrl(url)) {
          notification.warning({
            message: intl.formatMessage({
              id: "screen.label.enter_valid_url",
            }),
          });
          return;
        }
        let newThumbImage = thumbImage;
        if (typeof thumbImage === "string" && thumbImage !== "") {
          newThumbImage = thumbImage.split("?")[0];
        }
        onSubmit({
          url,
          // @ts-expect-error 多分いらないプロパティ
          isGlobalNews,
          newsComment,
          tags,
          notifyByEmail,
          permittedCompanies,
          thumbImage: newThumbImage,
        });
      }}
      style={{ maxWidth: 640 }}
    >
      {isGlobal && (
        <>
          <h3 style={{ fontWeight: "bold" }}>
            {intl.formatMessage({
              id: "screen.label.news_post",
            })}
          </h3>

          <br />
          <Form.Item>
            <Row>
              <Col>
                <Checkbox
                  checked={isGlobalNews}
                  onChange={(event) => {
                    if (event.target.checked) {
                      setPermittedCompanies([]);
                    }
                    setIsGlobalNews(event.target.checked);
                  }}
                >
                  {intl.formatMessage({
                    id: "screen.label.global",
                  })}
                </Checkbox>
              </Col>
              {!isGlobalNews && (
                <Col>
                  <Select
                    mode="multiple"
                    allowClear
                    style={{ width: "100%", minWidth: 300 }}
                    value={permittedCompanies}
                    placeholder={intl.formatMessage({
                      id: "screen.label.post_company",
                    })}
                    onChange={(value) => {
                      setPermittedCompanies(value);
                    }}
                  >
                    {/*
                     // @ts-expect-error TS7006 */}
                    {companies.map((a) => (
                      <Option key={a.id} value={a.id}>
                        {a.name}
                      </Option>
                    ))}
                  </Select>
                </Col>
              )}
            </Row>
          </Form.Item>
        </>
      )}
      <Form.Item>
        <h4 style={{ fontWeight: "bold" }}>URL</h4>
        <Input
          autoFocus={true}
          required
          value={url}
          onChange={(event) => {
            setURL(event.target.value);
          }}
          placeholder={intl.formatMessage({
            id: "screen.label.please_enter",
          })}
        />
      </Form.Item>
      {isUrl(url) && (
        <>
          <br />
          <NewLinkPreview
            news={{
              url,
              // @ts-expect-error TS2322
              thumbImage,
              // @ts-expect-error TS2322
              pageTitle: item?.pageTitle,
            }}
          />
        </>
      )}
      <br />
      <Form.Item>
        <h4 style={{ fontWeight: "bold" }}>
          {intl.formatMessage({
            id: "screen.label.post_comment",
          })}
        </h4>
        <TextArea
          required
          autoSize={true}
          style={{ minHeight: 80 }}
          value={newsComment}
          onChange={(event) => {
            setNewsComment(event.target.value);
          }}
          placeholder={intl.formatMessage({
            id: "screen.label.please_enter",
          })}
        />
      </Form.Item>
      <br />
      <Form.Item>
        <Checkbox
          checked={thumbManually}
          onChange={(event) => {
            setThumbManually(event.target.checked);
            if (!event.target.checked) {
              setThumbImage(null);
            }
          }}
        >
          {intl.formatMessage({
            id: "screen.label.set_thumb_manually",
          })}
        </Checkbox>
        {thumbManually && (
          <Form.Item>
            <FileUploadComponent
              onFileChanged={(imageThumbnail) => {
                // @ts-expect-error TS2345
                setThumbImage(imageThumbnail);
              }}
            />
          </Form.Item>
        )}
      </Form.Item>
      <br />
      {getPermissionForEmailSend(user) && (
        <>
          <Form.Item>
            <h4 style={{ fontWeight: "bold" }}>
              {intl.formatMessage({
                id: "screen.label.notification",
              })}
            </h4>
            <Checkbox
              checked={notifyByEmail}
              onChange={(event) => {
                setNotifyByEmail(event.target.checked);
              }}
            >
              {intl.formatMessage({
                id: "screen.label.notify_by_email",
              })}
            </Checkbox>
          </Form.Item>
          <br />
        </>
      )}
      <Button
        style={{
          paddingLeft: 30,
          paddingRight: 30,
        }}
        disabled={loading}
        loading={loading}
        type="primary"
        htmlType="submit"
        size="small"
      >
        <FormattedMessage id="screens.title.post" />
      </Button>
    </Form>
  );
}
export default NewsCreateForm;
