import FileSaver from "file-saver";
import { useEffect, useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";

import { ApiRequestData } from "@/Redux/ApiRedux";

import { endpoints, parseEndpoint } from "../../../Services/endpoints";
import { usePrevious } from "../../../utils/hooks";
import {
  IProjectArchiveScreenDetailsProps,
  ProjectArchiveScreenDetailsState,
} from "../types/IProjectArchiveScreenDetailsProps";

const useProjectArchiveScreenDetails = (
  props: IProjectArchiveScreenDetailsProps,
) => {
  const intl = useIntl();
  const { apiRequest, updatePageTitle } = props.context;
  const [state, customSetState] = useState<ProjectArchiveScreenDetailsState>(
    () => {
      return {
        submission: null,
        archive: null,
        mainMarginBottom: 0,
        shouldShowCommentsReplies: false,
        projectSetting: [],
      };
    },
  );
  const setStateCallbackRef = useRef(() => {});
  useEffect(() => {
    const callBack = setStateCallbackRef.current;
    callBack();
    setStateCallbackRef.current = () => {};
  }, [state]);
  const setState = (
    data: Partial<ProjectArchiveScreenDetailsState>,
    callback = () => {},
  ) => {
    setStateCallbackRef.current = callback;
    customSetState((previousState) => {
      return {
        ...previousState,
        ...data,
      };
    });
  };
  useEffect(() => {
    updatePageTitle("screen.label.archive");
    getArchiveDetail();
    fetchAcceptedTeam();
    setTimeout(() => {
      setState({
        shouldShowCommentsReplies: true,
      });
    }, 3000);
  }, []);

  const prevProps: IProjectArchiveScreenDetailsProps = usePrevious(props);
  useEffect(() => {
    if (props.project && props.project !== prevProps?.project) {
      if (props.project.batchId) {
        getBatchHypothesis(props.project.batchId);
      } else {
        getDefaultBatch();
      }
    }
  }, []);

  const getArchiveId = () => {
    const {
      match: { params },
    } = props;
    return params.archiveId ? params.archiveId : -1;
  };
  const getProjectId = (): string | number => {
    const {
      match: { params },
    } = props;
    return params.id ? params.id : -1;
  };
  const getArchiveDetail = () => {
    const requestBuilder: ApiRequestData = {
      method: "getRequest",
      url: parseEndpoint(endpoints.getArchiveDetail, {
        archiveId: getArchiveId(),
      }),
      data: {},
    };
    apiRequest(requestBuilder, ({ data }) => {
      setState({
        // @ts-expect-error TS2322
        submission: data,
        // @ts-expect-error TS18046
        archive: data.record,
      });
    });
  };
  const getBatchHypothesis = (batchId: unknown) => {
    const batchRequest: ApiRequestData = {
      method: "getRequest",
      url: parseEndpoint(endpoints.batchHypothesis, {
        // @ts-expect-error TS2322
        batchId,
      }),
    };
    apiRequest(batchRequest, ({ data }) => {
      setState({
        projectSetting: [
          //@ts-expect-error 要API型定義
          ...data,
        ],
      });
    });
  };
  const getDefaultBatch = () => {
    const batchRequest: ApiRequestData = {
      method: "getRequest",
      url: parseEndpoint(endpoints.defaultBatch),
    };
    apiRequest(batchRequest, ({ data }) => {
      // @ts-expect-error TS18046
      getBatchHypothesis(data.id);
    });
  };
  const fetchAcceptedTeam = () => {
    props.dispatchFetchUsers({
      page: 0,
      id: getProjectId(),
      shouldPaginate: false,
    });
  };
  const handleChangeBottomMargin = (margin: never) => {
    setState({
      mainMarginBottom: 0,
    });
  };
  const fileDownloaded = (fileId: unknown, fileSize: unknown) => {
    const data: ApiRequestData = {
      method: "postRequest",
      url: parseEndpoint(endpoints.fileDownload, {
        // @ts-expect-error TS2322
        fileId,
      }),
      data: {
        fileSize,
      },
    };
    apiRequest(data, ({ data }) => {});
  };
  // @ts-expect-error TS7006
  const saveFile = (href: string, fileName: string, fileId) => {
    if (href && href.length > 0) {
      const xhr = new XMLHttpRequest();
      xhr.withCredentials = true;
      xhr.open("GET", href, true);
      xhr.responseType = "arraybuffer";
      xhr.onload = (e) => {
        // @ts-expect-error TS18047
        const arrayBufferView = new Uint8Array(e.currentTarget.response);
        const blob = new Blob([arrayBufferView]);
        fileDownloaded(fileId, blob.size);
        FileSaver.saveAs(blob, fileName);
      };
      xhr.send();
    }
  };

  const projectCollapseKeys = useMemo(() => {
    let projectCollapseKeys: string[] = [];
    const project = state.archive;
    if (state.projectSetting && state.projectSetting.length > 0) {
      projectCollapseKeys = state.projectSetting.map(
        (a, index) => a.hypothesisFormId,
      );
      if (project && project.meta) {
        projectCollapseKeys.push("archiveFileContainer");
      }
      if (project && project.comments && project.comments.length > 0) {
        projectCollapseKeys.push("commentContainer");
      }
    }
    return projectCollapseKeys;
  }, [state.projectSetting, state.archive]);

  return {
    intl,
    state,
    projectCollapseKeys,
    setState,
    getProjectId,
    handleChangeBottomMargin,
    saveFile,
  };
};

export default useProjectArchiveScreenDetails;
