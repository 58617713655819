import { Col, Layout, Row } from "antd";
import PropTypes from "prop-types";
import { FC } from "react";
import { FormattedMessage } from "react-intl";

import {
  AlphaDriveImg,
  AlphaDriveImgContainer,
  AuthLayout,
  Banner,
  HorizontalLine,
  LoginBanner,
  LoginLabel,
  LogoTitleLayout,
  PointerParagraph,
  VerifyHeaderBold,
} from "../../Components/molecules";
import { IThankYouScreenProps } from "./types/IThankYouScreenProps";
const { Content } = Layout;

const ThankYouScreen: FC<IThankYouScreenProps> = function (props) {
  const goBack = () => {
    // @ts-expect-error TS18048
    props.history.goBack();
  };

  return (
    <AuthLayout className="layout">
      <Content className="authContent">
        <Row>
          <Banner>
            <LogoTitleLayout>
              <LoginBanner alt={"Banner"} src={"/login_banner.png"} />
              <LoginLabel>
                <FormattedMessage id="screen.label.comprehensive_support_tool_des" />
                <br />
                <FormattedMessage id="screen.label.incubation_suite" />
              </LoginLabel>
            </LogoTitleLayout>
            <AlphaDriveImgContainer>
              <div>
                <span>Powered by &nbsp;</span>
                <AlphaDriveImg alt={"alpha drive"} src={"/alpha_drive.png"} />
              </div>
            </AlphaDriveImgContainer>
          </Banner>
          <Col>
            <div className="contentRoot">
              <div>
                <VerifyHeaderBold>
                  <FormattedMessage id="screen.label.recieved_account_registration" />
                </VerifyHeaderBold>
                <VerifyHeaderBold>
                  <FormattedMessage id="screen.label.we_have_received_request" />
                </VerifyHeaderBold>
                <br />
                <p>
                  <FormattedMessage id="screen.label.if_request_approved" />
                  <FormattedMessage id="screen.label.contact_us_msg" />
                  <FormattedMessage id="screen.label.please_wait" />
                </p>
                <p
                  style={{
                    color: "#808080",
                    fontSize: "11px",
                  }}
                >
                  <FormattedMessage id="screen.label.contact_sales_staff" />
                </p>
                <HorizontalLine />
                <PointerParagraph onClick={goBack}>
                  <FormattedMessage id="screen.label.back_to_request_screen" />
                </PointerParagraph>
              </div>
            </div>
          </Col>
        </Row>
      </Content>
    </AuthLayout>
  );
};
ThankYouScreen.propTypes = {
  history: PropTypes.object,
  dispatchLoginRequest: PropTypes.func,
};

export default ThankYouScreen;
