import { EditFilled } from "@ant-design/icons";
import { LeftOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Card,
  Col,
  Collapse,
  Divider,
  List,
  Modal,
  PageHeader,
  Row,
  Tooltip,
} from "antd";
import moment from "moment";
import PropTypes from "prop-types";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { BatchHypothesis } from "@/../types/BatchHypothesis";
import { Team } from "@/../types/Team";
import ProjectHypothesisArchive from "@/Components/organisms/project/ProjectHypothesisArchive";
import { ReduxDispatch, ReduxState } from "@/Redux/types";
import { sanitizeHtml } from "@/utils/sanitizeHtml";

import { HorizonalLayout, Tag } from "../../Components/atoms";
import { LeftArrowComponent } from "../../Components/atoms";
import { InsuPrimaryButton } from "../../Components/atoms/buttons/shared";
import { ProjectOwners } from "../../Components/molecules/project/ProjectOwners";
import { CheckPointCoverageRate } from "../../Components/organisms/project/CheckPointCoverageRate";
import CommentComponent from "../../Components/organisms/project/CommentComponent";
import { ToolTipForPrivateProject } from "../../features/project/components/ToolTipForPrivateProject";
import ProjectActions from "../../Redux/ProjectRedux";
import TeamActions from "../../Redux/TeamRedux";
import {
  getDisplayName,
  getObjUUID,
  getProjectAvatar,
  getUserAvatar,
} from "../../utils/common";
import { getHypothesisObjectFromArray } from "../../utils/projectUtils";
import useProjectArchiveScreenDetails from "./hooks/useProjectArchiveScreenDetails";
import { withCheckProjectAccess } from "./permissionWrapper";
import { IProjectArchiveScreenDetailsProps } from "./types/IProjectArchiveScreenDetailsProps";

const { Panel } = Collapse;
const truncatedTeamCount = 6;
const customPanelStyle = {
  background: "#fff ",
  overflow: "hidden",
};

const ProjectArchiveScreenDetails: FC<IProjectArchiveScreenDetailsProps> =
  function (props) {
    const {
      intl,
      state,
      projectCollapseKeys,
      setState,
      getProjectId,
      handleChangeBottomMargin,
      saveFile,
    } = useProjectArchiveScreenDetails(props);

    const { company } = props.context;

    const _renderProjectHypothesis = (setttings: BatchHypothesis[]) => {
      if (setttings.length < 1) {
        return null;
      }

      const project = state.archive;
      const projectHypothesisRecord = getHypothesisObjectFromArray(
        //@ts-expect-error TS2345
        project,
      );
      const projectCheckpoints = project?.Project_Checkpoints ?? [];
      return setttings
        .filter((a) => a.hypothesisFormMetadata.displayHypothesis)
        .map((hypothesis) => {
          const hypotheiseMeta = hypothesis.hypothesisFormMetadata;
          const ProjectHypothesisBlock = (
            <ProjectHypothesisArchive
              // @ts-expect-error TS2322
              projectId={getProjectId()}
              batchId={hypothesis.batchId}
              hypothesisFormId={hypothesis.hypothesisFormId}
              key={hypothesis.hypothesisFormId}
              content={projectHypothesisRecord[hypothesis.hypothesisFormId]}
              projectCheckpoints={projectCheckpoints.filter(
                (a) => a.hypothesisFormId === hypothesis.hypothesisFormId,
              )}
              // @ts-expect-error TS2551
              displayCheckpoint={props.project?.Batch?.displayCheckpoint}
            />
          );
          return (
            <Panel
              showArrow={false}
              header={
                <PageHeader
                  className="project-item-header"
                  title={hypotheiseMeta.hypothesisFormName}
                />
              }
              key={hypothesis.hypothesisFormId}
              style={customPanelStyle}
            >
              {ProjectHypothesisBlock}
            </Panel>
          );
        });
    }; //
    const _renderProjectFileHypothesis = (setttings: BatchHypothesis[]) => {
      if (setttings.length < 1) {
        return null;
      }
      const project = state.archive;
      // @ts-expect-error TS18049
      if (project["meta"]) {
        const fileUrl =
          // @ts-expect-error TS18049
          project.meta.fileUrl && project.meta.fileUrl.length > 0
            ? // @ts-expect-error TS18049
              project.meta.fileUrl
            : "";
        // @ts-expect-error TS18049
        const fileUrlName = project.meta.fileUrlName
          ? // @ts-expect-error TS18049
            project.meta.fileUrlName
          : fileUrl;
        const uploadUrls =
          // @ts-expect-error TS18049
          project.meta.parsedFileList && project.meta.parsedFileList.length > 0
            ? // @ts-expect-error TS18049
              project.meta.parsedFileList
            : [];
        const content = (
          <>
            {fileUrl.length > 0 ? (
              <Button key={fileUrl} href={fileUrl} target="_blank" type="link">
                {fileUrlName}
              </Button>
            ) : null}
            {uploadUrls.map((a) => {
              return (
                <Button
                  key={a.uploadUrl}
                  onClick={() => {
                    const urlToDisplay = a.uploadUrl;
                    const href =
                      urlToDisplay && urlToDisplay.length > 0
                        ? urlToDisplay.indexOf("http") === -1
                          ? window.location.protocol + "//" + urlToDisplay
                          : urlToDisplay
                        : "";
                    saveFile(
                      href,
                      a.friendlyFileName ? a.friendlyFileName : "download",
                      a.uploadedFileId,
                    );
                  }}
                  target="_blank"
                  type="link"
                >
                  {a.friendlyFileName ? a.friendlyFileName : "download"}
                </Button>
              );
            })}
          </>
        );
        return (
          <Panel
            showArrow={false}
            header={
              <PageHeader
                className="project-item-header"
                title={intl.formatMessage({
                  id: "screen.label.submit_file",
                })}
              />
            }
            key={"archiveFileContainer"}
            style={customPanelStyle}
          >
            {content}
          </Panel>
        );
      }
      return null;
    };
    const _renderProjectDetailHypothesis = (setttings: BatchHypothesis[]) => {
      if (setttings.length < 1) {
        return null;
      }
      const project = state.archive;
      const projectHypothesisRecord = getHypothesisObjectFromArray(
        //@ts-expect-error TS2345
        project,
      );
      const projectCheckpoints = project?.Project_Checkpoints ?? [];
      return setttings
        .filter((a) => a.hypothesisFormMetadata.displayHypothesis)
        .map((hypothesis) => {
          const hypotheiseMeta = hypothesis.hypothesisFormMetadata;
          const ProjectHypothesisBlock = (
            <ProjectHypothesisArchive
              // @ts-expect-error TS2322
              projectId={getProjectId()}
              batchId={hypothesis.batchId}
              hypothesisFormId={hypothesis.hypothesisFormId}
              key={hypothesis.hypothesisFormId}
              content={projectHypothesisRecord[hypothesis.hypothesisFormId]}
              projectCheckpoints={projectCheckpoints.filter(
                (a) => a.hypothesisFormId === hypothesis.hypothesisFormId,
              )}
              // @ts-expect-error TS2551
              displayCheckpoint={props.project?.Batch?.displayCheckpoint}
            />
          );
          return (
            <Panel
              showArrow={false}
              header={
                <PageHeader
                  className="project-item-header"
                  title={hypotheiseMeta.hypothesisFormName}
                />
              }
              key={hypothesis.hypothesisFormId}
              style={customPanelStyle}
            >
              {ProjectHypothesisBlock}
            </Panel>
          );
        });
    }; //
    const renderProjectTitle = () => {
      return (
        <Row align="middle" justify="space-between">
          <Col flex="0">{getProjectAvatar(state.archive)}</Col>
          <Col flex="5">
            <Row align="middle">
              <Col
                style={{
                  maxWidth: "30vw",
                  wordBreak: "break-word",
                }}
              >
                {/*
                 // @ts-expect-error TS18049 */}
                {state.archive.name}
              </Col>
              <Col style={{ marginLeft: 16 }}>
                {/*
                 // @ts-expect-error TS18049 */}
                {state.archive.isPrivate ? (
                  <ToolTipForPrivateProject />
                ) : (
                  <Tooltip
                    title={intl.formatMessage({
                      id: `screens.message.project.public`,
                    })}
                  >
                    <div className="status-title-public">
                      {<FormattedMessage id="screens.title.project.public" />}
                    </div>
                  </Tooltip>
                )}
              </Col>
            </Row>
          </Col>
          <Col flex="none">
            <Row align="middle">
              <Col>
                <span style={{ fontSize: 10 }}>{`${intl.formatMessage({
                  id: "screen.label.last_updated",
                  // @ts-expect-error TS18049
                })}：${moment(state.submission.updatedAt)
                  .local()
                  .format("YYYY/MM/DD")}`}</span>
              </Col>
            </Row>
          </Col>
        </Row>
      );
    }; //
    const renderModalForRestMembers = (restTeamMembers: Team[]) => {
      return (
        <Modal
          width="auto"
          centered
          title={
            <Row align="middle" style={{ marginRight: 54 }}>
              <Col style={{ maxWidth: "30vw" }}>
                <FormattedMessage
                  id="screen.label.project_member_name"
                  // @ts-expect-error TS18049
                  values={{ name: state.archive.name }}
                />
              </Col>
              <Col style={{ marginLeft: 16 }}>
                <EditFilled />{" "}
                <Link to={"/dashboard/project.team/" + getProjectId()}>
                  {intl.formatMessage({
                    id: "screen.label.edit_members",
                  })}
                </Link>
              </Col>
            </Row>
          }
          visible={state.visibleRestMember}
          onCancel={() => {
            setState({
              visibleRestMember: false,
            });
          }}
          footer={null}
          destroyOnClose
        >
          <div style={{ maxHeight: "60vh", overflow: "auto" }}>
            <List
              itemLayout="horizontal"
              dataSource={restTeamMembers}
              renderItem={(item) => (
                <List.Item>
                  {/*
                   // @ts-expect-error TS2741 */}
                  <Link>
                    <Row align="middle">
                      <Col flex={1}>{getUserAvatar(item)}</Col>
                      <Col flex={2} style={{ maxWidth: 300 }}>
                        {getDisplayName(item)}
                      </Col>
                      {item.isProjectOwner && (
                        <Col flex={"none"} style={{ marginLeft: 16 }}>
                          <div className="meta-title">Owner</div>
                        </Col>
                      )}
                    </Row>
                  </Link>
                </List.Item>
              )}
            />
          </div>
        </Modal>
      );
    };

    const project = state.archive;
    if (!project) {
      return null;
    }
    const tags = project.Tag || [];
    const teams: Team[] = Object.assign(
      [],
      props.teams.teamMembersPayload.rows,
    );
    const projectOwners = teams.filter((a) => a.isProjectOwner);
    const otherMembers = teams.filter((a) => !a.isProjectOwner);
    const showTeamMembers =
      otherMembers.length > truncatedTeamCount
        ? otherMembers.slice(0, truncatedTeamCount)
        : otherMembers;
    const restTeamMembers =
      otherMembers.length > truncatedTeamCount
        ? otherMembers.slice(truncatedTeamCount, otherMembers.length)
        : [];

    return (
      <div
        className="project-top"
        style={{ marginBottom: state.mainMarginBottom }}
      >
        <LeftArrowComponent>
          <Link to={`/dashboard/project.archive/${getProjectId()}`}>
            <LeftOutlined />
            <span>
              <FormattedMessage id="screen.label.back_to_archive" />
            </span>
          </Link>
        </LeftArrowComponent>
        {renderModalForRestMembers(teams)}
        <Card title={renderProjectTitle()}>
          <Row align="middle">
            <Col flex="6">
              <ProjectOwners>
                {projectOwners.map((u, index) => {
                  return (
                    <Link
                      key={index}
                      to={"/dashboard/member/" + getObjUUID(u)}
                      style={{ color: "#000" }}
                    >
                      <div className="avatar-name-wrapper">
                        {getUserAvatar(u)}
                        {getDisplayName(u)}
                      </div>
                    </Link>
                  );
                })}
                {showTeamMembers.map((u, index) => {
                  return (
                    <Tooltip key={index} title={getDisplayName(u)}>
                      <Link to={"/dashboard/member/" + getObjUUID(u)}>
                        <div style={{ marginLeft: 10 }}>{getUserAvatar(u)}</div>
                      </Link>
                    </Tooltip>
                  );
                })}
                {restTeamMembers.length > 0 && (
                  <div style={{ marginLeft: 10 }}>
                    <Avatar
                      style={{
                        marginRight: 10,
                        verticalAlign: "middle",
                      }}
                      onClick={() => {
                        setState({
                          visibleRestMember: true,
                        });
                      }}
                      size="large"
                    >
                      <span
                        style={{
                          fontWeight: "bold",
                          fontSize: 14,
                          color: "#808080",
                        }}
                      >
                        +{restTeamMembers.length}
                      </span>
                    </Avatar>
                  </div>
                )}
              </ProjectOwners>
            </Col>
            <Col flex="none"></Col>
          </Row>
          <br />
          <Row>
            <Col span={24}>
              <div
                style={{
                  whiteSpace: "pre-line",
                  wordWrap: "break-word",
                }}
                dangerouslySetInnerHTML={{
                  __html: sanitizeHtml(project.description),
                }}
              />
            </Col>
          </Row>
          <Divider style={{ marginTop: 16, marginBottom: 16 }} />
          <Row>
            {tags.map((tag) => {
              return (
                <div key={tag.id} className="tag-title">
                  {tag.name}
                </div>
              );
            })}
          </Row>
        </Card>
        <br />
        <Card>
          {company.isAllowCheckpoint &&
            props.project?.Batch?.displayCheckpoint && (
              <Row>
                <Col span={24}>
                  <HorizonalLayout
                    style={{
                      justifyContent: "flex-end",
                    }}
                  >
                    <Tag>
                      <FormattedMessage
                        id={"screen.label.project_list.check_coverage_rate"}
                      />
                      <CheckPointCoverageRate
                        projectCheckpoints={project.Project_Checkpoints}
                      />
                    </Tag>
                  </HorizonalLayout>
                </Col>
              </Row>
            )}

          <Collapse activeKey={projectCollapseKeys} bordered={false}>
            {_renderProjectHypothesis(
              state.projectSetting
                .sort((a, b) => {
                  return (
                    a.hypothesisFormMetadata.order -
                    b.hypothesisFormMetadata.order
                  );
                })
                .filter(
                  (a) =>
                    a.hypothesisFormMetadata &&
                    a.hypothesisFormMetadata.firstViewHypothesis,
                ),
            )}
            {_renderProjectDetailHypothesis(
              state.projectSetting
                .sort((a, b) => {
                  return (
                    a.hypothesisFormMetadata.order -
                    b.hypothesisFormMetadata.order
                  );
                })
                .filter(
                  (a) =>
                    a.hypothesisFormMetadata &&
                    !a.hypothesisFormMetadata.firstViewHypothesis,
                ),
            )}
            {_renderProjectFileHypothesis(state.projectSetting)}
            {project.comments && project.comments.length > 0 ? (
              <Panel
                showArrow={false}
                header={
                  <PageHeader
                    className="project-item-header"
                    title={intl.formatMessage({
                      id: "screen.label.comment",
                    })}
                    extra={
                      <InsuPrimaryButton
                        onClick={() => {
                          setState({
                            shouldShowCommentsReplies:
                              !state.shouldShowCommentsReplies,
                          });
                        }}
                      >
                        {state.shouldShowCommentsReplies
                          ? intl.formatMessage({
                              id: "screen.label.all_hide",
                            })
                          : intl.formatMessage({
                              id: "screen.label.all_show",
                            })}
                      </InsuPrimaryButton>
                    }
                  />
                }
                key={"commentContainer"}
              >
                <div>
                  <CommentComponent
                    // @ts-expect-error TS2322
                    suggestions={[]}
                    canEdit={false}
                    comments={project.comments}
                    changeBottomMargin={handleChangeBottomMargin}
                    shouldShowCommentsReplies={state.shouldShowCommentsReplies}
                  />
                </div>
              </Panel>
            ) : (
              <></>
            )}
          </Collapse>
        </Card>
      </div>
    );
  };

const mapStateToProps = (
  state: ReduxState,
): Pick<
  IProjectArchiveScreenDetailsProps,
  "project" | "teams" | "company"
> => ({
  project: state.projects.projectPayload,
  company: state.company.companyPayload,
  teams: state.teams,
});

const mapDispatchToProps = (
  dispatch: ReduxDispatch,
): Pick<
  IProjectArchiveScreenDetailsProps,
  "dispatchFetchUsers" | "dispatchGetProjectDetail"
> => ({
  dispatchFetchUsers: (data) =>
    dispatch(
      TeamActions.teamGetRequest(
        //@ts-expect-error TS2554
        data,
      ),
    ),
  dispatchGetProjectDetail: (
    //@ts-expect-error TS7006
    id,
  ) =>
    // @ts-expect-error TS2554
    dispatch(ProjectActions.projectGetRequest(id)),
});

ProjectArchiveScreenDetails.propTypes = {
  dispatchGetProjectDetail: PropTypes.func,
  // @ts-expect-error TS2322
  dispatchFetchUsers: PropTypes.func,
  // @ts-expect-error TS2322
  context: PropTypes.object,
  match: PropTypes.object,
  // @ts-expect-error TS2322
  project: PropTypes.object,
  // @ts-expect-error TS2322
  teams: PropTypes.object,
};

export default withCheckProjectAccess(
  // @ts-expect-error TS2345
  connect(mapStateToProps, mapDispatchToProps)(ProjectArchiveScreenDetails),
);
