import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { ContentContext } from "../../../Provider";
import ApiActions, { ApiRequestData } from "../../../Redux/ApiRedux";
import BatchActions from "../../../Redux/BatchRedux";
import { endpoints, parseEndpoint } from "../../../Services/endpoints";

// @ts-expect-error TS7006
const useBatchHypothesisManagementScreen = (props) => {
  const { history } = props;
  const dispatch = useDispatch();
  const { company } = useContext(ContentContext);
  const [batchName, setBatchName] = useState("");
  const [isHypothesisSortable, setIsHypothesisSortable] = useState(false);
  const [displayCheckpoint, setDisplayCheckpoint] = useState(false);
  const [hypothesis, setHypothesis] = useState([]);
  const [isNewHypothesisModalVisible, setIsNewHypothesisModalVisible] =
    useState(false);
  const getBatchId = () => {
    const {
      match: { params },
    } = props;
    return params.id ? params.id : -1;
  };
  const [defaultHypothesisData, setDefaultHypothesisData] = useState({
    batchId: getBatchId(),
    hypothesisFormMetadata: {
      displayHypothesis: true,
      firstViewHypothesis: true,
      hypothesisFormDescription: "",
      hypothesisFormName: "",
      useInfoMark: true,
    },
  });
  const getBatchDetails = () => {
    const requestBuilder: ApiRequestData = {
      method: "getRequest",
      url: parseEndpoint(endpoints.singleBatch, {
        batchId: getBatchId(),
      }),
      data: {},
    };
    dispatch(
      ApiActions.apiRequest(requestBuilder, ({ data }) => {
        //@ts-expect-error 要API型定義
        setBatchName(data.name);
        //@ts-expect-error 要API型定義
        setDisplayCheckpoint(data.displayCheckpoint);
      }),
    );
  };
  const saveOrder = () => {
    const newOrder = hypothesis.map((h, index) => {
      return {
        // @ts-expect-error TS2339
        id: h.id,
        order: index,
      };
    });
    const data = {};
    for (let i = 0; i < newOrder.length; i++) {
      const key = newOrder[i].id;
      const value = newOrder[i].order;
      // @ts-expect-error TS7053
      data[key] = value;
    }
    const requestBuilder: ApiRequestData = {
      method: "putRequest",
      url: parseEndpoint(endpoints.updateBatchHypothesisOrder),
      data: data,
    };
    dispatch(
      ApiActions.apiRequest(requestBuilder, ({ data }) => {
        _getHypothesis();
      }),
    );
  };
  const _getHypothesis = () => {
    const requestBuilder: ApiRequestData = {
      method: "getRequest",
      url: parseEndpoint(endpoints.batchHypothesis, {
        batchId: getBatchId(),
      }),
      data: {},
    };
    dispatch(
      ApiActions.apiRequest(requestBuilder, ({ data }) => {
        setIsHypothesisSortable(false);
        if (Array.isArray(data)) {
          data.sort((a, b) => {
            return (
              a.hypothesisFormMetadata.order - b.hypothesisFormMetadata.order
            );
          });
          // @ts-expect-error TS2345
          setHypothesis(data);
        }
      }),
    );
  };
  useEffect(() => {
    _getHypothesis();
    getBatchDetails();
  }, []);
  // @ts-expect-error TS7006
  const onSaveHypothesis = (hyptothesisData) => {
    const requestBuilder: ApiRequestData = {
      method: hyptothesisData.id ? "putRequest" : "postRequest",
      url: hyptothesisData.id
        ? parseEndpoint(endpoints.singleBatchHypothesis, {
            id: hyptothesisData.id,
          })
        : parseEndpoint(endpoints.saveBatchHypothesis),
      data: hyptothesisData,
    };
    dispatch(
      ApiActions.apiRequest(requestBuilder, ({ data }) => {
        setIsNewHypothesisModalVisible(false);
        _getHypothesis();
        _clearDefaultHypothesis();
      }),
    );
  };
  const _clearDefaultHypothesis = () => {
    setDefaultHypothesisData({
      batchId: getBatchId(),
      hypothesisFormMetadata: {
        displayHypothesis: true,
        firstViewHypothesis: true,
        hypothesisFormDescription: "",
        hypothesisFormName: "",
        useInfoMark: true,
      },
    });
  };

  const onUpdateBatchDisplayCheckpoint = (checked: boolean) => {
    const requestBuilder: ApiRequestData = {
      method: "putRequest",
      url: parseEndpoint(endpoints.singleBatch, {
        batchId: getBatchId(),
      }),
      data: {
        displayCheckpoint: checked,
      },
    };
    dispatch(
      ApiActions.apiRequest(requestBuilder, ({ data }) => {
        setDisplayCheckpoint(checked);
        // @ts-expect-error TS2554
        dispatch(BatchActions.batchGetsRequest(""));
      }),
    );
  };

  return {
    company,
    history,
    batchName,
    isHypothesisSortable,
    hypothesis,
    isNewHypothesisModalVisible,
    displayCheckpoint,
    getBatchId,
    defaultHypothesisData,
    getBatchDetails,
    saveOrder,
    _getHypothesis,
    onSaveHypothesis,
    _clearDefaultHypothesis,
    setIsNewHypothesisModalVisible,
    setIsHypothesisSortable,
    setDefaultHypothesisData,
    setHypothesis,
    onUpdateBatchDisplayCheckpoint,
  };
};

export default useBatchHypothesisManagementScreen;
