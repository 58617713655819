import { Table } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";

import { ButtonCV, ButtonUndo, Tag } from "../../Components/atoms";
import { CustomPagination } from "../../Components/molecules";
import { endpoints, parseEndpoint } from "../../Services/endpoints";
import { getDisplayName, getObjUUID, getUserAvatar } from "../../utils/common";
import {
  adminUser,
  dateTimeFormat,
  generalUser,
  mentorUser,
  programManager,
} from "../../utils/constants";
import { getInitStorageValue } from "../../utils/localStorage";
import { IMembersInactiveProps } from "./types/IMembersScreenProps";
const PageInactiveSaveId = "MembersScreen_InActive";
const inactiviePageKey = `pagination_page_${PageInactiveSaveId}`;
const inactivieLimitKey = `pagination_limit_${PageInactiveSaveId}`;

const MembersInactive = (props: IMembersInactiveProps) => {
  const intl = useIntl();
  const [inactiveUsers, setInactiveUsers] = useState<{
    rows: any[];
    count: number;
  }>({ rows: [], count: 0 });
  const [inactiveLimit, setInactieLimit] = useState<number>(
    getInitStorageValue(inactivieLimitKey, process.env.REACT_APP_PAGE_SIZE),
  );
  const [inactiveUsersPage, setInactiveUsersPage] = useState<number>(
    getInitStorageValue(inactiviePageKey, 1),
  );

  useEffect(() => {
    if (props.activeTab === "inactive") {
      getInactiveUsers();
    }
  }, [props.activeTab, inactiveLimit, inactiveUsersPage]);
  // get inactive users
  const getInactiveUsers = (sorter?: any) => {
    const { apiRequest } = props.context;
    let url = `users/inactive?page=${inactiveUsersPage - 1}&limit=${
      inactiveLimit || process.env.REACT_APP_PAGE_SIZE
    }`;
    if (sorter && sorter.field && sorter.order != undefined) {
      url += `&sortBy=${sorter.field}&sortOrder=${sorter.order}`;
    }
    const requestbuilder = {
      method: "getRequest",
      url,
      data: {},
    };
    // @ts-expect-error TS2345
    apiRequest(requestbuilder, ({ data }: { data: any }) => {
      setInactiveUsers(data);
    });
  };

  const disapproveUserRequest = (userDetails: any) => {
    const { apiRequest } = props.context;
    const requestBuilder = {
      method: "deleteRequest",
      successMessage: intl.formatMessage({
        id: "screen.label.rejected_registration_request",
      }),
      url: parseEndpoint(endpoints.updateUserProfile, {
        data: {
          id: getObjUUID(userDetails),
        },
      }),
      data: {},
    };
    // @ts-expect-error TS2345
    apiRequest(requestBuilder, ({}) => {
      getInactiveUsers();
      props.performMainSearch();
    });
  };

  const changeStatusPendingUserRequest = (
    userDetails: any,
    data: any,
    successMessage = intl.formatMessage({
      id: "screen.label.approved_registration_request",
    }),
  ) => {
    const { apiRequest } = props.context;
    const updateUserRequest = {
      method: "putRequest",
      successMessage,
      url: parseEndpoint(endpoints.updateUserProfile, {
        data: {
          id: getObjUUID(userDetails),
        },
      }),
      data,
    };
    // @ts-expect-error TS2345
    apiRequest(updateUserRequest, ({}) => {
      getInactiveUsers();
      props.performMainSearch();
    });
  };

  const rowsOfInactiveUsers = () => {
    if (Array.isArray(inactiveUsers.rows)) {
      return inactiveUsers.rows.filter((u) => !u.isActive);
    }
    return [];
  };

  const getColumnsInactiveUsers = () => {
    return [
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
        responsive: ["sm"],
        sortDirections: ["descend", "ascend"],
        sorter: true,
      },
      {
        title: intl.formatMessage({
          id: "screen.events.full_name",
        }),
        dataIndex: "name",
        key: "name",
        sortDirections: ["descend", "ascend"],
        sorter: true,
        render: function _fname(text: any, row: any, index: any) {
          if (row.name === "" && row.createdAt === row.updatedAt) {
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <div style={{ minWidth: 50 }}>{getUserAvatar(row)}</div>
                <Link to={"/dashboard/member/" + getObjUUID(row)}>
                  {intl.formatMessage({
                    id: "screen.label.registration_waiting_invitation",
                  })}
                </Link>
              </div>
            );
          }
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div style={{ minWidth: 50 }}>{getUserAvatar(row)}</div>
              <div style={{ maxWidth: "20vw" }}>
                <Link to={"/dashboard/member/" + getObjUUID(row)}>
                  {getDisplayName(row)}
                </Link>
              </div>
            </div>
          );
        },
      },
      {
        title: intl.formatMessage({
          id: "screen.label.email",
        }),
        dataIndex: "email",
        key: "email",
        sortDirections: ["descend", "ascend"],
        sorter: true,
        render: function _fname(text: any, row: any, index: any) {
          return (
            <div style={{ width: "15vw", wordWrap: "break-word" }}>
              {row?.Credential?.email}
            </div>
          );
        },
      },
      {
        title: intl.formatMessage({
          id: "screen.label.user_type",
        }),
        dataIndex: "type",
        sortDirections: ["descend", "ascend"],
        sorter: true,
        render: function _fname(text: any, data: any, index: any) {
          if (data.type === "employee")
            return <Tag className="tag-selected">{generalUser}</Tag>;
          if (data.type === "mentor")
            return <Tag className="tag-selected">{mentorUser}</Tag>;
          if (
            data.type === "admin" &&
            (data.isSuper === false || data.isSuper === null)
          )
            return <Tag className="tag-selected">{programManager}</Tag>;
          if (data.type === "admin" && data.isSuper === true)
            return <Tag className="tag-selected">{adminUser}</Tag>;
        },
      },
      {
        title: intl.formatMessage({
          id: "screen.users.request_at",
        }),
        dataIndex: "createdAt",
        sortDirections: ["descend", "ascend"],
        sorter: true,
        render: function _fname(text: any, row: any, index: any) {
          return <span>{moment(text).local().format(dateTimeFormat)}</span>;
        },
      },
      {
        render: function _fname(text: any, row: any, index: any) {
          return (
            <span>
              <ButtonUndo
                style={{ margin: 10 }}
                onClick={() => {
                  disapproveUserRequest(row);
                }}
              >
                {intl.formatMessage({
                  id: "screen.label.rejection",
                })}
              </ButtonUndo>
              <ButtonCV
                style={{
                  margin: 10,
                }}
                onClick={() => {
                  changeStatusPendingUserRequest(row, {
                    isActive: true,
                  });
                }}
              >
                {intl.formatMessage({
                  id: "screen.label.approval",
                })}
              </ButtonCV>
            </span>
          );
        },
      },
    ];
  };

  return (
    <>
      <br />
      <Table
        pagination={false}
        rowKey={"id"}
        // @ts-expect-error TS2322
        columns={getColumnsInactiveUsers()}
        dataSource={rowsOfInactiveUsers()}
        className="ant-table-x-scroll"
        onChange={(pagination, filters, sorter) => {
          getInactiveUsers(sorter);
        }}
      />
      <br />
      <CustomPagination
        saveId={PageInactiveSaveId}
        count={inactiveUsers.count}
        onChangePage={(inactiveUsersPage, inactiveLimit) => {
          setInactieLimit(inactiveLimit);
          setInactiveUsersPage(inactiveUsersPage);
        }}
      />
    </>
  );
};

export default MembersInactive;
