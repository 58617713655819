import { Row } from "antd";

import useProjectArchiveDetailsCard from "../../../Screens/project/hooks/useProjectArchiveDetailsCard";
import { IProjectArchiveDetailsCardProps } from "../../../Screens/project/types/IProjectArchiveDetailsCardProps";
import {
  getHypothesisObjectFromArray,
  prettifyDiffWithSign,
} from "../../../utils/projectUtils";
import { Button } from "../../atoms";
import { HypothesisName } from "../../molecules/project/HypothesisName";
import ProjectArchiveHypothesis from "./ProjectArchiveHypothesis";

const ProjectArchiveDetailsCard = function (
  props: IProjectArchiveDetailsCardProps,
) {
  const { saveFile } = useProjectArchiveDetailsCard(props);

  // @ts-expect-error TS7006
  const _renderProjectHypothesis = (archive) => {
    const project = archive.record;
    const projectRecord = getHypothesisObjectFromArray(archive.record);
    const merged = props.projectSetting;
    return merged.map((hypothesis, index) => {
      let ProjectHypothesisBlock = <></>;
      const metadata = hypothesis.hypothesisFormMetadata;
      if (hypothesis.meta) {
        let content =
          project["meta"] !== undefined
            ? project["meta"][hypothesis.hypothesisFormId]
            : "";
        if (
          hypothesis.hypothesisFormId === "fileUrl" &&
          project["meta"] != null
        ) {
          const fileUrl =
            project.meta.fileUrl && project.meta.fileUrl.length > 0
              ? project.meta.fileUrl
              : "";
          const uploadUrl =
            project.meta.uploadUrl && project.meta.uploadUrl.length > 0
              ? project.meta.uploadUrl
              : "";
          const fileUrlName = project.meta.fileUrlName
            ? project.meta.fileUrlName
            : fileUrl;
          const filesUrl = [];
          if (fileUrl.length > 0) {
            filesUrl.push(fileUrl);
          }
          if (uploadUrl.length > 0) {
            filesUrl.push(uploadUrl);
          }
          content = (
            <>
              {fileUrl.length > 0 ? (
                <Button href={fileUrl} target="_blank" type="link">
                  {fileUrlName}
                </Button>
              ) : null}

              {uploadUrl.length > 0 ? (
                <Button
                  onClick={() => {
                    const urlToDisplay = uploadUrl;
                    const href =
                      urlToDisplay && urlToDisplay.length > 0
                        ? urlToDisplay.indexOf("http") === -1
                          ? window.location.protocol + "//" + urlToDisplay
                          : urlToDisplay
                        : "";
                    saveFile(
                      href,
                      project?.meta?.friendlyFileName
                        ? project?.meta?.friendlyFileName
                        : "download",
                    );
                  }}
                  target="_blank"
                  type="link"
                >
                  {project?.meta?.friendlyFileName
                    ? project?.meta?.friendlyFileName
                    : "download"}
                </Button>
              ) : null}
            </>
          );
        }
        ProjectHypothesisBlock = (
          // @ts-expect-error TS2741
          <ProjectArchiveHypothesis
            key={hypothesis.hypothesisFormId}
            content={content}
          />
        );
      } else {
        ProjectHypothesisBlock = (
          // @ts-expect-error TS2741
          <ProjectArchiveHypothesis
            key={hypothesis.hypothesisFormId}
            content={projectRecord[hypothesis.hypothesisFormId]}
          />
        );
      }
      return (
        <div key={index}>
          <Row justify="start" align="middle">
            <HypothesisName>{metadata.hypothesisFormName}</HypothesisName>
          </Row>
          {ProjectHypothesisBlock}
        </div>
      );
    });
  }; //
  // @ts-expect-error TS7006
  const _renderChangeProjectHypothesis = (diff, showType) => {
    if (!diff) {
      return null;
    }
    const mergedHypothesis = props.projectSetting;
    return mergedHypothesis
      .filter((hypothesis, index) => {
        if (diff[index].length > 0) {
          return true;
        }
        return false;
      })
      .map((hypothesis, index) => {
        const findIndex = mergedHypothesis.findIndex(
          (h) => h.hypothesisFormId === hypothesis.hypothesisFormId,
        );
        let ProjectHypothesisBlock = <></>;
        const metadata = hypothesis.hypothesisFormMetadata;
        if (hypothesis.meta) {
          ProjectHypothesisBlock = <></>;
        } else {
          ProjectHypothesisBlock = (
            <ProjectArchiveHypothesis
              key={hypothesis.hypothesisFormId}
              content={prettifyDiffWithSign(diff[findIndex])}
              isRawHtml={true}
            />
          );
        }
        return (
          <div key={index}>
            <Row justify="start" align="middle">
              <HypothesisName>{metadata.hypothesisFormName}</HypothesisName>
            </Row>
            {ProjectHypothesisBlock}
          </div>
        );
      });
  }; //
  // @ts-expect-error TS7006
  const _renderCompleteProjectHypothesis = (diff, showType) => {
    if (!diff) {
      return null;
    }
    const mergedHypothesis = props.projectSetting;
    return mergedHypothesis.map((hypothesis, index) => {
      let ProjectHypothesisBlock = <></>;
      const metadata = hypothesis.hypothesisFormMetadata;
      if (hypothesis.meta) {
        ProjectHypothesisBlock = <></>;
      } else {
        ProjectHypothesisBlock = (
          <ProjectArchiveHypothesis
            key={hypothesis.hypothesisFormId}
            content={prettifyDiffWithSign(diff[index])}
            isRawHtml={true}
          />
        );
      }
      return (
        <div key={index}>
          <Row justify="start" align="middle">
            <HypothesisName>{metadata.hypothesisFormName}</HypothesisName>
          </Row>
          {ProjectHypothesisBlock}
        </div>
      );
    });
  }; //

  const diffOnly = props.diffOnly;
  const diffComplete = props.diffComplete;
  const archive = props.archive;
  const showType = props.showType;
  if (props.selectedArchives.length < 2) {
    return _renderProjectHypothesis(archive);
  }
  if (showType === 1) {
    return _renderChangeProjectHypothesis(diffOnly, showType);
  }
  return _renderCompleteProjectHypothesis(diffComplete, showType);
};
export default ProjectArchiveDetailsCard;
