import { Checkbox, Modal, Row } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { useCallback, useState } from "react";
import { useIntl } from "react-intl";

import { AntdButton } from "../../../../shared/buttons/components/AntdButton";
import styles from "./styles.module.scss";
import { ExaminationPhaseDeleteConfirmModalProps } from "./type";

export const ExaminationPhaseDeleteConfirmModal = ({
  visible,
  onDelete,
  onDeleteCancel,
}: ExaminationPhaseDeleteConfirmModalProps) => {
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const intl = useIntl();

  const title = intl.formatMessage({
    id: "screen.label.examination_phase_delete_confirm_modal_header",
  });
  const content = intl.formatMessage({
    id: "screen.label.examination_phase_delete_confirm_modal_content",
  });
  const okText = intl.formatMessage({ id: "screen.label.execute_delete" });
  const cancelText = intl.formatMessage({ id: "screen.label.cancel" });
  const confirmText = intl.formatMessage({
    id: "screen.label.examination_phase_delete_confirmed",
  });

  const onChange = useCallback((e: CheckboxChangeEvent) => {
    setIsChecked(e.target.checked);
  }, []);

  const deleteExaminationPhase = useCallback(() => {
    onDelete();
    setIsChecked(false);
  }, [onDelete]);

  const deleteCancel = useCallback(() => {
    onDeleteCancel();
    setIsChecked(false);
  }, [onDeleteCancel]);

  return (
    <Modal
      onCancel={deleteCancel}
      footer={null}
      title={title}
      visible={visible}
    >
      <div className={styles.content_container}>
        <p className={styles.text}>{content}</p>
        <Checkbox onChange={onChange} checked={isChecked}>
          {confirmText}
        </Checkbox>
        <Row>
          <AntdButton
            category="secondary"
            onClick={deleteCancel}
            className={styles.delete_btn}
          >
            {cancelText}
          </AntdButton>
          <AntdButton
            category="danger"
            className={styles.cancel_btn}
            disabled={!isChecked}
            onClick={deleteExaminationPhase}
          >
            {okText}
          </AntdButton>
        </Row>
      </div>
    </Modal>
  );
};
