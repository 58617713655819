import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";

import { PublishedReviewResultDetail } from "@/../types/PublishedReviewResultDetail";

const ProjectExaminationReview = ({
  publishedReviewResult,
  projectId,
}: {
  publishedReviewResult: PublishedReviewResultDetail;
  projectId: string;
}) => {
  const intl = useIntl();
  const projectArchiveUrl = `/dashboard/project.archive/${projectId}/${publishedReviewResult.projectArchiveId}`;

  return (
    <>
      <h3
        style={{
          fontStyle: "normal",
          fontWeight: "bold",
          fontSize: "0.9rem",
          lineHeight: "200%",
          color: "#323232",
        }}
      >
        {intl.formatMessage({
          id: "screen.label.submitted_phase",
        })}
      </h3>
      <p>{publishedReviewResult.examinationPhaseName}</p>
      <br />

      <h3
        style={{
          fontStyle: "normal",
          fontWeight: "bold",
          fontSize: "0.9rem",
          lineHeight: "200%",
          color: "#323232",
        }}
      >
        {intl.formatMessage({
          id: "screen.label.submitted_category",
        })}
      </h3>
      <p>{publishedReviewResult.categoryName}</p>
      <br />

      <h3
        style={{
          fontStyle: "normal",
          fontWeight: "bold",
          fontSize: "0.9rem",
          lineHeight: "200%",
          color: "#323232",
        }}
      >
        {intl.formatMessage({
          id: "screen.label.submitted_version",
        })}
      </h3>
      <Link to={projectArchiveUrl}>URL</Link>
      <br />
      <br />

      <h3
        style={{
          fontStyle: "normal",
          fontWeight: "bold",
          fontSize: "0.9rem",
          lineHeight: "200%",
          color: "#323232",
        }}
      >
        {intl.formatMessage({
          id: "screen.label.examination_outcome",
        })}
      </h3>
      <p>
        {publishedReviewResult.result &&
        publishedReviewResult.result !== "BLANK"
          ? intl.formatMessage({
              id: `screen.label.${publishedReviewResult.result}`.toLowerCase(),
            })
          : ""}
      </p>
      <br />

      <h3
        style={{
          fontStyle: "normal",
          fontWeight: "bold",
          fontSize: "0.9rem",
          lineHeight: "200%",
          color: "#323232",
        }}
      >
        {intl.formatMessage({
          id: "screen.label.feedback_comments",
        })}
      </h3>
      <p
        style={{
          whiteSpace: "pre-line",
        }}
      >
        {publishedReviewResult.feedbackComment}
      </p>
      <br />
    </>
  );
};
ProjectExaminationReview.propTypes = {
  submittedProject: PropTypes.any,
  projectId: PropTypes.string,
  intl: PropTypes.any,
};

export default ProjectExaminationReview;
