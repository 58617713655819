import { useEffect, useRef } from "react";
import { confirmAlert } from "react-confirm-alert";
import { useIntl } from "react-intl";

import { endpoints, parseEndpoint } from "../../../Services/endpoints";
import { useStateWithRef } from "../../../utils/hooks";
import { getInitStorageValue } from "../../../utils/localStorage";
import { IEventMembersListScreenProps } from "../types/IEventMembersListScreenProps";

const PageSaveId = "EventMembersListScreen";
const pageKey = `pagination_page_${PageSaveId}`;
const limitKey = `pagination_limit_${PageSaveId}`;

const useEventMembersListScreen = (props: IEventMembersListScreenProps) => {
  const intl = useIntl();
  const { apiRequest, updatePageTitle } = props.context;
  const [state, customSetState, stateRef] = useStateWithRef(() => {
    return {
      page: getInitStorageValue(pageKey, 1),
      limit: getInitStorageValue(limitKey, process.env.REACT_APP_PAGE_SIZE),
      eventReminderModalIsVisible: false,
      eventSurveyModalIsVisible: false,
      fcfsEventReminderModalIsVisible: false,
    };
  });

  useEffect(() => {
    updatePageTitle("screen.label.events_members");
    fetchEventMembers(stateRef.current.page, stateRef.current.limit);
    _fetchEventDetails(getEventId());
  }, []);

  // @ts-expect-error TS7006
  const _fetchEventDetails = (eventUid) => {
    const requestBuilder = {
      method: "getRequest",
      url: parseEndpoint(endpoints.singleEvent, { id: eventUid }),
      data: {},
    };
    // @ts-expect-error TS2345
    apiRequest(requestBuilder, ({ data }) => {
      setState({
        eventDetail: data,
      });
    });
  };
  // @ts-expect-error TS7006
  const fetchEventMembers = (page, limit) => {
    props.dispatchFetchEventMembers({
      page: page - 1,
      limit,
      eventId: getEventId(),
      includeDeletedEvent: true,
    });
  };
  const getEventId = () => {
    const {
      match: { params },
    } = props;
    return params.id ? params.id : -1;
  };
  // @ts-expect-error TS7006
  const onChangeMemberEventsPage = (page, limit) => {
    fetchEventMembers(page, limit);
    setState({
      page,
      limit,
    });
  };
  const setStateCallbackRef = useRef(() => {});
  useEffect(() => {
    const callBack = setStateCallbackRef.current;
    callBack();
    setStateCallbackRef.current = () => {};
  }, [state]);
  const setState = (data: any, callback = () => {}) => {
    setStateCallbackRef.current = callback;
    // @ts-expect-error TS7006
    customSetState((previousState) => {
      return {
        ...previousState,
        ...data,
      };
    });
  };
  // @ts-expect-error TS7006
  const _canAttendCheckBox = (requestPayload, message) => {
    confirmAlert({
      message,
      buttons: [
        {
          label: intl.formatMessage({
            id: "screen.label.confirm_email_notification",
          }),
          onClick: () => {
            constUpdateEventMember({
              ...requestPayload,
              sendEmail: true,
            });
          },
        },
        {
          label: intl.formatMessage({
            id: "screen.label.confirm_without_notification",
          }),
          onClick: () => {
            constUpdateEventMember({
              ...requestPayload,
              sendEmail: false,
            });
          },
        },
        {
          label: intl.formatMessage({
            id: "screen.label.cancel",
          }),
          onClick: () => {},
        },
      ],
    });
  };
  // @ts-expect-error TS7006
  const constUpdateEventMember = (updatedData) => {
    const { eventId, userId, ...rest } = updatedData;
    const requestBuilder = {
      method: "putRequest",
      url: parseEndpoint(endpoints.findEventMember, {
        eventId,
        userId,
      }),
      data: rest,
    };
    // @ts-expect-error TS2345
    apiRequest(requestBuilder, ({ data }) => {
      fetchEventMembers(stateRef.current.page, stateRef.current.limit);
    });
  };
  const onCloseEventReminderModal = () => {
    setState({
      eventReminderModalIsVisible: false,
    });
  };
  const onCloseFCFSEventReminderModal = () => {
    setState({
      fcfsEventReminderModalIsVisible: false,
    });
  };

  return {
    intl,
    state,
    _fetchEventDetails,
    fetchEventMembers,
    getEventId,
    onChangeMemberEventsPage,
    setState,
    _canAttendCheckBox,
    constUpdateEventMember,
    onCloseEventReminderModal,
    onCloseFCFSEventReminderModal,
  };
};

export default useEventMembersListScreen;
