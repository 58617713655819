import { FC, Fragment, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import styled from "styled-components";

import { Thread } from "@/../types/Thread";
import { User } from "@/../types/User";
import { Button } from "@/Components/atoms";
import { getUserAvatar } from "@/utils/common";

const Wrapper = styled.div`
  height: 15px;
  margin-bottom: 15px;
`;

export type CommentFooterProps = {
  threads: Thread[];
  canEdit?: boolean;
  showChildren: boolean;
  onOpenCommentThread: () => void;
};

export const CommentFooter: FC<CommentFooterProps> = ({
  threads,
  canEdit,
  showChildren,
  onOpenCommentThread,
}) => {
  const intl = useIntl();

  const threadUsers = useMemo(() => {
    const userMap = threads.reduce((acc, thread) => {
      acc.set(thread.User.id, thread.User);
      return acc;
    }, new Map<number, User>());
    const uniqueUserIds = [
      ...new Set([...threads.map((thread) => thread.User.id)]),
    ];
    return uniqueUserIds
      .map((userId) => userMap.get(userId))
      .filter((u): u is User => !!u);
  }, [threads]);

  const hasThreads = threads && Array.isArray(threads) && threads.length > 0;

  return (
    <Wrapper>
      {canEdit && (
        <Button
          type="link"
          size="small"
          style={{
            marginRight: 10,
          }}
          onClick={onOpenCommentThread}
        >
          <span style={{}}>
            <FormattedMessage id="screen.program_top.post_reply" />
          </span>
        </Button>
      )}
      {hasThreads &&
        threadUsers.map((user) => (
          <Fragment key={user.id}>
            {getUserAvatar(user, () => {}, {
              height: "15px",
              width: "15px",
            })}
          </Fragment>
        ))}
      {hasThreads && (
        <Button
          onClick={onOpenCommentThread}
          style={{
            padding: 0,
            marginRight: 10,
          }}
          type="link"
        >
          {showChildren
            ? intl.formatMessage({
                id: "screen.label.hide_replies",
              })
            : intl.formatMessage(
                {
                  id: "screen.label.display_replies_count",
                },
                {
                  count: threads.length,
                },
              )}
        </Button>
      )}
    </Wrapper>
  );
};
