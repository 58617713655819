import "react-datepicker/dist/react-datepicker.css";

import { forwardRef } from "react";
import {
  CalendarContainer,
  CalendarContainerProps,
  ReactDatePickerCustomHeaderProps,
} from "react-datepicker";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { RiCalendarCheckLine } from "react-icons/ri";
import { useIntl } from "react-intl";

import { InputWithIcon } from "../InputWithIcon";
import styles from "./styles.module.scss";
import { CustomInputProps, CustomInputRefProps } from "./type";

export const CustomInput = forwardRef(
  (inputProps: CustomInputProps, ref: CustomInputRefProps) => (
    <InputWithIcon Icon={RiCalendarCheckLine} {...inputProps} ref={ref} />
  ),
);

export const CustomHeader = ({
  monthDate,
  decreaseMonth,
  increaseMonth,
}: ReactDatePickerCustomHeaderProps) => {
  const intl = useIntl();

  return (
    <div className={styles.calendar_header}>
      <span className={styles.current_month}>
        {monthDate.toLocaleString(intl.locale, {
          month: "long",
          year: "numeric",
        })}
      </span>
      <div className={styles.arrows_container}>
        <button
          aria-label="Previous Month"
          className={styles.navigation}
          onClick={decreaseMonth}
          type="button"
        >
          <AiOutlineLeft className={styles.navigation_icon} />
        </button>
        <button
          aria-label="Next Month"
          className={styles.navigation}
          onClick={increaseMonth}
          type="button"
        >
          <AiOutlineRight className={styles.navigation_icon} />
        </button>
      </div>
    </div>
  );
};

export const CustomCalendarContainer = ({
  className,
  children,
}: CalendarContainerProps) => {
  return (
    <CalendarContainer className={`${className} ${styles.calendar_container}`}>
      {children}
    </CalendarContainer>
  );
};
