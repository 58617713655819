import { Input, Popconfirm } from "antd";
import { FC, useCallback, useState } from "react";
import { useIntl } from "react-intl";

import { EllipsisTag } from "../EllipsisTag/component";

const INPUT_MAX_WIDTH = 160;

export type EditableTag = {
  tag: string;
  onEdited?: (oldTag: string, newTag: string) => void;
  onDeleted: (tag: string) => void;
};

export const EditableTag: FC<EditableTag> = ({ tag, onEdited, onDeleted }) => {
  const intl = useIntl();

  const [inputValue, setInputValue] = useState("");
  const [showInput, setShowInput] = useState(false);
  const [showPop, setShowPop] = useState(false);

  const handleEdit = useCallback(() => {
    onEdited?.(tag, inputValue);
    setShowInput(false);
    setInputValue("");
  }, [tag, onEdited, inputValue]);

  const handleDelete = useCallback(() => {
    onDeleted(tag);
    setShowPop(false);
  }, [onDeleted, tag]);

  return (
    <>
      {showInput ? (
        <Input
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
          size="small"
          style={{
            maxWidth: INPUT_MAX_WIDTH,
          }}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onBlur={handleEdit}
          onPressEnter={handleEdit}
        />
      ) : (
        <Popconfirm
          visible={showPop}
          title={intl.formatMessage(
            {
              id: "screen.label.delete_tag_confirm",
            },
            { tag },
          )}
          onConfirm={handleDelete}
          onCancel={() => setShowPop(false)}
          okText={intl.formatMessage({
            id: "screen.label.yes",
          })}
          cancelText={intl.formatMessage({
            id: "screen.label.no",
          })}
        >
          <EllipsisTag
            tag={tag}
            onDoubleClick={
              onEdited
                ? () => {
                    setShowInput(true);
                    setInputValue(tag);
                  }
                : undefined
            }
            closable
            onClose={(e) => {
              setShowPop(true);
              e.preventDefault();
            }}
          />
        </Popconfirm>
      )}
    </>
  );
};
