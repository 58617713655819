import { notification } from "antd";
import { ApiResponse } from "apisauce";

import { BaseResponse } from "../commonResponsTypes";

export const responseCallbackWithNotification = (
  response: ApiResponse<BaseResponse<unknown>>,
  message: string,
  successCallback: () => void,
) => {
  if (response.ok && response.data?.status === "success") {
    notification.success({
      message,
    });
    successCallback();
  }
};
