import styles from "@/features/login/components/ExternalLink/styles.module.scss";
import { ExternalLinkProps } from "@/features/login/components/ExternalLink/type";

export const ExternalLink = ({ href, children }: ExternalLinkProps) => {
  return (
    <div className={styles.footer_link_container}>
      <a
        className={styles.styled_link}
        href={href}
        target="_blank"
        rel="noopener noreferrer"
      >
        {children}
      </a>
    </div>
  );
};
