import { Card, Modal } from "antd";
import moment from "moment";
import PropTypes from "prop-types";
import { FC } from "react";
import { connect } from "react-redux";

import { ButtonUndo } from "../../Components/atoms";
import { CustomPagination } from "../../Components/molecules";
import { DraftEditor } from "../../Components/molecules";
import { NotificationComponent } from "../../Components/organisms";
import HomeActions from "../../Redux/HomeRedux";
import { sanitizeEditorStateMarkupHtml } from "../../utils/common";
import useNotificationScreen from "./hooks/useNotificationScreen";
import { INotificationScreenProps } from "./types/INotificationScreenProps";

const PageSaveId = "NotificationScreen";

const NotificationScreen: FC<INotificationScreenProps> = function (props) {
  const {
    state,
    intl,
    onTakeActionOnNotification,
    onPromptModal,
    onTakeActionRegisterEvent,
    onTakeActionNoticeClicked,
    onDeleteNotification,
    getNotificationsRequestion,
    onChangePage,
    handleCancel,
    onCloseNoticeModal,
    onUnRegisterEvent,
    onRegisterForEvent,
  } = useNotificationScreen(props);

  const { user, apiRequest } = props.context;

  const _renderOtherNotifications = () => {
    if (Array.isArray(state.notifications)) {
      // @ts-expect-error TS7006
      return state.notifications.map((notification) => {
        return (
          <NotificationComponent
            refresh={() => {
              getNotificationsRequestion(state.page, state.limit);
            }}
            dispatchApiRequest={apiRequest}
            authToken={user.token}
            currentUserRole={user.type}
            // @ts-expect-error TS2322
            currentUserId={user.id}
            onDeleteNotification={onDeleteNotification}
            onTakeActionNoticeClicked={onTakeActionNoticeClicked}
            notification={notification}
            onPromptModal={onPromptModal}
            onTakeAction={onTakeActionOnNotification}
            onTakeActionRegisterEvent={onTakeActionRegisterEvent}
            key={notification.id}
          />
        );
      });
    }
    return <></>;
  };

  let noticeDetailsNode = <>{state.noticeDetails.description}</>;
  if (state.noticeDetails.nodalData === true) {
    noticeDetailsNode = (
      <DraftEditor
        toolbarHidden={true}
        readOnly={true}
        editorState={sanitizeEditorStateMarkupHtml(state.editorState)}
      />
    );
  }
  return (
    <>
      <Card>{_renderOtherNotifications()}</Card>
      <div className={"card-footer"} style={{ padding: "3%" }}>
        <div style={{ overflow: "hidden" }}>
          <CustomPagination
            saveId={PageSaveId}
            count={state.count}
            onChangePage={onChangePage}
          />
        </div>
      </div>

      <Modal
        title={intl.formatMessage({
          id: "screen.label.events_news",
        })}
        visible={state.eventDetail.id > 0}
        footer={
          <span>
            <ButtonUndo
              loading={state.eventDetail.isMemberStatus == null}
              onClick={
                state.eventDetail.isMemberStatus
                  ? onUnRegisterEvent
                  : onRegisterForEvent
              }
              type={"ghost"}
            >
              {state.eventDetail.isMemberStatus
                ? intl.formatMessage({
                    id: "screen.label.cancel_participation_application",
                  })
                : intl.formatMessage({
                    id: "screen.label.apply_for_participation",
                  })}
            </ButtonUndo>
          </span>
        }
      >
        <h4>
          {intl.formatMessage({
            id: "screen.label.event_name",
          })}
        </h4>
        <p>{state.eventDetail.name}</p>
        <br />
        <h4>
          {intl.formatMessage({
            id: "screen.label.more_detail",
          })}
        </h4>
        <p style={{ whiteSpace: "pre-line" }}>
          {state.eventDetail.description}
        </p>
        <br />

        <h4>
          {intl.formatMessage({
            id: "screen.label.event_date_and_time",
          })}
        </h4>
        <p>
          {moment(state.eventDetail.beginsAt)
            .local()
            .format("YYYY-MM-DD HH:mm:ss")}
        </p>
      </Modal>

      <Modal
        title={state.noticeDetails.noticeType}
        visible={state.noticeDetails.id > 0}
        onOk={onCloseNoticeModal}
        onCancel={onCloseNoticeModal}
        cancelText={intl.formatMessage({
          id: "screen.label.cancel",
        })}
      >
        <h4>
          {intl.formatMessage({
            id: "screen.label.title",
          })}
        </h4>
        <p>{state.noticeDetails.title}</p>
        <br />
        {state.noticeDetails.meta.type === "poll" ? (
          <>
            <h4>URL</h4>
            <a
              rel="noreferrer"
              style={{ whiteSpace: "pre-line" }}
              target={"_blank"}
              href={state.noticeDetails.description}
            >
              {state.noticeDetails.description}
            </a>
          </>
        ) : (
          <>
            <h4>
              {intl.formatMessage({
                id: "screen.label.more_detail",
              })}
            </h4>
            <p style={{ whiteSpace: "pre-line" }}>{noticeDetailsNode}</p>
          </>
        )}
      </Modal>
      <Modal
        title={intl.formatMessage({
          id: "screen.label.members_wanted",
        })}
        visible={state.modalVisible}
        onCancel={handleCancel}
        onOk={() => {
          // @ts-expect-error TS2554
          handleCancel();
          // @ts-expect-error TS18048
          props.history.push(`/dashboard/project.top/${state.modalLink}`);
        }}
        cancelText={intl.formatMessage({
          id: "screen.label.cancel",
        })}
        okText={intl.formatMessage({
          id: "screen.label.check_project",
        })}
      >
        {state.modalTitle}{" "}
        {intl.formatMessage({
          id: "screen.label.there_looking_for_members",
        })}
        <br />
        <p style={{ whiteSpace: "pre-line" }}>{state.modalDesc}</p>
        <h4>
          {intl.formatMessage({
            id: "screen.label.application_method",
          })}
        </h4>
        <ol style={{ paddingLeft: "20px" }}>
          <li>
            {intl.formatMessage({
              id: "screen.label.check_project_des",
            })}
          </li>
          <li>
            {intl.formatMessage({
              id: "screen.label.apply_to_become_project_member_des",
            })}
          </li>
        </ol>
      </Modal>
    </>
  );
};

// @ts-expect-error TS7006
const mapDispatchToProps = (dispatch) => ({
  // @ts-expect-error TS7006
  dispatchTakeActionOnNotification: (data, id, message) =>
    // @ts-expect-error TS2554
    dispatch(HomeActions.homeNotificationActionRequest(data, id, message)),
});

NotificationScreen.propTypes = {
  // @ts-expect-error TS2322
  context: PropTypes.object,
  match: PropTypes.object,
  history: PropTypes.object,
  dispatchTakeActionOnNotification: PropTypes.func,
};
export default connect(null, mapDispatchToProps)(NotificationScreen);
