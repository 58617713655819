import { FileIconVariant } from "@/shared/icons/components/VariantIcon/type";

export const getFileIcon = (extension: string): FileIconVariant => {
  switch (extension) {
    case "jpg":
    case "jpeg":
    case "png":
    case "gif":
    case "bmp":
      return "file-image";
    case "pdf":
      return "file-pdf";
    case "csv":
      return "file-markdown";
    case "xlsx":
      return "file-excel";
    case "xls":
      return "file-excel";
    case "docx":
    case "doc":
      return "file-word";
    case "mp3":
      return "audio";
    case "mp4":
      return "video-camera";
    case "ppt":
    case "pptx":
      return "file-ppt";
    case "txt":
      return "file-text";
    case "zip":
      return "file-zip";
    default:
      return "file-unknown";
  }
};
