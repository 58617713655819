import { Card, Col, Layout, Row } from "antd";
import React from "react";
import { useIntl } from "react-intl";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";

import { ReduxDispatch, ReduxState } from "@/Redux/types";

import { ButtonUndo } from "../../../../Components/atoms";
import { PdfViewer } from "../../../../Components/organisms";
import ApiActions, {
  ApiRequestData,
  ApiSuccessCallback,
} from "../../../../Redux/ApiRedux";
import { endpoints, parseEndpoint } from "../../../../Services/endpoints";
const { Content } = Layout;
type OwnProps = {
  history: Object;
};
type StateProps = {
  auth: Object;
};
type ActionProps = {
  dispatchApiRequest: () => void;
};
type otherProps = {
  match: Object;
};
type Props = StateProps & ActionProps & OwnProps & otherProps;
function IndustryReportDetail(props: Props) {
  const intl = useIntl();
  const [item, setItem] = React.useState(null);
  const dispatch = useDispatch();
  const [itemId] = React.useMemo(() => {
    const {
      // @ts-expect-error TS2339
      match: { params },
    } = props;
    const itemId = params.id ? params.id : -1;
    return [itemId];
  }, [props]);
  React.useEffect(() => {
    if (itemId && itemId !== -1) {
      getIndustryReport();
    }
  }, [itemId]);
  const getIndustryReport = () => {
    const requestBuilder: ApiRequestData = {
      method: "getRequest",
      url: parseEndpoint(endpoints.updateIndustryReport, { id: itemId }),
    };
    dispatch(
      ApiActions.apiRequest(requestBuilder, ({ data }) => {
        //@ts-expect-error 要API型定義
        setItem(data);
      }),
    );
  };
  const url = React.useMemo(() => {
    if (
      item &&
      // @ts-expect-error TS2339
      item.industryReportFiles &&
      // @ts-expect-error TS2339
      item.industryReportFiles.length > 0
    ) {
      // @ts-expect-error TS2339
      if (typeof item.industryReportFiles[0] === "object") {
        // @ts-expect-error TS2339
        return item.industryReportFiles[0].filePath;
        // @ts-expect-error TS2339
      } else if (typeof item.industryReportFiles[0] === "string") {
        // @ts-expect-error TS2339
        return item.industryReportFiles[0];
      }
    }
    return "";
  }, [item]);
  return (
    <Content>
      {item && (
        <Card className="library-news">
          <Row align="middle" justify="center">
            <br />
            <br />
            <div style={{ width: "50vw" }}>
              <Row align="middle" justify="center">
                <PdfViewer url={url} />
              </Row>
              <br />
              <br />
              <Row align="middle">
                {/*
                 // @ts-expect-error TS2339 */}
                <h2>{item.title}</h2>
              </Row>
              <br />
              <br />
              <Row align="middle">
                <Col flex="200px">
                  {intl.formatMessage({
                    id: "screen.label.overview",
                  })}
                </Col>
                <Col flex="none">
                  <span
                    style={{
                      whiteSpace: "pre-line",
                    }}
                  >
                    {/*
                     // @ts-expect-error TS2339 */}
                    {item.summary}
                  </span>
                </Col>
              </Row>
              <hr
                style={{
                  border: "none",
                  borderBottom: "dashed 1px #BDBDBD",
                }}
              />
              <br />
              <Row align="middle">
                <Col flex="200px">
                  {intl.formatMessage({
                    id: "screen.label.theme",
                  })}
                </Col>
                {/*
                 // @ts-expect-error TS2339 */}
                <Col flex="none">{item.theme}</Col>
              </Row>
              <hr
                style={{
                  border: "none",
                  borderBottom: "dashed 1px #BDBDBD",
                }}
              />
              <br />
              <Row align="middle">
                <Col flex="200px">
                  {intl.formatMessage({
                    id: "screen.label.industry_medium_category",
                  })}
                </Col>
                {/*
                 // @ts-expect-error TS2339 */}
                <Col flex="none">{item.IndustryReportMainCategory.name}</Col>
              </Row>
              <hr
                style={{
                  border: "none",
                  borderBottom: "dashed 1px #BDBDBD",
                }}
              />
              <br />
              <Row align="middle">
                <Col flex="200px">
                  {intl.formatMessage({
                    id: "screen.label.industry_sub_category",
                  })}
                </Col>
                {/*
                 // @ts-expect-error TS2339 */}
                <Col flex="none">{item.IndustryReportSubCategory.name}</Col>
              </Row>
              <br />
              <Row align="middle" justify="center">
                <ButtonUndo
                  onClick={() => {
                    window.history.back();
                  }}
                  style={{
                    marginTop: "1px",
                  }}
                >
                  {intl.formatMessage({
                    id: "screen.label.close_preview",
                  })}
                </ButtonUndo>
              </Row>
              <br />
              <br />
            </div>
          </Row>
        </Card>
      )}
    </Content>
  );
}
const mapStateToProps = (state: ReduxState) => ({
  auth: state.auth,
});
const mapDispatchToProps = (dispatch: ReduxDispatch) => ({
  dispatchApiRequest: (data: ApiRequestData, callback: ApiSuccessCallback) =>
    dispatch(ApiActions.apiRequest(data, callback)),
});
export default withRouter(
  // @ts-expect-error TS2345
  connect(mapStateToProps, mapDispatchToProps)(IndustryReportDetail),
);
