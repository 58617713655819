import React from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import ApiActions, { ApiRequestData } from "../../../Redux/ApiRedux";
import HomeActions from "../../../Redux/HomeRedux";
import { endpoints, parseEndpoint } from "../../../Services/endpoints";

const useWatchSettingsScreen = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [watchListFromServer, setWatchListFromServer] = React.useState([]);
  const watchListRef = React.useRef();
  // @ts-expect-error TS2339
  const authPayload = useSelector((state) => state.auth.payload);
  const userId = authPayload.id;
  const userType = authPayload.type;
  React.useEffect(() => {
    // @ts-expect-error TS2554
    dispatch(HomeActions.pageTitle("screen.label.watch_setting"));
    getWatchList();
  }, []);
  const getWatchList = () => {
    const requestBuilder: ApiRequestData = {
      method: "getRequest",
      url: parseEndpoint(endpoints.userWatchList, {
        userId,
      }),
      data: {},
    };
    dispatch(
      ApiActions.apiRequest(requestBuilder, ({ data }) => {
        //@ts-expect-error 要API型定義
        setWatchListFromServer(data);
      }),
    );
  };
  // @ts-expect-error TS7006
  const changeWatchStatus = (watchType, isChecked) => {
    const requestBuilder: ApiRequestData = {
      method: "putRequest",
      url: parseEndpoint(endpoints.userWatchList, {
        userId,
      }),
      data: { watchType, isChecked },
      successMessage: intl.formatMessage({
        id: "screen.label.setting_has_been_saved",
      }),
    };
    dispatch(
      ApiActions.apiRequest(requestBuilder, ({ data }) => {
        //@ts-expect-error 要API型定義
        setWatchListFromServer(data);
      }),
    );
  };
  // @ts-expect-error TS7006
  const checkedWatchState = (list, obj) => {
    // @ts-expect-error TS7006
    const match = list.filter((e) => e.watchType === obj.watchType);
    if (match.length > 0) {
      return match[0].isChecked;
    }
    if (userType == "admin" || userType == "mentor") {
      return false;
    } else {
      return false;
    }
  };

  return {
    intl,
    watchListFromServer,
    watchListRef,
    changeWatchStatus,
    checkedWatchState,
  };
};

export default useWatchSettingsScreen;
