import { Col } from "antd";
import styled from "styled-components";

export const SingleHypoTitle = styled.h1`
  font-weight: 700;
  font-size: 20px;
  color: #000;
`;
export const SingleHypoCol = styled(Col)`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -0.5em;
  margin-right: 0.5em;
`;
export const SingleHypoComponent = styled.div`
  margin-bottom: 1vh;
  margin-top: 3vh;
  border-width: 1;
  border-radius: 8px;
  padding: 1rem;
  background-color: white;
`;
