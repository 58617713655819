import styles from "@/features/review/components/ExcelFileNameLabel/styles.module.scss";
import { ExcelFileNameLabelProps } from "@/features/review/components/ExcelFileNameLabel/type";
import { CrossIconButton } from "@/shared/buttons/components/CrossIconButton";
import { AlertIcon } from "@/shared/icons/components/AlertIcon";
import { CircleCheckIcon } from "@/shared/icons/components/CircleCheckIcon";

export const ExcelFileNameLabel = ({
  file,
  hasError,
  removeFile,
}: ExcelFileNameLabelProps) => {
  const containerColorClass = hasError
    ? styles.file_name_container_error_color
    : styles.file_name_container_base_color;
  return (
    <div className={`${styles.file_name_container} ${containerColorClass}`}>
      <div className={styles.left_contents}>
        {hasError ? <AlertIcon /> : <CircleCheckIcon />}
        <span className={styles.file_name}>{file.name}</span>
      </div>
      <CrossIconButton alt="delete-attached-file-button" onClick={removeFile} />
    </div>
  );
};
