import { forwardRef, LegacyRef } from "react";

import styles from "./styles.module.scss";
import { CheckboxWithLabelProps } from "./type";

export const CheckboxWithLabel = forwardRef(
  (
    { label, ...inputProps }: CheckboxWithLabelProps,
    ref: LegacyRef<HTMLInputElement>,
  ) => {
    return (
      <label className={styles.container}>
        <input ref={ref} type="checkbox" {...inputProps} />
        {label}
      </label>
    );
  },
);
