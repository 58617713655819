import { Action } from "@reduxjs/toolkit";
import { createActions, createReducer } from "reduxsauce";
import Immutable from "seamless-immutable";

/* ------------- Types and Action Creators ------------- */
import { ToAction, ToActionType } from "./types";

/* ------------- Types and Action Creators ------------- */
type NewsCommentActionCreators = {
  newsCommentCreateRequest: () => Action<"NEWS_COMMENT_CREATE_REQUEST">;
  newsCommentCreateSuccess: () => Action<"NEWS_COMMENT_CREATE_SUCCESS">;
  newsCommentCreateFailure: () => Action<"NEWS_COMMENT_CREATE_FAILURE">;

  newsCommentUpdateRequest: () => Action<"NEWS_COMMENT_UPDATE_REQUEST">;
  newsCommentUpdateSuccess: () => Action<"NEWS_COMMENT_UPDATE_SUCCESS">;
  newsCommentUpdateFailure: () => Action<"NEWS_COMMENT_UPDATE_FAILURE">;

  newsCommentDeleteRequest: () => Action<"NEWS_COMMENT_DELETE_REQUEST">;
  newsCommentDeleteSuccess: () => Action<"NEWS_COMMENT_DELETE_SUCCESS">;
  newsCommentDeleteFailure: () => Action<"NEWS_COMMENT_DELETE_FAILURE">;

  newsCommentGetsRequest: () => Action<"NEWS_COMMENT_GETS_REQUEST">;
  newsCommentGetsSuccess: () => Action<"NEWS_COMMENT_GETS_SUCCESS">;
  newsCommentGetsFailure: () => Action<"NEWS_COMMENT_GETS_FAILURE">;
};

type NewsCommentAction = ToAction<NewsCommentActionCreators>;
type NewsCommentActionType = ToActionType<NewsCommentAction>;

const { Types, Creators } = createActions<
  NewsCommentActionType,
  NewsCommentActionCreators
>({
  newsCommentCreateRequest: ["data", "message"],
  newsCommentCreateSuccess: null,
  newsCommentCreateFailure: ["errors"],

  newsCommentUpdateRequest: ["data", "message"],
  newsCommentUpdateSuccess: null,
  newsCommentUpdateFailure: ["errors"],

  newsCommentDeleteRequest: ["data", "message"],
  newsCommentDeleteSuccess: null,
  newsCommentDeleteFailure: ["errors"],

  newsCommentGetsRequest: ["data"],
  newsCommentGetsSuccess: ["newsCommentsPayload"],
  newsCommentGetsFailure: ["errors"],
});

export const NewsCommentTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
type NewsCommentState = {
  fetching: boolean;
  errors: unknown;
  error?: unknown;
  newsCommentsPayload: {
    rows: unknown[];
    count: number;
  };
};

export const INITIAL_STATE = Immutable<NewsCommentState>({
  fetching: false,
  errors: {},
  newsCommentsPayload: {
    rows: [],
    count: 0,
  },
});

type ImmNewsCommentState = typeof INITIAL_STATE;
/* ------------- Reducers ------------- */
const newsCommentCreateRequest = (state: ImmNewsCommentState) =>
  state.merge({ fetching: true });

const newsCommentCreateSuccess = (state: ImmNewsCommentState) => {
  return state.merge({ fetching: false });
};

// @ts-expect-error TS7031
const newsCommentCreateFailure = (state: ImmNewsCommentState, { error }) =>
  state.merge({ fetching: false, error });

const newsCommentDeleteRequest = (state: ImmNewsCommentState) =>
  state.merge({ fetching: true });

const newsCommentDeleteSuccess = (state: ImmNewsCommentState) => {
  return state.merge({ fetching: false });
};

// @ts-expect-error TS7031
const newsCommentDeleteFailure = (state: ImmNewsCommentState, { error }) =>
  state.merge({ fetching: false, error });

const newsCommentGetsRequest = (state: ImmNewsCommentState) =>
  state.merge({ fetching: true });

const newsCommentGetsSuccess = (
  state: ImmNewsCommentState,
  // @ts-expect-error TS7031
  { newsCommentsPayload },
) => {
  return state.merge({ fetching: false, newsCommentsPayload });
};

// @ts-expect-error TS7031
const newsCommentGetsFailure = (state: ImmNewsCommentState, { error }) =>
  state.merge({ fetching: false, error });

const newsCommentUpdateRequest = (state: ImmNewsCommentState) =>
  state.merge({ fetching: true });

const newsCommentUpdateSuccess = (state: ImmNewsCommentState) => {
  return state.merge({ fetching: false });
};

// @ts-expect-error TS7031
const newsCommentUpdateFailure = (state: ImmNewsCommentState, { error }) =>
  state.merge({ fetching: false, error });
/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer<ImmNewsCommentState, NewsCommentAction>(
  INITIAL_STATE,
  {
    [Types.NEWS_COMMENT_CREATE_REQUEST]: newsCommentCreateRequest,
    [Types.NEWS_COMMENT_CREATE_SUCCESS]: newsCommentCreateSuccess,
    [Types.NEWS_COMMENT_CREATE_FAILURE]: newsCommentCreateFailure,

    [Types.NEWS_COMMENT_UPDATE_REQUEST]: newsCommentUpdateRequest,
    [Types.NEWS_COMMENT_UPDATE_SUCCESS]: newsCommentUpdateSuccess,
    [Types.NEWS_COMMENT_UPDATE_FAILURE]: newsCommentUpdateFailure,

    [Types.NEWS_COMMENT_DELETE_REQUEST]: newsCommentDeleteRequest,
    [Types.NEWS_COMMENT_DELETE_SUCCESS]: newsCommentDeleteSuccess,
    [Types.NEWS_COMMENT_DELETE_FAILURE]: newsCommentDeleteFailure,

    [Types.NEWS_COMMENT_GETS_REQUEST]: newsCommentGetsRequest,
    [Types.NEWS_COMMENT_GETS_SUCCESS]: newsCommentGetsSuccess,
    [Types.NEWS_COMMENT_GETS_FAILURE]: newsCommentGetsFailure,
  },
);
