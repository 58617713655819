import { Button, Card, Layout, Table } from "antd";
import moment from "moment";
import React, { PureComponent } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";

import {
  CompanyContent,
  CompanyLayout,
  NavbarUser,
} from "../../Components/organisms";
import { I18NContext } from "../../i18n/context";
import AuthActions from "../../Redux/AuthRedux";
import { endpoints, parseEndpoint } from "../../Services/endpoints";
import { getObjUUID } from "../../utils/common";
import { appName } from "../../utils/constants";
import { IUserCompaniesScreenProps } from "./types/IUserCompaniesScreenProps";

const { Header, Footer } = Layout;

class UserCompaniesScreen extends PureComponent<IUserCompaniesScreenProps, {}> {
  context: any;
  static contextType = I18NContext;
  resetPasswordModalRef = React.createRef();
  componentDidMount() {
    const {
      context: { updatePageTitle },
    } = this.props;
    updatePageTitle("screen.label.companies");
    this.context.selectLanguage("ja");
    // @ts-expect-error TS2339
    localStorage.setItem("currentRoute", this.props.location.pathname);
  }

  // @ts-expect-error TS7006
  prepareDataForCompany = (payload) => {
    const {
      context: { apiRequest },
    } = this.props;
    const requestBuilder = {
      method: "putRequest",
      url: parseEndpoint(endpoints.prepareCompanyData),
      data: {},
    };
    // @ts-expect-error TS2345
    apiRequest(requestBuilder, ({ data }) => {
      this.props.dispatchSetAuthPayload(payload);
      setTimeout(() => {
        // @ts-expect-error TS2322
        window.location = "/dashboard";
      }, 1000);
    });
  };
  // @ts-expect-error TS7006
  gotoDasbboard = (company) => {
    const {
      context: { user },
    } = this.props;
    // @ts-expect-error TS2339
    if (Array.isArray(user.differentCompaniesPayload)) {
      // @ts-expect-error TS2339
      const selectedCompanyPayload = user.differentCompaniesPayload.filter(
        // @ts-expect-error TS7006
        (c) => {
          return c.companyId == company.id;
        },
      );
      if (selectedCompanyPayload.length === 1) {
        const baseUrl = `${
          process.env.REACT_APP_BASEHOST
        }companies/${getObjUUID(selectedCompanyPayload[0].company)}/`;
        // @ts-expect-error TS2722
        this.props.dispatchSetBaseUrl(baseUrl);
        // @ts-expect-error TS2722
        this.props.dispatchSetAuthToken(selectedCompanyPayload[0].token);
        setTimeout(() => {
          this.prepareDataForCompany({
            ...selectedCompanyPayload[0],
            // @ts-expect-error TS2339
            differentCompaniesPayload: user.differentCompaniesPayload,
            // @ts-expect-error TS2339
            otherCompanies: user.otherCompanies,
          });
        }, 1000);
      }
    }
  };
  render() {
    const {
      context: { user },
    } = this.props;
    const columns = [
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
        responsive: ["md"],
      },
      {
        // @ts-expect-error TS2532
        title: this.props.intl.formatMessage({
          id: "screen.label.contract_companies",
        }),
        dataIndex: "name",
        key: "name",
        // @ts-expect-error TS7006
        render: (text, row) => {
          return (
            <Button type="link" onClick={() => this.gotoDasbboard(row)}>
              {text}
            </Button>
          );
        },
      },
      {
        // @ts-expect-error TS2532
        title: this.props.intl.formatMessage({
          id: "screen.label.created_date",
        }),
        dataIndex: "createdAt",
        key: "createdAt",
        // @ts-expect-error TS7006
        render: (text) => (
          <span>{moment(text).local().format("YYYY/MM/DD")}</span>
        ),
        responsive: ["md"],
      },
    ];
    return (
      <CompanyLayout>
        <Layout>
          <Header>
            <NavbarUser
              pageTitle={"Select Company"}
              userDetails={user}
              logoutUser={this.logUserOut}
              showSwitchLang={false}
            />
          </Header>
          <CompanyContent>
            <br />
            <Card>
              <Table
                pagination={false}
                rowKey={"id"}
                // @ts-expect-error TS2322
                columns={columns}
                dataSource={
                  // @ts-expect-error TS2339
                  Array.isArray(user.otherCompanies) ? user.otherCompanies : []
                }
              />
            </Card>
          </CompanyContent>

          <Footer
            style={{ textAlign: "center" }}
          >{`Copyright© ${appName} Produced by Alphadrive Co.,Ltd. All rights reserved.`}</Footer>
        </Layout>
      </CompanyLayout>
    );
  }
  logUserOut = () => {
    // @ts-expect-error TS2722
    this.props.dispatchLogoutUser();
  };
}
// @ts-expect-error TS7006
const mapDispatchToProps = (dispatch) => ({
  // @ts-expect-error TS7006
  dispatchSetAuthPayload: (data) => dispatch(AuthActions.loginSuccess(data)),
  // @ts-expect-error TS7006
  dispatchSetAuthToken: (token) =>
    dispatch(AuthActions.loginSetAuthToken(token)),
  // @ts-expect-error TS7006
  dispatchSetBaseUrl: (url) => dispatch(AuthActions.loginSetBaseUrl(url)),
  dispatchLogoutUser: () => dispatch(AuthActions.logout()),
});
export default injectIntl(
  // @ts-expect-error TS2769
  connect(null, mapDispatchToProps)(UserCompaniesScreen),
);
