import { Card, Col, Modal, Row } from "antd";
import PropTypes from "prop-types";
import { FC, useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { IconRightArrow, Line } from "../../Components/atoms";
import { ManageCategoriesComponent } from "../../Components/organisms";
import VideoActions from "../../Redux/VideoRedux";
import { ILargeCategoriesScreenProps } from "./types/ILargeCategoriesScreenProps";

const LargeCategoriesScreen: FC<ILargeCategoriesScreenProps> = function (
  props,
) {
  const [state, customSetState] = useState(() => {
    return {
      smallCategories: {},
      isCategoryModalVisible: false,
      recommendedVideos: {
        rows: [],
        count: 0,
      },
    };
  });
  const { updatePageTitle, apiRequest, user } = props.context;
  const setStateCallbackRef = useRef(() => {});
  useEffect(() => {
    const callBack = setStateCallbackRef.current;
    callBack();
    setStateCallbackRef.current = () => {};
  }, [state]);
  const setState = (data: any, callback = () => {}) => {
    setStateCallbackRef.current = callback;
    customSetState((previousState) => {
      return {
        ...previousState,
        ...data,
      };
    });
  };

  useEffect(() => {
    updatePageTitle("screen.label.video");
    getVideoLargeCategories();
  }, []);
  const getVideoLargeCategories = () => {
    if (props.largeCategories.length === 0) {
      const requestBuilder = {
        method: "getRequest",
        url: `videos/large-category`,
        data: {},
      };
      // @ts-expect-error TS2345
      apiRequest(requestBuilder, ({ data }) => {
        // @ts-expect-error TS2722
        props.dispatchSetVideoCategories(data);
      });
    }
  };
  // @ts-expect-error TS7006
  const getLargeCategoriesToRender = (companyId) => {
    return props.largeCategories
      .filter((l) => l.companyId === companyId)
      .map((l) => {
        return (
          <Col key={l.name} span={24}>
            <Link to={`/dashboard/videos-large-category/${l.id}/${l.name}`}>
              <Card>
                <h3>{l.name}</h3>
                <span style={{ marginRight: "30px" }}>
                  <FormattedMessage id="screen.label.small_category" />：
                  {l.VideoSmallCategory ? l.VideoSmallCategory.length : 0}
                </span>
                <span>
                  <FormattedMessage id="screen.label.video" />：
                  {l.Videos ? l.Videos.length : 0}
                </span>
                <div
                  style={{
                    float: "right",
                    marginBottom: "30px",
                  }}
                >
                  {IconRightArrow}
                </div>
              </Card>
            </Link>
          </Col>
        );
      });
  };

  return (
    <>
      <Modal
        title={<FormattedMessage id="screen.label.category_management" />}
        visible={state.isCategoryModalVisible}
        footer={null}
        onCancel={() => {
          setState({
            isCategoryModalVisible: false,
          });
        }}
      >
        <ManageCategoriesComponent
          onDeleteSmallCategory={() => {}}
          onSubmitNewCategory={() => {}}
          smallCategories={state.smallCategories}
          largeCategories={props.largeCategories}
          user={user}
        />
      </Modal>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={12} md={8} lg={6}>
          <h2>
            <FormattedMessage id="screen.label.video_list" />
          </h2>
        </Col>
      </Row>

      <Row gutter={[0, 7]}>
        {getLargeCategoriesToRender(user.companyId)}
        <Line />
        {getLargeCategoriesToRender(null)}
      </Row>
    </>
  );
};
// @ts-expect-error TS7006
const mapStateToProps = (state) => ({
  largeCategories: state.videos.largeCategoriesPayload,
});
// @ts-expect-error TS7006
const mapDispatchToProps = (dispatch) => ({
  // @ts-expect-error TS7006
  dispatchSetVideoCategories: (largeCategories) =>
    // @ts-expect-error TS2554
    dispatch(VideoActions.videoLargeCategoriesGetSuccess(largeCategories)),
});

LargeCategoriesScreen.propTypes = {
  // @ts-expect-error TS2322
  largeCategories: PropTypes.array,
  // @ts-expect-error TS2322
  context: PropTypes.object,
  dispatchSetVideoCategories: PropTypes.func,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LargeCategoriesScreen);
