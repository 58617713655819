import { CloseOutlined } from "@ant-design/icons";
import { Button } from "antd";
import moment from "moment";
import PropTypes from "prop-types";

import { ReferenceThumbImage } from "@/features/reference/components/ReferenceThumbImage";

import { dateTimeFormat } from "../../../utils/constants";
import { HorizonalLayout, VerticalLayout } from "../../atoms";
import { SingleUploadReferenceLayout } from "../../molecules";

type SingleUploadedReferenceFileProps = {
  fileName: string;
  thumbnailUrl: string;
  uploadedAt?: string;
  removedFile: () => void;
};
const SingleUploadedReferenceFile: React.FC<SingleUploadedReferenceFileProps> =
  function (props) {
    return (
      <SingleUploadReferenceLayout>
        <HorizonalLayout>
          <ReferenceThumbImage size={100} thumbnailUrl={props.thumbnailUrl} />
          <VerticalLayout
            style={{
              justifyContent: "center",
            }}
          >
            <div
              style={{
                marginLeft: "20px",
              }}
            >
              <p
                style={{
                  color: "#323232",
                  maxWidth: "300px",
                  margin: 0,
                }}
              >
                {props.fileName}
              </p>
              <p
                style={{
                  color: "#808080",
                  margin: 0,
                }}
              >
                {props.uploadedAt &&
                  moment(props.uploadedAt).local().format(dateTimeFormat)}
              </p>
            </div>
          </VerticalLayout>
          <div
            style={{
              flexGrow: 1,
              textAlign: "right",
              margin: "1vh",
            }}
          >
            <Button
              onClick={props.removedFile}
              icon={<CloseOutlined />}
            ></Button>
          </div>
        </HorizonalLayout>
      </SingleUploadReferenceLayout>
    );
  };

SingleUploadedReferenceFile.propTypes = {
  fileName: PropTypes.string.isRequired,
  uploadedAt: PropTypes.string.isRequired,
  thumbnailUrl: PropTypes.string.isRequired,
  removedFile: PropTypes.func.isRequired,
};

export default SingleUploadedReferenceFile;
