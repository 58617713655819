import styled, { css } from "styled-components";
export const media = {
  // @ts-expect-error TS7019
  mobile: (...args) => css`
    @media (max-width: 48em) {
      ${
        // @ts-expect-error TS2556
        css(...args)
      };
    }
  `,
  // @ts-expect-error TS7019
  desktop: (...args) => css`
    @media (max-width: 48em) {
      ${
        // @ts-expect-error TS2556
        css(...args)
      };
    }
  `,
};

const largeDescriptionStyle = css`
  p {
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: ${
      // @ts-expect-error TS2339
      ({ maxLine }) => (maxLine ? maxLine : 2)
    };
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
  }
  ${media.mobile`
    > p {
      -webkit-line-clamp: ${
        // @ts-expect-error TS7031
        ({ minLine }) => (minLine ? minLine : 1)
      };
    }
  `};
`;
// Styled Components for cards
export const Media = styled("div")`
  height: 334px;
  position: relative;
  background: ${
    // @ts-expect-error TS2339
    ({ src }) =>
      src
        ? `url(${src}) center center / cover no-repeat rgb(225, 232, 237)`
        : "rgb(225, 232, 235)"
  };

  overflow: hidden;
  transition: flex-basis 0.25s ease-in-out 0s;
`;

export const Video = styled("video")`
  width: 100%;
  display: block;
  height: 100%;
  position: relative;
  flex: 1 1 0%;
  overflow: hidden;
  object-fit: cover;
`;

export const ContentWrapper = styled("div")`
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  flex: 1;
  padding: 10px 15px;
  min-width: 0;
  box-sizing: border-box;
  flex: 0 0 125px;
`;

export const Header = styled("header")`
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  margin: 0;
  flex-grow: 1.2;
  ${largeDescriptionStyle};
`;
export const Content = styled("div")`
  text-align: left;
  font-size: 14px;
  flex-grow: 1;
  margin: auto 0;
  line-height: 18px;
  ${largeDescriptionStyle};
`;

export const Footer = styled("footer")`
  text-align: left;
  font-size: 12px;
  margin: 0;
  flex-grow: 0;
`;

export const Card = styled("a")`
  width: 640px;
  background-color: rgb(255, 255, 255);
  color: rgb(24, 25, 25);
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  display: flex;
  opacity: 1;
  position: relative;
  transition-duration: 0.35s;
  transition-timing-function: ease-in-out;
  flex-direction: column;
  transition-property: background, border-color, height;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(225, 232, 237);
  overflow: hidden;
  text-decoration: none;
  -webkit-tap-highlight-color: rgb(245, 248, 250);
  cursor: pointer;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.08);
  &:hover {
    background: rgb(245, 248, 250);
  }
`;
Card.defaultProps = {
  rel: "noopener noreferrer",
  target: "_blank",
};
