import { DateTimeLabel } from "@/shared/label/DateTimeLabel";

import styles from "./styles.module.scss";
import { SubmittedTimeLabelProps } from "./type";

export const SubmittedTimeLabel = ({
  submittedTimeISO,
}: SubmittedTimeLabelProps) => {
  if (submittedTimeISO === "") return null;
  return (
    <DateTimeLabel
      showHour={true}
      dateTimeISO={submittedTimeISO}
      className={styles.submitted_time_label}
    />
  );
};
