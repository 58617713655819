import checkBlueIcon from "@/shared/icons/components/CheckIcon/assets/check-blue.svg";
import {
  CheckIconColor,
  CheckIconProps,
} from "@/shared/icons/components/CheckIcon/type";

const getImageSrc = (color: CheckIconColor) => {
  switch (color) {
    case "blue":
      return checkBlueIcon;
    default:
      return checkBlueIcon;
  }
};
export const CheckIcon = ({ color, ...props }: CheckIconProps) => {
  const alt = props.alt ?? "check icon";
  const src = getImageSrc(color);
  return <img src={src} alt={alt} {...props} />;
};
