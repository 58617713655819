import { useCallback } from "react";
import useSWR from "swr";

import { JsendError } from "@/../types/JSendObject";
import { api } from "@/Saga";
import { CommonResponse, SuccessResponse } from "@/Services/commonResponsTypes";
import { endpoints, parseEndpoint } from "@/Services/endpoints";
import { isFailedResponse } from "@/Services/isFailedResponse";
import {
  DeleteSubmissionResponse,
  SubmittedProjectsWithCount,
} from "@/Services/submissions/type";
import { useSWRCache } from "@/utils/useSWRCache";

import { FetchSubmittedProjectParams } from "../components/ProjectSubmissionsScreen/type";
import { PublishReviewsPatchRequestProps } from "../types/request";

type UseSubmittedProjectRequest = {
  page: number;
  limit: number;
  examinationPhaseId?: number;
  shouldFetch?: boolean;
  params?: FetchSubmittedProjectParams;
};

type CacheKeys = [string, FetchSubmittedProjectParams | undefined];

export const submittedProjectCacheKeyMatcher =
  /submissions\/advance-search\?page=[0-9]+&limit=[0-9]+/;

export const useSubmittedProjectRequest = ({
  page,
  limit,
  examinationPhaseId,
  shouldFetch = true,
  params,
}: UseSubmittedProjectRequest) => {
  const baseUrl = parseEndpoint(endpoints.submissionsAdvanceSearch, {
    page,
    limit,
  });
  const phaseQuery = examinationPhaseId ? `&phase=${examinationPhaseId}` : "";
  const url = baseUrl + phaseQuery;

  const fetcher = async ([url, params]: CacheKeys) => {
    const response = await api.postRequest<
      FetchSubmittedProjectParams,
      SubmittedProjectsWithCount,
      JsendError
    >(url, params);

    if (!response.ok) throw response;

    return response.data ?? { data: { rows: [], count: 0 } };
  };

  const { data, mutate: submittedProjectsMutate } = useSWR<
    SubmittedProjectsWithCount,
    JsendError
  >(shouldFetch ? [url, params] : null, fetcher, { keepPreviousData: true });

  const { deleteMatchedCaches } = useSWRCache();

  const deleteSubmittedProjectCache = useCallback(
    () => deleteMatchedCaches(submittedProjectCacheKeyMatcher),
    [deleteMatchedCaches],
  );

  const deleteSubmittedProject = useCallback(
    async (submittedProjectUuid: string) => {
      const url = parseEndpoint(endpoints.submittedProjectDetail, {
        submittedProjectUuid,
      });
      const response = await api.deleteRequest<
        DeleteSubmissionResponse,
        JsendError
      >(url);

      const isFailed = isFailedResponse(response);
      if (isFailed) return;

      deleteMatchedCaches(submittedProjectCacheKeyMatcher);
      submittedProjectsMutate();

      return { isFailed, response };
    },
    [deleteMatchedCaches, submittedProjectsMutate],
  );

  const publishReviews = useCallback(
    async (
      props: PublishReviewsPatchRequestProps,
    ): Promise<CommonResponse<null>> => {
      const URL_PUBLISH_REVIEWS = endpoints.publishReviews;
      const response = await api.patchRequest<
        PublishReviewsPatchRequestProps,
        SuccessResponse<null>,
        JsendError
      >(URL_PUBLISH_REVIEWS, props);
      const isFailed = isFailedResponse<null, SuccessResponse<null>>(response);
      if (isFailed) return { ok: false, status: "fail" };
      deleteMatchedCaches(submittedProjectCacheKeyMatcher);
      submittedProjectsMutate();
      return { ok: true, status: "success", data: null };
    },
    [deleteMatchedCaches, submittedProjectsMutate],
  );

  return {
    reviewInfos: data?.data?.rows ?? [],
    count: data?.data?.count ?? 0,
    deleteSubmittedProject,
    deleteSubmittedProjectCache,
    publishReviews,
    submittedProjectsMutate,
  };
};
