import { RiCalendarCheckLine } from "react-icons/ri";
import { useIntl } from "react-intl";

import { formatDateTimeRange } from "@/utils/formatDateTime";

import styles from "./styles.module.scss";
import { EventScheduleProps } from "./type";

export const EventSchedule = ({
  date,
  beginsAt,
  endsAt,
}: EventScheduleProps) => {
  const intl = useIntl();

  return (
    <div className={styles.event_schedule}>
      <span className={styles.icon_wrapper}>
        <RiCalendarCheckLine className={styles.calendar_icon} />
      </span>
      {formatDateTimeRange(date, beginsAt, endsAt, intl.locale)}
    </div>
  );
};
