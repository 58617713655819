import React from "react";

import { ProgramTable } from "./Common";
import DynamicTable from "./DynamicTable";
import useCustomTableToolbar from "./hooks";
import { CustomTableToolbarProps } from "./types";

const CustomTableToolbar: React.FC<CustomTableToolbarProps> = (
  props: CustomTableToolbarProps,
) => {
  const { isModalVisible, setModalVisibility, addTable } =
    useCustomTableToolbar(props);
  return (
    <div
      onClick={() => {
        if (!isModalVisible) {
          setModalVisibility(true);
        }
      }}
    >
      <ProgramTable />
      {isModalVisible ? (
        <DynamicTable
          setModalVisibility={setModalVisibility}
          visible={isModalVisible}
          addTable={addTable}
        />
      ) : null}
    </div>
  );
};
export default CustomTableToolbar;
