import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Carousel, Modal } from "antd";
import React, { PureComponent } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";

import { ProjectArchive } from "@/../types/ProjectArchive";
import { ReduxDispatch, ReduxState } from "@/Redux/types";

import { Button, Checkbox } from "../../Components/atoms";
import {
  CorouselTitle,
  LinkButtonArchive,
  OnboardingButtonImg,
  TutorialCheckbox,
} from "../../Components/molecules/project/ProjectCheckbox";
import ProjectArchiveScreenFullViewComponent from "../../Components/organisms/project/ProjectArchiveScreenFullViewComponent";
import ProjectArchiveScreenSplitViewComponent from "../../Components/organisms/project/ProjectArchiveScreenSplitViewComponent";
import ProjectArchiveSkeleton from "../../Components/organisms/project/ProjectArchiveSkeleton";
import ApiActions, {
  ApiRequestData,
  ApiSuccessCallback,
} from "../../Redux/ApiRedux";
import ArchivesActions from "../../Redux/ArchiveRedux";
import { endpoints, parseEndpoint } from "../../Services/endpoints";
import { withCheckProjectAccess } from "./permissionWrapper";
import {
  IProjectArchiveScreenProps,
  ProjectArchiveScreenState,
} from "./types/IProjectArchiveScreenProps";

class ProjectArchiveScreen extends PureComponent<
  IProjectArchiveScreenProps,
  ProjectArchiveScreenState
> {
  carouselRef: any;
  constructor(props: IProjectArchiveScreenProps) {
    super(props);
    const userId = this.props.context.user.id;
    const rawSouldTotModalShow = JSON.parse(
      localStorage.getItem("isTutorialModalKeepShowingObj") ||
        JSON.stringify({ [userId]: true }),
    );
    const shouldTotModalShow =
      typeof rawSouldTotModalShow[userId] === "undefined"
        ? true
        : rawSouldTotModalShow[userId];
    const newVal = {
      ...rawSouldTotModalShow,
      [userId]: shouldTotModalShow,
    };
    localStorage.setItem(
      "isTutorialModalKeepShowingObj",
      JSON.stringify(newVal),
    );
    this.state = {
      selectedArchives: [],
      isSplitView: false,
      isTutorialModalVisible: shouldTotModalShow,
      isNeverShowTutorial: false,
      selectedFilterArchiveType: "",
      projectTypesSubmissionFilterCheckList: [],
      isFirstLoading: true,
      projectSetting: [],
    };
    this.carouselRef = React.createRef();
  }
  onToggleSplitView = () => {
    this.setState({
      isSplitView: !this.state.isSplitView,
      selectedArchives: [],
    });
  };
  onAddToSelectedArchives = (
    archive: ProjectArchive,
    archiveIdToUnSelect?: string,
  ) => {
    const { selectedArchives } = this.state;
    if (archiveIdToUnSelect) {
      const newSelectedArchives = selectedArchives.filter(
        // @ts-expect-error TS18048
        (a) => archiveIdToUnSelect != a.id,
      );
      this.setState({
        selectedArchives: newSelectedArchives,
      });
    } else {
      const orderedArchive: ProjectArchive = {
        // @ts-expect-error TS2322
        order: selectedArchives.length + 1,
        ...archive,
      };
      this.setState({
        selectedArchives: [...selectedArchives, orderedArchive],
        isSplitView: true,
      });
    }
  };
  componentDidMount() {
    this.props.context.updatePageTitle("screen.label.archive");
    this.props.dispatchGetAllProjectArchives(-1, this.getProjectId(), "");
    this.getBatchHypothesis(this.props.project.batchId);
  }
  getProjectId = (): string | number => {
    const {
      match: { params },
    } = this.props;
    return params.id ? params.id : -1;
  };
  getBatchHypothesis = (batchId: unknown) => {
    const batchRequest: ApiRequestData = {
      method: "getRequest",
      url: parseEndpoint(endpoints.batchHypothesis, {
        // @ts-expect-error TS2322
        batchId,
      }),
    };
    this.props.context.apiRequest(batchRequest, ({ data }) => {
      this.setState({
        //@ts-expect-error 要API型定義
        projectSetting: [...data],
      });
    });
  };
  getDefaultBatch = () => {
    const batchRequest: ApiRequestData = {
      method: "getRequest",
      url: parseEndpoint(endpoints.defaultBatch),
    };
    this.props.context.apiRequest(batchRequest, ({ data }) => {
      //@ts-expect-error 要API型定義
      if (data && data.id) this.getBatchHypothesis(data.id);
    });
  };
  reSortSelectedArchives = (data: ProjectArchive[]) => {
    this.setState({
      selectedArchives: data,
    });
  };
  _renderContent = () => {
    const projectSettingList = this.state.projectSetting.filter(
      (a) =>
        a.hypothesisFormMetadata && a.hypothesisFormMetadata.displayHypothesis,
    );
    if (Array.isArray(this.props.archives)) {
      if (this.state.isSplitView) {
        return (
          <ProjectArchiveScreenSplitViewComponent
            projectSetting={projectSettingList}
            onToggleSplitView={this.onToggleSplitView}
            reSortSelectedArchives={this.reSortSelectedArchives}
            selectedArchives={this.state.selectedArchives}
            projectId={this.getProjectId()}
            onAddToSelectedArchives={this.onAddToSelectedArchives}
            completeArchiveList={this.props.archives}
          />
        );
      }
      return (
        <ProjectArchiveScreenFullViewComponent
          dispatchApiRequest={this.props.context.apiRequest}
          projectSetting={projectSettingList}
          onToggleSplitView={this.onToggleSplitView}
          onAddToSelectedArchives={this.onAddToSelectedArchives}
          projectId={this.getProjectId()}
          company={this.props.context.company}
          projectTypesSubmissionFilterCheckList={
            this.state.projectTypesSubmissionFilterCheckList
          }
          triggerGetArchives={(
            page,
            projectId,
            filter,
            newSelectedFilterArchiveType,
          ) => {
            this.props.dispatchGetAllProjectArchives(page, projectId, filter);
            this.setState({
              selectedFilterArchiveType: newSelectedFilterArchiveType,
            });
          }}
          selectedFilterArchiveType={this.state.selectedFilterArchiveType}
          handleProjectSubmissionTypesChange={
            this.handleProjectSubmissionTypesChange
          }
          archiveList={this.props.archives}
        />
      );
    }
    return <div />;
  };
  handleProjectSubmissionTypesChange = (
    projectTypesSubmissionFilterCheckList: string[],
  ) => {
    this.setState(
      {
        projectTypesSubmissionFilterCheckList,
        isFirstLoading: false,
      },
      () => {
        const emptyString = `(${this.props.intl.formatMessage({
          id: "screen.label.empty",
        })})`;

        if (this.state.projectTypesSubmissionFilterCheckList.length > 0) {
          if (projectTypesSubmissionFilterCheckList.indexOf(emptyString) > -1) {
            const requestBuilder: ApiRequestData = {
              method: "postRequest",
              url: parseEndpoint(endpoints.archiveListAdvanceSearch),
              data: {
                category: this.state.projectTypesSubmissionFilterCheckList,
                archiveType: "project_update",
                projectId: this.props.project.id,
              },
            };
            this.props.dispatchApiRequest(requestBuilder, ({ data }) => {
              this.props.dispatchSetArchiveSuccess(data);
            });
          } else {
            const requestBuilder: ApiRequestData = {
              method: "postRequest",
              url: parseEndpoint(endpoints.archiveListAdvanceSearch),
              data: {
                category: this.state.projectTypesSubmissionFilterCheckList,
                projectId: this.props.project.id,
              },
            };
            this.props.dispatchApiRequest(requestBuilder, ({ data }) => {
              this.props.dispatchSetArchiveSuccess(data);
            });
          }
        } else {
          this.props.dispatchGetAllProjectArchives(-1, this.getProjectId(), "");
        }
      },
    );
  };
  render() {
    if (this.props.archivesIsLoading && this.state.isFirstLoading) {
      return <ProjectArchiveSkeleton />;
    }
    return (
      <>
        <Modal
          width={"50vw"}
          className="project-archive-tutor-modal"
          bodyStyle={{
            paddingTop: 10,
            background: "#6E3CF5",
            borderRadius: 10,
          }}
          onCancel={() => {
            this.setState({
              isTutorialModalVisible: false,
            });
            const userId = this.props.context.user.id;
            const exising = JSON.parse(
              // @ts-expect-error TS2345
              localStorage.getItem("isTutorialModalKeepShowingObj"),
            );
            const newVal = {
              ...exising,
              [userId]: !this.state.isNeverShowTutorial,
            };
            localStorage.setItem(
              "isTutorialModalKeepShowingObj",
              JSON.stringify(newVal),
            );
          }}
          footer={this._renderNeverShowCheckbox()}
          visible={this.state.isTutorialModalVisible}
        >
          <Carousel ref={this.carouselRef}>
            <div>
              {this._renderCarouselHeader(
                this.props.intl.formatMessage({
                  id: "screen.label.see_change_immediately",
                }),
              )}
              <LinkButtonArchive>
                <Button type={"link"} />
                <OnboardingButtonImg src={"/images/onboarding01@2x.png"} />
                <Button
                  type={"link"}
                  onClick={this.onNextCarousel}
                  icon={
                    <RightOutlined
                      style={{
                        fontSize: "2rem",
                        color: "white",
                      }}
                    />
                  }
                />
              </LinkButtonArchive>
            </div>
            <div>
              {this._renderCarouselHeader(
                this.props.intl.formatMessage({
                  id: "screen.label.compare_change_pinned_version",
                }),
              )}
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "row",
                  alignItems: "center",
                  paddingBottom: "5vh",
                }}
              >
                <Button
                  type={"link"}
                  onClick={this.onPreviousCarousel}
                  icon={
                    <LeftOutlined
                      style={{
                        fontSize: "2rem",
                        color: "white",
                      }}
                    />
                  }
                />
                <OnboardingButtonImg src={"/images/onboarding02@2x.png"} />
                <Button
                  type={"link"}
                  onClick={this.onNextCarousel}
                  icon={
                    <RightOutlined
                      style={{
                        fontSize: "2rem",
                        color: "white",
                      }}
                    />
                  }
                />
              </div>
            </div>
            <div>
              {this._renderCarouselHeader(
                this.props.intl.formatMessage({
                  id: "screen.label.compare_only_difference",
                }),
              )}
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "row",
                  alignItems: "center",
                  paddingBottom: "5vh",
                }}
              >
                <Button
                  type={"link"}
                  onClick={this.onPreviousCarousel}
                  icon={
                    <LeftOutlined
                      style={{
                        fontSize: "2rem",
                        color: "white",
                      }}
                    />
                  }
                />
                <OnboardingButtonImg src={"/images/onboarding03@2x.png"} />
                <Button type={"link"} />
              </div>
            </div>
          </Carousel>
        </Modal>
        {this._renderContent()}
      </>
    );
  }
  onPreviousCarousel = () => {
    this.carouselRef.current.prev();
  };
  onNextCarousel = () => {
    this.carouselRef.current.next();
  };
  _renderCarouselHeader = (title: string) => {
    return (
      <div style={{ textAlign: "center" }}>
        <CorouselTitle>{title}</CorouselTitle>
      </div>
    );
  };
  _renderNeverShowCheckbox = () => {
    return (
      <TutorialCheckbox>
        <Checkbox
          checked={this.state.isNeverShowTutorial}
          onChange={(e) => {
            this.setState({
              isNeverShowTutorial: !this.state.isNeverShowTutorial,
            });
          }}
        >
          <span style={{ color: "black" }}>
            {this.props.intl.formatMessage({
              id: "screen.label.not_show_next_time",
            })}
          </span>
        </Checkbox>
      </TutorialCheckbox>
    );
  };
}
const mapStateToProps = (
  state: ReduxState,
): Pick<
  IProjectArchiveScreenProps,
  "project" | "archives" | "archivesIsLoading"
> => ({
  project: state.projects.projectPayload,
  // @ts-expect-error TS2740
  archives: state.archives.archivesPayload,
  archivesIsLoading: state.archives.fetching,
});
const mapDispatchToProps = (
  dispatch: ReduxDispatch,
): Pick<
  IProjectArchiveScreenProps,
  | "dispatchApiRequest"
  | "dispatchGetAllProjectArchives"
  | "dispatchSetArchiveSuccess"
> => ({
  dispatchGetAllProjectArchives: (page, id, filter) =>
    dispatch(ArchivesActions.archivesGetsRequest(page, id, filter)),
  // @ts-expect-error TS7006 多分使ってない
  dispatchGetProjectDetail: (id) =>
    // @ts-expect-error TS2554
    dispatch(ProjectActions.projectGetRequest(id)),
  dispatchSetArchiveSuccess: (data) =>
    dispatch(ArchivesActions.archivesGetsSuccess(data)),
  dispatchApiRequest: (data: ApiRequestData, callback: ApiSuccessCallback) =>
    dispatch(ApiActions.apiRequest(data, callback)),
});
export default withCheckProjectAccess(
  // @ts-expect-error TS2345
  injectIntl(
    connect(mapStateToProps, mapDispatchToProps)(ProjectArchiveScreen),
  ),
);
