import { FormattedMessage } from "react-intl";

import ENI18N from "../i18n/en.json";
import JAI18N from "../i18n/ja.json";
export const recruiting = "RECRUITING";
export const nonRecruiting = "NONRECRUITING";

// const displayReference = 'DISPLAY_REFERENCE'
// const notDisplayReference = 'NOT_DISPLAY_REFERENCE'

export const referenceDisplayedToEmployeesOption = {
  label: "screen.reference.label.yes_display_option",
  value: true,
};
export const referenceNotDisplayedToEmployeesOption = {
  label: "screen.reference.label.no_display_option",
  value: false,
};

export const recruiting_option = {
  label: "screen.label.recruiting",
  value: recruiting,
};
export const nonRecruiting_option = {
  label: "screen.label.nonrecruiting",
  value: nonRecruiting,
};

export const dateTimeFormat = "YYYY-MM-DD HH:mm";

export const dateFormat = "YYYY-MM-DD";
export const timeFormat = "HH:mm";

export const appName = "Incubation Suite";

export const b64toBlob = (b64Data, contentType, sliceSize) => {
  contentType = contentType || "";
  sliceSize = sliceSize || 512;

  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    byteArrays.push(new Uint8Array(byteNumbers));
  }

  return new Blob(byteArrays, { type: contentType });
};

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export const checkDormantPermission = (message) => {
  if (typeof message === "string") {
    if (
      message === ENI18N["screen.label.unauthorized_with_dormant"] ||
      message === JAI18N["screen.label.unauthorized_with_dormant"]
    ) {
      window.location = "/dashboard?error=unauthorized_with_dormant";
    }
  }
  return false;
};

export const generalUser = <FormattedMessage id="screen.label.general_user" />;
export const programManager = (
  <FormattedMessage id="screen.label.management_user" />
);
export const adminUser = <FormattedMessage id="screen.label.admin_user" />;
export const mentorUser = <FormattedMessage id="screen.label.mentor" />;

export const lottery = <FormattedMessage id="screen.label.lottery_formula" />;
export const firstComeFirstServer = (
  <FormattedMessage id="screen.label.first_come_formula" />
);

export const userTags = <FormattedMessage id="screen.label.user_label" />;
export const defaultThumbnailImg = "/icons/symbol-purple.png";
