// 半角と全角の文字コードの差分
const CHARACTER_SET_DIFFERENCE = 0xfee0;

/**
 * 全角数字を半角数字に変換する
 *
 * @param {string} input - 変換したい文字列
 * @returns {string} - 変換後の文字列。数字はすべて半角
 */
export const convertToHalfWidthNumber = (input: string) =>
  input.replace(/[０-９]/g, (s) => {
    return String.fromCharCode(s.charCodeAt(0) - CHARACTER_SET_DIFFERENCE);
  });
