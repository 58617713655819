import { PlusOutlined } from "@ant-design/icons";
import { Col, Row, Table } from "antd";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { ColumnsType, ColumnType, TableProps } from "antd/lib/table";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link, useHistory } from "react-router-dom";

import { Batch } from "../../../../types/Batch";
import {
  IMyProjectListComponentProps,
  MyProjectListComponentState,
} from "../../../Screens/project/types/IMyProjectListComponentProps";
import { Project2 } from "../../../Services/projects/useMyProjectsRequest";
import { batchStatus } from "../../../utils/batchUtils";
import {
  getObjUUID,
  getProjectAvatar,
  isEmptyString,
} from "../../../utils/common";
import { dateTimeFormat } from "../../../utils/constants";
import {
  nonRecruiting_option,
  recruiting_option,
} from "../../../utils/constants";
import { getInitStorageValue } from "../../../utils/localStorage";
import { IconEdit, Tag } from "../../atoms";
import { ButtonCV, ButtonOption, Card, Checkbox } from "../../atoms";
import { BatchNameComponent } from "../../atoms/texts/BatchNameComponent";
import { CustomPagination } from "../../molecules";
import BatchSelectModal from "./BatchSelectModal";

const PageSaveId = "MyProjectListComponent";
const pageKey = `pagination_page_${PageSaveId}`;
const limitKey = `pagination_limit_${PageSaveId}`;
const projectLocalStorageKeys = "MyProjectListState";

const MyProjectListComponent = (props: IMyProjectListComponentProps) => {
  const intl = useIntl();
  const history = useHistory();
  const batches = useMemo(
    () => (props.batchId !== "" ? [props.batchId] : []),
    [props.batchId],
  );

  const [state, _setState] = useState(getInitState);
  const setState = useCallback(
    (newSt: Partial<MyProjectListComponentState>) =>
      _setState((st) => ({ ...st, ...newSt })),
    [],
  );
  const [showBatchModal, setShowBatchModal] = useState(false);
  const openBatchModal = () => setShowBatchModal(true);
  const closeBatchModal = () => setShowBatchModal(false);

  // stateが変わるたびにlocalStorageにstateを保存する
  useEffect(() => {
    localStorage.setItem(projectLocalStorageKeys, JSON.stringify(state));
  }, [state]);

  // eslintにdepsで怒られるので一旦propsから取り出す
  const { handleMyProjectctsAdvanceFilter } = props;
  // stateに同期してプロジェクト一覧を取得する
  useEffect(() => {
    handleMyProjectctsAdvanceFilter({
      recruitmentStatus: state.memberStatusCheckList,
      stages: state.checkedList,
      tags: state.tagCheckList,
      milestones: [],
      sortField: state.sortField,
      sortOrder: state.sortOrder,
      page: state.myProjectsPage,
      limit: state.myProjectsLimit,
      batches,
    });
  }, [
    batches,
    handleMyProjectctsAdvanceFilter,
    state.checkedList,
    state.memberStatusCheckList,
    state.myProjectsLimit,
    state.myProjectsPage,
    state.sortField,
    state.sortOrder,
    state.tagCheckList,
  ]);

  const onTableChangeMyProj: TableProps<Project2>["onChange"] = (
    _pagination,
    _filters,
    sorter,
    _extra,
  ) => {
    /**  複数条件でのソートをしないので、sorterはSortResult<Project>になる（配列にならない） */
    if (sorter && !Array.isArray(sorter)) {
      const order = sorter.order && sorter.order === "ascend" ? "asc" : "desc";
      setState({ sortField: sorter.field as string, sortOrder: order });
    }
  };
  const handleTagChange = (tag?: CheckboxValueType[]) => {
    setState({ myProjectsPage: 1, tagCheckList: tag });
  };
  const handleStageChange = (stage: CheckboxValueType[]) => {
    setState({ myProjectsPage: 1, checkedList: stage });
  };
  const handleMemberStatusChange = (type?: CheckboxValueType[]) => {
    setState({
      myProjectsPage: 1,
      memberStatusCheckList: type,
    });
  };
  const getColumnTagProps = (): Pick<
    ColumnType<unknown>,
    "filterDropdown" | "onFilter"
  > => ({
    filterDropdown: ({ setSelectedKeys, clearFilters }) => {
      const tags = props.tags ? props.tags : [];
      const options: React.ReactNode[] = [];
      tags.forEach((o) => {
        options.push(
          <Col key={o.id} span={8}>
            <Checkbox value={o.id}>{o.name}</Checkbox>
          </Col>,
        );
      });
      return (
        <div style={{ padding: 8 }}>
          <Checkbox.Group
            style={{ width: "100%" }}
            value={state.tagCheckList}
            onChange={(value) => handleTagChange(value)}
          >
            <Row>{options}</Row>
          </Checkbox.Group>
          <br />
          <ButtonCV
            type="primary"
            onClick={() => {
              clearFilters?.();
              setSelectedKeys([]);
              handleTagChange();
            }}
            size="small"
          >
            Reset
          </ButtonCV>
        </div>
      );
    },
  });
  const getColumnPriorityProps = (): Pick<
    ColumnType<unknown>,
    "filterDropdown" | "onFilter"
  > => ({
    filterDropdown: ({ setSelectedKeys, clearFilters }) => {
      const stages = props.company.stages ? props.company.stages : [];
      const options: React.ReactNode[] = [];
      stages.forEach((o) => {
        options.push(
          <Col key={o} span={8}>
            <Checkbox value={o}>{o}</Checkbox>
          </Col>,
        );
      });
      return (
        <div style={{ padding: 8 }}>
          <Checkbox.Group
            style={{ width: "100%" }}
            value={state.checkedList}
            onChange={(value) => handleStageChange(value)}
          >
            <Row>{options}</Row>
          </Checkbox.Group>
          <br />
          <ButtonCV
            type="primary"
            onClick={() => {
              clearFilters?.();
              setSelectedKeys([]);
              handleStageChange([]);
            }}
            size="small"
          >
            Reset
          </ButtonCV>
        </div>
      );
    },
  });
  const getColumnMemberStatusProps = (): Pick<
    ColumnType<unknown>,
    "filterDropdown" | "onFilter"
  > => ({
    filterDropdown: ({ setSelectedKeys, clearFilters }) => {
      const options: React.ReactNode[] = [];
      [recruiting_option, nonRecruiting_option].forEach((o) => {
        options.push(
          <Col key={o.value} span={8}>
            <Checkbox value={o.value}>
              <FormattedMessage id={o.label} />
            </Checkbox>
          </Col>,
        );
      });
      return (
        <div style={{ padding: 8 }}>
          <Checkbox.Group
            style={{ width: "100%" }}
            value={state.memberStatusCheckList}
            onChange={(value) => handleMemberStatusChange(value)}
          >
            <Row>{options}</Row>
          </Checkbox.Group>
          <br />
          <ButtonCV
            onClick={() => {
              clearFilters?.();
              setSelectedKeys([]);
              handleMemberStatusChange();
            }}
            size="small"
          >
            Reset
          </ButtonCV>
        </div>
      );
    },
  });
  const getSelectedBatch = () => {
    if (props.batchId === "") {
      return {
        displayCheckpoint: true,
      };
    }
    if (Array.isArray(props.batches)) {
      return props.batches.find((b) => b.id === props.batchId);
    }
    return null;
  };
  const getMyProjectsColumnHeader = () => {
    const selectedBatch = getSelectedBatch();
    const columns: ColumnsType<Project2> = [
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
        sorter: true,
        sortDirections: ["descend", "ascend"],
        width: "20px",
      },
      {
        title: "",
        dataIndex: "icon",
        key: "icon",
        render: (text, row) => {
          return <div>{getProjectAvatar(row)}</div>;
        },
      },
      {
        title: intl.formatMessage({
          id: "screen.label.project_name",
        }),
        dataIndex: "name",
        key: "name",
        render: (text, row) => (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div style={{ maxWidth: "20vw" }}>
              <Link to={"/dashboard/project.top/" + getObjUUID(row)}>
                {text}
              </Link>
            </div>
          </div>
        ),
      },
      {
        title: intl.formatMessage({
          id: "screen.label.date_modified",
        }),
        key: "updatedAt",
        dataIndex: "updatedAt",
        render: (text) => (
          <span>{moment(text).local().format(dateTimeFormat)}</span>
        ),
        sortDirections: ["descend", "ascend"],
      },
      {
        title: intl.formatMessage({
          id: "screen.label.member_status",
        }),
        key: "type",
        dataIndex: "type",
        render: (text) => (
          <span>
            {isEmptyString(text)
              ? ""
              : intl.formatMessage({
                  id: `screen.label.${text}`.toLowerCase(),
                })}
          </span>
        ),
        ...getColumnMemberStatusProps(),
      },
      {
        title: intl.formatMessage({
          id: "screen.label.stage",
        }),
        key: "stage",
        dataIndex: "stage",
        render: (text) => <span>{isEmptyString(text) ? "" : text}</span>,
        ...getColumnPriorityProps(),
      },
      {
        title: intl.formatMessage({
          id: "screen.label.project_label",
        }),
        key: "tag",
        render: (_, row) => (
          <ul style={{ padding: 0 }}>
            {row.projectTags.map((tag) => (
              <li key={tag.id}>{tag.name}</li>
            ))}
          </ul>
        ),
        ...getColumnTagProps(),
      },
      {
        title: intl.formatMessage({
          id: "screen.label.owner",
        }),
        key: "",
        dataIndex: "",
        render: (text, row) => {
          const projectOwners = Array.isArray(row.team)
            ? row.team.filter((team) => {
                return team.isProjectOwner;
              })
            : [];
          return projectOwners.map((team, index) => {
            if (projectOwners.length === 1) {
              if (team.User.stoppedAt) {
                return (
                  <Tag color="purple" key={getObjUUID(team.User)}>
                    {intl.formatMessage({
                      id: "screen.label.stopped_user",
                    })}{" "}
                  </Tag>
                );
              }
              if (team.User.deletedAt) {
                return (
                  <Tag color="red" key={getObjUUID(team.User)}>
                    {intl.formatMessage({
                      id: "screen.label.deleted_user",
                    })}
                  </Tag>
                );
              } else {
                return (
                  <Link
                    to={`/dashboard/member/${getObjUUID(team.User)}`}
                    key={getObjUUID(team.User)}
                  >
                    {team.User.name
                      ? team.User.name
                      : team.User.Credential
                      ? team.User.Credential.email
                        ? team.User.Credential.email.split("@")[0]
                        : ""
                      : ""}
                  </Link>
                );
              }
            } else if (
              projectOwners.length > 1 &&
              (team.User.stoppedAt !== null || team.User.deletedAt !== null)
            ) {
              return "";
            } else {
              let comma = ",";
              if (index === projectOwners.length - 1) comma = "";
              return (
                <Link
                  to={`/dashboard/member/${getObjUUID(team.User)}`}
                  key={getObjUUID(team.User)}
                >
                  {team.User.name}
                  {comma}{" "}
                </Link>
              );
            }
          });
        },
      },
      props.company.isAllowCheckpoint &&
      selectedBatch?.displayCheckpoint &&
      props.authUser.type !== "employee"
        ? {
            title: intl.formatMessage({
              id: "screen.label.project_list.checkpoint_coverage_rate",
            }),
            key: "checkpointCoverageRate",
            dataIndex: "checkpointCoverageRate",
            sorter: true,
            sortDirections: ["descend", "ascend"],
            render: (checkpointCoverageRate: number, row) => {
              if (
                row.batch?.displayCheckpoint &&
                checkpointCoverageRate !== -1
              ) {
                return `${checkpointCoverageRate} %`;
              }
            },
          }
        : {},
      {
        title: "",
        key: "isAdmin",
        dataIndex: "isAdmin",
        render: (_, row) => {
          const projectOwners = Array.isArray(row.team)
            ? row.team
                .filter((team) => {
                  return team.isProjectOwner;
                })
                .map((t) => t.userId)
            : [];
          const text = projectOwners.includes(props.authUser.id);

          return text ? (
            <Link to={`/dashboard/projects.edit/${getObjUUID(row)}`}>
              {IconEdit}
            </Link>
          ) : (
            <span />
          );
        },
      },
    ];
    if (props.batchId === "") {
      columns.splice(5, 0, {
        title: intl.formatMessage({
          id: "screen.label.held_times",
        }),
        key: "batch",
        dataIndex: "batch",
        render: (text, row) => {
          if (row.batch) {
            return (
              <BatchNameComponent>
                <span>{row.batch.name}</span>
              </BatchNameComponent>
            );
          }
          return null;
        },
      });
    }

    return columns.filter((c) => c);
  };
  const render = () => {
    return (
      <>
        <TableHeader
          projectsCount={props.projectsCount}
          batch={props.batch}
          batchId={props.batchId ?? ""}
          onClickWhenEmptyBatchId={openBatchModal}
        />

        <Card>
          <Table
            pagination={false}
            columns={getMyProjectsColumnHeader()}
            dataSource={props.projects}
            onChange={onTableChangeMyProj}
            className="ant-table-x-scroll"
          />
          <br />
          <CustomPagination
            saveId={PageSaveId}
            count={props.projectsCount}
            initPage={state.myProjectsPage}
            initLimit={state.myProjectsLimit}
            onChangePage={(page, limit) => {
              setState({ myProjectsPage: page, myProjectsLimit: limit });
            }}
          />
        </Card>
        <BatchSelectModal
          batches={props.batches}
          showModal={showBatchModal}
          onCloseModal={closeBatchModal}
          onSelectBatch={(batchId) => {
            closeBatchModal();
            const projectCreateLocation = `/dashboard/projects.create/${batchId}`;
            history.push({
              pathname: projectCreateLocation,
            });
          }}
        />
        <br />
      </>
    );
  };

  return render();
};

export default MyProjectListComponent;

const TableHeader = (props: {
  projectsCount: number;
  batch?: Batch;
  batchId: string;
  onClickWhenEmptyBatchId: () => void;
}) => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <h2>
        {formatMessage(
          {
            id: "screen.label.projects_that_are_members_count_matter",
          },
          {
            count: props.projectsCount,
          },
        )}
      </h2>

      {batchStatus.find((status) => status.key === props.batch?.status)
        ?.value !== "batch_status_progress_but_no_new_project" ? (
        <ButtonOption
          icon={<PlusOutlined />}
          onClick={() => {
            if (props.batchId !== "") {
              const projectCreateLocation = `/dashboard/projects.create/${props.batchId}`;
              history.push({
                pathname: projectCreateLocation,
                state: {
                  batchId: props.batchId,
                },
              });
            } else {
              props.onClickWhenEmptyBatchId();
            }
          }}
        >
          {formatMessage({
            id: "screen.label.creating_project",
          })}
        </ButtonOption>
      ) : null}
    </div>
  );
};

const getInitState = (): MyProjectListComponentState => {
  const persistedState = localStorage.getItem(projectLocalStorageKeys);
  const parsedSt: MyProjectListComponentState | null = persistedState
    ? JSON.parse(persistedState)
    : null;

  const defaultState: MyProjectListComponentState = {
    myProjectsPage: getInitStorageValue(pageKey, 1),
    myProjectsLimit: getInitStorageValue(
      limitKey,
      process.env.REACT_APP_PAGE_SIZE,
    ),
    sortField: "id",
    sortOrder: "DESC",
    memberStatusCheckList: [],
    checkedList: [],
    tagCheckList: [],
  };

  return { ...defaultState, ...parsedSt };
};
