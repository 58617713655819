import { PropsWithChildren } from "react";

import styles from "@/shared/buttons/components/PrimaryButton/styles.module.scss";
import { PrimaryButtonProps } from "@/shared/buttons/components/PrimaryButton/type";

export const PrimaryButton = ({
  children,
  className,
  ...props
}: PropsWithChildren<PrimaryButtonProps>) => {
  const disabledClass = props.disabled ? styles.disabled : "";
  return (
    <button
      {...props}
      className={`${styles.button} ${disabledClass} ${className}`}
    >
      {children}
    </button>
  );
};
