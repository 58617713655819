import { Form } from "antd";
import { Button, DatePicker, Input, Layout, Rate, Select } from "antd";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import styled from "styled-components";

import { Col } from "../../Components/atoms";
import {
  InsuAcceptButton,
  InsuRejectButton,
} from "../../Components/atoms/buttons/shared";
import { ProjectModalButtonComponent } from "../../Components/molecules/project/ProjectTaskModal";
import ProjectActions from "../../Redux/ProjectRedux";
import TeamActions from "../../Redux/TeamRedux";
import useProjectTaskCreateScreen from "./hooks/useProjectTaskCreateScreen";

const ProjectLabelSaveButton = styled(Button)`
  float: right;
  margin-top: 50;
  width: 300;
`;
const { TextArea } = Input;
const { Content } = Layout;
const { Option } = Select;
// @ts-expect-error TS7006
const ProjectTask = function (props) {
  const { handleSubmit, handleChange, intl, state, onChange, handleCancel } =
    useProjectTaskCreateScreen(props);

  const _renderUsers = () => {
    if (Array.isArray(props.teams.teamMembersPayload.rows)) {
      return (
        props.teams.teamMembersPayload.rows
          // @ts-expect-error TS7006
          .filter((user) => {
            if (user.deletedAt) {
              return false;
            } else if (user.stoppedAt) {
              return false;
            }
            return true;
          })
          // @ts-expect-error TS7006
          .map((user) => {
            return (
              <Option key={user.id} value={user.id}>
                {user?.name?.trim()?.length > 0 ? user.name : user.email}
              </Option>
            );
          })
      );
    }
  }; //
  return (
    <Content>
      <Col>
        <Form
          layout={"vertical"}
          style={{ maxWidth: 600, backgroundColor: "#fff" }}
        >
          <Form.Item
            label={`${intl.formatMessage({
              id: "screen.label.item_name",
            })} *`}
          >
            <Input
              size="large"
              required
              onChange={(event) => handleChange("title", event.target.value)}
              value={state.title}
              placeholder={intl.formatMessage({
                id: "screen.label.please_enter",
              })}
            />
          </Form.Item>
          <Form.Item
            label={`${intl.formatMessage({
              id: "screen.label.more_detail",
            })} *`}
          >
            <TextArea
              rows={8}
              required
              onChange={(event) =>
                handleChange("description", event.target.value)
              }
              value={state.description}
              placeholder={intl.formatMessage({
                id: "screen.label.please_enter",
              })}
            />
          </Form.Item>
          <Form.Item
            label={`${intl.formatMessage({
              id: "screen.label.person_in_charge",
            })}`}
            help={intl.formatMessage({
              id: "screen.label.notification_e_mail_charge",
            })}
          >
            <Select
              showSearch
              allowClear
              mode="multiple"
              optionFilterProp="children"
              filterOption={(input, option) =>
                // @ts-expect-error TS18048
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              onChange={(value) => handleChange("incharge", value)}
            >
              {_renderUsers()}
            </Select>
          </Form.Item>
          <br />
          <Form.Item
            label={`${intl.formatMessage({
              id: "screen.label.priority",
            })}`}
          >
            <Rate
              onChange={(value) => handleChange("priority", value)}
              value={state.priority}
            />
          </Form.Item>
          <Form.Item
            label={`${intl.formatMessage({
              id: "screen.label.deadline",
            })}`}
          >
            <DatePicker
              value={state.timelimit}
              onChange={(date) => onChange(date)}
              placeholder={intl.formatMessage({
                id: "screen.label.select_date",
              })}
            />
          </Form.Item>
          <ProjectModalButtonComponent>
            <InsuRejectButton
              style={{
                marginLeft: "0.1vw",
                marginRight: "0.1vw",
              }}
              onClick={handleCancel}
            >
              <FormattedMessage id={"screen.label.cancel"} />
            </InsuRejectButton>
            <InsuAcceptButton
              style={{
                marginLeft: "0.1vw",
                marginRight: "0.1vw",
              }}
              onClick={handleSubmit}
              loading={props.loading}
            >
              <FormattedMessage id="screen.label.save" />
            </InsuAcceptButton>
          </ProjectModalButtonComponent>
        </Form>
      </Col>
    </Content>
  );
};

// @ts-expect-error TS7006
const mapDispatchToProps = (dispatch) => ({
  // @ts-expect-error TS7006
  dispatchFetchUsers: (data) => dispatch(TeamActions.teamGetRequest(data)),
  // @ts-expect-error TS7006
  dispatchCreateProjectTask: (data) =>
    // @ts-expect-error TS2554
    dispatch(ProjectActions.projectTaskCreateRequest(data)),
});
// @ts-expect-error TS7006
const mapStateToProps = (state) => ({
  teams: state.teams,
  users: state.user,
  loading: state.projects.fetching,
  projects: state.projects,
});
ProjectTask.propTypes = {
  context: PropTypes.object,
  history: PropTypes.object,
  teams: PropTypes.object,
  users: PropTypes.object,
  loading: PropTypes.bool,
  projects: PropTypes.object,
  match: PropTypes.object,
  dispatchFetchUsers: PropTypes.func,
  dispatchCreateProjectTask: PropTypes.func,
};
export default connect(mapStateToProps, mapDispatchToProps)(ProjectTask);
