import { Col, Form, Layout, Row } from "antd";
import PropTypes from "prop-types";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { ReduxDispatch } from "@/Redux/types";

import { ButtonSubmit } from "../../Components/atoms";
import {
  AlphaDriveImg,
  AlphaDriveImgContainer,
  AuthLayout,
  Banner,
  HorizontalLine,
  InputForm,
  LinkSpan,
  LoginBanner,
  LoginLabel,
  LogoTitleLayout,
  VerifyHeaderBold,
} from "../../Components/molecules";
import ApiActions, {
  ApiRequestData,
  ApiSuccessCallback,
} from "../../Redux/ApiRedux";
import useRegister from "./hooks/useRegister";
import { IRegisterProps } from "./types/IRegisterProps";
const { Content } = Layout;

const Register: FC<IRegisterProps> = function (props) {
  const { intl, state, onChange, onSubmit, emailValidationStatus } =
    useRegister(props);

  return (
    <AuthLayout className="layout">
      <Content className="authContent">
        <Row>
          <Banner>
            <LogoTitleLayout>
              <LoginBanner alt={"Banner"} src={"/login_banner.png"} />
              <LoginLabel>
                <FormattedMessage id="screen.label.comprehensive_support_tool_des" />
                <br />
                <FormattedMessage id="screen.label.incubation_suite" />
              </LoginLabel>
            </LogoTitleLayout>
            <AlphaDriveImgContainer>
              <div>
                <span>Powered by &nbsp;</span>
                <AlphaDriveImg alt={"alpha drive"} src={"/alpha_drive.png"} />
              </div>
            </AlphaDriveImgContainer>
          </Banner>
          <Col>
            {state.isCompanyRegistrationEnabled ? (
              <div className="contentRoot">
                <div>
                  <VerifyHeaderBold>
                    <FormattedMessage id="screen.label.request_account_registration" />
                  </VerifyHeaderBold>
                  <Form layout={"vertical"} onFinish={onSubmit}>
                    <Form.Item
                      label={intl.formatMessage({
                        id: "screens.title.name",
                      })}
                    >
                      <InputForm
                        onChange={(e) => onChange("name", e.target.value)}
                        value={state.name}
                      />
                    </Form.Item>
                    <Form.Item
                      label={intl.formatMessage({
                        id: "screens.title.email",
                      })}
                      validateStatus={emailValidationStatus()}
                    >
                      <InputForm
                        onChange={(e) =>
                          onChange("email", `${e.target.value}`.trim())
                        }
                        value={state.email}
                      />
                    </Form.Item>
                    <ButtonSubmit onClick={onSubmit}>
                      <FormattedMessage id="screen.label.request_registration" />
                    </ButtonSubmit>
                  </Form>
                  <HorizontalLine />
                  <LinkSpan>
                    <p>
                      <FormattedMessage id="screen.label.those_who_already_have_an_account" />{" "}
                      <Link to="/login">
                        <FormattedMessage id="screen.label.click_here_to_login" />
                      </Link>
                    </p>
                  </LinkSpan>
                </div>
              </div>
            ) : (
              <div className="contentRoot">
                <div>
                  <p
                    style={{
                      marginTop: "20%",
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    <FormattedMessage id="screen.register.not_accepting_registration" />
                  </p>
                </div>
              </div>
            )}
          </Col>
        </Row>
      </Content>
    </AuthLayout>
  );
};

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch: ReduxDispatch) => ({
  dispatchApiRequest: (data: ApiRequestData, callback: ApiSuccessCallback) =>
    dispatch(ApiActions.apiRequest(data, callback)),
});
Register.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
  dispatchApiRequest: PropTypes.func,
};
export default connect(mapStateToProps, mapDispatchToProps)(Register);
