import { Button, Card, Col, Row } from "antd";
import PropTypes from "prop-types";
import React, { FC } from "react";
import { useIntl } from "react-intl";
import { useHistory, withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

import {
  PlusButton,
  SingleLArgeVideoRoot,
  VideoShowInfo,
} from "../../atoms/videos";
import { VideoTopCategorySingleCard } from "../../molecules/videos";

type ISingleVideoLargeCategoryProps = {
  intl?: {
    formatMessage?: (...args: any[]) => any;
  };
  history?: {
    push?: (...args: any[]) => any;
  };
  largeCategory: any;
  rows?: any[];
  length?: any;
  formatMessage?: any;
  name?: any;
  push?: any;
  map?: any;
} & React.HTMLAttributes<Element>;

export const SingleVideoLargeCategory: FC<ISingleVideoLargeCategoryProps> =
  function (props) {
    const intl = useIntl();
    const history = useHistory();
    const _renderVideos = () => {
      // @ts-expect-error TS18048
      const videos = props.rows.map((singleVideo) => {
        return (
          <VideoTopCategorySingleCard
            onClick={() => {
              history.push(`/dashboard/videos-play/${singleVideo.id}`);
            }}
            key={singleVideo.id}
            singleVideo={singleVideo}
          />
        );
      });
      if (videos.length > 0) {
        videos.push(
          <SingleLArgeVideoRoot
            id={`videos-large-category-card${props.largeCategory.id}`}
            onClick={() => {
              history.push(
                `/dashboard/videos-large-category/${props.largeCategory.id}/${props.largeCategory.name}`,
              );
            }}
          >
            <PlusButton />
            <VideoShowInfo>
              {intl.formatMessage({
                id: "screen.label.show_all_videos",
              })}
            </VideoShowInfo>
          </SingleLArgeVideoRoot>,
        );
      }
      return videos;
    };

    // @ts-expect-error TS18048
    if (props.rows.length > 0) {
      return (
        <>
          <Card style={{ marginBottom: "5vh" }}>
            <Row>
              <Col>
                <p
                  style={{
                    fontWeight: "bold",
                    color: "#323232",
                    paddingLeft: "2vw",
                    marginBottom: 0,
                    fontStyle: "normal",
                    fontSize: "18px",
                    lineHeight: "26px",
                  }}
                >
                  {props.largeCategory.name}
                </p>
              </Col>
            </Row>
            <br />
            <Row
              style={{
                overflowX: "auto",
                overflowY: "hidden",
                whiteSpace: "nowrap",
                flexFlow: "nowrap",
              }}
            >
              {_renderVideos()}
            </Row>
            <Row>
              <Col
                xs={{ offset: 18, span: 6 }}
                sm={{ offset: 20, span: 4 }}
                lg={{ offset: 22, span: 2 }}
              >
                <Button type="link">
                  <Link
                    style={{
                      color: "#1F86E9",
                      fontStyle: "normal",
                      fontWeight: "bold",
                      fontSize: "0.9rem",
                      lineHeight: "200%",
                    }}
                    id={`videos-large-category-button${props.largeCategory.id}`}
                    to={`/dashboard/videos-large-category/${props.largeCategory.id}/${props.largeCategory.name}`}
                  >
                    {intl.formatMessage({
                      id: "screen.label.view_all",
                    })}
                  </Link>
                </Button>
              </Col>
            </Row>
          </Card>
        </>
      );
    }
    return <></>;
  };

SingleVideoLargeCategory.defaultProps = {
  rows: [],
};

SingleVideoLargeCategory.propTypes = {
  rows: PropTypes.array,
  // @ts-expect-error TS2322
  gotoPlayVideoScreen: PropTypes.func,
  history: PropTypes.object,
  largeCategory: PropTypes.object,
};
// @ts-expect-error TS2345
export default withRouter(SingleVideoLargeCategory);
