import { SmileOutlined } from "@ant-design/icons";
import Picker from "emoji-picker-react";
import { FC, useContext, useEffect, useRef, useState } from "react";
import { injectIntl } from "react-intl";
import { connect, useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";

import { CommentItem } from "@/features/projectComment/component/CommentItem";
import { ContentContext } from "@/Provider";
import CommentActions from "@/Redux/CommentRedux";
import { ICommentComponentProps } from "@/Screens/project/types/ICommentComponentProps";

import { endpoints, parseEndpoint } from "../../../Services/endpoints";
import { convertYVLinksToEmbed, getObjUUID } from "../../../utils/common";
import { Button, Card } from "../../atoms";
import { nodeConfig, uploadImageWithDraftEditor } from "../../molecules";
import EmojiComponent from "./EmojiComponent";

const CommentComponent: FC<ICommentComponentProps> = (props) => {
  const dispatch = useDispatch();
  // @ts-expect-error TS2339
  const project = useSelector((state) => state.projects.projectPayload);
  const projectId = project.uuid;
  const { user, apiRequest } = useContext(ContentContext);
  const scrollIntoRef = useRef(null);

  const [showAllChildren, setShowAllChildren] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState<{
    [identifier: string]: boolean;
  }>({});

  useEffect(() => {
    setShowAllChildren(props.shouldShowCommentsReplies);
  }, [props.shouldShowCommentsReplies]);

  const uploadImageCallBack = (file: any) => {
    return new Promise((resolve, reject) => {
      uploadImageWithDraftEditor({
        file,
        companyId: getObjUUID(user.company),
        authToken: user.token,
        subLink: "notification",
        headerDetail: {
          type: "comments",
          projectId,
        },
      })
        .then((response: any) => {
          resolve(response);
        })

        .catch((error) => {
          reject(error);
        });
    });
  };

  // @ts-expect-error TS7006
  const _renderMainCommentReaction = (comment, index, showChildren) => {
    const pickerStyle = {};
    if (props.comments.length - 1 === index && !showChildren) {
      // pickerStyle.bottom = -70
    }

    return (
      <>
        {props.canEdit && (
          <Button
            type="link"
            size="small"
            style={{
              marginTop: 3,
              marginRight: 10,
            }}
            // @ts-expect-error TS7006
            onClick={(e) => {
              e.stopPropagation();
              _toggleEmojiPicker(`${comment.id}_0`);
            }}
            icon={<SmileOutlined />}
          >
            +
          </Button>
        )}
        <span
          style={{
            position: "absolute",
            ...pickerStyle,
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {showEmojiPicker[`${comment.id}_0`] ? (
            <Picker
              pickerStyle={
                {
                  // top: `${(index*200)+1}px`,
                }
              }
              onEmojiClick={(event, emojiObject) => {
                event.stopPropagation();
                _toggleEmojiPicker(`${comment.id}_0`);
                _postReactionForParentComment(
                  emojiObject.emoji,
                  emojiObject.originalUnified,
                  comment.id,
                );
              }}
            />
          ) : null}
        </span>
        <>{_renderEmojis(comment.emojis, comment.id)}</>
      </>
    );
  };

  const _renderEmojis = (emojis: any, commentId: number, threadId?: number) => {
    if (emojis) {
      const keys = Object.keys(emojis);
      return keys.map((key, index) => {
        return (
          <Button
            key={`_renderEmojis_${index}`}
            style={{
              padding: 0,
              backgroundColor: "transparent",
            }}
            onClick={() => {
              if (threadId) {
                _postReactionForThread(
                  emojis[key].emoji,
                  key,
                  commentId,
                  threadId,
                );
              } else {
                _postReactionForParentComment(
                  emojis[key].emoji,
                  key,
                  commentId,
                );
              }
            }}
          >
            <EmojiComponent
              emoji={emojis[key]}
              // @ts-expect-error TS2322
              symbol={`${emojis[key].emoji}`}
              totalReaction={emojis[key].usersName.length}
            />
          </Button>
        );
      });
    }
  };

  const _toggleEmojiPicker = (identifier: string) => {
    const _showEmojiPicker = { ...showEmojiPicker };
    if (_showEmojiPicker[identifier]) {
      _showEmojiPicker[identifier] = !_showEmojiPicker[identifier];
    } else {
      _showEmojiPicker[identifier] = true;
    }
    // @ts-expect-error TS2722
    props.onEmojiVisibilityChanged(_showEmojiPicker[identifier]);
    setShowEmojiPicker(_showEmojiPicker);
  };
  // @ts-expect-error TS7006
  const _renderThreadMessageFooter = (commentId, thread) => {
    return (
      <>
        {props.canEdit && (
          <Button
            type="link"
            size="small"
            style={{
              marginRight: 10,
            }}
            // @ts-expect-error TS7006
            onClick={(e) => {
              e.stopPropagation();
              _toggleEmojiPicker(`${commentId}_${thread.id}`);
            }}
            icon={
              <>
                <SmileOutlined />
              </>
            }
          >
            +
          </Button>
        )}
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {showEmojiPicker[`${commentId}_${thread.id}`] ? (
            <Picker
              pickerStyle={{
                position: "absolute",
                top: "3vh",
              }}
              onEmojiClick={(event, emojiObject) => {
                event.stopPropagation();
                _toggleEmojiPicker(`${commentId}_${thread.id}`);
                _postReactionForThread(
                  emojiObject.emoji,
                  emojiObject.originalUnified,
                  commentId,
                  thread.id,
                );
              }}
            />
          ) : null}
        </div>
        {_renderEmojis(thread.threadEmojis, commentId, thread.id)}
      </>
    );
  };

  // @ts-expect-error TS7006
  const _postReactionForThread = (emoji, emojiName, commentId, threadId) => {
    const requestBuilder = {
      url: parseEndpoint(endpoints.threadReaction, {
        projectId,
      }),
      method: "putRequest",
      data: {
        emoji,
        threadId,
        emojiName,
        commentId,
      },
    };
    // @ts-expect-error TS2722
    apiRequest(requestBuilder, () => {
      dispatch(
        // @ts-expect-error TS7006
        CommentActions.commentGetsRequest({
          projectId,
          page: 0,
        }),
      );
    });
  };
  // @ts-expect-error TS7006
  const _postReactionForParentComment = (emoji, emojiName, commentId) => {
    const requestBuilder = {
      url: parseEndpoint(endpoints.projectCommentReaction, {
        projectId,
      }),
      method: "putRequest",
      data: {
        emoji,
        commentId,
        emojiName,
      },
    };
    // @ts-expect-error TS2722
    apiRequest(requestBuilder, () => {
      dispatch(
        // @ts-expect-error TS7006
        CommentActions.commentGetsRequest({
          projectId,
          page: 0,
        }),
      );
    });
  };

  if (!props.comments || !props.comments.length) {
    return null;
  }

  return (
    <div>
      <Card
        onClick={(e) => {
          const keys = Object.keys(showEmojiPicker);
          if (keys.length > 0) {
            // @ts-expect-error TS7006
            props.onEmojiVisibilityChanged(false);
            setShowEmojiPicker({});
          }
        }}
        bordered={false}
        style={{
          borderRadius: "10px",
        }}
      >
        {props.comments.map((comment, index) => {
          return (
            <CommentItem
              key={comment.id}
              projectId={projectId}
              comment={comment}
              showAllChildren={showAllChildren}
              index={index}
              user={user}
              canEdit={props.canEdit}
              suggestions={props.suggestions}
              editorToolbar={nodeConfig(
                uploadImageCallBack,
                convertYVLinksToEmbed.bind(this, user.token, user.companyId),
              )}
              renderThreadMessageFooter={_renderThreadMessageFooter}
              renderMainCommentReaction={_renderMainCommentReaction}
            />
          );
        })}
        <div ref={scrollIntoRef} />
      </Card>
    </div>
  );
};
// @ts-expect-error TS7006
const mapStateToProps = (state) => ({});
// @ts-expect-error TS7006
const mapDispatchToProps = (dispatch) => ({});
CommentComponent.defaultProps = {
  canEdit: false,
  comments: [],
};
export default injectIntl(
  // @ts-expect-error TS2769
  withRouter(connect(mapStateToProps, mapDispatchToProps)(CommentComponent)),
);
