import { Input, Layout } from "antd";
import styled from "styled-components";

export const LoginLabel = styled.h5`
  font-weight: 500;
  color: #686868;
  font-size: 1.15em;
  margin-top: 30px;
`;
export const LoginBanner = styled.img`
  margin: 0 auto;
`;
export const AuthLayout = styled(Layout)`
  height: 100vh;
  background-color: #5b3395;
`;
export const PasswordResetContainer = styled.div`
  text-align: center;
`;
export const AlphaDriveImg = styled.img`
  width: auto;
`;
export const AlphaDriveImgContainer = styled.div`
  color: #686868;
  font-weight: 400;
  font-size: 11px;
  margin: 0 auto;
  padding-bottom: 30px;
`;
export const HorizontalLine = styled.hr`
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ddd;
  margin: 1em 0;
  padding: 0;
`;

export const EmailLayout = styled.div`
  padding-top: 14vh;
`;
export const SubEmailLayout = styled.div`
  padding-top: 24vh;
`;
export const Banner = styled.div`
  display: flex;
  flex-direction: column;
  float: left;
  width: 100%;
  @media only screen and (min-width: 680px) {
    width: calc(100% - 510px);
  }
`;
export const LogoTitleLayout = styled.div`
  padding: 100px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: auto auto;
`;
export const InputForm = styled(Input)`
  border: 1px solid #ebebeb !important;
  border-radius: 3px;
  height: 48px;
`;
export const InputLoginForm = styled(Input)`
  border: 1px solid #ebebeb !important;
  border-radius: 3px;
  height: 48px;
  margin-bottom: 22px;
`;

export const InputFormPassword = styled(InputForm.Password)`
  border: 1px solid #ebebeb !important;
  border-radius: 3px;
  height: 48px;
`;
export const VerifyHeaderBold = styled.h3`
  font-weight: 700;
  font-size: 22px;
`;
export const VerifySubEMailHeaderBold = styled.h3`
  font-weight: 700;
  font-size: 24px;
`;

export const VerifyParagraph = styled.p`
  font-weight: 400;
  font-size: 1.05em;
  margin-top: 0;
`;
export const EmailParagraph = styled.p`
  font-size: 0.9em;
  font-weight: 400;
`;

export const SubEmailParagraph = styled.p`
  font-size: 12px;
  font-weight: 400;
  width: 366px;
  margin-bottom: 30px;
`;

export const VerifyEmailHeader = styled.h4`
  margin-top: 15px;
`;

export const VerifyEmailLinkContainer = styled.div`
  display: flex;
  justify-content: center;
  font-size: 12px;
`;
export const VerifyEmailLink = styled.span`
  display: block;
  color: 1f86e9;
  fontsize: 14px;
  margin-left: 2px;
`;
export const PointerParagraph = styled.p`
  cursor: pointer;
  text-align: center;
  color: #1f86e9;
  font-weight: bold;
`;
export const LinkSpan = styled.span`
  display: block;
  text-align: center;
  font-family: "Roboto";
  font-size: 12px;
  font-weight: bold;
`;
export const LinkContainer = styled.span`
  display: block;
  color: 1f86e9;
  font-size: 12px;
  text-align: center;
  font-family: Roboto;
  font-weight: bold;
`;
export const EmailH2 = styled.h2`
  font-family: "Noto Sans JP";
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 120%;
  color: #323232;
`;
export const EmailSuccessLink = styled.span`
  display: block;
  text-align: center;
  font-weight: bold;
`;
export const LoginDiv = styled.div`
  margin-bottom: 30px;
`;
export const EmailDiv = styled.div`
  margin-bottom: 100px;
`;
