import { message, Upload } from "antd";
import { UploadChangeParam, UploadFile } from "antd/lib/upload/interface";
import axios from "axios";
import React, { useContext, useState } from "react";
import { useIntl } from "react-intl";

import { ContentContext } from "../../../Provider";
import { loadMime } from "../../../utils/common";
const { Dragger } = Upload;

type Props = {
  isDragger: boolean;
  action: string;
  saveUrl: string;
  data: object;
  children: React.ReactChild;
  onFetchFileList: () => void;
  beforeUpload: (file: File) => Promise<boolean>;
  onChange?: () => void;
};

export const previewFiletypes = [
  "application/pdf",
  "application/msword",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.ms-powerpoint",
];

export const supportedFiletypes = [
  "image/jpeg",
  "image/png",
  "video/3gpp",
  "application/pdf",
  "audio/x-aac",
  "text/csv",
  "image/gif",
  "text/html",
  "video/mp4",
  "audio/mp4",
  "text/plain",
  "audio/aac",
  "video/x-msvideo",
  "image/bmp",
  "application/x-bzip",
  "application/x-bzip2",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/epub+zip",
  "audio/mpeg",
  "application/vnd.rar",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/zip",
  "application/x-7z-compressed",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.ms-powerpoint",
];

export const handleCustomRequest = async ({
  action,
  data,
  file,
  filename,
  headers,
  onError,
  onProgress,
  onSuccess,
  withCredentials,
}: {
  action: string;
  data: any;
  file: any;
  filename: string;
  headers: any;
  onError: any;
  onProgress: any;
  onSuccess: any;
  withCredentials: boolean;
}) => {
  let fileClone = file;
  const formData = new FormData();
  if (data) {
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
  }

  if (!supportedFiletypes.includes(fileClone.type)) {
    try {
      const mimeRes = await loadMime(fileClone);
      console.log("mimeRes", mimeRes);
      if (mimeRes && supportedFiletypes.includes(mimeRes.mime)) {
        fileClone = new File([file], file.name, {
          type: mimeRes.mime,
        });
      } else {
        onError(new Error("Unsupported file type"));
        return;
      }
    } catch (error) {
      onError(new Error("Unsupported file type"));
      return;
    }
  }

  formData.append(filename, fileClone);

  let uploadURL = `${action}/only-upload-files`;

  if (fileClone && previewFiletypes.includes(fileClone.type)) {
    uploadURL = `${action}/only-upload-files-transform`;
  }

  axios
    .post(uploadURL, formData, {
      withCredentials,
      headers,
      onUploadProgress: ({ total, loaded }) => {
        onProgress(
          {
            percent: Math.round((loaded / total) * 100).toFixed(2),
          },
          file,
        );
      },
    })
    .then(({ data: response }) => {
      if (response.status === "fail" || response.status === "error") {
        onError(new Error(response.data));
      } else {
        onSuccess(response, file);
      }
    })
    .catch(onError);

  return {
    abort() {
      console.log("upload progress is aborted.");
    },
  };
};

const Uploader = ({
  isDragger,
  action,
  saveUrl,
  data,
  children,
  onFetchFileList,
  beforeUpload,
  onChange,
}: Props) => {
  const context = useContext(ContentContext);
  const [uploadFileList, setUploadFileList] = useState<UploadFile[]>([]);
  const { apiRequest } = context;
  const intl = useIntl();
  const handleFileChange = (info: UploadChangeParam) => {
    let fileList = [...info.fileList];
    fileList = fileList.slice(-2);
    fileList = fileList.map((file) => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });
    setUploadFileList(fileList);
    const { status } = info.file;
    if (status !== "uploading") {
    }
    if (status === "done") {
      saveUploadedFileDetails(info.file?.response?.data);
      onFetchFileList();
      setUploadFileList([]);
      onChange?.();
    } else if (status === "error") {
      if (info?.file?.error?.message) {
        const errorMsg = info?.file?.error?.message;
        message.error(errorMsg);
      } else {
        message.error(`${info.file.name} file upload failed.`);
      }
    }
  };
  const saveUploadedFileDetails = (responseData: any) => {
    if (responseData) {
      const requestBuilder = {
        method: "postRequest",
        url: saveUrl,
        data: responseData,
      };
      // @ts-expect-error TS2345
      apiRequest(requestBuilder, () => {
        message.success(
          `${responseData.file.fileName} ${intl.formatMessage({
            id: "screen.label.upload_file_has_been_completed",
          })}`,
        );
      });
    }
  };
  const handleBeforeUpload = async (file: File) => {
    const shouldUpload = await beforeUpload(file);
    const limitation = 1024 * 1024 * 1000;
    const fileSize = file?.size;
    if (fileSize == null || !shouldUpload) return Upload.LIST_IGNORE;
    if (fileSize < limitation) return true;
    message.error(
      `${intl.formatMessage({
        id: "screen.label.file_limit_msg",
      })}`,
    );
    return Upload.LIST_IGNORE;
  };

  const draggerProps = {
    action,
    name: "fileName",
    multiple: true,
    data,
    headers: {
      Authorization: `Bearer ${context?.user?.token}`,
      alphafrontEndPath: window.location.href,
    },
    accept: supportedFiletypes,
    customRequest: handleCustomRequest,
    beforeUpload: handleBeforeUpload,
    onChange: handleFileChange,
  };

  const UploadComp = isDragger ? Dragger : Upload;

  return (
    // @ts-expect-error TS2769
    <UploadComp fileList={uploadFileList} {...draggerProps}>
      {children}
    </UploadComp>
  );
};

export default Uploader;
