import { ApiResponse } from "apisauce";

import { JsendError } from "../../types/JSendObject";
import { CommonResponse } from "./commonResponsTypes";

export const isFailedResponse = <
  Data = unknown,
  T extends CommonResponse<Data> = CommonResponse<Data>,
>(
  response: ApiResponse<T, JsendError>,
) => {
  return !response.ok || response.data?.status !== "success";
};
