import styled from "styled-components";

export const Badge = styled.span`
  background-color: #00bf76;
  padding: 4px;
  border-radius: 4px;
  margin-left: 11px;
  color: white;
  font-size: 0.7rem;
`;
