import { FC, useMemo } from "react";

import { ReferenceThumbAltIcon } from "../ReferenceThumbAltIcon/component";
import { getFileIconFromUrl, getThumbnailImageUrl } from "./helper";

export type ReferenceThumbImageProps = {
  size?: number;
  borderRadius?: number;
  thumbnailUrl: string | null;
};

const ReferenceThumbImage: FC<ReferenceThumbImageProps> = ({
  size,
  borderRadius,
  thumbnailUrl,
}) => {
  const altIcon = useMemo(
    () => getFileIconFromUrl(thumbnailUrl),
    [thumbnailUrl],
  );
  const thumbnailImageUrl = useMemo(
    () =>
      thumbnailUrl?.startsWith("data:") === true
        ? thumbnailUrl
        : getThumbnailImageUrl(thumbnailUrl),
    [thumbnailUrl],
  );
  const imageSize = useMemo(() => (size ? `${size}px` : "100%"), [size]);
  const imageBorderRadius = useMemo(
    () => (borderRadius ? `${borderRadius}px` : "6px"),
    [borderRadius],
  );
  if (thumbnailImageUrl) {
    return (
      <img
        src={thumbnailImageUrl}
        style={{
          width: imageSize,
          height: imageSize,
          borderRadius: imageBorderRadius,
        }}
        alt="thumbnail"
      />
    );
  }
  return <ReferenceThumbAltIcon size={size} type={altIcon} />;
};

export { ReferenceThumbImage };
