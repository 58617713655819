import { Card, Col, Row } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import Title from "antd/lib/typography/Title";
import { useIntl } from "react-intl";

import { IconGrayWarning } from "../../../Components/atoms/icons";

export const CompanyReferenceNotFoundCard = () => {
  const intl = useIntl();

  return (
    <Card className="library-news" style={{ height: "100%" }}>
      <Row align="middle" justify="center" style={{ marginTop: "120px" }}>
        <Col flex="none"> {IconGrayWarning} </Col>

        <Col span={10} style={{ marginLeft: "40px" }}>
          <Title level={4}>
            {intl.formatMessage({
              id: "screen.label.file_not_found",
            })}
          </Title>
          <Paragraph style={{ marginBottom: "0" }}>
            {intl.formatMessage({
              id: "screen.label.reasons_for_missing_files",
            })}
          </Paragraph>
        </Col>
      </Row>
    </Card>
  );
};
