import { Col, Row } from "antd";
import { FC, useState } from "react";
import { FormattedMessage } from "react-intl";

import { Button, Tag } from "@/Components/atoms";
import { RenderMilestoneComponent } from "@/Components/molecules/project/ProjectTopScreen";
import { ProjectApplyToBeMemberButton } from "@/features/project/components/ProjectApplyToBeMemberButton";
import { ProjectMileStoneLabel } from "@/features/project/components/ProjectMileStoneLabel";
import { ProjectSubmissionModal } from "@/features/submission/components/ProjectSubmissionModal";
import { isEmptyString } from "@/utils/common";

type ProjectTopComponentTopAreaProps = {
  stage: string;
  isOwner: boolean;
};

export const ProjectTopComponentTopArea: FC<
  ProjectTopComponentTopAreaProps
> = ({ stage, isOwner }) => {
  const [visibleSubmissionModal, setVisibleSubmissionModal] = useState(false);
  return (
    <Row
      align="middle"
      style={{
        paddingBottom: 24,
        borderBottom: "1px solid #DDDDDD",
      }}
    >
      {/* MileStone */}
      <Col flex="3">
        <RenderMilestoneComponent>
          {!isEmptyString(stage) && (
            <Tag
              style={{
                border: "1px solid #323232",
                borderRadius: "0px",
              }}
            >
              {stage}
            </Tag>
          )}
          <ProjectMileStoneLabel />
        </RenderMilestoneComponent>
      </Col>
      {/* Member */}
      <Col flex="none">
        <div
          style={{
            float: "right",
            marginRight: isOwner ? 20 : 0,
          }}
        >
          <ProjectApplyToBeMemberButton />
        </div>
      </Col>
      {/* Submission */}
      <Col flex="none">
        <div style={{ float: "right" }}>
          {isOwner && (
            <>
              <Button
                style={{
                  width: "100%",
                  color: "#1F86E9",
                  border: "1px solid #1F86E9",
                }}
                onClick={() => setVisibleSubmissionModal(true)}
              >
                <FormattedMessage id="screen.label.hand_in" />
              </Button>
              <ProjectSubmissionModal
                visible={visibleSubmissionModal}
                onClose={() => setVisibleSubmissionModal(false)}
              />
            </>
          )}
        </div>
      </Col>
    </Row>
  );
};
