import { VerticalLayout } from "../../atoms/layout";
import { InputTitle, Props } from "../index";

export const VerticalTitle = (props: Props) => {
  return (
    <>
      <br />
      <VerticalLayout>
        {props.title && props.title !== "" && (
          <InputTitle>{props.title}</InputTitle>
        )}
        {props.children}
      </VerticalLayout>
      <br />
    </>
  );
};
