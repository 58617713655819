import styles from "./styles.module.scss";
import { CategoryNameTagProps } from "./type";

export const CategoryNameTag = ({ categoryName }: CategoryNameTagProps) => {
  return (
    <div className={styles.category_name_tag}>
      <span className={styles.category_name_label}>{categoryName}</span>
    </div>
  );
};
