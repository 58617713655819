import { Tooltip } from "antd";
import { Link } from "react-router-dom";

import { getObjUUID } from "../../../utils/common";
import * as Icons from "../../atoms/icons";
import {
  ProjectFixedMenuItem,
  ProjectFloatMenuItem,
  ProjectMenuTitleSpan,
} from "../../molecules";

type ProjectsProps = {
  projects: Array<any>;
  gotoScreen: (url: string) => void;
};
export const ProjectsFixed = (props: ProjectsProps) => {
  return (
    <>
      {props.projects.map((project) => {
        return (
          <ProjectFixedMenuItem
            className="project-side-fixed-menu"
            id={`sidebar-project-top-${getObjUUID(project)}`}
            key={`/dashboard/project.top/${getObjUUID(project)}`}
          >
            <Link to={`/dashboard/project.top/${getObjUUID(project)}`}>
              <Icons.ProjectIcon
                projectId={project.id}
                url={project.icon}
                size={18}
              />
              <ProjectMenuTitleSpan>{project.name}</ProjectMenuTitleSpan>
            </Link>
          </ProjectFixedMenuItem>
        );
      })}
    </>
  );
};

export const ProjectsFloat = (props: ProjectsProps) => {
  return (
    <>
      {props.projects.map((project) => {
        return (
          <ProjectFloatMenuItem
            key={`/dashboard/project.top/${getObjUUID(project)}`}
            itemIcon={null}
            title={null}
            onClick={() => {
              props.gotoScreen(`/dashboard/project.top/${getObjUUID(project)}`);
            }}
          >
            <Tooltip placement="right" title={project.name}>
              <Icons.ProjectIcon
                projectId={project.id}
                url={project.icon}
                size={18}
              />
            </Tooltip>
          </ProjectFloatMenuItem>
        );
      })}
    </>
  );
};
