import { notification } from "antd";
import React from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import ApiActions, { ApiRequestData } from "../../../Redux/ApiRedux";
import { endpoints, parseEndpoint } from "../../../Services/endpoints";
import { getObjUUID } from "../../../utils/common";

// @ts-expect-error TS7006
const useProjectLinkComponent = (props) => {
  // @ts-expect-error TS2339
  const user = useSelector((state) => state.auth.payload);
  const dispatch = useDispatch();
  const intl = useIntl();
  const { onUpdateLinks, project, showOnlyLinks = false } = props;
  const [projects, setProjects] = React.useState([]);
  const [links, setLinks] = React.useState([]);
  const [selectProjects, setSelectProjects] = React.useState([]);
  const [searchValue, setSearchValue] = React.useState("");
  const [deleting, setDeleting] = React.useState(false);
  React.useEffect(() => {
    if (user && user.type === "admin" && !showOnlyLinks) {
      getAllProject();
    }
    if (project && getObjUUID(project)) {
      getProjectLinks(getObjUUID(project));
    }
  }, [user, project]);
  const getAllProject = () => {
    const requestBuilder: ApiRequestData = {
      method: "getRequest",
      url: parseEndpoint(endpoints.allProjects),
    };
    dispatch(
      ApiActions.apiRequest(requestBuilder, ({ data }) => {
        //@ts-expect-error 要API型定義
        setProjects(data);
      }),
    );
  };
  // @ts-expect-error TS7006
  const getProjectLinks = (projectId) => {
    const requestBuilder: ApiRequestData = {
      method: "getRequest",
      url: parseEndpoint(endpoints.singleProjectLinks, { projectId }),
    };
    dispatch(
      ApiActions.apiRequest(requestBuilder, ({ data }) => {
        //@ts-expect-error 要API型定義
        setLinks(data);
      }),
    );
  };
  // @ts-expect-error TS7006
  const deleteProjectLink = (id) => {
    if (deleting) {
      return;
    }
    if (links && links.length) {
      if (project && project.uuid) {
        setDeleting(true);
        const requestBuilder: ApiRequestData = {
          method: "deleteRequest",
          url: parseEndpoint(endpoints.deleteProjectLink, {
            projectId: project.uuid,
            id,
          }),
        };
        dispatch(
          ApiActions.apiRequest(requestBuilder, ({ data }) => {
            setDeleting(false);
            // @ts-expect-error TS2339
            const cloneLinks = links.filter((a) => a.uuid !== id);
            setLinks(cloneLinks);
            onUpdateLinks(cloneLinks.map((a) => getObjUUID(a)));
          }),
        );
      } else {
        // @ts-expect-error TS2339
        const cloneLinks = links.filter((a) => a.uuid !== id);
        setLinks(cloneLinks);
        onUpdateLinks(cloneLinks.map((a) => getObjUUID(a)));
      }
    }
  };
  const filteredProjects = React.useMemo(() => {
    let filteredProjects = [];
    const linkedProjectUUIDs = links.map((a) => getObjUUID(a));
    filteredProjects = projects.filter(
      (p) => !linkedProjectUUIDs.includes(getObjUUID(p)),
    );
    return filteredProjects;
  }, [projects, links]);
  // @ts-expect-error TS7006
  const handleSearch = (searchVal) => {
    if (searchVal.includes("/project.top/")) {
      const splitedLinks = searchVal.split("/project.top/");
      if (splitedLinks.length > 0) {
        const cloneSelectedProjects = [...selectProjects];
        const uuid = splitedLinks[1];
        if (uuid !== "") {
          // @ts-expect-error TS2345
          if (!cloneSelectedProjects.includes(uuid)) {
            const project = filteredProjects.find(
              (a) => getObjUUID(a) === uuid,
            );
            if (project) {
              // @ts-expect-error TS2345
              cloneSelectedProjects.push(uuid);
            } else {
              notification.warning({
                message: intl.formatMessage({
                  id: "screen.label.project_url_not_found",
                }),
              });
            }
            setSelectProjects(cloneSelectedProjects);
            setSearchValue("");
          } else {
            notification.warning({
              message: intl.formatMessage({
                id: "screen.label.project_already_added",
              }),
            });
          }
        } else {
          notification.warning({
            message: intl.formatMessage({
              id: "screen.label.project_url_not_found",
            }),
          });
        }
      }
    } else {
      setSearchValue(searchVal);
    }
  };
  return {
    deleteProjectLink,
    showOnlyLinks,
    user,
    links,
    intl,
    selectProjects,
    setSelectProjects,
    setSearchValue,
    searchValue,
    handleSearch,
    filteredProjects,
    setLinks,
    onUpdateLinks,
  };
};

export default useProjectLinkComponent;
