import { useDisplayUserName } from "./hooks";
import styles from "./styles.module.scss";
import { UserNameLabelProps } from "./type";

export const UserNameLabel = ({
  name,
  hasChangedPassword,
  stoppedAt,
  deletedAt,
  className,
  ...rest
}: UserNameLabelProps) => {
  const displayName = useDisplayUserName({
    name,
    hasChangedPassword,
    stoppedAt,
    deletedAt,
  });
  return (
    <p className={`${styles.user_name_label} ${className}`} {...rest}>
      {displayName}
    </p>
  );
};
