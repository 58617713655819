import { Col, Row } from "antd";
import moment from "moment";
import PropTypes from "prop-types";
import { FC } from "react";
import { Link, useParams } from "react-router-dom";

import useProjectsInvitedListComponent from "../../../Screens/project/hooks/useProjectsInvitedListComponent";
import { IProjectsInvitedListComponentProps } from "../../../Screens/project/types/IProjectsInvitedListComponentProps";
import {
  getObjUUID,
  getProjectAvatar,
  isEmptyString,
} from "../../../utils/common";
import { dateTimeFormat } from "../../../utils/constants";
import { ButtonCV, Card, Checkbox, Table } from "../../atoms";
import { InsuAcceptButton, InsuRejectButton } from "../../atoms/buttons/shared";
import { CustomPagination } from "../../molecules";
const PageSaveId = "ProjectsInvitedListComponent";

const ProjectsInvitedListComponent: FC<IProjectsInvitedListComponentProps> =
  function (props) {
    const {
      handleStageChange,
      state,
      handleTagChange,
      intl,
      getColumnMemberStatusProps,
      rejectToJoinProject,
      acceptToJoinProject,
      onTableChange,
      fetchProjects,
      setState,
    } = useProjectsInvitedListComponent(props);

    const { company, authUser } = props;

    // @ts-expect-error TS2339
    const { id: batchId } = useParams();

    const getSelectedBatch = () => {
      if (props.batchId === "") {
        return {
          displayCheckpoint: true,
        };
      }
      // @ts-expect-error TS2339
      if (Array.isArray(props.batches)) {
        // @ts-expect-error TS2339
        return props.batches.find((b: any) => b.id === props.batchId);
      }
      return null;
    };

    const getColumns = () => {
      const selectedBatch = getSelectedBatch();

      const columnsRequest = [
        {
          title: "ID",
          dataIndex: "id",
          key: "id",
          sorter: true,
          sortDirections: ["descend", "ascend"],
        },
        {
          title: "",
          dataIndex: "icon",
          key: "icon",
          // @ts-expect-error TS7006
          render: function _fn(text, row) {
            return <div>{getProjectAvatar(row)}</div>;
          },
        },
        {
          title: intl.formatMessage({
            id: "screen.label.project_name",
          }),
          dataIndex: "name",
          key: "name",
          // @ts-expect-error TS7006
          render: function _fn(text, row) {
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <div style={{ maxWidth: "20vw" }}>
                  <Link to={"/dashboard/project.top/" + row.id}>{text}</Link>
                </div>
              </div>
            );
          },
        },
        {
          title: intl.formatMessage({
            id: "screen.label.date_modified",
          }),
          key: "updatedAt",
          dataIndex: "updatedAt",
          // sorter: true,
          // sortOrder: state.currentProjUpdateAtSort,
          // @ts-expect-error TS7006
          render: function _fn(text) {
            return <span>{moment(text).local().format(dateTimeFormat)}</span>;
          },
          sortDirections: ["descend", "ascend"],
          responsive: ["lg"],
        },
        {
          title: intl.formatMessage({
            id: "screen.label.member_status",
          }),
          key: "type",
          dataIndex: "type",
          // @ts-expect-error TS7006
          render: function _fn(text) {
            return (
              <span>
                {isEmptyString(text)
                  ? ""
                  : intl.formatMessage({
                      id: `screen.label.${text}`.toLowerCase(),
                    })}
              </span>
            );
          },
          ...getColumnMemberStatusProps(),
          responsive: ["md"],
        },
        {
          title: intl.formatMessage({
            id: "screen.label.stage",
          }),
          key: "stage",
          dataIndex: "stage",
          ...getColumnPriorityProps(),
          responsive: ["md"],
        },
        {
          title: intl.formatMessage({
            id: "screen.label.project_label",
          }),
          key: "tag",
          dataIndex: "tag",
          ...getColumnTagProps(),
          responsive: ["md"],
        },
        // @ts-expect-error TS18048
        company.isAllowCheckpoint &&
        selectedBatch?.displayCheckpoint &&
        authUser.type !== "employee"
          ? {
              title: intl.formatMessage({
                id: "screen.label.project_list.checkpoint_coverage_rate",
              }),
              key: "checkpointCoverageRate",
              dataIndex: "checkpointCoverageRate",
              sorter: true,
              render: function _fn(checkpointCoverageRate: number, row: any) {
                if (
                  row.batch?.displayCheckpoint &&
                  checkpointCoverageRate !== -1
                ) {
                  return `${checkpointCoverageRate} %`;
                }
              },
            }
          : undefined,
        {
          title: "",
          dataIndex: "",
          key: "actions",
          width: "10vw",
          // @ts-expect-error TS7006
          render: function _fn(text, row, index) {
            return (
              <>
                <InsuAcceptButton
                  style={{
                    margin: 2,
                    backgroundColor: "#1F86E9",
                    color: "#fff",
                  }}
                  onClick={() => {
                    acceptToJoinProject(getObjUUID(row));
                  }}
                >
                  {intl.formatMessage({
                    id: "screen.label.approval",
                  })}
                </InsuAcceptButton>
                <InsuRejectButton
                  type="ghost"
                  style={{ margin: 2 }}
                  onClick={() => {
                    rejectToJoinProject(getObjUUID(row));
                  }}
                >
                  {intl.formatMessage({
                    id: "screen.label.decline",
                  })}
                </InsuRejectButton>
              </>
            );
          },
        },
      ];

      if (props.batchId === "") {
        columnsRequest.splice(5, 0, {
          title: intl.formatMessage({
            id: "screen.label.held_times",
          }),
          key: "batch",
          dataIndex: "batch",
          // @ts-expect-error TS2322
          render: function _fn(text, row) {
            if (row.batch) {
              return (
                <div
                  style={{
                    maxWidth: 150,
                    minWidth: 100,
                    wordWrap: "break-word",
                    display: "inline-block",
                  }}
                >
                  <span>{row.batch.name}</span>
                </div>
              );
            }
            return null;
          },
        });
      }
      return columnsRequest.filter((c) => c);
    };
    const getColumnPriorityProps = () => ({
      filterDropdown: function _fn({
        // eslint-disable-next-line react/prop-types
        // @ts-expect-error TS7031
        setSelectedKeys,
        // eslint-disable-next-line react/prop-types
        // @ts-expect-error TS7031
        selectedKeys,
        // eslint-disable-next-line react/prop-types
        // @ts-expect-error TS7031
        confirm,
        // eslint-disable-next-line react/prop-types
        // @ts-expect-error TS7031
        clearFilters,
      }) {
        // @ts-expect-error TS18048
        const stages = props.company.stages ? props.company.stages : [];
        // @ts-expect-error TS7034
        const options = [];
        // @ts-expect-error TS7006
        stages.forEach((o) => {
          options.push(
            <Col key={o} span={8}>
              <Checkbox value={o}>{o}</Checkbox>
            </Col>,
          );
        });
        return (
          <div style={{ padding: 8 }}>
            <Checkbox.Group
              style={{ width: "100%" }}
              value={state.stagesCheckedList}
              onChange={(value) => handleStageChange(value)}
            >
              {/*
               // @ts-expect-error TS7005 */}
              <Row>{options}</Row>
            </Checkbox.Group>
            <br />
            <ButtonCV
              type="primary"
              onClick={() => {
                clearFilters();
                setSelectedKeys([]);
                handleStageChange([]);
              }}
              size="small"
            >
              Reset
            </ButtonCV>
          </div>
        );
      },
      // @ts-expect-error TS7006
      onFilter: (value, record) => {},
    }); //
    const getColumnTagProps = () => ({
      filterDropdown: function _fn({
        // eslint-disable-next-line react/prop-types
        // @ts-expect-error TS7031
        setSelectedKeys,
        // eslint-disable-next-line react/prop-types
        // @ts-expect-error TS7031
        selectedKeys,
        // eslint-disable-next-line react/prop-types
        // @ts-expect-error TS7031
        confirm,
        // eslint-disable-next-line react/prop-types
        // @ts-expect-error TS7031
        clearFilters,
        // eslint-disable-next-line react/prop-types
      }) {
        const tags = props.tags ? props.tags : [];
        // @ts-expect-error TS7034
        const options = [];
        // @ts-expect-error TS7006
        tags.forEach((o) => {
          options.push(
            <Col key={o.id} span={8}>
              <Checkbox value={o.id}>{o.name}</Checkbox>
            </Col>,
          );
        });
        return (
          <div style={{ padding: 8 }}>
            <Checkbox.Group
              style={{ width: "100%" }}
              value={state.tagsCheckedList}
              onChange={(value) => handleTagChange(value)}
            >
              {/*
               // @ts-expect-error TS7005 */}
              <Row>{options}</Row>
            </Checkbox.Group>
            <br />
            <ButtonCV
              type="primary"
              onClick={() => {
                clearFilters();
                setSelectedKeys([]);
                // @ts-expect-error TS2554
                handleTagChange();
              }}
              size="small"
            >
              Reset
            </ButtonCV>
          </div>
        );
      },
      // @ts-expect-error TS7006
      onFilter: (value, record) => {},
    }); //
    return (
      <>
        <h2>
          {intl.formatMessage(
            {
              id: "screen.label.project_that_has_been_invited_count_matter",
            },
            {
              count: state.projects.count,
            },
          )}
        </h2>

        <Card>
          <Table
            pagination={false}
            // @ts-expect-error TS2769
            columns={getColumns()}
            dataSource={state.projects.rows}
            onChange={onTableChange}
            className="ant-table-x-scroll"
            locale={{
              emptyText: intl.formatMessage({
                id: "screen.label.no_invited_projects",
              }),
            }}
          />
          <br />
          <CustomPagination
            saveId={PageSaveId}
            count={state.projects.count}
            initPage={state.page}
            initLimit={state.limit}
            onChangePage={(page, limit) => {
              setState(
                {
                  page,
                  limit,
                },
                () => {
                  fetchProjects();
                },
              );
            }}
          />
        </Card>
        <br />
      </>
    );
  };
ProjectsInvitedListComponent.propTypes = {
  authUser: PropTypes.object,
  batchId: PropTypes.string,
  // @ts-expect-error TS2322
  dispatchApiRequest: PropTypes.func,
  userId: PropTypes.any,
  company: PropTypes.object,
  tags: PropTypes.array,
  batches: PropTypes.array,
};
export default ProjectsInvitedListComponent;
