import { notification } from "antd";
import { all, call, put, takeLatest } from "redux-saga/effects";

import FilesActions, { FilesTypes } from "../Redux/FilesRedux";
import { endpoints, parseEndpoint } from "../Services/endpoints";

// @ts-expect-error TS7006
function* attemptDeleteFile(api, { fileId, projectId, message }) {
  // @ts-expect-error TS7057
  const filesResponse = yield call(
    api.deleteRequest,
    parseEndpoint(endpoints.deleteProjectFile, { projectId, fileId }),
  );

  if (filesResponse.ok) {
    switch (filesResponse.data.status) {
      case "success":
        yield put(
          FilesActions.filesGetsRequest(
            // @ts-expect-error TS2554
            0,
            process.env.REACT_APP_PAGE_SIZE,
            projectId,
          ),
        );
        yield put(FilesActions.filesDeleteSuccess());
        notification.success({
          message: message,
        });
        break;
      case "fail":
      case "error":
        if (typeof filesResponse.data.data === "string") {
        }
        // @ts-expect-error TS2554
        yield put(FilesActions.filesDeleteFailure(filesResponse.data.data));
        break;
      default:
        break;
    }
  } else {
    // @ts-expect-error TS2554
    yield put(FilesActions.filesDeleteFailure(filesResponse.problem));
    if (filesResponse.status === 401) {
      // @ts-expect-error TS2322
      window.location = "/login";
    }
  }
}

// @ts-expect-error TS7006
function* attemptGetAllFiles(api, { page, limit, id, filter, sort }) {
  const endpoint = encodeURI(
    `projects/${id}/files?page=${page}&limit=${limit}${
      filter ? `&filter=${filter}` : ``
    }${sort ? `&sort=${sort}` : ``}`,
  );

  // @ts-expect-error TS7057
  const filesResponse = yield call(api.getRequest, endpoint);
  if (filesResponse.ok) {
    switch (filesResponse.data.status) {
      case "success":
        // @ts-expect-error TS2554
        yield put(FilesActions.filesGetsSuccess(filesResponse.data.data));
        break;
      case "fail":
      case "error":
        if (typeof filesResponse.data.data === "string") {
        }
        // @ts-expect-error TS2554
        yield put(FilesActions.filesGetsFailure(filesResponse.data.data));
        break;
      default:
        break;
    }
  } else {
    notification.error({
      message: filesResponse.problem,
    });
    // @ts-expect-error TS2554
    yield put(FilesActions.filesGetsFailure(filesResponse.problem));
    if (filesResponse.status === 401) {
      // @ts-expect-error TS2322
      window.location = "/login";
    }
  }
}

// @ts-expect-error TS7006
function* filesSaga(api) {
  yield all([
    // @ts-expect-error TS2345
    takeLatest(FilesTypes.FILES_DELETE_REQUEST, attemptDeleteFile, api),
    // @ts-expect-error TS2345
    takeLatest(FilesTypes.FILES_GETS_REQUEST, attemptGetAllFiles, api),
  ]);
}

export default filesSaga;
