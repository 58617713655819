import { FC, useEffect } from "react";
import { connect } from "react-redux";

import { ReduxDispatch, ReduxState } from "@/Redux/types";

import { Line } from "../../Components/atoms";
import AllProjectListComponent from "../../Components/organisms/project/AllProjectListComponent";
import MyProjectListComponent from "../../Components/organisms/project/MyProjectListComponent";
import ProjectsInvitedListComponent from "../../Components/organisms/project/ProjectsInvitedListComponent";
import ApiActions, {
  ApiRequestData,
  ApiSuccessCallback,
} from "../../Redux/ApiRedux";
import BatchActions from "../../Redux/BatchRedux";
import HomeActions from "../../Redux/HomeRedux";
import ProjectActions from "../../Redux/ProjectRedux";
import useBatchRequest from "../../Services/batches/useBatchRequest";
import useMyProjectsRequest from "../../Services/projects/useMyProjectsRequest";
import { getObjUUID } from "../../utils/common";
import useProjectsListScreen from "./hooks/useProjectsListScreen";
import { IProjectListScreenProps } from "./types/IProjectListScreenProps";

const ProjectListScreen: FC<IProjectListScreenProps> = function (
  props: IProjectListScreenProps,
) {
  const { state, getBatchId, handleAllProjectsAdvanceFilter } =
    useProjectsListScreen(props);
  const { batch, updateBatch } = useBatchRequest(props.match.params.id);

  useEffect(() => {
    updateBatch();
  }, [updateBatch]);

  const { user, company, apiRequest } = props.context;
  const { myProjects, myProjectsCount, updateMyProjects } =
    // @ts-expect-error TS2345
    useMyProjectsRequest(user.id);

  return (
    <>
      <MyProjectListComponent
        authUser={user}
        projects={myProjects}
        projectsCount={myProjectsCount}
        company={company}
        tags={state.tags}
        batches={props.batches}
        handleMyProjectctsAdvanceFilter={updateMyProjects}
        batchId={getBatchId()}
        batch={batch}
      />
      <br />
      <Line color={"#BBBBBB"} />
      <br />
      <ProjectsInvitedListComponent
        authUser={user}
        batchId={getBatchId()}
        dispatchApiRequest={apiRequest}
        // @ts-expect-error TS2322
        page={1}
        tags={state.tags}
        company={company}
        userId={getObjUUID(user)}
        batches={props.batches}
      />
      <br />
      <Line color={"#BBBBBB"} />
      <br />
      <AllProjectListComponent
        // @ts-expect-error TS2322
        batches={props.batches}
        batchId={getBatchId()}
        authUser={user}
        projects={props.projects}
        dispatchApiRequest={apiRequest}
        company={company}
        dispatchGetAllProjects={props.dispatchGetAllProjects}
        dispatchSetSuccessAllProjects={props.dispatchSetSuccessAllProjects}
        tags={state.tags}
        handleAllProjectsAdvanceFilter={handleAllProjectsAdvanceFilter}
      />
    </>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  projects: state.projects,
  userId: getObjUUID(state.auth.payload),
  company: state.company.companyPayload,
  authUser: state.auth.payload,
  batches: state.batch.batches,
});
const mapDispatchToProps = (dispatch: ReduxDispatch) => ({
  // @ts-expect-error TS7006
  dispatchSetPageTitle: (pageTitle) =>
    // @ts-expect-error TS2554
    dispatch(HomeActions.pageTitle(pageTitle)),
  dispatchApiRequest: (data: ApiRequestData, callback: ApiSuccessCallback) =>
    dispatch(ApiActions.apiRequest(data, callback)),
  // @ts-expect-error TS7006
  dispatchSetSuccessAllProjects: (data) =>
    // @ts-expect-error TS2554
    dispatch(ProjectActions.projectGetsSuccess(data)),
  // @ts-expect-error TS7006
  dispatchSetSuccessMyProjects: (data) =>
    // @ts-expect-error TS2554
    dispatch(ProjectActions.projectCurrentMemberGetsSuccess(data)),
  // @ts-expect-error TS7006
  dispatchGetAllProjects: (page, limit, filter, sort, tag) =>
    // @ts-expect-error TS2554
    dispatch(ProjectActions.projectGetsRequest(page, limit, filter, sort, tag)),
  dispatchFetchBatches: () => dispatch(BatchActions.batchGetsRequest()),
});
export default connect(mapStateToProps, mapDispatchToProps)(ProjectListScreen);
