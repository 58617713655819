import { StarFilled, StarOutlined } from "@ant-design/icons";
import { Button, Card, Col, Popconfirm, Row, Space } from "antd";
import moment from "moment";
import { useContext, useEffect, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";

import { ReferenceThumbImage } from "@/features/reference/components/ReferenceThumbImage";

import { Reference } from "../../../../types/Reference";
import {
  ButtonCV,
  ButtonOption,
  ButtonSwitch as Switch,
  Checkbox,
  CopyButton,
  HorizonalLayout,
  Table,
} from "../../../Components/atoms";
import { IconDelete, IconDownload, IconEdit } from "../../../Components/atoms";
import {
  CustomPagination,
  ReferenceDesLabel,
  SearchDefault as Search,
} from "../../../Components/molecules";
import {
  EditReferenceModal,
  NewReferenceModal,
} from "../../../Components/organisms";
import { ContentContext } from "../../../Provider";
import { normalizeUrl, saveFile } from "../../../utils/common";
import {
  dateTimeFormat,
  referenceDisplayedToEmployeesOption,
  referenceNotDisplayedToEmployeesOption,
} from "../../../utils/constants";
import { REFERENCE_TYPE } from "../../../utils/enums";
import { useReference } from "../hooks/useReference";

const PageSaveId = "CompanyReferenceList";

type CompanyReferenceListProps = {
  referenceType: REFERENCE_TYPE;
};

export const CompanyReferenceList = function ({
  referenceType,
}: CompanyReferenceListProps) {
  const { updatePageTitle } = useContext(ContentContext);
  useEffect(() => {
    updatePageTitle(`screen.label.${referenceType}_reference`);
  }, [referenceType, updatePageTitle]);

  const intl = useIntl();

  const {
    isNewModalVisible,
    onToggleNewReferenceModal,
    auth,
    references,
    onToggleEditReferenceModal,
    isEditModalVisible,
    selectedReference,
    updateReference,
    searchQuery,
    setSearchQuery,
    refreshReference,
    createReference,
    onTableChanged,
    page,
    limit,
    setLimit,
    setPage,
    deleteReference,
  } = useReference(referenceType);

  const getColumnDisplayProps = useMemo(
    () => ({
      filterDropdown: function f({
        // @ts-expect-error TS7031
        setSelectedKeys,
        // @ts-expect-error TS7031
        selectedKeys,
        // @ts-expect-error TS7031
        confirm,
        // @ts-expect-error TS7031
        clearFilters,
      }) {
        return (
          <div style={{ padding: 8 }}>
            <Checkbox.Group
              style={{ width: "100%" }}
              value={searchQuery.filter?.displayForEmployee ?? []}
              // @ts-expect-error TS2322
              onChange={(value: Array<boolean>) => {
                setSearchQuery((prevState) => {
                  return {
                    ...prevState,
                    filter:
                      value.length > 0
                        ? {
                            displayForEmployee: value,
                            isGlobal: prevState.filter?.isGlobal,
                          }
                        : undefined,
                  };
                });
              }}
            >
              <Row>
                {[
                  referenceDisplayedToEmployeesOption,
                  referenceNotDisplayedToEmployeesOption,
                ].map((o) => {
                  return (
                    // @ts-expect-error TS2322
                    <Col span={24} key={o.value}>
                      <Checkbox value={o.value}>
                        <FormattedMessage id={o.label} />
                      </Checkbox>
                    </Col>
                  );
                })}
              </Row>
              <br />
            </Checkbox.Group>
            <br />

            <ButtonCV
              onClick={() => {
                clearFilters();
                setSelectedKeys([]);
                setSearchQuery((prevState) => {
                  return {
                    ...prevState,
                    filter: {
                      displayForEmployee: [],
                      isGlobal: prevState.filter?.isGlobal,
                    },
                  };
                });
              }}
              size="small"
            >
              Reset
            </ButtonCV>
          </div>
        );
      },
      // @ts-expect-error TS7006
      onFilter: (value, record) => {},
    }),
    [setSearchQuery, searchQuery.filter?.displayForEmployee],
  );

  const columns = useMemo(() => {
    const tempColumns = [
      {
        title: "",
        dataIndex: "isStar",
        key: "isStar",
        responsive: ["sm"],
        // @ts-expect-error TS7006
        render: function _(_, reference: Reference) {
          const update = () => {
            if (auth.payload.type === "admin") {
              const companyReferenceId = reference?.CompanyReference?.id;
              updateReference(reference.id, {
                CompanyReference: {
                  companyReferenceId,
                  isStar: reference?.CompanyReference
                    ? !reference?.CompanyReference.isStar
                    : true,
                },
              });
            }
          };
          if (reference.CompanyReference && reference.CompanyReference.isStar) {
            return (
              <Button
                onClick={update}
                icon={<StarFilled style={{ color: "#1F86E9" }} />}
              />
            );
          }
          return <Button onClick={update} icon={<StarOutlined />} />;
        },
      },
      {
        title: "",
        dataIndex: "thumbnailUrl",
        key: "thumbnailUrl",
        render: function _(text: string, reference: Reference) {
          return (
            <ReferenceThumbImage
              size={72}
              thumbnailUrl={reference.thumbnailUrl}
            />
          );
        },
      },
      {
        title: intl.formatMessage({
          id: "screen.label.file_name",
        }),
        dataIndex: "fileName",
        key: "fileName",
        render: function _(text: string, reference: Reference) {
          return (
            <Link to={`/dashboard/reference-${referenceType}/${reference.id}`}>
              <ReferenceDesLabel>{text}</ReferenceDesLabel>
            </Link>
          );
        },
        sorter: true,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: intl.formatMessage({
          id: "screen.label.description",
        }),
        dataIndex: "description",
        key: "description",
        render: function _(text: string) {
          return <ReferenceDesLabel>{text}</ReferenceDesLabel>;
        },
      },
      {
        title: intl.formatMessage({
          id: "screen.label.date_time_of_addition",
        }),
        dataIndex: "uploadedAt",
        key: "uploadedAt",
        render: function _(uploadedAt: string) {
          return moment(uploadedAt).local().format(dateTimeFormat);
        },
        sorter: true,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "",
        dataIndex: "",
        key: "",
        render: function _(text: string, reference: Reference) {
          return (
            <Space>
              <Button
                onClick={() => {
                  saveFile(normalizeUrl(reference.fileUrl), reference.fileName);
                }}
              >
                {IconDownload}
              </Button>
              {!reference.isGlobal && auth.payload.type === "admin" ? (
                <Button
                  onClick={() => {
                    // @ts-expect-error TS2345
                    onToggleEditReferenceModal(reference);
                  }}
                  style={{ padding: 0 }}
                >
                  {IconEdit}
                </Button>
              ) : null}

              {!reference.isGlobal && auth.payload.type === "admin" ? (
                <Popconfirm
                  title={intl.formatMessage({
                    id: "screen.label.delete_reference_confirm",
                  })}
                  onConfirm={() => {
                    deleteReference(reference.id);
                  }}
                  okText={intl.formatMessage({
                    id: "screen.label.yes",
                  })}
                  cancelText={intl.formatMessage({
                    id: "screen.label.no",
                  })}
                >
                  <Button style={{ padding: 0 }}>{IconDelete}</Button>
                </Popconfirm>
              ) : null}
              <CopyButton
                text={`${window.location.origin}/dashboard/reference-${referenceType}/${reference.id}`}
                message={intl.formatMessage({
                  id: "screen.label.url_copied",
                })}
              />
            </Space>
          );
        },
      },
    ];

    if (auth.payload.type === "admin") {
      tempColumns.push({
        title: intl.formatMessage({
          id: "screen.label.visiblity",
        }),
        dataIndex: "visibility",
        key: "visibility",
        render: function _(text: string, reference: Reference) {
          return (
            <Switch
              onChange={(displayForEmployee) => {
                const companyReferenceId = reference?.CompanyReference?.id;
                updateReference(reference.id, {
                  CompanyReference: {
                    companyReferenceId,
                    displayForEmployee: displayForEmployee,
                  },
                });
              }}
              checked={
                reference.CompanyReference == null
                  ? true
                  : reference.CompanyReference?.displayForEmployee
              }
            />
          );
        },
        ...getColumnDisplayProps,
      });
    }
    return tempColumns;
  }, [
    intl,
    auth.payload.type,
    updateReference,
    referenceType,
    onToggleEditReferenceModal,
    deleteReference,
    getColumnDisplayProps,
  ]);

  return (
    <>
      <NewReferenceModal
        globalReference={false}
        onDismiss={onToggleNewReferenceModal}
        visible={isNewModalVisible}
        createReference={createReference}
      />
      {isEditModalVisible && selectedReference ? (
        <EditReferenceModal
          globalReference={false}
          onDismiss={onToggleEditReferenceModal}
          visible={isEditModalVisible}
          selectedReference={selectedReference}
          updateReference={updateReference}
        />
      ) : null}
      <Row>
        <Col xs={24} sm={12} md={8} lg={6}>
          <h2>{`${intl.formatMessage({
            id:
              referenceType === REFERENCE_TYPE.PRESET
                ? "screen.label.preset_reference"
                : "screen.label.original_reference",
          })}`}</h2>
        </Col>
        {auth.payload.type === "admin" &&
        referenceType === REFERENCE_TYPE.ORIGINAL ? (
          <Col xs={24} sm={12} md={16} lg={18}>
            <HorizonalLayout
              style={{
                justifyContent: "flex-end",
              }}
            >
              <ButtonOption
                onClick={() => {
                  onToggleNewReferenceModal(false);
                }}
              >
                <FormattedMessage id={"screen.label.reference_add_file"} />
              </ButtonOption>
            </HorizonalLayout>
          </Col>
        ) : null}
      </Row>
      <br />
      <Row>
        <Col span={24}>
          <Card>
            <Row>
              <Col xs={24} md={{ span: 8 }}>
                <Search
                  value={searchQuery.q}
                  placeholder={intl.formatMessage({
                    id: "screen.label.please_enter_keyword",
                  })}
                  enterButton={intl.formatMessage({
                    id: "screen.label.retrieval",
                  })}
                  onChange={(e) => {
                    setSearchQuery((prevState) => {
                      return {
                        ...prevState,
                        q: e.target.value,
                      };
                    });
                  }}
                  onSearch={(value) => {
                    refreshReference(searchQuery);
                  }}
                />
              </Col>
            </Row>
            <br />
            <Table
              pagination={false}
              rowKey={"id"}
              // @ts-expect-error TS2769
              columns={columns}
              // @ts-expect-error TS2339
              dataSource={Array.isArray(references.rows) ? references.rows : []}
              onChange={onTableChanged}
              className="ant-table-x-scroll"
            />
            <br />
            <CustomPagination
              saveId={PageSaveId}
              // @ts-expect-error TS2339
              count={references.count}
              initPage={page}
              initLimit={limit}
              onChangePage={(page, limit) => {
                setPage(page);
                setLimit(limit);
              }}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};
