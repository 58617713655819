import { PlusOutlined } from "@ant-design/icons";
import { Col, Modal, Row } from "antd";
import PropTypes from "prop-types";
import { FC } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import {
  Button,
  Card,
  IconDelete,
  Line,
  Popconfirm,
  Table,
  Tag,
} from "../../Components/atoms";
import { HorizonalLayout } from "../../Components/atoms";
import { Checkbox } from "../../Components/atoms";
import {
  InsuAcceptButton,
  InsuPrimaryButton,
  InsuRejectButton,
} from "../../Components/atoms/buttons/shared";
import {
  CustomPagination,
  Form,
  Option,
  Select,
  TextArea,
} from "../../Components/molecules";
import ProjectActions from "../../Redux/ProjectRedux";
import TeamActions from "../../Redux/TeamRedux";
import { getDisplayName, getObjUUID, getUserAvatar } from "../../utils/common";
import { nonRecruiting, recruiting } from "../../utils/constants";
import useProjectTeamScreen from "./hooks/useProjectTeamScreen";
import { IProjectTeamScreenProps } from "./types/IProjectTeamScreenProps";

const PageActiveSaveId = "ProjectTeamScreen_Active";
const PagePendingSaveId = "ProjectTeamScreen_Pending";

const ProjectTeamScreen: FC<IProjectTeamScreenProps> = function (props) {
  const {
    intl,
    state,
    setState,
    showModal,
    cancelRecruit,
    handleOk,
    handleCancel,
    handleChangeRecruitUser,
    setVisibleModalForInvite,
    isProjectOwner,
    deleteMember,
    toggleUserProjectOwnership,
    acceptToJoinProject,
    rejectAlreadyInvitedMemberToJoinProject,
    rejectToJoinProject,
    submitCreateProjectInvite,
    handleChange,
    fetchAcceptedTeam,
    fetchPendingTeam,
  } = useProjectTeamScreen(props);

  const _renderAddingMembersButton = () => {
    if (isProjectOwner()) {
      let bgColor = "#1F86E9";
      // @ts-expect-error TS18048
      if (props.project.type !== nonRecruiting) {
        bgColor = "black";
      }
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row-reverse",
          }}
        >
          <InsuPrimaryButton
            style={{
              color: "#1F86E9",
              border: "1px solid #1F86E9",
              marginLeft: "0.1vw",
              marginRight: "0.1vw",
            }}
            onClick={() => setVisibleModalForInvite(true)}
            type="default"
          >
            {/*
             // @ts-expect-error TS18048 */}
            {props.project.type !== recruiting
              ? intl.formatMessage({
                  id: "screen.label.to_recruit_members",
                })
              : intl.formatMessage({
                  id: "screen.label.stop_member_recruiting",
                })}
          </InsuPrimaryButton>
          <InsuPrimaryButton
            icon={<PlusOutlined />}
            style={{
              color: "#1F86E9",
              border: "1px solid #1F86E9",
              marginLeft: "0.1vw",
              marginRight: "0.1vw",
            }}
            onClick={showModal}
          >
            {intl.formatMessage({
              id: "screen.label.invite_members",
            })}
          </InsuPrimaryButton>
        </div>
      );
    }
  }; //
  const _renderUsers = () => {
    let _Arr = Array.isArray(props.allUsersForMention)
      ? props.allUsersForMention
      : [];
    if (Array.isArray(props.teams.teamPendingMembersPayload.rows)) {
      const _existing = props.teams.teamPendingMembersPayload.rows.map(
        // @ts-expect-error TS7006
        (o) => o.id,
      );
      // @ts-expect-error TS7034
      let _existingMember = [];
      if (Array.isArray(props.teams.teamMembersPayload.rows)) {
        // @ts-expect-error TS7006
        _existingMember = props.teams.teamMembersPayload.rows.map((o) => o.id);
      }
      if (Array.isArray(_Arr)) {
        _Arr = _Arr.filter((i) => _existing.indexOf(i.id) === -1);
        // @ts-expect-error TS7005
        _Arr = _Arr.filter((i) => _existingMember.indexOf(i.id) === -1);
      }
    }
    if (Array.isArray(_Arr)) {
      return _Arr
        .filter((user) => {
          if (user.deletedAt) {
            return false;
          } else if (user.stoppedAt) {
            return false;
          }
          return true;
        })
        .map((user) => {
          return <Option key={user.id}>{getDisplayName(user)}</Option>;
        });
    }
  }; //
  const _renderPendingTeamMembers = () => {
    if (isProjectOwner()) {
      const pendingTeamColumn = [
        {
          title: "ID",
          dataIndex: "id",
          key: "id",
        },
        {
          title: intl.formatMessage({
            id: "screen.events.full_name",
          }),
          dataIndex: "name",
          key: "name",
          // @ts-expect-error TS7006
          render: function _fn(text, row) {
            return (
              <HorizonalLayout
                style={{
                  alignItems: "center",
                }}
              >
                <div style={{ minWidth: 50 }}>{getUserAvatar(row)}</div>
                <div style={{ maxWidth: "20vw" }}>
                  <Link to={"/dashboard/member/" + getObjUUID(row)}>
                    {getDisplayName(row)}
                  </Link>
                </div>
              </HorizonalLayout>
            );
          },
        },
        {
          title: intl.formatMessage({
            id: "screens.title.email",
          }),
          dataIndex: "email",
          key: "email",
        },
        {
          title: "",
          dataIndex: "",
          key: "",
          width: "10%",
          // @ts-expect-error TS7006
          render: (text, row) => {
            if (row.projectOwnerStatus === 0) {
              return (
                <span
                  style={{
                    display: "float",
                  }}
                >
                  <InsuRejectButton
                    style={{
                      width: "64px",
                    }}
                    onClick={() => {
                      const func = () => {
                        rejectToJoinProject(
                          getObjUUID(row.project),
                          getObjUUID(row),
                        );
                      };
                      setState({
                        isVisibleMessageModalForUserAcceptanceOrRejection: true,
                        // @ts-expect-error TS2322
                        actionWhenSubmittingModal: func,
                      });
                    }}
                  >
                    {intl.formatMessage({
                      id: "screen.label.rejection",
                    })}
                  </InsuRejectButton>
                  <InsuAcceptButton
                    style={{
                      width: "64px",
                      marginLeft: "auto",
                    }}
                    onClick={() => {
                      const func = () => {
                        acceptToJoinProject(
                          getObjUUID(row.project),
                          getObjUUID(row),
                        );
                      };
                      setState({
                        isVisibleMessageModalForUserAcceptanceOrRejection: true,
                        // @ts-expect-error TS2322
                        actionWhenSubmittingModal: func,
                      });
                    }}
                  >
                    {intl.formatMessage({
                      id: "screen.label.approval",
                    })}
                  </InsuAcceptButton>
                </span>
              );
            } else if (row.projectOwnerStatus === 1 && row.userStatus === 0) {
              return (
                <span>
                  <InsuRejectButton
                    onClick={() => {
                      rejectAlreadyInvitedMemberToJoinProject(
                        getObjUUID(row.project),
                        getObjUUID(row),
                      );
                    }}
                  >
                    {intl.formatMessage({
                      id: "screen.label.cancel_invitation",
                    })}
                  </InsuRejectButton>
                </span>
              );
            }
          },
        },
      ];
      return (
        <>
          <h2>
            {intl.formatMessage(
              {
                id: "screen.label.waiting_approval",
              },
              {
                count: props.teams.teamPendingMembersPayload.count,
              },
            )}
          </h2>
          <Card>
            <Table
              pagination={false}
              rowKey="id"
              columns={pendingTeamColumn}
              dataSource={props.teams.teamPendingMembersPayload.rows}
              className="ant-table-x-scroll"
            />
            <br />
            <CustomPagination
              saveId={PagePendingSaveId}
              count={props.teams.teamPendingMembersPayload.count}
              onChangePage={(page, limit) => {
                setState(
                  {
                    pendingTeamLimit: limit,
                    pendingTeamPage: page,
                  },
                  () => {
                    fetchPendingTeam();
                  },
                );
              }}
            />
          </Card>
        </>
      );
    }
  }; //
  const columnsMembers = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: intl.formatMessage({
        id: "screen.events.full_name",
      }),
      dataIndex: "name",
      key: "name",
      // @ts-expect-error TS7006
      render: function _fn(text, row) {
        return (
          <HorizonalLayout
            style={{
              alignItems: "center",
            }}
          >
            <div style={{ minWidth: 50 }}>{getUserAvatar(row)}</div>
            <div style={{ maxWidth: "20vw" }}>
              <Link to={"/dashboard/member/" + getObjUUID(row)}>
                {getDisplayName(row)}
              </Link>
            </div>
          </HorizonalLayout>
        );
      },
    },
    {
      title: intl.formatMessage({
        id: "screens.title.email",
      }),
      dataIndex: "email",
      key: "email",
    },
    {
      title: "",
      dataIndex: "",
      key: "",
      // @ts-expect-error TS7006
      render: (row) => {
        if (row.project) {
          return row.isProjectOwner ? (
            <Tag
              style={{
                border: "1px solid #323232",
                borderRadius: "0px",
              }}
            >
              Owner
            </Tag>
          ) : null;
        }
      },
    },
    isProjectOwner()
      ? {
          title: "",
          dataIndex: "",
          key: "",
          // @ts-expect-error TS7006
          render: function _fn(row) {
            return (
              <Button
                type={"link"}
                onClick={() => {
                  toggleUserProjectOwnership(
                    getObjUUID(row.project),
                    getObjUUID(row),
                    !row.isProjectOwner,
                  );
                }}
              >
                {intl.formatMessage({
                  id: row.isProjectOwner
                    ? "screen.label.release_owner"
                    : "screen.label.set_to_owner",
                })}
              </Button>
            );
          },
        }
      : {},
    isProjectOwner()
      ? {
          title: "",
          dataIndex: "",
          key: "",
          // @ts-expect-error TS7006
          render: function _fn(row) {
            return (
              <Popconfirm
                title={intl.formatMessage({
                  id: "screen.label.delete_project_member",
                })}
                onConfirm={() => {
                  deleteMember(getObjUUID(row.project), getObjUUID(row));
                }}
                okText={intl.formatMessage({
                  id: "screen.label.yes",
                })}
                cancelText={intl.formatMessage({
                  id: "screen.label.no",
                })}
              >
                {IconDelete}
              </Popconfirm>
            );
          },
        }
      : {},
  ];
  let modalForInvite = (
    <Form layout={"vertical"}>
      <TextArea
        required
        onChange={(event) => handleChange("message", event.target.value)}
        value={state.message}
        rows={3}
        placeholder={intl.formatMessage({
          id: "screens.message.recruitment",
        })}
        title={intl.formatMessage({
          id: "screens.title.recruitment.message",
        })}
      />
      <Form.Item>
        <Checkbox
          onChange={(e) => {
            handleChange("sendEmail", e.target.checked);
          }}
          checked={state.sendEmail}
        >
          {intl.formatMessage({
            id: "screen.label.email_notification",
          })}
        </Checkbox>
      </Form.Item>
    </Form>
  );
  // @ts-expect-error TS18048
  if (props.project.type === recruiting) {
    modalForInvite = <></>;
  }
  return (
    <>
      <Modal
        cancelText={intl.formatMessage({
          id: "screen.label.cancel",
        })}
        okText={intl.formatMessage({
          id: "screen.label.transmission",
        })}
        title={intl.formatMessage({
          id: "screen.label.send_message",
        })}
        visible={state.isVisibleMessageModalForUserAcceptanceOrRejection}
        onOk={() => {
          if (state.messageToUserAfterRejectingOrAcceptance.length > 0) {
            state.actionWhenSubmittingModal();
          }
        }}
        onCancel={() => {
          setState({
            isVisibleMessageModalForUserAcceptanceOrRejection: false,
            actionWhenSubmittingModal: null,
          });
        }}
      >
        <Form>
          <Form.Item>
            <TextArea
              value={state.messageToUserAfterRejectingOrAcceptance}
              onChange={(event) => {
                setState({
                  // @ts-expect-error TS2345
                  messageToUserAfterRejectingOrAcceptance: event.target.value,
                });
              }}
            />
          </Form.Item>
        </Form>
      </Modal>

      <Row>
        <Col xs={24} sm={8} md={8} lg={6}>
          <h2>
            {intl.formatMessage(
              {
                id: "screen.label.team_members",
              },
              {
                count: props.teams.teamMembersPayload.count,
              },
            )}
          </h2>
        </Col>
        <Col xs={24} sm={24 - 8} md={24 - 8} lg={24 - 6}>
          {_renderAddingMembersButton()}
        </Col>
      </Row>

      <Card>
        <Table
          pagination={false}
          rowKey="id"
          columns={columnsMembers}
          dataSource={props.teams.teamMembersPayload.rows.filter(
            // @ts-expect-error TS7006
            (a) => !a.isDeleted,
          )}
          className="ant-table-x-scroll"
        />
        <br />
        <CustomPagination
          saveId={PageActiveSaveId}
          count={props.teams.teamMembersPayload.count}
          onChangePage={(page, limit) => {
            setState(
              {
                acceptedTeamLimit: limit,
                acceptedTeamPage: page,
              },
              () => {
                fetchAcceptedTeam();
              },
            );
          }}
        />
      </Card>

      <br />
      <br />
      <Line />
      <br />
      {_renderPendingTeamMembers()}
      <Modal
        width={"40vw"}
        bodyStyle={{
          paddingLeft: "7vw",
          paddingRight: "7vw",
        }}
        destroyOnClose
        title={intl.formatMessage({
          id: "screen.label.invite_members",
        })}
        visible={state.visible}
        onOk={handleOk}
        okText={intl.formatMessage({
          id: "screen.label.invite",
        })}
        cancelText={intl.formatMessage({
          id: "screen.label.cancel",
        })}
        onCancel={handleCancel}
        footer={null}
      >
        <Form layout={"vertical"}>
          <Form.Item
            label={intl.formatMessage({
              id: "screen.project.team.members_to_invite",
            })}
          >
            <Select
              // @ts-expect-error TS7006
              height={"100%"}
              showSearch
              allowClear
              mode="multiple"
              size="large"
              placeholder={intl.formatMessage({
                id: "screen.label.please_enter",
              })}
              value={state.selectHolder}
              onChange={handleChangeRecruitUser}
              optionFilterProp="children"
              filterOption={(input, option) =>
                // @ts-expect-error TS18048
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              style={{ width: "100%" }}
            >
              {_renderUsers()}
            </Select>
          </Form.Item>
          <Form.Item>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <InsuRejectButton
                onClick={handleCancel}
                style={{
                  marginLeft: "0.1vw",
                  marginRight: "0.1vw",
                }}
              >
                {intl.formatMessage({
                  id: "screen.label.cancel",
                })}
              </InsuRejectButton>
              <InsuAcceptButton
                onClick={handleOk}
                style={{
                  marginLeft: "0.1vw",
                  marginRight: "0.1vw",
                }}
              >
                OK
              </InsuAcceptButton>
            </div>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        width={"42vw"}
        bodyStyle={{
          paddingLeft: "10vw",
          paddingRight: "10vw",
        }}
        destroyOnClose
        title={
          // @ts-expect-error TS18048
          props.project.type === recruiting
            ? intl.formatMessage({
                id: "screen.label.stop_member_recruiting",
              })
            : intl.formatMessage({
                id: "screen.label.to_recruit_members",
              })
        }
        visible={state.visibleModalForInvite}
        onOk={
          // @ts-expect-error TS18048
          props.project.type === recruiting
            ? cancelRecruit
            : submitCreateProjectInvite
        }
        okText={
          // @ts-expect-error TS18048
          props.project.type === recruiting
            ? intl.formatMessage({
                id: "screen.label.stop_member_recruiting",
              }) // Stop recruit
            : intl.formatMessage({
                id: "screen.label.to_recruit_members",
              }) // Recruit members
        }
        cancelText={intl.formatMessage({
          id: "screen.label.cancel",
        })}
        onCancel={() => setVisibleModalForInvite(false)}
        footer={null}
      >
        {modalForInvite}
        <Form.Item>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <InsuRejectButton
              onClick={() => setVisibleModalForInvite(false)}
              style={{
                marginLeft: "0.1vw",
                marginRight: "0.1vw",
              }}
            >
              {intl.formatMessage({
                id: "screen.label.cancel",
              })}
            </InsuRejectButton>
            <InsuAcceptButton
              onClick={
                // @ts-expect-error TS18048
                props.project.type === recruiting
                  ? cancelRecruit
                  : submitCreateProjectInvite
              }
              style={{
                marginLeft: "0.1vw",
                marginRight: "0.1vw",
              }}
            >
              {
                // @ts-expect-error TS18048
                props.project.type === recruiting
                  ? intl.formatMessage({
                      id: "screen.label.stop_member_recruiting",
                    }) // Stop recruit
                  : intl.formatMessage({
                      id: "screen.label.to_recruit_members",
                    }) // Recruit members
              }
            </InsuAcceptButton>
          </div>
        </Form.Item>
      </Modal>
    </>
  );
};

// @ts-expect-error TS7006
const mapStateToProps = (state) => ({
  teams: state.teams,
  users: state.user,
  project: state.projects.projectPayload,
  allUsersForMention: state.user.allUsersForMentionPayload,
});
// @ts-expect-error TS7006
const mapDispatchToProps = (dispatch) => ({
  // @ts-expect-error TS7006
  dispatchFetchUsers: (data) => dispatch(TeamActions.teamGetRequest(data)),
  // @ts-expect-error TS7006
  dispatchFetchPendingUsers: (data) =>
    // @ts-expect-error TS2554
    dispatch(TeamActions.teamPendingGetSuccess(data)),
  // @ts-expect-error TS7006
  dispatchAddTeamMembers: (data, message) =>
    // @ts-expect-error TS2554
    dispatch(TeamActions.teamCreateRequest(data, message)),
  // @ts-expect-error TS7006
  dispatchGetProjectDetail: (id) =>
    // @ts-expect-error TS2554
    dispatch(ProjectActions.projectGetRequest(id)),
});

ProjectTeamScreen.propTypes = {
  // @ts-expect-error TS2322
  context: PropTypes.object,
  teams: PropTypes.object,
  match: PropTypes.object,
  users: PropTypes.object,
  project: PropTypes.object,
  allUsersForMention: PropTypes.array,

  // @ts-expect-error TS2322
  dispatchFetchUsers: PropTypes.func,
  // @ts-expect-error TS2322
  dispatchFetchPendingUsers: PropTypes.func,
  // @ts-expect-error TS2322
  dispatchAddTeamMembers: PropTypes.func,
  dispatchGetProjectDetail: PropTypes.func,
};
export default connect(mapStateToProps, mapDispatchToProps)(ProjectTeamScreen);
