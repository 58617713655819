import { useIntl } from "react-intl";

import { ConfirmModal } from "../../../../shared/modals/compoments/ConfirmModal";
import { ToggleIsExaminationPhaseModalProps } from "./type";

export const ToggleIsExaminationPhaseModal = ({
  isVisible,
  onExecute,
  onCancel,
}: ToggleIsExaminationPhaseModalProps) => {
  const intl = useIntl();

  const title = intl.formatMessage({
    id: "screen.label.turn_off_is_examination_phase_enabled_confirm_modal_title",
  });
  const content = intl.formatMessage({
    id: "screen.label.turn_off_is_examination_phase_enabled_confirm_modal_content",
  });

  const executionButtonText = intl.formatMessage({
    id: "screen.label.execute_turn_off_is_examination_phase_enabled",
  });
  const cancelButtonText = intl.formatMessage({ id: "screen.label.cancel" });
  const confirmText = intl.formatMessage({
    id: "screen.label.confirmed",
  });

  return (
    <ConfirmModal
      onCancel={onCancel}
      onExecute={onExecute}
      isVisible={isVisible}
      title={title}
      content={content}
      confirmText={confirmText}
      cancelButtonText={cancelButtonText}
      executionButtonText={executionButtonText}
    />
  );
};
