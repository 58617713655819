import { Layout } from "antd";
const { Content } = Layout;
import styled from "styled-components";

export const CompanyContent = styled(Content)`
  padding: 3% 3% 0 3%;
`;

export const CompanyLayout = styled(Layout)`
  background-color: #f5f5f5;
  min-height: 100vh;
`;
