import { Button } from "antd";
import { useContext } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import styled from "styled-components";
// @ts-expect-error TS2307
import { ExaminationDetail } from "types/ExaminationDetail";

import { ContentContext } from "../../../Provider";
import { HorizonalLayout, VerticalLayout } from "../../atoms";
import { CheckPointCoverageRate } from "../../organisms/project/CheckPointCoverageRate";

const Paragraph = styled.p`
  color: #323232;
  font-style: normal;
  word-wrap: break-word;
  white-space: break-spaces;
`;

const PreviewCurrentSubmissionContainer = styled.div`
  padding: 50px;
  background-color: #fafafa;
`;

type PreviewCurrentSubmissionProps = {
  examinationDetail: ExaminationDetail;
  saveFile: (...args: any[]) => any;
};

export const PreviewCurrentSubmission = (
  props: PreviewCurrentSubmissionProps,
) => {
  const { examinationDetail, saveFile } = props;
  const { projectArchive } = examinationDetail;
  const context = useContext(ContentContext);

  const { company } = context;
  const _renderParsedMultiplefiles = (parsedFileList: Array<any>) => {
    if (Array.isArray(parsedFileList)) {
      return parsedFileList.map((singlefile) => {
        const uploadUrl = singlefile.uploadUrl;
        const friendlyFileName = singlefile.friendlyFileName;

        return (
          <div key={singlefile.uploadUrl}>
            <Button
              style={{
                wordBreak: "break-all",
                overflowWrap: "break-word",
                whiteSpace: "initial",
              }}
              onClick={() => {
                const urlToDisplay = uploadUrl;
                const href =
                  urlToDisplay && urlToDisplay.length > 0
                    ? urlToDisplay.indexOf("http") === -1
                      ? window.location.protocol + "//" + urlToDisplay
                      : urlToDisplay
                    : "";
                saveFile(href, friendlyFileName, "", singlefile.uploadedFileId);
              }}
              target="_blank"
              type="link"
            >
              <span style={{ color: "black" }}></span> {friendlyFileName}
            </Button>
          </div>
        );
      });
    }
  };
  return (
    <PreviewCurrentSubmissionContainer>
      <VerticalLayout>
        <Paragraph
          style={{
            fontSize: "0.8rem",
            fontWeight: "normal",
          }}
        >
          {examinationDetail.categoryName}
        </Paragraph>

        <Paragraph
          style={{
            fontSize: "1.1rem",
            fontWeight: "bold",
          }}
        >
          {projectArchive.title}
        </Paragraph>

        <Paragraph
          style={{
            fontSize: "0.9rem",
            fontWeight: "bold",
          }}
        >
          <Link
            to={`/dashboard/project.archive/${examinationDetail.projectUuid}/${projectArchive.id}`}
          >
            {projectArchive.projectName}
          </Link>
        </Paragraph>

        <HorizonalLayout>
          {projectArchive.parsedFileList &&
            _renderParsedMultiplefiles(projectArchive.parsedFileList)}
          {projectArchive.fileUrl && projectArchive.fileUrl.length > 0 ? (
            <Button
              style={{
                wordBreak: "break-all",
                overflowWrap: "break-word",
                whiteSpace: "initial",
              }}
              href={projectArchive.fileUrl}
              target="_blank"
              type="link"
            >
              <span style={{ color: "black" }}></span>{" "}
              {projectArchive.fileUrlName}
            </Button>
          ) : null}
          <>
            {projectArchive.uploadUrl && projectArchive.uploadUrl.length > 0 ? (
              <Button
                style={{
                  wordBreak: "break-all",
                  overflowWrap: "break-word",
                  whiteSpace: "initial",
                }}
                onClick={() => {
                  const urlToDisplay = projectArchive.uploadUrl;

                  const href =
                    urlToDisplay && urlToDisplay.length > 0
                      ? urlToDisplay.indexOf("http") === -1
                        ? window.location.protocol + "//" + urlToDisplay
                        : urlToDisplay
                      : "";
                  saveFile(
                    href,
                    projectArchive.friendlyFileName
                      ? projectArchive.friendlyFileName
                      : "download file",
                  );
                }}
                target="_blank"
                type="link"
              >
                <span style={{ color: "black" }} />{" "}
                {projectArchive.friendlyFileName
                  ? projectArchive.friendlyFileName
                  : "download file"}
              </Button>
            ) : null}
          </>
        </HorizonalLayout>
        {company.isAllowCheckpoint &&
        examinationDetail.batchDisplayCheckpoint ? (
          <HorizonalLayout>
            <FormattedMessage
              id={"screen.label.project_list.checkpoint_coverage_rate"}
            />
            <CheckPointCoverageRate
              projectCheckpoints={projectArchive.projectCheckpoints}
            />
          </HorizonalLayout>
        ) : null}
      </VerticalLayout>
    </PreviewCurrentSubmissionContainer>
  );
};
