import "./CustomLoaderStyles.css";

import Loader from "react-loader-spinner";
const CustomLoader = () => {
  return (
    <div className="loader-container">
      <Loader type={"TailSpin"} color={"#6f49f5"} width={70} height={70} />
    </div>
  );
};
export default CustomLoader;
