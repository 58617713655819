import imageCompression from "browser-image-compression";
import React from "react";
import { useIntl } from "react-intl";

import { getProjectAvatarPlaceURL } from "../../../utils/common";
import { b64toBlob } from "../../../utils/constants";

// @ts-expect-error TS7006
const useProjectImageEdit = (props) => {
  const intl = useIntl();
  const { project, url, onFileChanged } = props;
  const [showModal, setShowModal] = React.useState(false);
  const [imgPreview, setImgPreview] = React.useState(null);
  const imageUrl = React.useMemo(() => {
    let imageUrl = "";
    if (imgPreview) {
      return imgPreview;
    }
    if (project && project.id) {
      if (url && url !== "") {
        imageUrl = url;
        if (url.indexOf("http") === -1) {
          imageUrl = `https://${url}`;
        }
      } else {
        imageUrl = getProjectAvatarPlaceURL(project.id);
      }
    }
    return imageUrl;
  }, [project, url, imgPreview]);
  const onProfileImageEditClose = () => {
    setImgPreview(null);
  };
  // @ts-expect-error TS7006
  const onProfileImageEditCrop = (preview) => {
    setImgPreview(preview);
  };
  // @ts-expect-error TS7006
  const onBeforeFileLoad = (elem) => {};
  const onChangeFile = async () => {
    // @ts-expect-error TS18047
    const block = imgPreview.split(";");
    const contentType = block[0].split(":")[1];
    const realData = block[1].split(",")[1];
    const blob = b64toBlob(realData, contentType);
    const options = {
      maxSizeMB: 10,
      maxWidthOrHeight: 1200,
      useWebWorker: false,
      // @ts-expect-error TS7006
      onProgress: (p) => {
        console.log("image compress progress", p);
      },
    };
    // @ts-expect-error TS2345
    const compressedFile = await imageCompression(blob, options);
    onFileChanged(compressedFile);
  };

  return {
    intl,
    project,
    showModal,
    imageUrl,
    onProfileImageEditClose,
    onProfileImageEditCrop,
    onBeforeFileLoad,
    onChangeFile,
    setShowModal,
  };
};

export default useProjectImageEdit;
