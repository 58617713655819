import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { connectRouter } from "connected-react-router";
import { routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import { persistReducer, persistStore } from "redux-persist";
import defaultStorage from "redux-persist/lib/storage";
import createSagaMiddleware from "redux-saga";
import { resettableReducer } from "reduxsauce";

import { reducer as archiveReducer } from "@/Redux/ArchiveRedux";
import { reducer as authReducer } from "@/Redux/AuthRedux";
import { reducer as batchExaminationReducer } from "@/Redux/BatchExaminationRedux";
import { reducer as batchReducer } from "@/Redux/BatchRedux";
import { reducer as commentReducer } from "@/Redux/CommentRedux";
import { reducer as companyReducer } from "@/Redux/CompanyRedux";
import { reducer as eventReducer } from "@/Redux/EventRedux";
import { reducer as fileReducer } from "@/Redux/FilesRedux";
import { reducer as homeReducer } from "@/Redux/HomeRedux";
import { reducer as industryMainCategoryReducer } from "@/Redux/IndustryMainCategoryRedux";
import { reducer as industryReducer } from "@/Redux/IndustryRedux";
import { reducer as industrySubCategoryReducer } from "@/Redux/IndustrySubCategoryRedux";
import { reducer as newsCommentReducer } from "@/Redux/NewsCommentRedux";
import { reducer as newsReducer } from "@/Redux/NewsRedux";
import { reducer as programInfoReducer } from "@/Redux/ProgramInfoRedux";
import { reducer as projectReducer } from "@/Redux/ProjectRedux";
import { reducer as teamReducer } from "@/Redux/TeamRedux";
import { reducer as threadReducer } from "@/Redux/ThreadRedux";
import { reducer as userReducer } from "@/Redux/UserRedux";
import { reducer as videoReducer } from "@/Redux/VideoRedux";

import rootSaga from "../Saga/index";
import immutablePersistenceTransform from "../Services/ImmutablePersistenceTransform";

const resettable = resettableReducer("LOGOUT");

export const history = createBrowserHistory();

const persistConfig = {
  key: "root",
  transforms: [immutablePersistenceTransform],
  blacklist: [
    "router",
    "events",
    "comments",
    // 'teams',
    // "user",
    "archives",
    "files",
    "dashboard",
    // "company",
    "videos",
  ],
};

const rootReducer = combineReducers({
  auth: resettable(authReducer),
  router: connectRouter(history),
  user: resettable(userReducer),
  projects: resettable(projectReducer),
  events: eventReducer,
  comments: commentReducer,
  teams: teamReducer,
  files: fileReducer,
  archives: archiveReducer,
  dashboard: homeReducer,
  company: companyReducer,
  videos: videoReducer,
  programInfo: programInfoReducer,
  threads: threadReducer,
  news: newsReducer,
  newsComments: newsCommentReducer,
  industryReports: industryReducer,
  industryMainCategories: industryMainCategoryReducer,
  industrySubCategories: industrySubCategoryReducer,
  batch: batchReducer,
  batchExamination: batchExaminationReducer,
});

type setupStoreConfig = {
  preloadedState?: Partial<RootState>;
  /** redux-persist で使うストレージ。デフォルトでは localStorage */
  storage?: typeof defaultStorage;
};

export const setupStore = ({
  preloadedState,
  storage = defaultStorage,
}: setupStoreConfig = {}) => {
  const sagaMiddleware = createSagaMiddleware();
  const middleware = [sagaMiddleware, routerMiddleware(history)];

  const persistedReducer = persistReducer(
    {
      ...persistConfig,
      storage,
    },
    rootReducer,
  );

  const store = configureStore({
    reducer: persistedReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        /**
         * @see https://stackoverflow.com/questions/61704805/getting-an-error-a-non-serializable-value-was-detected-in-the-state-when-using
         */
        serializableCheck: false,
        thunk: false,
      }).concat(middleware),
  });

  sagaMiddleware.run(rootSaga);

  const persistor = persistStore(store);

  return { store, persistor };
};

export type RootState = ReturnType<typeof rootReducer>;
