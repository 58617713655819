import { Button } from "antd";
import styled from "styled-components";

export const ProjectOwners = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 30vw;
`;
export const RenderMilestoneComponent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const CloseOutlineComponent = styled.div`
  padding-left: 16px;
  padding-right: 8px;
  padding-top: 2px;
  padding-bottom: 2px;
`;
export const CountUnreadComments = styled.div`
  padding-top: 4px;
  padding-bottom: 4px;
`;
export const MileStoneLocalDate = styled.span`
  margin-left: 10px;
  margin-right: 16px;
  font-weight: bold;
`;
export const MileStoneComponent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 16px;
`;
export const LocalDateComponent = styled.span`
  margin-left: 10px;
  margin-right: 16px;
  font-weight: bold;
`;
export const ProjectTopAcceptButton = styled(Button)`
  margin: 10px 10px 10px 4vw;
  background-color: #1f86e9;
  color: #fff;
`;
export const ProjectTopGhostButton = styled(Button)`
  margin: 10px;
  background-color: #b5b5b5;
  color: #fff;
`;
