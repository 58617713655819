/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC } from "react";
import { useIntl } from "react-intl";

import { DraftEditor as Editor, Form, Input } from "@/Components/molecules";

export type ReplyEditorProps = {
  suggestions: any;
  active: boolean;
  toolbar?: any;
  editorState?: any;
  onEditorStateChange: (state: any) => void;
  onPostReply: () => void;
  onCancelReply: () => void;
  onFocusEditor: () => void;
};

export const ReplyEditor: FC<ReplyEditorProps> = ({
  active,
  suggestions,
  toolbar,
  editorState,
  onFocusEditor,
  onEditorStateChange,
  onPostReply,
  onCancelReply,
}) => {
  const intl = useIntl();

  if (!active) {
    return (
      <div>
        <Form>
          <Form.Item>
            <Input
              placeholder={intl.formatMessage({
                id: "screen.label.please_enter",
              })}
              bordered={true}
              onFocus={onFocusEditor}
              className="comment-input-box"
              style={{
                width: "100%",
              }}
            />
          </Form.Item>
        </Form>
      </div>
    );
  }

  return (
    <div>
      <Editor
        onPostContent={onPostReply}
        onCancelContent={onCancelReply}
        toolbarHidden={true}
        // @ts-expect-error TS2322
        toolbarShowBottom={true}
        toolbar={toolbar}
        editorClassName="editor-class-comment"
        wrapperClassName="wrapper-class"
        toolbarClassName="toolbar-class"
        placeholder={intl.formatMessage({
          id: "screen.label.please_enter",
        })}
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        localization={{
          locale: "ja",
        }}
        mention={{
          separator: " ",
          trigger: "@",
          suggestions,
        }}
      />
    </div>
  );
};
