import { Avatar } from "antd";
import { notification } from "antd";
import DOMPurify from "dompurify";
import {
  ContentState,
  convertFromRaw,
  convertToRaw,
  EditorState,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import FileSaver from "file-saver";
import FileType from "file-type/browser";
import htmlToDraft from "html-to-draftjs";

const R = require("ramda");
const { htmlToText } = require("html-to-text");

DOMPurify.addHook("uponSanitizeElement", (node, data) => {
  if (data.tagName === "iframe") {
    const src = node.getAttribute("src") || "";
    if (
      !(
        src.startsWith("https://www.youtube.com/") ||
        src.indexOf("vimeo.com") > -1
      )
    ) {
      return node.parentNode.removeChild(node);
    }
  }
});

export const convertYVLinksToEmbed = async (
  token,
  company,
  embeddedLink,
  height,
  width,
) => {
  if (embeddedLink.indexOf("vimeo") >= 0) {
    let resp = await fetch(
      `${process.env.REACT_APP_BASEHOST}companies/${company}/videos/access`,
      {
        method: "POST",
        headers: new Headers({
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          alphafrontEndPath: window.location.href,
          "Content-Type": "application/json",
        }),
        body: JSON.stringify({
          videoUrl: embeddedLink,
        }),
      },
    );
    const content = await resp.json();
    if (content.status && content.status === "fail") {
      embeddedLink = "";
      notification.error({
        message: content.data,
      });
    }
  }
  return embeddedLink;
};

export const defaultConentOnProgramInfoRIchTextField = (ohtml) => {
  const html =
    ohtml ||
    `<div>
    <h2><FormattedMessage id="screen.description.business_system"></h2>
    <h2><FormattedMessage id="screen.label.faq"></h2>
</div>`;

  try {
    const contentBlock = htmlToDraft(html);

    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks,
      );
      return EditorState.createWithContent(contentState);
    }
    return EditorState.createEmpty();
  } catch (e) {
    return EditorState.createEmpty();
  }
};
export const sanitizeHtmlString = (dirtyHtml) => {
  if (dirtyHtml && typeof dirtyHtml === "string") {
    return DOMPurify.sanitize(dirtyHtml, {
      ADD_TAGS: ["iframe"],
      ADD_ATTR: [
        "allow",
        "allowfullscreen",
        "frameborder",
        "scrolling",
        "target",
      ],
    });
  }
  throw Error("Please provide html");
};
export const sanitizeContentStateMarkupHtml = (contentState) => {
  try {
    const markup = draftToHtml(convertToRaw(contentState));
    const html = sanitizeHtmlString(markup);
    const contentBlock = htmlToDraft(html);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks,
      );
      const newEditorState = EditorState.createWithContent(contentState);
      return newEditorState;
    }
    return EditorState.createEmpty();
  } catch (e) {
    return EditorState.createEmpty();
  }
};

export const sanitizeMarkupHtml = (editorState) => {
  try {
    const markup = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    return markup.replace(/<img[^>]*>/g, "");
  } catch (e) {
    return null;
  }
};

export const sanitizeMarkupString = (data) => {
  const record = JSON.parse(data);

  if (record && Array.isArray(record.blocks)) {
    return record.blocks
      .map((block) => (!block.text.trim() && "\n") || block.text)
      .join("\n");
  }
  return "";
};

export const getHypothesisFromEditorState = (content) => {
  if (isJsonString(content)) {
    return sanitizeMarkupString(content);
  }
  return content;
};

export const getContentFromString = (content) => {
  if (content && content !== "") {
    return isJsonString(content)
      ? sanitizeEditorStateMarkupHtml(
          EditorState.createWithContent(convertFromRaw(JSON.parse(content))),
        )
      : EditorState.createWithContent(ContentState.createFromText(content));
  } else {
    return EditorState.createEmpty();
  }
};

export const getRawBodyFromEditorState = (editState) => {
  const rawNodes = convertToRaw(editState.getCurrentContent());
  const content = JSON.stringify(rawNodes);
  return content;
};

export const sanitizeEditorStateMarkupHtml = (editorState) => {
  try {
    const markup = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    const html = DOMPurify.sanitize(markup, {
      ADD_TAGS: ["iframe"],
      ADD_ATTR: [
        "allow",
        "allowfullscreen",
        "frameborder",
        "scrolling",
        "target",
      ],
    });

    const contentBlock = htmlToDraft(html);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks,
      );
      const newEditorState = EditorState.createWithContent(contentState);

      return newEditorState;
    }
    return EditorState.createEmpty();
  } catch (e) {
    return EditorState.createEmpty();
  }
};

export const isEmailValid = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const getMimeType = (extension) => {
  switch (extension) {
    case "png":
      return "image/png";
    case "jpg":
    case "jpeg":
      return "image/jpeg";
    case "txt":
      return "text/plain";
    case "xlsx":
      return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    case "xls":
      return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    case "doc":
    case "docx":
      return "application/msword";
    case "pdf":
      return "application/pdf";
    case "mp4":
      return "video/mp4";
    default:
      return "image/jpeg";
  }
};
export const saveFile = (href, fileName) => {
  const xhr = new XMLHttpRequest();
  xhr.withCredentials = true;
  xhr.open("GET", href, true);
  xhr.responseType = "arraybuffer";
  xhr.onload = function (e) {
    const arrayBufferView = new Uint8Array(e.currentTarget.response);
    const blob = new Blob([arrayBufferView]);
    FileSaver.saveAs(blob, fileName);
  };
  xhr.send();
};

export const getUserName = (user) => {
  if (user) {
    if (user.name !== "" && user.name !== null && user.name.length > 0) {
      return user.name;
    } else if (user.Credential && user.Credential.email !== "") {
      return user.Credential.email.split("@")[0];
    } else {
      return user.id;
    }
  }
  return "";
};

export const normalizeUrl = (url) => {
  if (url.indexOf("http") === -1) return `https://${url}`;
  return url;
};

export const getProjectAvatar = (
  project,
  onClick = () => {},
  size = "large",
) => {
  const images = [
    "/icons/symbol-blue.png",
    "/icons/symbol-gray.png",
    "/icons/symbol-green.png",
    "/icons/symbol-light.png",
    "/icons/symbol-orange.png",
    "/icons/symbol-pink.png",
    "/icons/symbol-purple.png",
    "/icons/symbol-yellow.png",
  ];

  const colorIndex = project.id % images.length;

  let avatar = (
    <Avatar
      style={{
        marginRight: 10,
        verticalAlign: "middle",
      }}
      size={size}
      src={images[colorIndex]}
      onClick={onClick}
    />
  );
  if (project && project.icon && project.icon.length > 0) {
    avatar = (
      <Avatar
        style={{
          backgroundColor: "#E8E8E8",
          marginRight: 10,
          verticalAlign: "middle",
        }}
        size={size}
        src={window.location.protocol + "//" + project.icon}
        onClick={onClick}
      />
    );
  }
  return avatar;
};

export const getProjectAvatarPlaceURL = (id) => {
  const images = [
    "/icons/symbol-blue.png",
    "/icons/symbol-gray.png",
    "/icons/symbol-green.png",
    "/icons/symbol-light.png",
    "/icons/symbol-orange.png",
    "/icons/symbol-pink.png",
    "/icons/symbol-purple.png",
    "/icons/symbol-yellow.png",
  ];

  const colorIndex = id % images.length;
  return images[colorIndex];
};

export const getUserAvatar = (userObj, onClick = () => {}, style = {}) => {
  const images = [
    "/icons/user-blue.png",
    "/icons/user-gray.png",
    "/icons/user-green.png",
    "/icons/user-light.png",
    "/icons/user-orange.png",
    "/icons/user-pink.png",
    "/icons/user-purple.png",
    "/icons/user-yellow.png",
  ];
  const colorIndex = userObj.id % images.length;
  let avatar = (
    <Avatar
      src={images[colorIndex]}
      style={{
        marginRight: 10,
        verticalAlign: "middle",
        ...style,
      }}
      size="large"
      onClick={onClick}
    />
  );
  if (userObj && userObj.profileImage && userObj.profileImage.length > 0) {
    let profileImage = userObj.profileImage;
    if (profileImage.indexOf("http") === -1)
      profileImage = `https://${profileImage}`;
    avatar = (
      <Avatar
        src={profileImage}
        style={{
          backgroundColor: "#E8E8E8",
          marginRight: 10,
          verticalAlign: "middle",
          ...style,
        }}
        size="large"
        onClick={onClick}
      />
    );
  }
  return avatar;
};

export const getDisplayName = (userObj) => {
  const username =
    userObj.name !== "" && userObj.name !== null
      ? userObj.name
      : userObj.email
      ? userObj.email.split("@")[0]
      : userObj.id;
  return username;
};

export const isUrl = (url) => {
  if (typeof url !== "string") {
    return false;
  }
  const expression =
    /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi;
  const regex = new RegExp(expression);

  return !!url.match(regex);
};

export const isJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const appendScript = (scriptToAppend) => {
  const script = document.createElement("script");
  script.src = scriptToAppend;
  script.async = true;
  document.body.appendChild(script);
};

export const validUUIDV4 = (uuid) => {
  if (typeof uuid !== "string") {
    return false;
  }
  const expression =
    /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i;
  const regex = new RegExp(expression);
  return !!uuid.match(regex);
};

export const getObjUUID = (obj) => {
  if (obj && Object.hasOwn(obj, "uuid")) {
    return obj.uuid;
  }
  if (obj && Object.hasOwn(obj, "id")) {
    return obj.id;
  }
  return "";
};

export const isEmptyString = (str) => {
  if (!str) {
    return true;
  }
  if (str == "") {
    return true;
  }
  if (`${str}`.toLowerCase() === "null") {
    return true;
  }
  return `${str}`.toLowerCase() === "undefined";
};

export const getTruncatedText = (text, len = 20) => {
  if (text && text !== "") {
    return text.length > len ? `${text.slice(0, len)}...` : text;
  }
  return "";
};
export const isEnabledWatch = (project) => {
  if (project.isWatch == null || project.isWatch == undefined) {
    return true;
  }
  return project.isWatch;
};
export const stringToSlug = (str) => {
  return str;
};

export const doesPageLieWithinResultRange = (totalCount, selectedPage) => {
  return (
    Math.floor(totalCount / process.env.REACT_APP_PAGE_SIZE) >= selectedPage
  );
};

/**
 * @param {string} htmlStr
 * @returns {string}
 */
export const getPureStringFromHtml = (htmlStr) => {
  const text = htmlToText(htmlStr, {
    tags: { a: { options: { ignoreHref: true } } },
  });
  return text;
};

export const removeLineBreaks = (str) => {
  return str.replace(/[\r\n]+$/, "");
};

/**
 * @typedef {Object} RawBlockType
 * @property {Object} data
 * @property {number} depth
 * @property {Array<Object>} entityRanges
 * @property {Array<Object>} inlineStyleRanges
 * @property {string} key
 * @property {string} text
 * @property {string} type
 */

/**
 * @param {EditorState} editorState
 * @param {boolean} isContent
 */
export const convertToRawWithTrimmer = (editorState, isContent = true) => {
  let rawNodes = convertToRaw(editorState.getCurrentContent());
  const blocks = removeLineBreaksOfDraftEditor(rawNodes.blocks);
  rawNodes.blocks = blocks;
  if (isContent) {
    return JSON.stringify(rawNodes);
  }
  return rawNodes;
};

/**
 * @param {RawBlockType} rawNode
 * @param {string} type
 */
const checkRawNodeLineBreak = (rawNode, type) => {
  if (
    rawNode &&
    (rawNode.text === "" || rawNode.text === " ") &&
    rawNode.type === type &&
    R.isEmpty(rawNode.data) &&
    rawNode.entityRanges.length === 0 &&
    rawNode.inlineStyleRanges.length === 0
  ) {
    return true;
  }
  return false;
};

/**
 * @param {Array<RawBlockType>} blocks
 */
export const removeLineBreaksOfDraftEditor = (blocks) => {
  if (blocks && blocks.length > 0) {
    let cloneBlocks = [...blocks];
    cloneBlocks = recoverLineBreaks(cloneBlocks);
    cloneBlocks = cloneBlocks.reverse();
    let removeBlockIndexs = [];
    let index = 0;
    const type = "unstyled";
    while (index < cloneBlocks.length) {
      if (
        checkRawNodeLineBreak(cloneBlocks[index], type) &&
        checkRawNodeLineBreak(cloneBlocks[index + 1], type)
      ) {
        if (index === 0) {
          removeBlockIndexs.push(cloneBlocks[index].key);
        }
        removeBlockIndexs.push(cloneBlocks[index + 1].key);
      } else {
        break;
      }
      index++;
    }
    if (removeBlockIndexs.length > 0) {
      cloneBlocks = cloneBlocks.filter(
        (block) => !removeBlockIndexs.includes(block.key),
      );
    }
    return cloneBlocks.reverse();
  }
  return blocks;
};

export const convertFromRawWithTrimmer = (content) => {
  let jsonContent = JSON.parse(content);
  if (jsonContent && jsonContent.blocks && jsonContent.blocks.length > 0) {
    const blocks = removeLineBreaksOfDraftEditor(jsonContent.blocks);
    jsonContent.blocks = blocks;
  }
  const rawNodes = convertFromRaw(jsonContent);
  return rawNodes;
};

export const replaceUnStyledBlock = (rawNodes) => {
  let cloneRowNodes = { ...rawNodes };
  if (cloneRowNodes.blocks && cloneRowNodes.blocks.length > 0) {
    const blocks = replaceLineBreaks(cloneRowNodes.blocks);
    cloneRowNodes.blocks = blocks;
  }
  return cloneRowNodes;
};

/**
 * @param {EditorState} editorState
 */
export const adjustLineSpacing = (editorState) => {
  let rawNodes = convertToRaw(editorState.getCurrentContent());
  const content = convertFromRaw(replaceUnStyledBlock(rawNodes));
  const newEditorState = sanitizeEditorStateMarkupHtml(
    EditorState.createWithContent(content),
  );
  return newEditorState;
};

/**
 * @param {Array<RawBlockType>} blocks
 */
export const replaceLineBreaks = (blocks) => {
  if (blocks && blocks.length > 0) {
    let cloneBlocks = [...blocks];
    let index = 0;
    const type = "unstyled";
    const exchange_type = "header-six";
    while (index < cloneBlocks.length) {
      if (checkRawNodeLineBreak(cloneBlocks[index], type)) {
        cloneBlocks[index].type = exchange_type;
      }
      index++;
    }
    return cloneBlocks;
  }
  return blocks;
};

/**
 * @param {Array<RawBlockType>} blocks
 */
export const recoverLineBreaks = (blocks) => {
  if (blocks && blocks.length > 0) {
    let cloneBlocks = [...blocks];
    const type = "unstyled";
    let index = 0;
    const exchange_type = "header-six";
    while (index < cloneBlocks.length) {
      if (checkRawNodeLineBreak(cloneBlocks[index], exchange_type)) {
        cloneBlocks[index].type = type;
      }
      index++;
    }
    return cloneBlocks;
  }
  return blocks;
};

function fallbackCopyTextToClipboard(text) {
  const textArea = document.createElement("textarea");
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    const successful = document.execCommand("copy");
    const msg = successful ? "successful" : "unsuccessful";
    console.log("Fallback: Copying text command was " + msg);
  } catch (err) {
    console.error("Fallback: Oops, unable to copy", err);
  }

  document.body.removeChild(textArea);
}

export const copyTextToClipboard = (text) => {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(
    function () {
      console.log("Async: Copying to clipboard was successful!");
    },
    function (err) {
      console.error("Async: Could not copy text: ", err);
    },
  );
};

export const getResultTypes = () => {
  return [
    { label: "screen.label.pass", value: "PASS" },
    { label: "screen.label.send_off", value: "SEND_OFF" },
    {
      label: "screen.label.conditional_pass",
      value: "CONDITIONAL_PASS",
    },
    { label: "screen.label.on_hold", value: "ON_HOLD" },
    { label: "screen.label.other", value: "OTHER" },
  ];
};

export const loadMime = async (file) => {
  return new Promise((resolve, reject) => {
    var reader = new FileReader();
    const blob = file.slice(0, 5000);
    reader.readAsArrayBuffer(blob);
    reader.onloadend = function (e) {
      if (e.target.readyState === FileReader.DONE) {
        var bytes = new Uint8Array(e.target.result);
        FileType.fromBuffer(bytes)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }
    };
    reader.onerror = function (e) {
      reject(e);
    };
    reader.onabort = function (e) {
      reject(e);
    };
  });
};
