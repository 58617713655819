import {
  AppstoreOutlined,
  PlusOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { Col, Modal, Row } from "antd";
import PropTypes from "prop-types";
import { FC } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import DeleteIcon from "../../assets/icons/discard.svg";
import { IconUpload } from "../../Components/atoms";
import { Button, Card, Popconfirm } from "../../Components/atoms";
import { InsuPrimaryButton } from "../../Components/atoms/buttons/shared";
import {
  CustomPagination,
  Form,
  SearchDefault,
} from "../../Components/molecules";
import { Option, Select } from "../../Components/molecules";
import {
  Uploader,
  useFileUpload,
} from "../../Components/molecules/fileuploader";
import { Image } from "../../Components/molecules/project/CustomImage";
import { UploadConfirmationItem } from "../../Components/molecules/project/UploadConfirmationItem";
import GridViewProjectFiles from "../../Components/organisms/project/GridViewProjectFiles";
import ListViewProjectFiles from "../../Components/organisms/project/ListViewProjectFiles";
import FilesActions from "../../Redux/FilesRedux";
import { getObjUUID } from "../../utils/common";
import { getFileIcon } from "../../utils/imgPreviewUtils";
import useProjectFileScreen from "./hooks/useProjectFileScreen";
import { withCheckProjectAccess } from "./permissionWrapper";
import { IProjectFileScreenProps } from "./types/IProjectFileScreenProps";
import { ProjectFileMetaData } from "./types/ProjectFileMetaData";

const PageSaveId = "ProjectFileScreen";

const ProjectFileScreen: FC<IProjectFileScreenProps> = function (props) {
  const {
    intl,
    state,
    data,
    layout,
    setState,
    fetchFiles,
    sortOrder,
    showModal,
    hideModal,
    isSuperUser,
    saveFile,
    deleteFiles,
    getProjectId,
  } = useProjectFileScreen(props);

  const {
    selectedFile,
    beforeUpload,
    handleUpload,
    handleUploadCancel: _handleUploadCancel,
  } = useFileUpload();

  const handleUploadCancel = () => {
    _handleUploadCancel();
    hideModal();
  };

  const companyId = getObjUUID(props.context.user?.company);
  const projectId = getProjectId();
  const action = `${process.env.REACT_APP_BASEHOST}companies/${companyId}/projects/${projectId}`;
  const saveUrl = `${process.env.REACT_APP_BASEHOST}companies/${companyId}/projects/${projectId}/save-only-upload-project-files`;
  const projectFileMetaData: ProjectFileMetaData = {
    projectId,
    privacyTag: state.privacyTag,
  };

  // @ts-expect-error TS7006
  const galleryPreview = (file) => {
    if (
      file?.hash?.indexOf(".jpg") > -1 ||
      file?.hash?.indexOf(".png") > -1 ||
      file?.hash?.indexOf(".gif") > -1
    ) {
      let hash = file?.hash;
      if (hash?.indexOf("http") === -1) hash = `https://${hash}`;
      return (
        // @ts-expect-error TS2339
        <center>
          <div
            style={{
              height: 200,
              backgroundImage: `url(${hash})`,
              backgroundSize: "contain",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              display: "block",
              padding: 1,
            }}
          />
          {/*
         // @ts-expect-error TS2339 */}
        </center>
      );
    } else if (file?.hash?.indexOf(".mp4") > -1) {
      let hash = file?.hash;
      if (hash?.indexOf("http") === -1) hash = `https://${hash}`;
      return (
        <Image
          gallery={true}
          src={getFileIcon(file?.hash?.split(".").pop())}
          altIcon={getFileIcon(file?.hash?.split(".").pop())}
          alt={file?.name}
        />
      );
    } else {
      const hash = file?.hash;
      return (
        <Image
          gallery={true}
          src={`https://${hash?.replace(file?.hash?.split(".").pop(), "jpg")}`}
          altIcon={getFileIcon(file?.hash?.split(".").pop())}
          alt={file?.name}
        />
      );
    }
  }; //
  // @ts-expect-error TS7006
  const listViewPreview = (file) => {
    if (
      file?.hash?.indexOf(".jpg") > -1 ||
      file?.hash?.indexOf(".png") > -1 ||
      file?.hash?.indexOf(".gif") > -1
    ) {
      let hash = file?.hash;
      if (hash?.indexOf("http") === -1) hash = `https://${hash}`;
      return (
        // @ts-expect-error TS2339
        <center>
          <div
            style={{
              height: 80,
              backgroundImage: `url(${hash})`,
              backgroundSize: "contain",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              display: "block",
              padding: 1,
            }}
          />
          {/*
         // @ts-expect-error TS2339 */}
        </center>
      );
    } else if (file?.hash?.indexOf(".mp4") > -1) {
      let hash = file?.hash;
      if (hash?.indexOf("http") === -1) hash = `https://${hash}`;
      return (
        <Image
          gallery={false}
          src={getFileIcon(file?.hash?.split(".").pop())}
          altIcon={getFileIcon(file?.hash?.split(".").pop())}
          alt={file?.name}
        />
      );
    } else {
      let hash = file?.hash;
      if (hash?.indexOf("http") === -1) hash = `https://${hash}`;
      return (
        <Image
          gallery={false}
          src={hash?.replace(file?.hash?.split(".").pop(), "jpg")}
          altIcon={getFileIcon(file?.hash?.split(".").pop())}
          alt={file?.name}
        />
      );
    }
  }; //
  const _renderFileUploadButton = () => {
    if (props.project.isTeamMember) {
      return (
        <InsuPrimaryButton
          icon={<PlusOutlined />}
          style={{
            width: "100%",
            maxWidth: "200px",
            float: "right",
          }}
          onClick={showModal}
          // @ts-expect-error TS2769
          type="danger"
        >
          {intl.formatMessage({
            id: "screen.label.add_file",
          })}
        </InsuPrimaryButton>
      );
    }
  }; //
  // @ts-expect-error TS7006
  const _renderFileDeleteButton = (file) => {
    if (props.project.isProjectOwner || isSuperUser()) {
      return (
        <span>
          <Popconfirm
            title={intl.formatMessage({
              id: "screen.label.confirm_delete_file",
            })}
            onConfirm={() => {
              deleteFiles(file?.id);
            }}
            onCancel={() => {}}
            okText={intl.formatMessage({
              id: "screen.label.yes",
            })}
            cancelText={intl.formatMessage({
              id: "screen.label.no",
            })}
          >
            <a>
              <img style={{ marginLeft: "1vw" }} src={DeleteIcon} />
            </a>
          </Popconfirm>
        </span>
      );
    }
  }; //
  return (
    <>
      <Card>
        <Row align="middle" justify="space-between">
          <Col>
            <SearchDefault
              enterButton={intl.formatMessage({
                id: "screen.label.retrieval",
              })}
              placeholder={intl.formatMessage({
                id: "screen.label.please_enter_keyword",
              })}
              onSearch={(value) => {
                setState({ page: 1 });
                fetchFiles(`fileName iLike %${value}%`);
              }}
            />
          </Col>
          <Row
            align="middle"
            justify="space-between"
            style={{ justifyContent: "flex-end" }}
          >
            <Col>
              <Form
                {...layout}
                layout={"inline"}
                name="basic"
                style={{ float: "right" }}
              >
                <Form.Item>
                  <Button
                    onClick={() => {
                      setState({
                        // @ts-expect-error TS2345
                        viewMode: "list",
                      });
                    }}
                    icon={<UnorderedListOutlined />}
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    onClick={() => {
                      setState({
                        // @ts-expect-error TS2345
                        viewMode: "grid",
                      });
                    }}
                    icon={<AppstoreOutlined />}
                  />
                </Form.Item>

                <Form.Item>
                  <Select
                    defaultValue="createdAt"
                    style={{ width: 200 }}
                    onChange={sortOrder}
                  >
                    <Option value="createdAt">
                      {intl.formatMessage({
                        id: "screen.label.date_added_newest",
                      })}
                    </Option>
                    <Option value="fileName">
                      {intl.formatMessage({
                        id: "screen.label.file_name_order",
                      })}
                    </Option>
                  </Select>
                </Form.Item>
              </Form>
            </Col>
            <Col>{_renderFileUploadButton()}</Col>
          </Row>
        </Row>
        <Row id={"file-list-container"} style={{ margin: "40px 0px" }}>
          {state.viewMode === "grid" ? (
            <GridViewProjectFiles
              files={data}
              saveFile={saveFile}
              preview={galleryPreview}
              _renderFileDeleteButton={_renderFileDeleteButton}
            />
          ) : (
            <ListViewProjectFiles
              files={data}
              saveFile={saveFile}
              preview={listViewPreview}
              deleteFiles={deleteFiles}
              canDelete={props.project.isProjectOwner || isSuperUser()}
            />
          )}
        </Row>
        <br />
        <CustomPagination
          saveId={PageSaveId}
          count={props.files.filesPayload.count}
          initPage={state.page}
          initLimit={state.limit}
          onChangePage={(page, limit) => {
            setState(
              {
                page,
                limit,
              },
              () => {
                fetchFiles();
              },
            );
          }}
        />
      </Card>
      <Modal
        cancelText={intl.formatMessage({
          id: "screen.label.cancel",
        })}
        title={intl.formatMessage({
          id: "screen.label.file_upload",
        })}
        visible={state.visible}
        onOk={hideModal}
        onCancel={handleUploadCancel}
        footer={null}
      >
        <div style={{ ...(selectedFile && { display: "none" }) }}>
          <br />
          <Uploader
            isDragger={true}
            data={projectFileMetaData}
            action={action}
            saveUrl={saveUrl}
            onFetchFileList={fetchFiles}
            beforeUpload={beforeUpload}
            onChange={hideModal}
          >
            <>
              <div
                style={{
                  marginBottom: "2vh",
                  marginTop: "7vh",
                }}
              >
                {IconUpload}
              </div>
              <InsuPrimaryButton
                style={{
                  marginBottom: "7vh",
                }}
              >
                {intl.formatMessage({
                  id: "screen.label.click_drag_file",
                })}
              </InsuPrimaryButton>
            </>
          </Uploader>
        </div>
        <UploadConfirmationItem
          isHidden={!selectedFile}
          file={selectedFile}
          handleUpload={handleUpload}
          handleUploadCancel={handleUploadCancel}
          removeFile={_handleUploadCancel}
        />
      </Modal>
    </>
  );
};

// @ts-expect-error TS7006
const mapStateToProps = (state) => ({
  files: state.files,
  project: state.projects.projectPayload,
});
// @ts-expect-error TS7006
const mapDispatchToProps = (dispatch) => ({
  // @ts-expect-error TS7006
  dispatchFetchFiles: (page, limit, id, filter, sort) =>
    // @ts-expect-error TS2554
    dispatch(FilesActions.filesGetsRequest(page, limit, id, filter, sort)),
  // @ts-expect-error TS7006
  dispatchDeleteFiles: (fileId, id, message) =>
    // @ts-expect-error TS2554
    dispatch(FilesActions.filesDeleteRequest(fileId, id, message)),
});
ProjectFileScreen.propTypes = {
  // @ts-expect-error TS2322
  context: PropTypes.object,
  match: PropTypes.object,
  // @ts-expect-error TS2322
  dispatchFetchFiles: PropTypes.func,
  project: PropTypes.object,
  files: PropTypes.object,
  // @ts-expect-error TS2322
  dispatchDeleteFiles: PropTypes.func,
};
export default withCheckProjectAccess(
  // @ts-expect-error TS2345
  connect(mapStateToProps, mapDispatchToProps)(withRouter(ProjectFileScreen)),
);
