import { Col, Form, Modal, Row } from "antd";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

import { ButtonCV } from "../../../atoms";
import { Input, TextArea } from "../../../molecules";
type Props = {
  show: boolean;
  name?: string;
  description?: string;
  onUpdate: (name: string, description: string) => void;
  onHide: () => void;
};

const CheckPointModal = (props: Props) => {
  const { name = "", description = "", show, onUpdate, onHide } = props;

  const [localname, setLocalName] = useState(name);
  const [localdescription, setLocalDescription] = useState(description);

  useEffect(() => {
    setLocalName(name);
    setLocalDescription(description);
  }, [name, description]);

  return (
    <Modal
      destroyOnClose
      title={<FormattedMessage id="screen.label.checkpoint_add" />}
      style={{ minWidth: "40vw" }}
      centered
      visible={show}
      onCancel={onHide}
      footer={null}
    >
      <Row align="middle" justify="center">
        <Col span={16}>
          <Form
            onFinish={() => {
              onUpdate(localname, localdescription);
              onHide();
              setLocalName("");
              setLocalDescription("");
            }}
          >
            <Form.Item>
              <h4>
                <FormattedMessage id="screen.label.checkpoint_item" />
              </h4>
              <Input
                size="large"
                required
                value={localname}
                onChange={(event) => {
                  setLocalName(event.target.value);
                }}
              />
            </Form.Item>
            <br />
            <Form.Item>
              <h4>
                <FormattedMessage id="screen.label.description" />
              </h4>
              <TextArea
                required
                style={{ minHeight: 120 }}
                value={localdescription}
                onChange={(event) => {
                  setLocalDescription(event.target.value);
                }}
              />
            </Form.Item>
            <br />
            <Row align="middle" justify="center">
              <ButtonCV htmlType="submit">
                <FormattedMessage id="screen.label.save" />
              </ButtonCV>
            </Row>
          </Form>
        </Col>
      </Row>
    </Modal>
  );
};

export default CheckPointModal;
