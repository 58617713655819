import React, { Fragment, useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";

/** @type {<T extends object>(value: T) => T} */
export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const q = function ScrollToTop({ history, children }) {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, [history]);

  return <Fragment>{children}</Fragment>;
};

export const useWindowWidth = () => {
  const [width, setWindowWidth] = useState(() => window.innerWidth);
  const onWindowResize = () => {
    setWindowWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", onWindowResize);

    return () => {
      window.removeEventListener("resize", onWindowResize);
    };
  }, []);

  return width;
};

export const useStateWithRef = (initialState) => {
  const [state, _setState] = React.useState(initialState);
  const ref = React.useRef(state);
  const setState = React.useCallback((newState) => {
    if (typeof newState === "function") {
      _setState((prevState) => {
        const computedState = newState(prevState);
        ref.current = computedState;
        return computedState;
      });
    } else {
      ref.current = newState;
      _setState(newState);
    }
  }, []);
  return [state, setState, ref];
};

export const ScrollToTop = withRouter(q);
