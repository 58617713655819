import { Button, Input, Row } from "antd";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { withRouter } from "react-router-dom";

import { ButtonCV } from "../../atoms";
import type { News } from "./type";
const { TextArea } = Input;
type StateProps = {
  comment: News;
  onCreateComment: (commentId: string, commentStr: string) => void;
};
type OwnProps = {
  history: Object;
};
type Props = OwnProps & StateProps;
function NewsCommentInput(props: Props) {
  const { comment, onCreateComment } = props;
  const intl = useIntl();
  const [showInput, setShowInput] = React.useState(false);
  const [commentContent, setCommentContent] = React.useState("");
  const inputRef = React.useRef();
  React.useEffect(() => {
    if (showInput) {
      if (inputRef && inputRef.current) {
        // @ts-expect-error TS2339
        inputRef.current.focus();
      }
    }
  }, [showInput, commentContent]);
  return (
    <div style={{ maxWidth: "50vw" }}>
      {!showInput ? (
        <div
          onClick={() => {
            setShowInput(!showInput);
          }}
        >
          <Input
            placeholder={intl.formatMessage({
              id: "screen.label.please_enter",
            })}
          />
        </div>
      ) : (
        <>
          <Row align="top" justify="space-between">
            <TextArea
              // @ts-expect-error TS2322
              ref={inputRef}
              autoSize={true}
              style={{ minHeight: 80 }}
              value={commentContent}
              onChange={(event) => {
                setCommentContent(event.target.value);
              }}
              placeholder={intl.formatMessage({
                id: "screen.label.please_enter",
              })}
            />
          </Row>
          <br />
          <Row>
            <ButtonCV
              type="primary"
              size="small"
              style={{
                color: "white",
                paddingRight: 30,
                paddingLeft: 30,
              }}
              onClick={() => {
                if (commentContent !== "") {
                  onCreateComment(comment.id, commentContent);
                  setCommentContent("");
                  setShowInput(!showInput);
                }
              }}
            >
              {<FormattedMessage id="screens.title.post" />}
            </ButtonCV>
            <Button
              type="dashed"
              size="small"
              style={{
                marginLeft: 16,
                paddingRight: 16,
                paddingLeft: 16,
              }}
              onClick={() => {
                setShowInput(!showInput);
              }}
            >
              {<FormattedMessage id="screen.label.close" />}
            </Button>
          </Row>
        </>
      )}
      <br />
    </div>
  );
}
// @ts-expect-error TS2345
export default withRouter(NewsCommentInput);
