import { Input, Modal } from "antd";
import { useCallback, useState } from "react";
import { useIntl } from "react-intl";

import { AntdButton } from "../../../../shared/buttons/components/AntdButton";
import styles from "./styles.module.scss";
import { ExaminationPhaseCreateModalProps } from "./type";

export const ExaminationPhaseCreateModal = ({
  visible,
  onCreateCancel,
  onCreate,
}: ExaminationPhaseCreateModalProps) => {
  const [examinationPhaseName, setExaminationPhaseName] = useState("");
  const intl = useIntl();

  const title = intl.formatMessage({
    id: "screen.label.examination_phase_create",
  });
  const placeholder = intl.formatMessage({
    id: "screen.label.eg_first_examination_phase",
  });
  const saveButtonLabel = intl.formatMessage({
    id: "screen.label.storage",
  });
  const saveCancelButtonLabel = intl.formatMessage({
    id: "screen.label.cancel",
  });

  const onChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      setExaminationPhaseName(e.target.value);
    },
    [],
  );

  const createExaminationPhase = useCallback(() => {
    setExaminationPhaseName("");
    onCreate(examinationPhaseName);
  }, [examinationPhaseName, onCreate]);

  return (
    <Modal
      onCancel={onCreateCancel}
      footer={null}
      title={title}
      visible={visible}
    >
      <Input
        placeholder={placeholder}
        value={examinationPhaseName}
        onChange={onChange}
      />
      <div className={styles.btn_container}>
        <AntdButton
          category="primary"
          onClick={createExaminationPhase}
          className={styles.save_btn}
        >
          {saveButtonLabel}
        </AntdButton>
        <AntdButton
          category="secondary"
          onClick={onCreateCancel}
          className={styles.cancel_btn}
        >
          {saveCancelButtonLabel}
        </AntdButton>
      </div>
    </Modal>
  );
};
