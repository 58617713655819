import { Button, Card, Radio, Table } from "antd";
import moment from "moment";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";

import { CustomPagination } from "../../Components/molecules";
import { dateTimeFormat } from "../../utils/constants";
import useUserViewedPagesActivitiesScreen from "./hooks/useUserViewedPagesActivitiesScreen";
import { IUserViewedPagesActivitiesScreenProps } from "./types/IUserViewedPagesActivitiesScreenProps";
const PageSaveId = "UserViewedPagesActivitiesScreen";

const UserViewedPagesActivitiesScreen = function (
  props: IUserViewedPagesActivitiesScreenProps,
) {
  const { state, setState, getScreenActivities } =
    useUserViewedPagesActivitiesScreen(props);

  const _renderUsers = () => {
    return props.users.map((user) => {
      return (
        <Radio key={user.id} value={user.id}>
          {user.name}
        </Radio>
      );
    });
  }; //
  const getUsersFilter = () => ({
    filterDropdown: function _userFilterDropdown({
      // eslint-disable-next-line react/prop-types
      // @ts-expect-error TS7031
      setSelectedKeys,
      // eslint-disable-next-line react/prop-types
      // @ts-expect-error TS7031
      selectedKeys,
      // eslint-disable-next-line react/prop-types
      // @ts-expect-error TS7031
      confirm,
      // eslint-disable-next-line react/prop-types
      // @ts-expect-error TS7031
      clearFilters,
    }) {
      return (
        <div style={{ padding: 8 }}>
          <Radio.Group
            onChange={(e) => {
              setState(
                {
                  selectedUser: e.target.value,
                },
                () => {
                  getScreenActivities(`userId=${state.selectedUser}`);
                },
              );
            }}
          >
            {_renderUsers()}
          </Radio.Group>
          <br />
          <Button
            type="primary"
            onClick={() => {
              clearFilters();
              setSelectedKeys([]);
              setState({ selectedUser: 0 }, () => getScreenActivities(""));
            }}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Reset
          </Button>
        </div>
      );
    },
    // @ts-expect-error TS7006
    onFilter: (value, record) => {},
  }); //
  const getTableColumns = () => {
    return [
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
        responsive: ["sm"],
      },
      {
        title: <FormattedMessage id="screens.title.email" />,
        responsive: ["sm"],
        // @ts-expect-error TS7006
        render: (text, row) => {
          return row.User.Credential.email;
        },
      },
      {
        title: <FormattedMessage id="screens.title.ipaddress" />,
        dataIndex: "clientIp",
        key: "clientIp",
        responsive: ["sm"],
      },
      {
        title: <FormattedMessage id="screens.title.name" />,
        responsive: ["sm"],
        ...getUsersFilter(),
        // @ts-expect-error TS7006
        render: (text, row) => {
          return row.User.name;
        },
      },
      {
        title: <FormattedMessage id="screens.title.from" />,
        dataIndex: "from",
        key: "from",
        responsive: ["sm"],
      },
      {
        title: <FormattedMessage id="screens.title.to" />,
        dataIndex: "to",
        key: "to",
        responsive: ["sm"],
      },
      {
        title: <FormattedMessage id="screens.title.date_time" />,
        dataIndex: "createdAt",
        key: "createdAt",
        responsive: ["sm"],
        // @ts-expect-error TS7006
        render: (text) => moment(text).local().format(dateTimeFormat),
      },
    ];
  }; //

  return (
    <Card>
      <Table
        pagination={false}
        rowKey={"id"}
        // @ts-expect-error TS2322
        columns={getTableColumns()}
        dataSource={Array.isArray(state.screens.rows) ? state.screens.rows : []}
      />
      <br />
      <CustomPagination
        saveId={PageSaveId}
        count={state.screens.count}
        onChangePage={(page, limit) => {
          setState(
            {
              page,
              limit,
            },
            () => {
              if (state.selectedUser > 0) {
                getScreenActivities(`userId=${state.selectedUser}`);
              } else {
                getScreenActivities();
              }
            },
          );
        }}
      />
    </Card>
  );
};

// @ts-expect-error TS7006
const mapStateToProps = (state) => ({
  users: state.user.usersPayload.rows,
});

export default connect(mapStateToProps, null)(UserViewedPagesActivitiesScreen);
