import { useCallback } from "react";
import useSWR from "swr";

import { JsendError } from "../../../types/JSendObject";
import { api } from "../../Saga";
import { endpoints, parseEndpoint } from "../endpoints";
import { GetBatchesResponse } from "./type";

export const useBatchesRequest = () => {
  const url = parseEndpoint(endpoints.batch);

  const fetcher = useCallback(async () => {
    const response = await api.getRequest<GetBatchesResponse, JsendError>(url);
    if (!response.ok) throw response;

    return response.data;
  }, [url]);

  const {
    data,
    error,
    isValidating,
    mutate: batchesMutate,
  } = useSWR(url, fetcher);

  const getSortedBatches = () => {
    const batches = data?.data;
    batches?.sort((currentBatch, nextBatch) => {
      return currentBatch.ord - nextBatch.ord;
    });
    return {
      batches: batches ?? [],
      error,
      isValidating,
    };
  };

  return {
    getSortedBatches,
    batchesMutate,
  };
};
