import { Form, notification } from "antd";
import PropTypes from "prop-types";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";

import { useEventReminder } from "../../../Services/event/useEventReminder";
import { ButtonCV, ButtonUndo, Checkbox } from "../../atoms";
import { Input } from "../../molecules";
import {
  EventSurveyFormComponentState,
  IEventSurveyFormComponentProps,
} from "./types";

const useEventSurveyFormComponent = (props: IEventSurveyFormComponentProps) => {
  const intl = useIntl();
  const [state, customSetState] = useState<EventSurveyFormComponentState>(
    () => {
      return {
        title: "",
        description: "",
        videoUrl: "",
        sendEmail: false,
      };
    },
  );
  const { createEventSurvey } = useEventReminder();

  const onSubmitEventSurvey = useCallback(async () => {
    if (state.title.trim().length === 0) {
      return notification.error({
        message: intl.formatMessage({
          id: "screen.label.title_required",
        }),
      });
    }
    if (state.description.trim().length === 0) {
      return notification.error({
        message: intl.formatMessage({
          id: "screen.label.url_required",
        }),
      });
    }
    const data = {
      title: `${state.title}`,
      description: state.description,
      meta: {
        type: "poll",
        videoUrl: state.videoUrl,
      },
      sendEmail: state.sendEmail,
    };
    const { isFailed } = await createEventSurvey(props.eventId, data);
    if (isFailed) return;

    notification.success({
      message: intl.formatMessage({
        id: "screen.label.completed_successfully",
      }),
    });
    props.onClose?.();
  }, [
    createEventSurvey,
    intl,
    props,
    state.description,
    state.sendEmail,
    state.title,
    state.videoUrl,
  ]);

  // @ts-expect-error TS7006
  const handleChange = (key, value) => {
    // @ts-expect-error TS2345
    setState({ [key]: value });
  };

  const setStateCallbackRef = useRef(() => {});
  useEffect(() => {
    const callBack = setStateCallbackRef.current;
    callBack();
    setStateCallbackRef.current = () => {};
  }, [state]);
  const setState = (
    data: EventSurveyFormComponentState,
    callback = () => {},
  ) => {
    setStateCallbackRef.current = callback;
    customSetState((previousState) => {
      return {
        ...previousState,
        ...data,
      };
    });
  };

  return {
    intl,
    state,
    onSubmitEventSurvey,
    handleChange,
    setState,
  };
};

const EventSurveyFormComponent: FC<IEventSurveyFormComponentProps> = function (
  props,
) {
  const { intl, state, onSubmitEventSurvey, handleChange } =
    useEventSurveyFormComponent(props);

  return (
    <Form layout={"vertical"}>
      <Form.Item
        label={intl.formatMessage({
          id: "screen.label.title",
        })}
      >
        <Input
          required
          type="text"
          placeholder={intl.formatMessage({
            id: "screen.label.please_enter",
          })}
          value={state.title}
          onChange={(event) => handleChange("title", event.target.value)}
        />
      </Form.Item>
      <Form.Item label={"URL"}>
        <Input
          required
          type="url"
          placeholder="https://~"
          value={state.description}
          onChange={(event) => handleChange("description", event.target.value)}
        />
      </Form.Item>
      <Form.Item
        label={intl.formatMessage({
          id: "screen.label.video_url",
        })}
      >
        <Input
          onChange={(event) => handleChange("videoUrl", event.target.value)}
          value={state.videoUrl}
          placeholder={intl.formatMessage({
            id: "screen.label.video_url",
          })}
        />
      </Form.Item>
      <Form.Item
        label={`${intl.formatMessage({
          id: "screen.label.email_notification",
        })} *`}
      >
        <Checkbox
          onChange={(e) => {
            handleChange("sendEmail", e.target.checked);
          }}
          checked={state.sendEmail}
        >
          {intl.formatMessage({
            id: "screen.label.notify_same_content_email",
          })}
        </Checkbox>
      </Form.Item>
      <Form.Item>
        <ButtonCV onClick={onSubmitEventSurvey}>
          {intl.formatMessage({
            id: "screens.title.post",
          })}
        </ButtonCV>
        <ButtonUndo onClick={props.onClose} className="offset-left-16">
          {intl.formatMessage({
            id: "screen.label.cancel",
          })}
        </ButtonUndo>
      </Form.Item>
    </Form>
  );
};

EventSurveyFormComponent.propTypes = {
  eventId: PropTypes.any,
  onClose: PropTypes.func,
};
export default EventSurveyFormComponent;
