import { Row } from "antd";
import { useIntl } from "react-intl";
import { withRouter } from "react-router-dom";

import { IconRightArrow } from "../../Components/atoms";

const library_items = [
  {
    title: "screen.label.video_management",
    link: "/global-video",
  },
  {
    title: "screen.label.library_management",
    link: "/global-library",
  },
];
type ActionProps = {};
type StateProps = {
  history: Object;
};
type Props = StateProps & ActionProps;
function GlobalManagementScreen(props: Props) {
  const intl = useIntl();
  // @ts-expect-error TS7006
  const goToScreen = (item) => {
    // @ts-expect-error TS2339
    props.history.push(item.link);
  };
  return (
    <div className="library-management">
      {library_items.map((item) => {
        return (
          <Row
            key={item.link}
            className="management-item"
            align="middle"
            justify="space-between"
            onClick={() => {
              goToScreen(item);
            }}
          >
            <div>{intl.formatMessage({ id: item.title })}</div>
            <div>{IconRightArrow}</div>
          </Row>
        );
      })}
    </div>
  );
}
export default withRouter(GlobalManagementScreen);
