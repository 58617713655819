import { notification } from "antd";
import { useIntl } from "react-intl";
import useSWR from "swr";

import { LoginType } from "@/../types/Company";
import { JsendError } from "@/../types/JSendObject";
import { api } from "@/Saga";
import { BaseResponse, NullResponse } from "@/Services/commonResponsTypes";
import { endpoints, parseEndpoint } from "@/Services/endpoints";

type LoginTypeBody = {
  loginType: LoginType;
};

type GetLoginTypeResponse = BaseResponse<LoginTypeBody>;

export const useLoginTypeRequest = (companyUuid: string) => {
  const intl = useIntl();
  const failedPutLoginType = intl.formatMessage({
    id: "screen.label.failed_put_login_type",
  });
  const successPutLoginType = intl.formatMessage({
    id: "screen.label.success_put_login_type",
  });

  const url = parseEndpoint(endpoints.loginType, { companyUuid });

  const fetcher = async () => {
    const response = await api.getRequest<GetLoginTypeResponse, JsendError>(
      url,
    );
    if (response.data?.status !== "success") throw new Error("Failed to fetch");
    return response.data.data.loginType;
  };

  const putLoginTypeRequest = async (loginType: LoginType) => {
    const response = await api.putRequest<LoginTypeBody, NullResponse>(url, {
      loginType,
    });
    if (response.data?.status !== "success") {
      notification.error({
        message: response.data?.message ?? failedPutLoginType,
      });
      return;
    }
    notification.success({
      message: successPutLoginType,
    });
    mutate();
  };

  const { data: loginType, mutate } = useSWR<LoginType, JsendError>(
    url,
    fetcher,
  );

  return { loginType, putLoginTypeRequest };
};
