import { FC, useCallback, useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { ContentContext } from "@/Provider";
import { endpoints, parseEndpoint } from "@/Services/endpoints";
import { TagList } from "@/shared/tags/components/TagList";

import { Tag } from "../../../../../types/Tag";

type ProjectLabelTags = {
  //
};

export const ProjectLabelTags: FC<ProjectLabelTags> = () => {
  const intl = useIntl();
  const context = useContext(ContentContext);
  const [tags, setTags] = useState<Tag[]>([]);

  const getTags = useCallback(() => {
    const tagRequest = {
      method: "getRequest",
      url: parseEndpoint(endpoints.tags),
      data: {},
    };
    // @ts-expect-error TS2345
    context.apiRequest(tagRequest, ({ data }) => {
      // @ts-expect-error TS7006
      setTags(data);
    });
  }, [context, setTags]);

  useEffect(() => {
    getTags();
  }, [getTags]);

  const handleDelete = (tagName: string) => {
    const tagId = tags.find((t) => t.name === tagName)?.id;
    const tagRequest = {
      method: "deleteRequest",
      url: parseEndpoint(endpoints.singleTag, {
        // @ts-expect-error TS2322
        tagId,
      }),
      data: {},
    };
    // @ts-expect-error TS2345
    context.apiRequest(tagRequest, ({ data }) => {
      // @ts-expect-error TS7006
      setTags(data);
    });
  };

  const handleCreate = (tagName: string) => {
    const isDuplicateTag = tags.some((tag) => tag.name === tagName);
    const isEmpty = tagName.trim().length === 0;
    if (isDuplicateTag || isEmpty) return;

    const tagRequest = {
      method: "postRequest",
      url: parseEndpoint(endpoints.tags),
      data: {
        name: tagName,
      },
    };
    // @ts-expect-error TS2345
    context.apiRequest(tagRequest, () => {
      getTags();
    });
  };

  return (
    <TagList
      tags={tags.map((tag) => tag.name)}
      onCreated={handleCreate}
      onDeleted={handleDelete}
      createLabel={intl.formatMessage({
        id: "screen.label.project_label",
      })}
    />
  );
};
