import { FC } from "react";
import { useIntl } from "react-intl";

import { TagList } from "@/shared/tags/components/TagList";

type UserTagTags = {
  userTags: string[];
  setUserTags: (userTags: string[]) => void;
};

export const UserTagTags: FC<UserTagTags> = ({ userTags, setUserTags }) => {
  const intl = useIntl();

  const handleCreate = (newUserTag: string) => {
    const isDuplicate = userTags.some((userTag) => userTag === newUserTag);
    const isEmpty = newUserTag.trim().length === 0;
    if (isDuplicate || isEmpty) return;

    setUserTags([...userTags, newUserTag]);
  };

  const handleDelete = (deletedUserTag: string) => {
    const _userTags = userTags.filter((tag) => tag !== deletedUserTag);
    setUserTags(_userTags);
  };

  return (
    <TagList
      tags={userTags}
      createLabel={intl.formatMessage({
        id: "screen.label.user_label",
      })}
      onCreated={handleCreate}
      onDeleted={handleDelete}
    />
  );
};
