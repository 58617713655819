import FileSaver from "file-saver";
import { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";

import { ExaminationResult } from "@/../types/ExaminationResult";
import { publishReviewRequest } from "@/Screens/submissions/helper";
import { responseCallbackWithNotification } from "@/Services/api/responseCallbackWithNotiofication";
import {
  deleteExaminationDetail,
  getExaminationDetail,
  updateExaminationDetail,
} from "@/Services/examination/request";

import { ExaminationDetail } from "../../../types/ExaminationDetail";
import { AddReviewToProjectSubmission } from "../../Components/organisms/submissions";
import ApiActions, { ApiRequestData } from "../../Redux/ApiRedux";
import { endpoints, parseEndpoint } from "../../Services/endpoints";

const R = require("ramda");

const SingleSubmissionPreviewScreen = function () {
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();

  // @ts-expect-error TS2339
  const user = useSelector((state) => state.auth.payload);
  const { uuid } = useParams<{ uuid: string }>();
  const [examinationDetail, setExaminationDetail] =
    useState<ExaminationDetail>();
  const [hasChanged, setHasChanged] = useState(false);
  const [defaultExaminationResult, setDefaultExaminationResult] =
    useState<ExaminationResult>("BLANK");

  const getDefaultEvaluation = useCallback(
    () => ({
      userId: user.id,
      examinationFlags: [],
      score: 0,
    }),
    [user.id],
  );

  useEffect(() => {
    (async () => {
      const fetchedExaminationDetail = await getExaminationDetail(uuid);
      if (!fetchedExaminationDetail) return;
      const { evaluations } = fetchedExaminationDetail;
      const defaultEvaluation = getDefaultEvaluation();
      if (evaluations.length === 0) evaluations.push(defaultEvaluation);
      setExaminationDetail(fetchedExaminationDetail);
      setDefaultExaminationResult(fetchedExaminationDetail.examinationResult);
    })();
  }, [getDefaultEvaluation, user.id, uuid]);

  const updateEvalutationDetail = (
    index: number,
    key: string,
    value: string,
  ) => {
    const cloneExaminationDetail = R.clone(
      examinationDetail,
    ) as ExaminationDetail;
    const { evaluations } = cloneExaminationDetail;
    // @ts-expect-error TS7053
    evaluations[index][key] = value;
    cloneExaminationDetail.evaluations = evaluations;
    setExaminationDetail(cloneExaminationDetail);
    handleChanged();
  };

  const fileDownloaded = (fileId: number | string, fileDetail = {}) => {
    const data: ApiRequestData = {
      method: "postRequest",
      url: parseEndpoint(endpoints.fileDownload, {
        fileId,
      }),
      data: fileDetail,
    };
    dispatch(ApiActions.apiRequest(data, () => {}));
  };

  const onDeleteProjectSubmission = async () => {
    if (!examinationDetail) return;
    const response = await deleteExaminationDetail(uuid);
    const message = intl.formatMessage({
      id: "screen.label.submission_information_successfully_deleted",
    });
    responseCallbackWithNotification(response, message, () => {
      history.push(
        `/dashboard/project_submissions/${examinationDetail.batchUuid}`,
      );
    });
  };

  const onSaveProjectSubmissionReview = async () => {
    if (!examinationDetail) return;
    const message = intl.formatMessage({
      id: "screen.label.examination_information_successfully_saved",
    });
    const response = await updateExaminationDetail(uuid, {
      evaluations: examinationDetail.evaluations,
      examinationResult: examinationDetail.examinationResult,
      feedbackComment: examinationDetail.feedbackComment,
    });
    responseCallbackWithNotification(response, message, async () => {
      const fetchedExaminationDetail = await getExaminationDetail(uuid);
      setExaminationDetail(fetchedExaminationDetail);
      setDefaultExaminationResult(
        fetchedExaminationDetail?.examinationResult || "BLANK",
      );
      setHasChanged(false);
    });
  };

  const publishReview = async () => {
    if (!examinationDetail) return;
    const successMessage = intl.formatMessage({
      id: "screen.label.examination_information_successfully_published",
    });
    const response = await publishReviewRequest(examinationDetail.uuid);
    responseCallbackWithNotification(response, successMessage, async () => {
      setExaminationDetail(await getExaminationDetail(uuid));
    });
  };

  const saveFile = (
    href: string,
    fileName: string,
    extension?: string,
    fileId?: number | string,
  ) => {
    const xhr = new XMLHttpRequest();
    xhr.withCredentials = true;
    xhr.open("GET", href, true);
    xhr.responseType = "arraybuffer";
    xhr.onload = (e) => {
      const currentTarget: any = e.currentTarget;
      const arrayBufferView = new Uint8Array(currentTarget.response);
      const blob = new Blob([arrayBufferView]);
      fileDownloaded(fileId ? fileId : -1, {
        fileName,
        fileSize: blob.size,
      });
      FileSaver.saveAs(blob, fileName);
    };
    xhr.send();
  };

  const handleChanged = () => {
    setHasChanged(true);
  };

  if (examinationDetail) {
    return (
      <AddReviewToProjectSubmission
        handleChanged={handleChanged}
        defaultExaminationResult={defaultExaminationResult}
        hasChanged={hasChanged}
        onSaveProjectSubmissionReview={onSaveProjectSubmissionReview}
        setExaminationDetail={setExaminationDetail}
        updateEvalutationDetail={updateEvalutationDetail}
        saveFile={saveFile}
        examinationDetail={examinationDetail}
        onDeleteProjectSubmission={onDeleteProjectSubmission}
        publishReview={publishReview}
      />
    );
  }
  return <></>;
};

export default SingleSubmissionPreviewScreen;
