import React from "react";

type I18NContextType = {
  locale: string;
  selectLanguage: (language: string) => void;
};

// Default context values
const defaultI18NContext: I18NContextType = {
  locale: "ja",
  selectLanguage() {
    throw new Error("selectLanguage was called without being initialized");
  },
};

export const I18NContext = React.createContext(defaultI18NContext);
