import { Popconfirm } from "antd";
import { memo } from "react";
import { useIntl } from "react-intl";

import { DeleteIconButton } from "@/shared/buttons/components/DeleteIconButton";

import { PropsCommentDeleteButton } from "./type";
export const CommentDeleteButton = memo(
  ({ comment, onDelete }: PropsCommentDeleteButton) => {
    const intl = useIntl();
    const title = intl.formatMessage({
      id: "screen.label.delete_confirm_comment",
    });
    const okText = intl.formatMessage({ id: "screen.label.yes" });
    const cancelText = intl.formatMessage({ id: "screen.label.no" });
    return (
      <Popconfirm
        title={title}
        onConfirm={() => onDelete(comment)}
        okText={okText}
        cancelText={cancelText}
      >
        <DeleteIconButton
          id={`comment-button-${comment.id}`}
          alt="comment delete"
        />
      </Popconfirm>
    );
  },
);
