import { Action } from "@reduxjs/toolkit";
import { createActions, createReducer } from "reduxsauce";
import Immutable from "seamless-immutable";

import { Company } from "@/../types/Company";

import { ToAction, ToActionType } from "./types";

/* ------------- Types and Action Creators ------------- */
type CompanyActionCreators = {
  companySuccess: (companies: {
    count: number;
    rows: Company[];
  }) => Action<"COMPANY_SUCCESS">;

  companyGetRequest: () => Action<"COMPANY_GET_REQUEST">;
  companyGetSuccess: () => Action<"COMPANY_GET_SUCCESS">;
  companyGetFailure: () => Action<"COMPANY_GET_FAILURE">;

  companyUpdateRequest: () => Action<"COMPANY_UPDATE_REQUEST">;
  companyUpdateSuccess: () => Action<"COMPANY_UPDATE_SUCCESS">;
  companyUpdateFailure: () => Action<"COMPANY_UPDATE_FAILURE">;
};
type CompanyAction = ToAction<CompanyActionCreators>;
type CompanyActionType = ToActionType<CompanyAction>;

const { Types, Creators } = createActions<
  CompanyActionType,
  CompanyActionCreators
>({
  companySuccess: ["companies"],

  companyGetRequest: [],
  companyGetSuccess: ["companyPayload"],
  companyGetFailure: ["errors"],

  companyUpdateRequest: ["update", "message"],
  companyUpdateSuccess: null,
  companyUpdateFailure: ["errors"],
});

export const CompanyTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
type CompanyState = {
  fetching: boolean;
  errors: unknown;
  error?: unknown;

  companies: {
    rows: unknown[];
    count: number;
  };

  companyPayload: {
    id: number;
    name: string;
    icon: string;
    currentPlan: string;
    emailAllowed: string;
    stages: unknown[];
    milestones: unknown[];
    milestonePairs: unknown[];
    projectSubmissionTypes: unknown[];
    projectTags: unknown[];
    projectSetting: unknown[];
    examinationStatusOptions: unknown[];
  };
};

export const INITIAL_STATE = Immutable<CompanyState>({
  fetching: false,
  errors: {},

  companies: {
    rows: [],
    count: 0,
  },

  companyPayload: {
    id: 1,
    name: "",
    icon: "",
    currentPlan: "",
    emailAllowed: "",
    stages: [],
    milestones: [],
    milestonePairs: [],
    projectSubmissionTypes: [],
    projectTags: [],
    projectSetting: [],
    examinationStatusOptions: [],
  },
});

type ImmCompanyState = typeof INITIAL_STATE;

/* ------------- Reducers ------------- */

const request = (state: ImmCompanyState) => state.merge({ fetching: true });

const success = (state: ImmCompanyState) => {
  return state.merge({ fetching: false });
};

// @ts-expect-error TS7031
const failure = (state: ImmCompanyState, { error }) =>
  state.merge({ fetching: false, error });

// @ts-expect-error TS7031
const companySuccess = (state: ImmCompanyState, { companies }) =>
  state.merge({ companies });

const companyGetRequest = (state: ImmCompanyState) =>
  state.merge({ fetching: true });

// @ts-expect-error TS7031
const companyGetSuccess = (state: ImmCompanyState, { companyPayload }) => {
  return state.merge({ fetching: false, companyPayload });
};

// @ts-expect-error TS7031
const companyGetFailure = (state: ImmCompanyState, { error }) =>
  state.merge({ fetching: false, error });

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer<ImmCompanyState, CompanyAction>(
  INITIAL_STATE,
  {
    [Types.COMPANY_SUCCESS]: companySuccess,

    [Types.COMPANY_GET_REQUEST]: companyGetRequest,
    [Types.COMPANY_GET_SUCCESS]: companyGetSuccess,
    [Types.COMPANY_GET_FAILURE]: companyGetFailure,

    [Types.COMPANY_UPDATE_REQUEST]: request,
    [Types.COMPANY_UPDATE_SUCCESS]: success,
    [Types.COMPANY_UPDATE_FAILURE]: failure,
  },
);
