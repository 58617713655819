import styled from "styled-components";

export const ReorderSwitch = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const sortStyle = {
  SortableList: {
    position: "relative",
    zIndex: "0",
    outline: "none",
    listStyle: "none",
    padding: "0",
  },
};
