import { CloseOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Form, Input, Popconfirm, Row } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useIntl } from "react-intl";

import { SingleMilestoneItemProps } from "./types/SingleMilestoneItemProps";

const SingleMilestoneItem: React.FC<SingleMilestoneItemProps> = function ({
  singleMilestone,
  isMilestoneSortable,
  onSaveMilestone,
  deleteAMilestone,
}: SingleMilestoneItemProps) {
  const [isEditingMode, setEditingMode] = useState(false);
  const [localMilestone, setLocalMilestone] = useState(null);
  // @ts-expect-error TS7006
  const _renderFontLabel = (label) => {
    return <span style={{ fontSize: 14 }}>{label}</span>;
  };
  const intl = useIntl();
  useEffect(() => {
    setLocalMilestone(singleMilestone);
  }, [singleMilestone]);
  if (localMilestone !== null) {
    return (
      <div
        style={{
          marginBottom: "1vh",
          marginTop: "3vh",
          borderWidth: 1,
          borderColor: "#BDBDBD",
          cursor: isMilestoneSortable ? "row-resize" : "default",
          borderRadius: "8px",
          padding: "1rem",
          borderStyle: isMilestoneSortable ? "dashed" : "solid",
          backgroundColor: "white",
        }}
        className={isMilestoneSortable ? "noselect" : ""}
      >
        <Form layout={"vertical"}>
          <Row gutter={[12, 12]}>
            <Col span={14}>
              <Form.Item
                label={_renderFontLabel(
                  <FormattedMessage id="screen.label.milestone_name" />,
                )}
              >
                <Input
                  // @ts-expect-error TS2339
                  value={localMilestone.name}
                  onChange={(e) => {
                    setEditingMode(true);
                    setLocalMilestone({
                      // @ts-expect-error TS2698
                      ...localMilestone,
                      name: e.target.value,
                    });
                  }}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                label={_renderFontLabel(
                  <FormattedMessage id="screen.label.milestone_expiration_date" />,
                )}
              >
                <DatePicker
                  value={
                    // @ts-expect-error TS2339
                    typeof localMilestone.date === "string"
                      ? // @ts-expect-error TS2339
                        moment(localMilestone.date).local()
                      : // @ts-expect-error TS2339
                      moment.isMoment(localMilestone.date)
                      ? // @ts-expect-error TS2339
                        localMilestone.date
                      : null
                  }
                  // @ts-expect-error TS2769
                  defaultValue={
                    // @ts-expect-error TS2339
                    localMilestone.date
                      ? // @ts-expect-error TS2339
                        moment(localMilestone.date).local()
                      : null
                  }
                  onChange={(date) => {
                    setEditingMode(true);
                    setLocalMilestone({
                      // @ts-expect-error TS2698
                      ...localMilestone,
                      date: date ? date.local() : null,
                    });
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={1}>
              <Popconfirm
                title={intl.formatMessage(
                  {
                    id: "screen.label.delete_milestone_confirm",
                  },
                  // @ts-expect-error TS2339
                  { milestoneName: localMilestone.name },
                )}
                onConfirm={() => {
                  // @ts-expect-error TS2722
                  deleteAMilestone(localMilestone.index);
                }}
                okText={<FormattedMessage id="screen.label.yes" />}
                cancelText={<FormattedMessage id="screen.label.no" />}
              >
                <Button icon={<CloseOutlined />} />
              </Popconfirm>
            </Col>
          </Row>
          {isEditingMode && (
            // @ts-expect-error TS2322
            <Row align="end" style={{ marginTop: 10, marginRight: "15%" }}>
              <Button
                type="primary"
                size="small"
                onClick={() => {
                  setEditingMode(false);
                  // @ts-expect-error TS2722
                  onSaveMilestone(localMilestone);
                }}
                style={{ float: "right", fontSize: 14 }}
              >
                <FormattedMessage id="screens.title.save" />
              </Button>
              <Button
                size="small"
                onClick={() => {
                  setEditingMode(false);
                  setLocalMilestone(singleMilestone);
                }}
                style={{
                  float: "right",
                  marginLeft: 10,
                  fontSize: 14,
                }}
              >
                <FormattedMessage id="screen.label.cancel" />
              </Button>
            </Row>
          )}
        </Form>
      </div>
    );
  }
  return <></>;
};
export default SingleMilestoneItem;
