import { PlusOutlined, SmileOutlined } from "@ant-design/icons";
import { Button, Col, Popover, Row } from "antd";
import Picker from "emoji-picker-react";
import React from "react";

import { getDisplayName } from "../../../utils/common";
import type { EmojiComment, News } from "./type";
type Props = {
  comment: News;
  user: Object;
  companyId: number;
  isSub: boolean;
  isCanEditEmoji: boolean;
  onAddReaction: (commentId: string, emoji: string, emojiName: string) => void;
  onAddSubReaction: (
    id: string,
    commentId: string,
    emoji: string,
    emojiName: string,
  ) => void;
};
function NewEmoji(props: Props) {
  const {
    comment,
    user,
    companyId = null,
    isSub = false,
    isCanEditEmoji = true,
    onAddReaction,
    onAddSubReaction,
  } = props;
  const [showPicker, setShowPicker] = React.useState(false);
  const emojiGroups = React.useMemo(() => {
    let commentEmojjis = [];
    if (isSub) {
      // @ts-expect-error TS2551
      commentEmojjis = comment.NewsCommentEmojiResponse;
    } else {
      commentEmojjis = comment.NewsEmojiResponse;
    }
    if (commentEmojjis) {
      let emojiArr = [...commentEmojjis];
      if (companyId) {
        emojiArr = emojiArr.filter((a) => a.companyId === companyId);
      }
      let emojiGroups = emojiArr.reduce((ac, a) => {
        // @ts-expect-error TS7006
        const temp = ac.find((x) => x.emojiName === a.emojiName);
        if (!temp)
          ac.push({
            ...a,
            users: [a.User],
          });
        else {
          temp.users.push(a.User);
        }
        return ac;
      }, []);
      // @ts-expect-error TS7006
      emojiGroups = emojiGroups.sort((a, b) => a.id - b.id);
      return emojiGroups;
    }
    return [];
  }, [comment, companyId, isSub]);
  const renderEmoji = (emoji: EmojiComment) => {
    const defaultStyle = {
      borderStyle: "solid",
      borderWidth: "1px",
      borderRadius: "14px",
      borderColor: "rgb(29 155 209)",
      paddingLeft: "7px",
      paddingRight: "7px",
      paddingTop: "2px",
      paddingBottom: "2px",
      marginRight: "10px",
    };
    const styleSelected = {
      ...defaultStyle,
      backgroundColor: "rgb(235 245 250)",
    };
    // @ts-expect-error TS2551
    const userNamesList = Array.isArray(emoji.users) ? (
      // @ts-expect-error TS2551
      emoji.users.map((u) => {
        return <p key={u.id}>{getDisplayName(u)}</p>;
      })
    ) : (
      <></>
    );
    const content = <div>{userNamesList}</div>;
    let isAddedReaction = false;
    // @ts-expect-error TS2551
    if (emoji.users && emoji.users.length > 0) {
      // @ts-expect-error TS2551
      const findUser = emoji.users.find((a) => a.id === user.id);
      if (findUser) {
        isAddedReaction = true;
      }
    }
    return (
      <Popover
        key={`${comment.id}_${emoji.id}_hover`}
        content={content}
        trigger="hover"
      >
        <span
          style={isAddedReaction ? styleSelected : defaultStyle}
          className="emoji"
          role="img"
          aria-label={""}
          aria-hidden={"true"}
        >
          {emoji.emoji}{" "}
          {/*
           // @ts-expect-error TS2551 */}
          <span style={{ fontSize: "0.8rem" }}>{emoji.users.length}</span>
        </span>
      </Popover>
    );
  };
  if (!isCanEditEmoji) {
    let emojis = [];
    if (isSub) {
      if (companyId) {
        // @ts-expect-error TS2551
        emojis = comment.NewsCommentEmojiResponse.filter(
          // @ts-expect-error TS7006
          (a) => a.companyId === companyId,
        );
      } else {
        // @ts-expect-error TS2551
        emojis = comment.NewsCommentEmojiResponse;
      }
    } else {
      if (companyId) {
        emojis = comment.NewsEmojiResponse.filter(
          (a) => a.companyId === companyId,
        );
      } else {
        emojis = comment.NewsEmojiResponse;
      }
    }
    return (
      // @ts-expect-error TS2322
      <Row key={`${comment.id}-like`} align="middle" justify="flex-start">
        <Col style={{ marginRight: 10 }}>
          <div className={`like-btn disabled`}>
            <SmileOutlined />
            <span style={{ marginLeft: 10, fontWeight: "normal" }}>
              {emojis.length}
            </span>
          </div>
        </Col>
        {emojiGroups.length > 0 &&
          // @ts-expect-error TS7006
          emojiGroups.map((emoji) => {
            return (
              <Col key={`${comment.id}_${emoji.id}`}>
                <Button
                  style={{
                    padding: 0,
                    backgroundColor: "transparent",
                  }}
                >
                  {renderEmoji(emoji)}
                </Button>
              </Col>
            );
          })}
      </Row>
    );
  }
  return (
    // @ts-expect-error TS2322
    <Row key={`${comment.id}-like`} align="middle" justify="flex-start">
      <Col style={{ marginRight: 10 }}>
        <div
          className={isSub ? "" : `like-btn enabled`}
          onClick={() => {
            setShowPicker(!showPicker);
          }}
        >
          {isSub ? (
            <Row align="middle">
              <SmileOutlined
                style={{
                  fontSize: 20,
                  color: "#1F86E9",
                }}
              />{" "}
              <PlusOutlined
                style={{
                  fontSize: 10,
                  color: "#1F86E9",
                  marginLeft: 10,
                }}
              />
            </Row>
          ) : (
            <>😃</>
          )}
        </div>
        {showPicker && (
          <Picker
            pickerStyle={{
              position: "absolute",
              // @ts-expect-error TS2322
              top: 36,
            }}
            onEmojiClick={(event, emojiObject) => {
              event.stopPropagation();
              setShowPicker(false);
              if (isSub) {
                onAddSubReaction(
                  // @ts-expect-error TS2339
                  comment.newsId,
                  comment.id,
                  emojiObject.emoji,
                  emojiObject.originalUnified,
                );
              } else {
                onAddReaction(
                  comment.id,
                  emojiObject.emoji,
                  emojiObject.originalUnified,
                );
              }
            }}
          />
        )}
      </Col>

      {emojiGroups.length > 0 &&
        // @ts-expect-error TS7006
        emojiGroups.map((emoji) => {
          return (
            <Col key={`${comment.id}_${emoji.id}`}>
              <Button
                style={{
                  padding: 0,
                  backgroundColor: "transparent",
                }}
                onClick={() => {
                  if (isSub) {
                    onAddSubReaction(
                      // @ts-expect-error TS2339
                      comment.newsId,
                      comment.id,
                      emoji.emoji,
                      emoji.emojiName,
                    );
                  } else {
                    onAddReaction(comment.id, emoji.emoji, emoji.emojiName);
                  }
                }}
              >
                {renderEmoji(emoji)}
              </Button>
            </Col>
          );
        })}
    </Row>
  );
}
export default NewEmoji;
