import { notification } from "antd";
import { push } from "connected-react-router";
import { all, call, put, takeLatest } from "redux-saga/effects";

import AuthActions, { AuthTypes } from "../Redux/AuthRedux";
import { endpoints, parseEndpoint } from "../Services/endpoints";
import { getObjUUID } from "../utils/common";

// @ts-expect-error TS7006
function* attemptReset(api, action) {
  const { data, message } = action;

  // @ts-expect-error TS7057
  const resetResponse = yield call(
    api.postRequest,
    parseEndpoint(endpoints.authReset),
    {
      token: data.token,
      password: data.password,
    },
  );

  if (resetResponse.ok) {
    switch (resetResponse.data.status) {
      case "success":
        notification.success({
          message: message,
        });
        // @ts-expect-error TS2554
        yield put(AuthActions.resetPasswordSuccess(resetResponse.data.data));
        setTimeout(() => {
          // @ts-expect-error TS2322
          window.location = "/login";
        }, 1000);
        break;
      case "fail":
      case "error":
        if (typeof resetResponse.data.data === "string") {
        }
        // @ts-expect-error TS2554
        yield put(AuthActions.resetPasswordFailure(resetResponse.data.data));
        break;
      default:
        break;
    }
  } else {
    notification.error({
      message: resetResponse.problem,
    });
    // @ts-expect-error TS2554
    yield put(AuthActions.resetPasswordFailure(resetResponse.problem));
  }
}

// @ts-expect-error TS7006
function* attemptForgot(api, action) {
  const { data, message } = action;

  // @ts-expect-error TS7057
  const forgotResponse = yield call(
    api.postRequest,
    parseEndpoint(endpoints.forgotPassword),
    {
      email: data.email,
    },
  );

  if (forgotResponse.ok) {
    switch (forgotResponse.data.status) {
      case "success":
        notification.success({
          message: message,
        });
        // @ts-expect-error TS2554
        yield put(AuthActions.forgotPasswordSuccess(forgotResponse.data.data));
        setTimeout(() => {
          // @ts-expect-error TS2322
          window.location = "/login";
        }, 1000);
        break;
      case "fail":
      case "error":
        if (typeof forgotResponse.data.data === "string") {
        }
        // @ts-expect-error TS2554
        yield put(AuthActions.forgotPasswordFailure(forgotResponse.data.data));
        break;
      default:
        break;
    }
  } else {
    notification.error({
      message: forgotResponse.problem,
    });
    // @ts-expect-error TS2554
    yield put(AuthActions.forgotPasswordFailure(forgotResponse.problem));
  }
}

// @ts-expect-error TS7006
function* logUserOut(api, action) {
  localStorage.removeItem("AllProjectListState");
  localStorage.removeItem("MyProjectListState");
  localStorage.removeItem("membersScreen");
  localStorage.removeItem("persist:root");
  yield call(api.deleteRequest, parseEndpoint(endpoints.logOut));
  setBaseUrl(api, { url: process.env.REACT_APP_BASEHOST });
}

// @ts-expect-error TS7006
function* attemptLogin(api, action) {
  const { data } = action;

  // @ts-expect-error TS7057
  const loginResponse = yield call(
    api.postRequest,
    parseEndpoint(endpoints.login),
    {
      email: data.email,
      password: data.password,
    },
  );

  if (loginResponse.ok) {
    switch (loginResponse.data.status) {
      case "success": {
        yield put(AuthActions.loginSuccess(loginResponse.data.data));
        yield put(AuthActions.loginSetAuthToken(loginResponse.data.data.token));

        const { otherCompanies = [], differentCompaniesPayload = [] } =
          loginResponse.data.data;
        if (
          differentCompaniesPayload.length > 0 &&
          loginResponse.data.data.isSuper === true
        ) {
          const payload = differentCompaniesPayload[0];
          payload["meta"] = JSON.parse(
            JSON.stringify(differentCompaniesPayload),
          );

          yield put(AuthActions.loginSuccess(payload));
          setAuthToken(api, { token: payload.token });

          yield put(push("/companies"));
        } else {
          if (Array.isArray(otherCompanies) && otherCompanies.length > 1) {
            // @ts-expect-error TS2322
            window.location = "/user-companies/";
          } else {
            if (differentCompaniesPayload.length === 1) {
              const payload = differentCompaniesPayload[0];
              const baseUrl = `${
                process.env.REACT_APP_BASEHOST
              }companies/${getObjUUID(payload.company)}/`;
              setAuthToken(api, { token: payload.token });
              yield put(AuthActions.loginSuccess(payload));
              setBaseUrl(api, { url: baseUrl });
              yield call(
                api.putRequest,
                parseEndpoint(endpoints.prepareCompanyData),
                {},
              );
              window.location = data.redirect ? data.redirect : "/dashboard";
            }
          }
        }
        break;
      }
      case "fail":
      case "error":
        // @ts-expect-error TS2554
        yield put(AuthActions.loginFailure(loginResponse.data.data));
        break;
      default:
        break;
    }
  } else {
    notification.error({
      message: loginResponse.problem,
    });
    // @ts-expect-error TS2554
    yield put(AuthActions.loginFailure(loginResponse.problem));
  }
}

// @ts-expect-error TS7006
function* attemptLoginByIp(api, action) {
  const { data } = action;

  // @ts-expect-error TS7057
  const loginResponse = yield call(
    api.postRequest,
    parseEndpoint(endpoints.loginViaIP),
    data,
  );

  if (loginResponse.ok) {
    switch (loginResponse.data.status) {
      case "success": {
        yield put(AuthActions.loginSuccess(loginResponse.data.data));
        yield put(AuthActions.loginSetAuthToken(loginResponse.data.data.token));
        setAuthToken(api, { token: loginResponse.data.data.token });
        const baseUrl = `${
          process.env.REACT_APP_BASEHOST
        }companies/${getObjUUID(loginResponse.data.data.company)}/`;
        setBaseUrl(api, { url: baseUrl });
        window.location = data.redirect ? data.redirect : "/dashboard";

        break;
      }
      case "fail":
      case "error":
        if (typeof loginResponse.data.data === "string") {
        }

        // @ts-expect-error TS2554
        yield put(AuthActions.loginFailure(loginResponse.data.data));
        break;
      default:
        break;
    }
  } else {
    notification.error({
      message: loginResponse.problem,
    });
    // @ts-expect-error TS2554
    yield put(AuthActions.loginFailure(loginResponse.problem));
  }
}

// @ts-expect-error TS7006
function setAuthToken(api, { token }) {
  api.setHeaderBearerToken("Authorization", `Bearer ${token}`);
}
// @ts-expect-error TS7006
function setBaseUrl(api, { url }) {
  api.setAlphaBaseUrl(url);
}

// @ts-expect-error TS7006
function* authSaga(api) {
  yield all([
    takeLatest(AuthTypes.LOGIN_REQUEST, attemptLogin, api),
    // @ts-expect-error TS2345
    takeLatest(AuthTypes.LOGIN_SET_AUTH_TOKEN, setAuthToken, api),
    // @ts-expect-error TS2345
    takeLatest(AuthTypes.LOGIN_SET_BASE_URL, setBaseUrl, api),
    takeLatest(AuthTypes.FORGOT_PASSWORD_REQUEST, attemptForgot, api),
    takeLatest(AuthTypes.RESET_PASSWORD_REQUEST, attemptReset, api),
    takeLatest(AuthTypes.LOGIN_BY_IP_REQUEST, attemptLoginByIp, api),
    takeLatest(AuthTypes.LOGOUT, logUserOut, api),
  ]);
}

export default authSaga;
