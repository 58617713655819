import { FC, PropsWithChildren } from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import CustomLoader from "@/Components/atoms/CustomLoader";
import { ContextProvider } from "@/Provider";
import { Persistor, Store } from "@/Redux/types";

export type ReduxContextProvidersProps = {
  store: Store;
  persistor: Persistor;
};

export const ReduxContextProviders: FC<
  PropsWithChildren<ReduxContextProvidersProps>
> = ({ store, persistor, children }) => (
  <Provider store={store}>
    <PersistGate loading={<CustomLoader />} persistor={persistor}>
      <ContextProvider>{children}</ContextProvider>
    </PersistGate>
  </Provider>
);
