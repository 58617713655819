import { memo } from "react";

import styles from "@/features/projectComment/component/ThreadEditButton/styles.module.scss";
import { ThreadEditButtonProps } from "@/features/projectComment/component/ThreadEditButton/type";
import { EditIconButton } from "@/shared/buttons/components/EditIconButton";

export const ThreadEditButton = memo(
  ({ thread, onEdit }: ThreadEditButtonProps) => {
    return (
      <EditIconButton
        id={`thread-edit-${thread.id}`}
        alt="thread edit icon"
        className={styles.thread_edit_button}
        onClick={() => {
          onEdit(thread);
        }}
      />
    );
  },
);
