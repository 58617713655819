import { Action } from "@reduxjs/toolkit";
import { createActions, createReducer } from "reduxsauce";
import Immutable from "seamless-immutable";

import { ToAction, ToActionType } from "./types";

/* ------------- Types and Action Creators ------------- */

type IndustrySubCatActionCreators = {
  industrySubCategoryUpdateRequest: () => Action<"INDUSTRY_SUB_CATEGORY_UPDATE_REQUEST">;
  industrySubCategoryUpdateSuccess: () => Action<"INDUSTRY_SUB_CATEGORY_UPDATE_SUCCESS">;
  industrySubCategoryUpdateFailure: () => Action<"INDUSTRY_SUB_CATEGORY_UPDATE_FAILURE">;

  industrySubCategoryDeleteRequest: () => Action<"INDUSTRY_SUB_CATEGORY_DELETE_REQUEST">;
  industrySubCategoryDeleteSuccess: () => Action<"INDUSTRY_SUB_CATEGORY_DELETE_SUCCESS">;
  industrySubCategoryDeleteFailure: () => Action<"INDUSTRY_SUB_CATEGORY_DELETE_FAILURE">;

  industrySubCategoryCreateRequest: () => Action<"INDUSTRY_SUB_CATEGORY_CREATE_REQUEST">;
  industrySubCategoryCreateSuccess: () => Action<"INDUSTRY_SUB_CATEGORY_CREATE_SUCCESS">;
  industrySubCategoryCreateFailure: () => Action<"INDUSTRY_SUB_CATEGORY_CREATE_FAILURE">;

  industrySubCategoryGetRequest: () => Action<"INDUSTRY_SUB_CATEGORY_GET_REQUEST">;
  industrySubCategoryGetSuccess: () => Action<"INDUSTRY_SUB_CATEGORY_GET_SUCCESS">;
  industrySubCategoryGetFailure: () => Action<"INDUSTRY_SUB_CATEGORY_GET_FAILURE">;
};
type IndustrySubCatAction = ToAction<IndustrySubCatActionCreators>;
type IndustrySubCatActionType = ToActionType<IndustrySubCatAction>;

const { Types, Creators } = createActions<
  IndustrySubCatActionType,
  IndustrySubCatActionCreators
>({
  industrySubCategoryUpdateRequest: ["data"],
  industrySubCategoryUpdateSuccess: null,
  industrySubCategoryUpdateFailure: ["errors"],

  industrySubCategoryDeleteRequest: ["data"],
  industrySubCategoryDeleteSuccess: null,
  industrySubCategoryDeleteFailure: ["errors"],

  industrySubCategoryCreateRequest: ["data"],
  industrySubCategoryCreateSuccess: null,
  industrySubCategoryCreateFailure: ["errors"],

  industrySubCategoryGetRequest: ["data"],
  industrySubCategoryGetSuccess: ["industrySubCategoryPayload"],
  industrySubCategoryGetFailure: ["errors"],
  /// update nav header in the case of industrySubCategory editor open
});

export const IndustrySubCategoryTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
type IndustrySubCatState = {
  fetching: boolean;
  errors: unknown;
  industrySubCategoryPayload: {
    rows: unknown[];
    count: number;
  };
};

export const INITIAL_STATE = Immutable<IndustrySubCatState>({
  fetching: false,
  errors: {},
  industrySubCategoryPayload: {
    rows: [],
    count: 0,
  },
});
type ImmIndustrySubCatState = typeof INITIAL_STATE;

/* ------------- Reducers ------------- */

const industrySubCategoryCreateRequest = (state: ImmIndustrySubCatState) =>
  state.merge({ fetching: true });

const industrySubCategoryCreateSuccess = (state: ImmIndustrySubCatState) => {
  return state.merge({ fetching: false });
};

const industrySubCategoryCreateFailure = (
  state: ImmIndustrySubCatState,
  // @ts-expect-error TS7031
  { errors },
) => state.merge({ fetching: false, errors });

const industrySubCategoryDeleteRequest = (state: ImmIndustrySubCatState) =>
  state.merge({ fetching: true });

const industrySubCategoryDeleteSuccess = (state: ImmIndustrySubCatState) => {
  return state.merge({ fetching: false });
};

const industrySubCategoryDeleteFailure = (
  state: ImmIndustrySubCatState,
  // @ts-expect-error TS7031
  { errors },
) => state.merge({ fetching: false, errors });

const industrySubCategoryUpdateRequest = (state: ImmIndustrySubCatState) =>
  state.merge({ fetching: true });

const industrySubCategoryUpdateSuccess = (state: ImmIndustrySubCatState) => {
  return state.merge({ fetching: false });
};

const industrySubCategoryUpdateFailure = (
  state: ImmIndustrySubCatState,
  // @ts-expect-error TS7031
  { errors },
) => state.merge({ fetching: false, errors });

const industrySubCategoryGetRequest = (state: ImmIndustrySubCatState) =>
  state.merge({ fetching: true });

const industrySubCategoryGetSuccess = (
  state: ImmIndustrySubCatState,
  // @ts-expect-error TS7031
  { industrySubCategoryPayload },
) => {
  return state.merge({ fetching: false, industrySubCategoryPayload });
};

const industrySubCategoryGetFailure = (
  state: ImmIndustrySubCatState,
  // @ts-expect-error TS7031
  { errors },
) => state.merge({ fetching: false, errors });

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer<
  ImmIndustrySubCatState,
  IndustrySubCatAction
>(INITIAL_STATE, {
  [Types.INDUSTRY_SUB_CATEGORY_CREATE_REQUEST]:
    industrySubCategoryCreateRequest,
  [Types.INDUSTRY_SUB_CATEGORY_CREATE_SUCCESS]:
    industrySubCategoryCreateSuccess,
  [Types.INDUSTRY_SUB_CATEGORY_CREATE_FAILURE]:
    industrySubCategoryCreateFailure,

  [Types.INDUSTRY_SUB_CATEGORY_DELETE_REQUEST]:
    industrySubCategoryDeleteRequest,
  [Types.INDUSTRY_SUB_CATEGORY_DELETE_SUCCESS]:
    industrySubCategoryDeleteSuccess,
  [Types.INDUSTRY_SUB_CATEGORY_DELETE_FAILURE]:
    industrySubCategoryDeleteFailure,

  [Types.INDUSTRY_SUB_CATEGORY_GET_REQUEST]: industrySubCategoryGetRequest,
  [Types.INDUSTRY_SUB_CATEGORY_GET_SUCCESS]: industrySubCategoryGetSuccess,
  [Types.INDUSTRY_SUB_CATEGORY_GET_FAILURE]: industrySubCategoryGetFailure,

  [Types.INDUSTRY_SUB_CATEGORY_UPDATE_REQUEST]:
    industrySubCategoryUpdateRequest,
  [Types.INDUSTRY_SUB_CATEGORY_UPDATE_SUCCESS]:
    industrySubCategoryUpdateSuccess,
  [Types.INDUSTRY_SUB_CATEGORY_UPDATE_FAILURE]:
    industrySubCategoryUpdateFailure,
});
