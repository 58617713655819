import { notification } from "antd";
import { saveAs } from "file-saver";
import qs from "qs";

import { api } from "@/Saga";
import { BaseErrorResponse } from "@/Services/commonResponsTypes";
import { endpoints, parseEndpoint } from "@/Services/endpoints";

import { ExcelExportProps } from "./type";

export const excelExport = async (
  { batchId, categoryId, examinationPhaseId, results }: ExcelExportProps,
  fileName: string,
) => {
  const url = parseEndpoint(endpoints.submissionExcelExport);
  const response = await api.getRequest<ArrayBuffer, BaseErrorResponse>(
    url,
    { batchId, categoryId, results, examinationPhaseId },
    {
      responseType: "arraybuffer",
      paramsSerializer: (params) => qs.stringify(params),
    },
  );

  if (!(response.data instanceof ArrayBuffer)) return;
  if (response.ok) {
    const blob = new Blob([response.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, fileName);
  } else {
    const json: BaseErrorResponse = JSON.parse(
      new TextDecoder().decode(response.data),
    );

    notification.error({
      message: json.message,
    });
  }
};
