import { EditorState } from "draft-js";

import { DraftEditor } from "@/Components/molecules";
import { sanitizeEditorStateMarkupHtml } from "@/utils/common";
import { convertFromRawWithTrimmer } from "@/utils/common";

import { RenderDraftJsTextProps } from "./type";

export const RenderDraftJsText = ({ text }: RenderDraftJsTextProps) => {
  return (
    <DraftEditor
      toolbarHidden={true}
      readOnly={true}
      editorState={sanitizeEditorStateMarkupHtml(
        EditorState.createWithContent(convertFromRawWithTrimmer(text)),
      )}
    />
  );
};
