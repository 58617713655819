import { notification } from "antd";
import { all, call, put, takeLatest } from "redux-saga/effects";

import NewsCommentActions, {
  NewsCommentTypes,
} from "../Redux/NewsCommentRedux";
import { endpoints, parseEndpoint } from "../Services/endpoints";

// @ts-expect-error TS7006
function* attemptDeleteComment(api, action) {
  const { data, message } = action;

  // @ts-expect-error TS7057
  const commentResponse = yield call(
    api.deleteRequest,
    parseEndpoint(endpoints.deleteComment, { data }),
  );

  if (commentResponse.ok) {
    switch (commentResponse.data.status) {
      case "success":
        yield put(
          // @ts-expect-error TS2554
          NewsCommentActions.newsCommentGetsRequest({
            id: data.id,
            page: 0,
          }),
        );
        yield put(NewsCommentActions.newsCommentDeleteSuccess());
        notification.success({
          message: message,
        });

        break;
      case "fail":
      case "error":
        if (typeof commentResponse.data.data === "string") {
        }
        yield put(
          NewsCommentActions.newsCommentDeleteFailure(
            // @ts-expect-error TS2554
            commentResponse.data.data,
          ),
        );
        break;
      default:
        break;
    }
  } else {
    notification.error({
      message: commentResponse.problem,
    });
    yield put(
      // @ts-expect-error TS2554
      NewsCommentActions.newsCommentDeleteFailure(commentResponse.problem),
    );
    if (commentResponse.status === 401) {
      // @ts-expect-error TS2322
      window.location = "/login";
    }
  }
}

// @ts-expect-error TS7006
function* attemptUpdateComment(api, action) {
  const { data, message } = action;

  // @ts-expect-error TS7057
  const commentResponse = yield call(
    api.putRequest,
    parseEndpoint(endpoints.updateNewsComment, { data }),
    data,
  );

  if (commentResponse.ok) {
    switch (commentResponse.data.status) {
      case "success":
        yield put(
          // @ts-expect-error TS2554
          NewsCommentActions.newsCommentGetsRequest({
            projectId: data.projectId,
            page: 0,
          }),
        );
        // @ts-expect-error TS2551
        yield put(NewsCommentActions.commentUpdateSuccess());
        notification.success({
          message: message,
        });

        break;
      case "fail":
      case "error":
        if (typeof commentResponse.data.data === "string") {
        }
        yield put(
          // @ts-expect-error TS2551
          NewsCommentActions.commentUpdateFailure(commentResponse.data.data),
        );
        break;
      default:
        break;
    }
  } else {
    notification.error({
      message: commentResponse.problem,
    });
    // @ts-expect-error TS2551
    yield put(NewsCommentActions.commentUpdateFailure(commentResponse.problem));
    if (commentResponse.status === 401) {
      // @ts-expect-error TS2322
      window.location = "/login";
    }
  }
}

// @ts-expect-error TS7006
function* attemptCreateComment(api, action) {
  const { data, message } = action;

  // @ts-expect-error TS7057
  const commentResponse = yield call(
    api.postRequest,
    // @ts-expect-error TS2554
    parseEndpoint(endpoints.createNewsComment),
    data,
  );

  if (commentResponse.ok) {
    switch (commentResponse.data.status) {
      case "success":
        yield put(
          // @ts-expect-error TS2554
          NewsCommentActions.newsCommentGetsRequest({
            id: data.id,
            page: 0,
          }),
        );
        // @ts-expect-error TS2551
        yield put(NewsCommentActions.commentCreateSuccess());
        notification.success({
          message: message,
        });

        break;
      case "fail":
      case "error":
        if (typeof commentResponse.data.data === "string") {
        }
        yield put(
          // @ts-expect-error TS2551
          NewsCommentActions.commentCreateFailure(commentResponse.data.data),
        );
        break;
      default:
        break;
    }
  } else {
    notification.error({
      message: commentResponse.problem,
    });
    // @ts-expect-error TS2551
    yield put(NewsCommentActions.commentCreateFailure(commentResponse.problem));
    if (commentResponse.status === 401) {
      // @ts-expect-error TS2322
      window.location = "/login";
    }
  }
}

// @ts-expect-error TS7006
function* attemptGetComments(api, action) {
  const { data } = action;
  // @ts-expect-error TS7057
  const commentResponse = yield call(
    api.getRequest,
    parseEndpoint(endpoints.getNewsComments, { data }),
  );

  if (commentResponse.ok) {
    switch (commentResponse.data.status) {
      case "success":
        yield put(
          // @ts-expect-error TS2554
          NewsCommentActions.newsCommentGetsSuccess(commentResponse.data.data),
        );
        break;
      case "fail":
      case "error":
        if (typeof commentResponse.data.data === "string") {
        }
        yield put(
          // @ts-expect-error TS2554
          NewsCommentActions.newsCommentGetsFailure(commentResponse.data.data),
        );
        break;
      default:
        break;
    }
  } else {
    notification.error({
      message: commentResponse.problem,
    });
    yield put(
      // @ts-expect-error TS2554
      NewsCommentActions.newsCommentGetsFailure(commentResponse.problem),
    );
    if (commentResponse.status === 401) {
      // @ts-expect-error TS2322
      window.location = "/login";
    }
  }
}

// @ts-expect-error TS7006
function* newsCommentSaga(api) {
  yield all([
    takeLatest(
      NewsCommentTypes.NEWS_COMMENT_CREATE_REQUEST,
      attemptCreateComment,
      api,
    ),
    takeLatest(
      NewsCommentTypes.NEWS_COMMENT_UPDATE_REQUEST,
      attemptUpdateComment,
      api,
    ),
    takeLatest(
      NewsCommentTypes.NEWS_COMMENT_DELETE_REQUEST,
      attemptDeleteComment,
      api,
    ),
    takeLatest(
      NewsCommentTypes.NEWS_COMMENT_GETS_REQUEST,
      attemptGetComments,
      api,
    ),
  ]);
}

export default newsCommentSaga;
