import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Dispatch } from "@reduxjs/toolkit";
import { Button, Card, Col, Form, Layout, Modal, Row, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Immutable from "seamless-immutable";

import { Company } from "@/../types/Company";
import { User } from "@/../types/User";
import { ButtonCV, IconDelete } from "@/Components/atoms";
import { Input, Option, Select } from "@/Components/molecules";
import {
  CompanyContent,
  CompanyLayout,
  NavbarUser,
} from "@/Components/organisms";
import { CompanyDeleteModal } from "@/features/company/components/CompanyDeleteModal";
import { IpRangeSettingModal } from "@/features/company/components/IpRangeSettingModal";
import { I18NContext } from "@/i18n/context";
import { DispatchApiRequestData } from "@/Provider/type";
import AuthActions from "@/Redux/AuthRedux";
import CompanyActions from "@/Redux/CompanyRedux";
import { endpoints, parseEndpoint } from "@/Services/endpoints";
import { SecondaryButton } from "@/shared/buttons/components/SecondaryButton";
import { getObjUUID } from "@/utils/common";
import { appName } from "@/utils/constants";

import {
  Companies,
  CompaniesScreenState,
  ICompaniesScreenProps,
  IDispatchProps,
} from "./types/ICompaniesScreenProps";

const { Header, Footer } = Layout;
const { confirm } = Modal;

const CompaniesScreen: React.FC<ICompaniesScreenProps> = (props) => {
  const intl = useIntl();
  const { selectLanguage } = React.useContext(I18NContext);
  const { user, apiRequest } = props.context;
  const [state, _setState] = useState<CompaniesScreenState>({
    isCreateCompanyModalVisible: false,
    isDeleteCompanyModalVisible: false,
    isIpSettingModalVisible: false,
    editingContractLicenseNumber: null,
    name: "",
    currentPlan: "",
    emailAllowed: true,
    userMeta: [],
    contractLicense: 0,
    editContractLicense: 0,
    isSyncDelayActive: false,
    targetCompanyToDelete: null,
    targetCompanyToIpSetting: null,
  });

  const setState = (newState: Partial<CompaniesScreenState>) =>
    _setState((prevState) => ({ ...prevState, ...newState }));

  // Fetch companies data from /companies.
  const getCompanies = useCallback(
    (message?: string) => {
      const data: DispatchApiRequestData = {
        method: "getRequest",
        url: parseEndpoint(endpoints.companies),
        successMessage: message,
        data: {},
      };
      apiRequest<Companies>(data, ({ data }) =>
        props.dispatchSetCompanies(data),
      );
    },
    [apiRequest, props],
  );

  // Runs componentDidMount.
  // It sets auth token, base URL, companies from user meta.
  // If the user is a superuser, it fetches companies from the API.
  // It also sets the selected language to 'ja'
  // Stores the current route in localStorage.
  useEffect(() => {
    props.dispatchSetAuthToken(user?.token ?? "");
    props.dispatchSetBaseUrl?.(`${process.env.REACT_APP_BASEHOST}`);
    if (user?.meta && user.meta.length > 0) {
      setState({ userMeta: user.meta });
    }
    if (user?.isSuper) {
      getCompanies(
        intl.formatMessage({ id: "screen.label.acquired_company_list" }),
      );
    }
    selectLanguage("ja");
    localStorage.setItem("currentRoute", props.location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handlers
  const handleChangeCompanyPlan = (value: string, id: string) => {
    const data: DispatchApiRequestData = {
      method: "putRequest",
      url: parseEndpoint(endpoints.singleCompany, { companyId: id }),
      successMessage: intl.formatMessage({
        id: "screen.label.plan_has_been_updated",
      }),
      data: { currentPlan: value.toUpperCase() },
    };
    apiRequest(data, () => getCompanies());
  };

  const handleChangeEmailPlan = (value: string, id: string) => {
    const data: DispatchApiRequestData = {
      method: "putRequest",
      url: parseEndpoint(endpoints.singleCompany, { companyId: id }),
      successMessage: intl.formatMessage({
        id: "screen.label.plan_has_been_updated",
      }),
      data: { emailAllowed: value },
    };
    apiRequest(data);
  };

  const prepareDataForCompany = (payload: User) => {
    const data: DispatchApiRequestData = {
      method: "putRequest",
      url: parseEndpoint(endpoints.prepareCompanyData),
      data: {},
    };
    apiRequest(data, () => {
      props.dispatchSetSuperUser({ ...payload, isSuper: true });
      props.history.push("/dashboard");
    });
  };

  const showConfirmForChangeDepartmentInputType = (cbAction: () => void) => {
    confirm({
      title: intl.formatMessage({
        id: "screen.label.department_setting_will_be_reset",
      }),
      icon: <ExclamationCircleOutlined />,
      onOk: cbAction,
      okText: intl.formatMessage({ id: "screen.label.yes" }),
      cancelText: intl.formatMessage({ id: "screen.label.no" }),
    });
  };

  const handleChangeCompanyDepartment = (value: string, id: string) => {
    const data: DispatchApiRequestData = {
      method: "putRequest",
      url: parseEndpoint(endpoints.singleCompany, { companyId: id }),
      successMessage: intl.formatMessage({
        id: "screen.label.plan_has_been_updated",
      }),
      data: { selectedInputType: value },
    };
    apiRequest(data, () => getCompanies());
  };

  const updateCompanyDetail = (
    requestBody: { contractLicense: number },
    id: string,
  ) => {
    const data: DispatchApiRequestData = {
      method: "putRequest",
      url: parseEndpoint(endpoints.singleCompany, { companyId: id }),
      successMessage: intl.formatMessage({
        id: "screen.label.plan_has_been_updated",
      }),
      data: requestBody,
    };
    apiRequest(data, () => getCompanies());
  };

  const gotoDashboard = (company: Company) => {
    const data = Immutable.asMutable(user, { deep: true });
    if (!data) return;
    const companyId = getObjUUID(company);
    data.company = company;
    data.companyId = companyId;
    const baseUrl = `${process.env.REACT_APP_BASEHOST}companies/${companyId}/`;
    props.dispatchSetBaseUrl(baseUrl);

    const matchedUser = state.userMeta.find(
      ({ company }) => getObjUUID(company) === companyId,
    );
    if (matchedUser) {
      props.dispatchSetSuperUser({ ...matchedUser, isSuper: true });
      props.dispatchSetAuthToken(matchedUser.token);
      prepareDataForCompany(matchedUser);
    }
  };

  const onCreateCompanyModalCancel = () =>
    setState({ isCreateCompanyModalVisible: false });

  const deleteBtnHandler = (company: Company) =>
    setState({
      isDeleteCompanyModalVisible: true,
      targetCompanyToDelete: company,
    });

  const onCreateCompanyModalOk = () => {
    const { name, emailAllowed, currentPlan, contractLicense } = state;
    const requestBuilder: DispatchApiRequestData = {
      method: "postRequest",
      url: parseEndpoint(endpoints.companies),
      data: { name, emailAllowed, currentPlan, contractLicense },
      successMessage: intl.formatMessage({
        id: "screen.label.company_has_been_added",
      }),
    };
    apiRequest(requestBuilder, () => getCompanies());
    onCreateCompanyModalCancel();
  };

  const columns: ColumnsType<Company> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      responsive: ["md"],
    },
    {
      title: intl.formatMessage({
        id: "screen.label.contract_companies",
      }),
      dataIndex: "name",
      key: "name",
      render: (text, row) => (
        <Button type="link" onClick={() => gotoDashboard(row)}>
          {text}
        </Button>
      ),
    },
    {
      title: intl.formatMessage({ id: "screen.label.created_date" }),
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => (
        <span>{moment(text).local().format("YYYY/MM/DD")}</span>
      ),
    },
    {
      title: intl.formatMessage({ id: "screen.label.email" }),
      dataIndex: "emailAllowed",
      key: "emailAllowed",
      render: (text, row) => (
        <Select
          defaultValue={text}
          style={{ width: "8vw" }}
          onChange={(value) => handleChangeEmailPlan(value, getObjUUID(row))}
        >
          <Option value={true}>Allowed</Option>
          <Option value={false}>Blocked</Option>
        </Select>
      ),
      responsive: ["md"],
    },
    {
      title: intl.formatMessage({
        id: "screen.label.plan",
      }),
      dataIndex: "currentPlan",
      key: "currentPlan",
      render: (text, row) => (
        <Select
          defaultValue={text}
          style={{ width: "10vw" }}
          onChange={(value) => handleChangeCompanyPlan(value, getObjUUID(row))}
        >
          <Option value={"DORMANT"}>Dormant Plan</Option>
          <Option value={"BASIC"}>Basic Plan</Option>
          <Option value={"PRO"}>Pro Plan</Option>
          <Option value={"CLOSE"}>Close Plan</Option>
        </Select>
      ),
      responsive: ["md"],
    },
    {
      title: intl.formatMessage({
        id: "screen.label.business_unit_settings",
      }),
      dataIndex: "selectedInputType",
      key: "selectedInputType",
      render: (text, row) => (
        <Select
          defaultValue={text}
          style={{ width: "9vw" }}
          value={row.selectedInputType}
          onChange={(value) => {
            if (value === "input") {
              handleChangeCompanyDepartment(value, getObjUUID(row));
            } else {
              showConfirmForChangeDepartmentInputType(() => {
                handleChangeCompanyDepartment(value, getObjUUID(row));
              });
            }
          }}
        >
          <Option value={"input"}>
            <FormattedMessage id="screen.label.free_input" />
          </Option>
          <Option value={"dropdown"}>
            <FormattedMessage id="screen.label.master_configuration" />
          </Option>
        </Select>
      ),
      responsive: ["md"],
    },
    {
      title: "Stats",
      dataIndex: "stats",
      key: "status",
      render: (_, row) => (
        <>
          <span>
            {" "}
            {row.totalStoppedUsers}
            <FormattedMessage id="screen.label.stop" />
          </span>
          <span>
            {" "}
            {row.totalDeletedUsers}
            <FormattedMessage id="screen.label.delete" />
          </span>
        </>
      ),
      responsive: ["md"],
    },
    user.isSuper
      ? {
          title: <FormattedMessage id="screen.label.ip_address_limit" />,
          dataIndex: "ipSetting",
          render: (_, row) => (
            <SecondaryButton
              onClick={() =>
                setState({
                  isIpSettingModalVisible: true,
                  targetCompanyToIpSetting: row,
                })
              }
            >
              <FormattedMessage id="screen.label.editing" />
            </SecondaryButton>
          ),
        }
      : {},
    {
      title: intl.formatMessage({
        id: "screen.label.active_id_number",
      }),
      dataIndex: "activeNumber",
      key: "activeNumber",
      render: (_, row) => (
        <>{row.totalAvailableUsers + row.totalStoppedUsers}</>
      ),
      responsive: ["md"],
    },
    {
      title: intl.formatMessage({
        id: "screen.label.contract_id_number",
      }),
      dataIndex: "contractLicense",
      key: "contractLicense",
      render: (_, row) =>
        state.editingContractLicenseNumber === row.id ? (
          <Input
            style={{ width: "6vw" }}
            onBlur={() => {
              setState({ editingContractLicenseNumber: null });
              updateCompanyDetail(
                { contractLicense: state.editContractLicense },
                getObjUUID(row),
              );
            }}
            onPressEnter={() => {
              setState({ editingContractLicenseNumber: null });
              updateCompanyDetail(
                { contractLicense: state.editContractLicense },
                getObjUUID(row),
              );
            }}
            value={state.editContractLicense}
            onChange={(event) => {
              const value = Number(event.target.value);
              setState({
                editContractLicense: isNaN(value)
                  ? state.contractLicense
                  : value,
              });
            }}
          />
        ) : (
          <p
            onDoubleClick={() =>
              setState({
                editingContractLicenseNumber: row.id,
                editContractLicense: row.contractLicense,
              })
            }
            style={{
              color:
                row.totalAvailableUsers + row.totalStoppedUsers >
                row.contractLicense
                  ? "red"
                  : "green",
            }}
          >
            {row.contractLicense}
          </p>
        ),
      responsive: ["md"],
    },
    {
      title: "",
      dataIndex: "deleteButton",
      key: "deleteButton",
      render: (_text: string, company: Company) => {
        if (company.currentPlan !== "CLOSE") return;

        return (
          <Button onClick={deleteBtnHandler.bind(null, company)}>
            {IconDelete}
          </Button>
        );
      },
    },
  ];

  return (
    <CompanyLayout>
      <Layout>
        <Header>
          <NavbarUser
            pageTitle={""}
            userDetails={user}
            logoutUser={props.dispatchLogoutUser}
            showSwitchLang={false}
          />
        </Header>
        <CompanyContent>
          <Row align="middle" justify="space-between">
            <Col>
              <h2>
                <FormattedMessage id="screen.label.contract_number_companies" />
                {` ${props.companies?.count}`}
              </h2>
            </Col>
            <Col>
              <ButtonCV
                className="offset-right-16"
                style={{
                  backgroundColor: "#5a3495",
                }}
                onClick={() =>
                  setState({
                    isCreateCompanyModalVisible: true,
                  })
                }
              >
                + <FormattedMessage id="screen.label.additional_company" />
              </ButtonCV>
              <Link to={"/global"}>
                <ButtonCV
                  className="offset-right-16"
                  style={{
                    backgroundColor: "#00bf76",
                  }}
                >
                  <FormattedMessage id="screen.label.global_management" />
                </ButtonCV>
              </Link>
            </Col>
          </Row>
          <br />

          <Card>
            <Table
              pagination={false}
              rowKey={"id"}
              columns={columns}
              dataSource={props.companies?.rows}
              className="ant-table-x-scroll"
            />
          </Card>

          <Modal
            title={intl.formatMessage({
              id: "screen.label.created_company",
            })}
            visible={state.isCreateCompanyModalVisible}
            onOk={onCreateCompanyModalOk}
            onCancel={onCreateCompanyModalCancel}
            cancelText={intl.formatMessage({
              id: "screen.label.cancel",
            })}
          >
            <Form layout={"vertical"}>
              <Form.Item
                label={intl.formatMessage({
                  id: "screen.label.contract_companies",
                })}
                name="contract_companies"
              >
                <Input
                  onChange={(event) => setState({ name: event.target.value })}
                />
              </Form.Item>

              <Form.Item
                label={intl.formatMessage({
                  id: "screen.label.email",
                })}
                name="email_allowed"
              >
                <Select
                  onChange={(value: boolean) =>
                    setState({ emailAllowed: value })
                  }
                >
                  <Option value={true}>Allowed</Option>
                  <Option value={false}>Blocked</Option>
                </Select>
              </Form.Item>

              <Form.Item
                label={intl.formatMessage({
                  id: "screen.label.plan",
                })}
                name="plan"
              >
                <Select
                  onChange={(value: string) => setState({ currentPlan: value })}
                >
                  <Option value={"DORMANT"}>Dormant Plan</Option>
                  <Option value={"BASIC"}>Basic Plan</Option>
                  <Option value={"PRO"}>Pro Plan</Option>
                  <Option value={"CLOSE"}>Close Plan</Option>
                </Select>
              </Form.Item>

              <Form.Item
                label={intl.formatMessage({
                  id: "screen.label.contract_id_number",
                })}
                name="contract_id_number"
              >
                <Input
                  value={state.contractLicense}
                  onChange={(event) => {
                    const value = Number(event.target.value);

                    setState({
                      contractLicense: isNaN(value)
                        ? state.contractLicense
                        : value,
                    });
                  }}
                />
              </Form.Item>
            </Form>
          </Modal>
          {state.targetCompanyToIpSetting && (
            <IpRangeSettingModal
              visible={state.isIpSettingModalVisible}
              uuid={state.targetCompanyToIpSetting.uuid}
              name={state.targetCompanyToIpSetting.name}
              handleClose={() => {
                setState({ isIpSettingModalVisible: false });
              }}
            />
          )}

          {state.targetCompanyToDelete && (
            <CompanyDeleteModal
              company={state.targetCompanyToDelete}
              isVisible={state.isDeleteCompanyModalVisible}
              hideModal={() => {
                setState({
                  isDeleteCompanyModalVisible: false,
                });
                getCompanies();
              }}
            />
          )}
        </CompanyContent>

        <Footer
          style={{ textAlign: "center" }}
        >{`Copyright© ${appName} Produced by Alphadrive Co.,Ltd. All rights reserved.`}</Footer>
      </Layout>
    </CompanyLayout>
  );
};

const mapStateToProps = (state: {
  company: { companies: ICompaniesScreenProps["companies"] };
}) => ({ companies: state.company.companies });

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => ({
  dispatchSetBaseUrl: (url) => dispatch(AuthActions.loginSetBaseUrl(url)),
  dispatchLogoutUser: () => dispatch(AuthActions.logout()),
  dispatchSetAuthToken: (token) =>
    dispatch(AuthActions.loginSetAuthToken(token)),
  dispatchSetCompanies: (companies) =>
    dispatch(CompanyActions.companySuccess(companies)),
  dispatchSetSuperUser: (data) => dispatch(AuthActions.loginSuccess(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(CompaniesScreen);
