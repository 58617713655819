import { Action } from "@reduxjs/toolkit";
import { createActions, createReducer } from "reduxsauce";
import Immutable from "seamless-immutable";

import { Batch } from "@/../types/Batch";
import { Team } from "@/../types/Team";

import { ToAction, ToActionType } from "./types";

/* ------------- Types and Action Creators ------------- */

export const sampleSingleProjectPayload = {
  id: 0,
  name: "",
  description: "",
  hypothesis: "",
  hypothesisSolution: "",
  problem: "",
  customer: "",
  uniqueValueProposition: "",
  solution: "",
  channel: "",
  whyYou: "",
  isLiked: false,
  likeCount: 0,
  revenueStructure: "",
  marketability: "",
  marketChannel: "",
  advantage: "",
  stage: "",
  milestone: "",
  type: "",
  icon: "",
  isTeamMember: false,
  isProjectOwner: false,
  isPrivate: true,
  displayCheckpoint: true,
  createdAt: new Date(),
  Tag: [],
  projectTags: [],
  Team: [] as Team[],
  Comment: [],
  User: {
    name: "",
  },
  user: {
    id: 0,
    type: null,
    isSuper: false,
  },
  Company: {
    stages: [],
    milestones: [],
    milestonePairs: [],
    projectSubmissionTypes: [],
    currentPlan: "",
  },
};

type ProjectActionCreators = {
  projectCreateRequest: () => Action<"PROJECT_CREATE_REQUEST">;
  projectCreateSuccess: () => Action<"PROJECT_CREATE_SUCCESS">;
  projectCreateFailure: () => Action<"PROJECT_CREATE_FAILURE">;

  projectGetsRequest: () => Action<"PROJECT_GETS_REQUEST">;
  projectGetsSuccess: () => Action<"PROJECT_GETS_SUCCESS">;
  projectGetsFailure: () => Action<"PROJECT_GETS_FAILURE">;

  projectCurrentMemberGetsRequest: () => Action<"PROJECT_CURRENT_MEMBER_GETS_REQUEST">;
  projectCurrentMemberGetsSuccess: () => Action<"PROJECT_CURRENT_MEMBER_GETS_SUCCESS">;
  projectCurrentMemberGetsFailure: () => Action<"PROJECT_CURRENT_MEMBER_GETS_FAILURE">;

  projectGetRequest: () => Action<"PROJECT_GET_REQUEST">;
  projectGetSuccess: () => Action<"PROJECT_GET_SUCCESS">;
  projectGetFailure: () => Action<"PROJECT_GET_FAILURE">;

  projectSubmitRequest: () => Action<"PROJECT_SUBMIT_REQUEST">;
  projectSubmitSuccess: () => Action<"PROJECT_SUBMIT_SUCCESS">;
  projectSubmitFailure: () => Action<"PROJECT_SUBMIT_FAILURE">;

  projectDeleteRequest: () => Action<"PROJECT_DELETE_REQUEST">;
  projectDeleteSuccess: () => Action<"PROJECT_DELETE_SUCCESS">;
  projectDeleteFailure: () => Action<"PROJECT_DELETE_FAILURE">;

  projectTaskCreateRequest: () => Action<"PROJECT_TASK_CREATE_REQUEST">;
  projectTaskCreateSuccess: () => Action<"PROJECT_TASK_CREATE_SUCCESS">;
  projectTaskCreateFailure: () => Action<"PROJECT_TASK_CREATE_FAILURE">;

  projectTaskGetsRequest: () => Action<"PROJECT_TASK_GETS_REQUEST">;
  projectTaskGetsSuccess: () => Action<"PROJECT_TASK_GETS_SUCCESS">;
  projectTaskGetsFailure: () => Action<"PROJECT_TASK_GETS_FAILURE">;

  projectTaskUpdateRequest: () => Action<"PROJECT_TASK_UPDATE_REQUEST">;
  projectTaskUpdateSuccess: () => Action<"PROJECT_TASK_UPDATE_SUCCESS">;
  projectTaskUpdateFailure: () => Action<"PROJECT_TASK_UPDATE_FAILURE">;
};
type ProjectAction = ToAction<ProjectActionCreators>;
type ProjectActionType = ToActionType<ProjectAction>;

const { Types, Creators } = createActions<
  ProjectActionType,
  ProjectActionCreators
>({
  projectCreateRequest: ["data", "message", "resolve", "reject"],
  projectCreateSuccess: null,
  projectCreateFailure: ["errors"],

  projectGetsRequest: ["page", "limit", "filter", "sort", "tag"],
  projectGetsSuccess: ["projectsPayload"],
  projectGetsFailure: ["errors"],

  projectCurrentMemberGetsRequest: ["data"],
  projectCurrentMemberGetsSuccess: ["myProjectsPayload"],
  projectCurrentMemberGetsFailure: ["errors"],

  projectGetRequest: ["id"],
  projectGetSuccess: ["projectPayload"],
  projectGetFailure: ["errors"],

  projectSubmitRequest: ["data"],
  projectSubmitSuccess: null,
  projectSubmitFailure: ["errors"],

  projectDeleteRequest: ["id", "message", "resolve", "reject"],
  projectDeleteSuccess: null,
  projectDeleteFailure: ["errors"],

  projectTaskCreateRequest: ["data"],
  projectTaskCreateSuccess: null,
  projectTaskCreateFailure: ["errors"],

  projectTaskGetsRequest: ["id", "query"],
  projectTaskGetsSuccess: ["tasksPayload"],
  projectTaskGetsFailure: ["errors"],

  projectTaskUpdateRequest: ["id", "taskId", "update"],
  projectTaskUpdateSuccess: null,
  projectTaskUpdateFailure: ["errors"],
});

export const ProjectTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
type ProjectState = {
  fetching: boolean;
  errors: unknown;
  error?: unknown;
  projectsPayload: {
    rows: unknown[];
    count: number;
  };
  myProjectsPayload: {
    rows: unknown[];
    count: number;
  };
  tasksPayload: unknown;
  projectPayload: typeof sampleSingleProjectPayload & {
    checkpointCoverageRate?: unknown;
    Batch?: Batch;
    batchId?: string;
    milestoneDate?: unknown;
    numberOfUnreadComments?: number;
  };
};

export const INITIAL_STATE = Immutable<ProjectState>({
  fetching: false,
  errors: {},
  projectsPayload: {
    rows: [],
    count: 0,
  },
  myProjectsPayload: {
    rows: [],
    count: 0,
  },
  tasksPayload: {},
  projectPayload: sampleSingleProjectPayload,
});
type ImmProjectState = typeof INITIAL_STATE;

/* ------------- Reducers ------------- */

const projectCurrentMemberGetsRequest = (state: ImmProjectState) =>
  state.merge({ fetching: true });

const projectCurrentMemberGetsSuccess = (
  state: ImmProjectState,
  // @ts-expect-error TS7031
  { myProjectsPayload },
) => {
  return state.merge({ fetching: false, myProjectsPayload });
};

// @ts-expect-error TS7031
const projectCurrentMemberGetsFailure = (state: ImmProjectState, { error }) =>
  state.merge({ fetching: false, error });

const request = (state: ImmProjectState) => state.merge({ fetching: true });

const success = (state: ImmProjectState) => {
  return state.merge({ fetching: false });
};

// @ts-expect-error TS7031
const failure = (state: ImmProjectState, { error }) =>
  state.merge({ fetching: false, error });

const projectGetsRequest = (state: ImmProjectState) =>
  state.merge({ fetching: true });

// @ts-expect-error TS7031
const projectGetsSuccess = (state: ImmProjectState, { projectsPayload }) => {
  return state.merge({ fetching: false, projectsPayload });
};

// @ts-expect-error TS7031
const projectGetsFailure = (state: ImmProjectState, { error }) =>
  state.merge({ fetching: false, error });

const projectGetRequest = (state: ImmProjectState) =>
  state.merge({
    fetching: true,
    // @ts-expect-error TS2322
    projectPayload: sampleSingleProjectPayload,
  });

// @ts-expect-error TS7031
const projectGetSuccess = (state: ImmProjectState, { projectPayload }) => {
  return state.merge({ fetching: false, projectPayload });
};

// @ts-expect-error TS7031
const projectGetFailure = (state: ImmProjectState, { error }) =>
  state.merge({
    fetching: false,
    error,
    // @ts-expect-error TS2322
    projectPayload: sampleSingleProjectPayload,
  });

// @ts-expect-error TS7031
const projectTaskGetsSuccess = (state: ImmProjectState, { tasksPayload }) => {
  return state.merge({ fetching: false, tasksPayload });
};

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer<ImmProjectState, ProjectAction>(
  INITIAL_STATE,
  {
    [Types.PROJECT_CREATE_REQUEST]: request,
    [Types.PROJECT_CREATE_SUCCESS]: success,
    [Types.PROJECT_CREATE_FAILURE]: failure,

    [Types.PROJECT_GETS_REQUEST]: projectGetsRequest,
    [Types.PROJECT_GETS_SUCCESS]: projectGetsSuccess,
    [Types.PROJECT_GETS_FAILURE]: projectGetsFailure,

    [Types.PROJECT_GET_REQUEST]: projectGetRequest,
    [Types.PROJECT_GET_SUCCESS]: projectGetSuccess,
    [Types.PROJECT_GET_FAILURE]: projectGetFailure,

    [Types.PROJECT_CURRENT_MEMBER_GETS_REQUEST]:
      projectCurrentMemberGetsRequest,
    [Types.PROJECT_CURRENT_MEMBER_GETS_SUCCESS]:
      projectCurrentMemberGetsSuccess,
    [Types.PROJECT_CURRENT_MEMBER_GETS_FAILURE]:
      projectCurrentMemberGetsFailure,

    [Types.PROJECT_TASK_CREATE_REQUEST]: request,
    [Types.PROJECT_TASK_CREATE_SUCCESS]: success,
    [Types.PROJECT_TASK_CREATE_FAILURE]: failure,

    [Types.PROJECT_TASK_UPDATE_REQUEST]: request,
    [Types.PROJECT_TASK_UPDATE_SUCCESS]: success,
    [Types.PROJECT_TASK_UPDATE_FAILURE]: failure,

    [Types.PROJECT_TASK_GETS_REQUEST]: request,
    [Types.PROJECT_TASK_GETS_SUCCESS]: projectTaskGetsSuccess,
    [Types.PROJECT_TASK_GETS_FAILURE]: failure,

    [Types.PROJECT_DELETE_REQUEST]: request,
    [Types.PROJECT_DELETE_SUCCESS]: success,
    [Types.PROJECT_DELETE_FAILURE]: failure,

    [Types.PROJECT_SUBMIT_REQUEST]: request,
    [Types.PROJECT_SUBMIT_SUCCESS]: success,
    [Types.PROJECT_SUBMIT_FAILURE]: failure,
  },
);
