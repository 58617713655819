import { useContext } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";

import { ContentContext } from "@/Provider";
import { CheckboxWithLabel } from "@/shared/checkbox/components/CheckboxWithLabel";
import { UnderBarCollapse } from "@/shared/collapses/components/UnderbarCollapse";
import { Heading3 } from "@/shared/headings/components/Heading3";
import { PlainInput } from "@/shared/inputs/components/PlainInput";
import { PlainSelect } from "@/shared/selectbox/components/PlainSelect";
import { PlainTextarea } from "@/shared/textarea/components/PlainTextarea";
import { WYSIWYGEditor } from "@/shared/textarea/components/WYSISYGEditor";
import { isJsonString } from "@/utils/common";

import styles from "./styles.module.scss";

export const EventDetailInfoForm = () => {
  const { register, control, getValues } = useFormContext();
  const nodalData = getValues("nodalData");
  const description = getValues("description");
  const id = getValues("id");

  const intl = useIntl();
  const eventDetailInfoMessage = intl.formatMessage({
    id: "screens.events.detail_info",
  });
  const recommendedForMessage = intl.formatMessage({
    id: "screens.events.recommendations",
  });
  const optionalMessage = intl.formatMessage({
    id: "screen.label.optional",
  });
  const descriptionMessage = intl.formatMessage({
    id: "screens.events.description",
  });
  const descriptionPlaceholder = intl.formatMessage({
    id: "screens.events.description_placeholder",
  });
  const setUserLabelMessage = intl.formatMessage({
    id: "screens.events.set_user_label",
  });
  const setUserLabelPlaceholder = intl.formatMessage({
    id: "screens.events.set_user_label_placeholder",
  });
  const sendEmailMessage = intl.formatMessage({
    id: "screen.label.email_notification",
  });
  const { company, user } = useContext(ContentContext);
  const userTagOptions = company.userTags
    ? company.userTags.map((userTag) => ({
        value: userTag,
        label: userTag,
      }))
    : [];

  return (
    <div className={styles.event_info}>
      <Heading3>{eventDetailInfoMessage}</Heading3>
      <UnderBarCollapse
        label={`${recommendedForMessage}(${optionalMessage})`}
        key="eventRecommendation"
      >
        <ul className={styles.event_recommendation}>
          {/* // おすすめ欄は3つ表示する */}
          {[0, 1, 2].map((index) => (
            <li key={index}>
              <PlainInput
                {...register(`EventRecommendations.${index}.text`)}
                placeholder={intl.formatMessage({
                  id: `screens.events.recommendation_example_${index}`,
                })}
                maxLength={120}
              />
            </li>
          ))}
        </ul>
      </UnderBarCollapse>
      {nodalData && isJsonString(description) ? (
        <Controller
          name="description"
          control={control}
          render={({ field }) => (
            <WYSIWYGEditor
              {...field}
              label={descriptionMessage}
              companyUuid={company.uuid}
              userToken={user.token}
              uploadImageConfig={{
                subLink: "event",
                headerDetail: {
                  type: "WYSIWYG file",
                  eventId: id || null,
                },
              }}
            />
          )}
        />
      ) : (
        <PlainTextarea
          {...register("description")}
          placeholder={descriptionPlaceholder}
          label={descriptionMessage}
        />
      )}
      <UnderBarCollapse
        label={`${setUserLabelMessage}(${optionalMessage})`}
        key="userTag"
      >
        <Controller
          name="userTag"
          control={control}
          render={({ field }) => (
            <PlainSelect
              onChange={(value) => field.onChange(value?.value)}
              onBlur={field.onBlur}
              value={userTagOptions.find((x) => x.value === field.value)}
              id="event-user-tag-select-box"
              placeholder={setUserLabelPlaceholder}
              options={userTagOptions}
              className={styles.event_type_select_box}
              isClearable
            />
          )}
        />
      </UnderBarCollapse>
      <CheckboxWithLabel {...register("sendEmail")} label={sendEmailMessage} />
    </div>
  );
};
