import { memo, useCallback } from "react";
import { useIntl } from "react-intl";
import { FormattedMessage } from "react-intl";

import { TertiaryButton } from "@/shared/buttons/components/TertiaryButton";
import { DownloadIcon } from "@/shared/icons/components/DownloadIcon";

import { excelExport } from "./helper";
import styles from "./styles.module.scss";
import { ExcelExportButtonProps } from "./type";
export const ExcelExportButton = memo(
  ({
    batchId,
    batchName,
    categoryId,
    examinationPhaseId,
    results,
  }: ExcelExportButtonProps) => {
    const intl = useIntl();
    const fileName = `${batchName}-${intl.formatMessage({
      id: "screen.label.review_sheet",
    })}.xlsx`;
    const onClick = useCallback(() => {
      excelExport(
        {
          batchId,
          categoryId,
          examinationPhaseId,
          results,
        },
        fileName,
      );
    }, [batchId, categoryId, examinationPhaseId, results, fileName]);
    return (
      <TertiaryButton onClick={onClick} className={styles.excel_export_button}>
        <DownloadIcon className={styles.excel_export_button_icon} />
        <span className={styles.excel_export_button_font}>
          <FormattedMessage id="screen.label.excel_export" />
        </span>
      </TertiaryButton>
    );
  },
);
