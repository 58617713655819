import styled from "styled-components";

export const TutorialCheckbox = styled.div`
  text-align: center;
  width: 100%;
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: white;
  padding-top: 16px;
  padding-bottom: 16px;
`;
export const CorouselTitle = styled.p`
  margin-bottom: 0px;
  margin-top: 3vh;
  color: white;
  font-size: 1.3rem;
  font-weight: bold;
`;
export const OnboardingButtonImg = styled.img`
  flex-grow: 1;
  width: 90%;
`;
export const LinkButtonArchive = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  padding-bottom: 5vh;
`;
