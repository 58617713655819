import { useEffect, useState } from "react";
/** @deprecated use {@linkcode useLocalStorageState} instead*/
// @ts-expect-error Temporary disable until we can fix the errors.
export default function useLocalStorage(key, initialValue) {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      return initialValue;
    }
  });
  // @ts-expect-error Temporary disable until we can fix the errors.
  const setValue = (value) => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };
  return [storedValue, setValue];
}

/** @deprecated use {@linkcode getLocalStorageValue} instead */
// @ts-expect-error Temporary disable until we can fix the errors.

export const getInitStorageValue = (key, initialValue) => {
  try {
    const item = window.localStorage.getItem(key);
    return item ? JSON.parse(item) : initialValue;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    return initialValue;
  }
};

type ToStr<T> = {
  toStr: (a: T) => string;
};

type FromStr<T> = {
  fromStr: (str: string) => T | null;
};

export type Convertable<T> = ToStr<T> & FromStr<T>;

const IntConverter: Convertable<number> = {
  fromStr: (str) => {
    const n = Number(str);
    return Number.isSafeInteger(n) ? n : null;
  },
  toStr: (int) => int.toString(),
};

const StrConverter: Convertable<string> = {
  fromStr: (s) => s,
  toStr: (s) => s,
};

/**
 * stateの値をlocalStorageに保存するuseState
 *
 * 以下の定義済みHooksもあります。ユースケースに足りない場合は各自で実装してください。
 *
 * - 整数用 {@linkcode useLocalStorageInt}
 * - 文字列用 {@linkcode useLocalStorageString}
 *
 * @param key localStorageのKey
 * @param defaultVal 「localStorageに値が無い場合 or 変換が失敗した時」の値
 * @param converter localStorageに保存された文字列とJSの値で相互変換する処理
 * @param storage Optional。localStorage以外に保存するときに使用する。
 */
export const useLocalStorageState = <T>(
  key: string,
  defaultVal: T,
  converter: Convertable<T>,
  storage: Storage = localStorage,
) => {
  const initState = getLocalStorageValue(key, defaultVal, converter, storage);
  const [state, setState] = useState(initState);
  useEffect(() => {
    storage.setItem(key, converter.toStr(state));
  }, [key, converter, state, storage]);
  return [state, setState] as const;
};

/**整数用のuseLocalStorageState */
export const useLocalStorageInt = (
  key: string,
  initVal: number,
  storage: Storage = localStorage,
) => useLocalStorageState(key, initVal, IntConverter, storage);

/** 文字列用のuseLocalStorageState */
export const useLocalStorageString = (
  key: string,
  initVal: string,
  storage: Storage = localStorage,
) => useLocalStorageState(key, initVal, StrConverter, storage);

/**
 * localStorageの値を読み出す。読み出せない場合はinitValを返す
 * @param key localStorageのKey
 * @param defaultVal 「localStorageに値が無い場合 or 変換が失敗した時」の値
 * @param fromString localStorageに保存された文字列とJSの値で相互変換する処理
 * @param storage Optional。localStorage以外に保存するときに使用する。
 */
export const getLocalStorageValue = <T>(
  key: string,
  defaultVal: T,
  fromString: FromStr<T>,
  storage: Storage = localStorage,
): T => {
  const gotValue = storage.getItem(key);
  if (gotValue == null) {
    return defaultVal;
  }
  const convertedValue = fromString.fromStr(gotValue) ?? defaultVal;
  return convertedValue;
};

type StorageVal = string;
type StorageKey = string;
export type Storage = {
  getItem: (key: StorageVal) => StorageKey | null;
  setItem: (key: StorageVal, val: StorageKey) => void;
};
