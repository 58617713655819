import { Form, Modal } from "antd";
import PropTypes from "prop-types";
import { FC } from "react";
import { FormattedMessage } from "react-intl";

import { ConditionText, Input, Password } from "../../../molecules";
import useChangePasswordComponent from "./hook";
import { IChangePasswordComponentProps } from "./type";

const ChangePasswordComponent: FC<IChangePasswordComponentProps> = function (
  props,
) {
  const { intl, state, handleResetPass, handleChange } =
    useChangePasswordComponent(props);

  return (
    <Modal
      title={intl.formatMessage({
        id: "screens.title.reset_password",
      })}
      visible={state.showPassResetModal}
      onOk={handleResetPass}
      okText={intl.formatMessage({
        id: "screen.label.change_your_password",
      })}
      cancelButtonProps={{ style: { display: "none" } }}
      closable={false}
      maskClosable={false}
    >
      <Form layout={"vertical"}>
        <Form.Item
          label={intl.formatMessage({
            id: "screens.title.username",
          })}
        >
          <Input
            required
            onChange={(e) => handleChange("name", e.target.value)}
            value={state.name}
            id="name"
            type="name"
          />
        </Form.Item>

        <Form.Item
          label={intl.formatMessage({
            id: "screens.title.new_password",
          })}
        >
          <ConditionText>
            ※
            <FormattedMessage id="screens.message.pass_condition" />
          </ConditionText>
          <Password
            required
            onChange={(e) => handleChange("newPassword", e.target.value)}
            value={state.newPassword}
            id="new_password"
            type="password"
          />
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({
            id: "screens.title.confirm_password",
          })}
        >
          <ConditionText>
            ※
            <FormattedMessage id="screens.message.pass_condition" />
          </ConditionText>
          <Password
            required
            onChange={(e) => handleChange("confirmPassword", e.target.value)}
            value={state.confirmPassword}
            id="confirm_password"
            type="password"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
ChangePasswordComponent.propTypes = {
  user: PropTypes.object,
  dispatchApiRequest: PropTypes.func,
  refreshData: PropTypes.func,
};
export default ChangePasswordComponent;
