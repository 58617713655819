import { Row, Skeleton } from "antd";
import { FC, useState } from "react";
import { useIntl } from "react-intl";
// @ts-expect-error TS7016
import LoadingOverlay from "react-loading-overlay";

import {
  IProjectArchiveSkeletonProps,
  ProjectArchiveSkeletonState,
} from "../../../Screens/project/types/IProjectArchiveSkeletonProps";
import CustomLoader from "../../atoms/CustomLoader";
const headers = [
  { title: "screen.label.change_history" },
  { title: "screen.label.user" },
  { title: "screen.label.changes" },
  { title: "screen.label.content" },
  { title: "screen.label.attachments" },
  { title: "screen.label.id" },
];
// @ts-expect-error TS7006
const randomIntFromInterval = (min, max) => {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
};
// @ts-expect-error TS7006
const getItems = (count) =>
  Array.from({ length: count }, (v, k) => k).map((k) => ({
    line: {},
    content: {
      width1: randomIntFromInterval(200, 220),
      width2: randomIntFromInterval(130, 160),
    },
    title: { width: 100 },
    options: {
      count: k % 3,
      width1: randomIntFromInterval(300, 320),
      width2: randomIntFromInterval(300, 320),
      width3: randomIntFromInterval(300, 320),
    },
    attach: { width: 100 },
    id: { width: 70 },
  }));
const grid = 20;
const dotSize = {
  small: 10,
  large: 16,
};
const pinSize = 26;
const numberSize = 18;
// @ts-expect-error TS7006
const getDotStyle = (index) => ({
  width: index % 2 == 0 ? `${dotSize.small}px` : `${dotSize.large}px`,
  height: index % 2 == 0 ? `${dotSize.small}px` : `${dotSize.large}px`,
});
// @ts-expect-error TS7006
const getPinStyle = (index) => ({
  width: `${pinSize}px`,
  height: `${pinSize}px`,
  top:
    index % 2 == 0
      ? `${dotSize.small / 2 - pinSize / 2}px`
      : `${dotSize.large / 2 - pinSize / 2}px`,
  left:
    index % 2 == 0
      ? `${-dotSize.small / 2 - pinSize / 2 - 5}px`
      : `${-dotSize.large / 2 - pinSize / 2 - 5}px`,
});
// @ts-expect-error TS7006
const getNumberStyle = (index) => ({
  width: `${numberSize}px`,
  height: `${numberSize}px`,
  top:
    index % 2 == 0
      ? `${-dotSize.small / 2 - numberSize / 2}px`
      : `${-dotSize.large / 2 - numberSize / 2}px`,
  left:
    index % 2 == 0
      ? `${-dotSize.small / 2 - numberSize / 2}px`
      : `${-dotSize.large / 2 - numberSize / 2}px`,
});
const getListStyle = () => ({
  // padding: grid,
  width: "100%",
});

const ProjectArchiveSkeleton: FC<IProjectArchiveSkeletonProps> = function (
  props,
) {
  const intl = useIntl();

  const [state, customSetState] = useState<ProjectArchiveSkeletonState>(() => {
    return {
      items: getItems(10),
    };
  });
  return (
    <LoadingOverlay active={true} spinner={<CustomLoader />}>
      <table style={getListStyle()}>
        <thead>
          <tr>
            {headers.map((header) => (
              <th key={header.title}>
                {intl.formatMessage({
                  id: header.title,
                })}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {/*
           // @ts-expect-error TS18048 */}
          {state.items.map((item, index) => (
            <tr
              key={index}
              style={{
                userSelect: "none",
                background:
                  index % 2 == 0 ? "white" : "rgba(215, 218, 226, 0.2)",
                height: "3rem",
                width: "100%",
              }}
            >
              <td style={{ height: "100%" }}>
                <div
                  style={{
                    position: "relative",
                    width: "100px",
                    height: "100px",
                  }}
                >
                  <div className="line-in-middle">
                    {index === 0 && <div className="line-in-point"></div>}
                    <div style={getDotStyle(index)} className="circle-dot" />
                  </div>
                </div>
              </td>
              <td>
                <Row>
                  <Skeleton.Input
                    style={{
                      width: item.content.width1,
                      height: 20,
                    }}
                    active={true}
                    size={"small"}
                  />
                </Row>
                <Row style={{ marginTop: 10 }}>
                  <Skeleton.Input
                    style={{
                      width: item.content.width2,
                      height: 15,
                    }}
                    active={true}
                    size={"small"}
                  />
                </Row>
              </td>
              <td>
                <Row>
                  <Skeleton.Input
                    style={{
                      width: item.title.width,
                      height: 20,
                    }}
                    active={true}
                    size={"small"}
                  />
                </Row>
              </td>
              <td>
                {item.options.count === 0 ? (
                  <>
                    <Row>
                      <Skeleton.Input
                        style={{
                          width: item.options.width1,
                          height: 16,
                        }}
                        active={true}
                        size={"small"}
                      />
                    </Row>
                    <Row style={{ marginTop: 8 }}>
                      <Skeleton.Input
                        style={{
                          width: item.options.width2,
                          height: 16,
                        }}
                        active={true}
                        size={"small"}
                      />
                    </Row>
                    <Row style={{ marginTop: 8 }}>
                      <Skeleton.Input
                        style={{
                          width: item.options.width3,
                          height: 16,
                        }}
                        active={true}
                        size={"small"}
                      />
                    </Row>
                  </>
                ) : item.options.count === 1 ? (
                  <>
                    <Row style={{ marginTop: 8 }}>
                      <Skeleton.Input
                        style={{
                          width: item.options.width2,
                          height: 16,
                        }}
                        active={true}
                        size={"small"}
                      />
                    </Row>
                    <Row style={{ marginTop: 8 }}>
                      <Skeleton.Input
                        style={{
                          width: item.options.width3,
                          height: 16,
                        }}
                        active={true}
                        size={"small"}
                      />
                    </Row>
                  </>
                ) : (
                  <Row style={{ marginTop: 8 }}>
                    <Skeleton.Input
                      style={{
                        width: item.options.width3,
                        height: 16,
                      }}
                      active={true}
                      size={"small"}
                    />
                  </Row>
                )}
              </td>
              <td>
                <Row>
                  <Skeleton.Input
                    style={{
                      width: item.attach.width,
                      height: 20,
                    }}
                    active={true}
                    size={"small"}
                  />
                </Row>
              </td>
              <td>
                <Row>
                  <Skeleton.Input
                    style={{
                      width: item.id.width,
                      height: 20,
                    }}
                    active={true}
                    size={"small"}
                  />
                </Row>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </LoadingOverlay>
  );
};

export default ProjectArchiveSkeleton;
