import styles from "./styles.module.scss";
import { ProjectIdLabelProps } from "./type";

export const ProjectIdLabel = ({
  projectId,
  className,
  ...rest
}: ProjectIdLabelProps) => {
  return (
    <span
      className={`${styles.project_id_label} ${className}`}
      {...rest}
    >{`ID:${projectId}`}</span>
  );
};
