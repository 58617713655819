import { memo, useCallback } from "react";
import { OnChangeValue } from "react-select";

import {
  CategorySelectBoxProps,
  CategorySelectOption,
} from "@/features/review/components/CategorySelectBox/type";
import { LabelSelect } from "@/shared/selectbox/components/LabelSelect/component";

import { useBatchSubmissionCategoriesOptions } from "./hooks";

export const CategorySelectBox = memo(
  ({ categoryId, categories, onChange }: CategorySelectBoxProps) => {
    const onChangeSelect = useCallback(
      (newValue: OnChangeValue<CategorySelectOption, false>) => {
        if (!newValue) return;
        onChange(newValue.value);
      },
      [onChange],
    );

    const { options, selectedOption } = useBatchSubmissionCategoriesOptions(
      categories,
      categoryId,
    );

    return (
      <LabelSelect
        maxWidth="600px"
        value={selectedOption}
        type="secondary"
        id="program-select-box"
        options={options}
        onSelect={onChangeSelect}
      />
    );
  },
);
