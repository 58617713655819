import imageCompression from "browser-image-compression";
import { useState } from "react";

import { IFileUploadComponentProps } from "../types/IFileUploadComponentProps";

type ChangeEventHandler = React.ChangeEventHandler<HTMLInputElement>;
type FileData = string | ArrayBuffer | null | undefined;

const useFileUploadComponent = (
  props: IFileUploadComponentProps,
): {
  imageSrc: FileData;
  fileOnChange: ChangeEventHandler;
} => {
  const [imageSrc, setImageSrc] = useState<FileData>(props.imageSrc);

  const setReadImageSrc = (imageFile: File): void => {
    const fileReader = new FileReader();
    fileReader.onload = () => setImageSrc(fileReader.result);
    fileReader.readAsDataURL(imageFile);
  };

  const fileOnChange: ChangeEventHandler = async (e) => {
    const target = e.target as HTMLInputElement;
    const imageFile = target.files?.[0];
    if (!imageFile) return;

    const acceptImage = props.fileTypeAccept === "image/*";
    if (acceptImage) setReadImageSrc(imageFile);

    if (acceptImage || /image.*/.test(imageFile.type)) {
      const compressedImage = await imageCompression(imageFile, {
        maxSizeMB: 10,
        maxWidthOrHeight: 1200,
        useWebWorker: false,
      });
      props.onFileChanged(compressedImage);
    } else {
      props.onFileChanged(imageFile);
    }
  };

  return { imageSrc, fileOnChange };
};
export default useFileUploadComponent;
