import { DeleteFilled, EditFilled, EyeOutlined } from "@ant-design/icons";
import { Button, Popconfirm } from "antd";
import moment from "moment";
import { useIntl } from "react-intl";

import {
  getDisplayName,
  getObjUUID,
  getTruncatedText,
} from "../../../utils/common";
import { getGlobalPermissionForNews } from "./utils";
type Props = {
  // @ts-expect-error TS2314
  companies: Array;
  user: Object;
  onGoto: (id: string) => void;
  onUpdate: (id: string) => void;
  onDelete: (id: string) => void;
};
const TblColumns = ({
  companies = [],
  // @ts-expect-error TS2322
  user = null,
  onGoto = () => {},
  onUpdate = () => {},
  onDelete = () => {},
}: Props) => {
  const intl = useIntl();
  return [
    {
      title: intl.formatMessage({
        id: "screen.label.posted_date_and_time",
      }),
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: true,
      // @ts-expect-error TS7006
      render: (text) => (text ? moment(text).local().format("YYYY/MM/DD") : ""),
    },
    {
      title: intl.formatMessage({ id: "screen.label.name_contributor" }),
      dataIndex: "User",
      key: "User",
      // @ts-expect-error TS7006
      render: function _fUser(text, data, index) {
        return <span>{getDisplayName(data.User)}</span>;
      },
    },
    {
      title: intl.formatMessage({ id: "screen.label.public_type" }),
      dataIndex: "isGlobalNews",
      key: "isGlobalNews",
      // @ts-expect-error TS7006
      render: function _fIsGlobalNews(text, data, index) {
        return (
          <span>
            {intl.formatMessage({
              id: data.isGlobalNews
                ? "screen.label.global"
                : "screen.label.limited_release",
            })}
          </span>
        );
      },
    },
    {
      title: intl.formatMessage({ id: "screen.label.url_page_title" }),
      dataIndex: "pageTitle",
      key: "pageTitle",
      // @ts-expect-error TS7006
      render: function _fPageTitle(text, data, index) {
        return (
          <span
            style={{
              maxWidth: 250,
              wordWrap: "break-word",
              display: "inline-block",
            }}
          >
            {getTruncatedText(text, 60)}
          </span>
        );
      },
    },
    {
      title: intl.formatMessage({ id: "screen.label.post_comment" }),
      dataIndex: "newsComment",
      key: "newsComment",
      // @ts-expect-error TS7006
      render: function _fNewsComment(text, data, index) {
        return (
          <span
            style={{
              maxWidth: 250,
              wordWrap: "break-word",
              display: "inline-block",
            }}
          >
            {getTruncatedText(text, 60)}
          </span>
        );
      },
    },
    {
      // @ts-expect-error TS7006
      render: function _fPermission(text, row) {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Button
              icon={<EyeOutlined />}
              onClick={() => {
                onGoto(getObjUUID(row));
              }}
              type={"ghost"}
              style={{
                marginRight: 10,
                marginTop: "1px",
              }}
            />
            {getGlobalPermissionForNews(user) && (
              <Button
                icon={<EditFilled />}
                onClick={() => {
                  onUpdate(getObjUUID(row));
                }}
                type={"ghost"}
                style={{
                  marginRight: 10,
                  marginTop: "1px",
                }}
              />
            )}
            {getGlobalPermissionForNews(user) && (
              <Popconfirm
                title={intl.formatMessage({
                  id: "screen.label.delete_confirm_news",
                })}
                onConfirm={() => {
                  onDelete(getObjUUID(row));
                }}
                okText={intl.formatMessage({
                  id: "screen.label.yes",
                })}
                cancelText={intl.formatMessage({
                  id: "screen.label.no",
                })}
              >
                <Button
                  icon={<DeleteFilled />}
                  type={"ghost"}
                  style={{
                    marginTop: "1px",
                  }}
                />
              </Popconfirm>
            )}
          </div>
        );
      },
    },
  ];
};
export default TblColumns;
