import { DateTime } from "luxon";

import { EventData } from "@/../types/Event";
import { EventMember } from "@/../types/EventMember";

// イベントが終了しているかどうかを判定する
const checkEventHasEnded = (event: EventData) =>
  event.eventTime.endTime &&
  new Date(
    `${new Date(event.eventDate).toDateString()} ${event.eventTime.endTime}`,
  ) < new Date();

type EventState =
  | "finished"
  | "deadlinePassed"
  | "notAccepting"
  | "lotteryNotApplied"
  | "lotteryUndecided"
  | "lotteryElected"
  | "lotteryNotElected"
  | "firstComeFirstServeFull"
  | "firstComeFirstServeNotFull"
  | "firstComeFirstServeConfirmed"
  | "firstComeFirstServeWaitingList"
  | "unexpectedType";

export const checkEventState = (
  event: EventData,
  eventUserState: EventMember | undefined,
  participantsCount: number,
): EventState => {
  // イベントが終了していた場合
  if (checkEventHasEnded(event)) {
    return "finished";
  }

  // イベントの申し込み期限が過ぎていた場合
  if (
    event.applicationDeadline &&
    DateTime.fromISO(event.applicationDeadline) < DateTime.now()
  ) {
    return "deadlinePassed";
  }

  // イベントが募集停止していた場合
  if (!event.isAcceptingMembers) {
    return "notAccepting";
  }

  switch (event.type) {
    case "lottery":
      if (!eventUserState) {
        return "lotteryNotApplied"; // 抽選イベントで、まだ申し込みをしていない場合
      }
      switch (eventUserState.canAttendState) {
        case "undecided":
          return "lotteryUndecided"; // イベントに申し込んでいるが、参加が未確定の場合
        case "decide_to_participate":
          return "lotteryElected"; // 抽選イベントに参加が確定している場合（当選）
        case "decide_not_to_participate":
          return "lotteryNotElected"; // 抽選イベントに不参加が確定している場合（落選）
        default:
          throw new Error("Invalid lottery state");
      }
    case "first-come-first-serve":
      if (!eventUserState) {
        return participantsCount >= event.capacity
          ? "firstComeFirstServeFull" // 先着順イベントで、まだ申し込みをしておらず定員に達している場合
          : "firstComeFirstServeNotFull"; // 先着順イベントで、まだ申し込みをしておらず定員に達していない場合
      }
      return eventUserState.canAttend
        ? "firstComeFirstServeConfirmed" // 先着順イベントで、参加が確定している場合
        : "firstComeFirstServeWaitingList"; // 先着順イベントで、参加がキャンセル待ちの場合
    default:
      return "unexpectedType";
  }
};
