import "./styles.css";

import PropTypes from "prop-types";
import { Component } from "react";

import openlink from "../../../images/openlink.svg";

function findLinkEntities(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === "LINK"
    );
  }, callback);
}

function getLinkComponent(config) {
  const showOpenOptionOnHover = config.showOpenOptionOnHover;
  return class Link extends Component {
    static propTypes = {
      entityKey: PropTypes.string.isRequired,
      children: PropTypes.array,
      contentState: PropTypes.object,
    };

    state = {
      showPopOver: false,
    };

    openLink = () => {
      const { entityKey, contentState } = this.props;
      const { url, targetOption } = contentState.getEntity(entityKey).getData();
      const linkTab = window.open(url, targetOption); // eslint-disable-line no-undef
      // linkTab can be null when the window failed to open.
      if (linkTab) {
        linkTab.focus();
      }
    };

    toggleShowPopOver = () => {
      const showPopOver = !this.state.showPopOver;
      this.setState({
        showPopOver,
      });
    };

    render() {
      const { children, entityKey, contentState } = this.props;
      const { url, targetOption } = contentState.getEntity(entityKey).getData();
      const { showPopOver } = this.state;
      return (
        <span
          className="rdw-link-decorator-wrapper"
          onMouseEnter={this.toggleShowPopOver}
          onMouseLeave={this.toggleShowPopOver}
        >
          <a href={url} target={targetOption}>
            {children}
            {showPopOver && showOpenOptionOnHover ? (
              <img
                src={openlink}
                alt=""
                onClick={this.openLink}
                style={{
                  marginLeft: 10,
                  marginBottom: 10,
                  cursor: "pointer",
                  backgroundColor: "white",
                }}
              />
            ) : undefined}
          </a>
        </span>
      );
    }
  };
}

export default (config) => ({
  strategy: findLinkEntities,
  component: getLinkComponent(config),
});
