import { PlusOutlined } from "@ant-design/icons";
import styled from "styled-components";

export const VideoShowInfo = styled.span`
  font-size: 20px;
  font-weight: bold;
  margin-top: 20px;
`;
export const PlusButton = styled(PlusOutlined)`
  font-size: 40px;
  font-weight: bold;
  color: #656565;
`;
export const SingleLArgeVideoRoot = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-left: 60px;
  padding-right: 60px;
  margin-right: 2vw;
  background-color: #a8a9ab;
  cursor: pointer;
  width: 270px;
  margin-bottom: 3vh;
`;
