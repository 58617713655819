import { Checkbox, Col, Row } from "antd";
import { useEffect, useRef } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { ResetButton } from "../../../Components/molecules/project/ResetButton";
import { endpoints, parseEndpoint } from "../../../Services/endpoints";
import {
  nonRecruiting_option,
  recruiting_option,
} from "../../../utils/constants";
import { usePrevious, useStateWithRef } from "../../../utils/hooks";
import { getInitStorageValue } from "../../../utils/localStorage";
import {
  IProjectsInvitedListComponentProps,
  ProjectsInvitedListComponentState,
} from "../types/IProjectsInvitedListComponentProps";

const PageSaveId = "ProjectsInvitedListComponent";
const pageKey = `pagination_page_${PageSaveId}`;
const limitKey = `pagination_limit_${PageSaveId}`;

// @ts-expect-error TS7006
const useProjectsInvitedListComponent = (props) => {
  const intl = useIntl();
  const [state, customSetState, stateRef] = useStateWithRef(() => {
    return {
      page: getInitStorageValue(pageKey, 1),
      limit: getInitStorageValue(limitKey, process.env.REACT_APP_PAGE_SIZE),
      sortField: "id",
      sortOrder: "desc",
      projects: {
        rows: [],
        count: 0,
      },
      stagesCheckedList: [],
      tagsCheckedList: [],
      milestonesFilterCheckList: [],
      memberStatusCheckList: [],
      currentProjUpdateAtSort: false,
      currentProjMilestoneSort: false,
    };
  });
  const setStateCallbackRef = useRef(() => {});
  useEffect(() => {
    const callBack = setStateCallbackRef.current;
    callBack();
    setStateCallbackRef.current = () => {};
  }, [state]);
  const setState = (
    data: ProjectsInvitedListComponentState,
    callback = () => {},
  ) => {
    setStateCallbackRef.current = callback;
    // @ts-expect-error TS7006
    customSetState((previousState) => {
      return {
        ...previousState,
        ...data,
      };
    });
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  const prevProps: IProjectsInvitedListComponentProps = usePrevious(props);
  useEffect(() => {
    if (props.batchId !== "" && prevProps?.batchId !== props.batchId) {
      fetchProjects();
    }
  });
  const fetchProjects = () => {
    const { page, limit } = stateRef.current;
    const { batchId } = props;
    let batchFilter = "";
    if (batchId && batchId !== "") {
      batchFilter = `&batches[]=${batchId}`;
    }
    const fetchPendingProjectsInvite = {
      method: "getRequest",
      url: parseEndpoint(endpoints.pendingProjectInvite, {
        page: page - 1,
        limit,
        batchFilter,
        userId: props.userId,
      }),
      data: {},
    };
    // @ts-expect-error TS7031
    props.dispatchApiRequest(fetchPendingProjectsInvite, ({ data }) => {
      setState({
        projects: data,
      });
    });
  };
  // @ts-expect-error TS7006
  const acceptToJoinProject = (projectId) => {
    const acceptToJoinProjectRequest = {
      method: "putRequest",
      url: parseEndpoint(endpoints.updateTeamMember, {
        uid: projectId,
        userId: props.userId,
      }),
      data: {
        userStatus: 1,
      },
    };
    props.dispatchApiRequest(acceptToJoinProjectRequest, () => {
      fetchProjects();
    });
  };
  // @ts-expect-error TS7006
  const rejectToJoinProject = (projectId) => {
    const acceptToJoinProjectRequest = {
      method: "putRequest",
      url: parseEndpoint(endpoints.updateTeamMember, {
        uid: projectId,
        userId: props.userId,
      }),
      data: {
        userStatus: -1,
      },
      successMessage: intl.formatMessage({
        id: "screen.label.declineded_invitation",
      }),
    };
    props.dispatchApiRequest(acceptToJoinProjectRequest, () => {
      fetchProjects();
    });
  };
  // @ts-expect-error TS7006
  const filterProjects = (sort, filter, tag = null) => {
    const { page, limit } = stateRef.current;
    const { batchId } = props;
    let batchFilter = "";
    if (batchId && batchId !== "") {
      batchFilter = `&batches[]=${batchId}`;
    }
    const url = `users/${props.userId}/teams?page=${
      page - 1
    }&limit=${limit}&userStatus=0${sort ? `&sort=${sort}` : ""}${
      filter ? `&${filter}` : ""
      // @ts-expect-error TS2339
    }${tag ? `&tag=${tag.join(",")}` : ""}${batchFilter}`;
    const fetchPendingProjectsInvite = {
      method: "getRequest",
      url,
      data: {},
    };
    // @ts-expect-error TS7031
    props.dispatchApiRequest(fetchPendingProjectsInvite, ({ data }) => {
      setState({
        projects: data,
      });
    });
  };
  // @ts-expect-error TS7006
  const onTableChange = (pagination, filters, sorter, extra) => {
    if (sorter) {
      const order = sorter.order && sorter.order === "ascend" ? "asc" : "desc";
      setState({
        sortField: sorter.field,
        sortOrder: order,
      });
      filterProjects(`${sorter.field} ${order}`, null);
    }
  };
  const getColumnMemberStatusProps = () => ({
    filterDropdown: function _fn({
      // eslint-disable-next-line react/prop-types
      // @ts-expect-error TS7031
      setSelectedKeys,
      // eslint-disable-next-line react/prop-types
      // @ts-expect-error TS7031
      selectedKeys,
      // eslint-disable-next-line react/prop-types
      // @ts-expect-error TS7031
      confirm,
      // eslint-disable-next-line react/prop-types
      // @ts-expect-error TS7031
      clearFilters,
    }) {
      // @ts-expect-error TS7034
      const options = [];
      [recruiting_option, nonRecruiting_option].forEach((o) => {
        options.push(
          <Col key={o.value} span={8}>
            <Checkbox value={o.value}>
              <FormattedMessage id={o.label} />
            </Checkbox>
          </Col>,
        );
      });
      return (
        <div style={{ padding: 8 }}>
          <Checkbox.Group
            style={{ width: "100%" }}
            value={state.memberStatusCheckList}
            onChange={(value) => handleMemberStatusChange(value)}
          >
            {/*
             // @ts-expect-error TS7005 */}
            <Row>{options}</Row>
          </Checkbox.Group>
          <br />
          <ResetButton
            type="primary"
            onClick={() => {
              clearFilters();
              setSelectedKeys([]);
              // @ts-expect-error TS2554
              handleMemberStatusChange();
            }}
            size="small"
          >
            Reset
          </ResetButton>
        </div>
      );
    },
    // @ts-expect-error TS7006
    onFilter: (value, record) => {},
  });

  // @ts-expect-error TS7006
  const handleMemberStatusChange = (type) => {
    setState(
      {
        page: 1,
        memberStatusCheckList: type,
      },
      () => {
        if (type && type.length === 1) {
          filterProjects(null, `type=${type.join(",")}`);
        } else {
          fetchProjects();
        }
      },
    );
  };
  // @ts-expect-error TS7006
  const handleStageChange = (stage) => {
    setState(
      {
        page: 1,
        stagesCheckedList: stage,
      },
      () => {
        if (stage && stage.length > 0) {
          filterProjects(null, `stage=${stage.join(",")}`);
        } else {
          fetchProjects();
        }
      },
    );
  };
  // @ts-expect-error TS7006
  const handleTagChange = (tag) => {
    setState(
      {
        page: 1,
        tagsCheckedList: tag,
      },
      () => {
        if (tag && tag.length > 0) {
          filterProjects(null, null, tag);
        } else {
          fetchProjects();
        }
      },
    );
  };
  // @ts-expect-error TS7006
  const handleMilestoneChange = (milestone) => {
    setState(
      {
        page: 1,
        milestonesFilterCheckList: milestone,
      },
      () => {
        // @ts-expect-error TS7006
        const parsedMilestones = milestone.map((sb) => {
          const ps = sb.split("_").slice(1).join();
          if (ps.length > 0) {
            return ps;
          }
          return sb;
        });
        filterProjects(null, `milestone=${parsedMilestones.join(",")}`);
      },
    );
  };
  return {
    handleStageChange,
    state,
    handleTagChange,
    intl,
    getColumnMemberStatusProps,
    rejectToJoinProject,
    acceptToJoinProject,
    onTableChange,
    fetchProjects,
    setState,
    handleMilestoneChange,
  };
};

export default useProjectsInvitedListComponent;
