import { BiTimeFive } from "react-icons/bi";

import { filterAndConvertToNumber } from "@/utils/filterAndConvertToNumber";
import { formatTime } from "@/utils/formatTime";

import { InputWithIcon } from "../InputWithIcon";
import { TimeInputProps } from "./type";

export const TimeInput = ({
  onChange,
  onBlur,
  ref,
  ...inputProps
}: TimeInputProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = filterAndConvertToNumber(event.target.value);
    // 5文字以上入力されたら、5文字目以降を削除
    onChange(newValue.slice(0, 4));
  };

  const handleBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    const formattedTime = formatTime(event.target.value);
    onChange(formattedTime);
    onBlur();
  };

  const handleFocus = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value.replace(":", "");
    onChange(newValue);
  };

  return (
    <InputWithIcon
      Icon={BiTimeFive}
      onChange={handleChange}
      onBlur={handleBlur}
      onFocus={handleFocus}
      placeholder="00:00"
      maxLength={4}
      ref={ref}
      {...inputProps}
    />
  );
};
