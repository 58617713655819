import { useCallback } from "react";

import styles from "./styles.module.scss";
import { CheckboxProps } from "./type";

export const Checkbox = ({
  checked,
  disabled,
  onCheck,
  ...props
}: CheckboxProps) => {
  const checkStatusClass = disabled
    ? styles.disabled
    : checked
    ? styles.checked
    : styles.unchecked;
  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onCheck(e.target.checked);
    },
    [onCheck],
  );
  return (
    <input
      type="checkbox"
      checked={checked}
      onChange={onChange}
      disabled={disabled}
      className={`${styles.checkbox} ${checkStatusClass}`}
      {...props}
    />
  );
};
