import { Col, Layout, Row } from "antd";
import PropTypes from "prop-types";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import {
  AlphaDriveImg,
  AlphaDriveImgContainer,
  AuthLayout,
  Banner,
  EmailSuccessLink,
  HorizontalLine,
  LoginBanner,
  LoginLabel,
  LogoTitleLayout,
  VerifyHeaderBold,
  VerifyParagraph,
} from "../../Components/molecules";
const { Content } = Layout;

const VerifySubEmailSuccess: FC<{}> = function () {
  return (
    <AuthLayout className="layout">
      <Content className="authContent">
        <Row>
          <Banner>
            <LogoTitleLayout>
              <LoginBanner alt={"Banner"} src={"/login_banner.png"} />
              <LoginLabel>
                <FormattedMessage id="screen.label.comprehensive_support_tool_des" />
                <br />
                <FormattedMessage id="screen.label.incubation_suite" />
              </LoginLabel>
            </LogoTitleLayout>
            <AlphaDriveImgContainer>
              <div>
                <span>Powered by &nbsp;</span>
                <AlphaDriveImg alt={"alpha drive"} src={"/alpha_drive.png"} />
              </div>
            </AlphaDriveImgContainer>
          </Banner>
          <Col>
            <div className="contentRoot">
              <div>
                <VerifyHeaderBold>
                  <FormattedMessage id="screen.label.sub_email_verify_success" />
                </VerifyHeaderBold>
                <br />
                <br />
                <VerifyParagraph>
                  <FormattedMessage id="screen.label.sub_email_verify_success_des" />
                </VerifyParagraph>
                <EmailSuccessLink>
                  <HorizontalLine />
                  <Link to="/login">
                    <FormattedMessage id="screen.label.back_to_login_screen" />
                  </Link>
                </EmailSuccessLink>
              </div>
            </div>
          </Col>
        </Row>
      </Content>
    </AuthLayout>
  );
};
VerifySubEmailSuccess.propTypes = {
  history: PropTypes.object,
  dispatchLoginRequest: PropTypes.func,
};

export default VerifySubEmailSuccess;
