import { Avatar, Tooltip } from "antd";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { ProjectOwners } from "@/Components/molecules/project/ProjectOwners";
import { getDisplayName, getUserAvatar } from "@/utils/common";

import { TeamMember } from "../../../../../types/TeamMember";
import { ProjectMembersModal } from "../ProjectMembersModal";

const truncatedTeamCount = 6;

export const ProjectCardMembers = () => {
  const teamMembersPayload = useSelector(
    // @ts-expect-error TS2339
    (state) => state.teams.teamMembersPayload,
  );
  const [showModal, setShowModal] = useState(false);
  const teamMembers: TeamMember[] = teamMembersPayload.rows.filter(
    (member: TeamMember) => member.isProjectOwner || !member.deletedAt,
  );

  const projectOwners = teamMembers.filter((member) => member.isProjectOwner);
  const otherMembers = teamMembers.filter((member) => !member.isProjectOwner);
  const visibleTeamMembers = otherMembers.slice(0, truncatedTeamCount);
  const restTeamMembers = otherMembers.slice(truncatedTeamCount);

  return (
    <ProjectOwners>
      <ProjectMembersModal
        visible={showModal}
        onCancel={() => setShowModal(false)}
        teamMembers={teamMembers}
      />
      {projectOwners.map((user, index) =>
        user.deletedAt ? (
          <div
            key={index}
            className="avatar-name-wrapper"
            style={{ color: "#000" }}
          >
            {getUserAvatar(user)}
            {getDisplayName(user)}
          </div>
        ) : (
          <Link key={index} to={"/dashboard/member/" + user.uuid}>
            <div className="avatar-name-wrapper" style={{ color: "#000" }}>
              {getUserAvatar(user)}
              {getDisplayName(user)}
            </div>
          </Link>
        ),
      )}
      {visibleTeamMembers.map((user, index) => (
        <Tooltip key={index} title={getDisplayName(user)}>
          <Link to={"/dashboard/member/" + user.uuid}>
            <div
              style={{
                marginLeft: 10,
              }}
            >
              {getUserAvatar(user)}
            </div>
          </Link>
        </Tooltip>
      ))}
      {restTeamMembers.length > 0 && (
        <div style={{ marginLeft: 10 }}>
          <Avatar
            style={{
              marginRight: 10,
              verticalAlign: "middle",
            }}
            onClick={() => setShowModal(true)}
            size="large"
          >
            <span
              style={{
                fontWeight: "bold",
                fontSize: 14,
                color: "#808080",
              }}
            >
              +{restTeamMembers.length}
            </span>
          </Avatar>
        </div>
      )}
    </ProjectOwners>
  );
};
