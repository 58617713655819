import React, { useContext, useImperativeHandle } from "react";
import { useIntl } from "react-intl";

import { ContentContext } from "../../../../Provider";
import { endpoints, parseEndpoint } from "../../../../Services/endpoints";
import useLocalStorage from "../../../../utils/localStorage";
const PageSaveId = "WatchProjectList";
const pageKey = `pagination_page_${PageSaveId}`;
const limitKey = `pagination_limit_${PageSaveId}`;

// @ts-expect-error TS7006
const useWatchProjectList = (props) => {
  const { forwardedRef, watchSettingList } = props;
  const context = useContext(ContentContext);
  const { apiRequest, user } = context;
  const intl = useIntl();
  const [page, setPage] = useLocalStorage(pageKey, 1);
  const [limit, setLimit] = useLocalStorage(
    limitKey,
    process.env.REACT_APP_PAGE_SIZE,
  );
  const [projects, setProjects] = React.useState([]);
  const [pageCnt, setPageCnt] = React.useState(0);
  const [updating, setUpdating] = React.useState(false);
  React.useEffect(() => {
    getCompanyDetail();
    // @ts-expect-error TS2554
    getUserProjects();
  }, [watchSettingList, page, limit, updating]);
  useImperativeHandle(forwardedRef, () => ({
    // @ts-expect-error TS7006
    getProjects: (filter) => {
      getUserProjects(filter);
    },
  }));
  // @ts-expect-error TS7006
  const getUserProjects = (filter) => {
    const filterExp =
      filter && filter != ""
        ? `filter=isWatch eq true, ${filter}`
        : `filter=isWatch eq true`;
    const getProjectsRequest = {
      method: "getRequest",
      url: parseEndpoint(endpoints.getUserWatches, {
        userId: user.id,
        page: page - 1,
        limit,
        filterExp,
      }),
      data: {},
    };
    // @ts-expect-error TS2345
    apiRequest(getProjectsRequest, ({ data }) => {
      // @ts-expect-error TS18046
      setProjects(data.rows);
      // @ts-expect-error TS18046
      setPageCnt(data.count);
    });
  };
  const getCompanyDetail = () => {
    // dispatch(CompanyActions.companyGetRequest())
  };
  // @ts-expect-error TS7006
  const deleteProjectWatch = (projectId, watchId, isWatch) => {
    const requestBuilder = {
      method: "deleteRequest",
      url: parseEndpoint(endpoints.singleProjectWatch, {
        projectId,
        watchId,
      }),
      data: {
        isWatch,
      },
    };
    setUpdating(true);
    // @ts-expect-error TS2345
    apiRequest(requestBuilder, ({ data }) => {
      setUpdating(false);
    });
  };

  return {
    intl,
    setPage,
    setLimit,
    projects,
    pageCnt,
    deleteProjectWatch,
  };
};
export default useWatchProjectList;
