import { FeedbackCommentLabel } from "../FeedbackCommentLabel/component";
import { PublishStatusLabel } from "../PublishStatusLabel/component";
import { ReviewResultLabel } from "../ReviewResultLabel/component";
import styles from "./styles.module.scss";
import { ReviewResultInfoCellProps } from "./type";

export const ReviewResultInfoCell = (props: ReviewResultInfoCellProps) => {
  const { result, feedbackComment, isPublished } = props;

  return (
    <div className={styles.review_result_cell_container}>
      <div className={styles.review_result_cell_inner_row}>
        <ReviewResultLabel reviewResult={result} />
        <PublishStatusLabel isPublished={isPublished} />
      </div>
      <FeedbackCommentLabel feedbackComment={feedbackComment ?? ""} />
    </div>
  );
};
