import { Button } from "antd";
import PropTypes from "prop-types";
import { FC, useRef } from "react";
import { FormattedMessage } from "react-intl";

import useFileUploadComponent from "../../../Screens/project/hooks/useFileUploadComponent";
import { IFileUploadComponentProps } from "../../../Screens/project/types/IFileUploadComponentProps";

const ThumbUploadComponent: FC<IFileUploadComponentProps> = function (props) {
  const { fileOnChange } = useFileUploadComponent(props);
  const fileRef = useRef();

  return (
    <label>
      <input
        // @ts-expect-error TS2322
        ref={fileRef}
        style={{ display: "none" }}
        accept={props.fileTypeAccept}
        onChange={fileOnChange}
        type={"file"}
        name={"file"}
      />
      <Button
        type="primary"
        className="primary-outline"
        onClick={() => {
          if (fileRef && fileRef.current) {
            // @ts-expect-error TS2339
            fileRef.current.click();
          }
        }}
      >
        {props.message}
      </Button>
    </label>
  );
};
ThumbUploadComponent.defaultProps = {
  // @ts-expect-error TS2322
  message: <FormattedMessage id="screen.label.upload_image_click" />,
  fileTypeAccept: "image/*",
  imageSrc: null,
};
ThumbUploadComponent.propTypes = {
  imageSrc: PropTypes.string,
  fileTypeAccept: PropTypes.string,
  // @ts-expect-error TS2322
  onFileChanged: PropTypes.func,
  message: PropTypes.any,
};

export default ThumbUploadComponent;
