import { memo } from "react";

import { CommentedPersonsLink } from "@/features/projectComment/component/CommentedPersonsLink";
import { DateTimeLabel } from "@/shared/label/DateTimeLabel";
import { UserTypeTag } from "@/shared/tags/components/UserTypeTag";

import styles from "./styles.module.scss";
import { PropsCommentInfoLabel } from "./type";
export const CommentInfoLabel = memo((props: PropsCommentInfoLabel) => {
  const { id, commentedPerson, commentedDateAsISO } = props;
  return (
    <div
      id={`commented_person_link_${id}`}
      className={styles.comment_info_label}
    >
      <CommentedPersonsLink commentedPerson={commentedPerson} />
      <UserTypeTag
        user={commentedPerson}
        className={styles.commented_persons_type_tag}
      />
      <DateTimeLabel
        dateTimeISO={commentedDateAsISO}
        showHour={true}
        className={styles.commented_date}
      />
    </div>
  );
});
