import React from "react";

import { useProjectCheckAccessPermission } from "../hooks/useProjectCheckAccessPermission";
export function withCheckProjectAccess(WrappedComponent: React.ReactChildren) {
  const ComponentWithPermissionCheck = (props: any) => {
    const checkProjectPermission = useProjectCheckAccessPermission(props);
    // @ts-expect-error TS2604
    return <WrappedComponent {...props} />;
  };
  return ComponentWithPermissionCheck;
}
