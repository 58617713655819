import styled from "styled-components";

export const ProjectHypothesisFormName = styled.div`
  max-width: 12rem;
  max-height: 3rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;
