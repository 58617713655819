import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Button, Col, Modal, Row, Spin } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { withContext } from "../../../Provider";
import { isUrl } from "../../../utils/common";
type Props = {
  context: any;
  url: string;
  title: string;
  messasge: string;
  btnStyle: any;
  itemCount?: number;
  disabled?: boolean;
};

// eslint-disable-next-line no-restricted-syntax
enum DOWNLOAD_STEP {
  SHOW_EXCEED_AMOUNT = 0,
  START_DOWNLOAD = 1,
}

const DOWNLOAD_EXCEED_AMOUNT = 100000;

const extractFileName = (url: string) => {
  const splited_urls = url.split("?");
  if (splited_urls.length > 1) {
    return splited_urls[0].split("/").pop();
  }
  return url.split("/").pop();
};

const getInitDownloadStep = (itemCount?: number): DOWNLOAD_STEP => {
  if (!itemCount || itemCount < DOWNLOAD_EXCEED_AMOUNT) {
    return DOWNLOAD_STEP.START_DOWNLOAD;
  }
  return DOWNLOAD_STEP.SHOW_EXCEED_AMOUNT;
};

function CsvExportModal(props: Props) {
  const {
    context,
    title,
    url,
    btnStyle = {},
    itemCount,
    disabled = false,
  } = props;
  const { apiRequest } = context;
  const [showModal, setShowModal] = useState(false);
  const [csvRes, setCSVRes] = useState(null);
  const [downloadStep, setDownloadStep] = useState<DOWNLOAD_STEP>(
    getInitDownloadStep(itemCount),
  );
  const [loading, setLoading] = useState(false);
  const intl = useIntl();

  useEffect(() => {
    if (showModal) {
      setDownloadStep(getInitDownloadStep(itemCount));
    }
  }, [itemCount, showModal]);

  useEffect(() => {
    if (downloadStep === DOWNLOAD_STEP.START_DOWNLOAD && showModal) {
      getCSVFileDownloadLink();
    }
  }, [downloadStep, url, showModal]);

  const startCSVDownload = useCallback(() => {
    if (downloadStep == DOWNLOAD_STEP.SHOW_EXCEED_AMOUNT) {
      setDownloadStep(DOWNLOAD_STEP.START_DOWNLOAD);
      setLoading(true);
    }
  }, [url, downloadStep]);

  const onShowModal = () => {
    setCSVRes(null);
    setShowModal(true);
  };

  const onCloseModal = () => {
    setCSVRes(null);
    setDownloadStep(DOWNLOAD_STEP.SHOW_EXCEED_AMOUNT);
    setShowModal(false);
  };

  const getCSVFileDownloadLink = () => {
    setLoading(true);
    const data = {
      method: "getRequest",
      url: url,
    };
    apiRequest(
      data,
      // @ts-expect-error TS7006
      (res) => {
        console.log("error in csv res", res);
        setLoading(false);
        if (res.data.StatusCode === 200 && res.data.Payload !== "") {
          setCSVRes(res.data.Payload);
        }
      },
      // @ts-expect-error TS7006
      (error) => {
        console.log("error in csv export", error);
        setLoading(false);
      },
    );
  };

  const isResultValid = csvRes && isUrl(csvRes);
  const isDownloadDisabled = loading || !isResultValid;

  const onDownloadCSV = useCallback(() => {
    if (isResultValid) {
      window.open(csvRes, "_blank");
      onCloseModal();
    }
  }, [csvRes, isResultValid]);

  const renderShowItemCount = () => {
    return (
      <>
        <br />
        <Row align="middle" justify="center">
          <div>
            <p style={{ textAlign: "center", margin: 0 }}>
              ※{" "}
              {intl.formatMessage({
                id: "screen.label.will_take_5mins",
              })}
            </p>
            <p style={{ textAlign: "center" }}>
              {intl.formatMessage({
                id: "screen.label.want_to_download",
              })}
            </p>
          </div>
        </Row>
        <br />
      </>
    );
  };

  const renderDownloadState = () => {
    return (
      <>
        <br />
        <Row align="middle" justify="center">
          {loading ? (
            <Spin />
          ) : isResultValid ? (
            <CheckCircleOutlined style={{ fontSize: 36, color: "green" }} />
          ) : (
            <CloseCircleOutlined style={{ fontSize: 36, color: "red" }} />
          )}
        </Row>
        <br />
        <Row align="middle" justify="center">
          {loading ? (
            <p>
              {intl.formatMessage({
                id: "screen.label.downloading",
              })}
            </p>
          ) : isResultValid ? (
            <p>{extractFileName(csvRes)}</p>
          ) : (
            <p>
              {intl.formatMessage(
                {
                  id: "screen.label.contact_us_error",
                },
                {
                  contact: (
                    <a
                      href="https://support.incubation-suite.com/hc/ja/requests/new"
                      rel="noreferrer"
                      target="_blank"
                    >
                      {intl.formatMessage({
                        id: "screen.label.contact_form",
                      })}
                    </a>
                  ),
                },
              )}
            </p>
          )}
        </Row>
        <br />
      </>
    );
  };

  const renderFooter = () => {
    return (
      <Row align="middle" justify="center">
        <Button type="ghost" onClick={onCloseModal}>
          {intl.formatMessage({
            id: "screen.label.cancel",
          })}
        </Button>
        {downloadStep === DOWNLOAD_STEP.SHOW_EXCEED_AMOUNT ? (
          <Button onClick={startCSVDownload} type="primary">
            {intl.formatMessage({
              id: "screen.label.download",
            })}
          </Button>
        ) : (
          <Button
            disabled={isDownloadDisabled}
            onClick={onDownloadCSV}
            type="primary"
          >
            {intl.formatMessage({
              id: "screen.label.download",
            })}
          </Button>
        )}
      </Row>
    );
  };

  return (
    <>
      <Modal
        centered
        closable={false}
        maskClosable={false}
        style={{ minWidth: "40vw" }}
        title={intl.formatMessage({
          id: title,
        })}
        visible={showModal}
        okButtonProps={{
          disabled: loading,
        }}
        cancelButtonProps={{
          disabled: loading,
        }}
        cancelText={intl.formatMessage({
          id: "screen.label.cancel",
        })}
        okText={intl.formatMessage({
          id: "screen.label.download",
        })}
        onOk={onDownloadCSV}
        onCancel={onCloseModal}
        footer={
          <Col span={16} offset={4}>
            {renderFooter()}
          </Col>
        }
      >
        {downloadStep === DOWNLOAD_STEP.SHOW_EXCEED_AMOUNT
          ? renderShowItemCount()
          : renderDownloadState()}
      </Modal>
      <Button
        type="primary"
        className={`${disabled ? "" : "primary-outline"}`}
        onClick={onShowModal}
        style={{
          ...btnStyle,
        }}
        disabled={disabled}
      >
        {intl.formatMessage({
          id: title,
        })}
      </Button>
    </>
  );
}
export default withContext(CsvExportModal);
