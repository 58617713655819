import { useCallback } from "react";
import useSWR from "swr";

import { PublishedReviewResultDetail } from "@/../types/PublishedReviewResultDetail";
import { api } from "@/Saga";
import { BaseErrorResponse } from "@/Services/commonResponsTypes";
import { endpoints, parseEndpoint } from "@/Services/endpoints";

import { GetPublishedReviewResult } from "../types/response";

export const usePublishedReviewResult = (submittedProjectId: string) => {
  const url = parseEndpoint(endpoints.publishedReviewResult, {
    submittedProjectId,
  });

  const fetcher = useCallback(async () => {
    const response = await api.getRequest<
      GetPublishedReviewResult,
      BaseErrorResponse
    >(url);

    if (!response.ok) throw new Error(response.originalError.message);
    if (response.data?.status !== "success")
      throw new Error(response.data?.message);

    return response.data.data;
  }, [url]);

  const { data, error, isValidating } = useSWR<
    PublishedReviewResultDetail,
    Error
  >(url, fetcher, {
    revalidateIfStale: true,
  });

  return {
    publishedReviewResult: data,
    error,
    isValidating,
  };
};
