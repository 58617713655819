import { Modal, Row } from "antd";
import { useIntl } from "react-intl";

import { AntdButton } from "../../../../shared/buttons/components/AntdButton";
import styles from "./styles.module.scss";
import { CommonModalProps, UserManagementModalProps } from "./type";

export const UserManagementModal = ({
  isVisible,
  onSubmit,
  onCancel,
  modalType,
  userName,
}: UserManagementModalProps) => {
  const intl = useIntl();

  const stopMessage = (
    <div>
      <h4>
        {intl.formatMessage({
          id: "stop_user_confirm_modal_content_title1",
        })}
      </h4>
      <ol className={styles.message_list}>
        <li className={styles.message_item}>
          <span className={styles.user_name}>{userName}</span>
          {intl.formatMessage({
            id: "stop_user_confirm_modal_content1",
          })}
        </li>
        <li className={styles.message_item}>
          {intl.formatMessage({
            id: "stop_user_confirm_modal_content2",
          })}
        </li>
      </ol>
    </div>
  );

  const restoreMessage = (
    <div>
      <h4>
        {intl.formatMessage({
          id: "restore_user_confirm_modal_content_title",
        })}
      </h4>
      <ol className={styles.message_list}>
        <li className={styles.message_item}>
          <span className={styles.user_name}>{userName}</span>
          {intl.formatMessage({
            id: "restore_user_confirm_modal_content",
          })}
        </li>
      </ol>
    </div>
  );

  const deleteMessage = (
    <div>
      <h4>
        {intl.formatMessage({
          id: "delete_user_confirm_modal_content_title1",
        })}
      </h4>
      <ol className={styles.message_list}>
        <li className={styles.message_item}>
          <span className={styles.user_name}>{userName}</span>
          {intl.formatMessage({
            id: "delete_user_confirm_modal_content1",
          })}
        </li>
        <li className={styles.message_item}>
          <span className={styles.user_name}>{userName}</span>
          {intl.formatMessage({
            id: "delete_user_confirm_modal_content2",
          })}
        </li>
        <li className={styles.message_item}>
          <span className={styles.user_name}>{userName}</span>
          {intl.formatMessage({
            id: "delete_user_confirm_modal_content3",
          })}
        </li>
      </ol>
      <h4 className={styles.message_space}>
        {intl.formatMessage({
          id: "delete_user_confirm_modal_content_title2",
        })}
      </h4>
      <p className={styles.text}>
        {intl.formatMessage({
          id: "delete_user_confirm_modal_content4",
        })}
      </p>
    </div>
  );

  switch (modalType) {
    case "stop": {
      return (
        <CommonModal
          onCancel={onCancel}
          isVisible={isVisible}
          onSubmit={onSubmit}
          title={intl.formatMessage({
            id: "stop_user_confirm_modal_header",
          })}
          message={stopMessage}
          buttonLabel={intl.formatMessage({ id: "screen.label.execute_stop" })}
          modalType={modalType}
        />
      );
    }
    case "restore": {
      return (
        <CommonModal
          onCancel={onCancel}
          isVisible={isVisible}
          onSubmit={onSubmit}
          title={intl.formatMessage({
            id: "restore_user_confirm_modal_header",
          })}
          message={restoreMessage}
          buttonLabel={intl.formatMessage({
            id: "screen.label.execute_restoration",
          })}
          modalType={modalType}
        />
      );
    }
    case "delete": {
      return (
        <CommonModal
          onCancel={onCancel}
          isVisible={isVisible}
          onSubmit={onSubmit}
          title={intl.formatMessage({
            id: "delete_user_confirm_modal_header",
          })}
          message={deleteMessage}
          buttonLabel={intl.formatMessage({
            id: "screen.label.execute_delete",
          })}
          modalType={modalType}
        />
      );
    }
    default: {
      return null;
    }
  }
};

export const CommonModal = ({
  onCancel,
  isVisible,
  onSubmit,
  title,
  message,
  buttonLabel,
  modalType,
}: CommonModalProps) => {
  const intl = useIntl();

  return (
    <Modal
      onCancel={onCancel}
      footer={null}
      title={title}
      visible={isVisible}
      destroyOnClose
    >
      <div>
        {message}
        <div className={styles.content_container}>
          <Row>
            <AntdButton
              category="secondary"
              onClick={onCancel}
              className={styles.delete_btn}
            >
              {intl.formatMessage({ id: "screen.label.cancel" })}
            </AntdButton>
            <AntdButton
              category={modalType === "delete" ? "danger" : "primary"}
              className={styles.cancel_btn}
              onClick={onSubmit}
            >
              {buttonLabel}
            </AntdButton>
          </Row>
        </div>
      </div>
    </Modal>
  );
};
