import { Row } from "antd";
import { useState } from "react";
import { FormattedMessage } from "react-intl";

import { ButtonOption } from "../../../atoms";
import CheckpointModal from "./CheckpointModal";
type Props = {
  onCreateCheckpoint: (name: string, description: string) => void;
};

const CreateCheckPoint = (props: Props) => {
  const { onCreateCheckpoint } = props;
  const [show, setShow] = useState(false);
  return (
    <>
      <Row>
        <ButtonOption
          onClick={() => {
            setShow(true);
          }}
        >
          ＋<FormattedMessage id="screen.label.checkpoint_add" />
        </ButtonOption>
      </Row>
      <CheckpointModal
        show={show}
        name={""}
        description={""}
        onUpdate={(name, description) => {
          onCreateCheckpoint(name, description);
        }}
        onHide={() => {
          setShow(false);
        }}
      />
    </>
  );
};

export default CreateCheckPoint;
