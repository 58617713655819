import { Card, Layout } from "antd";
import React from "react";
import { useIntl } from "react-intl";
import { connect } from "react-redux";

import { IndustryReportFormData } from "@/Components/organisms/industry-report/form";
import { ReduxDispatch, ReduxState } from "@/Redux/types";

import {
  IndustryReportForm,
  IndustryReportMainCategory,
} from "../../../../Components/organisms";
import ApiActions, {
  ApiRequestData,
  ApiSuccessCallback,
} from "../../../../Redux/ApiRedux";
import IndustryMainCategoryActions from "../../../../Redux/IndustryMainCategoryRedux";
import IndustryActions from "../../../../Redux/IndustryRedux";
import { endpoints, parseEndpoint } from "../../../../Services/endpoints";
const { Content } = Layout;
type StateProps = {
  auth: Object;
  industryMainCategories: {
    industryMainCategoryPayload: Array<IndustryReportMainCategory>;
  };
};
type ActionProps = {
  // @ts-expect-error TS7006
  dispatchApiRequest: (data) => void;
  // @ts-expect-error TS7006
  dispatchCreateReport: (data, message) => void;
  // @ts-expect-error TS7006
  dispatchGetMainCategories: (data) => void;
};
type Props = ActionProps & StateProps;
function IndustryReportCreate(props: Props) {
  const [loading, setLoading] = React.useState(false);
  const [companies, setCompanies] = React.useState([]);
  const intl = useIntl();
  React.useEffect(() => {
    getCompanies();
    // @ts-expect-error TS2554
    props.dispatchGetMainCategories();
  }, []);
  const getCompanies = () => {
    const requestBuilder = {
      method: "getRequest",
      url: parseEndpoint(endpoints.companies),
      data: {},
    };
    // @ts-expect-error TS2554
    props.dispatchApiRequest(requestBuilder, ({ data }) => {
      if (data.rows && data.rows.length > 0) {
        const { libraryEnables } = data;
        const parseLibraryEnable = Array.isArray(libraryEnables)
          ? libraryEnables.reduce((s, c) => {
              return {
                ...s,
                [c.companyId]: {
                  isIndustryEnabled: c.isIndustryEnabled,
                },
              };
            }, {})
          : {};

        const tempComp = Array.isArray(data.rows)
          ? // @ts-expect-error TS7006
            data.rows.filter((c) => {
              return (
                parseLibraryEnable[c.id]?.isIndustryEnabled ||
                typeof parseLibraryEnable[c.id] === "undefined"
              );
            })
          : [];
        setCompanies(tempComp);
      }
    });
  };
  const handleSubmit = (data: IndustryReportFormData) => {
    setLoading(true);
    const message = intl.formatMessage({
      id: "screen.label.industry_theme_report_create_success",
    });
    props.dispatchCreateReport(data, message);
    setTimeout(() => {
      setLoading(false);
      window.history.back();
    }, 1500);
  };
  console.log(companies);
  return (
    <Content>
      <h2>
        {intl.formatMessage({
          id: "screen.label.create_industry_report",
        })}
      </h2>
      <Card>
        <IndustryReportForm
          item={null}
          companies={companies}
          mainCategories={
            props.industryMainCategories.industryMainCategoryPayload
          }
          onSubmit={handleSubmit}
          loading={loading}
          isGlobal={true}
        />
      </Card>
    </Content>
  );
}
const mapStateToProps = (state: ReduxState) => ({
  auth: state.auth,
  industryMainCategories: state.industryMainCategories,
});
const mapDispatchToProps = (dispatch: ReduxDispatch) => ({
  // @ts-expect-error TS7006
  dispatchCreateReport: (data, message) =>
    // @ts-expect-error TS2554
    dispatch(IndustryActions.industryCreateRequest(data, message)),
  // @ts-expect-error TS7006
  dispatchGetMainCategories: (data) =>
    // @ts-expect-error TS2554
    dispatch(IndustryMainCategoryActions.industryMainCategoryGetRequest(data)),
  dispatchApiRequest: (data: ApiRequestData, callback: ApiSuccessCallback) =>
    dispatch(ApiActions.apiRequest(data, callback)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
  // @ts-expect-error TS2345
)(IndustryReportCreate);
