import { useEffect, useRef, useState } from "react";

import { endpoints, parseEndpoint } from "../../../Services/endpoints";
import { usePrevious } from "../../../utils/hooks";
import { transformAllProjectsList } from "../TransformProjectsAPIData";
import {
  IProjectListScreenProps,
  ProjectListScreenState,
} from "../types/IProjectListScreenProps";
const projectLocalStorageKeys = "projectListScreen";

const useProjectsListScreen = (props: IProjectListScreenProps) => {
  const { user, apiRequest, updatePageTitle } = props.context;

  const [state, customSetState] = useState<ProjectListScreenState>(() => {
    return {
      tags: [],
    };
  });
  const prevProps = usePrevious(props);

  const setStateCallbackRef = useRef(() => {});
  useEffect(() => {
    const callBack = setStateCallbackRef.current;
    callBack();
    setStateCallbackRef.current = () => {};
  }, [state]);
  const setState = (data: ProjectListScreenState, callback = () => {}) => {
    setStateCallbackRef.current = callback;
    customSetState((previousState) => {
      return {
        ...previousState,
        ...data,
      };
    });
  };

  useEffect(() => {
    if (getBatchId() === "") {
      updatePageTitle("screen.label.all_projects");
    } else {
      setPageTitleWithBatchName();
    }
    getTag();
    // @ts-expect-error TS2722
    props.dispatchFetchBatches();
    window.addEventListener("beforeunload", eventOnBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", eventOnBeforeUnload);
    };
  }, []);

  useEffect(() => {
    const batches = props.batches;
    if (batches && batches !== prevProps?.batches) {
      setPageTitleWithBatchName();
    }
    const curBatchId = getBatchId(props);
    const prevBatchId = getBatchId(prevProps);
    if (curBatchId !== "" && curBatchId !== prevBatchId) {
      setPageTitleWithBatchName();
    }
  }, [props]);

  const setPageTitleWithBatchName = () => {
    const batchId = getBatchId();
    const batches = props.batches;
    if (batches && Array.isArray(batches) && batches.length > 0) {
      const batch = batches.find((a) => a.id === batchId);
      if (batch) {
        updatePageTitle(batch.name);
      }
    }
  };
  const eventOnBeforeUnload = () => {
    localStorage.removeItem(projectLocalStorageKeys);
  };
  // @ts-expect-error TS7006
  const handleAllProjectsAdvanceFilter = (params) => {
    const {
      page,
      limit,
      recruitmentStatus,
      stages,
      tags,
      milestones,
      sortField,
      sortOrder,
      batches,
      name,
      description,
    } = params;
    const filterProjectsRequest = {
      method: "postRequest",
      url: parseEndpoint(endpoints.advanceFilterProjects, {
        page: page - 1,
        limit,
      }),
      data: {
        recruitmentStatus,
        stages,
        tags,
        milestones,
        sortField,
        sortOrder,
        batches,
        description,
        name,
      },
    };
    // @ts-expect-error TS2345
    apiRequest(filterProjectsRequest, ({ data }) => {
      // @ts-expect-error TS18046
      const rows = transformAllProjectsList(data.rows);
      props.dispatchSetSuccessAllProjects({
        rows,
        // @ts-expect-error TS18046
        count: data.count,
      });
    });
  };
  const getTag = () => {
    const tagRequest = {
      method: "getRequest",
      url: parseEndpoint(endpoints.tags),
      data: {},
    };
    // @ts-expect-error TS2345
    apiRequest(tagRequest, ({ data }) => {
      setState({
        // @ts-expect-error TS2322
        tags: data,
      });
    });
  };
  const getBatchId = (cprops: any = props): string => {
    const {
      match: { params },
    } = cprops;
    return params.id ? params.id : "";
  };

  return {
    state,
    getBatchId,
    handleAllProjectsAdvanceFilter,
  };
};

export default useProjectsListScreen;
