import { Collapse } from "antd";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";

import styles from "./styles.module.scss";
import { UnderBarCollapseProps } from "./type";

export const UnderBarCollapse = ({
  children,
  key,
  label,
}: UnderBarCollapseProps) => {
  return (
    <Collapse
      bordered={false}
      expandIcon={({ isActive }) =>
        isActive ? (
          <MdKeyboardArrowUp className={styles.collapse_arrow_icon} />
        ) : (
          <MdKeyboardArrowDown className={styles.collapse_arrow_icon} />
        )
      }
      expandIconPosition="end"
      className={styles.collapse}
    >
      <Collapse.Panel
        header={label}
        key={key}
        className={styles.collapse_panel}
      >
        {children}
      </Collapse.Panel>
    </Collapse>
  );
};
