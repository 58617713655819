import { memo, useCallback, useState } from "react";
import { useIntl } from "react-intl";

import QuillEditor from "../../../Screens/project/QuillEditor";
import { getPureStringFromHtml } from "../../../utils/common";
import { ProjectHypothesisSubmitButton } from "./ProjectHypothesisSubmitButton";

type Props = {
  content: string;
  isEditing: boolean;
  updateProject: (hypothesisFormId: string, value: string) => void;
  onStartEdit: () => void;
  onCancelEdit: () => void;
  hypothesisFormId: string;
};

function ProjectHypothesisEditor({
  content,
  isEditing,
  hypothesisFormId,
  onStartEdit,
  onCancelEdit,
  updateProject,
}: Props) {
  const intl = useIntl();
  const [editorValue, setEditorValue] = useState(content);

  const onSubmit = useCallback(() => {
    updateProject(hypothesisFormId, editorValue);
  }, [hypothesisFormId, editorValue, updateProject]);

  const handleChangeEditor = (value: string) => {
    setEditorValue(value);
  };

  const onCancel = useCallback(() => {
    const r = window.confirm(
      intl.formatMessage({
        id: "screen.label.do_you_really_want_to_cancel",
      }),
    );
    if (r) {
      setEditorValue(content);
      onCancelEdit();
    }
  }, [intl, content, onCancelEdit]);

  const pureContent = getPureStringFromHtml(content).trim();

  return (
    <div onClick={!isEditing ? onStartEdit : undefined}>
      <QuillEditor
        defaultId={hypothesisFormId}
        defaultValue={content}
        placeholder={""}
        contentStyle={{
          backgroundColor: "white",
          minHeight: isEditing ? 154 : 50,
          paddingLeft: isEditing ? 0 : 13,
          paddingRight: isEditing ? 0 : 13,
          paddingTop: isEditing ? 0 : pureContent !== "" ? 20 : 0,
          paddingBottom: isEditing ? 0 : pureContent !== "" ? 20 : 0,
        }}
        onChangeValue={handleChangeEditor}
        readOnly={!isEditing}
      />
      {isEditing && (
        <div style={{ marginTop: 20 }}>
          <ProjectHypothesisSubmitButton
            onSubmit={onSubmit}
            onCancel={onCancel}
          />
        </div>
      )}
      <br />
      <br />
    </div>
  );
}
export default memo(ProjectHypothesisEditor);
