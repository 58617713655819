import { useEffect, useRef } from "react";

import { endpoints, parseEndpoint } from "../../../Services/endpoints";
import { useStateWithRef, useWindowWidth } from "../../../utils/hooks";
import { DepartmentsFromCSVComponentState } from "../../settings/types/IDepartmentsFromCSVComponentProps";
import { IDisplayAllVideosLargeCategoryScreenProps } from "../types/IDisplayAllVideosLargeCategoryScreenProps";

const useDisplayAllVideosLargeCategoryScreen = (
  props: IDisplayAllVideosLargeCategoryScreenProps,
) => {
  const [state, customSetState, stateRef] = useStateWithRef(() => {
    return {
      smallCategories: [],
    };
  });

  const { apiRequest, updatePageTitle } = props.context;

  useEffect(() => {
    updatePageTitle("screen.label.video");
    _getSmallCategories();
  }, []);
  const windowWidth = useWindowWidth();

  const setStateCallbackRef = useRef(() => {});
  useEffect(() => {
    const callBack = setStateCallbackRef.current;
    callBack();
    setStateCallbackRef.current = () => {};
  }, [state]);
  const setState = (
    data: DepartmentsFromCSVComponentState,
    callback = () => {},
  ) => {
    setStateCallbackRef.current = callback;
    // @ts-expect-error TS7006
    customSetState((previousState) => {
      return {
        ...previousState,
        ...data,
      };
    });
  };

  const getLargeCategoryId = () => {
    const {
      match: { params },
    } = props;
    return params.id ? params.id : -1;
  };
  const getLargeCategoryName = () => {
    const {
      match: { params },
    } = props;
    return params.name ? params.name : "";
  };

  const _getSmallCategories = () => {
    const requestBuilder = {
      method: "getRequest",
      url: parseEndpoint(endpoints.smallCategoriesForSingleLargeCategory, {
        largeCategoryId: getLargeCategoryId(),
      }),
      data: {},
    };
    // @ts-expect-error TS2345
    apiRequest(requestBuilder, ({ data }) => {
      // @ts-expect-error TS18046
      if (data.length > 0) {
        setState({
          // @ts-expect-error TS2345
          smallCategories: data,
        });
      }
    });
  };

  return {
    state: stateRef.current,
    windowWidth,
    getLargeCategoryName,
  };
};

export default useDisplayAllVideosLargeCategoryScreen;
