import { Layout } from "antd";
import { Suspense } from "react";
import { connect } from "react-redux";
import { Route, withRouter } from "react-router-dom";

import { ReduxDispatch, ReduxState } from "@/Redux/types";

import CustomLoader from "../../Components/atoms/CustomLoader";
import { NavbarUser } from "../../Components/organisms";
import ApiActions, {
  ApiRequestData,
  ApiSuccessCallback,
} from "../../Redux/ApiRedux";
import AuthActions from "../../Redux/AuthRedux";
import EditSmallCategoryScreen from "./EditSmallCategoryScreen";
import EditVideoScreen from "./EditVideoScreen";
import GlobalVideoMgt from "./GlobalVideoMgt";
import PostNewVideoScreen from "./PostNewVideoScreen";
import SmallCategoriesManagementScreen from "./SmallCategoriesManagementScreen";

const { Header, Content } = Layout;
type ActionProps = {
  dispatchSetBaseUrl: () => void;
  dispatchLogoutUser: () => void;
  dispatchSetAuthToken: () => void;
  dispatchApiRequest: () => void;
};
type StateProps = {
  auth: Object;
  pageTitle: string;
};
type Props = StateProps & ActionProps;
function LibraryScreen(props: Props) {
  const logUserOut = () => {
    props.dispatchLogoutUser();
    // @ts-expect-error TS2554
    props.dispatchSetBaseUrl(process.env.REACT_APP_BASEHOST);
  };
  return (
    <Layout
      style={{
        backgroundColor: "#f5f5f5",
        height: "100%",
        minHeight: "100vh",
      }}
    >
      <Layout>
        <Header>
          <NavbarUser
            pageTitle={props.pageTitle}
            // @ts-expect-error TS2339
            userDetails={props.auth.payload}
            logoutUser={logUserOut}
            showSwitchLang={false}
          />
        </Header>
        <Suspense fallback={<CustomLoader />}>
          <Content style={{ padding: "3% 5% 3% 5%" }}>
            <Route path={"/global-video"} exact component={GlobalVideoMgt} />
            <Route
              path={"/global-video/edit/:id"}
              exact
              component={EditVideoScreen}
            />
            <Route
              path={"/global-video/create"}
              exact
              component={PostNewVideoScreen}
            />
            <Route
              path={"/global-video/videos-large-category/:id/:name"}
              exact
              component={SmallCategoriesManagementScreen}
            />
            <Route
              path={"/global-video/small-category-edit/:id"}
              exact
              component={EditSmallCategoryScreen}
            />
          </Content>
        </Suspense>
      </Layout>
    </Layout>
  );
}
const mapStateToProps = (state: ReduxState) => ({
  pageTitle: state.dashboard.pageTitle,
  auth: state.auth,
});
const mapDispatchToProps = (dispatch: ReduxDispatch) => ({
  // @ts-expect-error TS7006
  dispatchSetBaseUrl: (url) => dispatch(AuthActions.loginSetBaseUrl(url)),
  dispatchLogoutUser: () => dispatch(AuthActions.logout()),
  // @ts-expect-error TS7006
  dispatchSetAuthToken: (token) =>
    dispatch(AuthActions.loginSetAuthToken(token)),
  dispatchApiRequest: (data: ApiRequestData, callback: ApiSuccessCallback) =>
    dispatch(ApiActions.apiRequest(data, callback)),
});
export default withRouter(
  // @ts-expect-error TS2345
  connect(mapStateToProps, mapDispatchToProps)(LibraryScreen),
);
