export default (requestData) => {
  const keys = Object.keys(requestData);

  let params = "";
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    const value = requestData[key];

    if (i === keys.length - 1) {
      params += `${key}=${value}`;
    } else {
      params += `${key}=${value}&`;
    }
  }

  return params;
};
