import styled from "styled-components";

export const Line = styled.hr`
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid ${(props) => (props.color ? props.color : "#bbbbbb")};
  margin: 0px !important;
  padding: 0;
`;
