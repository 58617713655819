import {
  DeadlinePassedUI,
  ErrorUI,
  FirstComeFirstServeFullUI,
  FirstComeFirstServeNotFullUI,
  FirstComeFirstServeWaitingListUI,
  LotteryNotAppliedUI,
  LotteryNotElectedUI,
  LotteryUndecidedUI,
  NotAcceptingUI,
  ParticipationConfirmedUi,
} from "./eventStateComponents";
import { checkEventState } from "./helper";
import { useEventActions, useEventUserState } from "./hooks";
import styles from "./styles.module.scss";
import { EventApplicationProps } from "./type";

export const EventApplication = ({ event, user }: EventApplicationProps) => {
  const [eventUserState, setEventUserState] = useEventUserState(event, user);
  const { applyEvent, cancelEvent } = useEventActions(
    event,
    user,
    setEventUserState,
  );
  const participantsCount = event.EventMembers.length;

  const EventState = () => {
    switch (checkEventState(event, eventUserState, participantsCount)) {
      case "finished":
      case "deadlinePassed":
        return <DeadlinePassedUI event={event} />;
      case "notAccepting":
        return <NotAcceptingUI />;
      case "lotteryNotApplied":
        return (
          <LotteryNotAppliedUI event={event} applyEventHandler={applyEvent} />
        );
      case "lotteryUndecided":
        return <LotteryUndecidedUI cancelEventHandler={cancelEvent} />;
      case "lotteryElected":
        return <ParticipationConfirmedUi cancelEventHandler={cancelEvent} />;
      case "lotteryNotElected":
        return <LotteryNotElectedUI />;
      case "firstComeFirstServeFull":
        return (
          <FirstComeFirstServeFullUI
            event={event}
            applyEventHandler={applyEvent}
            participantsCount={participantsCount}
          />
        );
      case "firstComeFirstServeNotFull":
        return (
          <FirstComeFirstServeNotFullUI
            event={event}
            applyEventHandler={applyEvent}
            participantsCount={participantsCount}
          />
        );
      case "firstComeFirstServeConfirmed":
        return <ParticipationConfirmedUi cancelEventHandler={cancelEvent} />;
      case "firstComeFirstServeWaitingList":
        return (
          <FirstComeFirstServeWaitingListUI cancelEventHandler={cancelEvent} />
        );
      default:
        return <ErrorUI />;
    }
  };

  return (
    <div className={styles.event_application}>
      <EventState />
    </div>
  );
};
