import { notification } from "antd";
import { all, call, put, takeLatest } from "redux-saga/effects";

import ArchivesActions, { ArchivesTypes } from "../Redux/ArchiveRedux";
import { endpoints, parseEndpoint } from "../Services/endpoints";

// @ts-expect-error TS7006
function* attemptGetAllArchives(api, { page, id, filter }) {
  // @ts-expect-error TS7057
  const archivesResponse = yield call(
    api.getRequest,
    parseEndpoint(endpoints.getProjectArchive, { id, page, filter }),
  );

  if (archivesResponse.ok) {
    switch (archivesResponse.data.status) {
      case "success":
        yield put(
          ArchivesActions.archivesGetsSuccess(archivesResponse.data.data),
        );
        break;
      case "fail":
      case "error":
        if (typeof archivesResponse.data.data === "string") {
        }
        yield put(
          ArchivesActions.archivesGetsFailure(archivesResponse.data.data),
        );
        break;
      default:
        break;
    }
  } else {
    notification.error({
      message: archivesResponse.problem,
    });
    yield put(ArchivesActions.archivesGetsFailure(archivesResponse.problem));
    if (archivesResponse.status === 401) {
      // @ts-expect-error TS2322
      window.location = "/login";
    }
  }
}

// @ts-expect-error TS7006
function* archivesSaga(api) {
  yield all([
    // @ts-expect-error TS2345
    takeLatest(ArchivesTypes.ARCHIVES_GETS_REQUEST, attemptGetAllArchives, api),
  ]);
}

export default archivesSaga;
