import { LikeFilled, LikeOutlined } from "@ant-design/icons";
import { Popover } from "antd";
import { FC, PropsWithChildren, useMemo } from "react";

import { Button } from "@/Components/atoms";
import { getDisplayName, getUserAvatar } from "@/utils/common";

import { ProjectLike } from "../../../../../types/ProjectLike";

const AVATAR_MAX_COUNT = 5;

export type ProjectLikeButtonProps = {
  projectLikes: ProjectLike[];
  isLiked: boolean;
  onClick: () => void;
};

export const ProjectLikeButton: FC<ProjectLikeButtonProps> = ({
  projectLikes,
  isLiked,
  onClick,
}) => {
  const likeCount = projectLikes.length;
  const sortedProjectLikes = [...projectLikes].sort((a, b) => b.id - a.id);

  const likeButton = (
    <Button
      style={{
        color: "#1F86E9",
        border: "1px solid #1F86E9",
      }}
      onClick={() => onClick()}
    >
      {isLiked ? (
        <LikeFilled style={{ color: "#1F86E9" }} />
      ) : (
        <LikeOutlined style={{ color: "#808080" }} />
      )}
      <span
        style={{
          marginLeft: 8,
          color: isLiked ? "#1F86E9" : "#808080",
        }}
      >
        {likeCount}
      </span>
    </Button>
  );

  return likeCount > 0 ? (
    <ProjectLikePopover projectLikes={sortedProjectLikes} likeCount={likeCount}>
      {likeButton}
    </ProjectLikePopover>
  ) : (
    likeButton
  );
};

type ProjectLikePopoverProps = {
  projectLikes: ProjectLike[];
  likeCount: number;
};

const ProjectLikePopover: FC<PropsWithChildren<ProjectLikePopoverProps>> = ({
  projectLikes,
  likeCount,
  children,
}) => {
  // 5人まではアバターを表示し、それ以上は+〇Member(s)と表示する
  const omittedLikeCount = likeCount - AVATAR_MAX_COUNT;

  const content = useMemo(
    () => (
      <>
        {projectLikes.map((projectLike, i) => {
          if (i >= AVATAR_MAX_COUNT) return;
          return (
            <div key={projectLike.id} className="avatar-name-wrapper">
              {getUserAvatar(projectLike.User, () => {}, {
                width: "20px",
                height: "20px",
              })}
              {getDisplayName({
                ...projectLike.User,
                email: projectLike.User.Credential.email,
              })}
            </div>
          );
        })}
        {omittedLikeCount > 0 && (
          <div className="avatar-name-wrapper">
            {omittedLikeCount === 1
              ? `+ ${omittedLikeCount} Member`
              : `+ ${omittedLikeCount} Members`}
          </div>
        )}
      </>
    ),
    [projectLikes, omittedLikeCount],
  );
  return <Popover content={content}>{children}</Popover>;
};
