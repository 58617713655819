import { useEffect, useRef } from "react";

import { endpoints, parseEndpoint } from "../../../Services/endpoints";
import { useStateWithRef } from "../../../utils/hooks";
import { getInitStorageValue } from "../../../utils/localStorage";
import {
  IUserViewedPagesActivitiesScreenProps,
  UserViewedPagesActivitiesScreenState,
} from "../types/IUserViewedPagesActivitiesScreenProps";

const PageSaveId = "UserViewedPagesActivitiesScreen";
const pageKey = `pagination_page_${PageSaveId}`;
const limitKey = `pagination_limit_${PageSaveId}`;

const useUserViewedPagesActivitiesScreen = (
  props: IUserViewedPagesActivitiesScreenProps,
) => {
  const { apiRequest, updatePageTitle } = props.context;
  const [state, customSetState, stateRef] = useStateWithRef(() => {
    return {
      page: getInitStorageValue(pageKey, 1),
      limit: getInitStorageValue(limitKey, process.env.REACT_APP_PAGE_SIZE),
      screens: {
        rows: [],
        count: 0,
      },
      selectedUser: 0,
    };
  });
  useEffect(() => {
    updatePageTitle("screen.label.screen_transition_log");
    getScreenActivities("");
  }, []);
  const setStateCallbackRef = useRef(() => {});
  useEffect(() => {
    const callBack = setStateCallbackRef.current;
    callBack();
    setStateCallbackRef.current = () => {};
  }, [state]);
  const setState = (
    data: UserViewedPagesActivitiesScreenState,
    callback = () => {},
  ) => {
    setStateCallbackRef.current = callback;
    // @ts-expect-error TS7006
    customSetState((previousState) => {
      return {
        ...previousState,
        ...data,
      };
    });
  };
  const getScreenActivities = (filter = "") => {
    const { page, limit } = stateRef.current;
    const requestBuilder = {
      method: "getRequest",
      url: parseEndpoint(endpoints.screenActivities, {
        page: page - 1,
        limit,
        filter,
      }),
      data: {},
    };
    // @ts-expect-error TS2345
    apiRequest(requestBuilder, ({ data }) => {
      setState({
        screens: data,
      });
    });
  };

  return {
    state: stateRef.current,
    setState,
    getScreenActivities,
  };
};

export default useUserViewedPagesActivitiesScreen;
