import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { Badge, Input, Row } from "antd";
import DOMPurify from "dompurify";
import { EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { FC, useRef, useState } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect, useDispatch, useSelector } from "react-redux";

import { useProjectWatchContext } from "@/features/project/contexts/ProjectWatchContext";
import CommentActions from "@/Redux/CommentRedux";

import DownArrowIcon from "../../../assets/icons/down_arrow_icon.svg";
import UpArrowIcon from "../../../assets/icons/up_arrow_icon.svg";
import { IEditorComponentProps } from "../../../Screens/project/types/IEditorComponentProps";
import { convertToRawWithTrimmer } from "../../../utils/common";
import { getObjUUID } from "../../../utils/common";
import { Button, Card } from "../../atoms";
import { InsuAcceptButton, InsuRejectButton } from "../../atoms/buttons/shared";
import {
  DraftEditor as Editor,
  Form,
  uploadImageWithDraftEditor,
} from "../../molecules";
import { nodeConfig } from "../../molecules";
DOMPurify.addHook("uponSanitizeElement", (node, data) => {
  if (data.tagName === "iframe") {
    const src = node.getAttribute("src") || "";
    if (
      !(
        src.startsWith("https://www.youtube.com/") ||
        src.indexOf("vimeo.com") > -1
      )
    ) {
      return node.remove();
    }
  }
});

const EditorComponent: FC<IEditorComponentProps> = (props) => {
  // @ts-expect-error TS2339
  const project = useSelector((state) => state.projects.projectPayload);
  const projectId = project.uuid;

  const dispatch = useDispatch();
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [showCommentBox, setShowCommentBox] = useState(false);
  const [showEditor, setShowEditor] = useState(false);
  const contentState = {};
  const commentMaxHeight = 250;

  const { setShowWatchModal, isProjectWatch } = useProjectWatchContext();

  const editorRef = useRef(null);

  const focusEditor = () => {
    setTimeout(() => {
      // @ts-expect-error TS2532
      editorRef.current?.focusEditor();
    }, 1000);
  };

  // @ts-expect-error TS7006
  const createComment = (values) => {
    if (!isProjectWatch) {
      setShowWatchModal(true);
    }
    // @ts-expect-error TS2532
    const message = props.intl.formatMessage({
      id: "screen.label.comment_has_been_completed",
    });
    dispatch(
      CommentActions.commentCreateRequest(
        // @ts-expect-error TS2339
        {
          ...values,
          projectId,
        },
        message,
      ),
    );
  };

  const uploadImageCallBack = (file: any) => {
    return new Promise((resolve, reject) => {
      uploadImageWithDraftEditor({
        file,
        companyId: props.companyId,
        authToken: props.authToken,
        subLink: "notification",
        headerDetail: {
          type: "comments",
          projectId,
        },
      })
        .then((response: any) => {
          resolve(response);
        })

        .catch((error) => {
          reject(error);
        });
    });
  };

  // @ts-expect-error TS7006
  const submitComment = (e) => {
    e.preventDefault();
    if (editorState.getCurrentContent().hasText()) {
      const rawNodes = convertToRawWithTrimmer(editorState, false);
      // @ts-expect-error TS7034
      const mentions = [];
      // @ts-expect-error TS2339
      Object.values(rawNodes.entityMap).forEach((entity) => {
        // @ts-expect-error TS18046
        if (entity.type === "MENTION") {
          // @ts-expect-error TS18046
          mentions.push(entity.data.url);
        }
      });
      // @ts-expect-error TS2345
      const markup = draftToHtml(rawNodes);
      const rawHtml = DOMPurify.sanitize(markup, {
        FORBID_TAGS: ["a"],
        ADD_TAGS: ["iframe"],
        ADD_ATTR: [
          "allow",
          "allowfullscreen",
          "frameborder",
          "scrolling",
          "target",
        ],
        FORBID_ATTR: ["style"],
      });
      const content = JSON.stringify(rawNodes);
      if (content.trim().length > 0) {
        const value = content;
        setEditorState(EditorState.createEmpty());
        createComment({
          content: value,
          nodalData: true,
          // @ts-expect-error TS7005
          mentions,
          htmlContent: rawHtml,
        });
      }
    }
  };

  const closeEditor = () => {
    onCloseEditor();
    handleShowCommentBox();
    // @ts-expect-error TS2722
    props.changeBottomMargin(0);
  };

  // @ts-expect-error TS7006
  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };

  // @ts-expect-error TS7006
  const onShowEditor = async (event) => {
    setShowEditor(true);
    focusEditor();
  };

  const onCloseEditor = () => {
    setShowEditor(false);
  };

  const handleShowCommentBox = () => {
    setShowCommentBox(!showCommentBox);
  };

  // @ts-expect-error TS7006
  const renderBadgeCount = (count) => {
    return <span className="comment-badge">{count}</span>;
  };

  const renderCommentBox = () => {
    const { suggestions, scrollToPostComment } = props;
    return (
      <div className="comment-container" style={{ maxHeight: 400 }}>
        <div className="comments-wrapper" />
        <div className="comment-editor-wrapper">
          {showEditor ? (
            <Form style={{ width: "100%" }} layout={"vertical"}>
              <Form.Item>
                <Editor
                  ref={editorRef}
                  // @ts-expect-error TS2554
                  toolbar={nodeConfig(uploadImageCallBack)}
                  showCancel={true}
                  wrapperClassName="wrapper-class"
                  editorClassName="editor-class-comment"
                  toolbarClassName="toolbar-class"
                  // @ts-expect-error TS2532
                  placeholder={props.intl.formatMessage({
                    id: "screen.label.please_enter",
                  })}
                  editorState={editorState}
                  editorFocused={true}
                  initialContentState={contentState}
                  mention={{
                    separator: " ",
                    trigger: "@",
                    suggestions: suggestions,
                  }}
                  onEditorStateChange={onEditorStateChange}
                  localization={{
                    locale: "ja",
                  }}
                  // @ts-expect-error TS2322
                  onPostContent={submitComment}
                  onCancelContent={closeEditor}
                />
              </Form.Item>
              <Form.Item style={{ marginBottom: 0 }}>
                <InsuAcceptButton
                  onClick={(e) => {
                    submitComment(e);
                    scrollToPostComment();
                  }}
                  style={{
                    marginLeft: "0.1vw",
                    marginRight: "0.1vw",
                  }}
                >
                  <FormattedMessage id="screens.title.post" />
                </InsuAcceptButton>
                <InsuRejectButton
                  onClick={closeEditor}
                  style={{
                    marginLeft: "0.1vw",
                    marginRight: "0.1vw",
                  }}
                >
                  <FormattedMessage id="screen.label.close" />
                </InsuRejectButton>
              </Form.Item>
            </Form>
          ) : (
            <Row
              align="middle"
              justify="space-between"
              style={{ width: "100%" }}
            >
              <Input
                // @ts-expect-error TS2532
                value={props.intl.formatMessage({
                  id: "screen.label.please_enter",
                })}
                bordered={true}
                onFocus={onShowEditor}
                className="comment-input-box"
                style={{
                  width: "97%",
                }}
              />
            </Row>
          )}
        </div>
      </div>
    );
  };

  const renderCommentPlaceHolder = () => {
    return (
      <Row
        align="middle"
        justify="space-between"
        style={{
          paddingBottom: showCommentBox ? 0 : 0,
          borderBottomColor: "#F0F0F0",
          borderBottomWidth: showCommentBox ? 0 : 0,
          borderBottomStyle: "solid",
        }}
      >
        <div className="comment-top-item" onClick={props.scrollToPostComment}>
          {/*
           // @ts-expect-error TS2532 */}
          {props.numberOfUnreadComments > 0 ? (
            <Badge
              count={renderBadgeCount(props.numberOfUnreadComments)}
              size="small"
            >
              <span />
            </Badge>
          ) : (
            <span />
          )}
          <span className="comment-top-title ">
            <FormattedMessage id="screen.label.comment" />
          </span>
        </div>
        {!showCommentBox ? (
          <Input
            // @ts-expect-error TS2532
            value={props.intl.formatMessage({
              id: "screen.label.please_enter",
            })}
            bordered={true}
            onFocus={() => {
              // @ts-expect-error TS2554
              onShowEditor();
              handleShowCommentBox();
              // @ts-expect-error TS2722
              props.changeBottomMargin(commentMaxHeight);
            }}
            className="comment-input-box"
          />
        ) : (
          <div className="comment-input-box-holder" />
        )}

        <div className="comment-top-item">
          {!showCommentBox ? (
            <Button
              style={{
                color: "white",
                backgroundColor: "#1F86E9",
              }}
            >
              <FormattedMessage id={"screens.title.post"} />
            </Button>
          ) : null}
          <div style={{ marginLeft: 12 }}>
            {showCommentBox ? (
              <img
                src={DownArrowIcon}
                onClick={() => {
                  onCloseEditor();
                  handleShowCommentBox();
                  // @ts-expect-error TS2722
                  props.changeBottomMargin(0);
                }}
              />
            ) : (
              <img
                src={UpArrowIcon}
                onClick={() => {
                  // @ts-expect-error TS2554
                  onShowEditor();
                  handleShowCommentBox();
                  // @ts-expect-error TS2722
                  props.changeBottomMargin(commentMaxHeight);
                }}
              />
            )}
          </div>
        </div>
      </Row>
    );
  };

  return (
    <div className={"comment-sheet-container-float comment-sheet-floating"}>
      <Card
        bordered={false}
        style={{
          borderRadius: "10px",
          display: "flex",
          flexGrow: 1,
        }}
        bodyStyle={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          paddingTop: 12,
          paddingLeft: 24,
          paddingRight: 24,
          paddingBottom: 12,
        }}
      >
        {renderCommentPlaceHolder()}
        {showCommentBox && renderCommentBox()}
      </Card>
    </div>
  );
};
// @ts-expect-error TS7006
const mapStateToProps = (state) => ({
  authToken: state.auth.payload.token,
  companyId: getObjUUID(state.auth.payload.company),
});
// @ts-expect-error TS2769
export default injectIntl(connect(mapStateToProps, null)(EditorComponent));
