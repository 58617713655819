import { Action } from "@reduxjs/toolkit";
import { createActions, createReducer } from "reduxsauce";
import Immutable from "seamless-immutable";

import { ToAction, ToActionType } from "./types";

/* ------------- Types and Action Creators ------------- */
type IndustryActionCreators = {
  industryUpdateRequest: () => Action<"INDUSTRY_UPDATE_REQUEST">;
  industryUpdateSuccess: () => Action<"INDUSTRY_UPDATE_SUCCESS">;
  industryUpdateFailure: () => Action<"INDUSTRY_UPDATE_FAILURE">;

  industryDeleteRequest: () => Action<"INDUSTRY_DELETE_REQUEST">;
  industryDeleteSuccess: () => Action<"INDUSTRY_DELETE_SUCCESS">;
  industryDeleteFailure: () => Action<"INDUSTRY_DELETE_FAILURE">;

  industryCreateRequest: () => Action<"INDUSTRY_CREATE_REQUEST">;
  industryCreateSuccess: () => Action<"INDUSTRY_CREATE_SUCCESS">;
  industryCreateFailure: () => Action<"INDUSTRY_CREATE_FAILURE">;

  industryGetRequest: () => Action<"INDUSTRY_GET_REQUEST">;
  industryGetSuccess: () => Action<"INDUSTRY_GET_SUCCESS">;
  industryGetFailure: () => Action<"INDUSTRY_GET_FAILURE">;
};
type IndustryAction = ToAction<IndustryActionCreators>;
type IndustryActionType = ToActionType<IndustryAction>;

const { Types, Creators } = createActions<
  IndustryActionType,
  IndustryActionCreators
>({
  industryUpdateRequest: ["data"],
  industryUpdateSuccess: null,
  industryUpdateFailure: ["errors"],

  industryDeleteRequest: ["data", "message"],
  industryDeleteSuccess: null,
  industryDeleteFailure: ["errors"],

  industryCreateRequest: ["data", "message"],
  industryCreateSuccess: null,
  industryCreateFailure: ["errors"],

  industryGetRequest: ["data"],
  industryGetSuccess: ["industryPayload"],
  industryGetFailure: ["errors"],
  /// update nav header in the case of industry editor open
});

export const IndustryTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
type IndustryState = {
  fetching: boolean;
  errors: unknown;
  industryPayload: {
    rows: unknown[];
    count: number;
  };
};

export const INITIAL_STATE = Immutable<IndustryState>({
  fetching: false,
  errors: {},
  industryPayload: {
    rows: [],
    count: 0,
  },
});
type ImmIndustryState = typeof INITIAL_STATE;

/* ------------- Reducers ------------- */

const industryCreateRequest = (state: ImmIndustryState) =>
  state.merge({ fetching: true });

const industryCreateSuccess = (state: ImmIndustryState) => {
  return state.merge({ fetching: false });
};

// @ts-expect-error TS7031
const industryCreateFailure = (state: ImmIndustryState, { errors }) =>
  state.merge({ fetching: false, errors });

const industryDeleteRequest = (state: ImmIndustryState) =>
  state.merge({ fetching: true });

const industryDeleteSuccess = (state: ImmIndustryState) => {
  return state.merge({ fetching: false });
};

// @ts-expect-error TS7031
const industryDeleteFailure = (state: ImmIndustryState, { errors }) =>
  state.merge({ fetching: false, errors });

const industryUpdateRequest = (state: ImmIndustryState) =>
  state.merge({ fetching: true });

const industryUpdateSuccess = (state: ImmIndustryState) => {
  return state.merge({ fetching: false });
};

// @ts-expect-error TS7031
const industryUpdateFailure = (state: ImmIndustryState, { errors }) =>
  state.merge({ fetching: false, errors });

const industryGetRequest = (state: ImmIndustryState) =>
  state.merge({ fetching: true });

// @ts-expect-error TS7031
const industryGetSuccess = (state: ImmIndustryState, { industryPayload }) => {
  return state.merge({ fetching: false, industryPayload });
};

// @ts-expect-error TS7031
const industryGetFailure = (state: ImmIndustryState, { errors }) =>
  state.merge({ fetching: false, errors });
/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer<ImmIndustryState, IndustryAction>(
  INITIAL_STATE,
  {
    [Types.INDUSTRY_CREATE_REQUEST]: industryCreateRequest,
    [Types.INDUSTRY_CREATE_SUCCESS]: industryCreateSuccess,
    [Types.INDUSTRY_CREATE_FAILURE]: industryCreateFailure,

    [Types.INDUSTRY_DELETE_REQUEST]: industryDeleteRequest,
    [Types.INDUSTRY_DELETE_SUCCESS]: industryDeleteSuccess,
    [Types.INDUSTRY_DELETE_FAILURE]: industryDeleteFailure,

    [Types.INDUSTRY_GET_REQUEST]: industryGetRequest,
    [Types.INDUSTRY_GET_SUCCESS]: industryGetSuccess,
    [Types.INDUSTRY_GET_FAILURE]: industryGetFailure,

    [Types.INDUSTRY_UPDATE_REQUEST]: industryUpdateRequest,
    [Types.INDUSTRY_UPDATE_SUCCESS]: industryUpdateSuccess,
    [Types.INDUSTRY_UPDATE_FAILURE]: industryUpdateFailure,
  },
);
