import { Col } from "antd";
import { useContext } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import { ContentContext } from "@/Provider";

import { validUUIDV4 } from "../../../utils/common";
import {
  PlanDetails,
  ProjectMenuContainer,
  ProjectTabMenuItem,
} from "../../molecules";

export const ProjectTabComponent = () => {
  const { pathname } = useLocation();
  const { user } = useContext(ContentContext);
  // @ts-expect-error TS2339
  const project = useSelector((state) => state.projects.projectPayload);
  // @ts-expect-error TS2339
  const tasks = useSelector((state) => state.projects.tasksPayload);

  const isSuperUser = user.type === "admin" && user.isSuper;

  const canViewOptionMenu = () => {
    if (!project.isPrivate) return true;
    return project.isTeamMember || isSuperUser;
  };

  const getProjectId = () => {
    let projectId = null;
    const path = window.location.href.split(/[/?]/);
    const Ids = path
      .map(function (x) {
        // @ts-expect-error TS2345
        return (validUUIDV4(x) || !isNaN(x)) && x;
      })
      .filter(Boolean);
    if (Ids && Ids.length > 0) projectId = String(Ids[0]);
    return projectId;
  };

  const renderTaskMenuItem = () => {
    if (user.company.currentPlan === "PRO") {
      return (
        <ProjectTabMenuItem key={`/dashboard/project.tasks/${getProjectId()}`}>
          <Link to={`/dashboard/project.tasks/${getProjectId()}`}>
            <FormattedMessage id="screen.label.task" />
          </Link>
        </ProjectTabMenuItem>
      );
    } else if (
      user.company.currentPlan === "BASIC" ||
      user.company.currentPlan === "DORMANT"
    ) {
      if (tasks && Array.isArray(tasks.rows) && tasks.rows.length > 0) {
        return (
          <ProjectTabMenuItem
            key={`/dashboard/project.tasks/${getProjectId()}`}
            disabled
          >
            <Link to={`/dashboard/project.tasks/${getProjectId()}`}>
              <span className="unhoverable-text">
                <FormattedMessage id="screen.label.task" />
              </span>
              <PlanDetails>PRO</PlanDetails>
            </Link>
          </ProjectTabMenuItem>
        );
      } else {
        return (
          <ProjectTabMenuItem
            key={`/dashboard/project.tasks/${getProjectId()}`}
            disabled
          >
            <span className="unhoverable-text">
              <FormattedMessage id="screen.label.task" />
            </span>
            <PlanDetails>PRO</PlanDetails>
          </ProjectTabMenuItem>
        );
      }
    }
  };

  return (
    <Col span={19} className="project-menu" style={{ flex: 1 }}>
      <ProjectMenuContainer
        defaultSelectedKeys={[pathname]}
        selectedKeys={[pathname]}
        mode="horizontal"
        theme="light"
      >
        {/* Topメニュー */}
        <ProjectTabMenuItem key={`/dashboard/project.top/${getProjectId()}`}>
          <Link to={`/dashboard/project.top/${getProjectId()}`}>
            <span>
              <FormattedMessage id="screen.label.top" />
            </span>
          </Link>
        </ProjectTabMenuItem>

        {/* Fileメニュー */}
        {canViewOptionMenu() ? (
          <ProjectTabMenuItem key={`/dashboard/project.file/${getProjectId()}`}>
            <Link to={`/dashboard/project.file/${getProjectId()}`}>
              <span>
                <FormattedMessage id="screen.label.file" />
              </span>
            </Link>
          </ProjectTabMenuItem>
        ) : null}

        <ProjectTabMenuItem key={`/dashboard/project.team/${getProjectId()}`}>
          <Link to={`/dashboard/project.team/${getProjectId()}`}>
            <span>
              <FormattedMessage id="screen.label.member" />
            </span>
          </Link>
        </ProjectTabMenuItem>

        {canViewOptionMenu() && renderTaskMenuItem()}

        {canViewOptionMenu() ? (
          <ProjectTabMenuItem
            key={`/dashboard/project.archive/${getProjectId()}`}
          >
            <Link to={`/dashboard/project.archive/${getProjectId()}`}>
              <span>
                <FormattedMessage id="screen.label.archive" />
              </span>
            </Link>
          </ProjectTabMenuItem>
        ) : null}

        {project.isProjectOwner ? (
          <ProjectTabMenuItem
            key={`/dashboard/project.notices/${getProjectId()}`}
          >
            <Link to={`/dashboard/project.notices/${getProjectId()}`}>
              <span>
                <FormattedMessage id="screen.label.notification" />
              </span>
            </Link>
          </ProjectTabMenuItem>
        ) : null}

        {project.isTeamMember ? (
          <ProjectTabMenuItem
            key={`/dashboard/project.reviews/${getProjectId()}`}
          >
            <Link to={`/dashboard/project.reviews/${getProjectId()}`}>
              <span>
                <FormattedMessage id="screen.label.examination_outcome" />
              </span>
            </Link>
          </ProjectTabMenuItem>
        ) : null}
      </ProjectMenuContainer>
    </Col>
  );
};
