import { Col, Form, Row } from "antd";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { ButtonCV, ButtonUndo, IconEdit } from "../../../../Components/atoms";
import { Input } from "../../../../Components/molecules";

type Props = {
  onChangePassword: (password: string) => void;
};
const fakePassword = `11111111`;
const ProfilePasswordForm = (props: Props) => {
  const intl = useIntl();
  const { onChangePassword } = props;
  const [password, setPassword] = useState(fakePassword);
  const [editable, setEditable] = useState(false);

  return (
    <Form.Item
      label={intl.formatMessage({
        id: "screen.label.password",
      })}
    >
      <Row
        align="middle"
        justify="space-between"
        style={{
          marginTop: 6,
          paddingBottom: 6,
          borderBottomColor: "#dddddd",
          borderBottomWidth: 1,
          borderBottomStyle: "solid",
        }}
      >
        <Col flex={1}>
          <Input
            disabled={!editable}
            bordered={false}
            className="user-email"
            autoComplete="new-password" //Prevent chrome autofill of password
            type="password"
            placeholder={intl.formatMessage({
              id: "screen.label.password",
            })}
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />
        </Col>
        <Col flex="none" className="offset-left-16">
          {editable ? (
            <Row>
              <ButtonCV
                className="offset-right-16"
                onClick={() => {
                  onChangePassword(password);
                }}
              >
                {intl.formatMessage({
                  id: "screen.label.change_your_password",
                })}
              </ButtonCV>
              <ButtonUndo
                style={{ fontSize: 11 }}
                onClick={() => {
                  setEditable(false);
                  setPassword(fakePassword);
                }}
              >
                <FormattedMessage id={"screen.label.cancel"} />
              </ButtonUndo>
            </Row>
          ) : (
            <div
              onClick={() => {
                setPassword("");
                setEditable(true);
              }}
            >
              {IconEdit}
            </div>
          )}
        </Col>
      </Row>
    </Form.Item>
  );
};

export default ProfilePasswordForm;
