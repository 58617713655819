import { convertToRaw, EditorState } from "draft-js";
import { useEffect, useState } from "react";

import {
  DraftEditor,
  nodeConfig,
  uploadImageWithDraftEditor,
} from "@/Components/molecules";
import {
  convertFromRawWithTrimmer,
  convertYVLinksToEmbed,
  sanitizeEditorStateMarkupHtml,
} from "@/utils/common";

import styles from "./styles.module.scss";
import { WYSIWYGEditorProps } from "./type";

export const WYSIWYGEditor = ({
  onChange,
  value,
  companyUuid,
  userToken,
  uploadImageConfig,
  label,
}: WYSIWYGEditorProps) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [updated, setUpdated] = useState(false);

  useEffect(() => {
    if (!updated) {
      const defaultValue = value ? value : "";
      setEditorState(
        sanitizeEditorStateMarkupHtml(
          EditorState.createWithContent(
            convertFromRawWithTrimmer(defaultValue),
          ),
        ),
      );
    }
  }, [updated, value]);

  const onEditorStateChange = (editorState: EditorState) => {
    setUpdated(true);
    setEditorState(editorState);

    return onChange(
      JSON.stringify(convertToRaw(editorState.getCurrentContent())),
    );
  };

  const uploadImageCallBack = (file: File) => {
    return new Promise((resolve, reject) => {
      uploadImageWithDraftEditor({
        file,
        companyId: companyUuid,
        authToken: userToken,
        ...uploadImageConfig,
      })
        .then((response: unknown) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  return (
    <div className={styles.container}>
      {label && <label className={styles.label}>{label}</label>}
      <DraftEditor
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        toolbar={nodeConfig(
          uploadImageCallBack,
          convertYVLinksToEmbed.bind(this, userToken, companyUuid),
        )}
        editorClassName={styles.editor}
      />
    </div>
  );
};
