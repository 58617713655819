import { Card, Col, Row } from "antd";
import { Table } from "antd";
import React from "react";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

import { ReduxDispatch, ReduxState } from "@/Redux/types";

import { ButtonOption } from "../../../../Components/atoms";
import {
  CustomPagination,
  SearchField,
} from "../../../../Components/molecules";
import { NewsTblColumns } from "../../../../Components/organisms";
import ApiActions, {
  ApiRequestData,
  ApiSuccessCallback,
} from "../../../../Redux/ApiRedux";
import NewsActions from "../../../../Redux/NewsRedux";
import { endpoints, parseEndpoint } from "../../../../Services/endpoints";
import useLocalStorage from "../../../../utils/localStorage";
const PageSaveId = "GlobalNews";
const pageKey = `pagination_page_${PageSaveId}`;
const limitKey = `pagination_limit_${PageSaveId}`;
type OwnProps = {
  history: Object;
};
type StateProps = {
  news: {
    // @ts-expect-error TS2314
    rows: Array;
    count: number;
  };
  auth: Object;
};
type ActionProps = {
  dispatchGetNews: (data: Object) => void;
  dispatchDelNews: (id: string) => void;
  dispatchUpdateNews: (data: Object) => void;
  dispatchApiRequest: () => void;
};
type Props = StateProps & ActionProps & OwnProps;
function NewItemsScreen(props: Props) {
  const [page, setPage] = useLocalStorage(pageKey, 1);
  const [limit, setLimit] = useLocalStorage(
    limitKey,
    process.env.REACT_APP_PAGE_SIZE,
  );
  const [searchTxt, setSearchTxt] = React.useState("");
  const [sortOrder, setSortOrder] = React.useState("");
  const [sortField, setSortField] = React.useState("");
  const [companies, setCompanies] = React.useState([]);
  const intl = useIntl();
  React.useEffect(() => {
    getCompanies();
  }, []);
  React.useEffect(() => {
    getNews();
  }, [page, limit, sortOrder, sortField]);
  const getCompanies = () => {
    const requestBuilder = {
      method: "getRequest",
      url: parseEndpoint(endpoints.companies),
      data: {},
    };
    // @ts-expect-error TS2554
    props.dispatchApiRequest(requestBuilder, ({ data }) => {
      if (data.rows && data.rows.length > 0) {
        setCompanies(data.rows);
      }
    });
  };
  const getNews = () => {
    props.dispatchGetNews({
      page: page - 1,
      limit,
      filter: getSortFilter(),
      isGlobal: true,
    });
  };
  const getSortFilter = () => {
    const filterObj = {};
    if (sortField !== "") {
      // @ts-expect-error TS7053
      filterObj["sortField"] = sortField;
    }
    if (sortOrder !== "") {
      // @ts-expect-error TS7053
      filterObj["sortOrder"] = sortOrder;
    }
    if (searchTxt !== "") {
      // @ts-expect-error TS7053
      filterObj["name"] = searchTxt;
      // @ts-expect-error TS7053
      filterObj["content"] = searchTxt;
      // @ts-expect-error TS7053
      filterObj["url"] = searchTxt;
      // @ts-expect-error TS7053
      filterObj["pageTitle"] = searchTxt;
    }
    return filterObj;
  };
  // @ts-expect-error TS7006
  const onGotoItem = (id) => {
    // @ts-expect-error TS2339
    props.history.push(`/global-library/news/${id}`);
  };
  // @ts-expect-error TS7006
  const onDeleteItem = (id) => {
    props.dispatchDelNews(id);
  };
  // @ts-expect-error TS7006
  const onUpdateItem = (id) => {
    // @ts-expect-error TS2339
    props.history.push(`/global-library/news-update/${id}`);
  };
  // @ts-expect-error TS7006
  const onTableChange = (pagination, filters, sorter, extra) => {
    if (sorter) {
      const order = sorter.order && sorter.order === "descend" ? "asc" : "desc";
      setSortOrder(order);
      setSortField(sorter.field);
    }
  };
  // @ts-expect-error TS7006
  const changePage = (pageNum, limitNum) => {
    setPage(pageNum);
    setLimit(limitNum);
  };
  const renderNewTask = (isSuper = false) => {
    return (
      <div
        style={{
          float: "right",
          marginTop: -40,
        }}
      >
        <ButtonOption
          style={{
            width: 200,
            marginLeft: 20,
          }}
        >
          <Link to={`/global-library/news-create`}>
            {intl.formatMessage({
              id: "screen.label.new_register",
            })}
          </Link>
        </ButtonOption>
      </div>
    );
  };
  const renderTitle = () => {
    return (
      <h3 style={{ fontWeight: "bold" }}>
        {intl.formatMessage({
          id: "screen.label.from_issuper",
        })}
      </h3>
    );
  };
  const renderFilterTopItems = () => {
    return (
      <Row>
        <Col xs={24} sm={6} md={6}>
          <SearchField
            placeholder={intl.formatMessage({
              id: "screen.label.please_enter_keyword",
            })}
            enterButton={intl.formatMessage({
              id: "screen.label.retrieval",
            })}
            value={searchTxt}
            onChange={(e) => {
              setSearchTxt(e.target.value);
            }}
            // @ts-expect-error TS2322
            onSearch={(value) => {
              setPage(1);
              getNews();
            }}
          />
        </Col>
      </Row>
    );
  };
  return (
    <>
      {renderTitle()}
      {renderNewTask()}
      <Card>
        {/*
         // @ts-expect-error TS2554 */}
        {renderFilterTopItems(searchTxt, setSearchTxt, getNews)}
        <br />
        <Table
          pagination={false}
          rowKey={"id"}
          columns={NewsTblColumns({
            companies: companies,
            // @ts-expect-error TS2339
            user: props.auth.payload,
            onGoto: onGotoItem,
            onUpdate: onUpdateItem,
            onDelete: onDeleteItem,
          })}
          onChange={onTableChange}
          dataSource={props.news ? props.news.rows : []}
          className="ant-table-x-scroll"
        />
        <br />
        <CustomPagination
          saveId={PageSaveId}
          count={props.news ? props.news.count : 0}
          initPage={page}
          initLimit={limit}
          onChangePage={changePage}
        />
      </Card>
    </>
  );
}
const mapStateToProps = (state: ReduxState) => ({
  news: state.news.newsPayload,
  auth: state.auth,
});
const mapDispatchToProps = (dispatch: ReduxDispatch) => ({
  // @ts-expect-error TS7006
  dispatchGetNews: (data) => dispatch(NewsActions.newsGetRequest(data)),
  // @ts-expect-error TS7006
  dispatchDelNews: (id) => dispatch(NewsActions.newsDeleteRequest(id)),
  // @ts-expect-error TS7006
  dispatchUpdateNews: (data) => dispatch(NewsActions.newsUpdateRequest(data)),
  dispatchApiRequest: (data: ApiRequestData, callback: ApiSuccessCallback) =>
    dispatch(ApiActions.apiRequest(data, callback)),
});
export default withRouter(
  // @ts-expect-error TS2345
  connect(mapStateToProps, mapDispatchToProps)(NewItemsScreen),
);
