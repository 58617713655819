import React from "react";
import { Redirect, Route } from "react-router-dom";

import { withContext } from "../../Provider";
import { RouteProps } from "./types/RouteProps";

const PrivateSuperAdminRoute: React.FC<RouteProps> = ({
  component: Component,
  location,
  context,
  ...rest
}: RouteProps) => {
  const user = context.user;
  return (
    <Route
      {...rest}
      render={(props) =>
        user.isSuper ? (
          <Component {...props} context={context} />
        ) : (
          <Redirect to={{ pathname: "/login", state: { from: location } }} />
        )
      }
    />
  );
};
export default withContext(PrivateSuperAdminRoute);
