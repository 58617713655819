import { PlusOutlined } from "@ant-design/icons";
import { Col, Modal, Row } from "antd";
import moment from "moment";
import PropTypes from "prop-types";
import { FC, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import Immutable from "seamless-immutable";

import { Button, ButtonCV, Card, Rate, Table } from "../../Components/atoms";
import { InsuPrimaryButton } from "../../Components/atoms/buttons/shared";
import {
  CustomPagination,
  Form,
  Option,
  SearchDefault,
  Select,
} from "../../Components/molecules";
import { ProjectTaskModal } from "../../Components/molecules";
import { ProjectTaskCheckbox } from "../../Components/molecules/project/ProjectTaskCheckbox";
import ProjectActions from "../../Redux/ProjectRedux";
import TeamActions from "../../Redux/TeamRedux";
import ProjectTaskCreateScreen from "../project/ProjectTaskCreateScreen";
import useProjectTasksScreen from "./hooks/useProjectTasksScreen";
import { withCheckProjectAccess } from "./permissionWrapper";
import { IProjectTasksScreenProps } from "./types/IProjectTasksScreenProps";
const PageSaveId = "ProjectTasksScreen";
const R = require("ramda");
const ProjectTasksScreen: FC<IProjectTasksScreenProps> = function (props) {
  const {
    getProjectId,
    setState,
    handleReset,
    state,
    intl,
    handleChangeStars,
    onSortParameter,
    fetchTask,
    handleChange,
    handleOk,
    onTableChange,
    handleCancel,
    handleCreateModalOk,
    handleCreateModalClose,
    setQueryProjectTask,
    queryProjectTask,
    performAdvanceTaskSearch,
  } = useProjectTasksScreen(props);

  const { user } = props.context;

  // @ts-expect-error TS7006
  const getColumnPriorityProps = (dataIndex) => ({
    filterDropdown: function _fn({
      // eslint-disable-next-line react/prop-types
      // @ts-expect-error TS7031
      setSelectedKeys,
      // eslint-disable-next-line react/prop-types
      // @ts-expect-error TS7031
      selectedKeys,
      // eslint-disable-next-line react/prop-types
      // @ts-expect-error TS7031
      confirm,
      // eslint-disable-next-line react/prop-types
      // @ts-expect-error TS7031
      clearFilters,
    }) {
      return (
        <div style={{ padding: 8 }}>
          <Rate
            style={{
              width: 188,
              marginBottom: 8,
              display: "block",
            }}
            onChange={(e) => {
              setState({ searchPriority: e });
              return setSelectedKeys(e ? [e] : []);
            }}
            value={state.searchPriority}
          />
          <ButtonCV
            type="primary"
            onClick={() => {
              performAdvanceTaskSearch();
            }}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </ButtonCV>
          <Button
            onClick={() => {
              clearFilters();
              setState(
                {
                  searchPriority: null,
                },
                () => {
                  performAdvanceTaskSearch();
                },
              );
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      );
    },
    // @ts-expect-error TS7006
    onFilter: (value, record) => record[dataIndex] === value,
  }); //

  const translationMap = useMemo(() => {
    return {
      PENDING: intl.formatMessage({
        id: "screen.label.unexecuted",
      }),
      PROGRESS: intl.formatMessage({
        id: "screen.label.in_progress",
      }),
      DONE: intl.formatMessage({
        id: "screen.label.completion",
      }),
    };
  }, []);
  // @ts-expect-error TS7006
  const _statusOptions = (text, index) => {
    if (text === "PENDING") {
      return (
        <>
          <Option key={`${index}_2`} className="PROGRESS" value={"PROGRESS"}>
            {intl.formatMessage({
              id: "screen.label.in_progress",
            })}
          </Option>
          <Option key={`${index}_1`} className="DONE" value={"DONE"}>
            {intl.formatMessage({
              id: "screen.label.completion",
            })}
          </Option>
        </>
      );
    } else if (text === "DONE") {
      return (
        <>
          <Option key={`${index}_1`} className="PENDING" value={"PENDING"}>
            {intl.formatMessage({
              id: "screen.label.unexecuted",
            })}
          </Option>
          <Option key={`${index}_2`} className="PROGRESS" value={"PROGRESS"}>
            {intl.formatMessage({
              id: "screen.label.in_progress",
            })}
          </Option>
        </>
      );
    } else if (text === "PROGRESS") {
      return (
        <>
          <Option value={"PENDING"} className="PENDING">
            {intl.formatMessage({
              id: "screen.label.unexecuted",
            })}
          </Option>
          <Option value={"DONE"} className="DONE">
            {intl.formatMessage({
              id: "screen.label.completion",
            })}
          </Option>
        </>
      );
    }
    return <></>;
  };
  const columns = [
    {
      title: "ID",
      dataIndex: "key",
      key: "key",
    },
    {
      title: intl.formatMessage({
        id: "screen.label.task_name",
      }),
      dataIndex: "name",
      key: "name",
      // @ts-expect-error TS7006
      render: (text, data, index) => {
        const row = Immutable.asMutable(data, { deep: true });
        let name = (
          <span
            style={{
              maxWidth: 300,
              wordWrap: "break-word",
              display: "inline-block",
            }}
          >
            {text}
          </span>
        );
        if (
          state.planEnabled &&
          (state.isTeamMember || user.type === "admin")
        ) {
          name = (
            <Button
              type={"link"}
              style={{
                whiteSpace: "break-spaces",
                wordWrap: "break-word",
              }}
              onClick={() => {
                setState({
                  visible: true,
                  activeState: index,
                  activeRow: row,
                  title: row.name,
                  description: row.description,
                  // @ts-expect-error TS7006
                  incharge: row.incharge.map((inchargeUserID) =>
                    parseInt(inchargeUserID),
                  ),
                  status: row.status,
                  priority: row.priority,
                  timelimit: moment(
                    row.timelimit ? row.timelimit : new Date(),
                  ).local(),
                });
              }}
            >
              <span
                style={{
                  maxWidth: 300,
                  wordWrap: "break-word",
                  display: "inline-block",
                  textAlign: "start",
                }}
              >
                {text}
              </span>
            </Button>
          );
        }
        return name;
      },
    },
    {
      title: intl.formatMessage({
        id: "screen.label.person_in_charge",
      }),
      dataIndex: "_incharge",
      key: "_incharge",
      // @ts-expect-error TS7006
      render: (_, row: any) => {
        const incharge: Array<number> = Array.isArray(row.incharge)
          ? row.incharge
          : [];
        // @ts-expect-error TS2322
        const teamMemebers: Array<object> = props.teams.teamMembersPayload.rows;
        return teamMemebers
          .filter((singleMembers) => {
            // @ts-expect-error TS2339
            return incharge.includes(singleMembers.id);
          })
          .map((singleMembers, index) => {
            // @ts-expect-error TS2339
            return `${singleMembers.name} `;
          });
      },
    },
    {
      title: intl.formatMessage({
        id: "screen.label.created_date",
      }),
      dataIndex: "createdAt",
      key: "createdAt",
      // @ts-expect-error TS7006
      render: (text) => moment(text).local().format("YYYY/MM/DD"),
    },
    {
      title: intl.formatMessage({
        id: "screen.label.deadline",
      }),
      dataIndex: "timelimit",
      key: "timelimit",
      sorter: true,
      sortOrder: state.currentTimelimitSort,
      // @ts-expect-error TS7006
      render: (text) =>
        text
          ? moment(text).local().format("YYYY/MM/DD")
          : intl.formatMessage({
              id: "screen.label.unset",
            }),
    },
    {
      title: intl.formatMessage({
        id: "screen.label.situation",
      }),
      dataIndex: "status",
      key: "status",
      width: "145px",
      // @ts-expect-error TS7006
      render: (text, row, index) => {
        if (
          state.planEnabled
          //&& row.incharge.indexOf(user.id) > -1
          // based on comment of Mr. @Uddy, we need not check
          // if user is incharge of the task
        )
          return (
            <Select
              // @ts-expect-error TS7053
              defaultValue={translationMap[text]}
              style={{
                width: "145px",
              }}
              onChange={(value) => {
                props.dispatchUpdateTask(getProjectId(), row.key, {
                  status: value,
                });
                setTimeout(() => {
                  props.dispatchFetchTasks(getProjectId());
                }, 1100);
              }}
            >
              {_statusOptions(text, index)}
            </Select>
          );
        else return <></>;
      },
    },
    {
      title: intl.formatMessage({
        id: "screen.label.priority",
      }),
      dataIndex: "priority",
      key: "priority",
      // @ts-expect-error TS7006
      render: (text, row, index) => {
        let rate = (
          <Rate
            disabled
            onChange={(value) => handleChangeStars(value, index)}
            value={parseInt(text)}
          />
        );
        if (
          state.planEnabled &&
          (state.isTeamMember || user.type === "admin")
        ) {
          rate = (
            <Rate
              onChange={(value) => handleChangeStars(value, index)}
              value={parseInt(text)}
            />
          );
        }
        return rate;
      },
      sortOrder: state.currentPrioritySort,
      sorter: true,
      ...getColumnPriorityProps("priority"),
    },
  ]; //
  const createTask = (
    <InsuPrimaryButton
      icon={<PlusOutlined />}
      style={{
        float: "right",
      }}
      onClick={() => {
        setState({ visibleCreateModal: true });
      }}
    >
      {intl.formatMessage({
        id: "screen.label.add_new",
      })}
    </InsuPrimaryButton>
  );
  return (
    <div className="project-task">
      <Card>
        <Row align="middle" justify="space-between">
          <Col>
            <SearchDefault
              placeholder={intl.formatMessage({
                id: "screen.label.please_enter_keyword",
              })}
              size="large"
              enterButton={intl.formatMessage({
                id: "screen.label.retrieval",
              })}
              value={state.searchText}
              onChange={(e) => {
                queryProjectTask.User.name = e.target.value;
                queryProjectTask.Task.description = e.target.value;
                queryProjectTask.Task.title = e.target.value;

                setQueryProjectTask(queryProjectTask);

                setState({
                  searchText: e.target.value,
                  // completedCheckBoxState: false,
                  // pendingCheckBoxState: false,
                  // inProgressCheckBoxState: false
                });
              }}
              onSearch={(value) => {
                setState({ page: 1 }, () => {
                  performAdvanceTaskSearch();
                });
              }}
            />
          </Col>
          <Col>
            <ProjectTaskCheckbox
              checked={queryProjectTask.Task.status.includes("PENDING")}
              text={intl.formatMessage({
                id: "screen.label.unexecuted",
              })}
              onChange={() => {
                const newQueryProjectTask = R.clone(queryProjectTask);
                if (queryProjectTask.Task.status.includes("PENDING")) {
                  newQueryProjectTask.Task.status =
                    queryProjectTask.Task.status.filter((s) => {
                      return s !== "PENDING";
                    });
                } else {
                  newQueryProjectTask.Task.status =
                    queryProjectTask.Task.status.concat("PENDING");
                }
                setQueryProjectTask(newQueryProjectTask);
              }}
            />
          </Col>
          <Col>
            <ProjectTaskCheckbox
              checked={queryProjectTask.Task.status.includes("PROGRESS")}
              text={intl.formatMessage({
                id: "screen.label.in_progress",
              })}
              onChange={() => {
                const newQueryProjectTask = R.clone(queryProjectTask);
                if (queryProjectTask.Task.status.includes("PROGRESS")) {
                  newQueryProjectTask.Task.status =
                    queryProjectTask.Task.status.filter((s) => {
                      return s !== "PROGRESS";
                    });
                } else {
                  newQueryProjectTask.Task.status =
                    queryProjectTask.Task.status.concat("PROGRESS");
                }
                setQueryProjectTask(newQueryProjectTask);
              }}
            />
          </Col>
          <Col>
            <ProjectTaskCheckbox
              text={intl.formatMessage({
                id: "screen.label.completion",
              })}
              checked={queryProjectTask.Task.status.includes("DONE")}
              onChange={() => {
                const newQueryProjectTask = R.clone(queryProjectTask);
                if (queryProjectTask.Task.status.includes("DONE")) {
                  newQueryProjectTask.Task.status =
                    queryProjectTask.Task.status.filter((s) => {
                      return s !== "DONE";
                    });
                } else {
                  newQueryProjectTask.Task.status =
                    queryProjectTask.Task.status.concat("DONE");
                }
                setQueryProjectTask(newQueryProjectTask);
              }}
            />
          </Col>
          <Col>
            <Form layout={"inline"} name="basic">
              <Form.Item>
                <Select
                  defaultValue={"id"}
                  value={state.sort}
                  onChange={onSortParameter}
                  style={{ width: 120 }}
                >
                  <Option value="id">
                    ID
                    <FormattedMessage id="screen.label.order" />
                  </Option>
                  <Option value="timelimit">
                    {intl.formatMessage({
                      id: "screen.label.date_order",
                    })}
                  </Option>
                  <Option value="priority">
                    {intl.formatMessage({
                      id: "screen.label.order_priority",
                    })}
                  </Option>
                </Select>
              </Form.Item>
            </Form>
          </Col>
          <Col>
            {state.planEnabled && props.projects.projectPayload.isTeamMember ? (
              createTask
            ) : (
              <></>
            )}
          </Col>
        </Row>

        <Table
          pagination={false}
          rowKey={"key"}
          columns={columns}
          onChange={onTableChange}
          dataSource={
            state.enableView
              ? props.projects.tasksPayload
                ? props.projects.tasksPayload.rows
                : []
              : []
          }
          className="ant-table-x-scroll"
          style={{ marginTop: "40px" }}
        />
        <CustomPagination
          saveId={PageSaveId}
          count={props.projects.tasksPayload.count}
          initPage={state.page}
          initLimit={state.limit}
          onChangePage={(page, limit) => {
            setState(
              {
                page,
                limit,
              },
              () => {
                fetchTask();
              },
            );
          }}
        />
      </Card>
      <Modal
        width={"42vw"}
        bodyStyle={{
          paddingLeft: "10vw",
          paddingRight: "10vw",
        }}
        title={intl.formatMessage({
          id: "screen.label.task_name",
        })}
        visible={state.visibleCreateModal}
        onCancel={handleCreateModalClose}
        footer={null}
      >
        <ProjectTaskCreateScreen
          // @ts-expect-error TS2322
          props={props}
          visible={state.visibleCreateModal}
          projectId={getProjectId()}
          handleOk={handleCreateModalOk}
          handleCancel={handleCreateModalClose}
        />
      </Modal>
      <ProjectTaskModal
        timelimit={state.timelimit}
        status={state.status}
        priority={state.priority}
        description={state.description}
        title={state.title}
        showModal={state.visible}
        incharge={state.incharge}
        isCreating={false}
        // @ts-expect-error TS2322
        teamMembers={props.teams.teamMembersPayload.rows}
        handleChange={handleChange}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
    </div>
  );
};

// @ts-expect-error TS7006
const mapStateToProps = (state) => ({
  teams: state.teams,
  users: state.user,
  projects: state.projects,
});
// @ts-expect-error TS7006
const mapDispatchToProps = (dispatch) => ({
  // @ts-expect-error TS7006
  dispatchFetchUsers: (data) => dispatch(TeamActions.teamGetRequest(data)),
  // @ts-expect-error TS7006
  dispatchFetchTasks: (id, query) =>
    // @ts-expect-error TS2554
    dispatch(ProjectActions.projectTaskGetsRequest(id, query)),
  // @ts-expect-error TS7006
  dispatchUpdateTask: (id, taskId, update) =>
    // @ts-expect-error TS2554
    dispatch(ProjectActions.projectTaskUpdateRequest(id, taskId, update)),
});
ProjectTasksScreen.propTypes = {
  // @ts-expect-error TS2322
  context: PropTypes.object,
  teams: PropTypes.object,
  users: PropTypes.object,
  projects: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  // @ts-expect-error TS2322
  dispatchFetchUsers: PropTypes.func,
  // @ts-expect-error TS2322
  dispatchFetchTasks: PropTypes.func,
  // @ts-expect-error TS2322
  dispatchUpdateTask: PropTypes.func,
};
export default withCheckProjectAccess(
  // @ts-expect-error TS2345
  connect(mapStateToProps, mapDispatchToProps)(ProjectTasksScreen),
);
