import moment from "moment";
import { FC } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import { IconFlag } from "@/Components/atoms";
import { LocalDateComponent } from "@/Components/molecules/project/ProjectTopScreen";
import { isEmptyString } from "@/editor/src/utils/common";
import { ReduxState } from "@/Redux/types";

export const ProjectReminderBox: FC = () => {
  const intl = useIntl();
  const project = useSelector(
    (state: ReduxState) => state.projects.projectPayload,
  );
  // @ts-expect-error TS2532
  const reminderDate = project.reminderDate;
  // @ts-expect-error TS2532
  const reminderContent = project.reminder;

  if (isEmptyString(reminderContent) && !reminderDate) return null;

  const reminderLocalDate = reminderDate
    ? `${moment(reminderDate)
        .local()
        .format("YYYY/MM/DD")} (${intl.formatMessage({
        id: `screens.title.day_week${moment(reminderDate).day()}`,
      })})`
    : intl.formatMessage({
        id: "screen.label.no_date_specified",
      });

  return (
    <div className="reminder-title" style={{ marginBottom: 24 }}>
      {IconFlag}
      <LocalDateComponent> {reminderLocalDate}</LocalDateComponent>
      <span style={{ fontWeight: "bold" }}>
        {isEmptyString(reminderContent) ? "" : reminderContent}
      </span>
    </div>
  );
};
