import { useMemo } from "react";
import { RiMapPin2Line } from "react-icons/ri";
import { useIntl } from "react-intl";

import { checkIfUrl } from "@/utils/checkIfUrl";

import styles from "./styles.module.scss";
import { EventLocationProps } from "./type";

export const EventLocation = ({ location }: EventLocationProps) => {
  const intl = useIntl();

  const displayLocation = useMemo(() => {
    // If location is not provided, display 'to be undecided'
    if (!location) {
      return intl.formatMessage({ id: "screens.events.undecided" });
    }

    // If location is a url, display it as a link
    if (checkIfUrl(location)) {
      return (
        <a href={location} target="_blank" rel="noopener noreferrer">
          {location}
        </a>
      );
    }

    // Otherwise, display the location
    return location;
  }, [intl, location]);

  return (
    <div className={styles.event_location}>
      <span className={styles.icon_wrapper}>
        <RiMapPin2Line className={styles.pin_icon} />
      </span>
      {displayLocation}
    </div>
  );
};
