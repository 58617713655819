import {
  AudioOutlined,
  FileExcelOutlined,
  FileImageOutlined,
  FileMarkdownOutlined,
  FilePdfOutlined,
  FilePptOutlined,
  FileTextOutlined,
  FileUnknownOutlined,
  FileWordOutlined,
  FileZipOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";

import { FileIconVariant } from "./type";

type Props = {
  variant: FileIconVariant;
} & IconProps;
export const VariantIcon = ({ variant, ...iconProps }: Props) => {
  const Icon = getIcon(variant);
  return <Icon {...iconProps} />;
};

type IconProps = Parameters<ReturnType<typeof getIcon>>[0];

const getIcon = (type: FileIconVariant) => {
  switch (type) {
    case "file-image":
      return FileImageOutlined;
    case "file-pdf":
      return FilePdfOutlined;
    case "file-markdown":
      return FileMarkdownOutlined;
    case "file-excel":
      return FileExcelOutlined;
    case "file-word":
      return FileWordOutlined;
    case "audio":
      return AudioOutlined;
    case "video-camera":
      return VideoCameraOutlined;
    case "file-ppt":
      return FilePptOutlined;
    case "file-text":
      return FileTextOutlined;
    case "file-zip":
      return FileZipOutlined;
    case "file-unknown":
      return FileUnknownOutlined;
    default: {
      // eslint-disable-next-line unused-imports/no-unused-vars
      const _: never = type;
      throw new Error(type);
    }
  }
};
