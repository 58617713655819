import { notification } from "antd";
import imageCompression from "browser-image-compression";
import { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";

import { uploadImageWithDraftEditor } from "../../../Components/molecules";
import { endpoints, parseEndpoint } from "../../../Services/endpoints";
import { getObjUUID } from "../../../utils/common";
import { b64toBlob } from "../../../utils/constants";
import {
  IProfileScreenProps,
  // @ts-expect-error TS2305
  ProfileFormInterests,
  ProfileScreenState,
} from "../types/IProfileScreenProps";

const useProfileScreen = (props: IProfileScreenProps) => {
  const intl = useIntl();
  const { updatePageTitle, apiRequest, updateUser, user, company } =
    props.context;
  const [state, customSetState] = useState<ProfileScreenState>(() => {
    return {
      departments: [],
      interest: [],
      isUploadProfileModalVisible: false,
      isSyncPasswordModalVisible: false,
    };
  });
  const setStateCallbackRef = useRef(() => {});
  useEffect(() => {
    const callBack = setStateCallbackRef.current;
    callBack();
    setStateCallbackRef.current = () => {};
  }, [state]);
  const setState = (data: ProfileScreenState, callback = () => {}) => {
    setStateCallbackRef.current = callback;
    customSetState((previousState) => {
      return {
        ...previousState,
        ...data,
      };
    });
  };
  useEffect(() => {
    updatePageTitle("screen.label.account_setting");
  }, []);

  // @ts-expect-error TS7006
  const createInterest = (interest) => {
    if (interest && interest.trim().length > 0) {
      const interestRequest = {
        method: "postRequest",
        url: parseEndpoint(endpoints.interest),
        data: {
          name: interest,
        },
      };
      // @ts-expect-error TS2345
      apiRequest(interestRequest, ({ data }) => {
        // @ts-expect-error TS2339
        const { id } = data;
        const newInterest = user.interest.concat(id);
        handleSubmit({
          interest: newInterest,
        });
        getInterest();
      });
    }
  };

  const getInterest = () => {
    const interestRequest = {
      method: "getRequest",
      url: parseEndpoint(endpoints.interest),
      data: {},
    };
    apiRequest(
      // @ts-expect-error TS2345
      interestRequest,
      ({ data }: { data: ProfileFormInterests[] }) => {
        setState({
          interest: data,
        });
      },
    );
  };

  // @ts-expect-error TS7006
  const handleSubmit = (rawData) => {
    const { projects, subsidiaries, userTags, ...data } = rawData;
    const message = intl.formatMessage({
      id: "screen.label.account_successfully_updated",
    });
    updateUser({ ...data, id: user.id }, message);
  };
  const onProfileImageEditClose = () => {
    setState({ preview: null });
  };
  // @ts-expect-error TS7006
  const onProfileImageEditCrop = (preview) => {
    setState({ preview });
  };

  // @ts-expect-error TS7006
  const onProgress = (p) => {
    console.log(p);
  };
  const uploadProfileImage = async () => {
    // @ts-expect-error TS18049
    const block = state.preview.split(";");
    const contentType = block[0].split(":")[1];
    const realData = block[1].split(",")[1];
    const blob = b64toBlob(realData, contentType);
    const options = {
      maxSizeMB: 10,
      maxWidthOrHeight: 1200,
      useWebWorker: false,
      // @ts-expect-error TS7006
      onProgress: (p) => onProgress(p),
    };
    // @ts-expect-error TS2345
    const compressedBlob = await imageCompression(blob, options);
    // @ts-expect-error TS2339
    const compressedFile = new File([compressedBlob], state.originalFileName, {
      type: contentType,
    });

    return new Promise((resolve, reject) => {
      uploadImageWithDraftEditor({
        file: compressedFile,
        companyId: getObjUUID(company),
        authToken: user.token,
        subLink: "notification",
        headerDetail: {
          type: "profile icon",
        },
        tokenize: false,
      })
        .then((response: any) => {
          notification.success({
            message: intl.formatMessage({
              id: "screen.label.image_has_been_uploaded",
            }),
          });
          resolve(response);
          const profileImage = response.data.link;
          const message = intl.formatMessage({
            id: "screen.label.account_successfully_updated",
          });
          updateUser(
            {
              name: user.name,
              profileImage: profileImage,
              id: user.id,
            },
            message,
          );
          setState({
            isUploadProfileModalVisible: false,
            preview: null,
          });
        })

        .catch((error) => {
          reject(error);
        });
    });
  };

  return {
    intl,
    state,
    setState,
    createInterest,
    handleSubmit,
    onProfileImageEditClose,
    onProfileImageEditCrop,
    uploadProfileImage,
  };
};

export default useProfileScreen;
