import { saveAs } from "file-saver";
import { useCallback } from "react";
import { useIntl } from "react-intl";

export const useDownloadErrorExcel = (errorExcelBlob: Blob | null) => {
  const intl = useIntl();
  const scoringSheetName = intl.formatMessage({
    id: "screen.label.scoring_sheet",
  });
  const downloadErrorExcel = useCallback(() => {
    if (!errorExcelBlob) return;
    saveAs(errorExcelBlob, scoringSheetName);
  }, [errorExcelBlob, scoringSheetName]);

  return downloadErrorExcel;
};
