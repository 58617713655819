import { DateTime } from "luxon";

import { getFormat } from "./helper";
import { PropsDateTimeLabel } from "./type";
export const DateTimeLabel = ({
  dateTimeISO,
  showHour,
  className,
  showSecond = false,
}: PropsDateTimeLabel) => {
  const dateTime = DateTime.fromISO(dateTimeISO);
  const format = getFormat(showHour, showSecond);
  return <span className={className}>{dateTime.toFormat(format)}</span>;
};
