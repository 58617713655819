import { useEffect, useRef } from "react";
import { useIntl } from "react-intl";

import { useStateWithRef } from "../../../utils/hooks";
type ManageCategoriesComponentState = {
  newSmallCategory?: { videoLargeCategoryId: number; name: string };
  name?: string;
  isSmallCategoryModalVisible?: boolean;
};

// @ts-expect-error TS7006
const useManageCategoriesComponent = (props) => {
  const intl = useIntl();
  const [state, customSetState, stateRef] = useStateWithRef(() => {
    return {
      isSmallCategoryModalVisible: false,
      newSmallCategory: {
        videoLargeCategoryId: 0,
        name: "",
      },
    };
  });
  const setStateCallbackRef = useRef(() => {});
  useEffect(() => {
    const callBack = setStateCallbackRef.current;
    callBack();
    setStateCallbackRef.current = () => {};
  }, [state]);
  const setState = (
    data: ManageCategoriesComponentState,
    callback = () => {},
  ) => {
    setStateCallbackRef.current = callback;
    // @ts-expect-error TS7006
    customSetState((previousState) => {
      return {
        ...previousState,
        ...data,
      };
    });
  };

  const getLargeCategoriesToRender = () => {
    // @ts-expect-error TS7006
    return props.largeCategories.filter((l) => {
      if (props.activeLargeCategory === 0) {
        if (l.companyId === null && props.user.isSuper) {
          return true;
        } else if (l.companyId !== null) {
          return true;
        }
        return false;
      } else {
        return l.id === props.activeLargeCategory;
      }
    });
  };
  // @ts-expect-error TS7006
  const handleChangeNewSmallCategory = (key, value) => {
    const { newSmallCategory } = state;
    newSmallCategory[key] = value;
    setState({ newSmallCategory });
  };
  const onCreateNewSmallCategory = () => {
    const data =
      props.smallCategories[state.newSmallCategory.videoLargeCategoryId];
    if (data && Array.isArray(data)) {
      const matches = data.filter((single) => {
        return single.name === state.newSmallCategory.name;
      });
      if (matches.length === 0) {
        props.onSubmitNewCategory(state.newSmallCategory);
        setState({
          newSmallCategory: {
            videoLargeCategoryId: 0,
            name: "",
          },
        });
      }
    }
  };
  return {
    intl,
    state: stateRef.current,
    setState,
    getLargeCategoriesToRender,
    handleChangeNewSmallCategory,
    onCreateNewSmallCategory,
  };
};
export default useManageCategoriesComponent;
