import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import React from "react";

import { VerticalSortableItemProps } from "./type";

export const VerticalSortableItem = <T,>({
  children,
  index,
  ...devProps
}: React.PropsWithChildren<VerticalSortableItemProps<T>>) => {
  const differenceOfIndexAndId = 1;
  const sortId = index + differenceOfIndexAndId;
  const { attributes, setNodeRef, listeners, transform, transition } =
    useSortable({ id: sortId });
  const styles = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      {...devProps}
      style={styles}
    >
      {children}
    </div>
  );
};
