import { Button, Card, Col, Form, Row } from "antd";
import { FC, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import CompanyActions from "@/Redux/CompanyRedux";

import { UserDepartmentTags } from "./UserDepartmentTags";
import { UserInterestTags } from "./UserInterestTags";
import { UserSubsidiaryTags } from "./UserSubsidiaryTags";
import { UserTagTags } from "./UserTagTags";

export type UserSettingsCardProps = {
  //
};

export const UserSettingsCard: FC<UserSettingsCardProps> = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  // @ts-expect-error TS2339
  const company = useSelector((state) => state.company.companyPayload);

  const [subsidiaries, setSubsidiaries] = useState(company.subsidiaries ?? []);
  const [userTags, setUserTags] = useState(company.userTags ?? []);

  const onSubmit = () => {
    dispatch(
      CompanyActions.companyUpdateRequest(
        // departmentとinterestは変更のタイミングで保存される。設定画面全体で挙動を統一した方がいい
        // @ts-expect-error TS2339
        { subsidiaries, userTags },
        intl.formatMessage({
          id: "screen.label.updated_company_information",
        }),
      ),
    );
  };

  const isTypeInput = company.selectedInputType === "input";

  return (
    <Card
      title={intl.formatMessage({
        id: "screen.label.user_setting",
      })}
      style={{
        marginBottom: 30,
      }}
    >
      <Row>
        <Col offset={4} span={14}>
          <Form layout={"vertical"}>
            <Form.Item
              label={intl.formatMessage({
                id: "screen.label.affiliation_companies",
              })}
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 18 }}
            >
              <UserSubsidiaryTags
                subsidiaries={subsidiaries}
                setSubsidiaries={setSubsidiaries}
              />
            </Form.Item>

            <Form.Item
              label={intl.formatMessage({
                id: "screen.label.department1",
              })}
            >
              {isTypeInput ? (
                <p>
                  {intl.formatMessage({
                    id: "screen.label.departments_des",
                  })}
                </p>
              ) : (
                <UserDepartmentTags />
              )}
            </Form.Item>

            <Form.Item
              label={intl.formatMessage({
                id: "screen.label.user_label",
              })}
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 18 }}
            >
              <UserTagTags userTags={userTags} setUserTags={setUserTags} />
            </Form.Item>

            <Form.Item
              label={intl.formatMessage({
                id: "screen.label.interest",
              })}
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 18 }}
            >
              <UserInterestTags />
            </Form.Item>

            <Form.Item
              style={{
                alignItems: "center",
                textAlign: "center",
                display: "grid",
              }}
            >
              <Button
                id={"alpha_savecompany_titles"}
                type="primary"
                htmlType="button"
                onClick={onSubmit}
              >
                {intl.formatMessage({
                  id: "screens.title.save",
                })}
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Card>
  );
};
