import { LeftOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Card, Col, Row, Switch } from "antd";
import { arrayMoveImmutable } from "array-move";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import Immutable from "seamless-immutable";

import { ReorderSwitch, sortStyle } from "./Components/ReorderSwitch";
import useBatchExaminationScoreManagementScreen from "./hooks/useBatchExaminationScoreManagementScreen";
import SingleExaminationSubmissionCategory from "./SingleExaminationSubmissionCategory";
import {
  BatchExaminationScoreManagementScreenProps,
  SingleSubmission,
  SubmissionCategorySubBodyProps,
} from "./types/IBatchExaminationScoreManagementScreen";
import {} from "./types/SortableSingleHypothesisItemProps";

// @ts-expect-error TS2322
const SortableSingleExaminationSubmissionCategoryItem: React.FC =
  // @ts-expect-error TS7031
  SortableElement(({ singleSubmissionCategory, isSortable }) => (
    <li>
      {/*
       // @ts-expect-error TS2741 */}
      <SingleExaminationSubmissionCategory
        singleSubmissionCategory={singleSubmissionCategory}
        isSortable={isSortable}
      />
    </li>
  ));

// @ts-expect-error TS2322
const SortableSingleExaminationSubmissionCategoryList: React.FC =
  SortableContainer(({ items, isSortable }: any) => {
    return (
      // @ts-expect-error TS2322
      <ul style={sortStyle["SortableList"]}>
        {items.map(
          (singleSubmissionCategory: SingleSubmission, index: number) => (
            <SortableSingleExaminationSubmissionCategoryItem
              key={`SortableSingleExaminationSubmissionCategoryList-${index}`}
              // @ts-expect-error TS2322
              index={index}
              singleSubmissionCategory={singleSubmissionCategory}
              disabled={!isSortable}
              isSortable={isSortable}
            />
          ),
        )}
      </ul>
    );
  });

const BatchExaminationScoreManagementScreen: React.FC<BatchExaminationScoreManagementScreenProps> =
  function (props) {
    const {
      history,
      batchName,
      saveNewBatchCategoryNewOrder,
      batchExaminationSubmissionCategory,
      batchId,
    } = useBatchExaminationScoreManagementScreen(props);

    return (
      <>
        <Card
          title={<FormattedMessage id="screen.users.examination_score_mgt" />}
        >
          <Row>
            <Col span={24}>
              <Button
                onClick={() => {
                  history.goBack();
                }}
                icon={<LeftOutlined />}
              >
                <FormattedMessage id="screen.batch_hypothesis.go_back" />
              </Button>
            </Col>
            <Col
              xs={24}
              sm={{ offset: 2, span: 20 }}
              md={{ offset: 3, span: 18 }}
              lg={{ offset: 6, span: 12 }}
            >
              <Row>
                <Col span={24}>
                  <div>
                    <span
                      style={{
                        fontFamily: "Roboto",
                        fontWeight: "bold",
                        fontSize: "24px",
                        lineHeight: "28px",
                      }}
                    >
                      {batchName}
                    </span>
                  </div>
                </Col>
              </Row>
              <div
                style={{
                  marginTop: "24px",
                  marginBottom: "15px",
                }}
              >
                <span
                  style={{
                    fontFamily: "Roboto",
                    fontWeight: "bold",
                    fontSize: "14px",
                    lineHeight: "16.41px",
                  }}
                >
                  <FormattedMessage id="screen.label.submission_category" />
                </span>
              </div>
              <SubmissionCategorySubBody
                batchId={batchId}
                submissionCategories={batchExaminationSubmissionCategory}
                onSaveSubmissionTypes={saveNewBatchCategoryNewOrder}
              />
            </Col>
          </Row>
        </Card>
      </>
    );
  };

const SubmissionCategorySubBody: React.FC<SubmissionCategorySubBodyProps> =
  function (props) {
    const [localSubmissionCategories, setLocalSubmissionCategories] = useState<
      Array<SingleSubmission>
    >([]);
    const [isSortable, setIsSortable] = useState<boolean>(false);

    useEffect(() => {
      const row = Immutable.asMutable(props.submissionCategories, {
        deep: true,
      });
      setLocalSubmissionCategories(row);
    }, [props.submissionCategories]);

    return (
      <>
        <Row gutter={[12, 12]}>
          <Col span={18}>
            <ReorderSwitch>
              <FormattedMessage id="screen.submission.examination.sort_submission_category" />
              <Switch
                style={{ marginLeft: "6px" }}
                size={"small"}
                onChange={(checked) => {
                  setIsSortable(checked);
                }}
                checked={isSortable}
              />
            </ReorderSwitch>
          </Col>
        </Row>
        {isSortable ? (
          <>
            <br />
            <Row>
              <Col>
                <Button
                  type={"ghost"}
                  onClick={() => {
                    setIsSortable(false);
                    props.onSaveSubmissionTypes(localSubmissionCategories);
                  }}
                >
                  <FormattedMessage id="screen.label.save" />
                </Button>
              </Col>
              <Col>
                <Button
                  onClick={() => {
                    setIsSortable(false);
                    setLocalSubmissionCategories(props.submissionCategories);
                  }}
                >
                  <FormattedMessage id="screen.label.cancel" />
                </Button>
              </Col>
            </Row>
          </>
        ) : null}
        <br />
        {Array.isArray(localSubmissionCategories) ? (
          <SortableSingleExaminationSubmissionCategoryList
            // @ts-expect-error TS2322
            distance={1}
            items={localSubmissionCategories}
            helperClass={"SortableHelper"}
            isSortable={isSortable}
            // @ts-expect-error TS7031
            onSortEnd={({ oldIndex, newIndex }) => {
              const newSubmissionCategoriesOrder = arrayMoveImmutable(
                localSubmissionCategories,
                oldIndex,
                newIndex,
              );
              setLocalSubmissionCategories(newSubmissionCategoriesOrder);
            }}
          />
        ) : null}

        <br />
        <Button
          style={{ width: "100%" }}
          className="add-milestone-btn"
          type="dashed"
          icon={<PlusOutlined />}
          onClick={() => {
            const newSingleSubmission: SingleSubmission = {
              order: localSubmissionCategories.length,
              batchId: props.batchId,
              SubmissionCategory: {
                submissionCategoryNumber: "",
              },
              submissionCategoryMetadata: {
                submissionCategory: "",
                isActive: true,
              },
            };
            setLocalSubmissionCategories(
              localSubmissionCategories.concat(newSingleSubmission),
            );
          }}
        >
          <FormattedMessage id="screen.submission.examination.mgt_submission_category.add_new_submission_category" />
        </Button>
      </>
    );
  };

SubmissionCategorySubBody.propTypes = {
  // @ts-expect-error TS2322
  submissionCategories: PropTypes.array,
  // @ts-expect-error TS2322
  onSaveSubmissionTypes: PropTypes.func,
  addNewProjectSubmissionTypes: PropTypes.func,
  // @ts-expect-error TS2322
  batchId: PropTypes.string,
};
export default BatchExaminationScoreManagementScreen;
export { SubmissionCategorySubBody };
