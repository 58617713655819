/* eslint-disable security/detect-object-injection */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { CheckOutlined, RightOutlined } from "@ant-design/icons";
import { Col, Modal, Popconfirm, Row, Select, Tabs } from "antd";
import { EditorState } from "draft-js";
import { useCallback, useEffect, useState } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import ReactPlayer from "react-player/lazy";

import { EventCancellationModal } from "@/features/notification/components/EventCancellationModal";

import { EventInNotification } from "../../../types/EventInNotification";
import { Notification } from "../../../types/Notification";
import { Badge, ButtonOption, ButtonUndo, Line } from "../../Components/atoms";
import { CustomPagination } from "../../Components/molecules";
import { DraftEditor, Select as Dropdown } from "../../Components/molecules";
import {
  CompanyBanner,
  NotificationComponent,
} from "../../Components/organisms";
import { endpoints, parseEndpoint } from "../../Services/endpoints";
import {
  convertFromRawWithTrimmer,
  sanitizeEditorStateMarkupHtml,
} from "../../utils/common";
import { getObjUUID } from "../../utils/common";
import { getInitStorageValue } from "../../utils/localStorage";
import {
  HomeScreenState,
  IHomeScreenProps,
  NoticeDetail,
  NotificationCountKey,
  NotificationType,
} from "./types/IHomeScreenProps";

const { TabPane } = Tabs;
const { Option } = Select;
const PageSaveId = "HomeScreen";
const pageKey = `pagination_page_${PageSaveId}`;
const limitKey = `pagination_limit_${PageSaveId}`;

type TabHeader = {
  key: NotificationType;
  keyCount: NotificationCountKey;
  title: string;
  query: string;
};
const HomeScreen = (props: IHomeScreenProps) => {
  const initState: HomeScreenState = {
    activeQuery: "?",
    modalTitle: "",
    modalDesc: "",
    modalLink: "",
    modalVisible: false,
    eventDetail: {
      name: "",
      id: 0,
      beginsAt: "",
      endsAt: "",
    },
    editorState: EditorState.createEmpty(),
    noticeDetails: {
      noticeType: "",
      title: "",
      description: "",
      id: 0,
      nodalData: false,
      meta: {
        type: "",
      },
    },
    notifications: {
      rows: [],
      count: 0,
      unReadNotification: [],
    },
    activeTabKey: 0,
    page: getInitStorageValue(pageKey, 1),
    limit: getInitStorageValue(limitKey, process.env.REACT_APP_PAGE_SIZE),
    allCount: 0,
    managementCount: 0,
    projectCount: 0,
    personalCount: 0,
    operationCount: 0,
    libraryCount: 0,
    activeNotification: null,
  };
  const [state, _setState] = useState(initState);
  const setState = useCallback(
    (v: Partial<HomeScreenState>) => _setState((st) => ({ ...st, ...v })),
    [],
  );

  const getNotificationPanes = useCallback(() => {
    const { user } = props.context;

    const tabsHeaders: TabHeader[] = [
      {
        key: "all",
        title: props.intl.formatMessage({
          id: "screen.label.all",
        }),
        query: `all?metaType=${"all"}&`,
        keyCount: "allCount",
      },
      {
        key: "management",
        title: props.intl.formatMessage({
          id: "screen.label.from_management",
        }),
        query: `management?metaType=${"all"}&`,
        keyCount: "managementCount",
      },
      {
        key: "project",
        title: props.intl.formatMessage({
          id: "screen.label.from_project",
        }),
        query: `project?metaType=${"all"}&`,
        keyCount: "projectCount",
      },
      {
        key: "library",
        title: props.intl.formatMessage({
          id: "screen.label.library",
        }),
        query: `library?metaType=${"all"}&`,
        keyCount: "libraryCount",
      },
      {
        key: "personal",
        title: props.intl.formatMessage({
          id: "screen.label.you_for",
        }),
        query: `personal?metaType=${"all"}&`,
        keyCount: "personalCount",
      },
    ];
    if (user.type === "admin") {
      tabsHeaders.push({
        key: "operation",
        title: props.intl.formatMessage({
          id: "screen.label.management_for",
        }),
        query: `operation?metaType=${"all"}&`,
        keyCount: "operationCount",
      });
    }
    return tabsHeaders;
  }, [props.context, props.intl]);
  const getEachTabCount = useCallback(() => {
    const { apiRequest } = props.context;
    getNotificationPanes().forEach((ta) => {
      const requestBuilder = {
        method: "getRequest",
        url: parseEndpoint(endpoints.getUnreadNotification, { ta }),
        data: {},
      } as const;
      apiRequest(requestBuilder, ({ data }) => {
        setState({ [ta.keyCount]: data });
      });
    });
  }, [getNotificationPanes, props.context, setState]);

  const onNotificationTabChanged = useCallback(
    (query: string, page?: number, limit?: number) => {
      const { apiRequest } = props.context;

      const requestBuilder = {
        method: "getRequest",
        url: parseEndpoint(endpoints.notificationTabChanged, {
          query,
          page: (page ?? state.page) - 1,
          limit: limit ?? state.limit,
        }),
        data: {},
      } as const;
      apiRequest<HomeScreenState["notifications"]>(
        requestBuilder,
        ({ data }) => {
          getEachTabCount();
          setState({
            notifications: data,
          });
        },
      );
    },
    [getEachTabCount, props.context, setState, state.limit, state.page],
  );

  const [componentMounted, setComponentMounted] = useState<boolean>(false);

  useEffect(() => {
    if (componentMounted) return;
    const { updatePageTitle } = props.context;
    updatePageTitle("screen.label.home");
    const savedPage = localStorage.getItem(pageKey);
    // @ts-expect-error TODO
    if (savedPage && !isNaN(savedPage)) {
      setState({
        page: parseInt(savedPage),
      });
    }
    const savedLimit = localStorage.getItem(limitKey);
    // @ts-expect-error TODO
    if (savedLimit && !isNaN(savedLimit)) {
      setState({
        limit: parseInt(savedLimit),
      });
    }
    setState({
      activeQuery: getNotificationPanes()[0].query,
    });
    try {
      onNotificationTabChanged(getNotificationPanes()[0].query);
    } catch (e) {
      console.log(e);
    }
    setComponentMounted(true);
  }, [
    getEachTabCount,
    getNotificationPanes,
    componentMounted,
    onNotificationTabChanged,
    props.context,
    setState,
  ]);
  const onPromptModal = (modalEnv: {
    name: string;
    description: string;
    uuid: string;
  }) => {
    setState({
      modalTitle: modalEnv.name,
      modalDesc: modalEnv.description,
      modalLink: modalEnv.uuid,
      modalVisible: true,
    });
  };
  const handleCancel = () => {
    setState({
      modalVisible: false,
    });
  };
  const onDeleteNotification = (notification: { id: number }) => {
    const { apiRequest } = props.context;
    const requestBuilder = {
      method: "deleteRequest",
      url: parseEndpoint(endpoints.updateNotification, {
        id: notification.id,
      }),
      data: {},
    } as const;
    apiRequest(requestBuilder, () => {
      onNotificationTabChanged(
        getNotificationPanes()[state.activeTabKey].query,
      );
    });
  };
  const onTakeActionRegisterEvent = (eventDetail: EventInNotification) => {
    setState({
      eventDetail,
    });
  };
  const onTakeActionNoticeClicked = (
    notification: Notification,
    noticeType: NotificationType,
  ) => {
    const noticeDetails: NoticeDetail = {
      noticeType,
      title: notification.actionText,
      description: notification.message,
      id: notification.id,
      meta: notification.meta,
      nodalData: notification.nodalData,
    };
    let editNode = null;
    try {
      editNode = sanitizeEditorStateMarkupHtml(
        EditorState.createWithContent(
          convertFromRawWithTrimmer(notification.message),
        ),
      );
    } catch (e) {}
    const state:
      | Pick<HomeScreenState, "editorState" | "noticeDetails">
      | Pick<HomeScreenState, "noticeDetails"> = editNode
      ? {
          noticeDetails,
          editorState: editNode,
        }
      : { noticeDetails };
    setState(state);
  };
  const _renderTabHead = (title: string, count: number) => {
    return (
      <>
        {title}
        {count > 0 ? (
          <Badge>
            {props.intl.formatMessage(
              {
                id: "screen.label.unread_count",
              },
              { count },
            )}
          </Badge>
        ) : null}
      </>
    );
  };
  const onPageChangeNotifications = (page: number, limit: number) => {
    setState({
      page,
      limit,
    });
    onNotificationTabChanged(state.activeQuery, page, limit);
  };
  const handleChange = (value: string) => {
    if (value) {
      setState({
        page: 1,
        activeQuery: value,
      });
    }
    onNotificationTabChanged(value);
  };
  const _renderEachTabFilter = (singleNotificationPane: TabHeader) => {
    if (singleNotificationPane.key === "all") {
      return (
        <>
          <Option value={`${singleNotificationPane.key}?metaType=all`}>
            {props.intl.formatMessage({
              id: "screen.label.all",
            })}
          </Option>
          <Option value={`${singleNotificationPane.key}?metaType=poll`}>
            {props.intl.formatMessage({
              id: "screen.label.questionnaire",
            })}
          </Option>
          <Option
            value={`${singleNotificationPane.key}?metaType=general_notice`}
          >
            {props.intl.formatMessage({
              id: "screen.label.notice",
            })}
          </Option>
          <Option
            value={`${singleNotificationPane.key}?metaType=important_notice`}
          >
            {" "}
            {props.intl.formatMessage({
              id: "screen.label.important_notices",
            })}
          </Option>
          <Option
            value={`${singleNotificationPane.key}?metaType=event_registration`}
          >
            {props.intl.formatMessage({
              id: "screen.label.seminars",
            })}
            /
            {props.intl.formatMessage({
              id: "screen.label.events",
            })}
          </Option>

          <Option
            value={`${singleNotificationPane.key}?metaType=recruit_members`}
          >
            {props.intl.formatMessage({
              id: "screen.label.members_wanted",
            })}
          </Option>
          <Option
            value={`${singleNotificationPane.key}?metaType=user_mentioned`}
          >
            {props.intl.formatMessage({
              id: "screen.label.mention",
            })}
          </Option>
          <Option
            value={`${singleNotificationPane.key}?metaType=project_invitation_request`}
          >
            {props.intl.formatMessage({
              id: "screen.label.member_invitation",
            })}
          </Option>
          <Option
            value={`${singleNotificationPane.key}?metaType=project_invitation_request_from_myself`}
          >
            {props.intl.formatMessage({
              id: "screen.label.participation_application",
            })}
          </Option>
          <Option
            value={`${singleNotificationPane.key}?metaType=project_invitation_acceptance`}
          >
            {props.intl.formatMessage({
              id: "screen.label.acceptance_participation",
            })}
          </Option>
          <Option
            value={`${singleNotificationPane.key}?metaType=project_invitation_rejection`}
          >
            {props.intl.formatMessage({
              id: "screen.label.denial_participation",
            })}
          </Option>
          <Option
            value={`${singleNotificationPane.key}?metaType=projection_rejection_by_owner`}
          >
            {props.intl.formatMessage({
              id: "screen.label.rejection_participation",
            })}
          </Option>
          <Option
            value={`${singleNotificationPane.key}?metaType=projection_rejection_by_invitee`}
          >
            {props.intl.formatMessage({
              id: "screen.label.decline_participation",
            })}
          </Option>
          <Option
            value={`${singleNotificationPane.key}?metaType=project_submission_review`}
          >
            {props.intl.formatMessage({
              id: "screen.label.examination_outcome",
            })}
          </Option>

          <Option
            value={`${singleNotificationPane.key}?metaType=project_creation`}
          >
            {props.intl.formatMessage({
              id: "screen.label.creating_project",
            })}
          </Option>
          <Option
            value={`${singleNotificationPane.key}?metaType=project_submission`}
          >
            {props.intl.formatMessage({
              id: "screen.label.submission_project",
            })}
          </Option>
          <Option
            value={`${singleNotificationPane.key}?metaType=project_watch`}
          >
            {props.intl.formatMessage({
              id: "screen.label.watch",
            })}
          </Option>
          <Option value={`${singleNotificationPane.key}?metaType=news_posted`}>
            {props.intl.formatMessage({
              id: "screen.label.news",
            })}
          </Option>
          <Option
            value={`${singleNotificationPane.key}?metaType=industry_theme_report`}
          >
            {props.intl.formatMessage({
              id: "screen.label.industry_theme_report",
            })}
          </Option>
          <Option
            value={`${singleNotificationPane.key}?metaType=reference_posted`}
          >
            {props.intl.formatMessage({
              id: "screen.label.reference",
            })}
          </Option>
        </>
      );
    } else if (singleNotificationPane.key === "management") {
      return (
        <>
          <Option value={`${singleNotificationPane.key}?metaType=all`}>
            {props.intl.formatMessage({
              id: "screen.label.all",
            })}
          </Option>
          <Option value={`${singleNotificationPane.key}?metaType=poll`}>
            {props.intl.formatMessage({
              id: "screen.label.questionnaire",
            })}
          </Option>
          <Option
            value={`${singleNotificationPane.key}?metaType=general_notice`}
          >
            {props.intl.formatMessage({
              id: "screen.label.notice",
            })}
          </Option>
          <Option
            value={`${singleNotificationPane.key}?metaType=event_registration`}
          >
            {props.intl.formatMessage({
              id: "screen.label.seminars",
            })}
            /
            {props.intl.formatMessage({
              id: "screen.label.events",
            })}
          </Option>
          <Option
            value={`${singleNotificationPane.key}?metaType=important_notice`}
          >
            {" "}
            {props.intl.formatMessage({
              id: "screen.label.important_notices",
            })}
          </Option>
          <Option
            value={`${singleNotificationPane.key}?metaType=reference_posted`}
          >
            {props.intl.formatMessage({
              id: "screen.label.reference",
            })}
          </Option>
        </>
      );
    } else if (singleNotificationPane.key === "project") {
      return (
        <>
          <Option value={`${singleNotificationPane.key}?metaType=all`}>
            {props.intl.formatMessage({
              id: "screen.label.all",
            })}
          </Option>
          <Option value={`${singleNotificationPane.key}?metaType=poll`}>
            {props.intl.formatMessage({
              id: "screen.label.questionnaire",
            })}
          </Option>
          <Option
            value={`${singleNotificationPane.key}?metaType=general_notice`}
          >
            {props.intl.formatMessage({
              id: "screen.label.notice",
            })}
          </Option>
          <Option
            value={`${singleNotificationPane.key}?metaType=recruit_members`}
          >
            {props.intl.formatMessage({
              id: "screen.label.members_wanted",
            })}
          </Option>
          <Option
            value={`${singleNotificationPane.key}?metaType=project_creation`}
          >
            {props.intl.formatMessage({
              id: "screen.label.creating_project",
            })}
          </Option>
        </>
      );
    } else if (singleNotificationPane.key === "library") {
      return (
        <>
          <Option value={`${singleNotificationPane.key}?metaType=all`}>
            {props.intl.formatMessage({
              id: "screen.label.all",
            })}
          </Option>
          <Option value={`${singleNotificationPane.key}?metaType=news_posted`}>
            {props.intl.formatMessage({
              id: "screen.label.news",
            })}
          </Option>
          <Option
            value={`${singleNotificationPane.key}?metaType=industry_theme_report`}
          >
            {props.intl.formatMessage({
              id: "screen.label.industry_theme_report",
            })}
          </Option>
        </>
      );
    } else if (singleNotificationPane.key === "personal") {
      return (
        <>
          <Option value={`${singleNotificationPane.key}?metaType=all`}>
            {props.intl.formatMessage({
              id: "screen.label.all",
            })}
          </Option>
          <Option
            value={`${singleNotificationPane.key}?metaType=user_mentioned`}
          >
            {props.intl.formatMessage({
              id: "screen.label.mention",
            })}
          </Option>
          <Option
            value={`${singleNotificationPane.key}?metaType=project_invitation_request`}
          >
            {props.intl.formatMessage({
              id: "screen.label.member_invitation",
            })}
          </Option>
          <Option
            value={`${singleNotificationPane.key}?metaType=project_invitation_request_from_myself`}
          >
            {props.intl.formatMessage({
              id: "screen.label.participation_application",
            })}
          </Option>
          <Option
            value={`${singleNotificationPane.key}?metaType=project_invitation_acceptance`}
          >
            {props.intl.formatMessage({
              id: "screen.label.acceptance_participation",
            })}
          </Option>
          <Option
            value={`${singleNotificationPane.key}?metaType=project_invitation_rejection`}
          >
            {props.intl.formatMessage({
              id: "screen.label.denial_participation",
            })}
          </Option>
          <Option
            value={`${singleNotificationPane.key}?metaType=projection_rejection_by_owner`}
          >
            {props.intl.formatMessage({
              id: "screen.label.rejection_participation",
            })}
          </Option>
          <Option
            value={`${singleNotificationPane.key}?metaType=projection_rejection_by_invitee`}
          >
            {props.intl.formatMessage({
              id: "screen.label.decline_participation",
            })}
          </Option>
          <Option
            value={`${singleNotificationPane.key}?metaType=project_submission_review`}
          >
            {props.intl.formatMessage({
              id: "screen.label.examination_outcome",
            })}
          </Option>
          <Option
            value={`${singleNotificationPane.key}?metaType=project_creation`}
          >
            {props.intl.formatMessage({
              id: "screen.label.creating_project",
            })}
          </Option>
          <Option
            value={`${singleNotificationPane.key}?metaType=project_submission`}
          >
            {props.intl.formatMessage({
              id: "screen.label.submission_project",
            })}
          </Option>
          <Option
            value={`${singleNotificationPane.key}?metaType=project_watch`}
          >
            {props.intl.formatMessage({
              id: "screen.label.watch",
            })}
          </Option>
        </>
      );
    } else if (singleNotificationPane.key === "operation") {
      return (
        <>
          <Option value={`${singleNotificationPane.key}?metaType=all`}>
            {props.intl.formatMessage({
              id: "screen.label.all",
            })}
          </Option>
          <Option
            value={`${singleNotificationPane.key}?metaType=project_creation`}
          >
            {props.intl.formatMessage({
              id: "screen.label.creating_project",
            })}
          </Option>
          <Option
            value={`${singleNotificationPane.key}?metaType=project_submission`}
          >
            {props.intl.formatMessage({
              id: "screen.label.submission_project",
            })}
          </Option>
        </>
      );
    }
  };
  const _renderNotificationTabPanes = () => {
    return getNotificationPanes().map((notificationPane, index) => {
      return (
        <TabPane
          tab={_renderTabHead(
            notificationPane.title,
            state[notificationPane.keyCount],
          )}
          key={index}
        >
          <Row>
            <Col flex="1">
              <div className={"notification-header"}>
                <Dropdown
                  listHeight={400}
                  style={{ width: "50%", maxWidth: 300 }}
                  onChange={handleChange}
                  defaultValue={`${notificationPane.key}?metaType=all`}
                >
                  {_renderEachTabFilter(notificationPane)}
                </Dropdown>
                {index === 0 && state.allCount > 0 && (
                  <Popconfirm
                    title={props.intl.formatMessage(
                      {
                        id: "screen.label.mark_as_read_confirm",
                      },
                      {
                        count: state.allCount,
                      },
                    )}
                    onConfirm={() => {
                      onNotificationHasBeenRead();
                    }}
                    okText={props.intl.formatMessage({
                      id: "screen.label.mark_as_read",
                    })}
                    cancelText={props.intl.formatMessage({
                      id: "screen.label.cancel",
                    })}
                  >
                    <ButtonOption
                      style={{ marginLeft: 10 }}
                      icon={<CheckOutlined />}
                      textColor={"#323232"}
                      borderColor={"#323232"}
                    >
                      {props.intl.formatMessage({
                        id: "screen.label.mark_all_as_read",
                      })}
                    </ButtonOption>
                  </Popconfirm>
                )}
              </div>
            </Col>

            <Col flex="none">
              <CustomPagination
                showLessItems={true}
                showPagination={false}
                containerStyle={{ float: "right" }}
                saveId={PageSaveId}
                initLimit={state.limit}
                initPage={state.page}
                count={state.notifications.count}
                onChangePage={onPageChangeNotifications}
              />
            </Col>
          </Row>
          <br />
          <br />
          <Line />
          <Row>
            <Col span={24}>{_renderSingleTabNotifications()}</Col>
          </Row>
          <br />
          <Row>
            <Col span={24}>
              <CustomPagination
                saveId={PageSaveId}
                initLimit={state.limit}
                initPage={state.page}
                count={state.notifications.count}
                onChangePage={onPageChangeNotifications}
              />
            </Col>
          </Row>
        </TabPane>
      );
    });
  };
  const onNotificationHasBeenRead = (notificationId: number | null = null) => {
    const { user, apiRequest } = props.context;
    const requestBuilder = {
      method: "postRequest",
      url: parseEndpoint(endpoints.notificationHasBeenRead, {
        userId: getObjUUID(user),
      }),
      data: {
        notificationId,
      },
    } as const;
    apiRequest(requestBuilder, () => {
      const activeTabQuery = getNotificationPanes()[state.activeTabKey].query;
      const query =
        state.activeQuery !== "?" ? state.activeQuery : activeTabQuery;
      onNotificationTabChanged(query);
      getEachTabCount();
    });
  };
  const _renderSingleTabNotifications = () => {
    const { user, apiRequest } = props.context;
    if (Array.isArray(state.notifications.rows)) {
      const unreadNotifications = state.notifications.unReadNotification;
      return state.notifications.rows.map((notification) => {
        return (
          <div
            key={notification.id}
            onClick={() => {
              setState({
                activeNotification: notification,
              });
              if (unreadNotifications.includes(notification.id)) {
                onNotificationHasBeenRead(notification.id);
              }
            }}
          >
            <NotificationComponent
              authToken={user.token}
              refresh={() => {
                onNotificationTabChanged(
                  getNotificationPanes()[state.activeTabKey].query,
                );
              }}
              unread={unreadNotifications.includes(notification.id)}
              dispatchApiRequest={apiRequest}
              // @ts-expect-error TODO
              currentUserId={user.id}
              currentUserRole={user.type}
              onDeleteNotification={onDeleteNotification}
              notification={notification}
              // @ts-expect-error TODO
              onTakeActionRegisterEvent={onTakeActionRegisterEvent}
              onPromptModal={onPromptModal}
              // @ts-expect-error TODO
              onTakeActionNoticeClicked={onTakeActionNoticeClicked}
              key={notification.id}
            />
          </div>
        );
      });
    }
    return <></>;
  };

  const getCompanyBanner = () => {
    const { company } = props.context;
    if (company.bannerImage && company.bannerImage !== "") {
      return window.location.protocol + "//" + company.bannerImage;
    }
    return "/inc-img-1@2x.png";
  };
  const render = () => {
    const { company } = props.context;
    let noticeDetailsNode = <>{state.noticeDetails.description}</>;
    if (state.noticeDetails.nodalData === true) {
      noticeDetailsNode = (
        <DraftEditor
          toolbarHidden={true}
          readOnly={true}
          editorState={sanitizeEditorStateMarkupHtml(state.editorState)}
        />
      );
    }
    return (
      <div className={"HomeScreen"}>
        <Row>
          <Col span={24}>
            <a
              rel="noreferrer"
              href={company.bannerLink || ""}
              target={
                company.bannerLink && company.bannerLink !== ""
                  ? "_blank"
                  : "_parent"
              }
              className={
                company.bannerLink && company.bannerLink !== ""
                  ? ""
                  : "disabled"
              }
            >
              <CompanyBanner alt="project" src={getCompanyBanner()} />
            </a>
          </Col>
        </Row>
        <br />
        <Row>
          <Col span={24}>
            <Tabs
              size={"small"}
              destroyInactiveTabPane
              onChange={(activeKey) => {
                setState({
                  // @ts-expect-error TODO
                  activeTabKey: activeKey,
                  // @ts-expect-error TODO
                  activeQuery: getNotificationPanes()[activeKey].query,
                  page: 1,
                });

                onNotificationTabChanged(
                  // @ts-expect-error TODO
                  getNotificationPanes()[activeKey].query,
                );
              }}
              type="card"
            >
              {_renderNotificationTabPanes()}
            </Tabs>
          </Col>
        </Row>
        <Modal
          title={props.intl.formatMessage({
            id: "screen.label.members_wanted",
          })}
          visible={state.modalVisible}
          onCancel={handleCancel}
          onOk={() => {
            handleCancel();
            props.history.push(`/dashboard/project.top/${state.modalLink}`);
          }}
          cancelText={props.intl.formatMessage({
            id: "screen.label.cancel",
          })}
          okText={props.intl.formatMessage({
            id: "screen.label.check_project",
          })}
        >
          {state.modalTitle}{" "}
          {props.intl.formatMessage({
            id: "screen.label.there_looking_for_members",
          })}
          <br />
          <p style={{ whiteSpace: "pre-line" }}>{state.modalDesc}</p>
          <h4>
            {props.intl.formatMessage({
              id: "screen.label.application_method",
            })}
          </h4>
          <ol style={{ paddingLeft: "20px" }}>
            <li>
              {props.intl.formatMessage({
                id: "screen.label.check_project_des",
              })}
            </li>
            <li>
              {props.intl.formatMessage({
                id: "screen.label.apply_to_become_project_member_des",
              })}
            </li>
          </ol>
        </Modal>
        <EventCancellationModal
          event={state.eventDetail}
          onCloseRegisterEventModal={onCloseRegisterEventModal}
        />
        <Modal
          title={state.noticeDetails.noticeType}
          centered
          style={{ minWidth: "40vw" }}
          visible={state.noticeDetails.id > 0}
          onOk={() => {
            if (state.noticeDetails.meta.projectId) {
              props.history.push(
                `/dashboard/project.top/${getObjUUID(
                  state.noticeDetails.meta,
                )}`,
              );
            }
            onCloseNoticeModal();
          }}
          onCancel={onCloseNoticeModal}
          footer={
            <span>
              {state.noticeDetails.meta.projectId ? (
                <ButtonUndo
                  className="offset-right-16"
                  onClick={() => {
                    if (state.noticeDetails.meta.projectId) {
                      props.history.push(
                        `/dashboard/project.top/${getObjUUID(
                          state.noticeDetails.meta,
                        )}`,
                      );
                    }
                    onCloseNoticeModal();
                  }}
                >
                  {props.intl.formatMessage({
                    id: "screen.label.check_project",
                  })}
                </ButtonUndo>
              ) : null}
              {Object.hasOwn(state.noticeDetails.meta, "capacity") &&
              Object.hasOwn(state.noticeDetails.meta, "isAcceptingMembers") &&
              Object.hasOwn(state.noticeDetails.meta, "eventNotificationId") ? (
                <ButtonOption
                  onClick={() => {
                    props.history.push(
                      `/dashboard/notification-detail/${state.noticeDetails.meta.eventNotificationId}`,
                    );
                  }}
                >
                  {props.intl.formatMessage({
                    id: "screen.label.check_event",
                  })}{" "}
                  <RightOutlined style={{ fontWeight: "bolder" }} />
                </ButtonOption>
              ) : (
                <ButtonOption
                  onClick={() => {
                    props.history.push(
                      `/dashboard/notification-detail/${state.activeNotification?.id}`,
                    );
                  }}
                >
                  <FormattedMessage id="screen.label.more_detail" />{" "}
                  <RightOutlined style={{ fontWeight: "bolder" }} />
                </ButtonOption>
              )}
            </span>
          }
        >
          <h4>
            <FormattedMessage id="screen.label.title" />
          </h4>
          <p>{state.noticeDetails.title}</p>
          <br />
          {state.noticeDetails.meta.type === "poll" ? (
            <>
              <h4>URL</h4>
              <a
                rel="noreferrer"
                style={{ whiteSpace: "pre-line" }}
                target={"_blank"}
                href={
                  state.noticeDetails.description.includes("http")
                    ? state.noticeDetails.description
                    : `https://${state.noticeDetails.description}`
                }
              >
                {state.noticeDetails.description}
              </a>
            </>
          ) : (
            <>
              <h4>
                <FormattedMessage id="screen.label.more_detail" />
              </h4>
              <p style={{ whiteSpace: "pre-line" }}>{noticeDetailsNode}</p>
            </>
          )}

          <br />
          {state.noticeDetails.meta.videoUrl &&
          state.noticeDetails.meta.videoUrl.length > 0 ? (
            <div className="player-wrapper">
              <ReactPlayer
                loop={false}
                controls
                className="react-player"
                url={state.noticeDetails.meta.videoUrl}
                width="100%"
                height="100%"
                config={{
                  file: {
                    attributes: {
                      controlsList: "nodownload",
                    },
                  },
                }}
              />
            </div>
          ) : null}
        </Modal>
      </div>
    );
  };
  const onCloseNoticeModal = () => {
    setState({
      noticeDetails: {
        noticeType: "",
        title: "",
        description: "",
        id: 0,
        nodalData: false,
        meta: {
          type: "",
        },
      },
    });
  };
  const onCloseRegisterEventModal = () => {
    _setState((st) => ({
      ...st,
      eventDetail: {
        ...st.eventDetail,
        name: "",
        id: 0,
        beginsAt: new Date().toString(),
      },
    }));
  };

  return render();
};

// @ts-expect-error TODO
export default injectIntl(HomeScreen);
