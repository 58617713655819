import { FormattedMessage } from "react-intl";
import styled from "styled-components";

const DivContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const AlphaContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-top: 22vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 400;
  font-size: 11px;
  color: #686868;
`;

const BackButton = styled.button`
  padding-top: 1vh;
  padding-bottom: 1vh;
  font-weight: 500;
  font-size: 1.14em;
  color: #323232;
  margin-top: 4vh;
  text-align: center;
  width: 13vw;
  background-color: #e9edf5;
  border-radius: 3;
  margin-right: auto;
  margin-left: auto;
  border-style: none;
  text-decoration: underline;
`;

const LoginBanner = styled.img`
  margin-left: auto;
  margin-right: auto;
  margin-top: 13vh;
  width: 126px;
  height: 140px;
`;

const H1 = styled.h1`
  color: black;
  margin-top: 10vh;
  text-align: center;
`;
const H4 = styled.h4`
  color: black;
  margin-top: 3vh;
  text-align: center;
  font-weight: 500;
  font-size: 16px;
`;

const NotFoundScreen = () => {
  return (
    <div style={{ backgroundColor: "#E9EDF5", height: "100vh" }}>
      <DivContainer>
        <LoginBanner src="login_banner.png" alt="banner"></LoginBanner>

        <H1>
          <FormattedMessage id="screen.label.page_does_not_open" />
        </H1>
        <H4>
          <FormattedMessage id="screen.label.not_found_des" />
          <br />
          <FormattedMessage id="screen.label.not_found_desc" />
        </H4>

        <BackButton id="btn-go-back">
          <FormattedMessage id="screen.label.return" />
        </BackButton>

        <AlphaContainer>
          <span>Powered by &nbsp;</span>
          <img src="alpha_drive.png" alt="banner"></img>
        </AlphaContainer>
      </DivContainer>
    </div>
  );
};
export default NotFoundScreen;
