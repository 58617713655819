import { useIntl } from "react-intl";

import styles from "./styles.module.scss";
import { EventRecommendationsProps } from "./type";

export const EventRecommendations = ({
  recommendations,
}: EventRecommendationsProps) => {
  const intl = useIntl();
  return (
    <div className={styles.event_recommendations_wrapper}>
      <div className={styles.event_recommendations_title_wrapper}>
        <span className={styles.event_recommendations_title}>
          {intl.formatMessage({
            id: "screens.events.recommendations",
          })}
        </span>
        <span className={styles.event_recommendations_title_line} />
      </div>
      <ul className={styles.event_recommendations_list}>
        {recommendations.map((recommendation) => {
          const recommendationText = recommendation.text;

          return <li key={recommendation.id}>{recommendationText}</li>;
        })}
      </ul>
    </div>
  );
};
