import Drawer from "@material-ui/core/Drawer";
import { Layout } from "antd";
import PropTypes from "prop-types";
import { FC, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import styled from "styled-components";

import BatchActions from "../../../Redux/BatchRedux";
import CommentActions from "../../../Redux/CommentRedux";
import ProgramInfoActions from "../../../Redux/ProgramInfoRedux";
import ProjectActions from "../../../Redux/ProjectRedux";
import TeamActions from "../../../Redux/TeamRedux";
import { endpoints, parseEndpoint } from "../../../Services/endpoints";
import { DrawerContaienr } from "../../molecules";
import { FixedSideMenu } from "./Fixed";
import { ISideMenuProps, SideMenuState } from "./types";

const { Sider } = Layout;

const StyledSider = styled(Sider)`
  height: 100vh;
  overflow-y: scroll;
  position: sticky;
  top: 0;
`;

const SideMenu: FC<ISideMenuProps> = function (props: ISideMenuProps) {
  const {
    context: { company, apiRequest },
  } = props;

  const [state, customSetState] = useState<SideMenuState>(() => {
    return {
      companyProgramInfoStatus: "private",
      dropdownSideMenuClicked: false,
    };
  });

  useEffect(() => {
    setTimeout(() => {
      getProgramInfoDetail();
    }, 2000);
  }, []);

  const setStateCallbackRef = useRef(() => {});

  useEffect(() => {
    const callBack = setStateCallbackRef.current;
    callBack();
    setStateCallbackRef.current = () => {};
  }, [state]);

  const setState = (data: SideMenuState, callback = () => {}) => {
    setStateCallbackRef.current = callback;
    customSetState((previousState) => {
      return {
        ...previousState,
        ...data,
      };
    });
  };

  const gotoHome = () => {
    // @ts-expect-error TS18048
    props.history.push("/dashboard");
  };

  const gotoScreen = (url: string) => {
    // @ts-expect-error TS18048
    props.history.push(url);
  };

  const getProgramInfoDetail = () => {
    const requestBuilder = {
      method: "getRequest",
      data: {},
      url: parseEndpoint(endpoints.programInfoDetails),
    };
    // @ts-expect-error TS2345
    apiRequest(requestBuilder, ({ data }) => {
      // @ts-expect-error TS2722
      props.dispatchSetProgramInfoPayload(data);
    });
    apiRequest(
      {
        method: "getRequest",
        data: {},
        url: parseEndpoint(endpoints.programInfoQA),
      },
      ({ data }) => {
        // @ts-expect-error TS2722
        props.dispatchSetProgramInfoQaPayload(data);
      },
    );
    // @ts-expect-error TS2722
    props.dispatchFetchBatches();
  };

  const updateMenuState = (value: boolean) => {
    setState({
      dropdownSideMenuClicked: value,
    });
  };

  const renderFixedMenu = () => {
    return (
      <FixedSideMenu
        company={company}
        companyIcon={props.companyIcon}
        programInfo={props.programInfo}
        programInfoQa={props.programInfoQa}
        myProjects={props.myProjects}
        // @ts-expect-error TS2322
        batches={props.batches}
        isAdminUser={props.isAdminUser}
        isSuperUser={props.isSuperUser}
        active={props.active}
        updateMenuState={updateMenuState}
        gotoHome={gotoHome}
        gotoScreen={gotoScreen}
        // @ts-expect-error TS18048
        pathname={props.history.location.pathname}
      />
    );
  };

  if (props.sideBarOption === "fixed") {
    return (
      <StyledSider
        breakpoint="md"
        collapsedWidth="0"
        onBreakpoint={(broken) => {}}
        onCollapse={(collapsed, type) => {}}
        theme="dark"
        className={"ant-layout-sidee-wide"}
      >
        {renderFixedMenu()}
      </StyledSider>
    );
  }

  return (
    <Drawer
      anchor={"left"}
      open={props.open}
      onClose={() => {
        // @ts-expect-error TS2722
        props.onToggleDrawer();
      }}
    >
      <DrawerContaienr>{renderFixedMenu()}</DrawerContaienr>
    </Drawer>
  );
};

const mapStateToProps = (state: any) => ({
  projects: state.projects,
  selectedProject: state.projects.projectPayload,
  programInfo: state.programInfo.payload,
  programInfoQa: state.programInfo.programInfoQaPayload,
  batches: state.batch.batches,
});

// @ts-expect-error TS7006
const mapDispatchToProps = (dispatch) => ({
  // @ts-expect-error TS7006
  dispatchGetProjectDetail: (id) =>
    // @ts-expect-error TS2554
    dispatch(ProjectActions.projectGetRequest(id)),
  // @ts-expect-error TS7006
  dispatchGetComments: (data) =>
    // @ts-expect-error TS2554
    dispatch(CommentActions.commentGetsRequest(data)),
  // @ts-expect-error TS7006
  dispatchSetProgramInfoPayload: (payload) =>
    // @ts-expect-error TS2554
    dispatch(ProgramInfoActions.programInfoGetSuccess(payload)),
  // @ts-expect-error TS7006
  dispatchFetchUsers: (data) => dispatch(TeamActions.teamGetRequest(data)),
  // @ts-expect-error TS7006
  dispatchSetProgramInfoQaPayload: (payload) =>
    // @ts-expect-error TS2554
    dispatch(ProgramInfoActions.programInfoQaGetSuccess(payload)),
  dispatchFetchBatches: () => dispatch(BatchActions.batchGetsRequest()),
});

SideMenu.propTypes = {
  selectedProject: PropTypes.any,
  // @ts-expect-error TS2322
  isSuperUser: PropTypes.bool,
  // @ts-expect-error TS2322
  isAdminUser: PropTypes.bool,
  companyIcon: PropTypes.string,
  programInfo: PropTypes.object,
  programInfoQa: PropTypes.object,
  batches: PropTypes.array,
  // @ts-expect-error TS2322
  active: PropTypes.string,
  // @ts-expect-error TS2322
  sideBarOption: PropTypes.string,
  projects: PropTypes.object,
  project: PropTypes.object,
  history: PropTypes.object,
  myProjects: PropTypes.any,
  dispatchSetProgramInfoPayload: PropTypes.func,
  dispatchSetProgramInfoQaPayload: PropTypes.func,
  dispatchFetchBatches: PropTypes.func,
  onToggleDrawer: PropTypes.func,
  open: PropTypes.any,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  // @ts-expect-error TS2345
)(withRouter(SideMenu));
