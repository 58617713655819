import { ArrowRightOutlined } from "@ant-design/icons";
import { Col, Row, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import PropTypes from "prop-types";
import React, { FC, useEffect, useRef, useState } from "react";
import ReactDragListView from "react-drag-listview";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { BatchHypothesis } from "@/../types/BatchHypothesis";
import { ProjectArchive } from "@/../types/ProjectArchive";

import PinSVG from "../../../assets/icons/right_point_pin.svg";
import { IProjectArchiveScreenFullViewComponentProps } from "../../../Screens/project/types/IProjectArchiveScreenFullViewComponentProps.1";
import { getPureStringFromHtml } from "../../../utils/common";
import { dateTimeFormat } from "../../../utils/constants";
import { usePrevious } from "../../../utils/hooks";
import {
  getDiffConent,
  getHypothesisChanged,
  getHypothesisObjectFromArray,
  mixingGreenAndRed,
} from "../../../utils/projectUtils";
import { Button, ButtonSwitch as Switch, Card } from "../../atoms";
import ProjectArchiveCard from "./ProjectArchiveDetailsCard";

const CircleDot10 = styled.div`
  width: 20px;
  height: 20px;
`;

const CircleDot = styled.div`
  width: 25px;
  height: 25px;
`;

const ProjectArchiveScreenFullViewComponent: FC<IProjectArchiveScreenFullViewComponentProps> =
  function (props) {
    const _renderPin = (row: ProjectArchive) => {
      if (row?.archiveType === "project_update") {
        return (
          <>
            <div
              className="number-dot"
              style={{
                top: "39%",
                left: "8%",
              }}
            >
              <span className="number-span">
                {/*
                 // @ts-expect-error TS18048 */}
                {props.pins.indexOf(row.id) + 1}
              </span>
            </div>
            <img
              src={PinSVG}
              className="pin-dot drag-handle"
              style={{
                width: "26px",
                height: "26px",
                top: "35%",
                left: "-24px",
              }}
            />
          </>
        );
      }
      return (
        <>
          <div
            className="number-dot"
            style={{
              top: "39%",
              left: "8%",
            }}
          >
            <span className="number-span">
              {/*
               // @ts-expect-error TS18048 */}
              {props.pins.indexOf(row.id) + 1}
            </span>
          </div>
          <img
            src={PinSVG}
            className="pin-dot drag-handle"
            style={{
              width: "26px",
              height: "26px",
              top: "34%",
              left: "-26px",
            }}
          />
        </>
      );
    };
    const addToSelected = (row: ProjectArchive) => {
      props.onAddToSelectedArchives(row);
    };
    const removeFromSelected = (row: ProjectArchive) => {
      props.onAddToSelectedArchives(
        row,
        // @ts-expect-error TS2345
        row.id,
      );
    };
    const getPinTableColumns = (): ColumnsType<ProjectArchive> => {
      const { selectedArchives } = props;
      const selectedArchiveId = selectedArchives.map((a) => a.id);
      return [
        {
          title: (
            <Button
              type="link"
              icon={<ArrowRightOutlined style={{ color: "transparent" }} />}
            />
          ),
          dataIndex: "record.meta.title",
          key: "title",
          fixed: "left",
          width: 60,
          render: function _fn(text, row, index) {
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  height: "84px",
                  minHeight: "84px",
                }}
              >
                <div
                  style={{
                    position: "relative",
                    marginRight: "30px",
                    marginLeft: "30px",
                    height: "inherit",
                  }}
                >
                  <div
                    className="line-in-middle"
                    onClick={() => {
                      if (selectedArchiveId.includes(row.id)) {
                        removeFromSelected(row);
                      } else {
                        addToSelected(row);
                      }
                    }}
                  >
                    <div
                      style={{ marginLeft: "20px" }}
                      className="just-line "
                    />

                    {selectedArchiveId.includes(row.id) ? (
                      <>
                        {row.archiveType === "project_update" ? (
                          <CircleDot10 className="circle-dot" />
                        ) : (
                          <CircleDot className="circle-dot" />
                        )}
                      </>
                    ) : (
                      <>
                        {row.archiveType === "project_update" ? (
                          <CircleDot10 className="circle-dot-dash-outline" />
                        ) : (
                          <CircleDot className="circle-dot-dash-outline" />
                        )}
                      </>
                    )}
                    {selectedArchiveId.includes(row.id)
                      ? // @ts-expect-error TS2554
                        _renderPin(row, selectedArchives)
                      : null}
                  </div>
                </div>
              </div>
            );
          },
        },
      ];
    };
    const getTableColumns = (): ColumnsType<ProjectArchive> => {
      return [
        {
          title: <FormattedMessage id="screen.label.change_history" />,
          dataIndex: "record.meta.title",
          key: "title",
          render: function _fn(text, row, index) {
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  marginLeft: "1vw",
                  height: "84px",
                  minHeight: "84px",
                  width: "150px",
                }}
              >
                <Link
                  rel="noreferrer"
                  to={`/dashboard/project.archive/${props.projectId}/${row.id}`}
                  style={{
                    fontStyle: "normal",
                    fontWeight: "normal",
                    fontSize: "0.8rem",
                    lineHeight: "200%",
                    color: "#1F86E9",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {row.record.meta && row.record.meta.title
                    ? row.record.meta.title
                    : "------"}
                </Link>
                <p
                  style={{
                    color: "#B5B5B5",
                    fontStyle: "normal",
                    fontWeight: "normal",
                    fontSize: "0.8rem",
                    lineHeight: "200%",
                  }}
                >
                  {moment(row.createdAt).local().format(dateTimeFormat)}
                </p>
              </div>
            );
          },
        },
        {
          title: (
            <Button
              style={{ float: "right" }}
              onClick={props.onToggleSplitView}
              type="link"
              icon={<ArrowRightOutlined style={{ color: "black" }} />}
            />
          ),
        },
      ];
    };
    const getDragProps = () => {
      return {
        onDragEnd: (fromIndex: number, toIndex: number): void => {
          const { selectedArchives } = props;
          const selectedArchiveId = selectedArchives.map((arc) => arc.id);
          const item = props.archiveList[fromIndex];
          for (
            let i = selectedArchives.length;
            i < props.archiveList.length;
            i++
          ) {
            //@ts-expect-error TS2322
            selectedArchives[i] = null;
          }
          let indexInSelectedArchive = -1;
          for (let i = 0; i < selectedArchiveId.length; i++) {
            if (selectedArchiveId[i] === item.id) {
              indexInSelectedArchive = i;
            }
          }
          selectedArchives.splice(indexInSelectedArchive, 1);
          selectedArchives.splice(
            indexInSelectedArchive,
            0,
            props.archiveList[toIndex],
          );
          const reor = selectedArchives.filter((arr) => arr != null);
          props.reSortSelectedArchives(reor);
        },
        handleSelector: ".drag-handle",
      };
    };

    return (
      <Card>
        <div
          className={"project-archive-split"}
          style={{ display: "flex", flexDirection: "row" }}
        >
          <ReactDragListView {...getDragProps()}>
            <Table
              rowClassName={"archive-split-row"}
              pagination={false}
              rowKey={"id"}
              columns={getPinTableColumns()}
              dataSource={props.archiveList}
            />
          </ReactDragListView>
          <Table
            style={{ width: "100%" }}
            rowClassName={"archive-split-row"}
            pagination={false}
            rowKey={"id"}
            columns={getTableColumns()}
            dataSource={props.archiveList}
          />
        </div>
      </Card>
    );
  };

const TAB_VIEW_ALL = 2;
const TAB_VIEW_ONLY_CHANGE = 1;
const GREEN_MODE = 1;
const RED_MODE = -1;
type IProjectArchiveScreenSplitViewComponentProps = {
  completeArchiveList: ProjectArchive[];
  onAddToSelectedArchives: (
    archive: ProjectArchive,
    archiveIdToUnSelect?: string,
  ) => void;
  onToggleSplitView: () => void;
  projectId: number | string;
  projectSetting: BatchHypothesis[];
  reSortSelectedArchives: (data: ProjectArchive[]) => void;
  selectedArchives: ProjectArchive[];
} & React.HTMLAttributes<Element>;
type ProjectArchiveScreenSplitViewComponentState = {
  selectedTabView?: number;
  tooltipVisible?: boolean;
  pins?: number[];
  selectPairs?: undefined[];
  diffPairs?: undefined[];
};

const ProjectArchiveScreenSplitViewComponent: FC<IProjectArchiveScreenSplitViewComponentProps> =
  function (props) {
    const intl = useIntl();
    const [state, customSetState] =
      useState<ProjectArchiveScreenSplitViewComponentState>(() => {
        return {
          selectedTabView: TAB_VIEW_ALL,
          tooltipVisible: false,
          pins: props.selectedArchives.map((sp) => sp.id),
          selectPairs: [],
          diffPairs: [],
        };
      });
    const setStateCallbackRef = useRef(() => {});
    useEffect(() => {
      const callBack = setStateCallbackRef.current;
      callBack();
      setStateCallbackRef.current = () => {};
    }, [state]);
    const setState = (
      data: ProjectArchiveScreenSplitViewComponentState,
      callback = () => {},
    ) => {
      setStateCallbackRef.current = callback;
      customSetState((previousState) => {
        return {
          ...previousState,
          ...data,
        };
      });
    };

    useEffect(() => {
      setDiffPairs(props.selectedArchives);
    }, []);

    const prevProps: IProjectArchiveScreenSplitViewComponentProps =
      usePrevious(props);
    useEffect(() => {
      const { selectedArchives } = props;
      if (props.selectedArchives !== prevProps?.selectedArchives) {
        setState({
          pins: props.selectedArchives.map((sp) => sp.id),
        });
        setDiffPairs(selectedArchives);
      }
      if (
        props.projectSetting !== prevProps?.projectSetting &&
        props.selectedArchives.length > 0
      ) {
        setState({
          pins: props.selectedArchives.map((sp) => sp.id),
        });
        setDiffPairs(selectedArchives);
      }
    });

    const setDiffPairs = (selectedArchives: ProjectArchive[]) => {
      if (selectedArchives.length < 1) {
        return;
      }
      if (selectedArchives.length > 1) {
        const selectPairs = [];
        const mergedHypothesis = props.projectSetting;
        for (let index = 1; index < selectedArchives.length; index++) {
          const prevArchive = selectedArchives[index - 1].record;
          const curArchive = selectedArchives[index].record;
          const prevRecord = getHypothesisObjectFromArray(prevArchive);
          const curRecord = getHypothesisObjectFromArray(curArchive);
          const redDiff = mergedHypothesis.map((hypothesis, index) =>
            getHypothesisChanged(
              getPureStringFromHtml(prevRecord[hypothesis.hypothesisFormId]),
              getPureStringFromHtml(curRecord[hypothesis.hypothesisFormId]),
              RED_MODE,
            ),
          );
          const greenDiff = mergedHypothesis.map((hypothesis, index) =>
            getHypothesisChanged(
              getPureStringFromHtml(prevRecord[hypothesis.hypothesisFormId]),
              getPureStringFromHtml(curRecord[hypothesis.hypothesisFormId]),
              GREEN_MODE,
            ),
          );
          // @ts-expect-error TS7022
          const pastGDiff = selectPairs[index - 1];
          if (pastGDiff) {
            selectPairs[index - 1] = mixingGreenAndRed(
              pastGDiff,
              redDiff,
              mergedHypothesis.length,
            );
          } else {
            selectPairs[index - 1] = redDiff;
          }
          selectPairs[index] = greenDiff;
        }
        const diffPairs = [];
        for (let indexDiff = 0; indexDiff < selectPairs.length; indexDiff++) {
          if (indexDiff === 0) {
            diffPairs.push(
              getDiffConent(
                selectPairs[indexDiff + 1],
                selectPairs[indexDiff],
                mergedHypothesis.length,
              ),
            );
          } else {
            diffPairs.push(
              getDiffConent(
                selectPairs[indexDiff - 1],
                selectPairs[indexDiff],
                mergedHypothesis.length,
              ),
            );
          }
        }
        setState({
          // @ts-expect-error TS2322
          selectPairs,
          // @ts-expect-error TS2322
          diffPairs,
        });
      }
    };
    const renderCards = (item: ProjectArchive, index: number) => {
      return (
        <div
          // @ts-expect-error TS2322
          key={item.createdAt}
          className={"card-archive-wrapper-view"}
          style={{
            width: "400px",
            display: "inline-block",
            marginLeft: "1vw",
            marginRight: "1vw",
          }}
        >
          <Card
            headStyle={{ borderRadius: "10px" }}
            bodyStyle={{ borderRadius: "10px" }}
            bordered={false}
          >
            <Row justify="start" align="middle">
              <div className="card-number-dot" style={{ padding: "6%" }}>
                <span className="card-number-span">{index + 1}</span>
              </div>

              <span
                style={{
                  marginLeft: "10px",
                  fontSize: "0.8rem",
                }}
              >
                {" "}
                ID: {item.id}
              </span>
              <span
                style={{
                  marginLeft: "10px",
                  fontSize: "0.8rem",
                }}
              >
                {moment(item.createdAt).local().format("YYYY-MM-DD HH:mm:ss")}
              </span>
            </Row>
            <Row justify="start" align="middle">
              <Link
                rel="noreferrer"
                to={`/dashboard/project.archive/${props.projectId}/${item.id}`}
                style={{
                  color: "#1F86E9",
                  marginTop: "20px",
                  fontSize: "1.2rem",
                  fontStyle: "normal",
                  fontWeight: "bold",
                  lineHeight: "30px",
                }}
              >
                {item.record.meta && item.record.meta.title
                  ? item.record.meta.title
                  : "------"}
              </Link>
            </Row>
            {
              // @ts-expect-error TS2786
              <ProjectArchiveCard
                projectSetting={props.projectSetting}
                selectedArchives={props.selectedArchives}
                archive={item}
                // @ts-expect-error TS18048
                diffOnly={state.diffPairs[index]}
                // @ts-expect-error TS18048
                diffComplete={state.selectPairs[index]}
                // @ts-expect-error TS2322
                showType={state.selectedTabView}
              />
            }
          </Card>
        </div>
      );
    };

    return (
      <>
        <Row>
          <Col xs={24} sm={14} md={10} lg={6}>
            <ProjectArchiveScreenFullViewComponent
              pins={state.pins}
              onToggleSplitView={props.onToggleSplitView}
              reSortSelectedArchives={props.reSortSelectedArchives}
              onAddToSelectedArchives={props.onAddToSelectedArchives}
              selectedArchives={props.selectedArchives}
              projectId={props.projectId}
              archiveList={props.completeArchiveList}
            />
          </Col>
          <Col xs={24} sm={10} md={14} lg={18}>
            <div style={{ marginLeft: "1vw", marginTop: "1.5vh" }}>
              <Row>
                <Col style={{ marginLeft: 20 }}>
                  <Switch
                    onChange={() => {
                      setState({
                        selectedTabView:
                          state.selectedTabView === TAB_VIEW_ONLY_CHANGE
                            ? TAB_VIEW_ALL
                            : TAB_VIEW_ONLY_CHANGE,
                      });
                    }}
                    checked={state.selectedTabView === TAB_VIEW_ONLY_CHANGE}
                  />{" "}
                  <FormattedMessage
                    id={"screen.project.archive.toggle_archive_view"}
                  />
                </Col>
              </Row>
              <br />
              <Row>
                <div
                  style={{
                    overflow: "auto",
                    whiteSpace: "nowrap",
                    display: "flex",
                  }}
                >
                  {props.selectedArchives.map((p, index) =>
                    renderCards(p, index),
                  )}

                  {props.selectedArchives.length < 2 && (
                    <div
                      className={"card-archive-wrapper-view"}
                      style={{
                        width: "400px",
                        display: "inline-block",
                        marginLeft: "1vw",
                        marginRight: "1vw",
                      }}
                    >
                      <Card
                        headStyle={{
                          borderRadius: "10px",
                        }}
                        bodyStyle={{
                          borderRadius: "10px",
                        }}
                        bordered={false}
                      >
                        <Row justify="start" align="middle">
                          <h3>
                            {intl.formatMessage({
                              id: "screen.label.unselected",
                            })}
                          </h3>
                        </Row>

                        <Row>
                          <Col span={24}>
                            <div
                              style={{
                                whiteSpace: "pre-line",
                                wordWrap: "break-word",
                              }}
                            >
                              <p>
                                {intl.formatMessage({
                                  id: "screen.label.see_only_change_history",
                                })}
                              </p>
                            </div>
                          </Col>
                        </Row>
                      </Card>
                    </div>
                  )}
                </div>
              </Row>
            </div>
          </Col>
        </Row>
      </>
    );
  };

ProjectArchiveScreenFullViewComponent.propTypes = {
  pins: PropTypes.array,
  //@ts-expect-error TS2322
  archiveList: PropTypes.array,
  //@ts-expect-error TS2322
  selectedArchives: PropTypes.array,
  //@ts-expect-error TS2322
  onAddToSelectedArchives: PropTypes.func,
  //@ts-expect-error TS2322
  reSortSelectedArchives: PropTypes.func,
  //@ts-expect-error TS2322
  onToggleSplitView: PropTypes.func,
  projectId: PropTypes.any,
};

ProjectArchiveScreenSplitViewComponent.propTypes = {
  // @ts-expect-error TS2322
  selectedArchives: PropTypes.array,
  // @ts-expect-error TS2322
  projectSetting: PropTypes.array,
  // @ts-expect-error TS2322
  completeArchiveList: PropTypes.array,
  projectId: PropTypes.any,
  // @ts-expect-error TS2322
  onToggleSplitView: PropTypes.func,
  // @ts-expect-error TS2322
  reSortSelectedArchives: PropTypes.func,
  // @ts-expect-error TS2322
  onAddToSelectedArchives: PropTypes.func,
};
export default ProjectArchiveScreenSplitViewComponent;
