import { memo, useCallback } from "react";
import { useIntl } from "react-intl";

import { notifyError, notifySuccess } from "@/utils/notification/notify";

import { PublishReviewConfirmButton } from "../PublishReviewConfirmButton/component";
import styles from "./styles.module.scss";
import { PublishReviewButtonProps } from "./type";
export const PublishReviewButton = memo(
  ({
    publishReview,
    selectedPublishTargets,
    hasSelectedTargets,
    resetSelectedPublishTargets,
    countOfSelectedTargets,
  }: PublishReviewButtonProps) => {
    const intl = useIntl();
    const successMessage = intl.formatMessage({
      id: "screen.label.examination_information_successfully_published",
    });
    const errorMessage = intl.formatMessage({
      id: "screen.label.examination_information_publish_failed",
    });
    const onConfirm = useCallback(async () => {
      const response = await publishReview({
        submittedProjectUuids: selectedPublishTargets,
      });
      if (response.ok) {
        notifySuccess(successMessage);
        resetSelectedPublishTargets();
      } else {
        notifyError(errorMessage);
      }
    }, [
      publishReview,
      selectedPublishTargets,
      resetSelectedPublishTargets,
      successMessage,
      errorMessage,
    ]);
    return (
      <PublishReviewConfirmButton
        countOfSelectedTargets={countOfSelectedTargets}
        data-testid="publish-review-button"
        disabled={!hasSelectedTargets}
        onConfirm={onConfirm}
        className={styles.publish_review_button}
      />
    );
  },
);
