import { api } from "@/Saga";
import { endpoints, parseEndpoint } from "@/Services/endpoints";

export const importExcelRequest = async (file: File) => {
  const formData = new FormData();
  formData.append("file", file);
  // @ts-expect-error TS2345
  const url = parseEndpoint(endpoints.examinationExcelImport, {});
  const response = await api.putRequest<FormData, ArrayBuffer>(url, formData, {
    responseType: "arraybuffer",
  });
  return response;
};
