import { memo, useRef } from "react";
import { useIntl } from "react-intl";

import styles from "@/features/review/components/ReviewExcelUploadArea/styles.module.scss";
import { ReviewExcelUploadAreaProps } from "@/features/review/components/ReviewExcelUploadArea/type";
import { FileDroppableArea } from "@/shared/upload/components/FileDroppableArea";
import { FileSelectItem } from "@/shared/upload/components/FileSelectItem";

import { ExcelFileNameLabel } from "../ExcelFileNameLabel/component";

export const ReviewExcelUploadArea = memo(
  ({
    file,
    hasError,
    handleAttach,
    handleDrop,
    removeFile,
  }: ReviewExcelUploadAreaProps) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const onClick = () => {
      inputRef.current?.click();
    };
    const intl = useIntl();
    const fileSelectMessage = intl.formatMessage({
      id: "screen.label.select_file",
    });
    const attachMessage = intl.formatMessage({
      id: "screen.label.review_excel_attach_message",
    });
    const uploadAreaContentClass = file ? "" : styles.upload_area_content;

    return (
      <div className={styles.upload_area_container}>
        <FileDroppableArea
          handleAttach={handleAttach}
          handleDrop={handleDrop}
          accept=".xlsx"
          inputRef={inputRef}
          dropAreaClass={styles.drop_area}
        >
          <div className={uploadAreaContentClass}>
            {file ? (
              <ExcelFileNameLabel
                hasError={hasError}
                file={file}
                removeFile={removeFile}
              />
            ) : (
              <FileSelectItem onClick={onClick}>
                <div className={styles.upload_area_message}>
                  {fileSelectMessage}
                </div>
                <div className={styles.upload_area_message}>
                  {attachMessage}
                </div>
              </FileSelectItem>
            )}
          </div>
        </FileDroppableArea>
      </div>
    );
  },
);
