import { Button, Card, Radio, Row } from "antd";
import { FormattedMessage } from "react-intl";

import { WatchSettingRoot } from "../../Components/molecules";
import { WatchProjectList } from "../../Components/organisms";
import useWatchSettingsScreen from "./hooks/useWatchSettingsScreen";

const watchListObject = [
  {
    watchType: "watchEnableNewProjects",
    title: (
      <FormattedMessage id="screen.label.automatic_creating_watch_with_new" />
    ),
    isAdmin: false,
  },
];
const defaultNewWatchOptions = [
  { label: <FormattedMessage id="screen.label.set" />, value: true },
  { label: <FormattedMessage id="screen.label.not_set" />, value: false },
];
function WatchSettingsScreen() {
  const {
    intl,
    watchListFromServer,
    watchListRef,
    changeWatchStatus,
    checkedWatchState,
  } = useWatchSettingsScreen();
  return (
    <>
      <h3 style={{ fontWeight: "bolder" }}>
        {intl.formatMessage({
          id: "screen.label.watch_personal_setting",
        })}
      </h3>
      <br />
      <Card>
        {watchListObject.map((watch, i) => {
          const radioOptions = defaultNewWatchOptions;
          return (
            <WatchSettingRoot key={i}>
              <Row style={{ marginBottom: "1vh" }}>
                <span style={{ fontWeight: "bolder" }}>{watch.title}</span>
              </Row>
              <Row>
                <Radio.Group
                  options={radioOptions}
                  onChange={(e) => {
                    changeWatchStatus(watch.watchType, e.target.value);
                  }}
                  value={checkedWatchState(watchListFromServer, watch)}
                />
              </Row>
            </WatchSettingRoot>
          );
        })}
        <WatchSettingRoot>
          <Row style={{ marginBottom: "1vh" }}>
            <span style={{ fontWeight: "bolder" }}>
              <FormattedMessage id="screen.label.enable_watch_old_project" />
            </span>
          </Row>
          <Row>
            <Button
              type="primary"
              className="primary-outline"
              onClick={() => changeWatchStatus("watchEnablePastProjects", true)}
            >
              <FormattedMessage id="screen.label.watch_all" />
            </Button>
          </Row>
        </WatchSettingRoot>
      </Card>
      <br />
      <br />
      <br />
      <Row align="middle" justify="space-between">
        <h3 style={{ fontWeight: "bolder" }}>
          {intl.formatMessage({
            id: "screen.label.wathcing_list",
          })}
        </h3>
        <Button
          type="primary"
          className="primary-outline"
          onClick={() => changeWatchStatus("watchEnablePastProjects", false)}
        >
          <FormattedMessage id="screen.label.remove_watch_all" />
        </Button>
      </Row>
      <br />
      <Card>
        <WatchProjectList
          ref={watchListRef}
          watchSettingList={watchListFromServer}
        />
      </Card>
    </>
  );
}
export default WatchSettingsScreen;
