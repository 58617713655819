import { DateTime } from "luxon";
import { memo } from "react";

import CsvExportModal from "../../../../Components/organisms/project/CsvExportModal";
import { ExportCsvButtonProps } from "./type";

export const ExportCsvButton = memo(
  ({ batchId, canShow, categoryId, disabled }: ExportCsvButtonProps) => {
    if (!canShow) return null;
    const categoryFilter = categoryId ? `&category=${categoryId}` : "";
    const batchesFilter = `&batches[]=${batchId}`;
    const url = `/submissions/download?${categoryFilter}${batchesFilter}&timeZone=${
      DateTime.local().zoneName
    }`;

    return (
      <CsvExportModal
        url={url}
        title="screen.label.csv_download"
        btnStyle={{ float: "right" }}
        disabled={disabled}
      />
    );
  },
);
