import { Card, Row } from "antd";
import { Link } from "react-router-dom";

import { ButtonOption } from "../../Components/atoms";
import { DraftEditor } from "../../Components/molecules";
import useProgramInfoViewQAScreen from "./hooks/useProgramInfoViewQAScreen";
function ProgramInfoViewQAScreen() {
  const { intl, authUser, editorState } = useProgramInfoViewQAScreen();
  return (
    <>
      <Row align="middle" justify="end">
        {authUser.type === "admin" ? (
          <Link to={"/dashboard/program-info-view-qa-mgt"}>
            <ButtonOption className="primary-outline" type="primary">
              {intl.formatMessage({
                id: "screen.label.to_edit",
              })}
            </ButtonOption>
          </Link>
        ) : null}
      </Row>
      <br />
      <Card>
        <DraftEditor
          editorClassName={"shortEditor"}
          toolbarHidden={true}
          readOnly={false}
          placeholder={intl.formatMessage({
            id: "screen.label.please_enter",
          })}
          editorState={editorState}
          onEditorStateChange={() => {}}
        />
      </Card>
    </>
  );
}
export default ProgramInfoViewQAScreen;
