import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { Card, Col, List, Row } from "antd";
import PropTypes from "prop-types";
import { FC } from "react";
import ReactPlayer from "react-player/lazy";
import { Link } from "react-router-dom";

import { ButtonOption, IconDownload } from "../../Components/atoms";
import { DraftEditor } from "../../Components/molecules";
import {
  convertFromRawWithTrimmer,
  sanitizeContentStateMarkupHtml,
} from "../../utils/common";
import useProgramInfoViewScreen from "./hooks/useProgramInfoViewScreen";
import { IProgramInfoViewScreenProps } from "./types/IProgramInfoViewScreenProps";

const ProgramInfoViewScreen: FC<IProgramInfoViewScreenProps> = function (
  props,
) {
  const { intl, state, user, saveUserFile } = useProgramInfoViewScreen(props);

  const downloadFile = (item: any) => {
    const href =
      item.hash.indexOf("http") === -1 ? `https://${item.hash}` : item.hash;
    saveUserFile(href, item.fileName, item.fileName.split(".").pop(), item.id);
  };

  return (
    <div className={"ProgramInfoViewScreen"}>
      <Row align="middle" justify="end">
        {user.type === "admin" ? (
          <Link to={"/dashboard/program-info-view-mgt"}>
            <ButtonOption>
              {intl.formatMessage({
                id: "screen.label.to_edit",
              })}
            </ButtonOption>
          </Link>
        ) : null}
      </Row>
      <br />
      <Card>
        <Col span={18}>
          <Row>
            <Col span={24}>
              {state.editorStateInJson.length > 0 ? (
                <DraftEditor
                  editorClassName={"shortEditor"}
                  toolbarHidden={true}
                  readOnly={true}
                  editorState={sanitizeContentStateMarkupHtml(
                    convertFromRawWithTrimmer(state.editorStateInJson),
                  )}
                />
              ) : null}
            </Col>
          </Row>
          <br />
          <Row>
            <Col span={24}>
              <h4>
                {intl.formatMessage({
                  id: "screen.label.public_file",
                })}
              </h4>
              <List
                size="large"
                dataSource={state.files}
                renderItem={(item) => (
                  <List.Item
                    actions={[
                      <div
                        // @ts-expect-error TS18046
                        key={item.id}
                        onClick={() => {
                          downloadFile(item);
                        }}
                      >
                        <a target="_blank">{IconDownload}</a>
                      </div>,
                    ]}
                  >
                    {/*
                     // @ts-expect-error TS18046 */}
                    {item.fileName}
                  </List.Item>
                )}
              />
            </Col>
          </Row>
        </Col>
      </Card>
      <br />
      <br />
      <Row>
        <Col span={24}>
          <div style={{ height: "70vh" }}>
            {state.videoUrl && state.videoUrl.length > 0 ? (
              <ReactPlayer
                loop={false}
                controls
                className="react-player"
                url={state.videoUrl}
                width="100%"
                height="100%"
                config={{
                  file: {
                    attributes: {
                      controlsList: "nodownload",
                    },
                  },
                }}
              />
            ) : null}
          </div>
        </Col>
      </Row>
    </div>
  );
};

ProgramInfoViewScreen.propTypes = {
  // @ts-expect-error TS2322
  context: PropTypes.object,
  history: PropTypes.object,
};
export default ProgramInfoViewScreen;
