import { Modal, Spin } from "antd";
import { useState } from "react";
import { useIntl } from "react-intl";

import { DeleteConfirmationForm } from "../DeleteConfirmationForm";
import { DeleteResultText } from "../DeleteResultText";
import { useDeleteCompany } from "./hooks";
import styles from "./styles.module.scss";
import { CompanyDeleteModalProps, CurrentContentTypes } from "./type";

export const CompanyDeleteModal = ({
  company,
  isVisible,
  hideModal,
}: CompanyDeleteModalProps) => {
  const [currentContent, setCurrentContent] =
    useState<CurrentContentTypes>("confirmation");
  const [isError, setIsError] = useState(false);
  const [errorCode, setErrorCode] = useState<undefined | number>(undefined);
  const intl = useIntl();
  const { deleteCompany } = useDeleteCompany();

  const titleText = intl.formatMessage({ id: "screen.label.delete_company" });

  const cancelHandler = () => {
    hideModal();
    setCurrentContent("confirmation");
  };

  const deleteCompanyHandler = async () => {
    setCurrentContent("loading");
    const response = await deleteCompany(company.id);

    if (!response.ok) {
      setIsError(true);
      setErrorCode(response.status);
    }

    setCurrentContent("finished");
  };

  return (
    <Modal
      title={titleText}
      visible={isVisible}
      footer={null}
      onCancel={cancelHandler}
      width={800}
      destroyOnClose
    >
      <div className={styles.content}>
        {currentContent == "confirmation" && (
          <DeleteConfirmationForm
            company={company}
            deleteCompanyHandler={deleteCompanyHandler}
            hideModal={hideModal}
          />
        )}

        {currentContent == "loading" && <Spin />}

        {currentContent == "finished" && (
          <DeleteResultText
            company={company}
            isError={isError}
            errorCode={errorCode}
          />
        )}
      </div>
    </Modal>
  );
};
