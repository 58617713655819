import { Tag } from "antd";
import { memo } from "react";

import { useUserTypeLabel } from "../../../../hooks/useUserTypeLabel";
import styles from "./styles.module.scss";
import { PropsUserTypeTag } from "./type";

export const UserTypeTag = memo((props: PropsUserTypeTag) => {
  const { user, className, ...rest } = props;
  const userType = useUserTypeLabel(user);
  return (
    <Tag className={`${styles.user_type_tag} ${className}`} {...rest}>
      <span title={userType} className={styles.user_type_tag_text}>
        {userType}
      </span>
    </Tag>
  );
});
