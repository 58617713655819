import { Col, Row } from "antd";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import {
  Card,
  ResizableIconCaclendar,
  ResizableIconChart,
  ResizableIconDownload,
  ResizableIconUsers2,
} from "../../Components/atoms";
import {
  AnalyticsCardComponent,
  AnalyticsListComponent,
} from "../../Components/organisms/analytics";
import { getObjUUID } from "../../utils/common";
import useAnalyticsScreen from "./hooks/useAnalyticsScreen";
import { IAnalyticsScreenProps } from "./types/IAnalyticsScreenProps";

const AnalyticsScreen: FC<IAnalyticsScreenProps> = function (
  props: IAnalyticsScreenProps,
) {
  const {
    state,
    onFileDownloadClick,
    user,
    trimOverflowText,
    trimOverflowFilename,
    getLabel,
  } = useAnalyticsScreen(props);

  const labelName = getLabel("screen.label.name");
  const labelStop = getLabel("screen.label.stop");
  const labelDelete = getLabel("screen.label.delete");
  const titleMember = <FormattedMessage id="screen.label.member" />;
  const titleProject = <FormattedMessage id="screen.label.project" />;
  const titleEvent = <FormattedMessage id="screen.label.events" />;
  const titleVideoViewed = (
    <FormattedMessage id="screen.label.video_that_well_being_viewed" />
  );

  return (
    <>
      <Row gutter={[12, 16]}>
        <Col xs={24} sm={12} md={8} lg={8}>
          <AnalyticsCardComponent
            title={titleMember}
            icon={<ResizableIconUsers2 width={40} height={28} />}
            number={state.statistics.totalUsers}
            description={`( ${labelStop} : ${
              state.statistics.totalStoppedUsers
            } ${labelName} ${
              user.isSuper
                ? `, ${labelDelete} : ${state.statistics.totalDeletedUsers}${labelName}`
                : ""
            } )`}
          />
        </Col>
        <Col xs={24} sm={12} md={8} lg={8}>
          <AnalyticsCardComponent
            title={titleProject}
            icon={<ResizableIconChart width={36} height={36} />}
            number={state.statistics.totalProjects}
          />
        </Col>
        <Col xs={24} sm={12} md={8} lg={8}>
          <AnalyticsCardComponent
            title={titleEvent}
            icon={<ResizableIconCaclendar width={40} height={44} />}
            number={state.statistics.totalEvents}
          />
        </Col>
      </Row>
      <br />
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Card>
            <Row>
              <Col
                xs={24}
                sm={{ offset: 1, span: 22 }}
                md={{ offset: 2, span: 20 }}
                lg={{ offset: 4, span: 16 }}
              >
                <div>
                  <AnalyticsListComponent
                    top
                    dataSource={state.statistics.mostWatched}
                    renderItem={(item) => (
                      <>
                        <span
                          style={{
                            flexGrow: 1,
                            fontWeight: "normal",
                            fontStyle: "normal",
                            fontSize: "14px",
                            lineHeight: "200%",
                            color: "#323232",
                          }}
                        >
                          <Link
                            id={`analytics-videos-${item.videoId}`}
                            to={`/dashboard/videos-play/${item.videoId}`}
                          >
                            {item.videoName}
                          </Link>
                        </span>
                        <span
                          style={{
                            fontSize: "14px",
                            lineHeight: "20px",
                            fontWeight: "normal",
                          }}
                        >
                          {item.numberOfTimesPlayed}
                        </span>
                      </>
                    )}
                    title={titleVideoViewed}
                  />
                  <AnalyticsListComponent
                    dataSource={state.statistics.topEvents}
                    renderItem={(item) => (
                      <>
                        <span
                          style={{
                            flexGrow: 1,
                            fontWeight: "normal",
                            fontStyle: "normal",
                            fontSize: "12px",
                            lineHeight: "200%",
                            color: "#323232",
                          }}
                        >
                          <Link
                            id={`analytics-events-${getObjUUID(item)}`}
                            to={`/dashboard/events.detail/${getObjUUID(item)}`}
                          >
                            {item.eventName}
                          </Link>
                        </span>
                        <span
                          style={{
                            fontSize: "14px",
                            lineHeight: "20px",
                            fontWeight: "normal",
                          }}
                        >
                          {item.membersNumber}
                        </span>
                      </>
                    )}
                    title={
                      <FormattedMessage id="screen.label.participation_many_events" />
                    }
                  />

                  <AnalyticsListComponent
                    dataSource={state.statistics.projectActivities}
                    renderItem={(item) => (
                      <>
                        <span
                          style={{
                            flexGrow: 1,
                            fontWeight: "normal",
                            fontStyle: "normal",
                            fontSize: "12px",
                            lineHeight: "200%",
                            color: "#323232",
                          }}
                        >
                          <Link
                            id={`analytics-project-${getObjUUID(item)}`}
                            to={`/dashboard/project.top/${getObjUUID(item)}`}
                          >
                            {item.projectName}
                          </Link>
                        </span>
                        <span
                          style={{
                            fontSize: "14px",
                            lineHeight: "20px",
                            fontWeight: "normal",
                          }}
                        >
                          {item.totalSumActivities}
                        </span>
                      </>
                    )}
                    title={
                      <FormattedMessage id="screen.label.active_project" />
                    }
                  />

                  <AnalyticsListComponent
                    dataSource={[
                      ...state.statistics.mostDownloadedFiles,
                      ...state.statistics.mostDownloadedUserFiles,
                    ]
                      .sort((a, b) => {
                        return b.downloadNumbers - a.downloadNumbers;
                      })
                      .filter((em, i) => i < 5 && em.downloadNumbers > 0)}
                    renderItem={(item) => (
                      <>
                        <span
                          style={{
                            flexGrow: 1,
                            fontWeight: "normal",
                            fontStyle: "normal",
                            fontSize: "12px",
                            lineHeight: "200%",
                            color: "#323232",
                          }}
                        >
                          {trimOverflowFilename(item.fileName, 23)}
                          <span>
                            {item.projectUuid && item.projectName ? (
                              <Link
                                id={`analytics-file-${item.projectUuid}`}
                                to={`/dashboard/project.top/${item.projectUuid}`}
                              >
                                {` (${trimOverflowText(item.projectName, 23)})`}
                              </Link>
                            ) : (
                              <Link
                                id="analytics-program-info-view"
                                to={"/dashboard/program-info-view"}
                              >
                                （
                                <FormattedMessage id="screen.label.program_overview" />
                                ）
                              </Link>
                            )}
                          </span>
                        </span>
                        <a
                          id={`analytics-filedl-link-${item.fileId}`}
                          target={"_blank"}
                        >
                          <div
                            id={`analytics-filedl-${item.fileId}`}
                            style={{
                              marginRight: "16.5px",
                            }}
                            onClick={() => onFileDownloadClick(item)}
                          >
                            <ResizableIconDownload width={17} height={18} />
                          </div>
                        </a>
                        <span
                          style={{
                            fontSize: "12px",
                            lineHeight: "200%",
                            fontWeight: "normal",
                            borderLeftColor: "#BBBBBB",
                            borderLeftStyle: "solid",
                            borderLeftWidth: "1px",
                            paddingLeft: "16px",
                          }}
                        >
                          {item.downloadNumbers}
                        </span>
                      </>
                    )}
                    title={
                      <FormattedMessage id="screen.label.well_has_been_article" />
                    }
                  />
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default AnalyticsScreen;
