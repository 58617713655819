import { useCallback } from "react";
import { useSWRConfig } from "swr";

export const useSWRCache = () => {
  const { cache } = useSWRConfig();

  const matchedFilter = useCallback(
    (matcher: RegExp) => {
      if (cache instanceof Map) {
        const keys = Array.from<string>(cache.keys());
        return keys.filter((key) => matcher.test(key));
      }
    },
    [cache],
  );

  const deleteMatchedCaches = useCallback(
    (matcher: RegExp) => {
      const matchedKeys = matchedFilter(matcher);
      matchedKeys?.forEach((key) => cache.delete(key));
    },
    [cache, matchedFilter],
  );

  return {
    cache,
    matchedFilter,
    deleteMatchedCaches,
  };
};
