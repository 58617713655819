import "react-confirm-alert/src/react-confirm-alert.css";

import { Card, Modal, notification, Radio, Row, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { FC } from "react";
import { confirmAlert } from "react-confirm-alert"; // Import
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { DateTimeLabel } from "@/shared/label/DateTimeLabel";

import { ButtonOption, Checkbox, Tag, UserIcon } from "../../Components/atoms";
import { CustomPagination } from "../../Components/molecules";
import {
  EventSurveyFormComponent,
  FCFSEventReminderComponent,
  LotteryEventReminderComponent,
} from "../../Components/organisms";
import CsvExportModal from "../../Components/organisms/project/CsvExportModal";
import EventActions from "../../Redux/EventRedux";
import { getDisplayName, getObjUUID } from "../../utils/common";
import useEventMembersListScreen from "./hooks/useEventMembersListScreen";
import {
  EventMemberInfo,
  IEventMembersListScreenProps,
} from "./types/IEventMembersListScreenProps";

const PageSaveId = "EventMembersListScreen";

const EventMembersListScreen: FC<IEventMembersListScreenProps> = function (
  props: IEventMembersListScreenProps,
) {
  const {
    intl,
    state,
    getEventId,
    onChangeMemberEventsPage,
    setState,
    _canAttendCheckBox,
    constUpdateEventMember,
    onCloseEventReminderModal,
    onCloseFCFSEventReminderModal,
  } = useEventMembersListScreen(props);

  const { apiRequest, user } = props.context;

  const columns: ColumnsType<EventMemberInfo> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: intl.formatMessage({
        id: "screen.events.full_name",
      }),
      dataIndex: "name",
      key: "name",
      render: function _name(text, row, index) {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div style={{ minWidth: 50 }}>
              {/* TODO: profileImageUrlがAPIから送られてないので要修正 */}
              <UserIcon userId={row.id} url={""} />
            </div>
            <Link to={"/dashboard/member/" + getObjUUID(row)}>
              {getDisplayName(row)}
            </Link>
          </div>
        );
      },
    },
    {
      title: intl.formatMessage({
        id: "screen.label.email",
      }),
      dataIndex: "email",
      key: "email",
      render: function _email(text, row, index) {
        return (
          <div style={{ width: "15vw", wordWrap: "break-word" }}>{text}</div>
        );
      },
    },
    {
      title: intl.formatMessage({
        id: "screen.label.admission_participation",
      }),
      dataIndex: "canAttend",
      key: "canAttend",
      render: function _canAttend(t, row) {
        const _requestPayload = {
          eventId: getEventId(),
          userId: row.id,
          canAttend: !row.canAttend,
        };
        return (
          <>
            <Radio.Group
              value={row.canAttendState}
              defaultValue={row.canAttendState}
            >
              <Radio
                value={"undecided"}
                onClick={() => {
                  if (row.canAttendState !== "undecided") {
                    confirmAlert({
                      message: intl.formatMessage({
                        id: "screens.events.participation_unconfirm_alert",
                      }),
                      buttons: [
                        {
                          label: intl.formatMessage({
                            id: "screen.label.confirm",
                          }),
                          onClick: () => {
                            constUpdateEventMember({
                              ..._requestPayload,
                              canAttendState: "undecided",
                            });
                          },
                        },
                        {
                          label: intl.formatMessage({
                            id: "screen.label.cancel",
                          }),
                          onClick: () => {},
                        },
                      ],
                    });
                  }
                }}
              >
                {intl.formatMessage({
                  id: "screens.events.participation_undecided",
                })}
              </Radio>
              <Radio
                onClick={() => {
                  if (row.canAttendState !== "decide_to_participate") {
                    _canAttendCheckBox(
                      {
                        ..._requestPayload,
                        canAttendState: "decide_to_participate",
                      },
                      intl.formatMessage({
                        id: "screens.events.user_participation_decide_to_participate",
                      }),
                    );
                  }
                }}
                value={"decide_to_participate"}
              >
                {intl.formatMessage({
                  id: "screens.events.user_participation_decide_to_participate",
                })}
              </Radio>
              <Radio
                onClick={() => {
                  if (row.canAttendState !== "decide_not_to_participate") {
                    _canAttendCheckBox(
                      {
                        ..._requestPayload,
                        canAttendState: "decide_not_to_participate",
                      },
                      intl.formatMessage({
                        id: "screens.events.participation_cancelled",
                      }),
                    );
                  }
                }}
                value={"decide_not_to_participate"}
              >
                {intl.formatMessage({
                  id: "screens.events.participation_cancelled",
                })}
              </Radio>
            </Radio.Group>
          </>
        );
      },
    },
    {
      title: intl.formatMessage({
        id: "screen.label.join_presence_or_absence",
      }),
      dataIndex: "wasPresent",
      key: "wasPresent",
      render: function _wasPresent(t, row) {
        return (
          <Checkbox
            checked={row.wasPresent}
            onChange={(e) => {
              constUpdateEventMember({
                eventId: getEventId(),
                userId: row.id,
                wasPresent: e.target.checked,
              });
            }}
          />
        );
      },
    },
    {
      render: (_, row) => {
        if (
          row.appliedAppliedAfterLimit &&
          row.eventType === "first-come-first-serve"
        ) {
          return (
            <Tag color={"magenta"}>
              {intl.formatMessage({
                id: "screens.events.waiting_cancellation",
              })}
            </Tag>
          );
        }
      },
    },
    {
      title: intl.formatMessage({
        id: "screens.events.applied_at",
      }),
      render: (_, eventMember) => {
        return (
          <DateTimeLabel
            dateTimeISO={eventMember.appliedAt}
            showHour={true}
            showSecond={true}
          />
        );
      },
    },
  ];
  let exportButton = <></>;
  if (props.eventMembers.count > 0) {
    exportButton = (
      <CsvExportModal
        url={`/events/${getEventId()}/export`}
        title="screen.label.csv_download"
        btnStyle={{
          marginLeft: 10,
        }}
      />
    );
  }
  const eventNotificationButton = (
    <ButtonOption
      onClick={() => {
        //first come first serve
        if (state.eventDetail.type === "first-come-first-serve") {
          setState({
            fcfsEventReminderModalIsVisible: true,
          });
        }
        //lottery
        else {
          setState({
            eventReminderModalIsVisible: true,
          });
        }
      }}
    >
      +
      {intl.formatMessage({
        id: "screens.events.create_event_notification",
      })}
    </ButtonOption>
  );
  const eventEnquete = (
    <ButtonOption
      className="offset-left-16"
      onClick={() => {
        const membersPresent = Array.isArray(props.eventMembers.rows)
          ? props.eventMembers.rows.filter((em) => em.wasPresent)
          : [];
        if (membersPresent.length < 1) {
          notification.error({
            message: intl.formatMessage({
              id: "screens.events.error_message_no_user_present",
            }),
          });
          return;
        }
        notification.success({
          message: intl.formatMessage(
            {
              id: "screens.events.count_participants_notified",
            },
            {
              count: membersPresent.length,
            },
          ),
        });
        setState({ eventSurveyModalIsVisible: true });
      }}
    >
      +
      {intl.formatMessage({
        id: "screen.label.event_questionnaire_created",
      })}
    </ButtonOption>
  );
  return (
    <div className={"EventMembersListScreen"}>
      <Row align="middle" justify="space-between">
        <h2>
          {state.eventDetail?.name}(
          {intl.formatMessage(
            {
              id: "screen.label.participants_count",
            },
            {
              count: props.eventMembers.count,
            },
          )}
          )
        </h2>
        <div style={{ float: "right", display: "flex" }}>
          {eventNotificationButton}
          {eventEnquete}
          {exportButton}
        </div>
      </Row>
      <br />
      <Card>
        <Table
          rowKey={"id"}
          pagination={false}
          columns={columns}
          dataSource={props.eventMembers.rows}
        />
        <br />
        <CustomPagination
          saveId={PageSaveId}
          count={props.eventMembers.count}
          onChangePage={onChangeMemberEventsPage}
        />
      </Card>
      <Modal
        destroyOnClose
        title={intl.formatMessage({
          id: "screens.events.create_event_notification",
        })}
        visible={state.eventReminderModalIsVisible}
        onCancel={() => {
          setState({
            eventReminderModalIsVisible: false,
          });
        }}
        footer={null}
      >
        <LotteryEventReminderComponent
          onCloseEventReminderModal={onCloseEventReminderModal}
          eventId={state.eventDetail ? state.eventDetail.id : -1}
        />
      </Modal>

      <Modal
        destroyOnClose
        title={intl.formatMessage({
          id: "screens.events.create_event_notification",
        })}
        visible={state.fcfsEventReminderModalIsVisible}
        onCancel={() => {
          setState({
            fcfsEventReminderModalIsVisible: false,
          });
        }}
        footer={null}
      >
        <FCFSEventReminderComponent
          onCloseEventReminderModal={onCloseFCFSEventReminderModal}
          eventId={state.eventDetail ? state.eventDetail.id : -1}
        />
      </Modal>
      <Modal
        destroyOnClose
        title={intl.formatMessage({
          id: "screens.title.event_survey",
        })}
        visible={state.eventSurveyModalIsVisible}
        onCancel={() => {
          setState({
            eventSurveyModalIsVisible: false,
          });
        }}
        footer={null}
      >
        <EventSurveyFormComponent
          eventId={state.eventDetail ? state.eventDetail.id : -1}
          onClose={() => {
            setState({
              eventSurveyModalIsVisible: false,
            });
          }}
        />
      </Modal>
    </div>
  );
};

// @ts-expect-error TS7006
const mapStateToProps = (state) => ({
  eventMembers: state.events.eventMembersPayload,
});
// @ts-expect-error TS7006
const mapDispatchToProps = (dispatch) => ({
  // @ts-expect-error TS7006
  dispatchFetchEventMembers: (data) =>
    // @ts-expect-error TS2554
    dispatch(EventActions.eventGetMembersRequest(data)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EventMembersListScreen);
