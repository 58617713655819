import { BasicReviewInfo } from "@/features/review/types/reviewInfo";

/**
 * 公開できるかどうかを判定する
 * 現状の仕様では、BLANK以外は公開できる
 * @param reviewInfo
 * @returns
 */
export const canPublish = (reviewInfo: BasicReviewInfo) => {
  return reviewInfo.isPublished === false && reviewInfo.result !== "BLANK";
};
