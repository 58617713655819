import styled from "styled-components";

import { Checkbox } from "../../atoms";

export const PrivacyPolicyLabelMessage = styled.div`
  white-space: pre-line;
  font-weight: bold;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  margin-top: 36px;
`;
export const PrivacyPolicyLabelSubMessage = styled.div`
  white-space: pre-line;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: #808080;
  margin-top: 32px;
`;
export const PrivacyPolicyCheckboxContainer = styled("div")<any>`
  text-align: center !important;
  margin-top: 14px;
`;
export const PrivacyPolicyCheckbox = styled(Checkbox)<any>`
  font-size: 14px !important;
  line-height: 150% !important;
  color: #808080 !important;
`;
export const PrivacyPolicyButton = styled.div`
  text-align: center;
  margin-top: 40px;
  margin-bottom: 36px;
`;
