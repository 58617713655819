import PropTypes from "prop-types";
import { FC, ReactElement } from "react";

import { Card } from "../../atoms";

type AnalyticsCardComponentProps = {
  title: ReactElement;
  number: number;
  description?: string;
  icon: ReactElement;
};

const AnalyticsCardComponent: FC<AnalyticsCardComponentProps> = function (
  props,
) {
  const { title, description, number, icon } = props;
  return (
    <Card>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "119px",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {icon}
          <div
            style={{
              flexDirection: "column",
              marginLeft: "16px",
            }}
          >
            <p
              style={{
                fontWeight: "normal",
                fontSize: "12px",
                lineHeight: "24px",
                marginBottom: 0,
                marginTop: 0,
              }}
            >
              {title}
            </p>
            <p
              style={{
                fontWeight: "bold",
                fontSize: "16px",
                lineHeight: "23.17px",
                marginBottom: 0,
                marginTop: 0,
              }}
            >
              {number}
            </p>
          </div>
        </div>
        <p
          style={{
            fontSize: "10px",
            lineHeight: "200%",
          }}
        >
          {description}
        </p>
      </div>
    </Card>
  );
};
AnalyticsCardComponent.propTypes = {
  title: PropTypes.element.isRequired,
  description: PropTypes.string,
  number: PropTypes.number.isRequired,
  icon: PropTypes.element.isRequired,
};
AnalyticsCardComponent.defaultProps = {
  description: "",
};
export default AnalyticsCardComponent;
