import FileSaver from "file-saver";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { Reference } from "../../../../types/Reference";
import { ContentContext } from "../../../Provider";
import { endpoints, parseEndpoint } from "../../../Services/endpoints";
import { REFERENCE_TYPE } from "../../../utils/enums";
import { getInitStorageValue } from "../../../utils/localStorage";

export type UpdateReferenceDTO = {
  Reference?: {
    description?: string;
    fileName?: string;
    fileUrl?: string;
    thumbnailUrl?: string;
  };
  CompanyReference?: {
    companyReferenceId?: string;
    isStar?: boolean;
    displayForEmployee?: boolean;
  };
};
export type ReferenceDTO = {
  id: string;
  fileName?: string;
  fileUrl?: string;
  description: string;
  thumbnailUrl?: string;
  uploadedAt: Date;
};
type GetReferenceQuery = {
  sort?: {
    field: string;
    order: string;
  };
  filter?: {
    isGlobal?: Array<boolean>;
    displayForEmployee?: Array<boolean>;
  };
  q?: string;
};

const PageSaveId = "GlobalReferenceListScreen";
const pageKey = `pagination_page_${PageSaveId}`;
const limitKey = `pagination_limit_${PageSaveId}`;

const mergeQueries = (
  query: GetReferenceQuery,
  referenceType: REFERENCE_TYPE,
): GetReferenceQuery => {
  let cloneQuery = query;
  if (referenceType === REFERENCE_TYPE.PRESET) {
    cloneQuery = {
      ...query,
      filter: {
        ...query.filter,
        isGlobal: [true],
      },
    };
  } else if (referenceType === REFERENCE_TYPE.ORIGINAL) {
    cloneQuery = {
      ...query,
      filter: {
        ...query.filter,
        isGlobal: [false],
      },
    };
  }
  return cloneQuery;
};

export function useReference(referenceType: REFERENCE_TYPE) {
  const [limit, setLimit] = useState<number>(() => {
    return getInitStorageValue(limitKey, process.env.REACT_APP_PAGE_SIZE);
  });

  const [page, setPage] = useState<number>(() => {
    return getInitStorageValue(pageKey, 1);
  });

  const auth = useSelector((state: any) => state.auth);

  const [references, setReferences] = useState<Array<any>>([]);

  const [searchQuery, setSearchQuery] = useState<GetReferenceQuery>({});

  const [selectedReference, setSelectedReference] = useState<ReferenceDTO>();

  const context = useContext(ContentContext);
  const { apiRequest } = context;

  const [isNewModalVisible, setIsNewModalVisible] = useState<boolean>(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState<boolean>(false);

  const refreshReference = useCallback(
    (searchQuery: GetReferenceQuery) => {
      const requestBuilder = {
        method: "postRequest",
        url: parseEndpoint(endpoints.filterAdvanceReference, {
          page: page - 1,
          limit: limit,
        }),
        data: mergeQueries(searchQuery, referenceType),
      };
      // @ts-expect-error TS2345
      apiRequest(requestBuilder, ({ data }) => {
        // @ts-expect-error TS2345
        setReferences(data);
      });
    },
    [referenceType, apiRequest, page, limit],
  );
  const onToggleNewReferenceModal = useCallback(
    (shouldRefresh) => {
      setIsNewModalVisible(!isNewModalVisible);
      if (shouldRefresh) {
        refreshReference(searchQuery);
      }
    },
    [isNewModalVisible, refreshReference, searchQuery],
  );

  const onToggleEditReferenceModal = useCallback(
    (selectedReference: ReferenceDTO | undefined) => {
      if (selectedReference) {
        setSelectedReference(selectedReference);
      }
      setIsEditModalVisible(!isEditModalVisible);
    },
    [isEditModalVisible],
  );

  useEffect(() => {
    refreshReference(searchQuery);
  }, [
    page,
    limit,
    searchQuery.filter,
    searchQuery.sort,
    refreshReference,
    searchQuery,
  ]);

  const updateReference = useCallback(
    (referenceId: string, newReferenceDetails: UpdateReferenceDTO) => {
      const requestBuilder = {
        method: "putRequest",
        url: parseEndpoint(endpoints.referenceById, {
          referenceId,
        }),
        data: newReferenceDetails,
      };
      // @ts-expect-error TS2345
      apiRequest(requestBuilder, ({ data }) => {
        refreshReference(searchQuery);
      });
    },
    [apiRequest, refreshReference, searchQuery],
  );

  const deleteReference = useCallback(
    (referenceId: string) => {
      const requestBuilder = {
        method: "deleteRequest",
        url: parseEndpoint(endpoints.referenceById, {
          referenceId,
        }),
        data: {},
      };
      // @ts-expect-error TS2345
      apiRequest(requestBuilder, ({ data }) => {
        refreshReference(searchQuery);
      });
    },
    [apiRequest, refreshReference, searchQuery],
  );

  const createReference = useCallback(
    (data: Reference, reset: () => void) => {
      const requestBuilder = {
        method: "postRequest",
        url: parseEndpoint(endpoints.createReference),
        data,
      };
      // @ts-expect-error TS2345
      apiRequest(requestBuilder, reset);
    },
    [apiRequest],
  );

  const onTableChanged = useCallback((pagination, filters, sorter) => {
    if (sorter) {
      const order = sorter.order && sorter.order === "ascend" ? "asc" : "desc";
      const field = sorter.field;

      setSearchQuery((prevState) => {
        return {
          ...prevState,
          sort: {
            field,
            order,
          },
        };
      });
    }
  }, []);

  const saveFile = useCallback((href, fileName, extension) => {
    const xhr = new XMLHttpRequest();
    xhr.withCredentials = true;
    xhr.open("GET", href, true);
    xhr.responseType = "arraybuffer";
    xhr.onload = (e) => {
      // @ts-expect-error TS18047
      const arrayBufferView = new Uint8Array(e.currentTarget.response);
      const blob = new Blob([arrayBufferView]);
      FileSaver.saveAs(blob, fileName);
    };
    xhr.send();
  }, []);

  return useMemo(() => {
    return {
      references,
      isNewModalVisible,
      onToggleNewReferenceModal,
      auth,
      saveFile,
      onToggleEditReferenceModal,
      isEditModalVisible,
      selectedReference,
      updateReference,
      searchQuery,
      setSearchQuery,
      refreshReference,
      createReference,
      onTableChanged,
      page,
      limit,
      setLimit,
      setPage,
      deleteReference,
    };
  }, [
    references,
    isNewModalVisible,
    isEditModalVisible,
    onToggleNewReferenceModal,
    auth,
    saveFile,
    onToggleEditReferenceModal,
    selectedReference,
    updateReference,
    searchQuery,
    setSearchQuery,
    refreshReference,
    createReference,
    onTableChanged,
    page,
    limit,
    deleteReference,
  ]);
}
