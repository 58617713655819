import { EyeOutlined } from "@ant-design/icons";
import { Popconfirm } from "antd";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";

import { getObjUUID, getTruncatedText } from "../../../utils/common";
import { ButtonUndo, IconDelete, IconEdit } from "../../atoms";
type Props = {
  isGlobal: boolean;
  user: Object;
  onGoto: (id: string) => void;
  onUpdate: (id: string) => void;
  onDelete: (id: string) => void;
};
const TblColumns = ({
  isGlobal = true,
  user = {},
  onGoto = () => {},
  onUpdate = () => {},
  onDelete = () => {},
}: Props) => {
  const intl = useIntl();
  if (isGlobal) {
    return [
      {
        title: intl.formatMessage({
          id: "screen.label.title",
        }),
        dataIndex: "title",
        key: "title",
        // @ts-expect-error TS7006
        render: function _TBLC(text, data, index) {
          return (
            <span
              style={{
                maxWidth: 250,
                wordWrap: "break-word",
                display: "inline-block",
              }}
            >
              {getTruncatedText(text, 60)}
            </span>
          );
        },
      },
      {
        title: intl.formatMessage({ id: "screen.label.theme" }),
        dataIndex: "theme",
        key: "theme",
        // @ts-expect-error TS7006
        render: function _fTheme(text, data, index) {
          return (
            <span
              style={{
                maxWidth: 250,
                wordWrap: "break-word",
                display: "inline-block",
              }}
            >
              {getTruncatedText(text, 60)}
            </span>
          );
        },
      },
      {
        title: intl.formatMessage({
          id: "screen.label.public_type",
        }),
        dataIndex: "isGlobalReport",
        key: "isGlobalReport",
        // @ts-expect-error TS7006
        render: function _fIsGlobalReport(text, data, index) {
          return (
            <span>
              {intl.formatMessage({
                id: data.isGlobalReport
                  ? "screen.label.global"
                  : "screen.label.limited_release",
              })}
            </span>
          );
        },
      },
      {
        title: intl.formatMessage({
          id: "screen.label.industry_medium_category",
        }),
        dataIndex: "IndustryReportMainCategory",
        key: "IndustryReportMainCategory",
        sorter: true,
        // @ts-expect-error TS7006
        render: function _fIndustryReportMainCategory(text, data, index) {
          return (
            <span
              style={{
                maxWidth: 250,
                wordWrap: "break-word",
                display: "inline-block",
              }}
            >
              {getTruncatedText(
                data.IndustryReportMainCategory?.name || "",
                60,
              )}
            </span>
          );
        },
      },
      {
        title: intl.formatMessage({
          id: "screen.label.industry_sub_category",
        }),
        dataIndex: "IndustryReportSubCategory",
        key: "IndustryReportSubCategory",
        sorter: true,
        // @ts-expect-error TS7006
        render: function _fIndustryReportSubCategory(text, data, index) {
          return (
            <span
              style={{
                maxWidth: 250,
                wordWrap: "break-word",
                display: "inline-block",
              }}
            >
              {getTruncatedText(data.IndustryReportSubCategory?.name || "", 60)}
            </span>
          );
        },
      },
      {
        // @ts-expect-error TS7006
        render: function _fIsGlobalUUID(text, row) {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <ButtonUndo
                icon={<EyeOutlined />}
                onClick={() => {
                  onGoto(getObjUUID(row));
                }}
                type={"ghost"}
                style={{
                  marginRight: 10,
                  marginTop: "1px",
                }}
              />
              <ButtonUndo
                icon={IconEdit}
                onClick={() => {
                  onUpdate(getObjUUID(row));
                }}
                type={"ghost"}
                style={{
                  marginRight: 10,
                  marginTop: "1px",
                }}
              />
              <Popconfirm
                title={intl.formatMessage({
                  id: "screen.label.delete_confirm_industory_report",
                })}
                onConfirm={() => {
                  onDelete(getObjUUID(row));
                }}
                okText={intl.formatMessage({
                  id: "screen.label.yes",
                })}
                cancelText={intl.formatMessage({
                  id: "screen.label.no",
                })}
              >
                <ButtonUndo
                  icon={IconDelete}
                  type={"ghost"}
                  style={{
                    marginTop: "1px",
                  }}
                />
              </Popconfirm>
            </div>
          );
        },
      },
    ];
  } else {
    return [
      {
        title: intl.formatMessage({
          id: "screen.label.title",
        }),
        dataIndex: "title",
        key: "title",
        // @ts-expect-error TS7006
        render: function _TBLC(text, data, index) {
          return (
            <span
              style={{
                maxWidth: 250,
                wordWrap: "break-word",
                display: "inline-block",
              }}
            >
              <Link
                to={`/dashboard/library/industry-report/${getObjUUID(data)}`}
              >
                {getTruncatedText(text, 60)}
              </Link>
            </span>
          );
        },
      },
      {
        title: intl.formatMessage({ id: "screen.label.theme" }),
        dataIndex: "theme",
        key: "theme",
        // @ts-expect-error TS7006
        render: function _fTheme(text, data, index) {
          return (
            <span
              style={{
                maxWidth: 250,
                wordWrap: "break-word",
                display: "inline-block",
              }}
            >
              {getTruncatedText(text, 60)}
            </span>
          );
        },
      },
      {
        title: intl.formatMessage({
          id: "screen.label.industry_medium_category",
        }),
        dataIndex: "IndustryReportMainCategory",
        key: "IndustryReportMainCategory",
        sorter: true,
        // @ts-expect-error TS7006
        render: function _fIndustryReportMainCategory(text, data, index) {
          return (
            <span
              style={{
                maxWidth: 250,
                wordWrap: "break-word",
                display: "inline-block",
              }}
            >
              {getTruncatedText(
                data.IndustryReportMainCategory?.name || "",
                60,
              )}
            </span>
          );
        },
      },
      {
        title: intl.formatMessage({
          id: "screen.label.industry_sub_category",
        }),
        dataIndex: "IndustryReportSubCategory",
        key: "IndustryReportSubCategory",
        sorter: true,
        // @ts-expect-error TS7006
        render: function _fIndustryReportSubCategory(text, data, index) {
          return (
            <span
              style={{
                maxWidth: 250,
                wordWrap: "break-word",
                display: "inline-block",
              }}
            >
              {getTruncatedText(data.IndustryReportSubCategory?.name || "", 60)}
            </span>
          );
        },
      },
    ];
  }
};
export default TblColumns;
