import { Action } from "@reduxjs/toolkit";
import { createActions, createReducer } from "reduxsauce";
import Immutable from "seamless-immutable";

import { ToAction, ToActionType } from "./types";

/* ------------- Types and Action Creators ------------- */
type ProgInfoActionCreators = {
  programInfoGetSuccess: () => Action<"PROGRAM_INFO_GET_SUCCESS">;
  programInfoQaGetSuccess: () => Action<"PROGRAM_INFO_QA_GET_SUCCESS">;
};
type ProgInfoAction = ToAction<ProgInfoActionCreators>;
type ProgInfoActionType = ToActionType<ProgInfoAction>;

const { Types, Creators } = createActions<
  ProgInfoActionType,
  ProgInfoActionCreators
>({
  programInfoGetSuccess: ["payload"],
  programInfoQaGetSuccess: ["programInfoQaPayload"],
});

export const ProgramInfoTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
type ProgInfoState = {
  payload: {
    content: string;
    status: string;
  };
  programInfoQaPayload: {
    content: string;
    status: string;
  };
};

export const INITIAL_STATE = Immutable<ProgInfoState>({
  payload: {
    content: "",
    status: "private",
  },
  programInfoQaPayload: {
    content: "<p></p>",
    status: "private",
  },
});
type ImmProgInfoState = typeof INITIAL_STATE;

/* ------------- Reducers ------------- */

const programInfoGetSuccess = (
  state: ImmProgInfoState,
  { payload }: { payload: ProgInfoState["payload"] },
) => state.merge({ payload });

const programInfoQaGetSuccess = (
  state: ImmProgInfoState,
  {
    programInfoQaPayload,
  }: { programInfoQaPayload: ProgInfoState["programInfoQaPayload"] },
) => state.merge({ programInfoQaPayload });

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer<ImmProgInfoState, ProgInfoAction>(
  INITIAL_STATE,
  {
    [Types.PROGRAM_INFO_GET_SUCCESS]: programInfoGetSuccess,
    [Types.PROGRAM_INFO_QA_GET_SUCCESS]: programInfoQaGetSuccess,
  },
);
