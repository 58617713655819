import { useCallback } from "react";
import useSWR from "swr";

import { EventData } from "@/../types/Event";
import { api } from "@/Saga";
import { BaseErrorResponse } from "@/Services/commonResponsTypes";
import { endpoints, parseEndpoint } from "@/Services/endpoints";

import { GetEvent } from "../types/response";

export const useEvent = (eventId: string) => {
  const url = parseEndpoint(endpoints.singleEvent, {
    id: eventId,
  });

  const fetcher = useCallback(async () => {
    const response = await api.getRequest<GetEvent, BaseErrorResponse>(url);

    if (!response.ok) throw new Error(response.originalError.message);
    if (response.data?.status !== "success")
      throw new Error(response.data?.message);

    return response.data.data;
  }, [url]);

  const { data, error, isValidating } = useSWR<EventData, Error>(url, fetcher, {
    revalidateIfStale: true,
  });

  return {
    event: data,
    error,
    isValidating,
  };
};
