import { ComponentProps, FC } from "react";
import { useIntl } from "react-intl";

import styles from "./styles.module.scss";

type OptionalLabelProps = ComponentProps<"span">;

export const OptionalLabel: FC<OptionalLabelProps> = ({
  className,
  ...props
}) => {
  const intl = useIntl();
  const label = intl.formatMessage({ id: "screen.label.optional" });

  return (
    <span {...props} className={`${styles.label} ${className}`}>
      {label}
    </span>
  );
};
