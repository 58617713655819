import { useIntl } from "react-intl";

import { getResultLabelId } from "./helper";
import styles from "./styles.module.scss";
import { ReviewResultLabelProps } from "./type";

export const ReviewResultLabel = ({
  reviewResult,
  className,
  ...rest
}: ReviewResultLabelProps) => {
  const isBlank = reviewResult === "BLANK";
  const intl = useIntl();
  const reviewResultLabel = intl.formatMessage({
    id: getResultLabelId(reviewResult),
  });
  return (
    <span
      className={
        isBlank
          ? `${styles.blank_result_label} ${className}`
          : `${styles.review_result_label} ${className}`
      }
      {...rest}
    >{`${reviewResultLabel}`}</span>
  );
};
