import Bugsnag from "@bugsnag/js";
import { Component, ErrorInfo, ReactNode } from "react";

import { ErrorPage } from "../ErrorPage";
import { ErrorBoundaryState } from "./type";

export class ErrorBoundary extends Component<
  { children?: ReactNode },
  ErrorBoundaryState
> {
  constructor(
    props: { children?: ReactNode } | Readonly<{ children?: ReactNode }>,
  ) {
    super(props);
    this.state = { hasError: false };
  }
  componentDidCatch(error: Error, info: ErrorInfo): void {
    Bugsnag.notify(error, (event) => {
      event.addMetadata("react", info);
      event.severity = "error";
    });
  }
  static getDerivedStateFromError() {
    return {
      hasError: true,
    };
  }
  render() {
    if (this.state.hasError) {
      return <ErrorPage />;
    }
    return this.props.children;
  }
}
