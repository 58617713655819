import { EditFilled } from "@ant-design/icons";
import { Col, List, Modal, Row } from "antd";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { getDisplayName, getUserAvatar } from "@/utils/common";

import { TeamMember } from "../../../../../types/TeamMember";

type ProjectMembersModalProps = {
  visible: boolean;
  onCancel: () => void;
  teamMembers: TeamMember[];
};
export const ProjectMembersModal: FC<ProjectMembersModalProps> = ({
  visible,
  onCancel,
  teamMembers,
}) => {
  // @ts-expect-error TS2339
  const project = useSelector((state) => state.projects.projectPayload);
  return (
    <Modal
      width="auto"
      centered
      title={
        <Row align="middle" style={{ marginRight: 54 }}>
          <Col style={{ maxWidth: "30vw" }}>
            <FormattedMessage
              id="screen.label.project_member_name"
              values={{
                name: project.name,
              }}
            />
          </Col>
          <Col style={{ marginLeft: 16 }}>
            <EditFilled />{" "}
            <Link to={"/dashboard/project.team/" + project.uuid}>
              <FormattedMessage id="screen.label.edit_members" />
            </Link>
          </Col>
        </Row>
      }
      visible={visible}
      onCancel={() => onCancel()}
      footer={null}
      destroyOnClose
    >
      <div style={{ maxHeight: "60vh", overflow: "auto" }}>
        <List
          itemLayout="horizontal"
          dataSource={teamMembers}
          renderItem={(member) => (
            <List.Item>
              <Link to={"/dashboard/member/" + member.uuid}>
                <Row align="middle">
                  <Col>{getUserAvatar(member)}</Col>
                  <Col style={{ maxWidth: 300 }}>{getDisplayName(member)}</Col>
                  {member.isProjectOwner && (
                    <Col style={{ marginLeft: 16 }}>
                      <div className="meta-title">Owner</div>
                    </Col>
                  )}
                </Row>
              </Link>
            </List.Item>
          )}
        />
      </div>
    </Modal>
  );
};
