import {
  Card,
  Col,
  Form,
  message,
  Modal,
  notification,
  Row,
  Upload,
} from "antd";
import { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";

import { ApiRequestData } from "@/Redux/ApiRedux";

import { ButtonCV, ButtonOption } from "../../Components/atoms";
import { InsuRejectButton } from "../../Components/atoms/buttons/shared";
import { Input, Option, Select, TextArea } from "../../Components/molecules";
import { ManageCategoriesComponent } from "../../Components/organisms";
import VideoActions from "../../Redux/VideoRedux";
import { endpoints, parseEndpoint } from "../../Services/endpoints";
import createFormData from "../../utils/createFormData";
import FileUploadComponent from "../project/FileUploadComponent";
import {
  IPostNewVideoScreenProps,
  PostNewVideoScreenState,
} from "./types/IPostNewVideoScreenProps";

class PostNewVideoScreen extends Component<
  IPostNewVideoScreenProps,
  PostNewVideoScreenState
> {
  // @ts-expect-error TS7006
  constructor(props) {
    super(props);
    this.state = {
      isModalManageCategoriesVisible: false,
      smallCategories: {},
      videoLargeCategoryId: 0,
      attachingVideo: {
        loading: false,
        message: "",
      },
      newVideo: {
        videoLargeCategoryId: 0,
        videoSmallCategoryId: 0,
        name: "",
        description: "",
        videoUrl: null,
      },
      companies: [],
    };
  }
  componentDidMount() {
    this.props.context.updatePageTitle("screen.label.video");
    this.getVideoLargeCategories();
  }
  getUploadProps = () => {
    return {
      // @ts-expect-error TS7006
      beforeUpload: (file) => {
        return new Promise((resolve, reject) => {
          this.setState({
            attachingVideo: {
              loading: true,
              // @ts-expect-error TS2722
              message: this.props.intl.formatMessage({
                id: "screen.label.please_wait",
              }),
            },
          });
          const requestBuilder: ApiRequestData = {
            method: "postRequest",
            url: parseEndpoint(endpoints.generateVimeoForm),
            data: {
              size: file.size,
              name:
                // @ts-expect-error TS2532
                this.state.newVideo.name.length > 0
                  ? // @ts-expect-error TS2532
                    this.state.newVideo.name
                  : file.name,
              description:
                // @ts-expect-error TS2532
                this.state.newVideo.description.length > 0
                  ? // @ts-expect-error TS2532
                    this.state.newVideo.description
                  : "",
            },
          };
          this.props.context.apiRequest(requestBuilder, ({ data }) => {
            const { newVideo } = this.state;
            // @ts-expect-error TS18048
            newVideo.videoUrl = data.link;
            this.setState(
              {
                // @ts-expect-error TS2345
                formActionUrl: data.action,
                newVideo,
              },
              () => {
                // @ts-expect-error TS2794
                return resolve();
              },
            );
          });
        });
      },
      accept: "video/*",
      name: "file_data",
      // @ts-expect-error TS2339
      action: this.state.formActionUrl,
      // @ts-expect-error TS7006
      onChange: (info) => {
        if (info.file.percent >= 100) {
          message.success(
            // @ts-expect-error TS2722
            `${info.file.name} ${this.props.intl.formatMessage({
              id: "screen.label.upload_successfully",
            })}`,
          );
          this.setState({
            attachingVideo: {
              loading: false,
              message: "",
            },
          });
        }
      },
      showUploadList: {
        removeIcon: true,
      },
    };
  };
  onCreateNewVideo = () => {
    let { newVideo } = this.state;
    // @ts-expect-error TS2532
    const selectedLargeCategory = this.props.largeCategories.filter(
      // @ts-expect-error TS7006
      (l) => l.id === this.state.videoLargeCategoryId,
    );
    const vimeoUrlMessage = this.props.context.user.isSuper
      ? // @ts-expect-error TS2722
        this.props.intl.formatMessage({
          id: "screen.label.vimeo_file_attachment_required",
        })
      : // @ts-expect-error TS2722
        this.props.intl.formatMessage({
          id: "screen.label.video_file_attachment_required",
        });
    if (selectedLargeCategory.length <= 0)
      return notification.error({
        // @ts-expect-error TS2722
        message: this.props.intl.formatMessage({
          id: "screen.label.large_category_required_field",
        }),
      });
    // @ts-expect-error TS18048
    if (newVideo.videoSmallCategoryId <= 0)
      return notification.error({
        // @ts-expect-error TS2722
        message: this.props.intl.formatMessage({
          id: "screen.label.small_category_required_field",
        }),
      });
    // @ts-expect-error TS18048
    if (newVideo.name.length <= 0)
      return notification.error({
        // @ts-expect-error TS2722
        message: this.props.intl.formatMessage({
          id: "screen.label.title_required_field",
        }),
      });
    // @ts-expect-error TS18048
    if (newVideo.description.length <= 0)
      return notification.error({
        // @ts-expect-error TS2722
        message: this.props.intl.formatMessage({
          id: "screen.label.details_are_required",
        }),
      });
    // @ts-expect-error TS18048
    if (!newVideo.videoUrl)
      return notification.error({ message: vimeoUrlMessage });
    newVideo = selectedLargeCategory[0].companyId
      ? {
          ...newVideo,
          // @ts-expect-error TS2322
          companyId: selectedLargeCategory[0].companyId,
        }
      : newVideo;
    const baseUrl = selectedLargeCategory[0].companyId
      ? ""
      : `${process.env.REACT_APP_BASEHOST}companies/`;
    const requestBuilder: ApiRequestData = {
      method: "postRequest",
      url: parseEndpoint(endpoints.createGlobalVideo, {
        baseUrl,
        // @ts-expect-error TS18048
        videoLargeCategoryId: newVideo.videoLargeCategoryId,
        // @ts-expect-error TS18048
        videoSmallCategoryId: newVideo.videoSmallCategoryId,
      }),
      data: createFormData(newVideo),
      // @ts-expect-error TS2722
      successMessage: this.props.intl.formatMessage({
        id: "screen.label.video_posted_wait_des",
      }),
      successReRoute: "/dashboard/videos-mgt",
    };
    this.props.context.apiRequest(requestBuilder, ({ data }) => {});
  };
  // @ts-expect-error TS7006
  getLargeCategoryIdForSelect = (largeCategory) => {
    return largeCategory.companyId === null;
  };
  // @ts-expect-error TS7006
  onDeleteSmallCategory = (largeCategoryId, smallCategoryId) => {
    const requestBuilder: ApiRequestData = {
      method: "deleteRequest",
      url: parseEndpoint(endpoints.videoSmallCategory, {
        smallCategoryId,
      }),
      data: {},
    };
    this.props.context.apiRequest(requestBuilder, ({}) => {
      this.getSmallCategoryForEachLargeCategory(largeCategoryId);
    });
  };
  // @ts-expect-error TS7006
  onSubmitNewCategory = (newSmallCategory) => {
    if (newSmallCategory.name.length > 0) {
      const data = {
        ...newSmallCategory,
        companyId: this.props.context.company.id,
      };
      const requestBuilder: ApiRequestData = {
        method: "postRequest",
        url: parseEndpoint(endpoints.smallCategoriesForSingleLargeCategory, {
          largeCategoryId: newSmallCategory.videoLargeCategoryId,
        }),
        data,
      };
      this.props.context.apiRequest(requestBuilder, ({ data }) => {
        this.getSmallCategoryForEachLargeCategory(
          newSmallCategory.videoLargeCategoryId,
        );
      });
    }
  };
  // @ts-expect-error TS7006
  onChangeNewVideoData = (key, value) => {
    const { newVideo } = this.state;
    // @ts-expect-error TS18048
    newVideo[key] = value;
    this.setState({
      newVideo,
    });
  };
  getVideoLargeCategories = () => {
    if (this.props.largeCategories.length === 0) {
      const requestBuilder: ApiRequestData = {
        method: "getRequest",
        url: parseEndpoint(endpoints.videoLargeCategory),
        data: {},
      };
      this.props.context.apiRequest(requestBuilder, ({ data }) => {
        this.props.dispatchSetVideoCategories(data);
        this.actionAfterFetchingLargeCategories(data);
      });
    } else {
      this.actionAfterFetchingLargeCategories(this.props.largeCategories);
    }
  };
  // @ts-expect-error TS7006
  actionAfterFetchingLargeCategories = (data) => {
    if (Array.isArray(data)) {
      data.forEach((singleLargeCategory) => {
        if (singleLargeCategory.companyId != null) {
          this.setState({
            companyLargeCatId: singleLargeCategory.id,
          });
        }
        this.getSmallCategoryForEachLargeCategory(singleLargeCategory.id);
      });
    }
  };
  // @ts-expect-error TS7006
  getSmallCategoryForEachLargeCategory = (largeCategoryId) => {
    const requestBuilder: ApiRequestData = {
      method: "getRequest",
      url: parseEndpoint(endpoints.smallCategoriesForSingleLargeCategory, {
        largeCategoryId,
      }),
      data: {},
    };
    this.props.context.apiRequest(requestBuilder, ({ data }) => {
      const { smallCategories } = this.state;
      // @ts-expect-error TS18048
      smallCategories[largeCategoryId] = data;
      this.setState({ smallCategories });
    });
  };
  // @ts-expect-error TS7006
  _renderSmallCategories = (largeCategoryId) => {
    // @ts-expect-error TS2532
    const data = this.state.smallCategories[largeCategoryId];
    if (data && Array.isArray(data)) {
      return data.map((sm) => {
        return (
          <Option key={sm.id} value={sm.id}>
            {sm.name}
          </Option>
        );
      });
    }
  };
  render() {
    return (
      <>
        <Modal
          // @ts-expect-error TS2722
          title={this.props.intl.formatMessage({
            id: "screen.label.category_management",
          })}
          visible={this.state.isModalManageCategoriesVisible}
          footer={null}
          onCancel={() => {
            this.setState({
              isModalManageCategoriesVisible: false,
            });
          }}
        >
          <ManageCategoriesComponent
            onDeleteSmallCategory={this.onDeleteSmallCategory}
            onSubmitNewCategory={this.onSubmitNewCategory}
            // @ts-expect-error TS2740
            largeCategories={this.props.largeCategories}
            smallCategories={this.state.smallCategories}
            user={this.props.context.user}
          />
        </Modal>
        <Card
          bodyStyle={{
            paddingLeft: "7vw",
            paddingRight: "7vw",
          }}
        >
          <Form layout={"vertical"}>
            <Row gutter={[32, 0]}>
              <Col xs={24} sm={12} md={8} lg={8}>
                <Form.Item
                  // @ts-expect-error TS2722
                  label={`${this.props.intl.formatMessage({
                    id: "screen.label.large_category",
                  })} *`}
                >
                  <Select
                    onChange={(videoLargeCategoryId) => {
                      this.onChangeNewVideoData(
                        "videoLargeCategoryId",
                        videoLargeCategoryId,
                      );
                      this.setState({
                        videoLargeCategoryId,
                      });
                    }}
                  >
                    {/*
                     // @ts-expect-error TS2722 */}
                    {this.props.largeCategories
                      // @ts-expect-error TS7006
                      .filter((l) => l.companyId !== null)
                      // @ts-expect-error TS7006
                      .map((l) => {
                        return (
                          <Option
                            disabled={this.getLargeCategoryIdForSelect(l)}
                            key={l.id}
                            value={l.id}
                          >
                            {l.name}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={8} lg={8}>
                <Form.Item
                  // @ts-expect-error TS2722
                  label={`${this.props.intl.formatMessage({
                    id: "screen.label.small_category",
                  })} *`}
                >
                  <Select
                    style={{ width: "100%" }}
                    onChange={(videoSmallCategoryId) => {
                      this.onChangeNewVideoData(
                        "videoSmallCategoryId",
                        videoSmallCategoryId,
                      );
                    }}
                  >
                    {this._renderSmallCategories(
                      this.state.videoLargeCategoryId,
                    )}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24} lg={24}>
                <ButtonOption
                  onClick={() => {
                    this.setState({
                      isModalManageCategoriesVisible: true,
                    });
                  }}
                >
                  <FormattedMessage id="screen.label.to_add_delete_category" />
                </ButtonOption>
              </Col>

              <Col xs={24} sm={12} md={13} lg={14}>
                <br />
                <Form.Item
                  // @ts-expect-error TS2722
                  label={`${this.props.intl.formatMessage({
                    id: "screen.label.title",
                  })} *`}
                >
                  <Input
                    onChange={(e) => {
                      this.onChangeNewVideoData("name", e.target.value);
                    }}
                    // @ts-expect-error TS2722
                    placeholder={this.props.intl.formatMessage({
                      id: "screen.label.please_enter",
                    })}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={12} md={13} lg={14}>
                <Form.Item
                  // @ts-expect-error TS2722
                  label={`${this.props.intl.formatMessage({
                    id: "screen.label.more_detail",
                  })} *`}
                >
                  <TextArea
                    onChange={(e) => {
                      this.onChangeNewVideoData("description", e.target.value);
                    }}
                    rows={3}
                    // @ts-expect-error TS2722
                    placeholder={this.props.intl.formatMessage({
                      id: "screen.label.please_enter",
                    })}
                  />
                </Form.Item>
              </Col>

              {this.props.context.user.isSuper ? (
                <>
                  <Col xs={24} sm={12} md={13} lg={14}>
                    <Form.Item label={"Vimeo URL *"}>
                      <Input
                        onChange={(e) => {
                          this.onChangeNewVideoData("videoUrl", e.target.value);
                        }}
                        placeholder="Vimeo URL"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={11} lg={10}></Col>
                </>
              ) : null}
              <Col xs={24} />
              <Col xs={24} sm={12} md={8} lg={8}>
                <Form.Item
                  // @ts-expect-error TS2722
                  label={`${this.props.intl.formatMessage({
                    id: "screen.label.thumbnail_attached",
                  })} *`}
                >
                  <FileUploadComponent
                    // @ts-expect-error TS2722
                    message={`${this.props.intl.formatMessage({
                      id: "screen.label.upload_image_click",
                    })}`}
                    onFileChanged={(imageThumbnail) => {
                      this.onChangeNewVideoData(
                        "imageThumbnail",
                        imageThumbnail,
                      );
                    }}
                  />
                </Form.Item>
                {this.props.context.user.type === "admin" ? (
                  <>
                    <Form.Item
                      // @ts-expect-error TS2722
                      label={`${this.props.intl.formatMessage({
                        id: "screen.label.video_file_attachment",
                      })} *`}
                    >
                      {/*
                       // @ts-expect-error TS2769 */}
                      <Upload {...this.getUploadProps()}>
                        <ButtonOption>
                          {/*
                           // @ts-expect-error TS2722 */}
                          {` ${this.props.intl.formatMessage({
                            id: "screen.label.video.upload_video_file",
                          })}`}
                        </ButtonOption>
                      </Upload>
                    </Form.Item>
                    {/*
                     // @ts-expect-error TS2532 */}
                    {this.state.attachingVideo.message}
                  </>
                ) : null}
              </Col>
              <Col span={24}>
                {/*
                 // @ts-expect-error TS2532 */}
                {this.state.attachingVideo.message.length > 0 ? null : (
                  <>
                    <ButtonCV
                      onClick={this.onCreateNewVideo}
                      style={{
                        marginTop: 20,
                        marginRight: "0.3vw",
                      }}
                    >
                      {/*
                       // @ts-expect-error TS2722 */}
                      {this.props.intl.formatMessage({
                        id: "screen.label.storage",
                      })}
                    </ButtonCV>
                    {/*
                     // @ts-expect-error TS2339 */}
                    <InsuRejectButton onClick={this.props.history.goBack}>
                      <FormattedMessage id={"screen.label.cancel"} />
                    </InsuRejectButton>
                  </>
                )}
              </Col>
            </Row>
          </Form>
        </Card>
      </>
    );
  }
}
// @ts-expect-error TS7006
const mapStateToProps = (state) => ({
  largeCategories: state.videos.largeCategoriesPayload,
  companies: state.company.companies.rows,
});
// @ts-expect-error TS7006
const mapDispatchToProps = (dispatch) => ({
  // @ts-expect-error TS7006
  dispatchSetVideoCategories: (largeCategories) =>
    // @ts-expect-error TS2554
    dispatch(VideoActions.videoLargeCategoriesGetSuccess(largeCategories)),
});
export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(PostNewVideoScreen),
);
