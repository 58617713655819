import useSWR from "swr";

import { JsendError } from "../../../types/JSendObject";
import { Reference } from "../../../types/Reference";
import { api } from "../../Saga";
import { endpoints, parseEndpoint } from "../endpoints";
import { GetReferenceResponse } from "./type";

const useReferenceRequest = (referenceId: string) => {
  const url = parseEndpoint(endpoints.referenceById, { referenceId });

  const fetcher = async () => {
    const response = await api.getRequest<GetReferenceResponse, JsendError>(
      url,
    );
    if (!response.ok) throw new Error(response.originalError.message);
    if (response.data?.status !== "success")
      throw new Error(response.data?.message);
    return response.data.data;
  };
  const {
    data: reference,
    error,
    isValidating,
    mutate: referenceMutate,
  } = useSWR<Reference, Error>(url, fetcher, {
    revalidateOnMount: true,
  });

  return { reference, error, isValidating, referenceMutate };
};

export default useReferenceRequest;
