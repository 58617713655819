import { Col, Row, Tag } from "antd";
import { useIntl } from "react-intl";

import {
  adminUser,
  generalUser,
  mentorUser,
  programManager,
} from "../../../utils/constants";
import {
  MemberSearchCloseSpan,
  MemberSearchDesSpan,
  MemberSearchTitleSpan,
} from "./Item";
const ARRAY_TYPE = 1;
const NON_ARRAY_TYPE = 0;
const REPLCE_TYPE = 2;

type searchTermsType = {
  [key: string]: any;
};
const searchTerms: searchTermsType = {
  subsidiary: {
    title: "screen.label.affiliation_companies",
    valueType: ARRAY_TYPE,
  },
  userTag: {
    title: "screen.label.user_label",
    valueType: ARRAY_TYPE,
  },
  type: {
    title: "screen.label.user_type",
    valueType: ARRAY_TYPE,
    replace_titles: {
      mentor: mentorUser,
      employee: generalUser,
      isSuper: adminUser,
      admin: programManager,
    },
  },
  department: {
    title: "screen.label.affiliation_department",
    valueType: ARRAY_TYPE,
  },
  firstLogin: {
    title: "screen.users.first_login",
    replace_titles: {
      completed_first_login: "screen.users.completed_first_login",
      not_completed_first_login: "screen.users.not_completed_first_login",
    },
    valueType: REPLCE_TYPE,
  },
  projects: {
    title: "screen.label.affiliation_project",
    valueType: NON_ARRAY_TYPE,
  },
  interst: {
    title: "screen.label.interest",
    valueType: NON_ARRAY_TYPE,
  },
};

type Props = {
  onPerformSerach: () => void;
  onChangeInterest: (interest: any) => void;
  onChangeSearchTerm: (key: string, parent: string, value: any) => void;
  allProjects?: Array<any>;
  users: any;
  projects: any;
  allInterests: Array<any>;
  interst?: Array<any>;
};
function MemberSearchTerm(props: Props) {
  const intl = useIntl();
  const {
    onPerformSerach,
    onChangeInterest,
    onChangeSearchTerm,
    users,
    projects,
    interst,
    allProjects,
    allInterests,
  } = props;

  const onClickRemove = (key: string, value: string) => {
    if (key === "projects") {
      onChangeSearchTerm("id", "Project", "");
    } else if (key === "type") {
      onChangeSearchTerm("type", "User", "");
      onChangeSearchTerm("isSuper", "User", false);
    } else {
      onChangeSearchTerm(key, "User", "");
    }
    onPerformSerach();
  };
  const renderSearchTerm = (key: string, value: string) => {
    const searchTerm = searchTerms[key];
    let values: any = value;
    if (!searchTerm) {
      return null;
    }
    if (searchTerm.valueType === ARRAY_TYPE) {
      values = value.split(",");
    }

    if (key === "firstLogin") {
      values = intl.formatMessage({
        id: searchTerm.replace_titles[values],
      });
    }

    if (key === "projects" && allProjects && allProjects.length > 0) {
      const findProjectName = allProjects.find((p) => p.id === values)?.name;
      values = findProjectName;
    }
    return (
      <Col className="offset-right-16">
        <Row>
          <MemberSearchTitleSpan>
            {intl.formatMessage({ id: searchTerm.title })}
          </MemberSearchTitleSpan>
          {"/"}
          {searchTerm.valueType === ARRAY_TYPE ? (
            values.map((val: string) => (
              <MemberSearchDesSpan key={val}>
                {key === "type" ? searchTerm.replace_titles[val] : val}
              </MemberSearchDesSpan>
            ))
          ) : (
            <MemberSearchDesSpan>{values}</MemberSearchDesSpan>
          )}
          <MemberSearchCloseSpan
            onClick={() => {
              onClickRemove(key, value);
            }}
          >
            [x]
          </MemberSearchCloseSpan>
        </Row>
      </Col>
    );
  };
  const renderUsers = () => {
    return Object.keys(users).map((key: string) => {
      if (users[key] && users[key] !== "") {
        return renderSearchTerm(key, users[key]);
      }
      return null;
    });
  };

  const renderProjects = () => {
    if (projects && projects.id !== "") {
      return renderSearchTerm("projects", projects.id);
    }
    return null;
  };

  const renderInterest = () => {
    if (interst && interst.length > 0) {
      return (
        <Row align="middle">
          <MemberSearchTitleSpan>
            {intl.formatMessage({
              id: searchTerms["interst"].title,
            })}
          </MemberSearchTitleSpan>
          {"/"}
          <MemberSearchDesSpan />
          {interst.map((id) => {
            const item = allInterests.find((item) => item.id === id);
            return (
              <Tag
                key={item.id}
                className="wrap-tag-text"
                closable
                onClose={() => {
                  onChangeInterest(item);
                  onPerformSerach();
                }}
              >
                {item.name}
              </Tag>
            );
          })}
        </Row>
      );
    }
    return null;
  };
  return (
    <>
      <Row style={{ marginTop: 16 }}>
        {renderUsers()}
        {renderProjects()}
      </Row>
      {renderInterest()}
    </>
  );
}

export default MemberSearchTerm;
