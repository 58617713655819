import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";

import ProjectActions from "../../../Redux/ProjectRedux";
import { endpoints, parseEndpoint } from "../../../Services/endpoints";
import { useStateWithRef } from "../../../utils/hooks";
import { getInitStorageValue } from "../../../utils/localStorage";
import { transformAllProjectTasksList } from "../TransformProjectsAPIData";
import {
  IProjectTasksScreenProps,
  ProjectTasksScreenState,
} from "../types/IProjectTasksScreenProps";
const R = require("ramda");
const PageSaveId = "ProjectTasksScreen";
const pageKey = `pagination_page_${PageSaveId}`;
const limitKey = `pagination_limit_${PageSaveId}`;

const useProjectTasksScreen = (props: IProjectTasksScreenProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const { user, updatePageTitle, apiRequest } = props.context;
  const [queryProjectTask, setQueryProjectTask] = useState(() => {
    return {
      Task: {
        title: "",
        description: "",
        status: ["DONE", "PROGRESS", "PENDING"],
        projectId: "",
      },
      User: {
        name: "",
      },
      sortField: "createdAt",
      sortOrder: "DESC",
    };
  });
  const [state, customSetState, stateRef] = useStateWithRef(() => {
    return {
      visible: false,
      visibleCreateModal: false,
      activeRow: {},
      activeState: null,
      searchText: "",
      searchPriority: null,
      searchedColumn: null,
      planEnabled: false,
      acceptedTeamPage: 1,
      isTeamMember: false,
      enableView: true,
      title: "",
      description: "",
      icon: "",
      incharge: [],
      status: null,
      priority: 0,
      timelimit: moment(new Date()).local(),
      page: getInitStorageValue(pageKey, 1),
      limit: getInitStorageValue(limitKey, process.env.REACT_APP_PAGE_SIZE),
      sort: "id",
      completedCheckBoxState: true,
      inProgressCheckBoxState: true,
      pendingCheckBoxState: true,
      currentPrioritySort: false,
      currentTimelimitSort: false,
    };
  });
  const setStateCallbackRef = useRef(() => {});
  useEffect(() => {
    const callBack = setStateCallbackRef.current;
    callBack();
    setStateCallbackRef.current = () => {};
  }, [state]);
  const setState = (data: ProjectTasksScreenState, callback = () => {}) => {
    setStateCallbackRef.current = callback;
    customSetState((previousState: ProjectTasksScreenState) => {
      return {
        ...previousState,
        ...data,
      };
    });
  };
  useEffect(() => {
    performAdvanceTaskSearch();
  }, [queryProjectTask]);

  useEffect(() => {
    updatePageTitle("screen.label.task");
    setState({ enableView: true });
    fetchTask();
    if (props.projects.projectPayload.isTeamMember) {
      setState({
        isTeamMember: true,
      });
    }
    fetchAcceptedTeam(state.acceptedTeamPage);
  }, []);

  // @ts-expect-error TS7006
  const handleOk = (e) => {
    setState({
      visible: false,
      title: "",
      description: "",
      icon: "",
      incharge: [],
      status: null,
      priority: 0,
      timelimit: moment(new Date()).local(),
    });
    props.dispatchUpdateTask(getProjectId(), state.activeRow.key, {
      priority: state.priority,
      title: state.title,
      description: state.description,
      status: state.status,
      incharge: state.incharge,
      timelimit: state.timelimit,
    });
    setTimeout(() => {
      props.dispatchFetchTasks(getProjectId());
    }, 1000);
  };
  // @ts-expect-error TS7006
  const handleCancel = (e) => {
    setState({
      visible: false,
    });
  };

  const handleCreateModalOk = () => {
    props.dispatchFetchTasks(getProjectId());
    setState({
      visibleCreateModal: false,
    });
  };
  const handleCreateModalClose = () => {
    setState({
      visibleCreateModal: false,
    });
  };
  // @ts-expect-error TS7006
  const handleMenuClick = (e) => {
    const status =
      Array.from(e.domEvent.target.classList).indexOf("DONE") > -1
        ? "DONE"
        : Array.from(e.domEvent.target.classList).indexOf("PENDING") > -1
        ? "PENDING"
        : "PROGRESS";
    props.dispatchUpdateTask(
      getProjectId(),
      props.projects.tasksPayload.rows[parseInt(e.key)].key,
      { status: status },
    );
    setTimeout(() => {
      props.dispatchFetchTasks(getProjectId());
    }, 1000);
  };
  const fetchTask = () => {
    const { page, limit } = stateRef.current;
    props.dispatchFetchTasks(getProjectId(), `page=${page - 1}&limit=${limit}`);
    setTimeout(() => {
      // @ts-expect-error TS18048
      const hash = props.location.hash.replace("#", "");
      let elementObj = undefined;
      let indexObj = undefined;
      setState({
        planEnabled: user.company.currentPlan === "PRO",
      });
      // @ts-expect-error TS7006
      props.projects.tasksPayload.rows.forEach((element, index) => {
        if (element.key === parseInt(hash)) {
          elementObj = element;
          indexObj = index;
        }
      });
      if (indexObj !== undefined) {
        // @ts-expect-error TS7034
        const users = [];
        // @ts-expect-error TS18048
        elementObj.incharge.forEach((inchargeUserID) =>
          users.push(parseInt(inchargeUserID)),
        );
        setState({
          visible: true,
          activeState: indexObj,
          activeRow: elementObj,
          // @ts-expect-error TS18048
          title: elementObj.name,
          // @ts-expect-error TS18048
          description: elementObj.description,
          // @ts-expect-error TS7005
          incharge: users,
          // @ts-expect-error TS18048
          status: elementObj.status,
          // @ts-expect-error TS18048
          priority: elementObj.priority,
          // @ts-expect-error TS18048
          timelimit: moment(elementObj.timelimit).local(),
        });
        // @ts-expect-error TS18048
        props.history.push(props.location.pathname);
      }
    }, 1200);
  };
  // @ts-expect-error TS7006
  const fetchAcceptedTeam = (page) => {
    setState({
      acceptedTeamPage: page,
    });
    props.dispatchFetchUsers({
      page: page - 1,
      id: getProjectId(),
      shouldPaginate: false,
    });
  };
  const getProjectId = () => {
    const {
      match: { params },
    } = props;
    return params.id ? params.id : -1;
  };
  // @ts-expect-error TS7006
  const handleReset = (clearFilters, elem) => {
    clearFilters();
    const obj = {};
    // @ts-expect-error TS7053
    obj[elem] = "";
    setState(obj, () => {
      performAdvanceTaskSearch();
    });
  };
  // @ts-expect-error TS7006
  const handleChange = (key, value) => {
    setState({ [key]: value });
  };

  // @ts-expect-error TS7006
  const handleChangeStars = (value, index) => {
    if (value !== 0) {
      props.dispatchUpdateTask(
        getProjectId(),
        props.projects.tasksPayload.rows[index].key,
        { priority: value },
      );
      setTimeout(() => {
        performAdvanceTaskSearch();
      }, 1000);
    }
  };
  // @ts-expect-error TS7006
  const onTableChange = (pagination, filters, sorter, extra) => {
    if (sorter) {
      const newQueryProjectTask = R.clone(queryProjectTask);
      const field = sorter.field ? sorter.field : "id";
      const order = sorter.order && sorter.order === "descend" ? "asc" : "desc";
      newQueryProjectTask.sortOrder = order.toUpperCase();
      newQueryProjectTask.sortField = field;
      setQueryProjectTask(newQueryProjectTask);
      setState({
        currentPrioritySort: sorter.field === "priority" ? sorter.order : false,
        currentTimelimitSort:
          sorter.field === "timelimit" ? sorter.order : false,
        sort: sorter.field,
      });
    }
  };
  const onSortParameter = (val: string) => {
    const newQueryProjectTask = R.clone(queryProjectTask);
    newQueryProjectTask.sortField = val;
    setQueryProjectTask(newQueryProjectTask);
    if (val === "id") {
      setState({
        completedCheckBoxState: false,
        pendingCheckBoxState: false,
        inProgressCheckBoxState: false,
        searchText: "",
        sort: "id",
        currentPrioritySort: false,
        currentTimelimitSort: false,
      });
      return props.dispatchFetchTasks(getProjectId());
    }
    const targetColumns = val === "timelimit";
    setState({
      completedCheckBoxState: false,
      pendingCheckBoxState: false,
      inProgressCheckBoxState: false,
      searchText: "",
      currentPrioritySort: !targetColumns ? "ascend" : false,
      currentTimelimitSort: targetColumns ? "descend" : false,
      sort: val,
    });
  };

  const performAdvanceTaskSearch = () => {
    const { page, limit, searchPriority } = stateRef.current;

    const newQueryProjectTask = R.clone(queryProjectTask);
    newQueryProjectTask.Task.status =
      newQueryProjectTask.Task.status.length > 0
        ? newQueryProjectTask.Task.status.join(",") + ","
        : undefined;

    if (searchPriority) {
      newQueryProjectTask["priority"] = searchPriority;
    }
    apiRequest(
      {
        method: "postRequest",
        url: parseEndpoint(endpoints.advanceProjectTaskSearch, {
          projectUUId: getProjectId(),
          page: page - 1,
          limit: limit,
        }),
        data: newQueryProjectTask,
      },
      ({ data }) => {
        // @ts-expect-error TS2339
        const { count } = data;
        // @ts-expect-error TS18046
        const rows = transformAllProjectTasksList(data.rows);
        // @ts-expect-error TS2554
        dispatch(ProjectActions.projectTaskGetsSuccess({ count, rows }));
      },
    );
  };
  return {
    getProjectId,
    setState,
    handleReset,
    state: stateRef.current,
    intl,
    handleMenuClick,
    handleChangeStars,
    onSortParameter,
    fetchTask,
    handleChange,
    handleOk,
    onTableChange,
    handleCancel,
    handleCreateModalOk,
    handleCreateModalClose,
    setQueryProjectTask,
    queryProjectTask,
    performAdvanceTaskSearch,
  };
};

export default useProjectTasksScreen;
