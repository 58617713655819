import React from "react";
import { Redirect, Route } from "react-router-dom";

import { withContext } from "../../Provider";
import { RouteProps } from "./types/RouteProps";

const PrivateAdminRoute: React.FC<RouteProps> = ({
  component: Component,
  location,
  context,
  ...rest
}: RouteProps) => {
  const user = context.user;
  return (
    <Route
      {...rest}
      render={(props) =>
        user.type === "admin" ? (
          <Component {...props} context={context} />
        ) : (
          <Redirect
            to={{
              pathname: "/dashboard",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};
export default withContext(PrivateAdminRoute);
