import { FC } from "react";
import { useIntl } from "react-intl";

import { SortableTagList } from "@/shared/tags/components/SortableTagList";

type ProjectStageTags = {
  stages: string[];
  setStages: (stages: string[]) => void;
};

export const ProjectStageTags: FC<ProjectStageTags> = ({
  stages,
  setStages,
}) => {
  const intl = useIntl();

  const handleCreate = (newStage: string) => {
    const isDuplicate = stages.some((stage) => stage === newStage);
    const isEmpty = newStage.trim().length === 0;
    if (isDuplicate || isEmpty) return;

    setStages([...stages, newStage]);
  };

  const handleEdit = (oldStage: string, newStage: string) => {
    const isDuplicate = stages.some((stage) => stage === newStage);
    const isEmpty = newStage.trim().length === 0;
    if (isDuplicate || isEmpty) return;

    const newStages = stages.map((stage) =>
      stage === oldStage ? newStage : stage,
    );
    setStages(newStages);
  };

  const handleDeleted = (deletedStage: string) => {
    const _stages = stages.filter((tag) => tag !== deletedStage);
    setStages(_stages);
  };

  const handleSortEnd = (newStages: string[]) => {
    setStages(newStages);
  };

  return (
    <SortableTagList
      tags={stages}
      createLabel={intl.formatMessage({
        id: "screen.label.stage",
      })}
      onCreated={handleCreate}
      onEdited={handleEdit}
      onDeleted={handleDeleted}
      onSortEnd={handleSortEnd}
    />
  );
};
