import { notification } from "antd";
import { useCallback, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";

import { endpoints, parseEndpoint } from "../../../Services/endpoints";
import { getPureStringFromHtml } from "../../../utils/common";
import { nonRecruiting } from "../../../utils/constants";
import {
  IProjectCreateScreenProps,
  ProjectCreateScreenState,
} from "../types/IProjectCreateScreenProps";

const useProjectCreateScreen = (props: IProjectCreateScreenProps) => {
  const intl = useIntl();
  const { user, apiRequest, updatePageTitle } = props.context;
  const {
    match: { params },
  } = props;

  const [state, customSetState] = useState<ProjectCreateScreenState>(() => {
    return {
      name: "",
      type: nonRecruiting,
      description: "",
      stage: null,
      icon: "",
      milestone: null,
      milestoneDate: null,
      isPrivate: false,
      reminder: "",
      reminderDate: null,
      projectTags: [],
      tags: [],
      defaultMilestoneDate: 0,
      newMileStone: "",
      batch: null,
      links: [],
    };
  });
  const setStateCallbackRef = useRef(() => {});
  useEffect(() => {
    const callBack = setStateCallbackRef.current;
    callBack();
    setStateCallbackRef.current = () => {};
  }, [state]);
  const setState = (data: ProjectCreateScreenState, callback = () => {}) => {
    setStateCallbackRef.current = callback;
    customSetState((previousState) => {
      return {
        ...previousState,
        ...data,
      };
    });
  };

  const getTag = useCallback(() => {
    const tagRequest = {
      method: "getRequest",
      url: parseEndpoint(endpoints.tags),
      data: {},
    };
    // @ts-expect-error TS2345
    apiRequest(tagRequest, ({ data }) => {
      setState({
        // @ts-expect-error TS2322
        tags: data,
      });
    });
  }, [apiRequest]);
  const getBatchId = useCallback(() => {
    return params.id ? params.id : -1;
  }, [params.id]);
  const setBatch = useCallback(() => {
    const batchId = getBatchId();
    const batches = Array.isArray(props.batches) ? props.batches : [];
    const batch = batches.find((b) => b.id === batchId);
    // 開催中のプログラムのみ設定する
    if (batch && batch.status === 1) {
      setState({
        batch,
      });
    }
  }, [props.batches, getBatchId]);

  useEffect(() => {
    updatePageTitle("screen.label.add_project");
    getTag();
    setBatch();
  }, [getTag, setBatch, updatePageTitle]);

  // @ts-expect-error TS7006
  const onChangeTags = (tag) => {
    // @ts-expect-error TS18048
    if (state.projectTags.includes(tag.id)) {
      // @ts-expect-error TS18048
      const newTag = state.projectTags.filter((tagId) => {
        if (tagId === tag.id) {
          return false;
        }
        return true;
      });
      setState({
        projectTags: newTag,
      });
    } else {
      setState({
        // @ts-expect-error TS18048
        projectTags: state.projectTags.concat(tag.id),
      });
    }
  };
  const handleChange = useCallback((key, value) => {
    setState({ [key]: value });
  }, []);
  // @ts-expect-error TS7006
  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      getPureStringFromHtml(
        // @ts-expect-error TS2345
        state.description,
      ).trim() === ""
    ) {
      notification.warning({
        message: intl.formatMessage({
          id: "screen.label.description_required",
        }),
      });
      return;
    }
    const createObj = {
      batchId: getBatchId(),
      links: state.links,
      name: state.name,
      type: state.type,
      description: state.description,
      icon: state.icon,
      isPrivate: state.isPrivate,
      defaultMilestoneDate: state.defaultMilestoneDate,
      redirectURL: `${getBatchId()}`,
    };
    if (state.stage) {
      // @ts-expect-error TS7053
      createObj["stage"] = state.stage;
    }
    if (state.projectTags) {
      // @ts-expect-error TS7053
      createObj["projectTags"] = state.projectTags;
    }
    if (state.milestoneDate) {
      // @ts-expect-error TS7053
      createObj["milestoneDate"] = state.milestoneDate;
    }
    if (state.milestone) {
      // @ts-expect-error TS7053
      createObj["milestone"] = state.milestone;
    }
    if (state.reminder) {
      // @ts-expect-error TS7053
      createObj["reminder"] = state.reminder;
    }
    if (state.reminderDate) {
      // @ts-expect-error TS7053
      createObj["reminderDate"] = state.reminderDate;
    }
    const message = intl.formatMessage({
      id: "screen.label.project_has_been_created",
    });
    props.dispatchCreateProject(createObj, message).then(() => {
      // @ts-expect-error TS2322
      props.dispatchGetMyProjects({ userId: user.id, page: 0 });
    });
  };

  // @ts-expect-error TS7006
  const onBeforeUpload = (file) => {
    //Prevent file uploader to upload file immediately
    handleChange("icon", file);
    return false;
  };

  return {
    intl,
    state,
    setState,
    onChangeTags,
    handleChange,
    handleSubmit,
    getTag,
    getBatchId,
    onBeforeUpload,
  };
};

export default useProjectCreateScreen;
