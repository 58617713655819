import { notification } from "antd";
import { all, call, put, takeLatest } from "redux-saga/effects";

import MainCategoryActions from "../Redux/IndustryMainCategoryRedux";
import SubCategoryActions, {
  IndustrySubCategoryTypes as SubCategoryTypes,
} from "../Redux/IndustrySubCategoryRedux";
import { endpoints, parseEndpoint } from "../Services/endpoints";

// @ts-expect-error TS7006
function* attemptUpdateCategories(api, action) {
  const { data } = action;
  const { id, ...body } = data;

  // @ts-expect-error TS7057
  const dataResponse = yield call(
    api.putRequest,
    parseEndpoint(endpoints.updateIndustrySubCategory, { id }),
    body,
  );
  if (dataResponse.ok) {
    switch (dataResponse.data.status) {
      case "success":
        yield put(SubCategoryActions.industrySubCategoryUpdateSuccess());
        yield put(MainCategoryActions.industryMainCategoryGetRequest());
        break;
      case "fail":
      case "error":
        if (typeof dataResponse.data.data === "string") {
        }
        yield put(
          SubCategoryActions.industrySubCategoryUpdateFailure(
            // @ts-expect-error TS2554
            dataResponse.data.data,
          ),
        );
        break;
      default:
        break;
    }
  } else {
    notification.error({
      message: dataResponse.problem,
    });
    yield put(
      // @ts-expect-error TS2554
      SubCategoryActions.industrySubCategoryUpdateFailure(dataResponse.problem),
    );
    if (dataResponse.status === 401) {
      // @ts-expect-error TS2322
      window.location = "/login";
    }
  }
}

// @ts-expect-error TS7006
function* attemptCreateCategories(api, action) {
  const { data } = action;

  // @ts-expect-error TS7057
  const dataResponse = yield call(
    api.postRequest,
    parseEndpoint(endpoints.createIndustrySubReport),
    data,
  );
  if (dataResponse.ok) {
    switch (dataResponse.data.status) {
      case "success":
        yield put(
          SubCategoryActions.industrySubCategoryCreateSuccess(
            // @ts-expect-error TS2554
            dataResponse.data.data,
          ),
        );
        yield put(MainCategoryActions.industryMainCategoryGetRequest());
        break;
      case "fail":
      case "error":
        if (typeof dataResponse.data.data === "string") {
        }
        yield put(
          SubCategoryActions.industrySubCategoryCreateFailure(
            // @ts-expect-error TS2554
            dataResponse.data.data,
          ),
        );
        break;
      default:
        break;
    }
  } else {
    notification.error({
      message: dataResponse.problem,
    });
    yield put(
      // @ts-expect-error TS2554
      SubCategoryActions.industrySubCategoryCreateFailure(dataResponse.problem),
    );
    if (dataResponse.status === 401) {
      // @ts-expect-error TS2322
      window.location = "/login";
    }
  }
}

// @ts-expect-error TS7006
function* attemptDeleteCategories(api, action) {
  const { data } = action;
  // @ts-expect-error TS7057
  const dataResponse = yield call(
    api.deleteRequest,
    parseEndpoint(endpoints.deleteSubCategory, { data }),
  );

  if (dataResponse.ok) {
    switch (dataResponse.data.status) {
      case "success":
        yield put(SubCategoryActions.industrySubCategoryDeleteSuccess());
        yield put(MainCategoryActions.industryMainCategoryGetRequest());
        break;
      case "fail":
      case "error":
        if (typeof dataResponse.data.data === "string") {
        }
        yield put(
          SubCategoryActions.industrySubCategoryDeleteFailure(
            // @ts-expect-error TS2554
            dataResponse.data.data,
          ),
        );
        break;
      default:
        break;
    }
  } else {
    yield put(
      // @ts-expect-error TS2554
      SubCategoryActions.industrySubCategoryDeleteFailure(dataResponse.problem),
    );
    if (dataResponse.status === 401) {
      // @ts-expect-error TS2322
      window.location = "/login";
    }
  }
}

// @ts-expect-error TS7006
function* newsSaga(api) {
  yield all([
    takeLatest(
      SubCategoryTypes.INDUSTRY_SUB_CATEGORY_UPDATE_REQUEST,
      attemptUpdateCategories,
      api,
    ),
    takeLatest(
      SubCategoryTypes.INDUSTRY_SUB_CATEGORY_CREATE_REQUEST,
      attemptCreateCategories,
      api,
    ),
    takeLatest(
      SubCategoryTypes.INDUSTRY_SUB_CATEGORY_DELETE_REQUEST,
      attemptDeleteCategories,
      api,
    ),
  ]);
}
export default newsSaga;
