import FileSaver from "file-saver";
import { useEffect, useRef } from "react";
import { useIntl } from "react-intl";

import { endpoints, parseEndpoint } from "../../../Services/endpoints";
import { useStateWithRef } from "../../../utils/hooks";
import { getInitStorageValue } from "../../../utils/localStorage";
import {
  IProjectFileScreenProps,
  ProjectFileScreenState,
} from "../types/IProjectFileScreenProps";
const PageSaveId = "ProjectFileScreen";
const pageKey = `pagination_page_${PageSaveId}`;
const limitKey = `pagination_limit_${PageSaveId}`;

const useProjectFileScreen = (props: IProjectFileScreenProps) => {
  const intl = useIntl();
  const { apiRequest, user, updatePageTitle } = props.context;
  const [state, customSetState, stateRef] = useStateWithRef(() => {
    return {
      minValue: 0,
      maxValue: 50,
      visible: false,
      page: getInitStorageValue(pageKey, 1),
      limit: getInitStorageValue(limitKey, process.env.REACT_APP_PAGE_SIZE),
      fileList: [],
      privacyTag: "public",
      viewMode: "list",
    };
  });
  const setStateCallbackRef = useRef(() => {});
  useEffect(() => {
    const callBack = setStateCallbackRef.current;
    callBack();
    setStateCallbackRef.current = () => {};
  }, [state]);
  const setState = (data: ProjectFileScreenState, callback = () => {}) => {
    setStateCallbackRef.current = callback;
    // @ts-expect-error TS7006
    customSetState((previousState) => {
      return {
        ...previousState,
        ...data,
      };
    });
  };

  useEffect(() => {
    updatePageTitle("screen.label.file");
    fetchFiles();
  }, []);

  const fetchFiles = (filter = "", sort = "") => {
    const { page, limit } = stateRef.current;
    props.dispatchFetchFiles(page - 1, limit, getProjectId(), filter, sort);
  };
  // @ts-expect-error TS7006
  const sortOrder = (ev) => {
    if (ev === "createdAt") {
      fetchFiles("", `${ev} DESC`);
      return;
    }
    fetchFiles("", `${ev} ASC`);
  };
  const showModal = () => {
    setState({
      visible: true,
    });
  };
  const hideModal = () => {
    setState({
      visible: false,
    });
  };
  const getProjectId = () => {
    const {
      match: { params },
    } = props;
    return params.id ? params.id : -1;
  };

  const isSuperUser = () => {
    return user.type === "admin" && user.isSuper;
  };

  // @ts-expect-error TS7006
  const fileDownloaded = (fileId, fileDetail = {}) => {
    const data = {
      method: "postRequest",
      url: parseEndpoint(endpoints.fileDownload, {
        fileId,
      }),
      data: fileDetail,
    };
    // @ts-expect-error TS2345
    apiRequest(data, ({ data }) => {});
  };
  // @ts-expect-error TS7006
  const saveFile = (href, fileName, extension, fileId) => {
    const xhr = new XMLHttpRequest();
    xhr.withCredentials = true;
    xhr.open("GET", href, true);
    xhr.responseType = "arraybuffer";
    xhr.onload = (e) => {
      // @ts-expect-error TS18047
      const arrayBufferView = new Uint8Array(e.currentTarget.response);
      const blob = new Blob([arrayBufferView]);
      fileDownloaded(fileId ? fileId : -1, {
        fileName,
        fileSize: blob.size,
      });
      FileSaver.saveAs(blob, fileName);
    };
    xhr.send();
  };
  const deleteFiles = (fileId: string) => {
    const message = intl.formatMessage({
      id: "screen.label.file_has_been_deleted",
    });
    props.dispatchDeleteFiles(fileId, getProjectId(), message);
  };

  const data = props.files.filesPayload.rows;

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
  };

  return {
    intl,
    state: stateRef.current,
    setState,
    fetchFiles,
    sortOrder,
    showModal,
    hideModal,
    getProjectId,
    isSuperUser,
    fileDownloaded,
    saveFile,
    deleteFiles,
    data,
    layout,
  };
};

export default useProjectFileScreen;
