import { ApisauceInstance } from "apisauce";
import { useCallback } from "react";
import useSWR from "swr";

import { api } from "../../../Saga";
import {
  BaseErrorResponse,
  NullResponse,
} from "../../../Services/commonResponsTypes";
import { endpoints, parseEndpoint } from "../../../Services/endpoints";
import {
  ExaminationPhase,
  UpdateExaminationPhaseOrderAttributes,
  UpsertExaminationPhaseNameAttribute,
} from "../types/ExaminationPhase";
import { GetExaminationPhasesResponse } from "../types/response";

type AxiosRequestConfig = Parameters<ApisauceInstance["any"]>[0];

export const useExaminationPhasesRequest = (batchId: string) => {
  const url = parseEndpoint(endpoints.getExaminationPhases, { batchId });

  const fetcher = useCallback(async () => {
    const response = await api.getRequest<
      GetExaminationPhasesResponse,
      BaseErrorResponse
    >(url);

    // !response.ok ならaxios(apiSauase)のエラーなのでresponse.originalError.messageにメッセージが入る
    // response.data?.status !== 'success'ならBaseResponseのエラーなのでresponse.data.messageにエラーメッセージが入る
    // fetcher内でthrowするとSWRが拾ってerrorにエラーメッセージが入る
    if (!response.ok) throw new Error(response.originalError.message);
    if (response.data?.status !== "success")
      throw new Error(response.data?.message);

    return response.data.data;
  }, [url]);

  const {
    data,
    error,
    isValidating,
    mutate: examinationPhasesMutate,
  } = useSWR<ExaminationPhase[], Error>(url, fetcher);

  const getExaminationPhases = () => {
    return {
      examinationPhases: data,
      error,
      isValidating,
    };
  };

  const updateExaminationPhaseName = useCallback(
    async (
      examinationPhaseId: number,
      name: string,
      config?: AxiosRequestConfig,
    ) => {
      const url = parseEndpoint(endpoints.updateExaminationPhaseName, {
        examinationPhaseId,
      });

      const response = await api.patchRequest<
        UpsertExaminationPhaseNameAttribute,
        NullResponse,
        BaseErrorResponse
      >(url, { name }, config);

      if (response.ok && response.data?.status === "success") {
        examinationPhasesMutate();
      }

      return response;
    },
    [examinationPhasesMutate],
  );

  const updateActiveExaminationPhase = useCallback(
    async (examinationPhaseId: number, config?: AxiosRequestConfig) => {
      const url = parseEndpoint(endpoints.updateExaminationPhaseActive, {
        examinationPhaseId,
        batchId,
      });

      const response = await api.patchRequest<
        unknown,
        NullResponse,
        BaseErrorResponse
      >(url, {}, config);

      if (response.ok && response.data?.status === "success") {
        examinationPhasesMutate();
      }

      return response;
    },
    [batchId, examinationPhasesMutate],
  );

  const updateExaminationPhaseOrder = useCallback(
    async (
      examinationPhases: ExaminationPhase[],
      config?: AxiosRequestConfig,
    ) => {
      const url = parseEndpoint(endpoints.patchExaminationPhaseOrder);

      const sortedExaminationPhases: UpdateExaminationPhaseOrderAttributes[] =
        examinationPhases.map((examinationPhase, index) => ({
          id: examinationPhase.id,
          order: index + 1,
        }));

      const response = await api.patchRequest<
        UpdateExaminationPhaseOrderAttributes[],
        NullResponse,
        BaseErrorResponse
      >(url, sortedExaminationPhases, config);
      if (response.ok && response.data?.status === "success") {
        examinationPhasesMutate();
      }

      return response;
    },
    [examinationPhasesMutate],
  );

  const deleteExaminationPhase = useCallback(
    async (examinationPhaseId: number) => {
      const url = parseEndpoint(endpoints.singleExaminationPhase, {
        examinationPhaseId,
      });

      const response = await api.deleteRequest<NullResponse, BaseErrorResponse>(
        url,
      );

      if (response.ok && response.data?.status === "success") {
        examinationPhasesMutate();
      }

      return response;
    },
    [examinationPhasesMutate],
  );

  const createExaminationPhase = useCallback(
    async (name: string, config?: AxiosRequestConfig) => {
      const url = parseEndpoint(endpoints.getExaminationPhases, {
        batchId,
      });
      const response = await api.postRequest<
        UpsertExaminationPhaseNameAttribute,
        NullResponse,
        BaseErrorResponse
      >(url, { name }, config);

      if (response.ok && response.data?.status === "success") {
        examinationPhasesMutate();
      }

      return response;
    },
    [batchId, examinationPhasesMutate],
  );

  return {
    createExaminationPhase,
    getExaminationPhases,
    updateExaminationPhaseName,
    updateActiveExaminationPhase,
    deleteExaminationPhase,
    updateExaminationPhaseOrder,
  };
};
