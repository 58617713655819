import styled from "styled-components";

export const MemberAddCol = styled.div`
  max-width: 120px;
  max-height: 70px;
  overflow: hidden;
`;

export const MemberSearchTitleSpan = styled.span`
  font-weight: bolder;
  margin-right: 6px;
`;

export const MemberSearchDesSpan = styled.span`
  margin-left: 6px;
`;

export const MemberSearchCloseSpan = styled.span`
  margin-left: 4px;
  cursor: pointer;
`;
