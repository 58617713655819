import { getObjUUID } from "../../utils/common";
// @ts-expect-error TS7006
export const transformAllProjectsList = function (projectsArray) {
  if (Array.isArray(projectsArray)) {
    return projectsArray.map((project) => {
      let tag = "";
      if (project.projectTags && project.projectTags.length > 0) {
        // @ts-expect-error TS7006
        project.projectTags.forEach((o) => {
          tag += ` ${o.name} ,`;
        });
      }
      return {
        key: project.id,
        id: project.id,
        uuid: getObjUUID(project),
        name: project.name,
        date: project.createdAt,
        type: project.type,
        stage: project.stage,
        tag: tag.slice(0, -1),
        isAdmin: project.isProjectOwner,
        members: 2,
        status: project.status,
        milestone: project.milestone,
        milestoneDate: project.milestoneDate,
        icon: project.icon,
        updatedAt: project.updatedAt,
        createdAt: project.createdAt,
        team: project.team,
        isWatch: project.isWatch,
        batch: project.batch,
        displayCheckpoint: project.displayCheckpoint,
        projectCheckpoints: project.projectCheckpoints,
        checkpointCoverageRate: project.checkpointCoverageRate,
      };
    });
  }
  return [];
};
// @ts-expect-error TS7006
export const transformAllProjectTasksList = function (projectsArray) {
  if (Array.isArray(projectsArray)) {
    return projectsArray.map((project) => {
      return {
        key: project.id,
        uuid: getObjUUID(project),
        name: project.title,
        description: project.description,
        userId: project.userId,
        incharge: project.incharge,
        timelimit: project.timelimit,
        date: project.createdAt,
        type: project.type,
        stage: project.stage,
        status: project.status,
        priority: project.priority,
        isAdmin: project.isProjectOwner,
      };
    });
  }
  return [];
};
