import { memo } from "react";

import EditIcon from "./assets/edit.svg";
import styles from "./styles.module.scss";
import { EditButtonProps } from "./type";
export const EditIconButton = memo(
  ({ id, className, onClick, alt, ...rest }: EditButtonProps) => {
    return (
      <button
        id={id}
        onClick={onClick}
        className={`${styles.edit_icon_button} ${className}`}
        {...rest}
      >
        <img src={EditIcon} alt={alt} />
      </button>
    );
  },
);
