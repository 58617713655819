import { useMemo } from "react";
import { useIntl } from "react-intl";
import { SingleValue } from "react-select";

import { PlainSelect } from "@/shared/selectbox/components/PlainSelect";
import { SelectOption } from "@/shared/selectbox/types/SelectOption";

import { Category } from "../../../../../types/Category";

type SubmissionCategorySelectBoxProps = {
  id: string;
  categories: Category[];
  className?: string;
  isError: boolean;
  value?: string;
  onChange: (newValue: SingleValue<SelectOption<string>>) => void;
  onBlur?: () => void;
};

export const SubmissionCategorySelectBox = ({
  categories,
  className,
  isError,
  value,
  id,
  onChange,
  onBlur,
}: SubmissionCategorySelectBoxProps) => {
  const intl = useIntl();
  const placeholder = intl.formatMessage({
    id: "screens.submission.select_category_required",
  });
  const options = categories.map((category) => ({
    value: category.id,
    label: category.submissionCategoryMetadata.submissionCategory,
  }));

  const selectedOption = useMemo(
    () => options.find((option) => option.value === value),
    [options, value],
  );

  return (
    <PlainSelect
      key={value}
      value={selectedOption}
      className={className}
      options={options}
      onChange={onChange}
      onBlur={onBlur}
      id={id}
      placeholder={placeholder}
      isError={isError}
      optionComponentVariant="check"
    />
  );
};
