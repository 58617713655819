import { Card, Col, Form, Input, Row } from "antd";
import PropTypes from "prop-types";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";

import { ReduxDispatch, ReduxState } from "@/Redux/types";

import { ButtonCV } from "../../Components/atoms";
import ApiActions, {
  ApiRequestData,
  ApiSuccessCallback,
} from "../../Redux/ApiRedux";
import HomeActions from "../../Redux/HomeRedux";
import FileUploadComponent from "../project/FileUploadComponent";
import useEditSmallCategoryScreen from "./hooks/useEditSmallCategoryScreen";
import { IEditSmallCategoryScreenProps } from "./types/IEditSmallCategoryScreenProps";

const { TextArea } = Input;

const EditSmallCategoryScreen: FC<IEditSmallCategoryScreenProps> = function (
  props,
) {
  const { intl, state, handleChange, onUpdateSmallCategory } =
    useEditSmallCategoryScreen(props);

  return (
    <>
      <Card
        title={intl.formatMessage({
          id: "screen.label.small_category_edit",
        })}
      >
        <Form layout={"vertical"}>
          <Col span={24}>
            <Form.Item
              label={intl.formatMessage({
                id: "screen.label.title",
              })}
            >
              <Input
                value={state.name}
                onChange={(event) => handleChange("name", event.target.value)}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              label={intl.formatMessage({
                id: "screen.label.more_detail",
              })}
            >
              <TextArea
                value={state.description}
                onChange={(event) =>
                  handleChange("description", event.target.value)
                }
              />
            </Form.Item>
          </Col>

          <Col xs={24} sm={12} md={8} lg={8}>
            <Form.Item
              label={intl.formatMessage({
                id: "screen.label.thumbnail_attached",
              })}
            >
              <FileUploadComponent
                imageSrc={null}
                message={`＋ ${intl.formatMessage({
                  id: "screen.label.click",
                })}`}
                onFileChanged={(imageThumbnail) => {
                  handleChange("thumbnail", imageThumbnail);
                }}
              />
            </Form.Item>
          </Col>
        </Form>
      </Card>
      <Row>
        <Col span={24}>
          <ButtonCV onClick={onUpdateSmallCategory} type="primary">
            <FormattedMessage id="screen.label.completion" />
          </ButtonCV>
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  user: state.auth.payload,
  auth: state.auth,
});
const mapDispatchToProps = (dispatch: ReduxDispatch) => ({
  // @ts-expect-error TS7006
  dispatchSetPageTitle: (pageTitle) =>
    // @ts-expect-error TS2554
    dispatch(HomeActions.pageTitle(pageTitle)),
  dispatchApiRequest: (data: ApiRequestData, callback: ApiSuccessCallback) =>
    dispatch(ApiActions.apiRequest(data, callback)),
});
EditSmallCategoryScreen.propTypes = {
  // @ts-expect-error TS2322
  user: PropTypes.object,
  match: PropTypes.object,
  auth: PropTypes.object,
  dispatchSetPageTitle: PropTypes.func,
  dispatchApiRequest: PropTypes.func,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditSmallCategoryScreen);
