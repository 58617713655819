import { Collapse, PageHeader } from "antd";
// @ts-expect-error TS7016
import * as R from "ramda";
import { FC, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import { Button } from "@/Components/atoms";
import CommentComponent from "@/Components/organisms/project/CommentComponent";
import EditorComponent from "@/Components/organisms/project/EditorComponent";
import { getObjUUID } from "@/utils/common";

import { Comment } from "../../../../../types/Comment";
import { Project } from "../../../../../types/Project";
import { UserBasicInfo } from "../../../../../types/User";

export const PROJECT_COMMENT_AREA_ID = "project_comment_area";

const customPanelStyle = {
  background: "#fff ",
  overflow: "hidden",
};

const { Panel } = Collapse;

type ProjectCommentAreaProps = {
  scrollToCommentTop: () => void;
};

export const ProjectCommentArea: FC<ProjectCommentAreaProps> = ({
  scrollToCommentTop,
}) => {
  const project: Project = useSelector(
    // @ts-expect-error TS2339
    (state) => state.projects.projectPayload,
  );
  const comments: Comment[] = useSelector(
    // @ts-expect-error TS2339
    (state) => state.comments.commentsPayload.rows,
  );
  const allUsersForMention: UserBasicInfo[] = useSelector(
    // @ts-expect-error TS2339
    (state) => state.user.allUsersForMentionPayload,
  );

  const [showAllReplies, setShowAllReplies] = useState(false);
  const [selectingEmoji, setSelectingEmoji] = useState(false);
  const [marginForEditor, setMarginForEditor] = useState(0);

  const isProPlan = project?.Company?.currentPlan === "PRO" ?? false;
  const isBasicPlan = project?.Company?.currentPlan === "BASIC" ?? false;
  const isDormantPlan = project?.Company?.currentPlan === "DORMANT" ?? false;

  const getSuggestions = () => {
    let suggestions: { text: string; value: string; url: string }[] = [];
    const teamMembers = project?.Team ?? [];
    const allUsers = allUsersForMention ?? [];

    // @ts-expect-error TS7006
    const parseAllUserDet = function (element) {
      if (element.name !== null && element.name !== "") {
        if (element.name.trim().length > 0) {
          return {
            text: element.name,
            value: element.name,
            url: getObjUUID(element),
          };
        }
      }
      return {
        text: element.email,
        value: element.email,
        url: getObjUUID(element),
      };
    };

    if (teamMembers.length > 0) {
      suggestions = teamMembers
        .filter((teamMember) => {
          if (teamMember.User.deletedAt) {
            return false;
          }
          if (getObjUUID(teamMember.User) == "") {
            return false;
          }
          return teamMember.status == 1 && teamMember.userStatus == 1;
        })
        .map((teamMember) => {
          if (teamMember.User.name !== null && teamMember.User.name !== "") {
            if (teamMember.User.name.trim().length > 0) {
              return {
                text: teamMember.User ? teamMember.User.name : "",
                value: teamMember.User ? teamMember.User.name : "",
                url: getObjUUID(teamMember.User),
              };
            }
          }
          return {
            text: teamMember.User
              ? // @ts-expect-error ts(2532)
                teamMember.User.Credential.email
              : "",
            value: teamMember.User
              ? // @ts-expect-error ts(2532)
                teamMember.User.Credential.email
              : "",
            url: getObjUUID(teamMember.User),
          };
        });
    }

    const commentUsers = R.sort(
      // @ts-expect-error TS7006
      (a, b) => {
        return a.url - b.url;
      },
      comments
        .filter((a) => {
          if (!a.User.isActive) {
            return false;
          }
          if (a.User.deletedAt !== null) {
            return false;
          }
          if (a.User.stoppedAt !== null) {
            return false;
          }
          if (getObjUUID(a.User) == "") {
            return false;
          }
          return suggestions.find((b) => b.url === getObjUUID(a.User))
            ? false
            : true;
        })
        .map((comment) => {
          return parseAllUserDet(comment.User);
        }),
    );

    const filteredCommentUsers = commentUsers.filter(
      (
        (s) =>
        // @ts-expect-error TS7006
        (o) =>
          ((k) => !s.has(k) && s.add(k))(["url"].map((k) => o[k]).join("|"))
      )(new Set()),
    );
    const suggestAndComments = [...suggestions, ...filteredCommentUsers];
    const filteredUsers = allUsers.filter((u) => {
      return suggestAndComments.find((b) => b.url === getObjUUID(u))
        ? false
        : true;
    });
    const mentors = filteredUsers
      .filter((u) => {
        return (
          u.type === "mentor" &&
          u.isActive &&
          u.deletedAt == null &&
          u.stoppedAt == null &&
          getObjUUID(u) !== ""
        );
      })
      .map(parseAllUserDet);
    const pmUsers = filteredUsers
      .filter((u) => {
        return (
          u.type === "admin" &&
          u.isActive &&
          u.deletedAt == null &&
          u.stoppedAt == null &&
          getObjUUID(u) !== ""
        );
      })
      .map(parseAllUserDet);
    const otherUsers = filteredUsers
      .filter((u) => {
        return (
          u.type === "employee" &&
          u.isActive &&
          u.deletedAt == null &&
          u.stoppedAt == null &&
          getObjUUID(u) !== ""
        );
      })
      .map(parseAllUserDet);
    // @ts-expect-error TS7006
    const removeDuplicates = (data, key) => {
      // @ts-expect-error TS7006
      return [...new Map(data.map((item) => [key(item), item])).values()];
    };
    suggestions = [
      ...suggestAndComments,
      ...mentors,
      ...pmUsers,
      ...otherUsers,
    ];
    // @ts-expect-error TS7006
    suggestions = removeDuplicates(suggestions, (item) => item.value);
    return suggestions;
  };

  return (
    <div
      id={PROJECT_COMMENT_AREA_ID}
      style={{
        marginBottom: marginForEditor,
      }}
    >
      <Collapse activeKey="collapse_comment_editor" bordered={false}>
        {isProPlan && (
          <>
            {comments.length > 0 && (
              <Panel
                showArrow={false}
                header={
                  <PageHeader
                    className="project-item-header"
                    title={<FormattedMessage id="screen.label.comment" />}
                    extra={
                      <Button
                        style={{
                          border: "1px solid #1F86E9",
                          color: "#1F86E9",
                        }}
                        onClick={() => setShowAllReplies(!showAllReplies)}
                      >
                        <FormattedMessage
                          id={
                            showAllReplies
                              ? "screen.label.all_hide"
                              : "screen.label.all_show"
                          }
                        />
                      </Button>
                    }
                  />
                }
                key="collapse_comment_editor"
                style={customPanelStyle}
              >
                <div
                  style={{
                    marginBottom: selectingEmoji ? "11vh" : 0,
                  }}
                >
                  <CommentComponent
                    // @ts-expect-error TS2322
                    onEmojiVisibilityChanged={(visibiltyState) => {
                      if (visibiltyState) {
                        setSelectingEmoji(true);
                      } else {
                        setSelectingEmoji(false);
                      }
                    }}
                    canEdit={true}
                    shouldShowCommentsReplies={showAllReplies}
                    comments={comments}
                    suggestions={getSuggestions()}
                  />
                </div>
              </Panel>
            )}
            <EditorComponent
              // @ts-expect-error TS2322
              numberOfUnreadComments={project.numberOfUnreadComments ?? 0}
              suggestions={getSuggestions()}
              changeBottomMargin={(margin: number) =>
                setMarginForEditor(margin)
              }
              scrollToPostComment={scrollToCommentTop}
            />
          </>
        )}
        {(isBasicPlan || isDormantPlan) && (
          <>
            {comments.length > 0 && (
              <Panel
                showArrow={false}
                header={
                  <PageHeader
                    className="project-item-header"
                    title={<FormattedMessage id="screen.label.comment" />}
                  />
                }
                key="collapse_comment_editor"
                style={customPanelStyle}
              >
                <CommentComponent
                  // @ts-expect-error TS2322
                  canEdit={false}
                  shouldShowCommentsReplies={showAllReplies}
                  comments={comments}
                  suggestions={getSuggestions()}
                />
              </Panel>
            )}
          </>
        )}
      </Collapse>
    </div>
  );
};
