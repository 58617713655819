import { useCallback } from "react";

import styles from "./styles.module.scss";
import { ReviewPhaseTabProps } from "./type";

export const PhaseTab = ({
  phase,
  isSelected,
  onSelected,
}: ReviewPhaseTabProps) => {
  const onClick = useCallback(() => {
    onSelected(phase);
  }, [phase, onSelected]);
  const tabSelectStyle = isSelected
    ? styles.phase_tab_selected
    : styles.phase_tab_not_selected;
  const underlineSelectStyle = isSelected
    ? styles.phase_tab_underline_selected
    : styles.phase_tab_underline_not_selected;
  return (
    <div
      role="tab"
      aria-selected={isSelected}
      className={styles.phase_tab_container}
    >
      <button
        className={`${styles.phase_tab} ${tabSelectStyle}`}
        onClick={onClick}
      >
        {phase.name}
      </button>
      <div
        className={`${styles.phase_tab_underline} ${underlineSelectStyle}`}
      />
    </div>
  );
};
