import { LinkOutlined } from "@ant-design/icons";
import { Button, notification } from "antd";
// @ts-expect-error TS7016
import { CopyToClipboard } from "react-copy-to-clipboard";

type CopyButtonProps = {
  text: string;
  message: string;
};

export const CopyButton = ({ text, message }: CopyButtonProps) => {
  return (
    <CopyToClipboard
      text={text}
      onCopy={() => {
        notification.success({ message });
      }}
    >
      <Button type="ghost" size="small" icon={<LinkOutlined />} />
    </CopyToClipboard>
  );
};
