import { Col, Row, Table } from "antd";
import { ColumnsType, ColumnType } from "antd/lib/table";
import moment from "moment";
import PropTypes from "prop-types";
import { FC } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Immutable from "seamless-immutable";

import { Batch_SubmissionCategory } from "@/../types/Batch_SubmissionCategory";
import {
  ProjectArchive,
  ProjectArchivesRecord,
} from "@/../types/ProjectArchive";
import { ReduxState } from "@/Redux/types";

import useProjectArchiveScreenFullViewComponent from "../../../Screens/project/hooks/useProjectArchiveScreenFullViewComponent";
import { IProjectArchiveScreenFullViewComponentProps } from "../../../Screens/project/types/IProjectArchiveScreenFullViewComponentProps";
import { getPureStringFromHtml } from "../../../utils/common";
import { dateTimeFormat } from "../../../utils/constants";
import {
  getHypothesisObjectFromArray,
  prettifyDiff,
} from "../../../utils/projectUtils";
import { Button, Card, Checkbox, IconLeftArrow } from "../../atoms";
import { ProjectHypothesisFormName } from "../../molecules/project/ProjectHypothesisFormName";

const ProjectArchiveScreenFullViewComponent: FC<IProjectArchiveScreenFullViewComponentProps> =
  function (props) {
    const { intl, saveFile, handleProjectSubmissionTypesChange } =
      useProjectArchiveScreenFullViewComponent(props);
    const getHypothesisThatWasChanged = (
      newProject: ProjectArchivesRecord,
      oldProject: ProjectArchivesRecord,
    ) => {
      const mergedHypotheses = props.projectSetting;
      const hypothesesKeys = mergedHypotheses.map(
        (hypo) => hypo.hypothesisFormId,
      );
      for (let i = 0; i < hypothesesKeys.length; i++) {
        const hypothesis = hypothesesKeys[i];
        const metadata = mergedHypotheses[i].hypothesisFormMetadata;
        const oldRecord = getHypothesisObjectFromArray(oldProject);
        const newRecord = getHypothesisObjectFromArray(newProject);
        const oldHypothesisValue = getPureStringFromHtml(oldRecord[hypothesis]);
        const newHypothesisValue = getPureStringFromHtml(newRecord[hypothesis]);
        if (oldHypothesisValue !== newHypothesisValue) {
          return (
            <ProjectHypothesisFormName>
              {metadata.hypothesisFormName}
            </ProjectHypothesisFormName>
          );
        }
      }
    }; //
    const getHypothesisFirstItemChanged = (
      newProject: ProjectArchivesRecord,
      type: string,
    ) => {
      const mergedHypotheses = props.projectSetting;
      const hypothesesKeys = mergedHypotheses.map(
        (hypo) => hypo.hypothesisFormId,
      );
      for (let i = 0; i < hypothesesKeys.length; i++) {
        const hypothesis = hypothesesKeys[i];
        const metadata = mergedHypotheses[i].hypothesisFormMetadata;
        const newRecord = getHypothesisObjectFromArray(newProject);
        const newHypothesisValue = getPureStringFromHtml(newRecord[hypothesis]);
        if (
          newHypothesisValue !== null &&
          newHypothesisValue !== undefined &&
          newHypothesisValue !== ""
        ) {
          return (
            <div
              style={{
                maxWidth: "12rem",
                maxHeight: "3rem",
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                // @ts-expect-error TS2322
                WebkitLineClamp: "2",
                color: type === "value" ? "#00D382" : "rgba(0, 0, 0, 0.85)",
                WebkitBoxOrient: "vertical",
              }}
            >
              {type === "value"
                ? newHypothesisValue
                : metadata.hypothesisFormName}
            </div>
          );
        }
      }
    }; //
    const getHypothesisChangedContent = (
      newProject: ProjectArchivesRecord,
      oldProject: ProjectArchivesRecord,
    ) => {
      const mergedHypotheses = props.projectSetting;
      const hypothesesKeys = mergedHypotheses.map(
        (hypo) => hypo.hypothesisFormId,
      );
      for (let i = 0; i < hypothesesKeys.length; i++) {
        const hypothesis = hypothesesKeys[i];
        const oldRecord = getHypothesisObjectFromArray(oldProject);
        const newRecord = getHypothesisObjectFromArray(newProject);
        const oldHypothesisValue = getPureStringFromHtml(oldRecord[hypothesis]);
        const newHypothesisValue = getPureStringFromHtml(newRecord[hypothesis]);
        if (oldHypothesisValue !== newHypothesisValue) {
          // @ts-expect-error TS2339
          const dmp = new window.diff_match_patch();
          const d = dmp.diff_main(
            oldHypothesisValue ? oldHypothesisValue : "",
            newHypothesisValue ? newHypothesisValue : "",
          );
          dmp.diff_cleanupEfficiency(d);
          const ds = prettifyDiff(d);
          return (
            <div
              style={{
                maxWidth: "12rem",
                maxHeight: "3rem",
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                // @ts-expect-error TS2322
                WebkitLineClamp: "2",
                WebkitBoxOrient: "vertical",
              }}
              dangerouslySetInnerHTML={{ __html: ds }}
            />
          );
        }
      }
    }; //

    const getTableColumns = (): ColumnsType<ProjectArchive> => {
      return [
        {
          title: intl.formatMessage({
            id: "screen.label.change_history",
          }),
          dataIndex: "record.meta.title",
          key: "title",
          render: function _fn(text, row, index) {
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "20vw",
                  height: "84px",
                  minHeight: "85px",
                }}
              >
                <div
                  style={{
                    position: "relative",
                    marginRight: "2vw",
                    marginLeft: "3vw",
                    height: "100%",
                  }}
                >
                  <div className="line-in-middle">
                    <div style={{ marginLeft: "6px" }} className="just-line " />

                    <div onClick={() => props.onAddToSelectedArchives(row)}>
                      {row.archiveType === "project_update" ? (
                        <div
                          className="circle-dot-outline"
                          style={{
                            width: "20px",
                            height: "20px",
                          }}
                        />
                      ) : (
                        <div
                          className="circle-dot-outline"
                          style={{
                            width: "25px",
                            height: "25px",
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    width: "100%",
                    overflow: "hidden",
                  }}
                >
                  <Link
                    rel="noreferrer"
                    style={{
                      fontStyle: "normal",
                      fontWeight: "normal",
                      fontSize: "0.8rem",
                      lineHeight: "200%",
                      width: "100%",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      color: "#1F86E9",
                    }}
                    to={`/dashboard/project.archive/${props.projectId}/${row.id}`}
                  >
                    {row.record && row.record.meta && row.record.meta.title
                      ? row.record.meta.title
                      : "------"}
                  </Link>
                  <span
                    style={{
                      color: "#B5B5B5",
                      fontStyle: "normal",
                      fontWeight: "normal",
                      fontSize: "0.8rem",
                      lineHeight: "200%",
                    }}
                  >
                    {moment(row.createdAt).local().format(dateTimeFormat)}
                  </span>
                </div>
              </div>
            );
          },
        },
        {
          title: intl.formatMessage({
            id: "screen.label.submission_category",
          }),
          dataIndex: "record.meta.category",
          key: "record.meta.category",
          ...getSubmissionsFilterByCategoryProps(),
          render: (_, row) => {
            return row?.record?.meta?.category;
          },
        },
        {
          title: intl.formatMessage({
            id: "screen.label.user",
          }),
          dataIndex: "User.name",
          key: "User.name",
          render: (text, row) => (row.User ? row.User.name : ""),
        },
        {
          title: intl.formatMessage({
            id: "screen.label.changes",
          }),
          dataIndex: "hypothesisType",
          key: "hypothesisType",
          render: (text, row, index) => {
            const { record } = row;
            if (index === props.archiveList.length - 1) {
              return getHypothesisFirstItemChanged(record, "name");
            } else {
              if (index < props.archiveList.length - 1) {
                return getHypothesisThatWasChanged(
                  record,
                  props.archiveList[index + 1].record,
                );
              }
            }
            return "";
          },
        },
        {
          title: intl.formatMessage({
            id: "screen.label.content",
          }),
          dataIndex: "changedHypothesisContent",
          key: "changedHypothesisContent",
          render: (text, row, index) => {
            const { record } = row;
            if (index === props.archiveList.length - 1) {
              return getHypothesisFirstItemChanged(record, "value");
            } else {
              if (index < props.archiveList.length - 1) {
                return getHypothesisChangedContent(
                  record,
                  props.archiveList[index + 1].record,
                );
              }
            }
            return "";
          },
        },
        {
          title: intl.formatMessage({
            id: "screen.label.attachments",
          }),
          dataIndex: "attachedFiles",
          key: "attachedFiles",
          render: (text, row) => {
            if (row?.archiveType === "submission") {
              const fileUrl =
                row.fileUrl && row.fileUrl.length > 0 ? row.fileUrl : "";
              const fileUrlName = row?.record?.meta?.fileUrlName
                ? row.record?.meta?.fileUrlName
                : fileUrl;
              const uploadUrl =
                row.uploadUrl && row.uploadUrl.length > 0 ? row.uploadUrl : "";
              const parsedMultippleFiles = Array.isArray(
                row?.record?.meta?.parsedFileList,
              ) ? (
                row?.record?.meta?.parsedFileList.map((singlefile) => {
                  const uploadUrl = singlefile.uploadUrl;
                  const friendlyFileName = singlefile.friendlyFileName;
                  return (
                    <Button
                      key={uploadUrl}
                      style={{
                        whiteSpace: "break-spaces",
                        wordWrap: "break-word",
                      }}
                      onClick={() => {
                        const urlToDisplay = uploadUrl;
                        const href =
                          urlToDisplay && urlToDisplay.length > 0
                            ? urlToDisplay.indexOf("http") === -1
                              ? window.location.protocol + "//" + urlToDisplay
                              : urlToDisplay
                            : "";
                        saveFile(href, friendlyFileName);
                      }}
                      target="_blank"
                      type="link"
                    >
                      <span
                        style={{
                          maxWidth: 300,
                          wordWrap: "break-word",
                          display: "inline-block",
                        }}
                      >
                        {" "}
                        {friendlyFileName}
                      </span>
                    </Button>
                  );
                })
              ) : (
                <></>
              );
              let fileCount = 0;
              if (fileUrl.length > 0) {
                fileCount++;
              }
              if (uploadUrl.length > 0) {
                fileCount++;
              }
              if (
                //@ts-expect-error TS2339
                parsedMultippleFiles.length > 0
              ) {
                fileCount++;
              }
              if (fileCount === 0) {
                return intl.formatMessage({
                  id: "screen.label.none",
                });
              }
              const friendlyFileName =
                row.record &&
                row.record.meta &&
                row.record.meta.friendlyFileName
                  ? row.record.meta.friendlyFileName
                  : "download file";
              const content = (
                <>
                  {fileUrl.length > 0 ? (
                    <Button href={fileUrl} target="_blank" type="link">
                      <span style={{ color: "black" }}></span> {fileUrlName}
                    </Button>
                  ) : null}

                  {uploadUrl.length > 0 ? (
                    <Button
                      onClick={() => {
                        const urlToDisplay = uploadUrl;
                        const href =
                          urlToDisplay && urlToDisplay.length > 0
                            ? urlToDisplay.indexOf("http") === -1
                              ? window.location.protocol + "//" + urlToDisplay
                              : urlToDisplay
                            : "";
                        saveFile(href, friendlyFileName);
                      }}
                      target="_blank"
                      type="link"
                    >
                      <span style={{ color: "black" }}></span>{" "}
                      {friendlyFileName}
                    </Button>
                  ) : null}
                </>
              );
              return (
                <div
                  style={{
                    overflowX: "hidden",
                    overflowY: "scroll",
                  }}
                >
                  {parsedMultippleFiles}
                  {content}
                </div>
              );
            }
          },
          responsive: ["sm"],
        },
        {
          title: "ID",
          dataIndex: "archiveId",
          key: "archiveId",
        },
        {
          title: <a onClick={props.onToggleSplitView}>{IconLeftArrow}</a>,
        },
      ];
    }; //
    const getProjectSubmissionTypes = () => {
      return Array.isArray(props.project?.Batch?.Batch_SubmissionCategory)
        ? // @ts-expect-error TS2551
          Immutable.asMutable(props.project.Batch.Batch_SubmissionCategory, {
            deep: true,
          }).sort(
            (a: Batch_SubmissionCategory, b: Batch_SubmissionCategory) =>
              a.order - b.order,
          )
        : [];
    };

    const getSubmissionsFilterByCategoryProps = (): Pick<
      ColumnType<ProjectArchive>,
      "filterDropdown" | "onFilter"
    > => {
      return {
        filterDropdown: function _fn({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
        }) {
          const projectSubmissionTypes: Batch_SubmissionCategory[] =
            Object.assign([], getProjectSubmissionTypes());
          const emptyString = intl.formatMessage({
            id: "screen.label.empty",
          });
          const lastSubmission: Batch_SubmissionCategory = {
            // @ts-expect-error TS2322
            id: new Date().getTime(),
            // @ts-expect-error TS2741
            submissionCategoryMetadata: {
              submissionCategory: `(${emptyString})`,
            },
          };
          projectSubmissionTypes.push(lastSubmission);
          return (
            <div style={{ padding: 8 }}>
              <Checkbox.Group
                style={{ width: "100%" }}
                value={props.projectTypesSubmissionFilterCheckList}
                onChange={(value) => {
                  handleProjectSubmissionTypesChange(value as string[]);
                }}
              >
                <Row>
                  {projectSubmissionTypes.map(
                    (o: Batch_SubmissionCategory, index: number) => {
                      return (
                        <Col key={o.id} span={8}>
                          <Checkbox
                            value={
                              o?.submissionCategoryMetadata?.submissionCategory
                            }
                          >
                            {o?.submissionCategoryMetadata?.submissionCategory}
                          </Checkbox>
                        </Col>
                      );
                    },
                  )}
                </Row>
              </Checkbox.Group>
              <br />
              <Button
                type="primary"
                onClick={() => {
                  // @ts-expect-error TS2722
                  clearFilters();
                  setSelectedKeys([]);
                  handleProjectSubmissionTypesChange([]);
                }}
                size="small"
                style={{ width: 90, marginRight: 8 }}
              >
                Reset
              </Button>
            </div>
          );
        },
        // @ts-expect-error TS2322
        onFilter: (value, record) => {},
      };
    }; //

    return (
      <Card>
        <Table
          rowClassName={"archive-split-row"}
          pagination={false}
          rowKey={"id"}
          columns={getTableColumns()}
          dataSource={props.archiveList}
          className="ant-table-x-scroll"
        />
      </Card>
    );
  };
ProjectArchiveScreenFullViewComponent.propTypes = {
  // @ts-expect-error TS2322
  projectId: PropTypes.string,
  // @ts-expect-error TS2322
  company: PropTypes.object,
  // @ts-expect-error TS2322
  project: PropTypes.object,
  // @ts-expect-error TS2322
  archiveList: PropTypes.array,
  // @ts-expect-error TS2322
  projectSetting: PropTypes.array,
  // @ts-expect-error TS2322
  dispatchApiRequest: PropTypes.func,
  // @ts-expect-error TS2322
  onToggleSplitView: PropTypes.func,
  selectedFilterArchiveType: PropTypes.any,
  projectTypesSubmissionFilterCheckList: PropTypes.any,
  // @ts-expect-error TS2322
  triggerGetArchives: PropTypes.func,
  // @ts-expect-error TS2322
  onAddToSelectedArchives: PropTypes.func,
  // @ts-expect-error TS2322
  handleProjectSubmissionTypesChange: PropTypes.func,
};

const mapStateToProps = (state: ReduxState) => ({
  project: state.projects.projectPayload,
});
export default connect(
  mapStateToProps,
  null,
)(ProjectArchiveScreenFullViewComponent);
