import { Row } from "antd";
import { arrayMoveImmutable } from "array-move";
import PropsType from "prop-types";
import { useCallback, useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { SortableContainer, SortableElement } from "react-sortable-hoc";

import { ContentContext } from "../../../../Provider";
import { endpoints, parseEndpoint } from "../../../../Services/endpoints";
import { ButtonCV, ButtonSwitch, ButtonUndo } from "../../../atoms";
import CheckPointItem from "../../../molecules/settings/hypoCheckpoint/HypoCheckpoint";
import CheckpointModal from "./CheckpointModal";
import CreateCheckPoint from "./CreateCheckpoint";
type CheckpointItemProps = {
  id: string;
  batchId: string;
  hypothesisFormId: string;
  checkpointFormId: string;
  checkpointFormMetadata: {
    displayCheckpoint: boolean;
    order: number;
  };
  Batches: any;
  HypothesisForms: any;
  CheckPointForms: {
    id: string;
    name: string;
    description: string;
    isGlobal: boolean;
  };
};
type Props = {
  batchId: string;
  hypothesisFormId: string;
};
const CheckPointItems = (props: Props) => {
  const context = useContext(ContentContext);
  const { apiRequest } = context;
  const { batchId, hypothesisFormId } = props;
  const [items, setItems] = useState<Array<CheckpointItemProps>>([]);
  const [sortable, setSortable] = useState<boolean>(false);
  const [showModal, setShowModal] = useState(false);
  const [editItem, setEditItem] = useState<CheckpointItemProps | null>(null);

  useEffect(() => {
    getCheckPoints();
  }, [batchId, hypothesisFormId]);

  useEffect(() => {
    if (editItem) {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, [editItem]);

  const getCheckPoints = useCallback(() => {
    const requestBuilder = {
      method: "getRequest",
      url: parseEndpoint(endpoints.batchHypothesisCheckpoints, {
        batchId,
        hypothesisFormId,
      }),
      data: {},
    };
    // @ts-expect-error TS2345
    apiRequest(requestBuilder, ({ data }) => {
      // @ts-expect-error TS2345
      setItems(data);
    });
  }, [batchId, hypothesisFormId]);

  const createCheckpoint = useCallback(
    (name, description) => {
      const requestBuilder = {
        method: "postRequest",
        url: parseEndpoint(endpoints.batchHypothesisCheckpoints, {
          batchId,
          hypothesisFormId,
        }),
        data: {
          name,
          description,
        },
      };
      // @ts-expect-error TS2345
      apiRequest(requestBuilder, ({ data }) => {
        getCheckPoints();
      });
    },
    [batchId, hypothesisFormId],
  );

  const updateCheckPoint = useCallback(
    (item: CheckpointItemProps, displayCheckpoint) => {
      const metaData = item.checkpointFormMetadata;
      metaData["displayCheckpoint"] = displayCheckpoint;
      const requestBuilder = {
        method: "putRequest",
        url: parseEndpoint(endpoints.batchHypothesisCheckpoints, {
          batchId,
          hypothesisFormId: item.id,
        }),
        data: {
          checkpointFormMetadata: metaData,
        },
      };
      // @ts-expect-error TS2345
      apiRequest(requestBuilder, ({ data }) => {
        getCheckPoints();
      });
    },
    [batchId],
  );

  const updateCheckpointNameDes = useCallback(
    (name: string, description: string) => {
      if (!editItem) {
        return;
      }
      const requestBuilder = {
        method: "putRequest",
        url: parseEndpoint(endpoints.checkpointForms, {
          checkpointFormId: editItem.CheckPointForms.id,
        }),
        data: {
          name,
          description,
        },
      };
      apiRequest(
        // @ts-expect-error TS2345
        requestBuilder,
        ({ data }) => {
          setEditItem(null);
          getCheckPoints();
        },
        () => {
          setEditItem(null);
        },
      );
    },
    [batchId, editItem],
  );

  const asyncApiRequst = (id: string, data: any) => {
    return new Promise((resolve, reject) => {
      const requestBuilder = {
        method: "putRequest",
        url: parseEndpoint(endpoints.batchHypothesisCheckpoints, {
          batchId,
          hypothesisFormId: id,
        }),
        data: data,
      };
      apiRequest(
        // @ts-expect-error TS2345
        requestBuilder,
        ({ data }) => {
          resolve(data);
        },
        (error) => {
          reject(error);
        },
      );
    });
  };

  const updateCheckPointOrder = useCallback(async () => {
    const promise = [];
    for (const index in items) {
      const item = items[index];
      const metaData = item.checkpointFormMetadata;
      metaData["order"] = Number(index);
      promise.push(
        asyncApiRequst(item.id, {
          checkpointFormMetadata: metaData,
        }),
      );
    }
    await Promise.all(promise);
    getCheckPoints();
    setSortable(false);
  }, [items]);

  const SortItem = SortableElement(
    ({ item, sortable }: { item: CheckpointItemProps; sortable: boolean }) => (
      <li>
        <CheckPointItem
          key={item.id}
          isSortable={sortable}
          item={item.CheckPointForms}
          display={item.checkpointFormMetadata.displayCheckpoint}
          onChangeDisplay={(checked) => {
            updateCheckPoint(item, checked);
          }}
          onUpdateCheckpoint={() => {
            if (!item.CheckPointForms.isGlobal) {
              setEditItem(item);
            }
          }}
        />
      </li>
    ),
  );

  const CheckPointList = SortableContainer(
    ({
      items,
      sortable,
    }: {
      items: Array<CheckpointItemProps>;
      sortable: boolean;
    }) => (
      <ul
        style={{
          position: "relative",
          zIndex: 0,
          outline: "none",
          listStyle: "none",
          padding: 0,
        }}
      >
        {items.map((item: CheckpointItemProps, index: number) => {
          return (
            <SortItem
              key={index}
              index={index}
              sortable={sortable}
              disabled={!sortable}
              item={item}
            />
          );
        })}
      </ul>
    ),
  );

  return (
    <>
      <Row>
        <h4>
          <FormattedMessage id="screen.label.checkpoint_setting" />
        </h4>
      </Row>
      {items.length > 0 && (
        <Row align="middle">
          <span>
            <FormattedMessage id="screen.label.sort_items" />
          </span>
          <ButtonSwitch
            className="offset-left-16"
            onChange={(checked) => {
              setSortable(checked);
              if (!checked) {
                getCheckPoints();
              }
            }}
            checked={sortable}
          />
          {sortable && (
            <>
              <ButtonCV
                className="offset-left-16"
                onClick={() => {
                  updateCheckPointOrder();
                }}
              >
                <FormattedMessage id="screen.label.save" />
              </ButtonCV>
              <ButtonUndo
                className="offset-left-16"
                onClick={() => {
                  getCheckPoints();
                  setSortable(false);
                }}
              >
                <FormattedMessage id="screen.label.cancel" />
              </ButtonUndo>
            </>
          )}
        </Row>
      )}
      {items && items.length > 0 && (
        <>
          <br />
          <CheckPointList
            items={items}
            sortable={sortable}
            helperClass={"SortableHelper"}
            onSortEnd={({ oldIndex, newIndex }) => {
              const orderedItems = arrayMoveImmutable(
                items,
                oldIndex,
                newIndex,
              ).map((m, index) => {
                return {
                  ...m,
                  index,
                };
              });
              setItems(orderedItems);
            }}
          />
        </>
      )}

      <br />

      <CreateCheckPoint onCreateCheckpoint={createCheckpoint} />
      <CheckpointModal
        show={showModal}
        name={
          editItem && editItem.CheckPointForms
            ? editItem.CheckPointForms.name
            : ""
        }
        description={
          editItem && editItem.CheckPointForms
            ? editItem.CheckPointForms.description
            : ""
        }
        onUpdate={(name, description) => {
          updateCheckpointNameDes(name, description);
        }}
        onHide={() => {
          setEditItem(null);
        }}
      />
    </>
  );
};

CheckPointItems.propTypes = {
  batchId: PropsType.string,
  hypothesisFormId: PropsType.string,
};

export default CheckPointItems;
