export const batchStatus = [
  {
    key: 0,
    value: "batch_status_not_implemented",
  },
  {
    key: 1,
    value: "batch_status_progress",
  },
  {
    key: 2,
    value: "batch_status_ended",
  },
  {
    key: 3,
    value: "batch_status_progress_but_no_new_project",
  },
];
