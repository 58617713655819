import { Action } from "@reduxjs/toolkit";
import { createActions, createReducer } from "reduxsauce";
import Immutable from "seamless-immutable";

import { ToAction, ToActionType } from "./types";

/* ------------- Types and Action Creators ------------- */
type TeamActionCreators = {
  teamCreateRequest: () => Action<"TEAM_CREATE_REQUEST">;
  teamCreateSuccess: () => Action<"TEAM_CREATE_SUCCESS">;
  teamCreateFailure: () => Action<"TEAM_CREATE_FAILURE">;

  teamGetRequest: () => Action<"TEAM_GET_REQUEST">;
  teamGetSuccess: () => Action<"TEAM_GET_SUCCESS">;
  teamGetFailure: () => Action<"TEAM_GET_FAILURE">;

  teamPendingGetRequest: () => Action<"TEAM_PENDING_GET_REQUEST">;
  teamPendingGetSuccess: () => Action<"TEAM_PENDING_GET_SUCCESS">;
  teamPendingGetFailure: () => Action<"TEAM_PENDING_GET_FAILURE">;
};
type TeamAction = ToAction<TeamActionCreators>;
type TeamActionType = ToActionType<TeamAction>;

const { Types, Creators } = createActions<TeamActionType, TeamActionCreators>({
  teamUpdateRequest: ["data"],

  teamCreateRequest: ["data", "message"],
  teamCreateSuccess: null,
  teamCreateFailure: ["errors"],

  teamGetRequest: ["data"],
  teamGetSuccess: ["teamMembersPayload"],
  teamGetFailure: ["errors"],

  teamPendingGetRequest: ["data"],
  teamPendingGetSuccess: ["teamPendingMembersPayload"],
  teamPendingGetFailure: ["errors"],
});

export const TeamTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
type TeamState = {
  fetching: boolean;
  errors: unknown;
  error?: unknown;

  teamMembersPayload: {
    rows: unknown[];
    count: number;
  };
  teamPendingMembersPayload: {
    rows: unknown[];
    count: number;
  };
};

export const INITIAL_STATE = Immutable<TeamState>({
  fetching: false,
  errors: {},

  teamMembersPayload: {
    rows: [],
    count: 0,
  },
  teamPendingMembersPayload: {
    rows: [],
    count: 0,
  },
});

type ImmTeamState = typeof INITIAL_STATE;

/* ------------- Reducers ------------- */

const teamCreateRequest = (state: ImmTeamState) =>
  state.merge({ fetching: true });

const teamCreateSuccess = (state: ImmTeamState) => {
  return state.merge({ fetching: false });
};

// @ts-expect-error TS7031
const teamCreateFailure = (state: ImmTeamState, { error }) =>
  state.merge({ fetching: false, error });

const teamMembersGetRequest = (state: ImmTeamState) =>
  state.merge({ fetching: true });

// @ts-expect-error TS7031
const teamMembersGetSuccess = (state: ImmTeamState, { teamMembersPayload }) => {
  return state.merge({ fetching: false, teamMembersPayload });
};

// @ts-expect-error TS7031
const teamMembersGetFailure = (state: ImmTeamState, { error }) =>
  state.merge({ fetching: false, error });

const teamMembersPendingGetRequest = (state: ImmTeamState) =>
  state.merge({ fetching: true });

const teamMembersPendingGetSuccess = (
  state: ImmTeamState,
  // @ts-expect-error TS7031
  { teamPendingMembersPayload },
) => {
  return state.merge({ fetching: false, teamPendingMembersPayload });
};

// @ts-expect-error TS7031
const teamMembersPendingGetFailure = (state: ImmTeamState, { error }) =>
  state.merge({ fetching: false, error });

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer<ImmTeamState, TeamAction>(INITIAL_STATE, {
  [Types.TEAM_CREATE_REQUEST]: teamCreateRequest,
  [Types.TEAM_CREATE_SUCCESS]: teamCreateSuccess,
  [Types.TEAM_CREATE_FAILURE]: teamCreateFailure,

  [Types.TEAM_GET_REQUEST]: teamMembersGetRequest,
  [Types.TEAM_GET_SUCCESS]: teamMembersGetSuccess,
  [Types.TEAM_GET_FAILURE]: teamMembersGetFailure,

  [Types.TEAM_PENDING_GET_REQUEST]: teamMembersPendingGetRequest,
  [Types.TEAM_PENDING_GET_SUCCESS]: teamMembersPendingGetSuccess,
  [Types.TEAM_PENDING_GET_FAILURE]: teamMembersPendingGetFailure,
});
