import {
  Card,
  Col,
  Form,
  Modal,
  notification,
  Row,
  Select,
  Table,
  Upload,
} from "antd";
import PropTypes from "prop-types";
import { FC, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { connect } from "react-redux";

import DeleteIcon from "../../assets/icons/discard.svg";
import UploadSVG from "../../assets/icons/upload.svg";
import {
  ButtonCV,
  ButtonOption,
  ButtonUndo,
  IconButton,
} from "../../Components/atoms";
import { Input, Select as Dropdown } from "../../Components/molecules";
import { MemberAddCol } from "../../Components/organisms";
import UserActions from "../../Redux/UserRedux";
import { endpoints, parseEndpoint } from "../../Services/endpoints";
import {
  adminUser,
  generalUser,
  mentorUser,
  programManager,
} from "../../utils/constants";
import { IMemberAddProps } from "./types/IMemberAddProps";

const { Option } = Select;
const { Dragger } = Upload;

const MemberAdd: FC<IMemberAddProps> = function (props: IMemberAddProps) {
  const intl = useIntl();
  const { user, apiRequest, updatePageTitle } = props.context;
  const [state, customSetState] = useState(() => {
    return {
      visible: false,
      users: [
        {
          email: "",
          userType: "employee",
        },
      ],
      usersImported: [],
      usersErrors: [],
      previewModal: false,
      errorModal: false,
    };
  });
  const setStateCallbackRef = useRef(() => {});
  useEffect(() => {
    const callBack = setStateCallbackRef.current;
    callBack();
    setStateCallbackRef.current = () => {};
  }, [state]);
  const setState = (data: any, callback = () => {}) => {
    setStateCallbackRef.current = callback;
    customSetState((previousState) => {
      return {
        ...previousState,
        ...data,
      };
    });
  };

  useEffect(() => {
    updatePageTitle("screen.label.user");
  }, []);

  const showModal = () => {
    setState({
      visible: true,
    });
  };
  // @ts-expect-error TS7006
  const handleOk = (e) => {
    setState(
      {
        visible: false,
      },
      () => {
        const { usersImported: users } = state;
        const data = users.map(
          (
            {
              name,
              subsidiary,
              email,
              role,
              type,
              personalBio,
              department,
              workHistorySummary,
              subEmails,
            },
            index,
          ) => {
            return {
              email: `${email}`.trim(),
              type,
              isSuper: false,
              name,
              role,
              personalBio,
              department,
              workHistorySummary,
              subsidiary,
              subEmails,
            };
          },
        );
        onCreateUsers(data);
      },
    );
  };

  // @ts-expect-error TS7006
  const onCreateUsers = (data) => {
    const message = intl.formatMessage({
      id: "screen.label.members_have_been_added",
    });
    const requestBuilder = {
      method: "postRequest",
      url: parseEndpoint(endpoints.createUsers),
      data,
    };
    // @ts-expect-error TS2345
    apiRequest(requestBuilder, ({ data }) => {
      if (data && Array.isArray(data) && data.length > 0) {
        setState({
          errorModal: true,
          usersErrors: data,
        });
      } else {
        notification.success({
          message,
        });
      }
    });
  };
  // @ts-expect-error TS7006
  const handleCancel = (e) => {
    setState({
      visible: false,
    });
  };
  // @ts-expect-error TS7006
  const handleChange = (key, index, value) => {
    const { users } = state;
    const newUsers = users.map((user, ii) => {
      if (index === ii) {
        return {
          ...user,
          [key]: value,
        };
      }
      return user;
    });
    setState({ users: newUsers });
  };
  // @ts-expect-error TS7006
  const submitCreateUser = (e) => {
    e.preventDefault();
    const { users } = state;
    const data = users.map(({ email, userType }, index) => {
      let type = "";
      let isSuper = false;
      if (userType === "isSuper") {
        type = "admin";
        isSuper = true;
      } else if (userType === "admin") {
        type = "admin";
      } else if (userType === "employee") {
        type = "employee";
      } else if (userType === "mentor") {
        type = "mentor";
      }
      return {
        email: `${email}`.trim(),
        type,
        isSuper,
      };
    });
    onCreateUsers(data);
  };
  // @ts-expect-error TS7006
  const _renderUserMemberDropDown = (userItem, index) => {
    if (user.type === "admin" && user.isSuper) {
      return (
        <Dropdown
          onChange={(value) => handleChange("userType", index, value)}
          defaultValue={userItem.userType}
          style={{
            minWidth: "10vw",
          }}
        >
          <Option value="isSuper">{adminUser}</Option>
          <Option value="admin">{programManager}</Option>
          <Option value="employee">{generalUser}</Option>
          <Option value="mentor">{mentorUser}</Option>
        </Dropdown>
      );
    }
    if (user.type === "admin") {
      return (
        <Dropdown
          onChange={(value) => handleChange("userType", index, value)}
          defaultValue={userItem.userType}
          style={{
            minWidth: "10vw",
          }}
        >
          <Option value="admin">{programManager}</Option>
          <Option value="employee">{generalUser}</Option>
          <Option value="mentor">{mentorUser}</Option>
        </Dropdown>
      );
    }
    return <></>;
  };
  // @ts-expect-error TS7006
  const _renderSingleUser = (user, index) => {
    return (
      <Row key={index} style={{ marginBottom: 16 }}>
        <Col span={12}>
          <Input
            required
            value={user.email}
            onChange={(event) =>
              handleChange("email", index, `${event.target.value}`.trim())
            }
          />
        </Col>
        <Col span={6} style={{ marginLeft: 15 }}>
          {_renderUserMemberDropDown(user, index)}
        </Col>
        <Col style={{ marginLeft: 15 }}>
          {state.users.length > 1 ? (
            <IconButton
              // @ts-expect-error TS2322
              iconSvg={DeleteIcon}
              onClick={() => onRemoveUser(index)}
            />
          ) : null}
        </Col>
      </Row>
    );
  };
  // @ts-expect-error TS7006
  const onRemoveUser = (index) => {
    const users = state.users.filter((user, ii) => ii !== index);
    setState({ users });
  };
  const onAddExtraUser = () => {
    const { users } = state;
    users.push({
      userType: "employee",
      email: "",
    });
    setState({ users });
  };
  // @ts-expect-error TS7006
  const handleFiles = (file, encoding) => {
    if (window.FileReader) {
      getAsText(file, encoding);
    } else {
      alert("FileReader are not supported in this browser.");
    }
  };
  // @ts-expect-error TS7006
  const getAsText = (fileToRead, encoding) => {
    const reader = new FileReader();
    reader.readAsText(fileToRead, encoding);
    reader.onload = loadHandler;
    reader.onerror = errorHandler;
  };
  // @ts-expect-error TS7006
  const loadHandler = (event) => {
    const csv = event.target.result;
    processData(csv);
  };
  // @ts-expect-error TS7006
  const errorHandler = (evt) => {
    if (evt.target.error.name === "NotReadableError") {
      alert("Can not read file !");
    }
  };

  const _rendererrors = () => {
    if (Array.isArray(state.usersErrors) && state.usersErrors.length > 0) {
      return (
        <Table
          tableLayout={"auto"}
          pagination={false}
          rowKey={"email"}
          columns={getErrorColums()}
          dataSource={state.usersErrors}
        />
      );
    }
  };

  const getErrorColums = () => {
    return [
      {
        title: "#",
        key: "index",
        // @ts-expect-error TS7006
        render: function _fn(text, record, index) {
          return <MemberAddCol>{index}</MemberAddCol>;
        },
      },
      {
        title: intl.formatMessage({
          id: "screen.label.user_name",
        }),
        dataIndex: "name",
        key: "name",
        // @ts-expect-error TS7006
        render: function _fn(text, record) {
          if (text) {
            return <p>{text}</p>;
          } else if (record.email && record.email.includes("@")) {
            return <p>{record.email.split("@")[0]}</p>;
          } else {
            return <p>{text}</p>;
          }
        },
      },
      {
        title: intl.formatMessage({
          id: "screens.title.email",
        }),
        dataIndex: "email",
        key: "email",
        // @ts-expect-error TS7006
        render: function _fn(text) {
          return <p>{text}</p>;
        },
      },
      {
        title: intl.formatMessage({
          id: "screen.label.cause",
        }),
        dataIndex: "isValid",
        key: "isValid",
        // @ts-expect-error TS7006
        render: function _fn(text, record, index) {
          if (record.isValid) {
            return (
              <div>
                {intl.formatMessage({
                  id: "screen.label.already_exist_user_msg",
                })}
              </div>
            );
          } else {
            let errorStrings = ``;
            if (
              record.validErrors &&
              Array.isArray(record.validErrors) &&
              record.validErrors.length > 0
            ) {
              const errorTypes = record.validErrors.map(
                (item: any) => item.type,
              );
              errorStrings = `[${errorTypes.join(" , ")}]`;
            }
            return (
              <div
                style={{
                  whiteSpace: "pre-line",
                  maxWidth: "40vw",
                }}
              >{`${intl.formatMessage({
                id: "screen.label.validation_error_des",
              })} ${errorStrings}`}</div>
            );
          }
        },
      },
    ];
  };

  const getPreviewColumns = () => {
    return [
      {
        title: intl.formatMessage({
          id: "screen.label.user_name",
        }),
        dataIndex: "name",
        key: "name",
        // @ts-expect-error TS7006
        render: function _fn(text) {
          return <MemberAddCol>{text}</MemberAddCol>;
        },
      },
      {
        title: intl.formatMessage({
          id: "screens.title.email",
        }),
        dataIndex: "email",
        key: "email",
        // @ts-expect-error TS7006
        render: function _fn(text) {
          return <MemberAddCol>{text}</MemberAddCol>;
        },
      },
      {
        title: intl.formatMessage({
          id: "screen.label.user_type",
        }),
        dataIndex: "type",
        key: "type",
        // @ts-expect-error TS7006
        render: function _fn(text) {
          return <MemberAddCol>{text}</MemberAddCol>;
        },
      },
      {
        title: intl.formatMessage({
          id: "screen.label.affiliation_companies",
        }),
        dataIndex: "subsidiary",
        key: "subsidiary",
        // @ts-expect-error TS7006
        render: function _fn(text) {
          return <MemberAddCol>{text}</MemberAddCol>;
        },
        responsive: ["xl"],
      },
      {
        title: intl.formatMessage({
          id: "screen.label.position",
        }),
        dataIndex: "role",
        key: "role",
        // @ts-expect-error TS7006
        render: function _fn(text) {
          return <MemberAddCol>{text}</MemberAddCol>;
        },
        responsive: ["xl"],
      },
      {
        title: intl.formatMessage({
          id: "screen.label.affiliation_department",
        }),
        dataIndex: "department",
        key: "department",
        // @ts-expect-error TS7006
        render: function _fn(text) {
          return <MemberAddCol>{text}</MemberAddCol>;
        },
        responsive: ["xl"],
      },
      {
        title: intl.formatMessage({
          id: "screen.label.main_work_experience",
        }),
        dataIndex: "workHistorySummary",
        key: "workHistorySummary",
        // @ts-expect-error TS7006
        render: function _fn(text) {
          return <MemberAddCol>{text}</MemberAddCol>;
        },
        responsive: ["xl"],
      },
      {
        title: intl.formatMessage({
          id: "screen.label.profile",
        }),
        dataIndex: "personalBio",
        key: "personalBio",
        // @ts-expect-error TS7006
        render: function _fn(text) {
          return <MemberAddCol>{text}</MemberAddCol>;
        },
        responsive: ["xl"],
      },
      {
        title: intl.formatMessage({
          id: "screen.label.sub_email",
        }),
        dataIndex: "subEmails",
        key: "subEmails",
        // @ts-expect-error TS7006
        render: function _fn(text, row) {
          return <MemberAddCol>{row.subEmails.join(", ")}</MemberAddCol>;
        },
      },
    ];
  };
  const draggerProps = {
    name: "file",
    multiple: false,
    accept: ".csv",
    action: `${process.env.REACT_APP_BASEHOST}companies/${props.companyId}/users/get-encoding`,
    headers: {
      Authorization: `Bearer ${props.authToken}`,
      alphafrontEndPath: window.location.href,
    },

    // @ts-expect-error TS7006
    onChange: (info) => {
      const { file } = info;
      if (file.response) {
        const encoding = file.response.data;
        handleFiles(file.originFileObj, encoding);
      }
    },
    // @ts-expect-error TS7006
    beforeUpload: (file) => {},
    onRemove: () => {
      setState({
        usersImported: [],
      });
    },
  };
  // @ts-expect-error TS7006
  const processData = (csv) => {
    const allTextLines = csv.split(/\r\n|\n/);
    const lines = [];
    for (let i = 1; i < allTextLines.length; i++) {
      // started from 1 because first element is csv titles
      const singleDataRow = allTextLines[i].split(",");
      if (singleDataRow.length > 1) {
        lines.push({
          name: singleDataRow[0],
          email: singleDataRow[1],
          type: singleDataRow[2],
          subsidiary: singleDataRow[3],
          role: singleDataRow[4],
          department: singleDataRow[5],
          workHistorySummary: singleDataRow[6],
          personalBio: singleDataRow[7],
          subEmails: getSubEmails(singleDataRow[8]),
        });
      }
    }
    setState({
      usersImported: lines,
    });
  };

  const getSubEmails = (subEmailsStr: string) => {
    let subEmails: string[] = [];
    if (subEmailsStr && subEmailsStr !== "") {
      subEmails = subEmailsStr.trim().split("|");
      subEmails = subEmails.map((email) => email.trim());
    }
    return subEmails;
  };

  let imported = <></>;
  if (state.usersImported.length > 0) {
    imported = (
      <Row>
        <Col span={20}>
          <p>
            Imported <b>{state.usersImported.length}</b> users
          </p>
        </Col>
        <Col span={4}>
          <ButtonCV onClick={() => setState({ previewModal: true })}>
            {intl.formatMessage({
              id: "screen.label.check_contents",
            })}
          </ButtonCV>
        </Col>
      </Row>
    );
  }
  return (
    <>
      <Modal
        title={intl.formatMessage({
          id: "screen.label.check_contents",
        })}
        visible={state.previewModal}
        width={"95vw"}
        footer={null}
        zIndex={2000}
        onCancel={() => {
          setState({ previewModal: false });
        }}
      >
        <Table
          tableLayout={"auto"}
          pagination={false}
          rowKey={"email"}
          // @ts-expect-error TS2322
          columns={getPreviewColumns()}
          dataSource={state.usersImported}
        />
      </Modal>
      <Modal
        title={intl.formatMessage({
          id: "screen.label.register_user_failed_title",
        })}
        visible={state.errorModal}
        width={"80vw"}
        footer={null}
        onCancel={() => {
          setState({ errorModal: false });
        }}
      >
        <br />
        <p>
          {intl.formatMessage({
            id: "screen.label.register_user_failed_des",
          })}
        </p>
        <p>
          {intl.formatMessage({
            id: "screen.label.register_user_failed_cause_des",
          })}
        </p>
        <br />
        {_rendererrors()}
      </Modal>

      <br />
      <Row align="middle" justify="space-between">
        <h2>
          {intl.formatMessage({
            id: "screen.lable.add_user",
          })}
        </h2>
        <ButtonOption onClick={showModal}>
          +{" "}
          {intl.formatMessage({
            id: "screen.label.csv_shelf_registration",
          })}
        </ButtonOption>
      </Row>
      <br />
      <Card>
        <Col span={12}>
          <Row>
            <Col span={12}>
              <Form.Item
                colon={false}
                label={intl.formatMessage({
                  id: "screens.title.email",
                })}
              />
            </Col>
            <Col span={6} style={{ marginLeft: 15 }}>
              <Form.Item
                colon={false}
                label={intl.formatMessage({
                  id: "screen.label.user_type",
                })}
              />
            </Col>
          </Row>
          <Form>
            {state.users.map((user, index) => {
              return _renderSingleUser(user, index);
            })}
          </Form>
        </Col>
        <ButtonOption onClick={onAddExtraUser}>
          +{" "}
          {intl.formatMessage({
            id: "screen.label.additional_invited_user",
          })}
        </ButtonOption>
        <Row>
          <Col xs={24} sm={12} md={8} lg={6}>
            <ButtonCV onClick={submitCreateUser} style={{ marginTop: 20 }}>
              {intl.formatMessage({
                id: "screen.label.invite",
              })}
            </ButtonCV>
          </Col>
        </Row>
      </Card>

      <Modal
        destroyOnClose
        title="CSV"
        style={{ minWidth: "40vw" }}
        centered
        visible={state.visible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={
          <Col span={16} offset={4}>
            <Row align="middle" justify="space-between">
              <ButtonOption href={"/sample_users-csv.csv"}>
                {intl.formatMessage({
                  id: "screen.label.sample_csv_download",
                })}
              </ButtonOption>
              <ButtonUndo onClick={handleCancel}>
                {intl.formatMessage({
                  id: "screen.label.cancel",
                })}
              </ButtonUndo>
              <ButtonCV onClick={handleOk}>
                {intl.formatMessage({
                  id: "screen.label.add",
                })}
              </ButtonCV>
            </Row>
          </Col>
        }
      >
        <Col span={16} offset={4}>
          <Dragger {...draggerProps}>
            <p className="ant-upload-drag-icon">
              <img src={UploadSVG}></img>
            </p>
            <p className="ant-upload-text">
              {intl.formatMessage({
                id: "screen.label.add_csv_file_with_drag_and_drop",
              })}
            </p>
            <p className="ant-upload-hint">
              {intl.formatMessage({
                id: "screen.label.required_item",
              })}
              : email, type (‘employee’, ‘admin’, ‘mentor’), subsidiary, role,
              department, work history, Bio, full name, subEmails
              (email1|email2|email3)
            </p>
          </Dragger>
          {imported}
        </Col>
      </Modal>
    </>
  );
};

// @ts-expect-error TS7006
const mapStateToProps = (state) => ({
  user: state.user,
  companyId: state.company.companyPayload.id,
  authToken: state.auth.payload.token,
});
// @ts-expect-error TS7006
const mapDispatchToProps = (dispatch) => ({
  // @ts-expect-error TS7006
  dispatchCreateUser: (data, message) =>
    // @ts-expect-error TS2554
    dispatch(UserActions.userCreateProfileRequest(data, message)),
});

MemberAdd.propTypes = {
  // @ts-expect-error TS2322
  context: PropTypes.object,
  user: PropTypes.object,
  match: PropTypes.object,
  // @ts-expect-error TS2322
  dispatchCreateUser: PropTypes.func,
};
export default connect(mapStateToProps, mapDispatchToProps)(MemberAdd);
