import { PropsWithChildren } from "react";

import styles from "@/shared/buttons/components/SecondaryButton/styles.module.scss";
import { SecondaryButtonProps } from "@/shared/buttons/components/SecondaryButton/type";

export const SecondaryButton = ({
  children,
  className,
  ...props
}: PropsWithChildren<SecondaryButtonProps>) => {
  return (
    <button {...props} className={`${styles.button} ${className}`}>
      {children}
    </button>
  );
};
