import { useEffect } from "react";

import useLocalStorage from "../../../utils/localStorage";

// @ts-expect-error TS7006
const useCustomPagination = (props) => {
  const {
    saveId,
    initLimit,
    initPage,
    count,
    showLessItems = false,
    showPagination = true,
    containerStyle,
    onChangePage,
  } = props;
  const pageKey = `pagination_page_${saveId}`;
  const limitKey = `pagination_limit_${saveId}`;
  const [page, setPage] = useLocalStorage(pageKey, 1);
  const [limit, setLimit] = useLocalStorage(limitKey, 10);
  const removePageAndLimit = () => {
    const keys = Object.keys(localStorage);
    if (keys && Array.isArray(keys) && keys.length > 0) {
      const paginationKeys = keys.filter((a) => a.includes("pagination"));
      for (const removableKey of paginationKeys) {
        localStorage.removeItem(removableKey);
      }
    }
  };
  useEffect(() => {
    if (initPage && initPage !== page) {
      setPage(initPage);
    }
    if (initLimit && initLimit !== limit) {
      setLimit(initLimit);
    }
  }, [initLimit, initPage]);

  return {
    saveId,
    initLimit,
    initPage,
    count,
    showLessItems,
    showPagination,
    containerStyle,
    onChangePage,
    pageKey,
    limitKey,
    page,
    setPage,
    setLimit,
    limit,
    removePageAndLimit,
  };
};

export default useCustomPagination;
