import { notification } from "antd";
import { all, call, put, takeLatest } from "redux-saga/effects";

import IndustrtActions, { IndustryTypes } from "../Redux/IndustryRedux";
import { endpoints, parseEndpoint } from "../Services/endpoints";
import createFormData from "../utils/createFormData";

// @ts-expect-error TS7006
function* attemptGetIndustryReports(api, action) {
  const { data } = action;
  const {
    page = 0,
    limit = process.env.REACT_APP_PAGE_SIZE,
    filter = {},
  } = data;
  const {
    title = "",
    theme = "",
    summary = "",
    sortModel = "IndustryReport",
    sortOrder = "DESC",
  } = filter;

  const filterObject = {
    IndustryReport: {
      title,
      theme,
      summary,
    },
    IndustryReportMainCategory: {
      name: title,
    },
    IndustryReportSubCategory: {
      name: title,
    },
    sortModel,
    sortOrder,
  };

  // @ts-expect-error TS7057
  const industryResponse = yield call(
    api.postRequest,
    parseEndpoint(endpoints.advanceSearchIndustryReport, { page, limit }),
    filterObject,
  );

  if (industryResponse.ok) {
    switch (industryResponse.data.status) {
      case "success":
        yield put(
          // @ts-expect-error TS2554
          IndustrtActions.industryGetSuccess(industryResponse.data.data),
        );
        break;
      case "fail":
      case "error":
        yield put(
          // @ts-expect-error TS2554
          IndustrtActions.industryGetFailure(industryResponse.data.data),
        );
        break;
      default:
        break;
    }
  } else {
    notification.error({
      message: industryResponse.problem,
    });
    // @ts-expect-error TS2554
    yield put(IndustrtActions.industryGetFailure(industryResponse.problem));
    if (industryResponse.status === 401) {
      // @ts-expect-error TS2322
      window.location = "/login";
    }
  }
}

// @ts-expect-error TS7006
function* attemptUpdateIndustryReport(api, action) {
  const { data } = action;

  const { id, industryReport, ...body } = data;

  if (industryReport) {
    // @ts-expect-error TS7057
    const fileUploadResponse = yield call(
      api.postRequest,
      parseEndpoint(endpoints.uploadIndustryReportFile),
      createFormData({ industryReport }),
    );
    if (fileUploadResponse.ok) {
      const industryReportFilesUrl = fileUploadResponse.data.data;
      body.industryReport = industryReportFilesUrl;
    }
  }

  // @ts-expect-error TS7057
  const industryResponse = yield call(
    api.putRequest,
    parseEndpoint(endpoints.updateIndustryReport, { id }),
    body,
  );
  if (industryResponse.ok) {
    switch (industryResponse.data.status) {
      case "success":
        yield put(IndustrtActions.industryUpdateSuccess());
        window.history.back();
        break;
      case "fail":
      case "error":
        if (typeof industryResponse.data.data === "string") {
        }
        yield put(
          // @ts-expect-error TS2554
          IndustrtActions.industryUpdateFailure(industryResponse.data.data),
        );
        break;
      default:
        break;
    }
  } else {
    notification.error({
      message: industryResponse.problem,
    });
    // @ts-expect-error TS2554
    yield put(IndustrtActions.industryUpdateFailure(industryResponse.problem));
    if (industryResponse.status === 401) {
      // @ts-expect-error TS2322
      window.location = "/login";
    }
  }
}

// @ts-expect-error TS7006
function* attemptCreateIndustryReport(api, action) {
  const { data, message } = action;

  const { industryReport, ...rest } = data;

  if (industryReport) {
    // @ts-expect-error TS7057
    const fileUploadResponse = yield call(
      api.postRequest,
      parseEndpoint(endpoints.uploadIndustryReportFile),
      createFormData({ industryReport }),
    );
    if (fileUploadResponse.ok) {
      const industryReportFilesUrl = fileUploadResponse.data.data;

      // @ts-expect-error TS7057
      const industryResponse = yield call(
        api.postRequest,
        parseEndpoint(endpoints.createIndustryReport),
        {
          ...rest,
          industryReportFiles: industryReportFilesUrl,
        },
      );
      if (industryResponse.ok) {
        switch (industryResponse.data.status) {
          case "success":
            if (
              Array.isArray(industryResponse.data.data) &&
              industryResponse.data.data.length > 0
            ) {
              yield put(
                IndustrtActions.industryCreateFailure(
                  // @ts-expect-error TS2554
                  industryResponse.data.data,
                ),
              );
            } else {
              notification.success({
                message: message,
              });
            }
            break;
          case "fail":
          case "error":
            if (typeof industryResponse.data.data === "string") {
            }
            yield put(
              // @ts-expect-error TS2554
              IndustrtActions.industryCreateFailure(industryResponse.data.data),
            );
            break;
          default:
            break;
        }
      } else {
        notification.error({
          message: industryResponse.problem,
        });
        yield put(
          // @ts-expect-error TS2554
          IndustrtActions.industryCreateFailure(industryResponse.problem),
        );
        if (industryResponse.status === 401) {
          // @ts-expect-error TS2322
          window.location = "/login";
        }
      }
    }
  }
}

// @ts-expect-error TS7006
function* attemptDeleteIndustryReport(api, action) {
  const { data, message } = action;
  // @ts-expect-error TS7057
  const industryResponse = yield call(
    api.deleteRequest,
    parseEndpoint(endpoints.deleteIndustryReport, { data }),
  );

  if (industryResponse.ok) {
    switch (industryResponse.data.status) {
      case "success":
        notification.success({
          message: message,
        });
        break;
      case "fail":
      case "error":
        if (typeof industryResponse.data.data === "string") {
        }
        yield put(
          // @ts-expect-error TS2554
          IndustrtActions.industryDeleteFailure(industryResponse.data.data),
        );
        break;
      default:
        break;
    }
  } else {
    // @ts-expect-error TS2554
    yield put(IndustrtActions.industryDeleteFailure(industryResponse.problem));
    if (industryResponse.status === 401) {
      // @ts-expect-error TS2322
      window.location = "/login";
    }
  }
}

// @ts-expect-error TS7006
function* IndustryReportSaga(api) {
  yield all([
    takeLatest(
      IndustryTypes.INDUSTRY_GET_REQUEST,
      attemptGetIndustryReports,
      api,
    ),
    takeLatest(
      IndustryTypes.INDUSTRY_UPDATE_REQUEST,
      attemptUpdateIndustryReport,
      api,
    ),
    takeLatest(
      IndustryTypes.INDUSTRY_CREATE_REQUEST,
      attemptCreateIndustryReport,
      api,
    ),
    takeLatest(
      IndustryTypes.INDUSTRY_DELETE_REQUEST,
      attemptDeleteIndustryReport,
      api,
    ),
  ]);
}
export default IndustryReportSaga;
