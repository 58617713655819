import { Button } from "antd";
import { BsFillFlagFill } from "react-icons/bs";

import styles from "./styles.module.scss";
import { ExaminationPhaseUpdateActiveButtonProps } from "./type";

export const ExaminationPhaseUpdateActiveButton = ({
  isActive,
  onUpdateActive,
}: ExaminationPhaseUpdateActiveButtonProps) => {
  return (
    <div className={styles.container}>
      {isActive ? (
        <div className={styles.flag_icon_container}>
          <BsFillFlagFill className={`${styles.flag_icon} ${styles.active}`} />
        </div>
      ) : (
        <Button onClick={onUpdateActive} className={styles.switch_btn}>
          <BsFillFlagFill
            className={`${styles.flag_icon} ${styles.inactive}`}
          />
        </Button>
      )}
    </div>
  );
};
