import { useCallback, useState } from "react";

import {
  HandleMyProjectctsAdvanceFilter,
  ProjectsAdvanceFilterParam,
} from "@/Screens/project/types/IMyProjectListComponentProps";

import { Batch } from "../../../types/Batch";
import { JsendError } from "../../../types/JSendObject";
import { Project } from "../../../types/Project";
import { Team } from "../../../types/Team";
import { api } from "../../Saga";
import { endpoints, parseEndpoint } from "../endpoints";
import { GetProjectsResponse, PostProjectsData } from "./types";

/** @deprecated ランタイムに合わせるための暫定措置 */
export type Project2 = Omit<Project, "Team" | "Batch"> & {
  team: Team;
  batch: Batch;
};
const useMyProjectsRequest = (userId: string) => {
  const [myProjects, setMyProjects] = useState<Project2[]>([]);
  const [myProjectsCount, setMyProjectsCount] = useState<number>(0);

  const getMyProjects = useCallback(
    async (userId: string, params: ProjectsAdvanceFilterParam) => {
      const {
        page,
        limit,
        recruitmentStatus,
        stages,
        tags,
        milestones,
        sortField,
        sortOrder,
        batches,
      } = params;

      const url = parseEndpoint(endpoints.advanceSearchUserProjects, {
        userId,
        page: page - 1,
        limit,
      });
      const data = {
        recruitmentStatus,
        stages,
        tags,
        milestones,
        sortField,
        sortOrder,
        batches,
      };
      const response = await api.postRequest<
        PostProjectsData,
        GetProjectsResponse,
        JsendError
      >(url, data);

      return response;
    },
    [],
  );

  const updateMyProjects: HandleMyProjectctsAdvanceFilter = useCallback(
    async (params) => {
      const response = await getMyProjects(userId, params);

      if (response.ok && response.data?.status === "success") {
        setMyProjectsCount(response.data.data.count);
        setMyProjects(response.data.data.rows);
      }
    },
    [userId, getMyProjects],
  );

  return { myProjects, myProjectsCount, updateMyProjects };
};

export default useMyProjectsRequest;
