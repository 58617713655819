import { Button, Card, Col, Layout, Popconfirm, Row, Space } from "antd";
import moment from "moment";
import { useContext, useEffect, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { ReferenceThumbImage } from "@/features/reference/components/ReferenceThumbImage";

import {
  ButtonOption,
  HorizonalLayout,
  Table,
} from "../../../Components/atoms";
import { IconDelete, IconDownload, IconEdit } from "../../../Components/atoms";
import {
  CustomPagination,
  ReferenceDesLabel,
  SearchDefault as Search,
} from "../../../Components/molecules";
import { ReferenceLayout } from "../../../Components/molecules/reference";
import {
  EditReferenceModal,
  NewReferenceModal,
} from "../../../Components/organisms";
import { ContentContext } from "../../../Provider";
import { normalizeUrl } from "../../../utils/common";
import { dateTimeFormat } from "../../../utils/constants";
import { REFERENCE_TYPE } from "../../../utils/enums";
import { useReference } from "../hooks/useReference";

const { Content } = Layout;
const PageSaveId = "GlobalReferenceListScreen";

const GlobalReferenceListScreen = function () {
  const { updatePageTitle } = useContext(ContentContext);
  useEffect(() => {
    updatePageTitle("screen.label.reference");
  }, []);

  const intl = useIntl();

  const {
    isNewModalVisible,
    onToggleNewReferenceModal,
    auth,
    references,
    onToggleEditReferenceModal,
    isEditModalVisible,
    selectedReference,
    updateReference,
    searchQuery,
    setSearchQuery,
    refreshReference,
    createReference,
    onTableChanged,
    page,
    limit,
    setLimit,
    setPage,
    deleteReference,
    saveFile,
  } = useReference(REFERENCE_TYPE.GLOBAL);

  const columns = useMemo(() => {
    const tempColumns = [
      {
        title: "",
        dataIndex: "thumbnailUrl",
        key: "thumbnailUrl",
        // @ts-expect-error TS7006
        render: function _(text: string, row) {
          return (
            <ReferenceThumbImage
              size={72}
              thumbnailUrl={row?.thumbnailUrl ?? ""}
            />
          );
        },
      },
      {
        title: intl.formatMessage({
          id: "screen.label.file_name",
        }),
        dataIndex: "fileName",
        key: "fileName",
        render: function _(text: string) {
          return <ReferenceDesLabel>{text}</ReferenceDesLabel>;
        },
        sorter: true,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: intl.formatMessage({
          id: "screen.label.description",
        }),
        dataIndex: "description",
        key: "description",
        render: function _(text: string) {
          return <ReferenceDesLabel>{text}</ReferenceDesLabel>;
        },
      },
      {
        title: intl.formatMessage({
          id: "screen.label.date_time_of_addition",
        }),
        dataIndex: "uploadedAt",
        key: "uploadedAt",
        // @ts-expect-error TS7006
        render: function _(uploadedAt: string, row) {
          return moment(uploadedAt).local().format(dateTimeFormat);
        },
        sorter: true,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "",
        dataIndex: "",
        key: "",
        // @ts-expect-error TS7006
        render: function _(text: string, row) {
          return (
            <Space>
              <Button
                onClick={() => {
                  saveFile(
                    normalizeUrl(row.fileUrl),
                    row.fileName,
                    row.fileName.split(".").pop(),
                  );
                }}
              >
                {IconDownload}
              </Button>
              {auth.payload.type === "admin" ? (
                <Button
                  onClick={() => {
                    onToggleEditReferenceModal(row);
                  }}
                >
                  {IconEdit}
                </Button>
              ) : null}

              {auth.payload.type === "admin" ? (
                <Popconfirm
                  title={intl.formatMessage({
                    id: "screen.label.delete_reference_confirm",
                  })}
                  onConfirm={() => {
                    deleteReference(row.id);
                  }}
                  okText={intl.formatMessage({
                    id: "screen.label.yes",
                  })}
                  cancelText={intl.formatMessage({
                    id: "screen.label.no",
                  })}
                >
                  <Button>{IconDelete}</Button>
                </Popconfirm>
              ) : null}
            </Space>
          );
        },
      },
    ];

    return tempColumns;
  }, [intl, onToggleEditReferenceModal, updateReference, deleteReference]);

  return (
    <ReferenceLayout>
      <NewReferenceModal
        globalReference={true}
        onDismiss={onToggleNewReferenceModal}
        visible={isNewModalVisible}
        createReference={createReference}
      />
      {isEditModalVisible && selectedReference ? (
        <EditReferenceModal
          globalReference={true}
          onDismiss={onToggleEditReferenceModal}
          visible={isEditModalVisible}
          selectedReference={selectedReference}
          updateReference={updateReference}
        />
      ) : null}

      <Content>
        <Row>
          <Col xs={24} sm={12} md={8} lg={6}>
            <h2>{`${intl.formatMessage({
              id: "screen.label.reference_list",
            })}`}</h2>
          </Col>
          {auth.payload.type === "admin" ? (
            <Col xs={24} sm={12} md={16} lg={18}>
              <HorizonalLayout
                style={{
                  justifyContent: "flex-end",
                }}
              >
                <ButtonOption onClick={onToggleNewReferenceModal}>
                  <FormattedMessage id={"screen.label.reference_add_file"} />
                </ButtonOption>
              </HorizonalLayout>
            </Col>
          ) : null}
        </Row>
        <br />
        <Row>
          <Col span={24}>
            <Card>
              {auth.payload.isSuper ? (
                <Row>
                  <Col xs={24} md={{ span: 8 }}>
                    <Search
                      value={searchQuery.q}
                      placeholder={intl.formatMessage({
                        id: "screen.label.please_enter_keyword",
                      })}
                      size="large"
                      enterButton={intl.formatMessage({
                        id: "screen.label.retrieval",
                      })}
                      onChange={(e) => {
                        setSearchQuery((prevState) => {
                          return {
                            ...prevState,
                            q: e.target.value,
                          };
                        });
                      }}
                      onSearch={(value) => {
                        refreshReference(searchQuery);
                      }}
                    />
                  </Col>
                </Row>
              ) : null}

              <br />
              <Table
                pagination={false}
                rowKey={"id"}
                // @ts-expect-error TS2769
                columns={columns}
                dataSource={
                  // @ts-expect-error TS2339
                  Array.isArray(references.rows) ? references.rows : []
                }
                onChange={onTableChanged}
                className="ant-table-x-scroll"
              />
              <br />
              <CustomPagination
                saveId={PageSaveId}
                // @ts-expect-error TS2339
                count={references.count}
                initPage={page}
                initLimit={limit}
                onChangePage={(page, limit) => {
                  setPage(page);
                  setLimit(limit);
                }}
              />
            </Card>
          </Col>
        </Row>
      </Content>
    </ReferenceLayout>
  );
};

export default GlobalReferenceListScreen;
