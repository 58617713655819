import { Action } from "@reduxjs/toolkit";
import { createActions, createReducer } from "reduxsauce";
import Immutable from "seamless-immutable";

import { ProjectArchive } from "@/../types/ProjectArchive";

import { ToAction, ToActionType } from "./types";

/* ------------- Types and Action Creators ------------- */
type ArchiveActionCreators = {
  archivesGetsRequest: (
    page: unknown,
    id: unknown,
    filter: unknown,
  ) => Action<"ARCHIVES_GETS_REQUEST"> & {
    page: unknown;
    id: unknown;
    filter: unknown;
  };
  archivesGetsSuccess: (
    archivesPayload: unknown,
  ) => Action<"ARCHIVES_GETS_SUCCESS"> & {
    archivesPayload: unknown;
  };
  archivesGetsFailure: (errors: unknown) => Action<"ARCHIVES_GETS_FAILURE"> & {
    errors: unknown;
  };
};
type ArchiveAction = ToAction<ArchiveActionCreators>;
type ArchiveActionType = ToActionType<ArchiveAction>;

const { Types, Creators } = createActions<
  ArchiveActionType,
  ArchiveActionCreators
>({
  archivesGetsRequest: ["page", "id", "filter"],
  archivesGetsSuccess: ["archivesPayload"],
  archivesGetsFailure: ["errors"],

  /**多分使ってない */
  archivesGetRequest: ["archiveId"],
  /**多分使ってない */
  archivesGetSuccess: ["archivePayload"],
  /**多分使ってない */
  archivesGetFailure: ["errors"],
});

export const ArchivesTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
type ArchiveState = {
  fetching: boolean;
  errors: unknown;
  error?: unknown;
  archivesPayload: ProjectArchive[];
};
export const INITIAL_STATE = Immutable<ArchiveState>({
  fetching: false,
  errors: {},
  archivesPayload: [],
});
type ImmArchiveState = typeof INITIAL_STATE;

/* ------------- Reducers ------------- */

const archivesGetsRequest = (state: ImmArchiveState) =>
  state.merge({ fetching: true });

// @ts-expect-error TS7031
const archivesGetsSuccess = (state: ImmArchiveState, { archivesPayload }) => {
  return state.merge({ fetching: false, archivesPayload });
};

// @ts-expect-error TS7031
const archivesGetsFailure = (state: ImmArchiveState, { error }) =>
  state.merge({ fetching: false, error });

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer<ImmArchiveState, ArchiveAction>(
  INITIAL_STATE,
  {
    [Types.ARCHIVES_GETS_REQUEST]: archivesGetsRequest,
    [Types.ARCHIVES_GETS_SUCCESS]: archivesGetsSuccess,
    [Types.ARCHIVES_GETS_FAILURE]: archivesGetsFailure,
  },
);
