import FileSaver from "file-saver";
import { useIntl } from "react-intl";

import { ApiRequestData } from "@/Redux/ApiRedux";

import { endpoints, parseEndpoint } from "../../../Services/endpoints";
import { IProjectArchiveScreenFullViewComponentProps } from "../types/IProjectArchiveScreenFullViewComponentProps";

const useProjectArchiveScreenFullViewComponent = (
  props: IProjectArchiveScreenFullViewComponentProps,
) => {
  const intl = useIntl();
  const fileDownloaded = (fileId: number, fileDetail = {}) => {
    const data: ApiRequestData = {
      method: "postRequest",
      url: parseEndpoint(endpoints.fileDownload, {
        fileId,
      }),
      data: fileDetail,
    };
    props.dispatchApiRequest(data, ({ data }) => {});
  };
  const saveFile = (
    href: string,
    fileName: string,
    extension?: string,
    fileId?: number,
  ) => {
    const xhr = new XMLHttpRequest();
    xhr.withCredentials = true;
    xhr.open("GET", href, true);
    xhr.responseType = "arraybuffer";
    xhr.onload = (e) => {
      // @ts-expect-error TS18047
      const arrayBufferView = new Uint8Array(e.currentTarget.response);
      const blob = new Blob([arrayBufferView]);
      fileDownloaded(fileId ? fileId : -1, {
        fileName,
        fileSize: blob.size,
      });
      FileSaver.saveAs(blob, fileName);
    };
    xhr.send();
  };
  const handleProjectSubmissionTypesChange = (
    projectTypesSubmissionFilterCheckList: string[],
  ) => {
    props.handleProjectSubmissionTypesChange(
      projectTypesSubmissionFilterCheckList,
    );
  };

  return {
    intl,
    fileDownloaded,
    saveFile,
    handleProjectSubmissionTypesChange,
  };
};

export default useProjectArchiveScreenFullViewComponent;
