import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";

import { PlusIconTextButtonProps } from "./type";

export const PlusIconTextButton = ({
  className,
  children,
  ...buttonProps
}: React.PropsWithChildren<PlusIconTextButtonProps>) => {
  return (
    <Button {...buttonProps} icon={<PlusOutlined />} className={className}>
      {children}
    </Button>
  );
};
