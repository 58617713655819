import { memo } from "react";

import { EditIconButton } from "@/shared/buttons/components/EditIconButton";

import styles from "./styles.module.scss";
import { PropsCommentEditButton } from "./type";
export const CommentEditButton = memo(
  ({ comment, onEdit }: PropsCommentEditButton) => {
    return (
      <EditIconButton
        id={`comment-edit-${comment.id}`}
        alt="comment edit"
        onClick={() => {
          onEdit(comment);
        }}
        className={styles.comment_edit_button}
      />
    );
  },
);
