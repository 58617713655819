import { memo } from "react";

import styles from "@/features/projectComment/component/ThreadDeleteButton/styles.module.scss";
import { ThreadDeleteButtonProps } from "@/features/projectComment/component/ThreadDeleteButton/type";
import { DeleteIconButton } from "@/shared/buttons/components/DeleteIconButton";

export const ThreadDeleteButton = memo(
  ({ thread, onDelete }: ThreadDeleteButtonProps) => {
    return (
      <DeleteIconButton
        id={`thread-delete-${thread.id}`}
        alt="thread delete icon"
        onClick={() => onDelete(thread)}
        className={styles.thread_delete_button}
      />
    );
  },
);
