import { Action } from "@reduxjs/toolkit";
import { createActions, createReducer } from "reduxsauce";
import Immutable from "seamless-immutable";

import { ToAction, ToActionType } from "./types";

/* ------------- Types and Action Creators ------------- */
type BatchActionCreators = {
  batchGetsRequest: () => Action<"BATCH_GETS_REQUEST">;
  batchGetsSuccess: () => Action<"BATCH_GETS_SUCCESS">;
};
type BatchAction = ToAction<BatchActionCreators>;
type BatchActionType = ToActionType<BatchAction>;

const { Types, Creators } = createActions<BatchActionType, BatchActionCreators>(
  {
    batchGetsRequest: ["data"],
    batchGetsSuccess: ["batches"],
  },
);

export const BatchTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

type BatchState = {
  batches: unknown[];
  fetching: boolean;
};

export const INITIAL_STATE = Immutable<BatchState>({
  batches: [],
  fetching: false,
});

type ImmBatchState = typeof INITIAL_STATE;

/* ------------- Reducers ------------- */

const batchGetsRequest = (state: ImmBatchState) =>
  state.merge({ fetching: true });

const batchGetsSuccess = (
  state: ImmBatchState,
  { batches }: { batches: unknown[] },
) => {
  // @ts-expect-error TS2322
  return state.merge({ fetching: false, batches });
};

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer<ImmBatchState, BatchAction>(
  INITIAL_STATE,
  {
    [Types.BATCH_GETS_REQUEST]: batchGetsRequest,
    [Types.BATCH_GETS_SUCCESS]: batchGetsSuccess,
  },
);
