import { notification } from "antd";
import { all, call, put, takeLatest } from "redux-saga/effects";

import CommentActions from "../Redux/CommentRedux";
import ThreadActions, { ThreadTypes } from "../Redux/ThreadRedux";
import { endpoints, parseEndpoint } from "../Services/endpoints";

// @ts-expect-error TS7006
function* attemptDeleteThread(api, action) {
  const { data, message } = action;

  // @ts-expect-error TS7057
  const threadResponse = yield call(
    api.deleteRequest,
    parseEndpoint(endpoints.deleteThread, { data }),
  );

  if (threadResponse.ok) {
    switch (threadResponse.data.status) {
      case "success":
        yield put(
          // @ts-expect-error TS2554
          CommentActions.commentGetsRequest({
            projectId: data.projectId,
            page: 0,
          }),
        );
        yield put(ThreadActions.threadDeleteSuccess());
        notification.success({
          message: message,
        });

        break;
      case "fail":
      case "error":
        if (typeof threadResponse.data.data === "string") {
        }
        // @ts-expect-error TS2554
        yield put(ThreadActions.threadDeleteFailure(threadResponse.data.data));
        break;
      default:
        break;
    }
  } else {
    notification.error({
      message: threadResponse.problem,
    });
    // @ts-expect-error TS2554
    yield put(ThreadActions.threadDeleteFailure(threadResponse.problem));
    if (threadResponse.status === 401) {
      // @ts-expect-error TS2322
      window.location = "/login";
    }
  }
}

// @ts-expect-error TS7006
function* attemptUpdateThread(api, action) {
  const { data, message } = action;

  // @ts-expect-error TS7057
  const threadResponse = yield call(
    api.putRequest,
    parseEndpoint(endpoints.updateCommentThread, { data }),
    data,
  );

  if (threadResponse.ok) {
    switch (threadResponse.data.status) {
      case "success":
        yield put(
          // @ts-expect-error TS2554
          CommentActions.commentGetsRequest({
            projectId: data.projectId,
            page: 0,
          }),
        );
        yield put(ThreadActions.threadUpdateSuccess());
        notification.success({
          message: message,
        });

        break;
      case "fail":
      case "error":
        if (typeof threadResponse.data.data === "string") {
        }
        // @ts-expect-error TS2554
        yield put(ThreadActions.threadUpdateFailure(threadResponse.data.data));
        break;
      default:
        break;
    }
  } else {
    notification.error({
      message: threadResponse.problem,
    });
    // @ts-expect-error TS2554
    yield put(ThreadActions.threadUpdateFailure(threadResponse.problem));
    if (threadResponse.status === 401) {
      // @ts-expect-error TS2322
      window.location = "/login";
    }
  }
}

// @ts-expect-error TS7006
function* attemptCreateThread(api, action) {
  const { data, message } = action;

  // @ts-expect-error TS7057
  const threadResponse = yield call(
    api.postRequest,
    parseEndpoint(endpoints.createProjectCommentThread, { data }),
    data,
  );

  if (threadResponse.ok) {
    switch (threadResponse.data.status) {
      case "success":
        yield put(
          // @ts-expect-error TS2554
          CommentActions.commentGetsRequest({
            projectId: data.projectId,
            page: 0,
          }),
        );
        yield put(ThreadActions.threadCreateSuccess());
        notification.success({
          message: message,
        });

        break;
      case "fail":
      case "error":
        if (typeof threadResponse.data.data === "string") {
        }
        // @ts-expect-error TS2554
        yield put(ThreadActions.threadCreateFailure(threadResponse.data.data));
        break;
      default:
        break;
    }
  } else {
    notification.error({
      message: threadResponse.problem,
    });
    // @ts-expect-error TS2554
    yield put(ThreadActions.threadCreateFailure(threadResponse.problem));
    if (threadResponse.status === 401) {
      // @ts-expect-error TS2322
      window.location = "/login";
    }
  }
}

// @ts-expect-error TS7006
function* attemptGetAllThreads(api, action) {
  const { data } = action;
  // @ts-expect-error TS7057
  const threadResponse = yield call(
    api.getRequest,
    parseEndpoint(endpoints.getCommentThreads, { data }),
  );

  if (threadResponse.ok) {
    switch (threadResponse.data.status) {
      case "success":
        // @ts-expect-error TS2554
        yield put(ThreadActions.threadGetsSuccess(threadResponse.data.data));
        break;
      case "fail":
      case "error":
        if (typeof threadResponse.data.data === "string") {
        }
        // @ts-expect-error TS2554
        yield put(ThreadActions.threadGetsFailure(threadResponse.data.data));
        break;
      default:
        break;
    }
  } else {
    notification.error({
      message: threadResponse.problem,
    });
    // @ts-expect-error TS2554
    yield put(ThreadActions.threadGetsFailure(threadResponse.problem));
    if (threadResponse.status === 401) {
      // @ts-expect-error TS2322
      window.location = "/login";
    }
  }
}

// @ts-expect-error TS7006
function* threadSaga(api) {
  yield all([
    takeLatest(ThreadTypes.THREAD_CREATE_REQUEST, attemptCreateThread, api),
    takeLatest(ThreadTypes.THREAD_UPDATE_REQUEST, attemptUpdateThread, api),
    takeLatest(ThreadTypes.THREAD_DELETE_REQUEST, attemptDeleteThread, api),
    takeLatest(ThreadTypes.THREAD_GETS_REQUEST, attemptGetAllThreads, api),
  ]);
}

export default threadSaga;
