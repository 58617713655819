import { EditorState } from "draft-js";
import { useEffect, useRef } from "react";
import { useIntl } from "react-intl";

import { endpoints, parseEndpoint } from "../../../Services/endpoints";
import {
  convertFromRawWithTrimmer,
  getObjUUID,
  sanitizeEditorStateMarkupHtml,
} from "../../../utils/common";
import { useStateWithRef } from "../../../utils/hooks";
import { getInitStorageValue } from "../../../utils/localStorage";
import {
  INotificationScreenProps,
  NotificationScreenState,
} from "../types/INotificationScreenProps";

const PageSaveId = "NotificationScreen";
const pageKey = `pagination_page_${PageSaveId}`;
const limitKey = `pagination_limit_${PageSaveId}`;
const useNotificationScreen = (props: INotificationScreenProps) => {
  const intl = useIntl();
  const { user, apiRequest, updatePageTitle } = props.context;
  const [state, customSetState, stateRef] = useStateWithRef(() => {
    return {
      page: getInitStorageValue(pageKey, 1),
      limit: getInitStorageValue(limitKey, process.env.REACT_APP_PAGE_SIZE),
      notifications: [],
      count: 0,
      editorState: EditorState.createEmpty(),
      modalTitle: "",
      modalDesc: "",
      modalLink: "",
      modalVisible: false,
      eventDetail: {
        name: "",
        description: "",
        id: "",
        uuid: "",
        nodalData: false,
        beginsAt: new Date(),
        isMemberStatus: null,
      },
      noticeDetails: {
        noticeType: "",
        title: "",
        description: "",
        id: "",
        nodalData: false,
        meta: {
          type: "",
        },
      },
    };
  });
  const setStateCallbackRef = useRef(() => {});
  useEffect(() => {
    const callBack = setStateCallbackRef.current;
    callBack();
    setStateCallbackRef.current = () => {};
  }, [state]);
  const setState = (data: NotificationScreenState, callback = () => {}) => {
    setStateCallbackRef.current = callback;
    // @ts-expect-error TS7006
    customSetState((previousState) => {
      return {
        ...previousState,
        ...data,
      };
    });
  };
  useEffect(() => {
    updatePageTitle("screen.label.notification"); ////
    getNotificationsRequestion(stateRef.current.page, stateRef.current.limit);
  }, []);

  const onChangePage = (
    page = 1,
    // @ts-expect-error TS2345
    limit: number = parseInt(process.env.REACT_APP_PAGE_SIZE),
  ) => {
    setState(
      {
        page,
        limit,
      },
      () => {
        getNotificationsRequestion(
          stateRef.current.page,
          stateRef.current.limit,
        );
      },
    );
  };

  const getNotificationType = () => {
    const {
      match: { params },
    } = props;
    return params.notificationType ? params.notificationType : -1;
  };
  // @ts-expect-error TS7006
  const onTakeActionOnNotification = (notificationData, id) => {
    const message = intl.formatMessage({
      id: "screen.label.notification_information_acquired",
    });
    // @ts-expect-error TS2722
    props.dispatchTakeActionOnNotification(notificationData, id, message);
  };
  // @ts-expect-error TS7006
  const onPromptModal = (modalEnv) => {
    setState({
      modalTitle: modalEnv.name,
      modalDesc: modalEnv.description,
      modalLink: modalEnv.projectId,
      modalVisible: true,
    });
  };
  // @ts-expect-error TS7006
  const getIsEventMember = (eventDetail) => {
    const getIsEventMemberRequestData = {
      method: "getRequest",
      url: parseEndpoint(endpoints.findEventMember, {
        eventId: getObjUUID(eventDetail),
        userId: user.id,
      }),
      data: {},
    };
    // @ts-expect-error TS2345
    apiRequest(getIsEventMemberRequestData, ({ data }) => {
      const newEventDettail = {
        ...eventDetail,
        isMemberStatus: data !== null,
      };
      setState({
        eventDetail: newEventDettail,
      });
    });
  };
  // @ts-expect-error TS7006
  const onTakeActionRegisterEvent = (eventDetail) => {
    getIsEventMember(eventDetail);
    setState({
      eventDetail,
    });
  };
  // @ts-expect-error TS7006
  const onTakeActionNoticeClicked = (notification, noticeType) => {
    const noticeDetails = {
      noticeType,
      title: notification.actionText,
      description: notification.message,
      id: notification.id,
      meta: notification.meta,
      nodalData: notification.nodalData,
    };
    let editNode = null;
    try {
      editNode = sanitizeEditorStateMarkupHtml(
        EditorState.createWithContent(
          convertFromRawWithTrimmer(notification.message),
        ),
      );
    } catch (e) {}
    const state = {
      noticeDetails,
    };
    if (editNode !== null) {
      // @ts-expect-error TS7053
      state["editorState"] = editNode;
    }
    setState(state);
  };
  // @ts-expect-error TS7006
  const onDeleteNotification = (notification) => {
    const requestBuilder = {
      method: "deleteRequest",
      url: parseEndpoint(endpoints.updateNotification, {
        id: notification.id,
      }),
      data: {},
    };
    // @ts-expect-error TS2345
    apiRequest(requestBuilder, ({ data }) => {
      getNotificationsRequestion(stateRef.current.page, stateRef.current.limit);
    });
  };
  // @ts-expect-error TS7006
  const getNotificationsRequestion = (page = 1, limit) => {
    const notificationRequest = {
      method: "getRequest",
      url: parseEndpoint(endpoints.notifications, {
        page: page - 1,
        limit,
        notificationType: getNotificationType(),
      }),
      data: {},
    };
    // @ts-expect-error TS2345
    apiRequest(notificationRequest, ({ data }) => {
      setState({
        // @ts-expect-error TS18046
        notifications: data.rows,
        // @ts-expect-error TS18046
        count: data.count,
      });
    });
  };
  // @ts-expect-error TS7006
  const handleCancel = (e) => {
    setState({
      modalVisible: false,
    });
  };
  const onCloseNoticeModal = () => {
    setState({
      // @ts-expect-error TS2741
      noticeDetails: {
        noticeType: "",
        title: "",
        description: "",
        id: "",
        meta: {
          type: "",
        },
      },
    });
  };
  const onCloseRegisterEventModal = () => {
    setState({
      eventDetail: {
        name: "",
        description: "",
        id: "",
        uuid: "",
        isMemberStatus: null,
        beginsAt: new Date(),
      },
    });
  };
  const onUnRegisterEvent = () => {
    const eventRequestData = {
      method: "deleteRequest",
      url: parseEndpoint(endpoints.findEventMember, {
        eventId: getObjUUID(stateRef.current.eventDetail),
        userId: user.id,
      }),
      successMessage: intl.formatMessage({
        id: "screen.label.events_have_been_removed",
      }),
      data: {},
    };
    // @ts-expect-error TS2345
    apiRequest(eventRequestData, () => {});
    onCloseRegisterEventModal();
  };
  const onRegisterForEvent = () => {
    const eventRequestData = {
      method: "postRequest",
      url: parseEndpoint(endpoints.getEventUsers, {
        data: {
          eventId: getObjUUID(stateRef.current.eventDetail),
        },
        queryParams: "",
      }),
      successMessage: intl.formatMessage({
        id: "screen.label.apply_participation_event",
      }),
      data: {
        userId: user.id,
        eventId: stateRef.current.eventDetail.id,
      },
    };
    // @ts-expect-error TS2345
    apiRequest(eventRequestData, () => {});
    onCloseRegisterEventModal();
  };

  return {
    state: stateRef.current,
    intl,
    setState,
    getNotificationType,
    onTakeActionOnNotification,
    onPromptModal,
    getIsEventMember,
    onTakeActionRegisterEvent,
    onTakeActionNoticeClicked,
    onDeleteNotification,
    getNotificationsRequestion,
    onChangePage,
    handleCancel,
    onCloseNoticeModal,
    onCloseRegisterEventModal,
    onUnRegisterEvent,
    onRegisterForEvent,
  };
};

export default useNotificationScreen;
