import { notification } from "antd";
import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { Redirect, Route, useHistory } from "react-router-dom";

import { withContext } from "../../Provider";
import { RouteProps } from "./types/RouteProps";

const PrivateRoute: React.FC<RouteProps> = ({
  component: Component,
  location,
  context,
  ...rest
}: RouteProps) => {
  const user = context.user;
  const history = useHistory();
  const intl = useIntl();
  useEffect(() => {
    if (
      location &&
      location.pathname == "/dashboard" &&
      location.search == "?error=unauthorized_with_dormant"
    ) {
      notification.error({
        message: intl.formatMessage({
          id: "screen.label.unauthorized_with_dormant",
        }),
      });
      history.replace("/dashboard");
    }
  }, [location, history]);
  return (
    <Route
      {...rest}
      render={(props) =>
        user.token ? (
          <Component {...props} context={context} />
        ) : (
          <Redirect to={{ pathname: "/login", state: { from: location } }} />
        )
      }
    />
  );
};
export default withContext(PrivateRoute);
