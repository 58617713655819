import {
  Button,
  Form,
  Input,
  Modal,
  notification,
  Popconfirm,
  Select,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { RangePicker } from "../../Components/molecules";
import { batchStatus } from "../../utils/batchUtils";
import { dateFormat } from "../../utils/constants";
import { NewBatchModalProps } from "./types/NewBatchModalProps";

const { Option } = Select;
const { TextArea } = Input;

const NewBatchModal: React.FC<NewBatchModalProps> = function ({
  isVisible,
  setIsNewBatchModalVisible,
  onSaveBatch,
  defaultNewBatchData,
  onModalCLosed,
}: NewBatchModalProps) {
  const [batchData, setBatchData] = useState(null);
  const intl = useIntl();
  useEffect(() => {
    setBatchData(defaultNewBatchData);
  }, [defaultNewBatchData]);
  if (batchData) {
    return (
      <Modal
        onCancel={() => {
          // @ts-expect-error TS2722
          onModalCLosed();
          // @ts-expect-error TS2722
          setIsNewBatchModalVisible(false);
        }}
        title={<FormattedMessage id="screens.company.new_batch" />}
        visible={isVisible}
        footer={null}
      >
        <div style={{ paddingLeft: "4vw", paddingRight: "4vw" }}>
          <Form layout={"vertical"}>
            <Form.Item
              label={
                <FormattedMessage id="screens.batch.new_batch_name_input" />
              }
            >
              <Input
                // @ts-expect-error TS2339
                value={batchData.name}
                onChange={(e) => {
                  setBatchData({
                    // @ts-expect-error TS2698
                    ...batchData,
                    name: e.target.value,
                  });
                }}
              />
            </Form.Item>
            <Form.Item
              label={
                <FormattedMessage id="screens.batch.new_batch_period_input" />
              }
            >
              <RangePicker
                containerName="batch-creation-form"
                format={dateFormat}
                onChange={(selectedTimes) => {
                  if (selectedTimes && selectedTimes.length > 0) {
                    setBatchData({
                      // @ts-expect-error TS2698
                      ...batchData,
                      from: selectedTimes[0]?.local(),
                      to: selectedTimes[1]?.local(),
                    });
                  }
                }}
                defaultValue={[
                  // @ts-expect-error TS2322
                  batchData.from ? moment(batchData.from).local() : null,
                  // @ts-expect-error TS2322
                  batchData.to ? moment(batchData.to).local() : null,
                ]}
              />
            </Form.Item>

            <Form.Item
              label={
                <FormattedMessage id="screens.batch.new_batch_status_input" />
              }
            >
              <Select
                defaultValue={1}
                // @ts-expect-error TS2339
                value={batchData.status}
                onChange={(value) => {
                  setBatchData({
                    // @ts-expect-error TS2698
                    ...batchData,
                    status: value,
                  });
                }}
              >
                {batchStatus.map(({ key, value }) => {
                  return (
                    <Option key={key} value={key}>
                      {intl.formatMessage({ id: value })}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              label={<FormattedMessage id="screen.label.description" />}
            >
              <TextArea
                // @ts-expect-error TS2339
                value={batchData.description}
                rows={5}
                onChange={(e) => {
                  setBatchData({
                    // @ts-expect-error TS2698
                    ...batchData,
                    description: e.target.value,
                  });
                }}
              />
            </Form.Item>
            <Form.Item>
              <Popconfirm
                title={
                  <FormattedMessage id="screens.batch.new_batch_confirm_msg" />
                }
                onConfirm={() => {
                  // @ts-expect-error TS2339
                  if (batchData.name.trim().length > 0) {
                    // @ts-expect-error TS2722
                    onSaveBatch(batchData);
                  } else {
                    notification.error({
                      message: intl.formatMessage({
                        id: "screens.batch.new_batch_name_input_required_err_msg",
                      }),
                    });
                  }
                }}
                onCancel={() => {}}
                okText={<FormattedMessage id="screen.label.yes" />}
                cancelText={<FormattedMessage id="screen.label.no" />}
              >
                <Button
                  style={{
                    backgroundColor: "#00D282",
                    color: "white",
                    fontWeight: 400,
                  }}
                  type={"ghost"}
                >
                  <FormattedMessage id="screens.batch.new_batch_status_save_btn" />
                </Button>
              </Popconfirm>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    );
  }
  return <></>;
};
export default NewBatchModal;
