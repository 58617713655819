import { useIntl } from "react-intl";
// @ts-expect-error TS2307
import { User } from "types/User";
export const useUserTypeLabel = (user: User) => {
  const intl = useIntl();
  switch (user.type) {
    case "admin":
      return user.isSuper
        ? intl.formatMessage({ id: "screen.label.admin_user" }) // system admin(システム管理者)
        : intl.formatMessage({ id: "screen.label.management_user" }); // program manager(事務局)
    case "mentor":
      return intl.formatMessage({ id: "screen.label.mentor" }); // mentor(メンター)
    default:
      return intl.formatMessage({ id: "screen.label.general_user" }); // originator (起案者)
  }
};
