import moment from "moment";
import PropTypes from "prop-types";
import React, { useMemo } from "react";

import { dateTimeFormat } from "../../../utils/constants";

type SingleVideoCardComponentProps = {
  imgSrc: string;
  videoName: string;
  description: string;
  datePosted: string;
  onClick: () => void;
};

const SingleVideoCardComponent: React.FC<SingleVideoCardComponentProps> =
  function (props) {
    const { imgSrc, videoName, description, datePosted, onClick } = props;
    const imageId = useMemo(() => {
      return new Date().getTime();
    }, []);

    const getImageHeight = () => {
      // @ts-expect-error TS2345
      const width = document.getElementById(imageId)?.width;
      if (width) {
        return (9 * width) / 16;
      }
      return 120;
    };
    return (
      <div
        onClick={onClick}
        style={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#fff",
          boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.15)",
        }}
      >
        <img
          // @ts-expect-error TS2322
          id={imageId}
          style={{
            objectFit: "cover",
            width: "100%",
            maxWidth: "100%",

            height: `${getImageHeight()}px`,
            maxHeight: `${getImageHeight()}px`,
          }}
          src={imgSrc}
        />
        <div
          style={{
            paddingLeft: "12px",
            paddingRight: "12px",
            paddingBottom: "12px",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          <p
            style={{
              marginTop: "12px",
              marginBottom: 0,
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "bold",
              lineHeight: "20px",
              color: "#323232",
            }}
          >
            {videoName}
          </p>
          <p
            style={{
              marginTop: "4px",
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: "normal",
              lineHeight: "17px",
              color: "#808080",
            }}
          >
            {description}
          </p>
          <p
            style={{
              marginTop: "16px",
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: "normal",
              lineHeight: "17px",
              color: "#323232",
            }}
          >
            {moment(datePosted).local().format(dateTimeFormat)}
          </p>
        </div>
      </div>
    );
  };
SingleVideoCardComponent.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  videoName: PropTypes.string.isRequired,
  // @ts-expect-error TS2322
  description: PropTypes.string,
  datePosted: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};
SingleVideoCardComponent.defaultProps = {
  description: "",
};

export default SingleVideoCardComponent;
