import { Popover } from "antd";
import { FC } from "react";

import { IconInfoDark } from "@/Components/atoms";

export type InformationPopoverIconProps = {
  info: string;
};

export const InformationPopoverIcon: FC<InformationPopoverIconProps> = ({
  info,
}) => (
  <Popover content={<p style={{ maxWidth: 300 }}>{info}</p>}>
    {IconInfoDark}
  </Popover>
);
