import { forwardRef, Ref } from "react";

import styles from "./styles.module.scss";
import { PlainTextareaProps } from "./type";

export const PlainTextarea = forwardRef(
  (
    { label, className, isError = false, ...textareaProps }: PlainTextareaProps,
    ref?: Ref<HTMLTextAreaElement>,
  ) => {
    const errorBorderColor = isError ? styles.error_border_color : "";
    return (
      <div className={styles.container}>
        {label && <label className={styles.label}>{label}</label>}
        <textarea
          ref={ref}
          className={`${styles.description_textarea} ${errorBorderColor} ${className}`}
          {...textareaProps}
        />
      </div>
    );
  },
);
