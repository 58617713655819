import { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";

import {
  ForgotPasswordScreenState,
  IForgotPasswordScreenProps,
} from "../types/IForgotPasswordScreenProps";

const useForgotPasswordScreen = (props: IForgotPasswordScreenProps) => {
  const intl = useIntl();
  const [state, customSetState] = useState<ForgotPasswordScreenState>(() => {
    return {
      email: "",
    };
  });
  const onChange = (e: { target: { id: any; value: any } }) => {
    setState({ [e.target.id]: `${e.target.value}`.trim() });
  };
  const onSubmit = () => {
    const userData = {
      email: state.email,
    };
    const message = intl.formatMessage({
      id: "screen.label.email_has_been_sent_password_reset",
    });
    props.dispatchForgotPasswordRequest(userData, message);
  };

  const setStateCallbackRef = useRef(() => {});
  useEffect(() => {
    const callBack = setStateCallbackRef.current;
    callBack();
    setStateCallbackRef.current = () => {};
  }, [state]);
  const setState = (data: ForgotPasswordScreenState, callback = () => {}) => {
    setStateCallbackRef.current = callback;
    customSetState((previousState) => {
      return {
        ...previousState,
        ...data,
      };
    });
  };

  return {
    intl,
    state,
    onChange,
    onSubmit,
    setState,
  };
};

export default useForgotPasswordScreen;
