import { useIntl } from "react-intl";

import { Popconfirm } from "@/Components/atoms";
import styles from "@/features/review/components/SaveReviewConfirmButton/styles.module.scss";
import { SaveReviewConfirmButtonProps } from "@/features/review/components/SaveReviewConfirmButton/type";
import { SecondaryButton } from "@/shared/buttons/components/SecondaryButton";

export const SaveReviewConfirmButton = ({
  onConfirm,
  disabled,
}: SaveReviewConfirmButtonProps) => {
  const intl = useIntl();
  const title = intl.formatMessage({
    id: "screens.submission.confirm_message",
  });
  const okText = intl.formatMessage({
    id: "screen.label.yes",
  });
  const cancelText = intl.formatMessage({
    id: "screen.label.no",
  });
  const saveButtonText = intl.formatMessage({
    id: "screen.label.save",
  });

  return (
    <Popconfirm
      title={title}
      onConfirm={onConfirm}
      okText={okText}
      cancelText={cancelText}
      okButtonProps={{
        disabled,
      }}
    >
      <SecondaryButton className={styles.button}>
        {saveButtonText}
      </SecondaryButton>
    </Popconfirm>
  );
};
