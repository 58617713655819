import { Avatar } from "antd";
import styled from "styled-components";

type SizeType = "small" | "large" | "default" | number;
type Props = {
  projectId: number;
  url?: string;
  onClick?: () => void;
  size?: SizeType;
  avatarStyle?: React.CSSProperties;
};

const images = [
  "/icons/symbol-blue.png",
  "/icons/symbol-gray.png",
  "/icons/symbol-green.png",
  "/icons/symbol-light.png",
  "/icons/symbol-orange.png",
  "/icons/symbol-pink.png",
  "/icons/symbol-purple.png",
  "/icons/symbol-yellow.png",
];

const PlaceHolderAvatar = styled(Avatar)`
  margin-right: 10px;
  vertical-align: middle;
`;

const MainAvatar = styled(PlaceHolderAvatar)`
  background-color: #e8e8e8;
`;

export const ProjectIcon = (props: Props) => {
  const {
    projectId,
    url,
    onClick = () => {},
    size = "large",
    avatarStyle = {},
  } = props;
  if (url && url.length > 0) {
    return (
      <MainAvatar
        style={avatarStyle}
        size={size}
        src={window.location.protocol + "//" + url}
        onClick={onClick}
      />
    );
  } else {
    const colorIndex = projectId % images.length;
    return (
      <PlaceHolderAvatar
        style={avatarStyle}
        size={size}
        src={images[colorIndex]}
        onClick={onClick}
      />
    );
  }
};

type LeftArrowComponentProps = {
  children: React.ReactChild;
};

const LeftArrow = styled.div`
  margin-bottom: 24px;
  span {
    color: #808080;
    vertical-align: middle;
  }
  svg {
    width: 24px;
    height: 24px;
    margin-right: 12px;
  }
`;

export const LeftArrowComponent = (props: LeftArrowComponentProps) => {
  return <LeftArrow>{props.children}</LeftArrow>;
};
