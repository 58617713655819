import { Table } from "antd";
import _flow from "lodash/flow";
import moment from "moment";
import React, { forwardRef, memo } from "react";
import { Link } from "react-router-dom";

import { getObjUUID } from "../../../../utils/common";
import { dateTimeFormat } from "../../../../utils/constants";
import ForwardRef from "../../../../utils/forwardRef";
import { IconDelete, ProjectIcon } from "../../../atoms";
import { CustomPagination } from "../../../molecules";
import { WatchProjectRoot } from "../../../molecules";
import useWatchProjectList from "./hooks";
import { WatchProjectListProps } from "./types";

const PageSaveId = "WatchProjectList";

const WatchProjectList: React.FC<WatchProjectListProps> = (
  props: WatchProjectListProps,
) => {
  const { intl, setPage, setLimit, projects, pageCnt, deleteProjectWatch } =
    useWatchProjectList(props);
  const renderColumn = () => {
    return [
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
      },
      {
        title: intl.formatMessage({
          id: "screen.label.project_name",
        }),
        dataIndex: "name",
        key: "name",
        // @ts-expect-error TS7006
        render: function _fRenderColumn(text, row) {
          return (
            <WatchProjectRoot>
              <div style={{ minWidth: 50 }}>
                <ProjectIcon
                  projectId={row.Project.id}
                  url={row.Project.icon}
                />
              </div>
              <div style={{ maxWidth: "10vw" }}>
                <Link to={"/dashboard/project.top/" + getObjUUID(row.Project)}>
                  {row.Project.name}
                </Link>
              </div>
            </WatchProjectRoot>
          );
        },
      },
      {
        title: intl.formatMessage({
          id: "screen.label.watch_date_modified",
        }),
        key: "updatedAt",
        dataIndex: "updatedAt",
        // @ts-expect-error TS7006
        render: function _fRenderColumnUpdatedAt(text) {
          return <span>{moment(text).local().format(dateTimeFormat)}</span>;
        },
      },
      {
        title: intl.formatMessage({ id: "screen.label.watch" }),
        key: "isWatch",
        dataIndex: "isWatch",
        // @ts-expect-error TS7006
        render: function _fisWatch(text, row, index) {
          return (
            <div
              onClick={() => {
                deleteProjectWatch(
                  getObjUUID(row.Project),
                  row.id,
                  !row.isWatch,
                );
              }}
            >
              {IconDelete}
            </div>
          );
        },
      },
    ];
  };
  return (
    <>
      <Table
        rowKey="key"
        pagination={false}
        columns={renderColumn()}
        dataSource={projects}
        className="ant-table-x-scroll"
      />
      <br />
      <CustomPagination
        saveId={PageSaveId}
        count={pageCnt}
        onChangePage={(pageNum, limitNum) => {
          setPage(pageNum);
          setLimit(limitNum);
        }}
      />
    </>
  );
};
export default _flow([memo, ForwardRef, forwardRef])(WatchProjectList);
