import { Col, Form, notification, Row } from "antd";
import PropTypes from "prop-types";
import React, { FC, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import {
  ButtonCV,
  ButtonOption,
  ButtonUndo,
} from "../../../../Components/atoms";
import { Input } from "../../../../Components/molecules";
import { endpoints, parseEndpoint } from "../../../../Services/endpoints";
import { isEmailValid } from "../../../../utils/common";

type IEmailFormProps = {
  apiRequest: (...args: any[]) => any;
  refreshUser?: (...args: any[]) => any;
  userId: number;
  companyId: number;
  email?: string;
  verified?: boolean;
} & React.HTMLAttributes<Element>;

const ProfileEmailForm: FC<IEmailFormProps> = function (props) {
  const intl = useIntl();
  const { apiRequest, refreshUser, userId, email, verified } = props;
  const [newEmail, setNewEmail] = useState("");
  const [editable, setEditable] = useState(false);
  const [newVerified, setNewVerified] = useState(true);

  useEffect(() => {
    setNewEmail(email ?? "");
    setNewVerified(!!verified);
  }, [email, verified]);

  const onChangeEmail = () => {
    if (!isEmailValid(newEmail)) {
      notification.warning({
        message: intl.formatMessage({
          id: "screen.label.invalid_email_address",
        }),
      });
      return;
    }

    const emailPostRequest = {
      method: "postRequest",
      url: parseEndpoint(endpoints.changeEmail, {
        userId,
      }),
      data: { oldEmail: email, newEmail },
    };
    // @ts-expect-error TS7031
    apiRequest(emailPostRequest, ({ data }) => {
      if (data) {
        setEditable(false);
        refreshUser?.({ id: userId });
        notification.success({
          message: intl.formatMessage({
            id: "screen.label.change_email_success",
          }),
        });
        setNewVerified(false);
      }
    });
  };

  return (
    <>
      <Form.Item
        label={intl.formatMessage({
          id: "screens.title.email",
        })}
      >
        <Row
          align="middle"
          justify="space-between"
          style={
            !editable
              ? {
                  marginTop: 6,
                  paddingBottom: 6,
                  borderBottomColor: "#dddddd",
                  borderBottomWidth: 1,
                  borderBottomStyle: "solid",
                }
              : { marginTop: 6, paddingBottom: 6 }
          }
        >
          <Col flex={1}>
            <Input
              bordered={editable}
              value={newEmail}
              className="user-email"
              onChange={(event) => {
                setNewEmail(event.target.value);
              }}
              placeholder={intl.formatMessage({
                id: "screen.label.email",
              })}
              disabled={!editable}
            />
          </Col>
          <Col flex="none" className="offset-left-16">
            {editable ? (
              <Row>
                <ButtonCV
                  type="primary"
                  className="offset-right-16"
                  onClick={onChangeEmail}
                >
                  {intl.formatMessage({
                    id: "screen.label.update_email",
                  })}
                </ButtonCV>
                <ButtonUndo
                  style={{ fontSize: 11 }}
                  type="ghost"
                  onClick={() => {
                    setEditable(false);
                    setNewEmail(email ?? "");
                  }}
                >
                  <FormattedMessage id={"screen.label.cancel"} />
                </ButtonUndo>
              </Row>
            ) : (
              <ButtonOption
                onClick={() => {
                  setEditable(true);
                }}
              >
                {intl.formatMessage({
                  id: "screen.label.change_email_btn",
                })}
              </ButtonOption>
            )}
          </Col>
        </Row>
      </Form.Item>
      {!newVerified && (
        <p
          style={{
            color: "rgba(0, 0, 0, 0.45)",
            fontSize: 12,
          }}
        >
          <FormattedMessage id="screen.label.change_email_non_active" />
        </p>
      )}
    </>
  );
};

ProfileEmailForm.propTypes = {
  // @ts-expect-error TS2322
  apiRequest: PropTypes.func,
  refreshUser: PropTypes.func,
  // @ts-expect-error TS2322
  userId: PropTypes.number,
  // @ts-expect-error TS2322
  companyId: PropTypes.number,
  email: PropTypes.string,
  verified: PropTypes.bool,
};

export default ProfileEmailForm;
