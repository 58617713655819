import { ComponentProps, FC, PropsWithChildren } from "react";

import styles from "./styles.module.scss";

type ErrorMessageProps = PropsWithChildren<ComponentProps<"span">>;

export const ErrorMessage: FC<ErrorMessageProps> = ({
  className,
  children,
  ...props
}) => {
  return (
    <span className={`${styles.message} ${className}`} {...props}>
      {children}
    </span>
  );
};
