import { LinkOutlined } from "@ant-design/icons";
import { Col, notification, Row } from "antd";
// @ts-expect-error TS7016
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useIntl } from "react-intl";

import type { News } from "./type";
type StateProps = {
  comment: News;
};
type OwnProps = {};
type Props = OwnProps & StateProps;
function NewsLinkCopy(props: Props) {
  const { comment } = props;
  const intl = useIntl();
  return (
    // @ts-expect-error TS2322
    <Row key={`${comment.id}-copy`} align="middle" justify="flex-start">
      <Col>
        <CopyToClipboard
          text={comment.url}
          onCopy={() => {
            notification.success({
              message: intl.formatMessage({
                id: "screen.label.url_copied",
              }),
            });
          }}
        >
          <div className={`like-btn enabled`}>
            <LinkOutlined />
          </div>
        </CopyToClipboard>
      </Col>
    </Row>
  );
}
export default NewsLinkCopy;
