import { useIntl } from "react-intl";

import styles from "./styles.module.scss";
import { HandOverNoteTagProps } from "./type";

export const HandOverNoteTag = ({ hasHandOverNote }: HandOverNoteTagProps) => {
  const intl = useIntl();
  const handOverNoteTagLabel = intl.formatMessage({
    id: "screen.label.review.has_handover",
  });
  return (
    <>
      {hasHandOverNote && (
        <div className={styles.handover_note_tag}>
          <span className={styles.handover_note_label}>
            {handOverNoteTagLabel}
          </span>
        </div>
      )}
    </>
  );
};
