import { Card, Col, Divider, Row } from "antd";
import { FC, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Tag } from "@/Components/atoms";
import { ProjectTabComponent } from "@/Components/organisms/project/ProjectTabComponent";
import { ContentContext } from "@/Provider";
import { ApiRequestData } from "@/Redux/ApiRedux";
import ProjectActions from "@/Redux/ProjectRedux";
import ProjectLinksComponent from "@/Screens/project/ProjectLinksComponent";
import { endpoints, parseEndpoint } from "@/Services/endpoints";
import { TextWithLink } from "@/shared/text/components/TextWithLink";

import { ProjectCardMembers } from "../ProjectCardMembers";
import { ProjectCardTitle } from "../ProjectCardTitle";
import { ProjectLikeButton } from "../ProjectLikeButton";
import { ProjectWatchButton } from "../ProjectWatchButton";

export const ProjectTopCard: FC = () => {
  const { apiRequest } = useContext(ContentContext);
  const dispatch = useDispatch();
  // @ts-expect-error TS2339
  const project = useSelector((state) => state.projects.projectPayload);

  const tags = project?.Tag ?? [];

  const handleLikeButtonClick = () => {
    const projectId = project.uuid;
    const requestBuilder: ApiRequestData = {
      method: "putRequest",
      url: parseEndpoint(endpoints.likeProject, {
        projectId,
      }),
      data: {},
    };
    apiRequest(requestBuilder, () => {
      dispatch(
        ProjectActions.projectGetRequest(
          // @ts-expect-error TS2554
          projectId,
        ),
      );
    });
  };

  return (
    <Card title={<ProjectCardTitle />}>
      <Row align="middle">
        <Col flex="6">
          <ProjectCardMembers />
        </Col>
        <Col flex="none">
          <Row>
            <ProjectLikeButton
              projectLikes={project?.projectLikes ?? []}
              isLiked={project?.isLiked ?? false}
              onClick={handleLikeButtonClick}
            />
            <ProjectWatchButton />
          </Row>
        </Col>
      </Row>
      <br />
      <Row>
        <Col span={24}>
          <TextWithLink text={project?.description ?? ""} />
        </Col>
      </Row>
      <ProjectLinksComponent project={project} showOnlyLinks={true} />
      <Divider
        style={{
          marginTop: 16,
          marginBottom: 16,
          backgroundColor: "#DDDDDD",
        }}
      />
      <Row>
        {/* @ts-expect-error TS2339 */}
        {tags.map((tag) => (
          <Tag key={tag.id}>{tag.name}</Tag>
        ))}
      </Row>

      {/* Tabメニュー */}
      <Row>
        <ProjectTabComponent />
      </Row>
    </Card>
  );
};
