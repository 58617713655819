import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Button, Col, Input, Row, Switch } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

import { ButtonOption, IconEdit } from "../../Components/atoms";
import { CheckPointItems } from "../../Components/organisms";
import { ContentContext } from "../../Provider";
import { SingleHypoCol, SingleHypoTitle } from "./Components/SingleHypo";
import { SingleHypothesisItemProps } from "./types/SingleHypothesisItemProps";

const R = require("ramda");

const SingleHypothesisItem: React.FC<SingleHypothesisItemProps> = function ({
  displayCheckpoint,
  singleHypothesis,
  openNewHypothesisModal,
  onSaveHypothesis,
  isHypothesisSortable,
}: SingleHypothesisItemProps) {
  const [editing, setEditing] = React.useState(false);
  const { company } = useContext(ContentContext);
  // @ts-expect-error TS7006
  const renderSubTitle = (string) => {
    return (
      <div style={{ marginRight: 12 }}>
        <span style={{ fontSize: 14 }}>{string}</span>
      </div>
    );
  };
  const [localHypothesisData, setLocalHypothesis] = useState(null);
  useEffect(() => {
    if (localHypothesisData == null) {
      setLocalHypothesis(singleHypothesis);
    }
  }, [singleHypothesis]);
  const _renderDescriptionBox = () => {
    return (
      <Input.TextArea
        rows={2}
        style={{ width: "40vw", fontSize: 14 }}
        value={
          // @ts-expect-error TS18047
          localHypothesisData.hypothesisFormMetadata.hypothesisFormDescription
        }
        onChange={(e) => {
          const a = R.clone(localHypothesisData);
          a.hypothesisFormMetadata.hypothesisFormDescription = e.target.value;
          setLocalHypothesis(a);
          setEditing(true);
        }}
      />
    );
  };

  if (localHypothesisData !== null) {
    return (
      <div
        className={isHypothesisSortable ? "noselect" : ""}
        style={{
          marginBottom: "1vh",
          marginTop: "3vh",
          borderWidth: 1,
          borderColor: isHypothesisSortable ? "#BDBDBD" : "#e9e9e9",
          borderRadius: "8px",
          padding: "1rem",
          borderStyle: isHypothesisSortable ? "dashed" : "solid",
          cursor: isHypothesisSortable ? "row-resize" : "default",
          backgroundColor: "white",
        }}
      >
        <Row>
          <SingleHypoCol span={3}>
            <SingleHypoTitle>
              {/*
               // @ts-expect-error TS2339 */}
              {localHypothesisData.Hypothesis
                ? // @ts-expect-error TS2339
                  localHypothesisData.Hypothesis.seqid
                : ""}
            </SingleHypoTitle>
          </SingleHypoCol>
          <Col span={21}>
            <Row justify="space-between" align="middle">
              <Col>
                <Row align="middle">
                  {editing ? (
                    <Input.TextArea
                      autoSize={{
                        minRows: 1,
                        maxRows: 5,
                      }}
                      style={{
                        width: "20vw",
                        fontSize: 15,
                      }}
                      value={
                        // @ts-expect-error TS2339
                        localHypothesisData.hypothesisFormMetadata
                          .hypothesisFormName
                      }
                      maxLength={300}
                      onChange={(e) => {
                        const a = R.clone(localHypothesisData);
                        a.hypothesisFormMetadata.hypothesisFormName =
                          e.target.value;
                        setLocalHypothesis(a);
                      }}
                    />
                  ) : (
                    <span style={{ fontSize: 14 }}>
                      {
                        // @ts-expect-error TS2339
                        localHypothesisData.hypothesisFormMetadata
                          .hypothesisFormName
                      }
                    </span>
                  )}

                  {editing ? null : (
                    <div
                      onClick={() => {
                        setEditing(true);
                      }}
                      style={{ marginLeft: 20 }}
                    >
                      {IconEdit}
                    </div>
                  )}
                </Row>
              </Col>

              <ButtonOption
                onClick={() => {
                  // @ts-expect-error TS2700
                  const { id, ...rest } = localHypothesisData;
                  rest.referenceHypothesisId = id;
                  const newHypthesisCopy = R.clone(rest);
                  // @ts-expect-error TS2722
                  openNewHypothesisModal(newHypthesisCopy);
                }}
              >
                <FormattedMessage id="screen.batch_hypothesis.copy_hypothesis" />
              </ButtonOption>
            </Row>
            <Row justify="start" align="middle" style={{ marginTop: 8 }}>
              <Col>
                {renderSubTitle(
                  <FormattedMessage id="screens.title.hypotheses.display" />,
                )}
              </Col>
              <Col style={{ marginRight: 7 }}>
                <Switch
                  size="small"
                  checked={
                    // @ts-expect-error TS2339
                    localHypothesisData.hypothesisFormMetadata.displayHypothesis
                  }
                  onChange={(checked) => {
                    setEditing(true);
                    const a = R.clone(localHypothesisData);
                    a.hypothesisFormMetadata.displayHypothesis = checked;
                    setLocalHypothesis(a);
                  }}
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                />
              </Col>
              <Col>
                {renderSubTitle(
                  <FormattedMessage id="screens.title.hypotheses.frombegin" />,
                )}
              </Col>
              <Col style={{ marginRight: 7 }}>
                <Switch
                  checked={
                    // @ts-expect-error TS2339
                    localHypothesisData.hypothesisFormMetadata
                      .firstViewHypothesis
                  }
                  size="small"
                  onChange={(checked) => {
                    setEditing(true);
                    const a = R.clone(localHypothesisData);
                    a.hypothesisFormMetadata.firstViewHypothesis = checked;
                    setLocalHypothesis(a);
                  }}
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                />
              </Col>
              <Col>
                {renderSubTitle(
                  <FormattedMessage id="screens.title.hypotheses.userinfomark" />,
                )}
              </Col>
              <Col style={{ marginRight: 7 }}>
                <Switch
                  size="small"
                  checked={
                    // @ts-expect-error TS2339
                    localHypothesisData.hypothesisFormMetadata.useInfoMark
                  }
                  onChange={(checked) => {
                    setEditing(true);
                    const a = R.clone(localHypothesisData);
                    a.hypothesisFormMetadata.useInfoMark = checked;
                    setLocalHypothesis(a);
                  }}
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                />
              </Col>
            </Row>
            <br />
            <Row justify="start" align="middle" style={{ marginTop: 8 }}>
              <Col>
                {renderSubTitle(
                  <FormattedMessage id="screens.title.hypotheses.infocontent" />,
                )}
              </Col>
              <Col
                style={{
                  width: "100%",
                }}
              >
                {_renderDescriptionBox()}
              </Col>
            </Row>
            {editing && (
              // @ts-expect-error TS2322
              <Row align="end" style={{ marginTop: 10 }}>
                <Button
                  type="primary"
                  size="small"
                  onClick={() => {
                    // @ts-expect-error TS2722
                    onSaveHypothesis(localHypothesisData);
                    setEditing(false);
                  }}
                  style={{ float: "right", fontSize: 14 }}
                >
                  <FormattedMessage id="screens.title.save" />
                </Button>
                <Button
                  size="small"
                  onClick={() => {
                    setLocalHypothesis(singleHypothesis);
                    setEditing(false);
                  }}
                  style={{
                    float: "right",
                    marginLeft: 10,
                    fontSize: 14,
                  }}
                >
                  <FormattedMessage id="screen.label.cancel" />
                </Button>
              </Row>
            )}
            {singleHypothesis &&
              company.isAllowCheckpoint &&
              displayCheckpoint && (
                <>
                  <br />
                  <br />
                  <CheckPointItems
                    batchId={singleHypothesis.batchId}
                    hypothesisFormId={singleHypothesis.hypothesisFormId}
                  />
                </>
              )}
          </Col>
        </Row>
      </div>
    );
  }
  return <></>;
};
export default SingleHypothesisItem;
