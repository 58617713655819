import { ContentState, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { useState } from "react";

import { customChunkRenderer } from "../../../utils/editorUtils";

// @ts-expect-error TS7006
const useCustomTableToolbar = (props) => {
  const [isModalVisible, setModalVisibility] = useState(false);
  const addTable = (getRawHtml: string) => {
    const { editorState, onChange } = props;
    const markup = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    // @ts-expect-error TS2345
    const contentBlock = htmlToDraft(markup + getRawHtml, customChunkRenderer);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks,
      );
      onChange(EditorState.push(editorState, contentState, "insert-fragment"));
    }
    setModalVisibility(false);
  };

  return {
    isModalVisible,
    setModalVisibility,
    addTable,
  };
};

export default useCustomTableToolbar;
