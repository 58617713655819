import { Action } from "@reduxjs/toolkit";
import { createActions, createReducer } from "reduxsauce";
import Immutable from "seamless-immutable";

import { ToAction, ToActionType } from "./types";

/* ------------- Types and Action Creators ------------- */
type AsyncActionCreators = {
  serial: (actions: unknown) => Action<"SERIAL"> & {
    actions: unknown;
  };
};
type AsyncAction = ToAction<AsyncActionCreators>;
type ActionType = ToActionType<AsyncAction>;

const { Types, Creators } = createActions<ActionType, AsyncActionCreators>({
  serial: ["actions"],
});

export const AsyncTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */

type AsyncState = Record<string, never>;
export const INITIAL_STATE = Immutable<AsyncState>({});
type ImmAsyncState = typeof INITIAL_STATE;

export const reducer = createReducer<ImmAsyncState, AsyncAction>(
  INITIAL_STATE,
  {},
);
