import { api } from "@/Saga";
import { NullResponse } from "@/Services/commonResponsTypes";
import { endpoints, parseEndpoint } from "@/Services/endpoints";

export const publishReviewRequest = async (submittedProjectId: string) => {
  const url = parseEndpoint(endpoints.publishReview, {
    submittedProjectId,
  });
  return api.patchRequest<null, NullResponse>(url);
};
