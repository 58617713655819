import { notification } from "antd";
import { useEffect, useRef } from "react";
import { useIntl } from "react-intl";

import { endpoints, parseEndpoint } from "../../../Services/endpoints";
import { nonRecruiting, recruiting } from "../../../utils/constants";
import createFormData from "../../../utils/createFormData";
import { useStateWithRef } from "../../../utils/hooks";
import { getInitStorageValue } from "../../../utils/localStorage";
import {
  IProjectTeamScreenProps,
  ProjectTeamScreenState,
} from "../types/IProjectTeamScreenProps";
const PageActiveSaveId = "ProjectTeamScreen_Active";
const activePageKey = `pagination_page_${PageActiveSaveId}`;
const activeLimitKey = `pagination_limit_${PageActiveSaveId}`;
const PagePendingSaveId = "ProjectTeamScreen_Pending";
const pendingPageKey = `pagination_page_${PagePendingSaveId}`;
const pendingLimitKey = `pagination_limit_${PagePendingSaveId}`;

const useProjectTeamScreen = (props: IProjectTeamScreenProps) => {
  const intl = useIntl();
  const { user, apiRequest, updatePageTitle } = props.context;

  const isSuperUser = user.type === "admin" && user.isSuper;
  const [state, customSetState, stateRef] = useStateWithRef(() => {
    return {
      visible: false,
      visibleModalForInvite: false,
      role: 1,
      usersToAddToProject: [],
      acceptedTeamPage: getInitStorageValue(activePageKey, 1),
      acceptedTeamLimit: getInitStorageValue(
        activeLimitKey,
        process.env.REACT_APP_PAGE_SIZE,
      ),
      pendingTeamPage: getInitStorageValue(pendingPageKey, 1),
      pendingTeamLimit: getInitStorageValue(
        pendingLimitKey,
        process.env.REACT_APP_PAGE_SIZE,
      ),
      type: null,
      message: "",
      isVisibleMessageModalForUserAcceptanceOrRejection: false,
      messageToUserAfterRejectingOrAcceptance: "",
      actionWhenSubmittingModal: null,
      sendEmail: false,
      selectHolder: undefined,
    };
  });
  const setStateCallbackRef = useRef(() => {});
  useEffect(() => {
    const callBack = setStateCallbackRef.current;
    callBack();
    setStateCallbackRef.current = () => {};
  }, [state]);
  const setState = (data: ProjectTeamScreenState, callback = () => {}) => {
    setStateCallbackRef.current = callback;
    // @ts-expect-error TS7006
    customSetState((previousState) => {
      return {
        ...previousState,
        ...data,
      };
    });
  };

  useEffect(() => {
    updatePageTitle("screen.label.member");
    fetchPendingTeam();
    fetchAcceptedTeam();
  }, []);

  const fetchAcceptedTeam = () => {
    const { acceptedTeamPage, acceptedTeamLimit } = stateRef.current;
    props.dispatchFetchUsers({
      page: acceptedTeamPage - 1,
      limit: acceptedTeamLimit,
      id: getProjectId(),
    });
  };
  const fetchPendingTeam = () => {
    const { pendingTeamPage, pendingTeamLimit } = stateRef.current;
    const getPendingMembersRequest = {
      method: "getRequest",
      url: parseEndpoint(endpoints.pendingProjectMembers, {
        data: {
          id: getProjectId(),
          page: pendingTeamPage - 1,
          // @ts-expect-error TS2322
          limit: pendingTeamLimit,
        },
        limit: pendingTeamLimit,
      }),
      data: {
        userStatus: -1,
      },
    };
    // @ts-expect-error TS2345
    apiRequest(getPendingMembersRequest, ({ data }) => {
      props.dispatchFetchPendingUsers(data);
    });
  };
  const getProjectId = () => {
    const {
      match: { params },
    } = props;
    return params.id ? params.id : -1;
  };

  const showModal = () => {
    setState({
      visible: true,
    });
  };
  // @ts-expect-error TS7006
  const updateProject = (values) => {
    const requestBuilder = {
      method: "putRequest",
      url: parseEndpoint(endpoints.singleProject, {
        id: getProjectId(),
      }),
      data: createFormData(values),
    };
    // @ts-expect-error TS2345
    apiRequest(requestBuilder, ({ data }) => {
      const projectId = getProjectId();
      // @ts-expect-error TS2722
      props.dispatchGetProjectDetail(projectId);
    });
  };
  // @ts-expect-error TS7006
  const cancelRecruit = (e) => {
    updateProject({
      type: nonRecruiting,
    });
    setState({
      visible: false,
      visibleModalForInvite: false,
    });
  };
  // @ts-expect-error TS7006
  const handleOk = (e) => {
    // @ts-expect-error TS7006
    const inviteUsers = stateRef.current.usersToAddToProject.map((userId) => {
      return {
        uid: getProjectId(),
        // @ts-expect-error TS18048
        projectId: props.project.id,
        userId,
        teamRoleId: stateRef.current.role,
        status: 1,
      };
    });
    if (inviteUsers.length > 0) {
      setState({
        visible: false,
        visibleModalForInvite: false,
        usersToAddToProject: [],
        selectHolder: undefined,
      });
      const mesasge = intl.formatMessage({
        id: "screen.label.team_members_have_been_added",
      });
      props.dispatchAddTeamMembers(inviteUsers, mesasge);
      updateProject({
        // type: recruiting,
        visible: false,
      });
    }
  };
  // @ts-expect-error TS7006
  const handleCancel = (e) => {
    setState({
      visible: false,
    });
  };
  // @ts-expect-error TS7006
  const handleChangeRecruitUser = (value) => {
    setState({
      usersToAddToProject: value,
      selectHolder: value,
    });
  };
  // @ts-expect-error TS7006
  const setVisibleModalForInvite = (viewState) => {
    setState({
      visibleModalForInvite: viewState,
    });
  };
  const isProjectOwner = () => {
    // @ts-expect-error TS18048
    return props.project.isProjectOwner || isSuperUser;
  };

  // @ts-expect-error TS7006
  const deleteMember = (projectId, userID) => {
    const requestBuilder = {
      method: "putRequest",
      url: parseEndpoint(endpoints.updateTeamMember, {
        uid: projectId,
        userId: userID,
      }),
      data: {
        isDeleted: true,
      },
    };
    // @ts-expect-error TS2345
    apiRequest(requestBuilder, () => {
      fetchAcceptedTeam();
    });
  };
  // @ts-expect-error TS7006
  const toggleUserProjectOwnership = (projectId, userID, isProjectOwner) => {
    const requestBuilder = {
      method: "putRequest",
      url: parseEndpoint(endpoints.updateTeamMember, {
        uid: projectId,
        userId: userID,
      }),
      data: {
        isProjectOwner,
      },
    };
    // @ts-expect-error TS2345
    apiRequest(requestBuilder, () => {
      fetchAcceptedTeam();
    });
  };
  // @ts-expect-error TS7006
  const acceptToJoinProject = (projectId, userID) => {
    const acceptToJoinProjectRequest = {
      method: "putRequest",
      url: parseEndpoint(endpoints.updateTeamMember, {
        uid: projectId,
        userId: userID,
      }),
      data: {
        status: 1,
        message: stateRef.current.messageToUserAfterRejectingOrAcceptance,
      },
      successMessage: intl.formatMessage({
        id: "screen.label.update_success",
      }),
    };
    // @ts-expect-error TS2345
    apiRequest(acceptToJoinProjectRequest, () => {
      fetchPendingTeam();
      fetchAcceptedTeam();
      setState({
        isVisibleMessageModalForUserAcceptanceOrRejection: false,
        actionWhenSubmittingModal: null,
      });
    });
  };
  // @ts-expect-error TS7006
  const rejectAlreadyInvitedMemberToJoinProject = (projectId, userID) => {
    const rejectMemberRequest = {
      method: "putRequest",
      url: parseEndpoint(endpoints.updateTeamMember, {
        uid: projectId,
        userId: userID,
      }),
      data: {
        userStatus: -1,
      },
    };
    // @ts-expect-error TS2345
    apiRequest(rejectMemberRequest, () => {
      fetchPendingTeam();
    });
  };
  // @ts-expect-error TS7006
  const rejectToJoinProject = (projectId, userID) => {
    const rejectMemberRequest = {
      method: "putRequest",
      url: parseEndpoint(endpoints.updateTeamMember, {
        uid: projectId,
        userId: userID,
      }),
      data: {
        status: -1,
        message: stateRef.current.messageToUserAfterRejectingOrAcceptance,
      },
      successMessage: intl.formatMessage({
        id: "screen.label.update_success",
      }),
    };
    // @ts-expect-error TS2345
    apiRequest(rejectMemberRequest, () => {
      fetchPendingTeam();
      setState({
        isVisibleMessageModalForUserAcceptanceOrRejection: false,
        actionWhenSubmittingModal: null,
      });
    });
  };

  const submitCreateProjectInvite = () => {
    if (stateRef.current.message.trim().length > 0) {
      const data = {
        url: parseEndpoint(endpoints.projectNotifications, {
          projectId: getProjectId(),
        }),
        method: "postRequest",
        successMessage: intl.formatMessage({
          id: "screen.label.notification_has_been_sent",
        }),
        data: {
          type: "INVITE_MEMBERS_TO_PROJECT",
          message: stateRef.current.message,
          sendEmail: stateRef.current.sendEmail,
        },
      };
      // @ts-expect-error TS2345
      apiRequest(data, () => {});
      setVisibleModalForInvite(false);
      updateProject({
        type: recruiting,
      });
    } else {
      notification.error({
        message: intl.formatMessage({
          id: "screen.label.enter_message",
        }),
      });
    }
  };
  // @ts-expect-error TS7006
  const handleChange = (key, value) => {
    setState({ [key]: value });
  };

  return {
    intl,
    state: stateRef.current,
    setState,
    showModal,
    cancelRecruit,
    updateProject,
    handleOk,
    handleCancel,
    handleChangeRecruitUser,
    setVisibleModalForInvite,
    isProjectOwner,
    deleteMember,
    toggleUserProjectOwnership,
    acceptToJoinProject,
    rejectAlreadyInvitedMemberToJoinProject,
    rejectToJoinProject,
    submitCreateProjectInvite,
    handleChange,
    fetchAcceptedTeam,
    fetchPendingTeam,
  };
};

export default useProjectTeamScreen;
