import { Col, Pagination, Row, Select } from "antd";
import { Beforeunload } from "react-beforeunload";
import { useIntl } from "react-intl";

import { customParsePagination } from "../../../utils/pagination";
import useCustomPagination from "./hooks";
import { PaginationProps } from "./types";
const defaultPageLimitOptions = [
  {
    key: 10,
    value: "10",
  },
  {
    key: 30,
    value: "30",
  },
  {
    key: 50,
    value: "50",
  },
  {
    key: 100,
    value: "100",
  },
  {
    key: 1000,
    value: "1,000",
  },
];
const { Option } = Select;
function CustomPagination(props: PaginationProps) {
  const intl = useIntl();
  const {
    count,
    showLessItems = false,
    showPagination = true,
    containerStyle,
    onChangePage,
    page,
    setPage,
    setLimit,
    limit,
    removePageAndLimit,
  } = useCustomPagination(props);

  if (count < 1) {
    return null;
  }

  return (
    <Beforeunload
      onBeforeunload={(event) => {
        removePageAndLimit();
        event.stopPropagation();
      }}
    >
      {showPagination ? (
        <Row className="custom-pagination">
          <Col xs={24} sm={12}>
            <div className="display-container" style={containerStyle}>
              <div className="display-lbl">
                {customParsePagination(page, limit, count)}
              </div>
              <div className="display-option-container">
                <div className="display-title">
                  {intl.formatMessage({
                    id: "screen.label.display_number_items",
                  })}
                </div>
                <Select
                  className="display-select"
                  defaultValue={limit}
                  value={`${limit} ${intl.formatMessage({
                    id: "screen.label.count",
                  })}`}
                  onChange={(value) => {
                    setPage(1);
                    onChangePage(1, value);
                    setLimit(value);
                  }}
                >
                  {defaultPageLimitOptions.map((pageSize) => (
                    <Option key={pageSize.key} value={pageSize.key}>
                      {pageSize.value}{" "}
                      {intl.formatMessage({
                        id: "screen.label.count",
                      })}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
          </Col>
          <Col className="pagination-container" xs={24} sm={12}>
            <Pagination
              className="pagination-item"
              showLessItems={showLessItems}
              defaultPageSize={limit}
              pageSize={limit}
              onChange={(pageNum) => {
                setPage(pageNum);
                onChangePage(pageNum, limit);
              }}
              showSizeChanger={false}
              defaultCurrent={1}
              total={count}
              current={page}
            />
          </Col>
        </Row>
      ) : (
        <Row className="custom-pagination">
          <Col>
            <div className="display-container" style={containerStyle}>
              <div className="display-lbl">
                {customParsePagination(page, limit, count)}
              </div>
              <div className="display-option-container">
                <div className="display-title">
                  {intl.formatMessage({
                    id: "screen.label.display_number_items",
                  })}
                </div>
                <Select
                  className="display-select"
                  defaultValue={limit}
                  value={`${limit} ${intl.formatMessage({
                    id: "screen.label.count",
                  })}`}
                  onChange={(value) => {
                    setPage(1);
                    onChangePage(1, value);
                    setLimit(value);
                  }}
                >
                  {defaultPageLimitOptions.map((pageSize) => (
                    <Option key={pageSize.key} value={pageSize.key}>
                      {pageSize.value}{" "}
                      {intl.formatMessage({
                        id: "screen.label.count",
                      })}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
          </Col>
        </Row>
      )}
    </Beforeunload>
  );
}
export default CustomPagination;
