import { notification } from "antd";
import { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";

import { endpoints, parseEndpoint } from "../../../Services/endpoints";
import createFormData from "../../../utils/createFormData";
import { EditSmallCategoryScreenState } from "../types/IEditSmallCategoryScreenProps";

// @ts-expect-error TS7006
const useEditSmallCategoryScreen = (props) => {
  const intl = useIntl();
  const [state, customSetState] = useState<EditSmallCategoryScreenState>(() => {
    return {
      name: "",
      description: "",
      thumbnail: "",
    };
  });
  const setStateCallbackRef = useRef(() => {});
  useEffect(() => {
    const callBack = setStateCallbackRef.current;
    callBack();
    setStateCallbackRef.current = () => {};
  }, [state]);
  const setState = (
    data: EditSmallCategoryScreenState,
    callback = () => {},
  ) => {
    setStateCallbackRef.current = callback;
    customSetState((previousState) => {
      return {
        ...previousState,
        ...data,
      };
    });
  };

  useEffect(() => {
    props.dispatchSetPageTitle("screen.label.video");
    getSmallCategoryDetails();
  }, []);

  // @ts-expect-error TS7006
  const handleChange = (key, value) => {
    setState({ [key]: value });
  };
  const onUpdateSmallCategory = () => {
    const { name, description, thumbnail } = state;
    if (name && name.length > 0) {
      const requestBuilder = {
        method: "putRequest",
        url: parseEndpoint(endpoints.smallCategoryDetails, {
          smallCategoryId: getSmallCategoryId(),
        }),
        data: createFormData({ name, description, thumbnail }),
        successMessage: intl.formatMessage({
          id: "screen.label.success",
        }),
        successReRoute: "/global-video",
      };
      // @ts-expect-error TS7031
      props.dispatchApiRequest(requestBuilder, ({ data }) => {});
    } else {
      notification.error({
        message: intl.formatMessage({
          id: "screen.label.invalid_details",
        }),
      });
    }
  };
  const getSmallCategoryDetails = () => {
    const requestBuilder = {
      method: "getRequest",
      url: parseEndpoint(endpoints.videoSmallCategory, {
        smallCategoryId: getSmallCategoryId(),
      }),
      data: {},
    };
    // @ts-expect-error TS7031
    props.dispatchApiRequest(requestBuilder, ({ data }) => {
      setState({
        name: data.name,
        description: data.description,
        imageThumbnail: data.imageThumbnail,
      });
    });
  };
  const getSmallCategoryId = () => {
    const {
      match: { params },
    } = props;
    return params.id ? params.id : -1;
  };

  return {
    intl,
    state,
    setState,
    handleChange,
    onUpdateSmallCategory,
    getSmallCategoryDetails,
    getSmallCategoryId,
  };
};

export default useEditSmallCategoryScreen;
