import { Button, Row } from "antd";
import { ColumnType } from "antd/es/table/interface";
import moment from "moment";
import { FormattedMessage } from "react-intl";

import { Event } from "../../../../types/Event";
import { EventMember } from "../../../../types/EventMember";
import { User } from "../../../../types/User";
import { EventTitleColumn } from "../../../Components/molecules/events/EventTitleColumn";
import { Ptag } from "../../../Components/organisms";
import { EventActionMenu } from "../../../Components/organisms/event/EventActionMenu";
import { getObjUUID } from "../../../utils/common";
import {
  dateFormat,
  firstComeFirstServer,
  lottery,
} from "../../../utils/constants";
import {
  getEventStatus,
  getUserEventApplicationStatus,
} from "../../../utils/eventUtils";

const isExpired = (event: Event) => {
  return (
    moment().isAfter(moment(event.applicationDeadline)) ||
    moment().isAfter(moment(event.endsAt))
  );
};

const eventMemberPageLink = (event: Event) => {
  return `/dashboard/events.members/${getObjUUID(event)}`;
};

export const titleColumn = (isAdmin: boolean): ColumnType<Event> => ({
  title: <FormattedMessage id="screen.label.title" />,
  dataIndex: "name",
  key: "name",
  render: (text: string, record: Event) => {
    return (
      <EventTitleColumn
        eventUUID={record.uuid ?? ""}
        text={text}
        textStyle={{
          maxWidth: isAdmin ? "12vw" : "15vw",
        }}
      />
    );
  },
});

export const dateColumn = (isAdmin: boolean): ColumnType<Event> => ({
  title: <FormattedMessage id="screen.label.event_date" />,
  dataIndex: "beginsAt",
  key: "beginsAt",
  width: isAdmin ? "" : "10%",
  render: (text: string) => {
    return <span>{moment(text).local().format(dateFormat)}</span>;
  },
  responsive: ["md"],
});

export const receptionColumn = (isAdmin: boolean): ColumnType<Event> => ({
  title: <FormattedMessage id="screen.label.reception_method" />,
  dataIndex: "type",
  key: "type",
  width: isAdmin ? "" : "10%",
  render: (text: string) => {
    if (text === "lottery") {
      return lottery;
    }
    if (text === "first-come-first-serve") {
      return firstComeFirstServer;
    }
  },
  responsive: ["md"],
});

export const capacityColumn = (isAdmin: boolean): ColumnType<Event> =>
  isAdmin
    ? {
        title: <FormattedMessage id="screens.events.admin_capacity" />,
        dataIndex: "numberOfApplications/capacity",
        key: "numberOfApplications/capacity",
        responsive: ["md"],
        render: (_, record: Event) => {
          if (record.capacity) {
            return `${record?.EventMembers?.length}/${record.capacity}`;
          }
          return `${record?.EventMembers?.length}`;
        },
      }
    : {
        title: <FormattedMessage id="screen.label.capacity" />,
        dataIndex: "capacity",
        key: "capacity",
        width: "10%",
        responsive: ["md"],
        render: (_, record: Event) => {
          if (
            record.type === "first-come-first-serve" &&
            record.EventMembers.length >= record.capacity
          ) {
            return <FormattedMessage id="screen.label.events_full_des" />;
          }
          return record.capacity;
        },
      };

export const applicationDeadlineColumn = (): ColumnType<Event> => ({
  title: <FormattedMessage id="screens.events.applicationDeadline" />,
  dataIndex: "applicationDeadline",
  key: "applicationDeadline",
  render: (text: string) => {
    if (!text) return;
    if (moment(text).isAfter(moment())) {
      return <span>{moment(text).local().format(dateFormat)}</span>;
    }
    return <FormattedMessage id="screen.label.application_expired" />;
  },
  responsive: ["md"],
});

export const statusColumn = (): ColumnType<Event> => ({
  title: <FormattedMessage id="screens.events.event_status" />,
  dataIndex: "eventStatus",
  key: "eventStatus",
  render: (_, row: Event) => {
    return <Ptag>{getEventStatus(row)}</Ptag>;
  },
  responsive: ["md"],
});

export const createdDateColumn = (): ColumnType<Event> => ({
  title: <FormattedMessage id="screen.label.created_date" />,
  dataIndex: "createdAt",
  key: "createdAt",
  render: (text: string) => {
    return <span>{moment(text).local().format(dateFormat)}</span>;
  },
  responsive: ["md"],
});

export const applicationStatusColumn = (user: User): ColumnType<Event> => ({
  title: <FormattedMessage id="screens.events.application_status" />,
  dataIndex: "application_status",
  key: "application_status",
  width: "10%",
  render: (_, event: Event) => {
    return <Ptag>{getUserEventApplicationStatus(event, user.id)}</Ptag>;
  },
  responsive: ["md"],
});

export const userPerticipationColumn = (user: User): ColumnType<Event> => ({
  title: <FormattedMessage id="screens.events.user_participation_status" />,
  dataIndex: "user_participation_status",
  key: "user_participation_status",
  width: "10%",
  render: (_, row: Event) => {
    const eventMembers = Array.isArray(row.EventMembers)
      ? row.EventMembers
      : [];
    const currentUserParticipation = eventMembers.filter(
      (ev: EventMember) => ev.userId === user.id,
    );
    if (
      currentUserParticipation.length > 0 &&
      currentUserParticipation[0].wasPresent
    ) {
      return (
        <Ptag>
          <FormattedMessage id="screens.events.participation_confirmed" />
        </Ptag>
      );
    }

    return "";
  },
  responsive: ["md"],
});

type UserActionColumnArgs = {
  user: User;
  onUnRegisterEvent: (event: Event) => void;
  onRegisterForEvent: (event: Event) => void;
};

export const userActionColumn = ({
  user,
  onUnRegisterEvent,
  onRegisterForEvent,
}: UserActionColumnArgs): ColumnType<Event> => ({
  title: "",
  dataIndex: "actions",
  key: "action",
  render: (_, event: Event) => {
    if (isExpired(event)) {
      return null;
    }
    const eventMembers = Array.isArray(event.EventMembers)
      ? event.EventMembers
      : [];

    const isCurrentUserApplied =
      eventMembers.filter(
        // eslint-disable-next-line react/prop-types
        (ev: EventMember) => ev.userId === user.id,
      ).length > 0;
    return isCurrentUserApplied ? (
      <Button
        onClick={() => {
          onUnRegisterEvent(event);
        }}
        type="primary"
      >
        <FormattedMessage id="screens.events.cancel_application_for_participation" />
      </Button>
    ) : (
      <Button
        onClick={() => {
          onRegisterForEvent(event);
        }}
        type="primary"
      >
        <FormattedMessage id="screen.label.participation_application" />
      </Button>
    );
  },
});

type AdminActionColumnArgs = {
  onConfirmDelete: (event: Event) => void;
  onToggleAcceptMember: (event: Event) => void;
  onCreateEventNotification: (event: Event) => void;
  onCreateEventSurvey: (event: Event) => void;
  onEdit: (event: Event) => void;
};

export const adminActionColumn = ({
  onConfirmDelete,
  onToggleAcceptMember,
  onCreateEventNotification,
  onCreateEventSurvey,
  onEdit,
}: AdminActionColumnArgs): ColumnType<Event> => ({
  key: "action",
  dataIndex: "action",
  render: (_, event: Event) => {
    return (
      <Row align="middle" justify="center">
        <EventActionMenu
          event={event}
          eventMemberPageLink={eventMemberPageLink}
          onConfirmDelete={onConfirmDelete}
          isExpired={isExpired}
          onToggleAcceptMember={onToggleAcceptMember}
          onEdit={onEdit}
          onCreateEventNotification={onCreateEventNotification}
          onCreateEventSurvey={onCreateEventSurvey}
        />
      </Row>
    );
  },
});
