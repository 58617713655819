import { useCallback, useEffect, useRef } from "react";
import { useIntl } from "react-intl";

import { endpoints, parseEndpoint } from "../../../Services/endpoints";
import { useStateWithRef } from "../../../utils/hooks";
import { getInitStorageValue } from "../../../utils/localStorage";
import {
  IProjectReviewScreenProps,
  ProjectReviewScreenState,
} from "../types/IProjectReviewScreenProps";
const PageSaveId = "ProjectReviewScreen";
const pageKey = `pagination_page_${PageSaveId}`;
const limitKey = `pagination_limit_${PageSaveId}`;

const useProjectReviewScreen = (props: IProjectReviewScreenProps) => {
  const intl = useIntl();
  const { updatePageTitle, apiRequest } = props.context;
  const [state, customSetState] = useStateWithRef(() => {
    return {
      page: getInitStorageValue(pageKey, 1),
      limit: getInitStorageValue(limitKey, process.env.REACT_APP_PAGE_SIZE),
      reviews: [],
      modalVisible: false,
    };
  });

  useEffect(() => {
    updatePageTitle("screen.label.examination_outcome");
    fetchReviews();
  }, []);
  const setStateCallbackRef = useRef(() => {});
  useEffect(() => {
    const callBack = setStateCallbackRef.current;
    callBack();
    setStateCallbackRef.current = () => {};
  }, [state]);
  const setState = useCallback(
    (data: ProjectReviewScreenState, callback = () => {}) => {
      setStateCallbackRef.current = callback;
      customSetState((previousState: ProjectReviewScreenState) => {
        return {
          ...previousState,
          ...data,
        };
      });
    },
    [customSetState],
  );

  const getProjectId = () => {
    const {
      match: { params },
    } = props;
    return params.id ? params.id : -1;
  };
  const fetchReviews = () => {
    const requestBuilder = {
      method: "getRequest",
      url: parseEndpoint(endpoints.projectPublishedReviewResult, {
        projectId: getProjectId(),
      }),
      data: {},
    };
    // @ts-expect-error TS2345
    apiRequest(requestBuilder, ({ data }) => {
      setState({ reviews: data });
    });
  };

  const showModalVisible = useCallback(
    (submittedProjectId: string) => {
      setState({
        submittedProjectId,
        modalVisible: true,
      });
    },
    [setState],
  );
  const hideModalVisible = () => {
    setState({
      modalVisible: false,
    });
  };

  return {
    intl,
    state,
    showModalVisible,
    hideModalVisible,
    getProjectId,
    fetchReviews,
    setState,
  };
};

export default useProjectReviewScreen;
