import { notification } from "antd";
import { push } from "connected-react-router";
import { all, call, put, takeLatest, takeLeading } from "redux-saga/effects";

import AuthActions from "../Redux/AuthRedux";
import UserActions, { UserTypes } from "../Redux/UserRedux";
import { endpoints, parseEndpoint } from "../Services/endpoints";

// @ts-expect-error TS7006
function* attemptRefreshProfile(api, action) {
  const { data } = action;

  // @ts-expect-error TS7057
  const refreshProfileResponse = yield call(
    api.putRequest,
    parseEndpoint(endpoints.updateUserProfile, { data }),
    data,
  );

  if (refreshProfileResponse.ok) {
    switch (refreshProfileResponse.data.status) {
      case "success":
        yield put(UserActions.userUpdateProfileSuccess());
        yield put(AuthActions.loginSuccess(refreshProfileResponse.data.data));
        break;
      case "fail":
      case "error":
        yield put(
          UserActions.userRefreshProfileFailure(
            // @ts-expect-error TS2554
            refreshProfileResponse.data.data,
          ),
        );
        break;
      default:
        break;
    }
  } else {
    notification.error({
      message: refreshProfileResponse.problem,
    });
    yield put(
      // @ts-expect-error TS2554
      UserActions.userRefreshProfileFailure(refreshProfileResponse.problem),
    );
    if (refreshProfileResponse.status === 401) {
      // @ts-expect-error TS2322
      window.location = "/login";
    }
  }
}

// @ts-expect-error TS7006
function* attemptGetProfile(api, action) {
  // @ts-expect-error TS7057
  const getProfileResponse = yield call(
    api.getRequest,
    parseEndpoint(endpoints.getUserProfile),
  );

  if (getProfileResponse.ok) {
    switch (getProfileResponse.data.status) {
      case "success":
        yield put(UserActions.userGetProfileSuccess());
        yield put(AuthActions.loginSuccess(getProfileResponse.data.data));
        break;
      case "fail":
      case "error":
        if (typeof getProfileResponse.data.data === "string") {
        }
        yield put(
          // @ts-expect-error TS2554
          UserActions.userGetProfileFailure(getProfileResponse.data.data),
        );
        break;
      default:
        break;
    }
  } else {
    notification.error({
      message: getProfileResponse.problem,
    });
    // @ts-expect-error TS2554
    yield put(UserActions.userGetProfileFailure(getProfileResponse.problem));
    if (getProfileResponse.status === 401) {
      localStorage.removeItem("persist:root");
      // @ts-expect-error TS2322
      window.location = "/login";
    }
  }
}

// @ts-expect-error TS7006
function* attemptUpdateProfile(api, action) {
  const { data, message } = action;

  // @ts-expect-error TS7057
  const updateProfileResponse = yield call(
    api.putRequest,
    parseEndpoint(endpoints.updateUserProfile, { data }),
    data,
  );

  if (updateProfileResponse.ok) {
    switch (updateProfileResponse.data.status) {
      case "success":
        notification.success({
          message: message,
        });
        yield put(UserActions.userUpdateProfileSuccess());
        yield put(AuthActions.loginSuccess(updateProfileResponse.data.data));
        break;
      case "fail":
      case "error":
        yield put(
          // @ts-expect-error TS2554
          UserActions.userUpdateProfileFailure(updateProfileResponse.data.data),
        );
        break;
      default:
        break;
    }
  } else {
    notification.error({
      message: updateProfileResponse.problem,
    });
    yield put(
      // @ts-expect-error TS2554
      UserActions.userUpdateProfileFailure(updateProfileResponse.problem),
    );
    if (updateProfileResponse.status === 401) {
      // @ts-expect-error TS2322
      window.location = "/login";
    }
  }
}

// @ts-expect-error TS7006
function* attemptGetAllUsers(api, { page, filter, sort }) {
  // @ts-expect-error TS7057
  const usersResponse = yield call(
    api.getRequest,
    `users?page=${page}&${filter ? `filter=${encodeURI(filter)}` : ``}${
      sort && sort !== "" ? `&sort=${sort}` : ``
    }`,
  );
  if (usersResponse.ok) {
    switch (usersResponse.data.status) {
      case "success":
        // @ts-expect-error TS2554
        yield put(UserActions.userGetsSuccess(usersResponse.data.data));
        break;
      case "fail":
      case "error":
        if (typeof usersResponse.data.data === "string") {
        }
        // @ts-expect-error TS2554
        yield put(UserActions.userGetsFailure(usersResponse.data.data));
        break;
      default:
        break;
    }
  } else {
    notification.error({
      message: usersResponse.problem,
    });
    // @ts-expect-error TS2554
    yield put(UserActions.userGetsFailure(usersResponse.problem));
    if (usersResponse.status === 401) {
      // @ts-expect-error TS2322
      window.location = "/login";
    }
  }
}

// @ts-expect-error TS7006
function* attemptCreateUserProfile(api, action) {
  const { data, message } = action;

  // @ts-expect-error TS7057
  const usersResponse = yield call(
    api.postRequest,
    parseEndpoint(endpoints.createUsers),
    data,
  );
  if (usersResponse.ok) {
    switch (usersResponse.data.status) {
      case "success":
        if (
          Array.isArray(usersResponse.data.data) &&
          usersResponse.data.data.length > 0
        ) {
          yield put(
            // @ts-expect-error TS2554
            UserActions.userCreateProfileFailure(usersResponse.data.data),
          );
        } else {
          notification.success({
            message: message,
          });
          yield put(push("/dashboard/members"));
        }

        break;
      case "fail":
      case "error":
        yield put(
          // @ts-expect-error TS2554
          UserActions.userCreateProfileFailure(usersResponse.data.data),
        );
        break;
      default:
        break;
    }
  } else {
    notification.error({
      message: usersResponse.problem,
    });
    // @ts-expect-error TS2554
    yield put(UserActions.userCreateProfileFailure(usersResponse.problem));
    if (usersResponse.status === 401) {
      // @ts-expect-error TS2322
      window.location = "/login";
    }
  }
}

// @ts-expect-error TS7006
function* userProfileSaga(api) {
  yield all([
    takeLatest(UserTypes.USER_GET_PROFILE_REQUEST, attemptGetProfile, api),
    takeLatest(
      UserTypes.USER_UPDATE_PROFILE_REQUEST,
      attemptUpdateProfile,
      api,
    ),
    takeLatest(
      UserTypes.USER_REFRESH_PROFILE_REQUEST,
      attemptRefreshProfile,
      api,
    ),
    // @ts-expect-error TS2345
    takeLatest(UserTypes.USER_GETS_REQUEST, attemptGetAllUsers, api),
    takeLeading(
      UserTypes.USER_CREATE_PROFILE_REQUEST,
      attemptCreateUserProfile,
      api,
    ),
  ]);
}

export default userProfileSaga;
