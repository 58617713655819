import { useIntl } from "react-intl";

import styles from "@/features/customerSupport/components/WatchLoginSupportVideoButton/styles.module.scss";
import { WatchLoginSupportVideoButtonProps } from "@/features/customerSupport/components/WatchLoginSupportVideoButton/type";
import { LinkStyleButton } from "@/shared/buttons/components/LinkStyleButton/component";

export const WatchLoginSupportVideoButton = ({
  onClick,
}: WatchLoginSupportVideoButtonProps) => {
  const intl = useIntl();
  const buttonLabel = intl.formatMessage({
    id: "screen.label.review.login_movie_button_label",
  });

  return (
    <LinkStyleButton
      onClick={onClick}
      className={styles.login_support_movie_button}
      data-testid="login-support-movie-button"
    >
      {buttonLabel}
    </LinkStyleButton>
  );
};
