import { useIntl } from "react-intl";

import { UserNameLabelProps } from "./type";

/*
 *  ラベルに表示するユーザー名を返します。
 *  ユーザーの状態によって表示するユーザー名の内容が変わります。
 * @param {UserNameLabelProps} props
 * @returns {string}
 */
export const useDisplayUserName = ({
  name,
  hasChangedPassword,
  deletedAt,
  stoppedAt,
}: UserNameLabelProps): string => {
  const intl = useIntl();
  if (!hasChangedPassword) {
    return intl.formatMessage({
      id: "screen.label.registration_waiting_invitation",
    });
  }
  if (deletedAt) {
    return intl.formatMessage({ id: "screen.label.deleted_user" });
  }
  if (stoppedAt) {
    return intl.formatMessage({ id: "screen.label.stopping_user" }, { name });
  }
  return name;
};
