import "quill/dist/quill.snow.css"; // Add css for snow theme

import { useCallback, useContext, useEffect } from "react";
import { useQuill } from "react-quilljs";

import { sanitizeHtml } from "@/utils/sanitizeHtml";

import { I18NContext } from "../../../i18n/context";
import { usePrevious } from "../../../utils/hooks";
import { QuillEditorProps } from "./QuillEditorProps";

const bindings = {
  bold: {
    key: "B",
    shortKey: true,
    handler: () => {},
  },
  italic: {
    key: "I",
    shortKey: true,
    handler: () => {},
  },
  underline: {
    key: "U",
    shortKey: true,
    handler: () => {},
  },
  indent: {
    key: 9,
    handler: () => {},
  },
  outdent: {
    key: 9,
    shiftKey: true,
    handler: () => {},
  },
};
const formats = ["link"];

export default function QuillEditor(props: QuillEditorProps) {
  const {
    defaultId = `${new Date().getTime()}`,
    defaultValue,
    placeholder,
    onChangeValue,
    contentStyle = {},
    readOnly,
  } = props;
  const i18nContent = useContext(I18NContext);
  const { quill, quillRef, Quill } = useQuill({
    modules: {
      magicUrl: true,
      toolbar: {
        container: "#toolbar",
      },
      keyboard: {
        bindings: bindings,
      },
    },
    placeholder,
    theme: "snow",
    bounds: `#quill-editor-container-${defaultId}`,
    formats: formats,
  });

  // @ts-expect-error TS2345
  const prevReadOnly = usePrevious(readOnly);

  const setText = useCallback(
    (defaultValue: string) => {
      if (quill) {
        quill.root.innerHTML = sanitizeHtml(defaultValue ?? "");
      }
    },
    [quill],
  );

  // setTextとdefaultValueの変更タイミングでのみsetTextを実行する
  useEffect(() => {
    setText(defaultValue);
  }, [setText, defaultValue]);

  useEffect(() => {
    if (quill) {
      quill.enable(!readOnly);
      // @ts-expect-error TS2551
      quill.container.classList.add(i18nContent.locale);
      // readOnlyから編集状態に変わった場合にのみfocusを実行する
      if (prevReadOnly && !readOnly) {
        quill.focus();
      }

      // 保存orキャンセルが押されたタイミングで実行する
      if (!prevReadOnly && readOnly) {
        setText(defaultValue);
      }

      quill.on("text-change", () => {
        const content = quill.root.innerHTML;
        onChangeValue(sanitizeHtml(content));
      });
      const editorElelment = document.querySelector(
        `#quill-editor-container-${defaultId}`,
      );
      // @ts-expect-error TS18047
      const linkElement = editorElelment.querySelector("div.ql-editor");
      // @ts-expect-error TS18047
      const linkElements = linkElement.querySelectorAll("a");
      if (linkElements && linkElements.length > 0) {
        linkElements.forEach((a) => {
          a.addEventListener("click", (e) => {
            e.stopPropagation();
          });
        });
      }
      return () => {
        if (linkElements && linkElements.length > 0) {
          linkElements.forEach((a) => {
            a.removeEventListener("click", () => {});
          });
        }
      };
    }
    return () => {};
  }, [
    quill,
    prevReadOnly,
    readOnly,
    defaultValue,
    setText,
    i18nContent.locale,
    defaultId,
    onChangeValue,
  ]);
  if (Quill && !quill) {
    const MagicUrl = require("quill-magic-url").default;
    Quill.register("modules/magicUrl", MagicUrl);
  }
  return (
    <div style={{ cursor: "text" }}>
      <div
        id={`quill-editor-container-${defaultId}`}
        // @ts-expect-error TS2559
        style={{
          ...contentStyle,
        }}
        ref={quillRef}
      />
      <div id="toolbar" style={{ height: 1, display: "none" }} />
    </div>
  );
}
