import { Col, Form, notification, Row } from "antd";
import PropTypes from "prop-types";
import { FC, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import {
  ButtonCV,
  ButtonOption,
  ButtonUndo,
  IconDelete,
  IconEdit,
} from "../../../../Components/atoms";
import { Input } from "../../../../Components/molecules";
import { endpoints, parseEndpoint } from "../../../../Services/endpoints";
import { isEmailValid } from "../../../../utils/common";

type SubEmail = {
  id: string;
  email: string;
  userId: number;
  companyId: number;
  token: string;
  createdAt: string;
  updatedAt: string;
  verified: boolean;
  /// Addtional
  registered?: boolean;
};

type ISubEmailProps = {
  apiRequest: (...args: any[]) => any;
  userId: number;
  companyId: number;
  subEmails?: SubEmail[];
} & React.HTMLAttributes<Element>;

type ItemProps = {
  emailObj: SubEmail;
  onDeleteEmail: (id: string) => void;
  onCreateSubEmail: (id: string, email: string) => void;
  onUpdateSubEmail: (id: string, email: string) => void;
};

const ProfileSubEmailItem = function (props: ItemProps) {
  const intl = useIntl();
  const { emailObj, onDeleteEmail, onCreateSubEmail, onUpdateSubEmail } = props;

  const [email, setEmail] = useState("");
  const [editing, setEditing] = useState(false);

  useEffect(() => {
    if (emailObj) {
      setEmail(emailObj.email);
    }
  }, [emailObj]);

  const isNewEmail = (emailObj: SubEmail) => {
    if (emailObj.email == "" && emailObj.token == "") {
      return true;
    }
    return false;
  };

  const editable = useMemo(() => {
    if (isNewEmail(emailObj)) {
      return true;
    }
    return editing;
  }, [editing, emailObj]);

  return (
    <div
      key={emailObj.id}
      style={{
        marginBottom: 16,
      }}
    >
      <Row
        align="middle"
        style={
          editable
            ? {
                paddingBottom: 6,
              }
            : {
                paddingBottom: 6,
                borderBottomColor: "#dddddd",
                borderBottomWidth: 1,
                borderBottomStyle: "solid",
              }
        }
      >
        <Col
          flex={1}
          style={{
            marginRight: 10,
          }}
        >
          <Input
            bordered={editable}
            disabled={!editable}
            className="user-email"
            key={emailObj.id}
            value={email}
            placeholder={intl.formatMessage({
              id: "screen.label.email",
            })}
            onChange={(event) => {
              setEmail(event.target.value);
            }}
          />
        </Col>
        {editable ? (
          <>
            <ButtonCV
              style={{ fontSize: 11 }}
              className="offset-right-16"
              onClick={() => {
                if (isEmailValid(email)) {
                  if (emailObj.registered) {
                    onUpdateSubEmail(emailObj.id, email);
                  } else {
                    onCreateSubEmail(emailObj.id, email);
                  }
                } else {
                  notification.warning({
                    message: intl.formatMessage({
                      id: "screen.label.invalid_email_address",
                    }),
                  });
                }
              }}
            >
              <FormattedMessage id={"screen.label.sub_email_btn"} />
            </ButtonCV>
            <ButtonUndo
              style={{ fontSize: 11 }}
              onClick={() => {
                setEditing(false);
                if (isNewEmail(emailObj)) {
                  onDeleteEmail(emailObj.id);
                }
              }}
            >
              <FormattedMessage id={"screen.label.cancel"} />
            </ButtonUndo>
          </>
        ) : (
          <>
            <div
              className="offset-right-16"
              onClick={() => {
                if (editing) {
                  setEmail(emailObj.email);
                }
                setEditing(!editing);
              }}
            >
              {IconEdit}
            </div>
            <div
              onClick={() => {
                onDeleteEmail(emailObj.id);
              }}
            >
              {IconDelete}
            </div>
          </>
        )}
      </Row>

      {emailObj.registered && !emailObj.verified && (
        <p
          style={{
            color: "rgba(0, 0, 0, 0.45)",
            fontSize: 12,
          }}
        >
          <FormattedMessage id="screen.label.sub_email_unconfrim_msg" />
        </p>
      )}
    </div>
  );
};

const ProfileSubEmail: FC<ISubEmailProps> = function (props) {
  const intl = useIntl();
  const { apiRequest, userId, companyId, subEmails } = props;
  const [emails, setEmails] = useState<Array<SubEmail>>([]);

  useEffect(() => {
    setEmails(subEmails ?? []);
  }, [subEmails]);

  const onCreateSubEmail = (id: string, email: string) => {
    const subEmailPostRequest = {
      method: "postRequest",
      url: parseEndpoint(endpoints.subEmail, {
        userId,
      }),
      data: { email },
    };
    apiRequest(subEmailPostRequest, ({ data }: { data: SubEmail }) => {
      if (data) {
        notification.success({
          message: intl.formatMessage({
            id: "screen.label.sub_email_success_msg",
          }),
        });
        data.registered = true;
        onUpdateEmailsObj(id, data);
      }
    });
  };

  const onUpdateSubEmail = (id: string, email: string) => {
    const subEmailPutRequest = {
      method: "putRequest",
      url: parseEndpoint(endpoints.subEmailWithId, {
        userId,
        emailId: id,
      }),
      data: { email },
    };
    apiRequest(subEmailPutRequest, ({ data }: { data: SubEmail }) => {
      if (data) {
        notification.success({
          message: intl.formatMessage({
            id: "screen.label.sub_email_success_msg",
          }),
        });
        data.registered = true;
        onUpdateEmailsObj(id, data);
      }
    });
  };

  const onDeleteSubEmail = (id: string) => {
    const subEmailDelRequest = {
      method: "deleteRequest",
      url: parseEndpoint(endpoints.subEmailWithId, {
        userId,
        emailId: id,
      }),
      data: {},
    };
    apiRequest(subEmailDelRequest, ({ data }: { data: number }) => {
      if (data) {
        setEmails(emails.filter((a) => a.id !== id));
      }
    });
  };

  /// Btn Actions

  const onAddSubEmails = () => {
    const newSubEmail = {
      id: new Date().getTime().toString(),
      verified: false,
      userId: userId,
      companyId: companyId,
      token: "",
      email: "",
      registered: false,
      createdAt: "",
      updatedAt: "",
    };
    const cloneEmails = [...emails];
    cloneEmails.push(newSubEmail);
    setEmails(cloneEmails);
  };

  const onDeleteEmail = (id: string) => {
    const cloneEmails = [...emails];
    const findIndex = cloneEmails.findIndex((a) => a.id === id);
    if (findIndex !== -1) {
      if (cloneEmails[findIndex].registered) {
        onDeleteSubEmail(id);
      } else {
        setEmails(emails.filter((a) => a.id !== id));
      }
    }
  };

  const onUpdateEmailsObj = (id: string, newOne: SubEmail) => {
    const cloneEmails = [...emails];
    const findIndex = cloneEmails.findIndex((a) => a.id === id);
    if (findIndex !== -1) {
      cloneEmails[findIndex] = newOne;
    }
    setEmails(cloneEmails);
  };

  return (
    <Form.Item label={<FormattedMessage id="screen.label.sub_email" />}>
      {emails.map((emailObj) => {
        return (
          <ProfileSubEmailItem
            key={emailObj.id}
            emailObj={emailObj}
            onCreateSubEmail={onCreateSubEmail}
            onDeleteEmail={onDeleteEmail}
            onUpdateSubEmail={onUpdateSubEmail}
          />
        );
      })}
      <ButtonOption onClick={onAddSubEmails}>
        + <FormattedMessage id="screen.lable.sub_email_add" />
      </ButtonOption>
    </Form.Item>
  );
};

ProfileSubEmail.propTypes = {
  // @ts-expect-error TS2322
  apiRequest: PropTypes.func,
  // @ts-expect-error TS2322
  userId: PropTypes.number,
  // @ts-expect-error TS2322
  companyId: PropTypes.number,
  subEmails: PropTypes.array,
};

export default ProfileSubEmail;
