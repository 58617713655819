import { CategoryNameTag } from "../CategoryNameTag/component";
import { HandOverNoteTag } from "../HandOverNoteTag/component";
import { ProjectIdLabel } from "../ProjectIdLabel/component";
import { SubmittedProjectMembersLabel } from "../SubmittedProjectMembersLabel/component";
import { SubmittedProjectNameLabel } from "../SubmittedProjectNameLabel/component";
import { SubmittedTimeLabel } from "../SubmittedTimeLabel/component";
import styles from "./styles.module.scss";
import { ProjectInfoCellProps } from "./type";

export const ProjectInfoCell = (props: ProjectInfoCellProps) => {
  const {
    projectId,
    projectName,
    projectMembers,
    categoryName,
    hasHandOverNote,
    submittedAt,
  } = props;
  return (
    <div className={styles.project_info_cell_container}>
      <div
        className={`${styles.project_info_cell_tag_area} ${styles.project_info_cell_inner_row} `}
      >
        <CategoryNameTag categoryName={categoryName} />
        <HandOverNoteTag hasHandOverNote={hasHandOverNote} />
      </div>
      <SubmittedProjectNameLabel projectName={projectName} />
      <div
        className={`${styles.project_info_cell_sub_info_area} ${styles.project_info_cell_inner_row}`}
      >
        <SubmittedProjectMembersLabel projectMembers={[...projectMembers]} />
        <SubmittedTimeLabel submittedTimeISO={submittedAt} />
        <ProjectIdLabel projectId={projectId} />
      </div>
    </div>
  );
};
