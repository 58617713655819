import { Upload } from "antd";
import { UploadChangeParam, UploadFile } from "antd/lib/upload/interface";
import axios from "axios";
import PropTypes from "prop-types";
import { ReactNode, useMemo } from "react";
import { useSelector } from "react-redux";

import { loadMime } from "../../../utils/common";
import { previewFiletypes, supportedFiletypes } from "../fileuploader";

const { Dragger } = Upload;

type GeneralUploaderComponentProps<T> = {
  baseAction: string;
  children: ReactNode;
  onChange: (info: UploadChangeParam<UploadFile<T>>) => void;
  handleBeforeUpload: (file: File) => void;
};

const GeneralUploaderComponent = <T,>(
  props: GeneralUploaderComponentProps<T>,
) => {
  const user = useSelector((state: any) => state.auth?.payload);

  const handleCustomRequest = async ({
    action,
    data,
    file,
    filename,
    headers,
    onError,
    onProgress,
    onSuccess,
    withCredentials,
  }: {
    action: string;
    data: any;
    file: any;
    filename: string;
    headers: any;
    onError: any;
    onProgress: any;
    onSuccess: any;
    withCredentials: boolean;
  }) => {
    let fileClone = file;
    const formData = new FormData();
    if (data) {
      Object.keys(data).forEach((key) => {
        formData.append(key, data[key]);
      });
    }

    if (!supportedFiletypes.includes(fileClone.type)) {
      try {
        const mimeRes = await loadMime(fileClone);
        if (mimeRes && supportedFiletypes.includes(mimeRes.mime)) {
          fileClone = new File([file], file.name, {
            type: mimeRes.mime,
          });
        } else {
          onError(new Error("Unsupported file type"));
          return;
        }
      } catch (error) {
        onError(new Error("Unsupported file type"));
        return;
      }
    }

    formData.append(filename, fileClone);

    let uploadURL = `${action}/upload-files`;

    if (fileClone && previewFiletypes.includes(fileClone.type)) {
      uploadURL = `${action}/upload-files-transform`;
    }

    axios
      .post(uploadURL, formData, {
        withCredentials,
        headers,
        onUploadProgress: ({ total, loaded }) => {
          onProgress(
            {
              percent: Math.round((loaded / total) * 100).toFixed(2),
            },
            file,
          );
        },
      })
      .then(({ data: response }) => {
        if (response.status === "fail" || response.status === "error") {
          onError(new Error(response.data));
        } else {
          onSuccess(response, file);
        }
      })
      .catch(onError);

    return {
      abort() {
        console.log("upload progress is aborted.");
      },
    };
  };

  const draggerProps = useMemo(() => {
    return {
      onChange: props.onChange,
      action: props.baseAction,
      multiple: false,
      accept: supportedFiletypes.join(", "),
      headers: {
        Authorization: `Bearer ${user.token}`,
        alphafrontEndPath: window.location.href,
      },
      customRequest: handleCustomRequest,
      beforeUpload: props.handleBeforeUpload,
    };
  }, [props.baseAction, user.token, props.onChange, props.handleBeforeUpload]);

  // @ts-expect-error TS2322
  return <Dragger {...draggerProps}>{props.children}</Dragger>;
};
GeneralUploaderComponent.propTypes = {
  baseAction: PropTypes.string.isRequired,
  children: PropTypes.node,
  handleBeforeUpload: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};
export default GeneralUploaderComponent;
