import { Menu, MenuItemProps, MenuProps } from "antd";
import styled from "styled-components";

export type ItemProps = {
  selected?: boolean;
} & MenuItemProps;

export const MenuContainer = styled(Menu)`
  background-color: #475865 !important;
  min-height: 100%;
`;

export const ProjectMenuContainer = styled(Menu)<any>``;

export const MainMenuContainer = styled(MenuContainer)`
  min-height: 100%;
`;

export const MenuItem = styled(Menu.Item)<ItemProps>`
  border-left-color: ${(props) =>
    props.selected ? "#1f86e9" : "transparent"} !important;
  border-left-width: 5px;
  border-left-style: solid;
  background-color: ${(props) =>
    props.selected ? "#272c41" : "transparent"} !important;
  padding-left: 17px !important;
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
  cursor: auto;

  > span > a {
    display: block;
  }

  .unhoverable-text {
    color: #dddddd;
    &:hover {
      color: #dddddd;
    }
  }
`;

export const SideFixedMenu = (props: MenuProps) => {
  return <MenuContainer {...props} />;
};

export const SideFloatingMenu = (props: MenuProps) => {
  return <MainMenuContainer {...props} />;
};

export const FixedMenuItem = (props: ItemProps) => {
  return <MenuItem {...props} />;
};

export const ProjectFixedMenuItem = styled(FixedMenuItem)`
  min-height: 43px !important;
`;

export const ProjectMenuTitleSpan = styled("span")`
  overflow: hidden;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const FloatMenuItem = (props: ItemProps) => {
  return (
    <MenuItem {...props} itemIcon={null} title={null}>
      {props.children}
    </MenuItem>
  );
};

export const ProjectFloatMenuItem = styled(FloatMenuItem)`
  min-height: 43px !important;
`;

export const ProjectTabMenuItem = styled(FixedMenuItem)`
  min-height: 39px !important;
  border-left-width: 0px !important;
`;
