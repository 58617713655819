import styles from "./styles.module.scss";
import { ColorTagProps } from "./type";

export const ColorTag = ({ label, color }: ColorTagProps) => {
  const colorClass =
    color === "green"
      ? styles.green_tag
      : color === "gray"
      ? styles.gray_tag
      : "";
  return <span className={`${styles.tag} ${colorClass}`}>{label}</span>;
};
