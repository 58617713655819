import { BiTimeFive } from "react-icons/bi";

import { useTimeInput } from "./hooks";
import styles from "./styles.module.scss";
import { TimeRangeInputProps } from "./type";

export const TimeRangeInput = ({
  className,
  startBind,
  endBind,
}: TimeRangeInputProps) => {
  const startProps = useTimeInput(startBind);
  const endProps = useTimeInput(endBind);

  return (
    <div className={`${styles.inputWrapper} ${className}`}>
      <BiTimeFive className={styles.icon} />
      <input className={styles.timeInput} {...startProps} placeholder="00:00" />
      <span>~</span>
      <input className={styles.timeInput} {...endProps} placeholder="00:00" />
    </div>
  );
};
