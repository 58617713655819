import PropTypes from "prop-types";
import ReactPlayer from "react-player/lazy";

const Embed = ({ block, contentState }) => {
  const entity = contentState.getEntity(block.getEntityAt(0));
  const { src, height, width } = entity.getData();
  let cleansrc = "";
  cleansrc = src.replace("player.vimeo.com/video", "vimeo.com");
  cleansrc = src.replace("embed/", "watch?v=");
  cleansrc = src.replace("/embed/", "/watch/");
  cleansrc = src.replace("youtube.com/embed/", "youtu.be/");
  return (
    <div className="player-wrapper">
      <ReactPlayer
        loop={false}
        controls
        className="react-player"
        url={cleansrc}
        width="100%"
        height="100%"
        config={{
          file: {
            attributes: {
              controlsList: "nodownload",
            },
          },
        }}
      />
    </div>
  );
};

Embed.propTypes = {
  block: PropTypes.object,
  contentState: PropTypes.object,
};

export default Embed;
