import { Col, Row } from "antd";
import moment from "moment";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Option, Select } from "../../dropdown";
import RelatedTaskCreation from "./RelatedTaskCreation";

type Task = {
  id: number;
  title: string;
  description: string;
  status: string;
  projectId: number;
  timelimit: string;
};
type Props = {
  index: string;
  item: Task;
  disabled: boolean;
  projectId: string;
  onChangeStatus: (rowId: string, status: string) => void;
  onCreatedTask: (id: number) => void;
};

const RelatedTaskItem = (props: Props) => {
  const intl = useIntl();
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Row align="middle" justify="start" style={{ marginBottom: 10 }}>
        <Col
          span={10}
          onClick={() => {
            if (!props.disabled) {
              setShowModal(true);
            }
          }}
        >
          <span
            style={{
              color: props.disabled ? "#333" : "#1f86e9",
              cursor: "pointer",
            }}
          >
            {props.item.title}
          </span>
        </Col>
        <Col span={9}>
          <FormattedMessage id="screen.label.deadline" />{" "}
          {props.item.timelimit && props.item.timelimit !== "" ? (
            moment(props.item.timelimit).local().format("YYYY/MM/DD")
          ) : (
            <FormattedMessage id="screen.label.unset" />
          )}
        </Col>
        <Col span={5}>
          <Select
            disabled={props.disabled}
            style={{ width: "100%" }}
            defaultValue={
              props.item.status && props.item.status !== ""
                ? props.item.status
                : "PENDING"
            }
            value={
              props.item.status && props.item.status !== ""
                ? props.item.status
                : "PENDING"
            }
            onChange={(value) => {
              // @ts-expect-error TS2345
              props.onChangeStatus(props.item.id, value);
            }}
          >
            <Option value="PENDING">
              {intl.formatMessage({
                id: "screen.label.unexecuted",
              })}
            </Option>
            <Option value="PROGRESS">
              {intl.formatMessage({
                id: "screen.label.in_progress",
              })}
            </Option>
            <Option value="DONE">
              {intl.formatMessage({
                id: "screen.label.completion",
              })}
            </Option>
          </Select>
        </Col>
      </Row>
      <RelatedTaskCreation
        showModal={showModal}
        task={props.item}
        projectId={props.projectId}
        onCreatedTask={props.onCreatedTask}
        onHideModal={() => {
          setShowModal(false);
        }}
      />
    </>
  );
};

export default RelatedTaskItem;
