import { DownOutlined, InfoCircleFilled, UpOutlined } from "@ant-design/icons";
import { Col, Row, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

import { Checkbox } from "../../../atoms";

type Props = {
  tasks: Array<any>;
  item: {
    name: string;
    description: string;
  };
  companyPlan: string;
  checked: boolean;
  showTask: boolean;
  disabled: boolean;
  onShowTask: () => void;
  onChecked?: (checked: boolean) => void;
};

const ProjectCheckPointItem = (props: Props) => {
  const [checked, setChecked] = useState(props.checked);

  useEffect(() => {
    setChecked(props.checked);
  }, [props.checked]);

  return (
    <>
      <Row align="top" justify="space-between">
        <Col lg={19} xs={12} md={10} sm={10}>
          <Row align="top">
            <Col span={22}>
              <Checkbox
                className="offset-right-16"
                checked={checked}
                onChange={(e) => {
                  if (!props.disabled) {
                    setChecked(e.target.checked);
                    if (props.onChecked) {
                      props.onChecked(e.target.checked);
                    }
                  }
                }}
              />
              <span className="offset-right-16">{props.item.name}</span>
            </Col>
            <Col>
              <Tooltip title={props.item.description}>
                <InfoCircleFilled style={{ color: "#B5b5b5" }} />
              </Tooltip>
            </Col>
          </Row>
        </Col>
        <Col lg={5} xs={12} md={10} sm={10}>
          {props.companyPlan !== "BASIC" && (
            <span>
              <FormattedMessage
                id="screen.label.related_tasks"
                values={{
                  count: props.tasks ? props.tasks?.length : 0,
                }}
              />
              &nbsp;&nbsp;
              <span onClick={props.onShowTask}>
                {props.showTask ? <UpOutlined /> : <DownOutlined />}
              </span>
            </span>
          )}
        </Col>
      </Row>
      <br />
    </>
  );
};

export default ProjectCheckPointItem;
