import { Popconfirm, Popover, Table } from "antd";
import moment from "moment";
import React from "react";
import { useIntl } from "react-intl";

import DeleteIcon from "../../../assets/icons/discard.svg";
import DownloadIcon from "../../../assets/icons/download.svg";
import { ListViewProjectFilesProps } from "../../../Screens/project/types/ListViewProjectFilesProps";
import { dateTimeFormat } from "../../../utils/constants";

const ListViewProjectFiles: React.FC<ListViewProjectFilesProps> = function ({
  files,
  saveFile,
  preview,
  deleteFiles,
  canDelete,
}: ListViewProjectFilesProps) {
  const intl = useIntl();

  // @ts-expect-error TS7006
  const _renderFileDeleteButton = (file) => {
    if (canDelete) {
      return (
        <Popconfirm
          title={intl.formatMessage({
            id: "screen.label.confirm_delete_file",
          })}
          onConfirm={() => {
            // @ts-expect-error TS2722
            deleteFiles(file.id);
          }}
          onCancel={() => {}}
          okText={intl.formatMessage({
            id: "screen.label.yes",
          })}
          cancelText={intl.formatMessage({
            id: "screen.label.no",
          })}
        >
          <img src={DeleteIcon} />
        </Popconfirm>
      );
    }
  };
  const getTableColumns = () => {
    return [
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
      },
      {
        dataIndex: "preview",
        key: "preview",
        // @ts-expect-error TS7006
        render: (text, row) => {
          // @ts-expect-error TS2722
          return preview(row);
        },
        width: "5vw",
      },
      {
        title: intl.formatMessage({
          id: "screen.label.file",
        }),
        dataIndex: "name",
        key: "name",
        // @ts-expect-error TS7006
        render: function renderTable(text, _) {
          const fileNameLength = text?.length;
          return (
            <Popover content={text || ""} trigger="hover">
              {`${text?.substr(0, 50)}  ${fileNameLength > 50 ? "..." : ""}`}
            </Popover>
          );
        },
      },
      {
        title: intl.formatMessage({
          id: "screen.label.date_and_time",
        }),
        dataIndex: "date",
        key: "date",
        // @ts-expect-error TS7006
        render: (date, file) => {
          return moment(file.date).local().format(dateTimeFormat);
        },
      },
      {
        title: intl.formatMessage({
          id: "screen.label.action",
        }),
        key: "action",
        // @ts-expect-error TS7006
        render: function _fRenderTablCloumn(text, file) {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                width: "6vw",
              }}
            >
              <a target="_blank">
                <img
                  src={DownloadIcon}
                  onClick={() => {
                    const href =
                      file.hash.indexOf("http") === -1
                        ? `https://${file.hash}`
                        : file.hash;
                    // @ts-expect-error TS2722
                    saveFile(
                      href,
                      file.name,
                      file.name.split(".").pop(),
                      file.id,
                    );
                  }}
                />
              </a>
              {_renderFileDeleteButton(file)}
            </div>
          );
        },
      },
    ];
  };
  return (
    <div
      className={"ListViewProjectFiles"}
      style={{
        width: "100%",
      }}
    >
      <Table
        size="small"
        pagination={false}
        rowKey={"id"}
        columns={getTableColumns()}
        dataSource={files}
        className="ant-table-x-scroll"
      />
    </div>
  );
};
export default ListViewProjectFiles;
