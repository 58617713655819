import { Action } from "@reduxjs/toolkit";
import { PROBLEM_CODE } from "apisauce";
import { createActions, createReducer } from "reduxsauce";
import Immutable from "seamless-immutable";

import {
  FailOrErrorResponse,
  SuccessResponse,
} from "@/Services/commonResponsTypes";

import { ToAction, ToActionType } from "./types";

/* ------------- Types and Action Creators ------------- */

type RequestMethod =
  | "getRequest"
  | "postRequest"
  | "putRequest"
  | "deleteRequest"
  | "patchRequest";

export type ApiRequestData = {
  method: RequestMethod;
  url: string;
  data?: unknown;
  headers?: Record<string, string>;
  successMessage?: string;
  successReRoute?: string;
};

export type ApiRequestAction<T> = Action<"API_REQUEST"> & {
  data: ApiRequestData;
  callback?: ApiSuccessCallback<T>;
  errorCallback?: ApiErrorCallback;
};

export type ApiSuccessCallback<T = unknown> = (
  data: SuccessResponse<T>,
) => void;

export type ApiErrorCallback = (
  data: FailOrErrorResponse | PROBLEM_CODE,
) => void;

export type ApiRequestActionCreator = <T>(
  data: ApiRequestData,
  callback?: ApiSuccessCallback<T>,
  errorCallback?: ApiErrorCallback,
) => ApiRequestAction<T>;

type ApiActionCreators = {
  apiRequest: ApiRequestActionCreator;
  apiSuccess: () => Action<"API_SUCCESS">;
  apiFailure: (errors: unknown) => Action<"API_FAILURE"> & { errors: unknown };
};

type ApiAction = ToAction<ApiActionCreators>;
type ApiActionType = ToActionType<ApiAction>;

const { Types, Creators } = createActions<ApiActionType, ApiActionCreators>({
  apiRequest: ["data", "callback", "errorCallback"],
  apiSuccess: null,
  apiFailure: ["errors"],
});

export const ApiTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
type ApiState = {
  fetching: boolean;
  errors: unknown;
};
export const INITIAL_STATE = Immutable<ApiState>({
  fetching: false,
  errors: {},
});

type ImmApiState = typeof INITIAL_STATE;

/* ------------- Reducers ------------- */

const apiRequest = (state: ImmApiState) => state.merge({ fetching: true });

const apiSuccess = (state: ImmApiState) => state.merge({ fetching: false });

const apiFailure = (state: ImmApiState, { errors }: { errors: unknown }) =>
  // @ts-expect-error TS2322
  state.merge({ fetching: false, errors });

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer<ImmApiState, ApiAction>(INITIAL_STATE, {
  [Types.API_REQUEST]: apiRequest,
  [Types.API_SUCCESS]: apiSuccess,
  [Types.API_FAILURE]: apiFailure,
});
