import { Col, Row } from "antd";
import { FC, ReactNode } from "react";
import styled from "styled-components";

import { CommentId } from "@/../types/Comment";
import { Thread } from "@/../types/Thread";
import { User } from "@/../types/User";
import { DraftEditor as Editor } from "@/Components/molecules";
import { CommentInfoLabel } from "@/features/projectComment/component/CommentInfoLabel";
import { ThreadDeleteButton } from "@/features/projectComment/component/ThreadDeleteButton/component";
import { ThreadEditButton } from "@/features/projectComment/component/ThreadEditButton";
import {
  convertFromRawWithTrimmer,
  sanitizeContentStateMarkupHtml,
} from "@/utils/common";

export const Wrapper = styled.div`
  padding: 10px;
  margin-bottom: 10px;
  padding-bottom: 1vh;
`;

export type ThreadItemProps = {
  thread: Thread;
  commentId: CommentId;
  canEdit?: boolean;
  user: User;
  onEdit: (thread: Thread) => void;
  onDelete: (thread: Thread) => void;
  renderThreadMessageFooter: (
    commentId: CommentId,
    thread: Thread,
  ) => ReactNode;
};

export const ThreadItem: FC<ThreadItemProps> = ({
  thread,
  commentId,
  canEdit,
  user,
  onEdit,
  onDelete,
  renderThreadMessageFooter,
}) => {
  return (
    <Wrapper>
      <Row
        key={thread.id}
        style={{
          width: "100%",
        }}
        align="middle"
        justify="space-between"
      >
        <Col flex="4">
          <Row align="middle" justify="start" style={{ minHeight: 40 }}>
            <CommentInfoLabel
              id={`comment_${commentId}_thread_${thread.id}`}
              commentedPerson={thread.User}
              commentedDateAsISO={thread.createdAt}
            />
          </Row>
        </Col>
        <Col flex="1">
          {user.id == thread.User.id && canEdit && (
            <Row align="middle" justify="end">
              <ThreadEditButton thread={thread} onEdit={onEdit} />
              <ThreadDeleteButton thread={thread} onDelete={onDelete} />
            </Row>
          )}
        </Col>
      </Row>
      <Row
        style={{
          borderBottomWidth: 0,
          marginBottom: 16,
          paddingLeft: 50,
        }}
      >
        <div
          style={{
            display: "block",
            width: "100%",
          }}
        >
          <Editor
            editorClassName={"shortEditor"}
            toolbarHidden={true}
            readOnly={true}
            editorState={sanitizeContentStateMarkupHtml(
              convertFromRawWithTrimmer(thread.content),
            )}
            // @ts-expect-error TS2322
            localization={{
              locale: "ja",
            }}
            mention={{
              separator: " ",
              trigger: "@",
            }}
          />
        </div>
      </Row>
      <Row>
        <Col>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {renderThreadMessageFooter(commentId, thread)}
          </div>
        </Col>
      </Row>
    </Wrapper>
  );
};
