import {
  DeleteFilled,
  EditFilled,
  EyeOutlined,
  LinkOutlined,
} from "@ant-design/icons";
import { Button, Col, notification, Popconfirm, Row, Tooltip } from "antd";
import moment from "moment";
import React from "react";
// @ts-expect-error TS7016
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useIntl } from "react-intl";
import { withRouter } from "react-router-dom";

import { getObjUUID, removeLineBreaks } from "../../../utils/common";
import { isUrl } from "../../../utils/common";
import { UserIcon } from "../../atoms";
import { NewLinkPreview } from "../../molecules";
import NewsCommentInput from "./newCommentInput";
import NewSubComment from "./newsCommentSub";
import NewsEmoji from "./newsEmoji";
import type { News } from "./type";
import { getPermissionForNews } from "./utils";
type StateProps = {
  comment: News;
  companyId: number;
  isCanEdit: boolean;
  isGlobal: boolean;
  isCanShow: boolean;
  isCanEditEmoji: boolean;
  user: Object;
  onDelete: (id: string) => void;
  onUpdate: (id: string) => void;
  onAddReaction: (commentId: string, emoji: string, emojiName: string) => void;
  onAddSubComment: (id: string, content: string) => void;
  onUpdateSubComment: (id: string, commentId: string, content: string) => void;
  onDeleteSubComment: (id: string, commentId: string) => void;
  onAddSubReaction: (
    id: string,
    commentId: string,
    emoji: string,
    emojiName: string,
  ) => void;
};
type OwnProps = {
  history: Object;
};
type Props = OwnProps & StateProps;
function NewsCommentItem(props: Props) {
  const {
    history,
    comment,
    user,
    isCanEdit,
    isGlobal,
    isCanEditEmoji = false,
    companyId = null,
    isCanShow = false,
    onDelete,
    onUpdate,
    onAddReaction,
    onAddSubComment,
    onUpdateSubComment,
    onDeleteSubComment,
    onAddSubReaction,
  } = props;
  const intl = useIntl();
  const clipboardLink = isGlobal
    ? `${window.location.origin}/global-library/news/${comment.id}`
    : `${window.location.origin}/dashboard/library/news/${comment.id}`;
  const subComments = React.useMemo(() => {
    let subComments = [...comment.NewsComments];
    if (companyId) {
      subComments = subComments.filter((a) => a.companyId === companyId);
    }
    return subComments;
  }, [comment, companyId]);
  return (
    <>
      <Row
        key={comment.id}
        align="top"
        justify="space-between"
        style={{
          paddingTop: 24,
        }}
      >
        <Col flex="0">
          {" "}
          <UserIcon
            // @ts-expect-error TS2339
            userId={comment?.User.id}
            // @ts-expect-error TS2339
            url={comment?.User.profileImage}
          />
        </Col>
        <Col flex="4">
          <Row align="middle" justify="start" style={{ minHeight: 40 }}>
            <span
              style={{
                fontWeight: "bold",
                color: "black",
                cursor: "pointer",
              }}
            >
              {/*
               // @ts-expect-error TS2339 */}
              {comment.User.name}
            </span>
            <Tooltip
              title={moment(comment.createdAt)
                .local()
                .format("YYYY-MM-DD HH:mm:ss")}
            >
              <span style={{ marginLeft: 10 }}>
                {moment(comment.createdAt).local().fromNow()}
              </span>
            </Tooltip>
          </Row>
        </Col>
        <Col flex="1">
          <Row
            style={{
              marginRight: "10px",
            }}
            align="middle"
            justify="end"
          >
            {isCanShow && (
              <Col>
                <Button
                  type="ghost"
                  size="small"
                  style={{
                    marginRight: 10,
                  }}
                  onClick={() => {
                    // @ts-expect-error TS2339
                    history.push(`/dashboard/library/news/${comment.id}`);
                  }}
                  icon={<EyeOutlined />}
                />
              </Col>
            )}
            {isCanEdit && (
              <Col>
                <Button
                  type="ghost"
                  size="small"
                  style={{
                    marginRight: 10,
                  }}
                  onClick={() => {
                    onUpdate(getObjUUID(comment));
                  }}
                  icon={<EditFilled />}
                />
              </Col>
            )}
            {isCanEdit && (
              <Col>
                <Popconfirm
                  title={intl.formatMessage({
                    id: "screen.label.delete_confirm_news",
                  })}
                  onConfirm={() => {
                    onDelete(getObjUUID(comment));
                  }}
                  okText={intl.formatMessage({
                    id: "screen.label.yes",
                  })}
                  cancelText={intl.formatMessage({
                    id: "screen.label.no",
                  })}
                >
                  <Button
                    type="ghost"
                    size="small"
                    style={{
                      marginRight: 10,
                    }}
                    icon={<DeleteFilled />}
                  />
                </Popconfirm>
              </Col>
            )}
            <Col>
              <CopyToClipboard
                text={clipboardLink}
                onCopy={() => {
                  notification.success({
                    message: intl.formatMessage({
                      id: "screen.label.url_copied",
                    }),
                  });
                }}
              >
                <Button type="ghost" size="small" icon={<LinkOutlined />} />
              </CopyToClipboard>
            </Col>
          </Row>
        </Col>
      </Row>
      <br />
      <Row>
        <div
          key={`${comment.id}_comment`}
          style={{ whiteSpace: "pre-line", wordBreak: "break-word" }}
        >
          {removeLineBreaks(comment.newsComment)}
        </div>
      </Row>
      <br />
      {isUrl(comment.url) && (
        <>
          <a
            rel="noreferrer"
            target="_blank"
            href={comment.url}
            title={comment.url}
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "block",
            }}
          >
            {comment.url}
          </a>
          <br />
          <br />
          <NewLinkPreview news={comment} />
          <br />
        </>
      )}
      <NewsEmoji
        key={`${comment.id}_main_news_emoji`}
        isCanEditEmoji={isCanEditEmoji}
        comment={comment}
        user={user}
        // @ts-expect-error TS2322
        companyId={companyId}
        onAddReaction={onAddReaction}
      />
      <br />
      {subComments.length > 0 && (
        <>
          {subComments.map((subComment) => {
            return (
              <NewSubComment
                key={subComment.id}
                // @ts-expect-error TS2322
                isCanEditEmoji={isCanEditEmoji}
                isCanEdit={
                  // @ts-expect-error TS2345
                  isGlobal ? false : getPermissionForNews(user, subComment)
                }
                comment={subComment}
                user={user}
                companyId={companyId}
                onUpdateSubComment={onUpdateSubComment}
                onDeleteSubComment={onDeleteSubComment}
                onAddSubReaction={onAddSubReaction}
              />
            );
          })}
          <hr
            key={`${comment.id}_divier`}
            style={{
              border: "none",
              borderBottom: "dashed 1px #BDBDBD",
            }}
          />
        </>
      )}
      {!isGlobal && (
        <>
          <br />
          <NewsCommentInput
            key={`${comment.id}_input`}
            // @ts-expect-error TS2322
            comment={comment}
            onCreateComment={onAddSubComment}
          />
        </>
      )}
      <hr />
    </>
  );
}
// @ts-expect-error TS2345
export default withRouter(NewsCommentItem);
