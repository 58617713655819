import { notification } from "antd";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";

import { EventData } from "@/../types/Event";
import Spinner from "@/editor/src/components/Spinner";
import { useEvent } from "@/features/event/hooks/useEvent";
import { useEventRequest } from "@/Services/event/useEventRequest";
import { NotFoundCard } from "@/shared/cards/components/NotFoundCard";
import { addProtocol } from "@/utils/http/addProtocol";
import { objectToFormData } from "@/utils/objectToFormData";

import { EventForm } from "../EventForm";

export const EditEventScreen = () => {
  const intl = useIntl();
  const history = useHistory();
  const { updateEvent } = useEventRequest();
  const { id } = useParams<{ id: string }>();
  const { event, isValidating } = useEvent(id);
  const { thumbnailUrl: defaultThumbnailUrl, ...defaultEvent } = event || {};
  const methods = useForm<
    Omit<EventData, "thumbnailUrl"> | Record<string, unknown>
  >({
    values: defaultEvent,
  });
  const [thumbnailUrl, setThumbnailUrl] = useState<File | string | null>(null);

  useEffect(() => {
    setThumbnailUrl(
      defaultThumbnailUrl ? addProtocol(defaultThumbnailUrl) : null,
    );
  }, [defaultThumbnailUrl, setThumbnailUrl]);

  const onSubmit = async (
    data: Omit<EventData, "thumbnailUrl"> | Record<string, unknown>,
  ) => {
    const formData = objectToFormData(data);
    if (thumbnailUrl) formData.append("thumbnailUrl", thumbnailUrl);

    const { isFailed } = await updateEvent(id, formData);

    if (isFailed) {
      return notification.error({
        message: intl.formatMessage({
          id: "screen.label.error",
        }),
      });
    }

    notification.success({
      message: intl.formatMessage({
        id: "screen.label.event_has_been_updated",
      }),
    });

    history.push("/dashboard/events");
  };

  if (isValidating) return <Spinner />;

  if (!event)
    return (
      <NotFoundCard
        resourceName={intl.formatMessage({ id: "screen.label.events" })}
      />
    );

  return (
    <FormProvider {...methods}>
      <EventForm
        onSubmit={onSubmit}
        thumbnailFile={thumbnailUrl}
        setThumbnailFile={setThumbnailUrl}
      />
    </FormProvider>
  );
};
