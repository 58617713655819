import { useIntl } from "react-intl";

import { PrimaryButton } from "@/shared/buttons/components/PrimaryButton/component";

import { EventApplicationButtonProps } from "./type";

export const EventApplicationButton = ({
  onClick,
  disabled,
}: EventApplicationButtonProps) => {
  const intl = useIntl();

  return (
    <PrimaryButton onClick={onClick} disabled={disabled}>
      {intl.formatMessage({
        id: "screen.label.apply_event",
      })}
    </PrimaryButton>
  );
};
