import { useIntl } from "react-intl";

import { AntdToggleButton } from "../../../../shared/buttons/components/AntdToggleButton";
import styles from "./styles.module.scss";
import { IsExaminationPhaseEnabledToggleButtonProps } from "./type";

export const IsExaminationPhaseEnabledToggleButton = ({
  isChecked,
  onClick,
}: IsExaminationPhaseEnabledToggleButtonProps) => {
  const intl = useIntl();
  return (
    <div className={styles.container}>
      <div className={styles.button_label}>
        {intl.formatMessage({
          id: "screen.label.use_examination_phase",
        })}
      </div>
      <div className={styles.button_container}>
        <AntdToggleButton checked={isChecked} onClick={onClick} />
      </div>
    </div>
  );
};
