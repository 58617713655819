import { Action } from "@reduxjs/toolkit";
import { createActions, createReducer } from "reduxsauce";
import Immutable from "seamless-immutable";

import { appName } from "../utils/constants";
import { ToAction, ToActionType } from "./types";

/* ------------- Types and Action Creators ------------- */
type HomeActionCreators = {
  homeGetNotificationsRequest: () => Action<"HOME_GET_NOTIFICATIONS_REQUEST">;
  homeGetNotificationsSuccess: () => Action<"HOME_GET_NOTIFICATIONS_SUCCESS">;
  homeGetNotificationsFailure: () => Action<"HOME_GET_NOTIFICATIONS_FAILURE">;

  homeGetOtherNotificationsRequest: () => Action<"HOME_GET_OTHER_NOTIFICATIONS_REQUEST">;
  homeGetOtherNotificationsSuccess: () => Action<"HOME_GET_OTHER_NOTIFICATIONS_SUCCESS">;
  homeGetOtherNotificationsFailure: () => Action<"HOME_GET_OTHER_NOTIFICATIONS_FAILURE">;

  homeNotificationActionRequest: () => Action<"HOME_NOTIFICATION_ACTION_REQUEST">;
  homeNotificationActionSuccess: () => Action<"HOME_NOTIFICATION_ACTION_SUCCESS">;
  homeNotificationActionFailure: () => Action<"HOME_NOTIFICATION_ACTION_FAILURE">;
  pageTitle: () => Action<"PAGE_TITLE">;
};

type HomeAction = ToAction<HomeActionCreators>;
type HomeActionType = ToActionType<HomeAction>;

const { Types, Creators } = createActions<HomeActionType, HomeActionCreators>({
  pageTitle: ["pageTitle"],
  homeGetNotificationsRequest: ["data", "filter"],
  homeGetNotificationsSuccess: ["notificationsPayload"],
  homeGetNotificationsFailure: ["errors"],

  homeGetOtherNotificationsRequest: ["data"],
  homeGetOtherNotificationsSuccess: ["otherNotificationsPayload"],
  homeGetOtherNotificationsFailure: ["errors"],

  homeNotificationActionRequest: ["data", "id", "message"],
  homeNotificationActionSuccess: null,
  homeNotificationActionFailure: ["errors"],
});

export const HomeTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
type HomeState = {
  pageTitle: string;
  fetching: boolean;
  /** 多分つかってない */
  errors: unknown;
  error?: unknown;
  notificationsPayload: {
    rows: unknown[];
    count: number;
  };
  otherNotificationsPayload: {
    rows: unknown[];
    count: number;
  };
};

export const INITIAL_STATE = Immutable<HomeState>({
  pageTitle: appName,
  fetching: false,
  errors: {},
  notificationsPayload: {
    rows: [],
    count: 0,
  },
  otherNotificationsPayload: {
    rows: [],
    count: 0,
  },
});
type ImmHomeState = typeof INITIAL_STATE;

/* ------------- Reducers ------------- */

const homeGetNotificationsRequest = (state: ImmHomeState) =>
  state.merge({ fetching: true });

const homeGetNotificationsSuccess = (
  state: ImmHomeState,
  // @ts-expect-error TS7031
  { notificationsPayload },
) => state.merge({ fetching: false, notificationsPayload });

// @ts-expect-error TS7031
const homeGetNotificationsFailure = (state: ImmHomeState, { error }) =>
  state.merge({ fetching: false, error });

const homeGetOtherNotificationsRequest = (state: ImmHomeState) =>
  state.merge({ fetching: true });

const homeGetOtherNotificationsSuccess = (
  state: ImmHomeState,
  // @ts-expect-error TS7031
  { otherNotificationsPayload },
) => state.merge({ fetching: false, otherNotificationsPayload });

// @ts-expect-error TS7031
const homeGetOtherNotificationsFailure = (state: ImmHomeState, { error }) =>
  state.merge({ fetching: false, error });

const homeNotificationActionRequest = (state: ImmHomeState) =>
  state.merge({ fetching: true });

const homeNotificationActionSuccess = (state: ImmHomeState) =>
  state.merge({ fetching: false });

// @ts-expect-error TS7031
const homeNotificationActionFailure = (state: ImmHomeState, { error }) =>
  state.merge({ fetching: false, error });

// @ts-expect-error TS7031
const pageTitle = (state: ImmHomeState, { pageTitle }) => {
  return state.merge({ pageTitle });
};

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.HOME_GET_NOTIFICATIONS_REQUEST]: homeGetNotificationsRequest,
  [Types.HOME_GET_NOTIFICATIONS_SUCCESS]: homeGetNotificationsSuccess,
  [Types.HOME_GET_NOTIFICATIONS_FAILURE]: homeGetNotificationsFailure,

  [Types.HOME_GET_OTHER_NOTIFICATIONS_REQUEST]:
    homeGetOtherNotificationsRequest,
  [Types.HOME_GET_OTHER_NOTIFICATIONS_SUCCESS]:
    homeGetOtherNotificationsSuccess,
  [Types.HOME_GET_OTHER_NOTIFICATIONS_FAILURE]:
    homeGetOtherNotificationsFailure,

  [Types.HOME_NOTIFICATION_ACTION_REQUEST]: homeNotificationActionRequest,
  [Types.HOME_NOTIFICATION_ACTION_SUCCESS]: homeNotificationActionSuccess,
  [Types.HOME_NOTIFICATION_ACTION_FAILURE]: homeNotificationActionFailure,
  [Types.PAGE_TITLE]: pageTitle,
});
