import { useIntl } from "react-intl";

import { Popconfirm } from "@/Components/atoms";
import { PublishReviewButtonProps } from "@/features/review/components/PublishReviewConfirmButton/type";
import { PrimaryButton } from "@/shared/buttons/components/PrimaryButton";

export const PublishReviewConfirmButton = ({
  onConfirm,
  disabled,
  countOfSelectedTargets,
  ...rest
}: PublishReviewButtonProps) => {
  const hasSelectedTargets = Boolean(countOfSelectedTargets);

  const intl = useIntl();
  const title = intl.formatMessage({
    id: "screen.label.publish_review_confirm_text",
  });
  const okText = intl.formatMessage({
    id: "screen.label.yes",
  });
  const cancelText = intl.formatMessage({
    id: "screen.label.no",
  });
  const publishButtonTextId = hasSelectedTargets
    ? "screen.label.bulk_publish_review_information"
    : "screen.label.publish_the_results_of_the_review";
  const publishButtonText = intl.formatMessage(
    {
      id: publishButtonTextId,
    },
    { count: countOfSelectedTargets },
  );

  return (
    <Popconfirm
      title={title}
      okText={okText}
      cancelText={cancelText}
      onConfirm={onConfirm}
      okButtonProps={{
        disabled,
      }}
    >
      <PrimaryButton {...rest}>{publishButtonText}</PrimaryButton>
    </Popconfirm>
  );
};
