import { zodResolver } from "@hookform/resolvers/zod";
import { Modal } from "antd";
import { FC, useCallback, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useIntl } from "react-intl";

import { Category } from "@/../types/Category";
import {
  ProjectSubmissionFormSchema,
  projectSubmissionFormSchema,
} from "@/features/submission/components/ProjectSubmissionModal/schema";
import { SubmissionCategorySelectBox } from "@/features/submission/components/SubmissionCategorySelectBox";
import { PrimaryButton } from "@/shared/buttons/components/PrimaryButton";
import { TertiaryButton } from "@/shared/buttons/components/TertiaryButton";
import { Checkbox } from "@/shared/checkbox/components/CheckBox";
import { PlainInput } from "@/shared/inputs/components/PlainInput";
import { ErrorMessage } from "@/shared/label/ErrorMessage";
import { InputLengthCountLabel } from "@/shared/label/InputLengthCountLabel";
import { OptionalLabel } from "@/shared/label/OptionalLabel";
import { RequiredLabel } from "@/shared/label/RequiredLabel";
import { PlainTextarea } from "@/shared/textarea/components/PlainTextarea";

import styles from "./styles.module.scss";

type ProjectSubmissionModalProps = {
  visible: boolean;
  categories: Category[];
  projectName: string;
  onClose: () => void;
  onSubmit: (
    data: ProjectSubmissionFormSchema,
    successCallback: () => void,
  ) => void;
  uploaderComponent: React.ReactNode;
};

export const ProjectSubmissionModal: FC<ProjectSubmissionModalProps> = ({
  visible,
  categories,
  projectName,
  onClose,
  onSubmit,
  uploaderComponent,
}) => {
  const intl = useIntl();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm<ProjectSubmissionFormSchema>({
    mode: "all",
    defaultValues: {
      categoryId: "",
      fileUrl: "",
      fileUrlName: "",
      title: "",
    },
    resolver: zodResolver(projectSubmissionFormSchema),
  });

  const [showFileUrlForm, setShowFileUrlForm] = useState<boolean>(false);

  const submissionText = intl.formatMessage({
    id: "screen.label.submission",
  });
  const submissionInfoText = intl.formatMessage(
    { id: "screens.submission.submission_info" },
    { projectName: projectName },
  );
  const categoryText = intl.formatMessage({ id: "screen.label.category" });
  const submissionDocumentText = intl.formatMessage({
    id: "screens.submission.submit_document",
  });
  const handOverText = intl.formatMessage({
    id: "screens.submission.hand_over",
  });

  const handOverPlaceholder = intl.formatMessage({
    id: "screens.submission.hand_over_place_holder",
  });
  const cancelButtonText = intl.formatMessage({
    id: "screen.label.cancel",
  });
  const submissionButtonText = intl.formatMessage({
    id: "screen.label.hand_in",
  });
  const categoryRequiredText = intl.formatMessage({
    id: "screens.label.error_message_required",
  });
  const specifyFileUrlText = intl.formatMessage({
    id: "screens.submission.specify_file_url",
  });
  const fileUrlText = intl.formatMessage({
    id: "screens.submission.submission_file_url",
  });
  const fileUrlNameText = intl.formatMessage({
    id: "screens.submission.submission_file_url_name",
  });
  const submissionFileUrlPlaceholder = intl.formatMessage({
    id: "screens.submission.input_submission_file_url",
  });
  const submissionFileUrlNamePlaceholder = intl.formatMessage({
    id: "screens.submission.input_submission_file_url_name",
  });

  const resetAll = useCallback(() => {
    reset();
    setShowFileUrlForm(false);
  }, [reset]);

  const handleClose = useCallback(() => {
    resetAll();
    onClose();
  }, [onClose, resetAll]);

  const getSortedActiveCategories = (categories: Category[]) => {
    return categories
      .filter((category) => category.submissionCategoryMetadata.isActive)
      .sort((a, b) => a.order - b.order);
  };

  return (
    <Modal
      onCancel={handleClose}
      width={800}
      title={submissionText}
      visible={visible}
      footer={null}
      bodyStyle={{ padding: styles.modal_body_padding }}
      destroyOnClose={true}
    >
      <form
        onSubmit={handleSubmit((e) => {
          onSubmit(e, resetAll);
        })}
        className={styles.submission_modal_body_container}
      >
        <div className={styles.submission_modal_info_container}>
          {/* 提出情報 */}
          <div className={styles.submission_modal_info_text}>
            <span>{submissionInfoText}</span>
          </div>

          {/* カテゴリ選択 */}
          <div className={styles.submission_element_container}>
            <div className={styles.labels}>
              <label htmlFor="categoryId">{categoryText}</label>
              <RequiredLabel />
            </div>
            <Controller<ProjectSubmissionFormSchema>
              name="categoryId"
              control={control}
              render={({ field: { onBlur, onChange, value } }) => {
                return (
                  <SubmissionCategorySelectBox
                    id="categoryId"
                    categories={getSortedActiveCategories(categories)}
                    onChange={(newValue) => {
                      onChange(newValue?.value ?? "");
                      setValue("category", newValue?.label ?? "");
                    }}
                    onBlur={onBlur}
                    isError={!!errors.categoryId}
                    value={value}
                  />
                );
              }}
            />
            {errors.categoryId && (
              <ErrorMessage>{categoryRequiredText}</ErrorMessage>
            )}
          </div>

          {/* 提出資料 */}
          <div className={styles.submission_element_container}>
            <label className={styles.labels}>
              {submissionDocumentText}
              <OptionalLabel />
            </label>
            {uploaderComponent}
            <div className={styles.specify_label}>
              <Checkbox
                id="checkbox"
                checked={showFileUrlForm}
                onCheck={(checked) => setShowFileUrlForm(checked)}
                disabled={false}
              />
              <label htmlFor="checkbox">{specifyFileUrlText}</label>
            </div>
            {showFileUrlForm && (
              <div className={styles.file_url_container}>
                <div className={styles.label_with_input}>
                  <label htmlFor="fileUrl">{fileUrlText}</label>
                  <PlainInput
                    placeholder={submissionFileUrlPlaceholder}
                    id="fileUrl"
                    {...register("fileUrl")}
                  />
                </div>
                <div className={styles.label_with_input}>
                  <label htmlFor="fileUrlName">{fileUrlNameText}</label>
                  <PlainInput
                    id="fileUrlName"
                    placeholder={submissionFileUrlNamePlaceholder}
                    {...register("fileUrlName")}
                  />
                </div>
              </div>
            )}
          </div>

          {/* 申し送り事項 */}
          <div className={styles.submission_element_container}>
            <div className={styles.labels}>
              <label htmlFor="title">{handOverText}</label>
              <OptionalLabel />
            </div>
            <PlainTextarea
              id="title"
              placeholder={handOverPlaceholder}
              isError={!!errors.title}
              {...register("title")}
            />
            <InputLengthCountLabel
              count={watch("title")?.length ?? 0}
              maxLength={200}
              isError={!!errors.title}
            />
          </div>
        </div>

        {/* ボタン */}
        <div className={styles.buttons_container}>
          <TertiaryButton
            type="button"
            className={styles.submission_modal_button}
            onClick={handleClose}
          >
            {cancelButtonText}
          </TertiaryButton>
          <PrimaryButton
            type="submit"
            className={styles.submission_modal_button}
          >
            {submissionButtonText}
          </PrimaryButton>
        </div>
      </form>
    </Modal>
  );
};
