import styles from "@/shared/buttons/components/LinkStyleButton/styles.module.scss";
import { LinkStyleButtonProps } from "@/shared/buttons/components/LinkStyleButton/type";

export const LinkStyleButton = ({
  children,
  className,
  ...rest
}: LinkStyleButtonProps) => {
  return (
    <button {...rest} className={`${styles.link_button} ${className}`}>
      {children}
    </button>
  );
};
