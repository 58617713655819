import { useIntl } from "react-intl";

import styles from "./styles.module.scss";
import { EventCapacityProps } from "./type";

export const EventCapacity = ({ capacity }: EventCapacityProps) => {
  const intl = useIntl();
  return (
    <p className={styles.event_capacity}>
      <span className={styles.lottery}>
        （
        {intl.formatMessage({
          id: "screens.events.lottery",
        })}
        ）
      </span>
      <span className={styles.event_capacity_count}>
        {intl.formatMessage(
          { id: "screens.events.capacity" },
          {
            capacity,
          },
        )}
      </span>
    </p>
  );
};
