import { Modal } from "antd";
import moment from "moment";
import { useIntl } from "react-intl";
import styled from "styled-components";

import { ButtonCV, ButtonUndo, DatePicker, Rate } from "../../atoms";
import { Form, Input, Option, Select, TextArea } from "..";

export const ProjectModalButtonComponent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

type Props = {
  timelimit?: string | null;
  status?: string | null;
  priority?: number | null;
  description?: string | null;
  title?: string | null;
  showModal: boolean;
  incharge?: Array<number>;
  teamMembers?: Array<any>;
  isCreating?: boolean;
  handleChange?: (e: string, value: any) => void;
  handleOk?: (e: any) => void;
  handleCancel?: (e: any) => void;
};

const ProjectTaskModal = (props: Props) => {
  const {
    timelimit,
    status,
    priority,
    description,
    title,
    showModal,
    teamMembers,
    incharge,
    isCreating,
    handleChange,
    handleOk,
    handleCancel,
  } = props;

  const intl = useIntl();

  const _renderUsers = () => {
    if (Array.isArray(teamMembers) && Array.isArray(incharge)) {
      const projectMembersIds = teamMembers.map((u) => u.id);
      const memberIdsAssignedToTask = incharge;
      const uniqueUsersIds = [
        ...new Set([...projectMembersIds, ...memberIdsAssignedToTask]),
      ];
      return teamMembers
        .filter((user) => {
          return uniqueUsersIds.includes(user.id);
        })
        .map((user) => {
          if (user.deletedAt) {
            return (
              <Option key={user.id} value={user.id}>
                {intl.formatMessage({
                  id: "screen.label.deleted_user",
                })}
              </Option>
            );
          }
          return (
            <Option key={user.id} value={user.id}>
              {user?.name?.trim()?.length > 0 ? user.name : user?.email}
            </Option>
          );
        });
    }
  }; //

  return (
    <Modal
      width={"42vw"}
      bodyStyle={{
        paddingLeft: "10vw",
        paddingRight: "10vw",
      }}
      title={intl.formatMessage({
        id: "screen.label.task_name",
      })}
      visible={showModal}
      onOk={handleOk}
      destroyOnClose
      onCancel={handleCancel}
      cancelText={intl.formatMessage({
        id: "screen.label.cancel",
      })}
      footer={null}
    >
      <Form style={{ maxWidth: 600 }} layout={"vertical"}>
        <Form.Item
          label={`${intl.formatMessage({
            id: "screen.label.item_name",
          })} *`}
        >
          <Input
            size="large"
            required
            // @ts-expect-error TS2722
            onChange={(event) => handleChange("title", event.target.value)}
            // @ts-expect-error TS2322
            value={title}
            placeholder={intl.formatMessage({
              id: "screen.label.please_enter",
            })}
          />
        </Form.Item>
        <Form.Item
          label={`${intl.formatMessage({
            id: "screen.label.more_detail",
          })} *`}
        >
          <TextArea
            rows={8}
            required
            onChange={(event) =>
              // @ts-expect-error TS2722
              handleChange("description", event.target.value)
            }
            // @ts-expect-error TS2322
            value={description}
            placeholder={intl.formatMessage({
              id: "screen.label.please_enter",
            })}
          />
        </Form.Item>
        <Form.Item
          label={`${intl.formatMessage({
            id: "screen.label.person_in_charge",
          })}`}
          help={intl.formatMessage({
            id: "screen.label.notification_e_mail_charge",
          })}
        >
          <Select
            showSearch
            allowClear
            defaultValue={incharge}
            mode="multiple"
            optionFilterProp="children"
            filterOption={(input, option) =>
              // @ts-expect-error TS18048
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            // @ts-expect-error TS2722
            onChange={(value) => handleChange("incharge", value)}
          >
            {_renderUsers()}
          </Select>
        </Form.Item>
        <br />
        <Form.Item
          label={`${intl.formatMessage({
            id: "screen.label.priority",
          })}`}
        >
          <Rate
            // @ts-expect-error TS2722
            onChange={(value) => handleChange("priority", value)}
            // @ts-expect-error TS2769
            value={priority}
          />
        </Form.Item>
        <Form.Item
          label={`${intl.formatMessage({
            id: "screen.label.deadline",
          })}`}
        >
          <DatePicker
            // @ts-expect-error TS2769
            value={timelimit ? moment(timelimit).local() : ""}
            // @ts-expect-error TS2722
            onChange={(date) => handleChange("timelimit", date)}
            placeholder={intl.formatMessage({
              id: "screen.label.select_date",
            })}
          />
        </Form.Item>
        {!isCreating && (
          <Form.Item
            label={`${intl.formatMessage({
              id: "screen.label.action",
            })}`}
          >
            <Select
              defaultValue={status}
              style={{ width: 120 }}
              // @ts-expect-error TS2722
              onChange={(value) => handleChange("status", value)}
            >
              <Option value="PENDING">
                {intl.formatMessage({
                  id: "screen.label.unexecuted",
                })}
              </Option>
              <Option value="PROGRESS">
                {intl.formatMessage({
                  id: "screen.label.in_progress",
                })}
              </Option>
              <Option value="DONE">
                {intl.formatMessage({
                  id: "screen.label.completion",
                })}
              </Option>
            </Select>
          </Form.Item>
        )}
        <Form.Item>
          <ProjectModalButtonComponent>
            <ButtonUndo
              onClick={handleCancel}
              style={{
                marginLeft: "0.1vw",
                marginRight: "0.1vw",
              }}
            >
              {intl.formatMessage({
                id: "screen.label.cancel",
              })}
            </ButtonUndo>
            <ButtonCV
              onClick={handleOk}
              style={{
                marginLeft: "0.1vw",
                marginRight: "0.1vw",
              }}
            >
              {intl.formatMessage({
                id: "screen.label.save",
              })}
            </ButtonCV>
          </ProjectModalButtonComponent>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ProjectTaskModal;
