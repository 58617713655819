import { notification } from "antd";
import { useIntl } from "react-intl";
import useSWR from "swr";

import { IpRanges } from "@/../types/Company";
import { JsendError } from "@/../types/JSendObject";
import { IpRangeSchema } from "@/features/company/components/IpRangeSettingModal/schema";
import { toIpRange } from "@/features/company/helpers/toIpRange";
import { api } from "@/Saga";
import { BaseResponse, NullResponse } from "@/Services/commonResponsTypes";
import { endpoints, parseEndpoint } from "@/Services/endpoints";

type IpRangesBody = {
  ipRanges: IpRanges;
};

type IpRangeBody = {
  ipRange: string;
};

type GetIpRangesResponse = BaseResponse<IpRangesBody>;

export const useIpRangeRequest = (companyUuid: string) => {
  const intl = useIntl();
  const failedPostMessage = intl.formatMessage({
    id: "screen.label.failed_post_ip_range",
  });
  const successPostMessage = intl.formatMessage({
    id: "screen.label.success_post_ip_range",
  });
  const failedDeleteMessage = intl.formatMessage({
    id: "screen.label.failed_delete_ip_range",
  });
  const successDeleteMessage = intl.formatMessage({
    id: "screen.label.success_delete_ip_range",
  });

  const ipRangesUrl = parseEndpoint(endpoints.ipRanges, {
    companyUuid,
  });
  const ipRangeUrl = parseEndpoint(endpoints.ipRange, {
    companyUuid,
  });

  const fetcher = async () => {
    const response = await api.getRequest<GetIpRangesResponse, JsendError>(
      ipRangesUrl,
    );
    if (response.data?.status !== "success") throw new Error("Failed to fetch");

    return response.data.data.ipRanges;
  };

  const putIpRangeRequest = async ({
    ipAddress,
    cidrPrefix,
  }: IpRangeSchema) => {
    const ipRange = toIpRange(ipAddress, cidrPrefix);
    const response = await api.putRequest<
      IpRangeBody,
      NullResponse,
      JsendError
    >(ipRangeUrl, { ipRange });
    if (response.data?.status !== "success") {
      notification.error({
        message: error?.message ?? failedPostMessage,
      });
      return;
    }
    notification.success({
      message: successPostMessage,
    });
    mutate();
  };

  const deleteIpRangeRequest = async (ipRange: string) => {
    const response = await api.deleteRequest<NullResponse, JsendError>(
      ipRangeUrl,
      {},
      { data: { ipRange } },
    );
    if (response.data?.status !== "success") {
      notification.error({
        message: error?.message ?? failedDeleteMessage,
      });
      return;
    }
    notification.success({
      message: successDeleteMessage,
    });
    mutate();
  };

  const {
    data: ipRanges,
    error,
    mutate,
  } = useSWR<IpRanges, JsendError>(ipRangesUrl, fetcher);

  return {
    ipRanges,
    putIpRangeRequest,
    deleteIpRangeRequest,
  };
};
