import { notification } from "antd";
import { all, call, put, takeLatest } from "redux-saga/effects";

import CompanyActions, { CompanyTypes } from "../Redux/CompanyRedux";

// @ts-expect-error TS7006
function* attemptUpdateCompany(api, { update, message }) {
  // filter update
  // @ts-expect-error TS7057
  const companyResponse = yield call(api.putRequest, "", update);
  if (companyResponse.ok) {
    switch (companyResponse.data.status) {
      case "success":
        notification.success({
          message: message,
        });
        yield put(CompanyActions.companyUpdateSuccess());
        yield put(CompanyActions.companyGetRequest());
        break;
      case "fail":
      case "error":
        if (typeof companyResponse.data.data === "string") {
        }
        yield put(
          // @ts-expect-error TS2554
          CompanyActions.companyUpdateFailure(companyResponse.data.data),
        );
        break;
      default:
        break;
    }
  } else {
    notification.error({
      message: companyResponse.problem,
    });
    // @ts-expect-error TS2554
    yield put(CompanyActions.companyUpdateFailure(companyResponse.problem));
    if (companyResponse.status === 401) {
      // @ts-expect-error TS2322
      window.location = "/login";
    }
  }
}

// @ts-expect-error TS7006
function* attemptGetCompanyDetail(api) {
  // @ts-expect-error TS7057
  const companyResponse = yield call(api.getRequest);
  if (companyResponse.ok) {
    switch (companyResponse.data.status) {
      case "success":
        // @ts-expect-error TS2554
        yield put(CompanyActions.companyGetSuccess(companyResponse.data.data));
        break;
      case "fail":
      case "error":
        if (typeof companyResponse.data.data === "string") {
        }
        // @ts-expect-error TS2554
        yield put(CompanyActions.companyGetFailure(companyResponse.data.data));
        break;
      default:
        break;
    }
  } else {
    notification.error({
      message: companyResponse.problem,
    });
    // @ts-expect-error TS2554
    yield put(CompanyActions.companyGetFailure(companyResponse.problem));
    if (companyResponse.status === 401) {
      // @ts-expect-error TS2322
      window.location = "/login";
    }
  }
}

// @ts-expect-error TS7006
function* companySaga(api) {
  yield all([
    takeLatest(CompanyTypes.COMPANY_GET_REQUEST, attemptGetCompanyDetail, api),
    // @ts-expect-error TS2345
    takeLatest(CompanyTypes.COMPANY_UPDATE_REQUEST, attemptUpdateCompany, api),
  ]);
}

export default companySaga;
