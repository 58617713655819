import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import React from "react";

const bugsnagApiKey = process.env.REACT_APP_BUGSNAG_API_KEY; // APIkeyは公開しても可

/**
 * BugSnagのAPIkeyがあるなら、BugSnagを有効にしたAppを返す
 */
export const getTrackedApp = (App: () => JSX.Element) => {
  if (typeof bugsnagApiKey === "string") {
    Bugsnag.start({
      apiKey: bugsnagApiKey,
      plugins: [new BugsnagPluginReact()],
      releaseStage: process.env.REACT_APP_BUGSNAG_RELEASE_STAGE,
    });

    const ErrorBoundary =
      Bugsnag.getPlugin("react")?.createErrorBoundary(React);

    return () => (
      // @ts-expect-error 型定義のエラーが出る。とりあえず無視。
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    );
  } else {
    return () => <App />;
  }
};
