import { Tabs } from "antd";
import { Row } from "antd";
import styled from "styled-components";

export const CreateNotifications = styled(Row)`
  position: relative;
`;

export const ProjectGeneralNoticeTabs = styled(Tabs)<any>`
  > .ant-tabs-nav::before {
    border-bottom: 0px;
  }
`;
