import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { notification } from "antd";
import { useIntl } from "react-intl";

import { Editor } from "../../../editor/src";
import { InputContainer, InputTitle } from "./field";

export const nodeConfig = (
  cb: (file: File) => Promise<unknown> | void,
  embedCb: () => void,
) => {
  return {
    options: [
      "inline",
      "blockType",
      "fontSize",
      "list",
      "textAlign",
      "link",
      "image",
      "embedded",
    ],
    inline: {
      inDropdown: false,
      options: [
        "bold",
        "italic",
        "underline",
        "strikethrough",
        "monospace",
        "superscript",
        "subscript",
      ],
    },
    blockType: {
      inDropdown: true,
      options: ["Normal", "H1", "H2", "H3", "H4", "Blockquote", "Code"],
    },
    fontSize: {
      icon: "fontSize",
      options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
    },
    list: {
      inDropdown: false,
      options: ["unordered", "ordered", "indent", "outdent"],
    },
    textAlign: {
      inDropdown: false,
      options: ["left", "center", "right", "justify"],
    },
    link: {
      inDropdown: false,
      showOpenOptionOnHover: true,
      defaultTargetOption: "_self",
      options: ["link", "unlink"],
    },
    image: {
      urlEnabled: true,
      uploadEnabled: true,
      alignmentEnabled: true,
      uploadCallback: cb,
      previewImage: true,
      inputAccept: "image/gif,image/jpeg,image/jpg,image/png,image/svg",
      alt: { present: false, mandatory: false },
      defaultSize: {
        height: "200px",
        width: "200px",
      },
    },
    embedded: {
      className: undefined,
      component: undefined,
      popupClassName: undefined,
      embedCallback: embedCb,
      defaultSize: {
        height: "auto",
        width: "auto",
      },
      title: undefined,
    },
  };
};

// Not in use?
type ReadDraftEditorProps = {
  title?: string;
  editorState: any;
  editorClassName?: string;
  mention?: any;
};

export const uploadImageWithDraftEditor = ({
  file,
  companyId,
  authToken,
  subLink,
  headerDetail,
  tokenize = true,
}: {
  file: File;
  companyId: string;
  authToken: string;
  subLink: string;
  headerDetail: any;
  tokenize?: boolean;
}) => {
  return new Promise((resolve, reject) => {
    const data = new FormData();
    data.append("file", file);
    data.append("filename", file.name);
    fetch(
      `${process.env.REACT_APP_BASEHOST}companies/${companyId}/users/upload-file`,
      {
        method: "POST",
        headers: new Headers({
          Authorization: `Bearer ${authToken}`,
          alphafrontEndPath: window.location.href,
        }),
        body: data,
      },
    )
      .then((response) => {
        response.json().then((body) => {
          if (body.status === "success") {
            let link = tokenize
              ? `${body.data.hash}?token=${authToken}`
              : body.data.hash;
            if (link.indexOf("http") === -1) link = `https://${link}`;
            createUserFile({
              fileBody: body.data,
              companyId,
              authToken,
              subLink,
              headerDetail,
            });
            resolve({
              data: {
                link: link,
              },
            });
          } else {
            if (typeof body.data === "string") {
              notification.error({
                message: body.data,
              });
            }
            reject(new Error("Failed to image uploading"));
          }
        });
      })
      .catch((error) => {
        reject(error);
        console.log("image uploading error", error);
      });
  });
};

export const createUserFile = async ({
  fileBody,
  companyId,
  authToken,
  subLink,
  headerDetail,
}: {
  fileBody: any;
  companyId: string;
  authToken: string;
  subLink: string;
  headerDetail: any;
}) => {
  return new Promise((resolve, reject) => {
    fetch(
      `${process.env.REACT_APP_BASEHOST}companies/${companyId}/users/upload/${subLink}`,
      {
        method: "POST",
        headers: new Headers({
          Authorization: `Bearer ${authToken}`,
          details: JSON.stringify(headerDetail),
          alphafrontEndPath: window.location.href,
          "Content-type": "application/json",
        }),
        body: JSON.stringify(fileBody),
      },
    )
      .then((response) => {
        response.json().then((body) => {
          if (body.status === "success") {
            resolve(body.data);
          } else {
            if (typeof body.data === "string") {
              notification.error({
                message: body.data,
              });
            }
            reject(null);
          }
        });
      })
      .catch((error) => {
        reject(error);
        console.log("image uploading error", error);
      });
  });
};

// Not in use?
export const ReadDraftEditor = (props: ReadDraftEditorProps) => {
  return (
    <InputContainer>
      {props.title && props.title !== "" && (
        <InputTitle>{props.title}</InputTitle>
      )}
      <Editor
        toolbarHidden={true}
        readOnly={true}
        localization={{
          locale: "ja",
        }}
        {...props}
      />
    </InputContainer>
  );
};

type DraftEditorProps = {
  title?: string;
  editorState: any;
  editorClassName?: string;
  mention?: any;
  editorRef?: any;
  toolbar?: any;
  wrapperClassName?: string;
  toolbarClassName?: string;
  placeholder?: string;
  onEditorStateChange?: (value: any) => void;
  onPostContent?: () => void;
  onCancelContent?: () => void;
  readOnly?: boolean;
  toolbarHidden?: boolean;
  toolbarCustomButtons?: any;
};

export const DraftEditor = (props: DraftEditorProps) => {
  const intl = useIntl();
  return (
    <InputContainer>
      {props.title && props.title !== "" && (
        <InputTitle>{props.title}</InputTitle>
      )}
      <Editor
        ref={props?.editorRef}
        wrapperClassName="wrapper-class"
        editorClassName={
          props.readOnly ? "" : props.editorClassName || "editor-class"
        }
        toolbarClassName={
          props.readOnly ? "" : props.toolbarClassName || "toolbar-class"
        }
        placeholder={
          props.placeholder ||
          intl.formatMessage({
            id: "screen.label.please_enter",
          })
        }
        localization={{
          locale: "ja",
        }}
        {...props}
      />
    </InputContainer>
  );
};
