/**
 * ユーザーからインプットされた文字列がhttpまたはhttpsのURLかどうかを判定します
 * 正規表現ではReDos攻撃に対応できないため、URLクラスを使用しています
 * 将来的にはサーバーで実現するか検討します
 * @param input - ユーザーからの入力
 * @returns true - プロトコルがhttpまたは、https / false - それ以外
 */
export const checkIfUrl = (input: string) => {
  try {
    const givenURL = new URL(input);
    return givenURL.protocol === "http:" || givenURL.protocol === "https:";
  } catch (error) {
    return false;
  }
};
