import { useIntl } from "react-intl";

import styles from "./styles.module.scss";
import { PublishStatusLabelProps } from "./type";

export const PublishStatusLabel = ({
  isPublished,
  className,
  ...rest
}: PublishStatusLabelProps) => {
  const intl = useIntl();
  const publishStatusLabel = intl.formatMessage({
    id: isPublished
      ? "screen.label.review_published_status_published"
      : "screen.label.review_published_status_before_publish",
  });
  return (
    <span
      className={
        isPublished
          ? `${styles.status_published_label} ${className}`
          : `${styles.status_before_publish_label} ${className}`
      }
      {...rest}
    >
      {publishStatusLabel}
    </span>
  );
};
