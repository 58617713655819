import getImageComponent from "../renderer/Image";
import Embedded from "./Embedded";

export function entityMapperToComponent(entity) {
  if (entity.type === "DIV") {
    return () => (
      <div dangerouslySetInnerHTML={{ __html: entity.data.innerHTML }} />
    );
  }
  if (entity.type === "TABLE") {
    return () => (
      <table dangerouslySetInnerHTML={{ __html: entity.data.innerHTML }} />
    );
  }
  if (entity.type === "TBODY") {
    return (
      <tbody dangerouslySetInnerHTML={{ __html: entity.data.innerHTML }} />
    );
  }
  if (entity.type === "TR") {
    return () => (
      <tr dangerouslySetInnerHTML={{ __html: entity.data.innerHTML }} />
    );
  }
  if (entity.type === "TH") {
    return () => (
      <th dangerouslySetInnerHTML={{ __html: entity.data.innerHTML }} />
    );
  }
  if (entity.type === "TD") {
    return () => (
      <td dangerouslySetInnerHTML={{ __html: entity.data.innerHTML }} />
    );
  }
  if (entity.type === "STYLE") {
    return () => <style>{entity.data.innerHTML}</style>;
  }

  return "";
}

const getBlockRenderFunc = (config, customBlockRenderer) => (block) => {
  if (typeof customBlockRenderer === "function") {
    const renderedComponent = customBlockRenderer(
      block,
      config,
      config.getEditorState,
    );
    if (renderedComponent) return renderedComponent;
  }
  if (block.getType() === "atomic") {
    const contentState = config.getEditorState().getCurrentContent();
    if (contentState && contentState !== null) {
      let entity = null;
      try {
        entity = contentState.getEntity(block.getEntityAt(0));
      } catch (e) {}
      if (entity && entity.type === "IMAGE") {
        let imageComponent = null;
        try {
          imageComponent = getImageComponent(config);
          return {
            component: imageComponent,
            editable: false,
          };
        } catch (e) {
          return {
            component: null,
            editable: false,
          };
        }
      } else if (entity && entity.type === "EMBEDDED_LINK") {
        return {
          component: Embedded,
          editable: false,
        };
      } else if (
        entity &&
        (entity.type === "DIV" ||
          entity.type === "TABLE" ||
          entity.type === "TBODY" ||
          entity.type === "TR" ||
          entity.type === "TH" ||
          entity.type === "TD" ||
          entity.type === "STYLE")
      ) {
        return {
          component: entityMapperToComponent(entity),
          editable: false,
          props: {
            children: () => entity.innerHTML,
          },
        };
      }
    } else {
      return {
        component: null,
        editable: false,
      };
    }
  }
  return undefined;
};

export default getBlockRenderFunc;
