import { memo } from "react";
import { useIntl } from "react-intl";

import styles from "./styles.module.scss";
import { SubmittedProjectCountLabelProps } from "./type";

export const SubmittedProjectCountLabel = memo(
  ({ count }: SubmittedProjectCountLabelProps) => {
    const intl = useIntl();
    return (
      <div className={styles.label_container}>
        <span className={styles.label}>
          {intl.formatMessage({
            id: "screen.label.examination_info_count",
          })}
        </span>
        <span className={styles.count}>{count}</span>
        <span>
          {intl.formatMessage({
            id: "screen.label.count",
          })}
        </span>
      </div>
    );
  },
);
