import PropTypes from "prop-types";

export type SingleProjectCheckpoint = {
  projectId: number;
  checkpointFormId: string;
  isChecked: boolean;
  checkpointFormMetadata: {
    displayCheckpoint: boolean;
  };
};

type CheckPointCoverageRateProps = {
  projectCheckpoints: Array<SingleProjectCheckpoint>;
};

export const CheckPointCoverageRate = function (
  props: CheckPointCoverageRateProps,
) {
  const { projectCheckpoints } = props;
  if (Array.isArray(projectCheckpoints)) {
    const totalNumberOfCheckPoints = projectCheckpoints.filter(
      (p) => p?.checkpointFormMetadata?.displayCheckpoint,
    ).length;
    const totalChecked = projectCheckpoints.filter(
      (spc) => spc.isChecked && spc?.checkpointFormMetadata?.displayCheckpoint,
    ).length;

    if (totalNumberOfCheckPoints > 0) {
      return (
        <span>
          &nbsp; {((totalChecked / totalNumberOfCheckPoints) * 100).toFixed(2)}{" "}
          %
        </span>
      );
    }
    return <span>&nbsp; 0 %</span>;
  }
  return <></>;
};

CheckPointCoverageRate.propTypes = {
  projectCheckpoints: PropTypes.array.isRequired,
};
