import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { Col, Modal, notification as NotificationMsg, Row, Tabs } from "antd";
import { EditorState } from "draft-js";
import moment from "moment";
import PropTypes from "prop-types";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import ReactPlayer from "react-player/lazy";
import { connect } from "react-redux";

import { Button, Checkbox } from "../../Components/atoms";
import { Line } from "../../Components/atoms";
import {
  InsuAcceptButton,
  InsuPrimaryButton,
  InsuRejectButton,
} from "../../Components/atoms/buttons/shared";
import { Form, Input, Option, Select } from "../../Components/molecules";
import { DraftEditor as Editor } from "../../Components/molecules";
import { nodeConfig } from "../../Components/molecules";
import { CustomPagination } from "../../Components/molecules";
import {
  CreateNotifications,
  ProjectGeneralNoticeTabs,
} from "../../Components/molecules/project/ProjectGeneralNoticeScreen";
import { NotificationComponent } from "../../Components/organisms";
import HomeActions from "../../Redux/HomeRedux";
import ProjectActions from "../../Redux/ProjectRedux";
import {
  convertFromRawWithTrimmer,
  convertYVLinksToEmbed,
  getObjUUID,
  sanitizeEditorStateMarkupHtml,
} from "../../utils/common";
import { dateTimeFormat } from "../../utils/constants";
import useProjectGeneralNoticeScreen from "./hooks/useProjectGeneralNoticeScreen";
import { withCheckProjectAccess } from "./permissionWrapper";
import { IProjectGeneralNoticeScreenProps } from "./types/IProjectGeneralNoticeScreenProps";
const PageSaveId = "ProjectGeneralNoticeScreen";
const PageDraftSaveId = "ProjectGeneralNoticeScreen_Draft";
const { TabPane } = Tabs;

const ProjectGeneralNoticeScreen: FC<IProjectGeneralNoticeScreenProps> =
  function (props) {
    const {
      state,
      setState,
      intl,
      uploadImageCallBack,
      onEditorStateChange,
      handleChange,
      getNotificationsRequestion,
      onDeleteNotification,
      onTakeActionNoticeClicked,
      onPromptModal,
      onTakeActionOnNotification,
      onTakeActionRegisterEvent,
      getDraftNotificationsRequestion,
      onModalCancelled,
      saveAsDraft,
      submitNotice,
      onCloseRegisterEventModal,
      onUnRegisterEvent,
      onRegisterForEvent,
      onCloseNoticeModal,
      handleCancel,
      validCreateNotification,
    } = useProjectGeneralNoticeScreen(props);

    const { apiRequest, user } = props.context;

    const _renderNoticeDetails = () => {
      let noticeDetailsNode = <>{state.noticeDetails.description}</>;
      if (state.noticeDetails.nodalData === true) {
        noticeDetailsNode = (
          <Editor
            toolbarHidden={true}
            readOnly={true}
            editorState={sanitizeEditorStateMarkupHtml(state.editorState)}
            // @ts-expect-error TS2322
            localization={{
              locale: "ja",
            }}
          />
        );
      }
      return noticeDetailsNode;
    }; //
    const _renderCreateNotifications = () => {
      return (
        <InsuPrimaryButton
          style={{
            position: "absolute",
            top: "10px",
            right: "0px",
            zIndex: 1000,
          }}
          onClick={() => setState({ visible: true, autoSaveNotificationId: 0 })}
        >
          +{" "}
          {intl.formatMessage({
            id: "screen.label.create_notification",
          })}
        </InsuPrimaryButton>
      );
    }; //
    // @ts-expect-error TS7006
    const _renderTabHead = (title, count) => {
      return (
        <>
          {title}
          {count > 0 ? (
            <span
              style={{
                backgroundColor: "#00D282",
                padding: "4px",
                borderRadius: "3px",
                marginLeft: "11px",
                color: "white",
                fontSize: "0.7rem",
              }}
            >
              {`${count}`}
              <FormattedMessage id="screen.label.count" />
            </span>
          ) : null}
        </>
      );
    }; //
    const _renderNoticeBody = () => {
      if (state.type === "general_notice") {
        return (
          <Form.Item
            label={intl.formatMessage({
              id: "screen.label.more_detail",
            })}
          >
            <Editor
              toolbar={nodeConfig(
                uploadImageCallBack,
                convertYVLinksToEmbed.bind(
                  // @ts-expect-error TS2683
                  this,
                  user.token,
                  getObjUUID(user.company),
                ),
              )}
              wrapperClassName="wrapper-class"
              editorClassName="editor-class"
              toolbarClassName="toolbar-class"
              placeholder={intl.formatMessage({
                id: "screen.label.please_enter",
              })}
              editorState={state.editorState}
              onEditorStateChange={onEditorStateChange}
              // @ts-expect-error TS2322
              localization={{
                locale: "ja",
              }}
            />
          </Form.Item>
        );
      } else {
        return (
          <Form.Item label={"URL"}>
            <Input
              required
              type="url"
              placeholder="URL"
              value={state.description}
              onChange={(event) =>
                handleChange("description", event.target.value)
              }
            />
          </Form.Item>
        );
      }
    }; //
    // @ts-expect-error TS7006
    const _renderOtherNotifications = (notifications) => {
      if (Array.isArray(notifications.rows)) {
        // @ts-expect-error TS7006
        return notifications.rows.map((notification) => {
          return (
            <div
              key={notification.id}
              style={
                notification.deletedAt
                  ? {
                      paddingTop: "1vh",
                      paddingLeft: "1vw",
                      paddingRight: "1vw",
                    }
                  : {}
              }
            >
              <NotificationComponent
                enableEditing
                currentUserRole={user.type}
                authToken={user.token}
                dispatchApiRequest={apiRequest}
                refresh={getNotificationsRequestion}
                // @ts-expect-error TS2322
                currentUserId={user.id}
                onDeleteNotification={onDeleteNotification}
                onTakeActionNoticeClicked={onTakeActionNoticeClicked}
                notification={notification}
                onPromptModal={onPromptModal}
                onTakeAction={onTakeActionOnNotification}
                onTakeActionRegisterEvent={onTakeActionRegisterEvent}
              />
            </div>
          );
        });
      }
      return <></>;
    }; //
    // @ts-expect-error TS7006
    const _renderDraftNotifications = (notifications) => {
      if (Array.isArray(notifications.rows)) {
        // @ts-expect-error TS7006
        return notifications.rows.map((notification) => {
          return (
            <div
              key={notification.id}
              style={
                notification.deletedAt
                  ? {
                      backgroundColor: "#dcdcdc",
                      paddingTop: "1vh",
                      paddingLeft: "1vw",
                      paddingRight: "1vw",
                    }
                  : {}
              }
            >
              <NotificationComponent
                onCLickForEditNotification={(notification) => {
                  const editorState = notification.nodalData
                    ? sanitizeEditorStateMarkupHtml(
                        EditorState.createWithContent(
                          convertFromRawWithTrimmer(notification.message),
                        ),
                      )
                    : EditorState.createEmpty();
                  setState({
                    visible: true,
                    title: notification.actionText,
                    type: notification.meta.type,
                    videoUrl: notification.meta.videoUrl,
                    editorState,
                    description: notification.message,
                    autoSaveNotificationId: notification.id,
                    sendEmail: notification.sendEmail,
                  });
                }}
                enableEditing={true}
                draftMessage={true}
                currentUserRole={user.type}
                authToken={user.token}
                dispatchApiRequest={apiRequest}
                refresh={getNotificationsRequestion}
                // @ts-expect-error TS2322
                currentUserId={user.id}
                onDeleteNotification={onDeleteNotification}
                onTakeActionNoticeClicked={onTakeActionNoticeClicked}
                notification={notification}
                onPromptModal={onPromptModal}
                onTakeAction={onTakeActionOnNotification}
                onTakeActionRegisterEvent={onTakeActionRegisterEvent}
              />
            </div>
          );
        });
      }
      return <></>;
    }; //

    return (
      <>
        <br />
        <CreateNotifications>
          <Col offset={20} span={4}>
            {_renderCreateNotifications()}
          </Col>
        </CreateNotifications>
        <ProjectGeneralNoticeTabs>
          <TabPane
            tab={intl.formatMessage({
              id: "screen.label.delivered",
            })}
            key={1}
          >
            <Row>
              <Col xs={24} sm={12} md={8} lg={6}>
                <Select
                  allowClear
                  onChange={(v) => {
                    setState(
                      {
                        page: 1,
                        // @ts-expect-error TS2345
                        metaType: v,
                      },
                      () => {
                        getNotificationsRequestion();
                      },
                    );
                  }}
                  listHeight={400}
                  style={{ width: "70%" }}
                >
                  <Option value={`metaType=general_notice`}>
                    {intl.formatMessage({
                      id: "screen.label.notice",
                    })}
                  </Option>
                  <Option value={`metaType=poll`}>
                    {intl.formatMessage({
                      id: "screen.label.questionnaire",
                    })}
                  </Option>
                  <Option value={`metaType=recruit_members`}>
                    {intl.formatMessage({
                      id: "screen.label.members_wanted",
                    })}
                  </Option>
                  <Option value={`metaType=project_creation`}>
                    {intl.formatMessage({
                      id: "screen.label.creating_project",
                    })}
                  </Option>
                </Select>
              </Col>
            </Row>
            <br />
            <Line />
            <br />
            <Row>
              <Col span={24}>
                {_renderOtherNotifications(state.notifications)}
                <div className={"card-footer"} style={{ padding: "3%" }}>
                  <CustomPagination
                    saveId={PageSaveId}
                    count={state.notifications.count}
                    initPage={state.page}
                    initLimit={state.limit}
                    onChangePage={(page, limit) => {
                      setState(
                        {
                          page,
                          limit,
                        },
                        () => {
                          getNotificationsRequestion();
                        },
                      );
                    }}
                  />
                </div>
              </Col>
            </Row>
            <br />
          </TabPane>
          <TabPane
            tab={_renderTabHead(
              intl.formatMessage({
                id: "screen.label.draft_saved",
              }),
              state.draftNotifications.count,
            )}
            key={2}
          >
            {_renderDraftNotifications(state.draftNotifications)}
            <div className={"card-footer"} style={{ padding: "3%" }}>
              <CustomPagination
                saveId={PageDraftSaveId}
                count={state.draftNotifications.count}
                initPage={state.page}
                initLimit={state.limit}
                onChangePage={(page, limit) => {
                  setState(
                    {
                      draftPage: page,
                      draftLimit: limit,
                    },
                    () => {
                      getDraftNotificationsRequestion();
                    },
                  );
                }}
              />
            </div>
          </TabPane>
        </ProjectGeneralNoticeTabs>

        <Modal
          destroyOnClose
          afterClose={() => {
            setState({
              sendEmail: false,
              editorState: EditorState.createEmpty(),
            });
          }}
          title={intl.formatMessage({
            id: "screen.label.add_announcement",
          })}
          visible={state.visible}
          onOk={getDraftNotificationsRequestion}
          onCancel={onModalCancelled}
          width={"42vw"}
          bodyStyle={{
            paddingLeft: "10vw",
            paddingRight: "10vw",
          }}
          footer={null}
        >
          <Form layout={"vertical"}>
            <Form.Item
              label={intl.formatMessage({
                id: "screen.label.notification_type",
              })}
            >
              <Select
                defaultValue={state.type}
                style={{ width: 200 }}
                onChange={(value) => handleChange("type", value)}
              >
                <Option value="general_notice">
                  {intl.formatMessage({
                    id: "screen.label.notice",
                  })}
                </Option>
                <Option value="poll">
                  {intl.formatMessage({
                    id: "screen.label.questionnaire",
                  })}
                </Option>
              </Select>
            </Form.Item>
            <Form.Item
              label={intl.formatMessage({
                id: "screen.label.title",
              })}
            >
              <Input
                type="text"
                placeholder={intl.formatMessage({
                  id: "screen.label.title",
                })}
                value={state.title}
                onChange={(event) => handleChange("title", event.target.value)}
              />
            </Form.Item>
            {_renderNoticeBody()}

            <Form.Item
              label={intl.formatMessage({
                id: "screen.label.video_url",
              })}
            >
              <Input
                onChange={(event) =>
                  handleChange("videoUrl", event.target.value)
                }
                value={state.videoUrl}
                placeholder={intl.formatMessage({
                  id: "screen.label.video_url",
                })}
              />
            </Form.Item>
            <Form.Item>
              <Checkbox
                onChange={(e) => {
                  handleChange("sendEmail", e.target.checked);
                }}
                checked={state.sendEmail}
              >
                {intl.formatMessage({
                  id: "screen.label.email_notification",
                })}
              </Checkbox>
            </Form.Item>
            <Form.Item>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <InsuAcceptButton
                  style={{
                    marginLeft: "0.1vw",
                    marginRight: "0.1vw",
                  }}
                  onClick={(e) => {
                    submitNotice(e);
                    onModalCancelled();
                  }}
                >
                  {<FormattedMessage id="screens.button.post" />}
                </InsuAcceptButton>
                <InsuPrimaryButton
                  style={{
                    marginLeft: "0.1vw",
                    marginRight: "0.1vw",
                  }}
                  onClick={() => {
                    if (!validCreateNotification()) {
                      return;
                    }
                    saveAsDraft();
                    NotificationMsg.success({
                      message: intl.formatMessage({
                        id: "screen.label.draft_saved",
                      }),
                    });
                    setState({ visible: false });
                    onModalCancelled();
                  }}
                >
                  {<FormattedMessage id="screen.label.draft_saved" />}
                </InsuPrimaryButton>
                <InsuRejectButton
                  style={{
                    marginLeft: "0.1vw",
                    marginRight: "0.1vw",
                  }}
                  onClick={onModalCancelled}
                >
                  {<FormattedMessage id="screen.label.cancel" />}
                </InsuRejectButton>
              </div>
            </Form.Item>
          </Form>
        </Modal>

        <Modal
          destroyOnClose
          onCancel={onCloseRegisterEventModal}
          title={intl.formatMessage({
            id: "screen.label.events_news",
          })}
          afterClose={() => {
            setState({
              editorState: EditorState.createEmpty(),
            });
          }}
          visible={state.eventDetail.id > 0}
          footer={
            <span>
              <Button
                loading={state.eventDetail.isMemberStatus == null}
                onClick={
                  state.eventDetail.isMemberStatus
                    ? onUnRegisterEvent
                    : onRegisterForEvent
                }
                type={"ghost"}
              >
                {state.eventDetail.isMemberStatus
                  ? intl.formatMessage({
                      id: "screen.label.cancel_participation_application",
                    })
                  : intl.formatMessage({
                      id: "screen.label.apply_for_participation",
                    })}
              </Button>
            </span>
          }
        >
          <h4>
            {intl.formatMessage({
              id: "screen.label.event_name",
            })}
          </h4>
          <p>{state.eventDetail.name}</p>
          <br />
          <h4>
            {intl.formatMessage({
              id: "screen.label.more_detail",
            })}
          </h4>
          <p style={{ whiteSpace: "pre-line" }}>
            {state.eventDetail.description}
          </p>
          <br />

          <h4>
            {intl.formatMessage({
              id: "screen.label.event_date_and_time",
            })}
          </h4>
          <p>
            {moment(state.eventDetail.beginsAt)
              .local()
              .format("YYYY-MM-DD HH:mm:ss")}
          </p>
          {state.eventDetail.capacity > 0 ? (
            <>
              <h4>
                {intl.formatMessage({
                  id: "screen.label.capacity",
                })}
              </h4>
              <p>{state.eventDetail.capacity}</p>
            </>
          ) : null}

          {state.eventDetail.applicationDeadline ? (
            <>
              <h4>
                {intl.formatMessage({
                  id: "screen.label.events_application_deadline_date",
                })}
              </h4>
              <p>
                {state.eventDetail.applicationDeadline
                  ? moment(state.eventDetail.applicationDeadline)
                      .local()
                      .format(dateTimeFormat)
                  : ""}
              </p>
            </>
          ) : null}

          {state.eventDetail.endsAt ? (
            <>
              <h4>
                {intl.formatMessage({
                  id: "screen.label.end_event_date_and_time",
                })}
              </h4>
              <p>
                {state.eventDetail.endsAt
                  ? moment(state.eventDetail.endsAt)
                      .local()
                      .format(dateTimeFormat)
                  : ""}
              </p>
            </>
          ) : null}
          {state.eventDetail.videoUrl &&
          state.eventDetail.videoUrl.length > 0 ? (
            <div className="player-wrapper">
              <ReactPlayer
                loop={false}
                controls
                className="react-player"
                url={state.eventDetail.videoUrl}
                width="100%"
                height="100%"
                config={{
                  file: {
                    attributes: {
                      controlsList: "nodownload",
                    },
                  },
                }}
              />
            </div>
          ) : null}
        </Modal>

        <Modal
          afterClose={() => {
            setState({
              editorState: EditorState.createEmpty(),
            });
          }}
          destroyOnClose
          title={state.noticeDetails.noticeType}
          visible={state.noticeDetails.id > 0}
          onOk={onCloseNoticeModal}
          onCancel={onCloseNoticeModal}
          footer={null}
        >
          <h4>
            {intl.formatMessage({
              id: "screen.label.title",
            })}
          </h4>
          <p>{state.noticeDetails.title}</p>
          <br />
          {state.noticeDetails.meta.type === "poll" ? (
            <>
              <h4>URL</h4>
              <a
                rel="noreferrer"
                style={{ whiteSpace: "pre-line" }}
                target={"_blank"}
                href={state.noticeDetails.description}
              >
                {state.noticeDetails.description}
              </a>
            </>
          ) : (
            <>
              <h4>
                {intl.formatMessage({
                  id: "screen.label.more_detail",
                })}
              </h4>
              <p style={{ whiteSpace: "pre-line" }}>{_renderNoticeDetails()}</p>
            </>
          )}

          <br />
          {state.noticeDetails.meta.videoUrl &&
          state.noticeDetails.meta.videoUrl.length > 0 ? (
            <div className="player-wrapper">
              <ReactPlayer
                loop={false}
                controls
                className="react-player"
                url={state.noticeDetails.meta.videoUrl}
                width="100%"
                height="100%"
                config={{
                  file: {
                    attributes: {
                      controlsList: "nodownload",
                    },
                  },
                }}
              />
            </div>
          ) : null}
        </Modal>
        <Modal
          afterClose={() => {
            setState({
              editorState: EditorState.createEmpty(),
            });
          }}
          destroyOnClose
          title={intl.formatMessage({
            id: "screen.label.members_wanted",
          })}
          visible={state.modalVisible}
          onCancel={handleCancel}
          onOk={() => {
            // @ts-expect-error TS2554
            handleCancel();
            // @ts-expect-error TS18048
            props.history.push(`/dashboard/project.top/${state.modalLink}`);
          }}
          cancelText={intl.formatMessage({
            id: "screen.label.cancel",
          })}
          okText={intl.formatMessage({
            id: "screen.label.check_project",
          })}
        >
          {state.modalTitle}{" "}
          {intl.formatMessage({
            id: "screen.label.there_looking_for_members",
          })}
          <br />
          <p style={{ whiteSpace: "pre-line" }}>{state.modalDesc}</p>
          <h4>
            {intl.formatMessage({
              id: "screen.label.application_method",
            })}
          </h4>
          <ol style={{ paddingLeft: "20px" }}>
            <li>
              {intl.formatMessage({
                id: "screen.label.check_project_des",
              })}
            </li>
            <li>
              {intl.formatMessage({
                id: "screen.label.apply_to_become_project_member_des",
              })}
            </li>
          </ol>
        </Modal>
      </>
    );
  };

// @ts-expect-error TS7006
const mapStateToProps = (state) => ({
  project: state.projects.projectPayload,
});
// @ts-expect-error TS7006
const mapDispatchToProps = (dispatch) => ({
  // @ts-expect-error TS7006
  dispatchGetProjectDetail: (id) =>
    // @ts-expect-error TS2554
    dispatch(ProjectActions.projectGetRequest(id)),
  // @ts-expect-error TS7006
  dispatchTakeActionOnNotification: (data, id, message) =>
    // @ts-expect-error TS2554
    dispatch(HomeActions.homeNotificationActionRequest(data, id, message)),
});

ProjectGeneralNoticeScreen.propTypes = {
  // @ts-expect-error TS2322
  context: PropTypes.object,
  match: PropTypes.object,
  history: PropTypes.object,
  dispatchTakeActionOnNotification: PropTypes.func,
  project: PropTypes.object,
  dispatchGetProjectDetail: PropTypes.func,
};
export default withCheckProjectAccess(
  // @ts-expect-error TS2345
  connect(mapStateToProps, mapDispatchToProps)(ProjectGeneralNoticeScreen),
);
