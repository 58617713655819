import { Card, Col, Row, Table } from "antd";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";

import { IconRightArrow } from "../../Components/atoms";
import ApiActions, { ApiRequestData } from "../../Redux/ApiRedux";
import CompanyActions from "../../Redux/CompanyRedux";
import { endpoints, parseEndpoint } from "../../Services/endpoints";

const library_items = [
  {
    type: "news",
    title: "screen.label.news",
    link: "/global-library/news",
  },
  {
    type: "industry",
    title: "screen.label.industry_theme_report",
    link: "/global-library/industry-report",
  },
  {
    type: "reference",
    title: "screen.label.reference_management",
    link: "/global-library/reference",
  },
];
type ActionProps = {};
type StateProps = {
  history: Object;
};
type Props = StateProps & ActionProps;
function LibraryManagementScreen(props: Props) {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [companies, setCompanies] = useState([]);
  const [libraryEnables, setLibraryEnables] = useState([]);

  useEffect(() => {
    getCompanies();
  }, []);

  // @ts-expect-error TS7006
  const goToScreen = (item) => {
    // @ts-expect-error TS2339
    props.history.push(item.link);
  };

  const getCompanies = () => {
    const reqCompanies: ApiRequestData = {
      method: "getRequest",
      url: parseEndpoint(endpoints.companies),
      data: {},
    };
    dispatch(
      ApiActions.apiRequest(reqCompanies, ({ data }) => {
        if (data) {
          //@ts-expect-error 要API型定義
          dispatch(CompanyActions.companySuccess(data));
          //@ts-expect-error 要API型定義
          if (Array.isArray(data.rows)) {
            //@ts-expect-error 要API型定義
            setCompanies(data.rows);
          }
          //@ts-expect-error 要API型定義
          if (Array.isArray(data.libraryEnables)) {
            //@ts-expect-error 要API型定義
            setLibraryEnables(data.libraryEnables);
          }
        }
      }),
    );
  };

  const getLibraryEnableItem = (companyId: number) => {
    // @ts-expect-error TS2339
    const item = libraryEnables.find((item) => item.companyId === companyId);
    return item;
  };

  const checkLibraryEnables = (type: string, companyId: number) => {
    const item = getLibraryEnableItem(companyId);
    if (item) {
      if (type === "news") {
        // @ts-expect-error TS2339
        return item.isNewsEnabled;
      } else if (type === "industry") {
        // @ts-expect-error TS2339
        return item.isIndustryEnabled;
      } else if (type === "reference") {
        // @ts-expect-error TS2339
        return item.isReferenceEnabled;
      }
    }
    return true;
  };

  const onUpdateItem = (type: string, companyId: number, value: boolean) => {
    const item = getLibraryEnableItem(companyId);
    console.log({ item, type });
    if (item) {
      const updateData = {
        companyId,
        // @ts-expect-error TS2339
        isNewsEnabled: type === "news" ? value : item.isNewsEnabled,
        // @ts-expect-error TS2339
        isIndustryEnabled: type === "industry" ? value : item.isIndustryEnabled,
        isReferenceEnabled:
          // @ts-expect-error TS2339
          type === "reference" ? value : item.isReferenceEnabled,
      };
      // @ts-expect-error TS2339
      onUpdateLibraryItem(item.id, updateData);
    } else {
      const createData = {
        companyId,
        isNewsEnabled: type === "news" ? value : true,
        isIndustryEnabled: type === "industry" ? value : true,
        isReferenceEnabled: type === "reference" ? value : true,
      };
      onCreateLibraryItem(createData);
    }
  };

  const onCreateLibraryItem = (creatData: any) => {
    const reqCreateItem: ApiRequestData = {
      method: "postRequest",
      url: parseEndpoint(endpoints.libraryEnable),
      data: creatData,
      successMessage: intl.formatMessage({
        id: "screen.label.has_been_updated",
      }),
    };
    dispatch(
      ApiActions.apiRequest(reqCreateItem, ({ data }) => {
        if (data) {
          getCompanies();
        }
      }),
    );
  };

  const onUpdateLibraryItem = (itemId: string, updateData: any) => {
    const reqUpdateItem: ApiRequestData = {
      method: "putRequest",
      url: parseEndpoint(endpoints.libraryEnableWithId, { itemId }),
      data: updateData,
      successMessage: intl.formatMessage({
        id: "screen.label.has_been_updated",
      }),
    };
    dispatch(
      ApiActions.apiRequest(reqUpdateItem, ({ data }) => {
        if (data) {
          getCompanies();
        }
      }),
    );
  };

  const renderLibraryEnables = () => {
    return library_items.map((item) => {
      return {
        title: intl.formatMessage({ id: item.title }),
        dataIndex: "",
        key: "",
        // @ts-expect-error TS7006
        render: function _fn(text, record, index) {
          return (
            <span>
              <label
                className="container"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                  paddingLeft: 0,
                  marginBottom: 0,
                }}
              >
                <input
                  onChange={(event) => {
                    onUpdateItem(item.type, record.id, event.target.checked);
                  }}
                  checked={checkLibraryEnables(item.type, record.id)}
                  type="checkbox"
                />
                <span className="checkmark" style={{ position: "relative" }} />
              </label>
            </span>
          );
        },
      };
    });
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: intl.formatMessage({
        id: "screen.label.contract_companies",
      }),
      dataIndex: "name",
      key: "name",
    },
    ...renderLibraryEnables(),
  ];

  return (
    <div className="library-management">
      {library_items.map((item, index) => {
        return (
          <Row
            key={index}
            className="management-item"
            align="middle"
            justify="space-between"
            onClick={() => {
              goToScreen(item);
            }}
          >
            <div>{intl.formatMessage({ id: item.title })}</div>
            <div>{IconRightArrow}</div>
          </Row>
        );
      })}
      <br />
      <br />
      <Row>
        <Col xs={24} sm={12} md={8} lg={6}>
          <h2>{`${intl.formatMessage({
            id: "screen.label.contract_number_companies",
          })} ${companies.length}`}</h2>
        </Col>
        <br />
        <Col span={24}>
          <Card>
            <Table
              className="ant-table-x-scroll"
              pagination={false}
              rowKey={"id"}
              columns={columns}
              dataSource={companies}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
}
export default withRouter(LibraryManagementScreen);
