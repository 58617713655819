import { memo } from "react";
import { useIntl } from "react-intl";

import styles from "@/features/review/components/CancelReviewExcelUploadButton/styles.module.scss";
import { CancelReviewExcelUploadButtonProps } from "@/features/review/components/CancelReviewExcelUploadButton/type";
import { TertiaryButton } from "@/shared/buttons/components/TertiaryButton";

export const CancelReviewExcelUploadButton = memo(
  ({ onCancel }: CancelReviewExcelUploadButtonProps) => {
    const intl = useIntl();
    const cancelButtonLabel = intl.formatMessage({
      id: "screen.label.cancel",
    });
    return (
      <TertiaryButton
        onClick={onCancel}
        className={styles.cancel_button}
        data-testid="cancel-review-excel-upload-button"
      >
        {cancelButtonLabel}
      </TertiaryButton>
    );
  },
);
