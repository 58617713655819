import { useContext } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";

import Spinner from "@/editor/src/components/Spinner";
import { ContentContext } from "@/Provider";
import { NotFoundCard } from "@/shared/cards/components/NotFoundCard";
import { Heading1 } from "@/shared/headings/components/Heading1";
import { ColorTag } from "@/shared/tags/components/ColorTag";
import { RenderDraftJsText } from "@/shared/text/components/RenderDraftJsText";
import { isJsonString } from "@/utils/common";

import { useEvent } from "../../hooks/useEvent";
import { EventApplication } from "../EventApplication";
import { EventLocation } from "../EventLocation";
import { EventSchedule } from "../EventSchedule";
import { EventSummary } from "../EventSummary";
import { EventThumbnail } from "../EventThumbnail";
import styles from "./styles.module.scss";

export const EventDetailScreen = function () {
  const params = useParams<{ id: string }>();
  const { event, isValidating } = useEvent(params.id);
  const intl = useIntl();
  const { user } = useContext(ContentContext);

  if (isValidating) {
    return <Spinner />;
  }

  return event ? (
    <>
      {event.thumbnailUrl && (
        <EventThumbnail thumbnailUrl={event.thumbnailUrl} />
      )}
      <div className={styles.event_body}>
        <div className={styles.event_info}>
          <div className={styles.event_header}>
            {(event.locationType == "ONLINE" ||
              event.locationType == "OFFLINE") && (
              <ColorTag
                color="green"
                label={intl.formatMessage({
                  id: `screens.events.${event.locationType.toLowerCase()}`,
                })}
              />
            )}
            <div className={styles.event_title_wrapper}>
              <Heading1>{event.name}</Heading1>
              <div className={styles.event_schedule_and_location}>
                <EventSchedule
                  date={event.eventDate}
                  beginsAt={event.eventTime.beginTime}
                  endsAt={event.eventTime.endTime}
                />
                <EventLocation location={event.location} />
              </div>
            </div>
          </div>
          {event.EventRecommendations.length > 0 && (
            <EventSummary recommendations={event.EventRecommendations} />
          )}
          <div className={styles.event_description}>
            {event.nodalData && isJsonString(event.description) ? (
              <RenderDraftJsText text={event.description} />
            ) : (
              event.description
            )}
          </div>
        </div>
        <div className={styles.event_application_container}>
          <EventApplication event={event} user={user} />
        </div>
      </div>
    </>
  ) : (
    <NotFoundCard
      resourceName={intl.formatMessage({ id: "screen.label.events" })}
    />
  );
};
