import { convertToHalfWidthNumber } from "./convertToHalfWidthNumber";
import { filterNonNumeric } from "./filterNonNumeric";

/**
 * 文字列入力を受け取り、数字以外の文字を削除し、全角の数字を半角に変換する。
 *
 * @param {string} input - フィルタリングして変換する文字列。
 * @returns {string} - フィルタリングされ変換された文字列で、半角数字のみを含む。
 */
export const filterAndConvertToNumber = (input: string) => {
  // 半角・全角数字以外の入力を排除
  const filtered = filterNonNumeric(input);

  // 全角数字を半角数字に変換
  const halfWidthValue = convertToHalfWidthNumber(filtered);

  return halfWidthValue;
};
