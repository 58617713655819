import { notification } from "antd";
import { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";

import { endpoints, parseEndpoint } from "../../../Services/endpoints";
import { isEmailValid } from "../../../utils/common";
import { RegisterState } from "../types/IRegisterProps";

// @ts-expect-error TS7006
const useRegister = (props) => {
  const intl = useIntl();
  const [state, customSetState] = useState<RegisterState>(() => {
    return {
      name: "",
      email: "",
      password: "",
      password2: "",
      errors: {},
      isCompanyRegistrationEnabled: true,
    };
  });

  const setStateCallbackRef = useRef(() => {});
  useEffect(() => {
    const callBack = setStateCallbackRef.current;
    callBack();
    setStateCallbackRef.current = () => {};
  }, [state]);
  const setState = (data: RegisterState, callback = () => {}) => {
    setStateCallbackRef.current = callback;
    customSetState((previousState) => {
      return {
        ...previousState,
        ...data,
      };
    });
  };
  useEffect(() => {
    const requestBuilder = {
      method: "getRequest",
      url: parseEndpoint(endpoints.getCompanyUnathenticatedUser, {
        companyId: getCompanyId(),
      }),
      data: {},
    };
    // @ts-expect-error TS7031
    props.dispatchApiRequest(requestBuilder, ({ data }) => {
      setState({
        isCompanyRegistrationEnabled:
          data.isCompanyRegistrationEnabled && data.currentPlan !== "CLOSE",
      });
    });
  }, []);

  const getCompanyId = () => {
    const {
      match: { params },
    } = props;
    return params.id ? params.id : -1;
  };
  const onChange = (key: string, value: string) => {
    setState({ [key]: value });
  };
  const onSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    if (state.name && state.email && isEmailValid(state.email)) {
      const newUser = {
        name: state.name,
        email: state.email,
      };
      const requestBuilder = {
        method: "postRequest",
        url: parseEndpoint(endpoints.requestRegistration, {
          companyId: getCompanyId(),
        }),
        data: newUser,
        successMessage: intl.formatMessage({
          id: "screen.label.successful_in_transmission_registration_request",
        }),
      };
      // @ts-expect-error TS7031
      props.dispatchApiRequest(requestBuilder, ({ data }) => {
        gotoThankYou();
      });
    } else if (!isEmailValid(state.email)) {
      return notification.error({
        message: intl.formatMessage({
          id: "screen.label.invalid_email_address",
        }),
      });
    }
  };

  const emailValidationStatus = () => {
    // @ts-expect-error TS18048
    if (state.email.length === 0) {
      return "";
    } else if (isEmailValid(state.email)) {
      return "success";
    }
    return "error";
  };
  const gotoThankYou = () => {
    props.history.push("/thank-you");
  };

  return {
    intl,
    state,
    setState,
    getCompanyId,
    onChange,
    onSubmit,
    emailValidationStatus,
    gotoThankYou,
  };
};

export default useRegister;
