import { memo } from "react";

import { getPureStringFromHtml } from "../../../utils/common";
import { ProjectHyComponent } from "../../molecules/project/ProjectHyComponent";

type Props = {
  content: string;
  isRawHtml: boolean;
};
function ProjectArchiveHypothesis({ content, isRawHtml = false }: Props) {
  return isRawHtml ? (
    <ProjectHyComponent dangerouslySetInnerHTML={{ __html: content }} />
  ) : (
    <ProjectHyComponent>{getPureStringFromHtml(content)}</ProjectHyComponent>
  );
}
export default memo(ProjectArchiveHypothesis);
