import { Col, Row } from "antd";
import { memo, useContext } from "react";

import { ContentContext } from "../../../Provider";
import QuillEditor from "../../../Screens/project/QuillEditor";
import { getPureStringFromHtml } from "../../../utils/common";
import { ProjectArchiveCheckpoints } from "./ProjectCheckPoints";

type Props = {
  projectId: string;
  batchId: string;
  content: string;
  displayCheckpoint: boolean;
  hypothesisFormId: string;
  projectCheckpoints?: Array<any>;
};
function ProjectHypothesisArchive({
  projectId,
  batchId,
  content,
  displayCheckpoint,
  hypothesisFormId,
  projectCheckpoints,
}: Props) {
  const { company } = useContext(ContentContext);

  const pureContent = getPureStringFromHtml(content).trim();

  const enableCheckpoint = company.isAllowCheckpoint && displayCheckpoint;
  return (
    <Row align="top" justify="space-between">
      <Col span={enableCheckpoint ? 11 : 24}>
        <QuillEditor
          defaultId={hypothesisFormId}
          defaultValue={content}
          placeholder={""}
          contentStyle={{
            backgroundColor: "white",
            minHeight: 50,
            paddingLeft: 13,
            paddingRight: 13,
            paddingTop: pureContent !== "" ? 20 : 0,
            paddingBottom: pureContent !== "" ? 20 : 0,
          }}
          onChangeValue={() => null}
          readOnly
        />
        <br />
        <br />
      </Col>
      {enableCheckpoint && (
        <Col span={12}>
          <ProjectArchiveCheckpoints
            projectCheckpoints={projectCheckpoints ?? []}
            companyPlan={company.currentPlan}
            projectId={projectId}
            batchId={batchId}
            hypothesisFormId={hypothesisFormId}
          />
        </Col>
      )}
    </Row>
  );
}
export default memo(ProjectHypothesisArchive);
