import { Form, notification, Row } from "antd";
import PropTypes from "prop-types";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";

import { useEventReminder } from "../../../Services/event/useEventReminder";
import { ButtonCV, ButtonUndo, Checkbox } from "../../atoms";
import { Input, TextArea } from "../../molecules";
import { FCFSColumn } from "./Common";
import {
  ILotteryEventReminderComponentProps,
  LotteryEventReminderComponentState,
} from "./types";

const useLotteryEventReminderComponent = (
  props: ILotteryEventReminderComponentProps,
) => {
  const intl = useIntl();
  const [state, customSetState] = useState<LotteryEventReminderComponentState>(
    () => {
      return {
        sendToMembersThatCanAttend: false,
        sendToMembersThatCannotAttend: false,
        sendToMembersYetToRegister: false,
        sendEmail: false,
        title: "",
        details: "",
      };
    },
  );
  const setStateCallbackRef = useRef(() => {});
  useEffect(() => {
    const callBack = setStateCallbackRef.current;
    callBack();
    setStateCallbackRef.current = () => {};
  }, [state]);
  const setState = (
    data: LotteryEventReminderComponentState,
    callback = () => {},
  ) => {
    setStateCallbackRef.current = callback;
    customSetState((previousState) => {
      return {
        ...previousState,
        ...data,
      };
    });
  };
  const { createLotteryEventReminder } = useEventReminder();

  // @ts-expect-error TS7006
  const handleChange = (key, value) => {
    // @ts-expect-error TS2345
    setState({ [key]: value });
  };

  const onSendReminder = useCallback(async () => {
    if (state.title.trim().length === 0) {
      return notification.error({
        message: intl.formatMessage({
          id: "screen.label.title_required",
        }),
      });
    }
    if (state.details.trim().length === 0) {
      return notification.error({
        message: intl.formatMessage({
          id: "screen.label.details_are_required",
        }),
      });
    }
    const { isFailed } = await createLotteryEventReminder(props.eventId, state);
    if (isFailed) return;
    notification.success({
      message: intl.formatMessage({
        id: "screen.label.sent_notification",
      }),
    });
    props.onCloseEventReminderModal?.();
  }, [createLotteryEventReminder, intl, props, state]);

  return {
    intl,
    handleChange,
    onSendReminder,
    state,
    setState,
  };
};

const LotteryEventReminderComponent: FC<ILotteryEventReminderComponentProps> =
  function (props) {
    const { intl, handleChange, onSendReminder, state, setState } =
      useLotteryEventReminderComponent(props);

    return (
      <Form layout={"vertical"}>
        <Form.Item
          label={`${intl.formatMessage({
            id: "screen.label.delivery_destination",
          })} *`}
        >
          <Row>
            <FCFSColumn xs={24} sm={12} md={7}>
              <Checkbox
                onChange={() => {
                  setState({
                    ...state,
                    sendToMembersThatCanAttend:
                      !state.sendToMembersThatCanAttend,
                  });
                }}
                checked={state.sendToMembersThatCanAttend}
              >
                {intl.formatMessage({
                  id: "screen.label.person_who_was_elected",
                })}
              </Checkbox>
            </FCFSColumn>
            <FCFSColumn xs={24} sm={12} md={7}>
              <Checkbox
                onChange={() => {
                  setState({
                    ...state,
                    sendToMembersThatCannotAttend:
                      !state.sendToMembersThatCannotAttend,
                  });
                }}
                checked={state.sendToMembersThatCannotAttend}
              >
                {intl.formatMessage({
                  id: "screen.label.defeated_people",
                })}
              </Checkbox>
            </FCFSColumn>
            <FCFSColumn xs={24} sm={12} md={10}>
              <Checkbox
                checked={state.sendToMembersYetToRegister}
                onChange={() => {
                  setState({
                    ...state,
                    sendToMembersYetToRegister:
                      !state.sendToMembersYetToRegister,
                  });
                }}
              >
                {intl.formatMessage({
                  id: "screen.label.people_who_do_not_apply",
                })}
              </Checkbox>
            </FCFSColumn>
          </Row>
        </Form.Item>

        <Form.Item
          label={`${intl.formatMessage({
            id: "screen.label.title",
          })} *`}
        >
          <Input
            onChange={(event) => handleChange("title", event.target.value)}
            value={state.title}
          />
        </Form.Item>

        <Form.Item
          label={`${intl.formatMessage({
            id: "screen.label.more_detail",
          })} *`}
        >
          <TextArea
            onChange={(event) => handleChange("details", event.target.value)}
            rows={7}
            value={state.details}
          />
        </Form.Item>

        <Form.Item
          label={`${intl.formatMessage({
            id: "screen.label.email_notification",
          })} *`}
        >
          <Checkbox
            checked={state.sendEmail}
            onChange={() => {
              setState({
                ...state,
                sendEmail: !state.sendEmail,
              });
            }}
          >
            {intl.formatMessage({
              id: "screen.label.notify_same_content_email",
            })}
          </Checkbox>
        </Form.Item>

        <Form.Item>
          <ButtonCV onClick={onSendReminder}>
            {intl.formatMessage({
              id: "screens.title.post",
            })}
          </ButtonCV>
          <ButtonUndo
            onClick={props.onCloseEventReminderModal}
            className="offset-left-16"
          >
            {intl.formatMessage({
              id: "screen.label.cancel",
            })}
          </ButtonUndo>
        </Form.Item>
      </Form>
    );
  };
LotteryEventReminderComponent.propTypes = {
  eventId: PropTypes.any,
  onCloseEventReminderModal: PropTypes.func,
};
export default LotteryEventReminderComponent;
