import { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";

import { endpoints, parseEndpoint } from "../../../Services/endpoints";
import {
  EmailSettingsScreenState,
  IEmailSettingsScreenProps,
} from "../types/IEmailSettingsScreenProps";

const useEmailSettingsScreen = (props: IEmailSettingsScreenProps) => {
  const intl = useIntl();
  const { user, apiRequest, updatePageTitle } = props.context;
  const [state, customSetState] = useState<EmailSettingsScreenState>(() => {
    return {
      emailListFromServer: [],
    };
  });
  const setStateCallbackRef = useRef(() => {});
  useEffect(() => {
    const callBack = setStateCallbackRef.current;
    callBack();
    setStateCallbackRef.current = () => {};
  }, [state]);
  const setState = (data: EmailSettingsScreenState, callback = () => {}) => {
    setStateCallbackRef.current = callback;
    customSetState((previousState) => {
      return {
        ...previousState,
        ...data,
      };
    });
  };

  useEffect(() => {
    updatePageTitle("screen.label.mail_settings");
    getSavedEmailSettings();
  }, []);

  // @ts-expect-error TS7006
  const syncWithServerKeys = (data) => {
    setState({
      emailListFromServer: data,
    });
  };

  type UserEmailSubscribe = {
    id: number;
    emailType: string;
    isChecked: boolean;
  };

  const upsertEmailSettings = (
    existingEmailSubscribes: UserEmailSubscribe[],
    newEmailSubscribe: UserEmailSubscribe,
  ) => [
    ...existingEmailSubscribes.filter(({ id }) => id !== newEmailSubscribe?.id),
    newEmailSubscribe,
  ];

  const getSavedEmailSettings = () => {
    const requestBuilder = {
      method: "getRequest",
      url: parseEndpoint(endpoints.emailSettings, {
        userId: user.id,
      }),
      data: {},
    };
    // @ts-expect-error TS2345
    apiRequest(requestBuilder, ({ data }) => {
      syncWithServerKeys(data);
    });
  };
  // @ts-expect-error TS7006
  const onChangeEmailStatus = (emailType, state) => {
    const requestBuilder = {
      method: "putRequest",
      url: parseEndpoint(endpoints.emailSettings, {
        userId: user.id,
      }),
      data: { emailType, isChecked: state },
      successMessage: intl.formatMessage({
        id: "screen.label.setting_has_been_saved",
      }),
    };
    // @ts-expect-error TS2345
    apiRequest(requestBuilder, ({ data }) => {
      customSetState((prevState) => ({
        emailListFromServer: upsertEmailSettings(
          prevState.emailListFromServer,
          // @ts-expect-error TS2345
          data,
        ),
      }));
    });
  };
  // @ts-expect-error TS7006
  const getCheckedDefaultState = (localObj) => {
    const match = state.emailListFromServer.filter(
      // @ts-expect-error TS7006
      (e) => e.emailType === localObj.emailType,
    );
    if (match.length > 0) {
      return match[0].isChecked;
    }
    return true;
  };

  return {
    intl,
    state,
    setState,
    syncWithServerKeys,
    getSavedEmailSettings,
    onChangeEmailStatus,
    getCheckedDefaultState,
  };
};

export default useEmailSettingsScreen;
