import { Card, Col, Form, Modal, Popconfirm, Row, Table } from "antd";
import moment from "moment";
import PropTypes from "prop-types";
import { FC } from "react";
import ReactDragListView from "react-drag-listview";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { ReduxDispatch, ReduxState } from "@/Redux/types";

import {
  ButtonCV,
  ButtonUndo,
  IconDelete,
  IconEdit,
} from "../../Components/atoms";
import {
  CustomPagination,
  Option,
  SearchField,
  Select,
} from "../../Components/molecules";
import { GlobalManageCategoriesComponent } from "../../Components/organisms";
import ApiActions, {
  ApiRequestData,
  ApiSuccessCallback,
} from "../../Redux/ApiRedux";
import HomeActions from "../../Redux/HomeRedux";
import VideoActions from "../../Redux/VideoRedux";
import { dateTimeFormat, defaultThumbnailImg } from "../../utils/constants";
import useSmallCategoriesManagementScreen from "./hooks/useSmallCategoriesManagementScreen";
import { ISmallCategoriesManagementScreenProps } from "./types/ISmallCategoriesManagementScreenProps";

const SmallCategoriesManagementScreen: FC<ISmallCategoriesManagementScreenProps> =
  function (props) {
    const {
      intl,
      state,
      setState,
      getLargeCategoryName,
      getLargeCategoryId,
      deleteVideo,
      getDragProps,
      onDeleteSmallCategory,
      onSubmitNewCategory,
      applyFilter,
    } = useSmallCategoriesManagementScreen(props);
    const getTableColumns = () => {
      return [
        {
          title: "ID",
          dataIndex: "id",
          key: "id",
        },
        {
          title: intl.formatMessage({
            id: "screen.label.video_name",
          }),
          dataIndex: "name",
          key: "name",
          // @ts-expect-error TS7006
          render: function _fn(text, row) {
            return (
              <div /*to={`/dashboard/videos-play/${row.id}`}*/>
                <img
                  alt={intl.formatMessage({
                    id: "screens.alt.video_thumbnail",
                  })}
                  src={row.imageThumbnail || defaultThumbnailImg}
                  style={{
                    borderRadius: "4px",
                    width: "25vh",
                    height: "14vh",
                    objectFit: "cover",
                  }}
                />
                <br />
                <h4>{text}</h4>
              </div>
            );
          },
        },
        {
          title: intl.formatMessage({
            id: "screen.label.category_title",
          }),
          dataIndex: "category",
          key: "category",
          // @ts-expect-error TS7006
          render: function _fn(text, row) {
            return (
              <>
                <h4>
                  {row.VideoLargeCategory.name
                    ? row.VideoLargeCategory.name
                    : ""}
                </h4>
                <h5>
                  {row.VideoSmallCategory.name
                    ? row.VideoSmallCategory.name
                    : ""}
                </h5>
              </>
            );
          },
        },
        {
          title: intl.formatMessage({
            id: "screen.label.posted_date_and_time",
          }),
          dataIndex: "createdAt",
          key: "createdAt",
          // @ts-expect-error TS7006
          render: (text, row) =>
            moment(row.createdAt).local().format(dateTimeFormat),
        },
        {
          title: "",
          dataIndex: "",
          key: "",
          // @ts-expect-error TS7006
          render: (_, row) => {
            const deleteVideoNode = (
              <>
                <Link to={`/global-video/edit/${row.id}`}>
                  <div
                    style={{
                      marginRight: "7px",
                    }}
                  >
                    {IconEdit}
                  </div>
                </Link>

                <Popconfirm
                  title={intl.formatMessage({
                    id: "screen.label.confirm_delete_video",
                  })}
                  onConfirm={() => {
                    deleteVideo(row.id);
                  }}
                  okText={intl.formatMessage({
                    id: "screen.label.yes",
                  })}
                  cancelText={intl.formatMessage({
                    id: "screen.label.no",
                  })}
                >
                  <div
                    style={{
                      marginRight: "7px",
                    }}
                  >
                    {IconDelete}
                  </div>
                </Popconfirm>
              </>
            );
            const selectedLargeCategory = row.VideoLargeCategory;
            if (selectedLargeCategory) {
              if (
                selectedLargeCategory.companyId === null &&
                // @ts-expect-error TS18048
                props.user.isSuper
              ) {
                return deleteVideoNode;
              } else if (selectedLargeCategory.companyId !== null) {
                return deleteVideoNode;
              }
            }
          },
        },
      ];
    }; //

    // @ts-expect-error TS7006
    const _renderSmallCategories = (largeCategoryId) => {
      const data = state.smallCategories[largeCategoryId];
      if (data && Array.isArray(data)) {
        return data.map((sm) => {
          return (
            <Option key={sm.id} value={sm.id}>
              {sm.name}
            </Option>
          );
        });
      }
    }; //
    return (
      <>
        <Modal
          title={intl.formatMessage({
            id: "screen.label.category_management",
          })}
          visible={state.isCategoryModalVisible}
          footer={null}
          onCancel={() => {
            setState({
              // @ts-expect-error TS2345
              isCategoryModalVisible: false,
            });
          }}
        >
          <GlobalManageCategoriesComponent
            onDeleteSmallCategory={onDeleteSmallCategory}
            onSubmitNewCategory={onSubmitNewCategory}
            smallCategories={state.smallCategories}
            // @ts-expect-error TS2322
            largeCategories={props.largeCategories}
            user={props.user}
            activeLargeCategory={parseInt(getLargeCategoryId())}
          />
        </Modal>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={8} md={8} lg={8}>
            <h2>{getLargeCategoryName()}</h2>
          </Col>
          <Col xs={24} sm={16} md={16} lg={16}>
            <Row align="middle">
              <ButtonCV style={{ background: "#00bf76" }}>
                <Link style={{ color: "white" }} to="/global-video/create">
                  <FormattedMessage id="screen.label.new_video_posting" />
                </Link>
              </ButtonCV>
              <ButtonUndo
                onClick={() => {
                  setState({
                    // @ts-expect-error TS2345
                    isCategoryModalVisible: true,
                  });
                }}
              >
                {intl.formatMessage({
                  id: "screen.label.edit_category",
                })}
              </ButtonUndo>
            </Row>
          </Col>
        </Row>
        <Card>
          <Row>
            <Col xs={24} sm={6} md={6}>
              <SearchField
                enterButton={intl.formatMessage({
                  id: "screen.label.retrieval",
                })}
                // @ts-expect-error TS2322
                onSearch={(value) => {
                  setState(
                    {
                      page: 1,
                      searchWord: value,
                      // @ts-expect-error TS2322
                      lastVideoId: null,
                    },
                    () => {
                      applyFilter();
                      setState({
                        isSearch: true,
                      });
                    },
                  );
                }}
              />
            </Col>
            <Col xs={24} sm={6} md={6} />
            <Col
              xs={24}
              md={12}
              style={{
                paddingTop: "7px",
                marginLeft: "-10px",
                float: "right",
              }}
            >
              <Form
                layout={"inline"}
                name="basic"
                style={{
                  float: "right",
                }}
              >
                <Form.Item>
                  {state.videoLargeCategoryId && state.videoSmallCategoryId ? (
                    <Select
                      defaultValue={state.videoSmallCategoryId}
                      onChange={(videoSmallCategoryId) => {
                        setState(
                          {
                            videoSmallCategoryId,
                            page: 1,
                            // @ts-expect-error TS2322
                            lastVideoId: null,
                          },
                          () => {
                            applyFilter();
                            setState({
                              isSearch: false,
                            });
                          },
                        );
                      }}
                    >
                      {_renderSmallCategories(state.videoLargeCategoryId)}
                    </Select>
                  ) : null}
                </Form.Item>
              </Form>
            </Col>
          </Row>
          <br />
          <ReactDragListView {...getDragProps()}>
            <Table
              rowClassName={"drag-handle"}
              pagination={false}
              rowKey={"id"}
              columns={getTableColumns()}
              dataSource={
                Array.isArray(state.videos.rows) ? state.videos.rows : []
              }
              className="ant-table-x-scroll"
            />
          </ReactDragListView>
          <br />
          <CustomPagination
            saveId={"SmallCategoriesManagementScreen"}
            count={state.videos.count}
            initPage={state.page}
            initLimit={state.limit}
            onChangePage={(page, limit) => {
              setState(
                {
                  page,
                  // @ts-expect-error TS2322
                  limit,
                },
                () => {
                  if (Array.isArray(state.videos.rows)) {
                    const lastVideoId =
                      state.videos.rows[state.videos.rows.length - 1].id;
                    setState({ lastVideoId }, () => {
                      applyFilter();
                    });
                  }
                },
              );
            }}
          />
        </Card>
      </>
    );
  };

const mapStateToProps = (state: ReduxState) => ({
  //@ts-expect-error 要API型定義
  largeCategories: state.company.companies.videosLargeCategory,
  user: state.auth.payload,
  auth: state.auth,
});
const mapDispatchToProps = (dispatch: ReduxDispatch) => ({
  // @ts-expect-error TS7006
  dispatchSetPageTitle: (pageTitle) =>
    // @ts-expect-error TS2554
    dispatch(HomeActions.pageTitle(pageTitle)),
  dispatchApiRequest: (data: ApiRequestData, callback: ApiSuccessCallback) =>
    dispatch(ApiActions.apiRequest(data, callback)),
  // @ts-expect-error TS7006
  dispatchSetVideoCategories: (largeCategories) =>
    // @ts-expect-error TS2554
    dispatch(VideoActions.videoLargeCategoriesGetSuccess(largeCategories)),
});

SmallCategoriesManagementScreen.propTypes = {
  largeCategories: PropTypes.array,
  user: PropTypes.object,
  // @ts-expect-error TS2322
  auth: PropTypes.object,
  match: PropTypes.object,

  dispatchSetPageTitle: PropTypes.func,
  dispatchApiRequest: PropTypes.func,
  dispatchSetVideoCategories: PropTypes.func,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  //@ts-expect-error 要API型定義
  SmallCategoriesManagementScreen,
);
