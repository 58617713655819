import { Card } from "antd";
import React from "react";
import { CgChevronRight } from "react-icons/cg";
import { useIntl } from "react-intl";

import { IsExaminationPhaseEnabledToggleButton } from "../../../batch/components/IsExaminationPhaseEnabledToggleButton";
import styles from "./styles.module.scss";
import { ExaminationPhaseManagementScreenContainerProps } from "./type";

export const ExaminationPhaseManagementScreenContainer: React.FC<
  ExaminationPhaseManagementScreenContainerProps
> = ({ children, batch, onClickToggleButton }) => {
  const intl = useIntl();
  const titleText = intl.formatMessage({
    id: "screen.users.examination_phases_management",
  });

  const title = batch && (
    <div className={styles.title_container}>
      <div>
        {batch.name}
        <span className={styles.title_icon_container}>
          <CgChevronRight className={styles.title_icon} />
        </span>
        {titleText}
      </div>
      <IsExaminationPhaseEnabledToggleButton
        isChecked={batch.isExaminationPhaseEnabled}
        onClick={onClickToggleButton}
      />
    </div>
  );

  return <Card title={title ?? <></>}>{children}</Card>;
};
