import PropTypes from "prop-types";
import { ReactElement } from "react";

type AnalyticsListComponentProps<T> = {
  title: ReactElement;
  renderItem: (item: T) => ReactElement;
  dataSource: Array<T>;
  top?: boolean;
};

function AnalyticsListComponent<T>(props: AnalyticsListComponentProps<T>) {
  const { dataSource, title, renderItem, top } = props;
  return (
    <div
      style={{
        marginTop: top ? "48px" : "60px",
      }}
    >
      <p
        style={{
          fontSize: "12px",
          fontWeight: "bold",
          marginBottom: "30px",
        }}
      >
        {title}
      </p>

      {dataSource.map((item: any, index) => {
        return (
          <li
            key={`${index}_${title}`}
            style={{
              paddingTop: "10px",
              paddingBottom: "10px",
              borderBottomStyle: "solid",
              borderBottomWidth: "1px",
              borderBottomColor: "#DDDDDD",
              height: "48px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                paddingRight: "1vw",
                paddingLeft: "1vw",
              }}
            >
              {renderItem(item)}
            </div>
          </li>
        );
      })}
    </div>
  );
}
AnalyticsListComponent.propTypes = {
  title: PropTypes.element.isRequired,
  renderItem: PropTypes.func.isRequired,
  dataSource: PropTypes.array.isRequired,
  top: PropTypes.bool,
};
AnalyticsListComponent.defaultProps = {};
export default AnalyticsListComponent;
