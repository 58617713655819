import { Card, Col, Modal, Row, Table } from "antd";
import PropTypes from "prop-types";
import { FC, useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { ReduxDispatch, ReduxState } from "@/Redux/types";

import { ButtonCV, IconRightArrow } from "../../Components/atoms";
import ApiActions, {
  ApiRequestData,
  ApiSuccessCallback,
} from "../../Redux/ApiRedux";
import AuthActions from "../../Redux/AuthRedux";
import CompanyActions from "../../Redux/CompanyRedux";
import HomeActions from "../../Redux/HomeRedux";
import { endpoints, parseEndpoint } from "../../Services/endpoints";
import { getObjUUID } from "../../utils/common";
import {
  GlobalVideoMgtState,
  IGlobalVideoMgtProps,
} from "./types/IGlobalVideoMgtProps";

const GlobalVideoMgt: FC<IGlobalVideoMgtProps> = function (props) {
  const intl = useIntl();
  const [state, customSetState] = useState<GlobalVideoMgtState>(() => {
    return {
      companyVideoLargeCategoryIdNotAllowedToView: {},
      companyVideoSharedSmallCategoryIdNotAllowedToView: {},
      isSmallCategoriesModalVisible: false,
      selectedCompany: {},
      selectedLargeCategory: {},
      largeCategories: [],
    };
  });
  const setStateCallbackRef = useRef(() => {});
  useEffect(() => {
    const callBack = setStateCallbackRef.current;
    callBack();
    setStateCallbackRef.current = () => {};
  }, [state]);
  const setState = (data: any, callback = () => {}) => {
    setStateCallbackRef.current = callback;
    customSetState((previousState) => {
      return {
        ...previousState,
        ...data,
      };
    });
  };

  useEffect(() => {
    // @ts-expect-error TS2722
    props.dispatchSetAuthToken(props.auth.payload.token);
    if (props.auth.payload.isSuper) {
      getCompanies();
      getSharedLargeCategories();
    }
  }, []);

  // @ts-expect-error TS7006
  const updateCompanyVideoSettings = (companyUUID, companyId) => {
    const data = {
      method: "putRequest",
      url: parseEndpoint(endpoints.singleCompany, {
        companyId: companyUUID,
      }),
      successMessage: intl.formatMessage({
        id: "screen.label.has_been_updated",
      }),
      data: {
        videoLargeCategoryIdNotAllowedToView:
          // @ts-expect-error TS18048
          state.companyVideoLargeCategoryIdNotAllowedToView[companyId],
      },
    };
    props.dispatchApiRequest(data, () => {
      getCompanies();
    });
  };
  // @ts-expect-error TS7006
  const updateCompanySmallVideoSettings = (companyUUID, companyId) => {
    const data = {
      method: "putRequest",
      url: parseEndpoint(endpoints.singleCompany, {
        companyId: companyUUID,
      }),
      successMessage: intl.formatMessage({
        id: "screen.label.has_been_updated",
      }),
      data: {
        videoSmallCategoryIdNotAllowedToView:
          // @ts-expect-error TS2551
          state.companyVideoSharedSmallCategoryIdNotAllowedToView[companyId],
      },
    };
    props.dispatchApiRequest(data, () => {
      getCompanies();
    });
  };
  const getSharedLargeCategories = () => {
    const shared = props.companies?.videosLargeCategory;
    if (Array.isArray(shared)) {
      return shared.map((largeCartegory) => {
        return {
          title: largeCartegory.name,
          dataIndex: "",
          key: "",
          // @ts-expect-error TS7006
          render: function _fn(_, company) {
            if (
              !Array.isArray(
                // @ts-expect-error TS18048
                state.companyVideoLargeCategoryIdNotAllowedToView[company.id],
              )
            ) {
              return <></>;
            }
            return (
              <span>
                <label className="container">
                  <input
                    onChange={() => {
                      const { companyVideoLargeCategoryIdNotAllowedToView } =
                        state;
                      const excludedLargeCategoryId =
                        // @ts-expect-error TS18048
                        companyVideoLargeCategoryIdNotAllowedToView[company.id];
                      if (excludedLargeCategoryId.includes(largeCartegory.id)) {
                        // @ts-expect-error TS18048
                        companyVideoLargeCategoryIdNotAllowedToView[
                          company.id
                          // @ts-expect-error TS7006
                        ] = excludedLargeCategoryId.filter((datum) => {
                          return datum !== largeCartegory.id;
                        });
                      } else {
                        // @ts-expect-error TS18048
                        companyVideoLargeCategoryIdNotAllowedToView[
                          company.id
                        ] = excludedLargeCategoryId.concat(largeCartegory.id);
                      }
                      setState(
                        {
                          companyVideoLargeCategoryIdNotAllowedToView,
                        },
                        () => {
                          updateCompanyVideoSettings(
                            getObjUUID(company),
                            company.id,
                          );
                        },
                      );
                    }}
                    checked={
                      // @ts-expect-error TS18048
                      !state.companyVideoLargeCategoryIdNotAllowedToView[
                        company.id
                      ].includes(largeCartegory.id)
                    }
                    type="checkbox"
                  />
                  <span className="checkmark" />
                </label>
                <ButtonCV
                  style={{
                    marginLeft: "12%",
                  }}
                  onClick={() => {
                    const {
                      // @ts-expect-error TS2339
                      companyVideoSharedSmallCategoryIdNotAllowedToView,
                    } = state;
                    companyVideoSharedSmallCategoryIdNotAllowedToView[
                      company.id
                    ] = company.videoSmallCategoryIdNotAllowedToView;
                    setState({
                      isSmallCategoriesModalVisible: true,
                      smallCategoriesData: largeCartegory.VideoSmallCategory,
                      selectedCompany: company,
                      selectedLargeCategory: largeCartegory,
                      companyVideoSharedSmallCategoryIdNotAllowedToView,
                    });
                  }}
                  size={"small"}
                >
                  <FormattedMessage id="screen.label.small_category" />
                </ButtonCV>
              </span>
            );
          },
        };
      });
    }
    return [];
  };
  const getCompanies = () => {
    const data = {
      method: "getRequest",
      url: parseEndpoint(endpoints.companies),
      data: {},
    };
    // @ts-expect-error TS7031
    props.dispatchApiRequest(data, ({ data }) => {
      // @ts-expect-error TS2722
      props.dispatchSetCompanies(data);
      if (Array.isArray(data.rows)) {
        const { companyVideoLargeCategoryIdNotAllowedToView } = state;
        for (let i = 0; i < data.rows.length; i++) {
          const company = data.rows[i];
          // @ts-expect-error TS18048
          companyVideoLargeCategoryIdNotAllowedToView[company.id] =
            company.videoLargeCategoryIdNotAllowedToView;
        }
        setState({ companyVideoLargeCategoryIdNotAllowedToView });
      }
    });
  };
  const getLargeCategoriesToRender = () => {
    const shared = props.companies?.videosLargeCategory;
    if (Array.isArray(shared)) {
      return shared.filter((l) => {
        if (l.companyId == null) {
          return true;
        }
      });
    }
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: intl.formatMessage({
        id: "screen.label.contract_companies",
      }),
      dataIndex: "name",
      key: "name",
    },
    ...getSharedLargeCategories(),
  ];
  return (
    <>
      <Row>
        <Col xs={24} sm={12} md={8} lg={6}>
          <h2>{`${intl.formatMessage({
            id: "screen.label.contract_number_companies",
            // @ts-expect-error TS18048
          })} ${props.companies.count}`}</h2>
        </Col>
        <br />
        <Col span={24}>
          <Card>
            <Table
              pagination={false}
              rowKey={"id"}
              columns={columns}
              dataSource={
                Array.isArray(props?.companies?.rows)
                  ? props?.companies?.rows
                  : []
              }
              className="ant-table-x-scroll"
            />
          </Card>
        </Col>
      </Row>
      <Modal
        onCancel={() => {
          setState({
            isSmallCategoriesModalVisible: false,
            selectedCompany: {},
            selectedLargeCategory: {},
          });
        }}
        onOk={() => {
          setState({
            isSmallCategoriesModalVisible: false,
            selectedCompany: {},
            selectedLargeCategory: {},
          });
        }}
        footer={null}
        // @ts-expect-error TS2339
        visible={state.isSmallCategoriesModalVisible}
        // @ts-expect-error TS2339
        title={state.selectedCompany.name}
      >
        <Table
          pagination={false}
          rowKey={"id"}
          columns={[
            {
              title: "",
              dataIndex: "",
              key: "",
              render: function _fn(_, smallCategory) {
                // @ts-expect-error TS2339
                const { selectedCompany } = state;
                return (
                  <label className="container">
                    <input
                      onChange={() => {
                        const {
                          // @ts-expect-error TS2339
                          companyVideoSharedSmallCategoryIdNotAllowedToView,
                        } = state;
                        const excludedSmallCategoryId =
                          companyVideoSharedSmallCategoryIdNotAllowedToView[
                            selectedCompany.id
                          ];
                        if (
                          excludedSmallCategoryId.includes(smallCategory.id)
                        ) {
                          companyVideoSharedSmallCategoryIdNotAllowedToView[
                            selectedCompany.id
                            // @ts-expect-error TS7006
                          ] = excludedSmallCategoryId.filter((datum) => {
                            return datum !== smallCategory.id;
                          });
                        } else {
                          companyVideoSharedSmallCategoryIdNotAllowedToView[
                            selectedCompany.id
                          ] = excludedSmallCategoryId.concat(smallCategory.id);
                        }
                        setState(
                          {
                            companyVideoSharedSmallCategoryIdNotAllowedToView,
                          },
                          () => {
                            updateCompanySmallVideoSettings(
                              getObjUUID(selectedCompany),
                              selectedCompany.id,
                            );
                          },
                        );
                      }}
                      checked={
                        // @ts-expect-error TS2551
                        !state.companyVideoSharedSmallCategoryIdNotAllowedToView[
                          selectedCompany.id
                        ].includes(smallCategory.id)
                      }
                      type="checkbox"
                    />
                    <span className="checkmark" />
                  </label>
                );
              },
            },
            {
              title: intl.formatMessage({
                id: "screen.label.small_category",
              }),
              dataIndex: "name",
              key: "name",
            },
          ]}
          dataSource={
            // @ts-expect-error TS2339
            Array.isArray(state.selectedLargeCategory.VideoSmallCategory)
              ? // @ts-expect-error TS2339
                state.selectedLargeCategory.VideoSmallCategory
              : []
          }
        />
      </Modal>
      <br />

      <Row>
        <Col xs={24} sm={12} md={8} lg={6}>
          <h2>
            <FormattedMessage id="screen.label.video_management" />
          </h2>
        </Col>

        {/*
         // @ts-expect-error TS2532 */}
        {getLargeCategoriesToRender().map((l) => {
          return (
            <Col span={24} key={l.id}>
              <Link
                to={`/global-video/videos-large-category/${l.id}/${l.name}`}
              >
                <Card>
                  <h3>{l.name}</h3>
                  <span
                    style={{
                      marginRight: "30px",
                    }}
                  >
                    <FormattedMessage id="screen.label.small_category" />：
                    {l.VideoSmallCategory ? l.VideoSmallCategory.length : 0}
                  </span>
                  <span>
                    <FormattedMessage id="screen.label.video" />:
                    {l.Videos ? l.Videos.length : 0}
                  </span>
                  <div
                    style={{
                      float: "right",
                      marginBottom: "30px",
                    }}
                  >
                    {IconRightArrow}
                  </div>
                </Card>
              </Link>
            </Col>
          );
        })}
      </Row>
      <br />
      <br />
      <br />
    </>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  auth: state.auth,
  companies: state.company.companies,
});
const mapDispatchToProps = (dispatch: ReduxDispatch) => ({
  // @ts-expect-error TS7006
  dispatchSetBaseUrl: (url) => dispatch(AuthActions.loginSetBaseUrl(url)),
  dispatchLogoutUser: () => dispatch(AuthActions.logout()),
  // @ts-expect-error TS7006
  dispatchSetAuthToken: (token) =>
    dispatch(AuthActions.loginSetAuthToken(token)),
  dispatchApiRequest: (data: ApiRequestData, callback: ApiSuccessCallback) =>
    dispatch(ApiActions.apiRequest(data, callback)),
  // @ts-expect-error TS7006
  dispatchSetCompanies: (companies) =>
    dispatch(CompanyActions.companySuccess(companies)),
  // @ts-expect-error TS7006
  dispatchSetPageTitle: (pageTitle) =>
    // @ts-expect-error TS2554
    dispatch(HomeActions.pageTitle(pageTitle)),
  // @ts-expect-error TS7006
  dispatchSetSuperUser: (data) => dispatch(AuthActions.loginSuccess(data)),
  // @ts-expect-error TS7006
  dispatchSetSuperUserToken: (token) =>
    dispatch(AuthActions.loginSetAuthToken(token)),
});

GlobalVideoMgt.propTypes = {
  auth: PropTypes.object,
  companies: PropTypes.object,

  dispatchSetBaseUrl: PropTypes.func,
  dispatchLogoutUser: PropTypes.func,
  dispatchSetAuthToken: PropTypes.func,
  // @ts-expect-error TS2322
  dispatchApiRequest: PropTypes.func,
  dispatchSetCompanies: PropTypes.func,
  dispatchSetPageTitle: PropTypes.func,
  dispatchSetSuperUserToken: PropTypes.func,
};
export default connect(mapStateToProps, mapDispatchToProps)(GlobalVideoMgt);
