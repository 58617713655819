import "./styles/line.css";

import { Card, Col, notification, Row, Table } from "antd";
import { ColumnsType } from "antd/es/table/interface";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import { Event, EventPostData } from "../../../types/Event";
import { EventMember } from "../../../types/EventMember";
import { CreateEventButton } from "../../Components/atoms/buttons/CreateEventButton";
import { CustomPagination } from "../../Components/molecules";
import { CreateEventReminderModal } from "../../Components/molecules/events/CreateEventReminderModal";
import { CreateEventSurveyModal } from "../../Components/molecules/events/CreateEventSurveyModal";
import { CreateFCFSEventReminderModal } from "../../Components/molecules/events/CreateFCFSEventReminderModal";
import { useEventRequest } from "../../Services/event/useEventRequest";
import { getObjUUID } from "../../utils/common";
import { getInitStorageValue } from "../../utils/localStorage";
import {
  adminActionColumn,
  applicationDeadlineColumn,
  applicationStatusColumn,
  capacityColumn,
  createdDateColumn,
  dateColumn,
  receptionColumn,
  statusColumn,
  titleColumn,
  userActionColumn,
  userPerticipationColumn,
} from "./table/columns";
import { EventsScreenProps } from "./types/EventsScreenProps";

export const defaultEventState: Event = {
  // @ts-expect-error TS2322
  applicationDeadline: new Date(),
  capacity: 0,
  createdAt: new Date(),
  description: "",
  customMessage: "",
  // @ts-expect-error TS2322
  endsAt: new Date(),
  id: 0,
  // @ts-expect-error TS2322
  beginsAt: new Date(),
  name: "",
  type: "lottery",
  nodalData: true,
  EventMembers: [],
  companyId: 0,
  isAcceptingMembers: true,
  updatedAt: new Date(),
  userId: 0,
};

/* eslint react/prop-types: 0 */
// [TODO]: eslint react proptypes ignore ts, tsx. #AC744
const EventsScreen: React.FC<EventsScreenProps> = function (props) {
  const intl = useIntl();
  const { user, updatePageTitle, company } = props.context;
  const history = useHistory();

  const [selectedEventId, setSelectedEventId] = useState<number>(0);
  const [eventModalVisible, setEventModalVisible] = useState<boolean>(false);
  const [tagsUpdated, setTagsUpdated] = useState<boolean>();
  const [userTags, setUserTags] = useState<string[]>();
  const [selectedEvent, setSelectedEvent] = useState<Event>(defaultEventState);
  const [eventReminderModalIsVisible, setEventReminderModalIsVisible] =
    useState<boolean>(false);
  const [eventSurveyModalIsVisible, setEventSurveyModalIsVisible] =
    useState<boolean>(false);
  const [fcfsEventReminderModalIsVisible, setFcfsEventReminderModalIsVisible] =
    useState<boolean>(false);

  const PageSaveId = "EventsScreen";
  const limitKey = `pagination_limit_${PageSaveId}`;
  const pageKey = `pagination_page_${PageSaveId}`;
  const [page, setPage] = useState<number>(getInitStorageValue(pageKey, 1));
  const [limit, setLimit] = useState<string>(
    getInitStorageValue(limitKey, process.env.REACT_APP_PAGE_SIZE),
  );

  const {
    getAllEvents,
    updateEvent,
    deleteEvent,
    leaveEevent,
    joinEvent,
    toggleAcceptMember,
  } = useEventRequest({
    page: page - 1,
    limit,
  });

  const { events, count } = getAllEvents();

  useEffect(() => {
    updatePageTitle?.("screen.label.events");
  }, [updatePageTitle]);

  useEffect(() => {
    if (!tagsUpdated) {
      setTagsUpdated(true);
      setUserTags(Array.isArray(company.userTags) ? company.userTags : []);
    }
  }, [company.userTags, tagsUpdated]);

  const handleSubmit = async (data: EventPostData) => {
    const formData = new FormData();
    for (const key in data) {
      // dataがnullの場合はformDataに追加しない
      // @ts-expect-error TS7053
      if (!data[key]) continue;

      // EventRecommendationsとapplicationDeadlineは別で処理する
      if (key === "EventRecommendations" || key === "applicationDeadline")
        continue;
      // @ts-expect-error TS7053
      formData.append(key, data[key]);
    }
    data.EventRecommendations.forEach((item) => {
      formData.append("EventRecommendations[]", item.text);
    });
    if (data.applicationDeadline) {
      formData.append("applicationDeadline", data.applicationDeadline);
    }

    const { isFailed } = await updateEvent(getObjUUID(data), formData);

    if (isFailed) return;
    notification.success({
      message: intl.formatMessage({
        id: "screen.label.event_has_been_updated",
      }),
    });

    setEventModalVisible(false);
    setSelectedEvent(defaultEventState);
  };

  const onConfirmDelete = async (event: Event) => {
    const id = getObjUUID(event);
    const { isFailed } = await deleteEvent(id);
    if (isFailed) return;

    notification.success({
      message: intl.formatMessage({
        id: "screen.label.event_successfully_removed",
      }),
    });
    setSelectedEvent(defaultEventState);
  };

  const onChangeEventsPage = (page: number, limit: number) => {
    setPage(page);
    setLimit(limit.toString());
  };

  const onCloseEventReminderModal = () => {
    setEventReminderModalIsVisible(false);
  };

  const onCloseFCFSEventReminderModal = () => {
    setFcfsEventReminderModalIsVisible(false);
  };

  const onCloseEventSurveyModal = () => {
    setEventSurveyModalIsVisible(false);
  };

  const onUnRegisterEvent = async (selectedEvent: Event) => {
    const query = {
      eventId: getObjUUID(selectedEvent),
      userId: user.id,
    };
    const { isFailed } = await leaveEevent(query);
    if (isFailed) return;

    notification.success({
      message: intl.formatMessage({
        id: "screen.label.canceled_participate_event",
      }),
    });
    setSelectedEvent({ ...selectedEvent, isMemberStatus: false });
  };

  const onRegisterForEvent = async (selectedEvent: Event) => {
    const query = {
      eventId: getObjUUID(selectedEvent),
      queryParams: "",
    };
    const data = {
      userId: user.id,
      eventId: selectedEvent.id,
    };

    const { isFailed } = await joinEvent(query, data);
    if (isFailed) return;

    notification.success({
      message: intl.formatMessage({
        id: "screen.label.apply_participation_event",
      }),
    });
    setSelectedEvent({ ...selectedEvent, isMemberStatus: true });
  };

  const onToggleAcceptMember = async (eventDetail: Event) => {
    const id = getObjUUID(eventDetail);
    const data = {
      isAcceptingMembers: !eventDetail.isAcceptingMembers,
    };
    await toggleAcceptMember(id, data);
  };

  const onCreateEventNotification = (event: Event) => {
    if (event.type === "lottery") {
      setEventReminderModalIsVisible(true);
      setSelectedEventId(getObjUUID(event));
    } else {
      setSelectedEventId(getObjUUID(event));
      setFcfsEventReminderModalIsVisible(true);
    }
  };

  const onCreateEventSurvey = (event: Event) => {
    const membersPresent = Array.isArray(event.EventMembers)
      ? event.EventMembers.filter((em: EventMember) => em.wasPresent)
      : [];

    if (membersPresent.length > 0) {
      notification.success({
        message: intl.formatMessage(
          {
            id: "screens.events.count_participants_notified",
          },
          {
            count: membersPresent.length,
          },
        ),
      });
      setSelectedEventId(getObjUUID(event));
      setEventSurveyModalIsVisible(true);
    } else {
      notification.error({
        message: intl.formatMessage({
          id: "screens.events.error_message_no_user_present",
        }),
      });
    }
  };

  const onEdit = (event: Event) => {
    history.push("/dashboard/events/" + event.uuid + "/edit");
  };

  const isAdmin = user.type === "admin";
  const commonColumns = [
    titleColumn(isAdmin),
    dateColumn(isAdmin),
    receptionColumn(isAdmin),
    capacityColumn(isAdmin),
    applicationDeadlineColumn(),
  ];

  const columns: ColumnsType<Event> = isAdmin
    ? [
        ...commonColumns,
        statusColumn(),
        createdDateColumn(),
        adminActionColumn({
          onToggleAcceptMember,
          onConfirmDelete,
          onCreateEventNotification,
          onCreateEventSurvey,
          onEdit,
        }),
      ]
    : [
        ...commonColumns,
        applicationStatusColumn(user),
        statusColumn(),
        userPerticipationColumn(user),
        userActionColumn({
          user,
          onUnRegisterEvent,
          onRegisterForEvent,
        }),
      ];

  const isNewEvent = useMemo(() => selectedEvent.id === 0, [selectedEvent.id]);
  const onCloseEventFormModal = useCallback(() => {
    setEventModalVisible(false);
    setSelectedEvent(defaultEventState);
  }, [setEventModalVisible, setSelectedEvent]);

  return (
    <div className={"EventsScreen"}>
      <Row>
        <Col xs={24} sm={12} md={8} lg={6}>
          <h2>
            {intl.formatMessage(
              {
                id: "screen.label.event_information_count_matter",
              },
              {
                count,
              },
            )}
          </h2>
        </Col>
        {user.type === "admin" && (
          <CreateEventButton
            onClick={() => {
              history.push("/dashboard/events/new");
            }}
            buttonLabel={intl.formatMessage({
              id: "screen.label.add_new",
            })}
          />
        )}
      </Row>
      <Card>
        <Table
          rowKey={"id"}
          pagination={false}
          columns={columns}
          dataSource={events}
        />
        <br />
        <CustomPagination
          saveId={PageSaveId}
          count={count}
          onChangePage={onChangeEventsPage}
        />
      </Card>
      <CreateEventReminderModal
        eventId={selectedEventId}
        visible={eventReminderModalIsVisible}
        onClose={onCloseEventReminderModal}
      />
      <CreateFCFSEventReminderModal
        eventId={selectedEventId}
        visible={fcfsEventReminderModalIsVisible}
        onClose={onCloseFCFSEventReminderModal}
      />
      <CreateEventSurveyModal
        eventId={selectedEventId}
        visible={eventSurveyModalIsVisible}
        onClose={onCloseEventSurveyModal}
      />
    </div>
  );
};

export default EventsScreen;
