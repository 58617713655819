import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

import { HorizonalLayout, VerticalLayout } from "../../atoms";
import {
  InsuPrimaryButton,
  InsuRejectButton,
} from "../../atoms/buttons/shared";
import { SingleUploadedReferenceFile } from "../../organisms";

type Props = {
  isHidden: boolean;
  file: File | undefined;
  handleUpload: () => void;
  handleUploadCancel: () => void;
  removeFile: () => void;
};
export const UploadConfirmationItem: React.FC<Props> = ({
  isHidden,
  file,
  handleUpload,
  handleUploadCancel,
  removeFile,
}) => {
  const [filePath, setFilePath] = useState<string | null>(null);
  useEffect(() => {
    if (file) {
      if (/image.*/.test(file.type)) {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
          if (typeof fileReader.result === "string") {
            setFilePath(fileReader.result as string);
          }
        };
      }
    }

    return () => {
      setFilePath(null);
    };
  }, [file]);

  return (
    <VerticalLayout style={{ ...(isHidden && { display: "none" }) }}>
      <SingleUploadedReferenceFile
        removedFile={removeFile}
        fileName={file?.name || ""}
        thumbnailUrl={filePath || ""}
      />
      <HorizonalLayout
        style={{
          marginTop: "2vh",
        }}
      >
        <InsuPrimaryButton
          style={{ marginRight: "15px" }}
          onClick={handleUpload}
        >
          <FormattedMessage id={"screen.label.upload"} />
        </InsuPrimaryButton>
        <InsuRejectButton onClick={handleUploadCancel}>
          <FormattedMessage id={"screen.label.cancel"} />
        </InsuRejectButton>
      </HorizonalLayout>
    </VerticalLayout>
  );
};

UploadConfirmationItem.propTypes = {
  isHidden: PropTypes.bool.isRequired,
  file: PropTypes.any,
  handleUpload: PropTypes.func.isRequired,
  handleUploadCancel: PropTypes.func.isRequired,
  removeFile: PropTypes.func.isRequired,
};
