import { Modal, notification } from "antd";
import { memo, useCallback, useState } from "react";
import { useIntl } from "react-intl";

import { mimeType } from "@/const/mimeType";
import styles from "@/features/review/components/UploadReviewExcelModal/styles.module.scss";
import { UploadReviewExcelModalProps } from "@/features/review/components/UploadReviewExcelModal/type";
import { useUploadReviewExcel } from "@/features/review/components/UploadReviewExcelModal/useUploadReviewExcel";
import { useFileHandler } from "@/shared/upload/hooks/useFileHandler";

import { CancelReviewExcelUploadButton } from "../CancelReviewExcelUploadButton";
import { ExcelErrorMessage } from "../ExcelErrorMessage";
import { ReviewExcelUploadArea } from "../ReviewExcelUploadArea/component";
import { UploadReviewExcelButton } from "../UploadReviewExcelButton";
import { UploadReviewModalTitle } from "../UploadReviewModalTitle";
import { useDownloadErrorExcel } from "./useDownloadErrorExcel";

export const UploadReviewExcelModal = memo(
  ({ isVisible, closeModal, onUploadSuccess }: UploadReviewExcelModalProps) => {
    const intl = useIntl();
    const uploadSuccessMessage = intl.formatMessage({
      id: "screen.label.event_successfully_upload_review_excel",
    });
    const [errorMessage, setErrorMessage] = useState<string>("");
    const hasError = !!errorMessage;
    const { hasRowError, errorExcelBlob, handleUpload, initRowError } =
      useUploadReviewExcel();
    const { file, handleAttach, handleDrop, initFile, setAttachable } =
      useFileHandler([mimeType.xlsx]);
    const downloadErrorExcel = useDownloadErrorExcel(errorExcelBlob);
    const disabledUploadButton = !file;

    const initMessage = useCallback(() => {
      setErrorMessage("");
    }, []);

    const removeFile = useCallback(() => {
      initFile();
      initMessage();
      initRowError();
      setAttachable(true);
    }, [initFile, initMessage, initRowError, setAttachable]);

    const handleClose = useCallback(() => {
      closeModal();
      removeFile();
    }, [closeModal, removeFile]);

    const successCallback = useCallback(() => {
      onUploadSuccess();
      notification.success({
        message: uploadSuccessMessage,
      });
      handleClose();
    }, [handleClose, onUploadSuccess, uploadSuccessMessage]);

    const errorCallback = useCallback(
      (message: string) => {
        setErrorMessage(message);
        setAttachable(false);
      },
      [setAttachable],
    );

    const uploadExcel = useCallback(() => {
      handleUpload(file, successCallback, errorCallback);
    }, [errorCallback, file, handleUpload, successCallback]);

    return (
      <Modal
        className={styles.modal}
        footer={null}
        visible={isVisible}
        onCancel={handleClose}
        title={<br />}
      >
        <div className={styles.modal_body_container}>
          <div className={styles.modal_body}>
            <UploadReviewModalTitle />
            <ReviewExcelUploadArea
              hasError={hasError}
              handleAttach={handleAttach}
              handleDrop={handleDrop}
              removeFile={removeFile}
              file={file}
            />
            <ExcelErrorMessage
              message={errorMessage}
              downloadable={hasRowError}
              downloadErrorExcel={downloadErrorExcel}
            />
            <div className={styles.buttons_container}>
              <CancelReviewExcelUploadButton onCancel={handleClose} />
              <UploadReviewExcelButton
                uploadExcel={uploadExcel}
                disabled={disabledUploadButton}
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  },
);
