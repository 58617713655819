import { Modal, Row } from "antd";
import { useIntl } from "react-intl";

import { AntdButton } from "../../../../shared/buttons/components/AntdButton";
import styles from "./styles.module.scss";
import { ExaminationPhaseUpdateActiveConfirmModalProps } from "./type";

export const ExaminationPhaseUpdateActiveConfirmModal = ({
  visible,
  onUpdateActive,
  onUpdateActiveCancel,
}: ExaminationPhaseUpdateActiveConfirmModalProps) => {
  const intl = useIntl();

  const title = intl.formatMessage({
    id: "screen.label.examination_phase_update_active_confirm_modal_title",
  });
  const content = intl.formatMessage({
    id: "screen.label.examination_phase_update_active_confirm_modal_content",
  });
  const okText = intl.formatMessage({ id: "screen.label.execute_switch" });
  const cancelText = intl.formatMessage({ id: "screen.label.cancel" });

  return (
    <Modal
      onCancel={onUpdateActiveCancel}
      footer={null}
      title={title}
      visible={visible}
    >
      <div className={styles.container}>
        <p className={styles.text}>{content}</p>
        <Row>
          <AntdButton
            category="secondary"
            className={styles.cancel_btn}
            onClick={onUpdateActiveCancel}
          >
            {cancelText}
          </AntdButton>
          <AntdButton
            category="primary"
            className={styles.update_btn}
            onClick={onUpdateActive}
          >
            {okText}
          </AntdButton>
        </Row>
      </div>
    </Modal>
  );
};
