const Mustache = require("mustache");

export const endpoints = /** @type {const} */ ({
  news: "news",
  industryReportCategory: "industry-report/category",
  getProjectArchive: "projects/{{id}}/archives?page={{page}}{{filter}}",
  login: "auth/login",
  getNewsComments: "news/{{data.id}}/news-comment?page={{data.page}}",
  batch: "batch/",
  getProjectComment: "projects/{{data.projectId}}/comments?page={{data.page}}",
  singleEvent: "events/{{id}}",
  toggleAcceptanceStatus: "events/{{id}}/acceptance-status",
  events: "events?page={{page}}&limit={{limit}}",
  getEventUsers: "events/{{data.eventId}}/users?{{queryParams}}",
  singleProject: "projects/{{id}}",
  allAcceptedProjectMembers:
    "projects/{{data.id}}/team-unpaginate?status=1&userStatus=1",
  limitedAcceptedProjectMembers:
    "projects/{{data.id}}/team?page={{data.page}}&limit={{limit}}&status=1&userStatus=1",
  pendingProjectMembers:
    "projects/{{data.id}}/team-pending?page={{data.page}}&limit={{limit}}",
  getCommentThreads:
    "projects/{{data.projectId}}/{{data.commentId}}/threads?page={{data.page}}",
  advanceNewsSearch: "news/advance-search?page={{page}}&limit={{limit}}",
  authReset: "auth/reset",
  resetTokenCheck: "auth/check-reset-token/{{token}}",
  forgotPassword: "auth/forgot",
  loginViaIP: "auth/login-ip",
  createProjectComment: "projects/{{data.projectId}}/comments",
  createEvent: "events",
  updateEvent: "events/{{data.id}}",
  createIndustryMainCategory: "industry-report/main-category",
  advanceSearchIndustryReport:
    "industry-report/advance-search?page={{page}}&limit={{limit}}",
  createIndustryReport: "industry-report",
  uploadIndustryReportFile: "industry-report/upload-file",
  createIndustrySubReport: "industry-report/sub-category",
  createNewsComment: "news/{{data.id}}/news-comment",
  submitProjectForReview: "projects/{{data.projectId}}/submission",
  updateProjectDetails: "projects/{{uid}}",
  createNewProject: "projects",
  createProjectTask: "projects/{{data.projectId}}/tasks",
  inviteMemberToTeam: "projects/{{projectUUid}}/team-invite",
  applyToBeMemberTeam: "projects/{{projectUUid}}/team-apply",
  createProjectCommentThread: "projects/{{data.projectId}}/threads",
  createUsers: "users",
  updateMainCategory: "industry-report/main-category/{{id}}",
  prepareCompanyData: "users/prepare-company-data",
  updateProjectComment: "projects/{{data.projectId}}/comments",
  updateNotification: "notifications/{{id}}",
  updateIndustryReport: "industry-report/{{id}}",
  updateIndustrySubCategory: "industry-report/sub-category/{{id}}",
  updateNewsComment: "news/{{data.id}}/news-comment",
  updateNews: "news/{{id}}",
  updateProjectTask: "projects/{{id}}/tasks/{{taskId}}",
  updateTeamMember: "projects/{{uid}}/team/{{userId}}",
  updateCommentThread: "projects/{{data.projectId}}/threads",
  updateUserProfile: "users/{{data.id}}",
  deleteUser: "users/{{data.id}}",
  deleteComment: "news/{{data.id}}/news-comment/{{data.commentId}}",
  logOut: "auth/logout",
  deleteProjectComment:
    "projects/{{data.projectId}}/comment/{{data.commentId}}",
  deleteEvent: "events/{{data.id}}",
  deleteProjectFile: "projects/{{projectId}}/files/{{fileId}}",
  deleteMainCategory: "industry-report/main-category/{{data.id}}",
  deleteIndustryReport: "industry-report/{{data.id}}",
  deleteSubCategory: "industry-report/sub-category/{{data.id}}",
  deleteNews: "news/{{id}}",
  deleteProject: "projects/{{id}}",
  deleteThread: "projects/{{data.projectId}}/thread/{{data.thread}}",
  subRouter: "{{subRouter}}/{{id}}",
  statistics: "statistics",
  companies: "companies/",
  getAllUsersForMentions: "users/get-all-users-for-mention",
  getUserCompanies: "users/{{userId}}/companies",
  programInfoDetails: "program-info/detail/",
  programInfoQA: "program-info/program-info-qa/",
  programInfoFaq: "program-info/faq/",
  programInfoFiles: "program-info/files/",
  findEventMember: "events/{{eventId}}/users/{{userId}}",
  notifications:
    "notifications?page={{page}}&limit={{limit}}&notificationType={{notificationType}}",
  getUnreadNotification:
    "notifications/unreadcount?selectedNotification={{ta.key}}",
  notificationTabChanged:
    "notifications/{{query}}&page={{page}}&limit={{limit}}",
  departments: "departments/",
  interest: "interests",
  getUserWatches:
    "users/{{userId}}/watchs?page={{page}}&limit={{limit}}&{{filterExp}}",
  userWatchList: "users/{{userId}}/watch-list",
  batchHypothesis: "batchHypothesis/batch/{{batchId}}",
  defaultBatch: "batch/default",
  submissionDetail: "submissions/{{submittedProjectUuid}}",
  getArchiveDetail: "submissions/{{archiveId}}",
  tags: "tags",
  allProjects: "projects/all",
  singleProjectLinks: "projects/{{projectId}}/links",
  getCompanyUnathenticatedUser: "companies/outside/{{companyId}}",
  userProjects: "users/{{userId}}/projects",
  singleBatch: "batch/{{batchId}}",
  getProjectReviews:
    "projects/{{projectId}}/reviews?page={{page}}&limit={{limit}}",
  getProjectReview: "projects/{{projectId}}/reviews/{{submissionId}}",
  singleProjectHypothesis: "projectHypothesis/project/{{projectId}}",
  projectWatch: "projects/{{id}}/watch",
  pendingProjectInvite:
    "users/{{userId}}/teams?page={{page}}&limit={{limit}}&userStatus=0&{{batchFilter}}",
  loginActivities: "activities/login?page={{page}}&limit={{limit}}&{{filter}}",
  screenActivities:
    "activities/screens?page={{page}}&limit={{limit}}&{{filter}}",
  submissionFilteredByBatch:
    "submissions?page={{page}}&limit={{limit}}{{filter}}{{batchesFilter}}",
  submissionsAdvanceSearch:
    "submissions/advance-search?page={{page}}&limit={{limit}}",
  submittedProjectDetail:
    "examinations/submitted-projects/{{submittedProjectUuid}}",
  archiveListAdvanceSearch: "submissions/advance-search/archive-list",
  emailSettings: "users/{{userId}}/email-list",
  videoSmallCategory: "videos/small-category/{{smallCategoryId}}",
  singleVideo: "videos/{{videoId}}",
  videoLargeCategory: "videos/large-category",
  smallCategoriesForSingleLargeCategory:
    "videos/large-category/{{largeCategoryId}}/small-category",
  smallCategoryVideos:
    "videos/small-category/{{videoSmallCategoryId}}/videos?page={{page}}&limit={{limit}}&{{filter}}",
  recommendations: "recommendations",
  filterRecommendation: "recommendations?page={{page}}&limit={{limit}}",
  eventFcfsReminder: "events/{{eventId}}/reminder-fcfs",
  fileDownload: "users/file/{{fileId}}/download",
  userFileDownload: "users/userFile/{{fileId}}/download",
  requestRegistration: "companies/{{companyId}}/users/outside",
  eventSurvey: "events/{{eventId}}/survey",
  changePassword: "auth/change",
  generateVimeoForm: "videos/generate-vimeo-form/",
  createGlobalVideo:
    "{{baseUrl}}videos/large-category/{{videoLargeCategoryId}}/small-category/{{videoSmallCategoryId}}/videos/",
  createScreenActivity: "activities/screens",
  advanceSearchUserProjects:
    "/users/{{userId}}/advance-filter-projects?page={{page}}&limit={{limit}}",
  eventReminder: "events/{{eventId}}/reminder",
  notificationHasBeenRead: "users/{{userId}}/notifications/",
  likeNews: "news/{{newsId}}/news-like",
  likeNewsComment: "news/{{newsId}}/{{commentId}}/new-comment-like",
  usersAdvanceSearch: "users/advance?page={{page}}&limit={{limit}}",
  notificationsDraft: "notifications/draft",
  createNotifications: "notifications",
  advanceFilterOperationalLogs: "activities/advance-filter-operation-logs",
  usersUploadNotification: "users/upload/notification",
  projectNotifications: "projects/{{projectId}}/notifications",
  projectHypothesis: "projectHypothesis",
  advanceFilterProjects:
    "projects/advance-filter?page={{page}}&limit={{limit}}",
  singleBatchHypothesis: "batchHypothesis/{{id}}",
  saveBatchHypothesis: "batchHypothesis",
  removeRecommendation: "recommendations/{{videoId}}",
  newsReaction: "news/{{newsId}}/reaction",
  updateExistingNewsComment: "news/{{newsId}}/news-comment/{{commentId}}",
  newsCommentReaction: "news/{{newsId}}/{{commentId}}/reaction",
  singleCompany: "companies/{{companyId}}/",
  prepareUserDataForSelectedCompany: "users/{{companyId}}/prepare-company-data",
  singleProjectWatch: "projects/{{projectId}}/watch/{{watchId}}",
  threadReaction: "projects/{{projectId}}/thread/reaction",
  projectCommentReaction: "projects/{{projectId}}/comment/reaction",
  updateSingleProjectHypothesis: "projectHypothesis/{{id}}",
  likeProject: "projects/{{projectId}}/like",
  updateBatchHypothesisOrder: "batchHypothesis/order/",
  singleDepartment: "departments/{{departmentId}}",
  settings: "settings", // TODO: 使われていないAPIなので削除する
  singleInterest: "interests/{{interestId}}",
  smallCategoryDetails: "videos/small-category/{{smallCategoryId}}/details",
  updateGlobalVideo:
    "{{baseUrl}}videos/large-category/{{videoLargeCategoryId}}/small-category/{{videoSmallCategoryId}}/videos/{{videoId}}",
  updateVideo:
    "videos/large-category/{{videoLargeCategoryId}}/small-category/{{videoSmallCategoryId}}/videos/{{videoId}}",
  reorderRecommendation: "recommendations/change-order",
  updateVideoDuration: "videos/{{videoId}}/duration",
  singleProgramFile: "program-info/files/{{programFileId}}/",
  deleteProjectLink: "projects/{{projectId}}/links/{{id}}",
  getProjectCommentCount: "projects/{{projectId}}/comment-count/",
  singleTag: "tags/{{tagId}}",
  advanceProjectTaskSearch:
    "projects/{{projectUUId}}/advance-task-filter?page={{page}}&limit={{limit}}",
  subEmail: "users/{{userId}}/subemail",
  subEmailWithId: "users/{{userId}}/subemail/{{emailId}}",
  subEmailVerify: "auth/verifysubemail",
  singleBatchExamination: "batch-examination-mgt/{{batchId}}",
  singleBatchExaminationSubmissionCategory:
    "batch-examination-mgt/{{batchId}}/{{batchsubmissionCategoryId}}",
  createNewSubmissionCategory: "batch-examination-mgt/{{batchId}}/",
  reorderSingleBatchExamination: "batch-examination-mgt/{{batchId}}/re-order",
  changeEmail: "users/{{userId}}/changeemail",
  newEmail: "users/{{userId}}/newemail",
  emailVerify: "auth/verifyemail",
  emailTokenCheck: "auth/check-email-token/{{token}}",
  libraryEnable: "companies/library-enable",
  libraryEnableWithId: "companies/library-enable/{{itemId}}",
  // batchHypothesisCheckpoints
  batchHypothesisCheckpoints:
    "batchHypothesisCheckpoint/batch/{{batchId}}/{{hypothesisFormId}}",
  checkpointForms:
    "batchHypothesisCheckpoint/checkpointform/{{checkpointFormId}}",
  projectBatchHypothesisCheckpoints:
    "projects/{{projectId}}/batchcheckpoint/{{batchId}}/{{hypothesisFormId}}",
  projectBatchHypothesisCheckpoint:
    "projects/{{projectId}}/batchcheckpoint/{{batchId}}/{{checkpointFormId}}",
  createProjectTaskForCheckpoint: "projects/{{projectId}}/tasks",
  privacyPolicyState: "privacy-policy/user/{{privacyPolicyId}}",
  privacyPolicy: "privacy-policy",
  getUserProfile: "users/profile",
  createReference: "reference/",
  filterAdvanceReference:
    "reference/advance-filter?page={{page}}&limit={{limit}}",
  referenceById: "reference/{{referenceId}}",
  getVideoTopScreen: "videos/top-screen",
  getProfileScreen: "users/{{userId}}/profile-screen",
  getExaminationPhases: "batches/{{batchId}}/examinationPhases",
  singleExaminationPhase: "examinationPhases/{{examinationPhaseId}}",
  updateExaminationPhaseName:
    "examinationPhases/{{examinationPhaseId}}/updateName",
  singleExaminationPhaseByBatch:
    "batches/{{batchId}}/examinationPhases/{{examinationPhaseId}}",
  patchExaminationPhaseOrder: "examinationPhases/sortOrder",
  updateExaminationPhaseActive:
    "batches/{{batchId}}/examinationPhases/{{examinationPhaseId}}/updateCurrentActive",
  toggleIsUseExainationPhaseEnabled:
    "batch/{{batchId}}/toggle-is-examination-phase-enabled",
  submissionExcelExport: "submissions/excel-export",
  examinationExcelImport: "reviews/excel-import",
  submittedProjects: "submitted-projects",
  submittedProject: "submitted-project/{{submittedProjectId}}",
  publishReview: "submitted-project/{{submittedProjectId}}/publish",
  publishReviews: "submitted-projects/publish",
  publishedReviewResult: "published-review-result/{{submittedProjectId}}",
  projectPublishedReviewResult:
    "projects/{{projectId}}/published-review-results",
  getFeatureToggle: "feature-toggles/{{featureName}}",
  loginType: "companies/{{companyUuid}}/login-type",
  ipRanges: "companies/{{companyUuid}}/ip-ranges",
  ipRange: "companies/{{companyUuid}}/ip-range",
});

/** @type {import('./endpointType').ParseEndpoint} */
export const parseEndpoint = (rawUrl, data) => {
  return Mustache.render(rawUrl, data, undefined, {
    escape: (x) => x,
  });
};
