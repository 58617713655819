import { Action } from "@reduxjs/toolkit";
import { createActions, createReducer } from "reduxsauce";
import Immutable from "seamless-immutable";

import { ToAction, ToActionType } from "./types";

/* ------------- Types and Action Creators ------------- */
type EventActionCreators = {
  eventCreateRequest: () => Action<"EVENT_CREATE_REQUEST">;
  eventCreateSuccess: () => Action<"EVENT_CREATE_SUCCESS">;
  eventCreateFailure: () => Action<"EVENT_CREATE_FAILURE">;

  eventDeleteRequest: () => Action<"EVENT_DELETE_REQUEST">;
  eventDeleteSuccess: () => Action<"EVENT_DELETE_SUCCESS">;
  eventDeleteFailure: () => Action<"EVENT_DELETE_FAILURE">;

  eventGetsRequest: () => Action<"EVENT_GETS_REQUEST">;
  eventGetsSuccess: () => Action<"EVENT_GETS_SUCCESS">;
  eventGetsFailure: () => Action<"EVENT_GETS_FAILURE">;

  eventGetRequest: () => Action<"EVENT_GET_REQUEST">;
  eventGetSuccess: () => Action<"EVENT_GET_SUCCESS">;
  eventGetFailure: () => Action<"EVENT_GET_FAILURE">;

  eventGetMembersRequest: () => Action<"EVENT_GET_MEMBERS_REQUEST">;
  eventGetMembersSuccess: () => Action<"EVENT_GET_MEMBERS_SUCCESS">;
  eventGetMembersFailure: () => Action<"EVENT_GET_MEMBERS_FAILURE">;
};
type EventAction = ToAction<EventActionCreators>;
type EventActionType = ToActionType<EventAction>;

const { Types, Creators } = createActions<EventActionType, EventActionCreators>(
  {
    eventCreateRequest: ["data", "message"],
    eventCreateSuccess: null,
    eventCreateFailure: ["errors"],

    eventDeleteRequest: ["data", "message"],
    eventDeleteSuccess: null,
    eventDeleteFailure: ["errors"],

    eventGetsRequest: ["page", "limit", "includeDeleted"],
    eventGetsSuccess: ["eventsPayload"],
    eventGetsFailure: ["errors"],

    eventGetRequest: ["id"],
    eventGetSuccess: ["eventPayload"],
    eventGetFailure: ["errors"],

    eventGetMembersRequest: ["data"],
    eventGetMembersSuccess: ["eventMembersPayload"],
    eventGetMembersFailure: ["errors"],
  },
);

export const EventTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
type EventState = {
  fetching: boolean;
  errors: unknown;
  error?: unknown;
  eventPayload: unknown;
  eventsPayload: {
    rows: unknown[];
    count: number;
  };
  eventMembersPayload: {
    rows: unknown[];
    count: number;
  };
};

export const INITIAL_STATE = Immutable<EventState>({
  fetching: false,
  errors: {},
  eventPayload: {},
  eventsPayload: {
    rows: [],
    count: 0,
  },
  eventMembersPayload: {
    rows: [],
    count: 0,
  },
});

type ImmEventState = typeof INITIAL_STATE;

/* ------------- Reducers ------------- */
const request = (state: ImmEventState) => state.merge({ fetching: true });

const success = (state: ImmEventState) => {
  return state.merge({ fetching: false });
};

// @ts-expect-error TS7031
const failure = (state: ImmEventState, { error }) =>
  state.merge({ fetching: false, error });

const eventGetsRequest = (state: ImmEventState) =>
  state.merge({ fetching: true });

// @ts-expect-error TS7031
const eventGetsSuccess = (state: ImmEventState, { eventsPayload }) =>
  state.merge({ fetching: false, eventsPayload });

// @ts-expect-error TS7031
const eventGetsFailure = (state: ImmEventState, { error }) =>
  state.merge({ fetching: false, error });

const eventGetRequest = (state: ImmEventState) =>
  state.merge({ fetching: true });

// @ts-expect-error TS7031
const eventGetSuccess = (state: ImmEventState, { eventPayload }) =>
  state.merge({ fetching: false, eventPayload });

// @ts-expect-error TS7031
const eventGetFailure = (state: ImmEventState, { error }) =>
  state.merge({ fetching: false, error });

const eventGetMembersSuccess = (
  state: ImmEventState,
  // @ts-expect-error TS7031
  { eventMembersPayload },
) => state.merge({ fetching: false, eventMembersPayload });

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer<ImmEventState, EventAction>(
  INITIAL_STATE,
  {
    [Types.EVENT_CREATE_REQUEST]: request,
    [Types.EVENT_CREATE_SUCCESS]: success,
    [Types.EVENT_CREATE_FAILURE]: failure,

    [Types.EVENT_DELETE_REQUEST]: request,
    [Types.EVENT_DELETE_SUCCESS]: success,
    [Types.EVENT_DELETE_FAILURE]: failure,

    [Types.EVENT_GETS_REQUEST]: eventGetsRequest,
    [Types.EVENT_GETS_SUCCESS]: eventGetsSuccess,
    [Types.EVENT_GETS_FAILURE]: eventGetsFailure,

    [Types.EVENT_GET_REQUEST]: eventGetRequest,
    [Types.EVENT_GET_SUCCESS]: eventGetSuccess,
    [Types.EVENT_GET_FAILURE]: eventGetFailure,

    [Types.EVENT_GET_MEMBERS_REQUEST]: request,
    [Types.EVENT_GET_MEMBERS_SUCCESS]: eventGetMembersSuccess,
    [Types.EVENT_GET_MEMBERS_FAILURE]: failure,
  },
);
