import { Form, Modal, Popconfirm, Row } from "antd";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { stringToSlug } from "../../../utils/common";
import { getTableData, getTableHeaders } from "../../../utils/editorUtils";
import { ButtonUndo, IconDelete } from "../../atoms";
import { Input, TextArea } from "../../molecules";
import { EditableTableProps } from "./types";
const R = require("ramda");

const EditableTable: React.FC<EditableTableProps> = function ({
  visible,
  setModalVisibility,
  addTable,
}: EditableTableProps) {
  const [newColumnName, setNewColumnName] = useState("");
  const [columnSource, setNewColumnSource] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const intl = useIntl();
  useEffect(() => {
    // @ts-expect-error TS2345
    setNewColumnSource(getTableHeaders());
    // @ts-expect-error TS2345
    setDataSource(getTableData());
  }, []);
  // @ts-expect-error TS7006
  const handleChange = (key, index, value) => {
    const newSource = dataSource.map((datum, ii) => {
      if (index === ii) {
        return {
          // @ts-expect-error TS2698
          ...datum,
          [key]: value,
        };
      }
      return datum;
    });
    // @ts-expect-error TS2345
    setDataSource(newSource);
  };
  // @ts-expect-error TS7006
  const _renderSingleRow = (singleRowData, index) => {
    const keys = Object.keys(singleRowData);
    return (
      <tr key={index}>
        {keys.map((k, i) => {
          return (
            <td key={`${i}`}>
              <TextArea
                style={{
                  resize: "vertical",
                  overflow: "hidden",
                }}
                value={singleRowData[k]}
                onChange={(event) => {
                  handleChange(k, index, event.target.value);
                }}
              />
            </td>
          );
        })}
        <Popconfirm
          title={<FormattedMessage id="screens.confirm.are_u_sure" />}
          onConfirm={() => {
            removeRow(index);
          }}
          onCancel={() => {}}
          okText={<FormattedMessage id="screen.label.yes" />}
          cancelText={<FormattedMessage id="screen.label.no" />}
        >
          {IconDelete}
        </Popconfirm>
      </tr>
    );
  };
  // @ts-expect-error TS7006
  const removeRow = (index) => {
    const newDataSource = dataSource.filter((_, ii) => {
      return ii !== index;
    });
    setDataSource(newDataSource);
  };
  const addNewColumn = () => {
    if (newColumnName.length > 0) {
      // @ts-expect-error TS2345
      columnSource.push(newColumnName);
      setNewColumnSource(columnSource);
      const index = columnSource.length - 1;
      const cdataSource = R.clone(dataSource);
      // @ts-expect-error TS7006
      const newDataSource = cdataSource.map((datum) => {
        datum[stringToSlug(index)] = "";
        return datum;
      });
      setNewColumnName("");
      setDataSource(newDataSource);
    }
  };
  // @ts-expect-error TS7006
  const removeColumn = (columnIndex) => {
    // const newColumns = columnSource.filter((_, i) => i !== columnIndex)
    const newColumns = columnSource.map((col, i) => {
      if (columnIndex === i) {
        return `del_${col}`;
      }
      return col;
    });
    const cdataSource = R.clone(dataSource);
    // @ts-expect-error TS7006
    const newDataSource = cdataSource.map((datum) => {
      const k = columnIndex;
      delete datum[stringToSlug(k)];
      return datum;
    });
    newColumns.length > 0 ? setDataSource(newDataSource) : setDataSource([]);
    // @ts-expect-error TS2345
    setNewColumnSource(newColumns);
  };
  const _renderTableHead = () => {
    return columnSource.map((hd, index) => {
      return (
        <th
          style={{
            paddingLeft: "1vw",
            // @ts-expect-error TS2339
            display: hd.startsWith("del") ? "none" : "",
          }}
          key={index}
        >
          <Row align="middle" justify="center">
            {hd}{" "}
            <Popconfirm
              title={<FormattedMessage id="screens.confirm.are_u_sure" />}
              onConfirm={() => {
                removeColumn(index);
              }}
              onCancel={() => {}}
              okText={<FormattedMessage id="screen.label.yes" />}
              cancelText={<FormattedMessage id="screen.label.no" />}
            >
              <div className="offset-left-16">{IconDelete}</div>
            </Popconfirm>
          </Row>
        </th>
      );
    });
  };
  const _renderRawTableHead = () => {
    const headers = columnSource
      // @ts-expect-error TS2339
      .filter((hd) => !hd.startsWith("del"))
      .map((hd) => {
        return `<th style="padding-left: 1vw; overflow-wrap: break-word;">${hd}</th>`;
      })
      .join("");
    return headers;
  };
  // @ts-expect-error TS7006
  const _renderRawSingleRow = (singleRowData) => {
    const keys = Object.keys(singleRowData);
    const rows = `<tr style="height: 12vh;">
            ${keys
              .map((k, i) => {
                return `<td style="border-bottom: 1px solid #f0f0f0; padding-left: 1vw;">${singleRowData[k]}</td>`;
              })
              .join("")}
        </tr>`;
    return rows;
  };
  const addNewRow = () => {
    const newDataSource = [...dataSource];
    const newROwDat = {};
    columnSource.forEach((col, ii) => {
      // @ts-expect-error TS2339
      if (!col.startsWith("del")) {
        const k = ii;
        // @ts-expect-error TS7053
        newROwDat[stringToSlug(k)] = "";
      }
    });
    // @ts-expect-error TS2769
    setDataSource(dataSource.concat(newROwDat));
  };
  const getRawHtml = () => {
    const rawHtmlStr = `
        <div style="overflow-x: auto;">
            <table style="border-bottom: 1px solid #f0f0f0; width:100%; table-layout:fixed;" id="dynamic-table-id">
                <thead>
                    <tr style="height:12vh; background-color: #D7DAE2">
                        ${_renderRawTableHead()}
                    </tr>
                </thead>
                <tbody>
                    ${dataSource
                      .map((singleRowData, index) => {
                        // @ts-expect-error TS2554
                        return _renderRawSingleRow(singleRowData, index);
                      })
                      .join("")}
                </tbody>
            </table>
        </div>
        `.replace(/(<.*?>)|\s+/g, (m, $1) => ($1 ? $1 : " "));
    return rawHtmlStr;
  };
  return (
    <Modal
      visible={visible}
      title={null}
      onOk={() => {
        // @ts-expect-error TS2722
        addTable(getRawHtml());
      }}
      onCancel={() => {
        // @ts-expect-error TS2722
        setModalVisibility(false);
      }}
      width={"60vw"}
      bodyStyle={{
        height: "70vh",
      }}
      cancelText={<FormattedMessage id="screen.label.cancel" />}
    >
      <div
        style={{
          overflowY: "auto",
          width: "auto",
          height: "65vh",
        }}
      >
        <Form layout={"inline"}>
          <Form.Item>
            <Input
              onChange={(e) => {
                setNewColumnName(e.target.value);
              }}
              value={newColumnName}
              placeholder={intl.formatMessage({
                id: "screen.label.enter_col_name",
              })}
            />
          </Form.Item>
          <Form.Item>
            <ButtonUndo onClick={addNewColumn} type={"ghost"}>
              <FormattedMessage id="screens.program_info.insert_column" />
            </ButtonUndo>
          </Form.Item>
        </Form>
        <br />

        <div>
          <table style={{ width: "100%" }}>
            <thead>
              <tr>{_renderTableHead()}</tr>
            </thead>
            <tbody>
              {dataSource.map((singleRowData, index) => {
                return _renderSingleRow(singleRowData, index);
              })}
            </tbody>
          </table>
        </div>
        <br />

        {columnSource.length > 0 ? (
          <>
            <ButtonUndo style={{ marginRight: "1vw" }} onClick={addNewRow}>
              <FormattedMessage id="screens.program_info.insert_row" />
            </ButtonUndo>
            <ButtonUndo
              onClick={() => {
                // @ts-expect-error TS2722
                addTable("<p></p>");
              }}
            >
              <FormattedMessage id="screens.program_info.remove_table" />
            </ButtonUndo>
          </>
        ) : null}
      </div>
    </Modal>
  );
};
export default EditableTable;
