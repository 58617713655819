import { notification } from "antd";
import apisauce, { ApisauceInstance } from "apisauce";

import { checkDormantPermission } from "../utils/constants";

type AxiosRequestConfig = Parameters<ApisauceInstance["any"]>[0];

const create = () => {
  const api = apisauce.create({
    baseURL: process.env.REACT_APP_BASEHOST,
    timeout: 7 * 60 * 1000,
    timeoutErrorMessage: "Request time out",
  });
  api.axiosInstance.defaults.withCredentials = true;
  api.axiosInstance.defaults.timeout = 7 * 60 * 1000;
  api.axiosInstance.defaults.timeoutErrorMessage = "Request time out";

  api.addResponseTransform((response) => {
    if (!response.ok) {
      /* 特殊なレスポンスについてはaxios instanceの呼び出し元でハンドリング */
      if (response.data instanceof ArrayBuffer) return;
      return notification.error({
        message: response.data?.message ?? response.originalError.message,
      });
    }
    if (response.data) {
      switch (response.data.status) {
        case "fail":
        case "error":
          checkDormantPermission(response.data.data);
          if (response.data?.message) {
            notification.error({
              message: response.data.message,
            });
          }
          if (typeof response.data.data === "string") {
            notification.error({
              message: response.data.data,
            });
          }
          break;
      }
    }
  });

  const postRequest = <Data, T = unknown, U = T>(
    url: string,
    data?: Data,
    config?: AxiosRequestConfig,
  ) => {
    const headers = config?.headers;
    return api.post<T, U>(url, data, {
      ...config,
      headers: headers ?? {
        alphafrontEndPath: window.location.href,
      },
    });
  };

  const getRequest = <T = unknown, U = T>(
    url: string,
    params?: {},
    config?: AxiosRequestConfig,
  ) => {
    return api.get<T, U>(url, params, {
      ...config,
      headers: {
        alphafrontEndPath: window.location.href,
      },
    });
  };

  const putRequest = <Data, T = unknown, U = T>(
    url: string,
    data?: Data,
    config?: AxiosRequestConfig,
  ) => {
    const headers = config?.headers;
    return api.put<T, U>(url, data, {
      ...config,
      headers: headers ?? {
        alphafrontEndPath: window.location.href,
      },
    });
  };

  const patchRequest = <Data, T = unknown, U = T>(
    url: string,
    data?: Data,
    config?: AxiosRequestConfig,
  ) => {
    const headers = config?.headers;
    return api.patch<T, U>(url, data, {
      ...config,
      headers: headers ?? {
        alphafrontEndPath: window.location.href,
      },
    });
  };

  const deleteRequest = <T = unknown, U = T>(
    url: string,
    params?: {},
    config?: AxiosRequestConfig,
  ) => {
    return api.delete<T, U>(url, params, {
      ...config,
      headers: {
        alphafrontEndPath: window.location.href,
      },
    });
  };
  // @ts-expect-error TS7006
  const setHeaderBearerToken = (key, value) => {
    api.setHeader(key, value);
  };
  // @ts-expect-error TS7006
  const setAlphaBaseUrl = (baseUrl) => {
    api.setBaseURL(baseUrl);
  };
  return {
    postRequest,
    getRequest,
    putRequest,
    patchRequest,
    deleteRequest,
    setHeaderBearerToken,
    setAlphaBaseUrl,
  };
};
export default {
  create,
};
