import { Tag } from "antd";
import styled from "styled-components";

export const WhiteDashTag = styled(Tag)`
  background: #fff;
  border-style: dashed;
`;
export const Bold12 = styled.p`
  font-weight: bold;
  font-size: 1.2rem;
`;
