import { Form, message, Modal, Upload } from "antd";
import { UploadFileStatus } from "antd/lib/upload/interface";
import PropTypes from "prop-types";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Reference } from "../../../../types/Reference";
import { ContentContext } from "../../../Provider";
import { HorizonalLayout } from "../../atoms";
import {
  InsuAcceptButton,
  InsuPrimaryButton,
  InsuRejectButton,
} from "../../atoms/buttons/shared";
import {
  GeneralUploaderComponent,
  TextArea,
  useFileUpload,
} from "../../molecules";
import SingleUploadedReferenceFile from "./SingleUploadedReferenceFile";

export type IUploadResponse = {
  fileName: string;
  fileUrl: string;
  thumbnailUrl: string;
};

type NewReferenceModalProps = {
  visible: boolean;
  globalReference: boolean;
  createReference: (data: Reference, reset: () => void) => void;
  onDismiss: (value: boolean) => void;
};

const NewReferenceModal: React.FC<NewReferenceModalProps> = ({
  visible: isModalVisible,
  globalReference,
  createReference,
  onDismiss,
}) => {
  const intl = useIntl();
  const context = useContext(ContentContext);

  const { selectedFile, beforeUpload, handleUpload, handleUploadCancel } =
    useFileUpload();

  const { apiRequest, user } = context;
  const fileUploadAction = globalReference
    ? `${process.env.REACT_APP_BASEHOST}reference`
    : `${process.env.REACT_APP_BASEHOST}companies/${user.company.uuid}/reference`;

  const [uploadResponse, setUploadResponse] = useState<IUploadResponse>({
    fileName: "",
    fileUrl: "",
    thumbnailUrl: "",
  });

  const disabled = !uploadResponse.fileName;

  const [description, setDescription] = useState<string>("");

  useEffect(() => {
    if (selectedFile) {
      setUploadResponse((prevState) => {
        return {
          ...prevState,
          fileName: selectedFile.name,
        };
      });
    }
  }, [selectedFile]);

  const closeModal = useCallback(() => {
    handleUploadCancel();
    setUploadResponse({
      fileName: "",
      fileUrl: "",
      thumbnailUrl: "",
    });
    setDescription("");
    onDismiss(true);
  }, [handleUploadCancel, onDismiss]);

  const handleBeforeUpload = useCallback(
    async (file: File) => {
      const shouldUpload = await beforeUpload(file);
      const limitation = 1024 * 1024 * 1024;
      const fileSize = file?.size;
      if (fileSize == null || !shouldUpload) return Upload.LIST_IGNORE;
      if (file && file.size && file.size < limitation) return true;
      message.warning(
        `${intl.formatMessage({
          id: "screen.label.file_limit_msg",
        })}`,
      );
      return Upload.LIST_IGNORE;
    },
    [beforeUpload, intl],
  );

  const uploadButton = useMemo(() => {
    return (
      <div
        style={{
          width: "70%",
        }}
      >
        <GeneralUploaderComponent<{
          data: IUploadResponse;
          status: UploadFileStatus;
        }>
          baseAction={fileUploadAction}
          handleBeforeUpload={handleBeforeUpload}
          onChange={(info) => {
            if (info.file.status === "done" && info.file.response) {
              // @ts-expect-error TS2739
              const data: Reference = {
                ...info.file.response.data,
                description,
              };

              createReference(data, () => {
                closeModal();
              });
            }
            if (
              info.file.status === "error" ||
              info.file.status === "removed"
            ) {
              closeModal();
            }
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: "30vh",
            }}
          >
            <div
              style={{
                paddingRight: "3vw",
                paddingLeft: "3vw",
              }}
            >
              <InsuPrimaryButton>
                {intl.formatMessage({
                  id: "screen.label.select_files",
                })}
              </InsuPrimaryButton>
              <p>
                <br />
                <FormattedMessage
                  id={"screen.label.reference_dragger_description_1G"}
                />
              </p>
            </div>
          </div>
        </GeneralUploaderComponent>
      </div>
    );
  }, [
    closeModal,
    createReference,
    description,
    fileUploadAction,
    handleBeforeUpload,
    intl,
  ]);

  return (
    <Modal
      title={intl.formatMessage({
        id: "screen.label.reference_management",
      })}
      visible={isModalVisible}
      footer={null}
      width={"55vw"}
      onCancel={closeModal}
      destroyOnClose
    >
      <Form layout={"vertical"}>
        <Form.Item
          label={intl.formatMessage({
            id: "screen.label.file",
          })}
        >
          <div
            style={{
              ...(!disabled && { display: "none" }),
            }}
          >
            {uploadButton}
          </div>
          <div
            style={{
              ...(disabled && { display: "none" }),
            }}
          >
            <SingleUploadedReferenceFile
              removedFile={() => {
                setUploadResponse({
                  fileName: "",
                  fileUrl: "",
                  thumbnailUrl: "",
                });
              }}
              fileName={uploadResponse?.fileName ?? ""}
              thumbnailUrl={uploadResponse?.thumbnailUrl ?? ""}
              uploadedAt={new Date().toISOString()}
            />
          </div>
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({
            id: "screen.label.more_detail",
          })}
        >
          <TextArea
            size="large"
            rows={7}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Form.Item>
        <Form.Item>
          <HorizonalLayout>
            <InsuAcceptButton onClick={handleUpload} disabled={disabled}>
              <FormattedMessage id={"screen.label.storage"} />
            </InsuAcceptButton>
            <InsuRejectButton
              style={{
                marginLeft: "0.7vw",
              }}
              onClick={closeModal}
            >
              <FormattedMessage id={"screen.label.cancel"} />
            </InsuRejectButton>
          </HorizonalLayout>
        </Form.Item>
      </Form>
    </Modal>
  );
};
NewReferenceModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  globalReference: PropTypes.bool.isRequired,
  onDismiss: PropTypes.func.isRequired,
};
export default NewReferenceModal;
