import FileSaver from "file-saver";
import path from "path";
import { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";

import { endpoints, parseEndpoint } from "../../../Services/endpoints";
import {
  IAnalyticsScreenProps,
  StatisticsBaseFile,
  StatisticsState,
} from "../types/IAnalyticsScreenProps";

const useAnalyticsScreen = (props: IAnalyticsScreenProps) => {
  const intl = useIntl();
  const {
    context: { apiRequest, updatePageTitle, user },
  } = props;

  const [state, customSetState] = useState<{ statistics: StatisticsState }>(
    () => {
      return {
        statistics: {
          totalUsers: 0,
          totalProjects: 0,
          totalEvents: 0,
          totalStoppedUsers: 0,
          totalDeletedUsers: 0,
          topEvents: [],
          mostWatched: [],
          projectActivities: [],
          mostDownloadedFiles: [],
          mostDownloadedUserFiles: [],
        },
      };
    },
  );
  const setStateCallbackRef = useRef(() => {});
  useEffect(() => {
    const callBack = setStateCallbackRef.current;
    callBack();
    setStateCallbackRef.current = () => {};
  }, [state]);
  const setState = (data: any, callback = () => {}) => {
    setStateCallbackRef.current = callback;
    customSetState((previousState) => {
      return {
        ...previousState,
        ...data,
      };
    });
  };

  useEffect(() => {
    updatePageTitle?.("screen.label.analytics");
    getSystemStatistics();
  }, []);

  const getSystemStatistics = () => {
    const requestBuilder = {
      method: "getRequest",
      url: parseEndpoint(endpoints.statistics),
      data: {},
    };
    // @ts-expect-error TS2345
    apiRequest?.(requestBuilder, ({ data }: { data: StatisticsState }) => {
      setState({ ["statistics"]: data });
    });
  };
  const getLabel = (id: string) => {
    return intl.formatMessage({ id });
  };
  const onFileDownloadClick = (item: StatisticsBaseFile) => {
    const href: string =
      item.hash.indexOf("http") === -1 ? `https://${item.hash}` : item.hash;
    const fileDownloadEndpoint: string =
      item.projectUuid && item.projectName
        ? endpoints.fileDownload
        : endpoints.userFileDownload;
    saveFile(
      fileDownloadEndpoint,
      href,
      item.fileName,
      item.fileName.split(".").pop() ?? "",
      item.fileId,
    );
  };
  const fileDownloaded = (
    endpoint: string,
    fileId: number,
    fileDetail: { fileName: string; fileSize: number },
  ) => {
    const data = {
      method: "postRequest",
      url: parseEndpoint(
        endpoint,
        // @ts-expect-error TS2345
        {
          fileId,
        },
      ),
      data: fileDetail,
    };
    // @ts-expect-error TS2345
    apiRequest?.(data, () => {});
  };
  const saveFile = (
    endpoint: string,
    href: string,
    fileName: string,
    extension: string,
    fileId: number,
  ) => {
    const xhr = new XMLHttpRequest();
    xhr.withCredentials = true;
    xhr.open("GET", href, true);
    xhr.responseType = "arraybuffer";
    xhr.onload = function (
      this: XMLHttpRequest,
      ev: ProgressEvent<EventTarget>,
    ) {
      const arrayBufferView = new Uint8Array(this.response);
      const blob = new Blob([arrayBufferView]);
      fileDownloaded(endpoint, fileId ? fileId : -1, {
        fileName,
        fileSize: blob.size,
      });
      FileSaver.saveAs(blob, fileName);
    };
    xhr.send();
  };
  const trimOverflowText = (text: string, len: number): string => {
    if (text.length < len) {
      return text;
    }
    return text.slice(0, len) + "…";
  };
  const trimOverflowFilename = (fileName: string, len: number): string => {
    const extname = path.extname(fileName);
    const basename = path.basename(fileName, extname);
    return trimOverflowText(basename, len) + extname;
  };

  return {
    intl,
    state,
    setState,
    getSystemStatistics,
    getLabel,
    onFileDownloadClick,
    user,
    trimOverflowText,
    trimOverflowFilename,
  };
};

export default useAnalyticsScreen;
