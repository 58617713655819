import styled from "styled-components";

export const MilestoneReorder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const MileStoneBatch = styled.p`
  font-family: Roboto;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
`;
