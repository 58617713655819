import { Button, Card, Form, Table } from "antd";
import { DateTime } from "luxon";
import moment from "moment";
import { FormattedMessage } from "react-intl";

import { CustomPagination } from "../../Components/molecules";
import { RangePicker } from "../../Components/molecules";
import CsvExportModal from "../../Components/organisms/project/CsvExportModal";
import { dateTimeFormat } from "../../utils/constants";
import useOperationalLogScreen from "./hooks/useOperationalLogScreen";

const PageSaveId = "OperationalLogScreen";

const OperationalLogScreen = function () {
  const {
    setPage,
    setLimit,
    operationalLogs,
    authUser,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    filterLogs,
    getTableColumns,
  } = useOperationalLogScreen();

  return (
    <div className={"OperationalLogScreen"}>
      <Card>
        <Form layout={"inline"}>
          <Form.Item>
            <RangePicker
              containerName="operation-form"
              format={dateTimeFormat}
              onChange={(selectedTimes) => {
                if (selectedTimes && selectedTimes.length > 0) {
                  if (selectedTimes[0]) {
                    setStartDate(selectedTimes[0].local());
                  }
                  if (selectedTimes[1]) {
                    setEndDate(selectedTimes[1].local());
                  }
                }
              }}
              defaultValue={[
                moment(startDate ? startDate : new Date()).local(),
                moment(endDate ? endDate : new Date()).local(),
              ]}
            />
          </Form.Item>
          <Form.Item>
            <Button
              onClick={() => {
                filterLogs();
              }}
              type="primary"
            >
              <FormattedMessage id="screen.label.narrow_down_specific_period" />
            </Button>
          </Form.Item>
          {authUser.isSuper ? (
            <Form.Item>
              <CsvExportModal
                itemCount={operationalLogs.count}
                title="screen.label.csv_output_result"
                url={`/activities/filter-operation-logs-csv?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}&timeZone=${
                  DateTime.local().zoneName
                }`}
              />
            </Form.Item>
          ) : null}
        </Form>
        <br />
        <Table
          pagination={false}
          rowKey={"id"}
          // @ts-expect-error TS2322
          columns={getTableColumns()}
          dataSource={operationalLogs.rows}
        />
        <br />
        <CustomPagination
          saveId={PageSaveId}
          count={operationalLogs.count}
          onChangePage={(pageNum, limitNum) => {
            setPage(pageNum);
            setLimit(limitNum);
          }}
        />
      </Card>
    </div>
  );
};
export default OperationalLogScreen;
