import { Modal } from "antd";
import React from "react";
import { useIntl } from "react-intl";
import { connect } from "react-redux";

import { ReduxDispatch, ReduxState } from "@/Redux/types";

import IndustryMainCategoryActions from "../../../Redux/IndustryMainCategoryRedux";
import IndustrySubCategoryActions from "../../../Redux/IndustrySubCategoryRedux";
import AddCategoryItem from "./addCategory";
import MainCategoryItem from "./mainCategory";
import SubCategoryItem from "./subCategory";
import type {
  IndustryReportMainCategory,
  IndustryReportSubCategory,
} from "./types";
type OwnProps = {
  visible: boolean;
  onChangeVisible: () => void;
};
type StateProps = {
  auth: Object;
  industryMainCategories: {
    industryMainCategoryPayload: Array<IndustryReportMainCategory>;
  };
};
type ActionProps = {
  dispatchGetMainCategories: (data?: unknown) => void;
  dispatchCreateMainCategories: (data: { name: string }) => void;
  dispatchDeleteMainCategories: (data: { id: string }) => void;
  dispatchUpdateMainCategories: (data: { id: string; name: string }) => void;
  dispatchCreateSubCategories: (data: {
    mainCategoryId: string;
    name: string;
  }) => void;
  dispatchDeleteSubCategories: (data: { id: string }) => void;
  dispatchUpdateSubCategories: (data: { id: string; name: string }) => void;
};
type Props = OwnProps & ActionProps & StateProps;
function EditCategory(props: Props) {
  const intl = useIntl();
  const [mainCategories, setMainCategories] = React.useState<
    IndustryReportMainCategory[]
  >([]);
  const [subCategories, setSubCategories] = React.useState<
    Record<string, IndustryReportSubCategory[]>
  >({});
  const {
    industryMainCategories: {
      industryMainCategoryPayload: mainCategoriesPayload,
    },
    visible,
    onChangeVisible,
    dispatchGetMainCategories,
    dispatchCreateMainCategories,
    dispatchDeleteMainCategories,
    dispatchUpdateMainCategories,
    dispatchCreateSubCategories,
    dispatchDeleteSubCategories,
    dispatchUpdateSubCategories,
  } = props;
  React.useEffect(() => {
    dispatchGetMainCategories();
  }, []);
  React.useEffect(() => {
    if (mainCategoriesPayload && Array.isArray(mainCategoriesPayload)) {
      setMainCategories(mainCategoriesPayload);
      const subCagetoriesTemp: Record<string, IndustryReportSubCategory[]> = {};
      for (const mainCategory of mainCategoriesPayload) {
        subCagetoriesTemp[mainCategory.id] =
          mainCategory.IndustryReportSubCategory;
      }
      setSubCategories(subCagetoriesTemp);
    }
  }, [mainCategoriesPayload]);
  const onCreateMainCategory = (name: string) => {
    dispatchCreateMainCategories({
      name,
    });
  };
  const onUpdateMainCategory = (id: string, name: string) => {
    dispatchUpdateMainCategories({
      id,
      name,
    });
  };
  const onDeleteMainCategory = (id: string) => {
    dispatchDeleteMainCategories({ id });
  };
  const onCreateSubCategory = (id: string, name: string) => {
    dispatchCreateSubCategories({
      mainCategoryId: id,
      name,
    });
  };
  const onUpdateSubCategory = (id: string, name: string) => {
    dispatchUpdateSubCategories({
      id,
      name,
    });
  };
  const onDeleteSubCategory = (id: string) => {
    dispatchDeleteSubCategories({ id });
  };
  const addSubCategoryItem = (mainCategoryId: string) => {
    const cloneSubCategories = { ...subCategories };
    let tempSubcategories = [...cloneSubCategories[mainCategoryId]];
    const addedSubcategories = tempSubcategories.filter((a) => !a.id);
    if (addedSubcategories.length > 0) {
      return;
    }
    if (tempSubcategories && tempSubcategories.length > 0) {
      tempSubcategories.push(
        //@ts-expect-error TS2345
        {
          name: "",
          mainCategoryId,
        },
      );
    } else {
      tempSubcategories = [];
      tempSubcategories.push(
        //@ts-expect-error TS2345
        {
          name: "",
          mainCategoryId,
        },
      );
    }
    cloneSubCategories[mainCategoryId] = tempSubcategories;
    setSubCategories(cloneSubCategories);
  };
  const removeSubCategoryItem = (mainCategoryId: string) => {
    const cloneSubCategories = { ...subCategories };
    let tempSubcategories = [...cloneSubCategories[mainCategoryId]];
    if (tempSubcategories && tempSubcategories.length > 0) {
      tempSubcategories = tempSubcategories.filter((a) => a.id);
    }
    cloneSubCategories[mainCategoryId] = tempSubcategories;
    setSubCategories(cloneSubCategories);
  };
  const addMainCategory = () => {
    const cloneMainCategories = [...mainCategories];
    const addedMaincategories = cloneMainCategories.filter((a) => !a.id);
    if (addedMaincategories.length > 0) {
      return;
    }
    // @ts-expect-error TS2345
    cloneMainCategories.push({
      name: "",
    });
    setMainCategories(cloneMainCategories);
  };
  const removeMainCategory = () => {
    let cloneMainCategories = [...mainCategories];
    if (cloneMainCategories && cloneMainCategories.length > 0) {
      cloneMainCategories = cloneMainCategories.filter((a) => a.id);
    }
    setMainCategories(cloneMainCategories);
  };
  return (
    <Modal
      title={intl.formatMessage({
        id: "screen.label.industry_category_management",
      })}
      centered
      width={"60vw"}
      footer={
        <AddCategoryItem
          // @ts-expect-error TS2322
          mainCategoryId={null}
          title="screen.label.create_main_category"
          onAddItem={addMainCategory}
        />
      }
      visible={visible}
      onCancel={onChangeVisible}
    >
      {mainCategories.map((mainItem) => {
        const categoryUI = [];
        categoryUI.push(
          <MainCategoryItem
            item={mainItem}
            onCreateItem={onCreateMainCategory}
            onUpdateItem={onUpdateMainCategory}
            onDeleteItem={onDeleteMainCategory}
            onRemoveItem={removeMainCategory}
          />,
        );
        const subCategoryItems = subCategories[mainItem.id];
        if (subCategoryItems && subCategoryItems.length > 0) {
          subCategoryItems.map((subItem) => {
            categoryUI.push(
              <SubCategoryItem
                mainItem={mainItem}
                item={subItem}
                onCreateItem={onCreateSubCategory}
                onUpdateItem={onUpdateSubCategory}
                onDeleteItem={onDeleteSubCategory}
                onRemoveItem={removeSubCategoryItem}
              />,
            );
          });
        }
        if (mainItem && mainItem.id) {
          categoryUI.push(
            <AddCategoryItem
              mainCategoryId={mainItem.id}
              title="screen.label.create_sub_category"
              onAddItem={(mainCategoryId) => {
                addSubCategoryItem(mainCategoryId);
              }}
            />,
          );
        }
        return categoryUI;
      })}
    </Modal>
  );
}
const mapStateToProps = (state: ReduxState): StateProps => ({
  auth: state.auth,
  //@ts-expect-error TS2322
  industryMainCategories: state.industryMainCategories,
});

const mapDispatchToProps = (dispatch: ReduxDispatch): ActionProps => ({
  dispatchGetMainCategories: (data) =>
    // @ts-expect-error TS2554
    dispatch(IndustryMainCategoryActions.industryMainCategoryGetRequest(data)),
  dispatchCreateMainCategories: (data) =>
    dispatch(
      // @ts-expect-error TS2554
      IndustryMainCategoryActions.industryMainCategoryCreateRequest(data),
    ),
  dispatchUpdateMainCategories: (data) =>
    dispatch(
      // @ts-expect-error TS2554
      IndustryMainCategoryActions.industryMainCategoryUpdateRequest(data),
    ),
  dispatchDeleteMainCategories: (data) =>
    dispatch(
      // @ts-expect-error TS2554
      IndustryMainCategoryActions.industryMainCategoryDeleteRequest(data),
    ),
  dispatchCreateSubCategories: (data) =>
    // @ts-expect-error TS2554
    dispatch(IndustrySubCategoryActions.industrySubCategoryCreateRequest(data)),
  dispatchUpdateSubCategories: (data) =>
    // @ts-expect-error TS2554
    dispatch(IndustrySubCategoryActions.industrySubCategoryUpdateRequest(data)),
  dispatchDeleteSubCategories: (data) =>
    // @ts-expect-error TS2554
    dispatch(IndustrySubCategoryActions.industrySubCategoryDeleteRequest(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(EditCategory);
