import { Row } from "antd";
import { useIntl } from "react-intl";

import { ButtonSwitch } from "../../../atoms";

type Props = {
  displayCheckpoint: boolean | undefined;
  onChangeStatus: (checked: boolean) => void;
};

const ProjectDisplayCheckpoint = (props: Props) => {
  const intl = useIntl();
  return (
    <Row align="middle" justify="start" className="offset-left-16">
      <span className="offset-right-16">
        {intl.formatMessage({
          id: "screen.label.display_checkpoint",
        })}
      </span>{" "}
      <ButtonSwitch
        checked={props.displayCheckpoint}
        onChange={(checked) => {
          props.onChangeStatus(checked);
        }}
      />
    </Row>
  );
};

export default ProjectDisplayCheckpoint;
