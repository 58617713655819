import { useEffect, useRef } from "react";
import { useIntl } from "react-intl";

import { endpoints, parseEndpoint } from "../../../Services/endpoints";
import { useStateWithRef, useWindowWidth } from "../../../utils/hooks";
import { getInitStorageValue } from "../../../utils/localStorage";
import {
  ISingleSmallCategoryVideosScreenProps,
  SingleSmallCategoryVideosScreenState,
} from "../types/ISingleSmallCategoryVideosScreenProps";

const PageSaveId = "SingleSmallCategoryVideosScreen";
const pageKey = `pagination_page_${PageSaveId}`;
const limitKey = `pagination_limit_${PageSaveId}`;

const useSingleSmallCategoryVideosScreen = (
  props: ISingleSmallCategoryVideosScreenProps,
) => {
  const intl = useIntl();

  const { apiRequest } = props.context;

  const windowWidth = useWindowWidth();
  const [state, customSetState, stateRef] = useStateWithRef(() => {
    return {
      page: getInitStorageValue(pageKey, 1),
      limit: getInitStorageValue(limitKey, process.env.REACT_APP_PAGE_SIZE),
      videos: {
        rows: [],
        count: 0,
      },
    };
  });
  useEffect(() => {
    applyFilter(`videoSmallCategoryId=${getSmallCategoryId()}`);
  }, []);

  // @ts-expect-error TS7006
  const applyFilter = (filter) => {
    const { page, limit } = stateRef.current;
    const requestBuilder = {
      method: "getRequest",
      url: parseEndpoint(endpoints.smallCategoryVideos, {
        videoSmallCategoryId: getSmallCategoryId(),
        page: page - 1,
        limit,
        filter,
      }),
      data: {},
    };
    // @ts-expect-error TS2345
    apiRequest(requestBuilder, ({ data }) => {
      setState({
        videos: data,
      });
    });
  };
  const setStateCallbackRef = useRef(() => {});
  useEffect(() => {
    const callBack = setStateCallbackRef.current;
    callBack();
    setStateCallbackRef.current = () => {};
  }, [state]);
  const setState = (
    data: SingleSmallCategoryVideosScreenState,
    callback = () => {},
  ) => {
    setStateCallbackRef.current = callback;
    // @ts-expect-error TS7006
    customSetState((prevState) => ({
      ...prevState,
      ...data,
    }));
  };
  const getSmallCategoryId = () => {
    const {
      match: { params },
    } = props;
    return params.id ? params.id : -1;
  };
  const getSmallCategoryName = () => {
    const {
      match: { params },
    } = props;
    return params.name ? params.name : "";
  };
  return {
    intl,
    state: stateRef.current,
    windowWidth,
    applyFilter,
    setState,
    getSmallCategoryId,
    getSmallCategoryName,
  };
};

export default useSingleSmallCategoryVideosScreen;
