import { Card, Col, Row } from "antd";
import React, { Component } from "react";
import { injectIntl } from "react-intl";
import ReactPlayer from "react-player/lazy";
import { connect } from "react-redux";

import { ApiRequestData } from "@/Redux/ApiRedux";

import { IconVideoNext, IconVideoPrev } from "../../Components/atoms";
import { NextVideoComponent } from "../../Components/organisms";
import { endpoints, parseEndpoint } from "../../Services/endpoints";
import { defaultThumbnailImg } from "../../utils/constants";
import {
  IWatchVideoScreenProps,
  WatchVideoScreenState,
} from "./types/IWatchVideoScreenProps";

const { Meta } = Card;
const emptyVideo = {
  imageThumbnail: "",
  videoUrl: "",
  VideoLargeCategory: {},
  VideoSmallCategory: {},
  VideoPlay: [
    {
      duration: 0,
    },
  ],
};

class WatchVideoScreen extends Component<
  IWatchVideoScreenProps,
  WatchVideoScreenState
> {
  reactVideoPlayerRef: any;
  state = {
    previousVideo: emptyVideo,
    currentVideo: emptyVideo,
    nextVideo: emptyVideo,
  };
  // @ts-expect-error TS7006
  constructor(props) {
    super(props);
    this.reactVideoPlayerRef = React.createRef();
  }
  componentWillUnmount() {
    if (this.reactVideoPlayerRef.current) {
      const played =
        this.reactVideoPlayerRef.current.getCurrentTime() /
        this.reactVideoPlayerRef.current.getDuration();
      this._updateVideoPlayedDuration({ played });
    }
    window.removeEventListener("beforeunload", this.onBeforeUnload);
  }
  _updateVideoDurationFromLocalStorage = () => {
    const videoProgressStr = localStorage.getItem("VideoProgress") || "{}";
    const videoProgress = JSON.parse(videoProgressStr);
    if (videoProgress && videoProgress?.userId == this.props.context.user.id) {
      this._updateVideoPlayedDuration({ played: videoProgress.played });
    }
    localStorage.removeItem("VideoProgress");
  };
  getVideoId = () => {
    const {
      match: { params },
    } = this.props;
    return params.id ? params.id : -1;
  };
  // @ts-expect-error TS7006
  getSingleVideo = (videoId) => {
    this.setState(
      {
        previousVideo: emptyVideo,
        currentVideo: emptyVideo,
        nextVideo: emptyVideo,
      },
      () => {
        const requestBuilder: ApiRequestData = {
          method: "getRequest",
          url: parseEndpoint(endpoints.singleVideo, {
            videoId,
          }),
          data: {},
        };
        this.props.context.apiRequest(requestBuilder, ({ data }) => {
          //@ts-expect-error 要API型定義
          const { previousVideo, currentVideo, nextVideo } = data;
          this.setState({ previousVideo, currentVideo, nextVideo });
        });
      },
    );
  };
  onBeforeUnload = () => {
    if (this.reactVideoPlayerRef.current) {
      const played =
        this.reactVideoPlayerRef.current.getCurrentTime() /
        this.reactVideoPlayerRef.current.getDuration();
      localStorage.setItem(
        "VideoProgress",
        JSON.stringify({
          // @ts-expect-error TS2339
          videoId: this.state.currentVideo.id,
          userId: this.props.context.user.id,
          played: played,
        }),
      );
    }
  };
  componentDidMount() {
    this.props.context.updatePageTitle("screen.label.video");
    this.getSingleVideo(this.getVideoId());
    window.addEventListener("beforeunload", this.onBeforeUnload);
    this._updateVideoDurationFromLocalStorage();
  }
  // @ts-expect-error TS7006
  onStartPlayingVideo = (s) => {
    if (
      Array.isArray(this.state.currentVideo.VideoPlay) &&
      this.state.currentVideo.VideoPlay.length > 0
    ) {
      if (this.reactVideoPlayerRef.current) {
        this.reactVideoPlayerRef.current.seekTo(
          this.state.currentVideo.VideoPlay[0].duration,
          "fraction",
        );
      }
    }
  };
  // @ts-expect-error TS7006
  onDurationVideo = (s) => {};
  onEndedVideo = () => {};
  // @ts-expect-error TS7006
  onProgressVideo = (progress) => {};
  _onPauseVideo = () => {
    if (this.reactVideoPlayerRef.current) {
      const played =
        this.reactVideoPlayerRef.current.getCurrentTime() /
        this.reactVideoPlayerRef.current.getDuration();
      this._updateVideoPlayedDuration({ played });
    }
  };
  _onReadyVideo = () => {};
  // @ts-expect-error TS7006
  _updateVideoPlayedDuration = (progress) => {
    const { played } = progress;
    if (played && !isNaN(played) && played > 0) {
      const requestBuilder: ApiRequestData = {
        method: "putRequest",
        url: parseEndpoint(endpoints.updateVideoDuration, {
          videoId: this.getVideoId(),
        }),
        data: {
          // @ts-expect-error TS2339
          videoLargeCategoryId: this.state.currentVideo.VideoLargeCategory.id,
          // @ts-expect-error TS2339
          videoSmallCategoryId: this.state.currentVideo.VideoSmallCategory.id,
          duration: played.toFixed(2),
        },
        headers: {
          alphafrontEndPath: `${
            window.location.origin
          }/dashboard/video-play/${this.getVideoId()}`,
        },
      };
      this.props.context.apiRequest(requestBuilder, ({ data }) => {});
    }
  };
  _renderVideoPlayer = () => {
    if (this.state.currentVideo?.videoUrl?.length > 0) {
      return (
        <div className="player-wrapper">
          {/*
           // @ts-expect-error TS2769 */}
          <ReactPlayer
            onReady={this._onReadyVideo}
            ref={this.reactVideoPlayerRef}
            onProgress={this.onProgressVideo}
            loop={false}
            onStart={this.onStartPlayingVideo}
            onDuration={this.onDurationVideo}
            onEnded={this.onEndedVideo}
            onPause={this._onPauseVideo}
            controls
            className="react-player"
            url={this.state.currentVideo.videoUrl}
            width="100%"
            height="100%"
            progressInterval={30 * 1000}
            config={{
              file: {
                attributes: {
                  controlsList: "nodownload",
                },
                vimeo: {
                  autoplay: true,
                  controls: true,
                },
              },
            }}
          />
        </div>
      );
    }
  };
  render() {
    return (
      <Row>
        <Col span={24}>
          <Card>
            <Row>
              <Col
                xs={24}
                sm={{ offset: 1, span: 22 }}
                md={{ offset: 2, span: 20 }}
                lg={{ offset: 4, span: 16 }}
              >
                <>
                  <p
                    style={{
                      fontStyle: "normal",
                      fontWeight: "bold",
                      fontSize: "18px",
                      lineHeight: "26px",
                      color: "#323232",
                      margin: 0,
                    }}
                  >
                    {/*
                     // @ts-expect-error TS2339 */}
                    {this.state.currentVideo?.name}
                  </p>
                  <p
                    style={{
                      fontStyle: "normal",
                      fontWeight: "normal",
                      fontSize: "14px",
                      lineHeight: "200%",
                      color: "#808080",
                      margin: 0,
                    }}
                  >
                    {/*
                     // @ts-expect-error TS2339 */}
                    {`${this.state.currentVideo?.VideoLargeCategory.name}/${this.state.currentVideo?.VideoSmallCategory?.name} `}
                  </p>
                  <Row>
                    <Col span={24}>
                      <Card cover={this._renderVideoPlayer()}>
                        <Meta
                          // @ts-expect-error TS2339
                          description={this.state.currentVideo?.description}
                        />
                      </Card>
                    </Col>
                  </Row>
                  <br />

                  <Row gutter={[70, 0]}>
                    {this.state.previousVideo ? (
                      <Col xs={24} sm={12} md={12} lg={12}>
                        <NextVideoComponent
                          type={"previous"}
                          icon={IconVideoPrev}
                          // @ts-expect-error TS2532
                          title={this.props.intl.formatMessage({
                            id: "screen.label.previous_video",
                          })}
                          imgSrc={
                            this.state.previousVideo.imageThumbnail ||
                            defaultThumbnailImg
                          }
                          // @ts-expect-error TS2339
                          description={this.state.previousVideo.description}
                          // @ts-expect-error TS2339
                          datePosted={this.state.previousVideo.createdAt}
                          // @ts-expect-error TS2339
                          videoName={this.state.previousVideo.name}
                          onClick={() => {
                            // @ts-expect-error TS2532
                            this.props.history.push(
                              // @ts-expect-error TS2339
                              `/dashboard/videos-play/${this.state.previousVideo.id}`,
                            );
                            // @ts-expect-error TS2339
                            this.getSingleVideo(this.state.previousVideo.id);
                          }}
                        />
                      </Col>
                    ) : null}

                    {this.state.nextVideo ? (
                      <Col xs={24} sm={12} md={12} lg={12}>
                        <NextVideoComponent
                          type={"next"}
                          icon={IconVideoNext}
                          // @ts-expect-error TS2532
                          title={this.props.intl.formatMessage({
                            id: "screen.label.next_video",
                          })}
                          imgSrc={
                            this.state.nextVideo.imageThumbnail ||
                            defaultThumbnailImg
                          }
                          // @ts-expect-error TS2339
                          description={this.state.nextVideo.description}
                          // @ts-expect-error TS2339
                          datePosted={this.state.nextVideo.createdAt}
                          // @ts-expect-error TS2339
                          videoName={this.state.nextVideo.name}
                          onClick={() => {
                            // @ts-expect-error TS2532
                            this.props.history.push(
                              // @ts-expect-error TS2339
                              `/dashboard/videos-play/${this.state.nextVideo.id}`,
                            );
                            // @ts-expect-error TS2339
                            this.getSingleVideo(this.state.nextVideo.id);
                          }}
                        />
                      </Col>
                    ) : null}
                  </Row>

                  <br />
                </>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    );
  }
}
// @ts-expect-error TS7006
const mapStateToProps = (state) => ({
  largeCategories: state.videos.largeCategoriesPayload,
});
export default injectIntl(connect(mapStateToProps, null)(WatchVideoScreen));
