import { FileIconVariant } from "@/shared/icons/components/VariantIcon/type";
import { normalizeUrl } from "@/utils/common";
import { getFileIcon } from "@/utils/imgPreviewUtils";

/**
 * Get file extension from Thumbnail URL of Image
 * @param url Thumbnail URL of Image (e.g. http://localhost:8000/alphacloud-dev-test-in-public-bucket/2/1693971355381-775493d462f4855f4746.png?Expires=1694132509&Key-Pair-Id=APKAJI7XHJN4PYFZO7RA&Signature=N2qMq~zgemauP20J9)
 * @returns file extension string or original string if no extension
 * @example png, jpg, mp4, etc.
 */
export const getFileExtensionFromUrl = (url: string) => {
  const urlWithoutQueryParam = url.split("?")[0];
  // get file extension from url
  return urlWithoutQueryParam.split(".").pop() ?? "";
};

/**
 * format the thumbnail url for image src
 * @param url - thumbnail url
 * @param fileExtension - file extension (e.g. png, jpg, mp4, etc.)
 * @returns url string for image src or null if file extension is mp4
 */
export const formatThumbnailUrl = (
  url: string,
  fileExtension: string,
): string | null => {
  switch (fileExtension) {
    case "jpg":
    case "jpeg":
    case "png":
    case "gif":
      return normalizeUrl(url);
    case "mp4":
      return null;
    default:
      // Use alt icon for other file types
      return null;
  }
};

/**
 * Get file icon type from thumbnail url
 * @param url - thumbnail url
 * @returns file icon type
 */
export const getFileIconFromUrl = (url: string | null): FileIconVariant => {
  const fileExtension =
    typeof url === "string" ? getFileExtensionFromUrl(url) : "";
  return getFileIcon(fileExtension);
};

/**
 * Get thumbnail image url from thumbnail url from database
 * @param url - thumbnail url from database
 * @returns thumbnail image url for image src
 */
export const getThumbnailImageUrl = (url: string | null): string | null => {
  if (!url) return null;
  const fileExtension = getFileExtensionFromUrl(url);
  return formatThumbnailUrl(url, fileExtension);
};
