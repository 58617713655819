import { Row } from "antd";
import { useCallback, useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

import { ContentContext } from "../../../../Provider";
import { endpoints, parseEndpoint } from "../../../../Services/endpoints";
import { IconDownArrow, IconUpArrow } from "../../../atoms";
import { ProjectCheckpointItem } from "../../../molecules";
import RelatedTaskItems from "./RelatedTaskItems";

type Props = {
  projectCheckpoints: Array<any>;
  companyPlan: string;
  projectId: string;
  batchId: string;
  hypothesisFormId: string;
};
const ProjectArchiveCheckPoints = (props: Props) => {
  const {
    companyPlan,
    projectId,
    batchId,
    hypothesisFormId,
    projectCheckpoints,
  } = props;
  const context = useContext(ContentContext);

  const { apiRequest } = context;
  const [items, setItems] = useState([]);
  const [showTasks, setShowTasks] = useState([]);
  const [showAll, setShowAll] = useState(false);

  useEffect(() => {
    let checkpointForms = [];
    if (projectCheckpoints && projectCheckpoints.length > 0) {
      checkpointForms = projectCheckpoints
        .filter((item) => item.checkPointForm)
        .map((item) => item.checkPointForm);
    }
    if (checkpointForms.length > 0) {
      const dataItems = projectCheckpoints
        .filter((item) => item.checkpointFormMetadata.displayCheckpoint)
        .sort((a, b) => {
          return (
            a.checkpointFormMetadata.order - b.checkpointFormMetadata.order
          );
        });
      // @ts-expect-error TS2345
      setItems(dataItems);
    } else {
      onGetCheckPointsAndTasks();
    }
  }, [projectId, batchId, hypothesisFormId, projectCheckpoints]);

  const onShowAll = useCallback(() => {
    setShowAll((showAll) => !showAll);
  }, []);

  const onGetCheckPointsAndTasks = useCallback(() => {
    const requestBuilder = {
      method: "getRequest",
      url: parseEndpoint(endpoints.projectBatchHypothesisCheckpoints, {
        projectId,
        batchId,
        hypothesisFormId,
      }),
      data: {},
    };
    // Only get checkpoint form name and description
    // @ts-expect-error TS2345
    apiRequest(requestBuilder, ({ data }) => {
      // @ts-expect-error TS2339
      if (data && data.length > 0) {
        let checkPoints = [];
        for (const item of projectCheckpoints) {
          // @ts-expect-error TS2339
          const dataItem = data.find(
            // @ts-expect-error TS7006
            (a) => a.checkpointFormId === item.checkpointFormId,
          );
          if (dataItem && item.checkpointFormMetadata.displayCheckpoint) {
            item.checkPointForm = dataItem.CheckPointForms;
            checkPoints.push(item);
          }
        }

        if (checkPoints.length > 0) {
          checkPoints = checkPoints.sort((a, b) => {
            return (
              a.checkpointFormMetadata.order - b.checkpointFormMetadata.order
            );
          });
          // @ts-expect-error TS2345
          setItems(checkPoints);
        }
      }
    });
  }, [projectId, batchId, hypothesisFormId, projectCheckpoints]);

  return (
    <div
      style={{
        background: items && items.length > 0 ? "#FAFAFA" : "#FFF",
        padding: 12,
      }}
    >
      {items &&
        items.length > 0 &&
        (showAll ? items : items.slice(0, 4)).map((item) => (
          <>
            <ProjectCheckpointItem
              disabled={true}
              // @ts-expect-error TS2339
              key={item.id}
              // @ts-expect-error TS2339
              item={item.checkPointForm}
              companyPlan={companyPlan}
              // @ts-expect-error TS2339
              checked={item ? item.isChecked : false}
              // @ts-expect-error TS2322
              onChecked={null}
              // @ts-expect-error TS2339
              tasks={item ? item.relatedTaskList : []}
              // @ts-expect-error TS2339
              showTask={!!showTasks.find((id) => id == item.id)}
              onShowTask={() => {
                let cloneShowTasks = [...showTasks];
                // @ts-expect-error TS2339
                if (showTasks.find((id) => id == item.id)) {
                  cloneShowTasks = cloneShowTasks.filter(
                    // @ts-expect-error TS2339
                    (id) => id !== item.id,
                  );
                } else {
                  // @ts-expect-error TS2345
                  cloneShowTasks.push(item.id);
                }
                setShowTasks(cloneShowTasks);
              }}
            />
            {companyPlan !== "BASIC" &&
              // @ts-expect-error TS2339
              showTasks.find((id) => id == item.id) && (
                <>
                  <RelatedTaskItems
                    projectId={projectId}
                    disabled={true}
                    // @ts-expect-error TS2339
                    tasks={item ? item.relatedTaskList : []}
                    // @ts-expect-error TS2322
                    onUpdateCheckPoints={null}
                  />
                </>
              )}
          </>
        ))}
      {items && items.length > 4 && (
        <Row>
          <div onClick={onShowAll}>
            {!showAll ? IconDownArrow : IconUpArrow}{" "}
            <span>
              {showAll ? (
                <FormattedMessage id="screen.label.hide_related_tasks" />
              ) : (
                <FormattedMessage id="screen.label.show_related_tasks" />
              )}
            </span>
          </div>
        </Row>
      )}
    </div>
  );
};

export default ProjectArchiveCheckPoints;
