import { Form, Row, Space } from "antd";
import { useState } from "react";
import { useIntl } from "react-intl";

import { Input } from "../../../../Components/molecules";
import { AntdButton } from "../../../../shared/buttons/components/AntdButton";
import { ItemWithUnderline } from "../../../../shared/table/components/ItemWithUnderline";
import styles from "./styles.module.scss";
import { DeleteConfirmationFormProps } from "./type";

export const DeleteConfirmationForm = ({
  company,
  hideModal,
  deleteCompanyHandler,
}: DeleteConfirmationFormProps) => {
  const intl = useIntl();
  const [enabledDeleteBtn, setEnabledDeleteBtn] = useState(false);

  const changeInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEnabledDeleteBtn(e.target.value === company.name);
  };

  const confirmationText = intl.formatMessage(
    { id: "screen.label.confirm_deletion" },
    { name: company.name, id: company.id },
  );
  const stopText = intl.formatMessage({ id: "screen.label.stop" });
  const deletedUserText = intl.formatMessage({ id: "screen.label.delete" });
  const activeIdText = intl.formatMessage({
    id: "screen.label.active_id_number",
  });
  const contractIdText = intl.formatMessage({
    id: "screen.label.contract_id_number",
  });
  const cancelText = intl.formatMessage({ id: "screen.label.cancel" });
  const deleteText = intl.formatMessage({ id: "screens.button.delete" });
  const confirmNameText = intl.formatMessage({
    id: "screen.label.confirm_company_name",
  });

  return (
    <div className={styles.content}>
      <h2 className={styles.title}>{confirmationText}</h2>
      <div className={styles.description}>
        <ItemWithUnderline title="stats">
          <Space>
            <span>
              {company.totalStoppedUsers}
              {stopText}
            </span>
            <span>
              {company.totalDeletedUsers}
              {deletedUserText}
            </span>
          </Space>
        </ItemWithUnderline>

        <ItemWithUnderline title={activeIdText}>
          {company.totalAvailableUsers + company.totalStoppedUsers}
        </ItemWithUnderline>

        <ItemWithUnderline title={contractIdText}>
          {company.contractLicense}
        </ItemWithUnderline>
      </div>
      <Form layout={"vertical"}>
        <Form.Item label={confirmNameText} className={styles.form_item}>
          <Input placeholder={company.name} onChange={changeInputHandler} />
        </Form.Item>
      </Form>

      <Row align="middle" justify="center">
        <Space>
          <AntdButton category={"secondary"} onClick={hideModal}>
            {cancelText}
          </AntdButton>
          <AntdButton
            category="danger"
            onClick={deleteCompanyHandler}
            disabled={!enabledDeleteBtn}
          >
            {deleteText}
          </AntdButton>
        </Space>
      </Row>
    </div>
  );
};
