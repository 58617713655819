import { useIntl } from "react-intl";

import { DeleteResultTextProps } from "./type";

export const DeleteResultText = ({
  company,
  isError,
  errorCode,
}: DeleteResultTextProps) => {
  const intl = useIntl();
  const failedMessage = intl.formatMessage(
    {
      id: "screen.label.failed_company_deletion",
    },
    { name: company.name, id: company.id },
  );
  const successMessage = intl.formatMessage(
    {
      id: "screen.label.success_company_deletion",
    },
    { name: company.name },
  );
  const errorCodeMessage = intl.formatMessage(
    {
      id: "screen.label.error_code",
    },
    { errorCode },
  );

  return (
    <div>
      {isError ? failedMessage : successMessage}
      <br />
      {isError && errorCode && errorCodeMessage}
    </div>
  );
};
