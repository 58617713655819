import type { News } from "./type";
export const getPermissionForNews = (user: Object, news: News) => {
  // @ts-expect-error TS2339
  if (user.isSuper) {
    return true;
  }
  // @ts-expect-error TS2339
  if (user.id === news.userId) {
    return true;
  }
  // @ts-expect-error TS2339
  if (user.type === "admin") {
    if (news.category === "from_issuper") {
      return false;
    }
    // @ts-expect-error TS2339
    if (news.User && news.User.isSuper) {
      if (news.category === "from_pm") {
        return true;
      }
      return false;
    }
    return true;
  }
  return false;
};
export const getGlobalPermissionForNews = (user: Object) => {
  if (!user) {
    return true;
  }
  // @ts-expect-error TS2339
  if (user.isSuper) {
    return true;
  }
  return false;
};
export const getPermissionForEmailSend = (user: Object) => {
  if (!user) {
    /// It means global news
    return true;
  }
  // @ts-expect-error TS2339
  if (user.type === "admin") {
    return true;
  }
  return false;
};
