import { notification } from "antd";
import { all, call, put, takeLatest } from "redux-saga/effects";

import BatchExaminationActions, {
  BatchExaminationTypes,
} from "../Redux/BatchExaminationRedux";
import { endpoints, parseEndpoint } from "../Services/endpoints";

// @ts-expect-error TS7006
function* attemptGetsBatchesExamination(api, { data }) {
  const { batchId } = data;

  // @ts-expect-error TS7057
  const batchExaminationResponse = yield call(
    api.getRequest,
    parseEndpoint(endpoints.singleBatchExamination, { batchId }),
  );
  if (batchExaminationResponse.ok) {
    switch (batchExaminationResponse.data.status) {
      case "success": {
        const { batchSubmissionCategories } =
          batchExaminationResponse.data.data;

        const batchExaminationSubmissionCategory = Array.isArray(
          batchSubmissionCategories,
        )
          ? batchSubmissionCategories
          : [];

        batchExaminationSubmissionCategory.sort((a, b) => {
          return a.order - b.order;
        });
        yield put(
          // @ts-expect-error TS2554
          BatchExaminationActions.batchExaminationGetsSuccess({
            batchExaminationSubmissionCategory,
          }),
        );
        break;
      }
    }
  } else {
    notification.error({
      message: batchExaminationResponse.problem,
    });

    if (batchExaminationResponse.status === 401) {
      // @ts-expect-error TS2322
      window.location = "/login";
    }
  }
}

// @ts-expect-error TS7006
function* batchExaminationSaga(api) {
  yield all([
    takeLatest(
      // @ts-expect-error TS2345
      BatchExaminationTypes.BATCH_EXAMINATION_GETS_REQUEST,
      attemptGetsBatchesExamination,
      api,
    ),
  ]);
}

export default batchExaminationSaga;
