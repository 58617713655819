import { notification } from "antd";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import { EventData } from "@/../types/Event";
import { useEventRequest } from "@/Services/event/useEventRequest";
import { objectToFormData } from "@/utils/objectToFormData";

import { EventForm } from "../EventForm";

export const NewEventScreen = () => {
  const intl = useIntl();
  const history = useHistory();
  const { createEvent } = useEventRequest();
  const [thumbnailFile, setThumbnailFile] = useState<File | string | null>(
    null,
  );
  const methods = useForm<Omit<EventData, "thumbnailUrl">>({
    defaultValues: {
      type: "first-come-first-serve",
    },
  });

  const onSubmit = async (data: Omit<EventData, "thumbnailUrl">) => {
    const formData = objectToFormData(data);
    if (thumbnailFile) formData.append("thumbnail", thumbnailFile);

    const { isFailed } = await createEvent(formData);

    if (isFailed) {
      return notification.error({
        message: intl.formatMessage({
          id: "screen.label.error",
        }),
      });
    }

    notification.success({
      message: intl.formatMessage({
        id: "screen.label.event_has_been_created",
      }),
    });

    history.push("/dashboard/events");
  };

  return (
    <FormProvider {...methods}>
      <EventForm
        onSubmit={onSubmit}
        thumbnailFile={thumbnailFile}
        setThumbnailFile={setThumbnailFile}
      />
    </FormProvider>
  );
};
