import { Button, Modal } from "antd";
import Avatar from "react-avatar-edit";
import { FormattedMessage } from "react-intl";

import CameraIcon from "../../assets/icons/camera.svg";
import useProjectImageEdit from "./hooks/useProjectImageEdit";

type Props = {
  project: Object;
  url: string;
  onFileChanged: (file: File) => void;
};
function ProjectImageEdit(props: Props) {
  const {
    intl,
    showModal,
    imageUrl,
    onProfileImageEditClose,
    onProfileImageEditCrop,
    onBeforeFileLoad,
    onChangeFile,
    setShowModal,
  } = useProjectImageEdit(props);
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <img
          style={{
            height: "80px",
            width: "80px",
            marginRight: "1.5vw",
            borderRadius: "40px",
          }}
          src={imageUrl.length > 0 ? imageUrl : CameraIcon}
        />
        <Button
          style={{
            color: "#1F86E9",
            border: "1px solid #1F86E9",
          }}
          onClick={() => setShowModal(true)}
        >
          <FormattedMessage id={"screen.label.upload_image_click"} />
        </Button>
      </div>
      <Modal
        destroyOnClose
        title={intl.formatMessage({
          id: "screen.label.project_image_upload",
        })}
        visible={showModal}
        onCancel={() => setShowModal(false)}
        onOk={() => {
          setShowModal(false);
          onChangeFile();
        }}
        cancelText={intl.formatMessage({
          id: "screen.label.cancel",
        })}
        okText={intl.formatMessage({
          id: "screen.label.application",
        })}
      >
        {/*
         // @ts-expect-error TS2339 */}
        <center>
          <div style={{ overflow: "hidden" }}>
            <Avatar
              label={intl.formatMessage({
                id: "screen.label.please_select_file",
              })}
              borderStyle={{
                borderColor: "blue",
                borderStyle: "dashed",
                borderWidth: "2px",
                borderRadius: "8px",
              }}
              labelStyle={{
                fontSize: "0.8rem",
              }}
              width={200}
              height={200}
              onCrop={onProfileImageEditCrop}
              onClose={onProfileImageEditClose}
              onBeforeFileLoad={onBeforeFileLoad}
            />
            <br />
            <p>
              {intl.formatMessage({
                id: "screen.label.maximum_that_can_be_uploaded_size_10mb",
              })}
            </p>
          </div>
          {/*
         // @ts-expect-error TS2339 */}
        </center>
      </Modal>
    </>
  );
}
export default ProjectImageEdit;
