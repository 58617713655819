import "./styles.css";

import linkifyIt from "linkify-it";
import PropTypes from "prop-types";

const linkify = linkifyIt();

class Linkify {
  constructor(config) {
    this.className = config.className;
    this.separator = config.separator || " ";
  }

  openLink = (url) => {
    const openURL = url.includes("http") ? url : `https://${url}`;
    console.log("linkify openURL", new Date().getTime(), openURL);
    const linkTab = window.open(openURL, "blank"); // eslint-disable-line no-undef
    // linkTab can be null when the window failed to open.
    if (linkTab) {
      linkTab.focus();
    }
  };

  getLinkifyComponent = () => {
    const LinkifyComponent = ({ children }) => {
      const text = children[0].props.text;
      return (
        <a
          onClick={() => {
            this.openLink(text);
          }}
        >
          {children}
        </a>
      );
    };
    LinkifyComponent.propTypes = {
      children: PropTypes.object,
    };
    return LinkifyComponent;
  };

  findLinkifyEntities = (contentBlock, callback) => {
    let text = contentBlock.getText();
    let startIndex = 0;
    let endIndex = 0;

    const links = linkify.match(text);
    if (links && links.length > 0) {
      for (const link of links) {
        startIndex = link.index;
        endIndex = link.lastIndex;
        const linkifyText = text.substr(startIndex, endIndex);
        if (linkifyText && linkifyText.length > 0) {
          callback(startIndex, endIndex);
        }
      }
    }
  };

  getLinkifyDecorator = () => ({
    strategy: this.findLinkifyEntities,
    component: this.getLinkifyComponent(),
  });
}

const getDecorator = (config) => new Linkify(config).getLinkifyDecorator();

export default getDecorator;
