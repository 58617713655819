/* eslint-disable security/detect-object-injection */

/**
 * Convert an object to FormData.
 * @param obj - The object to convert.
 * @param form - The FormData object to append to.
 * @param namespace - The namespace to use for the form data keys.
 * @returns The FormData object.
 */
export const objectToFormData = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  obj: Record<string, any>,
  form: FormData = new FormData(),
  namespace = "",
): FormData => {
  for (const property in obj) {
    if (
      !Object.prototype.hasOwnProperty.call(obj, property) ||
      !obj[property]
    ) {
      continue;
    }

    const formKey = namespace ? `${namespace}[${property}]` : property;

    if (obj[property] instanceof Date) {
      form.append(formKey, obj[property].toISOString());
    } else if (
      typeof obj[property] === "object" &&
      !(obj[property] instanceof File)
    ) {
      objectToFormData(obj[property], form, formKey);
    } else {
      form.append(formKey, obj[property].toString());
    }
  }

  return form;
};
