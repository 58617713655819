import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { defaultThumbnailImg } from "../../../utils/constants";
import { SingleVideo, VideoCardFooter } from "../../atoms/videos";

const VideoTopRoot = styled.div`
  max-height: 281px;
  margin-left: 1vw;
  background-color: white;
  margin-right: 1vw;
  display: contents;
`;

type VideoTopRecommendationCardProps = {
  singleVideo: SingleVideo;
};

export const VideoTopRecommendationCard = (
  props: VideoTopRecommendationCardProps,
) => {
  const { singleVideo } = props;
  const intl = useIntl();
  const history = useHistory();
  if (!singleVideo) {
    return null;
  }
  return (
    <div
      style={{
        marginRight: "0.5vw",
        boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.15)",
        marginBottom: "3vh",
      }}
    >
      <VideoTopRoot>
        <div className={"aspect-ratio-box"} style={{}}>
          <img
            // className={'video-wrapper-view'}
            id={`videos-recommendation-card${singleVideo.id}`}
            className={"aspect-ratio-box-inside"}
            alt={intl.formatMessage({
              id: "screens.alt.video_thumbnail",
            })}
            onClick={() => {
              history.push(`/dashboard/videos-play/${singleVideo.id}`);
            }}
            src={singleVideo.imageThumbnail || defaultThumbnailImg}
            style={{ width: "100%", objectFit: "cover" }}
          />
        </div>
        <VideoCardFooter singleVideo={singleVideo} />
      </VideoTopRoot>
    </div>
  );
};
