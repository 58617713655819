import { arrayMoveImmutable } from "array-move";
import { FC } from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";

import { EditableTag } from "../EditableTag/component";
import { InputTag } from "../InputTag";

// @ts-expect-error TS7031
const SortableItem = SortableElement(({ value }) => (
  <li style={{ marginBottom: "4px" }}>{value}</li>
));

// @ts-expect-error TS7031
const SortableList = SortableContainer(({ items }) => {
  return (
    <ul style={{ listStyleType: "none", padding: 0, marginBottom: "4px" }}>
      {/* @ts-expect-error TS7006 */}
      {items.map((value, index) => (
        <SortableItem key={index} index={index} value={value} />
      ))}
    </ul>
  );
});

export type SortableTagList = {
  tags: string[];
  createLabel: string;
  onCreated: (tag: string) => void;
  onEdited?: (oldTag: string, newTag: string) => void;
  onDeleted: (tag: string) => void;
  onSortEnd: (tags: string[]) => void;
};

export const SortableTagList: FC<SortableTagList> = ({
  tags,
  onCreated,
  onEdited,
  onDeleted,
  onSortEnd,
  createLabel,
}) => {
  const tagElements = tags.map((tag, i) => (
    <EditableTag key={i} tag={tag} onEdited={onEdited} onDeleted={onDeleted} />
  ));

  return (
    <>
      <SortableList
        distance={1}
        // lockAxis="y"
        items={tagElements}
        onSortEnd={({ oldIndex, newIndex }) => {
          onSortEnd(arrayMoveImmutable(tags, oldIndex, newIndex));
        }}
      />
      <InputTag onCreated={onCreated} createLabel={createLabel} />
    </>
  );
};
