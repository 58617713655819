import { notification } from "antd";
import { message } from "antd";
import { EditorState } from "draft-js";
import FileSaver from "file-saver";
import { useEffect, useRef } from "react";
import { useIntl } from "react-intl";

import { endpoints, parseEndpoint } from "../../../Services/endpoints";
import {
  convertFromRawWithTrimmer,
  convertToRawWithTrimmer,
  defaultConentOnProgramInfoRIchTextField,
  getObjUUID,
  sanitizeContentStateMarkupHtml,
} from "../../../utils/common";
import { useStateWithRef } from "../../../utils/hooks";
import {
  IProgramInfoViewMgtScreenProps,
  ProgramInfoViewMgtScreenState,
} from "../types/IProgramInfoViewMgtScreenProps";

const singleFaq = {
  question: "",
  answer: "",
};
const useProgramInfoViewMgtScreen = (props: IProgramInfoViewMgtScreenProps) => {
  const intl = useIntl();
  const { user, apiRequest, updatePageTitle } = props.context;
  const [state, customSetState, stateRef] = useStateWithRef(() => {
    return {
      faq: [singleFaq],
      editorState: EditorState.createEmpty(),
      files: [],
      programInfoStatus: "private",
      videoUrl: "",
    };
  });
  const setStateCallbackRef = useRef(() => {});
  useEffect(() => {
    const callBack = setStateCallbackRef.current;
    callBack();
    setStateCallbackRef.current = () => {};
  }, [state]);
  const setState = (
    data: ProgramInfoViewMgtScreenState,
    callback = () => {},
  ) => {
    setStateCallbackRef.current = callback;
    // @ts-expect-error TS7006
    customSetState((previousState) => {
      return {
        ...previousState,
        ...data,
      };
    });
  };

  useEffect(() => {
    updatePageTitle("screen.label.program_information");
    getProgramInfoDetail();
    getProgramInfoFaq();
    getProgramInfoFiles();
  }, []);

  const getProgramInfoDetail = () => {
    const requestBuilder = {
      method: "getRequest",
      url: parseEndpoint(endpoints.programInfoDetails),
      data: {},
    };
    // @ts-expect-error TS2345
    apiRequest(requestBuilder, ({ data }) => {
      // @ts-expect-error TS2722
      props.dispatchSetProgramInfoPayload(data);
      // @ts-expect-error TS2339
      const { content, status, videos } = data;
      if (content.length === 0) {
        const defaultHtml = `<div>
    <h2>${intl.formatMessage({
      id: "screen.description.business_system",
    })}</h2>
    <h2>${intl.formatMessage({
      id: "screen.label.faq",
    })}</h2>
</div>`;
        setState({
          editorState: defaultConentOnProgramInfoRIchTextField(defaultHtml),
          programInfoStatus: status,
          videoUrl: "",
        });
      } else {
        setState({
          editorState: sanitizeContentStateMarkupHtml(
            convertFromRawWithTrimmer(content),
          ),
          programInfoStatus: status,
          videoUrl: videos[0],
        });
      }
    });
  }; //
  // @ts-expect-error TS7006
  const handleChange = (key, value) => {
    setState({ [key]: value });
  };
  const getUploadProps = () => {
    return {
      name: "file",
      multiple: true,
      headers: {
        Authorization: `Bearer ${user.token}`,
        alphafrontEndPath: window.location.href,
      },
      action: `${process.env.REACT_APP_BASEHOST}companies/${getObjUUID(
        user.company,
      )}/users/upload-file`,
      // @ts-expect-error TS7006
      data: (file) => {
        return {
          filename: file.name,
        };
      },
      // @ts-expect-error TS7006
      onChange: (info) => {
        const { status, response } = info.file;
        console.log("info", status, response);
        if (status !== "uploading") {
        }
        if (status === "done") {
          message.success(`${info.file.name} file uploaded successfully.`);
          createUserFile(response.data);
          getProgramInfoFiles();
        } else if (status === "error") {
          message.error(`${info.file.name} file upload failed.`);
        }
      },
    };
  };
  const getProgramInfoFaq = () => {
    const requestBuilder = {
      method: "getRequest",
      url: parseEndpoint(endpoints.programInfoFaq),
      data: {},
    };
    // @ts-expect-error TS2345
    apiRequest(requestBuilder, ({ data }) => {
      setState({
        faq: data,
      });
    });
  };
  const getProgramInfoFiles = () => {
    const requestBuilder = {
      method: "getRequest",
      url: parseEndpoint(endpoints.programInfoFiles),
      data: {},
    };
    // @ts-expect-error TS2345
    apiRequest(requestBuilder, ({ data }) => {
      setState({
        files: data,
      });
    });
  };

  // @ts-expect-error TS7006
  const onEditorStateChange = (editorState) => {
    setState({
      editorState,
    });
  };
  // @ts-expect-error TS7006
  const fileDownloaded = (fileId, fileDetail = {}) => {
    const data = {
      method: "postRequest",
      url: parseEndpoint(endpoints.fileDownload, {
        fileId,
      }),
      data: fileDetail,
    };
    // @ts-expect-error TS2345
    apiRequest(data, ({ data }) => {});
  };
  // @ts-expect-error TS7006
  const saveFile = (href, fileName, extension, fileId) => {
    const xhr = new XMLHttpRequest();
    xhr.withCredentials = true;
    xhr.open("GET", href, true);
    xhr.responseType = "arraybuffer";
    xhr.onload = (e) => {
      // @ts-expect-error TS18047
      const arrayBufferView = new Uint8Array(e.currentTarget.response);
      const blob = new Blob([arrayBufferView]);
      fileDownloaded(fileId ? fileId : -1, {
        fileName,
        fileSize: blob.size,
      });
      FileSaver.saveAs(blob, fileName);
    };
    xhr.send();
  };
  // @ts-expect-error TS7006
  const uploadImageCallBack = (file) => {
    return new Promise((resolve, reject) => {
      const data = new FormData();
      data.append("file", file);
      data.append("filename", file.name);
      fetch(
        `${process.env.REACT_APP_BASEHOST}companies/${getObjUUID(
          user.company,
        )}/users/upload-file`,
        {
          method: "POST",
          headers: new Headers({
            Authorization: `Bearer ${user.token}`,
            alphafrontEndPath: window.location.href,
          }),
          body: data,
        },
      ).then((response) => {
        response.json().then((body) => {
          if (body.status === "success") {
            let link = `${body.data.hash}`;
            if (link.indexOf("http") === -1) link = `https://${link}`;
            createUserFile(body.data);
            resolve({
              data: {
                link: link,
              },
            });
          } else {
            if (typeof body.data === "string") {
              notification.error({
                message: body.data,
              });
            }
            reject();
          }
        });
      });
    });
  };

  const createUserFile = async (fileBody: any) => {
    console.log("create userfile", fileBody);
    const requestBuilder = {
      method: "postRequest",
      url: `${process.env.REACT_APP_BASEHOST}companies/${user.company.id}/users/upload/program_info_file`,
      data: { ...fileBody },
    };
    // @ts-expect-error TS2345
    apiRequest(requestBuilder, ({ data }) => {});
  };

  // @ts-expect-error TS7006
  const deleteProgramFiles = (programFileId) => {
    const requestBuilder = {
      method: "deleteRequest",
      url: parseEndpoint(endpoints.singleProgramFile, {
        programFileId,
      }),
      data: {},
      successMessage: intl.formatMessage({
        id: "screen.label.delete_success",
      }),
    };
    // @ts-expect-error TS2345
    apiRequest(requestBuilder, ({ data }) => {
      getProgramInfoFiles();
    });
  };
  const saveProgramInfo = () => {
    const content = convertToRawWithTrimmer(stateRef.current.editorState);
    const videos = [stateRef.current.videoUrl];
    const requestBuilder = {
      method: "postRequest",
      url: parseEndpoint(endpoints.programInfoDetails),
      data: {
        content,
        status: stateRef.current.programInfoStatus,
        videos,
      },
      successMessage: intl.formatMessage({
        id: "screen.label.information_has_been_saved",
      }),
    };
    // @ts-expect-error TS2345
    apiRequest(requestBuilder, ({ data }) => {
      // @ts-expect-error TS2722
      props.dispatchSetProgramInfoPayload({
        content,
        status: state.programInfoStatus,
      });
      // @ts-expect-error TS2322
      window.location = "/dashboard/program-info-view";
    });
  };

  return {
    intl,
    state: stateRef.current,
    user,
    handleChange,
    getUploadProps,
    onEditorStateChange,
    saveFile,
    uploadImageCallBack,
    deleteProgramFiles,
    saveProgramInfo,
    setState,
  };
};

export default useProgramInfoViewMgtScreen;
