import { notification } from "antd";
import { all, call, put, takeLatest } from "redux-saga/effects";

import CommentActions, { CommentTypes } from "../Redux/CommentRedux";
import { endpoints, parseEndpoint } from "../Services/endpoints";

// @ts-expect-error TS7006
function* attemptDeleteComment(api, action) {
  const { data, message } = action;

  // @ts-expect-error TS7057
  const commentResponse = yield call(
    api.deleteRequest,
    parseEndpoint(endpoints.deleteProjectComment, { data }),
  );

  if (commentResponse.ok) {
    switch (commentResponse.data.status) {
      case "success":
        yield put(
          // @ts-expect-error TS2554
          CommentActions.commentGetsRequest({
            projectId: data.projectId,
            page: 0,
          }),
        );
        yield put(CommentActions.commentDeleteSuccess());
        notification.success({
          message: message,
        });

        break;
      case "fail":
      case "error":
        if (typeof commentResponse.data.data === "string") {
        }
        yield put(
          // @ts-expect-error TS2554
          CommentActions.commentDeleteFailure(commentResponse.data.data),
        );
        break;
      default:
        break;
    }
  } else {
    notification.error({
      message: commentResponse.problem,
    });
    // @ts-expect-error TS2554
    yield put(CommentActions.commentDeleteFailure(commentResponse.problem));
    if (commentResponse.status === 401) {
      // @ts-expect-error TS2322
      window.location = "/login";
    }
  }
}

// @ts-expect-error TS7006
function* attemptUpdateComment(api, action) {
  const { data, message } = action;

  // @ts-expect-error TS7057
  const commentResponse = yield call(
    api.putRequest,
    parseEndpoint(endpoints.updateProjectComment, { data }),
    data,
  );

  if (commentResponse.ok) {
    switch (commentResponse.data.status) {
      case "success":
        yield put(
          // @ts-expect-error TS2554
          CommentActions.commentGetsRequest({
            projectId: data.projectId,
            page: 0,
          }),
        );
        yield put(CommentActions.commentUpdateSuccess());
        notification.success({
          message: message,
        });

        break;
      case "fail":
      case "error":
        if (typeof commentResponse.data.data === "string") {
        }
        yield put(
          // @ts-expect-error TS2554
          CommentActions.commentUpdateFailure(commentResponse.data.data),
        );
        break;
      default:
        break;
    }
  } else {
    notification.error({
      message: commentResponse.problem,
    });
    // @ts-expect-error TS2554
    yield put(CommentActions.commentUpdateFailure(commentResponse.problem));
    if (commentResponse.status === 401) {
      // @ts-expect-error TS2322
      window.location = "/login";
    }
  }
}

// @ts-expect-error TS7006
function* attemptCreateComment(api, action) {
  const { data, message } = action;

  // @ts-expect-error TS7057
  const commentResponse = yield call(
    api.postRequest,
    parseEndpoint(endpoints.createProjectComment, { data }),
    data,
  );

  if (commentResponse.ok) {
    switch (commentResponse.data.status) {
      case "success":
        yield put(
          // @ts-expect-error TS2554
          CommentActions.commentGetsRequest({
            projectId: data.projectId,
            page: 0,
          }),
        );
        yield put(CommentActions.commentCreateSuccess());
        notification.success({
          message: message,
        });

        break;
      case "fail":
      case "error":
        if (typeof commentResponse.data.data === "string") {
        }
        yield put(
          // @ts-expect-error TS2554
          CommentActions.commentCreateFailure(commentResponse.data.data),
        );
        break;
      default:
        break;
    }
  } else {
    notification.error({
      message: commentResponse.problem,
    });
    // @ts-expect-error TS2554
    yield put(CommentActions.commentCreateFailure(commentResponse.problem));
    if (commentResponse.status === 401) {
      // @ts-expect-error TS2322
      window.location = "/login";
    }
  }
}

// @ts-expect-error TS7006
function* attemptGetAllEvent(api, action) {
  const { data } = action;
  // @ts-expect-error TS7057
  const commentResponse = yield call(
    api.getRequest,
    parseEndpoint(endpoints.getProjectComment, { data }),
  );

  if (commentResponse.ok) {
    switch (commentResponse.data.status) {
      case "success":
        // @ts-expect-error TS2554
        yield put(CommentActions.commentGetsSuccess(commentResponse.data.data));
        break;
      case "fail":
      case "error":
        if (typeof commentResponse.data.data === "string") {
        }
        // @ts-expect-error TS2554
        yield put(CommentActions.commentGetsFailure(commentResponse.data.data));
        break;
      default:
        break;
    }
  } else {
    notification.error({
      message: commentResponse.problem,
    });
    // @ts-expect-error TS2554
    yield put(CommentActions.commentGetsFailure(commentResponse.problem));
    if (commentResponse.status === 401) {
      // @ts-expect-error TS2322
      window.location = "/login";
    }
  }
}

// @ts-expect-error TS7006
function* commentSaga(api) {
  yield all([
    takeLatest(CommentTypes.COMMENT_CREATE_REQUEST, attemptCreateComment, api),
    takeLatest(CommentTypes.COMMENT_UPDATE_REQUEST, attemptUpdateComment, api),
    takeLatest(CommentTypes.COMMENT_DELETE_REQUEST, attemptDeleteComment, api),
    takeLatest(CommentTypes.COMMENT_GETS_REQUEST, attemptGetAllEvent, api),
  ]);
}

export default commentSaga;
