import { useMemo } from "react";
import { useIntl } from "react-intl";
import Immutable from "seamless-immutable";

import { Batch_SubmissionCategory } from "../../../../../types/Batch_SubmissionCategory";
import { CategorySelectOption } from "./type";

export const useBatchSubmissionCategoriesOptions = (
  categories: Batch_SubmissionCategory[],
  categoryId: string | null,
) => {
  const intl = useIntl();

  const options = useMemo(() => {
    const orderedCategories = Immutable.asMutable(categories, {
      deep: true,
    }).sort((a, b) => {
      return a.order - b.order;
    });

    const categoryOptions: CategorySelectOption[] = orderedCategories.map(
      (category) => ({
        value: category.id,
        label: category.submissionCategoryMetadata.submissionCategory,
      }),
    );
    const allCategory = {
      value: null,
      label: intl.formatMessage({
        id: "screen.submission.examination.all_category",
      }),
    };

    return [allCategory, ...categoryOptions];
  }, [categories, intl]);

  const selectedOption = options.find((option) => option.value === categoryId);

  return { options, selectedOption };
};
