import { Modal } from "antd";
import { useState } from "react";
import { useIntl } from "react-intl";

import { ButtonCV } from "../../atoms";
import {
  PrivacyPolicyButton,
  PrivacyPolicyCheckbox,
  PrivacyPolicyCheckboxContainer,
  PrivacyPolicyLabelMessage,
  PrivacyPolicyLabelSubMessage,
} from "../../molecules/agreements/PrivacyPolicy";

type Props = {
  policyUrl: string | null;
  show: boolean;
  onAgreePrivacyPolicy: () => void;
  onCancel: () => void;
};

function PrivacyPolicyModal(props: Props) {
  const { policyUrl, show, onAgreePrivacyPolicy, onCancel } = props;
  const [agreed, setAgreed] = useState(false);
  const intl = useIntl();

  return (
    <>
      <Modal
        centered
        onCancel={() => {
          window.location.href = "/";
          onCancel();
        }}
        closable={true}
        maskClosable={false}
        style={{ minWidth: "800px" }}
        title={intl.formatMessage({
          id: "screen.label.privacy_policy_title",
        })}
        visible={show}
        footer={null}
      >
        <PrivacyPolicyLabelMessage>
          {intl.formatMessage({
            id: "screen.message.privacy_policy1",
          })}
        </PrivacyPolicyLabelMessage>
        <PrivacyPolicyLabelSubMessage>
          {intl.formatMessage({
            id: "screen.message.privacy_policy2",
          })}
        </PrivacyPolicyLabelSubMessage>
        <PrivacyPolicyCheckboxContainer>
          <PrivacyPolicyCheckbox
            // @ts-expect-error TS7006
            onChange={(e) => {
              setAgreed(e.target.checked);
            }}
            checked={agreed}
          >
            <a
              target="_blank"
              className={"view-event-btn"}
              href={policyUrl ?? ""}
              rel="noreferrer"
            >
              {intl.formatMessage({
                id: "screen.label.privacy_policy",
              })}
            </a>
            {intl.formatMessage({
              id: "screen.message.privacy_policy3",
            })}
          </PrivacyPolicyCheckbox>
        </PrivacyPolicyCheckboxContainer>
        <PrivacyPolicyButton>
          <ButtonCV
            size="middle"
            onClick={onAgreePrivacyPolicy}
            disabled={!agreed}
          >
            {intl.formatMessage({
              id: "screen.label.privacy_policy_agreement",
            })}
          </ButtonCV>
        </PrivacyPolicyButton>
      </Modal>
    </>
  );
}
export default PrivacyPolicyModal;
