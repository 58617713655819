import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { RiMapPin2Line } from "react-icons/ri";
import { useIntl } from "react-intl";

import { MAX_INTEGER } from "@/const/numbers";
import { UnderBarCollapse } from "@/shared/collapses/components/UnderbarCollapse";
import { Heading3 } from "@/shared/headings/components/Heading3";
import { DatePicker } from "@/shared/inputs/components/DatePicker";
import { InputWithIcon } from "@/shared/inputs/components/InputWithIcon";
import { PlainInput } from "@/shared/inputs/components/PlainInput";
import { TimeInput } from "@/shared/inputs/components/TimeInput";
import { TimeRangeInput } from "@/shared/inputs/components/TimeRangeInput";
import { RadioButton } from "@/shared/radioButtons/components/RadioButton";
import { PlainTextarea } from "@/shared/textarea/components/PlainTextarea";
import { filterAndConvertToNumber } from "@/utils/filterAndConvertToNumber";

import styles from "./styles.module.scss";

export const EventScheduleInfoForm = () => {
  const { register, control } = useFormContext();

  const intl = useIntl();
  const eventScheduleInfoMessage = intl.formatMessage({
    id: "screens.events.schedule_info",
  });
  const eventScheduleMessage = intl.formatMessage({
    id: "screens.events.schedule",
  });
  const eventLocationMessage = intl.formatMessage({
    id: "screens.events.location",
  });
  const optionalMessage = intl.formatMessage({
    id: "screen.label.optional",
  });
  const enterLocationMessage = intl.formatMessage({
    id: "screens.events.enter_location",
  });
  const receptionMethodMessage = intl.formatMessage({
    id: "screen.label.reception_method",
  });
  const capacityMessage = intl.formatMessage({
    id: "screen.label.capacity",
  });
  const setDeadlineMessage = intl.formatMessage({
    id: "screens.events.set_deadline",
  });
  const customMessage = intl.formatMessage({
    id: "screen.label.event_custom_message",
  });
  const customMessagePlaceholder = intl.formatMessage({
    id: "screens.events.custom_message_placeholder",
  });
  const firstComeFirstServeMessage = intl.formatMessage({
    id: "screens.events.first_come_first_serve",
  });
  const lotteryMessage = intl.formatMessage({
    id: "screens.events.lottery",
  });
  const [capacity, setCapacity] = useState<string | undefined>();

  return (
    <div className={styles.event_info}>
      <Heading3>{eventScheduleInfoMessage}</Heading3>
      <div className={styles.event_column}>
        <span>{eventScheduleMessage}</span>
        <div className={styles.event_date_time}>
          <Controller
            name="eventDate"
            control={control}
            render={({ field }) => {
              return (
                <DatePicker
                  {...field}
                  className={`${styles.mr_8} ${styles.small_input}`}
                />
              );
            }}
          />
          <TimeRangeInput
            startBind={register("eventTime[beginTime]")}
            endBind={register("eventTime[endTime]")}
            className={styles.small_input}
          />
        </div>
      </div>
      <div className={styles.event_column}>
        <span>{receptionMethodMessage}</span>
        <div className={styles.event_type}>
          <RadioButton
            {...register("type")}
            label={firstComeFirstServeMessage}
            value="first-come-first-serve"
          />
          <RadioButton
            {...register("type")}
            label={lotteryMessage}
            value="lottery"
          />
        </div>
      </div>
      <div className={styles.event_column}>
        <span>{capacityMessage}</span>
        <div className={styles.event_capacity}>
          <PlainInput
            {...register("capacity")}
            placeholder="0"
            className={styles.small_input}
            value={capacity}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              // 全角数字を半角数字に変換する
              const newValue = filterAndConvertToNumber(event.target.value);
              if (Number(newValue) > MAX_INTEGER) return;
              setCapacity(newValue);
            }}
          />
          <span>人</span>
        </div>
      </div>
      <UnderBarCollapse
        label={`${eventLocationMessage}(${optionalMessage})`}
        key="eventLocation"
      >
        <InputWithIcon
          {...register("location")}
          Icon={RiMapPin2Line}
          placeholder={enterLocationMessage}
          maxLength={120}
        />
      </UnderBarCollapse>
      <UnderBarCollapse
        label={`${setDeadlineMessage}(${optionalMessage})`}
        key="eventDeadline"
      >
        <div className={styles.event_date_time}>
          <Controller
            name="deadlineDate"
            control={control}
            render={({ field }) => {
              return (
                <DatePicker
                  {...field}
                  className={`${styles.mr_8} ${styles.small_input}`}
                />
              );
            }}
          />
          <Controller
            name="deadlineTime"
            control={control}
            render={({ field }) => {
              return <TimeInput {...field} className={styles.small_input} />;
            }}
          />
        </div>
      </UnderBarCollapse>
      <UnderBarCollapse
        label={`${customMessage}(${optionalMessage})`}
        key="customMessage"
      >
        <PlainTextarea
          {...register("customMessage")}
          placeholder={customMessagePlaceholder}
        />
      </UnderBarCollapse>
    </div>
  );
};
