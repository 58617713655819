import { Button, Card, Col, Form, Row } from "antd";
import { FC, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import CompanyActions from "@/Redux/CompanyRedux";

import { ExaminationLabelTags } from "./ExaminationLabelTags";

export type ExaminationSettingsCardProps = {
  //
};

export const ExaminationSettingsCard: FC<ExaminationSettingsCardProps> = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  // @ts-expect-error TS2339
  const company = useSelector((state) => state.company.companyPayload);

  const [examinationStatusOptions, setExaminationStatusOptions] = useState<
    string[]
  >(company.examinationStatusOptions ?? []);

  const onSubmitSettings = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    dispatch(
      CompanyActions.companyUpdateRequest(
        // @ts-expect-error TS2339
        {
          examinationStatusOptions,
        },
        intl.formatMessage({
          id: "screen.label.updated_company_information",
        }),
      ),
    );
  };

  return (
    <Card
      title={intl.formatMessage({
        id: "screen.label.review_setting",
      })}
      style={{
        marginBottom: 30,
      }}
    >
      <Row>
        <Col offset={4} span={14}>
          <Form layout={"vertical"}>
            <Form.Item
              label={intl.formatMessage({
                id: "screen.label.examination_flag",
              })}
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 18 }}
            >
              <ExaminationLabelTags
                labels={examinationStatusOptions}
                setLabels={(labels) => setExaminationStatusOptions(labels)}
              />
            </Form.Item>

            <Form.Item
              style={{
                alignItems: "center",
                textAlign: "center",
                display: "grid",
              }}
            >
              <Button
                id={"alpha_savecompany"}
                type="primary"
                htmlType="button"
                onClick={(e) => onSubmitSettings(e)}
              >
                {intl.formatMessage({
                  id: "screens.title.save",
                })}
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Card>
  );
};
