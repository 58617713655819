import { memo } from "react";
import { Link } from "react-router-dom";

import { getUserAvatar } from "@/utils/common";

import styles from "./styles.module.scss";
import { PropsCommentedPersonsLink } from "./type";

export const CommentedPersonsLink = memo((props: PropsCommentedPersonsLink) => {
  const { commentedPerson } = props;
  return (
    <Link
      to={`/dashboard/member/${commentedPerson.id}`}
      className={styles.commented_person_link}
    >
      {getUserAvatar(commentedPerson)}
      <span
        title={commentedPerson.name}
        className={styles.commented_persons_name}
      >
        {commentedPerson.name}
      </span>
    </Link>
  );
});
