import { notification } from "antd";

export const notifySuccess = (message: string) => {
  notification.success({
    message,
  });
};

export const notifyError = (message: string) => {
  notification.error({
    message,
  });
};
