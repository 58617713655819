import { Card, Layout, notification } from "antd";
import React from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";

import { NewsCreateForm, NewsFormData } from "../../../../Components/organisms";
import ApiActions, { ApiRequestData } from "../../../../Redux/ApiRedux";
import { endpoints, parseEndpoint } from "../../../../Services/endpoints";
import createFormData from "../../../../utils/createFormData";
const { Content } = Layout;
// @ts-expect-error TS7006
function NewsCreate(props) {
  const [loading, setLoading] = React.useState(false);
  const [companies, setCompanies] = React.useState([]);
  const intl = useIntl();
  const dispatch = useDispatch();
  React.useEffect(() => {
    getCompanies();
  }, []);
  const getCompanies = () => {
    const requestBuilder: ApiRequestData = {
      method: "getRequest",
      url: parseEndpoint(endpoints.companies),
      data: {},
    };
    dispatch(
      ApiActions.apiRequest(requestBuilder, ({ data }) => {
        //@ts-expect-error 要API型定義
        if (data.rows && data.rows.length > 0) {
          //@ts-expect-error 要API型定義
          const { libraryEnables } = data;
          const parseLibraryEnable = Array.isArray(libraryEnables)
            ? libraryEnables.reduce((s, c) => {
                return {
                  ...s,
                  [c.companyId]: {
                    isNewsEnabled: c.isNewsEnabled,
                  },
                };
              }, {})
            : {};
          //@ts-expect-error 要API型定義
          const tempComp = Array.isArray(data.rows)
            ? // @ts-expect-error TS7006
              data.rows.filter((c) => {
                return (
                  parseLibraryEnable[c.id]?.isNewsEnabled ||
                  typeof parseLibraryEnable[c.id] === "undefined"
                );
              })
            : [];
          setCompanies(tempComp);
        }
      }),
    );
  };
  const handleSubmit = (data: NewsFormData) => {
    setLoading(true);
    const requestBuilder: ApiRequestData = {
      method: "postRequest",
      url: parseEndpoint(endpoints.news),
      data: createFormData(data),
    };
    dispatch(
      ApiActions.apiRequest(requestBuilder, ({ data }) => {
        notification.success({
          message: intl.formatMessage({
            id: "screen.label.added_news_item",
          }),
        });
        setLoading(false);
        window.history.back();
      }),
    );
  };
  return (
    <Content>
      <Card>
        <NewsCreateForm
          item={null}
          // @ts-expect-error TS2322
          user={null}
          companies={companies}
          onSubmit={handleSubmit}
          loading={loading}
          isGlobal={true}
        />
      </Card>
    </Content>
  );
}
export default NewsCreate;
