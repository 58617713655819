import { Dispatch } from "@reduxjs/toolkit";
import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";

import { VideoTopRecommendationSection } from "@/Components/organisms/videos";
import { VideoTopCategorySection } from "@/Components/organisms/videos/VideoTopCategorySection";
import VideoActions from "@/Redux/VideoRedux";
import { endpoints, parseEndpoint } from "@/Services/endpoints";

import {
  IVideoTopScreenProps,
  Video,
  VideoLargeCategory,
  VideoTopScreenState,
} from "./types/IVideoTopScreenProps";

type GetVideoTopScreenResult = {
  recommendationVideos: Video[];
  largeCategoriesVideos: Record<string, Video>;
  allLargeCategories: VideoLargeCategory[];
};

const VideoTopScreen: React.FC<IVideoTopScreenProps> = ({
  context: { apiRequest, updatePageTitle },
  dispatchSetVideoCategories,
  largeCategories,
}) => {
  const [state, setState] = useState<VideoTopScreenState>({
    largeCategoriesVideos: {},
    recommendationVideos: [],
  });

  const getVideoTopScreen = useCallback(() => {
    const requestBuilder = {
      method: "getRequest",
      url: parseEndpoint(endpoints.getVideoTopScreen),
      data: {},
    } as const;
    apiRequest?.(
      requestBuilder,
      ({ data }: { data: GetVideoTopScreenResult }) => {
        setState({
          recommendationVideos: data.recommendationVideos || [],
          largeCategoriesVideos: data.largeCategoriesVideos || [],
        });
        dispatchSetVideoCategories?.(data.allLargeCategories);
      },
    );
  }, [apiRequest, dispatchSetVideoCategories]);

  useEffect(() => {
    updatePageTitle?.("screen.label.video");
    getVideoTopScreen();
  }, [getVideoTopScreen, updatePageTitle]);

  return (
    <>
      <VideoTopRecommendationSection
        recommendationVideos={state.recommendationVideos ?? []}
      />
      <br />
      <VideoTopCategorySection
        largeCategoriesVideos={state.largeCategoriesVideos}
        largeCategories={largeCategories ?? []}
      />
    </>
  );
};

const mapStateToProps = (state: {
  videos: { largeCategoriesPayload: VideoLargeCategory[] };
}) => ({
  largeCategories: state.videos.largeCategoriesPayload,
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  dispatchSetVideoCategories: (largeCategories: VideoLargeCategory[]) =>
    // @ts-expect-error TS2554
    dispatch(VideoActions.videoLargeCategoriesGetSuccess(largeCategories)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VideoTopScreen);
