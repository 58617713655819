import { ProjectMemberInfo } from "@/../types/ProjectMember";

/*
 * プロジェクトメンバーをプロジェクトオーナーとメンバーに分け、
 * プロジェクトオーナーが先に来るようにソートする。
 * プロジェクトオーナー同士やメンバー同士の場合はユーザー名でソートする。
 */
export const sortMembersByIsOwnerAndName = (
  member1: ProjectMemberInfo,
  member2: ProjectMemberInfo,
) => {
  // プロジェクトオーナーが先に来るようにソートする
  if (member1.isProjectOwner && !member2.isProjectOwner) {
    return -1;
  }
  if (!member1.isProjectOwner && member2.isProjectOwner) {
    return 1;
  }
  // プロジェクトオーナー同士やメンバー同士の場合はユーザー名でソートする
  // 直近、日本のユーザーにのみサービス提供予定なので、日本語のソートを優先とする
  return member1.name.localeCompare(member2.name, "ja");
};

/*
 * プロジェクトメンバーの中で、削除済みのメンバーは表示しない。
 * 表示可能なメンバーのみを返す。
 * @param projectMembers プロジェクトメンバー
 * @returns 表示するメンバー
 */
export const getDisplayMembers = (projectMembers: ProjectMemberInfo[]) => {
  return projectMembers.filter((member) => {
    return !member.deletedAt;
  });
};
