import { Form, message, Modal, Upload } from "antd";
import {
  UploadChangeParam,
  UploadFile,
  UploadFileStatus,
} from "antd/lib/upload/interface";
import PropTypes from "prop-types";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { ContentContext } from "../../../Provider";
import {
  ReferenceDTO,
  UpdateReferenceDTO,
} from "../../../Screens/reference/hooks/useReference";
import { useConfirmExecution } from "../../../utils/useConfirmExecution";
import { HorizonalLayout } from "../../atoms";
import {
  InsuAcceptButton,
  InsuPrimaryButton,
  InsuRejectButton,
} from "../../atoms/buttons/shared";
import {
  GeneralUploaderComponent,
  TextArea,
  useFileUpload,
} from "../../molecules";
import { IUploadResponse } from "./NewReferenceModal";
import SingleUploadedReferenceFile from "./SingleUploadedReferenceFile";

type EditReferenceModalProps = {
  visible: boolean;
  globalReference: boolean;
  onDismiss: (selectedReference: ReferenceDTO | undefined) => void;
  selectedReference: ReferenceDTO;
  updateReference: (
    referenceId: string,
    newReferenceDetails: UpdateReferenceDTO,
  ) => void;
};

const EditReferenceModal: React.FC<EditReferenceModalProps> = ({
  visible,
  globalReference,
  onDismiss,
  selectedReference,
  updateReference,
}) => {
  const intl = useIntl();
  const context = useContext(ContentContext);

  const {
    selectedFile,
    beforeUpload,
    handleUpload,
    handleUploadCancel: _handleUploadCancel,
  } = useFileUpload();

  const { confirmExecution, execute, notExecute } =
    useConfirmExecution<IUploadResponse>();

  const { user } = context;
  const fileUploadAction = globalReference
    ? `${process.env.REACT_APP_BASEHOST}reference`
    : `${process.env.REACT_APP_BASEHOST}companies/${user.company.uuid}/reference`;

  const [localReference, setLocalReference] = useState<ReferenceDTO>({
    ...selectedReference,
  });
  // ファイルが一回でも更新されるとtrue
  const [isFileChanged, setIsFileChanged] = useState<boolean>(false);

  useEffect(() => {
    if (selectedReference) {
      setLocalReference({ ...selectedReference });
    }
  }, [selectedReference]);

  useEffect(() => {
    if (selectedFile) {
      setLocalReference((prevState) => {
        return {
          ...prevState,
          fileName: selectedFile.name,
        };
      });
    }
  }, [selectedFile]);

  const truncateEncryptionPart = (url: string) => {
    if (url.split("?").length > 0) {
      return url.split("?")[0];
    }
    return url;
  };

  const handleBeforeUpload = useCallback(
    async (file: File) => {
      const shouldUpload = await beforeUpload(file);
      const limitation = 1024 * 1024 * 1024;
      const fileSize = file?.size;
      if (fileSize == null || !shouldUpload) return Upload.LIST_IGNORE;
      if (fileSize < limitation) return true;
      message.warning(
        `${intl.formatMessage({
          id: "screen.label.file_limit_msg",
        })}`,
      );
      return Upload.LIST_IGNORE;
    },
    [beforeUpload, intl],
  );

  const onUploadingStatusChange = useCallback(
    (
      info: UploadChangeParam<
        UploadFile<{
          data: IUploadResponse;
          status: UploadFileStatus;
        }>
      >,
    ) => {
      if (
        selectedReference &&
        info.file.status === "done" &&
        info.file.response
      ) {
        execute(info.file.response.data);
        onDismiss(undefined);
      }
      if (info.file.status === "error" || info.file.status === "removed") {
        notExecute();
      }
    },
    [execute, notExecute, onDismiss, selectedReference],
  );

  const uploadButton = useMemo(() => {
    return (
      <div
        style={{
          width: "70%",
        }}
      >
        <GeneralUploaderComponent<{
          data: IUploadResponse;
          status: UploadFileStatus;
        }>
          baseAction={fileUploadAction}
          handleBeforeUpload={handleBeforeUpload}
          onChange={onUploadingStatusChange}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: "30vh",
            }}
          >
            <div
              style={{
                paddingRight: "3vw",
                paddingLeft: "3vw",
              }}
            >
              <InsuPrimaryButton>
                {intl.formatMessage({
                  id: "screen.label.select_files",
                })}
              </InsuPrimaryButton>
              <p>
                <br />
                <FormattedMessage
                  id={"screen.label.reference_dragger_description_1G"}
                />
              </p>
            </div>
          </div>
        </GeneralUploaderComponent>
      </div>
    );
  }, [fileUploadAction, handleBeforeUpload, intl, onUploadingStatusChange]);

  // 最終的にDBに保存するReferenceを作成する
  const createUpdatingReference = useCallback(async () => {
    if (isFileChanged) {
      handleUpload();
      // データのアップロードが完了するまで待つ
      const uploadedResult = (await confirmExecution()) as IUploadResponse;
      if (uploadedResult) {
        return {
          ...localReference,
          fileName: uploadedResult.fileName,
          fileUrl: uploadedResult.fileUrl,
          thumbnailUrl: uploadedResult.thumbnailUrl,
        };
      }
    }
    return localReference;
  }, [confirmExecution, handleUpload, isFileChanged, localReference]);

  const handleUploadCancel = useCallback(() => {
    _handleUploadCancel();
    onDismiss(undefined);
  }, [_handleUploadCancel, onDismiss]);

  return (
    <Modal
      title={intl.formatMessage({
        id: "screen.label.reference_management",
      })}
      visible={visible}
      footer={null}
      width={"55vw"}
      onCancel={handleUploadCancel}
      destroyOnClose
    >
      <Form layout={"vertical"}>
        <Form.Item
          label={intl.formatMessage({
            id: "screen.label.file",
          })}
        >
          {/* uploadButtonをDOM上で表示していないとファイルアップロードをawaitできないためdisplayを使って見えなくさせている */}
          <div
            style={{
              ...(localReference.fileName && { display: "none" }),
            }}
          >
            {uploadButton}
          </div>
          <div
            style={{
              ...(!localReference.fileName && { display: "none" }),
            }}
          >
            <SingleUploadedReferenceFile
              removedFile={() => {
                setIsFileChanged(true);
                setLocalReference((prevState) => {
                  return {
                    ...prevState,
                    fileName: undefined,
                    fileUrl: undefined,
                    thumbnailUrl: undefined,
                  };
                });
              }}
              fileName={localReference?.fileName ?? ""}
              thumbnailUrl={localReference?.thumbnailUrl ?? ""}
              // @ts-expect-error TS2322
              uploadedAt={localReference?.uploadedAt}
            />
          </div>
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({
            id: "screen.label.more_detail",
          })}
        >
          <TextArea
            onChange={(e) => {
              setLocalReference((prevState) => {
                return {
                  ...prevState,
                  description: e.target.value,
                };
              });
            }}
            value={localReference.description}
            size="large"
            rows={7}
          />
        </Form.Item>
        <Form.Item>
          <HorizonalLayout>
            <InsuAcceptButton
              disabled={!localReference.fileName}
              onClick={async () => {
                const updatingReference = await createUpdatingReference();
                if (
                  selectedReference &&
                  updatingReference.fileName &&
                  updatingReference.fileUrl &&
                  updatingReference.thumbnailUrl
                ) {
                  updateReference(selectedReference.id, {
                    Reference: {
                      description: localReference.description,
                      fileName: updatingReference.fileName,
                      fileUrl: truncateEncryptionPart(
                        updatingReference.fileUrl,
                      ),
                      thumbnailUrl: truncateEncryptionPart(
                        updatingReference.thumbnailUrl,
                      ),
                    },
                  });
                  onDismiss(undefined);
                }
              }}
            >
              <FormattedMessage id={"screen.label.storage"} />
            </InsuAcceptButton>
            <InsuRejectButton
              style={{
                marginLeft: "0.7vw",
              }}
              onClick={handleUploadCancel}
            >
              <FormattedMessage id={"screen.label.cancel"} />
            </InsuRejectButton>
          </HorizonalLayout>
        </Form.Item>
      </Form>
    </Modal>
  );
};

EditReferenceModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  globalReference: PropTypes.bool.isRequired,
  onDismiss: PropTypes.func.isRequired,
  updateReference: PropTypes.func.isRequired,
  selectedReference: PropTypes.any,
};

export default EditReferenceModal;
