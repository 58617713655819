import { DragEndEvent } from "@dnd-kit/core";
import { arrayMove } from "@dnd-kit/sortable";
import { useCallback, useEffect, useState } from "react";

import { UseVerticalSortableListContextProps } from "./type";

export const useVerticalSortableListContext = <T>(
  items: UseVerticalSortableListContextProps<T>,
) => {
  const [sortableItems, setSortableItems] = useState(items);

  useEffect(() => {
    setSortableItems(items);
  }, [items]);

  const hundleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;
      const differenceOfIndexAndId = 1;
      if (!over?.id || active.id === over?.id) return;
      if (typeof active.id !== "number" || typeof over.id !== "number") return;

      // active.idにはソート対象配列の現在のindex + 1、over.idには移動先のindex + 1の値が入ってくる
      // なので それらの値から -1した値がそれぞれ現在の配列のindexと移動先の配列のindexとなる。
      // 0はなぜか使えなかった。https://github.com/clauderic/dnd-kit/issues/858
      const oldIndex = active.id - differenceOfIndexAndId;
      const newIndex = over.id - differenceOfIndexAndId;

      setSortableItems(arrayMove(sortableItems, oldIndex, newIndex));
    },
    [sortableItems],
  );

  return {
    sortableItems,
    hundleDragEnd,
  };
};
