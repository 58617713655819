import { Row } from "antd";
import { useIntl } from "react-intl";

import { ButtonSwitch } from "../../../atoms";

type Props = {
  isAllowCheckpoint: boolean | undefined;
  onChangeStatus: (checked: boolean) => void;
};

const AllowCheckpoint = (props: Props) => {
  const intl = useIntl();
  return (
    <>
      <Row align="middle" justify="start" style={{ marginBottom: 10 }}>
        <h4>
          {intl.formatMessage({
            id: "screen.label.checkpoint_function",
          })}
        </h4>
      </Row>
      <Row align="middle" justify="start" style={{ marginBottom: 10 }}>
        <span className="offset-right-16">
          {intl.formatMessage({
            id: "screen.label.usage",
          })}
        </span>{" "}
        <ButtonSwitch
          checked={props.isAllowCheckpoint}
          onChange={(checked) => {
            props.onChangeStatus(checked);
          }}
        />
      </Row>
    </>
  );
};

export default AllowCheckpoint;
