import { useEffect } from "react";
import { useHistory } from "react-router-dom";

/**
 * GoogleAnalyticsのPageViewイベントを送信する
 * SPAでは、ページ遷移時にイベントを送信する必要があるので、locationが変更されたらイベントを送信する
 * トラッキングIDがない場合は何もしない
 * @see https://developers.google.com/analytics/devguides/collection/gtagjs/single-page-applications?hl=ja
 */
export const usePageViewAnalytics = () => {
  const gaTrackingId = process.env.REACT_APP_GA_TRACKING_ID;

  const { listen } = useHistory();
  useEffect(() => {
    if (!gaTrackingId) return;
    if (!window.gtag) return;
    const stopListen = listen((location) => {
      window.gtag("event", "page_view", {
        page_path: location.pathname,
        send_to: gaTrackingId,
      });
    });
    return stopListen;
  }, [listen, gaTrackingId]);
};
