import "@react-pdf-viewer/zoom/lib/styles/index.css";
import "../../molecules/pdf-zoom-full-screen/styles.css";
import "@react-pdf-viewer/page-navigation/lib/styles/index.css";
import "@react-pdf-viewer/core/lib/styles/index.css";

import {
  CaretLeftFilled,
  CaretRightFilled,
  FullscreenOutlined,
  StepBackwardOutlined,
  StepForwardOutlined,
} from "@ant-design/icons";
import {
  Button,
  RenderViewer,
  SpecialZoomLevel,
  Viewer,
} from "@react-pdf-viewer/core";
import {
  pageNavigationPlugin,
  // @ts-expect-error TS2724
  RenderGoToFirstPageProps,
  // @ts-expect-error TS2724
  RenderGoToLastPageProps,
  // @ts-expect-error TS2724
  RenderGoToNextPageProps,
  // @ts-expect-error TS2724
  RenderGoToPreviousPageProps,
} from "@react-pdf-viewer/page-navigation";
import { zoomPlugin } from "@react-pdf-viewer/zoom";

import { fullScreenPlugin } from "../../molecules/pdf-zoom-full-screen";
const disableScrollPlugin = (): Plugin => {
  const renderViewer = (props: RenderViewer) => {
    const { slot } = props;
    if (slot.subSlot && slot.subSlot.attrs && slot.subSlot.attrs.style) {
      slot.subSlot.attrs.style = Object.assign({}, slot.subSlot.attrs.style, {
        // Disable scrolling in the pages container
        overflow: "hidden",
      });
    }
    return slot;
  };
  return {
    // @ts-expect-error TS2322
    renderViewer,
  };
};
const getPdfLink = (url: string) => {
  if (url.indexOf("data:application/pdf") === -1) {
    if (url.indexOf("http") === -1) {
      return `https://${url}`;
    }
  }
  return url;
};
type Props = {
  url: string;
};
function PdfViewer(props: Props) {
  const fullScreenPluginInstance = fullScreenPlugin();
  const { EnterFullScreen } = fullScreenPluginInstance;
  const pageNavigationPluginInstance = pageNavigationPlugin();
  const {
    CurrentPageLabel,
    GoToFirstPage,
    GoToLastPage,
    GoToNextPage,
    GoToPreviousPage,
  } = pageNavigationPluginInstance;
  const zoomPluginInstance = zoomPlugin();
  const { ZoomInButton, ZoomOutButton, ZoomPopover } = zoomPluginInstance;
  return (
    <div
      style={{
        border: "1px solid rgba(0, 0, 0, 0.1)",
        display: "flex",
        flexDirection: "column",
        height: "58.25vh",
        width: "100vh",
      }}
    >
      <div
        style={{
          alignItems: "center",
          backgroundColor: "#eeeeee",
          borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
          display: "flex",
          justifyContent: "center",
          padding: "4px",
        }}
      >
        <ZoomOutButton />
        <ZoomPopover />
        <ZoomInButton />
      </div>
      <div
        style={{
          flex: 1,
          overflow: "hidden",
        }}
      >
        <Viewer
          defaultScale={SpecialZoomLevel.PageFit}
          fileUrl={getPdfLink(props.url)}
          plugins={[
            fullScreenPluginInstance,
            pageNavigationPluginInstance,
            zoomPluginInstance,
          ]}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div></div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <GoToFirstPage>
            {(props: RenderGoToFirstPageProps) => (
              <Button onClick={props.onClick}>
                <StepBackwardOutlined
                  style={{
                    fontSize: 20,
                  }}
                />
              </Button>
            )}
          </GoToFirstPage>
          <GoToPreviousPage>
            {(props: RenderGoToPreviousPageProps) => (
              <Button onClick={props.onClick}>
                <CaretLeftFilled
                  style={{
                    fontSize: 20,
                  }}
                />
              </Button>
            )}
          </GoToPreviousPage>
          <CurrentPageLabel>
            {(props) => (
              <span>{`${props.currentPage + 1} of ${
                props.numberOfPages
              }`}</span>
            )}
          </CurrentPageLabel>
          <GoToNextPage>
            {(props: RenderGoToNextPageProps) => (
              <Button onClick={props.onClick}>
                <CaretRightFilled
                  style={{
                    fontSize: 20,
                  }}
                />
              </Button>
            )}
          </GoToNextPage>
          <GoToLastPage>
            {(props: RenderGoToLastPageProps) => (
              <Button onClick={props.onClick}>
                <StepForwardOutlined
                  style={{
                    fontSize: 20,
                  }}
                />
              </Button>
            )}
          </GoToLastPage>
        </div>
        <EnterFullScreen>
          {/*
           // @ts-expect-error TS7006 */}
          {(props) => (
            <Button
              onClick={() => {
                props.onClick();
              }}
            >
              <FullscreenOutlined
                style={{
                  fontSize: 20,
                }}
              />
            </Button>
          )}
        </EnterFullScreen>
      </div>
    </div>
  );
}
export default PdfViewer;
