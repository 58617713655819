import { Action } from "@reduxjs/toolkit";
import { createActions, createReducer } from "reduxsauce";
import Immutable from "seamless-immutable";

import { ToAction, ToActionType } from "./types";

/* ------------- Types and Action Creators ------------- */
type FileActionCreators = {
  filesGetsRequest: () => Action<"FILES_GETS_REQUEST">;
  filesGetsSuccess: () => Action<"FILES_GETS_SUCCESS">;
  filesGetsFailure: () => Action<"FILES_GETS_FAILURE">;

  filesDeleteRequest: () => Action<"FILES_DELETE_REQUEST">;
  filesDeleteSuccess: () => Action<"FILES_DELETE_SUCCESS">;
  filesDeleteFailure: () => Action<"FILES_DELETE_FAILURE">;
};

type FileAction = ToAction<FileActionCreators>;
type FileActionType = ToActionType<FileAction>;

const { Types, Creators } = createActions<FileActionType, FileActionCreators>({
  filesGetsRequest: ["page", "limit", "id", "filter", "sort"],
  filesGetsSuccess: ["filesPayload"],
  filesGetsFailure: ["errors"],

  filesDeleteRequest: ["fileId", "projectId", "message"],
  filesDeleteSuccess: null,
  filesDeleteFailure: ["errors"],
});

export const FilesTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
type FileState = {
  fetching: boolean;
  /** 多分使ってない */
  errors: unknown;
  error?: unknown;
  filesPayload: {
    rows: unknown[];
    count: number;
  };
};

export const INITIAL_STATE = Immutable<FileState>({
  fetching: false,
  errors: {},
  filesPayload: {
    rows: [],
    count: 0,
  },
});

type ImmFileState = typeof INITIAL_STATE;

/* ------------- Reducers ------------- */
const request = (state: ImmFileState) => state.merge({ fetching: true });

const success = (state: ImmFileState) => {
  return state.merge({ fetching: false });
};

// @ts-expect-error TS7031
const failure = (state: ImmFileState, { error }) =>
  state.merge({ fetching: false, error });

const filesGetsRequest = (state: ImmFileState) =>
  state.merge({ fetching: true });

// @ts-expect-error TS7031
const filesGetsSuccess = (state: ImmFileState, { filesPayload }) => {
  return state.merge({ fetching: false, filesPayload });
};

// @ts-expect-error TS7031
const filesGetsFailure = (state: ImmFileState, { error }) =>
  state.merge({ fetching: false, error });

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer<ImmFileState, FileAction>(INITIAL_STATE, {
  [Types.FILES_GETS_REQUEST]: filesGetsRequest,
  [Types.FILES_GETS_SUCCESS]: filesGetsSuccess,
  [Types.FILES_GETS_FAILURE]: filesGetsFailure,

  [Types.FILES_DELETE_REQUEST]: request,
  [Types.FILES_DELETE_SUCCESS]: success,
  [Types.FILES_DELETE_FAILURE]: failure,
});
