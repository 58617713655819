import { notification } from "antd";
import { useEffect, useRef } from "react";
import { useIntl } from "react-intl";

import { endpoints, parseEndpoint } from "../../../Services/endpoints";
import createFormData from "../../../utils/createFormData";
import { useStateWithRef } from "../../../utils/hooks";
import {
  EditSmallCategoryScreenState,
  IEditSmallCategoryScreenProps,
} from "../types/IEditSmallCategoryScreenProps";

const useEditSmallCategoryScreen = (props: IEditSmallCategoryScreenProps) => {
  const intl = useIntl();
  const { apiRequest, updatePageTitle } = props.context;
  const [state, customSetState, stateRef] = useStateWithRef(() => {
    return {
      name: "",
      description: "",
      thumbnail: null,
      hasThumbnailChanged: false,
    };
  });
  const setStateCallbackRef = useRef(() => {});
  useEffect(() => {
    const callBack = setStateCallbackRef.current;
    callBack();
    setStateCallbackRef.current = () => {};
  }, [state]);
  const setState = (
    data: EditSmallCategoryScreenState,
    callback = () => {},
  ) => {
    setStateCallbackRef.current = callback;
    // @ts-expect-error TS7006
    customSetState((previousState) => {
      return {
        ...previousState,
        ...data,
      };
    });
  };
  useEffect(() => {
    updatePageTitle("screen.label.video");
    getSmallCategoryDetails();
  }, []);
  // @ts-expect-error TS7006
  const handleChange = (key, value) => {
    if (key === "thumbnail") {
      setState({
        // @ts-expect-error TS2345
        hasThumbnailChanged: true,
      });
    }
    setState({ [key]: value });
  };

  const onUpdateSmallCategory = () => {
    const { name, description, thumbnail, hasThumbnailChanged } = state;
    if (name && name.length > 0) {
      const smallCategory = {
        name: name,
      };
      if (description) {
        // @ts-expect-error TS2339
        smallCategory.description = description;
      }
      if (thumbnail && hasThumbnailChanged) {
        // @ts-expect-error TS2339
        smallCategory.thumbnail = thumbnail;
      }
      const requestBuilder = {
        method: "putRequest",
        url: parseEndpoint(endpoints.smallCategoryDetails, {
          smallCategoryId: getSmallCategoryId(),
        }),
        data: createFormData(smallCategory),
        successMessage: intl.formatMessage({
          id: "screen.label.small_category_edit_success",
        }),
      };
      // @ts-expect-error TS2345
      apiRequest(requestBuilder, ({ data }) => {});
    } else {
      notification.error({
        message: intl.formatMessage({
          id: "screen.label.small_category_edit_failed",
        }),
      });
    }
  };
  const getSmallCategoryDetails = () => {
    const requestBuilder = {
      method: "getRequest",
      url: parseEndpoint(endpoints.videoSmallCategory, {
        smallCategoryId: getSmallCategoryId(),
      }),
      data: {},
    };
    // @ts-expect-error TS2345
    apiRequest(requestBuilder, ({ data }) => {
      setState({
        // @ts-expect-error TS18046
        name: data.name,
        // @ts-expect-error TS18046
        description: data.description,
        // @ts-expect-error TS18046
        thumbnail: data.thumbnail,
      });
    });
  };
  const getSmallCategoryId = () => {
    const {
      match: { params },
    } = props;
    return params.id ? params.id : -1;
  };

  return {
    intl,
    state: stateRef.current,
    handleChange,
    onUpdateSmallCategory,
    getSmallCategoryDetails,
    getSmallCategoryId,
  };
};

export default useEditSmallCategoryScreen;
