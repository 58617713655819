import { Card, Col, Form, Row } from "antd";
import { FC } from "react";
import { useIntl } from "react-intl";

import BatchManagementComponent from "@/Screens/settings/BatchManagementComponent";

export type ProgramSettingsCardProps = {
  //
};

export const ProgramSettingsCard: FC<ProgramSettingsCardProps> = () => {
  const intl = useIntl();

  return (
    <Card
      title={intl.formatMessage({
        id: "screens.label.batch_settings",
      })}
      style={{
        marginBottom: 30,
      }}
    >
      <Row>
        <Col offset={4} span={14}>
          <Form layout={"vertical"}>
            <BatchManagementComponent />
          </Form>
        </Col>
      </Row>
    </Card>
  );
};
