import { Tooltip } from "antd";
import { FormattedMessage, useIntl } from "react-intl";

export const ToolTipForPrivateProject = () => {
  const intl = useIntl();
  const message = intl.formatMessage({
    id: `screens.message.project.private`,
  });

  return (
    <Tooltip
      title={message}
      overlayStyle={{ maxWidth: "350px" }}
      placement={"bottom"}
    >
      <div className="status-title-private">
        <FormattedMessage id="screens.title.project.private" />
      </div>
    </Tooltip>
  );
};
