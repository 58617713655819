import { api } from "@/Saga";

import { ExaminationDetail } from "../../../types/ExaminationDetail";
import { BaseErrorResponse, BaseResponse } from "../commonResponsTypes";
import { endpoints, parseEndpoint } from "../endpoints";
import {
  GetExaminationDetailResponse,
  UpdateExaminationDetailParams,
} from "./type";

export const getExaminationDetail = async (
  submittedProjectUuid: string,
): Promise<ExaminationDetail | undefined> => {
  const url = parseEndpoint(endpoints.submittedProject, {
    submittedProjectId: submittedProjectUuid,
  });
  const response = await api.getRequest<
    GetExaminationDetailResponse,
    BaseErrorResponse
  >(url);

  if (response.ok && response.data?.status === "success") {
    return response.data.data;
  }
};

export const updateExaminationDetail = async (
  submittedProjectUuid: string,
  data: UpdateExaminationDetailParams,
) => {
  const url = parseEndpoint(endpoints.submittedProjectDetail, {
    submittedProjectUuid,
  });
  return api.putRequest<UpdateExaminationDetailParams, BaseResponse<number>>(
    url,
    data,
  );
};

export const deleteExaminationDetail = async (submittedProjectUuid: string) => {
  const url = parseEndpoint(endpoints.submittedProjectDetail, {
    submittedProjectUuid,
  });
  return api.deleteRequest<BaseResponse<number>>(url);
};
