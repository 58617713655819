import { Card, Table } from "antd";
import moment from "moment";
import { FormattedMessage } from "react-intl";

import { CustomPagination } from "../../Components/molecules";
import { dateTimeFormat } from "../../utils/constants";
import useUserActivityScreen from "./hooks/useUserActivityScreen";
import { IUserActivityScreenProps } from "./types/IUserActivityScreenProps";
const PageSaveId = "UserActivityScreen";

const UserActivityScreen = function (props: IUserActivityScreenProps) {
  const { state, setState, getLoginActivities } = useUserActivityScreen(props);
  const getTableColumns = () => {
    return [
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
        responsive: ["sm"],
      },
      {
        title: <FormattedMessage id="screens.title.email" />,
        // @ts-expect-error TS7006
        render: (text, row) => {
          return row.User.Credential.email;
        },
      },
      {
        title: <FormattedMessage id="screens.title.name" />,
        responsive: ["sm"],
        // @ts-expect-error TS7006
        render: (text, row) => {
          return row.User.name;
        },
      },
      {
        title: <FormattedMessage id="screens.title.ipaddress" />,
        dataIndex: "clientIpAddress",
        key: "clientIpAddress",
        responsive: ["sm"],
      },
      {
        title: <FormattedMessage id="screens.title.loginActivityType" />,
        dataIndex: "loginResult",
        key: "loginResult",
        responsive: ["sm"],
        // @ts-expect-error TS7006
        render: function _loginResult(text) {
          return text === "login" ? (
            <FormattedMessage id="screen.label.login" />
          ) : (
            <FormattedMessage id="screen.label.log_out" />
          );
        },
      },
      {
        title: <FormattedMessage id="screens.title.result" />,
        dataIndex: "loginResultOutcome",
        key: "loginResultOutcome",
        responsive: ["sm"],
        // @ts-expect-error TS7006
        render: function _loginResultOutcome(text) {
          return text === "success" ? (
            <FormattedMessage id="screen.label.success" />
          ) : (
            <FormattedMessage id="screen.label.failed" />
          );
        },
      },
      {
        title: <FormattedMessage id="screens.title.last_login" />,
        dataIndex: "lastLoggedInAt",
        key: "lastLoggedInAt",
        responsive: ["sm"],
        // @ts-expect-error TS7006
        render: (text) => moment(text).local().format(dateTimeFormat),
      },
    ];
  }; //

  return (
    <div className={"UserActivityScreen"}>
      <Card>
        <Table
          pagination={false}
          rowKey={"id"}
          // @ts-expect-error TS2322
          columns={getTableColumns()}
          dataSource={
            Array.isArray(state.activities.rows) ? state.activities.rows : []
          }
        />
        <br />
        <CustomPagination
          saveId={PageSaveId}
          count={state.activities.count}
          onChangePage={(page, limit) => {
            setState(
              {
                page,
                limit,
              },
              () => {
                getLoginActivities();
              },
            );
          }}
        />
      </Card>
    </div>
  );
};
export default UserActivityScreen;
