import { useCallback } from "react";
import Select, { OnChangeValue } from "react-select";

import { Arrow } from "@/shared/icons/components/Arrow";
import { SingleDropdownSelectProps } from "@/shared/selectbox/components/SingleDropdownSelect/type";
import { SelectOption } from "@/shared/selectbox/types/SelectOption";

import { SingleSelectOptionItem } from "../SingleSelectOptionItem";
import styles from "./styles.module.scss";

export const SingleDropdownSelect = <OptionValue,>({
  id,
  width,
  options,
  defaultValue,
  selectedValue,
  onSelect,
}: SingleDropdownSelectProps<OptionValue>) => {
  const onChange = useCallback(
    (newValue: OnChangeValue<SelectOption<OptionValue>, false>) => {
      // 存在するOptionが選択された場合のみonSelectを実行する
      if (newValue) {
        onSelect(newValue);
      }
    },
    [onSelect],
  );
  const checkedExists = !!selectedValue;
  const inputId = `${id}-input`;
  const labelColor = `${checkedExists ? styles.checked_label_color : ""}`;
  const containerBorderColor = checkedExists
    ? styles.checked_border_color
    : styles.border_color;
  return (
    <Select<SelectOption<OptionValue>>
      id={id}
      inputId={inputId}
      options={options}
      value={selectedValue}
      components={{
        DropdownIndicator: () => null,
        IndicatorSeparator: () => (
          <Arrow
            size="small"
            direction="down"
            color={`${checkedExists ? "green" : "black"}`}
          />
        ),
        Option: (props) => (
          <SingleSelectOptionItem onSelect={onSelect} {...props} />
        ),
      }}
      onChange={onChange}
      defaultValue={defaultValue}
      styles={{
        container: (baseStyles) => ({
          ...baseStyles,
          border: `1px solid ${containerBorderColor}`,
          borderRadius: "20px",
          width,
          zIndex: 3,
        }),
        control: (baseStyles) => ({
          ...baseStyles,
          fontWeight: Number(styles.normal_font_weight),
          fontSize: Number(styles.secondary_font_size),
          padding: "0 8px",
          backgroundColor: "transparent",
          border: "none",
          boxShadow: "none",
        }),
        singleValue: (baseStyles) => ({
          ...baseStyles,
          color: labelColor,
        }),
        valueContainer: (baseStyles) => ({
          ...baseStyles,
          flexWrap: "nowrap",
          alignItems: "center",
        }),
      }}
      classNames={{
        container: () => styles.select_container,
        control: () => styles.select_box_control,
        indicatorsContainer: () => styles.select_indicators_container,
        valueContainer: () => styles.select_value_container,
        input: () => styles.select_input,
      }}
    />
  );
};
