import { createContext, FC, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { ContentContext } from "@/Provider";
import ApiActions, { ApiRequestData } from "@/Redux/ApiRedux";
import { endpoints, parseEndpoint } from "@/Services/endpoints";

export type ProjectWatchContextType = {
  showWatchModal: boolean;
  setShowWatchModal: (value: boolean) => void;
  createProjectWatch: () => void;
  deleteProjectWatch: () => void;
  toggleProjectWatch: () => void;
  isProjectWatch: boolean | undefined;
  disableWatch: boolean;
};
export const ProjectWatchContext = createContext<ProjectWatchContextType>({
  showWatchModal: false,
  setShowWatchModal: () => {},
  createProjectWatch: () => {},
  deleteProjectWatch: () => {},
  toggleProjectWatch: () => {},
  isProjectWatch: false,
  disableWatch: false,
});

export const ProjectWatchContextProvider: FC = ({ children }) => {
  const { user } = useContext(ContentContext);
  // @ts-expect-error TS2339
  const project = useSelector((state) => state.projects.projectPayload);
  const dispatch = useDispatch();
  const location = useLocation();
  const [projectWatch, setProjectWatch] = useState<{
    isWatch: boolean;
    id: number;
  }>();
  const [showWatchModal, setShowWatchModal] = useState(false);
  const projectId = project.uuid;
  const watchId = projectWatch?.id;
  const isProjectWatch = projectWatch?.isWatch;

  const isPrivate = project?.isPrivate;
  const isAuthorized =
    project.isProjectOwner ||
    project.isTeamMember ||
    user.type === "admin" ||
    user.isSuper;

  const disableWatch = isPrivate && !isAuthorized;

  useEffect(() => {
    const requestBuilder: ApiRequestData = {
      method: "getRequest",
      url: parseEndpoint(endpoints.projectWatch, {
        id: projectId,
      }),
    };
    dispatch(
      ApiActions.apiRequest(requestBuilder, ({ data }) => {
        // @ts-expect-error TS2345
        setProjectWatch(data);
      }),
    );
  }, [dispatch, projectId]);

  useEffect(() => {
    if (
      isProjectWatch === false &&
      // @ts-expect-error TS2339
      location.state?.fromMentioned
    ) {
      setShowWatchModal(true);
    }
  }, [projectWatch, project, location, isProjectWatch]);

  const createProjectWatch = () => {
    const requestBuilder: ApiRequestData = {
      method: "postRequest",
      url: parseEndpoint(endpoints.projectWatch, {
        id: projectId,
      }),
      data: {
        isWatch: true,
      },
    };
    dispatch(
      ApiActions.apiRequest(requestBuilder, ({ data }) => {
        // @ts-expect-error TS2345
        setProjectWatch(data);
      }),
    );
  };

  const deleteProjectWatch = () => {
    const requestBuilder: ApiRequestData = {
      method: "deleteRequest",
      url: parseEndpoint(endpoints.singleProjectWatch, {
        projectId,
        // @ts-expect-error TS2322
        watchId,
      }),
      data: {
        isWatch: false,
      },
    };
    dispatch(
      ApiActions.apiRequest(requestBuilder, ({ data }) => {
        // @ts-expect-error TS2345
        setProjectWatch(data);
      }),
    );
  };

  const toggleProjectWatch = () => {
    if (isProjectWatch) {
      deleteProjectWatch();
    } else {
      createProjectWatch();
    }
  };

  return (
    <ProjectWatchContext.Provider
      value={{
        showWatchModal,
        setShowWatchModal,
        createProjectWatch,
        deleteProjectWatch,
        toggleProjectWatch,
        isProjectWatch,
        disableWatch,
      }}
    >
      {children}
    </ProjectWatchContext.Provider>
  );
};

export const useProjectWatchContext = () => useContext(ProjectWatchContext);
