import { FormattedMessage } from "react-intl";

import { SecondaryButton } from "@/shared/buttons/components/SecondaryButton";
import styles from "@/shared/upload/components/FileSelectItem/styles.module.scss";
import { FileSelectItemProps } from "@/shared/upload/components/FileSelectItem/type";

export const FileSelectItem = ({ onClick, children }: FileSelectItemProps) => {
  return (
    <>
      <SecondaryButton onClick={onClick} data-testId="file-select-item-button">
        <FormattedMessage id="screen.label.select_files" />
      </SecondaryButton>
      <div className={styles.upload_area_message_container}>{children}</div>
    </>
  );
};
