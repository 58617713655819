import { Card } from "antd";

import {
  LanguageSettingContainer,
  Option,
  Select,
} from "../../Components/molecules";
import useLanguageSettingsScreen from "./hooks/useLanguageSettingsScreen";

function LanguageSettingsScreen() {
  const { intl, authPayload, updateUserLanguage, context } =
    useLanguageSettingsScreen();
  return (
    <Card>
      <h3 style={{ fontWeight: "bolder" }}>
        {intl.formatMessage({
          id: "screen.label.lang_setting",
        })}
      </h3>
      <br />
      <LanguageSettingContainer>
        <Select
          key="langSelect"
          onChange={(lang) => {
            context.selectLanguage(lang);
            updateUserLanguage(lang);
          }}
          // @ts-expect-error TS2339
          defaultValue={authPayload.language}
        >
          <Option key={"ja"} value={"ja"}>
            日本語
          </Option>
          <Option key={"en"} value={"en"}>
            English
          </Option>
        </Select>
      </LanguageSettingContainer>
    </Card>
  );
}
export default LanguageSettingsScreen;
