import { DeleteFilled, EditFilled } from "@ant-design/icons";
import { Button, Col, Input, Popconfirm, Row, Tooltip } from "antd";
import moment from "moment";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { withRouter } from "react-router-dom";

import { removeLineBreaks } from "../../../utils/common";
import { UserIcon } from "../../atoms";
import NewsEmoji from "./newsEmoji";
import type { NewsComment } from "./type";
const { TextArea } = Input;
type Props = {
  isCanEditEmoji: boolean;
  isCanEdit: boolean;
  comment: NewsComment;
  companyId: number;
  user: Object;
  onUpdateSubComment: (id: string, commentId: string, content: string) => void;
  onDeleteSubComment: (id: string, commentId: string) => void;
  onAddSubReaction: (
    id: string,
    commentId: string,
    emoji: string,
    emojiName: string,
  ) => void;
};
function NewsSubCommentItem(props: Props) {
  const {
    isCanEditEmoji = true,
    isCanEdit = true,
    comment,
    companyId,
    user,
    onUpdateSubComment,
    onDeleteSubComment,
    onAddSubReaction,
  } = props;
  const intl = useIntl();
  const [updating, setUpdating] = React.useState(false);
  const [content, setContent] = React.useState("");
  React.useEffect(() => {
    if (comment.id && comment.content) {
      setContent(comment.content);
    }
  }, [comment]);
  return (
    <div
      style={{
        marginLeft: 50,
        paddingLeft: 44,
        paddingTop: 16,
        paddingBottom: 16,
        borderLeftColor: "#B5B5B5",
        borderLeftWidth: 2,
        borderLeftStyle: "solid",
      }}
    >
      <Row key={comment.id} align="top" justify="space-between">
        <Col flex="0">
          <UserIcon
            // @ts-expect-error TS2339
            userId={comment?.User.id}
            // @ts-expect-error TS2339
            url={comment?.User.profileImage}
          />
        </Col>
        <Col flex="4">
          <Row align="middle" justify="start" style={{ minHeight: 40 }}>
            <span
              style={{
                fontWeight: "bold",
                color: "black",
                cursor: "pointer",
              }}
            >
              {/*
               // @ts-expect-error TS2339 */}
              {comment.User.name}
            </span>
            <Tooltip
              title={moment(comment.createdAt)
                .local()
                .format("YYYY-MM-DD HH:mm:ss")}
            >
              <span style={{ marginLeft: 10 }}>
                {moment(comment.createdAt).local().fromNow()}
              </span>
            </Tooltip>
          </Row>
        </Col>
        <Col flex="1">
          {isCanEdit && (
            <Row
              style={{
                marginRight: "10px",
              }}
              align="middle"
              justify="end"
            >
              <Col>
                <Button
                  type="ghost"
                  size="small"
                  style={{
                    marginRight: 10,
                  }}
                  onClick={() => {
                    setUpdating(!updating);
                  }}
                  icon={<EditFilled />}
                />
              </Col>
              <Col>
                <Popconfirm
                  title={intl.formatMessage({
                    id: "screen.label.delete_confirm_news_comment",
                  })}
                  onConfirm={() => {
                    onDeleteSubComment(comment.newsId, comment.id);
                  }}
                  okText={intl.formatMessage({
                    id: "screen.label.yes",
                  })}
                  cancelText={intl.formatMessage({
                    id: "screen.label.no",
                  })}
                >
                  <Button type="ghost" size="small" icon={<DeleteFilled />} />
                </Popconfirm>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
      <br />
      <Row>
        {updating ? (
          <TextArea
            autoSize={true}
            style={{ minHeight: 80 }}
            value={content}
            onChange={(event) => {
              setContent(event.target.value);
            }}
            placeholder={intl.formatMessage({
              id: "screen.label.please_enter",
            })}
          />
        ) : (
          <div style={{ whiteSpace: "pre-line" }}>
            {removeLineBreaks(content)}
          </div>
        )}
      </Row>
      {updating && (
        <>
          <br />
          <Row>
            <Button
              type="primary"
              size="small"
              style={{
                paddingRight: 30,
                paddingLeft: 30,
              }}
              onClick={() => {
                if (content !== "") {
                  onUpdateSubComment(comment.newsId, comment.id, content);
                  setUpdating(false);
                }
              }}
            >
              {<FormattedMessage id="screens.title.post" />}
            </Button>
            <Button
              type="ghost"
              size="small"
              style={{
                marginLeft: 16,
                paddingRight: 16,
                paddingLeft: 16,
              }}
              onClick={() => {
                setUpdating(false);
              }}
            >
              {<FormattedMessage id="screen.label.close" />}
            </Button>
          </Row>
        </>
      )}
      <br />
      <NewsEmoji
        key="sub_news_emoji"
        isCanEditEmoji={isCanEditEmoji}
        // @ts-expect-error TS2740
        comment={comment}
        user={user}
        companyId={companyId}
        isSub={true}
        onAddSubReaction={onAddSubReaction}
      />
    </div>
  );
}
// @ts-expect-error TS2345
export default withRouter(NewsSubCommentItem);
