import { memo } from "react";
import { MdClear } from "react-icons/md";
import Select, { ClearIndicatorProps, components } from "react-select";

import { SingleSelectOptionItem } from "@/shared/selectbox/components/SingleSelectOptionItem";

import { SelectOption } from "../../types/SelectOption";
import styles from "./styles.module.scss";
import { PlainSelectProps } from "./type";

const ClearIndicator = <OptionValue,>({
  innerProps: { ref, ...restInnerProps },
  onClick,
}: ClearIndicatorProps<SelectOption<OptionValue>, false> & {
  onClick: () => void;
}) => {
  return (
    <div
      ref={ref}
      className={styles.clear_indicator_container}
      {...restInnerProps}
    >
      <MdClear onClick={onClick} className={styles.clear_indicator} />
    </div>
  );
};

export const PlainSelectComponent = <OptionValue,>({
  id,
  options,
  placeholder,
  onChange,
  onBlur,
  value,
  className,
  label,
  defaultValue,
  isClearable,
  isError,
  optionComponentVariant = "default",
}: PlainSelectProps<OptionValue>) => {
  return (
    <div className={`${styles.input_container} ${className}`}>
      {label && <label className={styles.label}>{label}</label>}
      <Select<SelectOption<OptionValue>>
        isClearable={isClearable}
        inputId={id}
        options={options}
        components={{
          IndicatorSeparator: null,
          ClearIndicator: (props) => (
            <ClearIndicator
              {...props}
              onClick={() => onChange({ value: null, label: "" })}
            />
          ),
          Option: (props) =>
            optionComponentVariant === "check" ? (
              <SingleSelectOptionItem {...props} onSelect={onChange} />
            ) : (
              <components.Option {...props} />
            ),
        }}
        placeholder={placeholder}
        classNames={{
          control: () => styles.select_box,
          indicatorsContainer: () => styles.select_indicators_container,
        }}
        styles={{
          dropdownIndicator: (base) => ({
            ...base,
            padding: 0,
            color: styles.black_color,
          }),
          control: (base) => ({
            ...base,
            borderColor: isError ? styles.error_color : styles.border_color,
          }),
        }}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        defaultValue={defaultValue}
      />
    </div>
  );
};

export const PlainSelect = memo(
  PlainSelectComponent,
) as typeof PlainSelectComponent;
