import { FC } from "react";
import { FormattedMessage } from "react-intl";

import { FileUploadImg } from "../../Components/molecules/project/FileUploadImg";
import useFileUploadComponent from "./hooks/useFileUploadComponent";
import { IFileUploadComponentProps } from "./types/IFileUploadComponentProps";

const FileUploadComponent: FC<IFileUploadComponentProps> = function (props) {
  const { imageSrc, fileOnChange } = useFileUploadComponent(props);

  return (
    <>
      {imageSrc ? (
        <div className={"await-file-upload-file-preview"}>
          <FileUploadImg alt={"Preview Selected "} src={imageSrc.toString()} />
        </div>
      ) : null}
      <label>
        <input
          style={{ display: "none" }}
          accept={props.fileTypeAccept}
          onChange={fileOnChange}
          type={"file"}
          name={"file"}
        />
        <div className={"await-file-upload-container"}>
          <span
            style={{
              fontStyle: "normal",
              fontWeight: "bold",
              fontSize: "0.9rem",
              lineHeight: "200%",
            }}
          >
            {props.message}
          </span>
        </div>
      </label>
    </>
  );
};
FileUploadComponent.defaultProps = {
  // @ts-expect-error TS2322
  message: <FormattedMessage id="screen.label.upload_image_click" />,
  fileTypeAccept: "image/*",
  imageSrc: null,
};

export default FileUploadComponent;
