import { Button } from "antd";
import { useCallback } from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";

import { Reference, ReferenceType } from "../../../../types/Reference";
import { normalizeUrl, saveFile } from "../../../utils/common";

type ReferenceDetailButtonsProps = {
  reference: Reference;
  referenceType: ReferenceType;
};

export const ReferenceDetailButtons = ({
  reference,
  referenceType,
}: ReferenceDetailButtonsProps) => {
  const intl = useIntl();
  const onClickHandler = useCallback(() => {
    saveFile(normalizeUrl(reference.fileUrl), reference.fileName);
  }, [reference.fileName, reference.fileUrl]);

  return (
    <>
      <Button onClick={onClickHandler} type="primary" size="large">
        {intl.formatMessage({
          id: "screen.label.download",
        })}
      </Button>
      <Button type="ghost" className="offset-left-16" size="large">
        <Link to={`/dashboard/reference-${referenceType}`}>
          {intl.formatMessage({
            id: "screen.label.back_to_home",
          })}
        </Link>
      </Button>
    </>
  );
};

export default ReferenceDetailButtons;
