import {
  closestCenter,
  DndContext,
  MouseSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  restrictToParentElement,
  restrictToVerticalAxis,
} from "@dnd-kit/modifiers";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import React from "react";

import { VerticalSortbaleListContextProps } from "./type";

export const VerticalSortbaleListContext = <T,>({
  children,
  sortableItems,
  isSortable,
  hundleDragEnd,
}: React.PropsWithChildren<VerticalSortbaleListContextProps<T>>) => {
  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 10,
    },
  });
  const sensors = useSensors(mouseSensor);
  const differenceOfIndexAndId = 1;
  const incrementedIndexes = sortableItems.map(
    (_, index) => index + differenceOfIndexAndId,
  );

  return (
    <DndContext
      collisionDetection={closestCenter}
      sensors={sensors}
      onDragEnd={hundleDragEnd}
      modifiers={[restrictToVerticalAxis, restrictToParentElement]}
    >
      <SortableContext
        items={incrementedIndexes}
        strategy={verticalListSortingStrategy}
        disabled={!isSortable}
      >
        {children}
      </SortableContext>
    </DndContext>
  );
};
