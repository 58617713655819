import { Card, Col, Modal, Row } from "antd";
import PropTypes from "prop-types";
import { FC, useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { ButtonOption, IconRightArrow } from "../../Components/atoms";
import { ManageCategoriesComponent } from "../../Components/organisms";
import VideoLineDivider from "../../Components/organisms/videos/VideoLineDivider";
import VideoActions from "../../Redux/VideoRedux";
import { endpoints, parseEndpoint } from "../../Services/endpoints";
import {
  ILargeCategoriesManagementScreenProps,
  LargeCategoriesManagementScreenState,
} from "./types/ILargeCategoriesManagementScreenProps";

const LargeCategoriesManagementScreen: FC<ILargeCategoriesManagementScreenProps> =
  function (props) {
    const intl = useIntl();
    const [state, customSetState] =
      useState<LargeCategoriesManagementScreenState>(() => {
        return {
          smallCategories: {},
          isCategoryModalVisible: false,
          recommendedVideos: {
            rows: [],
            count: 0,
          },
        };
      });
    const setStateCallbackRef = useRef(() => {});
    const { apiRequest, updatePageTitle, user } = props.context;
    useEffect(() => {
      const callBack = setStateCallbackRef.current;
      callBack();
      setStateCallbackRef.current = () => {};
    }, [state]);
    const setState = (
      data: LargeCategoriesManagementScreenState,
      callback = () => {},
    ) => {
      setStateCallbackRef.current = callback;
      customSetState((previousState) => {
        return {
          ...previousState,
          ...data,
        };
      });
    };

    useEffect(() => {
      updatePageTitle("screen.label.video");
      getVideoLargeCategories();
      applyFilterVideoRecommendation();
    }, []);

    // @ts-expect-error TS7006
    const getLargeCategoriesToRender = (companyId) => {
      return props.largeCategories
        .filter((l) => l.companyId === companyId)
        .map((l) => {
          return (
            <Col key={l.name} span={24}>
              <Link
                id={`videos-mgt-lcategory-${l.id}`}
                to={`/dashboard/video-mgt/videos-large-category/${l.id}/${l.name}`}
              >
                <Card>
                  <h3
                    style={{
                      fontStyle: "normal",
                      fontWeight: "bold",
                      fontSize: "1.1rem",
                      lineHeight: "26px",
                    }}
                  >
                    {l.name}
                  </h3>
                  <span
                    style={{
                      marginRight: "30px",
                      fontStyle: "normal",
                      fontWeight: "normal",
                      fontSize: "0.8rem",
                      lineHeight: "200%",
                    }}
                  >
                    <FormattedMessage id="screen.label.small_category" />:
                    {l.VideoSmallCategory ? l.VideoSmallCategory.length : 0}
                  </span>
                  <span
                    style={{
                      fontStyle: "normal",
                      fontWeight: "normal",
                      fontSize: "0.8rem",
                      lineHeight: "200%",
                    }}
                  >
                    <FormattedMessage id="screen.label.video" />：
                    {l.Videos ? l.Videos.length : 0}
                  </span>
                  <div
                    style={{
                      float: "right",
                      marginBottom: "30px",
                    }}
                  >
                    {IconRightArrow}
                  </div>
                </Card>
              </Link>
            </Col>
          );
        });
    };
    const applyFilterVideoRecommendation = () => {
      const requestBuilder = {
        method: "getRequest",
        url: parseEndpoint(endpoints.recommendations),
        data: {},
      };
      // @ts-expect-error TS2345
      apiRequest(requestBuilder, ({ data }) => {
        setState({
          recommendedVideos: {
            rows: data,
            // @ts-expect-error TS18046
            count: data.length,
          },
        });
      });
    };
    // @ts-expect-error TS7006
    const actionAfterFetchingLargeCategories = (data) => {
      if (Array.isArray(data)) {
        // @ts-expect-error TS2554
        getLargeCategoriesToRender().forEach((singleLargeCategory) => {
          // @ts-expect-error TS2339
          getSmallCategoryForEachLargeCategory(singleLargeCategory.id);
        });
      }
    };
    // @ts-expect-error TS7006
    const getSmallCategoryForEachLargeCategory = (largeCategoryId) => {
      const requestBuilder = {
        method: "getRequest",
        url: parseEndpoint(endpoints.smallCategoriesForSingleLargeCategory, {
          largeCategoryId,
        }),
        data: {},
      };
      // @ts-expect-error TS2345
      apiRequest(requestBuilder, ({ data }) => {
        const { smallCategories } = state;
        // @ts-expect-error TS18048
        smallCategories[largeCategoryId] = data;
        setState({ smallCategories });
      });
    };
    const getVideoLargeCategories = () => {
      if (props.largeCategories.length === 0) {
        const requestBuilder = {
          method: "getRequest",
          url: parseEndpoint(endpoints.videoLargeCategory),
          data: {},
        };
        // @ts-expect-error TS2345
        apiRequest(requestBuilder, ({ data }) => {
          // @ts-expect-error TS2722
          props.dispatchSetVideoCategories(data);
          actionAfterFetchingLargeCategories(data);
        });
      } else {
        actionAfterFetchingLargeCategories(props.largeCategories);
      }
    };
    // @ts-expect-error TS7006
    const onDeleteSmallCategory = (largeCategoryId, smallCategoryId) => {
      const requestBuilder = {
        method: "deleteRequest",
        url: parseEndpoint(endpoints.videoSmallCategory, {
          smallCategoryId,
        }),
        data: {},
      };
      // @ts-expect-error TS2345
      apiRequest(requestBuilder, ({ data }) => {
        getSmallCategoryForEachLargeCategory(largeCategoryId);
        const requestBuilder = {
          method: "getRequest",
          url: parseEndpoint(endpoints.videoLargeCategory),
          data: {},
        };
        // @ts-expect-error TS2345
        apiRequest(requestBuilder, ({ data }) => {
          // @ts-expect-error TS2722
          props.dispatchSetVideoCategories(data);
        });
      });
    };
    // @ts-expect-error TS7006
    const onSubmitNewCategory = (newSmallCategory) => {
      if (newSmallCategory.name.length > 0) {
        const data = {
          ...newSmallCategory,
          companyId: user.companyId,
        };
        const requestBuilder = {
          method: "postRequest",
          url: parseEndpoint(endpoints.smallCategoriesForSingleLargeCategory, {
            largeCategoryId: newSmallCategory.videoLargeCategoryId,
          }),
          data,
        };
        // @ts-expect-error TS2345
        apiRequest(requestBuilder, ({ data }) => {
          getSmallCategoryForEachLargeCategory(
            newSmallCategory.videoLargeCategoryId,
          );
        });
      }
    };

    return (
      <>
        <Modal
          title={intl.formatMessage({
            id: "screen.label.category_management",
          })}
          // @ts-expect-error TS2339
          visible={state.isCategoryModalVisible}
          footer={null}
          onCancel={() => {
            setState({
              // @ts-expect-error TS2345
              isCategoryModalVisible: false,
            });
          }}
        >
          <ManageCategoriesComponent
            onDeleteSmallCategory={onDeleteSmallCategory}
            onSubmitNewCategory={onSubmitNewCategory}
            smallCategories={state.smallCategories}
            largeCategories={props.largeCategories}
            user={user}
          />
        </Modal>
        <Row align="middle" justify="space-between">
          <Col>
            <h2>
              <FormattedMessage id="screen.label.video_management" />
            </h2>
          </Col>
          <Col>
            <ButtonOption
              onClick={() => {
                setState({
                  // @ts-expect-error TS2345
                  isCategoryModalVisible: true,
                });
              }}
            >
              <Link
                id="videos-mgt-recommendation"
                to={"/dashboard/videos-mgt/recommendation"}
              >
                <FormattedMessage id="screens.videos.recommendation_videos_mgt" />
              </Link>
            </ButtonOption>
          </Col>
        </Row>

        <Row gutter={[0, 7]}>
          {getLargeCategoriesToRender(user.companyId)}
          <Col span={24}>
            <VideoLineDivider />
          </Col>

          {getLargeCategoriesToRender(null)}
        </Row>
      </>
    );
  };

// @ts-expect-error TS7006
const mapStateToProps = (state) => ({
  largeCategories: state.videos.largeCategoriesPayload,
});
// @ts-expect-error TS7006
const mapDispatchToProps = (dispatch) => ({
  // @ts-expect-error TS7006
  dispatchSetVideoCategories: (largeCategories) =>
    // @ts-expect-error TS2554
    dispatch(VideoActions.videoLargeCategoriesGetSuccess(largeCategories)),
});
LargeCategoriesManagementScreen.propTypes = {
  dispatchSetVideoCategories: PropTypes.func,
  // @ts-expect-error TS2322
  largeCategories: PropTypes.array,
  // @ts-expect-error TS2322
  context: PropTypes.object,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LargeCategoriesManagementScreen);
